import React, { Component } from 'react';
import { loadModules } from 'esri-loader';
import ReactDOM from "react-dom";
import { Icon } from '@material-ui/core';
import { MapConstants } from '../util/mapConstants';

class CSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: this.props.view,
            isSelected: false,
            highlightdata: [],
            highlighttaxlotdata: [],
            selectedTaxLots: [],
            primaryTaxLot: ''
        }
        this.startup(
            this.props
        );
        this.mapConstants = new MapConstants();
    }


    startup = (config) => {
        loadModules([
            "esri/core/watchUtils",
            "esri/widgets/Widget",
            "esri/widgets/Sketch/SketchViewModel"
        ]).then(([watchUtils, Widget, SketchViewModel]) => {
            let vm = new SketchViewModel();
            vm.view = config.view;
            //this.setState({ vm: vm });
            //watchUtils.init(config.view, "sketch", this.onChange);
            const node = ReactDOM.findDOMNode(this);
            config.view.ui.add(node, "top-right");
        });

        let objIndex = this.props.options.FieldsData.findIndex((obj => obj.Fieldkey == "TAX_PARCEL_IDS"));
        if (objIndex > 0) {
            let pretaxlotdata = this.props.options.FieldsData[objIndex].FieldValue
            if (pretaxlotdata && pretaxlotdata != "") {
                let predata = pretaxlotdata.split(",");
                this.setState({ selectedTaxLots: predata })
              //  this.state.selectedTaxLots = predata
            }
        }

        let GeomObjIndex = this.props.options.FieldsData.findIndex((obj => obj.Fieldkey == "TAX_PARCEL_IDS_GEOM"));
        if (GeomObjIndex > 0) {
            let pretaxlotdatageom = this.props.options.FieldsData[GeomObjIndex].FieldValue
            if (pretaxlotdatageom && Array.isArray(pretaxlotdatageom) && pretaxlotdatageom.length > 0) {
                this.setState({ highlighttaxlotdata: pretaxlotdatageom })
            //    this.state.highlighttaxlotdata = pretaxlotdatageom
            }
        }
        this.props.widgetRenderCallBack("TaxlotSelector");
        let inThis = this;
        //Binding on click event on the map for selection
        config.view.on("click", function (event) {
            let screenPoint = { x: event.x, y: event.y };

            //alert('selected taxlot button ---' +inThis.state.isSelected)
            // Only when user selected taxlot button-----
            if (inThis.state.isSelected) {
                // Search for graphics at the pointer clicked location
                config.view.hitTest(event).then(function (response) {
                    //AMES specific highlighting the polygon
                    //response.results[0].graphic.geometry.type
                    if (response.results.length > 0) {
                        const isPolygon = (response.results[0].graphic && response.results[0].graphic.geometry
                            && response.results[0].graphic.geometry.type === "polygon");
                        if (isPolygon) {
                            loadModules([
                                "esri/layers/GraphicsLayer"
                            ]).then(([GraphicsLayer]) => {

                                inThis.hightlightlayer = config.view.map.layers.find(function (layer) {
                                    return layer.title === "Highlight Layer";
                                });

                                if (!inThis.hightlightlayer) {
                                    inThis.hightlightlayer = new GraphicsLayer({
                                        "title": "Highlight Layer", listMode: 'hide'
                                    });
                                    config.view.map.layers.add(inThis.hightlightlayer, 0);
                                }

                                try {

                                    //let geometry = response.results[0].graphic.geometry;
                                    let graphic = response.results[0].graphic;
                                    let MapTaxlot = graphic.attributes.PIN || graphic.attributes.Taxlot;
                                    let primaryTaxLot = inThis.state.primaryTaxLot;

                                    if (graphic) {
                                        let existingtaxlotsgeom = inThis.getTaxlotsGeom()
                                        if (existingtaxlotsgeom && Array.isArray(existingtaxlotsgeom) && existingtaxlotsgeom.length > 0) {
                                            let preselection = []
                                            if (inThis.state.highlighttaxlotdata.length > 0) {
                                                preselection = inThis.state.highlighttaxlotdata.map(a => a.attributes.PIN)
                                            }
                                            existingtaxlotsgeom.forEach(each => {
                                                if (!preselection.includes(each.attributes.PIN)) {
                                                    inThis.state.highlighttaxlotdata.push(each)
                                                }
                                            })
                                            //inThis.state.highlighttaxlotdata.concat(existingtaxlotsgeom);
                                        }

                                        //Adding graphics to highlight
                                        if (!inThis.state.highlighttaxlotdata.includes(graphic)) {
                                            inThis.state.highlighttaxlotdata.push(graphic);
                                        } else {
                                            const index = inThis.state.highlighttaxlotdata.indexOf(graphic);
                                            if (index > -1) {
                                                inThis.state.highlighttaxlotdata.splice(index, 1);
                                            }
                                        }

                                        let symbol = inThis.mapConstants.PolygonFillSymbol
                                        let secondarysymbol = inThis.mapConstants.PolygonFillSymbolSecondary
                                        let defaultsymbol = inThis.mapConstants.PolygonFillSymbolReset


                                        if (inThis.state.selectedTaxLots && inThis.state.selectedTaxLots.length == 0) {
                                            primaryTaxLot = MapTaxlot;
                                        } else {
                                            primaryTaxLot = inThis.state.selectedTaxLots[0];
                                        }


                                        if (!inThis.state.selectedTaxLots.includes(MapTaxlot)) {
                                            inThis.state.selectedTaxLots.push(MapTaxlot);
                                        } else {
                                            const index = inThis.state.selectedTaxLots.indexOf(MapTaxlot);
                                            if (index > -1) {
                                                inThis.state.selectedTaxLots.splice(index, 1);
                                            }
                                        }

                                        inThis.hightlightlayer.graphics.removeAll();
                                        inThis.state.highlighttaxlotdata.length > 0 && inThis.state.highlighttaxlotdata.map((eachgraphic, index) => {
                                            let eachTaxlot = eachgraphic.attributes.PIN;
                                            config.view.graphics.remove(eachgraphic); // make sure to remmove previous highlighted feature
                                            if (inThis.state.selectedTaxLots.includes(eachTaxlot)) {
                                                if (primaryTaxLot == eachTaxlot) //index === 0 &&
                                                    eachgraphic.symbol = symbol;
                                                else
                                                    eachgraphic.symbol = secondarysymbol
                                            } else {
                                                eachgraphic.symbol = defaultsymbol;
                                            }
                                            //eachgraphic.attributes.ishighlight = "true";

                                            inThis.hightlightlayer.graphics.add(eachgraphic);
                                        })
                                        //Adding taxparcels to the parent state
                                        if (inThis.props.addTaxParcels)
                                            inThis.props.addTaxParcels(inThis.state.selectedTaxLots.join())
                                        //document.getElementById("selectedTaxLots").innerHTML = inThis.state.selectedTaxLots.join();
                                        inThis.setState({ isSelected: false, primaryTaxLot: primaryTaxLot, selectedTaxLots: inThis.state.selectedTaxLots })
                                    }

                                } catch (e) {
                                    console.log(e);

                                }

                            })
                        }
                    }
                });
            }
        }.bind(this));
    }


    getTaxlotsGeom() {
        let GeomObjIndex = this.props.options.FieldsData.findIndex((obj => obj.Fieldkey == "TAX_PARCEL_IDS_GEOM"));
        if (GeomObjIndex > 0) {
            let pretaxlotdatageom = this.props.options.FieldsData[GeomObjIndex].FieldValue
            if (pretaxlotdatageom && Array.isArray(pretaxlotdatageom) && pretaxlotdatageom.length > 0) {
                this.setState({ highlighttaxlotdata: pretaxlotdatageom })
             //   this.state.highlighttaxlotdata = pretaxlotdatageom
            }
            return pretaxlotdatageom
        }
        return []
    }

    getTaxlotsMapNumbers() {
        let objIndex = this.props.options.FieldsData.findIndex((obj => obj.Fieldkey == "TAX_PARCEL_IDS"));
        if (objIndex > 0) {
            let pretaxlotdata = this.props.options.FieldsData[objIndex].FieldValue
            if (pretaxlotdata && pretaxlotdata != "") {
                let predata = pretaxlotdata.split(",");
                this.setState({ selectedTaxLots: predata })
             //   this.state.selectedTaxLots = predata
            }
            return pretaxlotdata
        }

        return []
    }

    onChange = (value) => {
        alert(value);

    };

    onSelect = (value) => {
        let preValue = this.state.isSelected
        this.setState({ isSelected: !preValue });

    };


    render() {

        const isSelectedClass = this.state.isSelected ? "map-taxlot-btn map-selector-highlight" : "map-taxlot-btn";
        const selIcon = this.state.isSelected ? 'edit_location' : 'beenhereoutlined'
        return (
            <span className="map-taxlot-btn" style={{order:this.props.order}}>
                <Icon className="map-button raised" onClick={this.onSelect} title="Select TaxLot"> {selIcon} </Icon>
            </span>
        );
    }
}

export default CSelector;