/**
      *********************************************************
      Description: Handles grid component.
      Required props:  type.
      limitations    : just displays the grid data. on double click on the row changes the route.
      *********************************************************
**/

import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { handlecustomCondition } from '../../modules/form/conditions';
import { getOptions, loadreportdata } from '../components/savegridreport';
import { handleActionsToEffects } from '../effects/effects';
import { groupByType } from '../helpers/components';
import { CustomColumnMenu } from './customColumnMenu.jsx';
import GridHeader from './kendoheader';
import DefaultSettings from './settings';
import { useDispatch, useSelector } from 'react-redux';
import { getEnvVariable } from '../../../modules/environmentalList';
const _ = require('lodash');


function handleFieldChange(state, action) {
    const { type, ...otherProps } = action
    if (type === 'sharedValues') {
        const { sharedValues } = otherProps
        const newstate = { ...state, sharedValues: sharedValues }
        return newstate
    }
    if (type === 'updates') {
        let newstate = { ...state }
        const { updates } = otherProps
        const { prop, grid, gridExport, columnProps, value, datastate, layout, validations, required, businessrules, isexport } = updates
        if (prop) {
            prop.forEach(each => {
                const { name, value, result, hasValue, disabled, ...otherProps } = each;
                if (hasValue) {
                    state = { ...state, fieldValues: { ...state.fieldValues, [name]: value || (disabled ? 'Y' : 'N') }, ...otherProps }
                }
                state = { ...state, ...otherProps }
            })
            newstate = { ...state }
        }

        if (grid || gridExport) {
            let gridVal = grid || gridExport
            gridVal.forEach(each => {
                const { data, value, ...otherProps } = each;
                if (data && !_.isEmpty(data)) {
                    let tempData = []
                    if (data && data.value)
                        tempData = data.value.data
                    else if (data && data.Message) {
                        tempData = [];
                        // if (DefaultSettings.getAlert() != null)
                        //     DefaultSettings.getAlert().show('No Results are Found', 'info');
                    }

                    else tempData = data && (data.data ? data.data : (Array.isArray(data) ? data : []));
                    let convertList = {};
                    let columns = newstate.gridColumns;
                    columns.map(res => {
                        if (res.convert)
                            convertList[res.field] = res.convert
                    })

                    let convertListKeys = Object.keys(convertList);
                    convertListKeys && convertListKeys.length > 0 && convertListKeys.forEach(listKeys => {
                        if (data && data.data && data.data.length > 0 || (Array.isArray(data) && data.length > 0)) {
                            tempData.map(list => {
                                if (list[listKeys]) {
                                    //need to change according to case for now date is added
                                    list[listKeys] = moment(list[listKeys]).format(convertList[listKeys])
                                }
                                if (list.NewValue) {
                                    list.NewValue = list.NewValueDesc && list.NewValueDesc !== null ? list.NewValueDesc : list.NewValue
                                }
                                if (list.OldValue) {
                                    list.OldValue = list.OldValueDesc && list.OldValueDesc !== null ? list.OldValueDesc : list.OldValue
                                }
                            })
                        }
                    })
                    if (data) {
                        if (grid) {
                            newstate = { ...newstate, ['data']: tempData, ['total']: data.total ? data.total : (data.value && data.value.total ? data.value.total : tempData.length), isexport: (state.fieldValues.exportaction === 'Y' || state.fieldValues.exportaction == true) ? true : false }
                        }
                        else {
                            newstate = { ...newstate, ['exportdata']: tempData, isexport: (state.fieldValues.exportaction === 'Y' || state.fieldValues.exportaction == true) ? true : false }
                        }
                    }
                }


            })

        }
        if (columnProps) {
            const { value } = columnProps
            newstate = { ...newstate, gridColumns: value }
        }
        if (datastate) {
            newstate = { ...state, dataState: datastate, data: state.data && state.data.data ? process(state.data.data, datastate) : process(state.data, datastate) }
            // return newstate
        }
        if (value) {
            value.forEach(each => {
                const { key, result } = each
                newstate = { ...newstate, fieldValues: { ...newstate.fieldValues, [key]: result } }
            })
            // newstate = { ...newstate } //return newstate = { ...state }
        }
        if (isexport) {
            newstate = { ...state, isexport: false, }
            // return newstate
        }
        return newstate
    }
    return state
}

const defaults = {
    mapActionsToEffects: {}
}

// const DetailComponent = (props) => {
//     const data = props.dataItem.details;

//     if (data) {
//         return (
//             <Grid data={data}>
//                 <GridColumn field="ProductID" title="Product ID" width="120px" />
//                 <GridColumn field="ProductName" title="Product Name" />
//                 <GridColumn field="UnitPrice" title="Unit Price" format="{0:c}" />
//             </Grid>
//         );
//     }

//     return (
//         <div
//             style={{
//                 height: "50px",
//                 width: "100%",
//             }}
//         >
//             <div
//                 style={{
//                     position: "absolute",
//                     width: "100%",
//                 }}
//             >
//                 <div className="k-loading-image" />
//             </div>
//         </div>
//     );
// };
function useGrid(props) {
    const { key, columns, gridkey, title, mapActionsToEffects, displayName, navigation, subnavigation, allgriddata, navigateItem,
        loadreportfilter, ReportJsonQueryStr, aliases, values, dispatchSharedValueChange, addRoute,
        routeParams, headerProps, customizedheight, subgrid, customsubgrid, nosubgridrouting, ...otherProps } = { ...defaults, ...props };
    const [component, setComponent] = useState(null)
    const [setMinWidth, setSetMinWidth] = useState(false)
    const [gridCurrent, setGridCurrent] = useState(0)
    const [rowSelected, setRowSelected] = useState(null)
    const [hasDefaultReport, setHasDefaultReport] = useState(false)
    const [filteredfield, setFilteredField] = useState([])
    const [initialloadextrainfo, setInitialloadextrainfo] = useState(null)
    const currentField = useRef([])
    const _export = useRef(null)
    const _grid = useRef(null)
    const counter = useRef(0)
    let minGridWidth = 0;
    let grid;
    const sharedProps = props.sharedProps || {}
    const sharedValues = props.sharedValues || []
    const history = useHistory()
    const location = useLocation();
    const params = useParams();
    const parentRecordVals = useSelector(state => state.sidebar && state.sidebar.modules);

    const [fields, dispatchFieldsChange] = useReducer(
        handleFieldChange,
        {
            gridColumns: columns, total: 0, data: [], exportdata: [], dataState: { skip: 0, take: 20 }, fieldValues: values,
            conditions: mapActionsToEffects, isexport: false,
            pageable: {
                buttonCount: 2,
                info: true,
                type: 'numeric',
                pageSizes: [10, 20, 30, 40, 50, 100],
                previousNext: true
            }
        }
    )

    const prevState = useRef(fields)
    const { gridColumns, data, total, dataState, fieldValues, conditions, pageable, isexport, exportdata } = fields


    useDeepCompareEffect(() => {
        const updatedSharedValues = sharedValues.reduce((accum, each) => {
            accum[each] = fieldValues[each]
            return accum
        }, {})
        const names = Object.keys(updatedSharedValues)
        if (counter.current > 0 && names.length > 0 && dispatchSharedValueChange) {
            dispatchSharedValueChange({
                type: 'values',
                values: names.map(each => {
                    return {
                        name: aliases[each] || each,
                        value: updatedSharedValues[each]
                    }
                })
            })
        }
        counter.current++
    }, [fieldValues])

    useDeepCompareEffect(() => {
        let filterkey = gridkey
        let tempExtras = (localStorage.getItem(filterkey) && JSON.parse(localStorage.getItem(filterkey))) || { skip: 0, take: 20 }
        const prevfieldValues = prevState.current.fieldValues
        const status = isEqual(prevfieldValues, fieldValues)
        if (!status) {
            const unEqualProps = Object.keys(fieldValues).filter(each => fieldValues[each] != prevfieldValues[each])
            unEqualProps.forEach(each => {
                if (conditions[each])
                    handleEffectUpdates(
                        handleActionsToEffects({
                            fieldValues: { ...fieldValues, ...props.match.params, }, //...prevfieldValues,
                            conditions: conditions[each],
                            extras: tempExtras
                        })
                    )
            })
        }
        prevState.current = fields
    }, [fieldValues])

    useDeepCompareEffect(() => {
        let sharedkeys = Object.keys(sharedProps);
        let filterkey = gridkey
        let tempExtras = (localStorage.getItem(filterkey) && JSON.parse(localStorage.getItem(filterkey))) || { skip: 0, take: 20 }
        if (sharedProps['editOpen'] === undefined)
            setRowSelected(null)
        sharedkeys && sharedkeys.map(share => {
            if (mapActionsToEffects && mapActionsToEffects[share] && (sharedProps[share])) {
                if (addRoute && subgrid && sharedProps['navigation'] && sharedProps['navigation'][routeParams.param]) {
                    let loc = location.pathname
                    let final = loc.substring(0, loc.lastIndexOf("/"))
                    props.history.push(final + '/' + sharedProps['navigation'][routeParams.param])
                }
                handleEffectUpdates(
                    handleActionsToEffects({
                        fieldValues: { ...fieldValues, ...sharedProps, ...props.match.params },
                        conditions: mapActionsToEffects[share],
                        extras: tempExtras
                    })
                )
                if (share === 'load' || share === 'loadtemp') {
                    dispatchSharedValueChange({
                        type: 'value',
                        name: share,
                        value: false
                    })
                }
            }
        })
    }, [sharedProps])

    useDeepCompareEffect(() => {
        dispatchFieldsChange({
            type: 'updates',
            updates: {
                value: Object.keys(sharedProps).map(each => {
                    return {
                        key: each,
                        result: sharedProps[each]
                    }
                })
            }
        })
    }, [sharedProps])


    useEffect(() => {
        grid = document.querySelector('.k-grid');
        window.addEventListener('resize', handleResize);
        gridColumns.map(item => minGridWidth += item.width);
        setGridCurrent(grid.offsetWidth)
        setSetMinWidth(grid.offsetWidth < minGridWidth)
        if (props.hascustomcondition) {
            hascustomconditionLoad();
        }
        initialLoad();
    }, [])

    const hascustomconditionLoad = async () => {
        let initialloadextrainfodata = await handlecustomCondition({ ...props, 'options': { 'crud': { customcondition: props.customcondition } } })
        setInitialloadextrainfo(initialloadextrainfodata)
    }

    const initialLoad = async (id) => {
        if (!subgrid) {
            const { match } = props;
            let filterkey = gridkey
            let tempExtras = (localStorage.getItem(filterkey) && JSON.parse(localStorage.getItem(filterkey))) ||
                { skip: 0, take: 20 }
            let url = match.url;
            if (routeParams && (url.substr(url.lastIndexOf("/") + 1) !== routeParams.replace)) {
                let final = url.substring(0, url.lastIndexOf("/"))
                //props.history.push(final)
            }
            console.log("load")
            if (mapActionsToEffects && mapActionsToEffects['load']) { // dependency and bussiness rules checking
                handleEffectUpdates(
                    handleActionsToEffects({
                        fieldValues: { ...fieldValues, ...props.match.params, ...sharedProps, },
                        conditions: [],
                        global: (mapActionsToEffects['load']) || [],
                        extras: tempExtras// props.hasreport ? filtereddatastate :
                    })
                )
            }
        }
        if (customsubgrid) {
            console.log("custom")
            if (mapActionsToEffects && mapActionsToEffects['load']) { // dependency and bussiness rules checking
                handleEffectUpdates(
                    handleActionsToEffects({
                        fieldValues: { ...fieldValues, ...props.match.params, ...sharedProps, },
                        conditions: [],
                        global: (mapActionsToEffects['load']) || [],
                        extras: { skip: 0, take: 20 }
                    })
                )
            }
        }
        if (props.hasreport) {
            let initalReqDataState = { skip: 0, take: 20 }
            let reportinfo = await loadreportdata(props.attachparamgridkey && props.match && props.match.params && props.match.params.key ? props.match.params.key +
                props.gridkey : props.gridkey, id)
            if (reportinfo) {
                let filtereddatastate = reportinfo && reportinfo.columnsdata ? reportinfo.datastate : reportinfo
                let columnsinfo = reportinfo && reportinfo.columnsdata ? reportinfo.columnsdata.flatMap(i => i.field) : null
                let hasfilterfields = reportinfo && reportinfo.columnsdata && filtereddatastate.filter ? columnsinfo.map(field => {
                    if (GridColumnMenuFilter.active(field, filtereddatastate.filter)) {
                        return field;
                    }
                }) : []
                if (filtereddatastate) {
                    dispatchFieldsChange({
                        type: 'updates',
                        updates: {
                            datastate: filtereddatastate
                        }
                    })
                }
                setFilteredField(hasfilterfields);
                // if (DefaultSettings.getAlert() != null && alert)
                //     DefaultSettings.getAlert().show('Please wait Default filter is applying to the grid', 'success');
                if (filtereddatastate && filtereddatastate.toString() !== initalReqDataState.toString()) {
                    console.log("filtereddatastate")
                    if (mapActionsToEffects && mapActionsToEffects['load']) { // dependency and bussiness rules checking
                        handleEffectUpdates(
                            handleActionsToEffects({
                                fieldValues: { ...fieldValues, ...props.match.params, ...sharedProps, },
                                conditions: [],
                                global: (mapActionsToEffects['load']) || [],
                                extras: filtereddatastate
                            })
                        )
                    }
                }
            }
        }
    }

    const handleResize = () => {
        if (grid.offsetWidth < minGridWidth && !setMinWidth) {
            setSetMinWidth(true)
        } else if (grid.offsetWidth > minGridWidth) {
            setGridCurrent(grid.offsetWidth);
            setSetMinWidth(false)
        }
    }

    // const setWidth = (minWidth) => {
    //     let width = setMinWidth ? minWidth :
    //         minWidth + (gridCurrent - minGridWidth) / gridColumns.length;
    //     return width;
    // }

    const setWidth = (title) => {
        if (title) {
            const baseWidthPerCharacter = 13; // This is an arbitrary value; adjust based on your font
            const standardWidth = 200; // Standard width for titles with less than 10 characters
            const titleLength = title.length;
            const calculatedWidth = titleLength >= 15 ? titleLength * baseWidthPerCharacter : standardWidth;
            return calculatedWidth
        }
        // let width = minWidth ? minWidth : (window.innerWidth / columns.length);
        // return width;
    }


    const handleEffectUpdates = (res) => {
        if (res)
            Promise.all(res).then(results => {
                dispatchFieldsChange({
                    type: 'updates',
                    updates: groupByType(results)
                })
            })
    }

    const navigateItems = (newitem) => {
        setRowSelected(newitem)
        rowRender({ props: { children: '' } }, newitem, true)
    }

    const rowRender = (trElement, gridItem, navigate) => {
        const nocolor = { fontWeight: "normal", fontStyle: 'normal' };
        const green = { fontWeight: "bold", fontStyle: 'italic' };
        if (navigate && gridItem && addRoute && !subgrid && !nosubgridrouting) {
            let url = history.location.pathname.substring(0, history.location.pathname.lastIndexOf("/"))
            if (gridItem.dataItem) {
                history.push(url + '/' + gridItem.dataItem[routeParams.param])
            }
            else {
                history.push(url + '/' + gridItem[routeParams.param])
            }
        };
        const trProps = {
            ...trElement.props,
            style: rowSelected && ((gridItem.dataItem === rowSelected) || (gridItem === rowSelected)) ? green : nocolor,
            onDoubleClick: () => {
                if (mapActionsToEffects && mapActionsToEffects['display']) { // dependency and bussiness rules checking
                    localStorage.setItem('BusinessRules', JSON.stringify([]))
                    counter.current++
                    setRowSelected(gridItem.dataItem)
                    if (addRoute && !subgrid) {
                        history.push(routeParams.replace + '/' + gridItem.dataItem[routeParams.param])
                    }
                    handleEffectUpdates(
                        handleActionsToEffects({
                            //allgriddata stores complete grid data (in that particular page)
                            fieldValues: {
                                ...fieldValues, ...sharedProps, "initialloadextrainfo": initialloadextrainfo && initialloadextrainfo.results ?
                                    initialloadextrainfo.results : null, [displayName]: gridItem.dataItem, [navigation]: gridItem.dataItem,
                                [subnavigation]: gridItem.dataItem, [allgriddata]: data, [navigateItem]: navigateItems, ...props.match.params
                            },
                            conditions: (mapActionsToEffects['display'])
                        })
                    )
                }
            }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    useDeepCompareEffect(() => {
        if (params && routeParams && params[routeParams.routeName] && !rowSelected && data && data.length > 0)
            if (mapActionsToEffects && mapActionsToEffects['display']) {
                let rowData = data.find(each => each.Id.toString() === params[routeParams.routeName])
                if (!rowData || (rowData && !rowData.Id)) {
                    initialLoad()
                }
                else {
                    setTimeout(() => {
                        // eslint-disable-next-line no-unused-expressions
                        handleEffectUpdates(
                            handleActionsToEffects({
                                fieldValues: {
                                    ...fieldValues, ...sharedProps, [displayName]: rowData,
                                    [navigation]: rowData, [subnavigation]: rowData,
                                    [allgriddata]: data, [navigateItem]: navigateItems, ...props.match.params
                                },
                                conditions: (mapActionsToEffects['display'])
                            })
                        ), 2000
                    })
                }
            }
    }, [params, data])

    const onColumnsSubmit = (columnsState) => {
        dispatchFieldsChange({
            type: 'updates',
            updates: {
                columnProps:
                    { value: columnsState }
            }
        })
    }

    const onClearColumnsSubmit = () => {
        dispatchFieldsChange({
            type: 'updates',
            updates: {
                columnProps:
                    { value: columns }
            }
        })
    }

    const handleDataStateChange = (changeEvent) => {
        let exportdata = changeEvent.dataState && changeEvent.dataState.filter ? true : false;
        if (changeEvent.dataState) {
            let filterkey = gridkey
            localStorage.setItem(filterkey, JSON.stringify(changeEvent.dataState))
        }
        else {
            let filterkey = gridkey
            localStorage.removeItem(filterkey);
        }
        let allcolumns = props && props.columns ? props.columns.flatMap(i => i.field) : null
        let hasfilterfields = [];
        hasfilterfields = exportdata && allcolumns ? allcolumns.map(field => {
            if (GridColumnMenuFilter.active(field, changeEvent.dataState.filter)) {
                return field;
            }
        }) : []
        dispatchFieldsChange({
            type: 'updates',
            updates: {
                datastate: changeEvent.dataState
            }
        })
        if (mapActionsToEffects && mapActionsToEffects['load']) { // dependency and bussiness rules checking
            console.log("datastate")
            handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: { ...props.match.params, ...sharedProps },
                    conditions: [],
                    global: (mapActionsToEffects['load']) || [],
                    extras: changeEvent.dataState
                })
            )
        }
        setFilteredField(hasfilterfields)
    }

    const clearFilters = () => {
        let dataState = {
            skip: 0, take: 20
        }
        if (gridkey)
            localStorage.removeItem(gridkey);
        //this.fetchData(dataState);
        onClearColumnsSubmit();
        dispatchFieldsChange({
            type: 'updates',
            updates: {
                datastate: dataState

            }
        })
        console.log("clear")
        if (mapActionsToEffects && mapActionsToEffects['load']) { // dependency and bussiness rules checking
            handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: props.match.params, ...sharedProps,
                    conditions: [],
                    global: (mapActionsToEffects['load']) || [],
                    extras: dataState
                })
            )
        }
        setFilteredField([]);
        setHasDefaultReport(false)
    }


    const handleAdd = () => {
        if (mapActionsToEffects && mapActionsToEffects['add']) { // dependency and bussiness rules checking
            // let initialloadextrainforesults = {initialloadextrainfo : initialloadextrainfo && initialloadextrainfo.results ? initialloadextrainfo.results : null}
            counter.current++
            handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: { ...fieldValues, ...sharedProps, ...props.match.params, initialloadextrainfo },
                    conditions: (mapActionsToEffects['add'])
                })
            )
        }

    }

    const handleReport = () => {
        let datastateval = dataState.value ? dataState.value : dataState
        let gridOptions = getOptions(data, columns, datastateval);
        if (mapActionsToEffects && mapActionsToEffects['report']) {
            counter.current++
            handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: { ...fieldValues, ...sharedProps, ...props.match.params, ReportJsonQueryStr: gridOptions, loadreportfilter: initialLoad },
                    conditions: (mapActionsToEffects['report'])
                })
            )
        }
    }

    const handleBack = () => {
        const { match, backnavigation } = props
        let url = match.url
        let final = url
        if (backnavigation) {
            for (let i = 1; i <= backnavigation; i++) {
                final = final.substring(0, final.lastIndexOf("/"))
                props.history.push(final)
            }
        }
        else {
            final = url.substring(0, url.lastIndexOf("/"))
            props.history.push(final)
        }
    }

    const handleDataPageState = () => {
        let deepCopy = _.cloneDeep(dataState);
        deepCopy.skip = 0;
        deepCopy.take = fields.total;
        const { skip, total, take, ...otherstate } = deepCopy;
        return deepCopy
    }

    useEffect(() => {
        if (isexport)
            if (_export.current !== null) {
                _export.current.save(exportdata, _grid.current.columns);
            }

        dispatchFieldsChange({
            type: 'updates',
            updates: {
                isexport: true
            }
        })
        dispatchFieldsChange({
            type: 'updates',
            updates: {
                prop: [{
                    type: 'prop',
                    name: 'exportaction',
                    hasValue: true,
                    value: false,
                }]
            }
        })
    }, [isexport])

    const NoDataDisplay = (props, field) => {
        if (props.dataItem[field] == null || props.dataItem[field] == '') {
            return <td style={{ color: '#adabaa' }}> N/A </td>
        }
        return <td > {props.dataItem[field]} </td>

    }

    const handleExport = (actiontype) => {
        if (mapActionsToEffects && mapActionsToEffects['load']) { // dependency and bussiness rules checking
            dispatchFieldsChange({
                type: 'updates',
                updates: {
                    prop: [{
                        type: 'prop',
                        name: 'exportaction',
                        hasValue: true,
                        value: true,
                    }]
                }
            })
            handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: { ...props.match.params },
                    conditions: [],
                    global: (mapActionsToEffects['load']) || [],
                    extras: actiontype === 'filterall' ? handleDataPageState() : dataState,
                    kendoProps: true
                })
            )
        }
    }
    //  let gridDataState = { ...dataState, take: pageable.pageSizes.includes(dataState['take']) ? dataState.take : 'All' }
    // let gridPageable = { ...pageable, ['info']: pageable.pageSizes.includes(dataState['take']) ? true : false }
    let reduxVals = {}
    if (fieldValues['GetParentValues']) {
        /**  GetParentValues:  should be add to jsons to data ( applicant, compnay) get from parent data */
        reduxVals = parentRecordVals
    }

    const getHeight = () => {
        if (history && history.location.pathname.includes('Records'))
            return window.innerHeight - 175
        else return window.innerHeight - 125
    }
    return [
        <div className="col-12">
            {headerProps && <GridHeader  {...headerProps} initialloadextrainfo={initialloadextrainfo}
                reportapplied={hasDefaultReport} {...{
                    addOnsByName: {
                        add: { onClick: handleAdd }, excel: { onClick: handleExport },
                        clear: { onClick: clearFilters }, back: { onClick: handleBack }, reports: { onClick: handleReport }
                    }
                }}
                sharedProps={{ ...fieldValues, ...reduxVals }} accessPermissions={props.accessPermissions}

            />}
            <ExcelExport
                data={data}
                fileName={title || 'ExcelFile.xlsx'}
                ref={_export}
            >
                <Grid
                    sortable={true}
                    reorderable
                    style={(otherProps && otherProps.style) ? otherProps.style : {
                        height: props.customizedheight ?
                            window.innerHeight - props.customizedheight : getHeight(),
                        width: 'w-90'
                    }}
                    resizable
                    data={data}
                    total={total}
                    rowRender={rowRender}
                    pageable={pageable}
                    {...dataState}
                    onDataStateChange={handleDataStateChange}
                    ref={_grid}
                ////  detail={props.detail ? DetailComponent : false}
                //     expandField={"expanded"}
                //   onExpandChange={expandChange}
                >
                    {gridColumns && gridColumns.length > 0 && gridColumns.map((each, index) => {
                        if (each.hideinexternal && getEnvVariable('AccessPortal') == 'E') {
                            return null
                        }
                        else if (each.show && (each.field == 'UpdatedOn' || each.field == 'UpdatedByName')) return <GridColumn {...each}
                            filter={each.filter} title={isexport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (dataState && dataState.filter)) ? 'text-primary' : ''}>{each.title}</span>} key={index} width={setWidth(each.title)} cell={e => NoDataDisplay(e, each.field)}
                            columnMenu={
                                props =>
                                    <CustomColumnMenu
                                        {...props}
                                        columns={gridColumns}
                                        onColumnsSubmit={onColumnsSubmit}
                                    />
                            } />
                        else if (each.show) return <GridColumn {...each} filter={each.filter} key={index} width={setWidth(each.title)}
                            title={isexport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (dataState && dataState.filter)) ? 'text-primary' : ''}>{each.title}</span>} columnMenu={
                                props =>
                                    <CustomColumnMenu
                                        {...props}
                                        columns={gridColumns}
                                        onColumnsSubmit={onColumnsSubmit}
                                    />
                            } />
                    })}
                </Grid>
            </ExcelExport>
        </div>
    ]
}


export default withRouter(useGrid)

