import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FolderIcon from '@material-ui/icons/Folder';
import Typography from '@material-ui/core/Typography';
import React, { Fragment, useEffect, useState } from 'react';
import Checkbox from '../../../core/controls/components/checkbox';
import TextBox from '../../../core/controls/components/textbox';
import { getEnvVariable } from '../../environmentalList';
import Datepicker from '../../../core/controls/components/datepicker';
import { fieldValidate } from '../../../core/controls/helpers/checkers';
import { checkReferenceKey } from '../../../controls/jsons/attachments/conditionsbymodule';

export default function ImagesS3Form({ selectedFile, fileProgress, validations, required = [],
    errorObj = {}, NoteView = [], currentUser = '', handleFieldValue, referencekey, handleHideSave }) {

    const [notInitalLoad, setNotInitalLoad] = useState(false);
    const [currentUsername, setCurrentUsername] = useState(currentUser ? currentUser.name : '');

    useEffect(() => {
        setNotInitalLoad(false)
    }, [])

    const FormhandleFieldValue = (evt, index) => {
        const { name, value, checked } = evt.target;
        let error_Obj = fieldValidate(validations[name], value);
        errorObj[name] = error_Obj;
        let temp = NoteView;
        if (temp.length === 0) {
            temp.push({ Notes: '', IsViewable: 'N', TakenBy: (currentUser ? currentUser.name : ''), UploadDate: '' })
        }
        let obj = {
            'TakenBy': name === 'TakenBy' ? value : (temp && temp[index] && temp[index].TakenBy || (currentUser ? currentUser.name : '')),
            'UploadDate': name === 'UploadDate' ? value : (temp && temp[index] && temp[index].UploadDate || ''),
            'Notes': name === 'Notes' ? value : (temp && temp[index] && temp[index].Notes || ''),
            'IsViewable': name === 'IsViewable' ? (checked ? 'Y' : 'N') : (temp && temp[index] && temp[index].IsViewable || '')
        }
        if (notInitalLoad === true)
            setCurrentUsername('')
        let newtemp = { ...temp[index], ...obj }
        temp[index] = newtemp
        handleFieldValue({ data: temp, error: errorObj })
    }

    if (NoteView && NoteView.length > 0) {
        let proceed = false
        NoteView.forEach(element => {
            if (!element['UploadDate'] || (element['UploadDate'] === '')) {
                proceed = true
            }
            if (!element['TakenBy'] || (element['TakenBy'] === '')) {
                proceed = true
            }
        });
        handleHideSave(proceed)
    }

    return <Fragment>
        {
            selectedFile && selectedFile.length > 0 ? <Typography variant="h6"> Selected File(s) to Upload </Typography> : <small className='text-primary text-center'>
                Please Upload the required files </small>
        } <List dense={true}>
            {selectedFile && selectedFile.length > 0 && ([...Array(selectedFile.length).keys()] || []).map((each, index) => {
                return <Fragment key={index}>
                    <ListItem className='col-12'>
                        <ListItemIcon> <FolderIcon /> </ListItemIcon>
                        <ListItemText className='col-6'
                            primary={selectedFile[index].name}
                            secondary={<small className='text-primary'>{"Size: " + (selectedFile[index].size / 1048576).toFixed(2) + " MB"}
                                {Array.isArray(fileProgress) && fileProgress.length > 0 && ' Progress %:' + fileProgress[index].progress}</small>}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText className='col-4'><TextBox error={errorObj['TakenBy'] && errorObj['TakenBy'].error}
                            required={required.indexOf("TakenBy") > -1 ? true : false} label="Taken By" name="TakenBy"
                            value={((NoteView && NoteView.length > 0 && NoteView[index] && NoteView[index].TakenBy) || '')}
                            helperText={errorObj['TakenBy'] && errorObj['TakenBy'].errorText} multiline={true}
                            handleFieldValue={(evt) => FormhandleFieldValue(evt, index)} /></ListItemText>&nbsp;

                        <ListItemText className='col-4'><Datepicker error={errorObj['UploadDate'] && errorObj['UploadDate'].error} required={required.indexOf("UploadDate") > -1 ? true : false}
                            helperText={errorObj['UploadDate'] && errorObj['UploadDate'].errorText} label="Date photo taken" name="UploadDate"
                            value={(NoteView && NoteView.length > 0 && NoteView[index] && NoteView[index].UploadDate) || ''}
                            handleFieldValue={(evt) => { FormhandleFieldValue(evt, index); setNotInitalLoad(true); }}
                            FieldProps={notInitalLoad ? JSON.stringify({ 'AllowDate': 'PD' }) : JSON.stringify({ 'AllowDate': 'PD', 'CurrentDefaultDate': 'Y' })} /></ListItemText> &nbsp;
                        {/* {getEnvVariable('AccessPortal') === 'I' && checkReferenceKey(referencekey) && <ListItemText className='col-4'><Checkbox name="IsViewable"
                            error={errorObj['IsViewable'] && errorObj['IsViewable'].error}
                            helperText={errorObj['IsViewable'] && errorObj['IsViewable'].errorText} required={required.indexOf("IsViewable") > -1 ? true : false}
                            value={(NoteView && NoteView.length > 0 &&
                                NoteView[index] && NoteView[index].IsViewable) || 'N'} label="Can be Viewed by Applicant?" labelPlacement="top" handleFieldValue={(evt) =>
                                    FormhandleFieldValue(evt, index)} /></ListItemText>} */}
                    </ListItem>
                    <ListItem className='col-12'>
                        <ListItemText className='col-12'><TextBox error={errorObj['Notes'] && errorObj['Notes'].error}
                            helperText={errorObj['Notes'] && errorObj['Notes'].errorText} label="Note" name="Notes" value={(NoteView && NoteView.length > 0 && NoteView[index] && NoteView[index].Notes) || ''}
                            required={required.indexOf("Notes") > -1 ? true : false}
                            multiline={true} handleFieldValue={(evt) => FormhandleFieldValue(evt, index)} /></ListItemText>
                    </ListItem>
                </Fragment>
            })}
        </List>
    </Fragment>
}