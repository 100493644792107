import { loadModules } from 'esri-loader';

export function xyToLngLat(cooridnates, spatialReference = null) {
    return new Promise((resolve, reject) => {
        loadModules([            
            "esri/geometry/support/webMercatorUtils"
        ]).then(([webMercatorUtils]) => {
            if(cooridnates.x && cooridnates.y){
                let sReference = spatialReference ? spatialReference : 102763
                let latlong = webMercatorUtils.xyToLngLat(cooridnates.x, cooridnates.y);
                console.log('to LATLONG',latlong);
                resolve({ lat :  latlong[1] , long: latlong[0]});                    
            }else{
                reject('No coordinates')
            }
        })
    });
};

export function getLatLong(X,Y){

    return  (new Promise(function (resolve, reject) {
        loadModules(["esri/tasks/GeometryService",
        "esri/geometry/Point",
        "esri/tasks/support/ProjectParameters",
        "esri/geometry/SpatialReference"]).then(([GeometryService, Point, ProjectParameters, SpatialReference]) => {
            var XCoord = parseInt(X, 10);
            var YCoord = parseInt(Y, 10);

            var inSR = new SpatialReference({wkid: 102763 });
            var outSR = new SpatialReference({wkid: 102100 });

            var gsvc = new GeometryService("https://maps.kytc.ky.gov/arcgis/rest/services/Utilities/Geometry/GeometryServer");
            var inputpoint = new Point(XCoord, YCoord, inSR);
            var prjParams = new ProjectParameters();
            prjParams.geometries = [inputpoint];
            prjParams.outSR = outSR;

             gsvc.project(prjParams).then(res => {
                 console.log(res);
                 resolve({ lat :  res[0].latitude , long: res[0].longitude});   
                 //resolve(res);
             });

            //  gsvc.on("project-complete",function(outputpoint){
            //      console.log(outputpoint); 
            //     resolve(outputpoint);
            // });

            // gsvc.on("error",function(err){
            //     reject(err);
            // })

        })


       
    }));//promise
}

export function getXYFromGeom(geom){

    return  (new Promise(function (resolve, reject) {
        loadModules(["esri/tasks/GeometryService",
        "esri/geometry/Point",
        "esri/tasks/support/ProjectParameters",
        "esri/geometry/SpatialReference"]).then(([GeometryService, Point, ProjectParameters, SpatialReference]) => {
            //var XCoord = parseInt(X, 10);
            //var YCoord = parseInt(Y, 10);

            //var inSR = new SpatialReference({wkid: 102100 });
            var outSR = new SpatialReference({wkid: 102763 });

            var gsvc = new GeometryService("https://maps.kytc.ky.gov/arcgis/rest/services/Utilities/Geometry/GeometryServer");
            //var inputpoint = new Point(XCoord, YCoord, inSR);
            var prjParams = new ProjectParameters();
            prjParams.geometries = [geom];
            prjParams.outSR = outSR;

             gsvc.project(prjParams).then(res => {
                 console.log(res);
                 if(geom.type == 'point'){
                    resolve({ lat :  geom.latitude , long: geom.longitude , x: res[0].x , y: res[0].y });  
                }else if(geom.type == "polyline"){                        
                        resolve({ lat :  geom.extent.center.latitude , long: geom.extent.center.longitude , x: res[0].extent.center.x , y: res[0].extent.center.y }); 
                 }else{
                    resolve({ lat :  res[0].centroid.latitude , long: res[0].centroid.longitude , x: res[0].centroid.x , y: res[0].centroid.y });   
                 }
                 
                 //resolve(res);
             });           

        })
       
    }));//promise
}

export function GetArea(geom){
    return new Promise((resolve, reject) => {
        loadModules([            
            "esri/geometry/geometryEngine"
        ]).then(([GeometryEngine]) => {
            let area = GeometryEngine.geodesicArea(geom, "acres");
            resolve(area); 
        })
    });
}
