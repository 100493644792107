/**
     *********************************************************
     Description: Form Fields Container.Manages Form fields.
     Required props:  type of the form and form fields (components) are required.
     limitations    :  Can be used only when you need that fields should be completely controlled by Form. For example, cannot be used in scenarios where map is
     included as map is not controlled by form. Contains validations, displaying the components based on certain conditions, enabling or disabling the components based on conditions,
     getting the values from service and saving the values.
     *********************************************************
**/




import Button from '@material-ui/core/Button';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import NotificatinoEditor from "../../controls/components/custom/notificationeditor";
import { snackbarMessage, unsavedData, setRecordData } from '../../actions';
import { getEnvVariable } from '../../../modules/environmentalList';
import CustomAlert from './calertbox';
import FormHeader from './cformheader';
import ConfirmationDialog from './confirmationdialog';
import SubForm from './csubform';
import { requestApi } from './utilities';
import { globalvalidate } from './validations';
//import { createRequest } from '../../controls/helpers/rest';
import { conditionsHandle } from '../../modules/form/conditions';
import { setProfile } from '../../security/actions';
import DefaultSettings from '../../controls/components/settings';
const _ = require('lodash');

const initvalues = {
    dropdown: '',
    textbox: '',
    checkbox: false,
    radio: false,
    text: ''
}

const formstyles = {
    formcontainer: {
        //margin: 15
    },
    formrow: {
        margin: 10
    },

    itemrow: {
        margin: 0
    }
}

class CFormContainer extends Component {
    constructor(props) {

        /**
       *********************************************************
       Description: Initialisation of the component.
           formControlsConfigs: contains the initial configuration details of each form field
           formControls: Manages the values the of form fields. It contains errors, value, dropdownvalues, edit, display, effect,
               display  - property that hides or shows the components.
               edit - to show in editable or non-editable format.
               dropdownvalues- necessary for dropdowns.
               effect - Currenlty, it is used for initial hiding or showing of the components.Needs to remove this property and use display.
               errors - Whether the field has an error.

           results: Contains final results to be saved.
           errors: It is a boolean value. If there are errors the form will not be saved.
           changedFieldName: It is an array and contains the name and value of the fields whose values have changed. This is needed to update the values in the fields. Values
           are passed from form to the fields.
           errfieldnames: Contains error and helpertext corresponding to each field mapped accroding to fieldname.
           subcomp: remove subcomp property
       Required props:  compmap, components, options
       limitations    : N/A
       *********************************************************
       **/


        super(props);
        // stores all the configs except values and validations of form fields
        this.formControlsConfigs = {};
        // stores values and validations of form fields
        this.storeFormControlInitValues = {};

        const { compmap, components, options, customedit } = props
        const { headerProps } = options
        const { editbutton } = headerProps ? headerProps : {}

        this.effects = {}
        this.initialresults = null
        components.forEach(each => {
            const { options, type } = each
            const { name, initialvalue, conditions, ...otherOptions } = options
            const transformProps = options.transformProps
            // stores the intial confiurations of fields
            this.formControlsConfigs[name] = { name: name, type: type, conditions: conditions, ...otherOptions }
            // whether to hide or show the field initially
            this.effects[name] = {
                show: true,
                hide: false,
                load: false
            }
            this.storeFormControlInitValues[name] = {
                value: transformProps ? transformProps[initialvalue] : (initialvalue || initvalues[type]),
                error: false,
                touched: 0,
                dropdownvalues: null,
                edit: customedit || (typeof editbutton !== 'undefined' ? (!editbutton || false) : false),
                editduplicate: !editbutton || false,
                effect: this.effects[name],
                callbacksave: false
            }
        })
        let tempinitalResults = this.props.results || this.setInitialResults()

        this.state = {
            formControls: { ...this.storeFormControlInitValues },
            results: tempinitalResults,
            compareresults: tempinitalResults,
            changedFieldName: null,
            errors: false,
            errfieldnames: {},
            opensnackbar: false,
            snackbarmessageType: '',
            snackbarmessage: '',
            components: components,
            subcomp: {},
            isedited: false,
            showview: false,
            showsavebtn: false,
            isfieldchanged: false,
            displayalert: false,
            openNotificationEditor: false,
            openconfirmation: false,
            closeviewconfirmation: false,
            navigatingnext: false,
            navigatingprev: false,
            hideediticon: false,
            hideduplicateicon: false,
            callbacksaveval: false,
            hidesavebutton: false,
            customvalue: {}
        };


    }

    /**
       *********************************************************
       Description: Used when the form components needs to be a child of other component.Get the field values from other component rather than fetching the values.
       Required props:   results.
       limitations    : N/A
       *********************************************************
       **/

    componentDidUpdate(prevProps, prevState) {
        if (this.props && this.props.showcustomvalidations && prevProps.hascustomvalidations !== this.props.hascustomvalidations) {
            if (!(_.isEmpty(this.props.errordata))) {
                this.onError(this.props.errordata)
            }
        }
        if (prevState !== this.state && !this.props.options.excludegetstate) {
            return this.props.getState && this.props.getState(this.state);
        }

        if (prevProps.results !== this.props.results) {
            const { formControls } = this.state
            let tempformControls = {}
            let tempchangedFieldName = []
            const { results } = this.props
            if (results) {// Added as this is undefined on change of section
                let resultskeys = Object.keys(results)
                resultskeys.forEach(each => {
                    if (formControls[each]) {
                        tempformControls[each] = { ...formControls[each], value: results[each] }
                        tempchangedFieldName.push({ name: each, value: results[each] })
                    }
                })
                this.setState({
                    results: this.props.results,
                    formControls: tempformControls,
                    changedFieldName: tempchangedFieldName
                }, () => {
                    this.fieldConfiguration(this.props.components, this.state.results, null, {})
                })
            }
        }
        else if (prevProps.dataItem !== this.props.dataItem) {
            if (this.props.options.hideEditIcon && this.props.options.hidecondition) {
                let condition = null;
                condition = conditionsHandle(this.props, this.props.initialloadextrainfo && this.props.initialloadextrainfo.results ? this.props.initialloadextrainfo.results : this.props.initialloadextrainfo)
                condition = condition ? condition.condition : false
                let adminuser = (this.props.accessPermissions.isAdmin === "Y" || this.props.accessPermissions.isSuperAdmin === "Y") ? true : false
                this.setState({ hideediticon: adminuser === true ? false : condition, hideduplicateicon: adminuser === true ? false : this.props.options.hideDuplicateIcon ? condition : false })
            }
            this.didmountinitialload();
        }
        else if (this.props.customSave) {
            this.handleSave()
        }
    }


    /**
 *********************************************************
Description: Used to convert all non-editable components to editable.refresh the page with new results
Required props:   N/A
 limitations    : N/A
*********************************************************
**/
    getlatestFormData = () => {
        const { formControls, results, showview } = this.state
        let formControlsKeys = Object.keys(formControls)
        let newformControls = {}
        formControlsKeys.forEach(each => {
            newformControls[each] = { ...formControls[each], edit: showview ? !showview : true }
        });
        this.setState({
            results: this.state.results,
            formControls: newformControls,
            changedFieldName: formControlsKeys.map(each => {
                return { ...formControls[each], edit: showview ? !showview : true, name: each }
            }),
            editbutton: true,
            isedited: showview ? !showview : true,
            showview: false,
        }, () => {
            this.fieldConfiguration(this.props.components, this.state.results, false, {})
        })
    }



    /**
   *********************************************************
   Description: Used to convert all non-editable components to editable.Get all form field
       components and change its edit property to true and push it to changedFieldName.
   Required props:   N/A
   limitations    : N/A
   *********************************************************
   **/

    handleEdit = (e, duplicate) => {
        if (this.props && this.props.options && this.props.options.IsBuildMGR === true && getEnvVariable('BuildScopeMGR') === true) {
            if (DefaultSettings.getAlert() != null)
                DefaultSettings.getAlert().show("This functionality is not in release scope, so you can't add or update. Please contact build manager", 'warning');
        }
        else {
            if (!this.state.onLoadValidate && this.props && this.props.actions && this.props.actions.Validate && this.props.actions.Validate.onLoadLayerURLRequest) {
                let e = { target: { innerText: 'Validate' } }
                this.handleIconClick(e, true);
            }
            if (duplicate) {
                this.props.unsavedData({ ...this.state, duplicate: true })
            }
            else {
                this.props.unsavedData(this.state);
            }

            const { options } = this.props
            if (options.headerProps && options.headerProps.editbutton) {
                const { formControls, showview } = this.state
                const formControlsKeys = Object.keys(formControls)
                let newformControls = {}
                formControlsKeys.forEach(each => {
                    newformControls[each] = { ...formControls[each], edit: showview ? !showview : true, editduplicate: duplicate }
                });
                this.setState({
                    editbutton: true,
                    isedited: showview ? !showview : true,
                    iseditduplicate: duplicate,
                    showview: false,
                    showsavebtn: true,
                    formControls: newformControls,
                    displayalert: false,
                    changedFieldName: formControlsKeys.map(each => {
                        return { ...formControls[each], edit: showview ? !showview : true, editduplicate: duplicate, name: each }
                    })
                }, () => {
                    this.handleDisplay();
                })
            }
        }
    }


    handleView = (results, setnewResults) => {
        const { options } = this.props
        if (results && setnewResults && this.props && this.props.match && this.props.match.params && options.viewroutesId && results[options.viewroutesId]) {
            let pathname = this.props.location.pathname
            this.props.match.params.Id = results[options.viewroutesId]
            pathname = pathname.substring(0, pathname.lastIndexOf("/"))
            pathname = pathname + '/' + this.props.match.params.Id
            this.props.onClose();
            this.props.history.push(pathname);
        }
        else {
            if (options.headerProps && options.headerProps.editbutton) {
                const { formControls, showview, editbutton } = this.state
                const formControlsKeys = Object.keys(formControls)
                let newformControls = {}
                formControlsKeys.forEach(each => {
                    newformControls[each] = { ...formControls[each], edit: showview ? !showview : true, value: each.split(".").reduce((res, prop) => { return res && res[prop] }, results) }
                });
                //options.headerProps.updateresult ? { ...formControls[each], edit: showview ? !showview : true, value: results[each] } : { ...formControls[each], edit: showview }
                this.setState({
                    editbutton: !editbutton,
                    isedited: showview ? !showview : true,
                    showview: false,
                    showsavebtn: showview ? !showview : true,
                    formControls: newformControls,
                    changedFieldName: formControlsKeys.map(each => {
                        return {
                            ...formControls[each], edit: showview ? !showview : true, name: each,
                            value: each.split(".").reduce((res, prop) => { return res && res[prop] }, results)
                        }
                    })
                    //options.headerProps.updateresult ?
                    // : formControlsKeys.map(each => {
                    //     return { ...formControls[each], edit: showview ? !showview : true, name: each }
                    // })
                }, () => {
                    this.handleDisplay();
                })
            }

        }
    }
    /**
     *
  *********************************************************
  Description: based on the result hide and show of components
  Required props:   N/A
  limitations    : N/A
  *********************************************************
  **/

    handleOnResultEdit = (each) => {
        const { formControls } = this.state;
        return { name: each.options.name, ...formControls[each.options.name], edit: false }//each.hideOnNoResult ? false : true
    }

    /**
      *********************************************************
      Description: Hide certain components based on other components value.When there is a change in value on the
          component or on initial loading check the conditions on the components. If the conditions match show the component
      else hide it.
      Required props:   N/A
      limitations    : N/A
      *********************************************************
      **/

    displayCondition = (conditions, name, results, otherval) => {
        let out = 'd-block';

        if (conditions && (conditions.accessPortal && conditions.accessPortal === this.props.accessPermissions.portal) ||
            (conditions.ModuleKey && (conditions.ModuleKey === getEnvVariable('ModuleKey')))) {
            out = 'd-none';
        }
        else if (conditions && conditions.showOnModuleKey && getEnvVariable('ModuleKey') && conditions.showOnModuleKey !== getEnvVariable('ModuleKey')) {
            out = 'd-none';
        }

        else if (conditions && conditions.hideonconfigType) {
            for (let each of conditions.hideonconfigType)
                if (each.configType === this.props.accessPermissions.configType) {
                    out = 'd-none';
                    return out;
                }
        }
        else if (conditions && conditions.hide && conditions.hide.hideonconfigType && this.props.initialloadextrainfo.results) {
            if (Array.isArray(conditions.hide)) {
                Object.keys(conditions.hide).forEach(e => {
                    const val = conditions.hide[e];
                    Object.keys(val).forEach(f => {
                        const secval = val[f]
                        if (this.props.initialloadextrainfo && this.props.initialloadextrainfo.results[f]) {
                            if (val.getdatafrom === "initiaload" && secval === this.props.initialloadextrainfo.results[f]) {
                                out = 'd-none';
                            }
                        }
                    })

                })
            }
            else {
                Object.keys(conditions.hide).forEach(e => {
                    const val = conditions.hide[e];
                    if (this.props.initialloadextrainfo.results && this.props.initialloadextrainfo.results[e]) {
                        if (conditions.hide.getdatafrom === "initiaload" && val === this.props.initialloadextrainfo.results[e]) {
                            out = 'd-none';
                        }
                    }
                })
            }
        }
        else if (results) {
            if (conditions && conditions.show) {
                Object.keys(conditions.show).forEach(e => {
                    const val = conditions.show[e]
                    if (Array.isArray(val)) {
                        if (!val.includes(results[e]))
                            out = 'd-none';
                    }
                    else {
                        if (val !== results[e]) {
                            out = 'd-none';
                        }

                    }
                })
            }
            if (conditions && conditions.hidecontains) {
                Object.keys(conditions.hidecontains).forEach(e => {
                    let val = conditions.hidecontains[e]
                    if (results && results[e] && !(results[e].includes(val))) {
                        out = 'd-none';
                    }
                })
            }

            if (conditions && conditions.hide) {
                if (conditions.hide.matchProps) {
                    const matchProps = conditions.hide.matchProps
                    if (matchProps) {
                        if (Array.isArray(matchProps)) {
                            matchProps.forEach(each => {
                                const { key, value } = each
                                if (results[key] ? results[key] === value : false) return out = 'd-none'
                            })
                        }
                        else {
                            if (matchProps.results || (matchProps && matchProps.results && matchProps.results.length > 0)) return 'd-none'
                            else return 'd-block'
                        }
                    }
                }
                else {
                    if (Array.isArray(conditions.hide)) {
                        Object.keys(conditions.hide).forEach(e => {
                            if (conditions.hide[e].nestedhidedataItem || conditions.hide[e].isLocalStorage) {
                                let dataItemKey = conditions.hide[e].nestedhidedataItem
                                let val = conditions.hide[e].value
                                if (this.props && this.props.dataItem && this.props.dataItem[dataItemKey] && this.props.dataItem[dataItemKey] === val)
                                    return out = 'd-none'
                                else {
                                    if (conditions.hide[e].isLocalStorage && localStorage.getItem(conditions.hide[e].isLocalStorage) === conditions.hide[e].value) {
                                        return out = 'd-none'
                                    }
                                }
                            }
                            else if (conditions.hide[e].nestedhide) {
                                let nested = conditions.hide[e].nestedhide
                                if (results[nested['isnested']][nested['nestedkey']] ? results[nested['isnested']][nested['nestedkey']] === nested['value'] : false) return out = 'd-none'
                            }
                            else {
                                const val = conditions.hide[e];
                                Object.keys(val).forEach(f => {
                                    const secval = val[f]
                                    if (Array.isArray(val)) {
                                        if (secval.includes(results[f]))
                                            out = 'd-none';
                                    }
                                    else {
                                        if ((_.isNull(results[f]) && _.isNull(secval))) {
                                            out = 'd-none';
                                        }
                                        else if (f === 'edit' && secval === this.state.isedited)
                                            out = 'd-none';
                                        else if (secval === results[f]) {
                                            out = 'd-none';
                                        }
                                    }
                                })
                            }
                        })
                    }
                    else {
                        Object.keys(conditions.hide).forEach(e => {
                            let val = conditions.hide[e]
                            let otherval = conditions && conditions.hideanotherval ? conditions.hideanotherval[e] : conditions.hide[e]
                            if (Array.isArray(val)) {
                                if (val.includes(results[e]))
                                    out = 'd-none';
                            }
                            else {
                                if (val === results[e]) {
                                    out = 'd-none';
                                }
                                else if (otherval === results[e]) {
                                    out = 'd-none';
                                }
                            }



                        })
                    }
                    return out;
                }
            }

            if (conditions && conditions.hideonnull) {
                if (this.props.dataItem) {
                    Object.keys(conditions.hideonnull).forEach(e => {
                        let dataItemval = this.props.dataItem[e]
                        const val = conditions.hideonnull[e]
                        if (dataItemval === val)
                            out = 'd-none';
                    })
                }
            }
            if (conditions && conditions.hideall) {
                out = 'd-none';
            }
        }

        return out
    }

    /**
        *********************************************************
        Description: Enable or disable certain components based on other components value. When there is a
            change in value on the component or on initial loading check the conditions on the components. If the conditions match enable the component
        or else disable the component.
        Required props:   N/A
        limitations    : N/A
        *********************************************************
        **/


    enableConditions = (conditions, name, results) => {
        let disabled = true;
        if (results)// Added as results is undefined on change of section
        {
            if (conditions && conditions.enable) {
                Object.keys(conditions.enable).forEach(e => {
                    const val = conditions.enable[e]
                    if (Array.isArray(val)) {
                        if (val.includes(results[e]))
                            disabled = false;
                    }
                    else {
                        if (val === results[e]) {
                            disabled = false;
                        }
                        if (val !== results[e]) {
                            disabled = true;
                        }
                    }
                })
                return disabled;
            }
        }
        return disabled
    }


    requiredConditions = (conditions, results) => {
        let isrequired = true;
        if (results) {
            const objKeys = Object.keys(conditions.required)
            objKeys.forEach(res => {
                if (conditions && conditions.required && isrequired) {
                    if (conditions.required[res] === results[res])
                        isrequired = true;
                    else { isrequired = false };
                }
            })

        }
        return isrequired
    }
    /**
        *********************************************************
        Description: Setintialvalues of the results object. When form loads we set the results object with the inital values of its field components or the values is set to empty.
        Required props:  components
        limitations    : N/A
        *********************************************************
        **/


    setInitialResults = () => {
        const { components, compmap, accessPermissions } = this.props
        let results = {}
        components.forEach(each => {
            const { options } = each
            let { name, multiplenames, initialvalue, intialvaluedata } = options;
            if (initialvalue === 'profile') {
                initialvalue = accessPermissions.name
            }
            else if (initialvalue === 'dataItem') {
                if (intialvaluedata && Array.isArray(intialvaluedata) && intialvaluedata.length > 0) {
                    let firstinitialvalue = this.props && this.props.dataItem && this.props.dataItem[intialvaluedata[0]] ? this.props.dataItem[intialvaluedata[0]] : this.props && this.props.match && this.props.match.params && this.props.match.params[intialvaluedata[1]] ? this.props.match.params[intialvaluedata[1]] : ''
                    initialvalue = _.isObject(firstinitialvalue) && firstinitialvalue[intialvaluedata[1]] ? firstinitialvalue[intialvaluedata[1]] : firstinitialvalue
                }
                else {
                    initialvalue = this.props && this.props.dataItem && this.props.dataItem[intialvaluedata] ? this.props.dataItem[intialvaluedata] : this.props && this.props.match && this.props.match.params && this.props.match.params[intialvaluedata] ? this.props.match.params[intialvaluedata] : ''

                }
            }
            else if (initialvalue === 'todaydate') {
                initialvalue = new Date()
            }
            if (name) {
                if (multiplenames) {
                    multiplenames.forEach(e => {
                        results[e] = initialvalue || ''
                    })
                }
                else {
                    const splitName = name.split(".")
                    if (splitName.length > 2) {
                        if (results[splitName[0]]) (results[splitName[0]][splitName[1]][splitName[2]] = (initialvalue || ''))
                        else results[splitName[0]] = { [splitName[1]]: { [splitName[2]]: (initialvalue || '') } }

                    }
                    else if (splitName.length > 1) {
                        if (results[splitName[0]]) (results[splitName[0]][splitName[1]] = (initialvalue || ''))
                        else results[splitName[0]] = { [splitName[1]]: (initialvalue || '') }

                    }
                    else {
                        results[name] = _.isNull(initialvalue) ? initialvalue : initialvalue ? (initialvalue === 0 ? '0' : initialvalue) : ''
                    }
                }

            }
        })
        return results || {}
    }

    loadurl = (compread) => {
        let val = this.props[compread.getdatafrom][compread.value]
        let requesturl = compread['urllist'] && compread['urllist'][val] ? compread['urllist'][val] : compread['urllist']['default']
        return requesturl.get
    }
    /**
        *********************************************************
        Description: Fetch dropdownvalues or values of the form fields and initialize corresponding fields. Read prop on
            the form field components is used to fetch individual component values. Required fields are set.
        Required props:   components, results, requiredFieldNamesInUpperCase, anotherProps. AnotherProps are extraProps  that the form components received from parent component.
        Components names are required.
        limitations    : N/A
        *********************************************************
        **/

    fieldConfiguration = (components, results, requiredFieldNamesInUpperCase, toolTipFieldNamesInUpperCase, anotherProps, compareresults, helpContext) => {

        const { actions } = this.props;
        let formControls = {};
        let changedFieldName = []
        this.requiredFields = {}
        let newcomponents = [...this.state.components]
        let subcomp = { ...this.state.subcomp }
        const { checkresults } = this.props.options;
        let count = 0;
        let compreadresult = 0
        if (this.props.errors && this.props.errfieldnames) {
            Object.keys(this.props.errfieldnames).forEach(function (key) {
                requiredFieldNamesInUpperCase[key.toUpperCase()] = true
            });
        }
        let tempFieldFormControls = []
        components.forEach(each => {
            const { options } = each
            const { name, values, conditions, transformProps, receiveValues, hideOnNoResult } = options ? options : {}
            const display = conditions ? this.displayCondition(conditions, name, results) : '';
            const disabled = (conditions && conditions.enable) ? this.enableConditions(conditions, name, results) : false
            const compcrud = options ? options.crud : null
            let compread = compcrud && !compcrud.avoidread ? compcrud.read : null
            const compget = compread ? compread.urllist ? this.loadurl(compread) : compread.get : null;
            compread = compread && compread.urllist ? { ...compread, get: compget } : compread
            //const {read,avoidread}
            const { matchProps, routeProps, customId, customProps, initialloadextrainfoProps } = compget ? compget : {}

            let ids = [], id
            if (Array.isArray(initialloadextrainfoProps) && this.props.initialloadextrainfo && this.props.initialloadextrainfo.results) {
                initialloadextrainfoProps.forEach(each => {
                    const { value } = each
                    ids.push({ key: value, value: this.props.initialloadextrainfo.results[value] })
                })
            }
            if (Array.isArray(routeProps)) {
                routeProps.forEach(each => {
                    const { value } = each
                    ids.push({ key: value, value: this.props.match.params[value] })
                })
            }


            else if (routeProps) {
                if (this.props.customId)
                    id = this.props.customId
                else id = routeProps ? this.props.match.params[routeProps.value] : null
            }
            else if (matchProps) {
                id = results[matchProps.value]
            }

            if (Array.isArray(customProps)) {
                customProps.forEach(each => {
                    const { custom } = each
                    ids.push({ key: custom, value: this.props.dataItem[custom] })
                })
            }

            let extraProps = {}
            receiveValues && receiveValues.forEach(each => {
                const { paramname, valuefromparent } = each
                extraProps[paramname] = results[valuefromparent] || anotherProps[valuefromparent]
            })


            let required = name ? (requiredFieldNamesInUpperCase ? (requiredFieldNamesInUpperCase[name.toUpperCase()] ? requiredFieldNamesInUpperCase[name.toUpperCase()] : false) : false) : false
            this.requiredFields[name] = required;
            const tooltip = name ? (toolTipFieldNamesInUpperCase ? (toolTipFieldNamesInUpperCase[name.toUpperCase()] ? toolTipFieldNamesInUpperCase[name.toUpperCase()] : '') : '') : ''
            required = (conditions && conditions.required) ? this.requiredConditions(conditions, results) : required;;
            const helpContextMsg = name ? (helpContext ? (helpContext[name.toUpperCase()] ? helpContext[name.toUpperCase()] : '') : '') : ''

            if (compread) {
                count = count + 1;
                if (compread.geom) {
                    compread = {
                        "geom": {
                            "url": { "url": results.Url, "type": "layerFieldsInfo" }
                        }
                    }
                }
                requestApi(compread, (ids.length > 0 ? ids : id), results ? results : null).then(resp => {
                    compreadresult = compreadresult + 1;
                    // for individual form fields if needs to fetch data
                    if (name) {
                        const val = name.split(".").reduce((res, prop) => { return res && res[prop] }, results)
                        const value = transformProps ? (transformProps[val] ? transformProps[val] : val) : val
                        //const otherchangedFieldNames = this.handleEffects(name, value)
                        const otherchangedFieldNames = []
                        formControls[name] = {
                            ...this.state.formControls[name],
                            value: value, //== null ? (resp.find (each=>each.isdefaut === y)).value : value,  // check value  is null ? (resp.find (each=>each.isdefaut === y)).value ||value
                            required: required,
                            tooltip: tooltip,
                            contexthelp: helpContextMsg,
                            display: display,
                            disabled: disabled,
                            dropdownvalues: compcrud.subname ? resp[compcrud.subname] : resp || null,
                            extraProps: { ...extraProps }
                        }
                        tempFieldFormControls.push({ name: name, ...formControls[name] })
                        if (count === compreadresult)
                            changedFieldName.push(...tempFieldFormControls)

                        this.setState({
                            formControls: { ...this.state.formControls, ...formControls },
                            //required: required,
                            compareresults: { ...this.state.compareresults, [name]: value, ...compareresults },
                            changedFieldName: changedFieldName.concat(otherchangedFieldNames),
                            results: { ...this.state.results, [name]: value, ...results },
                        }, () => {
                            //  this.handleDisplay()
                            if (actions && actions[name] && results && results[name])
                                this.handleDataLoad(name, results[name], null, results)
                        })
                    }
                })

            }
            else {
                if (name) {
                    //const otherchangedFieldNames = this.handleEffects(name, value)

                    const otherchangedFieldNames = []
                    const val = name.split(".").reduce((res, prop) => { return res && res[prop] }, results)
                    const value = transformProps ? (transformProps[val] ? transformProps[val] : val) : val
                    formControls[name] = {
                        ...this.state.formControls[name],
                        value: value,
                        required: required,
                        tooltip: tooltip,
                        contexthelp: helpContextMsg,
                        display: display,
                        disabled: disabled,
                        dropdownvalues: values || null,
                        extraProps: { ...extraProps }
                    }
                    changedFieldName = changedFieldName.concat(otherchangedFieldNames)
                    if (checkresults && Object.keys(results) === 0) {
                        formControls[name].edit = each.options.hideOnNoResult ? false : true;
                        changedFieldName.push({ name: name, ...formControls[name] })
                        if (actions && actions[name] && results && results[name])
                            this.handleDataLoad(name, value)
                    } else {
                        changedFieldName.push({ name: name, ...formControls[name] })
                        if (actions && actions[name] && results && results[name])
                            this.handleDataLoad(name, results[name], null, results)

                    }

                }
            }
        })
        if (count === 0) {

        }
        this.setState({
            results: { ...this.state.results, ...this.initialresults }, // { ...this.state.results, ...results},
            compareresults: { ...compareresults },
            formControls: { ...this.state.formControls, ...formControls },
            changedFieldName: changedFieldName,
            //errfieldnames: requiredFields,
            components: newcomponents,
            subcomp: subcomp
        }, () => {
            this.handleDisplay()
        })

    }

    /**
        *********************************************************
        Description: Fetch validations for the form fields.Validation prop on the form is used to fetch the validations. Names on the validations results are mapped to the corresponding names of the fields
        for storing the field validations. Required fields are also stored.
        Required props:    options, components.
        limitations    : N/A
        *********************************************************
        **/

    handleResultValues = () => {
        const { results } = this.props;
        const { formControls } = this.state;
        let tempformControls = {}
        let tempchangedFieldName = []
        let resultskeys = Object.keys(results)
        resultskeys.forEach(each => {
            if (formControls[each]) {
                tempformControls[each] = { ...formControls[each], value: results[each] }
                tempchangedFieldName.push({ name: each, value: results[each] })
            }
        })
        this.setState({
            results: this.props.results,
            formControls: tempformControls,
            changedFieldName: tempchangedFieldName
        })
    }
    componentDidMount() {
        if (this.props.options.hideEditIcon && (this.props.options.hidecondition || this.props.options.isexternal)) {
            let condition = null;
            condition = conditionsHandle(this.props, this.props.initialloadextrainfo && this.props.initialloadextrainfo.results ? this.props.initialloadextrainfo.results : this.props.initialloadextrainfo)
            condition = condition ? condition.condition : false
            let adminuser = (this.props.accessPermissions.isAdmin === "Y" || this.props.accessPermissions.isSuperAdmin === "Y") ? true : false
            this.setState({ hideediticon: adminuser === true ? false : this.props.options.isexternal ? true : condition, hideduplicateicon: adminuser === true ? false : this.props.options.hideDuplicateIcon ? condition : false })
        }
        this.didmountinitialload();
    }

    loopobj(resultdata) {
        let resultdataval = { ...resultdata }
        for (const property in resultdataval) {
            if (!Array.isArray(resultdataval[property]) && _.isObject(resultdataval[property])) {
                resultdataval = { ...resultdataval, [property]: { ...resultdataval[property], IsDuplicate: "Y" } }
                for (const propertyval in resultdataval[property]) {
                    if (!Array.isArray(resultdataval[property][propertyval]) && (_.isObject(resultdataval[property][propertyval]))) {
                        resultdataval = { ...resultdataval, [property]: { ...resultdataval[property], [propertyval]: { ...resultdataval[property][propertyval], IsDuplicate: "Y" } } }
                    }
                    // else if(Array.isArray(resultdataval[property][propertyval]) &&(_.isObject(resultdataval[property][propertyval])))
                    // {
                    //     let arr = [...resultdataval[property][propertyval]]
                    //     let arrobj = resultdataval[property][propertyval].length>0 ? resultdataval[property][propertyval][0] : resultdataval[property][propertyval]
                    //     if(_.isObject(arrobj)) {
                    //         arrobj['IsDuplicate'] = 'Y'
                    //         arr = [arrobj]
                    //     }
                    //     resultdataval =  {...resultdataval, [property] : {...resultdataval[property], [propertyval] : [...arr]}}
                    // }
                }
            }
        }
        return resultdataval
    }

    didmountinitialload() {
        const { type, options, components, extraProps, dataItem } = this.props
        const { crud, validations } = options ? options : {}
        const { read } = crud ? crud : {}
        const { get } = read ? read : {}
        const { routeProps, customProps, hidepath, passingProps } = get ? get : {}
        let ids = [], id
        if (hidepath) {
            id = this.props.accessPermissions.userId;
        }
        else {
            if (Array.isArray(routeProps)) {
                routeProps.forEach(each => {
                    const { value, custom } = each;
                    if (custom) {
                        ids.push({ key: custom, value: dataItem[get.customProps.value] || dataItem["Id"] })
                    }
                    else ids.push({ key: value, value: this.props.match.params[value] })
                })
            }
            if (Array.isArray(customProps)) {
                customProps.forEach(each => {
                    const { value, custom } = each;
                    if (custom) {
                        ids.push({ key: custom, value: dataItem[custom] })
                    }
                    else ids.push({ key: value, value: this.props.match.params[value] })
                })
            }
            else if (routeProps) {
                id = routeProps ? this.props.match.params[routeProps.value] : null
            }
            else if (customProps) {
                id = customProps ? dataItem[get.customProps.value] || dataItem.Id : null
            }
            else if (passingProps) {
                id = passingProps ? this.props.passingProps : null
            }
        }
        const self = this
        let aryofvalidations = []
        if (!Array.isArray(validations)) {
            aryofvalidations.push(validations)
        }
        else aryofvalidations = validations
        if (read && validations) {
            this.props.unsavedData(null);
            requestApi(read, (ids.length > 0 ? ids : id)).then(results => {
                if (results && Object.keys(results).length > 0)
                    this.initialresults = results;
                else this.initialresults = this.state.results;
                if (this.props.options.checkresultval && !results.FieldKey) {
                    this.handleEdit();
                }
                Promise.all(aryofvalidations.map(each => requestApi(each)))
                    .then(function (values) {
                        let formControls = {};
                        let changedFieldName = []
                        let xvalidations = {}, requiredFieldNamesInUpperCase = {}, toolTipFieldNamesInUpperCase = {}, helpContext = {}
                        validations && values.forEach((each, index) => {
                            const { transformProps } = aryofvalidations[index] ? aryofvalidations[index] : {}
                            const { subfieldname, addsubfieldname, replaceTable } = transformProps ? transformProps : {}
                            const subfieldnamelength = subfieldname ? subfieldname.length : 0
                            each && each.length > 0 && each.forEach(each => {
                                let validationname = replaceTable ? each[transformProps.name].replace(replaceTable.current, replaceTable.replace).slice(subfieldnamelength)
                                    : each[transformProps.name].slice(subfieldnamelength)

                                if (addsubfieldname) validationname = addsubfieldname + validationname
                                if (each.Type === 'R') requiredFieldNamesInUpperCase[validationname] = true
                                if (each.Type === 'T') toolTipFieldNamesInUpperCase[validationname] = each.Message || ''
                                if (each.Type === 'H') helpContext[validationname] = each.Message || ''
                                if (Array.isArray(xvalidations[validationname])) {
                                    const len = xvalidations[validationname].length
                                    const lastvalidation = xvalidations[validationname][len - 1]
                                    if (each.Order > lastvalidation.Order) xvalidations[validationname].push(each)
                                    else xvalidations[validationname].unshift(each)
                                }
                                else {
                                    xvalidations[validationname] = [each]
                                }
                            })
                            self.validations = xvalidations
                            self.requiredFieldNamesInUpperCase = requiredFieldNamesInUpperCase
                            self.toolTipFieldNamesInUpperCase = toolTipFieldNamesInUpperCase
                            self.helpContext = helpContext


                        })
                        self.fieldConfiguration(components, results, requiredFieldNamesInUpperCase, toolTipFieldNamesInUpperCase, extraProps, results, helpContext)
                    })
                    .catch(err => {
                        console.log(err)

                    })
            })
        }
        if (read && !validations) {
            this.props.unsavedData(null);
            requestApi(read, (ids.length > 0 ? ids : id)).then(results => {
                this.initialresults = results;
                Promise.all(aryofvalidations.map(each => requestApi(each)))
                    .then(function (values) {
                        let formControls = {};
                        let changedFieldName = []
                        let xvalidations = {}, requiredFieldNamesInUpperCase = {}, toolTipFieldNamesInUpperCase = {}, helpContext = {}
                        validations && values.forEach((each, index) => {
                            const { transformProps } = aryofvalidations[index] ? aryofvalidations[index] : {}
                            const { subfieldname, addsubfieldname, replaceTable } = transformProps ? transformProps : {}
                            const subfieldnamelength = subfieldname ? subfieldname.length : 0
                            each && each.forEach(each => {
                                let validationname = replaceTable ? each[transformProps.name].replace(replaceTable.current, replaceTable.replace).slice(subfieldnamelength)
                                    : each[transformProps.name].slice(subfieldnamelength)
                                if (addsubfieldname) validationname = addsubfieldname + validationname
                                if (each.Type === 'R') requiredFieldNamesInUpperCase[validationname] = true
                                if (each.Type === 'T') toolTipFieldNamesInUpperCase[validationname] = each.Message || ''
                                if (each.Type === 'H') helpContext[validationname] = each.Message || ''

                                if (Array.isArray(xvalidations[validationname])) {
                                    const len = xvalidations[validationname].length
                                    const lastvalidation = xvalidations[validationname][len - 1]
                                    if (each.Order > lastvalidation.Order) xvalidations[validationname].push(each)
                                    else xvalidations[validationname].unshift(each)
                                }
                                else {
                                    xvalidations[validationname] = [each]
                                }
                            })
                            self.validations = xvalidations
                            self.requiredFieldNamesInUpperCase = requiredFieldNamesInUpperCase
                            self.toolTipFieldNamesInUpperCase = toolTipFieldNamesInUpperCase
                            self.helpContext = helpContext



                        })
                        self.fieldConfiguration(components, results, requiredFieldNamesInUpperCase, toolTipFieldNamesInUpperCase, extraProps, {}, helpContext)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            })
        }

        if (!read && validations) {
            this.props.unsavedData(null);
            let requiredFieldNamesInUpperCase = {}
            let toolTipFieldNamesInUpperCase = {}
            let helpContext = {}
            aryofvalidations && Promise.all(aryofvalidations.map(each => requestApi(each)))
                .then(function (values) {
                    //
                    let formControls = {};
                    let changedFieldName = []
                    let xvalidations = {}
                    validations && values.forEach((each, index) => {
                        const { transformProps } = aryofvalidations[index] ? aryofvalidations[index] : {}
                        const { subfieldname, addsubfieldname, replaceTable } = transformProps ? transformProps : {}
                        const subfieldnamelength = subfieldname ? subfieldname.length : 0
                        each && each.forEach(each => {
                            let validationname = replaceTable ? each[transformProps.name].replace(replaceTable.current, replaceTable.replace).slice(subfieldnamelength)
                                : each[transformProps.name].slice(subfieldnamelength)
                            if (addsubfieldname) validationname = addsubfieldname + validationname
                            if (each.Type === 'R') requiredFieldNamesInUpperCase[validationname] = true
                            if (each.Type === 'T') toolTipFieldNamesInUpperCase[validationname] = each.Message || ''
                            if (each.Type === 'H') helpContext[validationname] = each.Message || ''
                            if (Array.isArray(xvalidations[validationname])) {
                                const len = xvalidations[validationname].length
                                const lastvalidation = xvalidations[validationname][len - 1]
                                if (each.Order > lastvalidation.Order) xvalidations[validationname].push(each)
                                else xvalidations[validationname].unshift(each)
                            }
                            else {
                                xvalidations[validationname] = [each]
                            }
                        })
                        self.validations = xvalidations
                        self.requiredFieldNamesInUpperCase = requiredFieldNamesInUpperCase
                        self.toolTipFieldNamesInUpperCase = toolTipFieldNamesInUpperCase
                        self.helpContext = helpContext
                    })
                    self.fieldConfiguration(components, self.state.results, requiredFieldNamesInUpperCase, toolTipFieldNamesInUpperCase, extraProps, self.state.results, helpContext)
                })
                .catch(err => {
                    console.log(err)
                })

        }
        if (!read && !validations) {
            //
            this.props.unsavedData(null);
            let formControls = {};
            let changedFieldName = []
            let xvalidations = {}, requiredFieldNamesInUpperCase = {}, toolTipFieldNamesInUpperCase = {}, helpContext = {}
            validations && validations.forEach((each, index) => {
                const { transformProps } = aryofvalidations[index] ? aryofvalidations[index] : {}
                const { subfieldname, addsubfieldname, replaceTable } = transformProps ? transformProps : {}
                const subfieldnamelength = subfieldname ? subfieldname.length : 0
                each && each.forEach(each => {
                    let validationname = replaceTable ? each[transformProps.name].replace(replaceTable.current, replaceTable.replace).slice(subfieldnamelength)
                        : each[transformProps.name].slice(subfieldnamelength)
                    if (addsubfieldname) validationname = addsubfieldname + validationname
                    if (each.Type === 'R') requiredFieldNamesInUpperCase[validationname] = true
                    if (each.Type === 'T') toolTipFieldNamesInUpperCase[validationname] = each.Message || ''
                    if (each.Type === 'H') helpContext[validationname] = each.Message || ''
                    if (Array.isArray(xvalidations[validationname])) {
                        const len = xvalidations[validationname].length
                        const lastvalidation = xvalidations[validationname][len - 1]
                        if (each.Order > lastvalidation.Order) xvalidations[validationname].push(each)
                        else xvalidations[validationname].unshift(each)
                    }
                    else {
                        xvalidations[validationname] = [each]
                    }
                })
                self.validations = xvalidations
                self.requiredFieldNamesInUpperCase = requiredFieldNamesInUpperCase
                self.toolTipFieldNamesInUpperCase = toolTipFieldNamesInUpperCase
                self.helpContext = helpContext
            })
            self.fieldConfiguration(components, self.state.results, requiredFieldNamesInUpperCase, toolTipFieldNamesInUpperCase, extraProps, {}, helpContext)

        }

    }
    /**
        *********************************************************
        Description: Insert values in the results where properties are nested. Split the name in json at '.' , go through the array with the names split at '.' and insert the value.
        Required props:   objProperty, len, splitName, value.
            objProperty: results
            len: length of the splitname array.
            splitname: Array contains the names of the form field
            value: value to be stored
        limitations    : N/A
        *********************************************************
        **/

    changeResults = (objProperty, len, splitName, value) => {
        if (len === 0) return value
        else {
            const index = splitName.length - len
            const name = splitName[index]
            if (name) return { ...objProperty, [name]: this.changeResults(objProperty[name], --len, splitName, value) }
        }
    }
    /**
            *********************************************************
            Description: Handles value for form fields to save in the results object. Checks the type of the components, transforms the value and return it.
            Required props:   value, checked, name
            limitations    : N/A
            *********************************************************
            **/
    handleValue = (value, checked, name) => {
        const { transformProps, multiple, type } = this.formControlsConfigs[name] ? this.formControlsConfigs[name] : {}
        switch (type) {
            case 'formcheckbox':
                return transformProps ? (transformProps[checked] ? transformProps[checked] : checked) : checked
            case 'formdropdown':
                return multiple && Array.isArray(value) ? value.join(',') : value
            case 'date':
                return moment(value).format('MM/DD/YYYY')
            case 'datetime-local':
                return moment(value).format('MM/DD/YYYY hh:mm:ss A')
            case 'formswitch':
                return transformProps ? (transformProps[checked] ? transformProps[checked] : checked) : checked
            default:
                return transformProps ? (transformProps[value] ? transformProps[value] : value) : value
        }
    }

    /**
        *********************************************************
        Description: Handles value and the errors for form fields. Once the value of the field is changed by user, validates the values against the validations. If there are errors, sets the errors and the value or
        else the value is set. Also, handles components display (hide or show, enable or disable).
        Required props:   event
        limitations    : N/A
        *********************************************************
        **/

    handleFieldChange = (event) => {
        const { actions } = this.props;
        //
        const { components, formControls, results, customvalue } = this.state
        const { name, checked, type } = event.target
        let value = this.handleValue(event.target.value, checked, name)
        const otherchangedFieldNames = []
        const splitName = name.split(".")
        const newResults = splitName.length > 1 ? this.changeResults(this.state.results, splitName.length, splitName, value) : null
        const nameinuppercase = name.toUpperCase()
        const { errfieldnames } = this.state
        if (customvalue && Object.keys(customvalue).length > 0 && !(results && results[customvalue.name] === "")) {
            let e = {
                target: {
                    name: event.target.name,
                    value: event.target.value
                }
            }
            this.setState({
                results: ({ ...this.state.results, [customvalue.name]: customvalue.result })
            }, () => this.handleFieldChangeData(e))
        }
        else {
            let e = {
                target: {
                    name: event.target.name,
                    value: event.target.value,
                    checked: event.target.checked,
                    type: event.target.type
                }
            }
            this.handleFieldChangeData(e)
        }

    }


    handleFieldChangeData = (event) => {
        const { actions } = this.props;
        //
        const { components, formControls, results, customvalue } = this.state
        const { name, checked, type } = event.target
        let value = this.handleValue(event.target.value, checked, name)
        const otherchangedFieldNames = []
        const splitName = name.split(".")
        const newResults = splitName.length > 1 ? this.changeResults(this.state.results, splitName.length, splitName, value) : null
        const nameinuppercase = name.toUpperCase()
        const { errfieldnames } = this.state
        if (this.validations && this.validations[nameinuppercase]) {//
            const err = globalvalidate({ [name]: this.validations[nameinuppercase] }, { [name]: value })
            let error, helperText
            if (err && err[name]) {
                error = err[name].error
                helperText = err[name].errortext
            }
            let setotherfield = this.props && this.props.components ? _.find(this.props.components, { 'key': name }) : null
            let otherfield = setotherfield ? setotherfield.options : null
            let removefields = setotherfield && setotherfield.options && setotherfield.options.removeotherfield ? setotherfield.options.removeotherfield : null
            let removefielddependency = setotherfield && setotherfield.options && setotherfield.options.removeotherfielddependency ? setotherfield.options.removeotherfielddependency : null
            let fielddependencyname = value === "" ? (setotherfield && setotherfield.options && setotherfield.options.fielddependencyname ? setotherfield.options.fielddependencyname : null) : null
            if (this.formControlsConfigs[name].hasconditionaldropdownfield) {
                let hasconditionaldropdownfield = this.formControlsConfigs[name].hasconditionaldropdownfield
                let hasconditionaldropdownvalues = this.formControlsConfigs[name].hasconditionaldropdownvalues[value]
                let valuesdata = this.props.options.conditionaldropdownvalues[hasconditionaldropdownfield][hasconditionaldropdownvalues]
                this.formControlsConfigs = { ...this.formControlsConfigs, [hasconditionaldropdownfield]: { ...this.formControlsConfigs[hasconditionaldropdownfield], values: valuesdata } }
                let changedFieldNames = otherchangedFieldNames.map(e => {
                    const { name, display, disabled, error, helperText, required } = e
                    if (e.key === [hasconditionaldropdownfield]) {
                        return { ...this.state.formControls[hasconditionaldropdownfield], dropdownvalues: valuesdata }
                    }
                    return e
                })
                this.setState({
                    results: (newResults || { ...this.state.results, [name]: value }),
                    formControls: { ...this.state.formControls, [name]: { ...this.state.formControls[name], value: value, error: error, helperText: helperText }, [hasconditionaldropdownfield]: { ...this.state.formControls[hasconditionaldropdownfield], dropdownvalues: valuesdata } },
                    components: this.state.components.map(e => {
                        if (e.key === [hasconditionaldropdownfield]) {
                            return { ...e, options: { ...e.options, values: valuesdata } }
                        }
                        return e
                    }),
                    changedFieldName: [{ name: name, value: value, error: error, helperText: helperText, dropdownvalues: this.state.formControls[name].dropdownvalues }].concat(changedFieldNames),
                    errors: error,
                    isfieldchanged: true,
                    errfieldnames: { ...this.state.errfieldnames, [name]: { filled: true, error: error } }
                }
                    , () => {
                        this.handleSetValue(removefielddependency, fielddependencyname, newResults, name, value, removefields, error, helperText, otherchangedFieldNames, 0)
                    }
                )

            }
            else if (removefields && removefields.length > 0) {
                this.handleSetValue(removefielddependency, fielddependencyname, newResults, name, value, removefields, error, helperText, otherchangedFieldNames, 0)

            }
            else {
                this.setState({
                    results: (newResults || { ...this.state.results, [name]: value }),
                    formControls: { ...this.state.formControls, [name]: { ...this.state.formControls[name], value: value, error: error, helperText: helperText } },
                    changedFieldName: [{ name: name, value: value, error: error, helperText: helperText, dropdownvalues: this.state.formControls[name].dropdownvalues }].concat(otherchangedFieldNames),
                    errors: error,
                    isfieldchanged: true,
                    errfieldnames: { ...this.state.errfieldnames, [name]: { filled: true, error: error } }
                }
                    , () => {
                        this.props.unsavedData(this.state);
                        this.handleDisplay();
                        if (actions && actions[name])
                            this.handleDataLoad(name, value);
                    }
                )
            }

        }
        else {
            this.setState({
                results: (newResults || { ...this.state.results, [name]: value }),
                formControls: { ...this.state.formControls, [name]: { ...this.state.formControls[name], value: value } },
                changedFieldName: [{ name: name, value: value, dropdownvalues: (this.state.formControls[name] && this.state.formControls[name].dropdownvalues) || [] }].concat(otherchangedFieldNames),
                errors: false,
                isfieldchanged: true,
            }, () => {
                this.props.unsavedData(this.state);
                this.handleDisplay();
                if (actions && actions[name])
                    this.handleDataLoad(name, value);
            })
        }
    }

    handleSetValue = (removefielddependency, fielddependencyname, newResults, name, value, removefields, error, helperText, otherchangedFieldNames, count) => {
        const { actions } = this.props;
        if (count < removefields.length) {
            let { removefield, changedvalue } = removefields[count]
            this.setState({
                ...this.state,
                results: removefielddependency ? (fielddependencyname ? newResults || { ...this.state.results, [name]: value, [removefield]: _.isNull(changedvalue) ? changedvalue : changedvalue ? changedvalue : '', [removefielddependency]: '', [fielddependencyname]: '' } : newResults || { ...this.state.results, [name]: value, [removefield]: _.isNull(changedvalue) ? changedvalue : changedvalue ? changedvalue : '', [removefielddependency]: '' }) :
                    (newResults || { ...this.state.results, [name]: value, [removefield]: _.isNull(changedvalue) ? changedvalue : changedvalue ? changedvalue : '' }),
                formControls: removefielddependency ? fielddependencyname ?
                    {
                        ...this.state.formControls, [name]: { ...this.state.formControls[name], value: value, error: error, helperText: helperText }, [removefield]: { ...this.state.formControls[removefield], value: _.isNull(changedvalue) ? changedvalue : changedvalue ? changedvalue : '', error: error, helperText: helperText }, [removefielddependency]: { ...this.state.formControls[removefielddependency], value: '', error: error, helperText: helperText }, [fielddependencyname]: { ...this.state.formControls[fielddependencyname], value: '', error: error, helperText: helperText }
                    } : {
                        ...this.state.formControls, [name]: { ...this.state.formControls[name], value: value, error: error, helperText: helperText }, [removefield]: { ...this.state.formControls[removefield], value: _.isNull(changedvalue) ? changedvalue : changedvalue ? changedvalue : '', error: error, helperText: helperText }, [removefielddependency]: { ...this.state.formControls[removefielddependency], value: '', error: error, helperText: helperText }
                    } :
                    {
                        ...this.state.formControls, [name]: { ...this.state.formControls[name], value: value, error: error, helperText: helperText }, [removefield]: { ...this.state.formControls[removefield], value: _.isNull(changedvalue) ? changedvalue : changedvalue ? changedvalue : '', error: error, helperText: helperText }
                    },
                changedFieldName: removefielddependency ? fielddependencyname ?
                    [{ name: name, value: value, error: error, helperText: helperText, dropdownvalues: this.state.formControls[name].dropdownvalues }, { name: removefield, value: _.isNull(changedvalue) ? changedvalue : changedvalue ? changedvalue : '', error: error, helperText: helperText, dropdownvalues: this.state.formControls[removefield].dropdownvalues }, { name: removefielddependency, value: '', error: error, helperText: helperText, dropdownvalues: this.state.formControls[removefielddependency].dropdownvalues }, { name: fielddependencyname, value: '', error: error, helperText: helperText, dropdownvalues: this.state.formControls[fielddependencyname].dropdownvalues }].concat(otherchangedFieldNames)
                    :
                    [{ name: name, value: value, error: error, helperText: helperText, dropdownvalues: this.state.formControls[name].dropdownvalues }, { name: removefield, value: _.isNull(changedvalue) ? changedvalue : changedvalue ? changedvalue : '', error: error, helperText: helperText, dropdownvalues: this.state.formControls[removefield].dropdownvalues }, { name: removefielddependency, value: '', error: error, helperText: helperText, dropdownvalues: this.state.formControls[removefielddependency].dropdownvalues }].concat(otherchangedFieldNames)
                    :
                    [{ name: name, value: value, error: error, helperText: helperText, dropdownvalues: this.state.formControls[name].dropdownvalues }, { name: removefield, value: _.isNull(changedvalue) ? changedvalue : changedvalue ? changedvalue : '', error: error, helperText: helperText, dropdownvalues: this.state.formControls[removefield].dropdownvalues }].concat(otherchangedFieldNames),
                errors: error,
                isfieldchanged: true,
                errfieldnames: removefielddependency ?
                    { ...this.state.errfieldnames, [name]: { filled: true, error: error }, [removefielddependency]: { filled: true, error: false }, [removefielddependency]: { filled: true, error: false } } :
                    { ...this.state.errfieldnames, [name]: { filled: true, error: error } },
            }, () => {
                this.props.unsavedData(this.state);
                this.handleDisplay();
                if (actions && actions[name])
                    this.handleDataLoad(name, value);
                this.handleSetValue(removefielddependency, fielddependencyname, newResults, name, value, removefields, error, helperText, otherchangedFieldNames, count + 1)
            })
        }

    }

    handleSlide = () => {
        const { results } = this.state;
        const { actions } = this.props
        if (actions && actions.slide)
            this.handleDataLoad(actions.slide, results[actions.slide], true);
    }

    handleIconClick = (event, isEdit = false) => {
        const { actions } = this.props;
        const { name, checked, type, innerText } = event.target;
        this.handleDataLoad(name ? name : innerText, null, null, null, isEdit);
    }

    handleDataLoad = (label, value, slide, viewresults, isEdit) => {
        const { actions } = this.props;
        const { name, read, getread } = actions[label];
        if (actions && actions[label] && actions[label].type === 'load' && !slide) {
            const { get } = read
            const { url, routeProps, matchProps } = get;
            const { results } = this.state;
            let ids = [], id
            if (Array.isArray(routeProps)) {
                routeProps.forEach(each => {
                    const { value } = each
                    ids.push({ key: value, value: this.props.match.params[value] })
                })
            }
            else if (routeProps) id = routeProps ? this.props.match.params[routeProps.value] : null
            else if (matchProps) {
                id = results[matchProps.value]
            }
            requestApi(read, (id || ids), results).then(response => {
                if (Array.isArray(name)) {
                    name.forEach(e => {
                        const { getvalue, setvalue } = e
                        this.setState({
                            results: ({ ...this.state.results, [setvalue]: response[getvalue] }),
                            formControls: { ...this.state.formControls, [setvalue]: { ...this.state.formControls[setvalue], value: response[getvalue] } },
                            changedFieldName: [{ name: setvalue, value: response[getvalue] }],
                            errors: false,
                            isfieldchanged: true,
                        })
                    })
                }
                else {
                    actions && actions[label] && requestApi(read, value).then(response => {
                        this.setState({
                            results: ({ ...this.state.results, [name]: '' }),
                            formControls: { ...this.state.formControls, [name]: { ...this.state.formControls[name], value: '', dropdownvalues: response } },
                            changedFieldName: [{ name: name, value: '', dropdownvalues: response }],
                            errors: false,
                            isfieldchanged: true,
                        })
                    })
                }
            }).catch(ex => {
                console.error(ex)
            })
        }
        else if (actions && actions[label] && actions[label].type === 'value' && !slide) {
            const { name, type } = actions[label]
            if (viewresults)
                this.setState({
                    results: ({ ...this.state.results, [name]: viewresults[name] }),
                    formControls: {
                        ...this.state.formControls, [name]: {
                            ...this.state.formControls[name],
                            dropdownvalues: viewresults[label], value: viewresults[name], dataoptions: viewresults
                        }
                    },
                    changedFieldName: [{
                        name: name, dropdownvalues: viewresults[label], value: viewresults[name],
                        dataoptions: viewresults
                    }],
                    errors: false,
                    isfieldchanged: true,
                })
            else this.setState({
                results: ({ ...this.state.results, [name]: this.state.results[name] }),
                formControls: { ...this.state.formControls, [name]: { ...this.state.formControls[name], dropdownvalues: value, value: this.state.results[name], dataoptions: this.state.results } },
                changedFieldName: [{ name: name, dropdownvalues: value, value: this.state.results[name], dataoptions: this.state.results }],
                errors: false,
                isfieldchanged: true,
            })
        }

        else if (actions && actions[label] && actions[label].type === 'GISRequest' && !slide) {
            const { name, type, from, initialLoad } = actions[label]
            let url = ''
            let gisdata = null
            //   let callRequest = this.state.editbutton ? initialLoad : false
            let GisDropdown = this.state.formControls[label].dropdownvalues || [];
            if (GisDropdown && GisDropdown.length > 0) {
                gisdata = GisDropdown.find(gisdd => gisdd['Id'] === value)//.Url
                url = gisdata && gisdata.Url || ''
            }
            let gissurl = {
                "geom": {
                    "url": { "url": url, "type": "Info" }
                }
            }
            actions && actions[label] && requestApi(gissurl).then(response => {
                if (response)
                    if (Array.isArray(name)) {
                        let tempChangedField = this.state.changedFieldName;
                        let tempformControls = this.state.formControls
                        let tempResults = this.state.results
                        name.forEach(e => {
                            const { key, subkey } = e
                            tempChangedField = tempChangedField.filter(each => each.name !== key)
                            tempChangedField.push({ name: key, dropdownvalues: (key === subkey) ? response : viewresults ? viewresults[subkey] : this.state.results[subkey], value: viewresults ? viewresults[key] : this.state.results[key] })
                            tempformControls = { ...tempformControls, [key]: { ...tempformControls[key], dropdownvalues: (key === subkey) ? response : viewresults ? viewresults[subkey] : this.state.results[subkey], value: viewresults ? viewresults[key] : this.state.results[key] } }
                            tempResults = ({ ...tempResults, [key]: viewresults ? viewresults[key] : tempResults[subkey] })
                        })
                        this.setState({
                            results: tempResults,
                            formControls: tempformControls,
                            changedFieldName: tempChangedField,
                            errors: false,
                            isfieldchanged: true,
                        })
                    }
                // else this.setState({
                //     results: ({ ...this.state.results, [from]: viewresults ? viewresults[from] : this.state.results[from] }),
                //     formControls: { ...this.state.formControls, [from]: { ...this.state.formControls[from], value: viewresults ? viewresults[from] : this.state.results[from] } },
                //     changedFieldName: [{ name: from, dropdownvalues: [], value: viewresults ? viewresults[from] : this.state.results[from], error: true, helperText: "Invalid Service Request URL" }],
                //     errors: false,
                //     isfieldchanged: true,
                // })
            }).catch(ex => {
                this.setState({
                    results: ({ ...this.state.results, [from]: viewresults ? viewresults[from] : this.state.results[from] }),
                    formControls: { ...this.state.formControls, [from]: { ...this.state.formControls[from], value: viewresults ? viewresults[from] : this.state.results[from] } },
                    changedFieldName: [{ name: from, value: viewresults ? viewresults[from] : this.state.results[from], error: true, helperText: "Invalid Service Request URL" }],
                    errors: false,
                    isfieldchanged: true,
                })
            })
        }

        else if (actions && actions[label] && actions[label].type === 'LayerURLRequest' && !slide) {
            const { name, type, from, initialLoad } = actions[label]
            let gisdata = null
            let gissurl = {
                "geom": {
                    "url": { "url": this.state.results.Url, "type": "layerinfo" }
                }
            }
            actions && actions[label] && requestApi(gissurl).then(response => {
                if (response) {
                    let tempResults = this.state.results;
                    let tempformControls = this.state.formControls;
                    let tempchangedFieldName = []
                    if (Array.isArray(name)) {
                        name.forEach(e => {
                            const { key, subkey, dropdown } = e
                            tempResults = { ...tempResults, [key]: (subkey && !isEdit) ? response[subkey] : tempResults[key] }
                            tempformControls = {
                                ...tempformControls, [key]: {
                                    ...tempformControls[key], value: (subkey && !isEdit) ? response[subkey] :
                                        tempResults[key], dropdownvalues: dropdown ? response[key] : tempformControls[key].dropdownvalues
                                }
                            }
                            tempchangedFieldName = [{
                                name: key, value: !isEdit ? response[subkey] :
                                    tempResults[subkey], dropdownvalues: dropdown ? response[key] : tempformControls[key].dropdownvalues
                            }]
                        })
                        this.setState({
                            results: tempResults,
                            formControls: tempformControls,
                            changedFieldName: tempchangedFieldName,
                            errors: false,
                            isfieldchanged: true,
                        }, () => {
                            this.handleDisplay();
                        })
                    }
                }
            }).catch(ex => {
                this.setState({
                    results: ({ ...this.state.results, [from]: viewresults ? viewresults[from] : this.state.results[from] }),
                    formControls: { ...this.state.formControls, [from]: { ...this.state.formControls[from], value: viewresults ? viewresults[from] : this.state.results[from] } },
                    changedFieldName: [{ name: from, value: viewresults ? viewresults[from] : this.state.results[from], error: true, helperText: "Invalid Service Request URL" }],
                    errors: false,
                    isfieldchanged: true,
                })
            })
        }
        else if (actions && actions[label] && actions[label].type === 'LoaddropdownMultipleRequest' && !slide) {
            const { urllist, type, from, initialLoad } = actions[label]
            let requesturl = '';
            let configType = this.props.accessPermissions.configType
            requesturl = urllist[configType]
            actions && actions[label] && requestApi(requesturl, null, this.state.results).then(response => {
                if (response)
                    if (Array.isArray(name)) {
                        name.forEach(e => {
                            this.setState({
                                results: ({ ...this.state.results, [label]: '' }),
                                formControls: { ...this.state.formControls, [label]: { ...this.state.formControls[label], value: '', dropdownvalues: response } },
                                changedFieldName: [{ name: label, value: '', dropdownvalues: response }],
                                errors: false,
                                isfieldchanged: true,
                            })
                        })
                    }
                // else this.setState({
                //     results: ({ ...this.state.results, [from]: viewresults ? viewresults[from] : this.state.results[from] }),
                //     formControls: { ...this.state.formControls, [from]: { ...this.state.formControls[from], value: viewresults ? viewresults[from] : this.state.results[from] } },
                //     changedFieldName: [{ name: from, dropdownvalues: [], value: viewresults ? viewresults[from] : this.state.results[from], error: true, helperText: "Invalid Service Request URL" }],
                //     errors: false,
                //     isfieldchanged: true,
                // })
            }).catch(ex => {
                this.setState({
                    results: ({ ...this.state.results, [from]: viewresults ? viewresults[from] : this.state.results[from] }),
                    formControls: { ...this.state.formControls, [from]: { ...this.state.formControls[from], value: viewresults ? viewresults[from] : this.state.results[from] } },
                    changedFieldName: [{ name: from, value: viewresults ? viewresults[from] : this.state.results[from], error: true, helperText: "Invalid Service Request URL" }],
                    errors: false,
                    isfieldchanged: true,
                })
            })
        }
        // else if (actions && actions[label] && actions[label].type === 'properties' && !slide) {
        //     let status = 'invalid'
        //     if (actions[label].value === this.state.results[label]) {
        //         status = 'valid'
        //     }
        //     if (Array.isArray(actions[label][status])) {
        //         actions[label][status].forEach(e => {
        //             const { key, ...otherProperties } = e
        //             this.setState({
        //                 formControls: { ...this.state.formControls, [key]: { ...this.state.formControls[key], ...otherProperties } },
        //                 changedFieldName: this.state.changedFieldName.push({ name: key, value: this.state.results[key] , ...otherProperties }),
        //                 errors: false,
        //                 isfieldchanged: true,
        //             })
        //         })
        //     }
        // }
        else if (actions && actions[label] && slide) {
            const { get } = getread
            const { url, routeProps, matchProps } = get;
            const { results } = this.state;
            let ids = [], id
            if (Array.isArray(routeProps)) {
                routeProps.forEach(each => {
                    const { value } = each
                    ids.push({ key: value, value: this.props.match.params[value] })
                })
            }
            else if (routeProps) id = routeProps ? this.props.match.params[routeProps.value] : null
            else if (matchProps) {
                id = results[matchProps.value]
            }
            actions && actions[label] && requestApi(getread, (id || ids), results).then(response => {
                this.setState({
                    results: ({ ...this.state.results, [label]: '' }),
                    formControls: { ...this.state.formControls, [label]: { ...this.state.formControls[label], value: '', dropdownvalues: response } },
                    changedFieldName: [{ name: label, value: '', dropdownvalues: response }],
                    errors: false,
                    isfieldchanged: true,
                })
            }).catch(ex => {
                console.error(ex)
            })
        }
    }

    /**
      *********************************************************
      Description: Handles show or hide, enable or disable of the field components.checks if conditions exists and calls the displaycondition and enableconditions function. Changed values are set to hide or show, enable or disable
      the component.
      else the value is set. Also, handles components display (hide or show, enable or disable).
      Required props:   results, formControls
      limitations    : N/A
      *********************************************************
      **/


    handleDisplay = (name) => {
        const { results, formControls } = this.state;
        let newformControls = {}
        let changed = [];
        let newchanged = [];
        var tempchange = {};
        Object.keys(formControls).forEach(e => {
            const { conditions, display, dependency, disabled, label, required } = this.formControlsConfigs[e];
            const newDisplay = conditions ? this.displayCondition(conditions, name, results) : display;
            const newdisabled = (conditions && conditions.enable) ? this.enableConditions(conditions, name, results) : false
            const isrequired = (conditions && conditions.required) ? this.requiredConditions(conditions, results) : formControls[e].required;
            const newdependency = (dependency) ? this.dependencyFields(dependency, e, label, results) : null
            let res;
            if (conditions && conditions.required)
                this.requiredFields[e] = isrequired;
            if (display !== newDisplay) {
                res = { name: e, display: newDisplay }
                newformControls[e] = { ...formControls[e], name: e, display: newDisplay, disabled: (disabled !== newdisabled) ? newdisabled : disabled }
            }
            if (disabled !== newdisabled) {
                if (res) res = { ...res, disabled: newdisabled };
                newformControls[e] = { ...formControls[e], name: e, disabled: newdisabled }
            }
            if (required !== isrequired) {
                if (res) res = { ...res, required: isrequired }
                else res = { name: e, required: isrequired }
                newformControls[e] = { ...formControls[e], name: e, required: isrequired, display: isrequired ? 'd-block' : formControls[e].display, disabled: (disabled !== newdisabled) ? newdisabled : disabled }
            }
            if (newdependency !== null) {
                if (newdependency.error)
                    res = { ...res, error: newdependency.error, helperText: newdependency.helperText }
                else {
                    res = newdependency;
                    // eslint-disable-next-line no-unused-expressions
                    newdependency.name ? newformControls[newdependency.name] =
                        { ...formControls[newdependency.name], name: newdependency.name, value: newdependency.value } : ''
                }
            }
            if (res) changed.push(res)
            if (res) {
                if (tempchange.name && tempchange.name === res.name) {
                    res = { ...tempchange, ...res }
                    newchanged.push(res)
                }
                else {
                    tempchange = res;
                }
            }
        })

        let finalformControls = { ...this.state.formControls, ...newformControls }
        let setval = newchanged ? _.find(newchanged, ['assignval', true]) : _.find(changed, ['assignval', true])
        if (setval && setval.name) {
            let finalval = setval.value
            let name = setval.name
            this.setState({
                results: ({ ...this.state.results, [name]: finalval }),
                formControls: finalformControls,
                changedFieldName: newchanged.map(e => {
                    const { name, display, disabled, error, helperText, required } = e
                    return { ...finalformControls[name], name: name, display: display, disabled: disabled, error: error, helperText: helperText, value: finalval, required: required }
                })
            })
        }
        else {
            this.setState({
                formControls: finalformControls,
                changedFieldName: changed.map(e => {
                    const { name, display, disabled, error, helperText, required } = e
                    return { ...finalformControls[name], name: name, display: display, error: error, helperText: helperText, required: required }
                })
            })
        }
    }

    /**
     *********************************************************
     Deprecated
     Description: Displaying the error text based on other field
     Required props:   N/A
     limitations    : N/A
     *********************************************************
     **/


    dependencyFields = (dependency, name, label, results) => {
        if (results) {
            if (dependency && dependency.maxLength) {
                if (Number(results[name]) > Number(results[dependency.maxLength])) {
                    return { error: true, helperText: `${label} is greater than ${dependency.label}` }
                }
                else return null
            }
            else if (dependency && dependency.equal) {
                if (results[name].length > 0 && results[name] !== results[dependency.equal]) {
                    this.setState({ hidesavebutton: true })
                    return { error: true, helperText: `${name} does not match` }
                }
                else {
                    this.setState({ hidesavebutton: false })
                    return null
                }
            }
            else if (dependency && dependency.multiply) {
                const { results, formControls } = this.state;
                let dropval, selectedval, finaldropval, fieldval, finalfieldval, finalval
                if (dependency.requestApi && dependency.url) { //requestapi
                    //for later use
                }
                if (dependency.requireddropdownval && dependency.dropdowns && dependency.dropdownfields.length > 0) { //dropdowns
                    dependency.dropdownfields.forEach((e, i) => {
                        dropval = formControls[e.fieldname].dropdownvalues
                        if (dropval) {
                            selectedval = _.find(dropval, { Value: this.state.results[e.fieldname] })[dependency.requireddropdownval]
                        }
                    })
                    finaldropval = !finaldropval ? selectedval : finaldropval * selectedval
                }
                if (dependency.field && dependency.fields && dependency.fields.length > 0) { // fields
                    dependency.fields.forEach((e, i) => {
                        fieldval = this.state.results[e.fieldname]
                    })
                    finalfieldval = !finalfieldval ? fieldval : finalfieldval * fieldval
                }
                //result conditions
                if (dependency.dropdowns && dependency.fields) {
                    if (finalfieldval !== "") {
                        finalval = finaldropval * finalfieldval
                        return { name: dependency.multiply, dropdownvalues: null, value: finalval, assignval: true }
                    }
                    else {
                        return { name: dependency.multiply, dropdownvalues: null, value: "", assignval: true }
                    }
                }
            }
            else if (dependency && dependency.isAdmin) {
                if (results[dependency.isAdmin]) {
                    if (this.state.isfieldchanged && (results[dependency.isAdmin] === "N" || results[dependency.isAdmin] === "Y") && this.state.changedFieldName[0].name === dependency.isAdmin) {
                        this.setState({ isfieldchanged: false }, () => {
                            this.props.snackbarMessage(dependency.isAdminAlert, 'info');
                        })

                    }
                    this.props.options.crud.read.get.addURL = `?isadmin=${results[dependency.isAdmin]}`;
                    this.props.match.addURL = `?isadmin=${results[dependency.isAdmin]}`;
                    return { error: false, helperText: `` }
                }
            }

            else if (dependency && dependency.isStatus) {
                if (results[dependency.isStatus].Status) {
                    if (this.state.isfieldchanged && results[dependency.isStatus].Status === dependency.statusValue
                        && this.state.changedFieldName[0].name === dependency.statusField && this.state.changedFieldName[0].value === dependency.statusValue) {
                        this.state.isfieldchanged = false;
                        if (results[dependency.statusCheck] !== "Y") {
                            let readURL = { get: { url: `${dependency.statusURL}/${this.props.match.params[dependency.statusID]}` } }
                            requestApi(readURL, (this.props.match.params[dependency.statusID])).then(results => {
                                if (!results) {
                                    let changed = [];
                                    const { formControls } = this.state;
                                    let res = { name: dependency.statusTarget, error: true, helperText: dependency.helperText }
                                    changed.push(res);
                                    this.setState({
                                        errors: true,
                                        changedFieldName: changed.map(e => {
                                            const { name, display, disabled, error, helperText } = e
                                            return { ...formControls[name], name: name, display: display, disabled: disabled, error: error, helperText: helperText }
                                        })
                                    })
                                }
                            }).catch(error => { });
                        }
                    }
                    return { error: false, helperText: `` }
                }
            }
        }
        return null
    }




    /**
        *********************************************************
        Description: Hanldes errors for the fields after saving. Maps the error object to field names of the form and then sets them to display the errors.
        Required props:   errors object after saving.
        limitations    : N/A
        *********************************************************
        **/


    onError = (errors) => {
        let save = true
        const { results, formControls } = this.state
        const errfieldnames = Object.keys(errors)
        let changedNames = [], errformControls = {}
        errfieldnames.forEach(each => {
            if (errors[each].error) save = false
            if (formControls[each] !== undefined) {
                errformControls[each] = {
                    value: formControls[each].value,//{...fieldValues[each],error:true,helperText:err[each].reduce((sum = '', val) => sum + val)},
                    error: errors[each].error,
                    touched: 1,
                    dropdownvalues: this.state.formControls[each].dropdownvalues,
                    helperText: errors[each].errortext
                }
            }
            let errorstate = false, errorval = ''
            if (errors[each]) {
                if (Array.isArray(errors[each])) {
                    errorstate = true
                    errorval = errors[each][0]
                }
                else {
                    errorstate = errors[each].error
                    errorval = errors[each].errortext
                }

            }
            if (formControls[each] !== undefined) {
                changedNames.push({
                    name: each,
                    value: formControls[each].value,//{...fieldValues[each],error:true,helperText:err[each].reduce((sum = '', val) => sum + val)},
                    error: errorstate,
                    touched: 1,
                    dropdownvalues: this.state.formControls[each].dropdownvalues,
                    helperText: errorval
                })
            }
        })
        this.setState({
            formControls: { ...formControls, ...errformControls },
            changedFieldName: changedNames
        })
        return save
    }

    // /**
    // *********************************************************
    // Description: Used to convert all non-editable components to editable.refresh the page with new results
    // Required props:   N/A
    // limitations    : N/A
    // *********************************************************
    // **/
    // getlatestFormData = () => {
    //     const { formControls, results, showview } = this.state
    //     let formControlsKeys = Object.keys(formControls)
    //     let newformControls = {}
    //     formControlsKeys.forEach(each => {
    //         newformControls[each] = { ...formControls[each], edit: showview ? !showview : true }
    //     });
    //     this.setState({
    //         results: this.state.results,
    //         formControls: newformControls,
    //         changedFieldName: formControlsKeys.map(each => {
    //             return { ...formControls[each], edit: showview ? !showview : true, name: each }
    //         }),
    //         editbutton: true,
    //         isedited: showview ? !showview : true,
    //         showview: false,
    //     }, () => {
    //         this.fieldConfiguration(this.props.components, this.state.results, false, {})
    //     })
    // }

    customnoticesave = (result, name, savetype) => {
        const { formControls, showview } = this.state
        const formControlsKeys = Object.keys(formControls)
        if (savetype === 'initialsave') {
            this.setState({
                callbacksaveval: !this.state.callbacksaveval,
                changedFieldName: formControlsKeys.map(each => {
                    return { ...formControls[each], callbacksave: !this.state.callbacksaveval }
                })
            })
        }
        else if (savetype === 'finalsave') {
            let resultitems = {}
            this.props.options.customsavenames.forEach(element => {
                resultitems[element] = result[element]

            });
            this.setState({
                results: ({ ...this.state.results, ...resultitems })
            }, () => this.handleSave())
        }
        else if (savetype === 'includeinresults') {
            this.setState({
                customvalue: { 'name': name, 'result': result },
                results: ({ ...this.state.results, [name]: result })
            })
        }
        else this.setState({
            results: ({ ...this.state.results, [name]: result })
        })

    }

    /**
        *********************************************************
        Description: Saves or updates the results. create the url using configuration properties from json(url,routeProps,matchProps). Verifies if all the required fields are filled and then saves
        the results. If errors exist corresponding fields with errors are set. Alerts if saved successfully or if there are errors.
        Required props:   options.put or options.post
        limitations    : N/A
        *********************************************************
        **/

    handleSave = (e, duplicaterecord) => {
        let urlpathname = this.props.location.pathname
        const id = this.props.match.params.routeid
        if (this.props && this.props.options && this.props.options.IsBuildMGR === true && getEnvVariable('BuildScopeMGR') === true) {
            if (DefaultSettings.getAlert() != null)
                DefaultSettings.getAlert().show("This functionality is not in release scope, so you can't add or update. Please contact build manager", 'warning');
        }
        else {
            const { options, customSave, actions, dataItem } = this.props

            const { crud } = options
            const save = duplicaterecord ? crud.duplicate : crud.update
            const { message, alert } = save
            const { put, post } = save || {}
            const { conditions } = put || {}
            let ids = [], id

            if (put) {
                let createput = true
                if (conditions) {
                    if (!this.state.results[conditions.results]) createput = false
                }
                if (createput) {
                    const { routeProps, matchProps, customProps } = save.put
                    if (save.put && (save.put.matchProps || save.put.routeProps || save.put.customProps) && Array.isArray(save.put.matchProps || save.put.routeProps || save.put.customProps)) {
                        const tempsaveprops = save.put.matchProps || save.put.routeProps || save.put.customProps
                        tempsaveprops.forEach(each => {
                            const { value, custom } = each
                            if (custom) {
                                ids.push({ key: custom, value: this.props.dataItem[custom] })
                            }
                            else {
                                ids.push({ key: value, value: this.props.match.params[value] })
                            }
                        })
                    }
                    else {
                        id = this.props.match.params.routeid
                    }
                }
                else {
                    const { routeProps, matchProps } = save.post
                    if (save.post && (save.post.matchProps || save.post.routeProps) && Array.isArray(save.post.matchProps || save.post.routeProps)) {
                        const tempsaveprops = save.post.matchProps || save.post.routeProps
                        tempsaveprops.forEach(each => {
                            const { value } = each
                            ids.push({ key: value, value: this.props.match.params[value] })
                        })
                    }
                    else {
                        const { routeProps, matchProps } = save.post
                        id = routeProps ? this.props.match.params[routeProps.value] : null
                    }
                }

            }
            else if (post) {
                const { routeProps, matchProps, customProps } = save.post
                if (save.post && (save.post.matchProps || save.post.routeProps || save.post.customProps) && Array.isArray(save.post.matchProps || save.post.routeProps || save.post.customProps)) {
                    const tempsaveprops = save.post.matchProps || save.post.routeProps || save.post.customProps
                    tempsaveprops.forEach(each => {
                        const { value, custom, hidepath, hidepathval } = each
                        hidepathval ? ids.push({ key: hidepathval, value: this.props.accessPermissions[hidepathval] }) : hidepath ? ids.push({ key: hidepath, value: this.props.accessPermissions.userId }) : custom ? ids.push({ key: custom, value: this.props.dataItem[custom] }) : ids.push({ key: value, value: this.props.match.params[value] })
                    })
                }
                else {
                    const { routeProps, matchProps, customProps } = save.post
                    id = routeProps ? this.props.match.params[routeProps.value] : (customProps ? this.props.dataItem[customProps.custom] : null)
                }
            }




            if (save || customSave) {
                const { results, formControls, errors, errfieldnames } = this.state
                //const errfieldnameskeys = Object.keys(errfieldnames)
                //const errors = globalvalidate(this.validations, formControls)
                // const xsave = this.onError(errors)
                const requiredFields = this.requiredFields
                const requiredFieldsKeys = requiredFields && Object.keys(requiredFields)
                let changedFieldName = []
                let err
                if (requiredFieldsKeys) {
                    requiredFieldsKeys.forEach(each => { //!errors &&
                        if (requiredFields[each]) {
                            const value = formControls[each].value
                            if ((value === null) || (value === undefined) || (value === '') || (value && Array.isArray(value) && value.length < 1)) {
                                err = true
                                changedFieldName.push({ ...this.state.formControls[each], name: each, error: true, helperText: 'Field is Required' })

                            }
                        }
                        if (this.state.formControls[each]) {
                            const value = formControls[each].value
                            const error = formControls[each].error
                            if (value && error) {
                                err = true
                                changedFieldName.push({ ...this.state.formControls[each], name: each, error: true })

                            }
                        }
                    })
                }
                if (err) {
                    this.setState({
                        errors: true,
                        changedFieldName: changedFieldName
                    }, () => {
                        if (this.props.customSave) this.props.handleCustomSave()
                    })

                }
                else {
                    const { components, options, formgridadd, sidepopupadd, slide } = this.props
                    const { addtoresults, title, tabadd } = options ? options : { addtoresults: {} }
                    let res
                    res = duplicaterecord ? { ...this.state.results, ...addtoresults, IsDuplicate: "Y" } : { ...this.state.results, ...addtoresults }
                    if (duplicaterecord) {
                        let resultdata = { ...res }
                        resultdata = this.loopobj(resultdata)
                        res = resultdata
                    }
                    components.forEach(each => {
                        const { options } = each
                        const { excludefromresults, name, allowDataItem, setValue, assignValue } = options ? options : {}
                        if (excludefromresults) delete res[name]
                        if (allowDataItem) res[assignValue] = this.props.dataItem[setValue];
                    })
                    if (errors) {
                        const formControlsKeys = Object.keys(this.state.formControls);

                        this.setState({
                            changedFieldName: formControlsKeys.map(each => {
                                return { ...formControls[each], name: each }
                            })
                        })
                    } else requestApi(save, (id || ids), res, null, alert, message).then(results => {
                        const { headerProps, tabadd } = options
                        const { navigateview, navigateadd, navigateclose, setnewResults, clearResults } = headerProps;
                        this.props.unsavedData(null);
                        if (save && save.put && save.put.isProfile)
                            this.props.setProfile(true)
                        if (this.state.formsave) {
                            //tabadd : to save in sliding page but its doesnot close sliding page
                            if (tabadd) {
                                this.setState({ formsave: false })
                            }
                            else {
                                this.props.onClose();
                                this.setState({ formsave: false })
                            }
                        }
                        if (navigateview) {

                            if (!duplicaterecord && results && results.UpdatedBy && results.UpdatedBy !== null) {
                                this.setState({ showview: true, results: results, compareresults: results, showsavebtn: false, closeviewconfirmation: true }, () => {
                                    this.handleView(results, setnewResults)
                                })

                            }
                            else if (duplicaterecord) {

                                let pathname = this.props.location.pathname
                                this.props.match.params.Id = results[options.viewroutesId]
                                pathname = pathname.substring(0, pathname.lastIndexOf("/"))
                                this.props.history.push(pathname);
                                this.props.onClose();
                            }
                            else {
                                this.handleView(results, true)

                            }
                        }
                        if (navigateclose) {
                            this.props.onClose();
                        }
                        if (navigateadd) {
                            // To close the sliding page.
                            if ((formgridadd || sidepopupadd)) {

                                this.props.accessPermissions.portal === 'I' && this.handleNotificationEdit(results);
                                if (this.props.accessPermissions.portal === 'E') {
                                    let pathname = this.props.location.pathname
                                    if (results && this.props && this.props.match && this.props.match.params && options.afterSaveroutesId) {
                                        this.props.match.params.Id = results[options.afterSaveroutesId]
                                        pathname = pathname + '/' + this.props.match.params.Id
                                    }
                                    this.props.onClose();
                                    this.props.history.push(pathname);
                                }
                            }
                            else {
                                const { afterSaveroutes = {} } = options;
                                const { connectingroute = null, connectingrouteid = null, routeparams = null, routeparams2 = null, route = null, existparams = null } = afterSaveroutes;
                                if (Array.isArray(afterSaveroutes)) {
                                    let path = ''
                                    afterSaveroutes.forEach(each => {
                                        const { route, routeParams, matchProps } = each
                                        if (routeParams) {
                                            path += route + "/" + this.props.match.params[routeParams]
                                        }
                                        else if (matchProps) {
                                            path += route + "/" + results[matchProps]
                                        }
                                        else {
                                            path += route
                                        }
                                    })
                                    this.props.history.push(path)
                                }
                                if (routeparams2 !== '2' && routeparams && connectingroute && connectingrouteid) {
                                    this.props.history.push(route + routeparams && results[routeparams] + routeparams2 && results[routeparams2] + connectingroute + results[connectingrouteid])
                                }
                                else if (routeparams2 === '2') {
                                    this.props.history.push(route + this.props.match.params['routeid'] + '/' + this.props.match.params['routeid1'] + connectingroute + results[connectingrouteid])
                                }
                                else if (connectingroute) {
                                    if (existparams) {
                                        this.props.history.push(route + this.props.match.params[existparams] + connectingroute)
                                    }
                                    else
                                        if (connectingrouteid) {
                                            if (results[routeparams]) { this.props.history.push(route + results[routeparams] + connectingroute + results[connectingrouteid]) }
                                            else { this.props.history.push(route + this.props.match.params[routeparams] + connectingroute + results[connectingrouteid]) }
                                        } else {
                                            this.props.history.push(route + results[routeparams] + connectingroute)
                                        }
                                }
                                if (afterSaveroutes) this.props.history.push(afterSaveroutes.route + results[afterSaveroutes.routeparams])

                            }
                        }
                    }).catch(error => {
                        console.log(error)
                        error.then(err => {
                            if (err.errors) this.onError(err.errors)
                            else this.onError(err)
                        })
                    })
                }
            }
            //  }
        }
    }

    /**
     * Handle Back to navigate to custom and history screen
     *
     * Required Props: back url, edit = true or false
     */
    handleBack = (e, closeconfirmation, stayInSamePage) => {
        const { options } = this.props
        const { back, nocondition } = options;
        const { routes, popupback, issubgrid } = back;
        let nocond = nocondition ? false : true
        let checkcond = this.props && this.props.header && this.props.header.unsaveddata ?
            !(_.isEqual(this.props.header.unsaveddata.results, this.props.header.unsaveddata.compareresults)) && !closeconfirmation && !this.state.closeviewconfirmation : false
        let insamepage = this.props && this.props.header && this.props.header.unsaveddata && this.props.header.unsaveddata.duplicate ? true : stayInSamePage

        if (popupback) {
            if (checkcond && nocond) {
                if (stayInSamePage) {
                    this.setState({ openconfirmation: false, navigatingnext: false, navigatingprev: false })
                }
                else {
                    this.setState({ openconfirmation: true, navigatingnext: false, navigatingprev: false })
                }
            }
            else {
                if (insamepage && !closeconfirmation && !stayInSamePage && nocond) {
                    this.setState({ openconfirmation: true, navigatingnext: false, navigatingprev: false })
                }
                else if (insamepage && !closeconfirmation && stayInSamePage && nocond) {
                    this.setState({ openconfirmation: false, navigatingnext: false, navigatingprev: false })
                }
                else {
                    let pathname = this.props.location.pathname
                    if (this.props && this.props.match && this.props.match.params && (this.props.match.params.Id || this.props.match.params.id) && !issubgrid) {
                        this.props.match.params.Id = undefined
                        pathname = pathname.substring(0, pathname.lastIndexOf("/"))
                    }
                    this.props.onClose()
                    this.props.history.push(pathname)
                }
            }
        }

        else {
            if (Array.isArray(routes)) {
                let path = ''
                routes.forEach(each => {
                    const { route, routeParams, matchProps } = each
                    if (routeParams) {
                        path += route + "/" + this.props.match.params[routeParams]
                    }
                    else if (matchProps) {
                        path += route + "/" + this.props.results[matchProps] //---correct: unused
                    }
                    else {
                        path += route
                    }
                })
                this.props.history.push(path)
            }
        }
    }

    /* user can choose whether to edit or not , displays alert message */
    chooseEditAlert = () => {
        this.setState({ displayalert: true })
    }

    closeAlert = () => {
        this.setState({ displayalert: false })
    }

    handleNotificationEdit = (result) => {
        const { options } = this.props
        if (options && options.notificationkey) {
            //Check any notifications
            let eventDetails = {
                SubModuleKey: "APPLICATIONS",
                SitePermitTypeId: this.props.match.params.routeid,
                EventId: this.props.match.params.routeid1,
                EventOnKey: options.notificationkey || '',//'FIELD_VALUE_CHANGE',
            }

            requestApi({ post: { url: getEnvVariable('Notification') + `/api/NotificationTrigger/HasAnyNotifications` } }, null, eventDetails, null, alert = true).then(results => {
                if (results) {
                    this.setState({ openNotificationEditor: true })
                }
                else {
                    let pathname = this.props.location.pathname
                    if (result && this.props && this.props.match && this.props.match.params && options.afterSaveroutesId) {
                        this.props.match.params.Id = result[options.afterSaveroutesId]
                        pathname = pathname + '/' + this.props.match.params.Id
                    }
                    this.props.onClose();
                    this.props.history.push(pathname);
                }
            })
        }
        else {


            let pathname = this.props.location.pathname
            if (result && this.props && this.props.match && this.props.match.params && options.afterSaveroutesId) {
                this.props.match.params.Id = result[options.afterSaveroutesId]
                pathname = pathname + '/' + this.props.match.params.Id
            }
            this.props.onClose();
            this.props.history.push({
                pathname: pathname,
                state: result
            });
            // this.props.history.push(pathname);
        }
    }

    handleNotificationClose = (isSent) => {
        this.setState({ openNotificationEditor: false });
        if (isSent) {
            this.props.snackbarMessage('Notification sent successfully', 'success');
        }
        this.props.onClose();
    }

    nextItem = (e, closeconfirmation, stayInSamePage) => {
        let checkcond = this.props && this.props.header && this.props.header.unsaveddata ?
            !(_.isEqual(this.props.header.unsaveddata.results, this.props.header.unsaveddata.compareresults)) && !closeconfirmation : false
        let insamepage = this.props.header.unsaveddata && this.props.header.unsaveddata.duplicate ? true : stayInSamePage

        if (checkcond) {
            if (stayInSamePage) {
                this.setState({ openconfirmation: false, navigatingnext: true, navigatingprev: false })
            }
            else {
                this.setState({ openconfirmation: true, navigatingnext: true, navigatingprev: false })
            }
        }
        else {
            if (insamepage && !closeconfirmation && !stayInSamePage) {
                this.setState({ openconfirmation: true, navigatingnext: false, navigatingprev: false })
            }
            else if (insamepage && !closeconfirmation && stayInSamePage) {
                this.setState({ openconfirmation: false, navigatingnext: false, navigatingprev: false })
            }
            else {
                this.setState({ openconfirmation: false, navigatingnext: true, navigatingprev: false }, () => {
                    this.props.onNextItemClick()
                })
            }
        }
    }

    prevItem = (e, closeconfirmation, stayInSamePage) => {

        let checkcond = this.props && this.props.header && this.props.header.unsaveddata ?
            !(_.isEqual(this.props.header.unsaveddata.results, this.props.header.unsaveddata.compareresults)) && !closeconfirmation : false
        let insamepage = this.props.header.unsaveddata && this.props.header.unsaveddata.duplicate ? true : stayInSamePage
        if (checkcond) {
            if (stayInSamePage) {
                this.setState({ openconfirmation: false, navigatingprev: true, navigatingnext: false })
            }
            else {
                this.setState({ openconfirmation: true, navigatingprev: true, navigatingnext: false })
            }
        }
        else {
            if (insamepage && !closeconfirmation && !stayInSamePage) {
                this.setState({ openconfirmation: true, navigatingnext: false, navigatingprev: false })
            }
            else if (insamepage && !closeconfirmation && stayInSamePage) {
                this.setState({ openconfirmation: false, navigatingnext: false, navigatingprev: false })
            }
            else {
                this.setState({ openconfirmation: false, navigatingprev: true, navigatingnext: false }, () => {
                    this.props.onPrevItemClick()
                })
            }
        }
    }

    /**
        *********************************************************
        Description: Renders the components.Subform manages the layout of the component. Form sends the changed field values to subform to update the field values. Form header contains
        save and edit actions.
        Required props:   N/A
        limitations    : N/A
        *********************************************************
        **/

    render() {
        const { options, defaults, order, type, compmap, className, styles, accessPermissions, accessLevelPermissions, windowHeight, hideEdit, dataItem, hideEditCheckVal, onFullScreen, onExitFullScreen, fullscreen, exitfullscreen, onNextItemClick, onPrevItemClick, ...otherProps } = this.props;
        const { crud, title, headerProps, hideheaderprops, savebtn, saveappbtn, designprops, historyalign, alignresend,alignprofile,hidescroll, hideEditCheckValue, hideEditButton, hideEditCheckName, } = options ? options : {}
        const { savebutton, editbutton, backbutton, savetemplatebutton, closebutton, breadcrumbs, sendbutton, nonstickyposition, navigateup, navigatedown, duplicatebutton, hasrolebasedcondition, historyurl } = headerProps ? headerProps : {}
        const save = crud ? crud.update : null
        const { errors, isedited, iseditduplicate, showsavebtn, displayalert, openNotificationEditor, openconfirmation, hideediticon, hideduplicateicon } = this.state
        let accessfrom = getEnvVariable('ConfigType') == 'R' ? 'moduleRegionAccess' : getEnvVariable('ConfigType') == 'D' ? 'moduleDistrictAccess' : 'moduleSiteAccess'
        let roleaccess = this.props.accessPermissions && this.props.accessPermissions[accessfrom] && this.props.accessPermissions[accessfrom] ? Object.values(this.props.accessPermissions[accessfrom]).includes('Add') ? 'CanAdd' : Object.values(this.props.accessPermissions[accessfrom]).includes('Update') ? 'CanEdit' : Object.values(this.props.accessPermissions[accessfrom]).includes('Read') ? 'CanRead' : 'CanAdd' : 'CanAdd'
        let portal = this.props.accessPermissions ? this.props.accessPermissions.portal : null
        let canAdd = (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanAdd" ? true : (!hasrolebasedcondition || portal === "E") ? true : false
        let canEdit = (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanEdit" ? true : (!hasrolebasedcondition || portal === "E") ? true : false
        let canRead = (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanRead" ? true : (!hasrolebasedcondition || portal === "E") ? true : false
        return (
            <Fragment>
                <form onSubmit={this.handleSubmit} className={className} style={{ ...formstyles.formcontainer, ...styles }}>
                    <div className={!designprops ? (!hidescroll ? "eps-content-scroll" : '') : ''} style={{ height: !designprops ? (!windowHeight ? window.innerHeight - 50 : '') : '' }}>

                        {headerProps && !hideheaderprops && <FormHeader accessPermissions={accessPermissions} nonstickyposition={nonstickyposition} rowrenderedslide={this.props.rowrenderedslide} SaveTemplateInfo={this.customnoticesave} savetemplatebutton={savetemplatebutton}
                            accessLevelPermissions={accessLevelPermissions} breadcrumbs={breadcrumbs} title={title} handleEdit={hideEdit && hideEditCheckVal ? (dataItem[hideEditCheckVal] === 'N' ? this.chooseEditAlert : this.handleEdit) : this.handleEdit} onFullScreen={onFullScreen} onExitFullScreen={onExitFullScreen} fullscreen={fullscreen} exitfullscreen={exitfullscreen} onNextItem={this.nextItem} onPrevItem={this.prevItem}
                            handleSave={this.handleSave} handleClose={this.handleClose} editbutton={((hideEditButton && dataItem[hideEditCheckName] === hideEditCheckValue) || hideediticon) ? false : (canAdd || canEdit) ? editbutton : false} handleBack={this.handleBack} dataItem={dataItem} navigateup={navigateup} navigatedown={navigatedown}
                            historyurl={historyurl} results={this.state.results}
                            savebutton={editbutton ? (showsavebtn ? (savebutton) : (false)) : (canAdd || canEdit) ? savebutton : false} duplicatebutton={hideduplicateicon ? false : (canAdd || canEdit) ? duplicatebutton : false} backbutton={backbutton} isedited={isedited} iseditduplicate={iseditduplicate} designprops={designprops} historyalign={historyalign} alignresend={alignresend} alignprofile={alignprofile} sendbutton={sendbutton} />}
                        {displayalert && <CustomAlert handleEdit={this.handleEdit} open={displayalert} message={' Are you sure, you want to edit ?'} closeAlert={this.closeAlert} severity={'Warning'} isAction={true} />}
                        < SubForm {...this.props} effects={this.effects} compmap={compmap} changedFieldName={this.state.changedFieldName}
                            handleFieldChange={this.handleFieldChange} handleIconClick={this.handleIconClick} results={this.state.formControls} res={this.state.results} addSubGrid={true}
                            handleSlide={this.handleSlide} customnoticesave={this.customnoticesave} />

                        {
                            (savebtn || saveappbtn) &&
                            <div className="col-sm-12">
                                <Button
                                    className="col-sm-2 float-right"
                                    variant="contained"
                                    color={this.state.hidesavebutton ? "inherit" : "primary"}
                                    disabled={this.state.hidesavebutton}
                                    onClick={() => { this.setState({ formsave: true }, () => this.handleSave(true)) }}
                                >
                                    Save
                                </Button>
                            </div>
                        }
                        {openconfirmation && <ConfirmationDialog open={this.state.openconfirmation} handleBack={this.handleBack} navigatingnext={this.state.navigatingnext} navigatingprev={this.state.navigatingprev} nextItem={this.nextItem} prevItem={this.prevItem} />}
                        {openNotificationEditor && <NotificatinoEditor
                            openNotificationSlide={true}
                            handleNotificationClose={this.handleNotificationClose}
                            recordid={this.props.match.params.routeid1}
                            sitepermittypeid={this.props.match.params.routeid}
                            key={options.notificationkey}
                            isFieldValueChange={false}
                            eventonkey={options.notificationkey}
                        />}
                    </div>
                </form>
            </Fragment>
        );
    }

}

const mapProps = (state) => {
    return state
}

// export const unsavedresultsReducer = (state = null, action) => {
//     switch (action.type) {
//         case 'UNSAVED_RESULTS':
//             return action.unsavedresults

//         default:
//             return state
//     }
// }

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage, unsavedData, setRecordData, setProfile }, dispatch);
}

CFormContainer = connect(mapProps, mapActions)(CFormContainer)
export default withRouter(CFormContainer)
