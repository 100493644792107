/**
      *********************************************************
      Description: Handles routing of the components. Based on routes config routes are generated. All the properties from
      the json are spread over the component along with accessPermissions, accessPortal and component mapping. Component mapping
      maps names (json type value) to components.
      Required props: components, options. In options routes are needed to configure the routes.
      limitations    : N/A
      *********************************************************
**/

import React, { Component, lazy, Suspense } from 'react'
import { HashRouter as Router, Route, withRouter, Switch } from 'react-router-dom';
import SnackBar from './snackbar';
import { getEnvVariable } from '../../../modules/environmentalList';
import Skeleton from '@material-ui/lab/Skeleton';
import Confirmation from '../../controls/components/confirmationPopup'
import Backdrop from '../backdrop';
import AMESRouteControls from '../../../routecontrols/ames.routecontrols';
import EPSRouteControls from '../../../routecontrols/eps.routecontrols';
import UPSRouteControls from '../../../routecontrols/ups.routecontrols';
import KEPTRouteControls from '../../../routecontrols/kept.routecontrols';
import SACRouteControls from '../../../routecontrols/sac.routecontrols';
//import AEPSRouteControls from '../../../routecontrols/aeps.routecontrols';
import MPSRouteControls from '../../../routecontrols/mps.routecontrols';
import WRSRouteControls from '../../../routecontrols/wrs.routecontrols';
import FORWARDRouteControls from '../../../routecontrols/forward.routecontrols';
// const AMESRouteControls = lazy(() => import('../../../routecontrols/ames.routecontrols'));
// const EPSRouteControls = lazy(() => import('../../../routecontrols/eps.routecontrols'));
// const UPSRouteControls = lazy(() => import('../../../routecontrols/ups.routecontrols'));
// const KEPTRouteControls = lazy(() => import('../../../routecontrols/kept.routecontrols'));
// const SACRouteControls = lazy(() => import('../../../routecontrols/sac.routecontrols'));
// const AEPSRouteControls = lazy(() => import('../../../routecontrols/aeps.routecontrols'));
// const MPSRouteControls = lazy(() => import('../../../routecontrols/mps.routecontrols'));
// const WRSRouteControls = lazy(() => import('../../../routecontrols/wrs.routecontrols'));
class Content extends Component {

    generatePin = () => {
        let min = 0;
        let max = 9999;
        return ("0" + Math.floor(Math.random() * (max - min + 1)) + min).substr(-4);
    }

    render() {
        const { type, components, options, compmap, accessPermissions, accessLevelPermissions, AccessPortal } = this.props
        const contentcomp = []
        components.forEach((each, index) => {
            const EachComp = compmap[each.type]
            const { options, key } = each
            const routes = options ? options.routes : null;
            if (Array.isArray(routes)) {
                let path = ''
                routes.forEach(each => {
                    const { route, routeParams } = each
                    if (routeParams) {
                        path += route + "/" + routeParams
                    }
                    else {
                        path += route
                    }
                })
                contentcomp.push(<Route exact={true} strict path={path} key={each.key || index} render={() => <EachComp AccessPortal={AccessPortal} accessPermissions={accessPermissions} accessLevelPermissions={accessLevelPermissions} compmap={compmap} {...each} key={each.key} />} />)
            }
            else if (routes) {
                const { route, routeParams, exactRoute } = routes
                if (routeParams) {
                    contentcomp.push(<Route exact={exactRoute || true} strict path={route + '/:routeid'} key={each.key || index} render={() => <EachComp accessPermissions={accessPermissions} accessLevelPermissions={accessLevelPermissions} AccessPortal={AccessPortal} compmap={compmap} {...each} key={each.key} />} />)
                }
                else {
                    contentcomp.push(<Route exact={true} strict path={route} key={each.key || index} render={() =>
                        <EachComp accessPermissions={accessPermissions} accessLevelPermissions={accessLevelPermissions} AccessPortal={AccessPortal} compmap={compmap} {...each}
                            key={each.key} dynamicKey={this.generatePin()} />} />)
                }

            }
        })
        return (
            <div className="eps-content-scroll w-100 m-2" style={{ height: 'calc(100% - (50px))' }}>
                <Suspense fallback={<div className="col">
                    <Backdrop open={true} />
                    <Skeleton variant="text" width='100%' height={50} />
                    <Skeleton variant="rect" width='100%' height={800} />
                </div>}>
                    <SnackBar />
                    <Confirmation />
                    {contentcomp ? <>
                        {contentcomp}
                        {getEnvVariable('ModuleKey') === 'AMES' && <AMESRouteControls {...this.props} />}
                        {getEnvVariable('ModuleKey') === 'EPS' && <EPSRouteControls {...this.props} />}
                        {getEnvVariable('ModuleKey') === 'UPS' && <UPSRouteControls {...this.props} />}
                        {getEnvVariable('ModuleKey') === 'KEPT' && <KEPTRouteControls {...this.props} />}
                        {getEnvVariable('ModuleKey') === 'SAC' && <SACRouteControls {...this.props} />}
                        {/* {getEnvVariable('ModuleKey') === 'AEPS' && <AEPSRouteControls {...this.props} />} */}
                        {getEnvVariable('ModuleKey') === 'MPS' && <MPSRouteControls {...this.props} />}
                        {getEnvVariable('ModuleKey') === 'WRS' && <WRSRouteControls {...this.props} />}
                        {getEnvVariable('ModuleKey') === 'FORWARD' && <FORWARDRouteControls {...this.props} />}</> : <></>}
                </Suspense>
            </div>
        )
    }

}

export default withRouter(Content);
