import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SnapshotIcon from '@material-ui/icons/FilterCenterFocus';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getEnvVariable } from '../../../modules/environmentalList';
import { createRequest, requestApi } from '../helpers/rest';
import DefaultSettings from './settings';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from "moment";
import TextBox from './textbox';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         width: '100%',
//         backgroundColor: theme.palette.background.paper,
//     },
// }));



export default function RulesSnapShot({ fieldValues, fieldProps }) {
    const [open, setOpen] = useState(false)
    // const [data, setData] = useState({})
    // const [businessrules, setBusinessRules] = useState([])
    const [applicationstate, setApplicationState] = useState([])
    const params = useParams();
    const [value, setValue] = React.useState(1);


    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const toggleDrawer = (event) => {
        setOpen(!open);
    };

    useEffect(() => {
        if (open) {
            let datakeysurl = [
                //     {
                //     url: getEnvVariable('Application') + `/api/BusinessRulesUserActivity/RecordState/{routeid1}`,
                //     "routeProps": [{
                //         name: "routeid1",
                //         key: "routeid1",
                //     },
                //     {
                //         name: "routeid",
                //         key: "routeid",
                //     }],
                //     type: 'get'
                // },
                {
                    url: getEnvVariable('Application') + `/api/BusinessRulesUserActivity/RecordState/{routeid1}`,
                    "routeProps": [{
                        name: "routeid1",
                        key: "routeid1",
                    },],
                    type: 'get',
                }]

            Promise.all(datakeysurl.map(each => requestApi(createRequest(each, {}, params)))).then(datacustomkeys => {
                const [RecordHiddenState = null] = datacustomkeys //businessrules = [],
                // setBusinessRules(businessrules)
                setApplicationState(RecordHiddenState)
            })
        }

    }, [open])


    return (
        <div>
            <IconButton className="zoom" title={'Business Rules Snapshot'} color={"inherit"} onClick={toggleDrawer} size='small'> <SnapshotIcon />  </IconButton>
            <Drawer anchor={'right'} open={open} onClose={toggleDrawer} PaperProps={{ style: { width: 700 } }} >
                <AppBar position="static" color='secondary'>
                    <Toolbar variant="dense">
                        <Typography variant="h6" color="inherit" className={'flex-grow-1'}>
                            {'Business Rules Snapshot'}
                        </Typography>
                        <IconButton
                            className='float-right'
                            edge="end"
                            onClick={toggleDrawer}
                            color="inherit"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <AppBar position="static" color="white">
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="white"
                        variant="fullWidth"
                        scrollButtons="auto"
                    >
                        {/* <Tab label="Rules SnapShot" {...a11yProps(0)} /> */}
                        <Tab label="Current Application State Triggered By Rules" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                {/* <TabPanel value={value} index={0}>
                    <TimeLine businessrules={businessrules} fieldProps={fieldProps} />
                </TabPanel> */}
                <TabPanel value={value} index={1}>
                    <RecordCurrentState fieldProps={fieldProps} applicationstate={applicationstate} />
                </TabPanel>
            </Drawer>
        </div >
    );
}

// function TimeLine({ businessrules, fieldProps }) {
//     const [expanded, setExpanded] = useState(false);
//     const [searchValue, setsearchValue] = useState('');
//     const [businessobj, setBusinessRuleObj] = useState({})
//     const [refresh, setRefreh] = useState(false)
//     const [fieldKeys, setfieldKeys] = useState(fieldProps && Object.keys(fieldProps).length > 0 ? Object.keys(fieldProps) : [])

//     const handleAccordianChange = (panel) => (event, isExpanded) => {
//         setExpanded(isExpanded ? panel : false);
//     };

//     const handleSearchValue = (evt) => {
//         const { name, value } = evt.target
//         setsearchValue(value);
//         createBusinessRules(value)
//     }

//     useEffect(() => {
//         createBusinessRules()
//     }, [])


//     const createBusinessRules = (value = '') => {
//         let tempbusinessobj = {};
//         let appKeys = Object.keys(fieldProps).filter(each => each.toLowerCase().includes(value.toLowerCase()));
//         appKeys.forEach(appkey => {
//             if ((fieldProps[appkey].label.toLowerCase()).includes(value.toLowerCase())) {
//                 let appKeyObj = businessrules.filter(each => each.EventField === appkey);
//                 tempbusinessobj[appkey] = appKeyObj;
//             }
//         })
//         setBusinessRuleObj(tempbusinessobj);
//         setfieldKeys(appKeys)
//         setRefreh(!refresh)
//     }


//     return (<div className='col-12' style={{ maxHeight: window.innerHeight - 145, overflow: 'auto' }}>
//         {refresh}
//         <TextBox className="col-10 row" label="Search" name="search" value={searchValue} handleFieldValue={handleSearchValue} />
//         <div className='my-1' />
//         {fieldKeys && fieldKeys.length > 0 && fieldKeys.map(each => {
//             return <><Accordion expanded={expanded === each} onChange={handleAccordianChange(each)}>
//                 <AccordionSummary
//                     expandIcon={<ExpandMoreIcon />}
//                     aria-controls="panel1bh-content"
//                     id="panel1bh-header"
//                 >
//                     <Typography variant="subtitle2" gutterBottom>{fieldProps[each].label}</Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                     <TableContainer component={Paper}>
//                         <Table aria-label="simple table">
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell align="right">Trigger Time</TableCell>
//                                     <TableCell align="right">Trigger By</TableCell>
//                                     <TableCell align="right">Required</TableCell>
//                                     <TableCell align="right">Visible</TableCell>
//                                     <TableCell align="right">Disabled</TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                                 {businessobj && Object.keys(businessobj).length > 0 && businessobj[each] && businessobj[each].map(eachBus => {
//                                     return <TableRow key={eachBus.EventField + eachBus.EventAt}>
//                                         <TableCell align="right" component="th" scope="row">
//                                             {moment(eachBus.EventAt).format('MM/DD/YYYY hh:mm:ss A')}
//                                         </TableCell>
//                                         <TableCell align="right">{fieldProps[eachBus.EventField].label}</TableCell>
//                                         <TableCell align="right">{eachBus.Required === 'Y' ? 'Yes' : 'No'}</TableCell>

//                                         <TableCell align="right"> {eachBus.IsDisabled === 'Y' ? 'Yes' : 'No'}</TableCell>
//                                         <TableCell align="right">{eachBus.IsVisible === 'Y' ? 'Yes' : 'No'}</TableCell>
//                                     </TableRow>
//                                 }
//                                 )}
//                             </TableBody>
//                         </Table>
//                     </TableContainer>
//                 </AccordionDetails>
//             </Accordion>
//                 <div className='my-1' />
//             </>
//         })}
//     </div>)

// }

function RecordCurrentState({ fieldProps, applicationstate }) {
    return <TableContainer component={Paper} style={{ height: window.innerHeight - 145, overflow: 'auto' }}>
        <Table stickyHeader aria-label="simple table">
            <TableHead >
                <TableRow>
                    {/* <TableCell align="right">Trigger Time</TableCell> */}
                    <TableCell >Field</TableCell>
                    <TableCell >Required</TableCell>
                    <TableCell >Visible</TableCell>
                    <TableCell >Disabled</TableCell>
                </TableRow>
            </TableHead>
            <TableBody  >
                {applicationstate && applicationstate.length > 0 && applicationstate.map(eachBus => {
                    return <TableRow key={eachBus.EventField + eachBus.EventAt}>
                        {/* <TableCell  component="th" scope="row">
                            {moment(eachBus.EventAt).format('MM/DD/YYYY hh:mm:ss A')}
                        </TableCell> */}
                        <TableCell >{(fieldProps && fieldProps[eachBus.EventField] && fieldProps[eachBus.EventField].label) || eachBus.EventField || ''}</TableCell>
                        <TableCell >{eachBus.Required === 'Y' ? 'Yes' : 'No'}</TableCell>

                        <TableCell > {eachBus.IsDisabled === 'Y' ? 'Yes' : 'No'}</TableCell>
                        <TableCell >{eachBus.IsVisible === 'Y' ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                }
                )}
            </TableBody>
        </Table>
    </TableContainer>

}