
/**
      *********************************************************
      Description: Handles signup form.
      Required props:  type, steps, components.
      limitations    : can be used only to create a sigin form with username and password and its variations.
      *********************************************************
**/

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import SubForm from '../form/csubform';
import { requestApi } from './utilities'
import FormHeader from './cformheader';
import { getToken } from '../header';
import BackDrop from '../backdrop';
import { GetData } from '../../controls/helpers/requests';
import { getEnvVariable } from '../../../modules/environmentalList';
import iwsusersignin from '../../../json/signin/isauthsignin';
class CSignUpForm extends Component {
  constructor(props) {
    super(props);
    /**
      *********************************************************
      Description: Handles component initialization.
      Required props:  type, steps, components. Each field prop are stored in formControls
      limitations    : N/A.
      *********************************************************
**/

    const { options, components, compmap, steps } = props
    this.formControls = {}
    this.state = {
      step: options.initialstep,
      username: "",
      password: "",
      emailAddress: null,
      pincode: null,
      changedFieldName: [], tokenvalid: true,
      isWindowsAuth: false
    };
    this.compsForEachStep = components.sort((a, b) => { return a.options.step - b.options.step }).map(each => {
      const { components } = each
      components.forEach(e => {
        const { options, ...otherProps } = e
        const { name, ...otherOptions } = options
        this.formControls[name] = {
          name: name,
          ...otherProps,
          ...otherOptions
        }
      })
      return { ...each }
    })

  }


  componentDidMount() {
    //this.getAuthenticationType();
    this.GetAuthType();
    if (this.props.match && this.props.match.params && this.props.match.params.guid)
      this.isValidtoken();
  }

  GetAuthType() {
    let url = getEnvVariable('Auth') + '/api/UserAuth/AuthType/' + getEnvVariable("AccessPortal");
    GetData(`${url}`).then(response => {
      //    console.log('sso login....', response);
      if (response) {
        let isldap = (response['LDAP_AUTH'] === 'Y');
        let iswindows = (response['ACTIVE_DIRECTORY_AUTH'] === 'Y');
        let isAutoLogin = (response['AUTOLOGIN'] === 'Y');
        let isIWAAuth = (response['IWAAUTH'] === 'Y');
        if (isIWAAuth && !window.location.hash.includes("resetpassword")) {
          localStorage.setItem('IWAAUTH', response['IWAAUTH'])
          let iwsJSON = iwsusersignin;
          const { options, components, compmap, steps } = iwsJSON.components[0]
          this.compsForEachStep = components.sort((a, b) => { return a.options.step - b.options.step }).map(each => {
            const { components } = each
            components.forEach(e => {
              const { options, ...otherProps } = e
              const { name, ...otherOptions } = options
              this.formControls[name] = {
                name: name,
                ...otherProps,
                ...otherOptions
              }
            })
            return { ...each }
          })
          const { type, jumptostep, jumptofalsestep, close, changepassword } = this.formControls['passwordbutton'] ? this.formControls['passwordbutton'] : {};
          this.setState({ step: 2 }, () => {
            this.goToNext(jumptostep, jumptofalsestep, 'passwordbutton', type, changepassword, isIWAAuth);
          })

        }
        if (iswindows && isAutoLogin) {
          this.GetSSOToken();
        }
        if (isldap || iswindows) {
          let message = ""//"Authentication directory is active. Provide credentials that exists in the directory."
          this.setState({
            isWindowsAuth: response, passwordmessage: message,
            changedFieldName: [{ name: 'passwordmessage', value: message }]
          })
        }
      }
    }).catch(ex => {
      //this.setState({ isWindowsAuth: false })
    })
  }

  GetSSOToken(isIWAAuth = false) {
    this.backdrop.backdropOpen();
    const currentform = this.compsForEachStep[this.state.step - 1]
    const { options } = currentform
    const { validations, crud, link, formdata } = options
    const { rest } = crud ? crud : {}
    const { post } = rest ? rest : {}
    requestApi(rest, null, {}, formdata, true)
      .then(results => {
        this.backdrop.backdropClose();
        if (results) {
          console.log(results);
          if (link) {
            const token = results.token_type + ' ' + results.access_token
            localStorage.setItem('token', token)
            this.props.history.push(`${link.url}`)
            window.location.reload(true);
          }
        }
      }).catch(error => {
        this.backdrop.backdropClose();
        error.then(err => {
          if (err.error) {
            alert('Not a correct windows user')
            this.setState({ passwordmessage: err.error, changedFieldName: [{ name: 'passwordmessage', value: err.error }] })
          }
        })
      })
  }



  /**
     *********************************************************
     Description: Handles navigation on clicking the link or next button.
     Required props:  name
     limitations    : N/A.
     *********************************************************
**/

  handleClick = (event) => {
    const { name, value } = event.currentTarget
    const { type, jumptostep, jumptofalsestep, close, changepassword } = this.formControls[name] ? this.formControls[name] : {}
    if (!close && (type === 'navformbutton' || type === 'navlink')) {
      if (changepassword) {
        if (this.state.tokenvalid) {
          this.goToNext(jumptostep, jumptofalsestep, name, type, changepassword);
        }
      }
      else this.goToNext(jumptostep, jumptofalsestep, name, type, null);

    }
    else if (close) {
      this.props.onClose();
    }
  }


  isValidtoken = () => {
    const { guid } = this.props.match.params;
    let url = getEnvVariable('Auth') + '/api/UserAuth/IsLinkValid' + '/' + guid;
    GetData(`${url}`).then(response => {
      if (!response.isLinkValid) {
        this.setState({
          step: 15,
          changedFieldName: [],
          tokenvalid: false
        });
      }
    }).catch(ex => {
      this.setState({ tokenvalid: false })
    })
  }
  /**
    *********************************************************
    Description: Handles updating the values in the field.
    Required props:  name, value
    limitations    : N/A.
    *********************************************************
**/
  handleFieldChange = (event) => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
      changedFieldName: [{ name: name, value: value }]
    })
  }

  onKeyPress = (event) => {
    let current = this.compsForEachStep[this.state.step - 1]
    let nextButton = current.components.filter(a => a.type === 'navformbutton')
    const { name, value } = event.target
    if (event.key === 'Enter') {
      let events = { currentTarget: { name: nextButton[0].options.name } }
      if (name === 'username' || name === 'password' || name === 'emailaddressusername' || name === 'emailaddressnextbtn1' ||
        name === 'confirmpassword' || name === 'createnewconfirmnewpassword' || name === 'emailaddresspassword')
        this.handleClick(events);
    }
  }

  /**
    *********************************************************
    Description: Handles jumping to the next form on clicking link or button.
    Required props:  step, name.
    limitations    : N/A.
    *********************************************************
**/

  goToNext = (step, jumptofalsestep, name, type, changepassword, isIWAAuth = false) => {
    let passwordvalid = true;
    const { components, compmap } = this.props
    const totalsteps = this.props.options.steps
    const currentform = this.compsForEachStep[this.state.step - 1]
    const { options } = currentform
    const { validations, crud, link, formdata } = options
    const { rest } = crud ? crud : {}
    const { post } = rest ? rest : {}
    const { username, password, emailaddressusername, emailaddresspassword, pincodevalue, newpassword, confirmpassword, passwordmessage, otpinput } = this.state;
    const { guid, routeid } = this.props.match.params;
    let message = '';
    if (changepassword) {
      if (newpassword === '' || newpassword === null || newpassword === undefined) {
        passwordvalid = false;
        message = 'Please enter new password'
        this.setState({ passwordmessage: message, changedFieldName: [{ name: 'passwordmessage', value: message }] })
      }
      else if (confirmpassword === '' || confirmpassword === null || confirmpassword === undefined) {
        passwordvalid = false;
        message = 'Please enter confirm password'
        this.setState({ passwordmessage: message, changedFieldName: [{ name: 'passwordmessage', value: message }] })
      }
      else if ((newpassword === '' && confirmpassword === '') || (newpassword === confirmpassword)) {
        let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
        passwordvalid = re.test(confirmpassword);
        if (!passwordvalid) {
          message = 'Password must contain minimum 10 characters, atleast 1 upper case,atleast one lower case , atleast one number and one special character'
        }
        this.setState({ passwordmessage: message, changedFieldName: [{ name: 'passwordmessage', value: message }] })

      }
      else {
        passwordvalid = false;
        message = 'Password  does not match';
        this.setState({
          passwordmessage: 'Password  does not match',
          changedFieldName: [{ name: 'passwordmessage', value: 'Password  does not match' }]
        })
      }
    }
    if(this.state.step && this.state.step == 3)
    {
    if(emailaddressusername =='' || emailaddressusername == undefined)
      {
        passwordvalid = false;
        message = 'Please provide Email Address'
        this.setState({ passwordmessage: message, changedFieldName: [{ name: 'passwordmessage', value: message }] })
      }
    }
    if (passwordvalid && rest && (type !== 'navlink')) {
      this.backdrop.backdropOpen();
      requestApi(rest, null, {
        Module: getEnvVariable('ModuleKey'), Email: emailaddresspassword, Portal: getEnvVariable("AccessPortal"),
        otpinput: otpinput, username: isIWAAuth ? 'IWA' : username, password: isIWAAuth ? 'IWA' : password, emailaddressusername: emailaddressusername,
        emailaddresspassword: emailaddresspassword, pincodevalue: pincodevalue, guid: guid, userID: routeid, confirmpassword: confirmpassword
      }, formdata, true).then(results => {
        this.backdrop.backdropClose();
        if (results && results.error) {
          this.setState({
            passwordmessage: results.error || 'Email invalid',
            //  step: jumptofalsestep ? jumptofalsestep : 'servicefail',
            changedFieldName: [{ name: 'passwordmessage', value: results.error }]
          })
        }
        if (results && !results.error) {
          if (link) {
            const token = results.token_type + ' ' + results.access_token
            localStorage.setItem('token', token)
            //document.cookie = "JWTAuthorization=" + results.access_token;
            //User login successful. Now set SSRS token.
            //Login to SSRS server
            //var ssrstokenparam = btoa('username=' + username + '&password=' + password + '&requester=app');
            //var reportauthurl = ReportServer + '/logon.aspx?token=' + ssrstokenparam;
            //window.open(reportauthurl, "_blank", "width=1,height=1");

            this.props.history.push(`${link.url}`)
            window.location.reload(true);
          }
          else if (results.validUser && results.pincodeRequired && !results.isFirstTimeLogin) {
            this.setState({
              step: 10,
              changedFieldName: []
            });
          }
          else {
            this.setState({
              step: step, //step
              changedFieldName: []
            });
          }
        }
      }).catch(error => {
        this.backdrop.backdropClose();
        error.then(err => {
          if (err.error) {
            if (err.error === "invalid_request") {
              let invalid = false;
              if (err.error_description === "The mandatory 'username' and/or 'password' parameters are missing.") {
                //  err.error_description = "Please provide username and Password"
                invalid = true
              }
              this.setState({ passwordmessage: err.error, changedFieldName: [{ name: 'passwordmessage', value: (invalid && err.error_description) ? 'Username/Password cannot be left blank' : err.error }] })
            }
            else if (err.error === "Password Expired") {
              this.setState({
                step: 12,
                changedFieldName: []
              });
            }
            else if (err.error === "Invalid PinCode") {
              this.setState({ passwordmessage: err.error, changedFieldName: [{ name: 'passwordmessage', value: err.error }] })
            }
            else if (err.error === "ACCOUNT_BLOCKED_LOGIN_ATTEMPTS_EXCEED" || err.error === "Too many invalid login attempts.") {
              this.setState({ passwordmessage: err.error_description, changedFieldName: [{ name: 'passwordmessage', value: err.error_description }] })
            }
            else if (err.error_description === "ACCOUNT_BLOCKED") {
              this.setState({ passwordmessage: err.error, changedFieldName: [{ name: 'passwordmessage', value: err.error }] })
            }
            else if (err.error_description === "access_denied" || err.error === "access_denied" ) {
              this.setState({ passwordmessage: err.error_description, changedFieldName: [{ name: 'passwordmessage', value: err.error_description }] })
            }
            else if (err.error === "USER_NO_ACCESS_CONTROLS") {
              this.setState({ passwordmessage: err.error, changedFieldName: [{ name: 'passwordmessage', value: 'Your account is not assigned any permissions. Please contact administrator' }] })
            }
            else if (err.error_description === "PASSWORD_UPDATED_LOGIN") {
              window.location.replace(err.error)
              // this.setState({
              //   step: 13,
              //   guid:err.error.slice(err.error.indexOf('resetpassword/') + ('resetpassword/').length),
              //   changedFieldName: []
              // });
            }
            else if (err.error_description === "WINDOWS_AUTH_INVALID") {
              this.setState({ passwordmessage: err.error, changedFieldName: [{ name: 'passwordmessage', value: err.error }] })
            }
            else if (err.error_description) {
              if (err.error_description === 'LOGIN_ATTEMPTS_EXCEEDED') {
                this.setState({
                  step: 11,
                  changedFieldName: []
                });
              }
              else if (err.error_description === 'NEW_IPADDRESS' || err.error_description === 'RANDOM_NUMBER') {
                this.setState({
                  step: jumptofalsestep,
                  changedFieldName: []
                });
              }
              else if (err.error_description === 'INACTIVE_USER') {
                this.setState({ passwordmessage: err.error, changedFieldName: [{ name: 'passwordmessage', value: err.error ? err.error : 'Invalid Information' }] })
              }
              else if (err.error_description === 'INVALID_PASSWORD') {
                this.setState({ passwordmessage: err.error, changedFieldName: [{ name: 'passwordmessage', value: err.error ? err.error : 'Invalid Information' }] })
              }
              else {
                this.setState({
                  passwordmessage: err.error + ':' + err.error_description,
                  changedFieldName: [{ name: 'passwordmessage', value: err.error ? err.error : 'Invalid Information' }]
                })
              }
            }
            else this.setState({ passwordmessage: err.error, changedFieldName: [{ name: 'passwordmessage', value: err.error }] })
          }
          else if (err.Password) {
            this.setState({ passwordmessage: err.Password[0], changedFieldName: [{ name: 'passwordmessage', value: err.Password[0] }] })
          }
          else this.setState({
            step: jumptofalsestep ? jumptofalsestep : 'servicefail',
            changedFieldName: []
          });
        })
      })
    }
    else if (passwordvalid && rest && (type === 'navformbutton')) {
      this.backdrop.backdropOpen();
      requestApi(rest, null, null, formdata, true).then(results => {
        this.backdrop.backdropClose();
        if (results) {
          if (link) {
            const token = results.token_type + ' ' + results.access_token
            localStorage.setItem('token', token)
            this.props.history.push(`${link.url}`)
            window.location.reload(true);

          }
          else {
            this.setState({
              step: step,
              changedFieldName: []
            });
          }
        }

      }).catch(error => {
        this.backdrop.backdropClose();
      })
    }
    else {
      if (!passwordvalid) {
        this.setState({ passwordmessage: message, changedFieldName: [{ name: 'passwordmessage', value: message }] })
      }
      else this.setState({
        step: step,
        changedFieldName: []
      });
    }

  }


  /**
  *********************************************************
  Description: renders the current component in the wizard flow for sigin.
  Required props:  currentformprops.
  limitations    : N/A.
  *********************************************************
**/


  render() {
    const { compmap, options, classes } = this.props;
    const { cheader, title, headerProps } = options;
    const { step } = this.state
    const currentformprops = this.compsForEachStep[this.state.step - 1]
    return (
      <Fragment>
        {cheader && <FormHeader title={title} closebutton={headerProps.closebutton} handleClose={() => this.props.onClose()} />}
        {step !== 'servicefail' && <SubForm {...currentformprops} key={currentformprops.key} compmap={compmap} onClick={this.handleClick} onKeyPress={this.onKeyPress} handleFieldChange={this.handleFieldChange} changedFieldName={this.state.changedFieldName} />}
        {step === 'servicefail' && <div className="row pl-4 text-danger">Unable to login at this time. Please try again or contact support team</div>}
        <div> <BackDrop ref={e => this.backdrop = e} /></div>

      </Fragment>
    )
  }
}



export default (withRouter(CSignUpForm));

