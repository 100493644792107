/**
      *********************************************************
      Description: Dropdown component. .
      Required props:  type,value,dropdownvalues.
      limitations    : N/A.
      *********************************************************
**/

import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { Fragment } from "react";
import Dropdown from '../../controls/components/dropdown';
import DefaultSettings from '../../controls/components/settings';
import Typography from '@material-ui/core/Typography'

export default function CFormDropdown(props) {
    const { handleFieldChange, dropdownvalues, edit, name, label, tooltip, disabled, error, required, value, defaultValue, helperText, transformProps, styles, className, multiple, contexthelp } = props;
    let displayname = '';


    if (edit === false) {
        if (transformProps) {
            let selected = ((dropdownvalues && dropdownvalues.length > 0) && dropdownvalues.find(each => {
                return (each[transformProps.value] && each[transformProps.value].toString()) === (value && value.toString())
            })) || {}
            displayname = (transformProps && selected[transformProps.name]) || ''
        }
        else {
            if (Array.isArray(value)) {
                displayname = value
            }
            else displayname = (dropdownvalues && value && (dropdownvalues.filter(each => { if (each.value === value) return each.name }))[0].name) || value
        }
        return <Fragment>
            {(tooltip || contexthelp) && <div > <Typography variant={'caption'} className="fw-bold" color={'textPrimary'} >{label}
                < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                    <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} onClick={() => DefaultSettings.showDrawer(contexthelp)} />
                </Tooltip>  </Typography>  <Typography className="text-secondary">{displayname}   </Typography>
            </div>}
            {!tooltip && <div > <Typography variant={'caption'} className="fw-bold" color={'textPrimary'}>{label}</Typography>   <Typography
                className="eps-content-wrap text-secondary">{displayname}   </Typography>
            </div>}
        </Fragment >
    }
    else {
        return <Dropdown name={name} label={label} extraProps={{
            transformProps: {
                label: (transformProps && transformProps.name) || 'name', value: (transformProps && transformProps.value) || 'value'
            }
        }} items={dropdownvalues} handleFieldValue={handleFieldChange}
            tooltip={tooltip} required={required} helperText={helperText} multiple={multiple} disabled={disabled} style={styles} className={className} error={error}
            contexthelp={contexthelp} value={value || defaultValue}
        />
        // return <Fragment>
        //     <FormControl disabled={editduplicate ? false : disabledstatus} error={error} required={required} fullWidth style={styles} className={className}>
        //         {(tooltip || contexthelp) && <InputLabel shrink htmlFor={htmlForLabel || label}>{label}< Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
        //             <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} onClick={() => DefaultSettings.showDrawer(contexthelp)} />
        //         </Tooltip></InputLabel>}
        //         {!(tooltip || contexthelp) && <InputLabel shrink htmlFor={htmlForLabel || label}>{label}</InputLabel>}
        //         <Select
        //             value={value || defval || ''}
        //             onChange={handleFieldChange}
        //             multiple={multiple ? multiple : false}
        //             inputProps={{
        //                 name: name,
        //                 id: id || name
        //             }}
        //             MenuProps={{ autoFocus: false }}
        //             onClose={() => setSearchText("")}
        //         >
        //             <ListSubheader>
        //                 <TextField
        //                     size="small"
        //                     // Autofocus on textfield
        //                     autoFocus
        //                     placeholder="Type to search..."
        //                     fullWidth
        //                     InputProps={{
        //                         startAdornment: (
        //                             <InputAdornment position="start">
        //                                 <SearchIcon />
        //                             </InputAdornment>
        //                         )
        //                     }}
        //                     onChange={(e) => setSearchText(e.target.value)}
        //                     onKeyDown={(e) => {
        //                         if (e.key !== "Escape") {
        //                             // Prevents autoselecting item while typing (default Select behaviour)
        //                             e.stopPropagation();
        //                         }
        //                     }}
        //                 />
        //             </ListSubheader>
        //             {(displayedOptions || allitems) && (displayedOptions||allitems).length > 0 && (displayedOptions||allitems).map((each, index) => {
        //                 const value = transformProps ? each[transformProps.value] : each.value
        //                 const name = transformProps ? each[transformProps.name] : each.name
        //                 const key = each.key || value
        //                 return <MenuItem key={key || ('item' + value)} value={value}>{name}</MenuItem>
        //             })}
        //         </Select>
        //         {helperText && <FormHelperText>{helperText}</FormHelperText>}
        //     </FormControl>
        // </Fragment>
    }
}