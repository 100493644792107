import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import MultipleDropdown from '../../core/controls/components/checkautocomplete';
import { GetData, PostData, PutData } from "../../core/controls/helpers/requests";
import { createRequest, requestApi } from '../../core/controls/helpers/rest';
import { snackbarMessage } from '../../core/actions';
import { getEnvVariable } from "../../modules/environmentalList";
import CFormCheckbox from '../../core/modules/form/cformcheckbox';
import FormHeader from '../../core/modules/form/cformheader';
import Template from '../../core/modules/template/template';


const multitransformProps = {
    transformProps: {
        label: "name",
        value: "id"
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

class NSTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabValue: 0,
            IsGrouped: "N",
            internalTemplateId: 0,
            externalTemplateId: 0,
            //updateRecord: this.props.match.params.routeid && (this.props.match.params.routeid == 'add' ? false : true),
            updateRecord: this.props.dataItem ? (!this.props.dataItem.ID ? false : true) : false,
            snackbarmessage: '', fields: []

        };
        this.transformProps = {
            "Y": true,
            "N": false,
            true: "Y",
            false: "N"
        }
        this.results = [
            { IsGrouped: "N", TemplateId: 0, UseType: "I", NotificationSettingId: this.props.dataItem.ID },
            { IsGrouped: "N", TemplateId: 0, UseType: "E", NotificationSettingId: this.props.dataItem.ID }
        ]
        this.url1 = this.props.dataItem ? getEnvVariable('Notification')+`/api/NotificationTemplates/EventNotificationtemplate/${this.props.dataItem.ID}` : getEnvVariable('Notification')+`/api/NotificationTemplates/EventNotificationtemplate/${this.props.match.params.routeid}`
        this.url2 = this.props.dataItem ? getEnvVariable('Notification')+`/api/NotificationTemplates/SaveEventNotificationTemplate/${this.props.dataItem.ID}` : getEnvVariable('Notification')+`/api/NotificationTemplates/SaveEventNotificationTemplate/${this.props.match.params.routeid}`
        this.TemplateForm = React.createRef()
    }
    handleIsGroupChange = (event) => {
        const value = this.transformProps ? this.transformProps[event.target.checked] : event.target.value;
        this.setState({
            [event.target.name]: value,
        }, () => {
            this.results[0].IsGrouped = value
        })
    }
    handleInternalTemplateId = (data) => {
        if (this.state.internalTemplateId !== data.id) {
            this.setState({
                "internalTemplateId": data.id,
            }, () => {
                console.log(this.results);
                this.results[0].TemplateId = data.id;
                this.handleSave();
            })

        } else {
            //this.props.snackbarMessage(' Saved Successfully', 'success')

        }
    }

    handleExternalTemplateId = (data) => {
        if (this.state.externalTemplateId !== data.id) {
            this.setState({
                "externalTemplateId": data.id,
            }, () => {
                this.results[1].TemplateId = data.id;
                this.handleSave();
            })
        } else {
          //  this.props.snackbarMessage(' Saved Successfully', 'success')

        }
    }

    fetchData(requestType, url, queryParam, body) {

        const queryStr = queryParam != null ? `${url}?${queryParam}` : `${url}`

        if (requestType == "GET") {
            return GetData(queryStr);
        }
        else if (requestType == "POST") {
            return PostData(queryStr, body);
        }
        else if (requestType == "PUT")
            return PutData(queryStr, body);
    }


    componentDidMount() {
        if (this.state.updateRecord) {
            this.fetchData('GET', this.url1, null, null).then(response => {
                if (response) {
                    console.log('ok response', response);
                    let data = response;
                    if (data.length > 0) {
                        this.results = data;
                        this.setState({
                            internalTemplateId: data[0].TemplateId,
                            IsGrouped: data[0].IsGrouped,
                            externalTemplateId: data[1].TemplateId,
                        }, () => {
                            this.getBusiessRules(this.props.extraProps.submoduleselectedtables)
                        })
                    }
                    return response
                }
                else {
                    throw response.json();
                }
            }).then(data => {
                if (data.length > 0) {
                    this.results = data;
                    this.setState({
                        internalTemplateId: data[0].TemplateId,
                        IsGrouped: data[0].IsGrouped,
                        externalTemplateId: data[1].TemplateId,
                    }, () => {
                        this.getBusiessRules(this.props.extraProps.submoduleselectedtables)
                    })
                }
            }).catch(x => console.log(x));
        }
    }

    getBusiessRules(sidebarItems) {
        if (this.state.updateRecord) {
            let sideUrl = '';
            sidebarItems && (sidebarItems !== '') && sidebarItems.split(',') && sidebarItems.split(',').length > 0 && sidebarItems.split(',').map(each => {
                sideUrl = sideUrl + '&SideBarItems=' + (this.props.extraProps.sideBarTablesRecords.find(item => (item.value).toString() === each)).name
            })
            const read = [
                {
                    url: getEnvVariable('Sidebar')+`/api/BusinessRules/Builder/Template?SitePermitTypeId=${this.props.extraProps.SitePermitTypeId}${sideUrl}`,
                    type: 'get'
                },
            ]
            Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(res => {
                let [fields] = res
                this.setState({
                    fields: fields.map(res => { res.name = res.name.replace(" ", ""); return res })
                })
            }).catch(x => console.log(x));
        }
    }


    //Updated by vijaya: Added snack bar to display the messages.
    handleSaveWithTemplate = (event) => {

        console.log(this.state.results);
        this.TemplateForm.current.saveTemplate();
    }

    //code changed
    handleSave = (event) => {
        console.log(this.state.results);
        this.fetchData('PUT', this.url2, null, this.results).then(response => {
            if (response) {
                return 'success'
            }
        }).then(data => {
            this.setState({
                results: data
            })
        })

    }

    handleChange = (event, value) => {
        var tabSelection = value == 0 ? 0 : 1;
        this.setState({ tabValue: tabSelection });
    }

    handlemultidropdown = (tags) => {
        let resultdata = tags.target.value
        let resultdatasplit = resultdata.split(",")
        let selectedRecords = this.props.extraProps.sideBarTablesRecords.filter(a => resultdatasplit.includes(a.id.toString()))
        this.setState({
            SideBarTables: resultdata,
            submoduleselectedtables: resultdata,
            submoduleselectedtablesresults: selectedRecords,
        }, () => {
            this.getBusiessRules(resultdata)
        })
    }

    noExtNotification = ["INTERNAL_STATUS_TIMER_NOTIFICATION","APPEAL_STATUS_TIMER_NOTIFICATION","EXTERNAL_STATUS_TIMER_NOTIFICATION"]

    render() {
        console.log(this.state, this.props)
        let hasExternalNotify = (!this.props.extraProps.hideexternal && this.props.extraProps.NotificationType != 'SCHEDULED_NOTIFICATION' && !this.noExtNotification.includes(this.props.extraProps.EventOn)) ;
        let templabel = hasExternalNotify ? "Internal" : "Internal";
        return (
            <Fragment>

                <Paper>
                    <FormHeader handleSave={this.handleSaveWithTemplate} savebutton={true} designprops={true} />
                    <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary">
                        <Tab label={templabel} />
                        { hasExternalNotify && <Tab label="External" />}
                    </Tabs>
                    <TabPanel className=" col-sm-12 p-1" value={this.state.tabValue} index={0}>
                        <Typography className="">
                            {(this.props.extraProps.NotificationType === 'EVENT_NOTIFICATION' || this.props.extraProps.NotificationType === 'BUSINESS_RULES_NOTIFICATION') && <CFormCheckbox value={this.state.IsGrouped} handleFieldChange={this.handleIsGroupChange}
                                name="IsGrouped" transformProps={this.transformProps} label="Group Notifications" checked="IsGrouped" />}
                             {(this.props.extraProps.NotificationType === 'EVENT_NOTIFICATION' || this.props.extraProps.NotificationType === 'BUSINESS_RULES_NOTIFICATION') && <div className="mt-2 pl-3">
                                <MultipleDropdown
                                    label="Select table(s)"
                                    name="SideBarTables"
                                    items={this.props.extraProps.sideBarTablesRecords}
                                    handleFieldValue={(e) => this.handlemultidropdown(e)}
                                    value={this.state.submoduleselectedtables || ''}
                                    extraProps={multitransformProps}
                                    ismulti={true}
                                />
                            </div>}
                            <Template ref={this.TemplateForm} moduleId={this.props.extraProps.SubModuleKey}
                                sitePermitTypeId={this.props.extraProps.SitePermitTypeId}
                                handleTemplateId={this.handleInternalTemplateId}
                                templateId={this.state.internalTemplateId}
                                isadmin={true}
                                hasSubject={true}
                                fields={this.state.fields || []}
                                sideBarTables={this.state.submoduleselectedtablesresults || []}
                            />
                        </Typography>
                    </TabPanel>
                    <TabPanel className=" col-sm-12 p-1" value={this.state.tabValue} index={1} >
                        <Typography>
                            <Template ref={this.TemplateForm} moduleId={this.props.extraProps.SubModuleKey}
                                sitePermitTypeId={this.props.extraProps.SitePermitTypeId}
                                handleTemplateId={this.handleExternalTemplateId}
                                templateId={this.state.externalTemplateId}
                                isadmin={true}
                                hasSubject={true}
                                fields={this.state.fields || []}
                                sideBarTables={this.state.submoduleselectedtablesresults || []}
                            />
                        </Typography>
                    </TabPanel>
                </Paper>
            </Fragment>
        )
    }
}
const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

NSTemplate = connect(null, mapActions)(NSTemplate)
export default withRouter(NSTemplate)