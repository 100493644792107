import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(3),
//     },
//   },
// }));

function ActionAlerts(props) {
  const { severity, isAction, style, icon, buttonLabels } = props;
  //const classes = useStyles();
  const [message, setMessage] = useState(props.message)
  const [showalertcond, setShowAlertCond] = useState(true)
  const handleAlertActions = (val) => {
    setShowAlertCond(false)
    props.handleAlerts(val)
  }
  useEffect(() => {
    setMessage(props.message)
  }, [props.message])
  return (
    <div className='py-1'>
      {showalertcond && <Alert severity={severity} style={style}
        action={
          <Fragment>
            {isAction && <Button onClick={() => props.handleEdit()} color="inherit" size="small">              <b> Yes </b>            </Button>}
            {isAction && <Button onClick={() => props.closeAlert()} color="inherit" size="small">              <b>No</b>            </Button>}
            {buttonLabels && buttonLabels.length > 0 && buttonLabels.map(each => {
              return <Button onClick={() => handleAlertActions(each.value)} color="inherit" size="small"><b>{each.name}</b> </Button>
            })}
          </Fragment>
        }
      >
        <div> <b>{message}</b> </div>
      </Alert>}
    </div >
  );
}

export default ActionAlerts