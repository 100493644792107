import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import CodeIcon from '@material-ui/icons/Code';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import EventIcon from '@material-ui/icons/OfflineBolt';
import React, { useEffect, useState } from 'react';
import CalcualtedQueryBuilder from './mainquerybuilder';
import Uploaded from './uploaded';
import QueryBuilder from 'react-querybuilder';
import { getEnvVariable } from '../../../../../modules/environmentalList';
import MultiCheckAutoComplete from '../../../../controls/components/checkautocomplete';
import { createRequest, requestApi } from '../../../../controls/helpers/rest';
import Events from './events';
import Fields from './fields';
import Switch from '../../../../controls/components/switch';

const extraProps = {
    transformProps: {
        label: 'Name',
        value: 'Value'
    },
}

export default function StaticFilter({ handleFieldValue, result, handleDynamicResult, fields, section = null, isSidebar = false, helperText }) {
    const [internalroles, setInternalRoles] = useState([])
    const [externalroles, setExternalroles] = useState([])

    useEffect(() => {
        const read = [
            {
                url: getEnvVariable('Admin') + `/api/Roles/All`,
                type: 'get'
            },
            {
                url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=EXTERNAL_ROLES&domainid=0`,
                type: 'get'
            },

        ]

        Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(response => {
            const [internalroleset, externalroleset] = response;
            setExternalroles(externalroleset)
            setInternalRoles(internalroleset)
        })
    }, [])

    return (<div className="mt-1 mb-1">
        <Stepper orientation="vertical">
            <Step key={"Roles"} active={true}>
                <StepLabel StepIconComponent={CodeIcon}>{"Roles"}</StepLabel>
                <StepContent>
                    <MultiCheckAutoComplete className="col-sm-12" label={'Internal'} name={'InternalRoles'} ismulti={true} value={result.InternalRoles} items={internalroles || []} handleFieldValue={(evt) => handleFieldValue(evt)} />
                    <MultiCheckAutoComplete className="col-sm-12" label={'External'} name={'ExernalRoles'} ismulti={true} value={result.ExernalRoles} extraProps={extraProps}
                        items={externalroles || []} handleFieldValue={(evt) => handleFieldValue(evt)} />
                </StepContent>
            </Step>
            <Step key={"Query"} active={true}>
                <StepLabel StepIconComponent={CodeIcon}>{"Query"}</StepLabel>
                <StepContent>
                    <Switch name='isCalcualtedQuery' label="is Calculated Query?" value={(result && result.isCalcualtedQuery) || 'Y'}
                        handleFieldValue={handleFieldValue} extraProps={{
                            transformProps: {
                                "Y": true,
                                "N": false,
                                true: "Y",
                                false: "N"
                            }
                        }} /><br />
                    {!isSidebar && <div className="p-1" ><Uploaded result={result} handleDynamicResult={handleDynamicResult} /></div>}
                    {result && result.isCalcualtedQuery === 'N' && <small className='col-12'>
                        <QueryBuilder fields={fields} query={result && result.query || null} onQueryChange={(query) =>
                            handleFieldValue({ target: { value: query, name: 'query', checked: false }, currentTarget: { dataset: null } })}
                            showNotToggle showCloneButtons showLockButtons enableDragAndDrop />
                        {helperText && <FormHelperText>{helperText || ''}</FormHelperText>}
                    </small>}
                    {result && result.isCalcualtedQuery === 'Y' && <small className='col-12'>
                        <CalcualtedQueryBuilder className="col-12" fields={fields || []} name='query'
                            query={result && result.query || null}
                            onQueryChange={(query) => handleFieldValue({
                                target: { value: query, name: 'query', checked: false },
                                currentTarget: { dataset: null }
                            })} />
                    </small>}
                </StepContent>
            </Step>
            <Step key={"Events"} active={true}>
                <StepLabel StepIconComponent={EventIcon}>{"Events"}</StepLabel>
                <StepContent>
                    <Events result={result} section={section} handleFieldValue={handleFieldValue} isSidebar={isSidebar} />
                </StepContent>
            </Step>
            <Step key={"Results(Valid / Invalid)"} active={true}>
                <StepLabel StepIconComponent={FiberManualRecordIcon}>{"Results(Valid / Invalid)"}</StepLabel>
                <StepContent>
                    <Paper elevation={3} >
                        <Fields result={result} fields={fields} handleFieldValue={handleFieldValue} isSidebar={isSidebar} />
                    </Paper>
                </StepContent>
            </Step>
        </Stepper>
    </div>)
}//.filter(each => { if (each.isVirtual === false) return true; else return false })