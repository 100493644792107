
const sidebarReducer = (state = null, action) => {
    switch (action.type) {
        case 'STORE_MODULES_DATA':
            return { ...state, ['modules']: action.modules }
        case 'BUSINESS_RULES_ONEVENT':
            return { ...state, ['effects']: action.effects }
        default:
            return state;
    }
}

export default sidebarReducer