export const NewApplicationSidebar = {
       'WRSAPPLICATIONS': {
        "type": "applicationdrawer",
        "order": 2,
        "key": "drawer",
        "options": {
            "icon": "alarm",
            "open": true,
            "iconsonly": true,
            anchor: 'right',
           // orientation: 'vertical',
            "items": [
                {
                    "type": "menu",
                    "title": "Attachments",
                    "icon": "fa fa-paperclip",
                    "materialicon": "attach_file",
                    "style": {
                        'color': '#f48fb1'
                    },
                    "link": [
                        {
                            "routes": [
                                {
                                    "route": "/Records",
                                    "routeParams": "key",
                                },
                                {
                                    "route": "",
                                    "routeParams": "routeid"
                                },
                                {
                                    "route": "",
                                    "routeParams": "routeid1"
                                },
                                {
                                    "route": "/attachments",
                                },
                            ],
                        }
                    ],
                    "key": "attachments"
                },
                {
                    "type": "menu",
                    "title": "Images",
                    "icon": "fa fa-files-o",
                    "materialicon": "wallpaper",
                    "style": {
                        'color': '#ffb300'
                    },
                    "link": [
                        {
                            "routes": [
                                {
                                    "route": "/Records",
                                    "routeParams": "key",
                                },
                                {
                                    "route": "",
                                    "routeParams": "routeid"
                                },
                                {
                                    "route": "",
                                    "routeParams": "routeid1"
                                },
                                {
                                    "route": "/images",
                                },
                            ],
                        }
                    ],
                    key: 'images'
                },
                {
                    "type": "menu",
                    "title": "Notification Log",
                    "icon": "fa fa-envelope-o",
                    "materialicon": "notifications_active",
                    "style": {
                        'color': '#40c4ff'
                    },
                    "link": [
                        {
                            "routes": [
                                {
                                    "route": "/Records",
                                    "routeParams": "key",
                                },
                                {
                                    "route": "",
                                    "routeParams": "routeid"
                                },
                                {
                                    "route": "",
                                    "routeParams": "routeid1"
                                },
                                {
                                    "route": "/notificationlog",
                                },
                            ],
                        }
                    ],
                    "key": "notificationlog"
                },
                // {
                //     "type": "menu",
                //     "title": "Existing Land Uses",
                //     "icon": "fa fa-comments-o",
                //     "materialicon": "landscape",
                //     "style": {
                //         'color': '#00BFA5'
                //     },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/existinglanduse",
                //                 },
                //             ],
                //         }
                //     ],
                //     "key": "existinglanduse"
                // },
                // {
                //     "type": "menu",
                //     "title": "Proposed Land Uses",
                //     "icon": "fa fa-comments-o",
                //     "materialicon": "eject",
                //     "style": {
                //         'color': '#96332c'
                //     },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/proposedlanduse",
                //                 },
                //             ],
                //         }
                //     ],
                //     "key": "proposedlanduse"
                // },
                // {
                //     "type": "menu",
                //     "title": "Contacts",
                //     "icon": "fa fa-user-circle-o",
                //     "materialicon": "contacts",
                //     "style": {
                //         'color': '#64b5f6'
                //     },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/contacts",
                //                 },
                //             ],
                //         }
                //     ],
                //     key: 'contacts'
                // },
                // {
                //     "type": "menu",
                //     "title": "Tax Lots",
                //     "icon": "fa fa-comments-o",
                //     "materialicon": "beenhereoutlined",

                //     "style": {
                //         'color': '#d95f09'
                //     },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/taxlots",
                //                 },
                //             ],
                //         }
                //     ],
                //     "key": "taxlots"
                // },
               

                // {
                //     "type": "menu",
                //     "title": "Collaboration",
                //     "icon": "fa fa-comments-o",
                //     "materialicon": "group_work",
                //     "style": {
                //         'color': '#2c3f7d'
                //     },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/collaboration",
                //                 },
                //             ],
                //         }
                //     ],
                //     "key": "collaboration"
                // },
                {
                    "type": "menu",
                    "title": "Notes",
                    "icon": "fa fa-sticky-note-o",
                    "materialicon": "note",
                    "style": {
                        'color': '#b26a00'
                    },
                    //     "link": [
                    //         {
                    //             "link": "/Record",
                    //             //"linkParams": "routeid1"
                    //         },
                    //         {
                    //             "type": 'sublink',
                    //             "link": "/notes",

                    //         }
                    //     ],
                    //     "key": "notes"
                    // },
                    "link": [
                        {
                            "routes": [
                                {
                                    "route": "/Records",
                                    "routeParams": "key",
                                },
                                {
                                    "route": "",
                                    "routeParams": "routeid"
                                },
                                {
                                    "route": "",
                                    "routeParams": "routeid1"
                                },
                                {
                                    "route": "/notes",
                                },
                            ],
                        }
                    ],
                    "key": "notes"
                },
                {
                    "type": "menu",
                    "title": "History",
                    "icon": "fa fa-files-o",
                    "materialicon": "history",
                    "style": {
                        'color': '#f44336'
                    },
                    "link": [
                        {
                            "routes": [
                                {
                                    "route": "/Records",
                                    "routeParams": "key",
                                },
                                {
                                    "route": "",
                                    "routeParams": "routeid"
                                },
                                {
                                    "route": "",
                                    "routeParams": "routeid1"
                                },
                                {
                                    "route": "/history",
                                },
                            ],
                        }
                    ],
                    key: 'history'
                },
               
                // {
                //     "type": "menu",
                //     "title": "Discussions",
                //     "key": "discussions",
                //     "materialicon": "chat",
                //     "style": {
                //         'color': '#607d8b'
                //     },
                //     //     "link": [
                //     //         {
                //     //             "link": "/Record",
                //     //             //"linkParams": "routeid1"
                //     //         },
                //     //         {
                //     //             "type": 'sublink',
                //     //             "link": "/discussions",
                //     //             "regionidParams": "/",
                //     //             "appcontactidParams": "/"
                //     //         }
                //     //     ],
                //     //     "icon": "fa fa-file-text",
                //     // },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/discussions",
                //                 },
                //             ],
                //         }
                //     ],
                //     "icon": "fa fa-file-text",
                // },
                // {
                //     "type": "menu",
                //     "title": "Notices",
                //     "icon": "fa fa-building",
                //     "materialicon": "tab",
                //     "style": {
                //         'color': '#8bc34a'
                //     },
                //     //     "link": [
                //     //         {
                //     //             "link": "/Record"
                //     //         },
                //     //         {

                //     //             "type": 'sublink',
                //     //             "link": "/notices",
                //     //             "appParams": "/"
                //     //         }
                //     //     ],
                //     //     // "icon": "fa fa-file-text",
                //     //     "key": "notices"
                //     // },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/notices",
                //                 },
                //             ],
                //         }
                //     ],
                //     //hidecondition: [{ Name: "isViolation", Value: "Y"},{prevcondition: 'or', Name: "isviolation", Value: "Y", getfrom :"localstorage"}],
                //     "key": "notices"
                // },
              
               
              
                // {
                //     "type": "menu",
                //     "title": "Property Group",
                //     "icon": "fa fa-comments-o",
                //     "materialicon": "home_work",
                //     "style": {
                //         'color': '#7b1fa2'
                //     },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/propertydetails",
                //                 },
                //             ],
                //         }

                //     ],
                //     "key": "propertydetails"
                // },
                // {
                //     "type": "menu",
                //     "title": "Change of Use",
                //     "icon": "fa fa-comments-o",
                //     "materialicon": "shuffle",
                //     hide: true,
                //     "style": {
                //         'color': '#607d8b'
                //     },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/changeofuse",
                //                 },
                //             ],
                //         }
                //     ],
                //     check: {
                //         condition: "AND",
                //         rules: [{ 'name': 'PEAK_HOUR_TRIPS_PKHR_', type: 'is not null', rules: [] },
                //         { 'name': 'SITE_ADT', type: 'is not null', rules: [] },
                //         { 'name': 'APPROACH_ADT', type: 'is not null', rules: [] },
                //         { 'name': 'PROCESS_TYPE', type: 'not in', value: '3,7,8,9', rules: [] }
                //         ],
                //     },
                //     "key": "changeofuse"
                // },
                // {
                //     "type": "menu",
                //     "title": "Completeness Review",
                //     "icon": "fa fa-comments-o",
                //     "materialicon": "assignment_turned_in",
                //     "style": {
                //         'color': '#0c4986'
                //     },
                //     "link": [{
                //         "routes": [
                //             {
                //                 "route": "/Records",
                //                 "routeParams": "key",
                //             },
                //             {
                //                 "route": "",
                //                 "routeParams": "routeid"
                //             },
                //             {
                //                 "route": "",
                //                 "routeParams": "routeid1"
                //             },
                //             {
                //                 "route": "/completenesscheck",
                //             },
                //         ],
                //     }
                //     ],
                //     "key": "completenesscheck"
                // },
                // {
                //     "type": "menu",
                //     "title": "Inspection",
                //     "icon": "fa fa-files-o",
                //     "materialicon": "policy",
                //     "style": {
                //         'color': '#827717'
                //     },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/inspections",
                //                 },
                //             ],
                //         }
                //     ],
                //     "key": "inspections"
                // },
                // {
                //     "type": "menu",
                //     "title": "Site Visit",
                //     "icon": "fa fa-comments-o",
                //     "materialicon": "work_outlineIcon",
                //     "style": {
                //         'color': '#bf0b3e'
                //     },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/sitevisit",
                //                 },
                //             ],
                //         }
                //     ],
                //     "key": "sitevisit"
                // },
                // {
                //     "type": "menu",
                //     "title": "Design",
                //     "icon": "fa fa-comments-o",
                //     "materialicon": "category",
                //     "style": {
                //         'color': '#f9a825'
                //     },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/design",
                //                 },
                //             ],
                //         }

                //     ],
                //     "key": "design"
                // },
                // {
                //     "type": "menu",
                //     "title": "Issue Permit",
                //     "icon": "fa fa-comments-o",
                //     "materialicon": "credit_score",
                //     "style": {
                //         'color': '#0c4986'
                //     },
                //     "link": [{
                //         "routes": [
                //             {
                //                 "route": "/Records",
                //                 "routeParams": "key",
                //             },
                //             {
                //                 "route": "",
                //                 "routeParams": "routeid"
                //             },
                //             {
                //                 "route": "",
                //                 "routeParams": "routeid1"
                //             },
                //             {
                //                 "route": "/issuepermit",
                //             },
                //         ],
                //     }
                //     ],
                //     "key": "issuepermit"
                // },
                // {
                //     "type": "menu",
                //     "title": "Cancel Permit",
                //     "icon": "fa fa-comments-o",
                //     "materialicon": "cancel",
                //     "style": {
                //         'color': '#FF0000'
                //     },
                //     "link": [{
                //         "routes": [
                //             {
                //                 "route": "/Records",
                //                 "routeParams": "key",
                //             },
                //             {
                //                 "route": "",
                //                 "routeParams": "routeid"
                //             },
                //             {
                //                 "route": "",
                //                 "routeParams": "routeid1"
                //             },
                //             {
                //                 "route": "/cancelpermit",
                //             },
                //         ],
                //     }
                //     ],
                //     "key": "cancelpermit"
                // },

                // {
                //     "type": "menu",
                //     "title": "Insurance and Bonds",
                //     "icon": "fa fa-comments-o",
                //     "materialicon": "account_balance_wallet",
                //     "style": {
                //         'color': '#ea156d'
                //     },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/insuranceandbonds",
                //                 },
                //             ],
                //         }
                //     ],
                //     "key": "InsuranceAndBond"
                // },

              

             
              

              
                // {
                //     "type": "menu",
                //     "title": "Supplemental Items",
                //     "icon": "fa fa-comments-o",
                //     "materialicon": "playlist_add_check_circle",
                //     "style": {
                //         'color': '#d500f9'
                //     },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/supplemental",
                //                 },
                //             ],
                //         }

                //     ],
                //     "key": "supplemental"
                // },
               
                // {
                //     "type": "menu",
                //     "title": "Description Of Construction",
                //     "icon": "fa fa-files-o",
                //     "materialicon": "featured_play_list",
                //     "style": {
                //         'color': '#6c3575'
                //     },
                //     "link": [
                //         {
                //             "routes": [
                //                 {
                //                     "route": "/Records",
                //                     "routeParams": "key",
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid"
                //                 },
                //                 {
                //                     "route": "",
                //                     "routeParams": "routeid1"
                //                 },
                //                 {
                //                     "route": "/descriptionofconstruction",
                //                 },
                //             ],
                //         }
                //     ],
                //     key: 'descriptionofconstruction'
                // },
                // {
                //     "type": "menu",
                //     "title": "Workflow",
                //     "icon": "fa fa-files-o",
                //     "materialicon": "call_split",
                //     "style": {
                //         'color': '#3e2723'
                //     },
                //     button: {
                //         type: 'slidingbutton',
                //         "materialicon": "call_split",
                //         "key": "workflowbutton",
                //         "options": {
                //             "icon": true,
                //             "name": "Workflow",
                //             "customstyling": true,
                //             "buttonprops": {
                //                 "text": "Workflow"
                //             }
                //         },
                //         components: [
                //             NewApplicationWorkflow
                //         ]
                //     },
                //     // "link": [
                //     //     {
                //     //         "link": "/Administration/applicationreview/",
                //     //         "params": "applicationnumber"
                //     //     },
                //     //     {
                //     //         "type": 'sublink',
                //     //         "link": "/Workflow",

                //     //     }
                //     //     // /Administration/applicationreview/{applicationnumber}/Workflow
                //     // ],
                //     key: 'workflow'
                // },
               
              

                // {
                //     "type": "menu",
                //     "title": "Internal Comments",
                //     "icon": "fa fa-comments-o",
                //     "materialicon": "people_alt",
                //     "style": {
                //         'color': '#9fa8da'
                //     },
                //     "link": [
                //         {
                //             "link": "/Administration/applicationreview",
                //             //"linkParams": "routeid1"
                //         },
                //         {
                //             "type": 'sublink',
                //             "link": "/internalcomments",

                //         }
                //     ],
                //     "key": "internalcomments"
                // },

              
              
              



            ]
        }
    },
 

}