
import { getEnvVariable } from '../../../modules/environmentalList';
import { getBasePath, GetFetchProps, GetHeaders, getProfile } from '../../modules/header';
import DefaultSettings from '../components/settings';
//import { lensPath } from 'ramda';

// const Insert503 = (url) => {
//     if (localStorage.getItem('503')) {
//         let items = JSON.parse(localStorage.getItem('503'));
//         if (items[url]) {
//             items[url] = items[url] + 1;
//         }
//         else {
//             items[url] = 1;
//         }
//         localStorage.setItem('503', JSON.stringify(items))
//         return items[url]
//     }
//     else {
//         let items = {}
//         items[url] = 1;
//         localStorage.setItem('503', JSON.stringify(items))
//         return items[url]
//     }
// }

// const Delete503 = (url) => {
//     if (localStorage.getItem('503')) {
//         let items = JSON.parse(localStorage.getItem('503'))
//         if (items[url]) {
//             delete items[url]
//             localStorage.setItem('503', JSON.stringify(items))
//         }
//     }
// }


export const asyncgetData = async (url, isLoader = true) => {
    if (DefaultSettings.getLoader() != null)
        DefaultSettings.getLoader().add();
    try {
        const response = await fetch(`${url}`, {
            method: 'GET',
            ...GetFetchProps()
        })
        if (response.status === 401 || response.status === 403) {
            if (response.status === 401) {
                window.location.replace(getEnvVariable('AuthorizationScreen') + 'user/logout');;
                throw response.json();
            }
            else console.error(response)
        }
        if (response.status > 200) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            //if (DefaultSettings.getAlert() != null)
            //  DefaultSettings.getAlert().show('Error Occurred,Please contact Admin', 'error');
        }
        const data = await response.json();
        if (data) {
            if (DefaultSettings.getLoader() != null && isLoader)
                DefaultSettings.getLoader().remove();
            return data
        }
    } catch (error) {
        if (DefaultSettings.getLoader() != null && isLoader)
            DefaultSettings.getLoader().remove();
        throw error;
    }
}

export const GetData = async (url, alert = false, message = '', isLoader = true, loaderMessage = '') => {
    if (DefaultSettings.getLoader() != null && isLoader)
        DefaultSettings.getLoader().add(loaderMessage);
    try {
        const response = await fetch(`${url}`, {
            method: 'GET',
            ...GetFetchProps()
        })
        if (response.status === 400) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            if (DefaultSettings.getAlert() != null)
                DefaultSettings.getAlert().show("Verify Validations/Errors" || 'Error Occurred', 'error');
            throw response.json();
        }
        if (response.status === 401 || response.status === 403) {
            if (response.status === 401) {
                window.location.replace(getEnvVariable('AuthorizationScreen') + 'user/logout');;
                throw response.json();
            }
            else console.error(response)
        }
        if (response.status > 200) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            //if (DefaultSettings.getAlert() != null)
            //  DefaultSettings.getAlert().show('Error Occurred,Please contact Admin', 'error');
        }
        const data = await response.json();
        if (data && response.status === 200) {
            if (DefaultSettings.getLoader() != null && isLoader)
                DefaultSettings.getLoader().remove();
            if (DefaultSettings.getAlert() != null && alert)
                DefaultSettings.getAlert().show(message, 'success');
            return data
        }
    } catch (error) {
        if (DefaultSettings.getLoader() != null && isLoader)
            DefaultSettings.getLoader().remove();
        throw error;
    }
}



export const PostData = async (url, bodyval, alert = false, message = 'Saved Successfully') => {
    if (DefaultSettings.getLoader() != null)
        DefaultSettings.getLoader().add();
    try {
        const response = await fetch((`${url}`), {
            method: 'POST',
            ...GetFetchProps(),
            body: JSON.stringify(bodyval)

        })
        if (response.status === 400) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            if (DefaultSettings.getAlert() != null)
                DefaultSettings.getAlert().show("Verify Validations/Errors" || 'Error Occurred', 'error');
            throw response.json();
        }
        if (response.status === 401 || response.status === 403) {
            if (response.status === 401) {
                window.location.replace(getEnvVariable('AuthorizationScreen') + 'user/logout');;
                throw response.json();
            }
            else console.error(response)
        }
        if (response.status > 200) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            //if (DefaultSettings.getAlert() != null)
            //  DefaultSettings.getAlert().show('Error Occurred,Please contact Admin', 'error');
        }
        const data = await response.json();
        if (data && response.status === 200) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            if (DefaultSettings.getAlert() != null && !alert)
                DefaultSettings.getAlert().show(message, 'success');
            return data
        }
    } catch (error) {
        if (DefaultSettings.getLoader() != null)
            DefaultSettings.getLoader().remove();
        throw error;
    }
}
export const PostDataNoBody = async (url, message = 'Saved Successfully') => {
    if (DefaultSettings.getLoader() != null)
        DefaultSettings.getLoader().add();
    try {
        const response = await fetch((`${url}`), {
            method: 'POST',
            ...GetFetchProps()
        })
        if (response.status === 400) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            if (DefaultSettings.getAlert() != null)
                DefaultSettings.getAlert().show("Verify Validations/Errors" || 'Error Occurred', 'error');
            throw response.json();
        }
        if (response.status === 401 || response.status === 403) {
            if (response.status === 401) {
                window.location.replace(getEnvVariable('AuthorizationScreen') + 'user/logout');;
                throw response.json();
            }
            else console.error(response)
        }
        if (response.status > 200) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
        }
        const data = await response.json();
        if (data && response.status === 200) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            if (DefaultSettings.getAlert() != null && alert)
                DefaultSettings.getAlert().show(message, 'success');
            return data
        }
    } catch (error) {
        if (DefaultSettings.getLoader() != null)
            DefaultSettings.getLoader().remove();
        throw error;
    }
}

export const PostFormData = async (url, bodyval, noHeader = false) => {
    let formData = new URLSearchParams();
    const bodyvalkeys = Object.keys(bodyval)
    bodyvalkeys.forEach(each => {
        formData.set(each, bodyval[each])
    })
    if (DefaultSettings.getLoader() != null)
        DefaultSettings.getLoader().add();
    try {
        const response = await fetch((`${url}`), {
            method: 'POST',
            ...GetFetchProps(true, noHeader),
            body: formData
        })
        if (response.status === 400) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            // if (DefaultSettings.getAlert() != null)
            //     DefaultSettings.getAlert().show("Verify Validations/Errors" || 'Error Occurred', 'error');
            throw response.json();
        }
        if (response.status === 401 || response.status === 403) {
            if (response.status === 401) {
                window.location.replace(getEnvVariable('AuthorizationScreen') + 'user/logout');;
                throw response.json();
            }
            else console.error(response)
        }
        if (response.status > 200) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
        }
        if (response.status === 200) {
            const data = await response.json();
            if (data) {
                if (DefaultSettings.getLoader() != null)
                    DefaultSettings.getLoader().remove();
                return data
            }
        }
    } catch (error) {
        if (DefaultSettings.getLoader() != null)
            DefaultSettings.getLoader().remove();
        throw error;
    }
}

export const PutData = async (url, bodyval, alert = false, message = 'Updated Successfully') => {
    if (bodyval) {
        if (DefaultSettings.getLoader() != null)
            DefaultSettings.getLoader().add();
        try {
            const response = await fetch((`${url}`), {
                method: 'PUT',
                ...GetFetchProps(),
                body: JSON.stringify(bodyval)

            })
            if (response.status === 400) {
                if (DefaultSettings.getLoader() != null)
                    DefaultSettings.getLoader().remove();
                if (DefaultSettings.getAlert() != null)
                    DefaultSettings.getAlert().show("Verify Validations/Errors" || 'Error Occurred', 'error');
                throw response.json();
            }
            if (response.status === 401 || response.status === 403) {
                if (response.status === 401) {
                    window.location.replace(getEnvVariable('AuthorizationScreen') + 'user/logout');;
                    throw response.json();
                }
                else console.error(response)
            }
            if (response.status > 200) {
                if (DefaultSettings.getLoader() != null)
                    DefaultSettings.getLoader().remove();
                if (DefaultSettings.getAlert() != null)
                    DefaultSettings.getAlert().show('Error Occurred,Please contact Admin', 'error');
            }
            const data = await response.json();
            if (data) {
                localStorage.setItem('SaveCurrentRecord', 'N')
                if (DefaultSettings.getLoader() != null)
                    DefaultSettings.getLoader().remove();
                if (DefaultSettings.getAlert() != null && !alert)
                    DefaultSettings.getAlert().show(message || 'Updated Successfully', 'success');
                return data
            }
        } catch (error) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            throw error;
        }
    }
    else {
        if (DefaultSettings.getLoader() != null)
            DefaultSettings.getLoader().add();
        try {
            const response = await fetch((`${url}`), {
                method: 'PUT',
                ...GetFetchProps(),
                body: {}
            })
            if (response.status === 400) {
                if (DefaultSettings.getLoader() != null)
                    DefaultSettings.getLoader().remove();
                if (DefaultSettings.getAlert() != null)
                    DefaultSettings.getAlert().show("Verify Validations/Errors" || 'Error Occurred', 'error');
                throw response.json();
            }
            if (response.status === 401 || response.status === 403) {
                if (response.status === 401) {
                    window.location.replace(getEnvVariable('AuthorizationScreen') + 'user/logout');;
                    throw response.json();
                }
                else console.error(response)
            }
            if (response.status > 200) {
                if (DefaultSettings.getLoader() != null)
                    DefaultSettings.getLoader().remove();
                if (DefaultSettings.getAlert() != null)
                    DefaultSettings.getAlert().show('Error Occurred,Please contact Admin', 'error');
            }
            const data = await response.json();
            if (data) {
                if (DefaultSettings.getLoader() != null)
                    DefaultSettings.getLoader().remove();
                if (DefaultSettings.getAlert() != null && alert)
                    DefaultSettings.getAlert().show(message, 'success');
                return data
            }
        } catch (error) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
        }
    }
}

export const PutFormData = async (url, bodyval, message = "Updated Successfully") => {
    let formData = new URLSearchParams();
    const bodyvalkeys = bodyval && Object.keys(bodyval)
    bodyvalkeys && bodyvalkeys.forEach(each => {
        formData.set(each, JSON.stringify(bodyval[each]))
    })
    if (bodyval) {
        if (DefaultSettings.getLoader() != null)
            DefaultSettings.getLoader().add();
        try {
            const response = await fetch((`${url}`), {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    ...GetHeaders(),
                },
                credentials: 'include',
                body: JSON.stringify(bodyval)

            })
            if (response.status === 401 || response.status === 403) {
                if (response.status === 401) {
                    window.location.replace(getEnvVariable('AuthorizationScreen') + 'user/logout');;
                    throw response.json();
                }
                else console.error(response)
            }
            const data = await response.json();
            if (data) {
                if (DefaultSettings.getLoader() != null)
                    DefaultSettings.getLoader().remove();
                if (DefaultSettings.getAlert() != null && alert)
                    DefaultSettings.getAlert().show(message, 'success');
                return data
            }
        } catch (error) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            throw error;
        }
    }
    else {
        if (DefaultSettings.getLoader() != null)
            DefaultSettings.getLoader().add();
        try {
            const response = await fetch((`${url}`), {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    ...GetHeaders(),
                },
                body: {}
            })
            if (response.status === 401 || response.status === 403) {
                if (response.status === 401) {
                    window.location.replace(getEnvVariable('AuthorizationScreen') + 'user/logout');;
                    throw response.json();
                }
                else console.error(response)
            }
            const data = await response.json();
            if (data) {
                if (DefaultSettings.getLoader() != null)
                    DefaultSettings.getLoader().remove();
                if (DefaultSettings.getAlert() != null && alert)
                    DefaultSettings.getAlert().show(message, 'success');
                return data
            }
        } catch (error) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
        }
    }
}

export const DeleteRecord = async (url, queryval = '', bodyval = {}, message = "Deleted Successfully") => {
    if (DefaultSettings.getLoader() != null)
        DefaultSettings.getLoader().add();
    try {
        const response = await fetch(`${url + queryval}`, {
            method: 'DELETE',
            ...GetFetchProps(),
            body: JSON.stringify(bodyval)
        })
        if (response.status === 400) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            if (DefaultSettings.getAlert() != null)
                DefaultSettings.getAlert().show("Verify Validations/Errors" || 'Error Occurred', 'error');
            throw response.json();
        }
        if (response.status === 401 || response.status === 403) {
            if (response.status === 401) {
                window.location.replace(getEnvVariable('AuthorizationScreen') + 'user/logout');;
                throw response.json();
            }
            else console.error(response)
        }
        if (response.status > 200) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
        }
        const data = await response.json();
        if (data) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            if (DefaultSettings.getAlert() != null && alert)
                DefaultSettings.getAlert().show(message, 'success');
            return data
        }
    } catch (error) {
        if (DefaultSettings.getLoader() != null)
            DefaultSettings.getLoader().remove();
        throw error;
    }
}

export const OldPostMutiFormData = async (url, bodyval, alert = false, noHeader = false, message = '') => {
    if (DefaultSettings.getLoader() != null)
        DefaultSettings.getLoader().add();
    try {
        const response = await fetch((`${url}`), {
            method: 'POST',
            ...GetFetchProps(false, noHeader),
            body: bodyval

        })
        if (response.status === 401 || response.status === 403) {
            if (response.status === 401) {
                window.location.replace(getEnvVariable('AuthorizationScreen') + 'user/logout');;
                throw response.json();
            }
            else console.error(response)
        }
        if (response.status > 200) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
        }
        const data = await response.json();
        if (data) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            if (DefaultSettings.getAlert() != null && !alert)
                DefaultSettings.getAlert().show(message || 'Saved Successfully', 'success');
            return data
        }
    } catch (error) {
        if (DefaultSettings.getLoader() != null)
            DefaultSettings.getLoader().remove();
        throw error;
    }
}

export const PostMutiFormData = async (url, bodyval, alert = false, noHeader = false, message = '') => {

    if (DefaultSettings.getLoader() != null)
        DefaultSettings.getLoader().add();
    try {
        const response = await fetch((`${url}`), {
            method: 'POST',
            ...GetFetchProps(false, noHeader),
            body: bodyval

        })
        if (response.status === 401 || response.status === 403) {
            if (response.status === 401) {
                window.location.replace(getEnvVariable('AuthorizationScreen') + 'user/logout');;
                throw response.json();
            }
            else console.error(response)
        }
        if (response.status > 200) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
        }
        const data = await response.json();
        if (data) {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            if (DefaultSettings.getAlert() != null && alert)
                DefaultSettings.getAlert().show("Saved Successfully", 'success');
            return data
        }
    } catch (error) {
        if (DefaultSettings.getLoader() != null)
            DefaultSettings.getLoader().remove();
        throw error;
    }
}
