import { Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import DefaultSettings from './settings';
const _ = require('lodash');

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        //margin: theme.spacing(3),
    },
}));

export default function CheckboxesGroup(props) {
    const { handleFieldValue, extraProps, shareProps, tooltip, contexthelp, disabled, IsSelectable, ...otherProps } = props
    const { label, helperText, required, items, error, name, fullWidth } = otherProps
    const { transformProps } = extraProps || {}
    const classes = useStyles();
    let tempval = props.value ? props.value.split(",") : [];
    const handleFieldChange = (e) => {
        let val = props.value ? props.value.split(",") : [];
        if (e.target.checked) {
            val.push(e.target.value)
        }
        else {
            val = _.remove(val, function (n) {
                return n != e.target.value;
            });
        }
        val = val.join()
        handleFieldValue({ target: { checked: true, value: val, name: e.target.name }, currentTarget: e.currentTarget })
    }

    const isDisabled = () => {
        if (IsSelectable)
            return IsSelectable === 'Y' ? true : false
        else return false
    }

    return (
        <div className={classes.root}>
            <FormControl fullWidth={fullWidth ? fullWidth : true} disabled={disabled} component="fieldset" className={classes.formControl} required={required} error={error} >
                <small className='fw-bold'>{label}&nbsp; {tooltip && <Tooltip title={tooltip ? tooltip : null}
                    placement="bottom-start" ><HelpOutlineIcon fontSize="small" style={{ width: '0.8em',color: '#007bff' }} onClick={(evt) => contexthelp ?
                    DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                </Tooltip>} </small>
                <FormGroup row className='text-secondary'>
                    {
                        items && items.map((each, index) => {
                            const { label, value } = transformProps
                            const text = each[label]
                            const key = each[value]
                            return <FormControlLabel
                                disabled={disabled ? disabled : isDisabled()}
                                key={key}
                                value={key}
                                control={<Checkbox checked={(key === props.value) || (tempval.includes(key))} onChange={handleFieldChange} name={name} />}
                                label={text}
                                labelPlacement="end"
                            />
                        })
                    }
                </FormGroup>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}

            </FormControl>
        </div>
    );
}
