/* eslint-disable no-use-before-define */

import { Chip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment } from 'react';
import DefaultSettings from './settings';
import { getColor } from '../../controls/components/custom/customcolor';
const _ = require('lodash');
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const defaults = {
    transformProps: {
        label: 'Name',
        value: 'Id'
    }
}

export default function CheckboxesTags(props) {
    const { value, handleFieldValue, extraProps, shareProps, error, required, tooltip, ismulti, contexthelp, changefieldto, limitTags, ...otherProps } = props
    const { label, helperText, items, name, fullWidth, disabled } = otherProps
    let { transformProps } = extraProps || {}
    transformProps = transformProps || defaults.transformProps
    let val = (value === undefined || value === null) ? '' : value;
    let valarray = []
    if (val !== '' && items && items.length > 0) {
        if (ismulti || changefieldto === 'DM') {
            val = val && val.split(',')
            items.sort((a, b) => val.indexOf(a) - val.indexOf(b));
            valarray = items.filter(function (item) {
                if (item[transformProps.value])
                    return val.indexOf((item[transformProps.value]).toString()) > -1
                else return false
            });
            valarray.sort(function (a, b) {
                return val.indexOf(a[transformProps.value]) - val.indexOf(b[transformProps.value]);
            });
        }
        else {
            valarray = items.filter(item => { return item[transformProps.value] === val })
            valarray = valarray[0]
        }
    }
    let tlabel = (tooltip || contexthelp) ? <Fragment> {label} < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
        <HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#007bff' }} onClick={() => DefaultSettings.showDrawer(contexthelp)} />
    </Tooltip> </Fragment> : label;
    return (

        <Autocomplete
            // label={tlabel}
            helperText={helperText}
            fullWidth={fullWidth ? fullWidth : true}
            error={error} required={required}
            multiple={ismulti || changefieldto === 'DM'}
            disabled={disabled || false}
            limitTags={limitTags || 3}
            value={valarray}
            {...otherProps}
            options={items && Array.isArray(items) ? items : []}
            disableCloseOnSelect
            getOptionLabel={(option) => option[transformProps.label]}
            onChange={(event, option) => handleFieldValue({
                target: {
                    name: name, value: (ismulti || changefieldto === 'DM') ?
                        (_.map(option, transformProps.value)).join(',') : (option && option[transformProps.value]) || null, checked: false
                }, currentTarget: { dataset: null }
            })}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option[transformProps.label]}
                </React.Fragment>
            )}
            renderTags={(value, getTagProps) =>
                valarray.map((option, index) => (
                    <Chip variant="outlined" label={option[transformProps.label]} {...getTagProps({ index })} />
                ))
            }
            //  renderTags={() => null} 
            renderInput={params => (
                <TextField
                    style={{ maxHeight: '80px', overflowX: 'hidden' }}
                    {...params}
                    label={tlabel}
                    helperText={helperText}
                    error={error}
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    InputLabelProps={{
                        style: {fontWeight: 'bold', color: required ? getColor() : (error ? 'red' : 'black')},
                        shrink: true,
                    }}
                />
            )}
        />

        // </FormControl>
    )
}

// <FormControl>
{/* <InputLabel style={{ fontWeight: 'bold', color: error ? 'red' : 'black' }} shrink> {label} {tooltip && <Tooltip title={tooltip ? tooltip : null}
                placement="bottom-start" >{contexthelp && <HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#007bff' }} onClick={(evt) => contexthelp ?
                    DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />}
            </Tooltip>} </InputLabel> */}
{/* <div className="mb-1" /> */ }