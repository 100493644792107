import { getEnvVariable } from '../../modules/environmentalList'
import { NewApplicationSidebar } from './newapplicationsidebar'
const newapplicationreview = {
    type: 'eps-newapplication-review',
    key: 'newnewapplicationformreview',
    options: {
        "routes": [
            {
                "route": "/Administration/applicationreview",
                "routeParams": ":routeid",
            },
            {
                "route": "",
                "routeParams": ":routeid1"
            },
        ],
        drawerprops: NewApplicationSidebar,
        navigationpath: {
            path: {
                url: '/Administration/applicationreview'
            }
        },
        allurls: {
            inquiryrecordData: {
                get: {
                    url: getEnvVariable('Application') + `/api/Application/ApplicationByAppId` + `${localStorage.getItem('impersonate') ? '?impersonateFor=' + localStorage.getItem('impersonate') : ''}`
                }
            },
            allSites: {
                get: {
                    url: getEnvVariable('Admin') + `/api/Sites/` + getEnvVariable('ModuleKey') + `/All/Active`
                }
            },
            allRegions: {
                get: {
                    url: getEnvVariable('Admin') + `/api/Regions/` + getEnvVariable('ModuleKey') + `/All/Active`
                }
            },
            allDistricts: {
                get: {
                    url: getEnvVariable('Admin') + `/api/Districts/` + getEnvVariable('ModuleKey') + `/All/Active`
                }
            },
            DomainValues: {
                get: {
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=`
                }
            },
            allProcessTypes: {
                get: {
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=WHAT_WOULD_YOU_LIKE_TO_DO_`
                }
            },
            generalApplicationTypes: {
                get: {
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=GENERAL_APPLICATION_TYPE`
                }
            },
            permittypes: {
                get: {
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=APPLICATION_SUB_TYPE`
                }
            },
            outdoorpermittypes: {
                get: {
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=OUTDOOR_PERMIT_SUBTYPE`
                }
            },
            encroachmentpermittypes: {
                get: {
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=ENCROACHMENT_SUB_TYPE`
                }
            },
            applicationProcessTypes: {
                get: {
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=PROCESS_TYPES`
                }
            },
            allSectionFields: {
                get: {
                    url: getEnvVariable('Application') + `/api/Application/Fields`
                }
            },
            AllOwnerInternal: {
                get: {
                    url: getEnvVariable('MasterAdmin') + `/api/CompanyInternal/All/Active`

                }
            }, AllOwnerInternalSearch: {
                get: {
                    url: getEnvVariable('MasterAdmin') + `/api/CompanyInternal/Search`

                }
            },
            TermsAndConditions: {
                get: {
                    url: getEnvVariable('AdminConfig') + `/api/SitePermitTypeTermsAndCond`,
                    routeProps: {}
                }
            },
            SitePermitTypesData: {
                get: {
                    url: getEnvVariable('AdminConfig') + `/api/${getEnvVariable('ModuleKey')}SitePermitTypes`,
                    routeProps: {}
                }
            },
            // domainValues: {
            //     get: {
            //         url: getEnvVariable('Application')+`/api/Domains/values?domainkey=`
            //     }
            // },
            updateNewApplication: {
                put: {
                    url: getEnvVariable('Application') + `/api/Application/Update`
                }
            },
            OfficialUser: {
                get: {
                    url: getEnvVariable('MasterAdmin') + `/api/CompanyInternal/`
                }
            },
            Preparer: {
                get: {
                    url: getEnvVariable('MasterAdmin') + `/api/CompanyInternal/`
                }
            },
            ContactType: {
                get: {
                    url: getEnvVariable('MasterAdmin') + `/api/CompanyInternal/`
                }
            },
            SelectUser: {
                get: {
                    url: getEnvVariable('MasterAdmin') + `/api/CompanyInternal/`
                }
            },
            SitePermitTypesRegions: {
                get: {
                    url: getEnvVariable('AdminConfig') + `/api/SitePermitTypesConfig/Regions/Active`
                }
            },
            SitePermitTypesSites: {
                get: {
                    url: getEnvVariable('AdminConfig') + `/api/SitePermitTypes/Sites/Active`
                }
            },
            SitePermitTypesBasedOnConfigType: {
                get: {
                    url: getEnvVariable('AdminConfig') + `/api/SitePermitTypesConfig/ApplicationTypes/Active`
                }
            },
            SitePermitTypesValue: {
                get: {
                    url: getEnvVariable('Admin') + `/api/MasterPermitTypes/`
                }
            },
            SitePermitTypesSubModuleKey: {
                get: {
                    url: getEnvVariable('AdminConfig') + `/api/SitePermitTypes/SubModuleKey/Active`, //${getEnvVariable('ModuleKey')}
                    routeProps: {}
                },

            },
            Validationurl: {
                get: {
                    url: getEnvVariable('Application') + `/api/SitePermitTypeValidation/`
                }
            },
            AllFields: {
                get: {
                    url: getEnvVariable('Application') + `/api/SitePermitSectionFieldsView/AllFields`
                }
            },

            AddNewApplication: {
                post: {
                    url: getEnvVariable('Application') + `/api/Application/Add/`
                }
            },

            AllFieldsGroupBySection: {
                get: {
                    url: getEnvVariable('Application') + `/api/SitePermitSectionFieldsView/AllFieldsGroupBySection`
                }
            },
            OwnerAddress: {
                get: {
                    url: getEnvVariable('MasterAdmin') + `/api/CompanyInternal`,
                    routeProps: {

                    }
                }
            },
            StatusChange: {
                put: {
                    url: getEnvVariable('Application') + `/api/Application/UpdateStatus/`
                }
            },
            StatusChangeNotification: {
                get: {
                    url: getEnvVariable('Application') + `/api/Application/TemplateSearchByDomainKey/`
                }
            },

            GetApplicationStatus: {
                get: {
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=APPLICATION_STATUS&domainid=0`
                }
            },

            GetSubmissionType: {
                get: {
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=SUBMISSION_TYPE&domainid=0`
                }
            },

            GetApplicationProcessType: {
                get: {
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=WHAT_WOULD_YOU_LIKE_TO_DO_&domainid=0`
                }
            },

            GetResolutionStatus: {
                get: {
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=RESOLUTION_STATUS&domainid=0`
                }
            },

            GetPermitStatus: {
                get: {
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=PERMIT_STATUS&domainid=0`
                }
            },

            Duplicate: {
                get: {
                    url: '${Application}/api/Application/Duplicate'
                }
            },
            AdminDomainValues: {
                get: {
                    url: getEnvVariable('MasterAdmin') + `/api/Domains/values?domainkey=`
                }
            },
            Domainsurl: {
                get: {
                    url: getEnvVariable('Application') + `/api/DomainsMapper/ByParams`,
                    routeProps: {}
                }
            },
            Getallsubmodules: {
                get: {
                    url: getEnvVariable('MasterAdmin') + `/api/module/` + getEnvVariable('ModuleKey') + `/submodule/All/Active/IsConfigurable`
                }
            },
            TownVillageCity:{
                get:{
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=MUNICIPALITY_TYPE`
                }
            },
            InterStateHwy:{
                get:{
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=INTERSTATE_HIGHWAY_NETWORK`
                }
            },
            InterStateHwyNumber:{
                get:{
                    url: getEnvVariable('Application') + `/api/Domains/values?domainkey=INTERSTATE_HIGHWAY_NETWORK`
                }
            }


        }
    },


}

export default newapplicationreview