import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import TextField from '../../core/controls/components/textbox';
import { GetData } from '../../core/controls/helpers/requests';
import { createRequest, requestApi } from '../../core/controls/helpers/rest';
import CForm from '../../core/modules/form/cform';
import FormHeader from '../../core/modules/form/cformheader';
import { getEnvVariable } from '../environmentalList';
import RequestApproval from './index';
//Updated By: vijaya. comments : Added Breadcrumbs.
const _ = require('lodash');




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

class RegistrationRequestApproval extends Component {

    constructor(props) {
        super(props)
        this.state = {
            title: "Registration Request",
            approvalButtons: [],
            opensnackbar: false,
            snackbarmessageType: '',
            snackbarmessage: '',
            results: {},
            dataItemVal: this.props.dataItem,
            tabvalue: 0,
            comments: null,
            disabletab: true,
            initialrequest: {},
            submodulemenudata: null,
            message: null,
            requestApprovalSave: false
        }
        this.LayoutForm = React.createRef()
    }

    handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ opensnackbar: false });
    };

    handleApprovalChange = (modules, message) => {
        let results = this.state.results;
        results.CompanyModules = modules;
        this.setState({
            approvalButtons: modules,
            results: results,
            message: message
        })
    }

    handleState = (inputState) => {
        this.setState({
            results: inputState.results,
            approvalButtons: inputState.results.CompaniesModules,
            comments: inputState.results.Comments,
            disabletab: false
        })
    }

    handleBack = () => {
        let pathname = this.props.location.pathname;
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.Id) {
            this.props.match.params.Id = undefined
            pathname = pathname.substring(0, pathname.lastIndexOf("/"))
        }
        this.props.onClose();
        this.props.history.push(pathname)

    }

    handleSave = (event) => {
        const { options } = this.props;
        const { crud, title } = options
        const save = crud.update;
        let ids = [], id
        let violation = _.find(this.state.submodulemenudata, { 'Key': "VIOLATIONS" });
        let violationId = violation ? violation.Id : null
        let resdata = { ...this.state.results }
        if (violationId) {
            let containsapproved = _.find(resdata.CompaniesModules, { "RequestStatus": 'A' })
            let companymodulesresdata = resdata.CompaniesModules ? resdata.CompaniesModules.map(res => {
                if (res.SubModuleId == violationId && containsapproved) {
                    return { ...res, RequestStatus: 'A' }
                }
                return res
            }) : null
            let companiesmodulesresdata = resdata.CompanyModules ? resdata.CompanyModules.map(res => {
                if (res.SubModuleId == violationId && containsapproved) {
                    return { ...res, RequestStatus: 'A' }
                }
                return res
            }) : null
            resdata = { ...resdata, CompanyModules: companymodulesresdata, CompaniesModules: companiesmodulesresdata }
        }
        if (Array.isArray(save.put.matchProps)) {
            save.put.matchProps.forEach(each => {
                const { value } = each
                ids.push({ key: value, value: this.props.match.params[value] })
            })
        }
        else if (save.put.customProps) {
            id = this.props.dataItem["Id"]
        }
        else {
            id = this.props.match.params.routeid
        }
        let saveurl = {
            url: save.put.url + '/{Id}',
            message: this.state.message ? this.state.message : "Saved Sucessfully",
            "matchProps": [{
                name: "Id",
                key: "Id",
            }],
            type: 'put',
        }
        if (save) {
            // requestApi(save, (id || ids), this.state.results).
            requestApi(createRequest(saveurl, resdata, (id || ids))).then(results => {
                if (results.CompaniesModules && results.CompaniesModules.length > 0) {
                    let reqstatus = {}
                    results.CompaniesModules.forEach(element => {
                        let idval = element.SubModuleId;
                        let requeststatusval = element.RequestStatus
                        reqstatus[idval] = requeststatusval
                    });
                    this.setState({ initialrequest: reqstatus, message: null, requestApprovalSave: _.includes(reqstatus, 'A') ? true : false })
                }
                //   this.props.snackbarMessage('Registration Requests Updated Successfully', 'success')
            }).catch(error => {
                error.then(err => {
                    console.log('error---', err)
                    if (err.errors) this.onError(err.errors)
                    else this.onError(err)
                })
            })
        }

    }

    assignSubModuleMenu = (submodulemenudata) => {
        this.setState({ submodulemenudata: submodulemenudata })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dataItem != this.props.dataItem) {
            this.setState({ dataItemVal: this.props.dataItem })
        }
    }

    handleEdit = () => {
        this.LayoutForm.current.handleEdit();
    }

    handleChange = (event, newValue) => {
        if (newValue == 0) {
            this.setState({ disabletab: true })
        }
        this.setState({ tabvalue: newValue })
    }

    handleFieldChange = (event) => {
        this.setState({ comments: event.target.value, results: { ...this.state.results, Comments: event.target.value } })
    }
    //http://development.delasoft.com:8443/KEPT-master-admin/api/CompanyInternal/consultantCompanyRequest/Update/KEPT/A/3423

    handleStatusChange = (status) => {
        const { dataItemVal, results } = this.state;
        if ((dataItemVal.RequestStatus && dataItemVal.RequestStatus !== 'A') || results.RequestStatus !== 'A') {
            let data = { ...dataItemVal, ...results }
            let url = getEnvVariable('MasterAdmin') + `/api/CompanyInternal/consultantCompanyRequest/Update/${getEnvVariable('ModuleKey')}/${status}/${data.Id}`
            let message = `Request is  ${status === 'A' ? 'approved' : 'denied'} and an email will be sent to consultant`
            GetData(url, true, message).then(res => {
                this.setState({ dataItemVal: { ...data, ['RequestStatus']: status } })
            })
        }
    }

    getHeader = (type) => {
        switch (type) {
            case 'T':
                return [
                    { "link": "Consultant Request", appendval: 'Name', "materialicon": "connect_without_contact", className: 'eps-breadcrumb-item ', class: 'text-light' }
                ]
            default: return [
                { "link": "Registration Request", appendval: 'Name', "materialicon": "how_to_vote", className: 'eps-breadcrumb-item ', class: 'text-light' }
            ]
        }
    }

    render() {
        const { compmap, options, accessPermissions, accessLevelPermissions, onPrevItemClick, onNextItemClick } = this.props;
        const { dataItemVal, tabvalue, results } = this.state
        return <div style={{ height: window.innerHeight - 50 }}>
            <FormHeader savebutton={false} editbutton={false} handleEdit={this.handleEdit} handleSave={this.handleSave} breadcrumbs={this.getHeader(results.Type)} backbutton={true} handleBack={this.handleBack} dataItem={dataItemVal} onNextItemClick={onNextItemClick} onPrevItemClick={onPrevItemClick} navigateup={true} navigatedown={true} />
            <AppBar position="static" color="white">
                <Tabs
                    value={tabvalue}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="scrollable auto tabs example"
                >

                    <Tab label="Applicant Information" {...a11yProps(0)} />
                    {results && results.Name !== 'NA' && results.Type === 'C' && <Tab label="Approvals" disabled={this.state.disabletab} {...a11yProps(1)} />}
                </Tabs>
            </AppBar>
            <TabPanel value={tabvalue} index={0}>

                {results.Type === 'T' && <div className='float-right'>
                    <small> Request Status : </small>
                    {((dataItemVal.RequestStatus && dataItemVal.RequestStatus === 'D') || results.RequestStatus === 'D') && <span className='badge badge-danger'>Denied</span>}
                    {((dataItemVal.RequestStatus && dataItemVal.RequestStatus === 'A') || results.RequestStatus === 'A') && <span className='badge badge-success'>Approved</span>}
                    {((dataItemVal.RequestStatus && dataItemVal.RequestStatus === 'P') || results.RequestStatus === 'P') && <span className='badge badge-info'>Pending</span>}
                </div>
                } <br />
                {results.Type === 'T' && ((dataItemVal.RequestStatus && dataItemVal.RequestStatus !== 'A') || results.RequestStatus !== 'A') && <div className='float-right pt-2'><ButtonGroup variant="contained">
                    <Button color="primary" onClick={() => { this.handleStatusChange('A') }}>Approve</Button>
                    <Button color="secondary" disabled={((dataItemVal.RequestStatus && dataItemVal.RequestStatus === 'D') || results.RequestStatus === 'S')} onClick={() => { this.handleStatusChange('D') }}>Deny</Button>
                </ButtonGroup></div>}
                <CForm ref={this.LayoutForm} {...options.embedform} compmap={compmap} getState={this.handleState} edit={false} windowHeight={true} dataItem={dataItemVal} />
            </TabPanel>
            <TabPanel value={tabvalue} index={1}>
                <RequestApproval assignSubModuleMenu={this.assignSubModuleMenu} companyModules={this.state.approvalButtons} initialresult={this.state.initialrequest}
                    handleApprovalChange={(modules, message) => this.handleApprovalChange(modules, message)}
                    handleSave={this.handleSave} save={this.state.requestApprovalSave} requestApprovalSaveFnc={() => { this.setState({ requestApprovalSave: false }) }} />
                <div className='col-sm-8'>
                    <TextField
                        label="Comments"
                        value={this.state.comments}
                        handleFieldValue={(e) => this.handleFieldChange(e)}
                        multiline
                        rowsMax='4'
                    />
                </div>
            </TabPanel>
        </div>

    }
}

const mapActions = dispatch => {
    return bindActionCreators({}, dispatch);
}

RegistrationRequestApproval = connect(null, mapActions, null, { forwardRef: true })(RegistrationRequestApproval)
export default withRouter(RegistrationRequestApproval)
