/**
      *********************************************************
      Description: Hanldes text. different types- dtext, datetimetext. dtext handles dynamic text. datetimetext handles datetime text.
      Required props:  type.
      limitations    : N/A
      *********************************************************
**/

import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import CalandarIcon from '@material-ui/icons/CalendarToday';

const CText = (props) => {
    const { options, style, type, value, edit, editduplicate } = props;
    const { text, error, divstyle, ...otherOptions } = options ? options : { text: '' }
    let dvalue = null
    if (type === 'dtext') {
        if (value) {
            dvalue = <div className="row col"> <Typography variant={'subtitle2'} color={error ? 'secondary' : 'primary'}>{text} </Typography>&nbsp; <Typography variant={'body2'} color={error ? 'secondary' : 'textPrimary'}>{value ? value : ''}  </Typography></div>
        }
        // else dvalue = text + (value ? value : "")
        //  else dvalue = <div className="row col"> <Typography variant={'subtitle2'} color={error ? 'secondary' : 'primary'}>{text} </Typography>&nbsp; <Typography variant={'body2'} color={error ? 'secondary' : 'textPrimary'}>{value ? value : ''}  </Typography></div>
    }
    if (type === 'etext') dvalue = (value ? value : "")
    // if (type === 'datetimetext') dvalue = (value ? value : "")
    if (type === 'datetimetext') {
        if (value) {
            const val = value.split('+');
            dvalue = val[0];
        }
        else
            dvalue = ""
    }


    return (
        <Fragment>
            <Typography style={style} {...otherOptions}>
                {(type === 'text') && <div className="row pl-3 m-1" style={divstyle}>{text}</div>}
                {(type === 'etext') && <div className="row col pt-3" style={{ color: '#f50057' }}>{dvalue === null ? '' : dvalue}</div>}
                {(type === 'dtext') && <div className="row col">{dvalue === null ? '' : dvalue}</div>}
                {(type === 'datetimetext' && value != null) && <div className={!edit ? " " : " "}> <Typography variant={'subtitle2'}
                    color={'primary'}>{text} </Typography>&nbsp; <Typography variant={'body2'} color={'textPrimary'}>
                        {moment(dvalue).format('MM/DD/YYYY hh:mm:ss A') === "Invalid date" ? '' : moment(dvalue).format('MM/DD/YYYY hh:mm:ss A')}  </Typography>
                </div>}
                {(type === 'infotext') && <small style={{ fontSize: '12px' }} className="text-secondary">{text}</small>}
                {(type === 'historyuser' && !editduplicate && value != null && value != "") && <small style={{ fontSize: '12px' }} >
                    <span className="fw-bold"> {text}</span>{" "}<span className="text-secondary">{value} </span>
                </small>}
                {(type === 'historydate' && !editduplicate && value != null && value != "" && value !== undefined && value) && <small style={{ fontSize: '12px' }}  >
                    <span className="fw-bold"> {text}</span>{" "} <span className="text-secondary">{(moment(value).format('MM/DD/YYYY hh:mm:ss A') === "Invalid date" ? '' : moment(value).format('MM/DD/YYYY hh:mm:ss A'))} </span>
                </small>}

            </Typography>
        </Fragment>
    )

}



export default CText;




/**
 * {
 * type:'title',
 * options:{
 *  text:'',
 * },
 * defaults:{
 * layout:'material',
 * customlayout: false
 * }
 * }
 */