import { getEnvVariable } from "../../modules/environmentalList";

const standarddescriptionofconstruction = {
    "type": "slidingbutton",
    "key": "newStandardDescriptionOfConstructionslidingbutton",
    "options": {
        "row": 7,
        "col": 2,
        "width": 2,
        "buttonprops": {
            "text": "Standard Description Of Construction",
            "size": "large",
            "color": "primary",
            "variant": "contained"
        },
        excludefromresults: true,
        "label": "Standard Description Of Construction",
        "name": "Standard Description Of Construction",
    },
    open: true,
    "components": [
        {
            "type": 'standarddescofconstruction',
            "options": {
                tabprops: true,
                // designprops: true,
                save: true,
                "title": 'Standard Description Of Construction',
                form: true,
                headerProps: {
                    "savebutton": true,
                    "backbutton": true
                },
                "crud": {
                    "read": {
                        "get": {
                            "url": getEnvVariable('AdminConfig') + `/api/SitePermitTypeDescriptionOfConstruction/`,

                        }
                    },
                    "update": {
                        "put": {
                            "url": getEnvVariable('AdminConfig') + `/api/SitePermitTypeDescriptionOfConstruction/`,
                        },
                    }
                },
            },
            components: []



        }]
}

export default standarddescriptionofconstruction;