import React, { useEffect, useRef } from "react"
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import Confirmation from './confirmationdialog'
import { getEnvVariable } from "../../../modules/environmentalList";
const _ = require('lodash');
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {/* by removing style scrollbar will be hided {value === index && <Box p={3} style={{ overflow: 'auto', height: '800px' }}>{children}</Box>} */}
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        "& .MuiTab-root": {
            'text-transform': 'capitalize'
        },
    },
}));

export default function ScrollableTabsButtonAuto(props) {
    const { options, components, compmap, headerProps, gridkey } = props
    let dataItemVal = props.dataItem
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [openConfirmation, setOpenconfirmation] = React.useState(false);
    const [tempVal, setTempVal] = React.useState(null);
    const prevdataItemVal = usePrevious(dataItemVal);
    let UnsavedData = useSelector(state => state.header && state.header.unsaveddata)

    function usePrevious(currentdatavalue) {
        const ref = useRef();
        useEffect(() => {
            ref.current = currentdatavalue;
            settingValue();
        });
        return ref.current;
    }

    useEffect(() => {
        if (prevdataItemVal && Object.entries(prevdataItemVal).toString() !== Object.entries(dataItemVal).toString()) {
            setValue(0)
        }
    }, [])

    function settingValue() {
        if (prevdataItemVal && Object.entries(prevdataItemVal).toString() !== Object.entries(dataItemVal).toString()) {
            setValue(0)
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = (event, leave, stay) => {
        if (leave && (tempVal || tempVal == 0)) {
            props.unsavedData(null)
            setOpenconfirmation(false)
            setValue(tempVal);
            setTempVal(null)
        }
        else {
            setOpenconfirmation(false)
            setTempVal(null)
        }

    }
    return (
        <div className={classes.root}>
            <AppBar position="static" color="white" className='col-sm-12'>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={(options && options.variant) || "scrollable"}
                    scrollButtons="auto"

                >
                    {options && options.tabinfo && options.tabinfo.map((e, i) => {
                        let Admin = props.accessPermissions.isAdmin == "Y" ? true : false
                        if (e.displaycondition) {
                            if (e.value === props.accessPermissions.configType) {
                                return <Tab label={e.tablabel} {...a11yProps(i)} />
                            }
                        }
                        else if (e.tabhide && e.hidetabcond && e.hidetabval && e.hidetabcond == e.hidetabval) {
                            return null
                        }
                        else if (e.hidetab && e.onhidetabcondition && props && props.dataItem && props.dataItem[e.nested] && props.dataItem[e.nested].Status == e.hidetabval) {
                            return null
                        }
                        else if (e.hidetab && e.onhidetabcondition && e.dataItemKey && props && props.dataItem && props.dataItem[e.nested] && props.dataItem[e.nested][e.dataItemKey] === e.dataItemVal) {
                            return null 
                        }
                        else if (e.hidetab && e.onhidetabcondition && (props && props.dataItem && !props.dataItem.Status) && e.aftersavetab) {
                            return null
                        }
                        else if (e.hidetab && props && props.dataItem && props.dataItem.Status === "I") {
                            // return <Tab label={e.tablabel} disabled {...a11yProps(i)} />
                            return null
                        }
                        else if (e.hidetab && props && e.isLocalStorage && localStorage.getItem(e.isLocalStorage) === 'Y') {
                            // return <Tab label={e.tablabel} disabled {...a11yProps(i)} />
                            return null
                        }
                        else if (e.hidetab && props && Array.isArray(e.isLocalStorage) && e.isLocalStorage.length > 0 && e.isLocalStorage.some(e => localStorage.getItem(e) === 'Y')) {
                            // return <Tab label={e.tablabel} disabled {...a11yProps(i)} />
                            return null
                        }
                        else if ((e.showtab && props.dataItem.IsAdmin == 'Y') || (Admin && e.profileaccesscontrol == 'Y')) {
                            return null
                        }
                        else return <Tab label={e.tablabel} disabled={e.disabletab} {...a11yProps(i)} />
                    })}
                </Tabs>
            </AppBar>
            {options && options.tabinfo && options.tabinfo.map((e, i) => {
                let Admin = props.accessPermissions.isAdmin == "Y" ? true : false

                let displaytab
                if (e.displaycondition) {
                    displaytab = _.find(components, { "tabkey": e.tabkey, "tabdisplay": props.accessPermissions.configType })
                }
                else if (e.hidetabcond && e.hidetabval && e.hidetabcond == e.hidetabval) {
                    displaytab = _.find(components, { "tabkey": e.alttabkey })
                }
                else if (e.onhidetabcondition && props && props.dataItem && props.dataItem[e.nested] && props.dataItem[e.nested].Status == e.hidetabval) {
                    displaytab = _.find(components, { "tabkey": e.alttabkey })
                }
                else if (e.onhidetabcondition && props && props.dataItem && props.dataItem.Status) {
                    displaytab = props.dataItem.Status == 'A' ? (props.dataItem.IsAdmin == 'Y' ? _.find(components, { "tabkey": e.alttabkey }) : _.find(components, { "tabkey": e.tabkey })) : _.find(components, { "tabkey": e.alttabkey })
                }
                else if (e.showtab && props.dataItem.IsAdmin) //Access Control Tab
                {
                    displaytab = props.dataItem.IsAdmin == 'Y' ? (props.dataItem.Status == 'A' ? _.find(components, { "tabkey": e.alttabkey }) : _.find(components, { "tabkey": e.inactiveusertabkey })) : _.find(components, { "tabkey": e.tabkey })
                }
                else if (e.profileaccesscontrol || e.profilepwd) {
                    displaytab = e.profileaccesscontrol ? (Admin ? _.find(components, { "tabkey": e.alttabkey }) : _.find(components, { "tabkey": e.tabkey })) : (Admin ? _.find(components, { "tabkey": e.alttabkey }) : _.find(components, { "tabkey": e.tabkey }))
                }
                else {
                    displaytab = _.find(components, { "tabkey": e.tabkey })
                }
                if (displaytab && displaytab.type) {
                    const EachComp = compmap[displaytab.type]
                    return <TabPanel value={value} index={i}>
                        <EachComp dataItem={props.dataItem} compmap={compmap} components={displaytab.components || displaytab.options}
                            actions={displaytab.actions || []} options={displaytab.options} accessPermissions={props.accessPermissions}
                            datecomponents={displaytab.datecomponents} createdcomponents={displaytab.createdcomponents}
                            onNextItemClick={props.onNextItemClick} onPrevItemClick={props.onPrevItemClick} onClose={props.onClose}
                            handleTabChange={handleChange} initialloadextrainfo={props.initialloadextrainfo} gridkey={displaytab && displaytab.gridkey ? displaytab.gridkey : gridkey} />
                    </TabPanel>
                }
                else return <></>
            })}
            {openConfirmation && <Confirmation open={openConfirmation} handleBack={handleClose} />}
        </div>
    );
}
