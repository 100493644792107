import * as React from 'react';
import * as DNDSettings from './util/dndSettings'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'; 

import CRowDraggable from './cRowDraggable'  
/*
 { this.state.isHovering &&  <div className="actionPanel">
                        {row.canDrag && <span {...sdp.dragHandleProps} className="dragHandle" ><Icon>open_with</Icon></span>} 
                      </div> }
*/
class CRowDroppable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false
    }
  }
  OnMouseHover = () => {
    this.setState({ isHovering: !this.state.isHovering })
  }


  render() {
    const {section, row,sdp,direction,dropclazzName,OnMouseHover,OnSettings} = this.props;
    return (
        <Droppable  
        key={row.dndId} 
        droppableId={`SecDP-${row.dndId}`}
        direction={direction} type={row.dropType}>
           {(p, s) => (
             <div className="eps-test2">
               <div ref={p.innerRef}
               className={dropclazzName}
               onMouseEnter={this.OnMouseHover}
               onMouseLeave={this.OnMouseHover}
               style={DNDSettings.getFieldListStyle(s.isDraggingOver)}> 
                   {row.fields && row.fields.map((field, index) => ( 
                        <div {...p.draggableProps} style={DNDSettings.getRowFieldStyle(p.isDragging)} >  
                           {field &&  <CRowDraggable section={section} direction={direction} dropclazzName={row.dropclazzName}  splitName={row.splitName}  dragclazzName={row.dragclazzName} field={field} draggableIndex={index} showFieldActions={row.showFieldActions} OnSettings={OnSettings}/>} 
                      </div>
                   ))} 
               {p.placeholder}
               </div>
               </div>
           )}
       </Droppable>
    );
  }
}

export default CRowDroppable; 