import { getEnvVariable } from "../..//modules/environmentalList"

export const SuperAdminList = {
    'WRS': {
        "epsgeneral":
            [
                {
                    "type": "menu",
                    "title": "Statistics",
                    "icon": "poll",
                    "materialicon": "poll",
                    "link": "/systemstatistics",
                    "key": "Statistics",
                    "style": {
                        'color': '#A020F0'
                    }
                },
                {
                    "type": "menu",
                    "title": "Notifications",
                    "icon": "notifications_active",
                    "materialicon": "notifications_active",
                    "link": "#/notifications",
                    "key": "Notifications",
                    "style": {
                        'color': '#00bfa5'
                    }
                },
                {
                    "type": "menu",
                    "title": "Search",
                    "icon": "search",
                    "materialicon": "search",
                    "link": "/search",
                    "key": "Search",
                    "style": {
                        'color': '#61A5DC'
                    }
                },
                {
                    "type": "menu",
                    "title": "Records",
                    "key": "newapplicationdetails",
                    "link": "#/Records/RECYCLERS",
                    "icon": "fa fa-file-text",
                    "materialicon": "new_releases",
                    "style": {
                        'color': '#9c27b0'
                    }
                },

                {
                    "type": "menu",
                    "title": "Reports",
                    "icon": "fa fa-files-o",
                    "link": getEnvVariable('ReportsUI'),
                    "key": "reports",
                    "newtab": true,
                    "materialicon": "post_add",
                    "style": {
                        'color': '#827717'
                    }
                },
                {
                    "type":"menu",
                    "title": "Authorization",
                    "key": "Authorization",
                    "link": "/Administration/authorizations",
                    "icon": "fa fa-globe",
                    "materialicon": "vpn_key",
                    "style": {
                        'color': '#c158dc'
                    }
                },
            ],
        "Adminlist":
            [
                {
                    "type": "menu",
                    "title": "Users",
                    "icon": "fa fa-users",
                    "link": "#/Administration/agencyusers",
                    "key": "AgencyUsers",
                    "materialicon": "person",
                    "style": {
                        'color': '#01579b'
                    }
                },
                {
                    "type": "menu",
                    "title": "Roles",
                    "key": "roles",
                    "link": "#/Administration/roles",
                    "icon": "fa fa-user-circle-o",
                    "materialicon": "manage_accounts",
                    "style": {
                        'color': '#004d40'
                    }
                },

                {
                    "type": "menu",
                    "title": "Groups",
                    "key": "groups",
                    "link": "#/Administration/groups",
                    "icon": "fa fa-users",
                    "materialicon": "device_hub",
                    "style": {
                        'color': '#3f51b5'
                    }
                },


                {
                    "type": "menu",
                    "title": "Regions",
                    "key": "regions",
                    "link": "#/Administration/regions",
                    "icon": "fa fa-map",
                    "materialicon": "streetview",
                    "style": {
                        'color': '#61A5DC'
                    }
                },

                // {
                //     "type": "menu",
                //     "title": "Districts",
                //     "key": "districts",
                //     "link": "#/Administration/districts",
                //     "icon": "fa fa-map",
                //     "materialicon": "map",
                //     "style": {
                //         'color': '#c158dc'
                //     }
                // },
                {
                    "type": "menu",
                    "title": "Counties",
                    "key": "sites",
                    "link": "#/Administration/counties",
                    "icon": "fa fa-globe",
                    "materialicon": "settings_system_daydream",
                    "style": {
                        'color': '#006064'
                    }
                },
                {
                    "type":"menu",
                    "title": "Holidays",
                    "key": "Holidays",
                    "link": "#/Administration/holidays",
                    "icon": "fa fa-globe",
                    "materialicon": "today",
                    "style": {
                        'color': '#c158dc'
                    }
                },
                
                // {
                //     "type": "menu",
                //     "title": "Email Groups",
                //     "key": "groupemails",
                //     "link": "#/Administration/groupemails",
                //     "icon": "fa fa-domain",
                //     "materialicon": "email",
                //     "style": {
                //         'color': '#0c755b'
                //     }
                // },
                {
                    "type": "menu",
                    "title": "Contractors",
                    "icon": "fa fa-building",
                    "link": "#/Administration/companies",
                    "key": "companies",
                    "materialicon": "apartment",
                    "style": {
                        'color': '#ea156d'
                    }
                },
                {
                    "type": "menu",
                    "title": "Attachment Types",
                    "key": "attachmenttypes",
                    "link": "#/Administration/attachmenttypes",
                    "icon": "fa fa-paperclip",
                    "materialicon": "attach_file",
                    "style": {
                        'color': '#b26a00'
                    }
                },
                {
                    "type": "menu",
                    "title": "Image Types",
                    "key": "imagetypes",
                    "link": "#/Administration/imagetypes",
                    "icon": "fa fa-file-image-o",
                    "materialicon": "wallpaper",
                    "style": {
                        'color': '#ffb300'
                    }
                },
   
                // {
                //     "type": "menu",
                //     "title": "Consultants",
                //     "icon": "fa fa-building",
                //     "link": "/Administration/consultants",
                //     "key": "consultants",
                //     "materialicon": "connect_without_contact",
                //     "style": {
                //         'color': '#cddc39'
                //     }
                // },
                // {
                //     "type": "menu",
                //     "title": "Project Messages",
                //     "key": "projectmessages",
                //     "link": "/Administration/projectmessages",
                //     "icon": "fa fa-domain",
                //     "materialicon": "message",
                //     "style": {
                //         'color': '#940786'
                //     }
                // },
                // {
                //     "type": "menu",
                //     "title": "Registrations",
                //     "key": "registrationrequest",
                //     "link": "#/Administration/registrationrequest",
                //     "icon": "fa fa-caret-square-o-right",
                //     "materialicon": "how_to_vote",
                //     "style": {
                //         'color': '#9fa8da'
                //     }
                //},
            ],
        'settings': [
            {
                "type": "menu",
                "title": "Permit Status",
                "icon": "fa fa-donut",
                "link": "#/Administration/applicationstatus",
                "key": "applicationstatus",
                "materialicon": "rotate_right",
                "style": {
                    'color': '#00796b'
                }
            },
            {
                "type": "menu",
                "title": "Master Sections",
                "key": "sections",
                "link": "#/Administration/mastersections",
                "icon": "fa fa-columns",
                "materialicon": "view_week",
                "style": {
                    'color': '#00c853'
                }
            },
            {
                "type": "menu",
                "title": "Master Fields List",
                "key": "masterFieldsList",
                "link": "#/Administration/masterfieldslist",
                "icon": "fa fa-list-alt",
                "materialicon": "view_module",
                "style": {
                    'color': '#e65100'
                }
            },
            {
                "type": "menu",
                "title": "Master Permit Types",
                "key": "masterpermittypes",
                "link": "#/Administration/masterpermittypes",
                "icon": "fa fa-id-badge",
                "materialicon": "verified_user",
                "style": {
                    'color': '#795548'
                }
            },
            {
                "type": "menu",
                "title": "Permit Types",
                "key": "sitepermittypes",
                "link": "#/Administration/applicationtypes",
                "icon": "fa fa-address-card-o",
                "materialicon": "language",
                "style": {
                    'color': '#a5d6a7'
                }
            },
            
            {
                "type": "menu",
                "title": "Master Templates",
                "key": "notices",
                "link": "#/Administration/masternotices",
                "icon": "fa fa-sticky-note",
                "materialicon": "document_scanner",
                "style": {
                    'color': '#ab003c'
                }
            },
            {
                "type": "menu",
                "title": "Notices",
                "key": "notices",
                "link": "#/Administration/noticestypes",
                "icon": "fa fa-sticky-note",
                "materialicon": "article",
                "style": {
                    'color': '#8bc34a'
                }
            },
            {
                "type": "menu",
                "title": "Notification Settings",
                "key": "notificationsettings",
                "link": "#/Administration/notificationsettings",
                "icon": "fa fa-bell-o",
                "materialicon": "notifications_active",
                "style": {
                    'color': '#40c4ff'
                }
            },
            {
                "type": "menu",
                "title": "Domains",
                "key": "domains",
                "link": "#/Administration/domains",
                "icon": "fa fa-domain",
                "materialicon": "domain",
                "style": {
                    'color': '#a98274'
                }
            },
            {
                "type": "menu",
                "title": "Layers",
                "key": "layers",
                "link": "#/Administration/layers",
                "icon": "fa fa-domain",
                "materialicon": "layers",
                "style": {
                    'color': '#c85a54'
                }
            },
            {
                "type": "menu",
                "title": "InvoiceCycles",
                "key": "invoicecycles",
                "link": "#/Administration/invoicecycles",
                "icon": "fa fa-users",
                "materialicon": "paid",
                "style": {
                    'color': '#3f51b5'
                }
            },
            // {
            //     "type": "menu",
            //     "title": "Project Downloads",
            //     "key": "settings",
            //     "link": "#/Administration/projectdownloads",
            //     "icon": "fa fa-domain",
            //     "materialicon": "download",
            //     "style": {
            //         'color': '#3f51b5'
            //     }
            // },
            {
                "type": "menu",
                "title": "Configurations",
                "key": "settings",
                "link": "#/Administration/settings",
                "icon": "fa fa-domain",
                "materialicon": "settings_suggest",
                "style": {
                    'color': '#5ddef4'
                }
            },
            // {
            //     "type": "menu",
            //     "title": "Sidebar Rules",
            //     "key": "settings",
            //     "link": "#/Administration/sidebarbr",
            //     "icon": "fa fa-domain",
            //     "materialicon": "ballot",
            //     "style": {
            //         'color': '#aeea00'
            //     }
            // },

        ]
    },
}
