import { ReGenerateInvoiceWrapper,GetComputedFee } from './billingservice';
import { getEnvVariable } from '../../../src/modules/environmentalList';
import { requestApi } from '../../../src/core/modules/form/utilities';

function groupArrayOfObjects(list, key) {
    return list.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
};

export function GetAllRenewalPermits(submodule){
    return new Promise((resolve, reject) => {
        let recordpath = getEnvVariable('Payment') + `/api/InvoiceCycle/RenewalPermits/${submodule}`;
        requestApi({ get: { url: recordpath } }, null, null)
        .then(res => {
            resolve(res)
        })
    }).catch(ex =>{
        console.error(ex);
    })
}

function groupByMultiple( array , f )
{
  var groups = {};
  array.forEach( function( o )
  {
    var group = JSON.stringify( f(o) );
    groups[group] = groups[group] || [];
    groups[group].push( o );
  });
  return Object.keys(groups).map( function( group )
  {
    return groups[group];
  })
}

export function RunInvoiceCycle(submodule){
    return new Promise((resolve, reject) => {
        GetAllRenewalPermits(submodule).then(renewalres => {
            console.log(renewalres);
            let renewalpermits = renewalres;
            let RegenerateProps = {};

                if(renewalpermits && Array.isArray(renewalpermits) && renewalpermits.length > 0){
                    let groupbysitepermit = groupArrayOfObjects(renewalpermits, "SitePermitTypeId");
                    if(groupbysitepermit){
                        let promises = [];
                        // for (const [key, value] of Object.entries(groupbysitepermit)) {
                        //     promises.push(GetComputedFee(key, 'SIGN_INFORMATION', { "SITE_PERMIT_TYPE_ID": key }))
                        // }
                        renewalpermits.forEach(element => {
                            promises.push(GetComputedFee(element['SitePermitTypeId'], 'SIGN_INFORMATION', { "Id": element["Id"] }))
                        });
                        Promise.all(promises).then(allres =>{
                            console.log(allres);
                            //If business rules exists modifing the fee details else processing from the static fee data.
                            if(allres && Array.isArray(allres)){
                                var mergedRef = [].concat.apply([], allres);
                                var merged = mergedRef.filter(value => Object.keys(value).length !== 0);
                                if(merged && merged.length > 0){
                                    renewalpermits.forEach(permit => {
                                        let BRFee = merged[0][permit.Id]
                                        if(BRFee){
                                            let dynamicpermitfee = [];// findPropFromObject(BRFee,'DYNAMIC_PERMIT_FEE'); :code correction: findPropFromObject missing function
                                            permit.PermitRenewalFee = dynamicpermitfee[0]
                                        }
                                    })
                                }
                            }

                            var ownersitepermittypegroup = groupByMultiple(renewalpermits, function(item){
                                return [item.SitePermitTypeId, item.OwnerId];
                            });
                            let resultPemrits = [];
                            if(Array.isArray(ownersitepermittypegroup)){
                                ownersitepermittypegroup.forEach(renpermits => {
                                    let wrapperProps = { ApplicantId : renpermits[0].OwnerId, ...RegenerateProps, RenewalPermits: renpermits}
                                    console.log(wrapperProps)
                                    ReGenerateInvoiceWrapper(wrapperProps).then(invoiceres =>{
                                        resultPemrits.push(invoiceres);
                                    })
                                });
                            }
                            resolve(resultPemrits);
                        })
                    }
                }else{
                   // reject['NORECORDS'] //Expected an assignment or function call and instead saw an expression
                }
        })
    })


}