 /**
        *********************************************************
            deprecated
        *********************************************************
 **/
import React, { Component, Fragment } from 'react';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { checkcomp } from './utilities';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    }
});

class CToggleButton extends Component {
    state = {
        globalitem: null,
        open: true
    }

    handleClick = (name, rules, passer) => event => {

        if (rules) {
            const changedval = checkcomp(!this.state.open, rules, 'onClick', this.props.passer)
            this.setState({
                globalitem: changedval
            })
        }
        this.setState({
            open: !this.state.open
        })

    }

    render() {
        const { classes } = this.props
        const { options, rules, passer, type } = this.props;
        const { text, icon, color, disabled, href, size, variant, ...otherOptions } = options
        const { iconname, iconColor, iconFontSize, iconposition, ...otherIconProps } = icon
        return (
            <Fragment>
                {type === 'togglebutton' && <Button onClick={this.handleClick( rules, passer)}
                    color={color || 'default'}
                    disabled={disabled}
                    href={href}
                    size={size}
                    variant={variant}
                    {...otherOptions}
                >
                    {text}
                    {(iconposition === 'left') && <Icon
                        className={classes.leftIcon} color={iconColor || 'inherit'} fontSize={iconFontSize || 'default'} {...otherIconProps}>{iconname}
                    </Icon>
                    }
                    {!(iconposition === 'left') && <Icon
                        className={classes.rightIcon} color={iconColor || 'inherit'} fontSize={iconFontSize || 'default'} {...otherIconProps}>{iconname}
                    </Icon>
                    }

                </Button>}
                {type === 'toggleicon' && <Icon
                    onClick={this.handleClick( rules, passer)}
                    color={'primary'} fontSize={iconFontSize || 'default'} {...otherIconProps}
                >
                    {iconname}
                </Icon>
                }
                {this.state.globalitem}
            </Fragment>

        )
    }
}

export default withStyles(styles)(CToggleButton);



/**
 * {
 * type:'button',
 * options:{
 *  text:'alarm',
 *  icon:'alarm',
 * button:''
 * },
 * defaults:{
 *  theme:'default',
 *  custom: false
 * }
 * }
 */