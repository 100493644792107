export function GetAppDownloadTableDetails(tablename){
    switch(tablename) {
        case "Invoices":
            return Invoice();
        case "InvoiceLineItems":
            return InvoiceLineItems();
        case "InvoiceDetails":
            return InvoiceDetails();
        case "InvoicePayments":
        case "BillingPaymentDetails":
            return BillingPaymentDetails();
        default:
            return [];
      }
}

function Invoice(){
    return [
        //{"Key":"ADDRESS_LINE_1","Label":"Address Line 1","IsTemplate":false,"TemplateId":null,"Value":"Address Line 1","Section":"Applicant Information"}
        {"Key":"Id","Label":"Id","IsTemplate":false,"TemplateId":null,"Value":"Id","Section":"Invoices"},
        {"Key":"InvoiceTypeKey","Label":"InvoiceTypeKey","IsTemplate":false,"TemplateId":null,"Value":"InvoiceTypeKey","Section":"Invoices"},
        {"Key":"InvoiceDate","Label":"InvoiceDate","IsTemplate":false,"TemplateId":null,"Value":"InvoiceDate","Section":"Invoices"},
        {"Key":"Status","Label":"Status","IsTemplate":false,"TemplateId":null,"Value":"Status","Section":"Invoices"},
        {"Key":"PaymentReferenceId","Label":"PaymentReferenceId","IsTemplate":false,"TemplateId":null,"Value":"PaymentReferenceId","Section":"Invoices"},
        {"Key":"DueDate","Label":"DueDate","IsTemplate":false,"TemplateId":null,"Value":"DueDate","Section":"Invoices"},
        {"Key":"InvoiceType","Label":"InvoiceType","IsTemplate":false,"TemplateId":null,"Value":"InvoiceType","Section":"Invoices"},        
        {"Key":"CreatedBy","Label":"CreatedBy","IsTemplate":false,"TemplateId":null,"Value":"CreatedBy","Section":"Invoices"},
        {"Key":"CreatedOn","Label":"CreatedOn","IsTemplate":false,"TemplateId":null,"Value":"CreatedOn","Section":"Invoices"},
        {"Key":"UpdatedBy","Label":"UpdatedBy","IsTemplate":false,"TemplateId":null,"Value":"UpdatedBy","Section":"Invoices"},
        {"Key":"UpdatedOn","Label":"UpdatedOn","IsTemplate":false,"TemplateId":null,"Value":"UpdatedOn","Section":"Invoices"},        
        {"Key":"CreatedByName","Label":"CreatedByName","IsTemplate":false,"TemplateId":null,"Value":"CreatedByName","Section":"Invoices"},
        {"Key":"UpdatedByName","Label":"UpdatedByName","IsTemplate":false,"TemplateId":null,"Value":"UpdatedByName","Section":"Invoices"},
        {"Key":"PermitNumber","Label":"PermitNumber","IsTemplate":false,"TemplateId":null,"Value":"PermitNumber","Section":"Invoices"},
        {"Key":"ApplicantId","Label":"ApplicantId","IsTemplate":false,"TemplateId":null,"Value":"ApplicantId","Section":"Invoices"},
        {"Key":"ApplicantName","Label":"ApplicantName","IsTemplate":false,"TemplateId":null,"Value":"ApplicantName","Section":"Invoices"},
        {"Key":"District","Label":"District","IsTemplate":false,"TemplateId":null,"Value":"District","Section":"Invoices"},
        {"Key":"SubmittedDate","Label":"SubmittedDate","IsTemplate":false,"TemplateId":null,"Value":"SubmittedDate","Section":"Invoices"},
        {"Key":"AuthorizedBy","Label":"AuthorizedBy","IsTemplate":false,"TemplateId":null,"Value":"AuthorizedBy","Section":"Invoices"},
        {"Key":"InvoiceDateFormat","Label":"InvoiceDateFormat","IsTemplate":false,"TemplateId":null,"Value":"InvoiceDateFormat","Section":"Invoices"},
        {"Key":"InvoiceDueDateFormat","Label":"InvoiceDueDateFormat","IsTemplate":false,"TemplateId":null,"Value":"InvoiceDueDateFormat","Section":"Invoices"},
        {"Key":"InvoiceStatusFormat","Label":"InvoiceStatusFormat","IsTemplate":false,"TemplateId":null,"Value":"InvoiceStatusFormat","Section":"Invoices"},
        {"Key":"InvoiceTotalAmount","Label":"InvoiceTotalAmount","IsTemplate":false,"TemplateId":null,"Value":"InvoiceTotalAmount","Section":"Invoices"},
        {"Key":"InvoicePaidAmount","Label":"InvoicePaidAmount","IsTemplate":false,"TemplateId":null,"Value":"InvoicePaidAmount","Section":"Invoices"},
    ]
}

function InvoiceLineItems(){
    return [        
        {"Key":"Id","Label":"Id","IsTemplate":false,"TemplateId":null,"Value":"Id","Section":"InvoiceLineItems"},
        {"Key":"RefId","Label":"RefId","IsTemplate":false,"TemplateId":null,"Value":"RefId","Section":"InvoiceLineItems"},
        {"Key":"RefType","Label":"RefType","IsTemplate":false,"TemplateId":null,"Value":"RefType","Section":"InvoiceLineItems"},
        {"Key":"OwnerId","Label":"OwnerId","IsTemplate":false,"TemplateId":null,"Value":"OwnerId","Section":"InvoiceLineItems"}        
    ]
}

function InvoiceDetails(){
    return [        
        {"Key":"Id","Label":"Id","IsTemplate":false,"TemplateId":null,"Value":"Id","Section":"InvoiceDetails"},
        {"Key":"InvoiceId","Label":"InvoiceId","IsTemplate":false,"TemplateId":null,"Value":"InvoiceId","Section":"InvoiceDetails"},
        {"Key":"Name","Label":"Name","IsTemplate":false,"TemplateId":null,"Value":"Name","Section":"InvoiceDetails"},
        {"Key":"Description","Label":"Description","IsTemplate":false,"TemplateId":null,"Value":"Description","Section":"InvoiceDetails"},
        {"Key":"Amount","Label":"Amount","IsTemplate":false,"TemplateId":null,"Value":"Amount","Section":"InvoiceDetails"}                
    ]
}

function BillingPaymentDetails(){
    return [        
        {"Key":"InvoiceId","Label":"InvoiceId","IsTemplate":false,"TemplateId":null,"Value":"invoiceId","Section":"BillingPaymentDetails"},
        {"Key":"OwnerId","Label":"OwnerId","IsTemplate":false,"TemplateId":null,"Value":"ownerId","Section":"BillingPaymentDetails"},
        {"Key":"Type","Label":"Type","IsTemplate":false,"TemplateId":null,"Value":"type","Section":"BillingPaymentDetails"},
        {"Key":"Status","Label":"Status","IsTemplate":false,"TemplateId":null,"Value":"status","Section":"BillingPaymentDetails"},
        {"Key":"TransactionDate","Label":"TransactionDate","IsTemplate":false,"TemplateId":null,"Value":"transactionDate","Section":"BillingPaymentDetails"},
        {"Key":"TransactionId","Label":"TransactionId","IsTemplate":false,"TemplateId":null,"Value":"transactionId","Section":"BillingPaymentDetails"},
        {"Key":"TotalAmount","Label":"TotalAmount","IsTemplate":false,"TemplateId":null,"Value":"totalAmount","Section":"BillingPaymentDetails"},
        {"Key":"PayeeName","Label":"PayeeName","IsTemplate":false,"TemplateId":null,"Value":"payeeName","Section":"BillingPaymentDetails"},
        {"Key":"PayeeAddressLine1","Label":"PayeeAddressLine1","IsTemplate":false,"TemplateId":null,"Value":"payeeAddressLine1","Section":"BillingPaymentDetails"},
        {"Key":"PayeeAddressLine2","Label":"PayeeAddressLine2","IsTemplate":false,"TemplateId":null,"Value":"payeeAddressLine2","Section":"BillingPaymentDetails"},
        {"Key":"PayeeCity","Label":"PayeeCity","IsTemplate":false,"TemplateId":null,"Value":"payeeCity","Section":"BillingPaymentDetails"},
        {"Key":"PayeeState","Label":"PayeeState","IsTemplate":false,"TemplateId":null,"Value":"payeeState","Section":"BillingPaymentDetails"},
        {"Key":"PayeeZip","Label":"PayeeZip","IsTemplate":false,"TemplateId":null,"Value":"payeeZip","Section":"BillingPaymentDetails"},
        {"Key":"PayeeEmail","Label":"PayeeEmail","IsTemplate":false,"TemplateId":null,"Value":"payeeEmail","Section":"BillingPaymentDetails"},

        {"Key":"PayeeLastName","Label":"PayeeLastName","IsTemplate":false,"TemplateId":null,"Value":"payeeLastName","Section":"BillingPaymentDetails"},
        {"Key":"CheckNumber","Label":"CheckNumber","IsTemplate":false,"TemplateId":null,"Value":"checkNumber","Section":"BillingPaymentDetails"},
        {"Key":"CheckDepositNumber","Label":"CheckDepositNumber","IsTemplate":false,"TemplateId":null,"Value":"checkDepositNumber","Section":"BillingPaymentDetails"},
        {"Key":"CheckBankNumber","Label":"CheckBankNumber","IsTemplate":false,"TemplateId":null,"Value":"checkBankNumber","Section":"BillingPaymentDetails"},
        {"Key":"CheckDepositDate","Label":"CheckDepositDate","IsTemplate":false,"TemplateId":null,"Value":"checkDepositDate","Section":"BillingPaymentDetails"},
        {"Key":"CheckDepositedBy","Label":"CheckDepositedBy","IsTemplate":false,"TemplateId":null,"Value":"checkDepositedBy","Section":"BillingPaymentDetails"},
        {"Key":"CheckMemo","Label":"CheckMemo","IsTemplate":false,"TemplateId":null,"Value":"checkMemo","Section":"BillingPaymentDetails"},
        
                      
    ]
}

function InvoicePayments(){
    return [        
        {"Key":"Id","Label":"Id","IsTemplate":false,"TemplateId":null,"Value":"Id","Section":"InvoicePayments"},
        {"Key":"Type","Label":"Type","IsTemplate":false,"TemplateId":null,"Value":"Type","Section":"InvoicePayments"},
        {"Key":"Name","Label":"Name","IsTemplate":false,"TemplateId":null,"Value":"Name","Section":"InvoicePayments"},
        {"Key":"Description","Label":"Description","IsTemplate":false,"TemplateId":null,"Value":"Description","Section":"InvoicePayments"},
        {"Key":"Amount","Label":"Amount","IsTemplate":false,"TemplateId":null,"Value":"Amount","Section":"InvoicePayments"}                
    ]
}