import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import React, { useCallback, useEffect, useState } from 'react';
import { formatQuery as Formatter } from 'react-querybuilder';
import { useParams } from 'react-router-dom';
import { getEnvVariable } from '../../../../../modules/environmentalList';
import Dropdown from '../../../../controls/components/dropdown';
import Header from '../../../../controls/components/header';
import Switch from '../../../../controls/components/switch';
import TextField from '../../../../controls/components/textbox';
import { GetData, PostData, PutData } from '../../../../controls/helpers/requests';
import { createRequest, requestApi } from '../../../../controls/helpers/rest';
import Static from './static';

const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 0
    },
    dragger: {
        width: "7px",
        cursor: "ew-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 100,
        //   backgroundColor: "#f4f7f9"
    }
}));



const transformProps = {
    "A": true,
    "I": false,
    true: "A",
    false: "I"
}


const defaultDrawerWidth = 900;
const minDrawerWidth = 700;
const maxDrawerWidth = window.innerWidth - 220;
const fullscreenwidth = window.innerWidth
const checkwidth = 960

const header = {
    name: "header",
    type: 'header',
    title: 'Business Rule',
    components: [
        {
            type: 'breadcrumbs',
            items: [
                { "link": "Business Rules", "materialicon": "assignment_turned_in", className: 'eps-breadcrumb-item ', class: 'text-light' },
            ]
        }
    ],
    actions: [
        {
            type: 'icon',
            icon: 'back',
            name: 'back',
            hideon: 'back',
            hidevalue: false
        },
        {
            type: 'icon',
            icon: 'duplicate',
            name: 'createduplicate',
            hideon: 'edit',
            hidevalue: true
        },
        {
            type: 'icon',
            icon: 'save',
            name: 'save',
            hideon: 'save',
            hidevalue: false
        },
    ],
}


function Rule(props) {
    const { item, section, businessrules } = props;
    const [result, setResult] = useState(item)
    const [fields, setFields] = useState([])
    const [uploaded, setUploaded] = useState(false)
    const [ruleTypeItems, setRuleTypeDropdown] = useState([])
    const [reload, setReload] = useState(false)
    const [queryError, setqueryError] = useState({ error: false, helperText: '' })
    const [nameError, setnameError] = useState({ error: false, helperText: '' })
    const [descError, setDescError] = useState({ error: false, helperText: '' })
    const [ruleError, setruleTypeError] = useState({ error: false, helperText: '' })
    const params = useParams();

    let screensize = (props.width == "sm" || props.width == "xs" || props.width == "md") ? false : true
    const [drawerWidth, setDrawerWidth] = useState((props.width == "sm" || props.width == "xs" || props.width == "md") ? fullscreenwidth : defaultDrawerWidth);
    const [fullscreen, setFullScreen] = useState(false)
    const [fullwidth, setFullWidth] = useState(fullscreenwidth)
    const [cloneVal, setCloneVal] = useState('')

    React.useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    });


    const updateWidth = () => {
        if (window.innerWidth < checkwidth) {
            setDrawerWidth(window.innerWidth);
        }
        else {
            setDrawerWidth(defaultDrawerWidth)
        }
    };

    React.useEffect(() => {
        if (!screensize) {
            let width = window.innerWidth
            setDrawerWidth(width);
            setFullWidth(width)
        }
        else {
            setDrawerWidth(defaultDrawerWidth);
            // setFullWidth(defaultDrawerWidth)
        }
    }, [props.width]);


    const handleMouseDown = e => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let newWidth =
            document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        //  const newWidth = e.clientX - document.body.offsetLeft;
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            setDrawerWidth(newWidth);
        }
    }, []);

    const handleBack = () => {
        props.handleClose()
    }

    useEffect(() => {
        let haskey = ''
        if (!result.Id && result.HashKey)
            haskey = `&HashKey=${result.HashKey}`
        const read = [
            {
                url: getEnvVariable('AdminConfig') + `/api/Domains/values?domainkey=BUSINESS_RULES_RULE_TYPE&domainid=0`,
                type: 'get'
            },
            {
                url: props.eachsection ? getEnvVariable('Application') + `/api/BusinessRules/Builder?SitePermitTypeId=${params.routeid}&sitePermitTypeSectionId=${props.section.Id}&businessRuleId=${result.Id || 0}${haskey}`
                    : getEnvVariable('Application') + `/api/BusinessRules/Builder?SitePermitTypeId=${params.routeid}&businessRuleId=${result.Id || 0}${haskey}`,
                type: 'get'
            },
        ]
        Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(response => {
            const [ruleset, fields] = response;
            setRuleTypeDropdown(ruleset)
            setFields(fields)
        })
    }, [uploaded])

    const handleFieldValue = (event) => {
        const { name, value } = event.target;
        if (name === 'Status')
            result[name] = event.target.checked ? 'A' : 'I'
        else if (name === 'EventType') {
            if (value === 'S') {
                result['NoticesIsAuto'] = 'Y'
                result['ReportIsAuto'] = 'Y'
                result['NotificationSettingsIsAuto'] = 'Y'
                result[name] = value
            }
            else {
                result['NoticesIsAuto'] = 'N'
                result['ReportIsAuto'] = 'N'
                result['NotificationSettingsIsAuto'] = 'N'
                result[name] = value
            }
        }
        else result[name] = value
        setResult(result)
        setReload(!reload)
    }
    const handleCloneItems = () => {
        let res = result
        res.query = res.JsonQueryStr !== '' ? JSON.parse(res.JsonQueryStr) : '';
        res.RuleType = res.RuleType ? res.RuleType : 'S';
        res.isCalcualtedQuery = 'Y'
        delete res['Id']
        delete res['RuleOrder']
        res.BusinessRuleFields.forEach(each=>{
            delete each['Id']
            delete each['BusinessRuleId']
        })
        setResult(res)
        //  setCloneVal(value)
        setReload(!reload)

    }

    const handleDynamicResult = (dynResponse) => {
        let tempResult = result;
        if (dynResponse) {
            if (dynResponse.CvsDateItems && dynResponse.CvsDateItems.length > 0)
                tempResult['CustomDataClxn'] = dynResponse.CvsDateItems;
            if (dynResponse.HashKey) {
                tempResult['HashKey'] = dynResponse.HashKey
            }
            setResult(tempResult)
            setUploaded(!uploaded)
        }
    }

    const onError = (errors) => {
        let errorstate = false, errorval = ''
        if (errors["Name"]) {
            if (Array.isArray(errors['Name'])) {
                errorstate = true
                errorval = errors['Name'][0]
            }
            else {
                errorstate = errors['Name'].error
                errorval = errors['Name'].errortext
            }
            setnameError({ error: errorstate, helperText: errorval })
        }
        if (errors["SqlQuery"]) {
            if (Array.isArray(errors['SqlQuery'])) {
                errorstate = true
                errorval = errors['SqlQuery'][0]
            }
            else {
                errorstate = errors['SqlQuery'].error
                errorval = errors['SqlQuery'].errortext
            }
            setqueryError({ error: errorstate, helperText: errorval })
        }
        if (errors["JsonQueryStr"]) {
            if (Array.isArray(errors['JsonQueryStr'])) {
                errorstate = true
                errorval = errors['JsonQueryStr'][0]
            }
            else {
                errorstate = errors['JsonQueryStr'].error
                errorval = errors['JsonQueryStr'].errortext
            }
            setqueryError({ error: errorstate, helperText: errorval })
        }
        if (errors["Description"]) {
            if (Array.isArray(errors['Description'])) {
                errorstate = true
                errorval = errors['Description'][0]
            }
            else {
                errorstate = errors['Description'].error
                errorval = errors['Description'].errortext
            }
            setDescError({ error: errorstate, helperText: errorval })
        }
    }

    const handleSave = () => {
        let tempResult = result
        if (tempResult && tempResult.Name) {
            tempResult.JsonQueryStr = JSON.stringify(result.query) //tempResult.RuleType === 'S' ? : ''
            tempResult.SqlQuery = Formatter(result.query, 'sql') // tempResult.RuleType === 'S' ?: ''
            tempResult.IsTriggerAnotherRuleOnFailure = tempResult.IsTriggerAnotherRuleOnFailure === 'Y' ? 'Y' : 'N';
            tempResult.NoticesEventOn = 'N';
            tempResult.NoticesOnLoad = 'N';
            tempResult.NotificationSettingsEventOn = 'N';
            tempResult.NotificationSettingsOnLoad = 'N';
            tempResult.ProjectMessagesEventOn = 'N';
            tempResult.ProjectMessagesOnLoad = 'N';
            tempResult.NotificationSettingsIsAuto = tempResult.NotificationSettingsIsAuto ? tempResult.NotificationSettingsIsAuto : 'N';
            tempResult.NoticesIsAuto = tempResult.NoticesIsAuto ? tempResult.NoticesIsAuto : 'N';
            tempResult.ReportIsAuto = tempResult.ReportIsAuto ? tempResult.ReportIsAuto : 'N';
            if (!tempResult.Id) {
                tempResult.Status = 'A';
                let url = ''
                if (props.eachsection)
                    url = getEnvVariable('AdminConfig') + `/api/BusinessRules/${params.routeid}/Add?sitePermitTypeSectionId=${props.section.Id}`
                else url = getEnvVariable('AdminConfig') + `/api/BusinessRules/${params.routeid}/Add`
                PostData(url, tempResult).then(res => {
                    props.handleClose(false)
                }).catch(error => {
                    error.then(err => {
                        if (err) {
                            tempResult.query = JSON.parse(tempResult.JsonQueryStr)
                            setResult(tempResult)
                            onError(err);
                        }
                    })
                })
            }
            else {
                let url = getEnvVariable('AdminConfig') + `/api/BusinessRules/${tempResult.Id}/Update`
                PutData(url, tempResult).then(res => {
                    props.handleClose(false)
                }).catch(error => {
                    error.then(err => {
                        if (err) {
                            tempResult.query = JSON.parse(tempResult.JsonQueryStr)
                            setResult(tempResult)
                            onError(err)
                        }
                    })
                })
            }
        }
        else {
            if (!result.Name || result.Name === undefined) {
                setnameError({ error: true, helperText: 'Name is required' })
                setReload(!reload)
            }
        }
    }


    const addOnsByName = { addOnsByName: { back: { onClick: handleBack }, save: { onClick: handleSave }, createduplicate: { onClick: handleCloneItems } } }
    let sharedProps = { edit: (result && result.Id) ? false : true }
    const classes = useStyles();
    return (
        <Drawer anchor="right" open={true}
            disableEnforceFocus={true}
            disableAutoFocus={true}
            PaperProps={{ style: { width: fullscreen ? fullwidth : drawerWidth } }}
        >
            <div onMouseDown={e => handleMouseDown(e)} className={classes.dragger} />
            <Header {...header} {...addOnsByName} sharedProps={sharedProps} />
            <div className="col-sm-12">
                {reload}

                <TextField label='Name' name='Name' value={(result && result.Name) || null} handleFieldValue={handleFieldValue} error={nameError.error} helperText={nameError.helperText} required={true} />
                <TextField label='Description' name='Description' value={(result && result.Description) || null} handleFieldValue={handleFieldValue} error={descError.error} helperText={descError.helperText} />
                <Switch label='Status' name="Status" value={(result && result.Status) || null} handleFieldValue={handleFieldValue} extraProps={{
                    transformProps: transformProps
                }} /><br />
                <Static result={result}
                    handleFieldValue={handleFieldValue} handleDynamicResult={handleDynamicResult} fields={fields} section={section} error={queryError.error} helperText={queryError.helperText} />
            </div>
        </Drawer>
    )
}

export default withWidth()(Rule)
