import React, { Component } from 'react';
import ReactDOM from "react-dom";

import { loadModules } from 'esri-loader';
import { GeometryEngineTask } from '../util/geometryEngineTask';
import Dropdown from '../../../modules/form/cformdropdown';
import { GISLookupTask, GISLookupTaskWithGeom } from '../eps/GISLookupTask';
import { ApplyEditsGeometry } from '../eps/applyEditsFeaturesTaskKEPT';
import { getEnvVariable } from '../../../../modules/environmentalList';

class CSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: this.props.view,
            options: this.props.options,
            SelectLayerListOptions: this.props.options.SelectLayerListOptions,
            selectedLayerId: 0,
            mapConstants: this.props.mapConstants,
            spatialReference: this.props.spatialReference,
            tools: ["point", "polygon", "rectangle", "circle"],
            bufferdistance: null,
            SingleSelect: false,
            bufferunits: "feet",
            enableSave: false,
            selectedLayerOptions: {}
        }
        this.startup(
            this.props
        );
        this.counter = 1
        this.onSaveorUpdate = this.onSaveorUpdate.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.clearsketch != prevProps.clearsketch && this.layer) {
            this.layer.graphics.removeAll();
        }
        if (this.props.layerCustomFilters && this.props.SubModuleKey) {
            this.setDefaultSelectLayerByModule();
        }
        if (this.props.defaultSelectLayer && this.props.defaultSelectLayer != this.state.selectedLayerOptions.Url) {
            this.setDefaultSelectLayerByUrl(this.props.defaultSelectLayer);
        }
        if (this.props.options && this.props.options.tasks && this.props.options.tasks.TaxParcels &&
            this.state.selectedLayerOptions.Url != this.props.options.tasks.TaxParcels.url) {
            let defaulturl = this.state.options.tasks.TaxParcels.url;
            this.setDefaultSelectLayerByUrl(defaulturl);
        }
    }

    setDefaultSelectLayerByUrl(url) {
        if (url && Array.isArray(this.state.SelectLayerListOptions)) {
            let selectlayer = this.state.SelectLayerListOptions.find(a => a.Url.includes(url));
            let val = selectlayer ? selectlayer.Id : null;
            if (val)
                this.handleChange({ target: { value: val } })
            else
                console.log('No layer matching for the select widget records')

            //alert('No layer matching for the select widget records');
        }
    }

    setDefaultSelectLayerByModule() {
        let customfilter = this.props.layerCustomFilters;
        if (customfilter) {
            let layers = this.props.view.map.layers.filter(function (layer) {
                return layer.LayerOptions && (layer.LayerOptions.CanSelect == "Y"
                    && layer.LayerOptions.Category.includes('S') && layer.LayerOptions.SelectionSubModules.includes(customfilter));
            });
            if (layers && layers.length > 0 && this.state.selectedLayerId != layers.items[0].LayerOptions.Id) {
                //this.setState({selectedLayerId : layers.items[0].LayerOptions.Id })
                this.handleChange({ target: { value: layers.items[0].LayerOptions.Id } })
            }
        }
    }

    RemoveNode = (clazzName) => {
        let node = this.selectcontainer.getElementsByClassName(clazzName);
        if (node !== null && node.length > 0)
            node[0].remove();

    }

    HideNode = (clazzName) => {
        let node = this.selectcontainer.getElementsByClassName(clazzName);
        if (node !== null && node.length > 0)
            node[0].style.display = "none";

    }

    ShowNode = (clazzName) => {
        let node = this.selectcontainer.getElementsByClassName(clazzName);
        if (node !== null && node.length > 0)
            node[0].style.display = "inline-block";

    }

    OnToolLoad = (Tools) => {
        this.state.view.popup.close();
        // esri-select__section esri-select__tool-section

        if (this.selectcontainer) {
            this.RemoveNode("esri-icon-undo");
            this.RemoveNode("esri-icon-redo");
            this.RemoveNode("esri-icon-map-pin");
            this.RemoveNode("esri-icon-polyline");

            let select_ToolBox_Widget_options = this.selectcontainer.getElementsByClassName("select_ToolBox_Widget_options");
            let toolboxdom = this.selectcontainer.getElementsByClassName("esri-sketch esri-widget")
            if (toolboxdom && toolboxdom.length > 0)
                toolboxdom[0].prepend(select_ToolBox_Widget_options[0]);

            var parentNodes = this.selectcontainer.getElementsByClassName("esri-sketch__section esri-sketch__tool-section")
            let pointbuffer_Widget_options = this.selectcontainer.getElementsByClassName("pointbuffer_Widget_options");
            let singleselection_Widget_options = this.selectcontainer.getElementsByClassName("singleselection_Widget_options");
            let clearselection_Widget_options = this.selectcontainer.getElementsByClassName("clearselection_Widget_options");
            if (parentNodes && parentNodes.length > 0) {
                parentNodes[1].append(singleselection_Widget_options[0]);
                parentNodes[1].append(pointbuffer_Widget_options[0]);
                parentNodes[1].append(clearselection_Widget_options[0]);
            }
            //this.RemoveNode("esri-sketch__button esri-icon-cursor");
            if (Tools && Tools.indexOf("polygon") === -1)
                this.HideNode("esri-icon-polygon");
            else
                this.ShowNode("esri-icon-polygon");

            if (Tools && Tools.indexOf("rectangle") === -1)
                this.HideNode("esri-icon-checkbox-unchecked");
            else
                this.ShowNode("esri-icon-checkbox-unchecked");

            if (Tools && Tools.indexOf("circle") === -1)
                this.HideNode("esri-icon-radio-unchecked");
            else
                this.ShowNode("esri-icon-radio-unchecked");

            if (Tools && Tools.indexOf("pointbuffer") === -1)
                this.HideNode("pointbuffer_Widget_options");
            else
                this.ShowNode("pointbuffer_Widget_options");

            if (Tools && Tools.indexOf("point") === -1)
                this.HideNode("singleselection_Widget_options");
            else {

                this.ShowNode("singleselection_Widget_options");
                if (this.props.SelectGeomInsteadOfDraw == true)
                    this.onSingleSelect();//Default Selection is single

            }
            this.ShowNode("clearselection_Widget_options");
        }

    }

    startup = (config) => {
        if (this.state.SelectLayerListOptions && this.state.SelectLayerListOptions.length > 0) {
            if (this.state.SelectLayerListOptions.length == 1) {
                this.state.selectedLayerOptions = this.state.SelectLayerListOptions[0];
                this.state.selectedLayerId = this.state.selectedLayerOptions.Id;
            }

            loadModules([
                "esri/core/watchUtils",
                "esri/widgets/Expand",
                "esri/widgets/Sketch",
                "esri/widgets/Sketch/SketchViewModel",
                "esri/layers/GraphicsLayer",
                "esri/layers/FeatureLayer"
            ]).then(([watchUtils, Expand, SelectTool, selectViewModel, GraphicsLayer, FeatureLayer]) => {

                this.layer = config.view.map.layers.find(function (layer) {
                    return layer.title === "Selected Graphices Layer";
                });

                if (!this.layer) {
                    this.layer = new GraphicsLayer({
                        "title": "Selected Graphices Layer", listMode: 'hide'
                    });
                }
                //this.state.graphicsLayer
                this.selectVM = new selectViewModel({
                    //updateOnGraphicClick: false,
                    pointSymbol: this.state.mapConstants.PointSymbolHidden,
                    polylineSymbol: this.state.mapConstants.LineSymbol,
                    polygonSymbol: this.state.mapConstants.PolygonNoFillSymbol,
                    view: config.view,
                    layer: this.layer,
                    //updateOnGraphicClick:false
                });
                const selectTool = new SelectTool({
                    layer: this.layer,
                    viewModel: this.selectVM,
                    availableCreateTools: this.state.tools
                });
                this.selectcontainer = ReactDOM.findDOMNode(this);
                const selectExpand = new Expand({
                    expandIconClass: "esri-icon-cursor expand-tour-cursor",
                    collapseTooltip: "Collapse Select Tool",
                    expandTooltip: "Expand Select Tool",
                    view: config.view,
                    container: this.selectcontainer,
                    content: selectTool
                });
                config.view.map.layers.add(this.layer, 1);
                config.view.ui.add(this.selectcontainer, "top-right");

                // Listen to selectViewModel's events.
                this.selectVM.on("create", this.onGraphicCreate);
                this.selectVM.on("update", this.onGraphicUpdate);
                this.selectVM.on("delete", this.onGraphicdelete);
                watchUtils.pausable(this.selectVM, "activeTool", this.onActiveTool);
                this.props.widgetRenderCallBack("Select");
                const inThis = this;
                watchUtils.pausable(selectExpand, "expanded", function (newValue, oldValue) {
                    inThis.OnToolLoad(inThis.state.selectedLayerOptions != null ? inThis.state.selectedLayerOptions.SelectTools : inThis.state.tools);
                    inThis.props.onexpanded(selectExpand, newValue);
                });
                this.OnToolLoad(this.state.selectedLayerOptions != null ? this.state.selectedLayerOptions.SelectTools : this.state.tools);
            });
        }
    }

    onActiveTool = (tooltype) => {
        if (this.state.selectedLayerId && this.state.selectedLayerId > 0) {
            if (tooltype != null) {
                this.layer.graphics.removeAll();
            }
        } else {
            //alert('Please select the layer');
            console.log('Please select the layer');
        }
    }

    onGraphicCreate = (event) => {
        this.state.view.popup.close();
        //  event.stopPropagation();
        var flag = false;
        if (event.state === "complete") {
            if (this.props.view.zoom < 8) {
                //alert('Please zoom in further');
                this.layer.remove(event.graphic);
            } else {
                if (event.tool == "point" && this.state.SingleSelect)//adding buffer point
                {
                    this.bufferGraphic(event, flag, 50, this.state.bufferunits)
                }
                else if (event.tool == "point" && this.state.bufferdistance)//adding buffer point
                {
                    this.bufferGraphic(event, flag, this.state.bufferdistance, this.state.bufferunits)
                }
                else {
                    this.onGraphicCreateComplete(event, flag);
                }
            }
        }
    }
    bufferGraphic(event, flag, bufferdistance, bufferunits) {
        const inthis = this;
        loadModules([
            "esri/Graphic",
        ]).then(([Graphic]) => {
            GeometryEngineTask({ "geometries": [event.graphic.geometry], "type": "buffer", "distance": bufferdistance, "unit": bufferunits }).then(
                GeomEngineresponse => {
                    inthis.pointgraphic = event.graphic
                    let g = new Graphic(GeomEngineresponse[0]);
                    g.symbol = inthis.state.mapConstants.PolygonNoFillSymbol;
                    event.graphic = g;
                    inthis.layer.graphics.add(g);
                    this.onGraphicCreateComplete(event, flag);
                },
                GeomEngineerror => {
                    console.log(GeomEngineerror);
                });
        });
    }

    onGraphicCreateComplete(event, flag) {
        this.graphic = event.graphic;
        //Graphic fetch
        this.onAreaSelection()
    }
    onGraphicdelete = (event) => {
        this.graphic = null;
        this.onSaveorUpdate()
    }

    onGraphicUpdate = (event) => {
        this.state.view.popup.close();
        this.setState({ enableSave: true })
        this.graphic = event.graphics[0];
        /* if(event.state=="cancel"){
             this.graphic=null;
             this.onSaveorUpdate()
         }else{
             this.graphic = event.graphics[0];
         }*/
    }

    //Specific to KEPT for selecting multiple locations
    onAreaSelection(cleargraphic) {
        this.counter = this.counter + 1;
        let graphicJson = {}
        if (this.graphic)
            graphicJson = { counter: this.counter, type: this.graphic.geometry.type, graphic: this.graphic }
        else
            graphicJson = { counter: this.counter, cleargraphic: cleargraphic }
        if (this.props.onAreaSelection) {
            if (this.state.selectedLayerOptions)
                this.state.selectedLayerOptions.AutoSelect = false;

            if (this.state.SingleSelect) {
                if (this.state.selectedLayerOptions)
                    this.state.selectedLayerOptions.AutoSelect = this.state.SingleSelect;

                this.removeGraphic(this.graphic);
                this.removeGraphic(this.pointgraphic);
                if (this.props.SelectGeomInsteadOfDraw == true)
                    this.onSingleSelect();//Default Selection is single
                //this.setState({SingleSelect: false})
            }
            this.props.onAreaSelection(graphicJson, this.state.selectedLayerOptions);
            if (getEnvVariable('ModuleKey') == 'AEPS') {
                this.setGISLookUpFieldsData()
            }

        }
    }

    setGISLookUpFieldsData() {
        if (this.props.onSelectSaveorUpdate)
            this.props.onSelectSaveorUpdate([this.graphic], "point");
    }



    removeGraphic(graphic) {
        if (graphic) {
            this.layer.graphics.remove(graphic);
        }
    }
    handleChange(evt) {
        const { value } = evt.target;
        const selectedLayerId = value;
        const selectedLayerOptions = this.state.SelectLayerListOptions.find(res => { return res.Id === value });
        if (selectedLayerOptions != null && this.state.selectedLayerId != selectedLayerId) {
            this.setState({ selectedLayerId: selectedLayerId, selectedLayerOptions: selectedLayerOptions })
            this.onAreaSelection(true);
            this.OnToolLoad(selectedLayerOptions.SelectTools);
        }
    }
    updateState(event, inthis) {
        let name = event.target.name
        let val = event.target.value
        inthis.setState({ [name]: val })
    }

    onSaveorUpdate = () => {
        if (this.graphic !== null) {
            if (this.selectVM && this.layer) {
                this.selectVM.cancel()

                this.layer.graphics.add(this.graphic);
                this.onAreaSelection(false)
            }
        } else {
            this.onAreaSelection(true);
        }
        this.setState({ enableSave: false })
    }
    onBufferPoint = () => {
        if (this.state.bufferdistance)
            this.selectVM.create("point");
        else
            alert("please enter Buffer Distance")
    }
    onSingleSelect = () => {
        this.setState({ SingleSelect: true })
        this.selectVM.create("point");
    }

    onClear = () => {
        if (this.props.onAreaClear)
            this.props.onAreaClear();
    }


    render() {
        return (
            <div className="select_ToolBox_Widget" style={{ order: this.props.order }}>
                <div className="select_ToolBox_Widget_options" style={{ "padding": "5px", "Width": "150px" }}>
                    {this.state.SelectLayerListOptions.length > 1 && <span>
                        <Dropdown label={'Select Layer'} edit={true} handleFieldChange={(evt) => this.handleChange(evt)} name="selectedLayerId" value={this.state.selectedLayerId || 0}
                            dropdownvalues={this.state.SelectLayerListOptions || []} transformProps={{
                                "value": "Id",
                                "name": "title"
                            }} />
                    </span>}
                </div>
                <span className="singleselection_Widget_options" style={{ "width": "max-content" }}>
                    <span className="singleselection_Widget_options" style={{ "width": "max-content" }}>
                        <span style={{ "position": "relative", "cursor": "pointer" }}>
                            <button aria-label="Select" className="esri-sketch__button esri-icon-applications" title="Single Select" style={{ "display": "inline-block" }} onClick={this.onSingleSelect}></button>
                        </span>
                    </span>
                </span>
                <span className="clearselection_Widget_options" style={{ "width": "max-content" }}>
                    <span className="clearselection_Widget_options" style={{ "width": "max-content" }}>
                        <span style={{ "position": "relative", "cursor": "pointer" }}>
                            <button aria-label="Clear" className="esri-sketch__button esri-icon-close-circled" title="Clear Selection" style={{ "display": "inline-block" }} onClick={this.onClear}></button>
                        </span>
                    </span>
                </span>
                <span className="pointbuffer_Widget_options" style={{ "width": "max-content" }}>
                    <span className="pointWithBuffer_Widget_options" style={{ "width": "max-content" }}>
                        <span style={{ "position": "relative", "cursor": "pointer" }}>
                            <button aria-label="Draw a point (Buffer)" className="esri-sketch__button esri-icon-radio-checked" title="Draw a point (Buffer)" style={{ "display": "inline-block" }} onClick={this.onBufferPoint}></button>
                        </span>

                        &nbsp;
                        <input style={{ "width": "120px", "height": "30px" }} id="bufferdistance" title="Buffer distance in feets" className="bufferdistance" type="number" name="bufferdistance" min="1" placeholder="Buffer (Feets)" value={this.state.bufferdistance} onChange={(event) => this.updateState(event, this)} />
                        &nbsp;
                    </span>
                    {this.state.enableSave && <span style={{ "position": "relative", "cursor": "pointer" }} aria-label="Save" title="Save" role="button">
                        <button aria-label="Save" className="esri-sketch__button esri-icon-save" title="Save" style={{ "display": "inline-block" }} onClick={this.onSaveorUpdate}></button>
                    </span>
                    }
                    {/*<select style={{ "width": "70px", "height": "30px" }} id="bufferunits" name="units" className="bufferunits" value={this.state.bufferunits} onChange={(event) => this.updateState(event, this)}>
                        <option value="">Units</option>
                        <option value="feet">Feet (ft)</option>
                        <option value="meters">Meters (m)</option>
                        <option value="kilometers">Kilometers (km)</option>
                        <option value="miles">Miles (mi)</option>
                    </select>
                    */}
                </span>
            </div>
        );
    }
}

export default CSelect;