/**
        *********************************************************
        Description: Handles passing the settings from sections and fields or inspections to the form component.
        form component manages the state and returns the changed state.
        Required props:   N/A
        limitations    : N/A
        *********************************************************
    **/

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import FormContainer from '../../core/modules/form/cform'
import SlidingButton from '../../core/modules/form/cslidingpage'
import Button from '@material-ui/core/Button';
import FormDropDown from '../../core/modules/form/cformdropdown'
import SaveIcon from '@material-ui/icons/Save';
import Fab from '@material-ui/core/Fab';

const dropdowntransformProps = {
    name: 'Name',
    value: 'SectionId'
}

class SitePermitsSectionsFields extends Component {

    state = {
        newsectiondropdown: ''
    }

    handleFieldChange = (event) => {
        const { value, name } = event.target;
        const { availableSections } = this.props;

        let selectedsection = {}
        if (availableSections && Array.isArray(availableSections) && availableSections.length > 0) {
            selectedsection = availableSections.filter((e, index) => {//.slice(1)  Removed as First selected section is deleted resulting undefin or no name in section and fields
                return e.SectionId === value;
            })
        }
        this.setState({
            [name]: value,
            selectedsection: selectedsection[0]
        })
    }



    render() {
        const { components, options, err, compmap, selectedParentSection, sectionsettings, fieldsettings, togglebackslider, handleBackSlider, opensection, openfield, isNewSection, sectionData, availableSections } = this.props
        const { settings } = options
        const { newsectiondropdown, selectedsection } = this.state
        return (
            <Fragment>

                {
                    opensection && !isNewSection && <SlidingButton {...settings.section} key='sectionsettings' togglebackslider={togglebackslider} handleBackSlider={handleBackSlider} >
                        {settings.section.items.map(each => {
                            const { options, type, key } = each
                            const EachComp = compmap[type]
                            return <EachComp compmap={compmap} key={key} errfieldnames={err} errors={err ? true : false} {...each} getState={(res) => this.props.getState(res, 'section')} results={sectionsettings} />
                        })
                        }
                    </SlidingButton>

                }
                {
                    opensection && isNewSection && <SlidingButton {...settings.section} key='newsectionsettings' togglebackslider={togglebackslider} handleBackSlider={handleBackSlider} >
                        <div className="col-sm-12">
                            {selectedsection && <Fab className="float-right p-1" title="Save Sections" size="small" color="secondary" aria-label="save"
                                onClick={(e) => { this.setState({ selectedsection: null }, () => this.props.OnAddNewSection(e, sectionsettings, selectedParentSection)) }}>
                                <SaveIcon />
                            </Fab>}
                            <div className="clear-fix" />
                            <div className="text-primary p-2">Please select the below dropdown </div>


                            <FormDropDown label="Select" name='newsectiondropdown' value={newsectiondropdown || ""} dropdownvalues={availableSections} transformProps={dropdowntransformProps} handleFieldChange={this.handleFieldChange} />
                            {newsectiondropdown && settings.section.items.map(each => {
                                const { options, type, key } = each
                                const EachComp = compmap[type]
                                return <EachComp compmap={compmap} key={key} {...each} getState={(res) => this.props.getState(res, 'newsection', selectedsection)} results={this.state.selectedsection} />
                            })
                            }
                        </div>
                    </SlidingButton>

                }
                {
                    openfield && <SlidingButton {...settings.field} key='fieldsettings' togglebackslider={togglebackslider} handleBackSlider={handleBackSlider} >
                        {settings.field.items.map(each => {
                            const { options, type, key } = each
                            const EachComp = compmap[type]
                            return <EachComp compmap={compmap} key={key} {...each} getState={(res) => this.props.getState(res, 'field', sectionsettings)} results={fieldsettings} />
                        })
                        }
                    </SlidingButton>
                }
            </Fragment>
        )
    }


}

export default withRouter(SitePermitsSectionsFields)