import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FormHelperText from '@material-ui/core/FormHelperText';
import CheckBoxAutoComplete from '../../controls/components/checkautocomplete'
import DefaultSettings from '../../controls/components/settings';

/**
 * multi dropdown returns values with string instead array
 * @param {} props
 */

const defaults = {
    transformProps: {
        label: 'label',
        value: 'value'
    },
}

export default function CheckboxesTags(props) {
    const { handleFieldChange, dropdownvalues, contexthelp, edit, name, label, tooltip, disabled, error, required,
        defaultValue, htmlForLabel, helperText, id, transformProps, crud, styles, className, multiple, conditions, extratempProps, ...otherProps } = props;
    let propsVal = [];
    let initalpropval = props.value || ''
    if (typeof initalpropval === 'string' && initalpropval !== '') {
        propsVal = initalpropval.split(',')
        // propsVal.push(initalpropval)
    }
    else propsVal = initalpropval

    let autoOptions = []
    dropdownvalues && Array.isArray(dropdownvalues) && dropdownvalues.forEach((each, index) => {
        const { label, value } = transformProps
        const name = each[label]
        const key = each[value]
        autoOptions.push({ label: name, value: key })
    })
    let value = []
    propsVal && propsVal.length > 0 && propsVal.forEach(val => {
        autoOptions.forEach(auto => {
            if (auto['value'] == val)
                value.push(auto)
        })
    });

    const handleChange = (e) => {
        // propsVal = []
        // option.map(res => {
        //     propsVal.push(res.value)
        // })
        // // value.push(option)
        handleFieldChange(e);
    }


    const options = autoOptions || []
    if (edit === false) {
        return <Fragment>
            {tooltip && <div class > <Typography variant={'caption'} className="fw-bold" color={'textPrimary'}>{label}&nbsp;
                < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                    <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} onClick={() => DefaultSettings.showDrawer(contexthelp)} />
                </Tooltip>  </Typography>   <Typography variant={'body2'} color={'textPrimary'} className="text-secondary"  >{propsVal && propsVal.join() || ''}   </Typography>
            </div>}
            {!tooltip && <div > <Typography variant={'caption'} className="fw-bold" color={'textPrimary'}>{label}&nbsp; </Typography>
                <Typography variant={'body2'} color={'textPrimary'} className="text-secondary"            >{(propsVal && propsVal.join()) || ''}   </Typography>
            </div>}
        </Fragment >
    }
    if (edit) {
        return (
            <CheckBoxAutoComplete
                ismulti={multiple || true}
                label={label}
                name={name}
                items={options}
                disabled={disabled || false}
                error={error}
                required={required}
                extraProps={extratempProps || defaults}
                value={props.value || ''}
                helperText={helperText}
                handleFieldValue={handleChange} />
        );
    }

}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

/* <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={options}
          value={value || []}
          filterSelectedOptions
          getOptionLabel={(option) => option.label}
          onChange={(event, option) => handleChange(event, option)}
          inputProps={{
              name: name,
              id: id || name
          }}
          InputLabelProps={{
              shrink: true,
          }
          }
          renderInput={(params) => (
              <TextField {...params}  error={error}
              required={required} label={label} />
          )}
      /> */