/**
        *********************************************************
        Description: Handles MasterFieldsList. Probably this component can be removed and managed by form.
        Required props:   commap, components, options.
        limitations    : custom module which only hanldes masterfields.
        *********************************************************
    **/

import React, { Component, Fragment } from 'react';
import { requestApi } from '../../core/modules/form/utilities'
import SubForm from '../../core/modules/form/csubform';
import { withRouter } from 'react-router-dom';
import FormHeader from '../../core/modules/form/cformheader'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { snackbarMessage } from '../../core/actions';
import SimpleGrid from '../../core/modules/simplegrid';
import { globalvalidate } from '../../core/modules/form/validations'
import CustomAlert from '../../core/modules/form/calertbox'
import { T } from 'ramda';
class MasterFieldsList extends Component {
    constructor(props) {
        super(props);
        const { compmap, components, options, datecomponents, createdcomponents } = this.props
        const { conditionalComponents, newmasterfieldslist } = options
        this.effects = {

        }


        /**
        *********************************************************
        Description: Initialization. Conditional components are hidden.
        Required props:   commap, components, options.
        limitations    : custom module which only hanldes masterfields.
        *********************************************************
    **/

        conditionalComponents.forEach(each => {
            const { options } = each
            const { name, isHidden } = options

            if (isHidden) this.effects[name] = {
                show: false
            }
        })
        this.newcomponents = components.concat(conditionalComponents).map(each => {
            return { ...each, options: { ...each.options, edit: newmasterfieldslist ? true : false } }
        })
        if (datecomponents)
            this.datecomps = datecomponents.map(each => {
                return { ...each, options: { ...each.options, edit: false } }
            })

        if (createdcomponents)
            this.createcomps = createdcomponents.map(each => {
                return { ...each, options: { ...each.options, edit: false } }
            })
        this.state = {
            changedFieldName: [],
            opensnackbar: false,
            snackbarmessageType: '',
            snackbarmessage: '',
            results: newmasterfieldslist ? {} : null,
            isedited: false,
            showview: false,
            displayalert: false,
           // isduplicate: false,
        }
    }

    /**
      *********************************************************
      Description: Used to convert all non-editable components to editable by setting edit to true.
      Required props:   this.newcomponents. Newcomponents equals conditional components and non-conditional components.
      limitations    : N/A
      *********************************************************
      **/

    handleEdit = () => {
        const { options } = this.props
        const { results, showview, isedited } = this.state
        this.setState({
            isedited: !isedited,
            displayalert: false,
            dateshowcomp: this.datecomps.map(each => {
                const { options } = each
                const { name } = options
                return { name: name, value: results[name] }
            }),
            changedFieldName: this.newcomponents.filter(f => {
                const { options } = f
                if (results.UIFieldTypeComponent === 'CF' && options.name === 'MasterDomainKey') return false
                else return true

            }).map(each => {
                const { options } = each
                const { name } = options

                if (name === 'FieldProps.AllowDate') {
                    let AllowDate = results && results['FieldProps'] && results['FieldProps'].AllowDate ? results['FieldProps'].AllowDate : ''
                    return { edit: showview ? !showview : true, editduplicate: this.state.isduplicate, name: name, value: AllowDate, effect: { show: (this.effects[name] != null) ? this.effects[name].show : true } }
                }
                if (name === 'FieldProps.CurrentDefaultDate') {
                    let currentDefaultDate = results && results['FieldProps'] && results['FieldProps'].CurrentDefaultDate ? results['FieldProps'].CurrentDefaultDate : ''
                    return { edit: showview ? !showview : true, editduplicate: this.state.isduplicate, name: name, value: currentDefaultDate, effect: { show: (this.effects[name] != null) ? this.effects[name].show : true } }
                }
                return { edit: showview ? !showview : true, editduplicate: this.state.isduplicate, name: name, value: results[name], effect: { show: (this.effects[name] != null) ? this.effects[name].show : true } }
            }),
            showview: false,
        })

    }

    /**
     * Helper text scenarios
     */
    handleHelperText = (value, UIfieldcomponent) => {
        let helperText = "";////
        if (UIfieldcomponent && UIfieldcomponent === 'TB') {
            if (value !== "" && (value < 200 || value === 200)) {
                helperText = "Length should be in the range of 0 to 200"
            }
            else if (value > 200 && value <= 4000) {
                helperText = "Length should be in the range of 200 to 4000"
            }
            else if (value > 4000 && value <= 8000) {
                helperText = "Length should be in the range of 4000 to 8000"
            }
        }
        else helperText = "Length cannot be more than 8000"
        this.setState({ lengthHelper: helperText })
    }

    /**
        *********************************************************
        Description: Handles displaying the components. When the field value of non-conditional component is changed, the
        conditions on the conditional components are searched. If the condition is successful the component is displayed
        Required props:   compname, value, results
        limitations    : N/A
        *********************************************************
        **/

    handleShow = (compname, value, results) => {
        const { options } = this.props
        const { conditionalComponents } = options
        let hidecomp = [], showcomp = [];
        const requiredFieldNamesInUpperCase = this.requiredFieldNamesInUpperCase
        const toshow = conditionalComponents.filter(each => {
            const { options } = each
            const { name } = options;
            //DefaultValue Content
            if (value === 'P' && (name === 'DefaultValue')) return true
            if (value === 'TB' && (name === 'DefaultValue')) return true
            if (value === 'TA' && name === 'DefaultValue') return true
            if (value === 'C' && (name === 'DefaultValue')) return true
            if (value === 'DN' && name === 'DefaultValue') return true
            if (value === 'N' && (name === 'DefaultValue')) return true
            if (value === 'RT' && (name === 'DefaultValue')) return true
            if (value === 'L' && (name === 'DefaultValue')) return true
            //end of default content
            if (compname === 'UIValidation') {
                if (value === 'I' && (name === 'Length' || name === 'DefaultValue' || name === 'UIValidation' || name === 'Mask')) return true
                if (value === 'R' & (name === 'Length' || name === 'DefaultValue' || name === 'UIValidation' || name === 'RegExpression')) return true
                if (value === 'N' && name === 'Length' || name === 'DefaultValue' || name === 'UIValidation') return true
            }
            if (compname === 'Mask') {
                if (name === 'Length' || name === 'DefaultValue' || name === 'UIValidation' || name === 'Mask') return true
            }
            if (value === 'TB' && (name === 'Length' || name === 'UIValidation')) {
                return true
            }
            //  if (value === 'TB' && (name === 'Length' || name === 'UIValidation')) return true
            if (value === 'TA' && name === 'Length') return true
            if (value === 'L' && name === 'Length') return true
            if (value === 'TA' && name === 'IsSpellCheck') return true
            if (value === 'TB' && name === 'IsSpellCheck') return true
            if (value === 'DS' && name === 'DomainKey') return true
            if (value === 'AC' && name === 'DomainKey') return true
            if (compname !== 'UIValidation' && value === 'N' && (name === 'Min' || name === 'Max')) return true
            if (value === 'P' && (name === 'Min' || name === 'Max')) return true
            if (value === 'DN' && (name === 'Min' || name === 'Max' || name === 'DecimalPlace')) return true
            if (value === 'C' && (name === 'Min' || name === 'Max' || name === 'DecimalPlace')) return true
            if (value === 'RB' && (name === 'DomainKey')) return true
            if (value === 'CB' && (name === 'DomainKey')) return true
            if (value === 'DM' && (name === 'DomainKey')) return true
            // if (value === 'B' ) return true
            if (value === 'CF' && (name === 'LinkedMasterFieldId')) return true
            if (compname === 'FieldProps.AllowDate') {
                if (value === 'PD' && (name === 'FieldProps.AllowDate')) return true
                if (value === 'CD' && (name === 'FieldProps.AllowDate')) return true
                if (value === 'FD' && (name === 'FieldProps.AllowDate')) return true
            }
            if (value === 'D' && (name === 'FieldProps.AllowDate')) return true
            if (value === 'DT' && (name === 'FieldProps.AllowDate')) return true
            //end of date and time components
            hidecomp.push({ name: name, display: 'd-none', value: results[name] })
        })

        toshow.forEach(each => {
            const { options } = each
            const { name } = options
            if (name === 'DomainKey') {
                const required = name ? (requiredFieldNamesInUpperCase ? (requiredFieldNamesInUpperCase[name.toUpperCase()] ? requiredFieldNamesInUpperCase[name.toUpperCase()] : false) : false) : false
                this.requiredFields[name] = required
                requestApi(this.props.options.dropdownchoice.read).then(res => {
                    this.setState({
                        changedFieldName: [{ name: 'DomainKey', value: this.state.results['DomainKey'], display: 'd-block', dropdownvalues: res, required: required }]

                    })
                })
            }

            else if (name === 'MasterDomainKey') {
                const required = name ? (requiredFieldNamesInUpperCase ? (requiredFieldNamesInUpperCase[name.toUpperCase()] ? requiredFieldNamesInUpperCase[name.toUpperCase()] : false) : false) : false
                this.requiredFields[name] = required
                requestApi(this.props.options.masterdropdownchoice.read).then(res => {
                    this.setState({
                        changedFieldName: [{ name: 'DomainKey', value: this.state.results['DomainKey'], display: 'd-block', dropdownvalues: res, required: required }]

                    })
                })
            }
            else if (name === 'LinkedMasterFieldId') {
                const required = name ? (requiredFieldNamesInUpperCase ? (requiredFieldNamesInUpperCase[name.toUpperCase()] ? requiredFieldNamesInUpperCase[name.toUpperCase()] : false) : false) : false
                this.requiredFields[name] = required
                requestApi(this.props.options.Linkeddropdown.read).then(res => {
                    this.setState({
                        changedFieldName: [{ required: required, name: 'LinkedMasterFieldId', value: this.state.results['LinkedMasterFieldId'], display: 'd-block', dropdownvalues: this.state.linkeddropdowns, transformProps: { "value": "id", "name": "name" } }]
                    })
                })
            }
            else if (name === 'DomainKey1') {
                const required = name ? (requiredFieldNamesInUpperCase ? (requiredFieldNamesInUpperCase[name.toUpperCase()] ? requiredFieldNamesInUpperCase[name.toUpperCase()] : false) : false) : false
                this.requiredFields[name] = required
                showcomp.push({ required: required, name: 'DomainKey1', value: results['DomainKey'], display: 'd-block', dropdownvalues: [{ name: 'Yes', value: 'Y', key: 'y' }, { name: "No", value: 'N', key: 'n' }] })
            }
            else if (name === 'FieldProps.AllowDate') {
                const { defaultValue } = options
                let AllowDate = results && results['FieldProps'] && results['FieldProps'].AllowDate ? results['FieldProps'].AllowDate : (defaultValue ? defaultValue : '')
                showcomp.push({ name: 'FieldProps.AllowDate', value: AllowDate, display: 'd-block' });
                showcomp.push({
                    name: 'FieldProps.CurrentDefaultDate', value: results['FieldProps'] && results['FieldProps'].CurrentDefaultDate ?
                        results['FieldProps'].CurrentDefaultDate : 'N',
                    display: 'd-block', required: true
                })
            }
            else if (name === "UIValidation") {
                if (results[name] === 'I' || (results['Mask'] && results[name] === 'I')) {
                    const required = name ? (requiredFieldNamesInUpperCase ? (requiredFieldNamesInUpperCase[name.toUpperCase()] ? requiredFieldNamesInUpperCase[name.toUpperCase()] : false) : false) : false
                    this.requiredFields['Mask'] = required || true
                    let mask = results && results['Mask'] ? results['Mask'] : '';
                    showcomp.push({ name: name, value: results[name], display: 'd-block', required: required })
                    showcomp.push({ name: 'Mask', value: mask, display: 'd-block', required: true })//required
                }
                if (results[name] === 'R' || (results['RegExpression'] && results[name] === 'R')) {
                    const required = name ? (requiredFieldNamesInUpperCase ? (requiredFieldNamesInUpperCase[name.toUpperCase()] ? requiredFieldNamesInUpperCase[name.toUpperCase()] : false) : false) : false
                    this.requiredFields['RegExpression'] = required || true
                    let mask = results && results['RegExpression'] ? results['RegExpression'] : '';
                    showcomp.push({ name: name, value: results[name], display: 'd-block', required: required })
                    showcomp.push({ name: 'RegExpression', value: mask, display: 'd-block', required: true })
                }
                if (value == 'TB') {
                    showcomp.push({ name: 'UIValidation', value: results[name], display: 'd-block' })
                }
            }
            else if (name === 'DefaultValue') {
                const required = name ? (requiredFieldNamesInUpperCase ? (requiredFieldNamesInUpperCase[name.toUpperCase()] ? requiredFieldNamesInUpperCase[name.toUpperCase()] : false) : false) : false
                this.requiredFields[name] = required;
                if (value === 'N' || value === 'P' || value === 'C' || value === 'DN')
                    showcomp.push({ name: name, value: results[name], display: 'd-block', required: required, xtype: "Number" })
                else showcomp.push({ name: name, value: results[name], display: 'd-block', required: required, xtype: "" })
            }
            else if (name === 'Length') {
                this.handleHelperText(results[name], results['UIFieldTypeComponent'])
                const required = name ? (requiredFieldNamesInUpperCase ? (requiredFieldNamesInUpperCase[name.toUpperCase()] ? requiredFieldNamesInUpperCase[name.toUpperCase()] : false) : false) : false
                this.requiredFields[name] = required;
                showcomp.push({ name: name, value: results[name], display: 'd-block', helperText: this.state.lengthHelper })
            }
            else {
                let tempResultShow = this.state.results
                const required = name ? (requiredFieldNamesInUpperCase ? (requiredFieldNamesInUpperCase[name.toUpperCase()] ? requiredFieldNamesInUpperCase[name.toUpperCase()] : false) : false) : false
                this.requiredFields[name] = required
                showcomp.push({ name: name, value: results[name] || options.defaultValue, display: 'd-block' })
                if (!results[name] && this.props.options.newmasterfieldslist) {
                    tempResultShow[name] = (options && options.defaultValue && options.defaultValue !== null) ? options.defaultValue : 0
                    this.setState({ results: tempResultShow })
                }
            }

        })
        return hidecomp.concat(showcomp)
    }

    /**
       *********************************************************
       Description: Handles displaying the components by calling handleshow method.
       Required props:   options, components
       limitations    : N/A
       *********************************************************
       **/
    componentDidMount() {
        this.didmountinitialload();
    }


    didmountinitialload() {
        const { options, components } = this.props;
        const { validations, newmasterfieldslist } = options;
        const read = options.crud.read

        if (this.props.options.Linkeddropdown) {
            requestApi(this.props.options.Linkeddropdown.read).then(linkeddropdowns => {
                this.setState({ linkeddropdowns: linkeddropdowns })
            })
        }
        //const routeProps = options.crud.read.get.routeProps
        //const id = this.props.match.params[routeProps.value]
        var self = this
        let aryofvalidations = []
        if (!Array.isArray(validations)) {
            aryofvalidations.push(validations)
        }
        else aryofvalidations = validations

        if (validations) {
            let requiredFieldNamesInUpperCase = {}
            let toolTipFieldNamesInUpperCase = {}
            let helpContext = {}
            aryofvalidations && Promise.all(aryofvalidations.map(each => requestApi(each)))
                .then(function (values) {
                    let xvalidations = {}
                    validations && values.forEach((each, index) => {
                        const { transformProps } = aryofvalidations[index] ? aryofvalidations[index] : {}
                        const { subfieldname, addsubfieldname } = transformProps ? transformProps : {}
                        const subfieldnamelength = subfieldname ? subfieldname.length : 0
                        each && each.forEach(each => {
                            let validationname = each[transformProps.name].slice(subfieldnamelength)
                            if (addsubfieldname) validationname = addsubfieldname + validationname
                            if (each.Type === 'R' || each.Type === 'F') requiredFieldNamesInUpperCase[validationname] = true
                            if (each.Type === 'T') toolTipFieldNamesInUpperCase[validationname] = each.Message || ''
                            if (each.Type === 'H') helpContext[validationname] = each.Message || ''
                            if (Array.isArray(xvalidations[validationname])) {
                                const len = xvalidations[validationname].length
                                const lastvalidation = xvalidations[validationname][len - 1]
                                if (each.Order > lastvalidation.Order) xvalidations[validationname].push(each)
                                else xvalidations[validationname].unshift(each)
                            }
                            else {
                                xvalidations[validationname] = [each]
                            }
                        })
                        self.validations = xvalidations
                        self.requiredFieldNamesInUpperCase = requiredFieldNamesInUpperCase
                        self.toolTipFieldNamesInUpperCase = toolTipFieldNamesInUpperCase
                        self.helpContext = helpContext
                    })
                    self.fieldConfiguration()

                })
                .catch(err => {
                    console.log(err)
                })
        }

    }

    fieldConfiguration = () => {
        const { options, components, datecomponents, createdcomponents, dataItem } = this.props;
        const read = options.crud.read
        if (this.props.options.Linkeddropdown) {
            requestApi(this.props.options.Linkeddropdown.read).then(linkeddropdowns => {
                this.setState({ linkeddropdowns: linkeddropdowns })

            })
        }
        let otherchangedfield = []
        let alreadyshowncomp = []
        let dateshowcomp = null
        let createshowcomp = null
        this.requiredFields = {}
        this.tempResults = {}
        const requiredFieldNamesInUpperCase = this.requiredFieldNamesInUpperCase;
        const toolTipFieldNamesInUpperCase = this.toolTipFieldNamesInUpperCase;
        const helpContext = this.helpContext;
        if (options.newmasterfieldslist) {
            components.map(each => {
                const { options } = each
                const { name, transformProps, defaultValue } = options
                const compcrud = options ? options.crud : null
                const compread = compcrud ? compcrud.read : null
                const required = name ? (requiredFieldNamesInUpperCase ? (requiredFieldNamesInUpperCase[name.toUpperCase()] ? requiredFieldNamesInUpperCase[name.toUpperCase()] : false) : false) : false
                this.requiredFields[name] = required;
                const tooltip = name ? (toolTipFieldNamesInUpperCase ? (toolTipFieldNamesInUpperCase[name.toUpperCase()] ? toolTipFieldNamesInUpperCase[name.toUpperCase()] : '') : '') : ''
                const helpContextMsg = name ? (helpContext ? (helpContext[name.toUpperCase()] ? helpContext[name.toUpperCase()] : '') : '') : ''
                this.tempResults[name] = defaultValue || null;
                if (compread) {
                    requestApi(compread).then(resp => {
                        alreadyshowncomp.push({ name: name, required: required, tooltip: tooltip, helpContext: helpContextMsg, dropdownvalues: resp || null, transformProps: transformProps });
                        this.setState({ changedFieldName: alreadyshowncomp.concat(alreadyshowncomp), results: this.tempResults })
                    })
                }
                else {
                    alreadyshowncomp.push({ name: name, required: required, tooltip: tooltip, helpContext: helpContextMsg, })
                }
            })
            this.setState({
                changedFieldName: alreadyshowncomp.concat(otherchangedfield), results: this.tempResults
            })

        }
        else if (options.crud.read) {
            const routeProps = options.crud.read.get.routeProps
            const customProps = options.crud.read.get.customProps
            const id = routeProps ? this.props.match.params[routeProps.value] : dataItem[customProps.value]
            requestApi(read, id).then(results => {
                if (results.FieldProps) {
                    results.FieldProps = JSON.parse(results.FieldProps)
                } const IsSystem = results.IsSystem
                if (IsSystem === 'N') this.IsSystem = true
                if (IsSystem === 'Y') this.IsSystem = false
                components.map(each => {
                    const { options } = each
                    const { name, transformProps } = options
                    const compcrud = options ? options.crud : null
                    const compread = compcrud ? compcrud.read : null
                    const required = name ? (requiredFieldNamesInUpperCase ? (requiredFieldNamesInUpperCase[name.toUpperCase()] ? requiredFieldNamesInUpperCase[name.toUpperCase()] : false) : false) : false
                    this.requiredFields[name] = required;
                    const tooltip = name ? (toolTipFieldNamesInUpperCase ? (toolTipFieldNamesInUpperCase[name.toUpperCase()] ? toolTipFieldNamesInUpperCase[name.toUpperCase()] : '') : '') : ''
                    const helpContextMsg = name ? (helpContext ? (helpContext[name.toUpperCase()] ? helpContext[name.toUpperCase()] : '') : '') : ''
                    if (name === 'UIFieldTypeComponent') {
                        otherchangedfield = this.handleShow(name, results[name], results)
                        if (compread) {
                            requestApi(compread).then(resp => {
                                alreadyshowncomp.push({ name: name, required: required, tooltip: tooltip, contexthelp: helpContextMsg, dropdownvalues: resp || null, transformProps: transformProps, value: results[name] });
                                this.setState({ changedFieldName: alreadyshowncomp.concat(alreadyshowncomp) })
                            })
                        }
                    }
                    if (name === 'Status' && transformProps) {
                        let tval = transformProps ? transformProps[results[name]] : false;
                        alreadyshowncomp.push({ name: name, value: tval, required: required, tooltip: tooltip, contexthelp: helpContextMsg })
                    }
                    else alreadyshowncomp.push({ name: name, value: results[name], required: required, tooltip: tooltip, contexthelp: helpContextMsg })
                })
                if (datecomponents)
                    dateshowcomp = datecomponents.map(each => {
                        const { options } = each
                        const { name } = options
                        return { name: name, value: results[name] }
                    })

                if (createdcomponents)
                    createshowcomp = createdcomponents.map(each => {
                        const { options } = each
                        const { name } = options
                        return { name: name, value: results[name] }
                    })
                let embeddtext = "?view=embedded"
                if (results.ContextHelp && results.ContextHelp.includes(embeddtext)) {
                    let contexthelpval = results.ContextHelp.split(embeddtext)
                    results = { ...results, ContextHelp: contexthelpval[0] }
                }
                if (results.ContextHelpExternal && results.ContextHelpExternal.includes(embeddtext)) {
                    let contexthelpval = results.ContextHelpExternal.split(embeddtext)
                    results = { ...results, ContextHelpExternal: contexthelpval[0] }
                }
                this.setState({
                    results: results,
                    showdomains: true,
                    changedFieldName: alreadyshowncomp.concat(otherchangedfield),
                    dateshowcomp: dateshowcomp,
                    createshowcomp: createshowcomp
                }, () => {
                    if (options.newmasterfieldslist) {
                        this.handleEdit();
                    }
                })
            })
        }

    }

    getDomainKeys = () => {
        const { results } = this.state;
        if (results['UIFieldTypeComponent'] && (results['UIFieldTypeComponent'] === 'CB' || results['UIFieldTypeComponent'] === 'RB' || results['UIFieldTypeComponent'] === 'AC' || results['UIFieldTypeComponent'] === 'DS' || results['UIFieldTypeComponent'] === 'DM') &&
            results['DomainKey'] !== '') {
            this.setState({ showdomains: true })
        }
        else {
            this.setState({ showdomains: false })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dataItem != this.props.dataItem) {
            this.didmountinitialload();
        }

    }
    /**
        *********************************************************
        Description: Handles field changes.
        Required props:   name or checked, type
        limitations    : N/A
        *********************************************************
        **/
    handleFieldChange = (event) => {
        const { name, checked, type } = event.target
        const value = (type == 'checkbox') ? checked : event.target.value
        const checkboxvalue = event.target.value;
        let error = false, helperText = name === 'Length' ? this.state.lengthHelper : '';
        const nameinuppercase = name.toUpperCase()
        if (this.validations && this.validations[nameinuppercase]) {
            const err = globalvalidate({ [name]: this.validations[nameinuppercase] }, { [name]: value })
            if (err && err[name] && err[name].error) {
                error = err[name].error
                helperText = err[name].errortext
            }
        }
        if (name === 'DomainKey1') {
            this.setState({
                results: { ...this.state.results, ['DomainKey']: value },
                changedFieldName: [{ name: name, value: value }],
            })
        }
        else if (name === 'IsSpellCheck') {
            this.setState({
                results: { ...this.state.results, ['IsSpellCheck']: (value === 'Y' || value === true) ? 'Y' : 'N' },
                changedFieldName: [{ name: name, value: value }],
            })
        }
        else if (name === 'MasterDomainKey') {
            this.setState({
                results: { ...this.state.results, ['DomainKey']: value },
                changedFieldName: [{ name: name, value: value }]
            })
        }
        else if (name === 'FieldProps.CurrentDefaultDate') {
            var addedname = name.slice(11)
            let fieldprops = { [addedname]: value }
            this.setState({
                results: { ...this.state.results, ['FieldProps']: { ...this.state.results.FieldProps, ...fieldprops } },
                changedFieldName: [{ name: name, value: value }]
            })
        }
        else if (name === 'FieldProps.AllowDate') {
            var addedname = name.slice(11)
            let fieldprops = { [addedname]: checkboxvalue }
            this.setState({
                results: { ...this.state.results, ['FieldProps']: { ...this.state.results.FieldProps, ...fieldprops } },
                changedFieldName: [{ name: name, value: checkboxvalue, checkboxvalue: checkboxvalue }]
            })
        }
        else {
            this.setState({
                results: { ...this.state.results, [name]: value },
                changedFieldName: [{ name: name, value: value, error: error, helperText: helperText }],
                showdomains: name === 'DomainKey' ? true : false,
                domainKey: value ? value : ''
            }, () => {
                if (name === 'UIFieldTypeComponent' || name === 'UIValidation' || name === 'Mask') {
                    if (name == 'UIFieldTypeComponent' && (this.state.results['UIFieldTypeComponent'] === 'DT' || this.state.results['UIFieldTypeComponent'] === 'D')) {
                        this.setState({
                            results: { ...this.state.results, ['FieldProps']: { ...this.state.results.FieldProps, ['AllowDate']: 'CD', ['CurrentDefaultDate']: 'N' } },
                            changedFieldName: this.handleShow(name, value, this.state.results)
                        })
                    }
                    else if (name == 'UIFieldTypeComponent' && (this.state.results['UIFieldTypeComponent'] === 'B' || this.state.results['UIFieldTypeComponent'] === 'CSC' || this.state.results['UIFieldTypeComponent'] === 'RC')) {
                        this.setState({
                            results: { ...this.state.results, ['DomainKey']: 'FLAG' },
                            changedFieldName: this.handleShow(name, value, this.state.results)
                        })
                    }
                    else this.setState({
                        changedFieldName: this.handleShow(name, value, this.state.results)

                    })
                }

            })
        }
        this.getDomainKeys()
    }

    getRequiredFields = (requiredFields) => {
        let keys = requiredFields && Object.keys(requiredFields);
        const { results } = this.state;
        let returnkeys = [];
        let requiredData = ['Name', 'UIFieldTypeComponent', 'Status']
        if (results['UIFieldTypeComponent'] === 'N' || results['UIFieldTypeComponent'] === 'C' ||
            results['UIFieldTypeComponent'] === 'P' || results['UIFieldTypeComponent'] === 'DN') {
            returnkeys = keys.filter(k => {
                if (k === 'Min' || k == 'Max')
                    return k
            })
        }
        else if (results['UIFieldTypeComponent'] === 'CB' || results['UIFieldTypeComponent'] === 'AC' || results['UIFieldTypeComponent'] === 'DS' || results['UIFieldTypeComponent'] === 'DM' || results['UIFieldTypeComponent'] === 'RB') {
            returnkeys = keys.filter(k => {
                if (k === 'DomainKey')
                    return k
            })
        }
        else if (results['UIFieldTypeComponent'] === 'TB') {
            returnkeys = keys.filter(k => {
                if (results['UIValidation'] === 'R' && k === 'RegExpression')
                    return k
                if (results['UIValidation'] === 'I' && k === 'Mask')
                    return k
            })
        }
        return [...requiredData, ...returnkeys]
    }
    /**
      *********************************************************
      Description: Handles component saving.
      Required props:   crud.update.save is needed to save the results.
      limitations    : N/A
      *********************************************************
      **/
    handleSave = (e, duplicaterecord) => {
        const { options } = this.props
        const { crud } = options
        const save = duplicaterecord ? crud.duplicate : crud.update;
        let embeddtext = "?view=embedded"
        let results
        results = duplicaterecord ? { ...this.state.results, IsDuplicate: "Y" } : this.state.results;
        if ((this.state.results.ContextHelp && !this.state.results.ContextHelp.includes(embeddtext))
            || (this.state.results.ContextHelpExternal && !this.state.results.ContextHelpExternal.includes(embeddtext))) {
         // let contexthelpval = this.state.results.ContextHelp.trim() 
            let contexthelpval = (this.state.results && this.state.results.ContextHelp && this.state.results.ContextHelp.trim()) || ''
            let contexthelp = contexthelpval + embeddtext
            let extcontexthelpval = (this.state.results && this.state.results.ContextHelpExternal && this.state.results.ContextHelpExternal.trim()) || ''
            let extcontexthelp = extcontexthelpval + embeddtext
            results = { ...results, ContextHelp: contexthelp, ContextHelpExternal: extcontexthelp }
        }
        let changedFieldName = []
        const requiredFields = this.requiredFields
        const requiredFieldsKeys = this.getRequiredFields(requiredFields);
        let err
        if (requiredFieldsKeys) {
            requiredFieldsKeys.forEach(each => {
                if (requiredFields[each]) {
                    if (each === 'Status')
                        results.Status = (results.Status === 'A' || results.Status === true) ? 'A' : 'I';

                    console.log(results)
                    const value = (results && results[each]) ? results[each] : null
                    if ((value === null) || (value === undefined) || (value === '')) {
                        err = true
                        changedFieldName.push({ value: value, name: each, error: true, helperText: 'Field is Required' })
                    }
                }
            })
        }
        if (err) {
            console.log('errors----------------', changedFieldName)
            this.setState({
                errors: true,
                changedFieldName: changedFieldName,
            })
                ;
        }
        else {
            if (options.newmasterfieldslist) {
                //   results.UiavailableFor = results.UiavailableFor ? 'A' : 'I';
                results.Status = 'A';
                if (results.FieldProps)
                    results.FieldProps = JSON.stringify(results.FieldProps)
            }
            if (!options.newmasterfieldslist) {
                results.Status = (results.Status === 'A' || results.Status === true) ? 'A' : 'I';
                //     results.UiavailableFor = results.UiavailableFor ? 'A' : 'I';
                if (results.FieldProps)
                    results.FieldProps = JSON.stringify(results.FieldProps)
            }
            requestApi(save, null, results).then(response => {
                const { headerProps } = options
                const { navigateview, navigateadd } = headerProps;
                if (navigateview) {
                    if (response && response.FieldProps) {
                        response.FieldProps = JSON.parse(response.FieldProps)
                    }
                    response.Status = response.Status === 'A' ? true : false
                    this.setState({ showview: true, results: response }, () => {
                        this.handleEdit()
                    })
                }
                if (navigateadd) {
                    const { sidepopupadd } = options;
                    if (sidepopupadd) {
                        let pathname = this.props.location.pathname
                        if (response && this.props && this.props.match && this.props.match.params && options.afterSaveroutesId) {
                            this.props.match.params.Id = response[options.afterSaveroutesId]
                            pathname = pathname + '/' + this.props.match.params.Id
                        }
                        this.props.onClose();
                        this.props.history.push(pathname);
                        //this.props.onClose();
                    }
                    else {
                        const { afterSaveroutes } = options;
                        const { connectingroute, connectingrouteid, routeparams, route } = afterSaveroutes;
                        if (connectingroute && connectingrouteid) {
                            this.props.history.push(route + response[routeparams] + connectingroute + response[connectingrouteid])
                        }
                        else this.props.history.push(afterSaveroutes.route + response[afterSaveroutes.routeparams])
                    }
                }

            }).catch(error => {
                error.then(err => {
                    if (err.errors) {
                        this.onError(err.errors)
                            ;
                    }
                    else if (err) {
                        this.onError(err)

                    }
                })
            })
        }
    }
    handleView = () => {
        this.handleEdit()
    }

    handleBack = () => {
        const { options } = this.props
        const { back } = options;
        const { routes, popupback } = back;
        if (!options.newmasterfieldslist && this.state.isedited) {
            this.setState({ showview: true }, () => {
                this.handleView()
            })

        }
        else {
            if (popupback) {
                this.props.onClose()
            }
            else if (Array.isArray(routes)) {
                let path = ''
                routes.forEach(each => {
                    const { route, routeParams, matchProps } = each
                    if (routeParams) {
                        path += route + "/" + this.props.match.params[routeParams]
                    }
                    else if (matchProps) {
                        path += route + "/" + this.props.results[matchProps]
                    }
                    else {
                        path += route
                    }
                })
                this.props.history.push(path)
            }
        }
    }

    /**
     * Description: handling the save errors and displaying the helper text
     */


    onError = (errors) => {
        const { results } = this.state
        const errfieldnames = Object.keys(errors)
        let changedNames = [], errformControls = {}
        errfieldnames.forEach(each => {
            if (results[each] != undefined) {
                errformControls[each] = {
                    value: results[each],
                    error: errors[each].error,
                    touched: 1,
                    //  dropdownvalues: this.state.results[each].dropdownvalues,
                    helperText: errors[each].errortext
                }
            }
            let errorstate = false, errorval = ''
            if (errors[each]) {
                if (Array.isArray(errors[each])) {
                    errorstate = true
                    errorval = errors[each][0]
                }
                else {
                    errorstate = errors[each].error
                    errorval = errors[each].errortext
                }

            }
            if (results[each] != undefined) {
                changedNames.push({
                    name: each,
                    value: results[each],
                    error: errorstate,
                    touched: 1,
                    //   dropdownvalues: this.state.results[each].dropdownvalues,
                    helperText: errorval
                })
            }
            else {
                changedNames.push({
                    name: each,
                    value: null,
                    error: errorstate,
                    touched: 1,
                    //   dropdownvalues: this.state.results[each].dropdownvalues,
                    helperText: errorval
                })
            }
        })
        this.setState({
            //  results: { ...results, ...errformControls }, //commented becoz, on error clicking on save double returning to error screen
            changedFieldName: changedNames
        })
    }

    customhandleedit = (e, duplicate) => {
        if (duplicate) {
            this.setState({ isduplicate: false }, () => this.handleEdit())

        }
        else {
            if (this.state.results && this.state.results.IsSystem && this.state.results.IsSystem === "Y") {
                this.setState({ displayalert: true })
            }
            else {
                this.handleEdit();
            }
        }

    }
    closeAlert = () => {
        this.setState({ displayalert: false })
    }
    /**
        *********************************************************
        Description: renders the components.
        Required props:   results
        limitations    : N/A
        *********************************************************
        **/
    render() {
        const { compmap, components, options, datecomponents, createdcomponents, onNextItemClick, onPrevItemClick, onFullScreen, onExitFullScreen, fullscreen, exitfullscreen } = this.props
        const { conditionalComponents, headerProps, newmasterfieldslist, designprops } = options;
        const { navigateup, navigatedown, historyurl } = headerProps ? headerProps : {}
        const { results, displayalert } = this.state
        const { isedited } = this.state;
        return (
            <Fragment >
                {this.state.results && <Fragment>
                    <FormHeader savebutton={(isedited === false && !newmasterfieldslist) ? false : true} isedited={isedited} editbutton={(isedited === false && !newmasterfieldslist) ? true : false} onFullScreen={onFullScreen} onExitFullScreen={onExitFullScreen} fullscreen={fullscreen} exitfullscreen={exitfullscreen} onNextItemClick={onNextItemClick} onPrevItemClick={onPrevItemClick} duplicatebutton={false}
                        handleEdit={this.customhandleedit} handleSave={this.handleSave} breadcrumbs={headerProps.breadcrumbs} backbutton={headerProps.backbutton} handleBack={this.handleBack} designprops={designprops} historyurl={historyurl} navigateup={navigateup} navigatedown={navigatedown} iseditduplicate={this.state.isduplicate} rowrenderedslide={this.props.rowrenderedslide} />
                    {results && results.IsSystem === "Y" && displayalert && <CustomAlert isAction={true}
                        message={'This is a System field. Do you want to edit ?'} severity={'Warning'}
                        handleEdit={this.handleEdit} closeAlert={this.closeAlert} masterfield={true} />}
                    <SubForm {...this.props} components={this.newcomponents} handleFieldChange={this.handleFieldChange} effects={this.effects} changedFieldName={this.state.changedFieldName} />
                    {(results && (results['UIFieldTypeComponent'] === 'CB' || results['UIFieldTypeComponent'] === 'AC' || results['UIFieldTypeComponent'] === 'DS' || results['UIFieldTypeComponent'] === 'DM' || results['UIFieldTypeComponent'] === 'RB')) && this.state.showdomains && results['DomainKey'] && results['DomainKey'] !== '' &&
                        <div className="col-sm-8 m-3"><SimpleGrid gridFields={this.props.options.domainvaluesurl.gridFields} url={this.props.options.domainvaluesurl.url + results['DomainKey']} title="Look Up Information" /></div>}
                </Fragment>}
            </Fragment>
        )

    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

MasterFieldsList = connect(null, mapActions)(MasterFieldsList)

export default withRouter(MasterFieldsList)
