/**
      *********************************************************
            Deprecated
      *********************************************************
**/
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { requestApi } from './utilities';


const styles = theme => ({

});

const formstyles = {
    formcontainer: {
        //margin: 15
    },
    formrow: {
        margin: 10
    },

    itemrow: {
        margin: 0
    }
}

class CForm extends Component {
    constructor(props) {
        super(props);
        this.subforms = {};
        this.managesubforms = {};
        this.previoussubforms = {};
        this.componentSubForm = null;
        this.validations = []
        this.state = {
            //memoizeFormLayout: this.createFormLayout(),
            additionalresults: null,
            changedFieldName: null,
            formLayout: null,
            fieldValues: this.setInitialFieldResults(),
            results: this.setInitialResults()
        };
    }

    handleFieldChange=()=>{

    }

    setInitialResults = () => {
        const { components, compmap } = this.props
        let results = {}
        components.forEach(each => {
            const { options } = each
            const { name, multiplenames, initialvalue } = options
            if (name) {
                if (multiplenames) {
                    multiplenames.forEach(e => {
                        results[e] = initialvalue || ''
                    })
                }
                else {
                    const splitName = name.split(".")
                    if (splitName.length > 1) {
                        if (results[splitName[0]]) (results[splitName[0]][splitName[1]] = (initialvalue || ''))
                        else results[splitName[0]] = { [splitName[1]]: (initialvalue || '') }

                    }
                    else results[name] = initialvalue || ''
                }

            }
        })
        return results
    }


    setInitialFieldResults = () => {
        const { components, compmap } = this.props
        let results = {}
        components.forEach(each => {
            const { options } = each
            const { name, initialvalue } = options
            if (name) {
                results[name] = {
                    value: initialvalue || '',
                    error: null,
                    validations: []
                }
            }

        })
        return results
    }

    changeResults = (objProperty, len, splitName, value) => {
        if (len === 0) return value
        else {
            const index = splitName.length - len
            const name = splitName[index]
            if (name) return { ...objProperty, [name]: this.changeResults(objProperty[name], --len, splitName, value) }
        }
    }

    setFormFieldValue = (name, value, error = false, helperText = '', subform, multiplenames) => {
        console.log('name,value-------------', name, value, error, this.state.results);
        const { storeChildrenState, options } = this.props
        if (name) {
            if (multiplenames) {
                this.setState({
                    results: { ...this.state.results, ...value },
                    fieldValues: { ...this.state.fieldValues, [name]: { ...this.state.fieldValues[name], value: value, error: error, helperText: helperText } },
                    changedFieldName: [name]
                }, () => {
                    if (storeChildrenState) {
                        storeChildrenState(options.name, this.state.results)
                    }
                })
            }
            else {
                let newResults
                const splitName = name.split(".")
                if (splitName.length > 1) newResults = this.changeResults(this.state.results, splitName.length, splitName, value)

                if (subform) {
                    if (this.subforms[name]) { this.previoussubforms[name] = this.subforms[name]; this.managesubforms[name] = 'R' }
                    else this.managesubforms[name] = 'C'
                    this.subforms[name] = subform
                    this.componentSubForm = name
                }
                else {
                    if (this.subforms[name]) this.managesubforms[name] = 'D'
                }

                this.setState({
                    //results: { ...this.state.results, [name]: name.split(".").reduce((res, prop) => { return res && res[prop] }, results) },
                    results: newResults || { ...this.state.results, [name]: value },
                    fieldValues: { ...this.state.fieldValues, [name]: { ...this.state.fieldValues[name], value: value, error: error, helperText: helperText } },
                    changedFieldName: [name]
                }, () => {
                    if (storeChildrenState) {
                        storeChildrenState(options.name, this.state.results)
                    }
                })
            }

        }
        else {
            this.setState({
                results: value
            }, () => {
                if (storeChildrenState) {
                    storeChildrenState(options.name, this.state.results)
                }
            })
        }

    }
    addSubForms = (subform, fieldname) => {

    }


    getValue = (name) => {
        const { fieldValues } = this.state
        if (fieldValues) return fieldValues[name]
    }



    createFormLayout = () => {
        const { components, compmap, options } = this.props
        const { totalrows, totalcolumns, items } = options
        const allComponents = components
        const subforms = this.subforms
        let cache = [], cacheColProps = {}, cacheRowProps = [], itemslen = 0, allRows = []
        if (items) itemslen = items.length
        const rows = totalrows || (allComponents.length + itemslen)

        allComponents.forEach((each, index) => {
            const { type, options, order, key } = each
            const { width, name } = options
            const EachComp = compmap[type]
            let compwidth, row, currentValue
            if (width) compwidth = 'col-' + width
            else compwidth = 'col'
            if (totalrows) row = options.row || order || index
            else row = index
            if (this.state && this.state.fieldValues) currentValue = this.state.fieldValues[name]
            else currentValue = { value: '', error: false, validations: [] }
            cache[row] || (cache[row] = [])
            cacheColProps[name] = {
                comprow: row,
                compcol: cache[row].length,
                className: compwidth,
                compmap: compmap,
                addSubForms: this.addSubForms,
                setFormFieldValue: this.setFormFieldValue,
                jumpToStep: this.jumpToStep,
                currentValue: currentValue,
                handleFieldChange:this.handleFieldChange,
                ...each
            }
            const { className, ...compAttr } = cacheColProps[name]
            cache[row].push(
                <div className={className} key={each.key}>
                    <EachComp {...compAttr} />
                </div>
            )
        })

        for (let currentrow = 0; currentrow < rows; currentrow++) {
            const rowProps = { className: 'row', key: 'fieldrow' + currentrow, style: formstyles.formrow }
            cacheRowProps[currentrow] = rowProps
            allRows.push(
                <div {...rowProps}>
                    {cache[currentrow]}
                </div>
            )
        }

        return (fieldNames) => {
            if (fieldNames && fieldNames.length > 0) {
                fieldNames.forEach(changedFieldName => {
                    const compProps = cacheColProps[changedFieldName]
                    const EachComp = compmap[compProps.type]
                    const { comprow, compcol, className, key, ...otherCompProps } = compProps
                    const rowProps = cacheRowProps[comprow]
                    const subforms = this.subforms
                    const componentSubForm = this.componentSubForm
                    cache[comprow][compcol] = <div className={className} key={key}>
                        <EachComp {...otherCompProps} key={key} currentValue={this.state.fieldValues[changedFieldName]} />
                    </div>
                    allRows[comprow] = <div {...rowProps}>
                        {cache[comprow]}
                    </div>

                    if (subforms[componentSubForm]) {
                        if (this.managesubforms[componentSubForm] === 'C') {
                            subforms[componentSubForm].components && subforms[componentSubForm].components.map((e, i) => {
                                const type = e.type
                                const EachComp = compmap[type]
                                let { options } = e
                                let { name, width } = options
                                let newcompwidth
                                if (width) newcompwidth = 'col-' + width
                                else newcompwidth = 'col'
                                const newrow = comprow + i + 1
                                const newcol = 0
                                cacheColProps[name] = {
                                    comprow: newrow,
                                    compcol: newcol,
                                    className: newcompwidth,
                                    compmap: compmap,
                                    addSubForms: this.addSubForms,
                                    setFormFieldValue: this.setFormFieldValue,
                                    jumpToStep: this.jumpToStep,
                                    handleFieldChange:this.handleFieldChange,
                                    currentValue: this.state.fieldValues[name] || { value: '', error: false, validations: [] },
                                    ...e
                                }
                                //this.subformCompPosition[changedFieldName] || (this.subformCompPosition[changedFieldName] = [])
                                const newrowProps = { className: 'row', key: 'insertedrow' + cacheColProps[name].key, style: formstyles.formrow }
                                const { className, ...compAttr } = cacheColProps[name]
                                const insertCol = [
                                    <div className={className} key={e.key}>
                                        <EachComp {...compAttr} />
                                    </div>
                                ]
                                //this.subformCompPosition[changedFieldName].push({ row: newrow, col: newcol })
                                cache.splice(newrow, 0, insertCol)
                                cacheRowProps.splice(newrow, 0, newrowProps)
                                allRows.splice(newrow, 0,
                                    <div {...newrowProps}>
                                        {cache[newrow]}
                                    </div>
                                )
                                const colNames = Object.keys(cacheColProps)
                                colNames.forEach(eachName => {
                                    const eachcomprow = cacheColProps[eachName].comprow;
                                    (eachcomprow > (newrow)) && cacheColProps[eachName].comprow++
                                })

                            })
                        }
                        else {
                            this.previoussubforms[changedFieldName] && this.previoussubforms[changedFieldName].components && this.previoussubforms[changedFieldName].components.map(e => {
                                const { options } = e
                                const { name } = options
                                const { comprow, compcol } = cacheColProps[name]
                                cache.splice(comprow, 1)
                                cacheRowProps.splice(comprow, 1)
                                allRows.splice(comprow, 1)
                                const colNames = Object.keys(cacheColProps)
                                colNames.forEach(eachName => {
                                    const eachcomprow = cacheColProps[eachName].comprow;
                                    (eachcomprow > (comprow)) && cacheColProps[eachName].comprow--
                                })
                            })
                            if (this.managesubforms[changedFieldName] === 'R') {
                                subforms[changedFieldName].components.map((e, i) => {
                                    const type = e.type
                                    const EachComp = compmap[type]
                                    let { options } = e
                                    let { name, width } = options
                                    let newcompwidth
                                    if (width) newcompwidth = 'col-' + width
                                    else newcompwidth = 'col'
                                    const newrow = comprow + i + 1
                                    const newcol = 0
                                    cacheColProps[name] = {
                                        comprow: newrow,
                                        compcol: newcol,
                                        className: newcompwidth,
                                        compmap: compmap,
                                        addSubForms: this.addSubForms,
                                        setFormFieldValue: this.setFormFieldValue,
                                        jumpToStep: this.jumpToStep,
                                        currentValue: this.state.fieldValues[name] || { value: '', error: false, validations: [] },
                                        ...e
                                    }
                                    //this.subformCompPosition[changedFieldName] || (this.subformCompPosition[changedFieldName] = [])
                                    const newrowProps = { className: 'row', key: 'insertedrow' + cacheColProps[name].key, style: formstyles.formrow }
                                    const { className, ...compAttr } = cacheColProps[name]
                                    const insertCol = [
                                        <div className={className} key={e.key}>
                                            <EachComp {...compAttr} />
                                        </div>
                                    ]
                                    //this.subformCompPosition[changedFieldName].push({ row: newrow, col: newcol })
                                    cache.splice(newrow, 0, insertCol)
                                    cacheRowProps.splice(newrow, 0, newrowProps)
                                    allRows.splice(newrow, 0,
                                        <div {...newrowProps}>
                                            {cache[newrow]}
                                        </div>
                                    )
                                    const colNames = Object.keys(cacheColProps)
                                    colNames.forEach(eachName => {
                                        const eachcomprow = cacheColProps[eachName].comprow;
                                        (eachcomprow > (newrow)) && cacheColProps[eachName].comprow++
                                    })
                                })
                            }
                        }
                    }
                })
            }
            return allRows
        }
    }

    componentDidMount() {
        const { options, components } = this.props
        const { crud, validations } = options
        const create = crud ? crud.create : null
        const read = crud ? crud.read : null
        const del = crud ? crud.delete : null
        const update = crud ? crud.update : null
        const get = read ? read.get : null
        const routeProps = get ? get.routeProps : null
        const id = routeProps ? this.props.match.params[routeProps.value] : null

        get && requestApi({ get: get }, id).then(results => {
            const { components } = this.props
            let fieldValues = {}
            components.forEach(each => {
                const { options } = each
                const { name, multiplenames } = options
                if (name) {
                    if (multiplenames) {
                        let multiplevalues = {}
                        multiplenames.forEach(e => {
                            multiplevalues[e] = results[e]
                        })
                        fieldValues[name] = {
                            value: multiplevalues,
                            error: null,
                            validations: []
                        }
                    }
                    else {
                        fieldValues[name] = {
                            value: name.split(".").reduce((res, prop) => { return res && res[prop] }, results),
                            error: null,
                            validations: []
                        }
                    }

                }

            })
            console.log('fieldValues-----', fieldValues)
            this.setState((prevState, props) => {
                return { ...prevState, results: results, fieldValues: fieldValues }
            }, () => {
                this.setState({
                    memoizeFormLayout: this.createFormLayout()
                })
            })

        })

        !get && this.setState({
            memoizeFormLayout: this.createFormLayout()
        })

    }

    // reCalculateFieldLayout = (results) => {
    //     const id = this.props.match.params.routeid
    //     const { rest } = this.props.options
    //     const { options, components, defaults, order, type, compmap, actions, storeChildrenState, ...otherProps } = this.props;
    //     const { totalcolumns, totalrows, rules, layout } = options
    //     this.setState({
    //         fieldLayout: { ...this.state.fieldLayout }
    //     })
    // }

    jumpToStep = (step, rest) => {
        const id = this.props.match.params.routeid
        const { fetchData, options } = this.props
        if (rest) {
            const requestOutput = requestApi(rest, id, this.state.results, fetchData)
            requestOutput.then(results => {
                const keys = this.state.results && Object.keys(this.state.results)
                results && this.props.goToNext(step)
            })
        }
        else {
            this.props.goToNext(step)
        }
    }



    setAdditionalFormFieldValue = (name, value) => {
        console.log('name,value additionalvalues-------------', name, value);
        const { storeChildrenState, options } = this.props
        this.setState({
            additionalresults: { ...this.state.additionalresults, [name]: value }
        })
    }

    onSave = () => (event) => {
        const id = this.props.match.params.routeid
        const { options } = this.props
        const { actions } = options
        const save = actions.crud.update
        const { fetchData, onClose } = this.props
        if (save) {
            requestApi(save, id, this.state.results, fetchData).then(results => {
                console.log('after save---', results);
                if (save.post.newroute) {
                    const { route, matchProps } = save.post.newroute;
                    const pushlink = route + '/' + results[matchProps.value];
                    this.props.history.push(`${pushlink}`)
                }
                if (onClose) onClose()

            }).catch(error => {
                error.then(err => {
                    const { results, fieldValues } = this.state
                    const errfieldnames = Object.keys(err)
                    let errresults, errfieldValues = {}
                    errfieldnames.forEach(each => {
                        errfieldValues[each] = {
                            value: fieldValues[each].value,//{...fieldValues[each],error:true,helperText:err[each].reduce((sum = '', val) => sum + val)},
                            error: true,
                            helperText: err[each].reduce((sum = '', val) => sum + val)
                        }
                    })
                    this.setState({
                        fieldValues: { ...fieldValues, ...errfieldValues },
                        changedFieldName: errfieldnames
                    })
                })


            })
        }
        else {
            onClose()
        }

    }
    onCancel = (cancel) => (event) => {
        const { fetchData, onClose } = this.props
        if (onClose) onClose()

    }


    render() {
        const { options, components, defaults, order, type, compmap, ...otherProps } = this.props;
        const { actions } = options
        let save, cancel, previous, next
        if (actions) {
            save = actions.crud.update; cancel = actions.crud.cancel;
        }
        const { classes, theme } = this.props;
        console.log('formresults----', this.state)
        const { sendProps, receiveProps } = options

        //const fieldLayout = this.fieldslayout(totalrows, totalcolumns, components, compmap, this.state.results)

        return (
            <Fragment>
                <form onSubmit={this.handleSubmit} style={formstyles.formcontainer}>
                    <div className={classes.sectionDesktop}>
                        {this.state.memoizeFormLayout && this.state.memoizeFormLayout(this.state.changedFieldName)}
                    </div>
                </form>
                {
                    this.state.memoizeFormLayout && actions && <div className="row">
                        {save && <div className="ml-4 mb-3 pt-2"> <button className=" btn btn-outlined btn-secondary" onClick={this.onSave()} > Save</button></div>}
                        {cancel && <div className="ml-3 mb-3 pt-2"> <button className=" btn btn-outlined btn-secondary" onClick={this.onCancel(cancel)} >Cancel </button></div>}
                        {/* <br /> */}
                    </div>

                }


            </Fragment>
        );
    }
}

CForm.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(CForm))












// class CFormLayout extends Component {
//     state = {
//         fieldLayout: null
//     }

//     componentDidMount() {
//         const { results, fieldProps } = this.props
//         const { totalrows, totalcols, components, compmap } = fieldProps
//         this.setState({
//             fieldLayout: this.generateFieldLayout(totalrows, totalcols, components, compmap, results)
//         })
//     }


//     render() {
//         return (
//             <Fragment>
//                 <form onSubmit={this.handleSubmit} style={formstyles.formcontainer}>
//                     <div className={classes.sectionDesktop}>
//                         {this.state.fieldLayout}
//                     </div>
//                 </form>
//                 {
//                     actions && <div className="row">
//                         {save && <div className="ml-4 mb-3 pt-2"> <button className=" btn btn-outlined btn-secondary" onClick={this.onSave(save)} > Save</button></div>}
//                         {cancel && <div className="ml-3 mb-3 pt-2"> <button className=" btn btn-outlined btn-secondary" onClick={this.onCancel(cancel)} >Cancel </button></div>}
//                         {/* <br /> */}
//                     </div>

//                 }
//             </Fragment>
//         )
//     }

// }

const CGroupFields = (props) => {
    const { label, formgrouprow, margin, ...otherProps } = props
    return (
        <FormControl component="fieldset" margin={margin || 'normal'}>
            <FormLabel>{label}</FormLabel>
            <FormGroup row={formgrouprow || true} {...otherProps}>
                {props.children}
            </FormGroup>
        </FormControl>
    )
}



// export default withRouter(withStyles(styles, { withTheme: true })(CForm))

/**
 * rows[
 * {
 *  type:'',
 * order:1,
 * options:{
 * width:3
 * }
 * }
 * ]
 */


/**
 * {
  "type":"form",
  "options":{
    "totalColums":1
  },
  "rules":{
    "edit":false,
    "scope":form
  },
  "components":[
    {
    "type":"textbox",
    "label":"Name",
    "options":{
        "row":1,
        "col":1
    },
    "rules":{
      "add":{
        "events":{
          "onChange":[1,2,3]
        },
        "items":[
          {
            "type":"textbox",
            "label":"Name"
          }
        ],
        "scope":"unique name"
      }
    }
  },
  {
    "type":"dropdown",
    "label":"Type",
    "options":{
        "row":2,
        "col":1
    },
    "rules":{
      "add":{
        "mapeventstovalues":{
          "onChange":[1,2,3]
        },
        "items":[
          {
            "type":"textbox",
            "label":"Name"
          }
        ],
        "scope":"unique name"
      }
    }
  },
  {
    "type":"sitepermityype",
    "label":"Type",
    "options":{
        "row":3,
        "col":1
    },
    "rules":{
      "add":{
        "mapeventstovalues":{
          "onChange":[1,2,3]
        },
        "items":[
          {
            "type":"textbox",
            "label":"Name"
          }
        ],
        "scope":"unique name"
      }
    }
  }
  ]
}
 */




// if (rest) {
//     const { get, post, put } = rest
//     const del = rest.delete
//     if (get) {
//         const requestparams = this.getDataFetch(get)
//         GetData(baseurl, get.url, requestparams).then(results => {
//             const keys = this.state.results && Object.keys(this.state.results)
//             results && keys.every(each => {
//                 return !this.state.results[each].error
//             }) && this.props.goToNext(step)

//         })
//     }
//     if (post) {
//         const requestparams = this.postDataFetch(baseurl, post, rest)
//         PostData(baseurl, get.url, requestparams).then(results => {
//             const keys = this.state.results && Object.keys(this.state.results)
//             results && keys.every(each => {
//                 return !this.state.results[each].error
//             }) && this.props.goToNext(step)

//         })
//     }
// }
// else {
//     this.props.goToNext(step)
// }




// {/* {results && React.cloneElement(child, { setFormFieldValue: this.setFormFieldValue, options: { ...options, initialvalue: results[name] } })} */ }


// gridLayout = () => {
//     const { options, components, fetchData, compmap, actions, storeChildrenState, ...otherProps } = this.props;
//     const { totalcolumns, totalrows, crud, rules } = options
//     //const results = this.state.results
//     const { classes, items } = this.props
//     const { parentProps } = this.props
//     let rowfield = []
//     const { subforms } = this
//     let subformskeys, keyslen
//     if (subforms) { subformskeys = Object.keys(subforms), keyslen = subformskeys.length }
//     const allComponents = components.concat(this.newAddedComponents)
//     let rows = totalrows || (allComponents.length + items.length)
//     this.newAddedComponents = []
//     for (let currentrow = 1; currentrow <= rows; currentrow++) {
//         let rowchild = []
//         allComponents.forEach((each, index) => {
//             const type = each.type
//             const EachComp = compmap[type]
//             let { options, order } = each
//             let { width, name } = options
//             let row = options.row || order || (index + 1)
//             let compwidth
//             if (width) {
//                 compwidth = 'col-' + width
//             }
//             else {
//                 compwidth = 'col'
//             }
//             if (row === currentrow) {
//                 rowchild.push(() => <div className={compwidth} key={'fieldcol' + index}>
//                     {/* <EachComp {...each} key={each.key} compmap={compmap} parentProps={{ id: results.Id || (parentProps && parentProps.id) }} getValue={this.getValue} setFormFieldValue={this.setFormFieldValue} setAdditionalFormFieldValue={this.setAdditionalFormFieldValue} reCalculateFieldLayout={this.reCalculateFieldLayout} jumpToStep={this.jumpToStep} />                      {results && React.cloneElement(child, { setFormFieldValue: this.setFormFieldValue, options: { ...options, initialvalue: results[name] } })} */}
//                     <EachComp {...each} key={each.key} compmap={compmap} checkkey={each.key} currentValue={this.state.fieldValues[name]} addSubForms={this.addSubForms} setFormFieldValue={this.setFormFieldValue} jumpToStep={this.jumpToStep} />
//                     {subformskeys && (keyslen > 0) && (subforms[each.key]) && subforms[each.key].components.map((each, c) => {
//                         this.newAddedComponents.push(each)
//                         const type = each.type
//                         const EachComp = compmap[type]
//                         let { options, order } = each
//                         let { width, name } = options
//                         let row = options.row || order || (index + c + 1)
//                         let compwidth
//                         if (width) {
//                             compwidth = 'col-' + width
//                         }
//                         else {
//                             compwidth = 'col'
//                         }
//                         return <EachComp {...each} key={each.key} checkkey={each.key} compmap={compmap} currentValue={this.state.fieldValues[name]} addSubForms={this.addSubForms} setFormFieldValue={this.setFormFieldValue} jumpToStep={this.jumpToStep} />

//                     })}
//                 </div>)
//             }
//         })
//         items && items.forEach((each, i) => {
//             const type = each.type
//             let { options, order } = each
//             let { width } = options
//             let row = options.row || order || (len + i + 1)
//             let compwidth
//             if (width) {
//                 compwidth = 'col-' + width
//             }
//             else {
//                 compwidth = 'col'
//             }
//             if (type === 'fieldsgroup') {
//                 const { type, components, options } = each
//                 const { row, col, width, ...otherProps } = options
//                 if (row === currentrow) {
//                     rowchild.push(() => <div className={compwidth} key={'fieldcol' + i}>
//                         <CGroupFields {...otherProps}>
//                             {components.map((each, ix) => {
//                                 const { type, options } = each
//                                 const { key } = options
//                                 const { name } = options
//                                 const EachComp = compmap[type]
//                                 // return <EachComp {...e} key={e.key} compmap={compmap} style={formstyles.itemrow} parentProps={{ id: results.Id || (parentProps && parentProps.id) }} getValue={this.getValue} setFormFieldValue={this.setFormFieldValue} setAdditionalFormFieldValue={this.setAdditionalFormFieldValue} reCalculateFieldLayout={this.reCalculateFieldLayout} jumpToStep={this.jumpToStep} />
//                                 return <EachComp {...each} key={(each.key + ix) || 'action' + ix} value={this.state.results[name]} checkkey={each.key} addSubForms={this.addSubForms} compmap={compmap} setFormFieldValue={this.setFormFieldValue} jumpToStep={this.jumpToStep} />
//                             })}
//                         </CGroupFields>
//                     </div>)
//                 }

//             }
//         })
//         let eachrow = <div className='row' key={'fieldrow' + currentrow} style={formstyles.formrow} >{rowchild}</div>
//         rowfield.push(rowchild)
//     }
//     const cache = rowfield
//     return () => {
//         return cache.map((each, index) => {
//             return <div className='row' key={'fieldrow' + index} style={formstyles.formrow} >
//                 {each.map(e => e())}
//             </div>
//         })

//     }
// }








    // fieldslayout = (subform, fieldkey) => {
    //     const { options, components, fetchData, compmap, actions, storeChildrenState, ...otherProps } = this.props;
    //     const { totalcolumns, totalrows, crud, rules } = options
    //     //const results = this.state.results
    //     const fieldValues = this.state.fieldValues
    //     const { classes, items } = this.props
    //     const { parentProps } = this.props
    //     let rowfield = []
    //     let rows = totalrows || (components.length + items.length)
    //     const len = components.length
    //     for (let currentrow = 1; currentrow <= rows; currentrow++) {
    //         let rowchild = []
    //         components.forEach((each, index) => {
    //             const type = each.type
    //             const EachComp = compmap[type]
    //             let { options, order } = each
    //             let { width, name } = options
    //             let row = options.row || order || index
    //             let compwidth
    //             if (width) {
    //                 compwidth = 'col-' + width
    //             }
    //             else {
    //                 compwidth = 'col'
    //             }
    //             if (row === currentrow) {
    //                 rowchild.push(<div className={compwidth} key={'fieldcol' + index}>
    //                     {/* <EachComp {...each} key={each.key} compmap={compmap} parentProps={{ id: results.Id || (parentProps && parentProps.id) }} getValue={this.getValue} setFormFieldValue={this.setFormFieldValue} setAdditionalFormFieldValue={this.setAdditionalFormFieldValue} reCalculateFieldLayout={this.reCalculateFieldLayout} jumpToStep={this.jumpToStep} />                      {results && React.cloneElement(child, { setFormFieldValue: this.setFormFieldValue, options: { ...options, initialvalue: results[name] } })} */}
    //                     <EachComp {...each} key={each.key} compmap={compmap} checkkey={each.key} addSubForms={this.addSubForms} setFormFieldValue={this.setFormFieldValue} jumpToStep={this.jumpToStep} />
    //                     {subform && (fieldkey === each.key) && subform.components.map((each, c) => {
    //                         const type = each.type
    //                         const EachComp = compmap[type]
    //                         let { options, order } = each
    //                         let { width, name } = options
    //                         let row = options.row || order || (index + c + 1)
    //                         let compwidth
    //                         if (width) {
    //                             compwidth = 'col-' + width
    //                         }
    //                         else {
    //                             compwidth = 'col'
    //                         }
    //                         return <EachComp {...each} key={each.key} checkkey={each.key} compmap={compmap} addSubForms={this.addSubForms} setFormFieldValue={this.setFormFieldValue} jumpToStep={this.jumpToStep} />

    //                     })}
    //                 </div>)
    //             }
    //         })
    //         items && items.forEach((each, i) => {
    //             const type = each.type
    //             let { options, order } = each
    //             let { width } = options
    //             let row = options.row || order || (len + i + 1)
    //             let compwidth
    //             if (width) {
    //                 compwidth = 'col-' + width
    //             }
    //             else {
    //                 compwidth = 'col'
    //             }
    //             if (type === 'fieldsgroup') {
    //                 const { type, components, options } = each
    //                 const { row, col, width, ...otherProps } = options
    //                 if (row === currentrow) {
    //                     rowchild.push(<div className={compwidth} key={'fieldcol' + i}>
    //                         <CGroupFields {...otherProps}>
    //                             {components.map((each, ix) => {
    //                                 const { type, options } = each
    //                                 const { key } = options
    //                                 const { name } = options
    //                                 const EachComp = compmap[type]
    //                                 // return <EachComp {...e} key={e.key} compmap={compmap} style={formstyles.itemrow} parentProps={{ id: results.Id || (parentProps && parentProps.id) }} getValue={this.getValue} setFormFieldValue={this.setFormFieldValue} setAdditionalFormFieldValue={this.setAdditionalFormFieldValue} reCalculateFieldLayout={this.reCalculateFieldLayout} jumpToStep={this.jumpToStep} />
    //                                 return <EachComp {...each} key={(each.key + ix) || 'action' + ix} checkkey={each.key} addSubForms={this.addSubForms} compmap={compmap} setFormFieldValue={this.setFormFieldValue} jumpToStep={this.jumpToStep} />
    //                             })}
    //                         </CGroupFields>
    //                     </div>)
    //                 }

    //             }
    //         })
    //         let eachrow = <div className='row' key={'fieldrow' + currentrow} style={formstyles.formrow} >{rowchild}</div>
    //         rowfield.push(eachrow)
    //     }
    //     return rowfield

    // }