/**
      *********************************************************
      Description: Handles grid component.
      Required props:  type.
      limitations    : just displays the grid data. on double click on the row changes the route.
      *********************************************************
**/
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import * as React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { withRouter } from 'react-router-dom';
import { GetData } from '../../controls/helpers/requests';
import { getToken } from '../header';
import GridHeader from './cgridheader';
//const StatefullGrid = withState(Grid);


function CStatus(props) {
    return (
        <td>
            <span className="badge badge-pill badge-success"> {this.props.dataItem[this.props.field]}</span>
        </td>
    )
}


class CSimpleGrid extends React.Component {
    constructor(props) {
        super(props);
        this.token = getToken()

        this.state = { dataState: { skip: 0, take: 20 } };
    }
    /**
    *********************************************************
    Description: Handles the double click on the grid row. Update prop on the json is used to generate the link and change the route
    Required props:  grid needs to have data.
    limitations    : only double click event is handled.
    *********************************************************
**/

    rowRender = (trElement, dataItem) => {
        const { options } = this.props
        const { crud } = options
        const { create, update, read } = crud
        const del = crud.delete
        const trProps = {
            ...trElement.props,
            onDoubleClick: () => {
                console.log('Double click on ', dataItem)
                if (update) {
                    let pushLink = ''
                    const { link, matchProps, type } = update.link
                    if (Array.isArray(update.link)) {
                        update.link.forEach(each => {
                            const { link, matchProps } = each
                            pushLink += link + '/' + (this.props.match.params[matchProps.value] ?
                                this.props.match.params[matchProps.value] : dataItem.dataItem[matchProps.value])

                        })
                    }
                    else {
                        if (matchProps) pushLink += link + '/' + dataItem.dataItem[matchProps.value]
                    }
                    this.props.history.push(`${pushLink}`)
                }

            }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    componentDidMount() {
        this.fetchData(this.state.dataState);
    }

    handleRowClick = (gridRowClickEvent) => {
        console.log('gridrowclickevent', gridRowClickEvent)
    }

    handleDataStateChange = (changeEvent) => {
        this.setState({ dataState: changeEvent.dataState });
        this.fetchData(changeEvent.dataState);
    }

    /**
     *********************************************************
     Description: Handles the coloring of the status column
     Required props:  grid needs to have data.
     limitations    : N/A
     *********************************************************
**/
    handleStatusStyle = (name, transformProps) => (event) => {
        const val = name ? name.split(".").reduce((res, prop) => { return res && res[prop] }, event.dataItem) : null
        // const value = transformProps ? (transformProps[val] ? transformProps[val] : val) : val
        console.log('handleStatusStyle-----', val);
        if (event.dataItem.Status) {
            if (event.dataItem.Status == 'I') {
                return (
                    <td>
                        <span className="badge badge-pill badge-danger">IN-ACTIVE</span>
                    </td>
                );
            }
            else if (event.dataItem.Status == 'A') {
                return (
                    <td>
                        <span className="badge badge-pill badge-light">ACTIVE</span>
                    </td>
                );
            }
            else {
                return (
                    <td>
                        event.dataItem.Status
                    </td>
                );
            }
        }
        else return <td>{transformProps[val]}</td>

    }

    handleGridForm = (name, transformProps) => (event) => {
        const val = name ? name.split(".").reduce((res, prop) => { return res && res[prop] }, event.dataItem) : null;
        return <td>{transformProps[val]}</td>
    }

    /**
     *********************************************************
     Description: Handles the data for the grid. creates the url to fetch either with query or without query.
     Required props:  options, components. Within options crud.read is needed to fetch data.
     limitations    : N/A
     *********************************************************
**/

    fetchData(dataState, value) {

        const queryStr = `${toDataSourceRequestString(dataState)}`; // Serialize the state
        const hasGroups = dataState.group && dataState.group.length;

        const { options, components, defaults, order, type, compmap, rules, accessPermissions, accessLevelPermissions, ...otherProps } = this.props;
        const { gridcolumns } = options
        const { routes, crud } = options
        const { read, create, update } = crud
        const del = crud.delete
        const { get } = read
        const { routeProps, matchProps, noquery } = get ? get : {}
        let url = get.url;

        if (Array.isArray(routeProps)) {
            routeProps.forEach(each => {
                const { value } = each
                const id = routeProps ? this.props.match.params[value] : null
                url = `${url.replace(`{${value}}`, id)}`

            })
        }
        else if (routeProps) {
            const id = routeProps ? this.props.match.params[routeProps.value] : null
            url = `${get.url.replace(`{${routeProps.value}}`, id)}`;
        }
        const urlPath = `${url}?${queryStr}`;

        GetData(urlPath).then((data) => {

            this.setState({
                data: data
            });
        });
    }
    /**
          *********************************************************
          Description: renders the grid component.
          Required props:  options, components. Within options gridcolumns are needed to create columns.
          limitations    : N/A
          *********************************************************
    **/
    render() {
        const { options, components, defaults, order, type, compmap, rules, accessPermissions, accessLevelPermissions, ...otherProps } = this.props;
        const { gridcolumns, headerProps } = options
        return (
            <React.Fragment>
                <ReactResizeDetector handleWidth handleHeight>
                    {({ width, height }) =>
                        <React.Fragment>
                            <GridHeader {...headerProps} accessPermissions={accessPermissions} accessLevelPermissions={accessLevelPermissions} />
                            <Grid
                                filterable={true}
                                sortable={true}
                                pageable={true}
                                total={this.state.total}
                                data={this.state.data}
                                {...this.state.dataState}
                                onDataStateChange={this.handleDataStateChange}
                                onRowClick={this.handleRowClick}
                                rowRender={this.rowRender}
                                style={{ height: window.innerHeight - 110 }}
                                resizable

                            >
                                {gridcolumns && gridcolumns.map((each, index) => {
                                    const { name, transformProps } = each
                                    if (name) return <GridColumn {...each} key={index} cell={this.handleGridForm(name, transformProps)} />
                                    else {
                                        if (each.field == 'Status')
                                            return <GridColumn {...each} key={index} cell={this.handleStatusStyle(name)} />
                                        else if (each.field == 'CreatedOn' || each.field == 'UpdatedOn')
                                            return <GridColumn {...each} key={index} filter={'date'} format="{0:d}" />
                                        else
                                            return <GridColumn {...each} key={index} />
                                    }
                                })}
                            </Grid>
                        </React.Fragment>
                    }
                </ReactResizeDetector>
            </React.Fragment>
        );
    }
}

export default withRouter(CSimpleGrid);

