import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { requestApi } from '../../../src/core/modules/form/utilities';
import { getEnvVariable } from '../../../src/modules/environmentalList';
//import { getCalculatedAmount } from '../../../src/payment/manager';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { snackbarMessage } from '../../core/actions';
import { GenerateInvoice, GetOwnerInvoice, GetOwnerInvoiceView, GetOwnerPaymentsInformation, ReGenerateInvoice } from './billingservice';
//import BillingSidepopup from '../billing/billingsidepopup'
//import BillingRegenerate from '../billing/billingregenerate'
import InvoiceSideBar from './billinginvoice';
import RegenerateSideBar from './billingregenerateoptions';
import { IsValidInvoiceStatus, IsValidPermitStatus } from './statusvalidator';

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PaymentIcon from '@material-ui/icons/Payment';
import Card from '../../core/controls/components/card';
import BillingGrid from './billinggrid';
import PhysicalCheckSideBar from './billingphysicalcheck';
const _ = require('lodash');


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {/* by removing style scrollbar will be hided {value === index && <Box p={3} style={{ overflow: 'auto', height: '800px' }}>{children}</Box>} */}
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        "& .MuiTab-root": {
            'text-transform': 'capitalize'
        },
    },
}));

function OwnerBilling(props) {
    // const {headerProps} = props.options
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [gridColumns, setGridColumns] = useState([])
    //const [attachmentTypeResults, setAttachmentTypeResults] = useState(null)
    const [TabValue, setTabValue] = useState(0)
    const [invoice, setInvoice] = useState({})
    const [pdf, setPDF] = useState(null)
    const [openInvoice, setOpenInvoice] = useState(false)
    const [currentDataItem, setCurrentDataItem] = useState(null)
    const [openSlide, setOpenSlide] = useState(false)
    const [generateInvoiceType, setGenerateInvoiceType] = useState(null)
    const [openGenerateInvoice, setOpenGenerateInvoice] = useState(false)

    const [permitDropdown, setPermitDropdown] = useState([])
    const [appDropdown, setAppDropdown] = useState([])
    const [paymentStatusOptions, setPaymentStatusOptions] = useState([])
    const [paymentStatusValue, setPaymentStatusValue] = useState(null)
    const [disabled, setDisabled] = useState(true)

    //const [components, setComponents] = useState({ ...BillingSidepopup })
    const [dataState, setDataState] = useState({
        skip: 0, take: 5
    })
    const [EachComp, setEachComp] = useState(null)
    const [pageable, setPageable] = useState({
        buttonCount: 6,
        info: true,
        type: 'numeric',
        pageSizes: [10, 20, 30, 40, 50, "all"],
        previousNext: true
    })
    const helpContext = useRef(null);
    const silderContext = useRef(null);
    const [gridSelectionRecord, setGridSelectionRecord] = useState(false)
    const [hascheckbox, setHasCheckBox] = useState(true)
    const [gridCheckBoxSelectionRecords, setGridCheckBoxSelectionRecords] = useState([])
    const [invoiceSummary, setInvoiceSummary] = useState([])
    const [allInvoices, setAllInvoices] = useState({})
    const [companyId, setCompanyId] = useState(null)
    const [physicalCheckProps, setPhysicalCheckProps] = useState({ open: false, type: 'NEW', id: 0 })
    const [checkModules, setCheckModules] = useState([])

    useEffect(() => {
        // requestApi({ get: { url: getEnvVariable('AdminConfig') + `/api/KEPTSitePermitTypes/${props.match.params.routeid}` } }, null, null).then(results => {
        //     setAttachmentTypeResults(results);
        // })

        if (props.options && props.options.billingtype && props.options.billingtype == "externalbilling") {
            requestApi({ get: { url: getEnvVariable('MasterAdmin') + `/api/ExternalUser/` + getEnvVariable('ModuleKey') + `/${props.accessPermissions.userId}` } }, null, null).then(results => {
                setCompanyId(results.CompanyId)
            })
        }
        requestApi({ get: { url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=APPLICATION_PAYMENT_STATUS` } }, null, null).then(results => {
            setAppDropdown(results);
            setPaymentStatusOptions(results);
        })
        requestApi({ get: { url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=PERMIT_PAYMENT_STATUS`, } }, null, null).then(results => {
            setPermitDropdown(results);
        })
        let submodule = localStorage.getItem('submodule') && JSON.parse(localStorage.getItem('submodule')) ? JSON.parse(localStorage.getItem('submodule')) : []
        let checkdata = []
        submodule.forEach(element => {
            checkdata.push(element.key)
        });
        setCheckModules(checkdata)

        LoadData();
    }, [])

    useEffect(() => {
        LoadExistingInvoiceData()
    }, [companyId])

    const LoadExistingInvoiceData = () => {
        let passid = props.options && props.options.billingtype && props.options.billingtype == "externalbilling" ? companyId : props.match.params.Id
        let passtype = TabValue == 0 ? 'Application' : TabValue == 1 ? 'Prorate' : 'PermitRenewal';
        if (passid) {
            LoadStatistics(passid, passtype);
            GetOwnerInvoiceView(passid, passtype, checkModules).then(invres => {
                let extdata = []; //[...data, ...invres.InvoiceLineItems];
                let statusopts = TabValue < 2 ? appDropdown : permitDropdown
                if (invres && Array.isArray(invres)) {
                    let invdataarr = invres;
                    setAllInvoices(invres);
                    setData(invdataarr)
                    setInvoice(invdataarr[0])
                    setPaymentStatusOptions(statusopts)
                    setGridSelectionRecord(null)
                    setGridCheckBoxSelectionRecords([]);
                }
            })
        }

    }

    const LoadStatistics = (passid, passtype) => {
        GetOwnerInvoice(passid, passtype).then(invres => {
            if (invres && Array.isArray(invres) && invres.length > 0) {
                let invdataarr = invres;
                setAllInvoices(invres);
                if (invdataarr && Array.isArray(invdataarr)) {
                    SetInvoiceSummary(invdataarr);
                }
            }
        })
    }

    const physicalcheckSlider = (open, type, id) => {
        if (!open) {
            setPhysicalCheckProps({ open: false, viewtype: '', id: 0 });

        }
        else
            setPhysicalCheckProps({ open: true, viewtype: type, id: id })
    }

    const SetInvoiceSummary = (invoices) => {
        let unpaid = invoices.filter(a => a.Status == 'U');
        let cancelled = invoices.filter(a => a.Status == 'C');
        let paid = invoices.filter(a => a.Status == 'P');
        let unpaidamount = GetSummaryFee(unpaid);
        let paidamount = GetSummaryFee(paid);
        let cancelledamount = GetSummaryFee(cancelled);
        //{ permitcount : 0 , paidinvoice:0, paidamount : 0, unpaidinvoice:0, unpaidamount : 0 }
        //{header: "Payment Details :- ", body: "Total amount: ", footer: "$1050"}
        let invoiceSummaryArr = [];
        invoiceSummaryArr.push({ header: " Number Of Invoice :" + paid.length, body: "Paid Invoice", footer: paidamount })
        invoiceSummaryArr.push({ header: " Number Of Invoice :" + unpaid.length, body: "UnPaid Invoice", footer: unpaidamount })
        invoiceSummaryArr.push({ header: " Number Of Invoice :" + cancelled.length, body: "Cancelled Invoice", footer: cancelledamount })

        // let obj = {
        //     paidinvoice: paid.length,
        //     paidamount: paidamount,
        //     unpaidinvoice: unpaid.length,
        //     unpaidamount: unpaidamount,
        //     cancelledinvoice:cancelled.length
        // }
        setInvoiceSummary(invoiceSummaryArr)
    }

    const GetSummaryFee = (invoices) => {
        if (invoices && Array.isArray(invoices)) {
            let totalAmount = 0;
            invoices.forEach(inv => {
                if (Array.isArray(inv.InvoiceDetails)) {
                    inv.InvoiceDetails.forEach(element => {
                        totalAmount = totalAmount + element.Amount
                    });
                }
            });
            return "$" + totalAmount
        }
        return "$0";
    }



    useEffect(() => {
        LoadData();
    }, [dataState])

    const LoadData = () => {
        const queryStr = `${toDataSourceRequestString(dataState)}`;
        //we do request grid request and need to  append queryStr for url
        let dataitems = data || []
        //setData(dataitems);
        setTotal(dataitems.length);
        //for getting distinct columns, returns all distinct columns by verifying all objects in data array
        let columnsarr = _.union(_.flatten(_.map(dataitems, (e) => _.keys(e))));
        var removedfields = _.remove(columnsarr, function (n) {
            return n != "UpdatedBy" && n != "UpdatedOn" && n != "BillingPayments";
        });
        setGridColumns(removedfields)
    }


    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }

    useEffect(() => {
        //console.log('Loading from tab value change----------------------**');
        setData([])
        setInvoice({})
        setGridSelectionRecord(null)
        setGridCheckBoxSelectionRecords([])
        setInvoiceSummary([])
        let hasCheckBoxVal = TabValue == 2 ? true : false;
        setHasCheckBox(hasCheckBoxVal);
        if (TabValue == 3) {
            LoadPaymentInformation();
        } else {
            LoadExistingInvoiceData()
        }
    }, [TabValue])

    const LoadPaymentInformation = () => {
        let passid = props.options && props.options.billingtype && props.options.billingtype == "externalbilling" ? companyId : props.match.params.Id

        GetOwnerPaymentsInformation(passid).then(invres => {
            console.log(invres)
            if (invres && Array.isArray(invres)) {
                setData(invres);
                setInvoice({});
                setPaymentStatusOptions([]);
                setGridSelectionRecord(null);
                setGridCheckBoxSelectionRecords([]);
            }
        })
    }

    //Genreate options
    const handleButtonClick = () => {
        let appdata = {
            applicantid: props.match.params.routeid2,
            sitepermittypeid: props.match.params.routeid,
            recordid: props.match.params.routeid1,
            district: "",
            county: ""
        };
        if (TabValue == '1') {
            GenerateInvoice(appdata, "PRORATE").then(res => {
                console.log(res);
                if (res && res.Id && res.InvoiceLineItems) {
                    // let extdata = [...data, ...res.InvoiceLineItems];
                    // setData(extdata)
                    // setInvoice(res)
                    LoadExistingInvoiceData();
                }
            }).catch(function (err) {
                console.error(err);
                let nobll = err.Error == 'NOBILLING'
                let nosetting = err.Error == 'NOSETTING'
                props.snackbarMessage(`No prorate fee found in settings.`, 'info')
            })
        } else if (TabValue == '2') {
            GenerateInvoice(appdata, "PERMITRENEWAL").then(res => {
                console.log(res);
                if (res && res.Id && res.InvoiceLineItems) {
                    // let extdata = [...data, ...res.InvoiceLineItems];
                    // setData(extdata)
                    // setInvoice(res)
                    LoadExistingInvoiceData();
                }
            }).catch(function (err) {
                console.error(err);
                let nobll = err.Error == 'NOBILLING'
                let nosetting = err.Error == 'NOSETTING'
                props.snackbarMessage(`No permit fee found in settings.`, 'info')
            })
        }

    }


    useEffect(() => {
        SetGridData();
    }, [data])



    const SetGridData = () => {
        const queryStr = `${toDataSourceRequestString(dataState)}`;
        //we do request grid request and need to  append queryStr for url
        let dataitems = data || []
        //setData(dataitems);
        setTotal(dataitems.length);
        //for getting distinct columns, returns all distinct columns by verifying all objects in data array
        let columnsarr = _.union(_.flatten(_.map(dataitems, (e) => _.keys(e))));
        var removedfields = _.remove(columnsarr, function (n) {
            return n != "UpdatedBy" && n != "UpdatedOn" && n != "BillingPayments" && n != "DownloadId";
        });
        setGridColumns(removedfields)
    }

    const payOnline = () => {
        let routeid = props.match.params.routeid;
        let routeid1 = props.match.params.routeid1;
        let currentuser = props.accessPermissions.userId
        let url = getEnvVariable('PaymentGateWay') + `/RequestPayment/Index/${routeid}/${routeid1}/${invoice.Id}/${currentuser}`  //'http://localhost/payment/RequestPayment/Index/1/1/1';
        let payment_window = window.open(url, 'popUpWindow', 'height=800,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
        payment_window.focus();
        var timer = setInterval(function () {
            if (payment_window.closed) {
                clearInterval(timer);
                LoadExistingInvoiceData();
            }
        }.bind(this), 1000);

    }

    const payOnlineAll = () => {
        let routeid = props.options && props.options.billingtype && props.options.billingtype == "externalbilling" ? companyId : props.match.params.Id;
        let routeid1 = props.match.params.routeid1 || -1;
        let currentuser = props.accessPermissions.userId;
        let invoicecondition = ""
        let selectionmapdataid = gridCheckBoxSelectionRecords.map(res => {
            return res.Id
        })
        if (selectionmapdataid && Array.isArray(selectionmapdataid)) {
            selectionmapdataid.forEach(element => {
                if (Number.isInteger(element))
                    invoicecondition = invoicecondition + 'id=' + element + '&';
            });

            let url = getEnvVariable('PaymentGateWay') + `/OnlinePayment/Index/${routeid}/${routeid1}/${currentuser}?${invoicecondition}`  //${invoice.Id}
            let payment_window = window.open(url, 'popUpWindow', 'height=800,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
            payment_window.focus();
            var timer = setInterval(function () {
                if (payment_window.closed) {
                    clearInterval(timer);
                    LoadExistingInvoiceData();
                }
            }.bind(this), 1000);
        }


    }



    const PaymentStatus = () => {
        return invoice.Status == 'P' ? 'Paid' : 'UnPaid';
    }


    const rowRender = (trElement, row) => {
        const trProps = {
            ...trElement.props,
            onDoubleClick: () => {
                if (TabValue < 3) {
                    setGridSelectionRecord(row.dataItem.InvoiceId)
                } else {
                    setGridSelectionRecord(row.dataItem.Id)
                }
            }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    const rowRenderData = (row) => {
        if (TabValue < 3) {
            setGridSelectionRecord(row.dataItem)
        } else {
            setGridSelectionRecord(row.dataItem)
        }
    }

    const gridSelection = (datavalues, selectionmapdata) => {
        // setData(datavalues);
        setGridCheckBoxSelectionRecords(selectionmapdata);
        if (selectionmapdata && selectionmapdata.length > 0) {
            setDisabled(false);
        }
        else {
            setDisabled(true);
        }
    }
    const gridselectionLoad = (gridcheckload) => {
        console.log(gridcheckload);
        setCheckModules(gridcheckload)
    }

    useEffect(() => {
        LoadExistingInvoiceData()
    }, [checkModules])

    useEffect(() => {
        if (gridSelectionRecord) {
            if (TabValue == 3) {
                physicalcheckSlider(true, 'PAYMENT', gridSelectionRecord.Id)
            } else {
                setOpenInvoice(true);
            }
        }
    }, [gridSelectionRecord])

    const handleSlide = () => {
        setOpenSlide(false);
        setCurrentDataItem(null);
        LoadExistingInvoiceData();
    }

    const handleInvoiceSlider = () => {
        setOpenInvoice(false);
        setGridSelectionRecord(false);
    }

    const regenerateSlider = (type) => {
        setGenerateInvoiceType(type);
    }

    const handleRegenerateSlider = (type, iscancel) => {
        //setGenerateInvoiceType(type);
        if (type) {
            if (generateInvoiceType == "G" && type == "2") {
                handleButtonClick();
            } else {
                let applicantid = props.match.params.routeid2
                let sitepermittypeid = props.match.params.routeid
                let recordid = props.match.params.routeid1
                let invtype = (type == "1" && generateInvoiceType == "R" || (type == "1" && generateInvoiceType == "G")) ? "A" : "C"
                let invid = invoice.Id ? invoice.Id : '-1'
                let cancelParent = iscancel ? "Y" : "N"
                ReGenerateInvoice(invid, applicantid, invtype, recordid, cancelParent).then(a => {
                    console.log(a);
                    LoadExistingInvoiceData();
                    props.snackbarMessage(`Invoice generated successfully.`, 'success');
                })

            }
        }
        setOpenGenerateInvoice(false);
        setGenerateInvoiceType(null);
    }

    const InvoiceStatusChange = (e) => {
        let currstatus = e.target.value;
        if (TabValue < 3) {
            if (!IsValidInvoiceStatus(invoice.Status, currstatus)) {
                props.snackbarMessage(`Invalid status change.`, 'error');
            } else {
                let inv = invoice;
                inv.Status = currstatus;
                setInvoice(inv)
            }
        } else {
            if (!IsValidPermitStatus(invoice.Status, currstatus)) {
                props.snackbarMessage(`Invalid status change.`, 'error');
            } else {
                let inv = invoice;
                inv.Status = currstatus;
                setInvoice(inv)
            }
        }
    }

    useEffect(() => {
        if (generateInvoiceType)
            setOpenGenerateInvoice(true)
    }, [generateInvoiceType])

    const tabdata = () => {
        return <div><div>
            {/* <Typography display="block" className="pl-3 pt-2" gutterBottom><b>All Permit Invoices</b></Typography> */}
            {TabValue == 2 && <Typography display="block" className="pl-3 pt-2" gutterBottom><b>Multiple unpaid invoices can be paid online. Maximum selection limited to 50 invoices.</b></Typography>}
            <Card componentlist={invoiceSummary} />
            {/* <Typography display="block" className="pl-3 pt-2" gutterBottom><b>Paid Invoice: {invoiceSummary.paidinvoice || 0}</b></Typography>
     <Typography display="block" className="pl-3 pt-2" gutterBottom><b>UnPaid Invoice: {invoiceSummary.unpaidinvoice || 0}</b></Typography>
     <Typography display="block" className="pl-3 pt-2" gutterBottom><b>Cancelled Invoice: {invoiceSummary.cancelledinvoice || 0}</b></Typography>
     <Typography display="block" className="pl-3 pt-2" gutterBottom><b>Total Paid Amount: {invoiceSummary.paidamount || 0}</b></Typography>
     <Typography display="block" className="pl-3 pt-2" gutterBottom><b>Total Unpaid Invoice Amount : {invoiceSummary.unpaidamount || 0}</b></Typography>
      */}
        </div>
            <BillingGrid data={data} total={total} gridSelection={gridSelection} gridselectionLoad={gridselectionLoad} hascheckbox={hascheckbox} rowRenderData={rowRenderData} gridColumns={gridColumns} issidebar={true} />
            {gridCheckBoxSelectionRecords.length > 0 && <Typography display="block" className="pl-3 pt-2" gutterBottom><b>You have selected {gridCheckBoxSelectionRecords.length || 0} invoices</b></Typography>}
            <div className="col-sm-12 pt-3">
                <InvoiceSideBar ref={helpContext} open={openInvoice} gridrow={gridSelectionRecord} handleInvoiceSlider={handleInvoiceSlider} />
                <RegenerateSideBar ref={silderContext} open={openGenerateInvoice} type={generateInvoiceType} invoiceid={invoice && invoice.Id ? invoice.Id : null} handleRegenerateSlider={handleRegenerateSlider} />
                {/* {invoice && invoice.Id && invoice.Status == 'U' && <Button className="col-sm-2 float-right pr-2" variant="contained" color="primary" onClick={payOnline}>Pay</Button>} */}
                {physicalCheckProps.open && <PhysicalCheckSideBar open={physicalCheckProps.open}
                    invoiceid={(invoice && invoice.Id) ? invoice.Id : gridSelectionRecord && gridSelectionRecord.invoices ? gridSelectionRecord.invoices : 0}
                    viewtype={physicalCheckProps.viewtype}
                    billingid={physicalCheckProps.id}
                    appdata={{}}
                    physicalcheckSlider={physicalcheckSlider}
                    recorddata={gridSelectionRecord} {...props} />}
            </div>
            <div>
                {pdf && <iframe src={pdf} width="100%" height="100%" frameborder="0" scrolling="no" id="iframe" />}
            </div>
            {TabValue == 2 && <Button className="col-sm-2 float-right pr-2" variant="contained" disabled={disabled} color={disabled ? "default" : "primary"} onClick={payOnlineAll}>Pay</Button>}

        </div>
    }

    return (
        <Fragment>
            <AppBar position="static" color="white">
                <Tabs
                    value={TabValue}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    scrollButtons="on"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Application Invoices" {...a11yProps(0)} icon={<AttachMoneyIcon />} value="0" />
                    <Tab label="Prorate Invoices" {...a11yProps(1)} icon={<LocalAtmIcon />} value="1" />
                    <Tab label="All Renewal Invoices" {...a11yProps(3)} icon={<PaymentIcon />} value="2" />
                    <Tab label="Payments" {...a11yProps(4)} icon={<AccountBalanceWalletIcon />} value="3" />
                </Tabs>
            </AppBar>
            <TabPanel value={TabValue} index={0}></TabPanel>
            <TabPanel value={TabValue} index={1}></TabPanel>
            <TabPanel value={TabValue} index={2}></TabPanel>
            <TabPanel value={TabValue} index={3}></TabPanel>
            {tabdata()}
        </Fragment>
    )
}

const mapProps = (state) => {
    return state
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

OwnerBilling = connect(mapProps, mapActions)(OwnerBilling)
export default withRouter(OwnerBilling)