import { getEnvVariable } from '../../../../src/modules/environmentalList';
import SelectedApplicants from './associatedapplicants';

const externaluserprofile = {
    "type": "slidingbutton",
    "key": "externaluserprofileslidingbutton",
    "options": {
        "row": 6,
        "col": 4,
        "width": 2,
        "buttonprops": {
            "text": "My Profile",
            "size": "large",
            "color": "primary",
            "variant": "contained"
        },
        excludefromresults: true,
        "label": "My Profile",
        "name": "My Profile",
    },
    open: true,
    components: [{
        "type": "formcontainer",
        "key": "externaluserprofileform",
        "options": {
            "title": "My Profile",
            headerProps: {
                "editbutton": false,
                "savebutton": false,
                "backbutton": true,
                "closebutton": false,
                "navigateview": true,
                "breadcrumbs": [
                    { "link": "My Profile", "materialicon": "person", className: 'eps-breadcrumb-item', class: 'text-light' }
                ],
            },
            hidescroll: true,
            "name": "externaluserprofileform",
            "back": {
                "popupback": true
            }
        },
        "sidepopupadd": true,
        "components": [
            {
                "type": "ctab",
                "key": "tab",
                hidemargin: true,
                "name": "externaluserstabform",
                excludefromresults: true,
                "options": {
                    tabinfo: [
                        {
                            tablabel: "My Profile",
                            tabkey: 0
                        },
                        {
                            tablabel: "Applicant Information",
                            tabkey: 1
                        },
                        {
                            tablabel: "Update Password",
                            tabkey: 2
                        },
                        // {
                        //     tablabel: "Associate Applicants",
                        //     tabkey: 3,
                        // },
                    ],
                },
                "components": [
                    {
                        "tabkey": 0,
                        "type": "formcontainer",
                        "key": "vieweditprofileform",
                        "options": {
                            "totalcolumns": 2,
                            "totalrows": 14,
                            "alignitem": "center",
                            "title": " My Profile",
                            className: 'siteclass',
                            styles: {
                                //margin: 15
                            },
                            headerProps: {
                                "editbutton": true,
                                "savebutton": true,
                                "backbutton": false,
                                "closebutton": false,
                                "navigateview": true,
                                "breadcrumbs": [
                                    { "link": "View/Edit Contractor", "materialicon": "edit", className: 'eps-breadcrumb-item ', class: 'text-light' }

                                ],
                            },
                            "hideEditIcon": false,
                            //"hidecondition": [{accessPortal: "E"}],
                            designprops: true,
                            "name": "vieweditprofileform",
                            "crud": {
                                "read": {
                                    "get": {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ExternalUser/` + getEnvVariable('ModuleKey') + `/`,
                                        "urlpath": '{userId}',
                                        // "routeProps": {
                                        //     "value": "routeid1"
                                        // }
                                        "customProps": [{
                                            "custom": "userId"
                                        }]
                                    }
                                },
                                "update": {
                                    "put": {
                                        "isProfile": true,
                                        "url": getEnvVariable('MasterAdmin') + `/api/ExternalUser/` + getEnvVariable('ModuleKey') + `/`,
                                        "urlpath": 'Update/{userId}',
                                        // "matchProps": [{
                                        //     "value": "routeid1"
                                        // }]
                                        "customProps": [{
                                            "custom": "userId"
                                        }]
                                    }
                                }
                            },
                            "validations": [
                                {
                                    get: {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.users`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'MASTER.USERS_',
                                        addsubfieldname: 'USER.'
                                    }

                                },
                                {
                                    get: {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.externalusers`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'MASTER.EXTERNALUSERS_'
                                    }

                                },
                                {
                                    get: {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.contacts`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'MASTER.CONTACTS_',
                                        addsubfieldname: 'CONTACTS.'
                                    }

                                }
                            ],
                        },
                        "components": [
                            {
                                "type": "formtextbox",
                                "key": "username",
                                "options": {
                                    "row": 1,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Username",
                                    "name": "User.UserName",
                                    "disabled": true,
                                    conditions: {
                                        enable: true,
                                    }
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "firstname",
                                "options": {
                                    "row": 2,
                                    "col": 0,
                                    "width": 6,
                                    "label": "First Name",
                                    "name": "User.FirstName"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "lastname",
                                "options": {
                                    "row": 2,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Last Name",
                                    "name": "User.LastName"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "title",
                                "options": {
                                    "row": 3,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Title",
                                    "name": "Title",

                                },

                            },
                            // {
                            //     "type": "formtextbox",
                            //     "key": "department",
                            //     "options": {
                            //         "row": 3,
                            //         "col": 1,
                            //         "width": 6,
                            //         "label": "Department",
                            //         "name": "DepartmentName"
                            //     },

                            // },
                            {
                                "type": "formtextbox",
                                "key": "email",
                                "options": {
                                    "row": 4,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Email",
                                    "name": "Email",
                                    "disabled": true,
                                    conditions: {
                                        enable: true,
                                    }
                                },

                            },
                            {
                                "type": "cformphonetext",
                                "key": "Phone",
                                "options": {
                                    "row": 4,
                                    "col": 1,
                                    "width": 6,
                                    "label": "Contact Number",
                                    "name": "Phone"
                                }
                            },
                            //commented by @vijaya- not required in external profile.
                            // {
                            //     "type": "cformphonetext",
                            //     "key": "SecondaryPhone",
                            //     "options": {
                            //         "row": 5,
                            //         "col": 1,
                            //         "width": 6,
                            //         "label": "Cell",
                            //         "name": "SecondaryPhone"
                            //     }
                            // },
                            // {
                            //     "type": "formswitch",
                            //     "key": "statusswitch",
                            //     "options": {
                            //         "row": 6,
                            //         "col": 0,
                            //         "width": 6,
                            //         "label": "Status",
                            //         "name": "User.Status",
                            //         "transformProps": {
                            //             "A": true,
                            //             "I": false,
                            //             true: "A",
                            //             false: "I"
                            //         }
                            //     }
                            // },
                            {
                                "type": "formcheckbox",
                                "key": "official",
                                "options": {
                                    "row": 6,
                                    "col": 1,
                                    "width": 3,
                                    "label": "Admin",
                                    "name": "IsOfficial",
                                    initialvalue: 'N',
                                    "disabled": true,
                                    conditions: {
                                        enable: true,
                                    },
                                    "transformProps": {
                                        "Y": true,
                                        "N": false,
                                        true: "Y",
                                        false: "N"
                                    }

                                }
                            },


                            {
                                "type": "historydate",
                                "key": "datecreated",
                                "options": {
                                    "row": 9,
                                    "col": 0,
                                    "width": 6,
                                    "text": "Created On: ",
                                    "name": "CreatedOn"
                                }
                            },
                            {
                                "type": "historyuser",
                                "key": "createdby",
                                "options": {
                                    "row": 9,
                                    "col": 1,
                                    "width": 6,
                                    "text": "Created By: ",
                                    "name": "CreatedByName"
                                }
                            },
                            {
                                "type": "historydate",
                                "key": "updatedon",
                                "options": {
                                    "row": 10,
                                    "col": 0,
                                    "width": 6,
                                    "text": "Updated On: ",
                                    "name": "UpdatedOn"
                                }
                            },
                            {
                                "type": "historyuser",
                                "key": "updatedbyname",
                                "options": {
                                    "row": 10,
                                    "col": 1,
                                    "width": 6,
                                    "text": "Updated By: ",
                                    "name": "UpdatedByName"
                                }
                            },
                        ]
                    },
                    {
                        "tabkey": 1,
                        "type": "formcontainer",
                        "key": "companyinternalform",

                        "options": {
                            "totalcolumns": 2,
                            "totalrows": 14,
                            "alignitem": "center",
                            "title": " Applicant",
                            className: 'siteclass',
                            styles: {
                                //margin: 15
                            },
                            headerProps: {
                                "editbutton": true,
                                "savebutton": true,
                                "backbutton": true,
                                "closebutton": false,
                                "navigateview": true,
                                "breadcrumbs": [
                                    { "link": "View/Edit Contractor", "materialicon": "edit", className: 'eps-breadcrumb-item ', class: 'text-light' }

                                ],
                            },
                            "hideEditButton": false,
                            "hideEditCheckName": "IsOfficial",
                            "hideEditCheckValue": "N",

                            designprops: true,
                            "name": "companyinternalform",
                            "crud": {
                                "read": {
                                    "get": {
                                        "url": getEnvVariable('MasterAdmin') + `/api/CompanyInternal/` + getEnvVariable('ModuleKey') + `/`,
                                        "urlpath": '{CompanyId}',
                                        "customProps": [{
                                            "custom": "CompanyId"
                                        }]
                                    }
                                },
                                "update": {
                                    "put": {
                                        "url": getEnvVariable('MasterAdmin') + `/api/CompanyInternal`,
                                        "urlpath": '/Update/{CompanyId}/' + getEnvVariable('ModuleKey'),
                                        "customProps": [{
                                            "custom": "CompanyId"
                                        }]
                                    }
                                }
                            },
                            "validations": [
                                {
                                    get: {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.companies`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'MASTER.COMPANIES_'
                                    }

                                },
                                {
                                    get: {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.contacts`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'MASTER.CONTACTS_',
                                        addsubfieldname: 'CONTACTS.PRIMARYCONTACTADDRESS.'
                                    }

                                }
                            ]
                        },
                        "components": [
                            {
                                "type": "formtextbox",
                                "key": "name",
                                "options": {
                                    "row": 0,
                                    "col": 0,
                                    "width": 12,
                                    "label": " Name",
                                    "name": "Name"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "address1",
                                "options": {
                                    "row": 1,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Address Line 1",
                                    "name": "Contacts.PrimaryContactAddress.AddressLine1"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "address2",
                                "options": {
                                    "row": 2,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Address Line 2",
                                    "name": "Contacts.PrimaryContactAddress.AddressLine2"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "city",
                                "options": {
                                    "row": 3,
                                    "col": 0,
                                    "width": 6,
                                    "label": "City",
                                    "name": "Contacts.PrimaryContactAddress.City"
                                },

                            },
                            {
                                "type": "formdropdown",
                                "key": "state",
                                "options": {
                                    "row": 3,
                                    "col": 1,
                                    "width": 6,
                                    "label": "State",
                                    "name": "Contacts.PrimaryContactAddress.State",
                                    "id": "stateformdropdown",
                                    "crud": {
                                        "read": {
                                            "get": {
                                                "url": getEnvVariable('MasterAdmin') + `/api/Domains/values?domainkey=US_STATES`
                                            }
                                        }
                                    },
                                    "transformProps": {
                                        "value": "Value",
                                        "name": "Name"
                                    }

                                }
                            },
                            {
                                "type": "formtextbox",
                                "key": "zip",
                                "options": {
                                    "row": 4,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Zip",
                                    "name": "Contacts.PrimaryContactAddress.Zip"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "Country",
                                "options": {
                                    "row": 4,
                                    "col": 1,
                                    "width": 6,
                                    //"xtype": "email",
                                    "label": "Country",
                                    "name": "Contacts.PrimaryContactAddress.Country"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "ZipExtension ",
                                "options": {
                                    "row": 5,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Zip Code Comments",
                                    "name": "Contacts.PrimaryContactAddress.ZipExtension"
                                },

                            },
                            {
                                "type": "cformphonetext",
                                "key": "Phone",
                                "options": {
                                    "row": 5,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Office Phone",
                                    "name": "Contacts.PrimaryContactAddress.Phone"
                                }
                            },
                            {
                                "type": "cformphonetext",
                                "key": "Phone",
                                "options": {
                                    "row": 5,
                                    "col": 1,
                                    "width": 6,
                                    "label": "Mobile Phone",
                                    "name": "Contacts.PrimaryContactAddress.SecondaryPhone"
                                }
                            },
                            {
                                "type": "cformphonetext",
                                "key": "Phone",
                                "options": {
                                    "row": 6,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Home Phone",
                                    "name": "Contacts.PrimaryContactAddress.HomePhone"
                                }
                            },
                            {
                                "type": "cformphonetext",
                                "key": "Fax",
                                "options": {
                                    "row": 6,
                                    "col": 1,
                                    "width": 6,
                                    "label": "Fax/Others",
                                    "name": "Contacts.PrimaryContactAddress.Fax"
                                }
                            },
                            {
                                "type": "formtextbox",
                                "key": "MaxUsers",
                                "options": {
                                    "row": 7,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Max Users",
                                    "name": "MaxUsers",
                                    "xtype": "number",
                                    "inputProps": { min: 1 },
                                    conditions: {
                                        hideall: true
                                    },
                                }
                            },
                            {
                                "type": "formswitch",
                                "key": "switch",
                                "options": {
                                    "row": 8,
                                    "col": 0,
                                    "width": 2,
                                    "label": "Status",
                                    "name": "Status",
                                    "transformProps": {
                                        "A": true,
                                        "I": false,
                                        true: "A",
                                        false: "I"
                                    },
                                    conditions: {
                                        hideall: true
                                    },
                                }
                            },
                            // {
                            //     "type": "submoduleselection",
                            //     "key": "SubModuleKey",
                            //     "options": {
                            //         "row": 9,
                            //         "col": 0,
                            //         "width": 6,
                            //         "label": "",
                            //         "text": "SubModuleKey",
                            //         "name": "CompaniesModules",
                            //         "islabel": true,
                            //         "labelinfo": "Request module access for",
                            //         "titleinfo": "Are you sure you want request module access?",
                            //         "urlinfo": "request"
                            //         //"hidelink": true
                            //         // "transformProps": {
                            //         //     "Y": true,
                            //         //     "N": false,
                            //         //     true: "Y",
                            //         //     false: "N"
                            //         // }
                            //     }
                            // },
                            {
                                "type": "historydate",
                                "key": "datecreated",
                                "options": {
                                    "row": 11,
                                    "col": 0,
                                    "width": 6,
                                    "text": "Created On : ",
                                    "name": "CreatedOn"
                                }
                            },
                            {
                                "type": "historyuser",
                                "key": "createdby",
                                "options": {
                                    "row": 11,
                                    "col": 1,
                                    "width": 6,
                                    "text": "Created By : ",
                                    "name": "CreatedByName"
                                }
                            },
                            {
                                "type": "historydate",
                                "key": "updatedon",
                                "options": {
                                    "row": 12,
                                    "col": 0,
                                    "width": 6,
                                    "text": "Updated On : ",
                                    "name": "UpdatedOn"
                                }
                            },
                            {
                                "type": "historyuser",
                                "key": "updatedbyname",
                                "options": {
                                    "row": 12,
                                    "col": 1,
                                    "width": 6,
                                    "text": "Updated By : ",
                                    "name": "UpdatedByName"
                                }
                            }
                        ]
                    },
                    {
                        "tabkey": 2,
                        "type": "formcontainer",
                        "key": "updatepassword",
                        "options": {
                            "totalcolumns": 2,
                            "totalrows": 13,
                            "alignitem": "center",
                            "title": "Update password",
                            //"savebtn": true,
                            headerProps: {

                                "savebutton": true,
                                "closebutton": true,
                                "navigateadd": true,
                                "editbutton": false,

                                //    "editbutton": true,
                                //    "savebutton": true,
                                //   "backbutton": true,
                                //  "closebutton": false,
                                // "navigateview": true,
                                "breadcrumbs": [
                                    { "link": "View/Edit Contractor", "materialicon": "edit", className: 'eps-breadcrumb-item ', class: 'text-light' }

                                ],
                            },
                            "hideEditButton": true,
                            "hideEditCheckName": "IsOfficial",
                            "hideEditCheckValue": "N",

                            designprops: true,
                            "tabadd": true,
                            "crud": {
                                "update": {
                                    "post": {
                                        "url": getEnvVariable('Admin') + '/api/Users/UpdatePassword',
                                        "customProps": {
                                            "custom": "userId"
                                        }
                                    },
                                    message: 'Password updated successfully'
                                }
                            },
                            "validations": [
                                {
                                    get: {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.users`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'MASTER.USERS_',
                                    }
                                },
                            ],
                        },

                        "components": [
                            {
                                "type": "formtextbox",
                                "key": "newpassword",
                                "options": {
                                    "row": 1,
                                    "col": 0,
                                    "width": 8,
                                    "label": "New Password",
                                    "name": "newpassword",
                                    "xtype": "password",
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "Password",
                                "options": {
                                    "row": 3,
                                    "col": 0,
                                    "width": 8,
                                    "label": "Confirm Password",
                                    "name": "Password",
                                    "xtype": "password",
                                    dependency: {
                                        equal: 'newpassword'
                                    },
                                },

                            },
                            {
                                "type": "infotext",
                                "key": "Infotext",
                                "options": {
                                    "row": 4,
                                    "col": 0,
                                    "width": 12,
                                    "text": "Password Policy:",

                                }
                            },
                            {
                                "type": "infotext",
                                "key": "Infotext",
                                "options": {
                                    "row": 5,
                                    "col": 0,
                                    "width": 12,
                                    "text": "1. Password must contain at least 10 characters.",

                                }
                            },
                            {
                                "type": "infotext",
                                "key": "Infotext",
                                "options": {
                                    "row": 6,
                                    "col": 0,
                                    "width": 12,
                                    "text": "2. Password should includes 1 Uppercase Alphabet[A-Z], 1 Lowercase Alphabet[a-z], 1 number[0-9], 1 Special Character.",

                                }
                            },
                            {
                                "type": "formcheckbox",
                                "key": "IsForcePasswordUpdate",
                                "options": {
                                    "row": 7,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Require this user to change the password when login",
                                    "name": "IsForcePasswordUpdate",
                                    initialvalue: 'N',
                                    "transformProps": {
                                        "Y": true,
                                        "N": false,
                                        true: "Y",
                                        false: "N"
                                    }
                                }
                            },
                        ]
                    },

                    {
                        "tabkey": 3,
                        ...SelectedApplicants
                    },

                ]
            },
        ]
    }]
}

export default externaluserprofile