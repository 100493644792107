import Axios from 'axios';

const URL = process.env.REACT_APP_API_URL

export default function Base(done, fail) {
    const instance = Axios.create({
        baseURL: URL,
        headers: { 'Access-Control-Allow-Origin':'*','Referrer-Policy': 'no-referrer' },
        // headers: {
        //     'Authorization': 'Bearer ' + authenticationService.getToken()
        // }
    })
    return instance;
}