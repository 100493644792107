/**
      *********************************************************
      Description: Right sidebar.
      Required props:  options,items. Items to generate icons and names.
      limitations    : N/A
      *********************************************************
**/
import { Typography } from '@material-ui/core';
//import MenuItem from '@material-ui/core/MenuItem';
//Expansion Panels For Tree
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Avatar from '@material-ui/core/Avatar';
import { deepPurple } from '@material-ui/core/colors';
// Menu Items and Grouping
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import withWidth from '@material-ui/core/withWidth';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link, withRouter } from "react-router-dom";
import { getEnvVariable } from '../../../modules/environmentalList';
import { getLandingPagelogo, hideForSubmodules } from '../../modules/header/layouts/ImageLogos';
//import useUnsavedChangesWarning from '../form/useUnsavedChangesWarning';


const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    purpleAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepPurple[500],
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(9.2) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px 0 0',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1) * 3,
    },
    last: {
        display: 'flex',
        position: 'fixed',
        bottom: '0px',
        backgroundColor: '#212529 !important',
        margin: '0px'
    }
});

const Accordion = withStyles({
    root: {
        //border: '1px solid rgba(0,0,0,.125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
            width: 'min-content'
        },
        '&:before': {
            display: 'none',
        },
        // margin: 'auto',
        marginTop: '1px',
        marginBottom: '0px',
        width: 'min-content'
    },
    expanded: {
        margin: '0px'
        // margin: 'auto',
        // marginTop: '1px',
        // marginBottom: '0px'
    },
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        //backgroundColor: 'rgba(0,0,0,.03)',
        //borderBottom: '1px solid rgba(0,0,0,.125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
        display: 'block'
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(props => <MuiAccordionSummary {...props} />);

AccordionSummary.muiName = 'AccordionSummary';

const AccordionDetails = withStyles(theme => ({
    root: {
        paddingLeft: '5px',
        display: 'block'
    },
}))(MuiAccordionDetails);


const SidebarView = withStyles(styles)((props) => {
    const { options, components, defaults, order, type, children, rules, drawerOpen, handleTitle, classes, theme, width, ...otherProps } = props;
    const { text, icon, items, anchor, ...otherOptions } = options
    let openSidebarDisplay = (props.width == "sm" || props.width == "xs" || props.width == "md") ? false : true
    const [open, setOpen] = React.useState(openSidebarDisplay);
    let Profile = localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))
    let typeAccess = Profile && Profile.configType == 'S' ? Profile.moduleSiteAccess : Profile.configType == 'R' ? Profile.moduleRegionAccess : Profile.moduleDistrictAccess
    let roleaccess = Profile && typeAccess ?
        Object.values(typeAccess).includes('Add') ? 'CanAdd' :
            Object.values(typeAccess).includes('Update') ? 'CanEdit' :
                Object.values(typeAccess).includes('Read') ? 'CanRead' : 'CanAdd' : 'CanAdd'
    let portal = props.accessPermissions ? props.accessPermissions.portal : null
    let canAdd = (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanAdd" ? true : (portal === "E") ? true : false
    let canEdit = (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanEdit" ? true : (portal === "E") ? true : false
    let canRead = (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanRead" ? true : (portal === "E") ? true : false



    React.useEffect(() => {
        setOpen(openSidebarDisplay);
    }, [props.width]);

    function handleDrawerOpen() {
        setOpen(true);
    }

    function handleDrawerClose() {
        setOpen(false);
    }

    if (Profile)
        return (
            <Drawer open={open}
                variant="permanent"
                anchor={anchor}
                className={classNames(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: classNames({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                {...otherOptions}
            >
                <div className={classes.toolbar}>
                    {(!open || getEnvVariable('ModuleKey') !== 'AEPS') && <Avatar style={{ margin: "auto" }} src={getLandingPagelogo(open)} />} &nbsp;
                    {(open && getEnvVariable('ModuleKey') === 'AEPS') && <img src={getLandingPagelogo() || ''} className="d-inline-block" width='auto' height='40px' />}
                    {open && <Fragment>
                        {getEnvVariable('ModuleKey') !== 'AEPS' && <Typography variant='h6'>{`${getEnvVariable('TitleKey')}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>}
                        <div /> <Popovers classes={classes} moduleRegionAccess={props.accessPermissions.moduleRegionAccess} canAdd={canAdd} /></Fragment>}
                    {
                        open && <IconButton title="Collapse" onClick={handleDrawerClose} >
                            {<ChevronLeftIcon />}
                        </IconButton>
                    }
                </div>
                {!open && <div className={classes.toolbar}>
                    <Popovers classes={classes} moduleRegionAccess={props.accessPermissions.moduleRegionAccess} />
                    <IconButton title="Expand" onClick={handleDrawerOpen} >
                        {<ChevronRightIcon />}
                    </IconButton>
                </div>}
                <Wrapper items={items} classes={classes} Profile={Profile} />
            </Drawer>
        );

    else return <div></div>
});



const Popovers = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    let submodules = JSON.parse(localStorage.getItem('submodule')) || []

    const CSSColorClasses = (style = '') => {
        let module = getEnvVariable('ModuleKey')
        switch (module) {
            case 'AEPS':
                return 'rgba(0, 53, 95, 255)'
            // case 'AMES':
            //     return 'rgb(41,51,82) !important'
            case 'WRS':
                return '#0076A5'
            case 'FORWARD':
                return '#0076A5'
            default:
                return '';
        }
    }

    return (
        <div>
            {/* {props && props.moduleRegionAccess.find(element => element === 'Add') && <IconButton onClick={handleClick} title="Quick Links"><AddIcon /></IconButton>} */}
            {props.canAdd && <IconButton onClick={handleClick} title="Quick Links" style={{ color: CSSColorClasses() }}><AddIcon /></IconButton>}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {submodules && Array.isArray(submodules) && submodules.length > 0 && submodules.map(res => {
                    if ((getEnvVariable('AccessPortal') == 'E' && res.key && (hideForSubmodules(res.key)))) {
                        return null
                    }
                    else {
                        return <Typography paragraph gutterBottom={true} className="m-3 " onClick={handleClose}>
                            <Link to={`${res.link}/NEW`}> {`Add ${res.title}`}</Link>
                        </Typography>
                    }
                })}
                <Divider />
            </Popover>
        </div>
    );
}
/**
      *********************************************************
      Description: Wrapper for generating the menu or grouping items or having the tree layout.
      Required props:  items
      limitations    : N/A
      *********************************************************
**/
const Wrapper = (props) => {
    const { items, handleTitle, classes, Profile } = props
    // setPristine();
    return items && items.length > 0 && items.map(each => {
        const { type, key, ...otherProps } = each
        if (type == 'menu')
            return <MenuItem {...otherProps} classes={classes} key={key} Profile={Profile} />
        if (type == 'group')
            return <GroupItem {...otherProps} classes={classes} key={key} />
        if (type == 'tree')
            return <TreeItem {...otherProps} classes={classes} key={key} />
        if (type == 'recordmenu')
            return <RecordMenuView {...otherProps} classes={classes} key={key} />
    })
}

/**
      *********************************************************
      Description: Generates list item.
      Required props:  N/A
      limitations    : N/A
      *********************************************************
**/

const MenuItem = withRouter(({ location, history, ...listitem }) => {
    const { link, handleTitle, classes, newtab, isProfile, Profile } = listitem;
    //   const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
    let tempColor = {
        "style": {
            'color': listitem.color || ''
        }
    }
    React.useEffect(() => {
        let linkisval = isProfile ? link + (Profile && Profile.modulePermissions && Profile.modulePermissions[0] && Profile.modulePermissions[0].companyId) : link;
        if (linkisval === location.pathname) {
            //    setPristine();
        }
    }, [location.pathname])
    if (link) {
        const linkis = isProfile ? link + (Profile && Profile.modulePermissions && Profile.modulePermissions[0] && Profile.modulePermissions[0].companyId) : link;
        return (
            <Fragment>
                {!newtab && <Tooltip title={listitem.title} placement="top-start">
                    <Link to={linkis}>
                        <ListItem button selected={linkis === location.pathname} style={{ paddingRight: '0px', paddingTop: '4px', paddingBottom: '4px' }} >
                            {<ListItemIcon><Icon style={listitem.style || tempColor.style}>{listitem.materialicon} </Icon></ListItemIcon>}
                            <ListItemText
                                key={listitem.materialicon}><Typography color={'secondary'} variant="body2">{listitem.title}</Typography></ListItemText>
                        </ListItem>
                    </Link>
                </Tooltip >}

                {
                    newtab && <Tooltip title={listitem.title}><a href={linkis} target={newtab ? "_blank" : ""} rel="noopener noreferrer" placement="top-start" >
                        <ListItem button selected={linkis === location.pathname} style={{ paddingRight: '0px', paddingTop: '4px', paddingBottom: '4px' }} >
                            {<ListItemIcon><Icon style={listitem.style || tempColor.style} >{listitem.materialicon} </Icon></ListItemIcon>}
                            <ListItemText
                                key={listitem.materialicon}><Typography color={'secondary'} variant="body2">{listitem.title}</Typography></ListItemText>
                        </ListItem>
                    </a>
                    </Tooltip>
                }
            </Fragment >
        )

    }//selected
    else {
        return (
            <ListItem>
                <ListItemText key={listitem.title}><Typography color={'secondary'} variant="body2">{listitem.title}</Typography></ListItemText>
            </ListItem>
        )
    }

})
/**
      *********************************************************
      Description: Generates items.
      Required props:  N/A
      limitations    : N/A
      *********************************************************
**/
const GroupItem = (groupitems) => {
    return (
        <Fragment>
            <Divider />
            <List>
                <Wrapper {...groupitems.items} />
            </List>
            <Divider />
        </Fragment>
    )
}
/**
      *********************************************************
      Description: Generates items in the form of a tree with a expand collapse icon.
      Required props:  N/A
      limitations    : N/A
      *********************************************************
**/

const CSSColorClasses = (style = '') => {
    let module = getEnvVariable('ModuleKey')
    switch (module) {
        case 'WRS':
            return '#0076A5'
        // case 'AMES':
        //     return 'rgb(41,51,82)'
        case 'UPS':
            return '#004785'
        default:
            return '#6200ea';
    }
}

const TreeItem = (treeitems) => {
    const { title, items, materialicon, lskey } = treeitems;
    return (
        <Accordion defaultExpanded={title === 'Dashboard' ? true : false} >
            <AccordionSummary style={{ padding: 0 }} key='Accordionsummary' > {/*expandIcon={<ExpandMoreIcon />}*/}
                <Tooltip title={title} placement="top-start">
                    <ListItem button >
                        <ListItemIcon> <Icon style={{ color: CSSColorClasses() }}>{materialicon} </Icon> </ListItemIcon>
                        <ListItemText><Typography color={'secondary'} variant="body2">{title}</Typography></ListItemText>
                    </ListItem>
                </Tooltip>
            </AccordionSummary>
            <AccordionDetails key='Accordiondetails'>
                <Wrapper items={lskey ? JSON.parse(localStorage.getItem(lskey)) : items} />
            </AccordionDetails>
        </Accordion>
    )
}

const RecordMenuView = (treeitems) => {
    const { title, items, materialicon, lskey } = treeitems;
    // const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
    // setDirty(false);
    // setPristine();
    return (
        <Wrapper items={lskey ? JSON.parse(localStorage.getItem(lskey)) : items} />
    )
}

SidebarView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withWidth()(withRouter(SidebarView));

