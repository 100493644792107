import Fab from '@material-ui/core/Fab';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { withStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ConfirmationDialog from "../../core/controls/components/confirmationPopup";
import { snackbarMessage } from '../../core/actions';
import defaultImage from '../../core/resources/ProfilePic.jpg';
import defaultSigImage from '../../core/resources/pencil.png';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',

    },
    title: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 'bold'
    },
    subtitle: {
        lineHeight: 1,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
});


class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openConfirmation: false,
            imagename: "",
        };
    }

    handleFileUpload = (event) => {
        const name = event.target.name;
        this.toBase64(event)
            .then(result => {
                const answer_array = result.split(';');
                if (answer_array[0] === "data:image/png" || answer_array[0] === "data:image/jpeg") {
                    var e = {
                        target: {
                            name: name,
                            value: result
                        }
                    }
                    this.props.handleFieldChange(e)
                }
                else {
                    this.props.snackbarMessage('Please upload files with correct extension, supported file extensions are JPEG and PNG.', 'error')
                }
            })
            .catch(ex => console.log(ex));
    }

    handleImageUpload = (event) => {
        const name = event.target.name;
        this.toBase64(event)
            .then(result => {

                var e = {
                    target: {
                        name: name,
                        value: result
                    }
                }
                this.props.handleFieldChange(e)

            })
            .catch(ex => console.log(ex));
    }
    toBase64 = (event) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        })
    };

    RemoveImage = (event) => {
        this.setState({ openConfirmation: true, imagename: event.target.name })
    }

    ConfirmDelete = () => {
        this.setState({ openConfirmation: false })
        var e = {
            target: {
                name: this.state.imagename,
                value: ""
            }
        }
        this.props.handleFieldChange(e)
    }

    handleCancelDelete = () => {
        this.setState({ openConfirmation: false })
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <small className='fw-bold' style={{ fontSize: '12px' }}>{this.props.text}</small>
                <GridList className={classes.gridList} >
                    <GridListTile style={{
                        // height: '150px',
                        width: '60%'
                    }}>
                        <img style={{ display: 'block' }} src={this.props.value && this.props.value != '' ? (this.props.value) : this.props.profile ? defaultImage : defaultSigImage} alt="Image" />
                        {this.props.value && <GridListTileBar
                            // title={<small>{this.props.text}</small>}
                            classes={{
                                root: classes.titleBar,
                                title: classes.title,
                            }}
                            // subtitle={<span>png,jpeg</span>}
                            actionIcon={
                                this.props.edit && <Fab size="small" component="label" color='secondary'  >
                                    <DeleteIcon /> <input type="button" onClick={this.RemoveImage} name={this.props.name} hidden />
                                </Fab>

                            }
                        />
                        }
                        {!this.props.value && <GridListTileBar
                            // title={this.props.text}
                            classes={{
                                root: classes.titleBar,
                                title: classes.title,
                            }}
                            actionIcon={
                                this.props.edit && <Fab size="small" component="label" color='secondary' >
                                    <CloudUploadIcon /> <input type="file" name={this.props.name} onChange={this.props.imagesupload ? this.handleImageUpload : this.handleFileUpload} hidden />
                                </Fab>
                            }
                        />
                        }
                    </GridListTile>
                </GridList>
                {this.state.openConfirmation && <ConfirmationDialog value={this.state.openConfirmation} title="Are you sure you want to delete?" onSubmit={this.ConfirmDelete} onClose={this.handleCancelDelete} />}
                {!this.props.imagesupload && this.props.edit && <span style={{ fontSize: '10px' }} className="row col text-secondary">Supported file type extensions are png and jpeg.</span>}
            </Fragment>
        )
    }
}
const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

FileUpload = connect(null, mapActions)(FileUpload)

export default withStyles(styles)(FileUpload);
