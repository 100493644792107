/**        
      *********************************************************
      Description: Intermeditiary component between cform.js and createsubform in utilities.js for handling the form layout and updating the field values.
      Required props:  N/A.
      limitations    : N/A
      *********************************************************
**/
import React, { Component, Fragment } from 'react';
import { createSubFormLayout } from './utilities'


class CSubForm extends Component {
    state = {
        memoizeLayout: null
    }
    componentDidMount() {
        this.setMemoizeLayout()
    }

    setMemoizeLayout(){
        const { changedFieldName, ...otherProps } = this.props
        this.setState({
            memoizeLayout: createSubFormLayout(otherProps)
        })
    }

    componentDidUpdate(prevProps,prevState){      
        if(prevProps.dataItem &&  Object.entries(prevProps.dataItem).toString() !==  Object.entries(this.props.dataItem).toString()) {
            this.setMemoizeLayout()
        }
    }
    
    render() {
       
        const { memoizeLayout } = this.state
        const { changedFieldName, ...otherProps } = this.props
        return <Fragment> 
            {memoizeLayout && memoizeLayout(changedFieldName)}
        </Fragment>
    }


}

export default CSubForm;