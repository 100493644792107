import React, { Fragment, Component } from 'react';
import Form from '../form/cform'
import savedreportsjson from '../../../json/reports/savedreports'

const statusoptions = [{
    label: 'Active',
    value: 'A'
}, {
    label: 'Inactive',
    value: 'I'
}]


const defaultoptions = [{
    label: 'Count',
    value: 1
}, {
    label: 'List',
    value: 2
}]


class CreateReport extends Component {

    state = {
        report: {},
        errors: null,
        customSave: false,
        hascustomvalidations : false
    }
    componentsArray = { ...savedreportsjson };
    optionprops = this.componentsArray
    finalprops = { ...this.optionprops, ...this.props }
    savePermit = () => {
        const { report } = this.state
        if (report) {
            if (report.Name) {
                report.CanEdit = 'Y';
                report.ShowOnUi = 'Y';
                report.PageOreientation = 'P';
                report.Status = 'A';
                report.Title = report.Name;
                report.IsDynamicQuery = 'N'
                if (!this.props.iscgrid) {
                    report.ReportJsonQueryStr = JSON.stringify(this.props.value)
                }

                return report
            }
            this.setState({ customSave: true })
            return report
        }
        else return false
    }


    handleSelectedColumns = () => {
        let list = []
        const { fields } = this.props;
        fields && fields.length > 0 && fields.map(table => {
            table.col.map(each => {
                if (each.config.edit) {
                    list.push(each)
                }
            })
        })
        return list;
    }

    componentDidMount() {
        const { report } = this.state;
        report.ReportJsonQueryStr = this.props.ReportJsonQueryStr ? this.props.ReportJsonQueryStr : (this.props.results && this.props.results.ReportJsonQueryStr ?this.props.results.ReportJsonQueryStr : "")
        this.setState({ report: report })
    }

    customValidation = (LayoutForm) => {
        let errorsdata = {}
        let strlengthreport = LayoutForm && LayoutForm.Name && LayoutForm.Name != null ? LayoutForm.Name.length : ""
        let strlengthreportdesc = LayoutForm && LayoutForm.Description && LayoutForm.Description != null ? LayoutForm.Description.length : ""
        if(LayoutForm.Name && LayoutForm.Name.match(/^\s+$/) !== null)
            {
                let msg = ['Field is not valid']
                errorsdata.Name = msg
            }
        else if(LayoutForm.Description && LayoutForm.Description.match(/^\s+$/) !== null)
            {
                let msg = ['Field is not valid']
                errorsdata.Description = msg
            }
        else if(LayoutForm.Name && LayoutForm.Name != "" && (LayoutForm.Name.charAt(0) === " " || LayoutForm.Name.charAt(0) === '\n' || LayoutForm.Name.charAt(strlengthreport - 1) === " "))
            {
                let msg = ['Leading or trailing spaces are not allowed']
                errorsdata.Name = msg
            }
        else if(LayoutForm.Description && LayoutForm.Description != "" && (LayoutForm.Description.charAt(0) === " " || LayoutForm.Description.charAt(0) === '\n' || LayoutForm.Description.charAt(strlengthreportdesc - 1) === " "))
            {
                let msg = ['Leading or trailing spaces are not allowed']
                errorsdata.Description = msg
            }
            else if(LayoutForm.Name && LayoutForm.Name != "" && LayoutForm.Name.length > 100)
            {
                let msg = ['Name length should be in the range of (1-100)']
                errorsdata.Name = msg
            }
            else if(LayoutForm.Description && LayoutForm.Description != "" && LayoutForm.Description.length > 4000)
            {
                let msg = ['Description length should be in the range of (1-4000)']
                errorsdata.Description = msg
            }
            return errorsdata
    }

    componentDidUpdate(prevProps, prevState) {
        if((prevProps.checkval != this.props.checkval) && this.props.checkval){
               this.setState({checkval:true})
        }
       if(this.state.checkval && this.state.hascustomvalidations != this.props.hascustomvalidations) {
           this.setState({hascustomvalidations : this.props.hascustomvalidations})
       }
    }

    getState = (data) => {
        this.setState({ report: data.results, hascustomvalidations: false, checkval:false })
        this.props.callbackfunc();

    }

    handleCustomSave = () => {
        this.setState({ customSave: false })
    }

    render() {
        const { report, errors, masterreports, customSave } = this.state;
        return (
            <Fragment>
                <Form {...this.finalprops} customedit={true} compmap={this.props.compmapdata} getState={this.getState} showcustomvalidations={true} hascustomvalidations = {this.state.hascustomvalidations} errordata={this.props.errordata} customSave={customSave} handleCustomSave={this.handleCustomSave} windowHeight={true} />

            </Fragment>
        )
    }
}




export default CreateReport;