import { withStyles } from "@material-ui/core/styles";
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { snackbarMessage } from '../../core/actions';
import { requestApi } from '../../core/modules/form/utilities';
import { getEnvVariable } from '../environmentalList';
import S3Upload from './S3Upload';
const _ = require('lodash');

const styles = theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        overflow: "auto",
        "& > *": {
            margin: theme.spacing(1),
            width: theme.spacing(120),
            height: theme.spacing(60)
        }
    }
});
class MultipleFileUpload extends React.Component {
    constructor(props) {
        super(props)
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.fileInput = React.createRef();
        this.state = {
            FileName: null,
            ContentStr: null,
            ContentType: null,
            multiplefiles: [],
            open: false,
            files: [],
            openconfirmation: false,
            deleteattachmentindex: null

        }

    }

    componentDidMount() {
        const { multiplefiles } = this.props
        if (multiplefiles && multiplefiles.length > 0) {
            this.setState({ multiplefiles: this.props.multiplefiles })
        }
    }

    componentDidUpdate(prevState, prevProps) {
        const { multiplefiles } = this.props
        if ((prevProps.multiplefiles !== this.props.multiplefiles)) {
            this.setState({ multiplefiles: this.props.multiplefiles })
        }
    }

    handleFileUpload = (event) => {
        let file = []
        event.preventDefault();
        const name = this.fileInput.current.files[0].name;
        const size = this.fileInput.current.files[0].size;
        this.toBase64(event)
            .then(result => {
                var e = {
                    target: {
                        name: name,
                        value: result
                    }
                }
                let attach = result.split(',')
                if (attach.length === 2)
                    file.push({ ContentType: attach[0], ContentStr: attach[1], FileName: name, Size: size });
                let multiplefiles = [...this.state.multiplefiles, ...file];

                //500MB
                if (size > 500000000) {
                    this.props.snackbarMessage('Uploading size exceeded 500MB', 'error')
                } else {
                    this.setState({ multiplefiles: multiplefiles }, () => {
                        this.props.handleAttachmentSet({ ContentType: attach[0], ContentStr: attach[1], FileName: name })
                    })
                }

            }).catch(ex => console.log(ex));
    }

    toBase64 = (event) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        })
    };

    getUploadResult = () => {
        let res = this.state.multiplefiles;
        return res
    }

    handleDownload = (dFile, fileName) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = dFile;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    OpenConfirmationMessage = (index) => {
        this.setState({ openconfirmation: true, deleteattachmentindex: index })
    }

    handleDelete = (e) => {
        const { multiplefiles, deleteattachmentindex } = this.state;

        let deletefile = multiplefiles.filter((m, i) => { return i == deleteattachmentindex })
        var deletefilearr = _.map(deletefile, function (a) {
            return { ...deletefile[0], Status: "I" };
        });
        let remFiles = multiplefiles.filter((m, i) => { return i !== deleteattachmentindex })
        var finalarr = _.concat(remFiles, deletefilearr);
        this.setState({ openconfirmation: false, deleteattachmentindex: null })
        this.props.handleDelete(remFiles, finalarr);
    }

    CloseConfirmationMessage = () => {
        this.setState({ openconfirmation: false, deleteattachmentindex: null })
    };

    CancelDelete = (e) => {
        this.setState({ openconfirmation: false, deleteattachmentindex: null })
    }

    download = (filename, result) => {
        let data = result.split(',')
        var element = document.createElement('a');
        element.setAttribute('href', result); //data:text/plain;base64,
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    DownloadAttachment = (Name, ContentStr) => {
        const linkSource = ContentStr;
        const downloadLink = document.createElement("a");
        const fileName = Name;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    zipAttachDownload = () => {
        const { archiveParentId, downloadAttachId } = this.props;
        requestApi({ get: { url: getEnvVariable('Application') + `/api/Attachments/${this.props.archiveParentId}/attachmentarchive/${this.props.downloadAttachId}` } })
            .then((results) => {
                this.download(results.result.FileName, results.result.ContentStr)
            })
    }

    viewAttachments = (Id, dFile, fileName) => {
        if (Id >= 0) {
            requestApi({ get: { url: getEnvVariable('Application') + `/api/Attachments/DownloadFile/${Id}` } })
                .then((results) => {
                    this.DownloadAttachment(results.FileName, results.ContentStr)
                })
        }
        else {
            this.handleDownload(dFile, fileName);
        }
    }

    downloadAttachments = (Id, dFile, fileName) => {
        if (Id >= 0) {
            requestApi({ get: { url: getEnvVariable('Application') + `/api/Attachments/Download/${Id}` } })
                .then((result) => {
                    if (result && result.FileId) {
                        let downloadurl = getEnvVariable('Application') + `/api/Attachments/Downloadcontent/${result.FileId}`
                        window.open(downloadurl, '_blank').focus();
                    }
                })
        }
        else {
            this.handleDownload(dFile, fileName);
        }
    }


    render() {
        const { classes, ismultiple, zipDownload } = this.props;
        const { multiplefiles } = this.state
        return (
            <div className={classes.root}>
                <S3Upload {...this.props} filetype={"Attachments"} />
                {/* <S3NativeUpload {...this.props} filetype={"Attachments"}/> */}
            </div >
        );
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

MultipleFileUpload = connect(null, mapActions)(MultipleFileUpload)

export default withStyles(styles)(MultipleFileUpload);