import React, { Component } from 'react';

class Map extends Component {



    render() {

        return (
            <div className='col-sm-12'>
                map
            </div>
        )
    }
}




export default Map;