import { getEnvVariable } from "../../../modules/environmentalList"

const ViewProposedLandUse = {
    "type": "popup",
    "key": "ViewProposedLandUse",
    "name": "ViewProposedLandUse",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'editOpen'
    },
    sharedValues: ['open'],
    container: {
        closepopup: true,
        closepopupval: 'proposedlanduse',
        "addRoute": true,
        'removelayout': 'ViewProposedLandUse',
        values: { editInfo: null, edit: false, createduplicate: false, fullscreen: false, back: false, allgriddata: null, navigation: null },
        mapValues: {
            header: [
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'createduplicate', name: 'createduplicate' },
                { key: 'allgriddata', name: 'allgriddata' },
            ],
            ViewProposedLand: [
                { key: 'editInfo', name: 'editInfo' },
                { key: 'allgriddata', name: 'allgriddata' },
                { key: 'save', name: 'save' },
                { key: 'saveduplicate', name: 'saveduplicate' },
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'createduplicate', name: 'createduplicate' },
                { key: 'back', name: 'back' },
                { key: 'navigation', name: 'navigation' },]
        },
        //  sharedValues: ['save', 'edit'],
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },


            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'ViewProposedLand',
                    type: 'div',
                    className: 'col-12 pl-4',
                    sub: {
                        type: 'div',
                        className: 'col-12 pl-4',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Proposed Land Use',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "View/Edit Proposed Land Use", "appendval": "UsageNotes", "materialicon": "landscape", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },

                    {
                        type: 'icon',
                        icon: 'edit',
                        name: 'edit',
                        hideon: 'createduplicate',
                        hideanother: 'edit',
                        hidevalue: true,
                        title: 'Edit Proposed Land Use'
                    },
                    {
                        type: 'icon',
                        icon: 'duplicate',
                        name: 'createduplicate',
                        hideon: 'edit',
                        hideanother: 'createduplicate',
                        hidevalue: true,
                        title: 'Clone Proposed Land Use'
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'edit',
                        hidevalue: false,
                        title: 'Save Proposed Land Use'
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'saveduplicate',
                        hideon: 'createduplicate',
                        hidevalue: false,
                        title: 'Save Proposed Land Use'
                    },
                    {
                        type: 'icon',
                        icon: 'previtem',
                        name: 'previtem',
                        hideon: 'previtem',
                        hidevalue: false,
                        title: 'Previous Item'
                    },
                    {
                        type: 'icon',
                        icon: 'nextitem',
                        name: 'nextitem',
                        hideon: 'nextitem',
                        hidevalue: false,
                        title: 'Next Item'
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        title: 'FullScreen',
                        hidevalue: false,
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        title: 'Exit FullScreen',
                        hidevalue: false
                    },
                    {
                        type: 'icon',
                        icon: 'history',
                        name: 'history',
                        title: 'History',
                        hideon: 'history',
                        hidevalue: false,
                        historyurl: {
                            title: "Proposed Land Use History",
                            url: getEnvVariable('Application') + `/api/ProposedLandUse/history/{Id}?`,
                            "routeProps": [{ name: "id", key: "Id", },],
                            type: 'get',
                            saveAs: { key: 'data' }
                        }
                    },

                ],


            },
            ViewProposedLand: {
                "type": "form",
                initRun: true,
                "key": "ViewProposedLand",
                "name": "ViewProposedLand",
                aliases: {
                    'editInfo': 'editInfo',
                    'allgriddata': 'allgriddata',
                    'navigateItem': 'navigateItem',
                    'edit': 'edit'
                },
                sharedValues: ['editInfo', 'allgriddata', 'navigateItem', 'navigation', 'edit', 'save', 'createduplicate', 'exitfullscreen', 'fullscreen', 'back'],
                validationMapKeys: [],
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'ProposedHeader',
                                type: 'div',
                                className: 'col-12',
                                sub: {
                                    type: 'div',
                                    className: 'col-12',
                                }
                            }],

                        },
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'UsageNotes',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            }],

                        },

                        {
                            order: 1,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'LandUse',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8'
                                }
                            },

                            ]
                        },
                        {
                            order: 2,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Unit',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8'
                                }
                            },

                            ]
                        },
                        {
                            order: 3,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'LandUseADT',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8'
                                }
                            },]
                        },
                        {
                            order: 4,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Id',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8'
                                }
                            },]
                        },
                        {
                            order: 5,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'PropertyGroupID',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8'
                                }
                            },]
                        },
                        {
                            order: 6,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'CreatedOn',
                                type: 'div',
                                className: 'col-md-12 col-lg-6',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-12 col-lg-6'
                                }
                            },
                            {
                                name: 'CreatedByName',
                                type: 'div',
                                className: 'col-md-12 col-lg-6',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-12 col-lg-6'
                                }
                            },
                            ]
                        },
                        {
                            order: 7,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'UpdatedOn',
                                    type: 'div',
                                    className: 'col-md-12 col-lg-6',
                                    sub: {
                                        type: 'div',
                                        className: 'col-md-12 col-lg-6'
                                    }
                                },
                                {
                                    name: 'UpdatedByName',
                                    type: 'div',
                                    className: 'col-md-12 col-lg-6',
                                    sub: {
                                        type: 'div',
                                        className: 'col-md-12 col-lg-6'
                                    }
                                },]
                        },
                    ]
                },
                values: {
                    UsageNotes: '',
                    LandUse: '',
                    Unit: 0,
                    LandUseADT: '',
                    Id: '',
                    PropertyGroupID: '',
                    UpdatedByName: '',
                    CreatedByName: '',
                    UpdatedOn: '',
                    CreatedOn: '',
                    UpdatedBy: '',
                    CreatedBy: '',
                    editInfo: null,
                    allgriddata: null,
                    navigation: null,
                    portal: getEnvVariable('AccessPortal') === 'E' ? 'N' : 'Y',
                    ProposedHeader: ''
                },
                //doubt
                dataAttributes: {
                    LandUse: {},

                },

                "components": {
                    ProposedHeader: {
                        "type": "label",
                        "key": "ProposedHeader",
                        "label": "Proposed Land Use",
                        "name": "ProposedHeader",
                        //   disabled: true,
                        changefieldto: "label",

                    },
                    UsageNotes: {
                        "type": "label",
                        "key": "UsageNotes",
                        "label": "Usage Notes",
                        "name": "UsageNotes",
                        //   disabled: true,
                        changefieldto: "textfield",

                    },
                    LandUse: {
                        "type": "label",
                        "key": "LandUse",
                        "label": "Land Use ",
                        "name": "LandUse",
                        changefieldto: "dropdown",
                        //  disabled: true,

                        extraProps: {
                            transformProps: {
                                label: 'Name',
                                value: 'Value',

                            },
                            dataAttributes: [
                                {
                                    key: 'data-calculatedvalue',
                                    name: 'CalculatedValue'
                                }
                            ]
                        }
                    },
                    Unit: {
                        "type": "label",
                        "key": "unit",
                        "label": "Unit Count",
                        name: "Unit",
                        "xtype": "number",
                        // disabled: true,
                        changefieldto: "textfield",

                    },
                    LandUseADT: {
                        "type": "label",
                        "key": "LandUseADT",
                        "label": "Land Use ADT",
                        name: "LandUseADT",
                        "xtype": "number",
                        disabled: true,
                        changefieldto: "domainCalVal",

                    },
                    Id: {
                        "type": "label",
                        "key": "Id",
                        "label": "Land Use ID",
                        name: "Id",
                        disabled: true,
                        changefieldto: "textfield"
                    },
                    PropertyGroupID: {
                        "type": "label",
                        "key": "PropertyGroupID",
                        "label": "Property Group ID",
                        name: "PropertyGroupID",
                        //"xtype": "number",
                        disabled: true,
                        changefieldto: "textfield",

                    },
                    CreatedOn: {
                        "type": "label",
                        "key": "CreatedOn",
                        "label": "Created On",
                        name: "CreatedOn",

                        changefieldto: "label",
                    },
                    CreatedByName: {
                        "type": "label",
                        "key": "CreatedByName",
                        "label": "Created By",
                        name: "CreatedByName",

                        changefieldto: "label",
                    },
                    UpdatedOn: {
                        "type": "label",
                        "key": "UpdatedOn",
                        "label": "Updated On",
                        name: "UpdatedOn",

                        changefieldto: "label",
                    },
                    UpdatedByName: {
                        "type": "label",
                        "key": "UpdatedByName",
                        "label": "Updated By",
                        name: "UpdatedByName",

                        changefieldto: "label",
                    },

                },
                mapActionsToEffects: {
                    'onLoad': {
                        'init': {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        { // add and edit
                                            type: 'load',
                                            name: 'LandUse',
                                            "read": [
                                                {
                                                    url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=PROPOSEDLANDUSE`,
                                                    type: 'get',
                                                    saveAs: {
                                                        key: 'items'
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            type: 'load',
                                            name: 'LandUseADT',
                                            "read": [
                                                {
                                                    url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=PROPOSEDLANDUSE`,
                                                    type: 'get',
                                                    saveAs: {
                                                        key: 'items'
                                                    }
                                                }
                                            ]
                                        },

                                        {
                                            type: 'validations', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                            "read": [
                                                {
                                                    "url": getEnvVariable('Application') + `/api/ProjectMessages/RulesByName?name=modulemaster.proposedlanduse`,
                                                    type: 'get'
                                                }
                                            ],
                                            transformProps: {
                                                removesubfieldname: 'AMESMODULEMASTER.EXISTINGLANDUSE_'
                                            }
                                        },
                                        {
                                            type: 'businessrules',
                                            "read": [
                                                {
                                                    url: getEnvVariable('Application') + `/api/BusinessRules/Grid/modulemaster.proposedlanduse`,
                                                    type: 'get',
                                                }
                                            ],
                                        },
                                    ]
                                }
                            }]
                        },
                        'GLOBAL': {
                            rules: [

                                {
                                    canOverwrite: false,
                                    check: [
                                        {
                                            condition: "AND",
                                            rules: [
                                                { name: "portal", type: "=", value: "N", rules: [] },
                                            ]
                                        }
                                    ],
                                    fields: ["Unit"],
                                    ruleOrder: 0,
                                    run: {

                                        inValid: [
                                            { type: "prop", name: "LandUseADT", disabled: true, isVisible: true },
                                            { type: "prop", name: "PropertyGroupID", disabled: true, isVisible: true },
                                            { type: "prop", name: "Id", disabled: true, isVisible: true },
                                        ],
                                        //   type: "uncheck",
                                        valid: [
                                            { type: "prop", name: "LandUseADT", disabled: true, isVisible: false },
                                            { type: "prop", name: "PropertyGroupID", disabled: true, isVisible: false },
                                            { type: "prop", name: "Id", disabled: true, isVisible: false },
                                        ],

                                    },
                                    uncheck: true
                                },

                            ]
                        },
                    },
                    'onChange': {
                        'navigation': {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'initial', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                            "read": [
                                                {
                                                    url: getEnvVariable('Application') + `/api/ProposedLandUse/{routeid1}/proposedLandUse/{Id}`,
                                                    key: 'save',
                                                    "matchProps": [
                                                        {
                                                            name: "routeid1",
                                                            key: "routeid1",
                                                        },
                                                        {
                                                            name: "navigation.Id",
                                                            key: "Id",
                                                        }
                                                    ],
                                                    type: 'get',
                                                }
                                            ]
                                        },
                                    ]
                                }
                            }]
                        },
                        'edit': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'edit',

                                            },
                                            {
                                                type: 'loadValues',
                                                name: 'LandUse',
                                                loadtransformProps: {
                                                    label: 'Name',
                                                    value: 'Value',
                                                    defaultCondition: 'IsDefault',
                                                    checkDefaultValue: 'Y',
                                                    savetype: 'update'
                                                },
                                                initialdataAttributes: {
                                                    CalculatedValue: 'calculatedvalue',
                                                    Value: 'value',
                                                }
                                            },

                                        ]
                                    }
                                },

                            ]
                        },
                        'exitfullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'exitfullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'fullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'fullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'createduplicate': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'createduplicate',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },

                        'GLOBAL': {
                            rules: [
                                // {
                                //     canOverwrite: false,
                                //     check: [
                                //         {
                                //             condition: "AND",
                                //             rules: [
                                //                 { name: "Unit", type: "is not null", rules: [] },
                                //                 { name: "portal", type: "=", value: 'Y', rules: [] },
                                //                 { name: "LandUse", type: "is not null", rules: [] }
                                //             ]
                                //         }
                                //     ],
                                //     fields: ["LandUse", 'Unit'],
                                //     run: {
                                //         valid: [{
                                //             type: 'calculate',
                                //             name: 'LandUseADT',
                                //             attr: 'calculatedvalue',
                                //             operands: 'LandUse',
                                //             quotient: 'Unit',
                                //             operator: '*',
                                //             hasValue: true,
                                //             valueType: 'D',
                                //             isVisible: true,disabled:true

                                //         }],
                                //         inValid: [{
                                //             type: 'calculate',
                                //             name: 'LandUseADT',
                                //             attr: 'calculatedvalue',
                                //             operands: 'LandUse',
                                //             quotient: 'Unit',
                                //             operator: '*',
                                //             hasValue: true,
                                //             valueType: 'D',
                                //             isVisible: false,disabled:true

                                //         }
                                //         ]
                                //     },
                                // },

                                {
                                    canOverwrite: false,
                                    check: [
                                        {
                                            condition: "AND",
                                            rules: [
                                                { name: "LandUse", type: ">", value: "0", rules: [] },
                                                { name: "LandUse", type: "=", value: "20", rules: [] },
                                            ]
                                        }
                                    ],
                                    fields: ["LandUse"],
                                    ruleOrder: 0,
                                    run: {

                                        inValid: [
                                            { type: "prop", name: "Unit", required: true, disabled: false },
                                            { type: "prop", name: "UsageNotes", required: false, disabled: false },

                                        ],
                                        //   type: "uncheck",
                                        valid: [
                                            { type: "prop", name: "Unit", required: false, disabled: false },
                                            { type: "prop", name: "UsageNotes", required: true, disabled: false },

                                        ],

                                    },
                                    uncheck: true
                                },
                            ],
                            fields: [
                                'Unit', 'LandUse'
                            ]
                        },

                    },

                    'afterSave': {
                        'saveduplicate': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Application') + `/api/ProposedLandUse/{routeid1}/Add`,
                                                        type: 'post',
                                                        "matchProps": [{
                                                            name: "routeid1",
                                                            key: "routeid1",
                                                        }]
                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'save': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',// http://development.delasoft.com:8443/AMES-application/api/Property/23123/Add
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Application') + `/api/ProposedLandUse/{routeid1}/Update/{Id}`,
                                                        type: 'put',
                                                        "matchProps": [{
                                                            name: "routeid1",
                                                            key: "routeid1",
                                                        },
                                                        {
                                                            name: "Id",
                                                            key: "Id",
                                                        }
                                                        ]
                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    },

                }
            }
        },

    }
}

export default ViewProposedLandUse







// const ViewProposedLand = {
//     "type": "form",
//     "key": "ViewProposedLand",
//     "name": "ViewProposedLand",

//     layout: {
//         group: [
//             {
//                 order: 0,
//                 type: 'div',
//                 className: 'row',
//                 components: [{
//                     name: 'TaxLotType',
//                     type: 'div',
//                     className: 'col-4',
//                     sub: {
//                         type: 'div',
//                         className: 'col-4',
//                     }
//                 }],

//             },

//             {
//                 order: 1,
//                 type: 'div',
//                 className: 'row',
//                 components: [{
//                     name: 'TownShip',
//                     type: 'div',
//                     className: 'col-2',
//                     sub: {
//                         type: 'div',
//                         className: 'col-2'
//                     }
//                 },
//                 {
//                     name: 'Range',
//                     type: 'div',
//                     className: 'col-2',
//                     sub: {
//                         type: 'div',
//                         className: 'col-2',
//                     }
//                 },
//                 {
//                     name: 'Section',
//                     type: 'div',
//                     className: 'col-2',
//                     sub: {
//                         type: 'div',
//                         className: 'col-2'
//                     }
//                 },
//                 {
//                     name: 'ViewProposedLand',
//                     type: 'div',
//                     className: 'col-2',
//                     sub: {
//                         type: 'div',
//                         className: 'col-2'
//                     }
//                 },]
//             },
//             {
//                 order: 2,
//                 type: 'div',
//                 className: 'row',
//                 components: [{
//                     name: 'IsGivenEasement',
//                     type: 'div',
//                     className: 'col-2',
//                     sub: {
//                         type: 'div',
//                         className: 'col-2'
//                     }
//                 },
//                 {
//                     name: 'IsSiteEasement',
//                     type: 'div',
//                     className: 'col-2',
//                     sub: {
//                         type: 'div',
//                         className: 'col-2'
//                     }
//                 },
//                 {
//                     name: 'IsBoundaryCorrect',
//                     type: 'div',
//                     className: 'col-3',
//                     sub: {
//                         type: 'div',
//                         className: 'col-3'
//                     }
//                 },]
//             },
//         ],
//     },
//     values: {
//         TaxLotType: '',
//         TownShip: '',
//         Range: '',
//         Section: '',
//         ViewProposedLand: '',
//         IsGivenEasement: '',
//         IsSiteEasement: '',
//         IsBoundaryCorrect: ''
//     },

//     "components": {
//         TaxLotType: {
//             "type": "dropdown",
//             "key": "TaxLotType",
//             "label": "Requester Tax Lot Type",
//             "name": "TaxLotType",
//             "crud": {
//                 "read": {
//                     "get": {
//                         "url": getEnvVariable('Admin')+`/api/Domains/values?domainkey=REQUESTED_TAX_LOT_TYPE`
//                     }
//                 }
//             },
//             extraProps: {
//                 transformProps: {
//                     label: 'Name',
//                     value: 'Value'
//                 },
//             }
//         },
//         TownShip: {
//             "type": "textfield",
//             "key": "TownShip",
//             "label": "TownShip",
//             "name": "TownShip",
//         },
//         Range: {
//             "type": "textfield",
//             "key": "Range",
//             "label": "Range",
//             "name": "Range",
//         },
//         Section: {
//             "type": "textfield",
//             "key": "Section",
//             "label": "Section",
//             "name": "Section",
//         },
//         ViewProposedLand: {
//             "type": "textfield",
//             "key": "ViewProposedLand",
//             "label": "ViewProposedLand",
//             "name": "ViewProposedLand",
//         },
//         IsGivenEasement: {
//             "type": "checkbox",
//             "key": "IsGivenEasement",
//             "label": "Give Easement",
//             "name": "IsGivenEasement",
//             extraProps: {
//                 transformProps: {
//                     N: false,
//                     Y: true,
//                     'true': 'Y',
//                     'false': 'N'
//                 },
//             }
//         },
//         IsSiteEasement: {
//             "type": "checkbox",
//             "key": "IsSiteEasement",
//             "label": "Site Easement",
//             "name": "IsSiteEasement",
//             extraProps: {
//                 transformProps: {
//                     N: false,
//                     Y: true,
//                     'true': 'Y',
//                     'false': 'N'
//                 },
//             }
//         },
//         IsBoundaryCorrect: {
//             "type": "checkbox",
//             "key": "IsBoundaryCorrect",
//             "label": "Boundary Correct",
//             "name": "IsBoundaryCorrect",
//             extraProps: {
//                 transformProps: {
//                     N: false,
//                     Y: true,
//                     'true': 'Y',
//                     'false': 'N'
//                 },
//             }
//         },
//     },
//     mapActionsToEffects: {
//         init: {
//             rules: [{
//                 run:
//                 {
//                     valid: [{
//                         type: 'load',
//                         name: 'TaxLotType',
//                         "read": [
//                             {
//                                 url: getEnvVariable('Admin')+`/api/Domains/values?domainkey=REQUESTED_TAX_LOT_TYPE`,
//                                 type: 'get',
//                                 saveAs: {
//                                     key: 'items'
//                                 }
//                             }
//                         ]
//                     }
//                     ]
//                 }
//             }]
//         },
//     }
// }

// export default ViewProposedLand
