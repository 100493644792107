import React, { useState, Fragment } from 'react'
import CheckBox from '../../../../controls/components/checkbox';
import Dropdown from '../../../../controls/components/dropdown';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import FieldSettings from './fieldsettings';
import DNSIcon from '@material-ui/icons/Dns'
import MultiCheckAutoComplete from '../../../../controls/components/checkautocomplete';

const transformProps = {
    transformProps: {
        "Y": true,
        "N": false,
        true: "Y",
        false: "N"
    }
}

export default function ViewFields({ fields, handleFieldValue, ruleType, handleClose }) {

    const [selected, setSelected] = useState({ AllowedValues: null, ValueType: 'N', Value: null, IsEnabled: 'Y', IsVisible: 'Y', RuleType: ruleType, Status: 'A', IsRequired: 'Y', MasterFieldId: null, MasterFieldName: null, IsVirtual: false, MasterFieldName: null })
    const [reload, setReload] = useState(false)
    const [open, setOpen] = useState(false)


    const EachResultChange = (event) => {
        let newSet = selected
        const { name, value } = event.target;
        if (name === 'IsVisible') {
            newSet['IsRequired'] = !event.target.checked ? 'N' : newSet['IsRequired']
            newSet['IsEnabled'] = !event.target.checked ? 'N' : newSet['IsEnabled']
        }
        if (name === 'IsEnabled') {
            newSet['IsRequired'] = !event.target.checked ? 'N' : newSet['IsRequired']
        }
        let each = fields.find(each => each.id === selected.MasterFieldId)
        if (each && each.isVirtual) {
            newSet['IsVirtual'] = true;
            newSet['ColumnName'] = each.name;
            newSet['MasterFieldId']= null
            newSet['MasterFieldName']= each.name
        }
        else newSet['SystemBusinessRuleFieldId'] = null;
        newSet[name] = event.target.checked ? 'Y' : 'N'
        setSelected(newSet)
        setReload(!reload)
    }

    const EachDropResultChange = (event) => {
        let newSet = selected
        const { name, value } = event.target;
        newSet[name] = value;
        let each = fields.find(each => each.id === value)
        if(each && each.name){
            newSet['FieldName'] = each.name;
        }
        if (each && each.isVirtual) {
            newSet['IsVirtual'] = true;
            newSet['ColumnName'] = each.name;
            newSet['MasterFieldId']= null
            newSet['MasterFieldName']= each.name
        }
        else newSet['SystemBusinessRuleFieldId'] = null;
        setSelected(newSet)
        setReload(!reload)
    }

    return (<div className="col-sm-12">
        {reload}
        <div className="row col-sm-12">
            {<small className="col-sm-5 text-secondary">
                <MultiCheckAutoComplete label={'Name'} ismulti={false} name={'MasterFieldId'} value={selected && (selected.MasterFieldId || selected.MasterFieldName) || null} items={fields || []} extraProps={{
                    transformProps: selected.MasterFieldName ? {
                        label: 'label',
                        value: 'name'
                    } : {
                        label: 'label',
                        value: 'id'
                    }
                }}
                    handleFieldValue={EachDropResultChange} />
            </small>}
            <small className="col-sm-2" >
                <CheckBox name="IsVisible" label={'Show'} labelPlacement="top" handleFieldValue={EachResultChange} value={selected && selected.IsVisible} extraProps={transformProps} />
            </small>
            <small className="col-sm-3">
                <div className="row col-sm-12"> <div className="col-sm-6"> <CheckBox disabled={selected.IsVisible && selected.IsVisible == 'Y' ? false : true} label={'Enabled'} name="IsEnabled" labelPlacement="top" handleFieldValue={EachResultChange} value={selected && selected.IsEnabled} extraProps={transformProps} /></div>
                    <div className="col-sm-4"> <CheckBox disabled={(selected.IsVisible && selected.IsVisible == 'Y') ? (selected.IsEnabled && selected.IsEnabled == 'Y' ? false : true) : true} handleFieldValue={EachResultChange} label={'Required'} labelPlacement="top" name="IsRequired" extraProps={transformProps} value={selected && selected.IsRequired} />
                    </div></div> </small>
            {selected && (selected.MasterFieldId) && <FieldSettings values={fields && fields.length > 0 && fields.find(f => f.id === parseInt(selected.MasterFieldId)).values || []} type={fields && fields.length > 0 && fields.find(f => f.id === parseInt(selected.MasterFieldId)).type || 'text'} {...selected} fieldopen={open} allfields={fields} closeFieldSettings={() => { setOpen(false) }} fieldSettingsValue={EachDropResultChange} />}
            <div className="col-sm-2">
                <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => { handleClose(ruleType); handleFieldValue(selected) }}><SaveIcon /></IconButton>
                <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => handleClose(ruleType)}><CloseIcon /></IconButton>
            </div>
        </div>
    </div >)
}