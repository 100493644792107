import { getEnvVariable } from '../../modules/environmentalList';
//import { requestApi } from '../../form/utilities'
import { createRequest, requestApi } from '../../core/controls/helpers/rest';
import { GetLookUpValues } from '../../core/modules/map/eps/lookupDataTaskKEPT'; 


export function GetGISFields(sitepermittypeid) {
  return new Promise((resolve, reject) => {
    let datakeys = [{
          url: getEnvVariable('Application') + `/api/SitePermitSectionFieldsView/AllFieldsGroupBySection/${sitepermittypeid}`,
          "routeProps": [],
          type: 'get'              
      }]
      Promise.all(datakeys.map(each => requestApi(createRequest(each, each.body, null)))).then(totalsections => {
          console.log(totalsections, ' Get');
          let sectionKeys = Object.keys(totalsections);
          let sections = totalsections;
          let GISDomainLookupFields = [];
          let GISLookupData = []
          sectionKeys.forEach((each, index) => {
            let allSectionNames = Object.keys(sections[each])
            allSectionNames.map((sectionname,inx) =>{ 
              sections[each][sectionname].sort((a, b) => a.FieldRowDisplayOrder - b.FieldRowDisplayOrder).map((section, i) => {
                const { FieldKey, Key, FieldValue, FieldName, FieldDisplayName, UIFieldTypeComponent, FieldDescription,
                   SectionName, SectionKey, FieldDecimalPlace, FieldContextHelp,FieldRowDisplayOrder, FieldColDisplayOrder, 
                   FieldMin, FieldMax, IsHidden, FieldFetchDomainKey, FieldFetchGeomType, IsLabel, IsInternal, 
                   FieldIsSpellCheck, FieldDomainKey, FieldProps, FieldIsBuffer, FieldBufferDistance, FieldBufferUnits } = section;
                    if (FieldFetchDomainKey) {
                      let eachDomainLookup = {
                          FieldFetchDomainKey: FieldFetchDomainKey,
                          FieldFetchGeomType: FieldFetchGeomType,
                          FieldKey: FieldKey,
                          FieldIsBuffer: FieldIsBuffer,
                          FieldBufferDistance: FieldBufferDistance,
                          FieldBufferUnits: FieldBufferUnits,
                          UIFieldTypeComponent: UIFieldTypeComponent
                      }
                      GISDomainLookupFields.push(eachDomainLookup);
                      //GISKeys[FieldKey] = each.FieldFetchDomainKey
                  }
                })
            })            
          })
          console.log(GISDomainLookupFields);
          let allkeys = GISDomainLookupFields.map(each => each.FieldFetchDomainKey);
          GetGISFieldsDomainLookup(allkeys).then((results) => {
            GISDomainLookupFields.forEach(function (master) {
                    results && results[0].forEach(function (domain) {
                        if (domain.Layer && domain.Layer.Url && master.FieldFetchDomainKey === domain.Key) {
                            let obj = {
                                'Id': domain.Id, //MasterField
                                'ServiceUrl': domain.Layer.Url, //Domain
                                'FieldKey': master.FieldKey, //Masterfield
                                'NameReference': domain.NameReference,//Domain
                                'ValueReference': domain.ValueReference, //Domain,
                                'FieldFetchGeomType': master.FieldFetchGeomType,
                                'FieldIsBuffer': master.FieldIsBuffer,
                                'FieldBufferDistance': master.FieldBufferDistance,
                                'FieldBufferUnits': master.FieldBufferUnits,
                                'UIFieldTypeComponent': master.UIFieldTypeComponent,  //-- Feild UI type for tectbox only name should come
                                'Token': domain.Layer.Token,
                                'TokenExpiration': domain.Layer.TokenExpiration,
                                'NameFormat':domain.NameFormat,
                                'ValueFormat':domain.ValueFormat
                            }
                            GISLookupData.push(obj)
                        }
                    });
                });
                console.log(GISLookupData);
                resolve(GISLookupData);
          })
      }).catch(error => {
          console.error('Error in saving multiple locations')
          console.log(error);
          reject(error)
      })      
  })
}

export function GetGISFieldValues(sitepermittypeid, coordinates) {
  return new Promise((resolve, reject) => {
    let datakeys = [{
          url: getEnvVariable('Application') + `/api/SitePermitSectionFieldsView/AllFieldsGroupBySection/${sitepermittypeid}`,
          "routeProps": [],
          type: 'get'              
      }]
      Promise.all(datakeys.map(each => requestApi(createRequest(each, each.body, null)))).then(totalsections => {
          console.log(totalsections, ' Get');
          let sectionKeys = Object.keys(totalsections);
          let sections = totalsections;
          let GISDomainLookupFields = [];
          let GISLookupData = []
          sectionKeys.forEach((each, index) => {
            let allSectionNames = Object.keys(sections[each])
            allSectionNames.map((sectionname,inx) =>{ 
              sections[each][sectionname].sort((a, b) => a.FieldRowDisplayOrder - b.FieldRowDisplayOrder).map((section, i) => {
                const { FieldKey, Key, FieldValue, FieldName, FieldDisplayName, UIFieldTypeComponent, FieldDescription,
                   SectionName, SectionKey, FieldDecimalPlace, FieldContextHelp,FieldRowDisplayOrder, FieldColDisplayOrder, 
                   FieldMin, FieldMax, IsHidden, FieldFetchDomainKey, FieldFetchGeomType, IsLabel, IsInternal, 
                   FieldIsSpellCheck, FieldDomainKey, FieldProps, FieldIsBuffer, FieldBufferDistance, FieldBufferUnits } = section;
                    if (FieldFetchDomainKey) {
                      let eachDomainLookup = {
                          FieldFetchDomainKey: FieldFetchDomainKey,
                          FieldFetchGeomType: FieldFetchGeomType,
                          FieldKey: FieldKey,
                          FieldIsBuffer: FieldIsBuffer,
                          FieldBufferDistance: FieldBufferDistance,
                          FieldBufferUnits: FieldBufferUnits,
                          UIFieldTypeComponent: UIFieldTypeComponent
                      }
                      GISDomainLookupFields.push(eachDomainLookup);
                      //GISKeys[FieldKey] = each.FieldFetchDomainKey
                  }
                })
            })            
          })
          console.log(GISDomainLookupFields);
          let allkeys = GISDomainLookupFields.map(each => each.FieldFetchDomainKey);
          GetGISFieldsDomainLookup(allkeys).then((results) => {
            GISDomainLookupFields.forEach(function (master) {
                    results[0].forEach(function (domain) {
                        if (domain.Layer && domain.Layer.Url && master.FieldFetchDomainKey === domain.Key) {
                            let obj = {
                                'Id': domain.Id, //MasterField
                                'ServiceUrl': domain.Layer.Url, //Domain
                                'FieldKey': master.FieldKey, //Masterfield
                                'NameReference': domain.NameReference,//Domain
                                'ValueReference': domain.ValueReference, //Domain,
                                'FieldFetchGeomType': master.FieldFetchGeomType,
                                'FieldIsBuffer': master.FieldIsBuffer,
                                'FieldBufferDistance': master.FieldBufferDistance,
                                'FieldBufferUnits': master.FieldBufferUnits,
                                'UIFieldTypeComponent': master.UIFieldTypeComponent,  //-- Feild UI type for tectbox only name should come
                                'Token': domain.Layer.Token,
                                'TokenExpiration': domain.Layer.TokenExpiration,
                                'NameFormat':domain.NameFormat,
                                'ValueFormat':domain.ValueFormat
                            }
                            GISLookupData.push(obj)
                        }
                    });
                });
                console.log(GISLookupData);
                 //Send for fetching fields data
                 GetLookUpValues(coordinates, GISLookupData).then(lookupres => {
                    console.log(lookupres);
                    resolve(lookupres);
                })
          })
          
      }).catch(error => {
          console.error('Error in saving multiple locations')
          console.log(error);
          reject(error)
      })      
  })
}

export function GetGISFieldsDomainLookup(keys){
  return new Promise((resolve, reject) => {
    let datakeys = [{
          url: getEnvVariable('Application') + '/api/Domains/Keys?domaintype=A',
          type: 'post',
          alert: true,
          body: keys
      },
      {
          url: getEnvVariable('Admin') + `/api/Layers/All/Active`,
          type: 'get'
      }]
      Promise.all(datakeys.map(each => requestApi(createRequest(each, each.body, null)))).then(resp => {
          const [domainlookup, layers] = resp;
          if(Array.isArray(layers)){
              domainlookup.forEach(each => {
                  each.Layer = layers.find(layer => each.LayerId === layer.Id)
                  // layers.forEach(layer => {
                  //     if(each.LayerId === layer.Id){                         
                  //         each.Layer.Token = layer.Token;
                  //         each.Layer.TokenExpiration = layer.TokenExpiration;
                  //     }
                  // })
              })          
          }
          resolve([domainlookup])
      }).catch(error => {
          console.error('Error in saving multiple locations')
          console.log(error);
          reject(error)
      })      
  })
}





