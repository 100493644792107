import React, { forwardRef, useImperativeHandle, useState, useRef, forwarRef } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { layoutTypes } from './types'
import { handleActionsToEffects } from '../effects/effects'
import { fieldTypes, hookTypes } from '../helpers/fieldtypes'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} className="my-2" >
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}




const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
    },
}));

export default function TabComponent(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const tabRef = useRef(null)
    const { sections, steps, errorSections } = props

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };




    return (
        <div className={classes.root}>
            <AppBar position="static" color="inherit">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    {sections && sections.map((tab, index) => {
                        return <Tab label={steps[index]} style={{ backgroundColor: errorSections[steps[index]] === true ? '' : '', color: errorSections[steps[index]] === true ? '' : 'rgba(0, 0, 0, 0.54)' }} />
                    })}
                </Tabs>
            </AppBar>
            <SwipeableViews
                style={{ height: window.innerHeight - 140 }}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {sections && sections.map((tab, index) => {
                    return <TabPanel value={value} index={index} dir={theme.direction}>
                        {tab}
                    </TabPanel>
                })}
            </SwipeableViews>
        </div>
    )
}
