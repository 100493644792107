import { getEnvVariable } from '../../../../modules/environmentalList';
import { requestApi } from '../../form/utilities'
import moment from 'moment'
const _ = require('lodash');


export function MapSettings(config, submodulekey, layervalues, settingvalues, sectionmapsettings, mapbussinesRules) {
  const getSettingValue = (key, collection) => {
    if (collection && collection.length > 0) {
      let data = collection.filter(res => {
        return res.Key === key
      })
      if (data && Array.isArray(data) && data[0]) {
        return data[0].Value;
      } else return null;
    }
  }
  return new Promise((resolve, reject) => {
    // let settingprom = []

    // [requestApi({ get: { url: getEnvVariable('Admin') + `/api/MapAppSettings/AllMapSettings` } }),
    //   requestApi({ get: { url: getEnvVariable('Admin') + `/api/Layers/All/Active` } })
    // ];
    // if (config && config.SITE_PERMIT_TYPE_ID) {
    //   settingprom.push(requestApi({ get: { url: getEnvVariable('AdminConfig') + `/api/SitePermitTypeSection/MapSettings/${config.SITE_PERMIT_TYPE_ID}` } }))
    //   settingprom.push(requestApi({ get: { url: getEnvVariable('Application') + `/api/BusinessRules/MapRules/${config.SITE_PERMIT_TYPE_ID}` } }))
    // }

    //  Promise.all(settingprom).then((values) => {
    //  const [sectionmapsettings, mapbussinesRules] = values;
    let centergeomser = layervalues.filter(res => {
      return res.KEY === 'CenterGeomService'
    })
    let statemaintroads = layervalues.filter(res => {
      return res.KEY === 'STATE_MAINTAINED'
    })
    let taxparcelserDB = layervalues.filter(res => {
      return res.KEY === 'Tax Parcels'
    });

    let taxparcelser = [];
    //Change to the taxparcel layers format
    if (taxparcelserDB && Array.isArray(taxparcelserDB) && taxparcelserDB.length > 0) {
      taxparcelserDB.forEach(function (layer) {
        let obj = {
          "ExtentZoom": true,
          "title": layer.DisplayName,
          "type": "F",
          "url": layer.Url,
          "outFields": ["*"]
        }
        taxparcelser.push(obj);
      })
    }

    config.MapBussinessRules = flatMapBussinessRules(mapbussinesRules);
    let baseLayersConfig = config && config.LayerOptionsConfig ? config.LayerOptionsConfig.Category : ["F"];
    let mapType = config && config.LayerOptionsConfig ? config.LayerOptionsConfig.MapType : [];
    let featurelayersConfig = layervalues.filter(res => {
      res.SubModuleKeys = res.SubModuleKey !== null ? (res.SubModuleKey + "").split(",") : [];
      return (mapType.some(r => res.Category && res.Category.split(',').indexOf(r) >= 0)) && !res.SubModuleKey || res.SubModuleKey == 'COMMON' || res.SubModuleKeys.includes(submodulekey)
    })

    //Specifc to landing page, No submodule key accessible. Fixed after baseLayerConfig from Raghv: FP Naren
    if (mapType.includes("L")) {
      let landingFeaturelayersConfig = layervalues.filter(res => {
        return (mapType.some(r => res.Category && res.Category.split(',').indexOf(r) >= 0))
      })
      featurelayersConfig = [...featurelayersConfig, ...landingFeaturelayersConfig]
    }

    // filter(a => !a.SubModuleKey || a.SubModuleKey=='COMMON' || a.SubModuleKeys.includes(this.state.SubModuleKey));
    /* Added comment by Raghavendar baseLayersConfig is fixed to check is editable or not  it will have values F- Features M- Map Server layers


    let featurelayersConfig = layervalues.filter(res => {
      return (baseLayersConfig.includes(res.Type) && mapType.some(r => res.Category.split(',').indexOf(r) >= 0))
    })*/
    // let featurelayersConfig = layervalues.filter(res => {
    //   return res.Category === 'F'
    // })

    let maplayersConfig = layervalues.filter(res => {
      return res.Category === 'M'
    })

    if (featurelayersConfig && featurelayersConfig.length > 0) {
      let allFeatureLayers = []
      featurelayersConfig.forEach(function (layer) {
        let eachLayer = layer;
        eachLayer.url = layer.Url;
        eachLayer.defaultExpression = getExpression(layer);
        eachLayer.key = layer.KEY;
        eachLayer.title = layer.DisplayName || layer.Name;
        //eachLayer.CanSearch = layer.CanSearch;
        //eachLayer.SearchField = layer.SearchField;
        eachLayer.HasValidation = false;
        eachLayer.SelectTools = (layer.SelectTools + "").split(",");
        //eachLayer.SubModuleKeys = (layer.SubModuleKey + "").split(",");
        eachLayer.SubscribedMasterFieldsKey = layer.SubscribedMasterFieldsKey;
        eachLayer.id = layer.Id;
        eachLayer.type = layer.Type;
        if (layer.CanLabel && layer.CanLabel == 'Y' && layer.LabelExpression) {
          eachLayer.labelExpression = GetStringFromJsonText(layer.LabelExpression, 'L');
          if (eachLayer.labelExpression)
            eachLayer.showLabels = true;
          eachLayer.labelColor = layer.LabelColor || [0, 0, 0];
        }
        eachLayer.InfoTemplate = GetJsonFromText(layer.InfoTemplate, 'I') //JSONParser(layer.InfoTemplate);
        eachLayer.GridTemplate = GetJsonFromText(layer.GridTemplate, 'G') //JSONParser(layer.GridTemplate);
        eachLayer.EditorTemplate = GetJsonFromText(layer.EditorTemplate, 'E') //JSONParser(layer.EditorTemplate);
        //console.log('Layers config --------------------', eachLayer);
        allFeatureLayers.push(eachLayer)
      })

      if(Array.isArray(allFeatureLayers) && allFeatureLayers.length > 0){
        allFeatureLayers = _.sortBy(allFeatureLayers, 'DisplayName').reverse();
      }

      config.AllFeatureLayerListOptions = allFeatureLayers;
      config.FeatureLayerListOptions = allFeatureLayers.filter(res => {
        return !res.ShowOnMap || res.ShowOnMap == "Y" || res.ShowOnMap == "V"
      });
      // Validate layers on sketch and distance.
      config.SketchValidationLayers = allFeatureLayers.filter(res => {
        return res.HasSketchValidation ==='Y' && res.ValidationDistance 
      })
    }

    if (config && config.AllFeatureLayerListOptions && config.AllFeatureLayerListOptions.length > 0) {
      config.AllFeatureLayerListOptions.forEach(function (item) {
        if (item.key == 'AAH_Network') {
          if (taxparcelser && taxparcelser[0]) {
            item.HasValidation = true;
            item.Validation = [{ "FieldName": "Status", "AllowedValues": ["Available"] }]

          }
        }
      });
    }

    config.SearchLayerListOptions = config.AllFeatureLayerListOptions && config.AllFeatureLayerListOptions.filter(res => {
      return res.CanSearch === 'Y'
    });
    config.SelectLayerListOptions = config.AllFeatureLayerListOptions && config.AllFeatureLayerListOptions.filter(res => {
      return res.CanSelect === 'Y'
    });


    //Get layer default expression
    function getExpression(LayerOptions) {
      const { AsofArchiveStart, AsofArchiveEnd, CanFilterAsof, Url, KEY } = LayerOptions;
      let defaultExpression = "1=1"
      try {
        if (Url && Url.indexOf("where=") !== -1) {
          const decodedurl = decodeURIComponent(Url).replace(/\+/g, " ");
          var params = decodedurl.split("where=")[1]
          defaultExpression = (params.indexOf("&") !== -1) ? (params.split("&")[0]) : params;
        }
        if (KEY == 'CenterGeomService') {
          let HasCenterGeomServiceArchive = getSettingValue('HasCenterGeomServiceArchive', settingvalues)
          if (HasCenterGeomServiceArchive && HasCenterGeomServiceArchive == 'Y') {
            if (CanFilterAsof == "Y" && AsofArchiveStart && AsofArchiveEnd) {
              let archivedate = moment(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD HH:mm:ss');
              let queryExpression = "(SysStart<='" + archivedate + "' AND SysEnd>'" + archivedate + "' AND " + AsofArchiveStart + "<='" + archivedate + "' AND " + AsofArchiveEnd + ">'" + archivedate + "')"
              defaultExpression = (defaultExpression == "1=1") ? (queryExpression) : (("(" + defaultExpression + ")") + " and " + queryExpression);
            }
          }

        }
      } catch (ex) {
        console.error(ex)
      }
      return defaultExpression;
    }

    //Get JSON L-Label Expression  $feature.NAME+' ('+$feature.FIPS+')'
    function GetStringFromJsonText(josnStr, type) {
      try {
        if (josnStr) {
          let arr = JSONParser(josnStr);
          if (arr && Array.isArray(arr)) {

          } else if (arr && arr.list && Array.isArray(arr.list)) {
            let rtnstr = ""
            if (type == 'L') {
              arr.list.forEach(element => {
                if (element.fieldName) {
                  if (element.label) {
                    if (rtnstr && rtnstr != "")
                      rtnstr = rtnstr + "+'" + element.label + "'"
                    else
                      rtnstr = "'" + element.label + "'"
                  }
                  if (rtnstr && rtnstr != "")
                    rtnstr = rtnstr + "+$feature." + element.fieldName
                  else
                    rtnstr = "$feature." + element.fieldName
                }
              });
              if (arr.postfix) {
                if (rtnstr && rtnstr != "")
                  rtnstr = rtnstr + "+'" + arr.postfix + "'"
                else
                  rtnstr = "'" + arr.postfix + "'"
              }
              return rtnstr;
            }
          }
        }
      } catch (ex) {
        console.error(ex)
      }
      return null;
    }

    //Get JSON I-Info, G-Grid, E-Editor
    function GetJsonFromText(josnStr, type) {
      try {
        if (josnStr) {
          let arr = JSONParser(josnStr);
          if (arr && Array.isArray(arr)) {
            let fieldInfos = []
            arr.forEach(element => {
              if (element.fieldName) {
                if (type == 'I')
                  fieldInfos.push({ fieldName: element.fieldName, label: element.label });
                else if (type == 'G')
                  fieldInfos.push({ field: element.fieldName, label: element.label, sortable: true, hidden: false });
                else if (type == 'E')
                  fieldInfos.push({ name: element.fieldName, editable: true, label: element.label, description: element.label });
              }
            });
            if (fieldInfos.length > 0) {
              //console.log(fieldInfos);
              if (type == 'I') return [{ type: "fields", fieldInfos: fieldInfos }]
              if (type == 'G' || type == 'E') return fieldInfos
            }
          }
        }
      } catch (ex) {
        console.error(ex)
      }
      return null;
    }

    function JSONParser(jsonstr) {
      try {
        return JSON.parse(jsonstr)
      } catch (e) {
        console.error("Error in parsing JSON from database")
      }
      return null;
    }
    function flatMapBussinessRules(mapBussinessRules) {
      let rules = [];
      if (mapBussinessRules) {
        for (const [key, value] of Object.entries(mapBussinessRules)) {
          if (value && !_.isEmpty(value)) {
            for (const [ruleKey, rule] of Object.entries(value)) {
              if (rule && !_.isEmpty(rule))
                rules.push(rule);
            }
          }
        }
      }
      return rules;
    }
    if (config && config.LayerOptions && config.LayerOptions.length > 0) {
      config.LayerOptions.forEach(function (item) {
        if (item.title == 'CenterGeomService') {
          if (centergeomser && centergeomser[0]) {
            item.url = centergeomser[0].Url; //getEnvVariable('ArcGIS') + '/' + centergeomser[0].Url;
            item.key = centergeomser[0].KEY;
            item.title = centergeomser[0].Name;
          }
        } else if (item.title == 'STATE_MAINTAINED') {
          if (statemaintroads && statemaintroads[0]) {
            item.url = statemaintroads[0].Url;
            item.key = statemaintroads[0].KEY;
            item.title = statemaintroads[0].Name;
          }
        } else if (item.title == 'Tax Parcels') {
          if (taxparcelser && taxparcelser[0]) {
            item.url = taxparcelser[0].Url;
            item.key = taxparcelser[0].KEY;
            item.title = taxparcelser[0].Name;

          }
        }
        else if (item.key == 'AAH_Network') {
          if (taxparcelser && taxparcelser[0]) {
            item.HasValidation = true;
            item.Validation = [{ "FieldName": "Status", "AllowedValues": ["Available"] }]

          }
        }
      });

    }

    // if (config && config.FeatureLayerListOptions && config.FeatureLayerListOptions.length > 0) {
    //   config.FeatureLayerListOptions.forEach(function (item) {
    //     if (item.title == 'CenterGeomService') {
    //       if (centergeomser && centergeomser[0]) {
    //         item.url = getEnvVariable('ArcGIS') + '/' + centergeomser[0].Url;
    //         item.key = centergeomser[0].KEY;
    //         item.title = centergeomser[0].Name;
    //       }
    //     } else if (item.title == 'STATE_MAINTAINED') {
    //       if (statemaintroads && statemaintroads[0]) {
    //       item.url = statemaintroads[0].Url;
    //       item.key = statemaintroads[0].KEY;
    //       item.title = statemaintroads[0].Name;
    //       }
    //     } else if (item.title == 'Tax Parcels') {
    //       if (taxparcelser && taxparcelser[0]) {
    //       item.url = taxparcelser[0].Url;
    //       item.key = taxparcelser[0].KEY;
    //       item.title = taxparcelser[0].Name;

    //       }
    //     }else if (item.title == 'Master Table GIS Data') {
    //       if (centergeomser && centergeomser[0]) {
    //         item.url = item.url;
    //         item.key = item.Key;
    //         item.title = item.title;
    //       }
    //     }
    //   });
    // }


    // //Specific to AMES loading the all county layers and taxlayers information
    // if (config && (!config.CountyLayerOptions || config.CountyLayerOptions.length < 1)){
    //   config.CountyLayerOptions = taxparcelser;
    // }
    if (config && taxparcelser && taxparcelser.length > 0) {
      config.CountyLayerOptions = taxparcelser;
    }

    let featurelayerPoint = layervalues.filter(res => {
      return res.KEY === 'POINT'
    })
    let featurelayermultiPoint = layervalues.filter(res => {
      return res.KEY === 'MULTIPOINT'
    })
    let featurelayerLine = layervalues.filter(res => {
      return res.KEY === 'LINE'
    })
    let featurelayerPolygon = layervalues.filter(res => {
      return res.KEY === 'POLYGON'
    })

    if (featurelayerPoint && featurelayerPoint[0] && featurelayermultiPoint && featurelayermultiPoint[0] &&
      featurelayerLine && featurelayerLine[0] && featurelayerPolygon && featurelayerPolygon[0])
      if (config && config.FeatureLayers) {
        console.log('config.FeatureLayers', config.FeatureLayers)
        // config.FeatureLayers.POINT = getEnvVariable('ArcGIS') + '/' + featurelayerPoint[0].Url;
        // config.FeatureLayers.MULTIPOINT = getEnvVariable('ArcGIS') + '/' + featurelayermultiPoint[0].Url;
        // config.FeatureLayers.LINE = getEnvVariable('ArcGIS') + '/' + featurelayerLine[0].Url;
        // config.FeatureLayers.POLYGON = getEnvVariable('ArcGIS') + '/' + featurelayerPolygon[0].Url;
        // Getting data from 
        config.FeatureLayers.POINT = featurelayerPoint[0].Url;
        config.FeatureLayers.MULTIPOINT = featurelayermultiPoint[0].Url;
        config.FeatureLayers.LINE = featurelayerLine[0].Url;
        config.FeatureLayers.POLYGON = featurelayerPolygon[0].Url;
        console.log('config.FeatureLayers', config.FeatureLayers)
      }
    // let taxparcelLayer = layervalues.filter(res => {
    //   return res.KEY === 'Tax Parcels'
    // })
    // if (taxparcelLayer && taxparcelLayer[0])
    //   if (config && config.tasks && config.tasks.TaxParcels) {
    //     let currenturl = config.tasks.TaxParcels.url
    //     if (!currenturl)
    //       config.tasks.TaxParcels.url = taxparcelLayer[0].Url;
    //   }


    //Set MasterLookupFields data - From Appsettings
    if (settingvalues) {
      let masterlookups = settingvalues.filter(a => a.Key == "MASTER_LOOKUP");
      if (masterlookups && Array.isArray(masterlookups) && masterlookups.length > 0) {
        let MasterLookupFields = [];
        masterlookups.forEach(lookup => {
          let lookupjson = JSONParser(lookup.Value);
          if(lookupjson && lookupjson['LayerAuthenticationId'] && Array.isArray(layervalues)){
            let masterlayerauth = layervalues.find(a => a.LayerAuthenticationId == lookupjson['LayerAuthenticationId']);
            if(masterlayerauth){
              lookupjson['Token'] = masterlayerauth['Token'];
              lookupjson['TokenExpiration'] = masterlayerauth['TokenExpiration'];
            }
          }
          MasterLookupFields.push(lookupjson);
        });
        config.MasterLookupFields = MasterLookupFields
      }
    }


    let X_Center;
    let Y_Center;

    X_Center = parseFloat(getSettingValue('CENTER_X', settingvalues));
    Y_Center = parseFloat(getSettingValue('CENTER_Y', settingvalues));
    if (X_Center != undefined && X_Center != null && X_Center != 0 && Y_Center != undefined && Y_Center != null && Y_Center != 0) {
      config.viewProperties.center = [X_Center, Y_Center];
    }
    config.hastaxlotlayer = getSettingValue('HAS_TAXLOT', settingvalues);
    config.viewProperties.extent = {
      "xmax": parseFloat(getSettingValue('XMAX', settingvalues)),
      "xmin": parseFloat(getSettingValue('XMIN', settingvalues)),
      "ymax": parseFloat(getSettingValue('YMAX', settingvalues)),
      "ymin": parseFloat(getSettingValue('YMIN', settingvalues)),
      "spatialReference": parseFloat(getSettingValue('WKID', settingvalues)),
      "basemap_spatialReference": (parseFloat(getSettingValue('BASEMAP_WKID', settingvalues))) || 102100
    }
    config.searchOptions = { "State": getSettingValue('STATE', settingvalues) || 'OR' }
    //config.searchbookmarks.drawTool = getSettingValue('XMAX', settingvalues);
    config.viewProperties.zoom = parseFloat(getSettingValue('MIN_ZOOM', settingvalues));
    config.viewProperties.initialzoom = parseFloat(getSettingValue('INIT_MAP_ZOOM', settingvalues));
    //Add print services
    if (config.printOptions)
      config.printOptions.serviceURL = getSettingValue('PRINT_SERVICE_URL', settingvalues);

    if (sectionmapsettings)
      config.MapSettings = sectionmapsettings;

    if (getEnvVariable('ModuleKey') == "KEPT")
      config.widgets.push("MultiLocationSelector")

    if (config && config.MapSettings) {
      if (config.MapSettings["IsAllowingInternalEdit"] === "Y") {
        if (config.MapSettings["IsAllowingInternalToEditPoints"] === "Y")
          config.drawOptions.Tools.push("point");
        if (config.MapSettings["IsAllowingInternalToEditLines"] === "Y")
          config.drawOptions.Tools.push("polyline");
        if (config.MapSettings["IsAllowingInternalToEditPolygons"] === "Y") {
          config.drawOptions.Tools.push("polygon");
          config.drawOptions.Tools.push("rectangle");
          config.drawOptions.Tools.push("circle");
        }
        // config.drawOptions.AllowMultiple = false;
        config.drawOptions.AllowMultiple = {
          "Points": config.MapSettings["IsAllowingInternalToEditPointsSignleOrMultiple"] === "Y",
          "Lines": config.MapSettings["IsAllowingInternalToEditLinesSignleOrMultiple"] === "Y",
          "Polygons": config.MapSettings["IsAllowingInternalToEditPolygonsSignleOrMultiple"] === "Y"
        };

      } else if (config.MapSettings["IsInternal"] !== "Y"
        && config.MapSettings["IsAllowingExternalEdit"] === "Y") {
        if (config.MapSettings["IsAllowingExternalToEditPoints"] === "Y")
          config.drawOptions.Tools.push("point");
        if (config.MapSettings["IsAllowingExternalToEditLines"] === "Y")
          config.drawOptions.Tools.push("polyline");
        if (config.MapSettings["IsAllowingExternalToEditPolygons"] === "Y") {
          config.drawOptions.Tools.push("polygon");
          config.drawOptions.Tools.push("rectangle");
          config.drawOptions.Tools.push("circle");
        }
        config.drawOptions.AllowMultiple = {
          "Points": config.MapSettings["IsAllowingExternalToEditPointsSignleOrMultiple"] === "Y",
          "Lines": config.MapSettings["IsAllowingExternalToEditLinesSignleOrMultiple"] === "Y",
          "Polygons": config.MapSettings["IsAllowingExternalToEditPolygonsSignleOrMultiple"] === "Y"
        };
        //config.drawOptions.AllowMultiple = false
      }
    }
    let gisbuffer = getSettingValue('GISLOOKUP_BUFFER', settingvalues);
    config.GISLookupBuffer = gisbuffer ? parseFloat(gisbuffer) : '1';
    console.log('config.FeatureLayers', config);
    resolve(config);
    //   })
  })  
}

