import React, { Component, Fragment } from 'react';
import FormTextBox from '../../core/modules/form/cformtextbox'
import FormDropDown from '../../core/modules/form/cformdropdown'


class EPSNotifications extends Component {
    state = {
        notificationtype: 1,
        module: 1
    }
    handleFieldChange = (event) => {
        const { name, value } = event.target
    }
    render() {
        const { notificationtype, module } = this.state
        return (
            <form>
                <div className="row">
                    <div className="col-sm-6">
                        <FormTextBox label='Notification name' handleFieldChange={this.handleFieldChange} name='notificationname' />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <FormTextBox label='Description' handleFieldChange={this.handleFieldChange} name='description' />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <FormDropDown dropdownvalues={notificationvalues} label='Notification Type' value={notificationtype} name='notificationtype' />
                    </div>
                </div>
                {(notificationtype === 1) &&
                    <Fragment>
                        <div className="row">
                            <div className="col-sm-6">
                                <FormDropDown dropdownvalues={modulevalues} label='Module' value={module} name='module' />
                            </div>
                        </div>
                        {/* {(module !== 3) &&
                            <Fragment>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <FormDropDown dropdownvalues={sitevalues} label='Site' value={site} name='site' />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <FormDropDown dropdownvalues={sitepermittypevalues} label='Application Type' value={sitepermittype} name='sitepermittype' />
                                    </div>
                                </div>
                            </Fragment>
                        } */}
                        {/* <div className="row">
                            <div className="col-sm-6">
                                <FormDropDown dropdownvalues={eventonvalues} label='Event On:' value={site} name='eventon' />
                            </div>
                        </div> */}
                    </Fragment>
                }
                {/* <div className="row">
                    <div className="col-3">
                        <button type="button">Sites</button>
                    </div>
                    <div className="col-3">
                        <button type="button">recipients</button>
                    </div>
                    <div className="col-3">
                        <button type="button">notification template</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <button type="button">Sites</button>
                    </div>
                    <div className="col-3">
                        <button type="button">recipients</button>
                    </div>
                    <div className="col-3">
                        <button type="button">notification template</button>
                    </div>
                    <div className="col-3">
                        <button type="button">notification template</button>
                    </div>
                </div> */}
            </form>
        )

    }
}


export default EPSNotifications

const notificationvalues = [
    {
        name: 'Event Notificaton',
        value: 1,
        key: 'eventnotificaton'
    },
    {
        name: 'Scheduled Notificaton',
        value: 2,
        key: 'schedulednotificaton'
    }
]

const modulevalues = [
    {
        name: 'Entrance Permit Applications',
        value: 1,
        key: 'entrancepermitapplications'
    },
    {
        name: 'Records',
        value: 2,
        key: 'entrancepermits'
    },
    {
        name: 'Entrance Permit Owners',
        value: 3,
        key: 'entrancepermitowners'
    }
]

const eventonvalues = {
    applications: [
        {
            name: 'Application Status Change',
            value: 1,
            key: 'applicationstatuschange'
        },
        {
            name: 'Workflow Status Change',
            value: 2,
            key: 'workflowstatuschange'
        },
        {
            name: 'Field Value Change',
            value: 3,
            key: 'fieldvaluechange'
        },
        {
            name: 'Workflow due date',
            value: 4,
            key: 'workflowduedate'
        },
        {
            name: 'Notes Change',
            value: 5,
            key: 'noteschange'
        },
        {
            name: 'Attachments Change',
            value: 6,
            key: 'attachmentschange'
        },
        {
            name: 'Inspections Change',
            value: 7,
            key: 'inspectionschange'
        },
        {
            name: 'Images Change',
            value: 8,
            key: 'Imageschange'
        },
        // {
        //     name: 'Shared discussions Change',
        //     value: 9,
        //     key: 'shareddiscussionschange'
        // },
        {
            name: 'Notices Change',
            value: 10,
            key: 'noticeschange'
        },
        {
            name: 'Payment Slips Change',
            value: 11,
            key: 'paymentslipschange'
        }
    ],
    permits: [
        {
            name: 'Permit Status Change',
            value: 1,
            key: 'Permitstatuschange'
        },
        {
            name: 'Sign Status Change',
            value: 2,
            key: 'Signstatuschange'
        },
        {
            name: 'Construction Window',
            value: 3,
            key: 'constructionwindow'
        },
        {
            name: 'Cancellation Work Flow Status Change',
            value: 4,
            key: 'cancellationworkflow'
        },
        {
            name: 'Field Value Change',
            value: 5,
            key: 'fieldvaluechange'
        },
        {
            name: 'Notes Change',
            value: 6,
            key: 'noteschange'
        },
        {
            name: 'Attachments Change',
            value: 7,
            key: 'Attachmentschange'
        },
        {
            name: 'Inspections Change',
            value: 8,
            key: 'Inspectionschange'
        },
        {
            name: 'Images Change',
            value: 9,
            key: 'imageschange'
        },
        // {
        //     name: 'Shared Discussions Change',
        //     value: 10,
        //     key: 'shareddiscussionschange'
        // },
        {
            name: 'Notices Change',
            value: 11,
            key: 'noticeschange'
        },
        {
            name: 'Invoice Change',
            value: 12,
            key: 'invoiceschange'
        },
        {
            name: 'Prebills Change',
            value: 13,
            key: 'prebillschange'
        }
    ],
    owners: [
        {
            name: 'Owner Status Change',
            value: 1,
            key: 'ownerstatuschange'
        },
        {
            name: 'Field Value Change',
            value: 2,
            key: 'fieldvaluechange'
        },
        {
            name: 'Notes Change',
            value: 3,
            key: 'noteschange'
        },
        {
            name: 'Attachment Change',
            value: 4,
            key: 'attachmentchange'
        },
        {
            name: 'Name Change Applications Change',
            value: 5,
            key: 'namechangeapplicationchange'
        },
        {
            name: 'Invoices Change',
            value: 6,
            key: 'invoiceschange'
        },
        {
            name: 'Pre-Bills Change',
            value: 7,
            key: 'prebillschange'
        },
    ]
}
