import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import React, { forwardRef, useEffect, useState } from 'react';

const drawerWidth = 840;

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(1, 1, 0, 0),
    },
  }));

function RegenerateSideBar(props, ref) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = React.useState('2');
    const [cancelInvoice, setCancelInvoice] = React.useState(false);
    const [invoiceDate, setInvoiceDate] = React.useState(new Date());
    const [invoiceDueDays, setInvoiceDueDays] = React.useState(180);
    const classes = useStyles();


    useEffect(() => {
        setOpen(props.open);
        if(props.open){

        }

      }, [props.open]);



    const toggleDrawer = () => {
        setOpen(false);
        props.handleRegenerateSlider(null);
    };

    const handleRadioChange = (event) => {
        setValue(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let regenerateOptions = { RegenerateType: value, CancelInvoice: cancelInvoice, InvoiceDate: invoiceDate, InvoiceDueDays:invoiceDueDays  }
        props.handleRegenerateSlider(regenerateOptions);
        //props.handleRegenerateSlider(value,cancelInvoice);
    };

    const handleCancelInvoice = (e) => {
        setCancelInvoice(e.target.checked)
    }

    const handleFieldChange = (e, name) => {
        if(name == 'invoiceDate') {
            setInvoiceDate(e.target.value);
        }
        else {
            setInvoiceDueDays(e.target.value);
        }

    }

    const lable_baseCAP = (props.recordDetails && props.recordDetails["KEY"] == 'VIOLATIONS') ? "Fine" : "Invoice";
    const isFine = (props.recordDetails && props.recordDetails["KEY"] == 'VIOLATIONS');

    return (
        <Drawer anchor={'right'} open={open} onClose={() => toggleDrawer()} classes={{
            paper: classes.drawerPaper,
        }}>
            <AppBar position="static" color='secondary'>
                <Toolbar variant="dense">
                    <div className="row float-right">
                        <Fab size="small" color="secondary" aria-label="Close" className="float-right" title="Close" onClick={toggleDrawer}>
                            <CloseIcon fontSize="small" />
                        </Fab>
                    </div>

                </Toolbar>
            </AppBar>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend"> {props.type =="G" ? "Generate Options:" : "Regenerate Options:"} </FormLabel>
                <FormLabel component="legend" color="secondary">{lable_baseCAP} will be generated based on current setting data. Latest fee details will be considered.</FormLabel>
                <div className="row pl-5">
                    <TextField
                        label={lable_baseCAP +" Date"}
                        className={'col-sm-5 pr-2'}
                        value={invoiceDate}
                        onChange={(e) => handleFieldChange(e, 'invoiceDate')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="date"
                    />
                    <TextField
                        label={lable_baseCAP +" Due Days"}
                        className={'col-sm-5 pr-3'}
                        value={invoiceDueDays}
                        onChange={(e) => handleFieldChange(e, 'invoiceDueDays')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="number"
                    />
                </div>
                {!isFine && <RadioGroup aria-label="regenerate" name="regenerate" value={value} onChange={handleRadioChange}>
                    <FormControlLabel value="1" control={<Radio />} label="Include all active permits of applicant" />
                    <FormControlLabel value="2" control={<Radio />} label= { props.type =="G" ? "Current permit" : "Current invoice permits"} />
                </RadioGroup>}

                {props.type =="R" && <FormControlLabel
                    control={
                    <Checkbox
                        checked={cancelInvoice}
                        onChange={handleCancelInvoice}
                        name="cancelInvoice"
                        color="primary"
                    />
                    }
                    label="Cancel Current Invoice"
                />}
               {cancelInvoice && <FormLabel component="legend" color="secondary">You are about to cancel current invoice upon regenerating.</FormLabel> }
                <Button type="submit" variant="outlined" color="primary" className={classes.button} onClick={handleSubmit}>
                    Confirm
                </Button>
            </FormControl>

        </Drawer>
    );


}

export default forwardRef(RegenerateSideBar);
