import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import Checkbox from '../../core/controls/components/checkbox';
import { createRequest, requestApi } from '../../core/controls/helpers/rest';
import FormHeader from '../../core/modules/form/cformheader';
import { getEnvVariable } from '../environmentalList';
const _ = require('lodash');


class RequestApproval extends Component {
    constructor(props) {
        super(props)

        this.state = {
            confirmClickedEvent: false,
            modules: '',
            requestStatus: {},
            subModuleMenu: null,
            ApproveAll: getEnvVariable('HideSubmodules') === "Y" ? props && props.companyModules && props.companyModules.length > 0 && props.companyModules[0].RequestStatus === 'A' ? true : false : false,
            DenyAll: getEnvVariable('HideSubmodules') === "Y" ? props && props.companyModules && props.companyModules.length > 0 && props.companyModules[0].RequestStatus === 'D' ? true : false : false,
            approveallClickedEvent: false,
            hidedeny: getEnvVariable('HideSubmodules') === "Y" ? props && props.companyModules && props.companyModules.length > 0 && (props.companyModules[0].RequestStatus === 'D' || props.companyModules[0].RequestStatus === 'A') ? true : false : false,
            disableapproveall: getEnvVariable('HideSubmodules') === "Y" ? props && props.companyModules && props.companyModules.length > 0 && (props.companyModules[0].RequestStatus === 'A') ? true : false : false,
            initialresult: {},
            notaccesseditems: [],
        }
    }

    componentDidMount() {
        this.loadSubModuleMenu()
    }

    setApproveDenyStates = () => {
        const { companyModules } = this.props
        let reqstatus = {}
        companyModules && companyModules.length > 0 && companyModules.forEach(element => {
            let idval = element.SubModuleId;
            let requeststatusval = element.RequestStatus
            reqstatus[idval] = requeststatusval
        });
        let hidedenyval = (_.includes(reqstatus, 'A') || _.includes(reqstatus, 'D')) ? true : false
        this.setState({
            requestStatus: reqstatus, initialresult: reqstatus, hidedeny: hidedenyval,
            disableapproveall: (_.includes(reqstatus, 'A')) ? true : false
        })
    }

    loadSubModuleMenu() {
        let SubModuleMenuRead = {
            url: getEnvVariable('MasterAdmin') + `/api/module/` + getEnvVariable('ModuleKey') + `/submodule/All/Active/IsConfigurable`,
            "matchProps": [{
                name: "Id",
                key: "Id",
            }],
            type: 'get',
        }
        requestApi(createRequest(SubModuleMenuRead)).then(results => {
            console.log(results);
            if (Array.isArray(results)) {
                let data = [...results]
                this.props.companyModules.forEach(element => {
                    _.remove(data, result => result.Id === element.SubModuleId);
                });

                this.setState({ subModuleMenu: results, notaccesseditems: data }, () => {
                    this.setApproveDenyStates()
                });
            }
        })
    }

    componentDidUpdate(prevState, prevProps) {
        if (prevState.companyModules != this.props.companyModules) {
            if (this.props.companyModules && this.props.companyModules.length > 0) {
                let reqstatus = {}
                this.props.companyModules && this.props.companyModules.length > 0 && this.props.companyModules.forEach(element => {
                    let idval = element.SubModuleId;
                    let requeststatusval = element.RequestStatus
                    reqstatus[idval] = requeststatusval
                });
                this.setState({ requestStatus: reqstatus })
            }
        }
        if (prevState.initialresult != this.props.initialresult) {
            this.setState({ initialresult: this.props.initialresult })

        }
        // if (this.props.save !== prevProps.save) {
        //     this.setApproveDenyStates()
        // }
    }

    handleFieldChange = (event) => {
        const { name, value } = event.target
        let radiodata = { name: event.target.name, value: event.target.value }
        if (value === 'A') {
            this.setState({
                confirmClickedEvent: true, DenyAll: false, radioevt: {
                    name: event.target.name,
                    value: event.target.value
                }
            }, this.changestatus(radiodata, value === 'A' ? 'Registration is set to Approved.' : 'Registration is set to Denied'));
        }
        else {
            let modules = this.props.companyModules;
            modules.find(v => v.SubModuleId === name).RequestStatus = value;
            //let radioval = {name : value}
            this.setState({ confirmClickedEvent: false, ApproveAll: false, DenyAll: false, requestStatus: { ...this.state.requestStatus, [name]: value } }, () => {
                this.props.handleApprovalChange(modules, value === 'A' ? 'Registration is set to Approved.' : 'Registration is set to Denied')
            })
        }
    }

    changestatus = (radioevt, message = null) => {
        let modules = this.props.companyModules;
        let moduleidval = Number(radioevt.name)
        let radioval = { [moduleidval]: radioevt.value }
        modules.find(v => v.SubModuleId === radioevt.name).RequestStatus = radioevt.value;
        //let reqstatus = {[this.state.requestStatus['SubModuleId']] : radioevt.value}
        this.setState({
            confirmClickedEvent: false, requestStatus: {
                ...this.state.requestStatus,
                [moduleidval]: radioevt.value
            }
        }, () => {
            this.props.handleApprovalChange(modules, message)
        })
    }

    resetStatus = () => {
        this.setState({ confirmClickedEvent: false, approveallClickedEvent: false });
        // this.setState({ requestStatus: this.props.requestStatus });
    }

    handleChange = (event) => {
        let othername = event.target.name === "ApproveAll" ? "DenyAll" : "ApproveAll"
        if (othername != "DenyAll" && event.target.checked) {
            let requestStatusData = { ...this.state.requestStatus }
            let modules = this.props.companyModules;
            modules.forEach(element => {
                requestStatusData[element.SubModuleId] = 'D';
                element['RequestStatus'] = 'D';
            });
            this.setState({
                requestStatus: requestStatusData,
                [event.target.name]: event.target.checked, [othername]: false
            }, () => {
                this.props.handleApprovalChange(modules, 'Registration is set to Denied')
            })

        }
        else if (othername != "ApproveAll" && event.target.checked) {
            this.setState({ approveallClickedEvent: true }, () => {
                this.changeToApprovestatus();
            })

        }
        else {
            this.setState({ [event.target.name]: event.target.checked, [othername]: false })
        }
    }

    changeToApprovestatus = () => {
        let requestStatusData = { ...this.state.requestStatus }
        let modules = this.props.companyModules;
        modules.forEach(element => {
            requestStatusData[element.SubModuleId] = 'A';
            element['RequestStatus'] = 'A';
        });

        this.setState({ requestStatus: requestStatusData, ApproveAll: true, DenyAll: false, approveallClickedEvent: false },
            () => {
                this.props.handleApprovalChange(modules, 'Registration is set to Approved.')
            })


    }


    handleIcons = () => {
        if (this.props.save && this.state.disableapproveall === false) {
            const { companyModules } = this.props
            let reqstatus = {}
            companyModules && companyModules.length > 0 && companyModules.forEach(element => {
                let idval = element.SubModuleId;
                let requeststatusval = element.RequestStatus
                reqstatus[idval] = requeststatusval
            });
            if ((_.includes(reqstatus, 'A'))) {
                this.setState({
                    disableapproveall: (_.includes(reqstatus, 'A')) ? true : false
                })
            }
        }

    }

    render() {
        const { companyModules } = this.props
        let hidesubmodules = getEnvVariable('HideSubmodules') === "Y" ? true : false
        //const { requestStatus } = this.state
        if (this.props.save && this.state.disableapproveall === false)
            this.handleIcons()
        return (
            <div className="col">
                <FormHeader savebutton={this.state.disableapproveall ? false : true} handleSave={this.props.handleSave}
                    designprops={true} hasclearfix={true} />
                <div className='row'>
                    <Checkbox className="col-2" label={'Approve'} disabled={this.state.disableapproveall} value={this.state.ApproveAll} handleFieldValue={this.handleChange} name="ApproveAll" />
                    <Checkbox className="col-2" label={'Deny'} disabled={this.state.hidedeny || this.state.disableapproveall} value={this.state.DenyAll} handleFieldValue={this.handleChange} name="DenyAll" />
                </div>
                <small style={{ fontSize: '12px' }} className="text-secondary">Note : Once a request is approved, the action cannot be undone.</small>
                {
                    !hidesubmodules && companyModules && companyModules.map((each, index) => {
                        let x = this.state.subModuleMenu ? _.find(this.state.subModuleMenu, { 'Id': each.SubModuleId }) : null
                        let radioval = _.isEmpty(this.state.requestStatus) ? "P" : this.state.requestStatus[each.SubModuleId]
                        return (
                            <div key={index}>
                                {!(x && x.Key && x.Key === 'VIOLATIONS') &&
                                    <FormControl component="fieldset" >
                                        <FormLabel component="legend">{x ? x['SubModuleName'] : ''}</FormLabel>
                                        <RadioGroup
                                            aria-label="Request Approval"
                                            name={each.SubModuleId}
                                            //value={this.state.requestStatus[each.SubModuleId]}
                                            value={radioval}
                                            onChange={this.handleFieldChange}
                                        >
                                            {(this.state.initialresult[each.SubModuleId] === "A" || hidesubmodules) ?
                                                (<div>
                                                    <FormControlLabel
                                                        value="D"
                                                        control={<Radio color="primary" />}
                                                        label="Deny"
                                                        labelPlacement="end"
                                                        disabled
                                                    />
                                                    <FormControlLabel
                                                        value="A"
                                                        control={<Radio color="primary" />}
                                                        label="Approve"
                                                        labelPlacement="end"
                                                        disabled={hidesubmodules ? true : false}
                                                    />
                                                    <FormControlLabel
                                                        value="P"
                                                        control={<Radio color="primary" />}
                                                        label="Pending"
                                                        labelPlacement="end"
                                                        disabled
                                                    />
                                                </div>) :
                                                (<div>
                                                    <FormControlLabel
                                                        value="D"
                                                        control={<Radio color="primary" />}
                                                        label="Deny"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="A"
                                                        control={<Radio color="primary" />}
                                                        label="Approve"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="P"
                                                        control={<Radio color="primary" />}
                                                        label="Pending"
                                                        labelPlacement="end"
                                                    />
                                                </div>)}
                                        </RadioGroup>
                                    </FormControl>}
                            </div>
                        )
                    })
                }

                {/* {
                    this.state.confirmClickedEvent && <ConfirmDialog title="Request Status" description={"Do you want to Approve this request ? This action cannot be undone!"} onSubmit={() => this.changestatus(this.state.radioevt)} onClose={() => this.resetStatus()} ></ConfirmDialog>
                }
                 {
                    this.state.approveallClickedEvent && <ConfirmDialog title="Request Status" description={"Do you want to Approve all the requests ? This action cannot be undone!"} onSubmit={this.changeToApprovestatus} onClose={this.resetStatus} ></ConfirmDialog>
                } */}
                {
                    this.state.notaccesseditems && this.state.notaccesseditems.length > 0 && this.state.notaccesseditems.map((each, index) => {
                        return <Typography variant="body1" className="text-secondary pl-3" display="block" gutterBottom>
                            {each.DisplayName} : Not Requested
                        </Typography>
                    })
                }

            </div >

        )
    }
}
export default RequestApproval;