
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withWidth from '@material-ui/core/withWidth';
import FolderIcon from '@material-ui/icons/Folder';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { OldPostMutiFormData } from '../../core/controls/helpers/requests';
import { getEnvVariable } from '../../modules/environmentalList';
import AttachmentsS3Form from './forms/attachments';
import ImagesS3Form from './forms/images';
import DefaultSettings from '../../core/controls/components/settings';

const UploadImageToS3WithNativeSdk = (props) => {
    const [selectedFile, setSelectedFile] = useState([]);
    const [attachmentDetails, setAttachmentDetails] = useState([]);
    const [fileProgress, setFileProgress] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [NoteView, setNoteView] = useState(props.dataItem || [])
    const [reload, setReload] = useState(false)
    const [errorObj, setErrorObj] = useState(props.errors || {})
    const [hideSave, setHideSave] = useState(false)
    const params = useParams()

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files);
    }
    useEffect(() => {
        if (props && props.errors && Object.keys(props.errors).length > 0) {
            setErrorObj(props.errors)
            setUploadedFiles([])
            setAttachmentDetails([])
        }
    }, [props && props.errors])
    const uuidv4 = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    const uploadFile = (files) => {
        var type = getFileTypes(props.uploadType)
        var extension = files[0].name.split('.')
        if(type.includes(extension[1]))
        {
            let saveData = errorObj && Object.keys(errorObj).length > 0 && Object.keys(errorObj).every(each => each.error === true) || true
            if (saveData) {
                if (files && files.length > 0) {
                    uploadFileWrapper(files, NoteView);
                }
            }
        }
        else{
            DefaultSettings.getAlert().show("File Type is Not Valid", 'error');
        }
    }
    const uploadFileWrapper = (files, info) => {
        let url = getEnvVariable('Sidebar') + `/api/FileSystem/${props.refid ? props.refid + '/Upload' : 'Upload'}?parentid=${props.refid}&sitePermitTypeId=${params.routeid}&attachmentType=${props.selectedAttachName}`;
        let predetails = []
        var formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i], files[i].name);
        }
        OldPostMutiFormData(url, formData, true, true,'').then(response => {
            let res = response
            if (res && res.length > 0)
                for (let i = 0; i < res.length; i++) {
                    predetails.push({
                        ...info[i], FileName: res[i].fileName, ContentType: res[i].fileName.split(".").pop(), Size: res[i].size, FileReference: res[i].fileReference,
                        fileName: res[i].fileName, extension: files[i].type, fileType: files[i].type, guid: res[i].fileReference, size: res[i].size,
                    });
                }
            setAttachmentDetails(predetails);
            ParentCallBack(predetails)
        }).catch(ex => {
            console.error(ex)
        })
    }
    const ParentCallBack = (predetails) => {
        if (props.handleUploads) {
            props.handleUploads(predetails);
            if (!props.customclose)
                setSelectedFile([])
        }
    }
    const getFileTypes = (type) => {
        switch (type) {
            case 'I': return ".jpg, .jpeg, .png"
            case 'A': return getEnvVariable('AttachmentExtensions')
            default: return getEnvVariable('AttachmentExtensions') + ".jpg, .jpeg, .png"
        }
    }
    return <div className="col-3 pl-0 m-4">
        {reload}
        {selectedFile && selectedFile.length === 0 && <Fragment><input
            type="file"
            name="file"
            accept={".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.csv,.zip,.jpg,.png"}
            multiple={props.multiple || false}
            id="file-upload"
            onChange={handleFileInput}
            style={{ display: "none" }}
        />
            <label htmlFor="file-upload">  <Button className=' mt-2' size="small" color="primary" aria-label="add" title="Upload" component="span">{props.title}</Button></label></Fragment>}
        {(selectedFile && selectedFile.length > 0) && <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={true} fullWidth={true} maxWidth={'md'}>
            <MuiDialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                {props.title}
            </MuiDialogTitle>
            <MuiDialogContent dividers>
                <Grid item >
                    {!props.isCustom && props.uploadType === "I" && <ImagesS3Form {...props} selectedFile={selectedFile} fileProgress={fileProgress} NoteView={NoteView} currentUser={props.currentUser} validations={props.validations}
                        errorObj={errorObj}
                        handleHideSave={(val) => { setHideSave(val) }}
                        handleFieldValue={(obj) => { setNoteView(obj.data); setErrorObj(obj.error); setReload(!reload) }} />}
                    {!props.isCustom && props.uploadType === "A" && <AttachmentsS3Form {...props} selectedFile={selectedFile} fileProgress={fileProgress} NoteView={NoteView} validations={props.validations}
                        errorObj={errorObj} handleFieldValue={(obj) => { setNoteView(obj.data); setErrorObj(obj.error); setReload(!reload) }} />}
                    {props.isCustom && <ListItem className='col-12'>
                        <ListItemIcon> <FolderIcon /> </ListItemIcon>
                        <ListItemText className='col-6'
                            primary={selectedFile[0].name}
                            secondary={<small className='text-primary'>{"Size: " + (selectedFile[0].size / 1048576).toFixed(2) + " MB"}
                                {Array.isArray(fileProgress) && fileProgress.length > 0 && ' Progress %:' + fileProgress[0].progress}</small>}
                        />
                    </ListItem>}
                </Grid>
            </MuiDialogContent>
            <MuiDialogActions>
                <Button autoFocus onClick={() => props.handleClose ? props.handleClose() : setSelectedFile([])} color="primary">
                    Close
                </Button>
                {selectedFile && selectedFile.length > 0 && <Button
                    disabled={hideSave === true ? true : false} autoFocus onClick={() => uploadFile(selectedFile)} color="primary">
                    Upload
                </Button>}
            </MuiDialogActions>
        </Dialog>}
    </div>
}
export default withWidth()(withRouter(UploadImageToS3WithNativeSdk));
