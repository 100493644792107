import GeneralsettingsContainer from '../settings/generalsettings/generalsettingsgrid'
import ProjectMessagesGrid from '../settings/projectmessages/projectmessagesgrid'
import PolicysettingsContainer from '../settings/policysettings/policysettingsgrid'
const SettingstabView = {
    type: "sections",
    "key": "SettingstabView",
    name: "SettingstabView",
    aliases: {
    },
    initRun: true,
    sharedValues: [],
    validationMapKeys: [],
    layout: {
        // 'Settings': {
        //     group: [
        //         {
        //             order: 0,
        //             type: 'div',
        //             className: 'row',
        //             components: [{
        //                 name: 'GeneralsettingsContainer',
        //                 type: 'div',
        //                 className: 'col-12',
        //                 sub: {
        //                     type: 'div',
        //                     className: 'col-12'
        //                 }
        //             },]
        //         },
        //     ],
        // },
        'Project Messages': {
            group: [
                {
                    order: 0,
                    type: 'div',
                    className: 'row',
                    components: [{
                        name: 'ProjectMessagesGrid',
                        type: 'div',
                        className: 'col-12',
                        sub: {
                            type: 'div',
                            className: 'col-12'
                        }
                    },]
                },
            ],
        },
        'System Templates': {
            group: [
                {
                    order: 0,
                    type: 'div',
                    className: 'row',
                    components: [{
                        name: 'PolicysettingsContainer',
                        type: 'div',
                        className: 'col-12',
                        sub: {
                            type: 'div',
                            className: 'col-12'
                        }
                    },]
                },
            ],
        },
    },
    values: {
      //  GeneralsettingsContainer: '',
        ProjectMessagesGrid: '',
        PolicysettingsContainer: ''
    },

    sections: {
        type: 'tab',
        sectionNames: [ 'Project Messages', 'System Templates'],
        steps: [ 'Project Messages', 'System Templates'],
    },
    components: {
      //  GeneralsettingsContainer: GeneralsettingsContainer,
         ProjectMessagesGrid: ProjectMessagesGrid,
         PolicysettingsContainer: PolicysettingsContainer
    },
    mapActionsToEffects: {

       
    }

}

export default SettingstabView