import ProjectMessagesTableAdd from '../projectmessages/projectmessagestableadd';
import ProjectMessagesTableEdit from '../projectmessages/projectmessagestableview'
import ProjectMessagesTable from '../projectmessages/projectmessagestable';


const ProjectmessagesEdit = {
    "type": "popup",
    "key": "ProjectmessagesEdit",
    "name": "ProjectmessagesEdit",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'editOpen'
    },
    sharedValues: ['open'],
    container: {
       // addRoute: true,
        values: {
            editProjectInfo: null,
            editInfo: null, edit: false,
            allgriddata: null, addOpen: false,
            editOpen: false,
            navigation: null,
            subnavigation: null,
            fullscreen: false
        },
        mapValues: {
            header: [
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'edit', name: 'edit' },
                { key: 'allgriddata', name: 'allgriddata' },
            ],
            ProjectMessagesTable: [
                { key: 'editInfo', name: 'editInfo' },
                {
                    key: 'addOpen',
                    name: 'addOpen'
                },
                {
                    key: 'editOpen',
                    name: 'editOpen'
                },
                { key: 'navigation', name: 'navigation' }
            ],
            ProjectMessagesTableAdd: [
                { key: 'editInfo', name: 'editInfo' },
                { key: 'editProjectInfo', name: 'editProjectInfo' },
                { key: 'addOpen', name: 'open' },
                { key: 'subnavigation', name: 'subnavigation' },
            ],
            ProjectMessagesTableEdit: [
                { key: 'editInfo', name: 'editInfo' },
                { key: 'editOpen', name: 'open' },
                { key: 'editProjectInfo', name: 'editProjectInfo' },
                { key: 'allgriddata', name: 'allgriddata' },
                { key: 'subnavigation', name: 'subnavigation' },
            ]
        },
        //  sharedValues: ['save', 'edit'],
        mapActionsToEffects: {
            'addOpen': {
                rules: [
                    {
                        check: [
                            {
                                condition: 'AND',
                                rules: [
                                    {
                                        "name": "addOpen",
                                        "type": "!=",
                                        "value": true,
                                        "rules": []
                                    }
                                ]
                            }
                        ],
                        uncheck: true,
                        run: {
                            valid: [
                                {
                                    type: 'removelayout',
                                    name: 'ProjectMessagesTableAdd',
                                }

                            ],
                            inValid: [
                                {
                                    type: 'addlayout',
                                    name: 'ProjectMessagesTableAdd',
                                }
                            ]
                        }
                    }
                ]
            },
            editOpen: {
                rules: [{
                    check: [
                        {
                            condition: 'AND',
                            rules: [
                                {
                                    "name": "editOpen",
                                    "type": "!=",
                                    "value": true,
                                    "rules": []
                                }
                            ]
                        }
                    ],
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'removelayout',
                                name: 'ProjectMessagesTableEdit',
                            }
                        ],
                        inValid: [

                            {
                                type: 'addlayout',
                                name: 'ProjectMessagesTableEdit',
                            }
                        ]
                    }
                }]
            },

        },
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },


            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'ProjectMessagesTable',
                    type: 'div',
                    className: 'col-12',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },

            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Project Messages',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "Project Messages", "appendval": "Name", "materialicon": "message", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },
                    // {
                    //     type: 'icon',
                    //     icon: 'previtem',
                    //     name: 'previtem',
                    //     hideon: 'previtem',
                    //     hidevalue: false,
                    //     title: 'Previous Item'
                    // },
                    // {
                    //     type: 'icon',
                    //     icon: 'nextitem',
                    //     name: 'nextitem',
                    //     hideon: 'nextitem',
                    //     hidevalue: false,
                    //     title: 'Next Item'
                    // },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hideon: 'exitfullscreen',
                        hidevalue: false
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false
                    }
                ],


            },
            ProjectMessagesTable: ProjectMessagesTable,
            ProjectMessagesTableAdd: ProjectMessagesTableAdd,
            ProjectMessagesTableEdit: ProjectMessagesTableEdit
        },

    }
}

export default ProjectmessagesEdit