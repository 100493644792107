import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import React, { Fragment, useEffect, useState } from 'react';
import { getEnvVariable } from '../../../../modules/environmentalList';
import { requestApi } from '../../../modules/form/utilities';
const _ = require('lodash');


export default function SubModuleCheckboxes(props) {
    let checkboxval = props && props.dropdownvalues ? props.dropdownvalues : null
       let arr = []
    let checkboxitems = []
    let resultarr = []
    const [subModuleItems, setSubModuleItems] = useState(resultarr);
    const [companySubModuleMenu, setCompanySubModuleMenu] = useState([]);
    const [subModuleMenu, setSubModuleMenu] = useState([]);
    const [subModuleArr, setSubModuleArr] = useState([]);
    const [changed, setChanged] = useState(false);
    const [helpertext, setHelperText] = useState(null);

    useEffect(() => {
        let SubModuleMenuRead = {
            "get": {
                url: getEnvVariable('MasterAdmin') + `/api/module/` + getEnvVariable('ModuleKey') + `/submodule/All/Active/IsConfigurable`
            }
        };
        requestApi(SubModuleMenuRead, '').then(res => {
            if (res && Array.isArray(res)) {
                setSubModuleMenu(res)
            }
        })
    }, [])

    useEffect(() => {
        let CompanySubModuleMenuRead = {
            "get": {
                url: getEnvVariable('MasterAdmin') + `/api/CompanyInternal/` + getEnvVariable('ModuleKey') + `/` + `${props.dataItem.CompanyId}`
            }
        };
        requestApi(CompanySubModuleMenuRead, '').then(res => {
            setCompanySubModuleMenu(res.CompaniesModules)

        })
    }, [subModuleMenu]);

    useEffect(() => {
        setHelperText(props.helperText);
    }, [props.helperText]);

    useEffect(() => {
        companySubModuleMenu.forEach(element => {
            let x = _.find(subModuleMenu, { 'Id': element['SubModuleId'] })
            if (x) {
                arr.push(x);
            }
        })
        setSubModuleArr(arr)
        checkboxitems = arr.map(x => x.Id);
        resultarr = []
        for (const element of checkboxitems) {
            let obj = {}
            let selectedval = null
            if (checkboxval) {
                selectedval = _.find(checkboxval, { 'SubModuleId': element })
            }
            if (selectedval) {
                obj["Id"] = selectedval.Id
                obj["UserId"] = selectedval.UserId
                obj["CreatedBy"] = selectedval.CreatedBy
            }
            obj["ModuleId"] = selectedval ? selectedval.ModuleId : companySubModuleMenu[0].ModuleId
            obj["SubModuleId"] = element
            obj["AccessType"] = selectedval ? selectedval["AccessType"] : "P"
            obj["isprimarycheck"] = selectedval && selectedval["isprimarycheck"] ? selectedval["isprimarycheck"] : "N"
            resultarr.push(obj);
        }
        setSubModuleItems(resultarr)
    }, [companySubModuleMenu]);

    useEffect(() => {
        props.customnoticesave(subModuleItems, props.name)
    }, [subModuleItems]);

    const handleChange = (event, primarycheck) => {
        let arr = subModuleItems
        let changedsubmodule = _.find(subModuleItems, { 'SubModuleId': Number(event.target.name) });
        let objIndex = arr.findIndex((obj => obj.SubModuleId == changedsubmodule.SubModuleId));
        if (primarycheck) {
            changedsubmodule["isprimarycheck"] = event.target.checked == true ? "Y" : "N";
            arr[objIndex]["isprimarycheck"] = changedsubmodule["isprimarycheck"]
        }
        else {
            changedsubmodule["AccessType"] = event.target.checked == true ? "O" : "P";
            arr[objIndex]["AccessType"] = changedsubmodule["AccessType"]
        }
        setSubModuleItems(arr);
        setChanged(!changed)
    };


    useEffect(() => {
        setSubModuleArr(subModuleArr);
    }, [changed]);

    if (props.edit === false) {
        return (
            <Fragment>
                <Typography variant="h6" className="fw-bold">{props.label}</Typography>
                <div>
                    {subModuleArr.map(each => {
                        let selectedval = _.find(subModuleItems, { 'SubModuleId': each.Id })
                        let checkedval = selectedval && selectedval['AccessType'] === 'O' ? true : false
                        return (
                            <div>
                                <label className="col-sm-5" style={{ fontSize: '14px' }}>{each.DisplayName} </label>
                                <label style={{ fontSize: '12px' }}>Admin : {props.value ? (props.transformProps ?
                                    String((checkedval === true || props.transformProps[props.value] === 'O' || props.transformProps[props.value] === true) ? 'Yes' : 'No') : String(props.value)) : 'No'}
                                </label>
                                {each.Key && each.Key == 'STATEPROJECTS' &&
                                    <label className="pl-5" style={{ fontSize: '12px' }}>Primary Contact : {
                                        String(selectedval && selectedval["isprimarycheck"]  === "Y" ? 'Yes' : 'No')}
                                    </label>
                                }
                            </div>
                        )
                    })}
                </div>
            </Fragment>
        )
    }
    else {
        return (
            <div>
                <Typography variant="h6" className="fw-bold">{props.label}</Typography>
                {subModuleArr.map(each => {
                    let selectedval = _.find(subModuleItems, { 'SubModuleId': each.Id })
                    let checkedval = selectedval && selectedval['AccessType'] === 'O' ? true : false
                    return (
                        <div>
                            <label className="col-sm-5" style={{ fontSize: '14px' }}>{each.DisplayName}</label>
                            <FormControlLabel
                                control={<Checkbox checked={each.key ? each.key : checkedval} onChange={(e) => handleChange(e, false)} name={each.Id ? each.Id : each.Key} />}
                                labelPlacement="start" label={<span style={{ fontSize: '12px' }}>Admin</span>}
                            />
                            {each.Key && each.Key == 'STATEPROJECTS' &&
                                <FormControlLabel
                                    control={<Checkbox checked={selectedval['isprimarycheck'] == "Y" ? true : false} onChange={(e) => handleChange(e, true)} name={each.Id ? each.Id : each.Key} />}
                                    labelPlacement="start" label={<span style={{ fontSize: '12px' }}>Primary Contact</span>}
                                />
                            }

                        </div>
                    )
                })}
                {helpertext && <div style={{ color: "#F44336" }}>
                                    <Typography variant="caption" display="block" gutterBottom>{helpertext}</Typography>
                                </div>}
            </div>
        );
    }
}
