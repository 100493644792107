import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import React, { Fragment } from 'react';
import Checkbox from '../../../core/controls/components/checkbox';
import TextBox from '../../../core/controls/components/textbox';
import { checkReferenceKey } from '../../../controls/jsons/attachments/conditionsbymodule';
import { fieldValidate } from '../../../core/controls/helpers/checkers';
import { getEnvVariable } from '../../environmentalList';

export default function AttachmentsS3Form({ selectedFile, validations, fileProgress, required = [], errorObj = {}, NoteView = [], handleFieldValue ,referencekey}) {

    const FormhandleFieldValue = (evt, index) => {
        const { name, value, checked } = evt.target;
        let error_Obj = {}
        error_Obj = fieldValidate(validations[name], value) || {};
        errorObj[name] = error_Obj;
        let temp = NoteView || [];
        if (temp.length === 0) {
            temp.push({ Notes: ''})
        }
        let obj = {
            'Notes': name === 'Notes' ? value : (temp && temp[index] && temp[index].Notes || ''),
            // 'IsViewable': name === 'IsViewable' ? (checked ? 'Y' : 'N') : (temp && temp[index] && temp[index].IsViewable || '')
        }
        let newtemp = { ...temp[index], ...obj }
        temp[index] = newtemp
        handleFieldValue({ data: temp, error: errorObj })
    }

    return <Fragment>
        {
            selectedFile && selectedFile.length > 0 ? <Typography variant="h6"> Selected File(s) to Upload </Typography> : <small className='text-primary text-center'>
                Please Upload the required files </small>
        } <List dense={true}>
            {selectedFile && selectedFile.length > 0 && ([...Array(selectedFile.length).keys()] || []).map((each, index) => {
                return <>
                    <ListItem key={index} className='col-12'>
                        <ListItemIcon> <FolderIcon /> </ListItemIcon>
                        <ListItemText className='col-6'
                            primary={selectedFile[index].name}
                            secondary={<small className='text-primary'>{"Size: " + (selectedFile[index].size / 1048576).toFixed(2) + " MB"}
                                {Array.isArray(fileProgress) && fileProgress.length > 0 && ' Progress %:' + fileProgress[index].progress}</small>}
                        />
                        {/* {getEnvVariable('AccessPortal') === 'I' && checkReferenceKey(referencekey) &&  <ListItemText className='col-6'><Checkbox name="IsViewable"
                            error={errorObj['IsViewable'] && errorObj['IsViewable'].error}
                            helperText={errorObj['IsViewable'] && errorObj['IsViewable'].errorText} required={required.indexOf("IsViewable") > -1 ? true : false}
                            value={(NoteView && NoteView.length > 0 &&
                                NoteView[index] && NoteView[index].IsViewable) || 'N'} label="Can be Viewed by Applicant?" labelPlacement="top" handleFieldValue={(evt) =>
                                    FormhandleFieldValue(evt, index)} /></ListItemText>} */}
                                    <br />
                    </ListItem>
                    <ListItem key={index} className='col-12'>
                        <ListItemText className='col-12'><TextBox error={errorObj['Notes'] && errorObj['Notes'].error}
                            helperText={errorObj['Notes'] && errorObj['Notes'].errorText} label="Note" name="Notes" value={(NoteView && NoteView.length > 0 && NoteView[index] && NoteView[index].Notes) || ''}
                            required={required.indexOf("Notes") > -1 ? true : false}
                            multiline={true} handleFieldValue={(evt) => FormhandleFieldValue(evt, index)} /></ListItemText>
                    </ListItem>
                </>
            })}
        </List>
    </Fragment>
}