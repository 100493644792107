import { Card } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FormHelperText from '@material-ui/core/FormHelperText'
import React, { Fragment } from 'react';
const _ = require('lodash');

const extract = ([beg, end]) => {
    const matcher = new RegExp(`${beg}(.*?)${end}`, 'gm');
    const normalise = (str) => str.slice(beg.length, end.length * -1);
    return function (str) {
        return str.match(matcher).map(normalise);
    }
}

// const dropValGenerator = (splitDropArray, val) => {
//     let generatestr = ''
//     splitDropArray.map((spl, index) => {
//         if (splitDropArray[index + 1])
//             generatestr = generatestr + '${' + `${spl}` + '}' + ' # '
//         else generatestr = generatestr + '${' + `${spl}` + '}'
//     })
//     return val.split('-')[0] + '-' + generatestr
// }

const strGenerate = (text, dropdown) => {
    let str = '';
    if (text.length > dropdown.length) {
        text.map((t, i) => {
            str = str + t + ('${' + (dropdown[i] ? `${dropdown[i]}` : '') + '}')
        })
    }
    if (text.length < dropdown.length) {
        dropdown.map((t, i) => {
            str = str + (text[i] ? text[i] : '') + '${' + `${t}` + '}'
        })
    }
    if (text.length == dropdown.length) {
        dropdown.map((t, i) => {
            str = str + text[i] + '${' + `${t}` + '}'
        })
    }
    return str
}


export default function CheckboxesTags(props) {
    const { handleFieldChange, dropdownvalues, edit, name, label, tooltip, disabled, error, required,
        defaultValue, htmlForLabel, helperText, id, transformProps, crud, styles, className, multiple, conditions, ...otherProps } = props;
    // let [dropval, setDropVal] = useState([]) //props.value && props.value !== undefined && props.value.includes("${") ? extract(['{', '}'])(props.value) : ''
    // let [initalVal, setInitalVal] = useState([])
    let dropValues = dropdownvalues && dropdownvalues.length > 0 && dropdownvalues.split(',') || []
    let dropval = [];
    let initalVal = [];
    let val = props.value || '';
    let lastvalue = '';
    let changedVal = val
    if (dropValues && dropValues.length > 0 && changedVal === '') {
        changedVal = strGenerate(initalVal, dropValues)
        dropval = dropValues
    }
    if (changedVal && changedVal !== '') {
        if (changedVal !== '') {
            if (val && val.includes("${")) {
                dropval = extract(['{', '}'])(changedVal)
            }
            if (val && val.includes("$")) {
                let initial = []
                changedVal = '}' + changedVal
                const words = changedVal.split('}');
                words.map(word => {
                    if (word && word.includes("$")) {
                        initial.push(word.substr(0, word.indexOf('$')))
                        initalVal = initial
                    }
                    else lastvalue = word
                })
            }
        }
    }
    // macth dropval and dropValues
    if (dropValues && dropValues.length > 0 && (props.value && props.value !== '' && props.value.includes("${"))) {
        let inital = [];
        if (dropValues && dropval && (dropValues.length > dropval.length)) {
            let newDrop = _.difference(dropValues, dropval);
            let splitDropArray = extract(['{', '}'])(val);
            if (!(newDrop.some(r => dropValues.indexOf(r) >= 0)) && newDrop && newDrop.length > 0) {
                newDrop.map(nd => {
                    const index = splitDropArray.indexOf(nd);
                    if (index > -1) {
                        _.pullAt(dropval, [index])
                        //  splitDropArray = splitDropArray.splice(index, 1);
                        _.pullAt(initalVal, [index])
                        // inital = initalVal.splice(index + 1, 1);
                        // dropval = splittedArray
                    }
                })
                //     dropval = splittedArray;
                initalVal = inital
                val = strGenerate(initalVal, splitDropArray)
                //setDropVal(extract(['{', '}'])(val))
            }
        }
        else if (dropValues && dropval && (dropValues.length < dropval.length)) {
            let newDrop = _.difference(dropval, dropValues);
            let splittedArray = extract(['{', '}'])(val);
            if (!(dropValues.some(r => newDrop.indexOf(r) >= 0)) && newDrop && newDrop.length > 0) {
                newDrop.map(nd => {
                    const index = splittedArray.indexOf(nd);
                    if (index > -1) {
                        _.pullAt(dropval, [index])
                        //  splitDropArray = splitDropArray.splice(index, 1);
                        _.pullAt(initalVal, [index])

                    }
                })
                //  initalVal = inital
                // dropval = splittedArray
                if (dropval && dropval.length > 0)
                    val = strGenerate(initalVal, dropval)
            }
        }
    }

    const handleChange = (event, index) => {
        const { value, name } = event.target;
        let splitDropArray = dropval;
        let textval = initalVal;
        if (name === 'initalVal') {
            if (textval.length > 0) {
                textval[index] = value;
                if (!splitDropArray[index]) {
                    splitDropArray.push(dropValues[index])
                }
            }
            else {
                if (dropValues[index] !== splitDropArray[index]) {
                    splitDropArray.push(dropValues[index])
                }
                textval.push(value);

            }
            // setInitalVal(textval)
            initalVal = textval
            //  splitDropArray = extract(['{', '}'])(changedVal);
        }
        //  lastvalue = initalVal.pop()
        if (name === 'dropval') {
            if (splitDropArray.length > 0)
                splitDropArray[index] = value;

            else {
                splitDropArray.push(value)
            }
            dropval = splitDropArray
        }

        let result = strGenerate(textval, splitDropArray)
        handleFieldChange({ target: { name: props.name, value: result + lastvalue, checked: false } })
    }

    const handleLastVal = (event) => {
        const { value, name } = event.target;
        let result = strGenerate(initalVal, dropval)
        handleFieldChange({ target: { name: props.name, value: result + value, checked: false } })
    }
    // US${34343}YP${sdfsd}oopp

    if (edit === true) {
        return (<Fragment>
            <small className="pt-1 fw-bold " style={{ fontSize: '13px' }}>{label}</small> <br />
            <Card style={{ maxHeight: '300px', overflow: 'auto' }}>
                {dropValues && dropValues.length > 0 && dropValues.map((drop, i) => {
                    return <Fragment>
                        <TextField value={initalVal[i]} className="m-1 p-0" onChange={(event) => handleChange(event, i)} name={'initalVal'} /> &nbsp;
                        <Select
                            value={(dropval && dropval.length > 0 && dropval[i]) || drop[i]}
                            onChange={(event) => handleChange(event, i)}
                            name={'dropval'}
                            className="col-sm-5 my-1 mt-2"
                        >
                            {dropValues && dropValues.length > 0 && dropValues.map(item => {
                                return <MenuItem value={item}>{item}</MenuItem>
                            })}
                        </Select> &nbsp;<br /></Fragment>

                })}
                <TextField value={lastvalue} className="m-1" onChange={(event) => handleLastVal(event)} name={'lastvalue'} /> &nbsp;
            </Card>
            {helperText && <FormHelperText error>{helperText}</FormHelperText>}
        </Fragment>
        );
    }
    if (edit === false) {
        return <Fragment>
            {tooltip && <div > <Typography variant={'caption'} className="fw-bold" >{label}&nbsp;
                < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                    <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
                </Tooltip>  </Typography>   <Typography variant={'body2'} color={'textPrimary'} className="text-secondary">{props.value || ''}   </Typography>
            </div>}
            {!tooltip && <div > <Typography variant={'caption'} className="fw-bold">{label}&nbsp; </Typography>   <Typography variant={'body2'} color={'textPrimary'}
                className="text-secondary">{props.value || ''}   </Typography>
            </div>}
        </Fragment >
    }

}