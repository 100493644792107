/**
      *********************************************************
            Description: Date and Time component.  The value this component receives is in
            MM/DD/YYYY hh:mm:ss format which needs to be converted.Format required by the component is YYYY-MM-DDThh:mm. Moment is used to convert to this format.
            Required props:  type,value.
            limitations    : N/A
      *********************************************************
**/

import React, { Fragment, Component } from 'react';
import moment from 'moment';
import { DateTimePicker, KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        "& label": {
            color: "white"
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: "white"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "white"
        },
        "& .MuiInputBase-input": {
            color: "white",
            fontSize: 11
        },
        "& .MuiInputBase-root": {
            color: "white",
            fontSize: 11
        },
        "& .MuiIconButton-root": {
            color: "white",
        },
        "& .MuiFormLabel-root": {
            color: "white",
            fontSize: 11
        },
        "& .MuiSvgIcon-root": {
            color: "white",
            fontSize: 16
        },
        "& .MuiInput-formControl": {
            marginTop: 4
        }

    }
}));

export default function DateTimePickerComp(props) {
    const classes = useStyles();
    const { value, handleFieldValue, name, extraProps, shareProps, ...otherProps } = props
    const { label, xtype, fullWidth, tooltip, FieldProps, onAsOfDateChanged, HeaderFieldProps, ...otherConfigs } = otherProps;
    let val = ((value === null) || (value === undefined) || (value === '')) ? '' : value;
    val = val ? moment(val).format('MM/DD/YYYY hh:mm:ss') : '';
    const [range, setRange] = useState({})
    const [reload, setReload] = useState(false)

    let currentDate = false;
    let passDate = false;
    let futureDate = false;
    let fieldprops = null;
    let headerfieldprops = HeaderFieldProps || null;

    if (FieldProps) {
        fieldprops = JSON.parse(FieldProps)
        currentDate = fieldprops['CurrentDefaultDate'];
        if (currentDate && value === '') {
            val = new Date()
        }
        if (fieldprops['AllowDate'] === 'CD') {
            passDate = true;
            futureDate = true;
        }
        else if (fieldprops['AllowDate'] === 'PD') {
            passDate = false;
            futureDate = true;
        }
        else if (fieldprops['AllowDate'] === 'FD') {
            passDate = true;
            futureDate = false;
        }
    }
    else if (headerfieldprops) {
        if (headerfieldprops['AllowDate'] === 'PD') {
            passDate = false;
            futureDate = true;
        }
    }

    useEffect(() => {
        if (passDate) {
            range['maxDate'] = (val === '' ? new Date() : new Date(val))
            setRange(range)
            setReload(!reload)
        }
        else if (futureDate) {
            range['minDate'] = (val === '' ? new Date() : new Date(val));
            setRange(range)
            setReload(!reload)
        }
    }, [])

    let tlabel = tooltip ? <Fragment> {label} < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
        <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
    </Tooltip> </Fragment> : label;
    if(handleFieldValue === "hidedisplay") {
      return null
    }
    else {
    return (
        <div className={name}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDateTimePicker
                    cancelLabel="Cancel"
                    okLabel="OK"
                    disableToolbar
                    variant="dialog"
                    //color='inherit'
                    ampm={false}
                    value={((val === '') ? new Date() : val) || null} //currentDate
                    disablePast={passDate}
                    disableFuture={futureDate}
                    onChange={(e) => handleFieldValue(e)}
                    label={tlabel}
                    name={name}
                    {...range}
                    {...otherConfigs}
                    {...extraProps}
                    format="MM/DD/YYYY hh:mm:ss"
                    className={classes.root}
                />
            </MuiPickersUtilsProvider>
        </div>
    )
    }
}