/**
        *********************************************************
        Deprecated and the csheduler inbuild api might be wrong.
        *********************************************************
 **/

        import React, { Component } from 'react'
        //import Cron from 'react-cron-generator'
        import Cron from '../corn_generator'
        import 'react-cron-generator/dist/cron-builder.css'
        import FormHeader from '../form/cformheader';
        import { HashRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
        import { PostData, PutData, GetData } from '../../controls/helpers/requests';
        import { getEnvVariable } from '../../../modules/environmentalList';
        
        
        
        class CScheduler extends Component {
        
        
        
          constructor(props) {
            super(props);
            this.state = {
              results: {},
              value: null
            };
          }
        
          componentDidMount() {
            if (this.props.dataItem) {
              const url = getEnvVariable('Notification') + `/api/NotificationScheduling/Schedule/${this.props.dataItem.ID}`
              this.fetchData('GET', url, null, this.state.results).then(data => {
                this.setState({
                  value: data.ScheduledOn,
                  results: data
                })
              })
            }
          }
        
          fetchData(requestType, url, queryParam, body) {
            const queryStr = queryParam != null ? `${url}?${queryParam}` : `${url}`
        
            if (requestType == "GET") {
              return GetData(queryStr);
            }
            else if (requestType == "POST") {
              return PostData(queryStr, body);
            }
            else if (requestType == "PUT")
              return PutData(queryStr, body);
          }
        
        
          onhandleSave = (e) => {
            const url = getEnvVariable('Notification') + `/api/NotificationScheduling/SaveSchedule/${this.props.dataItem.ID}`
            this.fetchData('PUT', url, null, this.state.results).then(data => this.setState({
              value: data.ScheduledOn
            }))
          }
        
          handleChange = (e) => {
            this.setState({
              value: -1,
            }, () => {
              this.setState({ value: e, results: { ...this.state.results, ScheduledOn: e } }, () => {
                let event = { target: { value: this.state.value, name: this.props.name, checked: false }, currentTarget: { dataset: null } }
                this.props.handleFieldValue(event)
              })
            });
          }
        
          render() {
            const { options } = this.props
        
            return (<div className="row w-100 h-100">
              <div className="col-md-12">
                {options && options.headerProps && <FormHeader title={options && options.title || ''} handleSave={this.onhandleSave}
                  savebutton={options && options.headerProps.savebutton} designprops={true} />}
              </div>
              <div className="col-md-12">
                {(<Cron
                  onChange={this.handleChange}
                  value={this.state.value || this.props.value}
                />)}
              </div>
            </div>)
          }
        }
        
        export default withRouter(CScheduler);
