/**        
      *********************************************************
      Deprecated
      *********************************************************
**/
import React, { Component, Fragment } from 'react';
import EntranceImg from '../../resources/bannerA.jpg'

class Banner extends Component {
    
    render() {
        return (
            <div></div>
        )
    }

}

export default Banner;