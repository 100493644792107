import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        padding: '20px',
    },
})

const Minutes = (props) => {
    const [value, setValue] = useState(props.value)

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const onChange = (e) => {
        if ((e.target.value > 0 && e.target.value < 60) || e.target.value === '') {
            let val = ['*', '*', '*', '*', '*']

            if (e.target.value === '') {
                val[0] = '0/1';
            } else {
                val[0] = `0/${e.target.value}`;
            }

            props.onChange(val)
        }
    }

    const classes = props
    return (
        <Paper className={classes.root}>
            <div className="col-sm-2 m-2">
                <TextField               
                    label="Every minute(s)"
                    value={value[1].split('/')[1]} min={1} max={60}
                    onChange={e => onChange(e)}
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"                
                />
            </div>
        </Paper >

    )
}

export default withStyles(styles)(Minutes);
