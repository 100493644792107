import { getEnvVariable } from "../../modules/environmentalList"

export const AdminList = {
    'WRS': {
        "epsgeneral":
            [
                {
                    "type": "menu",
                    "title": "Statistics",
                    "icon": "poll",
                    "materialicon": "poll",
                    "link": "/systemstatistics",
                    "key": "Statistics",
                    "style": {
                        'color': '#A020F0'
                    }
                },
                {
                    "type": "menu",
                    "title": "Notifications",
                    "icon": "notifications_active",
                    "materialicon": "notifications_active",
                    "link": "notifications",
                    "key": "Notifications",
                    "style": {
                        'color': '#00bfa5'
                    }
                },
                {
                    "type": "menu",
                    "title": "Search",
                    "icon": "search",
                    "materialicon": "search",
                    "link": "/search",
                    "key": "Search",
                    "style": {
                        'color': '#61A5DC'
                    }
                },
                {
                    "type": "menu",
                    "title": "Records",
                    "key": "newapplicationdetails",
                    "link": "/Administration/applications",
                    "icon": "fa fa-file-text",
                    "materialicon": "new_releases",
                    "style": {
                        'color': '#9c27b0'
                    }
                },

                {
                    "type": "menu",
                    "title": "Reports",
                    "icon": "fa fa-files-o",
                    "link": getEnvVariable('ReportsUI'),
                    "key": "reports",
                    "newtab": true,
                    "materialicon": "post_add",
                    "style": {
                        'color': '#827717'
                    }
                },
                {
                    "type":"menu",
                    "title": "Authorization",
                    "key": "Authorization",
                    "link": "/Administration/authorizations",
                    "icon": "fa fa-globe",
                    "materialicon": "vpn_key",
                    "style": {
                        'color': '#c158dc'
                    }
                },
            ],
        "Adminlist":
            [
                {
                    "type": "menu",
                    "title": "Users",
                    "icon": "fa fa-users",
                    "link": "#/Administration/agencyusers",
                    "key": "AgencyUsers",
                    "materialicon": "person",
                    "style": {
                        'color': '#01579b'
                    }
                },
                {
                    "type": "menu",
                    "title": "Roles",
                    "key": "roles",
                    "link": "#/Administration/roles",
                    "icon": "fa fa-user-circle-o",
                    "materialicon": "manage_accounts",
                    "style": {
                        'color': '#004d40'
                    }
                },

                {
                    "type": "menu",
                    "title": "Groups",
                    "key": "groups",
                    "link": "#/Administration/groups",
                    "icon": "fa fa-users",
                    "materialicon": "device_hub",
                    "style": {
                        'color': '#3f51b5'
                    }
                },

                {
                    "type": "menu",
                    "title": "Regions",
                    "key": "regions",
                    "link": "#/Administration/regions",
                    "icon": "fa fa-map",
                    "materialicon": "streetview",
                    "style": {
                        'color': '#61A5DC'
                    }
                },

                // {
                //     "type": "menu",
                //     "title": "Districts",
                //     "key": "districts",
                //     "link": "#/Administration/districts",
                //     "icon": "fa fa-map",
                //     "materialicon": "map",
                //     "style": {
                //         'color': '#c158dc'
                //     }
                // },
                {
                    "type": "menu",
                    "title": "Counties",
                    "key": "sites",
                    "link": "#/Administration/counties",
                    "icon": "fa fa-globe",
                    "materialicon": "settings_system_daydream",
                    "style": {
                        'color': '#006064'
                    }
                },
                // {
                //     "type": "menu",
                //     "title": "Domains",
                //     "key": "domains",
                //     "link": "#/Administration/domains",
                //     "icon": "fa fa-domain",
                //     "materialicon": "domain",
                //     "style": {
                //         'color': '#a98274'
                //     }
                // },
                {
                    "type":"menu",
                    "title": "Holidays",
                    "key": "Holidays",
                    "link": "#/Administration/holidays",
                    "icon": "fa fa-globe",
                    "materialicon": "today",
                    "style": {
                        'color': '#c158dc'
                    }
                },
                // {
                //     "type": "menu",
                //     "title": "Email Groups",
                //     "key": "groupemails",
                //     "link": "#/Administration/groupemails",
                //     "icon": "fa fa-domain",
                //     "materialicon": "email",
                //     "style": {
                //         'color': '#0c755b'
                //     }
                // },
               // ,
                {
                    "type": "menu",
                    "title": "Contractors",
                    "icon": "fa fa-building",
                    "link": "#/Administration/companies",
                    "key": "companies",
                    "materialicon": "apartment",
                    "style": {
                        'color': '#ea156d'
                    }
                },
                // {
                //     "type": "menu",
                //     "title": "Consultants",
                //     "icon": "fa fa-building",
                //     "link": "/Administration/consultants",
                //     "key": "consultants",
                //     "materialicon": "connect_without_contact",
                //     "style": {
                //         'color': '#cddc39'
                //     }
                // },
                // {
                //     "type": "menu",
                //     "title": "Registrations",
                //     "key": "registrationrequest",
                //     "link": "#/Administration/registrationrequest",
                //     "icon": "fa fa-caret-square-o-right",
                //     "materialicon": "how_to_vote",
                //     "style": {
                //         'color': '#9fa8da'
                //     }
                // },
            ],

    },


}

