/**
      *********************************************************
      Currently not used anywhere.
      *********************************************************
**/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = {
    card: {

    }
};

class CSectionCard extends Component {

    passPropsToOtherFields = (values, name) => {
        const { options } = this.props
        const { forwardProps } = options
    }
    render() {
        const { classes } = this.props;
        const { components, options, compmap } = this.props
        const { forwardProps } = options
        return (
            <Card className={classes.card}>
                <CardContent>
                    <div className="row">
                        {components.map((each, index) => {
                            const { type, key, options, name } = each
                            const { width, sendProps, receiveProps } = options
                            const EachComp = compmap[type]
                            if (sendProps && receiveProps) {
                                return <div className={'col-' + width} key={key}>
                                    <EachComp compmap={compmap} key={key} {...each} passPropsToOtherFields={this.passPropsToOtherFields} receiveProps={this.state[name].receiveProps} />
                                </div>
                            }
                            if (sendProps) {
                                return <div className={'col-' + width} key={key}>
                                    <EachComp compmap={compmap} key={key} {...each} passPropsToOtherFields={this.passPropsToOtherFields} />
                                </div>
                            }
                            if (receiveProps) {
                                return <div className={'col-' + width} key={key}>
                                    <EachComp compmap={compmap} key={key} {...each} receiveProps={this.state[name].receiveProps} />
                                </div>
                            }

                        })}
                    </div>
                </CardContent>
                {/* <CardActions>
                    <Button size="small" color="primary">
                        Share
              </Button>
                    <Button size="small" color="primary">
                        Learn More
              </Button>
                </CardActions> */}
            </Card>
        );
    }

}

CSectionCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CSectionCard);