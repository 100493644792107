import MomentUtils from '@date-io/moment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import DefaultSettings from './settings';


export default function DatePicker(props) {
    const { name, options, value, handleFieldValue, edit, required, disabled, extraProps, error, helperText,contexthelp, ...otherProps } = props
    const { label, xtype, fullWidth, tooltip, FieldProps, ...otherConfigs } = otherProps;
    let val = ((value === null) || (value === undefined) || (value === '')) ? '' : value;
    const [range, setRange] = useState({})
    const [reload, setReload] = useState(false)
    let dateFormat = extraProps && extraProps.dateFormat ? extraProps.dateFormat : "MM/DD/YYYY hh:mm a"
    val = val ? moment(val).format("MM/DD/YYYY hh:mm a") : '';
    let currentDate = 'N';
    let pastDate = false;
    let futureDate = false;
    let fieldprops = null;
    if (FieldProps) {
        fieldprops = JSON.parse(FieldProps)
        currentDate = fieldprops['CurrentDefaultDate'];
        if (fieldprops['AllowDate'] === 'CD') {
            pastDate = true;
            futureDate = true;
        }
        else if (fieldprops['AllowDate'] === 'PD') {
            pastDate = true;
            futureDate = false;
        }
        else if (fieldprops['AllowDate'] === 'FD') {
            pastDate = false;
            futureDate = true;
        }
        if (currentDate === 'Y' && val === '') {
            let convertMoment = moment(new Date(), 'MM/DD/YYYY hh:mm:ss a').format('MM/DD/YYYY hh:mm:ss a')
            let events = { target: { value: convertMoment, name: name, checked: false }, currentTarget: { dataset: null } }
            handleFieldValue(events);
        }
    }
    useEffect(() => {
        if (pastDate && futureDate) {
            range['maxDate'] = (val === '' ? new Date() : new Date(val))
            range['minDate'] = (val === '' ? new Date() : new Date(val));
            setRange(range)
            setReload(!reload)
        }
        else if (pastDate) {
            range['maxDate'] = (val === '' ? new Date() : new Date(val))
            setRange(range)
            setReload(!reload)
        }
        else if (futureDate) {
            range['minDate'] = (val === '' ? new Date() : new Date(val));
            setRange(range)
            setReload(!reload)
        }
    }, [])


    return (
        <Fragment>
            {reload}
            <FormControl className={name} fullWidth={fullWidth ? fullWidth : true} error={error} required={required} disabled={disabled || false}  >
            <InputLabel style={{ fontWeight: 'bold', color: error ? 'red' : 'black' }} shrink> {label}&nbsp; {tooltip && <Tooltip title={tooltip ? tooltip : null}
                    placement="bottom-start" ><HelpOutlineIcon fontSize="small" style={{ width: '0.8em',color: '#007bff' }} onClick={(evt) => contexthelp ?
                        DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                </Tooltip>} </InputLabel>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                    disabled={disabled || false}
                        style={{ paddingTop:'8px' }}
                        clearable
                        variant="filled"
                        ampm={false}
                        value={((currentDate == 'Y') ? new Date() : (val === '' ? null : val)) || null}
                        {...range}
                        onChange={(e) => handleFieldValue({ target: { value: e ? moment(e, dateFormat).format(dateFormat) : null, name: name, checked: false, }, currentTarget: { dataset: null } })}
                        name={name}
                        {...otherConfigs}
                        format={dateFormat}
                        InputLabelProps={{
                            shrink: true,

                        }}
                    />
                </MuiPickersUtilsProvider>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </Fragment>
    );
}