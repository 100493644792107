//import adotbackground from '../images/adotlogo.png';
import adotbackground from '../images/Secondary_transparent.png';
import delasoftlogo from '../images/Delasoftlogo.jpg';
import oregonlogo from '../images/odoticon.png';
import kytclogo from '../images/kytcicon.jpg';
import adotavatar from '../images/adotavatar.png';
import wisconsin from '../images/wisdot.png';
import wisdot from '../images/wisdot.png';
import DeldotLogo from '../images/deldot.png';
import { getEnvVariable } from '../../../../modules/environmentalList';

export const getLandingPagelogo = (open = true) => {
    let right = (localStorage.getItem('internaltoken') || localStorage.getItem('externaltoken')) ? RightDrawerAvatar() : false
    let key = getEnvVariable('ModuleKey')
    switch (key) {
        case 'KEPT':
            return kytclogo
        case 'AMES':
            return oregonlogo
        case 'MPS':
            return delasoftlogo
        case 'AEPS':
            return (right && open) ? adotbackground : !open ? adotavatar : adotbackground
        case 'WRS':
            return wisconsin
        case 'FORWARD':
                return wisdot
        case 'UPS':
            return DeldotLogo
        default: return adotbackground
    }
}


export const RightDrawerAvatar = () => {
    let key = getEnvVariable('ModuleKey')
    switch (key) {
        case 'AEPS':
        case 'WRS':
            return true
        default: return false
    }
}

//Condition for Hiding Modules for External
export const hideForSubmodules = (key) => {
    switch (key) {
        case 'APPLICATIONS':
            return true
        case 'VIOLATIONS':
            return true
        default: return false
    }
}