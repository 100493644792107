import React from 'react';
import Autocompletetags from '../../controls/components/custom/autocompletetagsnotification'
import { requestApi } from '../form/utilities'
import FormDropdown from '../form/cformdropdown'
import { getEnvVariable } from '../../../modules/environmentalList';

const transformprops = {
    name: 'Name',
    value: 'Id'
}

const sharedtooptions = [
    {
        name: 'None',
        value: 'N',
        key: 'self'
    },
    {
        name: 'All Internal Users',
        value: 'P',
        key: 'public'
    },
    // {
    //     name: 'Roles',
    //     value: 'R',
    //     key: 'roles'
    // }
]

class ShareOptions extends React.Component {

    constructor(props) {
        super(props);
        this.LayoutForm = React.createRef()
    }
    state = {
       SharedTo:'P',
       SharedToRoles:'',
       rolesoptions:[],
       rolesoptionsselected:[],
       reportDetails:{}
    };

    componentDidMount() {
        console.log(this.props.gridkey)
        this.getRoles()
        this.getReportDetails();
    }

    getRoles(){
        let reader =  {"get": { "url": getEnvVariable('Admin')+`/api/Roles/All`, "routeProps": {} } }
        requestApi(reader).then(res => {
            if(res && res.length > 0){
                console.log(res);
                let roles = res.map(a => {return {id: a.Id.toString(), name: a.Name}})
                this.setState({ rolesoptions: roles })
            }
        })
    }

    getReportDetails(){
        let reader =  {"get": { "url": getEnvVariable('Report')+`/api/Reports/Report?id=${this.props.id}`, "routeProps": {} } }
        requestApi(reader).then(res => {
            if(res){
                console.log(res);
                this.setState({ reportDetails: res, SharedTo: res.SharedTo == 'N' ? 'P' : res.SharedTo, SharedToRoles: res.SharedToRoles })

            }
        })
    }


    autcompletetagshelproles = {
        "options": {
            "label": "Select role(s)",
            "placeholder": "",
            "row": 0,
            "col": 0,
            "width": 12,
            "name": "SharedToRoles",
            "itemname": "roleId",
            disabled: false,
            conditions: {
            },
            "defaultSelected": true
        }
    }

   tagsChange = (tags) => {
        console.log(tags)
        let resultdata = tags.target.value.map(a => a.id);
        this.setState({
            SharedToRoles: [...new Set(resultdata)].join()
        })
    }

    handleFieldChange = (evt) =>{
        let val = evt.target.value;
        if(val != 'R')
            this.setState({SharedTo : val, SharedToRoles:''})
        else
            this.setState({ SharedTo : val})
    }

    getShareOptions(){
        let reportDetails = this.state.reportDetails;
        reportDetails.SharedTo = this.state.SharedTo;
        reportDetails.SharedToRoles = this.state.SharedToRoles;
        return reportDetails
    }

    render() {
        this.autcompletetagshelproles.options.disabled = (this.state.SharedTo != 'R')
        return (
            <div>
                <FormDropdown dropdownvalues={sharedtooptions}
                   // transformProps={transformprops}
                    name='SharedTo' label='Shared With'
                    value={this.state.SharedTo}
                    handleFieldChange={this.handleFieldChange}
                />

                {/* {this.state.SharedTo == 'R' && <Autocompletetags className="pt-2" label="Role(s)"
                                name="SharedToRoles"
                                dropdownvalues={this.state.rolesoptions}
                                {...this.autcompletetagshelproles}
                                handleFieldChange={this.tagsChange}
<<<<<<< HEAD
                                defaultSelValue={this.state.SharedToRoles}
                            />}
=======
                                defaultSelValue={this.state.SharedToRoles}
                            />} */}

            </div>
        );
    }
}

// const mapActions = dispatch => {
//     return bindActionCreators({ snackbarMessage }, dispatch);
// }

// ShareOptions = connect(null, mapActions)(ShareOptions)
export default ShareOptions;
