import React, { Fragment, useEffect, useState } from 'react';
import MultiAdd from '../../core/controls/components/multiadd';
import { createRequest, requestApi } from '../../core/controls/helpers/rest';
import { getEnvVariable } from '../environmentalList';
import FormHeader from '../../core/modules/form/cformheader';
const extraProps = {
    transformProps: {
        label: 'Name',
        value: 'Value'
    },
}

const breadcrumbs = [
    { "link": "User - Selected Associates", "materialicon": "group_add", className: 'eps-breadcrumb-item ', class: 'text-light' }
]

export default function Consultant(props) {
    const [selected, SetSelectedCom] = useState([])

    useEffect(() => {
        getAll()
    }, [])

    const getAll = () => {
        const dropLists = [
            {
                url: getEnvVariable('MasterAdmin') + `/allAssociated?consulatantid=${props.dataItem.UserId}`,//${company.OfficalUserId}`,
                type: 'get'
            },
        ]
        Promise.all(dropLists.map(each => requestApi(createRequest(each, {}, null)).catch(error => { return error; }))).then(response => {
            const [companies] = response
            SetSelectedCom(companies)
        })
    }

    const handleUpdate = (newData) => { //${company.OfficalUserId}
        const update = [
            {
                url: getEnvVariable('MasterAdmin') + `/api/ExternalUsersConsultant/StatusUpdateForInternal/${getEnvVariable('ModuleKey')}/${newData.Companies.Id}/${newData.UserId}/${newData.RequestStatus}`,
                type: 'get',
            }
        ]
        Promise.all(update.map(each => requestApi(createRequest(each, {}, null)).catch(error => { return error; }))).then(response => {
            if (response)
                getAll()
        })

    }


    const handleBack = () => {
        // let path = '/Administration/registrationrequest'
        // props.history.push(path)
        props.onClose()
    }

    return <Fragment >
        <FormHeader savebutton={false} editbutton={false} breadcrumbs={breadcrumbs} backbutton={true} handleBack={handleBack} dataItem={props.dataItem} />
        <div className='col-sm-12 text-dark mx-4 my-5'>
            <MultiAdd
                edit={getEnvVariable('AccessPortal') === 'I' ? true : false}
                customsave={true}
                toolbar={false}
                //Urls
                handleUpdate={handleUpdate}
                columns={[
                    { title: 'Name', field: 'Companies.Name', editable: 'never' },
                    {
                        title: 'Request Status',
                        field: 'RequestStatus',
                        lookup: { 'A': 'Approved', 'D': 'Denied', 'P': 'Pending' },
                    },
                ]}

                value={selected}

            />
            {/* <div className='row '>
            <div className='col-sm-11'>     <RadioButtonsGroup handleFieldValue={(e) => handleAllChange(e)} extraProps={extraProps} items={[{ Name: 'Approve All', Value: 'Y' },
            { Name: 'Deny All', Value: 'N' }]} /></div>
            <div className="col-sm-1 float-right" > <Fab size="small" color='secondary' aria-label="save" onClick={handleSave}>
                <SaveIcon />
            </Fab></div>
        </div>
        {selected.map(each => {
            return <RadioButtonsGroup label={<u className="text-dark fw-bold">{'Applicant : ' + each.Name}</u>} handleFieldValue={(e) => handleChange(e, each)} items={status} extraProps={extraProps} />
        })} */}
        </div>
    </Fragment>
}