import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CancelIcon from '@material-ui/icons/Cancel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FilterListIcon from '@material-ui/icons/ClearAll';
import CloseIcon from '@material-ui/icons/Close';
import SaveReportIcon from '@material-ui/icons/Description';
import DraftsIcon from '@material-ui/icons/Drafts';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ForwardIcon from '@material-ui/icons/Forward';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import GetAppIcon from '@material-ui/icons/GetApp';
import TourIcon from '@material-ui/icons/LocalLibraryTwoTone';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import RefreshIcon from '@material-ui/icons/Refresh';
import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';
import SaveIcon from '@material-ui/icons/Save';
import ExportExcel from '@material-ui/icons/SaveAlt';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import React from "react";
import { useDispatch } from "react-redux";
import { showTour } from '../../actions';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import MenuIconOpen from '@material-ui/icons/Menu';
import CallSplit from '@material-ui/icons/CallSplit';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import HistoryIcon from '@material-ui/icons/History';
import DoneAll from '@material-ui/icons/DoneAll';
import CheckBox from '@material-ui/icons/CheckBox';
import History from './history';
import MissingItemsDrawer from './custom/missinglists';
import RulesSnapShot from './rulesSnapshot';
import Preview from '@material-ui/icons/Visibility';
import QuickView from './quickview';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { getEnvVariable } from '../../../modules/environmentalList';
//import Filter from '@material-ui/icons/FilterList';
const _ = require('lodash');

const fieldTypes = {
    edit: EditIcon,
    add: AddIcon,
    save: SaveIcon,
    close: CloseIcon,
    back: ChevronLeftIcon,
    duplicate: FileCopyIcon,
    cloneitems: DynamicFeedIcon,
    download: GetAppIcon,
    refresh: RefreshIcon,
    lock: LockIcon,
    unlock: UnlockIcon,
    reports: SaveReportIcon,
    excel: ExportExcel,
    clear: FilterListIcon,
    submit: ForwardIcon,
    markread: DraftsIcon,
    markunread: MarkunreadIcon,
    cancel: CancelIcon,
    previtem: ArrowUpwardIcon,
    nextitem: ArrowDownwardIcon,
    fullscreen: FullscreenIcon,
    exitfullscreen: FullscreenExitIcon,
    tour: TourIcon,
    downloadpermit: SystemUpdateAltIcon,
    withdraw: AssignmentReturnIcon,
    moreitems: MenuIconOpen,
    call_split: CallSplit,
    pause: PauseCircleFilledIcon,
    history: HistoryIcon,
    check_box: CheckBox,
    doneall: DoneAll,
    preview: Preview,
    quickview: QuickView,
    delete: DeleteForeverIcon
    //filter: Filter
}

export default function Icons(props) {
    const { icon, type, title, color, hidevalue, menudisplay, sharedProps, name, showon, hideon, hideanother, showOnly, historyurl, fieldValues, hideByValues,
        urlparams, fieldProps, ...otherProps } = props
    const EachComp = fieldTypes[icon]
    const dispatch = useDispatch();
    
    const getColor = () => {
        let module = getEnvVariable('ModuleKey')
        switch (module) {
            case 'AEPS':
                return 'rgba(0, 53, 95, 255)'
            case 'AMES':
                return '#1976d2'
            default:
                return '';
        }
    }
    const Conditions = () => {
        if (hideByValues && hideByValues.length > 0 || showOnly) {
            let hideResult = hideByValues.find(each => {
                if (each['condition']) {
                    if (each['value'] && sharedProps[each['name']] && sharedProps[each['name']].includes(each['value']))
                        return each
                }
                else if (each['value'] == sharedProps[each['name']])
                    return each
            })
            if (hideResult && showOnly) {
                let profile = JSON.parse(localStorage.getItem('profile'))
                //  let role = JSON.parse(localStorage.getItem('roles')) && JSON.parse(localStorage.getItem('roles')).find(each => each.RoleType === showOnly)
                let isRoleBased = ((profile && profile.modulePermissions && profile.modulePermissions.length > 0 &&
                    profile.modulePermissions.find(each => each.roleTypeName === 'RAME')) || (profile && profile.isAdmin === 'Y')) //role.Id.toString()
                return isRoleBased ? ((sharedProps && (sharedProps[hideon] === hidevalue || sharedProps[hideanother] === hidevalue)) ? true : false) : hideResult

            }
            else return hideResult ? true : false;
        }

        else if (((name === 'fullscreen' || name === 'exitfullscreen') && (sharedProps && (sharedProps[name] === true))) ||
            (sharedProps && sharedProps.allgriddata && (name === 'nextitem' || name === 'previtem') && (_.isEmpty(sharedProps.allgriddata))) ||
            (otherProps.onClick === "hidedisplay" || (sharedProps && (sharedProps[hideon] === hidevalue || sharedProps[hideanother] === hidevalue))))
            return true
        else return false
    }
    if (Conditions())
        return null
    else if (name === 'tour') {
        return <i className={name} onClick={() => { dispatch(showTour(true)) }}><IconButton title={title ? title : ''} color={color ? color : "inherit"}> <EachComp />  </IconButton ></i>
    }
    else if (name === 'history') {
        return <History url={historyurl} fieldValues={fieldValues} sharedProps={sharedProps} />
    }
    else if (name === 'required') {
        return <MissingItemsDrawer fieldValues={fieldValues} urlparams={urlparams} label={title} />
    }
    else if (name === 'dcrequired') {
        return <MissingItemsDrawer fieldProps={fieldProps} fieldValues={fieldValues} urlparams={urlparams} label={title} name={name} />
    }
    else if (name === 'rulessnapshot') {
        return <RulesSnapShot fieldValues={fieldValues} fieldProps={fieldProps} />
    }
    else if (menudisplay) {
        return <EachComp className={name} style={{ color: color || "inherit" }} />
    }
    else if (name === 'quickview') {
        return <QuickView fieldValues={fieldValues} sharedProps={sharedProps} />
    }
    else return <i className={name + ' zoom mx-2'} {...otherProps}><IconButton style={{ backgroundColor: 'white', color: color || getColor() }} title={title ? title : ''} >
        <EachComp fontSize="medium" />
    </IconButton></i>
}

