import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
//import { makeStyles } from '@material-ui/styles'
import StartTime from './chooseTime'
import Card from './card'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        padding: '20px',
    },
    secondaryPaper: {
        padding: '20px',
        marginTop: '20px'
    },
    radioBtn: {
        marginTop: '20px'
    },
    endTimeSelect: {
        marginLeft: '10px'
    }
})

const Hourly = (props) => {
    const [value, setValue] = useState(props.value)

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    // useEffect(() => {
    //     const value = props.value;
    // }, [])

    const onAtHourChange = (e) => {
        let val = [value[0], '*', '1/1', '*', '*']
        val[1] = `0/${e.target.value}`;
        props.onChange(val)
    }
    const onAtMinuteChange = (e) => {
        let val = ['*', value[1], '1/1', '*', '*']
        val[0] = `${e.target.value}`;
        props.onChange(val)
    }

    const classes = props;
    return (
        <Paper className={classes.root}>
            <div className="col">
                <StartTime hour={value[1].split('/')[1] ? value[1].split('/')[1] : ''} minute={value[0]} onAtMinuteChange={onAtMinuteChange} onAtHourChange={onAtHourChange} />
            </div>
        </Paper>
    )
}

export default withStyles(styles)(Hourly) 
