/**
     *********************************************************
     Description: Creates drawer on the left side. Needs to be modified to make it generic.
     Required props:  Used internally by other components.
     limitations    : N/A
     *********************************************************
**/
import { Divider, Icon, Tooltip } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Link, withRouter } from "react-router-dom";
import { handleRules } from '../../core/controls/effects/effects';

const styles = theme => ({
    paperAnchorRight: {
        top: 'auto',
        maxHeight: '100%',
    },
    root: {
        width: 300
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

class LeftSideBar extends Component {

    state = {
        open: false,
        value: 0,
    }
    /**
      *********************************************************
      Description: renders the sidebar with icons. Each icon is a link which when clicked changes the route
      Required props:  N/A.
      limitations    : N/A
      *********************************************************
    **/

    componentDidMount() {
        this.setState({ open: this.props.open })
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.open !== prevProps.open) {
            this.setState({ open: this.props.open })
        }
        if ((this.props && this.props.match && this.props.match.params && prevProps.match && prevProps.match.params) && (this.props.match.params.sidebar !== prevProps.match.params.sidebar)) {
            this.handleClose();
        }
    }
    handleOpen = () => {
        this.setState({ open: true })
    };

    handleClose = () => {
        let e = {}
        this.props.handleSidebar(e, false)
        this.setState({ open: false });

    };

    handleChange = () => {

    }

    sidebarList = () => {
        const { options, fieldValues } = this.props
        const { items } = options
        let pathcontains = this.props && this.props.match && this.props.match.url ? this.props.match.url.substring(this.props.match.url.lastIndexOf('/') + 1) : ""
        let displayOrientation = options && options.orientation ? options.orientation : 'horizontal'
        return (
            items && items.map((each, idx) => {
                const { key, link, hidecondition, check } = each
                let path = ''
                let condition = null;
                if (check) {
                    condition = !handleRules(check, fieldValues)
                }
                else if (hidecondition) {
                    hidecondition.forEach(element => {
                        const { Name, Value, prevcondition, getfrom, notEquals } = element;

                        let getdatafrom = getfrom === "localstorage" ? 'localstorage' : getfrom === "requestdata" ?
                            ['reqdata'] : getfrom === "dataItem" ? this.props.dataItem : getfrom === "params" && this.props &&
                                this.props.match && this.props.match.params ? this.props.match.params : this.props
                        if (getdatafrom) {
                            condition = prevcondition && prevcondition === 'or' && condition === true ? true :
                                prevcondition === 'and' && condition === false ? false : (notEquals ? (getfrom === "localstorage" ? localStorage.getItem(Name) !== Value :
                                    getdatafrom[Name] !== Value) : (getfrom === "localstorage" ? localStorage.getItem(Name) === Value : getdatafrom[Name] === Value)) ? true : false
                        }
                    });
                }
                if (condition) {
                    return null
                }
                else if (link) {
                    if (Array.isArray(link)) {
                        link.forEach(e => {
                            const { link, type, routes, linkParams, params } = e
                            if (Array.isArray(routes)) {
                                routes.forEach(each => {
                                    const { route, routeParams, matchProps } = each
                                    if (routeParams) {
                                        path += route + "/" + this.props.match.params[routeParams]
                                    }
                                    else if (matchProps) {
                                        path += route + "/" + this.props.results[matchProps]
                                    }
                                    else {
                                        path += route
                                    }
                                })
                            }
                            else {
                                if (linkParams) path += link + this.props.match.params[linkParams]
                                else if (params) path += link + this.props[params]
                                else path += link
                            }

                        })
                    }
                    else path = link
                    return <div className={each.hide ? this.props.hide ? 'd-none' : 'd-block' : 'd-block'} key={idx}><Tooltip title={each.title} placement="top">
                        <Link key={key} to={path} >
                            <ListItem button style={{ backgroundColor: (key === pathcontains) ? "#d8e7f3" : "#ffffff" }}>
                                < Icon fontSize="small" style={each.style}>{each.materialicon}</Icon>
                                {(key === pathcontains) && <Typography variant="caption" display="block" style={{ color: "#0076A5" }} className="pl-1 pt-1" gutterBottom>
                                    <b>{each.title}</b>
                                </Typography>}
                                {(key !== pathcontains) && <Typography variant="caption" display="block" style={{ color: "#000000" }} className="pl-1 pt-1" gutterBottom>
                                    {each.title}
                                </Typography>}
                            </ListItem>
                            {displayOrientation === 'vertical' && <Divider />}
                        </Link>
                    </Tooltip>
                    </div>
                }
            })
        )
    }


    render() {
        const { classes, options, } = this.props
        const { open } = this.state
        let displayOrientation = options && options.orientation ? options.orientation : 'horizontal'
        return (
            <Fragment>
                {displayOrientation === 'horizontal' && <AppBar position="static" color="#808080" className='col-sm-12'>
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="white"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {this.sidebarList()}
                    </Tabs>
                </AppBar>}
                {displayOrientation === 'vertical' && <div className="eps-application-drawer">
                    <Drawer
                        open={open}
                        anchor="right"
                        classes={{
                            paperAnchorRight: classes.paperAnchorRight,
                            root: classes.root
                        }}
                        onClose={this.handleClose}
                    >
                        {this.sidebarList()}
                    </Drawer></div>}
            </Fragment>
        )
    }

}

export default withStyles(styles)(withRouter(LeftSideBar));