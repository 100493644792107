import { loadModules } from 'esri-loader';
import { QueryTask, CenterAndZoom, setCenterforMapviewwithFlag } from '../util/queryTask';
import { GeometryEngineTask } from '../util/geometryEngineTask';

export function TaxParcelCenter(view, config, queryexpression, mapConstants, zoom, isReset = false) {
    return new Promise((resolve, reject) => {
        if (!loadModules) {
            reject('TaxParcelTask: ArcGIS API is not loaded');
            return;
        }
        const TaxParcelzoom = zoom;
        if (config && config.TaxParcels) {
            config.TaxParcels.queryExpression = queryexpression

            if (config.TaxParcels.queryExpression == null || config.TaxParcels.queryExpression == "" || config.TaxParcels.queryExpression == "1!=1") {
                resolve({ "Message": "Invalid Expression" });
            } else {
                if (queryexpression != null && queryexpression != "") {
                    if (queryexpression != undefined && queryexpression != null && queryexpression != "") {
                        let parcelIdarra = queryexpression.split();
                        let geometries = [];      
                        let queryTaxlotsString = ''
                        for(var i =0; i<parcelIdarra.length; i++ ){
                            if(queryTaxlotsString === ""){
                                if(parcelIdarra[i] && parcelIdarra[i] != null)
                                    queryTaxlotsString = "'"+parcelIdarra[i]+"'";
                            }else{
                                if(parcelIdarra[i] && parcelIdarra[i] != null)
                                    queryTaxlotsString = queryTaxlotsString + ",'"+parcelIdarra[i]+"'";
                            }                    
                        }
                        let querycondition = "PIN in (" + queryTaxlotsString + ")";


                            
                            let layer = view.map.layers.find(function (layer) {
                                return layer.title === "Tax Parcel Graphics Layer";
                            });

                            QueryTask(config.TaxParcels, querycondition, view.spatialReference).then(
                                response => {
                                    if (response && response.features) {
                                        loadModules([
                                            "esri/Graphic",
                                            "esri/layers/GraphicsLayer"
                                        ]).then(([Graphic, GraphicsLayer]) => {

                                            if (!layer) {
                                                layer = new GraphicsLayer({
                                                    "title": "Tax Parcel Graphics Layer", listMode: 'hide'
                                                });
                                            }                                            

                                                response.features.forEach(function (item) {
                                                    if(item && item.attributes.PIN===parcelIdarra[i]){                                                           
                                                        geometries.push(item.geometry);
                                                    }
                                                });
                                            if (geometries.length > 0) {
                                                if (geometries.length > 1) {
                                                    GeometryEngineTask({ "geometries": geometries, "type": "union" }).then(
                                                        GeomEngineresponse => {
                                                            if (GeomEngineresponse && GeomEngineresponse.centroid)
                                                                CenterAndZoom(view, GeomEngineresponse.centroid, TaxParcelzoom);
                                                        },
                                                        GeomEngineerror => {
                                                         //   console.log(GeomEngineerror);
                                                        });
                                                } else if (geometries.length === 1) {
                                                    CenterAndZoom(view, geometries[0], TaxParcelzoom);
                                                }
                                            }                                              
                                            resolve({ "Message": geometries.length + " Tax Parcels centered" });
                                        });
                                    }
                                    else {
                                        resolve({ "Message": "Tax Parcels not found" });
                                    }
                                },
                                error => {
                                    console.log(error);
                                    reject({ "error": error });
                                }
                            );
                      

                    }

                }

            }
        }
    });
};

