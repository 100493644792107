import { getEnvVariable } from '../../../modules/environmentalList';
import newsavedreportstypes from '../../reports/savedreports';
const attachmenthistorygrid = {
    "type": "grid",
    "key": "attachmentshistorygrid",
    "gridkey": 'attachmentshistorygrid',
    "order": 1,
    "options": {
        sidepopupgridheight:true,
        "stopRowClick": true,
        "routes": [
            {
                "route": "/Administration/applicationreview",
                "routeParams": ":routeid",
            },
            {
                "route": "",
                "routeParams": ":routeid1"
            },
            {
                "route": "/attachments",
                "routeParams": ":routeid2"
            },
            {
                "route": "/history"
            }
        ],
        "crud": {
            "update": {
                "link": {
                    // "link": "/Administration/notes",
                    // "matchProps": {
                    //     "value": "Id"
                    // }
                }
            },
            "read": {
                "get": {
                    "url": getEnvVariable('Application')+`/api/${getEnvVariable('ModuleKey')}Attachment/history/{Id}`,
                    "customProps": [{
                        "custom": "Id"
                    }]
                }
            }
        },
        "back": {

            "routes": [
                {
                    "route": "/Administration/applicationreview",
                    "routeParams": "routeid",
                },
                {
                    "route": "",
                    "routeParams": "routeid1"
                },
                {
                    "route": "/attachments/view",
                    "routeParams": "routeid2"
                },
            ],
        },
        "headerProps": {
            designprops:true,
            "title": "attachment",
            "breadcrumbs": [
                { "link": "Attachment History", "materialicon": "history", className: 'eps-breadcrumb-item ', className: 'breadcrumb-item text-dark', class: 'text-dark' }
            ]
        },
        savedreports: {
            ...newsavedreportstypes
        },
        "gridcolumns": [
            {
                "title": "Field Name",
                "field": "FieldLabel",
                "show": true,
                width:185
            },
            {
                "title": "Old Value",
                "field": "OldValue",
                "show": true,
                width:185
            },
            {
                "title": "New Value",
                "field": "NewValue",
                "show": true,
                width:185
            },
            {
                "title": "Updated By",
                "field": "CreatedByName",
                "show": true,
                width:185
            },
            {
                "title": "Updated On",
                "field": "CreatedOn",
                "show": true,
                width:185
            },
        ]
    }
}
export default attachmenthistorygrid