import moment from 'moment';
import DefaultSettings from '../components/settings';
import { createGridRequest, createRequest, requestApi } from '../helpers/rest';
import { validation as validationConverter } from '../wrappers/helpers/converters';
import { DynamicEvaluate, Evaluate } from './calculate';
import handleEvents from './events';
const _ = require('lodash');
const validityStatus = {
    'true': 'valid',
    'false': 'inValid'
}

const prevalidityStatus = {
    'true': 'V',
    'false': 'I'
}

let currentrefIds = []

export const handleActionsToEffects = ({ fieldValues, conditions, global, extras, onEvent = {}, info = null, kendoProps, name = null, EventType = 'OC' }) => {
    localStorage.removeItem('onEventRules')

    if (global) {
        return handleGlobalRules(fieldValues, conditions, global, extras, onEvent, info, kendoProps, name, EventType)
    }
    else {
        if (conditions) {
            const { rules, triggerRules } = conditions
            const temp = rules && rules.reduce((accum, eachCondition) => {
                let { effects } = handleConditon(eachCondition, fieldValues, extras, triggerRules, onEvent, info, kendoProps, name, EventType)
                return accum.concat(effects)
            }, [])
            return temp
        }
    }
}

export const handleGlobalRules = (fieldValues, conditions, global, extras, onEvent = {}, info = null, kendoProps, name, EventType) => {
    const { rules, triggerRules } = conditions
    const globalEffects = global && global.rules && global.rules.map(eachCondition => {
        return handleConditon(eachCondition, fieldValues, extras, global.triggerRules, onEvent, info, kendoProps, name, EventType)
    }) || []

    //remove duplicate field changes based on priority and convert to an object
    const globalFieldChanges = removeDuplicateEffects(globalEffects)

    const individualEffects = rules && rules.reduce((accum, eachCondition) => {
        let { canOverwrite, effects = [], namehandle } = handleConditon(eachCondition, fieldValues, extras, triggerRules, onEvent, info, kendoProps, name, EventType)
        if (canOverwrite) {
            effects.forEach((e, i) => {
                const { name, type } = e
                if (!accum[name]) {
                    accum[name ? name : (namehandle && namehandle.length > 0 && namehandle[i] || name)] = e
                }
            })
        }
        else {
            effects.forEach(e => {
                const { name, type } = e
                if (!accum[name]) {
                    if (globalFieldChanges[name]) accum[name] = globalFieldChanges[name]
                    else { accum[name] = e }
                }
            })
        }
        return accum
    }, {})

    let onEventEffects = {}
    if (localStorage.getItem('onEventRules'))
        onEventEffects = removeDuplicateEffects(JSON.parse(localStorage.getItem('onEventRules')) || [])
    const alleffects = { ...individualEffects, ...onEventEffects }
    let effect = Object.keys(alleffects).map(each => alleffects[each])
    let globaleffect = Object.keys(globalFieldChanges).map(each => globalFieldChanges[each])
    return [...effect, ...globaleffect]
}

export const removeDuplicateEffects = (alleffects) => {
    if (alleffects)
        return alleffects.reduce((accum, each) => {
            if (each) {
                const { effects = [] } = each
                effects.forEach(e => {
                    if (e) {
                        const { name, type } = e
                        //handles Priority
                        if (!accum[name]) accum[name] = e
                    }
                })
            }
            return accum
        }, {})
}


export const handleConditon = (eachCondition, fieldValues, extras, triggerRules = {}, onEvent = {}, info = null, kendoProps, name, EventType) => {
    if (eachCondition) {
        const { check, uncheck = true, run, ruleId, canOverwrite, isDynamicRule, hasTriggerRule, triggerRuleId, dynamicData = [], fields, eventOn } = eachCondition
        let status = false;
        //    console.log('check:', check, ruleId)
        // if (ruleId === 421)
        //     debugger
        status = check ? check.every(each => {
            currentrefIds = []
            return handleRules(each, fieldValues, isDynamicRule, dynamicData, fields)
        }) : true
        let effects = []
        let namehandle = []
        if (hasTriggerRule && !status && triggerRuleId) {
            return handleConditon(triggerRules[triggerRuleId], fieldValues, extras, triggerRules, onEvent, info, kendoProps, name, EventType)
        }
        else {
            if ((status || uncheck) || (onEvent && onEvent.rules && onEvent.rules.length > 0)) {
                // if (ruleId === undefined) {
                //     console.log(check, status)
                // }
                // else console.log(ruleId, status)
                let evts = (run && run['events']) || null;
                if (status && extras && extras.runEvents && evts && (evts.alertMessage !== null || evts.hasRaiseNotification !== null ||
                    evts.isAutoNotice !== null || evts.noticesId !== null ||
                    evts.isAutoNotification !== null || evts.notificationId !== null)) {
                    run['events'] && handleEvents(run['events'], fieldValues, { ...info, 'BusinessRuleId': ruleId })
                }
                const validityRun = run[validityStatus[status]]
                let tempValidityRun = _.cloneDeep(validityRun)
                if (onEvent && onEvent.rules && onEvent.rules.length > 0) {
                    let filterRules = onEvent.rules.filter(parent => {
                        return ruleId === parent.preConditionRuleId && (parent.preConditionCheck === prevalidityStatus[status])
                    })
                    if (filterRules && filterRules.length > 0) {
                        handleOnEvent(filterRules, status, fieldValues, extras, triggerRules, onEvent, info, kendoProps, name, EventType)
                    }
                }
                if (localStorage.getItem('BusinessRules')) {
                    let randomnumber = (Math.random() + 1).toString(36).substring(2)
                    let storageBusiness = JSON.parse(localStorage.getItem('BusinessRules')) ? JSON.parse(localStorage.getItem('BusinessRules')) : [];
                    let storageValues = (tempValidityRun && tempValidityRun.length > 0 && tempValidityRun.filter(e => e.type === 'prop')) || [];
                    if (storageValues && storageValues.length > 0) {
                        (storageValues || []).map(each => {
                            each['HasValue'] = each['hasValue'] ? 'Y' : 'N';
                            delete each['hasValue']
                            each['HasFilter'] = each['hasFilter'] ? 'Y' : 'N';
                            delete each['hasFilter']
                            each['SwappedRuleType'] = each['swappedRuleType'] ? 'Y' : 'N';
                            delete each['swappedRuleType']
                            each['ValueType'] = each['valueType'];
                            delete each['valueType']
                            each['IsRequired'] = each['required'] ? 'Y' : 'N';
                            delete each['required']
                            each['IsDisabled'] = each['disabled'] ? 'Y' : 'N';
                            delete each['disabled']
                            each['IsVisible'] = each['isVisible'] ? 'Y' : 'N';
                            delete each['isVisible']
                            each['Value'] = each['value']
                            delete each['value']
                            each['Name'] = each['name']
                            delete each['name']
                            each['Type'] = each['type']
                            delete each['type']
                            each['EventAt'] = moment().format('MM/DD/YYYY, hh:mm:ss a');
                            each['EventGroupId'] = randomnumber;
                            each['AllowedValues'] = (each['allowedValues'] && each['allowedValues'].length > 0) ? each['allowedValues'].toString() : '';
                            delete each['allowedValues']
                            each['RuleId'] = ruleId;
                            each['EventType'] = EventType
                            each['TriggerField'] = name
                            each['Triggerby'] = JSON.parse(localStorage.getItem('profile')).name
                            each['ProjectMessageId'] = ''//status && run['events'] && run['events'].alertMessage.toString()
                            each['NotificationId'] = status && run['events'] && run['events'].notificationId
                            each['NoticesId'] = status && run['events'] && run['events'].noticesId
                            each['EventTriggerType'] = 'BR' //get it from sections.js bases on routes with sidebar
                            each['EventMessage'] = ''
                            each['EventActionType'] = 'A'
                        })
                    }
                    storageBusiness = [...storageBusiness, ...storageValues]
                    localStorage.setItem('SaveCurrentRecord', 'Y')
                    localStorage.setItem('BusinessRules', JSON.stringify(storageBusiness))
                }
                //   const validityRun = run[validityStatus[status]]
                if (validityRun && validityRun.length > 0) {
                    effects = validityRun.map(each => {
                        const temp = handleEffects({ ...each, currentrefIds, dynamicData }, fieldValues, status, extras, kendoProps);
                        if (temp) {
                            namehandle.push(each.name)
                            return temp
                        }
                    })
                }
            }
            else effects = []
        }
        return {
            canOverwrite: canOverwrite || false,
            effects: effects,
            status: status,
            namehandle: namehandle
        }
    }
}

export const handleOnEvent = (onEvtconditions, status, fieldValues, extras, triggerRules, onEvent, info, kendoProps, name, EventType) => {
    onEvtconditions.sort((a, b) => a.ruleId - b.ruleId).forEach(pre => {
        if ((pre.preConditionCheck && (pre.preConditionCheck === prevalidityStatus[status]))) {
            let preResult = handleConditon(pre, fieldValues, extras, triggerRules, onEvent, info, kendoProps, name, EventType);
            if (preResult && preResult.effects && preResult.effects.length > 0) {
                let stateeffects = localStorage.getItem('onEventRules') && JSON.parse(localStorage.getItem('onEventRules')) || []
                stateeffects.push(preResult)
                localStorage.setItem('onEventRules', JSON.stringify(stateeffects))
            }
        }
    })
    // return [preCondeffects, preCondnamehandle]
}

export const handleEffects = (fieldEffects, fieldValues, status, extras, kendoProps) => {
    let tempval = {}
    const { type, name, getdatafrom, getValue, read, events, itemfilterval, setLocalStorage, ...otherProps } = fieldEffects
    // const { setLocalStorage } = otherProps
    switch (type) {
        case 'load':
            return handleLoad(read, fieldValues, extras).then(res => {
                return {
                    name: name,
                    type: 'prop',
                    ...otherProps,
                    ...res
                }
            })
        case 'loadtemp':
            return handleLoad(read, tempval, extras).then(res => {
                if (res) {
                    return {
                        type: 'grid',
                        ...res
                    }
                }
                else {
                    return {
                        type: 'grid',
                        ...{ data: [] }
                    }
                }
            })
        case 'loadValues':
            const { loadtransformProps, initialdataAttributes } = otherProps
            return {
                name: name,
                type: 'loadDefaultValues',
                transformProps: loadtransformProps,
                datasetval: initialdataAttributes
            }

        case 'save':
        case 'saveduplicate':
            return handleSave(read, fieldValues).then(res => {
                if (res.errors)
                    return {
                        type: 'error',
                        error: res.errors
                    }
                else if (res.returnvalue)
                    return {
                        type: 'initial',
                        error: res.errors
                    }
                else {
                    events && handleEvents(events, fieldValues);
                    // if (setLocalStorage) {
                    //     localStorage.setItem(name, res[name])
                    // }
                    return {
                        type: 'savesuccess',
                        fieldValues: res,
                        ...otherProps
                    }
                }
            })
        case 'edit':
            return {
                type: 'edit',

            }
        case 'initial':
            return handleLoad(read, fieldValues).then(res => {
                return {
                    type: 'initial',
                    ...res
                }
            })
        case 'BRActivity':
            return handleBRActivity(read, (localStorage.getItem('BusinessRules') && JSON.parse(localStorage.getItem('BusinessRules'))) || [], fieldValues).then(res => {
                if (res)
                    return { type: 'BRActivity', value: false }
            })
        case 'count':
            return handleLoad(read, fieldValues).then(res => {
                return {
                    type: 'value',
                    key: name,
                    result: (res && Object.keys(res)) ? Object.keys(res).length : 0
                }
            })
        // case 'getLocalStorageData':
        //     return {
        //         name: name,
        //         type: 'getLocalStorageData',
        //         result: getLocalStorageData(name, fieldValues, getdatafrom, otherProps.getvalue)
        //     }
        case 'loadbybody':
            return handleLoadBody(read, fieldValues).then(res => {
                return {
                    type: 'initial',
                    ...res
                }
            })
        case 'businessrules':
            return handleLoad(read, fieldValues).then(res => {
                return {
                    type: 'businessrules',
                    ...res
                }
            })
        case 'BRHiddenState':
            return handleLoad(read, fieldValues).then(res => {
                return {
                    type: 'BRHiddenState',
                    ...res
                }
            })
        case 'validations':
            const { transformProps } = otherProps
            return handleValidations(read, fieldValues).then(res => {
                const temp = validationConverter(res[0], {
                    removesubfieldname: transformProps.removesubfieldname,
                }, Object.keys(fieldValues))
                return {
                    type: 'validations',
                    ...temp
                }
            })
        case 'grid':
            return handleGrid(read, fieldValues, extras, kendoProps).then(res => {
                if (res && !kendoProps) {
                    return {
                        type: 'grid',
                        ...res
                    }
                }
                else if (res && kendoProps) {
                    return {
                        type: 'gridExport',
                        ...res
                    }
                }
                else {
                    return {
                        type: 'grid',
                        ...{ data: [] }
                    }
                }
            })
        // case 'show': //correct: unused
        //     return {
        //         name: name,
        //         type: 'layout',
        //         result: handleShow()
        //     }
        case 'clear':
            return {
                name: name,
                type: 'value',
                result: { value: '' }
            }
        case 'clearItems':
            return {
                name: name,
                type: 'prop',
                result: { items: [] }
            }
        case 'clearValues':
            return {
                type: 'clearList',
                result: {}
            }
        case 'calculate':
            return {
                name: name,
                type: 'prop',
                hasValue: true,
                ...otherProps,
                value: handleCalculations(otherProps, fieldValues, extras)
            }
        case 'arrayloadMultiple':
            return handleLoad(read, fieldValues).then(res => {
                return {
                    name: name,
                    type: 'nestedlistArray',
                    otherProps,
                    ...res
                }
            })
        case 'arrayloadMultipleValues':
            return handleLoad(read, fieldValues).then(res => {
                return {
                    name: name,
                    type: 'nestedlistArrayValues',
                    itemfilterval: itemfilterval,
                    otherProps,
                    ...res
                }
            })
        case 'autocompletemultiselect':
            return handleLoad(read, fieldValues).then(res => {
                return {
                    name: name,
                    type: 'autocompleteselectonload',
                    value:fieldValues,
                    itemfilterval: itemfilterval,
                    otherProps,
                    ...res
                }
            })
        case 'arraydropdownMultiple':
            return handleLoad(read, fieldValues).then(res => {
                return {
                    name: name,
                    type: 'nesteddropdownArray',
                    otherProps,
                    ...res
                }
            })
        case 'result':
            return {
                name: name,
                type: 'prop',
                ...otherProps,
                value: fieldValues[name]
            }
        case 'resultvalue':
        case 'initialloadextrainfovalue':
        case 'fulldata':
        case 'reportinfo':
            return {
                key: name,
                type: 'value',
                result: fieldValues[name]
            }
        case 'getValue':
            return {
                key: name,
                type: 'value',
                result: { value: fieldValues[getdatafrom][getValue] }
            }
        case 'addlayout':
            return {
                type: 'addlayout',
                name: name
            }
        case 'removelayout':
            return {
                type: 'removelayout',
                name: name
            }
        case 'domainmapper':
            return handleLoad(read, fieldValues, extras).then(res => {
                return {
                    name: name,
                    type: 'domainmapper',
                    ...res
                }
            })
        case 'appendextravalues':
            return handleLoad(read, fieldValues, extras).then(res => {
                return {
                    name: name,
                    getdatafrom: getdatafrom,
                    type: 'appendextravalues',
                    ...res
                }
            })
        default:
            return fieldEffects
    }


}

// export const getLocalStorageData = (name, fieldValues, getdatafrom, getvalue) => {
//     let value
//     if (getvalue === getdatafrom) {
//         value = localStorage.getItem(getvalue) ? JSON.parse(localStorage.getItem(getvalue)) : null
//     }
//     else {
//         value = localStorage.getItem(getdatafrom) && JSON.parse(localStorage.getItem(getdatafrom))[getvalue]
//     }
//     return { ...fieldValues, [name]: value }
// }

export const handleRules = (fieldRules, fieldValues, isDynamicRule = false, dynamicData = {}, fields) => {
    const { condition, rules, hideLocalStorage = false } = fieldRules;

    // eslint-disable-next-line default-case
    switch (condition) {
        case 'AND':
            let tempAndBoolean = rules.every(each => {
                const { condition } = each
                if (condition && fieldValues) {
                    return handleRules(each, fieldValues, hideLocalStorage)
                }
                else if (!condition && each.name && fieldValues && !fieldValues.hasOwnProperty(each.name) && isDynamicRule) {
                    const temp = DynamicEvaluate(each, fieldValues, dynamicData, fields)
                    if (temp && temp.length > 0) {
                        if (currentrefIds.length === 0)
                            currentrefIds = temp
                        else currentrefIds = currentrefIds.filter(value => temp.includes(value))// [...currentrefIds, ...temp[0]] //concat(temp[0])
                        // currentrefIds = currentrefIds.filter((val, index) => { return currentrefIds.indexOf(val) === index })
                    }
                    return (temp && temp.length > 0) ? true : false
                }
                else {
                    if (fieldValues) {
                        const temp = handleOperators(each, fieldValues, hideLocalStorage)
                        return temp
                    }
                }
            })
            return tempAndBoolean;
        case 'OR':
            let tempBoolean = rules.some(each => {
                const { condition } = each
                if (condition) {
                    return handleRules(each, fieldValues)
                }
                else if (!condition && fieldValues && !fieldValues.hasOwnProperty(each.name) && isDynamicRule) {
                    const temp = DynamicEvaluate(each, fieldValues, dynamicData, fields)
                    if (temp && temp.length > 0) {
                        if (currentrefIds.length === 0)
                            currentrefIds = temp
                        else currentrefIds = currentrefIds.filter(value => temp.includes(value))// [...currentrefIds, ...temp[0]] //concat(temp[0])
                        // currentrefIds = currentrefIds.filter((val, index) => { return currentrefIds.indexOf(val) === index })
                    }
                    return (temp && temp.length > 0) ? true : false
                }
                else {
                    const temp = handleOperators(each, fieldValues)
                    return temp
                }
            })
            return tempBoolean;
    }

}

export const handleOperators = (field, fieldValues, hideLocalStorage = false) => {
    const { name, type, value } = field;
    let RecordHiddenState = localStorage.getItem('RecordHiddenState') && !hideLocalStorage ? JSON.parse(localStorage.getItem('RecordHiddenState')) : null
    switch (type) {
        case 'value':
            return Boolean(fieldValues[name])
        case '=':
            if (fieldValues && Object.keys(fieldValues).includes(name)) {
                if (typeof fieldValues[name] === 'number' && typeof value === 'number') {
                    return fieldValues[name] === value
                }
                else if (typeof fieldValues[name] === 'number' && typeof value !== 'number') {
                    return fieldValues[name].toString() === value
                }
                else return fieldValues[name] === value
            }
            else if (name.includes('.')) {
                return value === name.split(".").reduce((res, prop) => { return res && res[prop] }, fieldValues)
            }
            else return name === value
        case '!=':
            if (Object.keys(fieldValues).includes(name)) {
                if (typeof fieldValues[name] === 'number') {
                    return fieldValues[name].toString() !== value
                }
                else return fieldValues[name] !== value
            }
            else return name !== value
        case '>':
            return Number(fieldValues[name]) > Number(value)
        case '>=':
            return Number(fieldValues[name]) >= Number(value)
        case '<':
            return Number(fieldValues[name]) < Number(value)
        case '<=':
            return Number(fieldValues[name]) <= Number(value)
        case 'not in':
            return !(value && name && fieldValues[name] && (value.replace(/[()''\s]/g, '').split(',') || []).some(each => (isNaN(fieldValues[name]) ? fieldValues[name] : fieldValues[name].toString()).split(',').includes(each))) || false
        case 'in':
            return (value && name && fieldValues[name] && (value.replace(/[()''\s]/g, '').split(',') || []).some(each => (isNaN(fieldValues[name]) ? fieldValues[name] : fieldValues[name].toString()).split(',').includes(each))) || false
        case 'is null':
            if (RecordHiddenState && Array.isArray(RecordHiddenState) && RecordHiddenState.length > 0 && name && fieldValues) {
                let IsDisabledObj = RecordHiddenState.find(each => name.split(".").includes(each.EventField)) || {}
                let IsDisabled = (IsDisabledObj && IsDisabledObj.IsDisabled) || null
                console.log(IsDisabled, name)
                if (IsDisabled && (IsDisabled === 'Y'))
                    return false
                else return (!fieldValues[name] || fieldValues[name] === "") ? true : false
            }
            return (!fieldValues[name] || fieldValues[name] === "") ? true : false
        case 'is not null':
            if (RecordHiddenState && Array.isArray(RecordHiddenState) && RecordHiddenState.length > 0 && name && fieldValues) {
                let IsDisabledObj = RecordHiddenState.find(each => each.EventField === name) || {}
                let IsDisabled = (IsDisabledObj && IsDisabledObj.IsDisabled) || null
                console.log(IsDisabled, name)
                if (IsDisabled && (IsDisabled === 'Y'))
                    return true
                else return (fieldValues[name] && Array.isArray(fieldValues[name])) ? fieldValues[name].length > 0 ? true : false : (!fieldValues[name] || fieldValues[name] === '') ? false : true
            }
            return (fieldValues && Array.isArray(fieldValues[name])) ? fieldValues[name].length > 0 ? true : false : (fieldValues && (!fieldValues[name] || fieldValues[name] === '')) ? false : true
        case 'check':
            return fieldValues[name] ? ((fieldValues[name].disabled === 'N' || fieldValues[name].disabled === false) ? true : false ||
                (fieldValues[name] === 'N' || fieldValues[name] === false) ? true : false) : false
        case 'CE':
            return Evaluate(value, fieldValues)
        default:
            return false
    }
}

export const handleLoadBody = (read, fieldValues, extras) => {
    return Promise.all(read.map(each => requestApi(createRequest(each, each.body ? each.body : fieldValues, each.body ? fieldValues : extras)))).then(results => {
        return read.reduce((sum, current, index) => {
            if (current.saveAs) {
                const { saveAs } = current
                return { ...sum, [saveAs.key]: results[index] }
            }
            else return { ...sum, ...results[index] }
        }, {})
    }).catch(ex => {
        console.log(ex)
    })
}


export const handleBRActivity = (read, data, params) => {
    return Promise.all(read.map(each => requestApi(createRequest(each, data, params)))).then(results => {
        return results[0]
    }).catch(ex => {
        console.log(ex)
    })
}


export const handleLoad = (read, fieldValues, extras) => {
    return Promise.all(read.map(each => requestApi(createRequest(each, fieldValues, extras)))).then(results => {
        return read.reduce((sum, current, index) => {
            if (current.saveAs) {
                const { saveAs, sublist } = current;
                let resp = results
                if (sublist) {
                    resp = results[index][sublist]
                }
                if (saveAs.rename) {
                    const { key } = saveAs.rename;
                    resp = results[0][key]
                }
                else resp = results[index]
                return { ...sum, [saveAs.key]: resp }
            }
            else return { ...sum, ...results[index] }
        }, {})
    }).catch(ex => {
        console.log(ex)
    })
}

export const handleSave = (read, fieldValues) => {
    return Promise.all(read.map(each => requestApi(createRequest(each, fieldValues)))).then(results => {
        if (results)
            return { ...results[0] }
    }).catch(error => {
        return error.then(errors => {
            if (errors) {
                return { errors: errors }
            }
        })
    })
}

export const handleValidations = (read, fieldValues) => {
    return Promise.all(read.map(each => requestApi(createRequest(each, fieldValues)))).then(results => {

        return results
    })
}

export const handleGrid = (read, fieldValues, extras) => {
    let extra = extras || { skip: 0, take: 20 }
    return Promise.all(read.map(each => requestApi(createGridRequest(each, fieldValues, null, extra)))).then(results => {

        return read.reduce((sum, current, index) => {
            const { saveAs } = current
            return { ...sum, [saveAs.key]: results[index] }
        }, {})
    }).catch(error => console.log(`Error in executing ${error}`))
}

export const handleEnable = (props) => {
    const { status, enable, disable } = props
    if (status) return enable
    else return disable
}

export const handleDisable = (props) => {
    const { status, enable, disable } = props
    if (status) return disable
    else return enable

}

export const handleRequired = (props, status) => {
    return props[status]
}



export const handleUncheck = (props, status) => {
    const { valid, inValid } = props
    if (status)
        return valid
    else return inValid

}

export const handleCalculations = (props, values, extras) => {
    const { operator, operands, attr, quotient } = props;
    // const { dataAttr } = extras
    switch (operator) {
        case '*':
            return (extras && extras.dataAttr && extras.dataAttr[operands]) ?
                (Number(values[quotient]) * Number(extras.dataAttr[operands][attr])) : null //.toFixed(2).toString() 
        default: return false
    }

}