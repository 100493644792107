/**        
      *********************************************************
            Description: Date and Time component.  The value this component receives is in
            MM/DD/YYYY hh:mm:ss format which needs to be converted.Format required by the component is YYYY-MM-DDThh:mm. Moment is used to convert to this format.
            Required props:  type,value. 
            limitations    : N/A
      *********************************************************
**/

import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
});

class CDateTimePicker extends Component {
    render() {
        const { classes, type, handleFieldChange, name, compmap, options, value, edit } = this.props
        const { label } = options
        let val = ((value === null) || (value === undefined) || (value === '')) ? '' : value
        if (type === 'date') val = val ? moment(val).format('MM/DD/YYYY') : val
        //  if (type === 'datetime-local') val = val ? moment(val, 'MM/DD/YYYY hh:mm:ss').format('YYYY-MM-DDThh:mm') : val;
        if (type === 'time') val = val ? moment(val, "HH:mm:ss").format("hh:mm A") : '';
        if (edit === false) {
            return <Fragment>
                <div > <Typography variant={'caption'} className="text-secondary">{label} </Typography>
                    <Typography variant={'body2'} color={'textPrimary'} className="fw-bold">{val || ''}  </Typography>
                </div>
            </Fragment >
        }
        else return (
            <div className="row pl-1">
                <TextField
                    label={label}
                    type={type}
                    className={classes.textField}
                    onChange={handleFieldChange}
                    name={name}
                    defaultValue={value}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
        );
    }

}

CDateTimePicker.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CDateTimePicker);




/**
 * {
 * type:'title',
 * options:{
 *  text:'',
 * },
 * defaults:{
 * layout:'material',
 * customlayout: false
 * }
 * }
 */













// import React, { useState } from "react";
// import MomentUtils from '@date-io/moment';
// import {
//   DatePicker,
//   TimePicker,
//   DateTimePicker,
//   MuiPickersUtilsProvider,
// } from "@material-ui/pickers";

// function App() {
//   const [selectedDate, handleDateChange] = useState(new Date());

//   return (
//     <div>
//     <MuiPickersUtilsProvider utils={DateFnsUtils}>
//       <DatePicker value={selectedDate} onChange={handleDateChange} />
//       {/* <TimePicker value={selectedDate} onChange={handleDateChange} />
//       <DateTimePicker value={selectedDate} onChange={handleDateChange} /> */}
//     </MuiPickersUtilsProvider>
//     </div>
//   );
// }

/**
 * {
 *  type:'datetimepicker',
 * options:{
 *  pick:'date/time/datetime'
 * }
 * }
 */

