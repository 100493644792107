  /**        
        *********************************************************
        Currently not used anywhere. 
        *********************************************************
 **/   

import React, { Component, Fragment } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


class CFormFieldsGroup extends Component {

    render() {
        const { options, compmap, components } = this.props;
        const { label, labelcomp, controlcomp, helpertext, disabled, error, required, fullWidth, margin, formgrouprow, ...otherprops } = options

        return (
            <Fragment>
                <FormControl component="fieldset" disabled={disabled} required={required} error={error} fullWidth={fullWidth} margin={margin} {...otherprops}>
                    <FormLabel component={labelcomp}>{label}</FormLabel>
                    <FormGroup row={formgrouprow}>
                        {components && components.map(each => {
                            const EachComp = compmap[each.type]
                            return <EachComp {...each} />
                        })}
                    </FormGroup>
                    {helpertext && <FormHelperText>{helpertext}</FormHelperText>}
                </FormControl>
            </Fragment>
        )
    }

}

export default CFormFieldsGroup;



/**
* {
* type: 'formgroup',
* options:{
    label:'',
    labelcomp: "legend",
    controlcomp:'fieldset',

    items:[{
        type:'radio',
        options:{

        }
    }]
* label: '',
        * },
* defaults:{
* theme: 'default'
        * }
        * }
*/