import React from 'react';
import PropTypes from 'prop-types';


const ValueEditor = ({
  operator,
  value,
  handleOnChange,
  title,
  className,
  type,
  inputType,
  values, fieldData
}) => {
  if (operator === 'null' || operator === 'notNull') {
    return null;
  }


  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    handleOnChange(value.join(','))
  };

  switch (type) {
    case 'select':
      return (
        <select
          style={{ height: values && values.length > 15 ? '100px' : '150px' }}
          multiple={fieldData && fieldData.mutliple ? true : false}
          className={className}
          title={title}
          onChange={(e) => handleChangeMultiple(e)}
          value={fieldData.mutliple ? value.split(',') : value}>
          {values.map((v) => (
            <option key={v.name} value={v.name}>
              {v.label}
            </option>
          ))}
        </select>
      );

    case 'checkbox':
      return (
        <input
          type="checkbox"
          className={className}
          title={title}
          onChange={(e) => handleOnChange(e.target.checked)}
          checked={(value === 'N' || !value) ? false : true}
        //  value={value === false ? 'N' : 'Y'}
        />
      );

    case 'radio':
      return (
        <span className={className} title={title}>
          {values.map((v) => (
            <label key={v.name}>
              <input
                type="radio"
                value={v.name}
                checked={value === v.name}
                onChange={(e) => handleOnChange(e.target.value)}
              />
              {v.label}
            </label>
          ))}
        </span>
      );

    case 'checkboxgroup':
      return (
        <span className={className} title={title}>
          {values.map((v) => (
            <label key={v.name}>
              <input
                type="checkbox"
                value={v.name}
                checked={value === v.name}
                onChange={(e) => handleOnChange(e.target.value)}
              />
              {v.label}
            </label>
          ))}
        </span>
      );
    case 'date':
      return (
        <input
          type={'date' || 'text'}
          value={value}
          title={title}
          className={className}
          onChange={(e) => handleOnChange(e.target.value)}
        />
      );

    default:
      return (
        <input
          type={inputType || 'text'}
          value={value}
          title={title}
          className={className}
          onChange={(e) => handleOnChange(e.target.value)}
        />
      );
  }
};

ValueEditor.displayName = 'ValueEditor';

ValueEditor.propTypes = {
  field: PropTypes.string,
  operator: PropTypes.string,
  value: PropTypes.any,
  handleOnChange: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['select', 'checkbox', 'radio', 'text', 'date', 'checkboxgroup']),
  inputType: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.object)
};

export default ValueEditor;
