import React, { Fragment, Component } from 'react';
import { NumericTextBox } from '@progress/kendo-react-inputs'
import '@progress/kendo-react-intl'
import '@progress/kendo-drawing'
import '@progress/kendo-react-tooltip'
import '@progress/kendo-react-form'
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Numberformat from '../../controls/components/numberformat';
import DefaultSettings from '../../controls/components/settings';

class CNumberFormat extends Component {

    handleChange = (e) => {
        // const { handleFieldChange, value, name, id, edit, options, ...otherProps } = this.props
        // let events = { target: { value: e.value || value, name: name, checked: false } }
        this.props.handleFieldChange(e);
    }

    render() {
        const { handleFieldChange, defaultValue, value, tooltip, helperText, format, htmlForLabel, name, disabled, min, max, error, required, id,
            edit, options, conditions, accessPermissions, contexthelp, accessLevelPermissions, ...otherProps } = this.props
        let disabledstatus = conditions && conditions.enable ? conditions.enable : disabled
        const { label, xtype } = otherProps
        let val = ((value === null) || (value === undefined) || (value === '')) ? 0 : value;
        let pvalue = 0
        if (xtype === 'P') {
            pvalue = value !== 0 ? value : 0
        }
        if (edit === false) {
            if (xtype === 'C') {
                let cformat = format ? format > 100 ? 2 : format : 2
                val = '$' + parseFloat(val).toFixed(cformat)
            }
            if (xtype === 'DN') {
                let cformat = format ? format > 100 ? 2 : format : 2
                val = parseFloat(val).toFixed(cformat)
            }
            if (xtype === 'P') {
                val = val + '%';
            }
            return <div >
                {tooltip && <Fragment> <Typography variant={'caption'} className="text-secondary">{label} </Typography> &nbsp;
                    < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                        <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} onClick={(evt) => contexthelp ?
                            DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                    </Tooltip>  <Typography variant={'body2'} color={'textPrimary'} className="fw-bold">{val}   </Typography>  </Fragment>}
                {!tooltip && <Fragment><Typography variant={'caption'} className="text-secondary">{label} </Typography>
                    <Typography variant={'body2'} color={'textPrimary'} className="fw-bold">{val}   </Typography>  </Fragment>}
            </div>
        }
        else return (
            <Fragment>
                <Numberformat
                    required={required}
                    disabled={true}
                    min={min}
                    max={max}
                    xtype={xtype}
                    format={format}
                    tooltip={tooltip}
                    error={error}
                    helperText={helperText}
                    label={label}
                    contexthelp={contexthelp}
                    handleFieldValue={this.handleChange}
                />
            </Fragment>
            // <div>
            //     {xtype === 'DN' && <FormControl error={error} required={required}>
            //         {!tooltip && <small className="text-secondary" htmlFor="input-with-icon-adornment">{label}
            //         </small>}
            //         {tooltip && <small className="text-secondary" htmlFor="input-with-icon-adornment">{label}&nbsp;
            //             < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
            //                 <HelpOutlineIcon fontSize="small" style={{ fontSize: 12 }} onClick={(evt) => contexthelp ?
            //         DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()}/>
            //             </Tooltip></small>}
            //         <Number
            //             format={`n${format}` || "n2"}
            //             value={value ? Number(value) : null}
            //             min={min || 0}
            //             max={max || 1000}
            //             disabled={disabledstatus}
            //             handleFieldValue={this.handleChange}
            //         />
            //         {helperText && <FormHelperText>{helperText}</FormHelperText>}

            //     </FormControl >}
            //     {
            //         xtype === 'P' && <FormControl error={error} required={required}>
            //             {!tooltip && <small className="text-secondary" htmlFor="input-with-icon-adornment">{label}
            //             </small>}
            //             {tooltip && <small className="text-secondary" htmlFor="input-with-icon-adornment">{label}
            //                 < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
            //                     <HelpOutlineIcon fontSize="small" onClick={(evt) => contexthelp ?
            //         DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()}/>
            //                 </Tooltip></small>}

            //             <label>   <NumericTextBox
            //                 //     format="p"
            //                 value={pvalue ? Number(pvalue) : null}
            //                 min={min || 0}
            //                 max={max || 1000}
            //                 disabled={disabledstatus}
            //                 onChange={this.handleChange}
            //             /> % </label>
            //             {helperText && <FormHelperText>{helperText}</FormHelperText>}
            //         </FormControl >
            //     }
            //     {
            //         xtype === 'C' && <FormControl error={error} required={required}>
            //             {!tooltip && <small className="text-secondary" htmlFor="input-with-icon-adornment">{label}
            //             </small>}
            //             {tooltip && <small className="text-secondary" htmlFor="input-with-icon-adornment">{label}
            //                 < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
            //                     <HelpOutlineIcon fontSize="small" onClick={(evt) => contexthelp ?
            //         DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()}/>
            //                 </Tooltip></small>}
            //             <NumericTextBox
            //                 format={`c${format}` || "c"}
            //                 min={0}
            //                 value={value ? Number(value) : null}
            //                 onChange={this.handleChange}
            //             />
            //             {helperText && <FormHelperText>{helperText}</FormHelperText>}
            //         </FormControl >
            //     }
            // </div >
        );
    }
}


export default CNumberFormat;

