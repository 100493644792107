import React, { Component, Fragment } from 'react';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { TextField, Typography, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { validateTaxParcelTask } from '../../core/modules/map/eps/validatetaxparcelQuery';
import ConfirmDialog from '../../core/modules/form/cconfirmationpopup';
const styles = theme => ({
    // button: {
    //     margin: theme.spacing(1),
    // },
    paper: {
        height: 500
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    }
});

class CFormButton extends Component {
    state = {
        taxparcels: this.props.FieldValue ? this.props.FieldValue.split(", ").map((each, index) => { return { name: each, id: index } }) : [],
        open: false,
        taxparcel: ''
    }

    validateDuplicateTacparcel = () => {
        let isexists = false;
        this.state.taxparcels.map(each => {
            if (each.name == this.state.taxparcel) {
                isexists = true;
                //return confirm("Tax parcel already exists");
            }
        })
        return isexists;
    }
    addTaxParcel = () => {
        console.log("--in add tax parcel---");
        console.log(this.state.taxparcel);
        //check if this taxparcel exists in the list of taxparcels
        let isexistsduplicate = this.validateDuplicateTacparcel();
        if (isexistsduplicate) {
            //  confirm("Tax parcel already exists");
            this.setState({ confirmClickedEvent: true, message: "Tax parcel already exists .You can not add this taxparcel again." });
        }
        else {
            this.validateTaxparcel().then(result => {
                if (result.taxParcelFlag) {
                    this.setState({
                        taxparcels: this.state.taxparcels.concat({ name: this.state.taxparcel, id: this.state.taxparcels.length }),
                        taxparcel: ''
                    })
                }
            }
            ).catch(ex => console.log(ex));
        }

    }

    validateTaxparcel = () => {
        let taxparcelTosend = this.state.taxparcel.split('').join('%');//531-8.00-15.01
        let site = "Sussex"
        let queryexpression = "PIN like('" + taxparcelTosend + "') ";      //and COUNTY = '" + site + "'
        return new Promise((resolve, reject) => {

            validateTaxParcelTask(this.props.options.tasks, queryexpression, this.state.taxparcel).then(
                response => {
                    console.log("TaxParcelTask:: ", response);
                    resolve(response);
                },
                error => {
                    console.log(error);
                    reject({ "error": error });
                });
        });
    }

    handleValue = (e) => {
        this.setState({
            taxparcel: e.target.value
        })
    }

    deleteTaxParcel = (id) => {
        this.setState({
            taxparcels: this.state.taxparcels.filter(each => each.id !== id).map((each, index) => {
                const { name, id } = each
                return { name: name, id: index }
            })
        })

    }

    openDialog = () => {
        const { edit } = this.props
        if (edit !== false) this.setState({
            open: true
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }


    handleClear = () => {
        this.setState({
            taxparcels: []
        })
    }


    handleSave = () => {
        console.log()
        this.props.addTaxParcels(this.state.taxparcels.map(each => each.name).join(', '), this.state.taxparcels)
        this.closeDialog()
    }

    closeDialog = () => {
        this.setState({
            open: false
        })
    }



    render() {
        const { classes } = this.props
        const { options, passer, type, edit, rules, components, handleFieldChange, value, error, touched, ...otherProps } = this.props;
        const { text, icon, color, disabled, href, size, variant, name, ...otherOptions } = options
        let iconname, iconColor, iconFontSize, iconposition
        if (icon) {
            iconname = icon.iconname
            iconColor = icon.iconColor
            iconFontSize = icon.iconFontSize
        }
        return (
            <Fragment>
                {edit &&

                    < Fab size="small" color="secondary" aria-label="add" onClick={this.openDialog}>
                        <Tooltip title="Add tax Parcels"><AddIcon /></Tooltip>
                    </Fab>
                }
                {
                    <Dialog onClose={this.handleClose} open={this.state.open} fullWidth={true} maxWidth='sm'  >
                        <AppBar position="static" color='secondary'>
                            <Toolbar variant="dense">
                                <Typography>
                                    TaxParcels
                                </Typography>
                                <div className='flex-grow-1' />
                                <i className="" onClick={this.closeDialog} >
                                    <IconButton color="inherit">
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </i>
                            </Toolbar>
                        </AppBar>
                        <DialogContent className={classes.paper}>
                            <TextField
                                name='taxparcel'
                                value={this.state.taxparcel}
                                onChange={this.handleValue}
                            />
                            <Button
                                color='primary'
                                variant='contained'
                                name='addtaxparcel'
                                onClick={this.addTaxParcel}
                                className="ml-2"
                            >Add</Button>
                            <div>
                                <List>
                                    {this.state.taxparcels.map(each => {
                                        const { name, id } = each
                                        return <ListItem key={id}>
                                            {name}
                                            <i onClick={() => this.deleteTaxParcel(id)} >
                                                <IconButton color="inherit">
                                                    <CloseIcon fontSize="small" />
                                                </IconButton>
                                            </i>
                                        </ListItem>
                                    })}
                                </List>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    name='addtaxparcel'
                                    onClick={this.handleClear}
                                >Clear</Button>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    name='addtaxparcel'
                                    onClick={this.handleSave}
                                    className="ml-2"
                                >Save</Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                }
                {
                    this.state.confirmClickedEvent && <ConfirmDialog title="Tax Parcel Validation" description={this.state.message}
                        onSubmit={() => this.setState({ confirmClickedEvent: false })} onClose={() => () => this.setState({ confirmClickedEvent: false })} ></ConfirmDialog>
                }
            </Fragment >

        )

    }
}

export default withStyles(styles)(CFormButton);






/**
 * {
 * type:'button',
 * options:{
 *  text:'alarm',
 *  icon:'alarm',
 * button:''
 * },
 * defaults:{
 *  theme:'default',
 *  custom: false
 * }
 * }
 */