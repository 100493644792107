import React, { Fragment } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import * as DNDSettings from './util/dndSettings'
import CSectionDroppable from "./cSectionDroppable";
import ValidationsRules from './validationrules';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingIcon from '@material-ui/icons/Settings';
import MoveUpIcon from '@material-ui/icons/ArrowUpward';
import MoveDownIcon from '@material-ui/icons/ArrowDownward';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/Menu';
import BussinessIcon from '@material-ui/icons/AssignmentTurnedIn';
import DeleteIcon from '@material-ui/icons/Delete';
import ExclamatoryIcon from '@material-ui/icons/ErrorOutline'
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

class CSectionDraggable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isHovering: false,
      opened: this.props.isSectionsOpen,
      openValidate: false,
      anchorEl: null
    }
  }

  handleBackSlider = (back) => {
    this.setState({ openValidate: back })
  }


  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  };

  handleClose = () => {
    this.setState({ anchorEl: null })
  };


  render() {

    const { anchorEl } = this.state
    const { section, sectionConfig, availablelist, getSubSections, draggable, OnAddSubSection, OnAddRow, OnMoveDown, OnMoveUp, CanShowMoveUp, CanShowMoveDown, OnSectionSettings, OnRowSettings, OnSectionRemove, errors } = this.props;
    let sectionerror = errors ? (errors[section.dndId] ? errors[section.dndId] : null) : null;

    return (
      <div>
        <Draggable key={section.dndId} draggableId={`SecDG-${section.dndId}`} index={draggable} isDragDisabled={true}>
          {(sdp, sds) => (
            <div ref={sdp.innerRef}
              {...sdp.draggableProps}
              {...sdp.dragHandleProps}
              className={availablelist ? section.clazzName : "m-1"}
              style={DNDSettings.getSectionStyle(sds.isDragging, sdp.draggableProps.style)} >
              <div className={section.titleClazz}    >
                <div className="row col-sm-12">
                  <div className="col-sm-3"> {section.Name} {sectionerror && <span className="text-white bg-danger" title="Error" >
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      color={'inherit'}
                    >
                      <ExclamatoryIcon />
                    </IconButton></span>}
                  </div>
                  {section.dndId !== "allfields" && <div className="actionPanel col-sm-9">
                    <Fragment>
                      <IconButton  className='float-end mx-0'
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        color={'inherit'}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                      >
                        {section.canAddRows && <MenuItem style={{ color: '#f4511e' }} onClick={(ev) => this.setState({ anchorEl: null }, () => OnAddRow(ev, { ...section }))}>
                          <ListItemIcon style={{ color: '#f4511e' }}>
                            <AddIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Add Row" />
                        </MenuItem>}
                        {CanShowMoveUp(section) && <MenuItem style={{ color: '#6200ea' }} onClick={(ev) => this.setState({ anchorEl: null }, () => OnMoveUp(ev, { ...section }))}>
                          <ListItemIcon style={{ color: '#6200ea' }}>
                            <MoveUpIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Move Up" />
                        </MenuItem>}
                        {CanShowMoveDown(section) && <MenuItem style={{ color: '#2e7d32' }} onClick={(ev) => this.setState({ anchorEl: null }, () => OnMoveDown(ev, { ...section }))}>
                          <ListItemIcon style={{ color: '#2e7d32' }}>
                            <MoveDownIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Move Down" />
                        </MenuItem>}
                        {section.showSettings && <StyledMenuItem style={{ color: '#3e2723' }} onClick={(ev) => this.setState({ anchorEl: null }, () => OnSectionSettings(ev, sectionerror, { ...section }))}>
                          <ListItemIcon style={{ color: '#3e2723' }}>
                            <SettingIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Settings" />
                        </StyledMenuItem>}
                        {((section.showSettings && section.rows && section.rows.length > 0)) && <StyledMenuItem style={{ color: '#6a1b9a' }} onClick={(ev) => this.setState({ openValidate: true, anchorEl: null })} >
                          <ListItemIcon style={{ color: '#6a1b9a' }}>
                            <BussinessIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Business Rules" />
                        </StyledMenuItem>}
                        {section.showSettings && <StyledMenuItem style={{ color: '#d50000' }} onClick={(ev) => this.setState({ anchorEl: null }, () => OnSectionRemove(ev, { ...section }))}>
                          <ListItemIcon style={{ color: '#d50000' }}>
                            <DeleteIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Delete" />
                        </StyledMenuItem>}
                      </StyledMenu>
                    </Fragment>
                  </div>}
                </div>
              </div>
              {this.state.opened && <div><CSectionDroppable sectionConfig={sectionConfig} section={section} getSubSections={getSubSections} dropclazzName={section.dropclazzName}
                OnRowSettings={OnRowSettings} canDrag={section.canDrag} dragclazzName={section.dragclazzName} droppable={section.dndId} dropType={section.dropType}
                rows={section.rows} type={section.dndId} /></div>}
              {this.state.openValidate && <ValidationsRules section={section} eachsection={true} open={this.state.openValidate} handleBackSlider={this.handleBackSlider} />}
              {sdp.placeholder}
            </div>
          )
          }
        </Draggable>
      </div>
    );
  }
}
export default CSectionDraggable;
