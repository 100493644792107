export const ExternalList = {
    WRS: {
        "epsgeneral":
            [
                {
                    "type": "menu",
                    "title": "Notifications",
                    "icon": "notifications_active",
                    "materialicon": "notifications_active",
                    "link": "/notifications",
                    "key": "Notifications",
                    "style": {
                        'color': '#d50000'
                    }
                },
                {
                    "type": "menu",
                    "title": "Search",
                    "icon": "search",
                    "materialicon": "search",
                    "link": "/search",
                    "key": "Search",
                    "style": {
                        'color': '#61A5DC'
                    }
                },

                {
                    "type": "menu",
                    "title": "Records",
                    "key": "newapplicationdetails",
                    "link": "#/Administration/applications",
                    "icon": "fa fa-file-text",
                    "materialicon": "new_releases",
                    "style": {
                        'color': '#9c27b0'
                    }
                },
                {
                    "type": "menu",
                    "title": "Users",
                    "icon": "fa fa-users",
                    "link": "#/users",
                    "key": "usersform",
                    "materialicon": "person",
                    "style": {
                        'color': '#01579b'
                    }
                },
                {
                    "type": "menu",
                    "title": "Contacts",
                    "key": "contacts",
                    "link": "#/Administration/externalcontacts",
                    "icon": "fa fa-user-circle-o",
                    "materialicon": "contacts",
                    "style": {
                        'color': '#006064'
                    }
                }
            ],
    },
 
}

