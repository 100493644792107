import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import _chunk from 'lodash/chunk';
import TextField from '@material-ui/core/TextField';
import { useRef } from 'react';
import { useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';

export default function BasicButtonGroup({ items = [], rows = 1, options = [], ...otherProps }) {
    const { Value, changeValue } = otherProps
    const [value, setValue] = React.useState(Value || '');
    const [insert, setInsertValue] = React.useState('');
    const [pos, setPos] = useState(0);
    const text = useRef(null);

    const handleClick = (val) => evt => {
        if (val === 'del')
            handleDel()
        else {
            val = val === 'Insert' ? `{{${insert}}}` : val
            setPos(pos + 1);
            const output = [value.slice(0, pos), val, value.slice(pos)].join('');
            setValue(output);
            showCaretPos(pos + 1)
        }
    }
    const showCaretPos = (val) => {
        setTimeout(() => {
            text.current.focus();
            text.current.setSelectionRange(val, val)
        }, 10)
    }
    const handlePos = () => {
        setPos(text.current.selectionStart)
    }
    const handleDel = () => {
        const output = [value.slice(0, pos - 1), value.slice(pos)].join('');
        setValue(output);
        setPos(pos - 1)
        showCaretPos(pos - 1)
    }
    const handleChange = (evt) => {
        const { value } = evt.target
        setInsertValue(value);
    }
    const handleSave = () => {
        changeValue(value)
    }

    const handleTextChange = (evt) => {
        setPos(pos + 1);
      //  const output = [value.slice(0, pos), evt.target.value].join('');
        setValue(evt.target.value);
        showCaretPos(pos + 1)
    }

    return (
        <Paper className="row col-sm-12">
            <TextField fullWidth label="Text Editor" inputRef={text} onClick={handlePos} multiline={4}  maxRows={4} value={value} onChange={handleTextChange} />
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Fields</InputLabel>
                <Select onChange={handleChange} value={insert} displayEmpty>
                    {options && options.length > 0 && options.map((opt, index) => { return <MenuItem key={index} value={opt.name}>{opt.label}</MenuItem> })}
                </Select>
            </FormControl>
            <div className="text-center col-sm-4 m-2">
                {_chunk(items, rows).map((list, idx) => {
                    return <ButtonGroup key={idx} color="primary" aria-label="outlined primary button group">
                        {list.map((e, i) => {
                            return <Button key={i} onClick={handleClick(e)}>{e}</Button>
                        })}
                    </ButtonGroup>
                })}
                <div className="text-center p-2">  <Button size="small" color="primary" variant="contained" onClick={handleSave}>Save</Button></div>
            </div>
        </Paper>
    );
}

