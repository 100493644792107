import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from "react";
import DefaultSettings from './settings';
import InputLabel from '@material-ui/core/InputLabel';

export default function DaysTimer(props) {
    const { name, options, value, handleFieldValue, edit, extraProps, required, disabled, helperText, error, contexthelp, ...otherProps } = props
    const { label, xtype, fullWidth, tooltip, FieldProps, ...otherConfigs } = otherProps;
    // let val = ((value === null) || (value === undefined) || (value === '')) ? null : new Date(value);

    return (
        <FormControl className={name} fullWidth={fullWidth ? fullWidth : true} error={error} required={required} disabled={disabled || false}>
                  <InputLabel style={{ fontWeight: 'bold', color: error ? 'red' : 'black' }} shrink> {label}&nbsp; {tooltip && <Tooltip title={tooltip ? tooltip : null}
                    placement="bottom-start" ><HelpOutlineIcon fontSize="small" style={{ width: '0.8em',color: '#007bff' }} onClick={(evt) => contexthelp ?
                        DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                </Tooltip>} </InputLabel>
                <div className='pt-2'></div>
            <TextField
                disabled = {disabled || false}
                id="time"
                name={name}
                type="time"
                value={value}
                //disabled={disabled || false}
                onChange={(e) => handleFieldValue({ target: { value: e.target.value, name: name, checked: false, }, currentTarget: { dataset: null } })}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 300, // 5 min
                }}
                {...otherConfigs}
                {...extraProps}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
