import Fab from '@material-ui/core/Fab'
import CheckBoxOutlineBlank from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import SignaturePad from 'react-signature-canvas'
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DefaultSettings from './settings';


export default function Signature(props) {
    const { value, name, helperText, error, label, edit, contexthelp, tooltip, fullWidth, required, disabled, handleFieldValue } = props
    const sigPad = useRef(null)

    const clear = () => {
        sigPad.current.clear()
    }

    useEffect(() => {
        if (value !== "" || value !== null || value !== undefined) {
            sigPad.current.getSignaturePad().fromDataURL(value)
        }
    }, [value])

    const trim = () => {
        let events = { target: { value: sigPad.current.getSignaturePad().toDataURL('image/png'), name: name, checked: false }, currentTarget: { dataset: null } }
        handleFieldValue(events);
    }

    return <FormControl fullWidth={fullWidth ? fullWidth : true} error={error} required={required}>
        {label && tooltip && <Fragment>
            <InputLabel shrink>{label}&nbsp;
                <Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                    <HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#007bff', marginLeft: 5 }} onClick={(evt) => contexthelp ?
                    DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                </Tooltip></InputLabel>&nbsp;
        </Fragment>}
        {label && !tooltip && <Fragment>
            <InputLabel shrink>{label}
            </InputLabel>
        </Fragment>}
        <div className='row'>
            <div className='row  m-2 col-sm-6 border'>
                <SignaturePad disabled={disabled || false} clearOnResize={true} ref={sigPad} canvasProps={{ width: 800, height: 100, className: 'sigCanvas' }} />
            </div><br />
        </div>
        <div className='row col-sm-6'  >
            <Fab variant="extended" className=' col-sm-6' size="small" color="secondary" aria-label="add" onClick={clear}> <Close /> Clear  </Fab>
            <Fab variant="extended" className=' col-sm-6' size="small" color="secondary" aria-label="add" onClick={trim}> <CheckBoxOutlineBlank />OK </Fab>
        </div>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>

}

