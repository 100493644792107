
import { isRequired as IsRequired } from './validate'
import { isNumber as IsNumber } from './validate'
import { minLength as MinLength } from './validate'
import { maxLength as maxLength } from './validate'
import { isPercentage as IsPercentage } from './validate'
import { isString as IsString } from './validate'
import { regExp as RegExpression } from './validate'
import { isRange as IsRange } from './validate'
import { urlData as urlData } from './validate'
import { phoneMask as phoneMask } from './validate'
import { isInteger as isInteger } from './validate'
import { stringLength as stringLength } from './validate'
import { editorLength as editorLength } from './validate'
import { date as date } from './validate'
import { leadingorTrailing as leadingorTrailing } from './validate'

const validationrules = {
    Type: {
        R: {
            IsRequired: IsRequired
        },
        D: {
            Mask: {
                P: IsPercentage,
                BI: IsNumber,
                DN: IsNumber,
                I: IsNumber,
                R: IsNumber,
                N: IsNumber,
                C: IsNumber,
                VC: IsString,
                NVC: IsString,
                L: IsNumber,
                int: isInteger,
                datetime2: date
                //nvarchar: IsString
            }
        },
        L: {
            Minlen: MinLength,
            Maxlen: maxLength,
        },
        X: {
            RegExp: RegExpression
        },
        G: {
            IsRange: IsRange
        },
        // M: {
        //     Mask: phoneMask
        // },
        U: {
            Url: urlData
        },
        Z: {
            Str: stringLength
        },
        Y: {
            EStr: editorLength // Editor Count
        },
        S: {
            leadingorTrailing : leadingorTrailing
        }
    }
}

/**        
      *********************************************************
      Description: Handles validations. Validations are verified until an error is found.
      Required props:  validations, fieldvalues.
      limitations    : N/A
      *********************************************************
**/

export const globalvalidate = (validations, fieldValues) => {
    const errors = {}
    // const fieldvalidationskeys = Object.keys(validations)
    // // let requiredfields = {}
    // fieldvalidationskeys.forEach(each => {
    //     // requiredfields[each] = validations[each].find(each=>{
    //     //     return each.IsRequired === 'Y'
    //     // })
    //     validations[each].sort((a, b) => a.Order - b.Order)
    // })
    const validationskeys = Object.keys(validations).map(each => each.toUpperCase())
    const fieldValueskeys = Object.keys(fieldValues)
    const uppercasefieldValueskeys = fieldValueskeys.map(each => each.toUpperCase())
    validationskeys.forEach(each => {
        const index = uppercasefieldValueskeys.indexOf(each)
        const fieldName = fieldValueskeys[index]
        const eachfieldvalidations = validations[fieldName]
        if (eachfieldvalidations) {
            const requiredRule = eachfieldvalidations.filter(r => r.Type == "R");
            const isRequired = (requiredRule != null && requiredRule.length > 0)
            eachfieldvalidations.some(e => {
                const fieldValue = fieldValues[fieldName]
                if (fieldName && ((!fieldValue && isRequired) || (fieldValue))) {
                    errors[fieldName] = globaleachvalidate(fieldName, e, fieldValue)
                    if (errors[fieldName].error) return true

                }

            })
        }
    })
    return errors
}
/**        
      *********************************************************
      Description: Handles one validation based on type. 
      Required props:  fieldName, validation, fieldValue.
      limitations    : N/A
      *********************************************************
**/

const globaleachvalidate = (fieldName, validation, fieldValue) => {
    let errortext = ''
    let error = false
    const validationtype = validation.Type
    let validationtypevariants
    let callvalidationfn
    if (validation.Type === 'D') {
        validationtypevariants = validationrules['Type'][validationtype].Mask
        callvalidationfn = validation.Mask
        if (validationtypevariants[callvalidationfn]) {
            errortext = validationtypevariants[callvalidationfn](fieldValue, validation['Mask'][callvalidationfn], validation.Message)
            if (errortext) {
                return {
                    error: true,
                    errortext: errortext
                }
            }
            else {
                return {
                    error: error,
                    errortext: errortext
                }
            }
        }
    }
    if (validation.Type === 'L') {
        validationtypevariants = validationrules['Type'][validationtype]
        if (validation.Maxlen > 0) callvalidationfn = 'Maxlen'
        if (validation.Minlen > 0) callvalidationfn = 'Minlen'

    }
    if (validation.Type === 'R') {
        validationtypevariants = validationrules['Type'][validationtype]
        callvalidationfn = 'IsRequired'

    }
    if (validation.Type === 'G') {
        validationtypevariants = validationrules['Type'][validationtype]
        callvalidationfn = 'IsRange'

    }
    if (validation.Type === 'U') {
        validationtypevariants = validationrules['Type'][validationtype]
        callvalidationfn = 'Url'

    }
    if (validation.Type === 'X') {
        validationtypevariants = validationrules['Type'][validationtype]
        callvalidationfn = 'RegExp'

    }
    if (validation.Type === 'Y') {
        validationtypevariants = validationrules['Type'][validationtype]
        callvalidationfn = 'EStr'
    }
    if (validation.Type === 'Z') {
        validationtypevariants = validationrules['Type'][validationtype]
        callvalidationfn = 'Str'
    }
    if (validation.Type === 'S') {
        validationtypevariants = validationrules['Type'][validationtype]
        callvalidationfn = 'leadingorTrailing'

    }
    if (validationtypevariants) {
       // const validationtypekeys = Object.keys(validationtypevariants)
        //error = validationtypekeys.some(each => {
        if (validationtypevariants[callvalidationfn]) {
            let callvalidationAttr = callvalidationfn
            if (callvalidationfn === 'RegExp')
                callvalidationAttr = 'Mask';
            errortext = validationtypevariants[callvalidationfn](fieldValue, validation[callvalidationAttr], validation.Message, validation.Minlen, validation.Maxlen)
            if (errortext) {
                return {
                    error: true,
                    errortext: errortext
                }
            }
        }
        //})
    }
    return {
        error: error,
        errortext: errortext
    }

}



/**        
      *********************************************************
      currently not used anywhere. hanldes validations from json
      *********************************************************
**/

export const validate = (validations, fieldValues) => {
    if (Array.isArray(validations)) {
        const errors = {}
        const fieldNames = Object.keys(validations)
        fieldNames.forEach(fieldName => {
            const error = eachvalidate(fieldName, validations[fieldName], fieldValues[fieldName])
            errors[fieldName] = error
        })
        return errors
    }
    else {
        const keys = Object.keys(validations)
        const fieldName = keys[0]
        return eachvalidate(fieldName, validations[fieldName], fieldValues)
    }


}


/**        
      *********************************************************
      currently not used anywhere. hanldes validations from json
      *********************************************************
**/


const eachvalidate = (fieldName, validations, fieldvalue) => {
    let errortexts = ''
    let error = false
    const validationkeys = Object.keys(validations)
    validationkeys.forEach(each => {
        if (validationrules[each]) {
            if (each === 'Type') {
                const validationtype = validationrules['Type'][validations['Type']]
                if (validationtype) {
                    const errortext = validationtype(fieldvalue)
                    if (errortext) {
                        error = true
                        errortexts += errortext
                    }
                }

            }
            else {
                const errortext = validationrules[each](fieldvalue, validations[each])
                if (errortext) {
                    error = true
                    errortexts += errortext
                }
            }
        }
    })

    return {
        error: error,
        errortext: errortexts
    }
}
