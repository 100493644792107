import * as React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import * as DNDSettings from './util/dndSettings'
import CRowDroppable from './cRowDroppable'
import CSubSectionsContainer from './cSubSectionsContainer'

class CSectionDroppable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false
    }
  }

  OnMouseHover = () => {
    this.setState({ isHovering: true })
  }
  onMouseLeave = () => {
    this.setState({ isHovering: false })
  }

  render() {
    const { section, sectionConfig, rows, dropclazzName, dragclazzName, droppable, dropType, OnRowSettings } = this.props;
    const { OnAddSubSection, sections, getSubSections, OnAddRow, OnSectionSettings, OnSectionRemove, clazzName, wrapperWlazzName, sectiondroptype, direction, OnMoveDown, OnMoveUp, CanShowMoveUp, CanShowMoveDown, errors, isSectionsOpen } = sectionConfig;
    const subSections = getSubSections(sections, section) || [];

    return (
      <div >
        <Droppable droppableId={`SecDP-${droppable}`} type={dropType}>
          {(sdp, sds) => (
            <div ref={sdp.innerRef}
              {...sdp.draggableProps}
              {...sdp.dragHandleProps}
              className={dropclazzName}
              style={DNDSettings.getNestedListStyle(sds.isDraggingOver)}>
              {rows && rows.length > 0 && rows.map((row, index) => (
                <div>
                  <Draggable key={row.dndId} draggableId={`${row.dndId}`} index={index} isDragDisabled={row.canDrag === false}>
                    {(sdp2, sds2) => (
                      <div ref={sdp2.innerRef}
                        {...sdp2.draggableProps}
                        {...sdp2.dragHandleProps}
                        className={dragclazzName}
                        onMouseEnter={this.OnMouseHover}
                        onMouseLeave={this.onMouseLeave}
                        style={DNDSettings.getRowStyle(sds2.isDraggingOver, sdp2.draggableProps.style)}>
                        <div>
                          <CRowDroppable section={section} row={row} sdp={sdp2} dropclazzName={row.dropclazzName} dragclazzName={row.dragclazzName} OnSettings={OnRowSettings}
                            direction={row.direction} />
                        </div>
                        {sdp2.placeholder}
                      </div>
                    )}
                  </Draggable>
                </div>
              ))}
              {sdp.placeholder}
            </div>
          )}
        </Droppable>
        {subSections && subSections.length > 0 &&
          <div>

            < CSubSectionsContainer sectionConfig={sectionConfig} OnAddSubSection={OnAddSubSection} getSubSections={getSubSections} OnAddRow={OnAddRow}
              mainSections={subSections}
              OnSectionSettings={OnSectionSettings} OnRowSettings={OnRowSettings} OnSectionRemove={OnSectionRemove} droppableId={droppable}
              clazzName={clazzName} wrapperWlazzName={wrapperWlazzName} droptype={dropType} direction={direction} OnMoveDown={OnMoveDown} OnMoveUp={OnMoveUp}
              CanShowMoveUp={CanShowMoveUp} CanShowMoveDown={CanShowMoveDown} errors={errors} isSectionsOpen={isSectionsOpen} />
          </div>
        }
      </div>
    );
  }
}
export default CSectionDroppable;


