/**        
      *********************************************************
            Description: Manages group of checkboxes.
            Required props:  type, components. For each checkboxgroup child component options with name and value are required.
            limitations    : N/A
      *********************************************************
**/

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

const CCheckBoxGroup = (props) => {

    const { options, value, handleFieldChange, dropdownvalues, checkboxvalue, tooltip, edit } = props;
    const { label, labelcomp, controlcomp, name, items, disabled, select, transformProps, ...otherOptions } = options;
    let lists = items && items.length > 0 ? items : dropdownvalues && dropdownvalues.length > 0 ? dropdownvalues : []
    if (edit === false) {
        let result = lists && lists.filter(res => {
            if (res[transformProps.value] === value)
                return res
        })
        result = result && result[0]
        return <Fragment>
            {tooltip && <div > <Typography variant={'caption'} className="text-secondary">{label}  &nbsp; < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
            </Tooltip></Typography><br />
                <Typography variant={'body2'} className="fw-bold" color={'textPrimary'}>{result && result[transformProps.label || transformProps.name] || ''}       </Typography></div>
            }
            {!tooltip && <div > <Typography variant={'caption'} className="text-secondary">{label}</Typography><br />
                <Typography variant={'body2'} className="fw-bold" color={'textPrimary'}>{result && result[transformProps.label || transformProps.name] || ''}  </Typography>
            </div>}
        </Fragment >
    }
    else return (
        <Fragment>
            <FormControl component="fieldset" >  {/* required={props.required} */}
                {tooltip &&
                    <FormLabel component={labelcomp}>{label}&nbsp;< Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                        <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
                    </Tooltip></FormLabel>}
                {!tooltip &&
                    <FormLabel component={labelcomp}>{label}</FormLabel>}
                <FormGroup  >
                    {lists && lists.map(each => {
                        const { options, key } = each
                        let cvalue, clabel
                        if (transformProps) {
                            cvalue = each[transformProps.value]
                            clabel = each[transformProps.label || transformProps.name]
                        }
                        else {
                            cvalue = options.value
                            clabel = options.label
                        }
                        return <FormControlLabel key={key || cvalue + 'item'} onChange={(e) => handleFieldChange({ target: { value: e.target.value, name: label, checked: true } })} control={<Checkbox disabled={disabled} checked={value === cvalue}
                            value={cvalue || name} />} label={clabel} />
                    })}
                </FormGroup>
            </FormControl>
        </Fragment>
    )


}

export default CCheckBoxGroup;