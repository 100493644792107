import React, { useReducer, useEffect, useRef, useState, Fragment } from 'react';
import { layoutTypes } from '../layout/types'
import { handleActionsToEffects } from '../effects/effects'
import { fieldTypes, hookTypes } from '../helpers/fieldtypes'
import useDeepCompareEffect from 'use-deep-compare-effect'
import isEqual from 'lodash/isEqual'
import { groupByType } from '../helpers/components'
import { useHistory, useParams } from 'react-router-dom';

//container only maintains layout change
function handleFieldChange(state, action) {
    const { type, ...otherProps } = action
    if (type === 'updates') {
        const { updates } = otherProps
        const { name, value, type, addlayout, removelayout } = updates
        // const { addlayout, removelayout } = updates
        let newstate = state
        if (addlayout) {
            newstate = addlayout.reduce((accum, each) => {
                const { name } = each
                return {
                    ...accum, fieldProps: accum.fieldProps, fieldsLayout: {
                        ...accum.fieldsLayout,
                        group: accum.fieldsLayout.group.concat([
                            {
                                order: accum.fieldsLayout.group.length,
                                type: 'div',
                                className: 'row',
                                components: [
                                    {
                                        name: name
                                    }
                                ]
                            }
                        ])
                    }
                }
            }, newstate)
        }
        if (removelayout) {
            newstate = removelayout.reduce((accum, each) => {
                const { name } = each
                return {
                    ...accum, fieldProps: accum.fieldProps, fieldsLayout: {
                        ...accum.fieldsLayout,
                        group: accum.fieldsLayout.group.filter(each => {
                            const { components } = each
                            const compExists = components.find(e => e.name === name)
                            if (compExists) return false
                            else return true
                        })
                    }
                }
            }, newstate)
        }
        if (value) {
            value.forEach(each => {
                const { key, result } = each
                state = { ...state, sharedValues: { ...state.sharedValues, [key]: result } }
            })
            return newstate = { ...state }
        }
        return newstate
    }
    if (type === 'value') {
        const { name, value, changevalue } = otherProps
        if (changevalue) {
            return { ...state, sharedValues: { ...state['sharedValues'], [name]: value, [changevalue]: !value } }
        }
        return { ...state, sharedValues: { ...state['sharedValues'], [name]: value } }
    }
    /***  for click on next item, it will change shared values and usedeepcompare effect of shared values
          will be executed and then the same pattern of add layout will be followed taking new edit info values **/
    if (type === 'navigatenextitem') {
        const { name, value, subname } = otherProps
        let checkval = otherProps.name
        let completedata = Array.isArray(otherProps.value) ? otherProps.value : (otherProps.value && otherProps.value.data ? otherProps.value.data : [])
        let indexdata = state.sharedValues[checkval] ? state.sharedValues[checkval] : state.sharedValues[subname]
        let currentItem = completedata.indexOf(indexdata)
        let nextitemindex = currentItem + 1
        let nextItem = completedata[nextitemindex]
        if (nextitemindex < completedata.length) {
            return { ...state, sharedValues: { ...state['sharedValues'], [checkval]: nextItem, [subname]: nextItem, } }
        }
    }

    /***  for click on previous item, it will change shared values and usedeepcompare effect of shared values
      will be executed and then the same pattern of add layout will be followed taking new edit info values **/
    if (type === 'navigateprevitem') {
        const { name, value, subname } = otherProps
        let checkval = otherProps.name
        let completedata = Array.isArray(otherProps.value) ? otherProps.value : (otherProps.value && otherProps.value.data ? otherProps.value.data : [])
        let indexdata = state.sharedValues[checkval] ? state.sharedValues[checkval] : state.sharedValues[subname]
        let currentItem = completedata.indexOf(indexdata)
        let previtemindex = currentItem - 1
        let prevItem = completedata[previtemindex]
        if (previtemindex >= 0) {
            return { ...state, sharedValues: { ...state['sharedValues'], [name]: prevItem, [subname]: prevItem } }
        }
    }


    if (type === 'values') {
        const { values } = otherProps
        values.forEach(each => {
            const { name, value } = each
            state = { ...state, sharedValues: { ...state['sharedValues'], [name]: value } }
        })
        return state
    }
    if (type === 'customfields') {
        const { layout, components } = otherProps
        const { group, ...othersLayout } = layout
        return {
            ...state, fieldProps: { ...state.fieldProps, ...components }, fieldsLayout: {
                ...state.fieldsLayout, ...othersLayout,
                group: state.fieldsLayout.group.concat(group).reduce((acc, current) => {
                    const x = acc.find(item => item.order === current.order);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        const { position, components } = current
                        x.components.splice(position, 0, ...components)
                        return acc;
                    }
                }, [])
            }
        }
    }
    return state
}


export default function useContainer(props) {
    const { components, layout, values, mapValues, mapActionsToEffects, actions, handleScreenWidth, addRoute, reload = false, removelayout = false } = props
    const { layoutType } = layout
    const history = useHistory();
    let params = useParams();
    let sharedProps = props.sharedProps || {}
    const [fields, dispatchFieldsChange] = useReducer(
        handleFieldChange,
        { fieldProps: components, conditions: mapActionsToEffects, fieldsLayout: layout, sharedValues: { ...values, ...sharedProps } }
    )

    const { fieldProps, fieldsLayout, conditions, sharedValues } = fields
    const prevState = useRef(fields)
    const handleSave = () => {
        dispatchFieldsChange({
            type: 'value',
            name: 'save',
            value: true
        })
    }

    const handleSaveDuplicate = () => {
        dispatchFieldsChange({
            type: 'value',
            name: 'saveduplicate',
            value: true
        })
    }

    const handleEdit = () => {
        dispatchFieldsChange({
            type: 'value',
            name: 'edit',
            value: true
        })
    }

    const handleDuplicate = () => {
        dispatchFieldsChange({
            type: 'value',
            name: 'createduplicate',
            value: true
        })
    }
    /**method for clicking next item */
    const NavigatenextItem = () => {
        dispatchFieldsChange({
            type: 'navigatenextitem',
            name: 'navigation',
            subname: 'subnavigation',
            value: sharedProps['allgriddata']
        })
    }

    /**method for clicking previous item */
    const NavigateprevItem = () => {
        dispatchFieldsChange({
            type: 'navigateprevitem',
            name: 'navigation',
            subname: 'subnavigation',
            value: sharedProps['allgriddata']
        })
    }

    const handleBack = (isAdd = false) => {
        localStorage.setItem('BusinessRules', JSON.stringify([]))
        if (props.backtorecord) {
            let loc = history.location.pathname
            let path = loc.substring(0, loc.lastIndexOf("/"))
            let finalpath = path.substring(0, path.lastIndexOf("/"))
            history.push(finalpath)
        }
        else if (props.backtoapplicationsteps) {
            if (isAdd === true) {
                let loc = history.location.pathname
                history.push(loc)
            }
            else {
                let loc = history.location.pathname
                for (let index = 0; index < props.backtoapplicationsteps; index++) {
                    loc = loc.substring(0, loc.lastIndexOf("/"))
                }
                history.push(loc)
            }

        }
        else if (props.closepopup && props.closepopupval) {
            let loc = history.location.pathname
            let path = loc.substring(0, loc.lastIndexOf("/"))
            let lastSlash = loc.lastIndexOf("/");
            let lastSlashVal = loc.substring(lastSlash + 1);
            if (path.includes(props.closepopupval)) {
                actions && actions.toggleDrawer()
                history.push(path)
            }
            else if (lastSlashVal == props.closepopupval) {
                actions && actions.toggleDrawer()
                history.push(loc)
            }
        }
        else if (addRoute) {
            if (removelayout) {
                dispatchFieldsChange({
                    type: 'updates',
                    updates: {
                        removelayout: [{ 'name': removelayout }]
                    }
                })
            }
            actions && actions.toggleDrawer()
            history.goBack()
        }
        // else if (reload) {
        //     history.go(0)
        // }
        else {
            //if (!isAdd)
            setTimeout(() => {
                actions && actions.toggleDrawer()
            }, 1000)

        }

    }

    const handleFullScreen = () => {
        dispatchFieldsChange({
            type: 'value',
            name: 'fullscreen',
            value: true,
            changevalue: 'exitfullscreen'
        })
        handleScreenWidth && handleScreenWidth('fullscreen')
    }

    const handleExitFullScreen = () => {
        dispatchFieldsChange({
            type: 'value',
            name: 'exitfullscreen',
            value: true,
            changevalue: 'fullscreen'
        })
        handleScreenWidth && handleScreenWidth('exitfullscreen')
    }

    const handleCustomActions = (e, name) => {
        if (mapActionsToEffects && mapActionsToEffects[name]) {
            handleEffectUpdates(
                handleActionsToEffects({
                    conditions: (mapActionsToEffects[name])
                })
            )
        }

    }

    useDeepCompareEffect(() => {
        dispatchFieldsChange({
            type: 'values',
            values: Object.keys(sharedProps).map(each => {
                return {
                    name: each,
                    value: sharedProps[each]
                }
            })
        })
    }, [sharedProps])


    const handleEffectUpdates = (res) => {
        if (res)
            Promise.all(res).then(results => {
                dispatchFieldsChange({
                    type: 'updates',
                    updates: groupByType(results)
                })
            })
    }

    useDeepCompareEffect(() => {
        const prevsharedValues = prevState.current.sharedValues
        const status = isEqual(prevsharedValues, sharedValues)
        if (!status && sharedValues && Object.keys(sharedValues).length > 0) {
            const unEqualProps = Object.keys(sharedValues).filter(each => sharedValues[each] != prevsharedValues[each])
            if (unEqualProps)
                (unEqualProps || []).forEach(each => {
                    if (conditions && conditions[each]) {
                        handleEffectUpdates(
                            handleActionsToEffects({
                                fieldValues: { ...sharedValues, ...params },
                                conditions: conditions[each]
                            })
                        )
                    }
                    if (conditions && (conditions[each] && conditions[each].add)) {
                        dispatchFieldsChange({
                            type: 'values',
                            values: [{
                                name: each,
                                value: sharedValues[each]
                            }]
                        })
                    }
                    if (each == 'navigation' || each == 'subnavigation') {
                        props.sharedProps && props.sharedProps.navigateItem && props.sharedProps.navigateItem(sharedValues[each])
                    }
                })
        }
        prevState.current = fields

    }, [sharedValues])



    const addons = {
        dispatchSharedValueChange: dispatchFieldsChange,
        handleBack: handleBack,
        addOnsByName: { // change handle custom and remove duplicates
            save: { onClick: handleSave }, history: { onClick: (e) => handleCustomActions(e, 'history') },
            saveduplicate: { onClick: handleSaveDuplicate }, edit: { onClick: handleEdit }, createduplicate: { onClick: handleDuplicate },
            back: { onClick: handleBack }, previtem: { onClick: NavigateprevItem }, nextitem: { onClick: NavigatenextItem },
            fullscreen: { onClick: handleFullScreen }, exitfullscreen: { onClick: handleExitFullScreen }
        }
    }

    const componentsCreation = (fieldNames) => {
        const temp = fieldNames.reduce((accum, each) => {
            try {
                const { type, custom, name, ...otherProps } = fieldProps[each]
                const passProps = (mapValues[name] || []).reduce((sum, each) => {
                    const { key, name } = each
                    sum[name] = sharedValues[key]
                    return sum
                }, {})

                if (fieldTypes[type]) {
                    const EachComp = fieldTypes[type];
                    accum[each] = <EachComp {...addons} {...otherProps} sharedProps={passProps} accessPermissions={props.accessPermissions} routeparams={params} />
                }
                else if (hookTypes[type]) {
                    const temp = hookTypes[type]({ ...otherProps, ...addons, sharedProps: passProps, accessPermissions: props.accessPermissions, routeparams: params, actions: actions })
                    accum[each] = temp[0]
                }
                else if (custom) accum[each] = type
                return accum
            }
            catch (ex) { console.log(ex); return "n/a" }
        }, {})
        return temp
    }

    return [
        <Fragment>
            {layoutType && layoutTypes[layoutType] ?
                layoutTypes[layoutType]({
                    layout: fieldsLayout,
                    fields: componentsCreation(Object.keys(fieldProps))

                }) :
                layoutTypes.default({
                    layout: fieldsLayout,
                    fields: componentsCreation(Object.keys(fieldProps))
                })
            }
        </Fragment>
    ]


}

