import React, { useEffect, useState, Fragment } from 'react'
import { getEnvVariable } from '../../../../modules/environmentalList';
import { createRequest, requestApi } from '../../helpers/rest';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Dropdown from '../../../modules/form/cformdropdown';
import Switch from '../../../modules/form/cformswitch';
import TextBox from '../../../modules/form/cformtextbox';
import { IconButton } from '@material-ui/core';

export default function CustomAdminLayer(props) {
    const { label, transformProps, edit, error, required, isCombo, handleFieldChange, dropdownvalues } = props;
    const [domainlist, setDomain] = useState([]);
    const [add, setAdd] = useState(false);
    const [filtertypelist, setFilterType] = useState([])
    let value = props.value && JSON.parse(props.value) || [];

    useEffect(() => {
        const read = [
            {
                url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=LAYER_FILTERTYPE`,
                type: 'get'
            },
            {
                "url": getEnvVariable('AdminConfig') + `/api/Domains/All`,
                type: 'get'
            },
        ]
        Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(response => {
            const [list1, list2] = response;
            setDomain(list2)
            setFilterType(list1)

        })
    }, [])

    const handleChange = (evt, index, type = '') => {
        const { name } = evt.target;
        if (value && value[index]) {
            value[index][name] = (evt.target.value || type === 'TB' ? evt.target.value : (evt.target.checked && evt.target.checked === true ? 'Y' : 'N'))
        }
        else {
            let subvalue = Object.assign({}, { [name]: evt.target.value })
            value.push(subvalue)
        }
        setAdd(false);
        handleFieldChange({ target: { name: props.name, value: JSON.stringify(value), checked: false } })
    }

    const handleDelete = (evt, index) => {
        value.splice(index, 1);
        setAdd(false);
        handleFieldChange({ target: { name: props.name, value: JSON.stringify(value), checked: false } })
    }

    return (
        <div className="row col-sm-12">
            <small className="fw-bold" style={{ fontSize: '12px' }}>{label} {edit && <IconButton size="small" color='primary' onClick={() => setAdd(true)} ><AddIcon />  </IconButton>}</small><br />
            {add && <LayerTemplate handleChange={handleChange} edit={true} dropdownvalues={dropdownvalues} filtertypelist={filtertypelist}
                domainlist={domainlist} transformProps={transformProps} />}
            {value && value.length > 0 && value.map((each, index) => {
                return <small className="row col-sm-12"><LayerTemplate isCombo={isCombo} value={each} index={index} edit={edit}
                    dropdownvalues={dropdownvalues} filtertypelist={filtertypelist} domainlist={domainlist} transformProps={transformProps}
                    handleChange={handleChange} /> {edit && <IconButton size="small" color="primary" onClick={(evt) => handleDelete(evt, index)} ><DeleteIcon />  </IconButton>}</small>
            })}
        </div>
    )

}


function LayerTemplate(props) {

    const { handleChange, value, dropdownvalues, filtertypelist, domainlist, edit, transformProps, index, isCombo } = props;
    return <div className="row col-sm-12">
        <div className={isCombo ? "col-sm-2" : 'col-sm-4'}>
            <Dropdown edit={edit} name="fieldName" label={'Field Name'} value={(value && value.fieldName) || ''}
                dropdownvalues={dropdownvalues ? dropdownvalues : ((value && value.fieldName) ? [{ Name: value.fieldName, Value: value.fieldName }] : [])}
                transformProps={transformProps} handleFieldChange={(evt) => handleChange(evt, index)} />
        </div>
        {isCombo && value && value.fieldName && <div className="col-sm-3">
            <Dropdown edit={edit} name="filterType" label={'Filter Type'} value={(value && value.filterType) || ''}
                dropdownvalues={filtertypelist ? filtertypelist : ((value && value.filterType) ? [{ Name: value.filterType, Value: value.fieldName }] : [])}
                transformProps={transformProps} handleFieldChange={(evt) => handleChange(evt, index)} />
        </div>}
        {isCombo && value && value.filterType === 'SCM' && <div className="col-sm-2">
            <Switch edit={edit} label="Domain?" handleFieldChange={(evt) => handleChange(evt, index)} name="isDomain" value={value.isDomain || ''}
                transformProps={{
                    'true': "Y",
                    'false': "N",
                    "Y": true,
                    "N": false
                }} />
        </div>}
        {isCombo && value && value.isDomain === 'Y' && <div className="col-sm-4">
            <Dropdown label={'Domain List'} edit={edit} handleFieldChange={(evt) => handleChange(evt, index)} name="lookUp" value={value.lookUp || ''}
                dropdownvalues={domainlist || []} transformProps={{
                    "value": "Key",
                    "name": "Name"
                }} />
        </div>}
        {value && (value.filterType === 'STB' || value.label || !isCombo) &&
            <div className={isCombo ? "col-sm-2" : 'col-sm-4'}>
                <TextBox label={'Label'} edit={edit} handleFieldChange={(evt) => handleChange(evt, index, 'TB')} name="label" value={value.label || ''} />
            </div>
        }
    </div>
}