import { AppBar, Toolbar, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MobileStepper from '@material-ui/core/MobileStepper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InfoIcon from '@material-ui/icons/Info';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { PostData } from '../../core/controls/helpers/requests';
import { requestApi } from '../../core/modules/form/utilities';
import { getEnvVariable } from '../environmentalList';
import Heading from './heading';
import Drawer from '@material-ui/core/Drawer';
import Editor from '../../core/modules/form/cwysiwyg';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
  root: {
    maxWidth: window.innerwidth,
    flexGrow: 1,
  },
  showbutton: {
    // margin: 15,
    // display: 'inline-block',
    // disable : false
  },
  hidebutton: {
    //margin: 15,
    display: 'none',
    // disable : true
  }
};


const checkwidth = 960

export class SignUp extends Component {
  state = {
    activeStep: 3,
    openpolicy: false,
    editorval: null
  };



  componentDidMount() {
    requestApi({ get: { url: getEnvVariable('Application') + `/api/EmailConfiguration/Key?key=TERMSANDCONDITIONS_DOTTNC` } }, null, null).then(results => {
      this.setState({
        editorval: results.Body
      })
    })
  }



  validateusernameandsubmit = () => {
    const { values, errors, singuperrorupdate, signuperrors } = this.props;
    let exp = "^[A-Za-z0-9._]{5,50}$"
    let newreg = new RegExp(exp)
    let correctvalidation = true;
    if (values && values.UserName && (!newreg.test(values.UserName))) {
      correctvalidation = false;
    }
    if (values && values.UserName) {
      if (!correctvalidation) {
        this.props.singuperrorupdate({
          signUpError: {
            ...signuperrors,
            UserName: { error: true, errortext: 'Must be a valid username', isRequired: true }
          },
          HasValidationError: true
        })
      }
      //Validating the uniqueness of user username and proceed
      else {
        const value = values.UserName;
        var externalUsernameReqURL = {
          "get": {
            "url": getEnvVariable('MasterAdmin') + `/api/CompanyExternal/UniqueUserName/${getEnvVariable('ModuleKey')}?userName=${value}`
          }
        };
        requestApi(externalUsernameReqURL, '', null, null, true).then(results => {
          if (results && results.isValid) {
            this.submit();
          }
          else {
            this.props.singuperrorupdate({
              signUpError: {
                ...signuperrors,
                UserName: { error: true, errortext: 'User Name already exits, Please try with another User Name', isRequired: true }
              },
              HasValidationError: true
            })
          }
        }).catch(error => {
          error.then(err => {
            this.props.singuperrorupdate({
              signUpError: {
                ...signuperrors,
                UserName: { error: true, errortext: 'User Name already exits, Please try with another User Name', isRequired: true }
              },
              HasValidationError: true
            })

          })

        })
      }
    }

  }

  submit = () => {
    let values = this.props.values;
    let url = getEnvVariable('MasterAdmin') + `/api/${getEnvVariable('ModuleKey')}CompanyExternal/` + getEnvVariable('ModuleKey') + `/${getEnvVariable('ModuleKey')}Add`
    PostData(url, values, true).then(res => {
      this.props.nextStep();
    }).catch(error => {
      error.then(err => {
        if (err)
          this.props.errorSubmit(err);
        else this.props.nextStep();
      })
    })
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ openpolicy: open })
  };

  handleFieldChange = (event) => {
    console.log(event);
  }

  getTerms = () => {
    let type = getEnvVariable('ModuleKey')
    switch (type) {
      case 'AMES':
      case 'WRS':
        return ' I authorize the Wildlife Reporting System to contact me using the email address provided. Emails will be automatically generated and sent to the provided  email address by the system'
      default: return 'By checking the above checkbox you are agreeing to all terms and conditions of the department for using this online system.'
    }
  }

  render() {
    const { values, handleChange, handleChecked, classes, signuperrors, handlerecaptchachange } = this.props;
    return (
      <div className='col-sm-12'>
        <Heading subheading="Username Information" />
        <div className="row">
          <div className="col-sm-12">
            <TextField
              label="Username"
              onChange={handleChange('UserName')}
              value={values.UserName}
              required
              fullWidth
              error={signuperrors && signuperrors.UserName && signuperrors.UserName.error ? signuperrors.UserName.error : false}
              helperText={(signuperrors && signuperrors.UserName && signuperrors.UserName.error) ? signuperrors.UserName.errortext : ""}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <Typography variant="caption" className="pl-2" gutterBottom><InfoIcon color="primary" />
            Username may contain 5-50 characters, letters (a-z)(A-Z),numbers(0-9), period(.) and underscore(_).
          </Typography>
        </div>
        <FormControlLabel
          control={<Checkbox onChange={handleChecked('AgreeTerms')} value="AgreeTerms" />}
          label={<Box component="div" fontSize={12}>
            {this.getTerms()}
          </Box>}
        />
        <br />
        <Button size="small" variant="contained" color="primary" onClick={this.toggleDrawer(true)} >
          View terms and conditions
        </Button>
        <Drawer
          anchor={'right'}
          open={this.state.openpolicy}
          onClose={this.toggleDrawer(false)}
          key="slider"
          disableEnforceFocus={true}
          disableAutoFocus={true}
          PaperProps={{ style: { width: window.innerWidth - 600 } }}
        >
          <AppBar position="sticky" color='secondary'>
            <Toolbar variant="dense">
              <i className="float-right" onClick={this.toggleDrawer(false)} ><IconButton title="Close" color="inherit">
                <CloseIcon fontSize="small" />
              </IconButton></i>
            </Toolbar></AppBar>
          <Editor name={'Terms and conditions'} readonlyandprint={true} edit={true} handleFieldChange={this.handleFieldChange} value={this.state.editorval} label={'Terms and conditions'} />
        </Drawer>
        {values && values.UserName && <ReCAPTCHA sitekey={'6Lcfh6sUAAAAALUwhdig01KatfUoQhm_KjNsZ6TU'} onChange={handlerecaptchachange} />}
        <div className="row">
          <MobileStepper
            variant="dots"
            steps={4}
            position="static"
            activeStep={this.state.activeStep}
            className={classes.root}
            nextButton={
              <Button size="small" onClick={this.validateusernameandsubmit}
                disabled={(values.AgreeTerms == 'Y' && values.ReCaptcha == 'Y') ? false : true}
              // style={(values.AgreeTerms == 'Y' && values.ReCaptcha == 'Y') ? styles.showbutton : styles.hidebutton}
              >
                Submit
                {<KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={this.back}>
                {<KeyboardArrowLeft />}
                Back
              </Button>
            }
          />
        </div>

      </div>
    );
  }
}



export default withStyles(styles)(SignUp);
