import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { GridColumn, Grid, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { CustomColumnMenu } from './customColumnMenu.jsx';
import { toDataSourceRequestString, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { withRouter } from 'react-router-dom';
import GridHeader from './cgridheader'
import ReactResizeDetector from 'react-resize-detector';
import { getToken } from '../header';
import moment from 'moment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GetData } from '../../controls/helpers/requests.js';
import ClearIcon from '@material-ui/icons/Clear';
import { getEnvVariable } from '../../../modules/environmentalList';
import LeftSideBar from '../../../custommodules/newentrancepermit/applicationdrawer';
import { ExternalApplicationSidebar } from '../../../json/newapplication/externalapplicationsidebar';
import { NewApplicationSidebar } from '../../../json/newapplication/newapplicationsidebar';
import { handlecustomCondition } from '../../modules/form/conditions';

class FilteredNameHeader extends React.Component {
    render() {
        return (
            <a className="k-link" onClick={this.props.onClick}>
                <span style={{ color: "#1976d2" }} className="k-icon k-i-filter"></span>
                <span style={{ color: "#1976d2" }}>{this.props.title}</span>
                {this.props.children}
            </a>
        );
    }
}

class NameHeader extends React.Component {
    render() {
        return (
            <a className="k-link" onClick={this.props.onClick}>
                <span>{this.props.title}</span>
                {this.props.children}
            </a>
        );
    }
}

class CInterchangableGrid extends React.Component {
    constructor(props) {
        super(props);
        this.token = getToken();
        this.state = {
            dataState: { skip: 0, take: 20 },
            pageable: {
                buttonCount: 2,
                info: true,
                type: 'numeric',
                pageSizes: [10, 20, 30, 40, 50, 100],
                previousNext: true
            }, tabValue: 0, filteredfield: [], initialloadextrainfo: null
        };
    }

    gridWorkflowColumns = [
        {
            "title": "Field Name",
            "field": "Field",
            "show": true,
            width: 200
        },
        {
            "title": "Old Value",
            "field": "OldValueDesc",
            "show": true,
            width: 200
        },
        {
            "title": "New Value",
            "field": "NewValueDesc",
            "show": true,
            width: 200
        },
        {
            "title": "Updated By",
            "field": "UpdatedBy",
            "show": true,
            width: 200
        },
        {
            "title": "Updated On",
            "field": "UpdatedOn",
            "show": true,
            width: 200
        },
        // {
        //     "title": "Comments",
        //     "field": "Comments",
        //     "show": true,
        //     width: 200
        // }
    ]

    componentDidMount() {
        const { useComp, gridkey, options } = this.props;
        const { crud } = options || {}
        if (crud && crud.customcondition) {
            this.LoadExtraInfo();
        }
        this.fetchData(this.state.dataState);
    }

    async LoadExtraInfo() {
        let initialloadextrainfodata = await handlecustomCondition(this.props)
        this.setState({ initialloadextrainfo: initialloadextrainfodata })
    }

    handleRowClick = (gridRowClickEvent) => {
        console.log('gridrowclickevent', gridRowClickEvent)
    }

    handleDataStateChange = (changeEvent) => {
        this.setState({ dataState: changeEvent.dataState });
        this.fetchData(changeEvent.dataState);
    }

    columnProps(field) {
        if (!this.state.filteredfield.includes(field)) {
            if (this.isColumnActive(field, this.state.dataState)) {
                this.setState({ filteredfield: [...this.state.filteredfield, field] })
            }
        }
    }

    isColumnActive(field, dataState) {
        return GridColumnMenuFilter.active(field, dataState.filter)
    }

    handleStatusStyle = (name, transformProps) => (event) => {
        const val = name ? name.split(".").reduce((res, prop) => { return res && res[prop] }, event.dataItem) : null
        console.log('handleStatusStyle-----', val);
        if (event.dataItem.Status) {
            if (event.dataItem.Status == 'I') {
                return (
                    <td>
                        <span className="badge badge-pill badge-danger">IN-ACTIVE</span>
                    </td>
                );
            }
            else if (event.dataItem.Status == 'A') {
                return (
                    <td>
                        <span className="badge badge-pill badge-light">ACTIVE</span>
                    </td>
                );
            }
            else {
                return (
                    <td>
                        event.dataItem.Status
                    </td>
                );
            }
        }
        else return <td>{transformProps[val]}</td>

    }

    handleGridForm = (name, transformProps) => (event) => {
        const val = name ? name.split(".").reduce((res, prop) => { return res && res[prop] }, event.dataItem) : null;
        return <td>{transformProps[val]}</td>
    }
    /**
     *********************************************************
     Description: Handle Back to navigate to custom and history screen
     Required props:  back url
     limitations    : N/A
     *********************************************************
**/
    handleBack = () => {
        const { options } = this.props
        const { back } = options;
        const { routes } = back;
        if (Array.isArray(routes)) {
            let path = ''
            routes.forEach(each => {
                const { route, routeParams, matchProps } = each
                if (routeParams) {
                    path += route + "/" + this.props.match.params[routeParams]
                }
                else if (matchProps) {
                    path += route + "/" + this.props.results[matchProps] //--- correct unsused
                }
                else {
                    path += route
                }
            })
            this.props.history.push(path)
        }
    }


    fetchData(dataState, value, action) {
        const queryStr = `${toDataSourceRequestString(dataState)}`; // Serialize the state
        const hasGroups = dataState.group && dataState.group.length;

        const { options, components, defaults, order, type, compmap, rules, accessPermissions, accessLevelPermissions, ...otherProps } = this.props;
        const { gridcolumns } = options
        const { routes, crud } = options
        const { read, create, update } = crud
        const del = crud.delete
        const { get } = read
        const { routeProps, matchProps, noquery } = get ? get : {}
        let url = (this.state.tabValue == 2) ? get.gisurl : get.url;

        if (Array.isArray(routeProps)) {
            routeProps.forEach(each => {
                const { value } = each
                const id = routeProps ? this.props.match.params[value] : null
                url = `${url.replace(`{${value}}`, id)}`

            })
        }
        else if (routeProps) {
            const id = routeProps ? this.props.match.params[routeProps.value] : null
            url = `${get.url.replace(`{${routeProps.value}}`, id)}`;
        }
        const urlPath = options.crud.read.get.noquery && options.crud.read.get.noquery == true ? `${url}?submodulekey=${this.props.match.params.key}&${queryStr}` : `${url}?${queryStr}`;

        GetData(urlPath).then((data) => {
            if (data) {
                data.map(res => {
                    if (res.UpdatedOn) {
                        res.UpdatedOn = res.UpdatedOn !== null ? moment(res.UpdatedOn).format('MM/DD/YYYY') : ""
                    }
                })
            };
            this.setState({
                data: data
            });
        });

        const urlPath2 = getEnvVariable('AdminConfig') + `/api/SitePermitTypeWorkflow/history/` + this.props.match.params['routeid'];
        if (this.props.match.params['routeid'] && urlPath2) {
            GetData(urlPath2).then((data) => {
                if (data && data.data) {
                    data.data.map(res => {
                        if (res.UpdatedOn) {
                            res.UpdatedOn = res.UpdatedOn !== null ? moment(res.UpdatedOn).format('MM/DD/YYYY') : ""
                        }
                    })
                }
                this.setState({
                    workflowdata: data
                });
            });
        }

        if (action === 'filterall') {
            let tempString = urlPath.split('?')
            GetData(tempString[0]).then(result => {
                result && Array.isArray(result) && result.map(res => {
                    if (res.CreatedOn) {
                        res.CreatedOn = res.CreatedOn !== null ? moment(res.CreatedOn).format('MM/DD/YYYY hh:mm:ss A ') : ""
                    } if (res.UpdatedOn) {
                        res.UpdatedOn = res.UpdatedOn !== null ? moment(res.UpdatedOn).format('MM/DD/YYYY hh:mm:ss A') : ""
                    }
                    if (res.LoginDate) {
                        res.LoginDate = res.LoginDate !== null ? moment(res.LoginDate).format('MM/DD/YYYY hh:mm:ss A') : ""
                    } if (res.NoticeDate) {
                        res.NoticeDate = res.NoticeDate !== null ? moment(res.NoticeDate).format('MM/DD/YYYY') : ""
                    }
                    if (res.Body) {
                        let bodyval = res.Body !== null ? atob(res.Body) : ""
                        res.Body = bodyval.replace(/<\/?[^>]+(>|$)/g, "")
                    }
                })
                this._export.save(result, this.gridWorkflowColumns);
            })
        }
        else if (action === 'filtercurrent') {
            GetData(urlPath).then(result => {
                result && Array.isArray(result) && result.map(res => {
                    if (res.CreatedOn) {
                        res.CreatedOn = res.CreatedOn !== null ? moment(res.CreatedOn).format('MM/DD/YYYY hh:mm:ss A ') : ""
                    } if (res.UpdatedOn) {
                        res.UpdatedOn = res.UpdatedOn !== null ? moment(res.UpdatedOn).format('MM/DD/YYYY hh:mm:ss A') : ""
                    }
                    if (res.LoginDate) {
                        res.LoginDate = res.LoginDate !== null ? moment(res.LoginDate).format('MM/DD/YYYY hh:mm:ss A') : ""
                    } if (res.NoticeDate) {
                        res.NoticeDate = res.NoticeDate !== null ? moment(res.NoticeDate).format('MM/DD/YYYY') : ""
                    }
                    if (res.Body) {
                        let bodyval = res.Body !== null ? atob(res.Body) : ""
                        res.Body = bodyval.replace(/<\/?[^>]+(>|$)/g, "")
                    }
                })
                this._export.save(result, this.gridWorkflowColumns);
            })
        }
    }

    handleTabChange = (event, value) => {
        let dataState = {
            skip: 0, take: 20
        }
        this.setState({ tabValue: value, filteredfield: [], dataState: dataState }, () => {
            this.fetchData(this.state.dataState);
        });
    }

    // columnProps(field) {
    //     // field: field,
    //     if (this.isColumnActive(field, this.state.dataState))
    //         return 'border border-dark '
    //     else return ''

    // }

    // isColumnActive(field, dataState) {
    //     return GridColumnMenuFilter.active(field, dataState.filter)
    // }

    onColumnsSubmit = (columnsState) => {
        // this.setState({
        //     columns: columnsState,
        //     data: this.state.data
        // });
    }

    clearFilters = () => {
        let dataState = {
            skip: 0, take: 20
        }
        if (this.props.location.pathname)
            localStorage.removeItem(this.props.location.pathname);
        this.setState({ filteredfield: [], dataState: dataState })
        this.fetchData(dataState);
    }

    _export;
    _grid;
    export = (action) => {
        this.fetchData(this.state.dataState, null, action)
    }

    saveReport = (cols) => {
        console.log("save report is clicked", cols);
        const { options } = this.props
        const { crud } = options
        const { read } = crud
        let gridoptions = {
            excel: {
                fileName: "Excel.xlsx",
                allPages: true,
            },
            dataSource: {
                type: "webapi",
                transport: {
                    read: {
                        url: read.get.url,
                        headers: this.headers
                    }
                },
                change: () => {
                },
                pageSize: 20,
                total: this.state.data.length,
                skip: 0,
                take: 20,
                serverPaging: true,
                serverSorting: true,
                serverFiltering: true,
                serverGrouping: true,
                serverAggregates: true,
                schema: {
                    data: "data",
                    total: "total",
                    errors: "errors"
                }
            },
            change: () => this.onChange(),
            pageable: {
                refresh: true,
                buttonCount: 5,
                pageSizes: [10, 20, 30, 40, 50, 100],
            },
            dataBound: () => this.onLoad(),
            selectable: 'single row',
            columnMenu: true,
            sortable: true,
            resizable: true,
            scrollable: true,
            reorderable: true,
            columns: (this.state.columns || []).filter(each => {
                if (each.show)
                    return { ...each, width: each.width || 200, filterable: each.filterable || { multi: true, search: true } }
            }),
            noRecords: {
                template: "<p class='lead'>No data available.</p>"
            },
        }
        console.log("settings" + JSON.stringify(gridoptions))
        this.setState({ savereprt: true, gridoptions: JSON.stringify(gridoptions) })
    }


    render() {
        const { options, components, defaults, order, type, compmap, rules, accessPermissions, accessLevelPermissions, ...otherProps } = this.props;
        const { headerProps } = options || {}
        return (
            <React.Fragment>
                <ReactResizeDetector handleWidth handleHeight>
                    {({ width, height }) =>
                        <React.Fragment>
                            <GridHeader initialloadextrainfo={this.state.initialloadextrainfo} headerProps={headerProps}
                                export={(evt) => {
                                    this.export(evt)
                                }}
                                accessPermissions={accessPermissions} accessLevelPermissions={accessLevelPermissions}
                                clearFilters={this.clearFilters} handleBack={this.handleBack} />
                            <Tabs
                                value={this.state.tabValue}
                                onChange={this.handleTabChange}
                                variant="fullWidth"
                                aria-label="icon label tabs example"
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab value={0} label="Permit History" />
                                {/* <Tab value={1} label="Workflow History" />*/}
                                <Tab value={2} label="GIS History" />
                            </Tabs>

                            {this.state.tabValue === 0 &&
                                <ExcelExport
                                    data={this.state.data}
                                    ref={exporter => this._export = exporter}
                                >
                                    <Grid
                                        sortable={true}
                                        pageable={this.state.pageable}
                                        total={this.state.total}
                                        data={(this.state.data && this.state.data.length) ? this.state.data.slice(this.state.dataState.skip, this.state.dataState.take + this.state.dataState.skip) : []}
                                        {...this.state.dataState}
                                        onDataStateChange={this.handleDataStateChange}
                                        style={{ height: '550px' }}
                                        resizable
                                    >
                                        {this.gridWorkflowColumns && this.gridWorkflowColumns.map((each, index) => {
                                            const { name, transformProps } = each
                                            if (name) return <GridColumn {...each} key={index} cell={this.handleGridForm(name, transformProps)} />
                                            else {
                                                if (each.field == 'Status')
                                                    return <GridColumn {...each} headerClassName={this.columnProps(each.field)} headerCell={this.state.filteredfield.length > 0 && this.state.filteredfield.includes(each.field) ? FilteredNameHeader : NameHeader} filter={each.filter} key={index} cell={this.handleStatusStyle(name)} columnMenu={
                                                        props =>
                                                            <CustomColumnMenu
                                                                {...props}
                                                                columns={this.gridWorkflowColumns}
                                                                saveReport={this.saveReport}
                                                                onColumnsSubmit={this.onColumnsSubmit}
                                                            />
                                                    } />
                                                else if (each.field == 'CreatedOn' || each.field == 'UpdatedOn')
                                                    return <GridColumn {...each} headerClassName={this.columnProps(each.field)} headerCell={this.state.filteredfield.length > 0 && this.state.filteredfield.includes(each.field) ? FilteredNameHeader : NameHeader} key={index} filter={'date'} format="{0:d}" columnMenu={
                                                        props =>
                                                            <CustomColumnMenu
                                                                {...props}
                                                                columns={this.gridWorkflowColumns}
                                                                saveReport={this.saveReport}
                                                                onColumnsSubmit={this.onColumnsSubmit}
                                                            />
                                                    } />
                                                else
                                                    return <GridColumn {...each} headerClassName={this.columnProps(each.field)} headerCell={this.state.filteredfield.length > 0 && this.state.filteredfield.includes(each.field) ? FilteredNameHeader : NameHeader} filter={each.filter} key={index} columnMenu={
                                                        props =>
                                                            <CustomColumnMenu
                                                                {...props}
                                                                columns={this.gridWorkflowColumns}
                                                                saveReport={this.saveReport}
                                                                onColumnsSubmit={this.onColumnsSubmit}
                                                            />
                                                    } />
                                            }
                                        })}
                                    </Grid>
                                </ExcelExport>}

                            {this.state.tabValue === 1 &&
                                <ExcelExport
                                    data={this.state.workflowdata}
                                    ref={exporter => this._export = exporter}
                                >
                                    <Grid
                                        sortable={true}
                                        pageable={this.state.pageable}
                                        total={this.state.total}
                                        //  data={(this.state.data && this.state.data.length )?this.state.workflowdata.slice(0, 20 + 0):[]}
                                        data={(this.state.workflowdata && this.state.workflowdata.length) ? this.state.workflowdata.slice(this.state.dataState.skip, this.state.dataState.take + this.state.dataState.skip) : []}

                                        {...this.state.dataState}
                                        //style={{ height: (isNaN(height) ? 500 : height) - 110 }}
                                        style={{ height: '550px' }}
                                        resizable
                                    >
                                        {this.gridWorkflowColumns && this.gridWorkflowColumns.map((each, index) => {
                                            const { name, transformProps } = each
                                            if (name) return <GridColumn {...each} key={index} cell={this.handleGridForm(name, transformProps)} />
                                            else {
                                                if (each.field == 'Status')
                                                    return <GridColumn {...each} headerClassName={this.columnProps(each.field)} headerCell={this.state.filteredfield.length > 0 && this.state.filteredfield.includes(each.field) ? FilteredNameHeader : NameHeader} filter={each.filter} key={index} cell={this.handleStatusStyle(name)} columnMenu={
                                                        props =>
                                                            <CustomColumnMenu
                                                                {...props}
                                                                columns={this.gridWorkflowColumns}
                                                                saveReport={this.saveReport}
                                                                onColumnsSubmit={this.onColumnsSubmit}
                                                            />
                                                    } />
                                                else if (each.field == 'CreatedOn' || each.field == 'UpdatedOn')
                                                    return <GridColumn {...each} headerClassName={this.columnProps(each.field)} headerCell={this.state.filteredfield.length > 0 && this.state.filteredfield.includes(each.field) ? FilteredNameHeader : NameHeader}key={index} filter={'date'} format="{0:d}" columnMenu={
                                                        props =>
                                                            <CustomColumnMenu
                                                                {...props}
                                                                columns={this.gridWorkflowColumns}
                                                                saveReport={this.saveReport}
                                                                onColumnsSubmit={this.onColumnsSubmit}
                                                            />
                                                    } />
                                                else
                                                    return <GridColumn {...each} headerClassName={this.columnProps(each.field)} headerCell={this.state.filteredfield.length > 0 && this.state.filteredfield.includes(each.field) ? FilteredNameHeader : NameHeader} filter={each.filter} key={index} columnMenu={
                                                        props =>
                                                            <CustomColumnMenu
                                                                {...props}
                                                                columns={this.gridWorkflowColumns}
                                                                saveReport={this.saveReport}
                                                                onColumnsSubmit={this.onColumnsSubmit}
                                                            />
                                                    } />
                                            }
                                        })}
                                    </Grid>
                                </ExcelExport>
                            }

                            {this.state.tabValue === 2 &&
                                <ExcelExport
                                    data={this.state.data}
                                    ref={exporter => this._export = exporter}
                                >
                                    <Grid
                                        sortable={true}
                                        pageable={this.state.pageable}
                                        total={this.state.total}
                                        data={(this.state.data && this.state.data.length) ? this.state.data.slice(this.state.dataState.skip, this.state.dataState.take + this.state.dataState.skip) : []}
                                        {...this.state.dataState}
                                        {...this.state.dataState}
                                        onDataStateChange={this.handleDataStateChange}
                                        style={{ height: '550px' }}
                                        resizable
                                    >
                                        {this.gridWorkflowColumns && this.gridWorkflowColumns.map((each, index) => {
                                            const { name, transformProps } = each
                                            if (name) return <GridColumn {...each} key={index} cell={this.handleGridForm(name, transformProps)} />
                                            else {
                                                if (each.field == 'Status')
                                                    return <GridColumn {...each} headerClassName={this.columnProps(each.field)} headerCell={this.state.filteredfield.length > 0 && this.state.filteredfield.includes(each.field) ? FilteredNameHeader : NameHeader} filter={each.filter} key={index} cell={this.handleStatusStyle(name)} columnMenu={
                                                        props =>
                                                            <CustomColumnMenu
                                                                {...props}
                                                                columns={this.gridWorkflowColumns}
                                                                saveReport={this.saveReport}
                                                                onColumnsSubmit={this.onColumnsSubmit}
                                                            />
                                                    } />
                                                else if (each.field == 'CreatedOn' || each.field == 'UpdatedOn')
                                                    return <GridColumn {...each} headerClassName={this.columnProps(each.field)} headerCell={this.state.filteredfield.length > 0 && this.state.filteredfield.includes(each.field) ? FilteredNameHeader : NameHeader}  key={index} filter={'date'} format="{0:d}" columnMenu={
                                                        props =>
                                                            <CustomColumnMenu
                                                                {...props}
                                                                columns={this.gridWorkflowColumns}
                                                                saveReport={this.saveReport}
                                                                onColumnsSubmit={this.onColumnsSubmit}
                                                            />
                                                    } />
                                                else
                                                    return <GridColumn {...each} headerClassName={this.columnProps(each.field)} headerCell={this.state.filteredfield.length > 0 && this.state.filteredfield.includes(each.field) ? FilteredNameHeader : NameHeader} filter={each.filter} key={index} columnMenu={
                                                        props =>
                                                            <CustomColumnMenu
                                                                {...props}
                                                                columns={this.gridWorkflowColumns}
                                                                saveReport={this.saveReport}
                                                                onColumnsSubmit={this.onColumnsSubmit}
                                                            />
                                                    } />
                                            }
                                        })}
                                    </Grid>
                                </ExcelExport>}
                        </React.Fragment>
                    }
                </ReactResizeDetector>
            </React.Fragment>
        );
    }
}

export default withRouter(CInterchangableGrid);

