  /**        
        *********************************************************
        Description: Form for dynamically creating form fields.
        Required props:  urls for getting the fields.
        limitations    :  N/A
        *********************************************************
 **/   
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { requestApi } from '../../core/modules/form/utilities';
import SubForm from '../../core/modules/form/csubform';
import moment from 'moment';
import FormHeader from '../../core/modules/form/cformheader'
import SnackBar from '../../core/modules/viewport/snackbar'
import { withRouter } from 'react-router-dom';


const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    }
});

const applicationstatustransformProps = {
    name: 'Name',
    value: 'Value'
}



const comptypes = {
    TB: {
        type: 'formtextbox', options: {

        }
    },
    TA: {
        type: 'formtextbox', options: {
            multiline: true
        }
    },
    N: {
        type: 'formtextbox', options: {

        }
    },
    DN: {
        type: 'formtextbox', options: {

        }
    },
    P: {
        type: 'formtextbox', options: {

        }
    },
    RB: {
        type: 'radio', options: {

        }
    },
    CB: {
        type: 'formcheckbox', options: {
            transformProps: {
                'N': false,
                'Y': true,
                'false': 'N',
                'true': 'Y'
            }
        }
    },
    C: {
        type: 'formtextbox', options: {

        }
    },
    D: {
        type: 'date', options: {

        }
    },
    T: {
        type: 'time', options: {

        }
    },
    DT: {
        type: 'datetime-local', options: {

        }
    },
    B: {
        type: 'formdropdown', options: {
            values: [
                {
                    name: 'Yes',
                    value: 'Y'
                },
                {
                    name: 'No',
                    value: 'N'
                }
            ]
        }
    },
    DS: {
        type: 'formdropdown', options: {

        }
    },
    DM: {
        type: 'formdropdown', options: {
            multiple: true
        }
    },
    RT: {
        type: 'editor', options: {

        }
    },
    CM: {
        type: 'dropdownwithadd', options: {
        }
    },
    CF: {
        type: 'formdropdown', options: {

        }
    },
    LB: {
        type: 'text', options: {

        }
    }
}
const breadcrumbs = [
    { "link": "Dashboard", "tab": 'D', "materialicon": "dashboard", href: "#", className: 'eps-breadcrumb-item', class: 'text-light' },
    { "link": "Records", className: 'eps-breadcrumb-item ', "materialicon": "assignment", class: 'text-light' },
    { "link": "Inspections", "materialicon": "settings_system_daydream", className: 'eps-breadcrumb-item ', class: 'text-light' },
    { "link": "Add/Edit Inspections", "materialicon": "edit", className: 'eps-breadcrumb-item', class: 'text-light' }
]

class ApplicationInspections extends Component {
    constructor(props) {
        super(props);
        this.requiredFields = {}
        this.validations = {}
        this.extraProps = {}
        this.mapfieldnamestofieldkeys = {}
        this.initformControls = {}
        this.state = {
            results: null,
            opensnackbar: false,
            snackbarmessageType: '',
            snackbarmessage: '',
            changedFieldName: [],
        }
    }
      /**        
        *********************************************************
        Description: Fetches the validations and gets the fields. formcontrols contains the field configuration. header object
        for updating or saving.
        Required props:  Validationurl, AllFields.
        limitations    :  N/A
        *********************************************************
 **/  
    componentDidMount() {
        //const { ApplicationNumber } = this.props
        const { options, compmap } = this.props
        const { allurls } = options
        const { SitePermitTypesSites, SitePermitTypesValue, Validationurl, AllFields } = allurls
        const val = 'NC' + '#' + 'Commercial'
        const validationurl = Validationurl.get.url + encodeURIComponent(val);
        const read = {
            get: {
                url: AllFields.get.url,
                routeProps: {}
            }
        }
        const validations = {
            get: {
                url: validationurl
            }
        }
        const { routeid, routeid1 } = this.props.match.params
        const { allSectionFields, applicationstatus } = allurls
        Promise.all([requestApi(validations), requestApi({ get: { url: `${allSectionFields.get.url}/${routeid}/${routeid1}` } })]).then((values) => {

            const [allvalidations, results] = values
            let header = {}
            let formControls = {}


            const formvalues = this.transformIntoForms(results, 'inspectionfields', allvalidations)

            this.initformControls = formvalues.initformControls
            header = {
                ...header,
                ...formvalues.header
            }
            formControls = {
                ...formControls,
                ...formvalues.formControls
            }



            this.setState({
                section: formvalues.section,
                header: { ...header },
                formControls: formControls
            })
        })

    }
  /**        
        *********************************************************
        Description: Used to convert all non-editable components to editable.Get all form field 
            components and change its edit property to true and push it to changedFieldName.
        Required props:   N/A 
        limitations    : N/A
        *********************************************************
        **/
    handleEdit = () => {
        const { options } = this.props
        const { formControls } = this.state
        const formControlsKeys = Object.keys(formControls)
        let newformControls = {}
        formControlsKeys.forEach(each => {
            newformControls[each] = { ...formControls[each], edit: true }
        });
        this.setState({
            formControls: newformControls,
            changedFieldName: formControlsKeys.map(each => {
                return { ...formControls[each], edit: true }
            })
        })
    }

    /**        
        *********************************************************
        Description:transforms into a format that is consumed by form to render the fields.
        Required props:fields, name
        limitations    : N/A
        *********************************************************
    **/

    transformIntoForms = (fields, name, allvalidations) => {
        const { options } = this.props
        const { allurls } = options
        this[name] = {}
        let initformControls = {}
        let header = {}
        let formControls = {}
        this.requiredFields[name] = {}

        const section = {
            type: 'form',
            key: name,
            options: {
                name: name
            },
            components: fields.map(each => {
                const { UIFieldTypeComponent, FieldDescription, FieldName, FieldRowDisplayOrder, ...otherProps } = each
                const { FieldKey, Key, FieldValue, Id, IsLabel, IsHidden } = otherProps


                this.mapfieldnamestofieldkeys[FieldName] = FieldKey
                // this.validations[FieldKey] = allvalidations.filter(each => {
                //     if (each.Type === 'R') { this.requiredFields[name][FieldKey] = true }
                //     return each.Key === Key
                // })
                let datevalue
                if (UIFieldTypeComponent === 'D') datevalue = FieldValue ? moment(FieldValue, 'MM/DD/YYYY').format('YYYY-MM-DD') : '';
                if (UIFieldTypeComponent === 'DT') datevalue = FieldValue ? moment(FieldValue, 'MM/DD/YYYY hh:mm:ss').format('YYYY-MM-DD') : '';
                header[FieldKey] = FieldValue
                const value = (UIFieldTypeComponent === 'D' || UIFieldTypeComponent === 'DT') ? datevalue : each.FieldValue

                const compprops = { options: { ...otherProps, label: FieldName, edit: false, initialvalue: value, name: FieldName, row: FieldRowDisplayOrder }, type: comptypes[UIFieldTypeComponent].type, key: FieldKey, }

                initformControls[FieldName] = compprops
                formControls[FieldKey] = {
                    name: FieldName,
                    value: value,
                    touched: 0,
                    error: false,
                    edit: false,
                    edit: !(IsLabel === 'Y'),
                    display: IsHidden === 'Y' ? 'd-none' : 'd-block',
                    dropdownvalues: null,
                }
                return compprops
            })
        }
        return { section: section, initformControls: initformControls, header: header, formControls: formControls }

    }

    /**        
        *********************************************************
        Description: Handles value for form fields to save in the headers object. Checks the type of the components, transforms the value and return it.
        Required props:   value, checked, name
        limitations    : N/A
        *********************************************************
        **/

    handleValue = (value, checked, name) => {
        const { transformProps, multiple, type } = this.initformControls[name] ? this.initformControls[name] : {}
        switch (type) {
            case 'formcheckbox':
                return transformProps ? (transformProps[checked] ? transformProps[checked] : checked) : checked
            case 'formdropdown':
                return multiple && Array.isArray(value) ? value.join(',') : value
            case 'date':
                return moment(value).format('MM/DD/YYYY')
            case 'datetime-local':
                return moment(value).format('MM/DD/YYYY hh:mm:ss')
            case 'formswitch':
                return transformProps ? (transformProps[checked] ? transformProps[checked] : checked) : checked
            default:
                return transformProps ? (transformProps[value] ? transformProps[value] : value) : value
        }
    }

     /**        
        *********************************************************
        Description: Handles value changes to the field.
        Required props:   event
        limitations    : N/A
        *********************************************************
        **/
    handleFieldChange = (event) => {
        const { name, checked, type } = event.target
        let value = this.handleValue(event.target.value, checked, name)
        const fieldkey = this.mapfieldnamestofieldkeys[name]
        if (fieldkey) {
            this.setState({
                header: { ...this.state.header, [fieldkey]: value },
                formControls: { ...this.state.formControls, [fieldkey]: { ...this.state.formControls[fieldkey], value: value } },
                changedFieldName: [{ ...this.state.formControls[fieldkey], value: value }]

            })
        }

    }

    /**        
        *********************************************************
        Description: handles saving the results.
        Required props:   options.put or options.post
        limitations    : N/A
        *********************************************************
        **/

    handleSave = () => {
        const { routeid, routeid1 } = this.props.match.params
        const { options } = this.props
        const { allurls, newapplicationinspection } = options
        const { updateInspection, newInspection } = allurls
        let save
        if (newapplicationinspection) {
            save = {
                post: {
                    url: `${newInspection.post.url}/${routeid1}/${routeid}`
                }
            }
        }
        else {
            save = {
                put: {
                    url: `${updateInspection.put.url}/${routeid1}/${routeid}`
                }
            }
        }

        requestApi(save, null, this.state.header).then(results => {
            console.log('saved----', results);
            this.setState({
                opensnackbar: true,
                snackbarmessage: 'Saved Successfully',
                snackbarmessageType: 'success'
            })
        }).catch(error => {
            error.then(err => {
                console.log(err);

                this.setState({
                    opensnackbar: true,
                    snackbarmessage: JSON.stringify(err),
                    snackbarmessageType: 'error',
                })
            })
        })
    }

     /**        
        *********************************************************
        Description: Handles alerts close.Alerts are closed after the user clicks the close button on the alert.
        Required props:   N/A 
        limitations    : N/A
        *********************************************************
        **/

    handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ opensnackbar: false });
    };

   

/**        
        *********************************************************
        Description: Renders the components.Subform manages the layout of the component. Form sends the changed field values to subform to update the field values. Form header contains
        save and edit actions.
        Required props:   N/A 
        limitations    : N/A
        *********************************************************
        **/

    render() {
        const { results, section } = this.state
        const { compmap, options } = this.props
        console.log('this.state,this.requiredfirlds---', this.state, this.requiredFields)
        const { opensnackbar, snackbarmessage, snackbarmessageType } = this.state
        const { routeid, routeid1 } = this.props.match.params

        return (
            <div>
                <FormHeader breadcrumbs={breadcrumbs} key="headerapplications" handleEdit={this.handleEdit} handleSave={this.handleSave} editbutton={true} savebutton={true} backbutton={true} />
                {
                    section && <SubForm {...section} compmap={compmap} changedFieldName={this.state.changedFieldName} handleFieldChange={this.handleFieldChange} />
                }
                <SnackBar messageType={snackbarmessageType} message={snackbarmessage} open={opensnackbar} handleSnackBarClose={this.handleSnackBarClose} />

            </div>
        )
    }
}
ApplicationInspections.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(withRouter(ApplicationInspections));


