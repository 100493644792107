import React, { Fragment } from 'react';
  /**
        *********************************************************
            deprecated
        *********************************************************
 **/
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    // button: {
    //     margin: theme.spacing(1),
    // },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    }
});

const CFormButton = (props) => {

    const { classes } = props
    const { options, passer, type, rules, components, handleFieldChange, value, error, touched, ...otherProps } = props;
    const { text, icon, color, disabled, href, size, variant, name, ...otherOptions } = options
    let iconname, iconColor, iconFontSize, iconposition
    if (icon) {
        iconname = icon.iconname
        iconColor = icon.iconColor
        iconFontSize = icon.iconFontSize
    }

    const handleButton=(event)=>{
        console.log(event.name)
    }
    return (
        <Fragment>
            {(type === 'formbutton' || type === 'navformbutton') && <Button
                color={color || 'default'}
                disabled={disabled}
                href={href}
                size={size}
                variant={variant}
                {...otherProps}
                {...otherOptions}
                name = {name}
            >
                {text}
                {(iconposition === 'left') && <Icon
                    className={classes.leftIcon} color={iconColor || 'inherit'} fontSize={iconFontSize || 'default'} >{iconname}
                </Icon>
                }
                {iconposition && !(iconposition === 'left') && <Icon
                    className={classes.rightIcon} color={iconColor || 'inherit'} fontSize={iconFontSize || 'default'} >{iconname}
                </Icon>
                }

            </Button>}
            {type === 'icon' && <Icon
                onClick={this.handleClick(name, rules, passer)}
                color={iconColor || 'inherit'} fontSize={iconFontSize || 'default'}
            >
                {iconname}
            </Icon>
            }
        </Fragment>

    )

}

export default withStyles(styles)(CFormButton);






/**
 * {
 * type:'button',
 * options:{
 *  text:'alarm',
 *  icon:'alarm',
 * button:''
 * },
 * defaults:{
 *  theme:'default',
 *  custom: false
 * }
 * }
 */