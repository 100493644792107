import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { useParams } from 'react-router-dom';
import SchemaView from '../schema';
import InvoiceView from '../invoices';
import Request from '../REST/groups';
import { getSchema } from '../expressions';
import { getFieldConfig } from '../parameters/create';
import { Utils as QbUtils } from 'react-awesome-query-builder';
import jsonLogic from 'json-logic-js';
import moment from 'moment';
import { add, round } from 'mathjs';
import { getEnvVariable } from '../../../src/modules/environmentalList';

export default function ManagerView() {
    const { group } = useParams();
    if (group)
        return <PaymentManager group={group} />
    return <p></p>
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function PaymentManager({ group }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = useState(null)

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = (val) => {
        setPage(val)
        setOpen(true)
    }

    return (
        <div>
            <p className='lead'>Payment API Settings</p>
            <List>
                <ListItem button>
                    <ListItemText onClick={() => handleClick('Schemas')} primary="Schema Editor" secondary="Create or Edit Schemas" />
                </ListItem>
                <Divider />
                <ListItem button>
                    <ListItemText onClick={() => handleClick('Invoices')} primary="Invoice Types" secondary="Create or Edit Invoice Types" />
                </ListItem>
            </List>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {page}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className='container-fluid'>
                    {page === 'Schemas' && <SchemaView group={group} />}
                    {page === 'Invoices' && <InvoiceView group={group} />}
                </div>
            </Dialog>
        </div>
    );
}

const SCHEMA_URL = getEnvVariable('PaymentAPI') +'schema'
const INVOICE_URL = getEnvVariable('PaymentAPI') +'invoicetype'
const INVOICE_ITEMS = getEnvVariable('PaymentAPI') +'invoiceitem'
const ITEM_TYPE = getEnvVariable('PaymentAPI') +'itemtype'

export const getCalculatedAmount = (invoices = [], data = []) => {
    const getInvoice = (asset, invoice) => {
        let result = { ...asset };
        let config = getFieldConfig(invoice.schema);
        return invoice.items.filter(e => e.recursive).map(item => {
            try {
                item.expressions.forEach(expr => {
                    if (expr.itemType === 1) {
                        let conditions = JSON.parse(expr.itemExpression);
                        result[expr.itemKey] = expr.itemValue;
                        conditions.forEach(a => {
                            let tree = QbUtils.checkTree(QbUtils.loadTree(a.query), config);
                            let json = QbUtils.jsonLogicFormat(tree, config);
                            let value = jsonLogic.apply(json.logic, result);
                            if (value)
                                result[expr.itemKey] = a.value;
                        })
                    }
                    else if (expr.itemType === 2) {
                        result[expr.itemKey] = parse(getExpression(expr.itemExpression, result));
                    }
                    else if (expr.itemType === 3) {
                        let temp = getDateFormat(result, invoice.schema, expr.itemUnit);
                        result[expr.itemKey] = parse(getExpression(expr.itemExpression, temp));
                    }
                    else {
                        result[expr.itemKey] = 0;
                    }
                })
                let cnt = item.expressions.length;
                const amount = cnt > 0 ? result[item.expressions[cnt - 1].itemKey] : 0
                return { name: item.name, amount: round(amount, 5), description: item.description }
            }
            catch (ex) {
                console.log(ex);
                return { name: item.name, amount: 0, description: item.description }
            }
        })
    }

    const getTotal = (total, invoice) => {
        return invoice.items.filter(e => !e.recursive).map(item => {
            const amount = parse(getExpression(item.expression, { total }))
            return { name: item.name, amount: round(amount, 5), description: item.description }
        })
    }

    const getDateFormat = (result, schema, format) => {
        let data = { ...result };
        Object.keys(schema.properties).forEach(e => {
            if (schema.properties[e].type === 'date') {
                let temp = moment(data[e], schema.properties[e].format).valueOf();
                if (format === 1)
                    data[e] = Math.floor(moment.duration(temp).asHours())
                if (format === 2)
                    data[e] = Math.floor(moment.duration(temp).asDays())
                if (format === 3)
                    data[e] = Math.floor(moment.duration(temp).asMonths())
                if (format === 4)
                    data[e] = Math.floor(moment.duration(temp).asYears())
            }
            if (schema.properties[e].type === 'object') {
                data[e] = getDateFormat(data[e], schema.properties[e], format)
            }
        })
        return data;
    }

    const getExpression = (expression, data) => {
        Object.keys(data).forEach(e => {
            expression = expression.replace(`[${e}]`, data[e])
        })
        return expression;
    }

    const parse = (str) => {
        // eslint-disable-next-line
        return Function(`'use strict'; return (${str})`)()
    }

    return new Promise((res, rej) => {
        Promise.all(invoices.map(a => Request(INVOICE_URL + '/data').getById(a))).then(result => {
            const invoiceTypes = result.map(x => x.data);
            Promise.all(invoiceTypes.map(b => Request(SCHEMA_URL).getById(b.groupId))).then(resp => {
                const schemas = resp.map(x => x.data);
                Promise.all(invoices.map(e => Request(INVOICE_ITEMS).getById(e))).then(rslt => {
                    const invoiceItems = rslt.map(e => e.data);
                    const flat = invoiceItems.flat();
                    Promise.all(flat.map(e => Request(ITEM_TYPE).getById(e.id))).then(results => {
                        const itemTypes = results.map(e => e.data).flat();
                        Promise.all(schemas.map(e => getSchema(e.id))).then(schemaData => {
                            const list = invoiceTypes.map((e, idx) => {
                                return {
                                    ...e, items: invoiceItems[idx].map(x => {
                                        return { ...x, expressions: itemTypes.filter(a => a.invoiceId === x.id) }
                                    }),
                                    schema: schemaData[idx]
                                }
                            });
                            const finalData = data.map((assets, idx) => {
                                let finalAssets = assets.map(e => {
                                    return { ...e, invoice: getInvoice(e, list[idx]) }
                                })
                                let total = finalAssets.reduce((acc, curr) => add(acc, curr.invoice.reduce((a, c) => add(a, c.amount), 0)), 0);
                                let finalTotal = getTotal(total, list[idx]);
                                return { lineitems: finalAssets, otheritems: finalTotal }
                            })
                            res(finalData)
                        })
                    })
                })
            })
        })
    })
}




//[[{ "ID": "1", "OwnerID": "101", "PermitNumber": "10001", "Type": "A", "Status": true, "Permitteddate": "05/12/2021", "RenewalDate": "12/31/21", "Location": "P1-L1" }, { "ID": "2", "OwnerID": "101", "PermitNumber": "10002", "Type": "A", "Status": true, "Permitteddate": "05/12/2021", "RenewalDate": "12/31/21", "Location": "P2-L2" }, { "ID": "3", "OwnerID": "101", "PermitNumber": "10003", "Type": "C", "Status": true, "Permitteddate": "05/12/2021", "RenewalDate": "12/31/21", "Location": "P3-L3" }, { "ID": "4", "OwnerID": "102", "PermitNumber": "10004", "Type": "A", "Status": true, "Permitteddate": "05/12/2021", "RenewalDate": "12/31/21", "Location": "P4-L4" }, { "ID": "5", "OwnerID": "102", "PermitNumber": "10005", "Type": "A", "Status": false, "Permitteddate": "05/12/2021", "RenewalDate": "12/31/21", "Location": "P5-L5" }, { "ID": "6", "OwnerID": "102", "PermitNumber": "10006", "Type": "C", "Status": true, "Permitteddate": "05/12/2021", "RenewalDate": "12/31/21", "Location": "P6-L6" }, { "ID": "7", "OwnerID": "102", "PermitNumber": "10007", "Type": "C", "Status": false, "Permitteddate": "05/12/2021", "RenewalDate": "12/31/21", "Location": "P7-L7" }]]