import React, { Fragment, useEffect, useState, useRef } from "react"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    root: {
        //width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));


export default function Tags(props) {
    const { name, Name, value, handleFieldValue, customnoticesave, handleFieldChange, options, dropdownvalues, dataItem, defaultSelValue, tooltip, edit, items } = props
    const classes = useStyles();
    let ditems = options && options.sublist && dropdownvalues ? dropdownvalues[options.sublist] : items ? items : []
    let defaultitems = value || [];

    const handleChange = (event, values) => {
        let resultdata = values.map(a => a.Id);
        let stringformatvalues = [...new Set(resultdata)].join()

        const e = {
            target: {
                name: name || Name || values.name,
                value: values

            }
        }
        defaultitems = values
        if (values && values.savestringformat) {
            const e1 = {
                target: {
                    name: name || Name || values.name,
                    value: stringformatvalues
                }
            }
            if (props.handleFieldChange) {
                props.handleFieldChange(e1)
            }
            else {
                props.handleFieldValue(e1)
            }

            defaultitems = items.filter(a => value.split(',').includes(a.Id.toString()))
        }
        else {
            if (props.handleFieldChange) {
                props.handleFieldChange(e)
            }
            else {
                props.handleFieldValue(e)
            }
        }

    }

    if (options && options.savestringformat) {
        defaultitems = items ? items.filter(a => value.split(',').includes(a.Id.toString())) : []
    }


    if (dataItem && options.itemname) {
        if (dataItem[options.itemname] && dataItem[options.itemname] != "") {
            let dataitemvalue = dataItem[options.itemname];
            if (dataitemvalue) {
                dataitemvalue = dataitemvalue.toString();
                let dataitemarray = dataitemvalue.split(",");
                if (Array.isArray(dataitemarray)) {
                    defaultitems = items.filter(a => dataitemarray.includes(a.id.toString()))
                }
            }
        }
    } else if (defaultSelValue) {
        let selitemarray = defaultSelValue.split(",");
        if (Array.isArray(selitemarray)) {
            defaultitems = items.filter(a => selitemarray.includes(a.id.toString()))
        }
    }


    if (edit === false) {
        let resultdata = defaultitems.map(a => a.Name);
        let displayvalues = [...new Set(resultdata)].join()
        let getvalue = (ditems) => ditems.name || ditems.Name
        return <Fragment>
            {tooltip && <div > <Typography variant={'caption'} className="text-secondary">{options && options.label}&nbsp;
                 < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                    <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
                </Tooltip>  </Typography>  <Typography className="fw-bold" variant={'body2'} color={'textPrimary'} className="fw-bold">{displayvalues}   </Typography>
            </div>}
            {!tooltip && <div > <Typography variant={'caption'} className="text-secondary">{options && options.label}&nbsp; </Typography>   <Typography className="fw-bold" variant={'body2'} color={'textPrimary'} className="fw-bold">{displayvalues}   </Typography>
            </div>}
        </Fragment >
    }
    else {
        return (
            <div className={classes.root}>
                <Autocomplete
                    key={name || options && options.name}
                    fullWidth
                    multiple
                    name={name || Name}
                    id="tags-standard"
                    options={ditems}
                    getOptionLabel={(customitems) => customitems.name || customitems.Name}
                    onChange={handleChange}
                    //filterSelectedOptions
                    defaultValue={ditems[0]}
                    value={defaultitems}
                    disabled={options && options.disabled}
                    renderOption={(ditems, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {ditems && ditems.name || ditems.Name}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            fullWidth
                            {...params}
                            onChange={handleFieldChange ? handleFieldChange : handleFieldValue}
                            name={name || Name}
                            variant="standard"
                            label={options && options.label}
                            placeholder={options && options.placeholder}
                            InputLabelProps={{
                                shrink: true,
                            }
                            }
                        />
                    )}
                />
            </div>
        );
    }
}

