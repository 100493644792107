import { getEnvVariable } from "../../../../modules/environmentalList"

const ProjectMessagesTableAdd = {
    "type": "popup",
    "key": "ProjectMessagesTableAdd",
    "name": "ProjectMessagesTableAdd",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'addOpen'
    },
    sharedValues: ['open'],
    container: {
        values: { save: false, back: false, editInfo: null },
        mapValues: {
            AddProjectMessagesTable: [{ key: 'save', name: 'save' },
            { key: "editInfo", name: "editInfo" }]
        },
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },


            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'AddProjectMessagesTable',
                    type: 'div',
                    className: 'col-12 pl-4 m-2',
                    sub: {
                        type: 'div',
                        className: 'col-12 pl-4 m-2',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Project Messages',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "Project Messages", "materialicon": "message", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'save',
                        hidevalue: false
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hideon: 'exitfullscreen',
                        hidevalue: false
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false
                    }
                ],


            },
            AddProjectMessagesTable: {
                "type": "form",
                "key": "AddProjectMessagesTable",
                "name": "AddProjectMessagesTable",
                noParams: true,
                aliases: {
                    save: 'save',
                    'editInfo': 'editInfo',
                },
                sharedValues: ['save', 'editInfo', 'back'],
                layout: {
                    group: [

                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Key',
                                type: 'div',
                                className: 'col-md-10 col-lg-8 pt-2',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8 pt-2',
                                }
                            }],

                        },
                        {
                            order: 1,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Type',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8'
                                }
                            },
                            ]
                        },



                        {
                            order: 2,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Message',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            },

                            ]
                        },
                        // {
                        //     order: 3,
                        //     type: 'div',
                        //     className: 'row',
                        //     components: [{
                        //         name: 'Type',
                        //         type: 'div',
                        //         className: 'col-md-10 col-lg-8',
                        //         sub: {
                        //             type: 'div',
                        //             className: 'col-md-10 col-lg-8',
                        //         }
                        //     },

                        //     ]
                        // },
                        // {
                        //     order: 4,
                        //     type: 'div',
                        //     className: 'row',
                        //     components: [
                        //         {
                        //             name: 'Minlen',
                        //             type: 'div',
                        //             className: 'col-md-5 col-lg-4',
                        //             sub: {
                        //                 type: 'div',
                        //                 className: 'col-md-5 col-lg-4',
                        //             }
                        //         },
                        //         {
                        //             name: 'Maxlen',
                        //             type: 'div',
                        //             className: 'col-md-5 col-lg-4',
                        //             sub: {
                        //                 type: 'div',
                        //                 className: 'col-md-5 col-lg-4',
                        //             }
                        //         },
                        //     ]
                        // },
                        // {
                        //     order: 5,
                        //     type: 'div',
                        //     className: 'row',
                        //     components: [{
                        //         name: 'Mask',
                        //         type: 'div',
                        //         className: 'col-md-10 col-lg-8',
                        //         sub: {
                        //             type: 'div',
                        //             className: 'col-md-10 col-lg-8'
                        //         }
                        //     },
                        //     ]
                        // },
                        // {
                        //     order: 6,
                        //     type: 'div',
                        //     className: 'row',
                        //     components: [{
                        //         name: 'Regex',
                        //         type: 'div',
                        //         className: 'col-md-10 col-lg-8',
                        //         sub: {
                        //             type: 'div',
                        //             className: 'col-md-10 col-lg-8'
                        //         }
                        //     },
                        //     ]
                        // },
                    ],
                },
                values: {
                    Key: '',
                    Message: '',
                    Type: '',
                    TableName: '',
                    // Minlen: '',
                    // Maxlen: '',
                    // Mask: '',
                    // Regex: '',
                    Status: 'A',
                    editInfo: null,

                },

                "components": {
                    Type: {
                        "type": "dropdown",
                        "key": "Type",
                        "label": "Message Type",
                        "name": "Type",
                        extraProps: {
                            transformProps: {
                                label: 'Name',
                                value: 'Value'
                            },
                        }
                    },
                    Key: {
                        "type": "dropdown",
                        "key": "Key",
                        "label": "Column Name",
                        "name": "Key",
                        subname: "TableName",
                        extraProps: {
                            transformProps: {
                                label: 'name',
                                value: 'value'
                            },
                        }
                    },
                    Message: {
                        "type": "textfield",
                        "key": "Message",
                        "label": "Message",
                        "name": "Message",
                    },
                    // Type: {
                    //     "type": "textfield",
                    //     "key": "Type",
                    //     "label": "Type",
                    //     "name": "Type",
                    // },

                    // Minlen: {
                    //     "type": "textfield",
                    //     "key": "Minlen",
                    //     "label": "Min",
                    //     "name": "Minlen",
                    //     "extraProps": {
                    //         "type": "number"
                    //     }
                    // },
                    // Maxlen: {
                    //     "type": "textfield",
                    //     "key": "Maxlen",
                    //     "label": "Max",
                    //     "name": "Maxlen",
                    //     "extraProps": {
                    //         "type": "number"
                    //     }
                    // },
                    // Mask: {
                    //     "type": "textfield",
                    //     "key": "Mask",
                    //     "label": "Mask",
                    //     "name": "Mask",
                    // },
                    // Regex: {
                    //     "type": "textfield",
                    //     "key": "Regex",
                    //     "label": "Regex",
                    //     "name": "Regex",
                    // },
                },
                mapActionsToEffects: {
                    'onLoad': {
                        init: {
                            rules: [
                                {
                                    run:
                                    {
                                        valid: [{
                                            type: 'load',
                                            name: 'Key',
                                            "read": [
                                                {
                                                    url: getEnvVariable('Admin') + `/api/DomainsMapper/GridByParams/modulemaster.ProjectMessages?autoComplete=false`,
                                                    type: 'post',
                                                    alternateUrl: getEnvVariable('MasterAdmin') + `/api/DomainsMapper/GridByParams/master.ProjectMessages?autoComplete=false`,
                                                    condition: [
                                                        { value: 'Master', name: 'editInfo.Path' }
                                                    ],
                                                    alert: true,
                                                    saveAs: {
                                                        key: 'items'
                                                    },
                                                    "matchProps": [
                                                        {
                                                            name: "editInfo.Value",
                                                            key: "name",
                                                        }
                                                    ],
                                                    sublist: 'TableName',
                                                    body: {
                                                        "TABLENAME": "editInfo.Value"
                                                    },
                                                    replaceBody: true,
                                                    word: "TABLENAME"
                                                }
                                            ]
                                        },
                                        {
                                            type: 'load',
                                            name: 'Type',
                                            "read": [
                                                {
                                                    url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=PROJECT_MESSAGE_TYPE`,
                                                    type: 'get',
                                                    saveAs: {
                                                        key: 'items'
                                                    }
                                                }
                                            ]
                                            },
                                            {
                                                type: 'getValue',
                                                name: 'TableName',
                                                getdatafrom: 'editInfo',
                                                getValue:'Value'
                                            },
                                        {
                                            type: 'validations', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                            "read": [
                                                {
                                                    "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.projectmessages`,
                                                    type: 'get'
                                                }
                                            ],
                                            transformProps: {
                                                removesubfieldname: 'MASTER.PROJECTMESSAGES_'
                                            }
                                        },
                                        {
                                            canOverwrite: true,
                                            check: [{
                                                condition: "AND",
                                                rules: [{
                                                    name: "Type", type: "=", value: 'I', rules: []
                                                },
                                                ]
                                            }],
                                            uncheck: true,
                                            run: {
                                                valid: [
                                                    {
                                                        type: 'prop',
                                                        name: 'Minlen',
                                                        isVisible: false
                                                    }
                                                ],
                                                inValid: [


                                                ]
                                            }
                                        },
                                        ]
                                    }
                                },

                            ]
                        },
                    },

                    'afterSave': {
                        'save': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Admin') + `/api/ProjectMessages/Add/{name}`,
                                                        alternateUrl: getEnvVariable('MasterAdmin') + `/api/ProjectMessages/Add/{name}`,
                                                        condition: [
                                                            { value: 'Master', name: 'editInfo.Path' }
                                                        ],
                                                        type: 'post',
                                                        key: 'save',
                                                        "matchProps": [
                                                            {
                                                                name: "editInfo.Value",
                                                                key: "name",
                                                            }
                                                        ],

                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },

                    }
                }
            }
        },

    }
}
export default ProjectMessagesTableAdd