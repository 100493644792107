import React, { useState, useEffect } from "react";
import MaterialTable from '@material-table/core';
import { FormHelperText, FormControl } from "@material-ui/core";
import { customSave, customUpdate } from "./custom/sectionscustomsave"
import { useParams } from "react-router-dom";
import { createRequest, requestApi } from "../helpers/rest";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import { getDropdown } from "../helpers/domainapi";
import DefaultSettings from "./settings";
import moment from 'moment';

const Table = props => {
    const { handleFieldValue, handlerowClick, hideEditIcon = false, extraProps, shareProps, required, tooltip, columns, actions, toolbar, handleUpdate, handleSave, ...otherProps } = props
    const { label, title, helperText, name, xtype, fullWidth, disabled, subname, Urls, edit, isDelete, handleDelete, isUpdate = true, tableName, contexthelp, ...otherConfigs } = otherProps;
    const [requiredfield, setRequired] = useState('')
    const [error, setError] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const params = useParams()

    let editicon = hideEditIcon ? false : (edit ? edit : props && props.sharedProps && props.sharedProps.edit ? props.sharedProps.edit : false)
    const [gridData, setGridData] = useState({
        data: [],
        columns: [],
        resolve: () => { },
        updatedAt: new Date()
    });

    useEffect(() => {
        if (Urls && Urls.get) {
            Promise.all(Urls.get.read.map(each => requestApi(createRequest(each, {}, params)).catch(error => { return error; }))).then(response => {
                const [data] = response
                gridData.resolve();
                setGrid(data.map(x => x.data), columns)
            })
        }
        else setGrid(props.value, extraProps.columns)
    }, [])


    useEffect(() => {
        setGrid(props.value, extraProps.columns)
    }, [props.value])

    const setGrid = (data = [], columns = []) => {
        getDomainApi().then(result => {
            setGridData({
                data: data,
                columns: columns.map(x => {
                    return {
                        ...x, lookup: (result && result[x.field]) ? result[x.field].reduce((acc, curr) => { return { ...acc, [curr.value]: curr.name } }, {}) : (x.lookup || undefined)
                    }
                }),
                resolve: () => { },
                updatedAt: new Date()
            })
        })
    }

    const getDomainApi = (each = []) => {
        return getDropdown(tableName)
    }


    useEffect(() => {
        if (props.value || (gridData.data && gridData.data.length > 0)) {
            gridData.resolve();
            setGridData({
                data: (props.value || gridData.data),
                columns: gridData.columns,
                resolve: () => { },
                updatedAt: new Date()
            })
            setRefresh(!refresh)
            // setGrid((props.value || gridData.data), columns)
        }
    }, [gridData.data, props.value]);

    const onRowAdd = newData =>
        new Promise((resolve, reject) => {
            let currentObj = gridData.columns.filter(each => { if (each.setCurrentUser === true || each.setCurrentDate === true) return each })
            if (currentObj && currentObj.length > 0)
                currentObj.map(each => {
                    if (each.setCurrentUser) {
                        newData[each.field] = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')).userId : ''
                    }
                    else if (each.setCurrentDate){
                        newData[each.field] =  moment(new Date()).format('MM/DD/YYYY')
                    }
                })
            if (props.customsave) {
                if (Urls && Urls.save) {
                    Promise.all(Urls.save.read.map(each => requestApi(createRequest(each, newData, params)).catch(error => { return error; }))).then(response => {
                        const [result] = response;
                        let tempData = gridData.data || [];
                        tempData.push(result)
                        let data = [...tempData];
                        //  data.push(result);
                        if (data !== 'fail') {
                            setGrid(data, columns)
                            setError(false)
                            setRequired('')
                            const updatedAt = new Date();
                            setGridData({ ...gridData, 'data': data, columns: gridData.columns, updatedAt, resolve });
                            handleFieldValue({ target: { name: name, value: data, checked: false, hold: true }, currentTarget: { dataset: null } })
                            setRefresh(!refresh)
                            //  setRefresh(!refresh)
                            // setGridData({
                            //     data: data,
                            //     columns: gridData.columns,
                            //     resolve: resolve,
                            //     updatedAt: updatedAt
                            // })

                        }
                    })
                }
                else {
                    if (handleSave) {
                        let data = [...gridData.data];
                        handleSave(newData);
                        data.push(newData);
                        setError(false)
                        setRequired('')
                        //  const updatedAt = new Date();
                        setGridData({
                            data: data,
                            columns: gridData.columns,
                            resolve: resolve,
                            updatedAt: new Date()
                        })
                    }
                }
            }
            else {
                let data = [...gridData.data];
                data.push(newData);
                setError(false)
                setRequired('')
                handleFieldValue({ target: { name: name, value: data, checked: false, hold: true }, currentTarget: { dataset: null } })
                setGridData({
                    data: data,
                    columns: gridData.columns,
                    resolve: resolve,
                    updatedAt: new Date()
                })
                //   setGridData({ ...gridData, data, updatedAt, resolve });

            }
        });


    const onRowUpdate = (newData, oldData) =>
        new Promise((resolve, reject) => {
            if (props.customsave) {
                if (Urls && Urls.update) {
                    Promise.all(Urls.update.read.map(each => requestApi(createRequest(each, { ...newData, ...params })).catch(error => { return error; }))).then(response => {
                        const [result] = response;
                        let data = [...gridData.data];
                        const index = oldData.tableData.id;
                        data[index] = newData;
                        if (data != 'fail') {
                            setGrid(data, columns)
                            setError(false)
                            setRequired('')
                            const updatedAt = new Date();
                            setGridData({ ...gridData, data, updatedAt, resolve });
                            handleFieldValue({ target: { name: name, value: data, checked: false, hold: true }, currentTarget: { dataset: null } })
                            setRefresh(!refresh)
                        }
                    })
                }
                else {
                    if (handleUpdate) {
                        handleUpdate(newData);
                        let data = [...gridData.data];
                        const index = oldData.tableData.id;
                        setError(false)
                        setRequired('')
                        data[index] = newData;
                        const updatedAt = new Date();
                        setGridData({ ...gridData, data, updatedAt, resolve });
                    }
                }
            }
            else {
                let data = [...gridData.data];
                const index = oldData.tableData.id;
                setError(false)
                setRequired('')
                data[index] = newData;
                const updatedAt = new Date();
                setGridData({ ...gridData, data, updatedAt, resolve });
                handleFieldValue({ target: { name: name, value: data, checked: false, hold: true }, currentTarget: { dataset: null } })
            }
        });

    const onRowDelete = oldData =>
        new Promise((resolve, reject) => {
            let data = [...gridData.data];
            const index = oldData.tableData.id;
            data.splice(index, 1);
            const updatedAt = new Date();
            setGridData({ ...gridData, data, updatedAt, resolve });
            if (props.customsave) {
                if (handleDelete) {
                    handleDelete(oldData);
                    setGridData({ ...gridData, data, updatedAt, resolve });
                }
                else {
                    let savetype = 'delete'
                    customSave(props.matchparams, oldData, props.mapActionsToEffects, savetype).then(function (finaldata) {
                        if (finaldata != 'fail') {
                            data = finaldata
                            setGridData({ ...gridData, data, updatedAt, resolve });
                            handleFieldValue({ target: { name: name, value: data, checked: false, hold: true }, currentTarget: { dataset: null } })
                        }
                    })
                }
            }
            else {
                handleFieldValue({ target: { name: name, value: data, checked: false, hold: true }, currentTarget: { dataset: null } })
            }
        });

    const rowClick = (e, rowData) => {
        if (rowData.Id && disabled !== true)
            handlerowClick({ target: { name: name, value: true, checked: false }, currentTarget: { dataset: null } }, rowData)
    }

    //hold value will hold the mapactions exceuting in habdlefieldvalue function , for individual components use in json

    return (
        <FormControl error={error} fullWidth={fullWidth ? fullWidth : true} required={required} disabled={disabled || false}>
            <InputLabel style={{ fontWeight: 'bold', color: error ? 'red' : 'black' }} shrink> {label}&nbsp; {tooltip && <Tooltip title={tooltip ? tooltip : null}
                placement="bottom-start" ><HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#007bff', marginLeft: 5 }} onClick={(evt) => contexthelp ?
                    DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
            </Tooltip>} </InputLabel>
            {refresh}
            <MaterialTable
                disabled={disabled || false}
                actions={actions}
                title={title || label || ""}
                //  style={{ height: '500px','overflow':'auto'}}
                onRowClick={rowClick}
                columns={gridData.columns}
                data={gridData.data}
                doubleHorizontalScroll={true}
                options={{
                    search: false,
                    paging: false,
                    maxBodyHeight: '500px',
                    overflowY: 'auto',
                    emptyRowsWhenPaging: true,
                    addRowPosition: 'first',
                    toolbar: (toolbar === false || disabled) ? false : hideEditIcon ? true : ((editicon && disabled !== true) || disabled),
                    actionsColumnIndex: -1,
                    headerStyle: {
                        fontWeight: 'bold'
                    }
                }}
                editable={{
                    isEditable: ((rowData, rows) => (editicon && disabled !== true ? true : false)),
                    //  isDeletable: rowData => (edit && !disabled) || disabled,
                    onRowAdd: onRowAdd, //disabled ? false :
                    onRowUpdate: isUpdate && !hideEditIcon ? onRowUpdate : null,
                    onRowDelete: isDelete ? onRowDelete : null,
                }}
            />
            <FormHelperText color="secondary">{requiredfield}</FormHelperText>
        </FormControl>
    );
};

export default Table;
