import { getEnvVariable } from '../../../../modules/environmentalList';
import { withRouter } from 'react-router-dom';
import { requestApi } from '../../../modules/form/utilities';
import { GetData, PostData, PutData } from '../../helpers/requests';

export const Download = (downloadObject, routeid, routeid1) => {
    //let Group = GetAppDownloadGroup(downloadObject.Key);
    let body = {};
    body['sitePermitTypeId'] = routeid //383
    body['masterTableId'] = routeid1 //23649
    body['sideBarItems'] = ["Images","AppWorkNotification","WorkNotifVolunteers"]
    //body['sideBarDataItemFullData'] = ["AppWorkNotification"]
    //body['sideBarDataItems'] = {"AppWorkNotification" : "50" }

    let url = getEnvVariable('Application') + `/api/Application/FieldValues`;
    fetchData('POST', url, null, body, true).then(returndata => {
        if (returndata && downloadObject.Group) {
            let formattedData = GetMailMergeFormatFromObj(downloadObject.Group, returndata);  //get group here
            console.log(formattedData);
            ProjectDownloadMailMerge(downloadObject, formattedData);
        } else {
            ProjectDownload(downloadObject, []);
        }
    })
}

export const DownloadWithSideBarData = (downloadObject, routeid, routeid1, datatiems) => {
    //let Group = GetAppDownloadGroup(downloadObject.Key); 371

    let body = {};
    body['sitePermitTypeId'] = routeid //383
    body['masterTableId'] = routeid1 //23649
    body['sideBarItems'] = ["Images","AppWorkNotification","WorkNotifVolunteers"]
    body['sideBarDataItemFullData'] = ["AppWorkNotification"]
    body['sideBarDataItems'] = datatiems

    let url = getEnvVariable('Application') + `/api/Application/FieldValues`;
    fetchData('POST', url, null, body, true).then(returndata => {
        if (returndata && downloadObject.Group) {
            let formattedData = GetMailMergeFormatFromObj(downloadObject.Group, returndata);  //get group here
            console.log(formattedData);
            ProjectDownloadMailMerge(downloadObject, formattedData);
        } else {
            ProjectDownload(downloadObject, []);
        }
    })
}


export const fetchData = (requestType, url, queryParam, body, alert = false) => {

    const queryStr = queryParam != null ? `${url}?${queryParam}` : `${url}`

    if (requestType == "GET") {
        return GetData(queryStr);
    }
    else if (requestType == "POST") {
        return PostData(queryStr, body, alert);
    }
    else if (requestType == "PUT")
        return PutData(queryStr, body);
}


export const GetAppDownloadGroup = (key) => {
   // return "RECYCLERS";
}

export const GetDataPromise = (sourceURL) => {
    return new Promise((resolve, reject) => {
        requestApi({ get: { url:sourceURL  } },null, null).then(results => {
            if(results)
                resolve(results)

        }).catch(ex =>{
            resolve([])
        })
    })
}

export const GetMailMergeFormatFromObj = (group, dataobj) => {
    let schema = [];
    let parentObj = {}
    let parentfields = [];
    let parentvalues = [];
    let relationtableobjs = [];
    if(Array.isArray(dataobj)){
        dataobj.forEach(each => {
            const { Key, Value, Entity} = each
            parentObj.name = group;
            parentObj.id = group;
            if (Value != null && (typeof Value === 'string' || typeof Value === 'number') && Value != 'SIDEBARENTITY' ) {
                parentfields.push({ "name": Key.replace(/ /g,''), "id": Key.replace(/ /g,'') })
                parentvalues.push(Value)
            } else if (Value != null && Array.isArray(Value) && Value.length>0) {
                console.log('Fetching it from the array group--------')
                let relationtables = GetMailMergeFormatFromArray(group, Key, Value);
                relationtableobjs.push(relationtables);
            } else if (Value != null && typeof Value === 'object' && Value != 'SIDEBARENTITY') {
                let relationtables = GetMailMergeFormatFromObj(Key, Value)
                relationtableobjs.push(relationtables);
            }else if(Value != null && Value == 'SIDEBARENTITY'){
                if(Entity && Array.isArray(Entity)){
                    let relationtables = GetMailMergeFormatFromArray(group, Key, Entity);
                    relationtableobjs.push(relationtables);
                }else if(typeof Entity === 'object'){
                    let relationtables = GetMailMergeFormatFromObj_Defined(group, Key, Entity);
                    relationtableobjs.push(relationtables);
                }
            }
         })
    }

    schema.push({
        "name": group,
        "id": group,
        "fields": parentfields,
        "values": [parentvalues]
    })
    if (relationtableobjs.length > 0) {
        relationtableobjs.forEach(eachrelationtable => {
            //schema.push(e);
            if (Array.isArray(eachrelationtable)) {
                eachrelationtable.forEach(eachrelatiochildobj => {
                    schema.push(eachrelatiochildobj);
                })
            }
        })
    }
    return schema;
}

export const GetMailMergeFormatFromObj_Defined = (parenttablename, group, dataobj) => {
    let schema = [];
    let parentObj = {}
    let parentfields = [];
    let parentvalues = [];
    let relationtableobjs = [];

    for (const [key, value] of Object.entries(dataobj)) {
        console.log(`${key}: ${value}`);
        if (value != null && (typeof value === 'string' || typeof value === 'number') ) {
            parentfields.push({ "name": key.replace(/ /g,''), "id": key.replace(/ /g,'') })
            parentvalues.push(value)
        }
        else if (value != null && Array.isArray(value) && value.length>0) {
            let relationtables = GetMailMergeFormatFromArray(group, key, value);
            relationtableobjs.push(relationtables);
        }else if (value != null && typeof value === 'object' ){
            let relationtables = GetMailMergeFormatFromObj_Defined(key, value)
            relationtableobjs.push(relationtables);
        }
    }
    let groupName = firstLetterCaps(group)

    schema.push({
        "name": group,
        "id": group,
        "fields": parentfields,
        "values": [parentvalues],
        "relation": parenttablename + "Id = %" + groupName + ".id%"
    })
    if (relationtableobjs.length > 0) {
        relationtableobjs.forEach(eachrelationtable => {
            //schema.push(e);
            if (Array.isArray(eachrelationtable)) {
                eachrelationtable.forEach(eachrelatiochildobj => {
                    schema.push(eachrelatiochildobj);
                })
            }
        })
    }
    return schema;
}

// Check for nested data if any
export const GetMailMergeFormatFromArray = (parenttablename, group, arr) => {
    let schema = [];
    let parentObj = {}
    let parentfields = [];
    let parentvalues = [];
    let relationtableobjs = [];
    console.log(arr);
    if (arr.length > 0) {
        for (var i = 0; i < arr.length; i++) {
            let valuesarr = [];
            if (arr[i]) {
                for (const [key, value] of Object.entries(arr[i])) {
                    if (value != null && (typeof value === 'string' || typeof value === 'number')) {
                        if (i == 0) {
                            parentfields.push({ "name": key.replace(/ /g,''), "id": key.replace(/ /g,'') })
                        }
                        valuesarr.push(value)
                    } else if (value != null && Array.isArray(value) && value.length > 0) {
                        let relationtables = GetMailMergeFormatFromArray(group, key, value);
                        relationtableobjs.push(relationtables);
                    } else if (value != null && typeof value === 'object') {
                        let relationtables = GetMailMergeFormatFromObj(key, value);
                        relationtableobjs.push(relationtables);
                    }
                }
                parentvalues.push(valuesarr)
            }
        }

        let relationtablename = firstLetterSmall(parenttablename)
        let groupName = firstLetterCaps(group)
        schema.push({
            "name": groupName,
            "id": groupName,
            "fields": parentfields,
            "values": parentvalues,
            "relation": relationtablename + "ID = %" + groupName + ".id%"
        })
        if (relationtableobjs.length > 0) {
            relationtableobjs.forEach(eachrelationtable => {
                //schema.push(e);
                if (Array.isArray(eachrelationtable)) {
                    eachrelationtable.forEach(eachrelatiochildobj => {
                        schema.push(eachrelatiochildobj);
                    })
                }
            })
        }
    }
    return schema;
}

export const firstLetterSmall = (string) => {
    return string.charAt(0).toLowerCase() + string.slice(1);

}

export const firstLetterCaps = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);

}

export const ProjectDownloadMailMerge = (e, appdetails) => {

    console.log(e);
    requestApi({ post: {  url: getEnvVariable('AppDownloadAPI') + `/Editor/DownloadTemplateMerger/${e.DownloadId}` } }, null, appdetails, null, false, "Downloaded Successfully")
    .then(data => {
        if (data) {
            console.log(data);
            const downloadLink = document.createElement("a");
                downloadLink.href = data.base64PDFFileContent;//dFile
                downloadLink.download = data.fileName; //fileName
                downloadLink.click();
                downloadLink.remove();
        }
    })
}

export const DownloadInvoice = (invoiceid, downloadid) => {
    return new Promise((resolve, reject) => {
        let url = getEnvVariable('Payment') + `Invoice/FullInvoice/${invoiceid}`;
        let downloadObject={"Group":"Invoice", "DownloadId":downloadid }
        fetchData('GET', url, null, null, true).then(returndata => {
            if (returndata) {
                let tabledata = GetTableDataToCustomFormat(returndata)
                let formattedData = GetMailMergeFormatFromObj("Invoices", tabledata);  //get group here
                console.log(formattedData);
                ProjectDownloadMailMergeGetContent(downloadObject, formattedData).then(downloadres=>{
                    resolve(downloadres)
                });
            } else {
                ProjectDownloadMailMergeGetContent(downloadObject, []).then(downloadres=>{
                    resolve(downloadres)
                });
            }
        })
    })
}

function GetTableDataToCustomFormat(tabledata){
   //let resdata = {IsTemplate: false, Key: "Record Id",Label: "24022",Section: null,TemplateId: null,Value: "24022"};
    let returnval = [{IsTemplate: false, Key: "",Label: "",Section: null,TemplateId: null,Value: ""}]
    for (const [key, value] of Object.entries(tabledata)) {
        returnval.push({IsTemplate: false, Key: key,Label: key,Section: null,TemplateId: null,Value: value})
      }
    return returnval;
}


export const ProjectDownloadMailMergeGetContent = (e, appdetails) => {
    return new Promise((resolve, reject) => {
        console.log(e);
        requestApi({ post: {  url: getEnvVariable('AppDownloadAPI') + `/Editor/DownloadTemplateMerger/${e.DownloadId}` } }, null, appdetails)
        .then(data => {
            resolve(data)
        })
    })
}

export const ProjectDownload = (e, appdetails) => {

    console.log(e);
    requestApi({ post: {  url: getEnvVariable('AppDownloadAPI') + `/Template/DownloadTemplate/${e.DownloadId}` } }, null, appdetails)
    .then(data => {
        if (data) {
            console.log(data);
            const downloadLink = document.createElement("a");
                downloadLink.href = data.base64PDFFileContent;//dFile
                downloadLink.download = data.fileName; //fileName
                downloadLink.click();
                downloadLink.remove();
        }
    })
}