const AssociatedApplicants = {
    "type": "associatedapplicants",
    "key": "associatedapplicants",
    "order": 0,
    "options": {
        "alignitem": "center",
     },
    "components": [

    ]
}

export default AssociatedApplicants