import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { setResetValue } from '../../actions';


function Confirmation(props, ref) {
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState(false);
  const dispatch = useDispatch()
  const resetval = useSelector(state => state.header && state.header.resetval);

  const onSubmit = () => {
    if (props.onSubmit)
      props.onSubmit();
    else {
      let tempResetVal = resetval
      setOpen(false);
      if (tempResetVal && tempResetVal.name)
        dispatch(setResetValue({ name: tempResetVal.name, isReset: false }))
    }
  }

  const onClose = () => {
    if (props.onClose && !props.customClose)
      props.onClose();
    else {
      let tempResetVal = resetval
      setOpen(false);
      if (tempResetVal && tempResetVal.name)
        dispatch(setResetValue({ name: tempResetVal.name, isReset: true }))
    }
  }

  useImperativeHandle(ref, () => (
    {
      show: (message) => {
        setDescription(message)
        setOpen(true);
      }
    }
  ))

  return (
    <Dialog open={open ||  (props.value && props.value !== 'N')}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>        <DialogContentText>{description || props.description}</DialogContentText>      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onSubmit}>          {(props.labels && props.labels.length > 0) ? props.labels[0] : "YES"}        </Button>
        <Button color="primary" onClick={onClose}>          {(props.labels && props.labels.length > 0) ? props.labels[1] : "NO"}        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default forwardRef(Confirmation);