import React, { useState, Component } from 'react';
import QueryBuilder, { formatQuery } from './querybuilder/QueryBuilder';


export default function MainBuilder(props) {
    const { fields, query, disabled } = props

    const getValues = (field) => {
        let returntype = fields && fields.length > 0 && fields.filter(a => a.name === field)
        if (returntype && returntype.length > 0 && returntype[0].editorType && returntype[0].values && returntype[0].values.length > 0) {
            let values = returntype[0].values.map(res => {
                return { name: res.value, label: res.name }
            })
            return values
        }
        else return []
    };

    const getOperatorsfields = (field) => {
        let returntype = fields && fields.length > 0 && fields.filter(a => a.name === field)
        if (returntype && returntype.length > 0 && returntype[0].operators)
            return returntype[0].operators
        else return [{ name: "=", label: "=", value: "=" }]
    };

    const getValueEditorType = (field) => {
        let returntype = fields && fields.length > 0 && fields.filter(a => a.name === field)
        if (returntype && returntype.length > 0 && returntype[0].editorType)
            return returntype[0].editorType
        else return 'text'
    };

    const getInputType = (field) => {
        let returntype = fields && fields.length > 0 && fields.filter(a => a.name === field)
        if (returntype && returntype.length > 0 && returntype[0].type)
            return returntype[0].type
        else return 'text'
    };

    const handleQueryChange = (query) => {
        props.onQueryChange(query)
    };

    return (
        <QueryBuilder
            query={query}
            fields={fields}
            disabled={disabled}
            onQueryChange={handleQueryChange}
            getOperators={getOperatorsfields}
            getValueEditorType={getValueEditorType}
            getInputType={getInputType}
            getValues={getValues}
            showCombinatorsBetweenRules={true} showCloneButtons={true} enableDragAndDrop={true}
        />
    )
}
