import React, { useReducer, useEffect, useRef, useState, Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";



const styles = muiBaseTheme => ({
    card: {
       width: 220,
        margin: "10px",
        transition: "0.3s",
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        "&:hover": {
            boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
        },
        display: "inline-block",
        textAlign:"center"
       
    },
    media: {
        paddingTop: "56.25%"
    },
    content: {
        textAlign: "left",
        padding: muiBaseTheme.spacing.unit * 1,
        "&:last-child": {
            paddingBottom: 0
          }
    },
    divider: {
        margin: 0
    },
    heading: {
        fontWeight: "bold"
    },
    subheading: {
        lineHeight: 1.8
    },
});

function Cards(props) {
    const {classes} = props;
   
    return (
        props.componentlist && props.componentlist.map(each => {
        return <div className={classes.card}>
            <Card >
                <CardMedia/>
                <CardContent className={classes.content}>
                   
                         <Typography
                        className={"MuiTypography--subheading"}
                        variant={"caption"}
                        display="block"
                    >
                         {each.header}
                    </Typography>
                    <Typography variant="button" display="block" gutterBottom>
                        {each.body}
                    </Typography>
                    <Divider className={classes.divider} light />
                    <Typography
                        className={"MuiTypography--heading"}
                        variant={"h6"}
                        gutterBottom
                    >
                      {each.footer}
                    </Typography>
                </CardContent>
            </Card>
        </div>})
   )
}

Cards = withStyles(styles)(Cards);
export default (withRouter)(Cards);
