import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from './fab';

export default function SimpleMenu(props) {
    const { icon, type, title, color, name, items, onClick, ...otherProps } = props
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (evt, action) => {
        setAnchorEl(null);
        if (action === 'filterall' || action == 'filtercurrent')
            onClick(action)
    };

    return (
        <div className='col m-0 p-0'>
            <Fab className={name} title={title ? title : ''} color={color ? color : "inherit"} icon={icon} onClick={handleClick} {...otherProps}>
                Open Menu
            </Fab>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {items.map(each => { return <MenuItem onClick={(evt) => handleClose(evt, each.action)}>{each.name}</MenuItem> })}
            </Menu>
        </div>
    );
}
