import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import React, { Component, Fragment } from 'react';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

class FormColorPicker extends Component {

    state = {
        displayColorPicker: false,
        color: null,
    };

    handleChange = (e) => {
        const { handleFieldChange, value, name, id, edit, options, ...otherProps } = this.props
        let events = { target: { value: e.hex, name: name, checked: false } }
        this.props.handleFieldChange(events);
        this.handleClick();
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };


    render() {
        const { handleFieldChange, value, name, id, edit, options, disabled, error, required, defaultValue, className, helperText, conditions, ...otherProps } = this.props
        let disabledstatus = conditions && conditions.enable ? conditions.enable : disabled
        const { label, xtype, inputProps, password } = otherProps
        const val = ((value === null) || (value === undefined)) ? '' : value;
        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: val,
                },
                editcolor: {
                    height: '14px',
                    borderRadius: '2px',
                    background: val,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                    width: '45px'
                },
                popover: {
                    //  position: 'absolute',
                    // zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        if (edit === false) {
            return <Fragment>
                <div  >
                    <Typography variant={'caption'} className="fw-bold">{label} </Typography><br />
                    <div style={styles.swatch} >
                        <div style={styles.color} />
                    </div>
                </div>
            </Fragment >
        }
        // else return <ColorPicker
        //     value={val || ''}
        //     name={name}
        //     {...otherProps}
        //     //defaultValue='#000000'
        //     onChange={this.handleChange}//event.target=['value']= e, event.target['checked']= false, event.target['name']= name

        // />
        else return <Fragment>
            {/* <FormControl fullwidth disabled={disabledstatus} error={error} required={required}> */}
            <InputLabel shrink error={error} required={required}>{label}</InputLabel>
            <div style={styles.swatch} onClick={() => this.handleClick()}>
                <div style={styles.editcolor} />
            </div>
            {
                this.state.displayColorPicker ? <div style={styles.popover}>
                    <div style={styles.cover} onClick={() => this.handleClose()} />
                    <SketchPicker color={val} onChange={(e) => this.handleChange(e)} />
                </div> : null
            }
            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
            {/* </FormControl> */}
        </Fragment>

    }

}






export default FormColorPicker;


