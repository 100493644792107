
import { getEnvVariable } from '../../modules/environmentalList';
import SelectedApplicants from './associatedapplicants';
import externaluserloginhistory from './externaluserloginhistory';
import users from './users/grid';

const companyinternal = {
    "type": "slidingbutton",
    "key": "companyconsultantinternaluserslidingbutton",
    "options": {
        "row": 6,
        "col": 4,
        "width": 2,
        "buttonprops": {
            "text": "Consultant",
            "size": "large",
            "color": "primary",
            "variant": "contained"
        },
        excludefromresults: true,
        "label": "Consultant",
        "name": "Consultant",
        receiveValues: [{ paramname: 'Id', valuefromparent: 'Id' }],
    },
    open: true,
    components: [{
        "type": "formcontainer",
        "key": "companyinternaluserform",
        "viewroutesId": "Id",
        "options": {
            "title": "Consultant",
            headerProps: {
                "navigateup": true,
                "navigatedown": true,
                "editbutton": false,
                "savebutton": false,
                "backbutton": true,
                "closebutton": false,
                "navigateview": true,
                "breadcrumbs": [
                    { "link": "Consultant", "materialicon": "connect_without_contact", className: 'eps-breadcrumb-item', class: 'text-light' }
                ],
            },

            hidescroll: true,
            "name": "companyinternaluserform",
            "back": {
                "routes": [{
                    "route": "/consultants",
                    "exactRoute": true
                },],
                "popupback": true,
            },
        },
        "sidepopupadd": true,
        "components": [
            {
                "type": "ctab",
                "key": "companycinternalusertabform",
                hidemargin: true,
                "name": "companycinternalusertabform",
                excludefromresults: true,
                "options": {
                    tabinfo: [
                        {
                            tablabel: "View/Edit Consultant",
                            tabkey: 0
                        },
                        {
                            tablabel: "Users",
                            tabkey: 1
                        },
                        {
                            tablabel: "Associate Applicants",
                            tabkey: 2,
                        },


                    ],
                },
                components: [
                    {
                        "tabkey": 0,
                        "type": "formcontainer",
                        "key": "companyuserform",
                        "options": {
                            "totalcolumns": 2,
                            "totalrows": 13,
                            "title": "User ",
                            "alignitem": "center",
                            "routes": [
                                {
                                    "route": "/consultants",
                                    "routeParams": ":routeid",
                                },
                            ],
                            styles: {
                                margin: 15
                            }, headerProps: {
                                "editbutton": true,
                                "savebutton": true,
                                "backbutton": true,
                                "closebutton": false,
                                "navigateview": true,
                                "breadcrumbs": [
                                    // { "link": "View/Edit Applicant", href: "#/companies", popupclose: true, "materialicon": "edit", className: 'eps-breadcrumb-item ', class: 'text-light' },
                                    { "link": "Consultants", "materialicon": "person", className: 'eps-breadcrumb-item', class: 'text-light' }
                                ],
                            },
                            designprops: true,
                            "name": "consultantform",
                            "afterSaveroutes": {
                                "route": "/consultants/view/",
                                "routeparams": 'Id'
                            },
                            "back": {
                                "routes": [
                                    {
                                        "route": "/consultants",
                                        "exactRoute": true
                                    },
                                ],
                            },
                            "crud": {
                                "read": {
                                    "get": {
                                        "url": getEnvVariable('MasterAdmin') + `/api/CompanyInternal/` + getEnvVariable('ModuleKey'),
                                        "customProps": {
                                            "value": "Id"
                                        }
                                    }
                                },
                                "update": {
                                    "put": {
                                        "url": getEnvVariable('MasterAdmin') + `/api/CompanyInternal`,
                                        "urlpath": '/Update/{Id}/' + getEnvVariable('ModuleKey'),
                                        "customProps": [{
                                            "custom": "Id"
                                        }]
                                    }
                                }
                            },
                            "validations": [
                                {
                                    get: {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.companies`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'MASTER.COMPANIES_'
                                    }

                                },
                                {
                                    get: {
                                        "url": getEnvVariable('Admin') + `/api/ProjectMessages/RulesByName?name=admin.contactaddress`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'ADMIN.CONTACTADDRESS_',
                                        //addsubfieldname: 'CONTACTADDRESS.'
                                    }
                                }
                            ]
                        },
                        "components": [
                            {
                                "type": "formtextbox",
                                "key": "name",
                                "options": {
                                    "row": 0,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Consultant Name",
                                    "name": "Name"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "address1",
                                "options": {
                                    "row": 1,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Address Line 1",
                                    "name": "Contacts.PrimaryContactAddress.AddressLine1"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "address2",
                                "options": {
                                    "row": 2,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Address Line 2",
                                    "name": "Contacts.PrimaryContactAddress.AddressLine2"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "city",
                                "options": {
                                    "row": 3,
                                    "col": 0,
                                    "width": 6,
                                    "label": "City",
                                    "name": "Contacts.PrimaryContactAddress.City"
                                },

                            },
                            {
                                "type": "formdropdown",
                                "key": "state",
                                "options": {
                                    "row": 3,
                                    "col": 1,
                                    "width": 6,
                                    "label": "State",
                                    "name": "Contacts.PrimaryContactAddress.State",
                                    "id": "stateformdropdown",
                                    "crud": {
                                        "read": {
                                            "get": {
                                                "url": getEnvVariable('MasterAdmin') + `/api/Domains/values?domainkey=US_STATES`
                                            }
                                        }
                                    },
                                    "transformProps": {
                                        "value": "Value",
                                        "name": "Name"
                                    }

                                }
                            },
                            {
                                "type": "formtextbox",
                                "key": "zip",
                                "options": {
                                    "row": 4,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Zip",
                                    "name": "Contacts.PrimaryContactAddress.Zip"
                                },

                            },
                            // {
                            //     "type": "formtextbox",
                            //     "key": "companyemail",
                            //     "options": {
                            //         "row": 4,
                            //         "col": 1,
                            //         "width": 6,
                            //         "xtype": "email",
                            //         "label": "Email",
                            //         "name": "Contacts.PrimaryContactAddress.Email"
                            //     },

                            // },
                            {
                                "type": "cformphonetext",
                                "key": "Phone",
                                "options": {
                                    "row": 5,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Office Phone",
                                    "name": "Contacts.PrimaryContactAddress.Phone"
                                }
                            },
                            {
                                "type": "cformphonetext",
                                "key": "Phone",
                                "options": {
                                    "row": 5,
                                    "col": 1,
                                    "width": 6,
                                    "label": "Mobile Phone",
                                    "name": "Contacts.PrimaryContactAddress.SecondaryPhone"
                                }
                            },
                            {
                                "type": "cformphonetext",
                                "key": "Phone",
                                "options": {
                                    "row": 6,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Home Phone",
                                    "name": "Contacts.PrimaryContactAddress.HomePhone"
                                }
                            },
                            {
                                "type": "cformphonetext",
                                "key": "Fax",
                                "options": {
                                    "row": 6,
                                    "col": 1,
                                    "width": 6,
                                    "label": "Fax/Others",
                                    "name": "Contacts.PrimaryContactAddress.Fax"
                                }
                            },
                            // {
                            //     "type": "formtextbox",
                            //     "key": "MaxUsers",
                            //     "options": {
                            //         "row": 7,
                            //         "col": 0,
                            //         "width": 6,
                            //         "label": "Max Users",
                            //         "name": "MaxUsers",
                            //         "xtype": "number",
                            //         "inputProps": { min: 1 },
                            //         conditions: {
                            //             hideall: true
                            //         },
                            //     }
                            // },
                            {
                                "type": "formswitch",
                                "key": "switch",
                                "options": {
                                    "row": 8,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Status",
                                    "name": "Status",
                                    "transformProps": {
                                        "A": true,
                                        "I": false,
                                        true: "A",
                                        false: "I"
                                    }
                                }
                            },
                            // {
                            //     "type": "submoduleselection",
                            //     "key": "SubModuleKey",
                            //     "options": {
                            //         "row": 9,
                            //         "col": 0,
                            //         "width": 6,
                            //         "label": "",
                            //         "text": "SubModuleKey",
                            //         "name": "CompaniesModules",
                            //         "islabel": true,
                            //         "labelinfo": "Grant module access for",
                            //         "titleinfo": "Are you sure you want grant module access?",
                            //         "urlinfo": "grant"
                            //         // "transformProps": {
                            //         //     "Y": true,
                            //         //     "N": false,
                            //         //     true: "Y",
                            //         //     false: "N"
                            //         // }
                            //     }
                            // },
                            {
                                "type": "historydate",
                                "key": "datecreated",
                                "options": {
                                    "row": 11,
                                    "col": 0,
                                    "width": 6,
                                    "text": "Created On : ",
                                    "name": "CreatedOn"
                                }
                            },
                            {
                                "type": "historyuser",
                                "key": "createdby",
                                "options": {
                                    "row": 11,
                                    "col": 1,
                                    "width": 6,
                                    "text": "Created By : ",
                                    "name": "CreatedByName"
                                }
                            },
                            {
                                "type": "historydate",
                                "key": "updatedon",
                                "options": {
                                    "row": 12,
                                    "col": 0,
                                    "width": 6,
                                    "text": "Updated On : ",
                                    "name": "UpdatedOn"
                                }
                            },
                            {
                                "type": "historyuser",
                                "key": "updatedbyname",
                                "options": {
                                    "row": 12,
                                    "col": 1,
                                    "width": 6,
                                    "text": "Updated By : ",
                                    "name": "UpdatedByName"
                                }
                            }
                        ]
                    },
                    {
                        "tabkey": 1,
                        ...users
                    },
                    {
                        "tabkey": 2,
                        ...SelectedApplicants
                    },


                ]
            }]
    }]
}

export default companyinternal