import { loadModules } from 'esri-loader';
//import { GeometryEngineTask } from '../util/geometryEngineTask';
import { QueryTaskExecuteWithrelation, QueryTaskExecuteBufferWithrelation } from '../util/queryTask';



/**
 * GIS fetch lookup using features
 * @param {*} features
 * @param {*} validationlayers
 */
export function SketchDistanceValidatorTask(config, features, validationlayers) {
    console.log('Sketch validation for layers *********', validationlayers)
    let geometries = [];

    //caluclating only on the single geom, need to change for multiple
    geometries.push(features)
    return new Promise((resolve, reject) => {
        if (validationlayers && validationlayers.length > 0) {
            let allPromises = [];

            validationlayers.forEach(function (layer) {
                // let FieldKey = layer.FieldKey;
                // let NameReference = layer.NameReference;
                // let ValueReference = layer.ValueReference;
                let ServiceUrl = layer.Url || layer.url;
                let BufferDistance = layer.ValidationDistance;

                let eachpromise = new Promise((resolve, reject) => {
                    let gisFeatureLayer = { url:ServiceUrl,  Token: layer.Token, TokenExpiration: layer.TokenExpiration };                    
                    let queryExpression = "1=1";
                    let inputgeom;

                    //verify geometries and get the center if multiple
                    if (geometries && geometries.length > 1) {
                        inputgeom = geometries[0];
                    } else {
                        inputgeom = geometries[0];
                    }
                    QueryTaskExecuteBufferWithrelation(gisFeatureLayer, queryExpression, config.viewProperties.extent.spatialReference, inputgeom, BufferDistance, "feet").then(
                        response => {    
                            if (response && response.features && response.features.length > 0){
                                resolve({"Layer": layer, "ResponseCount": response.features.length});
                            }else{
                                resolve({"Layer": layer, "ResponseCount": 0});
                            }
                        },
                        error => {
                            console.log(error);
                            resolve({});
                        }
                    )
                });
                allPromises.push(eachpromise);
            })
            Promise.all(allPromises).then((data) => {               
                resolve(data);
            });
        } else {
            console.log({ error: 'No geometries found to gis lookup task' })
            resolve({});
        }
    })
}


// export function UserAccessControlValidation(masterlookupdata){
//     try {
//         let profile = localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'));
//         let isInternal = profile && profile.portal === "I";        

//             if (isInternal && profile.configType == 'S' && profile.moduleSiteAccess) {
//                 let sites = Object.keys(profile.moduleSiteAccess);
//                 if (Array.isArray(sites) && sites.length > 0) {
//                     let siteid = masterlookupdata.find(a => a.FieldKey == "SITE_GISID")
//                     return IsArrayIncludes(sites, masterlookupdata.find(a => a.FieldKey == "SITE_GISID").)
//                 }
//             } else if (isInternal && profile.configType == 'D' && profile.moduleDistrictAccess) {
//                 let districts = Object.keys(profile.moduleDistrictAccess);
//                 if (Array.isArray(districts) && districts.length > 0) {
//                     return " District_Id in (" + districts.join(",") + ")";
//                 }
//             } else if (isInternal && profile.configType == 'R' && profile.moduleRegionAccess) {
//                 let regions = Object.keys(profile.moduleRegionAccess);
//                 if (Array.isArray(regions) && regions.length > 0) {
//                     return " Region_Id in (" + regions.join(",") + ")";
//                 }
//             } else if (!isInternal) {
//                 return " Owner ='" + profile.modulePermissions[0].companyId + "'";
//             }
        
//     } catch (error) {
//         console.log(error);
//     }
// }

// export function IsArrayIncludes(currarr){
//     try {        
//         return true;
//     } catch (error) {
//         console.log(error);
//     }
//     return false;
// }


