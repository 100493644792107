import React, { Component, Fragment } from 'react';
import Link from '@material-ui/core/Link';
import { requestApi } from './utilities';
import { Link as RouterLink } from 'react-router-dom';
import { snackbarMessage } from '../../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import ConfirmationDialog from '../../controls/components/confirmationPopup'
import SendIcon from '@material-ui/icons/Send';
import Fab from '@material-ui/core/Fab';

class CSimpleLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snackbarmessage: '',
            openConfirmation: false
        }
    }

    handleCancel = () => {
        this.setState({ openConfirmation: false })
    }

    handleClick = () => {
        this.setState({ openConfirmation: true })
    }

    handleChange = (event) => {
        this.setState({ openConfirmation: false })
        const { options } = this.props
        const { crud } = options
        const save = crud.update
        const { put, post } = save || {}
        let ids = [], id;
        if (post) {
            const { url, urlpath, matchProps, customProps } = save.post;
            const { custom } = customProps
            if (customProps) {
                id = this.props.dataItem[custom];
            }
            else {
                id = this.props.match.params[matchProps.value];
            }
        }
        requestApi(save, (ids.length > 0 ? ids : id),'','',false,post.message).then(resp => {
            if (resp) {
                // this.props.snackbarMessage('Activation Link Sent Successfully', 'success');
            }
        }).catch(err => {
            // this.props.snackbarMessage('Invalid Data. Please verify.', 'error');
        })
    }

    render() {
        const { options, type, handleFieldChange, error, value, touched, dropdownvalues, effect, editduplicate, edit, ...otherProps } = this.props;
        const { text, name, format } = options
        return (
            <Fragment>
                {format && format == "icon" && type === 'simplelink' && !editduplicate && <Fab size="small" color="secondary" aria-label="Edit"
                    className="float-end" style={{ marginTop: '11px' }} title="Send Activation Link" onClick={this.handleClick}>
                    <SendIcon fontSize="small" />
                </Fab>}
                {!format && type === 'simplelink' && <Link underline="always" component={RouterLink} onClick={this.handleClick} name={name} {...otherProps}>
                    {text}
                </Link>
                }
                {this.state.openConfirmation && <ConfirmationDialog value={this.state.openConfirmation} title="Do you want to send Activation Link?" labels={['SEND', 'CLOSE']} onSubmit={this.handleChange} onClose={this.handleCancel} />}
            </Fragment>
        )
    }
}


const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

CSimpleLink = connect(null, mapActions)(CSimpleLink)
export default CSimpleLink;



