
import React, { Fragment } from 'react';
import Dropdown from '../../../core/controls/components/dropdown';
import TextBox from '../../../core/controls/components/textbox';


const extraProps = {
    transformProps: {
        label: 'Name',
        value: 'Id'
    },
}

const domainProps = {
    transformProps: {
        label: 'Name',
        value: 'Value'
    },
}

const domainPropsHWY = {
    transformProps: {
        label: 'name',
        value: 'value'
    },
}



export default function WRSApplicationProperties({ handleFieldChange, loadFieldsData, state }) {

    return <Fragment>
        <div className='d-flex col- sm-12 mx-1'>
            <Dropdown disabled={state.disable} className="col-3 mx-3" extraProps={extraProps} items={state.applicationsite || []} handleFieldValue={handleFieldChange} value={state.SITE_ID} label={'County'} name={'SITE_ID'} />
            <Dropdown disabled={state.disable} className="col-3 mx-3" extraProps={domainProps} items={state.townvillagecity || []} handleFieldValue={handleFieldChange} value={state.townvillagecityvalue} label={'Town/Village/City'} name={'TOWN_VILLAGE_CITY'} />
            <Dropdown disabled={!state.interstatehwyvalue} className="col-3 mx-3" extraProps={domainPropsHWY} items={state.interstatehwy|| []} handleFieldValue={handleFieldChange} value={state.interstatehwyvalue} label={'Interstate/Hwy'} name={'INTERSTATE_HIGHWAY_NETWORK'} />
        </div>
    </Fragment>
}


//value={((state.applicationregion || []).find(each => String(each.Id) === String(state.REGION_ID)) || {}).Name}