/**
      *********************************************************
      Description: Formheader component.
      Required props:  N/A
      limitations    : Doesn't handle state. Needs to handle state in parent component for save, edit button
      *********************************************************
**/

import React, { Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom'
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';

import CloseIcon from '@material-ui/icons/Close';
import BreadCrumb from '../breadcrumbs';
import Icon from '@material-ui/core/Icon';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { getSiteAccess } from '../header';
import SendIcon from '@material-ui/icons/Send';
import ExportExcel from '@material-ui/icons/SaveAlt';
import FilterListIcon from '@material-ui/icons/ClearAll';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import KendoHeader from '../../controls/components/kendoheader';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { getEnvVariable } from '../../../modules/environmentalList';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import NotesIcon from '@material-ui/icons/Notes';
import Print from '@material-ui/icons/Print';
import CachedIcon from '@material-ui/icons/Cached';
import GetAppIcon from '@material-ui/icons/GetApp';
import History from '../../controls/components/history';

const items = [{
    name: 'Export Current Page',
    action: 'filtercurrent'
},
{
    name: 'Export All Pages',
    action: 'filterall'
}
]

const styles = {
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {

    },
};
const checkwidth = 960

class CFormHeader extends React.Component {

    state = {
        openSlide: false,
        width: window.innerWidth,
        displayicon: window.innerWidth > checkwidth ? true : false,
        anchorEl: null
    }

    updateDimensions = () => {
        if (window.innerWidth < checkwidth) {
            this.setState({ width: window.innerWidth, displayicon: false });
        }
        else {
            this.setState({ displayicon: true });
        }
    };


    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }


    /**
       *********************************************************
       Description: Renders the form header with action icons. When the user accessPermissions Add and Edit button are activated.
       Required props:  N/A
       limitations    : Doesn't handle state. Needs to handle state in parent component for save, edit button
       *********************************************************
**/
    handleAdd = () => {
        const { sidebar } = this.props
        if (sidebar) {
            this.setState({ openSlide: true }, () => { this.props.handleFormGrid() })
        }
        else {
            this.props.handleAdd();
        }
    }


    handleSlide = () => {
        this.setState({ openSlide: false }, () => this.props.handleSlide())
    }


    handleExport = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    };

    handleClose = (evt, action) => {
        this.setState({ anchorEl: null }, () => {
            if (action === 'filterall' || action === 'filtercurrent')
                this.props.export(action)
        })
    };


    CreateNewRecord = (options) => (event) => {
        const { crud } = options
        const { create } = crud
        const { link } = create;
        if (link && Array.isArray(link)) {
            let pushLink = '';
            link.forEach(each => {
                const { link, matchProps } = each
                pushLink += link + (matchProps ? (this.props.match.params[matchProps.value] && ('/' + this.props.match.params[matchProps.value])) : '');
                link && this.props.history.replace(`${pushLink}`)
            })
        }
        else if (link.type === 'sublink') {
            this.props.history.push(`${this.props.location.pathname}${link.link}`)
        }
        else {
            link && this.props.history.push(`${link.link}`)
        }

    }

    getColor = () => {
        let module = getEnvVariable('ModuleKey')
        switch (module) {
            case 'AEPS':
                return 'rgba(0, 53, 95, 255)'
            case 'AMES':
                return '#1976d2'
            default:
                return '';
        }
    }

    /**
     * Custombutton
     * Use: Allows  the user to add custom button along with edit , handleCustom triggers the function in the moduel by props
     */
    render() {

        const { actions, title, customtitle, customicon, doneoutline, contactbutton, notesbutton, printbutton, custombutton, components, compmap,
            add, editbutton, savebutton, savetemplatebutton, sendtemplatebutton, addbutton, backbutton, historyurl, historyalign, alignresend,alignprofile, results,
            closebutton, handleClose, accessPermissions, accessLevelPermissions, breadcrumbs, materialicon, isedited, customprops, customId, designprops, sendbutton,
            gridprops, reportbutton, excelbutton, filterbutton, onFullScreen, onExitFullScreen, fullscreen, exitfullscreen, dataItem, nonstickyposition, navigateup,
            navigatedown, customheader, duplicatebutton, iseditduplicate, rowrenderedslide, hasclearfix, resetbutton, downloadbutton, className, refreshbutton } = this.props;
        const { isAdmin } = accessPermissions ? accessPermissions : {}
        const addaccessPermissions = getEnvVariable('AccessPortal') === "I" ? (isAdmin ? (isAdmin === 'Y') : null) : (true)
        const editaccessPermissions = isAdmin ? (isAdmin === 'Y') : null;
        const { openSlide } = this.state
        // const showaddedit = getSiteAccess(moduleInput ? moduleInput : null)
        return (
            <Fragment>
                {designprops &&  <div className={"float-end " + className}>
                    {alignresend && <span>&nbsp;&nbsp;</span>}
                    {editbutton && !isedited && <Fab size="small" color="secondary" aria-label="Edit" className="float-end" title={`Edit ${title}`} onClick={(e) => this.props.handleEdit(e, false)}>
                        <EditIcon fontSize="medium" />
                    </Fab>}
                    {alignprofile && <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                    {hasclearfix && <div className='clearfix' />}
                    <div className="float-end">&nbsp;&nbsp;</div>
                    {duplicatebutton && !isedited && getEnvVariable('AccessPortal' !== 'E') && <Fab size="small" color="secondary" aria-label="Clone" className="float-end" title={`Clone ${title}`} onClick={(e) => this.props.handleEdit(e, true)}>
                        <FileCopyIcon fontSize="medium" />
                    </Fab>}
                    {historyurl && !historyalign && <span>&nbsp;&nbsp;</span>}

                    {hasclearfix && <div className='clearfix' />}
                    {savebutton && iseditduplicate && <Fab size="small" color="secondary" aria-label="Save" className="float-end" title={`Save ${title}`} onClick={(e) => this.props.handleSave(e, true)}>
                        <SaveIcon fontSize="medium" />
                    </Fab>}
                    {refreshbutton && <Fab size="small" color="secondary" aria-label="Save" className="float-end" title={`Refresh ${title}`} onClick={(e) => this.props.handleRefresh(e, true)}>
                        <CachedIcon fontSize="medium" />
                    </Fab>}
                    {savebutton && !iseditduplicate && <Fab size="small" color="secondary" aria-label="Save" className="float-end mx-2" title={`Save ${title}`} onClick={(e) => this.props.handleSave(e, false)}>
                        <SaveIcon fontSize="medium" />
                    </Fab>}
                    {savetemplatebutton && <Fab size="small" color="secondary" aria-label="Save" className="float-end" title="Send" onClick={(e) => this.props.SaveTemplateInfo(null, null, 'initialsave')}>
                        <SendIcon fontSize="medium" />
                    </Fab>}
                    {hasclearfix && <div className='clearfix' />}
                    {sendbutton && <Fab size="small" color="secondary" aria-label="Save" className="float-end" title="Send" onClick={this.props.handleSave}>
                        <SendIcon fontSize="medium" />
                    </Fab>}
                    {alignresend && isedited && <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                    {historyurl && <History url={historyurl} fieldValues={results} />}
                    {hasclearfix && <div className='clearfix' />}
                    {contactbutton &&
                        <Fab size="small" color="secondary" aria-label="Save" className="float-end" title="Non Responders" onClick={(e) => this.props.handleContact(e, true)}>
                            <ContactMailIcon fontSize="medium" />
                        </Fab>}
                    {notesbutton &&
                        <Fab size="small" color="secondary" aria-label="Save" className="float-end" title="Notes" onClick={this.props.handleNotes}>
                            <NotesIcon fontSize="medium" />
                        </Fab>}
                    {hasclearfix && <div className='clearfix' />}
                    {doneoutline &&
                        <Fab size="small" color="secondary" aria-label="Save" className="float-end" title="Send" onClick={(e) => this.props.handleDoneOutline(e, false)}>
                            <DoneOutlineIcon fontSize="medium" />
                        </Fab>}
                    {hasclearfix && <div className='clearfix' />}
                    {printbutton &&
                        <Fab size="small" color="secondary" aria-label="Print" className="float-end" title="Print" onClick={this.props.handlePrint}>
                            <Print fontSize="medium" />
                        </Fab>}
                </div>}
                {hasclearfix && <div className='clearfix' />}
                {this.props.headerProps && this.props.headerProps.kendogridprops && <KendoHeader {...this.props.headerProps} {...{
                    addOnsByName:
                    {
                        add: {
                            onClick: (this.props.addtype && this.props.addtype === 'CreateNewRecord') ?
                                this.CreateNewRecord(this.props.addactions[0].options) : this.handleAdd
                        }, excel: { onClick: this.props.export }, reports:
                            { onClick: this.props.saveReport }, clear: { onClick: this.props.clearFilters }, back: { onClick: this.props.handleBack },
                    }
                }} />}
                {gridprops && <div>
                    {(addaccessPermissions) && actions && actions.map(each => {
                        const { type, options, key, sidepopup } = each
                        switch ((add === false) ? '' : type) {
                            case 'createnewrecordicon':
                                return <div>
                                    <div className="float-end">&nbsp;&nbsp;</div>
                                    <Fab className="float-md-right" size="small" color="primary" title={"Add " + title} aria-label="add" key={key} onClick={sidepopup ? this.handleAdd : this.CreateNewRecord(options)}>
                                        <AddIcon />
                                    </Fab>
                                </div>

                        }
                    })}
                    {/* <Fab size="small" color="primary" aria-label="Export to  Excel" title="Export to Excel" className="col float-end" onClick={this.props.export}>
                        <ExportExcel />
                    </Fab> */}
                    <Fab size="small" color="primary" aria-label="Export to  Excel" title="Export to Excel" className="col float-end" onClick={this.handleExport}>
                        <ExportExcel />
                    </Fab>
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                    >
                        {items.map(each => { return <MenuItem onClick={(evt) => this.handleClose(evt, each.action)}>{each.name}</MenuItem> })}
                    </Menu>
                    <Fab size="small" color="primary" aria-label="Save Report" className="float-end" title="Save Report" onClick={this.props.saveReport}>
                        <SaveIcon />
                    </Fab>

                    <Fab size="small" color="primary" aria-label="Save Report" className="float-end" title="Clear Filters" onClick={this.props.clearFilters}>
                        <FilterListIcon />
                    </Fab>
                </div>
                }
                {!designprops && !customheader && <AppBar position={nonstickyposition ? "static" : "sticky"} color='secondary'>
                    <Toolbar variant="dense">
                        {breadcrumbs && <div className="pt-2">
                            <BreadCrumb breadcrumbs={breadcrumbs} dataItem={dataItem} />
                        </div>}{!breadcrumbs && <div className="row ">{materialicon && <Fragment><Icon style={{ lineHeight: '29px' }}>{materialicon}</Icon>&nbsp;</Fragment>}{title}</div>}

                        <div className='flex-grow-1' />
                        <div>
                            {custombutton && <i className="zoom m-1" onClick={this.props.handleCustom} ><IconButton title={customtitle || title} className="zoom m-1" style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <Icon fontSize="medium">{customicon}</Icon>
                            </IconButton></i>}
                            {resetbutton && <i className="zoom m-1" onClick={(e) => this.props.handleReset(e)} ><IconButton className="zoom m-1" title="Reset" style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <CachedIcon fontSize="medium" />
                            </IconButton></i>}
                            {backbutton && <i className="zoom m-1" onClick={(e) => this.props.handleBack(e)} ><IconButton className="zoom m-1" title="Back" style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <ChevronLeftIcon fontSize="medium" />
                            </IconButton></i>}
                            {filterbutton && <i className="zoom m-1" onClick={this.props.clearFilters}><IconButton className="zoom m-1" title="Clear Filters" style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <FilterListIcon fontSize="medium" />
                            </IconButton></i>}
                            {reportbutton && <i className="zoom m-1" onClick={this.props.saveReport}><IconButton className="zoom m-1" title="Save Report" style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <SaveIcon fontSize="medium" />
                            </IconButton></i>}
                            {refreshbutton && <i className="zoom m-1" onClick={this.props.handleRefresh}><IconButton className="zoom m-1" title="Refresh Cache" style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <CachedIcon fontSize="medium" />
                            </IconButton></i>}
                            {excelbutton &&
                                <Fragment>
                                    <i className="zoom m-1" onClick={this.handleExport}><IconButton className="zoom m-1" title="Export to Excel" style={{ backgroundColor: 'white', color: this.getColor() }}>
                                        <ExportExcel fontSize="medium" />
                                    </IconButton></i>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={this.state.anchorEl}
                                        keepMounted
                                        open={Boolean(this.state.anchorEl)}
                                        onClose={this.handleClose}
                                    >
                                        {items.map(each => { return <MenuItem onClick={(evt) => this.handleClose(evt, each.action)}>{each.name}</MenuItem> })}
                                    </Menu>
                                </Fragment>
                            }
                            {downloadbutton && <i className="zoom m-1" onClick={this.props.handleDownload}><IconButton className="zoom m-1" title="Download" style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <GetAppIcon fontSize="medium" />
                            </IconButton></i>}
                            {addbutton && <i className="zoom m-1" onClick={this.handleAdd}><IconButton className="zoom m-1" title={`Add ${title}`} style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <AddIcon fontSize="medium" />
                            </IconButton></i>}
                            {editbutton && !isedited && <i className="zoom m-1" onClick={this.props.handleEdit} >  <IconButton className="zoom m-1" title={`Edit ${title}`} style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <EditIcon fontSize="medium" />
                            </IconButton></i>}

                            {duplicatebutton && !isedited && getEnvVariable('AccessPortal' !== 'E') && <i className="zoom m-1" onClick={(e) => this.props.handleEdit(e, true)}><IconButton className="zoom m-1" title={`Clone ${title}`} style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <FileCopyIcon fontSize="medium" />
                            </IconButton></i>}


                            {savebutton && iseditduplicate && <i className="zoom m-1" onClick={(e) => this.props.handleSave(e, true)} ><IconButton className="zoom m-1" title={`Save ${title}`} style={{ backgroundColor: 'white', color: 'green' }}>
                                <SaveIcon fontSize="medium" />
                            </IconButton></i>}
                            {savebutton && !iseditduplicate && <i className="zoom m-1" onClick={(e) => this.props.handleSave(e, false)} ><IconButton className="zoom m-1" title={`Save ${title}`} style={{ backgroundColor: 'white', color: 'green' }}>
                                <SaveIcon fontSize="medium" />
                            </IconButton></i>}
                            {historyurl && <History url={historyurl} fieldValues={results} />}
                            {closebutton && <i className="zoom m-1" onClick={this.props.handleClose} ><IconButton className="zoom m-1" title="Close" style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <CloseIcon fontSize="medium" />
                            </IconButton></i>}
                            {sendbutton && <i className="zoom m-1" onClick={this.props.handleSave} ><IconButton className="zoom m-1" title="Send" style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <SendIcon fontSize="medium" />
                            </IconButton></i>}
                            {savetemplatebutton && <i className="zoom m-1" onClick={(e) => this.props.SaveTemplateInfo(null, null, 'initialsave')} ><IconButton className="zoom m-1" title="Send" style={{ backgroundColor: 'white', color: 'green' }}>
                                <SendIcon fontSize="medium" />
                            </IconButton></i>}
                            {sendtemplatebutton && <i className="zoom m-1" onClick={(e) => this.props.SaveTemplateInfo(null, null, 'initialsave')} ><IconButton className="zoom m-1" title="Email Me" style={{ backgroundColor: 'white', color: 'green' }}>
                                <SendIcon fontSize="medium" />
                            </IconButton></i>}
                            {rowrenderedslide && navigateup && <i className="zoom m-1" onClick={this.props.onPrevItemClick ? this.props.onPrevItemClick : this.props.onPrevItem} ><IconButton className="zoom m-1" title="Previous Item" style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <ArrowUpwardIcon fontSize="medium" />
                            </IconButton></i>}
                            {rowrenderedslide && navigatedown && <i className="zoom m-1" onClick={this.props.onNextItemClick ? this.props.onNextItemClick : this.props.onNextItem} ><IconButton className="zoom m-1" title="Next Item" style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <ArrowDownwardIcon fontSize="medium" />
                            </IconButton></i>}
                            {this.state.displayicon && !fullscreen && exitfullscreen && <i className="zoom m-1" onClick={this.props.onFullScreen} ><IconButton className="zoom m-1" title="FullScreen" style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <FullscreenIcon fontSize="medium" />
                            </IconButton></i>}
                            {this.state.displayicon && fullscreen && <i className="zoom m-1" onClick={this.props.onExitFullScreen} ><IconButton className="zoom m-1" title="Exit FullScreen" style={{ backgroundColor: 'white', color: this.getColor() }}>
                                <FullscreenExitIcon fontSize="medium" />
                            </IconButton></i>}

                        </div>
                    </Toolbar>
                </AppBar>}
                {/* subgrid - represents the form header true or false*/
                    openSlide && components && components.map(each => {
                        const EachComp = compmap[each.type]
                        return <EachComp {...each} key={each.key} handleSlide={this.handleSlide} addSubGrid={true} compmap={compmap}
                            accessPermissions={accessPermissions} accessLevelPermissions={accessLevelPermissions} customprops={customprops}
                            customId={customId} dataItem={this.props.dataItem} handleCustomBack={this.props.handleCustomBack} />
                    })
                }
            </Fragment >

        );
    }
}
export default withRouter(CFormHeader);
