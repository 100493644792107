import { loadModules } from 'esri-loader';
import { result } from 'lodash-es';
import { GeometryEngineTask } from '../util/geometryEngineTask';

export function QueryTask(config, querycondition, spatialReference) {
    return new Promise((resolve, reject) => {
        console.log('QueryTask------------------SR', spatialReference)
        if (!loadModules) {
            reject('QueryTask: ArcGIS API is not loaded');
            return;
        }
        QueryTaskExecute(config, querycondition, spatialReference).then(
            response => {
                resolve(response);
            },
            error => {
                reject(error);
            }
        );
    });
};

function QueryTaskExecute(config, querycondition, spatialReference) {
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/tasks/support/Query", "esri/tasks/QueryTask", "esri/geometry/Point", "esri/geometry/SpatialReference"
        ]).then(([Query, QueryTask, Point, SpatialReference]) => {
            if (config && config.url) {
                console.log('---------------QueryTaskExecute-----------------------')
                const query = new Query();
                if (querycondition) {
                    if (config.defaultExpression)
                        querycondition = (!querycondition || querycondition == "()" || querycondition == "1=1") ? (config.defaultExpression) : (("(" + config.defaultExpression + ")") + " and " + querycondition)
                    query.where = querycondition;
                }

                query.outFields = ['*'];
                console.log('spatialReference',spatialReference)
                query.spatialReference = spatialReference || 2992;
                query.returnGeometry = true;
                query.outSpatialReference =  spatialReference || 2992;
                const queryTask = new QueryTask({
                    url: config.url
                });

                queryTask.execute(query).then(
                    response => {
                        resolve(response);
                    },
                    error => {
                        console.log("QueryTaskExecute error-------------")
                        console.error("WebMap: error", error);
                        reject(error);
                    }
                );

            } else {
                resolve({ customerrormessage: 'no url present for query' });
            }

        })
    });
}

export function QuerySelectedTask(subModuleKey, config, view, mapConstants, querycondition, spatialReference, zoom) {
    return new Promise((resolve, reject) => {
        if (!loadModules) {
            reject('QueryTask: ArcGIS API is not loaded');
            return;
        }
        QuerySelectedTaskExecute(subModuleKey, config, view, mapConstants, querycondition, spatialReference, zoom).then(
            response => {
                resolve(response);
            },
            error => {
                reject(error);
            }
        );
    });
};

function QuerySelectedTaskExecute(subModuleKey, config, view, mapConstants, querycondition, spatialReference, zoom) {
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/Graphic",
            "esri/layers/GraphicsLayer",
            "esri/layers/FeatureLayer",
            "esri/tasks/support/Query",
            "esri/tasks/QueryTask",
            "esri/geometry/Point",
            "esri/geometry/SpatialReference"
        ]).then(([Graphic, GraphicsLayer, FeatureLayer, Query, QueryTask, Point, SpatialReference]) => {
            if (config && config.url) {
                /*let layer = view.map.layers.find(function (layer) {
                    return layer.title === config.title;//"Data Table Seleced Records_"+config.id;
                });
                let isNew=!layer;
                if (isNew) {
                    layer = new FeatureLayer({
                        "title": config.title//"Data Table Seleced Records_"+config.id, listMode: 'hide'
                    });
                }*/
                console.log('---------------QueryTaskExecute-----------------------')
                let layer = view.map.layers.find(function (layer) {
                    return layer.title === "Data Table Seleced Records_" + config.id;
                });
                let isNew = !layer;
                if (isNew) {
                    layer = new GraphicsLayer({
                        "title": "Data Table Seleced Records_" + config.id, listMode: 'hide'
                    });
                }

                const query = new Query();
                if (querycondition) {
                    if (config.defaultExpression && config.defaultExpression !== "1=1")
                        querycondition = (!querycondition || querycondition == "()" || querycondition == "1=1") ? (config.defaultExpression) : (("(" + config.defaultExpression + ")") + " and " + querycondition)
                    query.where = querycondition;
                }
                let highlightSelect = null
                let geometries = []
                /*view.whenLayerView(layer).then(function (lyrView) {
                    // creates the query that will be used to obtain the features needed for the highlight
                    var querylayer = layer.createQuery();
                    querylayer.where = querycondition;
                    layer.queryFeatures(querylayer).then(
                        response => {
                            if (highlightSelect) {
                                highlightSelect.remove();
                            }
                            response.features.forEach((item) => {
                                item.symbol =mapConstants.getHighlightsymbol(subModuleKey,item.geometry.type,item.attributes,config);//mapConstants.getHighlightsymbol(subModuleKey,s.geometry.type,s.attributes,config);
                                geometries.push(item.geometry)
                            });
                            highlightSelect = lyrView.highlight(response.features)
                            CenterAndZoomWithUnion(view,geometries,zoom)
                        },
                        error => {
                            console.log("QueryTaskExecute error-------------")
                            console.error("WebMap: error", error);
                            reject(error);
                        });
                });*/
                query.outFields = ['*'];
                query.spatialReference = spatialReference || 26957;
                query.returnGeometry = true;
                query.outSpatialReference = spatialReference || 26957;
                const queryTask = new QueryTask({
                    url: config.url
                });
                queryTask.execute(query).then(
                    response => {
                        if (response && response.features) {
                            /*view.whenLayerView(layer).then(function (lyrView) {
                            response.features.forEach((item) => {
                                item.symbol =mapConstants.getHighlightsymbol(subModuleKey,item.geometry.type,item.attributes,config);//mapConstants.getHighlightsymbol(subModuleKey,s.geometry.type,s.attributes,config);
                                geometries.push(item.geometry)
                                highlightSelect=lyrView.highlight(item.attributes['OBJECTID'])
                            });*/

                            response.features.forEach(function (item) {
                                let g = new Graphic({
                                    geometry: item.geometry,
                                    attributes: item.attributes,
                                    symbol: mapConstants.getHighlightsymbol(subModuleKey, item.geometry.type, item.attributes, config)//mapConstants.getsymbol(item.geometry.type,true,config)//
                                });
                                geometries.push(g.geometry)
                                layer.graphics.add(g);
                            });
                            if (isNew)
                                view.map.layers.add(layer, 0);
                            CenterAndZoomWithUnion(view, geometries, zoom)
                            //});
                        }
                        resolve(response);
                    },
                    error => {
                        console.log("QueryTaskExecute error-------------")
                        console.error("WebMap: error", error);
                        reject(error);
                    }
                );

            } else {
                resolve({ customerrormessage: 'no url present for query' });
            }

        })
    });
}

export function CenterAndZoom(view, graphic, defaultZoom) {
    if (view && graphic) {
        view.goTo(graphic).then(function () {
            if (defaultZoom && defaultZoom > view.zoom)
                view.zoom = defaultZoom
        });
    }
}

export function CenterAndZoomWithUnion(view, graphics, defaultZoom) {
    if (graphics && graphics.length > 0) {
        if (graphics.length > 1) {
            GeometryEngineTask({ geometries: graphics, type: "union" })
                .then((GeomEngineresponse) => {
                    if (GeomEngineresponse) {
                        if (GeomEngineresponse.centroid)
                            CenterAndZoom(view, GeomEngineresponse.centroid, defaultZoom);
                        else if (GeomEngineresponse.extent)
                            CenterAndZoom(view, GeomEngineresponse.extent, defaultZoom);
                    }
                },
                    (GeomEngineerror) => {
                        console.log(GeomEngineerror);
                    }
                );
        } else if (graphics.length === 1) {
            CenterAndZoom(view, graphics[0], defaultZoom);
        }
    }
}

export function setCenterforMapviewwithFlag(view, xCenter, yCenter, spid, symbol) {
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/geometry/Point", "esri/geometry/SpatialReference", "esri/Graphic",
        ]).then(([Point, SpatialReference, Graphic]) => {
            if (xCenter && xCenter != undefined && xCenter != null) {
                let spatialReference = new SpatialReference(spid);
                let geomPt = new Point(xCenter, yCenter, spatialReference);
                let g = new Graphic({
                    geometry: geomPt,
                    symbol: symbol
                });
                view.graphics.add(g);
                //view.center = geomPt;
            }
        })
    });
}

export function setCenterforMapviewNoFlag(view, xCenter, yCenter, spid) {
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/geometry/Point", "esri/geometry/SpatialReference", "esri/Graphic",
        ]).then(([Point, SpatialReference, Graphic]) => {
            if (xCenter && xCenter != undefined && xCenter != null) {
                let spatialReference = new SpatialReference(spid);
                let geomPt = new Point(xCenter, yCenter, spatialReference);
                let g = new Graphic({
                    geometry: geomPt
                });
                view.graphics.add(g);
                //view.center = geomPt;
            }
        })
    });
}

export function QueryTaskExecuteWithrelation(config, querycondition, spatialReference, inputgeom) {
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/tasks/support/Query", "esri/tasks/QueryTask", "esri/geometry/Point", "esri/geometry/SpatialReference"
        ]).then(([Query, QueryTask, Point, SpatialReference]) => {
            console.log('---------------QueryTaskExecuteWithrelation-----------------------')
            const query = new Query();
            if (config.url.indexOf('/query') === -1) {
                if (querycondition) {
                    if (config.defaultExpression)
                        querycondition = (!querycondition || querycondition == "()" || querycondition == "1=1") ? (config.defaultExpression) : (("(" + config.defaultExpression + ")") + " and " + querycondition)
                    query.where = querycondition;
                }
            }
            query.outFields = ['*'];
            query.spatialReference = spatialReference || 2992;
            query.returnGeometry = true;
            query.outSpatialReference = spatialReference || 2992;
            if (inputgeom != null) {
                query.geometry = inputgeom.geometry;
                query.spatialRelationship = "intersects";
            }
            const queryTask = new QueryTask({
                url: config.url
            });

            queryTask.execute(query).then(
                response => {
                    resolve(response);
                },
                error => {
                    console.log("query info ", querycondition, config.url, query.where);
                    console.error("WebMap: error", error);
                    reject(error);
                }
            );
        })
    });
}


export function QueryTaskExecuteBufferWithrelation(config, querycondition, spatialReference, inputgeom, buffer, units = "meters") {
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/tasks/support/Query", "esri/tasks/QueryTask", "esri/geometry/Point", "esri/geometry/SpatialReference","esri/config"
        ]).then(([Query, QueryTask, Point, SpatialReference, esriConfig]) => {
            // console.log('---------------QueryTaskExecuteBufferWithrelation-----------------------')
            //console.log(querycondition, spatialReference, inputgeom, buffer, units)
            if (config && config.Token && config.TokenExpiration) {
                esriConfig.request.interceptors.push({
                  urls: config.url,
                  before: function (params) {
                    params.requestOptions.query = params.requestOptions.query || {};
                    params.requestOptions.query.token = config.Token;
                    params.requestOptions.query.expiration = config.TokenExpiration;
                  }
                });
            }
            const query = new Query();
            if (config.url.indexOf('/query') === -1) {
                if (querycondition) {
                    if (config.defaultExpression)
                        querycondition = (!querycondition || querycondition == "()" || querycondition == "1=1") ? (config.defaultExpression) : (("(" + config.defaultExpression + ")") + " and " + querycondition)
                    query.where = querycondition;
                }
            }
            query.outFields = ['*'];
            query.spatialReference = spatialReference || 2992;
            query.returnGeometry = true;
            query.outSpatialReference = spatialReference || 2992;
            if (inputgeom != null) {
                query.geometry = inputgeom.geometry;
                query.spatialRelationship = "intersects";
            }
            if (buffer && buffer > 0) {
                query.distance = buffer; // queries all features within 1 mile of the point
                query.units = units;
            }

            const queryTask = new QueryTask({
                url: config.url
            });

            queryTask.execute(query).then(
                response => {
                    resolve(response);
                },
                error => {
                    console.log("query info ", querycondition, config.url, query.where);
                    console.log('Following error is just informational purpose.... NOT AN ISSUE OF LAYERS')
                    console.error("WebMap: error", error);
                    resolve({ "features": [{ "attributes": {} }] })
                    //reject(error);
                }
            );
        })
    });
}


export function QueryTaskBufferWithGeom(config, querycondition, spatialReference, inputgeom, buffer, unit) {
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/tasks/support/Query", "esri/tasks/QueryTask", "esri/geometry/Point", "esri/geometry/SpatialReference"
        ]).then(([Query, QueryTask, Point, SpatialReference]) => {
            console.log('---------------QueryTaskBufferWithGeom-----------------------')
            const query = new Query();
            if (querycondition) {
                if (config.defaultExpression)
                    querycondition = (!querycondition || querycondition == "()" || querycondition == "1=1") ? (config.defaultExpression) : (("(" + config.defaultExpression + ")") + " and " + querycondition)
                query.where = querycondition;
            }
            query.outFields = ['*'];
            query.spatialReference = spatialReference || 2992;
            query.returnGeometry = true;
            query.outSpatialReference = spatialReference || 2992;
            if (inputgeom != null) {
                query.geometry = inputgeom;
                query.spatialRelationship = "intersects";
            }
            if (buffer && buffer > 0) {
                query.distance = buffer;
                query.units = unit;
            }
            const queryTask = new QueryTask({
                url: config.url
            });

            queryTask.execute(query).then(
                response => {
                    resolve(response);
                },
                error => {
                    console.log("query info ", querycondition, config.url, query.where);
                    console.error("WebMap: error", error);
                    reject(error);
                }
            );
        })
    });
}

export function EsriRequest(config, querycondition, spatialReference) {
    return new Promise((resolve, reject) => {
        if (!loadModules) {
            reject('QueryTask: ArcGIS API is not loaded');
            return;
        }
        EsriRequestExecute(config, querycondition, spatialReference).then(
            response => {
                resolve(response);
            },
            error => {
                reject(error);
            }
        );
    });
}

function EsriRequestExecute(config, querycondition, spatialReference) {
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/tasks/support/Query", "esri/tasks/QueryTask", "esri/geometry/Point",
            "esri/geometry/SpatialReference", "esri/request"
        ]).then(([Query, QueryTask, Point, SpatialReference, esriRequest]) => {
            if (config && config.url) {
                // Define the 'options' for the request
                var options = {
                    query: {
                        f: "pjson"
                    },
                    responseType: "pjson"
                };
                esriRequest(config.url, options).then(function (response) {
                    console.log("response", response);
                    resolve(JSON.parse(response.data))
                });
            } else {
                resolve({ customerrormessage: 'no url present for query' });
            }

        })
    });
}

function QueryTaskExecuteMultipleLocation(config, querycondition, spatialReference) {
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/tasks/support/Query", "esri/tasks/QueryTask", "esri/geometry/Point", "esri/geometry/SpatialReference"
        ]).then(([Query, QueryTask, Point, SpatialReference]) => {
            if (config && config.url) {

                const query = new Query();
                if (querycondition) {
                    if (config.defaultExpression)
                        querycondition = (!querycondition || querycondition == "()" || querycondition == "1=1") ? (config.defaultExpression) : (("(" + config.defaultExpression + ")") + " and " + querycondition)
                    query.where = querycondition;
                }

                query.outFields = ['*'];
                query.units = 'feet';
                //query.spatialReference = spatialReference || 2992;
                query.returnGeometry = true;
                //query.outSpatialReference  = spatialReference || 2992;
                const queryTask = new QueryTask(config.url);
                // queryTask.execute(query, function(featureSet){
                //     // for (var x = 0; x < featureSet.features.length; x++) {
                //     //    dataArray.push(featureSet.features[x].attributes.RTUnique);
                //     // }
                //     console.log(featureSet);
                //     resolve(featureSet);
                // }, function(error){
                //     console.log(error);
                // });
                let queryReponseData = queryTask.execute(query);
                console.log(queryReponseData);
                queryReponseData.then(
                    function (response) {
                        resolve(response);
                    }, function (m) {
                        resolve(m);
                    },
                    function (error) {
                        console.log("QueryTaskExecute error-------------")
                        console.error("WebMap: error", error);
                        reject(error);
                    }
                );

            } else {
                resolve({ customerrormessage: 'no url present for query' });
            }

        })
    });
}

function GeoProcessingRequestAsync(config, querycondition, spatialReference) {
    loadModules([
        "esri/tasks/support/Query", "esri/tasks/QueryTask", "esri/geometry/Point", "esri/geometry/SpatialReference"
    ]).then(([Query, QueryTask, Point, SpatialReference]) => {
        if (config && config.url) {

            const query = new Query();
            if (querycondition) {
                if (config.defaultExpression)
                    querycondition = (!querycondition || querycondition == "()" || querycondition == "1=1") ? (config.defaultExpression) : (("(" + config.defaultExpression + ")") + " and " + querycondition)
                query.where = querycondition;
            }

            query.outFields = ['*'];
            query.units = 'feet';
            //query.spatialReference = spatialReference || 2992;
            query.returnGeometry = true;
            //query.outSpatialReference  = spatialReference || 2992;
            const queryTask = new QueryTask({
                url: config.url
            });

            var queryResult = queryTask.execute(query);
            console.log(queryResult);


        } else {
       //   code correction: 'resolve' is not defined  no - undef
            // resolve({ customerrormessage: 'no url present for query' });
        }
    })
    return null;
}

function GeoProcessingRequestNormalization(config, querycondition, spatialReference) {
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/tasks/support/Query", "esri/tasks/QueryTask", "esri/geometry/Point",
            "esri/geometry/SpatialReference", "esri/tasks/Geoprocessor"
        ]).then(([Query, QueryTask, Point, SpatialReference, Geoprocessor]) => {
            if (config && config.url) {
                var gp = new Geoprocessor(config.url);
                gp.execute({}, displayTrack);
                function displayTrack(results, messages) {
                    console.log(results)
                    // //do something with the results
                    // var features = results[0].value.features;
                }
            } else {
                resolve({ customerrormessage: 'no url present for query' });
            }
        })

    })

}

// async function GetProcessAsync(config, querycondition, spatialReference){
//     var attr = await attributes(config, querycondition, spatialReference);

//     async function attributes(config, querycondition, spatialReference){
//         loadModules([
//             "esri/tasks/support/Query", "esri/tasks/QueryTask","esri/geometry/Point","esri/geometry/SpatialReference"
//         ]).then(([Query, QueryTask,Point,SpatialReference]) => {
//             if(config && config.url){

//                 const query = new Query();
//                 if(querycondition)
//                     query.where = querycondition;

//                 query.outFields = ['*'];
//                 query.units = 'feet';
//                 query.returnGeometry = true;
//                 const queryTask = new QueryTask({
//                     url: config.url
//                 });
//                 var response =  queryTask.execute(query);
//                 var fs = await response.promise;
//                 return fs;
//             }
//         })
//     };
//     console.log(attr)

//   }


export function QueryTaskExecuteZoomToReponse(view, config, queryexpression, mapConstants, zoom, spatialReference, queryField = "SearchField") {
    return new Promise((resolve, reject) => {
        if (!loadModules) {
            reject('QueryTaskExecuteZoomToRepons: ArcGIS API is not loaded');
            return;
        }
        if (queryexpression == null || queryexpression == "" || queryexpression == "1!=1") {
            resolve({ "Message": "Invalid Expression" });
        }
        else {
            if (queryexpression != null && queryexpression != "") {
                // Specific to AMES project.
                if (queryexpression != undefined && queryexpression != null && queryexpression != "") {
                    let queryParams = queryexpression.split(",");
                    let geometries = [];
                    let resultgraphics = [];
                    let queryPrimaryParam = queryParams && queryParams.length > 0 ? queryParams[0] : null
                    let queryParamsStr = ''
                    for (var i = 0; i < queryParams.length; i++) {
                        if (queryParamsStr === "") {
                            if (queryParams[i] && queryParams[i] != null)
                                queryParamsStr = "'" + queryParams[i] + "'";
                        } else {
                            if (queryParams[i] && queryParams[i] != null)
                                queryParamsStr = queryParamsStr + ",'" + queryParams[i] + "'";
                        }
                    }
                    let queryFieldName = config[queryField];
                    let querycondition = queryFieldName + " in (" + queryParamsStr + ")";

                    let layer = view.map.layers.find(function (layer) {
                        return layer.title === "Highlight Layer";
                    });
                    console.log('QueryTaskExecuteZoomToReponse---------');
                    QueryTask(config, querycondition, spatialReference).then(
                        response => {
                            if (response && response.features) {
                                loadModules([
                                    "esri/Graphic",
                                    "esri/layers/GraphicsLayer",
                                    "esri/layers/FeatureLayer",
                                    "esri/PopupTemplate"
                                ]).then(([Graphic, GraphicsLayer, FeatureLayer, PopupTemplate]) => {
                                    if (!layer) {
                                        layer = new GraphicsLayer({
                                            //"title": "Tax Parcel Graphic Layer", listMode: 'hide'
                                            "title": "Highlight Layer", listMode: 'hide'
                                        });
                                    }
                                    layer.graphics.removeAll();
                                    response.features.forEach(function (item, index) {
                                        if (item && item.geometry && item.geometry.centroid) {
                                            let center = item.geometry.centroid;
                                            setCenterforMapviewNoFlag(view, parseFloat(center.x), parseFloat(center.y), spatialReference);
                                        }
                                        geometries.push(item.geometry);
                                        let g = new Graphic({
                                            geometry: item.geometry,
                                            attributes: item.attributes,
                                            symbol: (queryPrimaryParam && queryPrimaryParam == item.attributes[queryFieldName]) ? mapConstants.PolygonFillSymbol : mapConstants.PolygonFillSymbolSecondary
                                        });
                                        resultgraphics.push(g);
                                        if (layer.graphics)
                                            layer.graphics.add(g);
                                    });
                                    if (geometries.length > 0) {
                                        if (geometries.length > 1) {
                                            GeometryEngineTask({ "geometries": geometries, "type": "union" }).then(
                                                GeomEngineresponse => {
                                                    if (GeomEngineresponse && GeomEngineresponse.centroid)
                                                        CenterAndZoom(view, GeomEngineresponse.centroid, zoom);
                                                },
                                                GeomEngineerror => {
                                                    //   console.log(GeomEngineerror);
                                                });
                                        } else if (geometries.length === 1) {
                                            CenterAndZoom(view, geometries[0], zoom);
                                        }
                                    }
                                    view.map.layers.add(layer, 0);
                                    //Set geom information to MAPConstanstans, process further for simplified zoom levels and search
                                    resolve({ "Message": geometries.length + " Search Completed", graphics: geometries });
                                });
                            }
                            else {
                                resolve({ "Message": "Search Completed" });
                            }
                        },
                        error => {
                            console.log(error);
                            reject({ "error": error });
                        }
                    );

                }
            }

        }
    });
};


export function CenterAndZoomWithUnionPointZoom(view, graphics, defaultZoom) {
    if (graphics && graphics.length > 0) {
        if (graphics.length > 1) {
            GeometryEngineTask({ geometries: graphics, type: "unionpointzoom" })
                .then((GeomEngineresponse) => {
                    if (GeomEngineresponse) {
                        if (GeomEngineresponse.extent)
                            CenterAndZoom(view, GeomEngineresponse.extent);                        
                        else if (GeomEngineresponse.centroid)
                            CenterAndZoom(view, GeomEngineresponse.centroid, defaultZoom);
                    }
                },
                    (GeomEngineerror) => {
                        console.log(GeomEngineerror);
                    }
                );
        } else if (graphics.length === 1) {
            CenterAndZoom(view, graphics[0], defaultZoom);
        }
    }
}


export function QueryTaskExecuteZoomAddLayerToResponse(view, options, config, feature, mapConstants,
    queryField = "SearchField", zoomonly = false) {
    return new Promise((resolve, reject) => {
        let zoom = options.viewProperties.zoom;
        let spatialReference = options.viewProperties.extent.spatialReference;

        if (!loadModules) {
            reject('QueryTaskExecuteZoomToRepons: ArcGIS API is not loaded');
            return;
        }
        let queryParams = []
        let selectedLayer = options.SelectLayerListOptions.find(a => a.Url.includes(config.url));
        let selectedfield =  selectedLayer.SelectField;  
        if(selectedLayer){                     
            if (feature.attributes && feature.attributes[selectedfield]) {
                queryParams = feature.attributes[selectedfield] ? feature.attributes[selectedfield].toString().split(","):[];
            }
        }        
        let geometries = [];
        let queryParamsStr = ''
        for (var i = 0; i < queryParams.length; i++) {
            if (queryParamsStr === "") {
                if (queryParams[i] && queryParams[i] != null)
                    queryParamsStr = "'" + queryParams[i] + "'";
            } else {
                if (queryParams[i] && queryParams[i] != null)
                    queryParamsStr = queryParamsStr + ",'" + queryParams[i] + "'";
            }
        }

        loadModules(["esri/Graphic",
            "esri/layers/GraphicsLayer",
            "esri/layers/FeatureLayer",
            "esri/PopupTemplate"
        ]).then(([Graphic, GraphicsLayer, FeatureLayer, PopupTemplate]) => {
            //Adding only feature layer for taxlot
            let premaplayer = view.map.layers.find(function (layer) {
                return layer.title === "Taxlot selector";
            });
            view.map.layers.remove(premaplayer);
            let maplayer = new FeatureLayer({
                title: 'Taxlot selector',
                url: config.url,
                visible: true
            })
            let rendereruniqueValueInfos = [];            
            if (!zoomonly) {
                queryParams.map((taxlotval, indx) => {
                    let uniqvalObj = { value: taxlotval, symbol: mapConstants.PolygonFillSymbol, label: selectedfield };
                    let primaryObjIndex = options.FieldsData.findIndex((obj => obj.Fieldkey === "PRIMARY_TAX_PARCEL_IDS"));
                    if(primaryObjIndex > 0){
                       var primaryrecordsel =  options.FieldsData[primaryObjIndex].FieldValue;
                       //console.log('primarly selected record-------------', primaryrecordsel);
                       if(primaryrecordsel && primaryrecordsel===taxlotval){
                            uniqvalObj = { value: primaryrecordsel, symbol: mapConstants.PolygonFillSymbolSecondary, label: selectedfield };
                       }
                    }else{
                        if (indx == (queryParams.length - 1))
                            uniqvalObj = { value: taxlotval, symbol: mapConstants.PolygonFillSymbolSecondary, label: selectedfield };
                    }
                    rendereruniqueValueInfos.push(uniqvalObj);
                })
            }

            const highlightrenderer = {
                type: "unique-value",
                defaultLabel: selectedfield,
                field: selectedfield,
                defaultSymbol: {
                    type: "simple-fill",
                    color: [255, 255, 153, 0.01],
                    style: 'solid',
                    outline: {
                        type: "simple-line",
                        color: '#09eb3a',
                        width: "2px",
                        style: "solid"
                    }
                },
                uniqueValueInfos: rendereruniqueValueInfos
            };
            maplayer.renderer = highlightrenderer;

            maplayer.when(function () {
                maplayer.renderer.symbol.outline.color = '#09eb3a';
            });
            // //Add template
            // let popupTemplate = new PopupTemplate({
            //     title: 'Select Primary Taxlot ( {MapTaxlot} )',
            //     content: (evt) => PrimaryChangeContent(evt, view,options)
            // });
            // maplayer.popupTemplate = popupTemplate;

            view.map.layers.add(maplayer, view.map.layers.length - 2);
            if (feature && feature.geometry && zoomonly)
                CenterAndZoom(view, feature.geometry, zoom);

            //Set geom information to MAPConstanstans, process further for simplified zoom levels and search
            resolve({ "Message": geometries.length + " Search Completed", graphics: geometries });
        })
    });
};

function PrimaryChangeContent(evt, view, options) {
    console.log(evt, this);
    let selection = options.tasks.TaxParcels;
    var node = document.createElement("div");
    let chk = document.createElement("input");
    chk.type = "checkbox";
    chk.onclick = (evt1) => onPrimaryChage(view, evt.graphic.attributes);
    chk.id = "primarycheck_id";

    node.appendChild(chk);
    let brek = document.createElement("br");
    let bold = document.createElement("b");
    let lable = document.createElement("label");
    lable.for = "primarycheck_id";
    lable.innerHTML = " Is Primary Taxlot";
    bold.appendChild(lable);
    node.appendChild(bold);
    node.appendChild(brek);
    node.appendChild(brek);

    let lablebody = document.createElement("label");
    lablebody.innerHTML = "Pre Selected taxlots are : " + (selection.queryexpression ? selection.queryexpression : "NA");
    node.appendChild(lablebody);

    return node;
}

function GetSelectedTaxlots(view, options) {
    console.log(options);

}

function onPrimaryChage(view, selection) {
    console.log(view, selection);
    alert('Changed to primary' + selection)
    return true;
}

export function TaxlotLayerListner(view) {
    try {
        view.on("click", function (event) {
            view.hitTest(event).then(function (response) {
                console.log('all features at this point---', response);
                if (Array.isArray(response.results) && response.results.length > 0) {
                    // Check for any taxlot layers data present on hit test
                    let taxlots = response.results.filter(a => a.graphic.layer.title = "Taxlot selector");
                    console.log(taxlots.results)
                }
            })
        })
    } catch (error) {
        console.log(error)
    }
}