import * as Actions from './actions';

const initialState = {
    user: null,

}

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.AUTHORIZE:
            let user = setUserAccess(action.user);
            return { ...state, user: user }
        case Actions.PROFILE:
            let profile = action.profile;
            return { ...state, profile: profile }
        case Actions.LOGOUT:
            action.user = null;
            return { ...state, user: action.user }
        default:
            return state
    }
    return state;
}


export const getConfigType = (data) => {
    let profile = localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))
    switch (profile.configType) {
        case 'S':
            return data['SiteId']
        case 'R':
            return data['RegionId']
        case 'D':
            return data['DistrictId']
        default: return ''
    }
}

const setUserAccess = (user) => {
    if (user) {
        if (user.isAdmin === 'Y')
            user = true
    }
    /*
    if (user.isInternal) {
        if (user.isAdmin) {
            user.super = true;
            user.admin = true;
            user.editor = true;
            user.access = true;
        }
        if (user.isEditor && user.canAdd) {
            user.admin = true;
            user.editor = true;
            user.access = true;
        }
        if (user.isEditor) {
            user.editor = true;
        }
        user.moderator = true;
    }
    else {
        if (user.isAdmin)
            user.access = true;
        else
            user.access = false
    }

    user.trusted = false;

    if (user.isInternal) {
        if (user.isAdmin) {
            user.trusted = true;
        }
        if (user.isEditor && user.canAdd)
            user.trusted = true;
    }
    else {
        if (user.isAdmin)
            user.trusted = true;
    }*/
    return user;

}