import { loadModules } from 'esri-loader';
//import { GeometryEngineTask } from '../util/geometryEngineTask';
import { QueryTaskExecuteWithrelation, QueryTaskExecuteBufferWithrelation } from '../util/queryTask';



/**
 * GIS fetch lookup using features
 * @param {*} features
 * @param {*} domainlookupdata
 */
export function GISLookupTask(config, features, domainlookupdata) {
    console.log('GIS lookup fields for query *********', domainlookupdata)
    let geometries = [];

    //caluclating only on the single geom, need to change for multiple
    geometries.push(features)
    return new Promise((resolve, reject) => {
        if (domainlookupdata && domainlookupdata.length > 0) {
            let allPromises = [];

            domainlookupdata.forEach(function (domainitem) {
                let FieldKey = domainitem.FieldKey;
                let NameReference = domainitem.NameReference;
                let NameFormat = domainitem.NameFormat;
                let ValueReference = domainitem.ValueReference;
                let ValueFormat = domainitem.ValueFormat;
                let ServiceUrl = domainitem.ServiceUrl;
                let eachpromise = new Promise((resolve, reject) => {
                    let gisFeatureLayer = { url:ServiceUrl,  Token: domainitem.Token, TokenExpiration: domainitem.TokenExpiration };                    
                    let queryExpression = "1=1";
                    let inputgeom;

                    //verify geometries and get the center if multiple
                    if (geometries && geometries.length > 1) {
                        inputgeom = geometries[0];
                    } else {
                        inputgeom = geometries[0];
                    }
                    QueryTaskExecuteBufferWithrelation(gisFeatureLayer, queryExpression, config.viewProperties.extent.spatialReference, inputgeom).then(
                        response => {
                            let gisResponse = {}
                            //Check isMultiple Names
                            let isMultiNames = false;
                            let isMultiValues = false;
                            let refs = NameReference ? NameReference.split(',') : [];
                            let valefs = ValueReference ? ValueReference.split(',') : [];

                            if (NameReference) {
                                if (refs && refs.length > 1)
                                    isMultiNames = true;
                            }
                            if (ValueReference) {
                                if (valefs && valefs.length > 1)
                                    isMultiValues = true;
                            }
                            if (response && response.features && response.features.length > 0) {
                                let resposnsattr = response.features[0].attributes;
                                gisResponse.FieldKey = FieldKey;
                                gisResponse.NameReferenceValue = (isMultiNames && NameFormat) ? GetFormatVal(resposnsattr, NameFormat, refs) : resposnsattr[NameReference]; //resposnsattr[NameReference];
                                gisResponse.ValueReferenceValue = (isMultiValues && ValueFormat) ? GetFormatVal(resposnsattr, ValueFormat, valefs) : resposnsattr[ValueReference];
                            }
                            resolve(gisResponse);
                        },
                        error => {
                            console.log(error);
                            resolve({});
                        }
                    )


                });

                allPromises.push(eachpromise);
            })


            Promise.all(allPromises).then((data) => {
                let responseData = {}
                if (data && data.length > 0) {
                    data.forEach(function (domainitem) {
                        responseData[domainitem.FieldKey] = domainitem.ValueReferenceValue;
                    })
                }
                console.log(data)
                resolve(responseData);
            });
        } else {
            console.log({ error: 'No geometries found to gis lookup task' })
            resolve({});
        }
    })
}

function GetFormatVal(attributes, namereference, arrnamereference) {
    if (Array.isArray(arrnamereference) && namereference) {
        arrnamereference.forEach(ref => {
            try {
                namereference = namereference.replace('${' + ref + '}', attributes[ref])
            } catch (error) {
                console.log(error);
            }
        });
    }
    return namereference;
}

/**
 * GIS fetch lookup using geometries
 * @param {*} features
 * @param {*} domainlookupdata
 */
export function GISLookupTaskWithGeom(config, geometries, domainlookupdata) {
    console.log('GISLookupTaskWithGeom-------', geometries,domainlookupdata);
    let UIFieldType = {}
    return new Promise((resolve, reject) => {
        if (domainlookupdata && domainlookupdata.length > 0) {
            let allPromises = [];
            //let initialBuffer = 100; //Meter caluclation
            let initialBuffer = (config && config.GISLookupBuffer) ? config.GISLookupBuffer : 1 ; //Meter caluclation
            domainlookupdata.forEach(function (domainitem) {
                UIFieldType[domainitem.FieldKey] = domainitem.UIFieldTypeComponent
                let FieldKey = domainitem.FieldKey;
                let NameReference = domainitem.NameReference;
                let ValueReference = domainitem.ValueReference;
                let ServiceUrl = domainitem.ServiceUrl;
                let FieldIsBuffer = domainitem.FieldIsBuffer;
                let FieldBufferDistance = domainitem.FieldBufferDistance;
                let FieldBufferUnits = domainitem.FieldBufferUnits;
                let Token = domainitem.Token || null;
                let TokenExpiration = domainitem.TokenExpiration || null;
                let NameFormat = domainitem.NameFormat;
                let ValueFormat = domainitem.ValueFormat;

                let eachpromise = new Promise((resolve, reject) => {
                    let gisFeatureLayer = {
                        url : ServiceUrl,
                        Token: Token,
                        TokenExpiration: TokenExpiration
                    }
                    let queryExpression = "1=1";
                    let inputgeom = {};
                    if (geometries && geometries.length > 1) {
                        inputgeom.geometry = geometries[0];
                    } else {
                        inputgeom.geometry = geometries[0];
                    }

                    QueryTaskExecuteBufferWithrelation(gisFeatureLayer, queryExpression, config.viewProperties.extent.spatialReference, inputgeom, FieldBufferDistance, FieldBufferUnits).then(
                        response => {
                            let gisResponse = {}
                            let isMultiNames = false;
                            let isMultiValues = false;

                            let refs = NameReference ? NameReference.split(',') : [];
                            let valefs = ValueReference ? ValueReference.split(',') : [];
                            if (NameReference) {
                                if (refs && refs.length > 1)
                                    isMultiNames = true;
                            }
                            if (ValueReference) {
                                if (valefs && valefs.length > 1)
                                    isMultiValues = true;
                            }

                            if (response && response.features && response.features.length > 0) {
                                let resposnsattr = response.features[0].attributes;
                                try {
                                    gisResponse.FieldKey = FieldKey;
                                    gisResponse.NameReferenceValue = (isMultiNames && NameFormat) ? GetFormatVal(resposnsattr, NameFormat, refs) : resposnsattr[NameReference]; //resposnsattr[NameReference];
                                    gisResponse.ValueReferenceValue = (isMultiValues && ValueFormat) ? GetFormatVal(resposnsattr, ValueFormat, valefs) : resposnsattr[ValueReference];
                                } catch (e) {
                                    console.log(e)
                                }
                                //check buffer with db value
                                resolve(gisResponse);
                            } else if (FieldIsBuffer && FieldIsBuffer == 'Y') {
                                try {
                                    gisResponse.FieldKey = FieldKey;
                                    gisResponse.NameReferenceValue = null;
                                    gisResponse.ValueReferenceValue = null;
                                } catch (e) {
                                    console.log(e)
                                }
                                //check buffer with db value
                                resolve(gisResponse);
                            } else {
                                let buffertimeresponse = {
                                    ...domainitem,
                                    BufferTime: true,
                                    BufferDistance: initialBuffer
                                }
                                DomainLookupWithinBufferDistance(config, buffertimeresponse, inputgeom).then(function (result) {
                                    resolve(result);
                                });
                            }
                        }, error => {
                            console.log(error);
                            reject({ "error": error });
                        })
                });
                allPromises.push(eachpromise);
            })
            return Promise.all(allPromises).then((data) => {
                console.log('GIS lookup completed *********', data)
                let responseData = {}
                if (data && data.length > 0) {
                    data.forEach(function (domainitem) {
                        // responseData[domainitem.FieldKey] = domainitem.ValueReferenceValue ?
                        // (UIFieldType[domainitem.FieldKey] === 'TB' ? domainitem.NameReferenceValue : domainitem.ValueReferenceValue.toString().trim()) : "";
                        responseData[domainitem.FieldKey] = domainitem.ValueReferenceValue ? domainitem.ValueReferenceValue.toString().trim() : "";
                        if (domainitem.FieldKey === "TOWNSHIP" && domainitem.NameReferenceValue) {
                            let resultreference = domainitem.NameReferenceValue.split(" ")
                            if (resultreference && resultreference.length > 1) {
                                responseData["TOWNSHIP"] = resultreference[0]
                                responseData["RANGE"] = resultreference[1]
                                //FieldKey: "TOWNSHIP"
                                //NameReferenceValue: "T35S R04W"
                                //ValueReferenceValue: "035"
                            }
                        }
                    })
                }
                console.log(responseData)
                resolve(responseData);
            });

        } else {
            resolve({});
        }

    })
}

function DomainLookupWithinBufferDistance(config, domainitem, inputgeom) {
    // console.log(domainitem);
    //console.log('Re query with buffer----------------------------', domainitem);
    let FieldKey = domainitem.FieldKey;
    let NameReference = domainitem.NameReference;
    let NameFormat = domainitem.NameFormat;
    let ValueReference = domainitem.ValueReference;
    let ValueFormat = domainitem.ValueFormat;
    let ServiceUrl = domainitem.ServiceUrl;
    let buffer = domainitem.BufferDistance;

    return new Promise((resolve, reject) => {
        let gisFeatureLayer = {}
        gisFeatureLayer.url = ServiceUrl;

        let queryExpression = "1=1";

        QueryTaskExecuteBufferWithrelation(gisFeatureLayer, queryExpression, config.viewProperties.extent.spatialReference, inputgeom, buffer, "feet").then(
            response => {
                let gisResponse = {}

                let isMultiNames = false;
                let isMultiValues = false;
                let refs = NameReference ? NameReference.split(',') : [];
                let valefs = ValueReference ? ValueReference.split(',') : [];
                if (NameReference) {
                    if (refs && refs.length > 1)
                        isMultiNames = true;
                }
                if (ValueReference) {
                    if (valefs && valefs.length > 1)
                        isMultiValues = true;
                }

                if (response && response.features && response.features.length > 0) {
                    let resposnsattr = response.features[0].attributes;
                    try {
                        gisResponse.FieldKey = FieldKey;
                        gisResponse.NameReferenceValue = (isMultiNames && NameFormat) ? GetFormatVal(resposnsattr, NameFormat, refs) : resposnsattr[NameReference]; //resposnsattr[NameReference];
                        gisResponse.ValueReferenceValue = (isMultiValues && ValueFormat) ? GetFormatVal(resposnsattr, ValueFormat, valefs) : resposnsattr[ValueReference];

                    } catch (e) {
                        console.log(e)
                    }
                    //check buffer with db value
                    resolve(gisResponse);
                } else {
                    let buffertimeresponse = {
                        ...domainitem,
                        BufferTime: true,
                        BufferDistance: buffer * 2
                    }
                    DomainLookupWithinBufferDistance(config, buffertimeresponse, inputgeom).then(function (result) {
                        resolve(result);
                    });
                }

            },
            error => {
                console.log(error);
                resolve({})
                //reject({ "error": error });
            })

    });

}