import { getEnvVariable } from "../../../../modules/environmentalList"

const AttachmentAdd = {
    "type": "popup",
    "key": "AttachmentAdd",
    "name": "AttachmentAdd",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'addOpen'
    },
    sharedValues: ['open'],
    container: {
        values: { save: false, back: false, fullscreen: false, },
        mapValues: {
            header: [
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
            ],
            Attachment: [{ key: 'save', name: 'save' },
            { key: 'exitfullscreen', name: 'exitfullscreen' },
            { key: 'fullscreen', name: 'fullscreen' },]
        },
        //  sharedValues: ['save', 'edit'],
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },


            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'Attachment',
                    type: 'div',
                    className: 'col-12 pl-4 m-3',
                    sub: {
                        type: 'div',
                        className: 'col-12 pl-4 m-3',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Attachment',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "Add Attachment", "materialicon": "attach_file", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'save',
                        hidevalue: false,
                        title: 'Save',
                        color:"#008000"
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hidevalue: false,
                        title: 'FullScreen'
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false,
                        title: 'Exit FullScreen'
                    }
                ],


            },
            Attachment: {
                "type": "form",
                "key": "Attachment",
                "name": "Attachment",
                aliases: {
                    save: 'save',
                },
                sharedValues: ['save', 'back', 'exitfullscreen', 'fullscreen'],
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'FileName',
                                type: 'div',
                                className: 'col-10 ml-3 mt-2',
                                sub: {
                                    type: 'div',
                                    className: 'col-10 ml-3 mt-2',
                                }
                            }],

                        },

                        {
                            order: 1,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Notes',
                                type: 'div',
                                className: 'col-10 ml-3 mt-2',
                                sub: {
                                    type: 'div',
                                    className: 'col-10 ml-3 mt-2'
                                }
                            },]
                        },
                    ],
                },
                values: {
                    FileName: '',
                    Notes: '',

                },
                "components": {
                    FileName: {
                        "type": "textfield",
                        "key": "FileName",
                        "label": "File Name",
                        "name": "FileName",
                    },
                    Notes: {
                        "type": "textfield",
                        "key": "Notes",
                        "label": "Notes",
                        "name": "Notes",
                        "multiline": true,
                        rowsMax: "5",
                        "rows": 6,
                    },
                },
                mapActionsToEffects: {
                    'onLoad': {
                        init: {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'validations', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                            "read": [
                                                {
                                                    "url": getEnvVariable('Sidebar') + `/api/ProjectMessages/RulesByName?name=modulemaster.Attachments`,
                                                    type: 'get'
                                                }
                                            ],
                                            transformProps: {
                                                removesubfieldname: 'AMESMODULEMASTER.AttachmentS_'
                                            }
                                        },
                                    ]
                                }
                            }]
                        },
                        'exitfullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'exitfullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'fullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'fullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    },
                    'afterSave': {
                        'save': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',// http://development.delasoft.com:8443/AMES-application/api/Property/23103/Add
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Admin') + `/api/Files/{mid}/NoticeFileUploadFiles`,
                                                        type: 'post',
                                                        "matchProps": [{
                                                            name: "mid",
                                                            key: "mid",
                                                        }],
                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    }
                }
            }
        },

    }
}

export default AttachmentAdd
