import React, { Fragment, Component } from "react";
import { DateTimePicker, KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FormHelperText from '@material-ui/core/FormHelperText';
import DefaultSettings from "../../controls/components/settings";
import DatePicker from '../../controls/components/datepicker';

class KeyboardDatePickerExample extends Component {

    handleChange = (e) => {
        // const { handleFieldChange, value, name, id, edit, options, ...otherProps } = this.props
        // let convertMoment = e ? moment(e, 'MM/DD/YYYY').format('MM/DD/YYYY') : null
        // let events = { target: { value: convertMoment, name: name, checked: false } }
        this.props.handleFieldChange(e);
    }

    render() {
        const { type, tooltip, required, error, helperText, contexthelp, handleFieldChange, name, options, value, edit } = this.props
        const { label, disabled, FieldProps } = options;

        if (edit === false) {
            return <Fragment>
                {tooltip && <div > <Typography variant={'caption'} className="fw-bold">{label}&nbsp;
                    < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                        <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} onClick={(evt) => contexthelp ?
                            DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                    </Tooltip>  </Typography>   <Typography variant={'body2'} color={'textPrimary'} className="text-secondary">{(value !== '' && moment(value).format('MM/DD/YYYY')) || ''}   </Typography>
                </div>}
                {!tooltip && <div > <Typography variant={'caption'} className="fw-bold">{label}&nbsp; </Typography>   <Typography variant={'body2'} color={'textPrimary'} className="text-secondary">{(value !== '' && moment(value).format('MM/DD/YYYY')) || ''}   </Typography>
                </div>}
            </Fragment >
        }

        else return (
            <Fragment>
                <div className='row col-12'>
                    <DatePicker
                        disabled={disabled || false}
                        variant="filled"
                        ampm={false}
                        value={value}
                        handleFieldValue={this.handleChange}
                        label={label}
                        error={error}
                        name={name}
                        required={required}
                        FieldProps={FieldProps && JSON.stringify(FieldProps) || null}
                        helperText={helperText}
                        contexthelp={contexthelp}
                    />
                    {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        clearable
                        variant="filled"
                        disabled={disabled || false}
                        ampm={false}
                        value={((currentDate && val === '') ? new Date() : val) || null}
                        disablePast={passDate}
                        disableFuture={futureDate}
                        onChange={this.handleChange}
                        label={tlabel}
                        error={error}
                        name={name}
                        required={required}
                        helperText={helperText}
                        format="MM/DD/YYYY"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </MuiPickersUtilsProvider>*/}
                </div>
            </Fragment>
        );
    }
}

export default KeyboardDatePickerExample;