import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { loadModules } from 'esri-loader';
import { TaxParcelTask, TaxParcelTaskFeatureLayer } from '../eps/taxParcelTask';
import { MapConstants } from '../util/mapConstants';
import {QueryTask, QueryTaskBufferWithGeom, CenterAndZoom } from '../util/queryTask';
import CCompMultiLocationBufferCalc from './compmultilocationbuffercalc'

class CMultiLocationSelector extends Component {
    constructor(props) {
        super(props); 
        this.state =  { 
            view: this.props.view,
            units:"feet",
            distance: "0"
        } 
        this.startup(
            this.props
        ); 
    }
   
     
    startup = (config) => {       
        
        loadModules([
            "esri/core/watchUtils",
            "esri/widgets/Expand",            
        ]).then(([watchUtils, Expand]) => {  
            this.multilocationcalccontainer = ReactDOM.findDOMNode(this);   
            let domEle = document.getElementsByClassName("map-locationselector-container");
            if(domEle){
                const locationcalcExpand = new Expand({
                    collapseTooltip: "Collapse Location Selection Tool",
                    expandTooltip: "Expand Location Selection Tool",
                    view: config.view,
                    //content: node,
                    content: domEle[0],
                    //container: this.buffercalccontainer,
                    expandIconClass: "esri-icon-applications",
                    expanded:true
                }); 
                config.view.ui.add(locationcalcExpand, "bottom-left");    
                this.props.widgetRenderCallBack("MultiLocationSelector");      
                const inThis = this;
                watchUtils.pausable(locationcalcExpand, "expanded", function (newValue, oldValue) { 
                    inThis.props.onexpanded(locationcalcExpand, newValue);
                });
            }
        });
    }
     
    bufferCalcLayer(){
        return (this.props.options && this.props.options.LayerOptions.length > 0) ? this.props.options.LayerOptions : {};
    }
 
  render() {
    return (
        <div id="multiLocationSelectorParent" style={{order:this.props.order}}>
            <div id="multiLocationSelector" className="map-locationselector-container">
                <CCompMultiLocationBufferCalc  {...this.props} 
                    bufferCalcLayer = {this.bufferCalcLayer()} 
                    canEdit={this.props.canEdit} 
                    parentcal="multilocation" 
                    coordinates={this.props.coordinates}
                    multipleLocationHandler={this.props.multipleLocationHandler}/> 
            </div>
        </div>
        
    );
  }

}

export default CMultiLocationSelector;