import { Button, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DropDownIcon from '@material-ui/icons/VerticalAlignBottom';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import '@progress/kendo-react-animation';
import { handleTreeViewCheckChange, processTreeViewItems } from '@progress/kendo-react-treeview';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getEnvVariable } from '../..//modules/environmentalList';
import { passtabData, snackbarMessage } from '../../core/actions';
import FormHeader from '../../core/modules/form/cformheader';
import { requestApi } from '../../core/modules/form/utilities';
import ProgressBar from '../../core/modules/progress-bar';
import { getData, postRecordData } from '../../core/modules/rest';

//for styling
const styles = theme => ({
    root: {
        background: 'white',
        // position: '-webkit-sticky',
        position: 'static',
        top: 20,
        bottom: 20,
        paddingTop: '40px',
        paddingBottom: '40px',
        zIndex: 5,
    }
})

class CRoutingTreeView extends React.Component {
    state = {
        items: null, singleMode: false, checkChildren: false, checkParents: false,
        check: { ids: [], applyCheckIndeterminate: true }, expanded: [], selected:[],
        itemsselected:[], defaultExpanded:[], initialload : true , notescount : false,
        showvalidation : false

    };
    expandedvalue = []
    componentDidMount() {
        const { options, components, extraProps, match, dataItem } = this.props
        const { crud, validations, iscurrentuser } = options ? options : {}
        const { read } = crud ? crud : {}
        const { get } = read ? read : {}
        const { routeProps, initialloadextrainfoProps, customProps, allnotesurl, allroutingurl, allnotescounturl } = get ? get : {}
        const { addURL } = match;
        let notesurl = allnotesurl;
        let routingurl = allroutingurl
        let notescounturl = allnotescounturl
        let ids = [], id
        let treeURL = get.url;
        this.progressbar.start();
        if (Array.isArray(initialloadextrainfoProps) && this.props.initialloadextrainfo && this.props.initialloadextrainfo.results) {
            initialloadextrainfoProps.forEach(each => {
                const { value } = each
                ids.push({ key: value, value: this.props.initialloadextrainfo.results[value] })
            })
        }
        if (Array.isArray(routeProps)) {
            routeProps.forEach(each => {
                const { value } = each
                ids.push({ key: value, value: this.props.match.params[value] })
            })
        }

        else if (routeProps) {
            id = routeProps ? this.props.match.params[routeProps.value] : null
        }
        else if (customProps) {
            id = customProps ? (dataItem.Id || dataItem.userId) : null
        }
        if (iscurrentuser && this.props.accessPermissions) {
            id = this.props.accessPermissions.userId;
        }
        if (addURL == "?isadmin=Y") {
            treeURL = get.url + id + addURL;
        }
        else {
            if(id) {
                treeURL = get.url + id;
            }
            else if(ids && ids.length>0) {
                ids.forEach(each => {
                    const { key, value } = each;
                    notesurl = `${notesurl.replace(`{${key}}`, value)}`;
                    routingurl = `${routingurl.replace(`{${key}}`, value)}`;
                    treeURL =  `${treeURL.replace(`{${key}}`, value)}`;
                    notescounturl = `${notescounturl.replace(`{${key}}`, value)}`;
                })
            }
            else{
                treeURL = get.url
            }
        }

        //let getRecordNotesDetails = getEnvVariable('Application')+`/api/Notes/23445/All`

        let allRequests = [this.getDataPromise(treeURL), this.getDataPromise(notesurl), this.getDataPromise(routingurl), this.getDataPromise(notescounturl)];
        Promise.all(allRequests).then((res) => {
            this.setState({notescount : res[3]})
            this.dataWrapper(res[0],res[1], res[2])
        })

        // getData(treeURL).then(treeURLData => {
        //     getData(allnotesurl).then(allnotesurlData => {
        //         getData(allroutingurl).then(allroutingData => {
        //             this.dataWrapper(treeURLData,allnotesurlData, allroutingData)
        //         })
        //     })
        // }).catch(err => {
        //     this.progressbar.complete();
        // });
    }

    dataWrapper = (treeData, allnotesData, allroutingData) => {
        let result = this.treeStructureLatestUpdates(treeData, allnotesData, allroutingData, 0)
        console.log(result)
        this.setState({ items: result })
        this.progressbar.complete();
    }

    getDataPromise = (url) =>{
        return new Promise((resolve, reject) => {
            getData(url).then(data => {
                resolve(data)
            }).catch(err => {
                resolve([])
            });
        })
    }

    treeStructureLatestUpdates = (res, allnotes, allrouting, superparentid) => {
        const { options, match } = this.props;
        const { addURL } = match;
        const { tree } = this.state
        const { headerProps, title, viewMode } = options;
        let isViewMode = viewMode
        if (addURL == "?isadmin=Y") {
            isViewMode = true;
        }
        return res.map(each => {
            each.expanded = true;
            each.disabled = isViewMode;
            each.lastcommenteddate = this.getLastDataNotes(allnotes, each.id) ;
            each.userid = this.getLastDataCreated(allnotes, each.id) ;
            each.lastsentdate = this.getLastDataRouting(allrouting, each.id) ;
            each.superparentid = superparentid;
            this.expandedvalue.push(each.id+'node'+superparentid)
            if (each.items) {
                each.items = this.treeStructureLatestUpdates(each.items, allnotes, allrouting, each.parentID);
            }
            return each
        })
    }

    getLastDataCreated = (records, id) =>{
        if(records && Array.isArray(records)){
            let filterrecords = records.filter(a => a.CreatedBy == id);
            if(filterrecords.length > 0){
                filterrecords = filterrecords.sort((a,b) => b.Id - a.Id);
                console.log('filtered data *******', filterrecords)
                return filterrecords[0].CreatedBy
            }
        }
        return "";
    }

    getLastDataNotes = (records, id) =>{
        if(records && Array.isArray(records)){
            let filterrecords = records.filter(a => a.CreatedBy == id);
            if(filterrecords.length > 0){
                filterrecords = filterrecords.sort((a,b) => b.Id - a.Id);
                console.log('filtered data *******', filterrecords)
                return this.getDateFormat(filterrecords[0].CreatedOn)
            }
        }
        return "";
    }
    getLastDataRouting = (records, id) =>{
        if(records && Array.isArray(records)){
            let filterrecords = records.filter(a => a.UserId == id);
            if(filterrecords.length > 0){
                filterrecords = filterrecords.sort((a,b) => b.Id - a.Id);
                console.log('filtered data *******', filterrecords)
                return this.getDateFormat(filterrecords[0].CreatedOn)
            }
        }
        return "";
    }

    handleSave = () => {
        const { options, components, extraProps, dataItem,onClose } = this.props
        const { crud, validations, title } = options ? options : {}
        const { update } = crud ? crud : {}
        const { put } = update ? update : {}
        const { routeProps, customProps } = put ? put : {}
        let ids = [], id
        if (Array.isArray(routeProps)) {
            routeProps.forEach(each => {
                const { value } = each
                ids.push({ key: value, value: this.props.match.params[value] })
            })
        }
        else if (routeProps) {
            id = routeProps ? this.props.match.params[routeProps.value] : null
        }
        else if (customProps) {
            id = customProps ? this.props.dataItem[customProps.custom] : null
        }
        postRecordData(put.url + id, processTreeViewItems(this.state.items, { check: this.state.check })).then(res => {
            this.props.snackbarMessage(title + ' Saved Successfully', 'success');
            this.props.onClose()
        }).catch(error => {
            this.props.snackbarMessage(JSON.stringify(error), 'error');
        })
    }

    getAllSelectedItems(allitems){
        let returnitems = []
        allitems.forEach(element => {
            if(element.checked){
                returnitems.push(element.id)
                if(element.items && Array.isArray(element.items)){

                }
            }
        });


    }

    getAllSelectedItems = (allitems, result, superparentid = 0, nonresponders) => {
        allitems.map(element => {
            if(element.parentType == "R" && element.checked){
                result.push({id: element.id, name: element.text, description: element.description, refId: 0, groupId: superparentid, roleId: element.parentID} )
            }
            if(element.parentType == "R" && element.lastsentdate && element.lastcommenteddate == ''){
                nonresponders.push({id: element.id, name: element.text, description: element.description, refId: 0, groupId: superparentid, roleId: element.parentID} )
            }
            if(element.items && Array.isArray(element.items)){
                this.getAllSelectedItems(element.items, result, element.parentID, nonresponders);
            }
        })
    }
    handleNoteUrl = () =>{
        this.props.history.push('/' + this.props.match.params.key + '/' + this.props.match.params.routeid + '/' + this.props.match.params.routeid1 + '/' + 'notes')
    }

    handleDoneOutline = (e, isbool) => {
        let selectedrecords = [];
        let nonresponders = []
        this.getAllSelectedItems(this.state.items, selectedrecords, 0, nonresponders);
        let passDbData = isbool ?  { 'UsersList' : nonresponders } :  { 'UsersList' : selectedrecords }
        this.props.passtabData(passDbData);
        if(passDbData && passDbData.UsersList && passDbData.UsersList.length<=0) {
           this.setState({showvalidation : true})
        }
        else {
            this.props.handleTabChange(e, this.props.options.tabindex)
        }
    }

    checkForNestedItems(allitems, element, isChecked, superparentid){
        let isitemfound = false;
        allitems.map(a => {
            if(a.parentName == element.parentName && a.parentID == element.parentID && a.id == element.id && a.superparentid == superparentid){
                a.checked =  isChecked;
                isitemfound = true;
            }
            if(!isitemfound && a.items && Array.isArray(a.items)){
                this.checkForNestedItems(a.items, element, isChecked, superparentid);
            }
            if(isitemfound && a.items && Array.isArray(a.items)){
                // Check all nested child elements
                this.checkAllChildItems(a.items, isChecked)
            }
        })
        return allitems;
    }

    DownloadNote = (e) =>{
        requestApi({ get: { url: getEnvVariable('Application')+`/api/`+getEnvVariable('ModuleKey')+`Notes/PDF/${this.props.match.params.routeid1}/${e.userid}`, } })
            .then((data) => {
                    console.log(data)
                    this.progressbar.complete();
                    if(data && data.base64PDFFileContent){
                        this.downloadBase64File(data.base64PDFFileContent, data.fileName);
                    }
                }).catch(err => {

                });
    }

    checkAllChildItems(allitems, isChecked){
        allitems.forEach(childrecord => {
            childrecord.checked = isChecked;
            if(childrecord.items && Array.isArray(childrecord.items)){
                // Check all nested child elements
                this.checkAllChildItems(childrecord.items, isChecked)
            }
        })
    }

    getDateFormat(stringdate){
       return new Date(stringdate).toLocaleDateString();
    }

    handleCheckBoxChange = (event, element, superparentid) => {
        let name = event.target.name;
        let isChecked = event.target.checked ;
        let moditems = this.checkForNestedItems(this.state.items, element, isChecked, superparentid)
        this.setState({showvalidation:false, items : moditems},()=>{
            console.log(this.state)
            console.log('state item----------')
        });
       // let isChecked =  [event.target.name]: event.target.checked
    }




    getRoutingControl = (e, superparentid) => {
        let nestedchecks = []
        let lableVal =  e.title ? e.text +' (~'+ e.title+')' : e.text;
        let el = e;
        el.superparentid = superparentid;
        return (
                <div>
                    <TreeItem nodeId={e.id+'node'+superparentid} label={
                        <div className="row col-12">
                            <div className="col-5">
                                <FormControlLabel key={e.id} control={
                                    <Checkbox
                                        checked={e.checked}
                                        onChange={(evt) => this.handleCheckBoxChange(evt, el, superparentid)}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={<span style={{ fontSize: '14px' }}>{lableVal}</span>}>
                                </FormControlLabel>
                            </div>

                            <div className="col-3 pl-2">
                                <Typography  className="" variant="caption" align="right" color="inherit">
                                    {e.lastsentdate}
                                </Typography>
                            </div>
                            <div className="col-3 pl-2">
                                <Typography  className="" variant="caption" align="right" color="inherit">
                                    {e.lastcommenteddate}
                                </Typography>
                            </div>
                            {e.lastcommenteddate!="" &&
                            <div >
                               <i className="" onClick={() => this.DownloadNote(e)}><IconButton title="Print" color="inherit">
                                <DropDownIcon fontSize="small" />
                            </IconButton></i>

                            </div>}
                        </div>}>
                        {e.items && Array.isArray(e.items) && e.items.map((nest) => {
                            //console.log(nest)
                            //nestedchecks.push(this.getRoutingControl(nest))
                            return  this.getRoutingControl(nest,e.parentID)

                        })}
                        </TreeItem>
                    <Divider />
                </div>

                )
    }

    handleToggle = (event, nodeIds) => {
        this.setState({expanded : nodeIds, initialload: false})
     };

      handleSelect = (event, nodeIds) => {
       this.setState({selected : nodeIds})
     };

     handlePrint = () =>{
        this.progressbar.start();
        const { options, components, extraProps, match, dataItem } = this.props
        const { crud, validations, iscurrentuser } = options ? options : {}
        const { read } = crud ? crud : {}
        const { get } = read ? read : {}
        const { routeProps, customProps, allnotesurl, allroutingurl, printurl } = get ? get : {}
        let ids = []
        let printurlval = printurl
        if (Array.isArray(routeProps)) {
            routeProps.forEach(each => {
                const { value } = each
                ids.push({ key: value, value: this.props.match.params[value] })
            })
        }
        ids.forEach(each => {
            const { key, value } = each;
            printurlval = `${printurlval.replace(`{${key}}`, value)}`;
        })
        console.log(printurlval)
        getData(printurlval).then(data => {
            console.log(data)
            this.progressbar.complete();
            if(data && data.base64PDFFileContent){
                this.downloadBase64File(data.base64PDFFileContent, data.fileName);
            }
            else {
                this.props.snackbarMessage('There are no notes to download', 'info');
            }
        }).catch(err => {

        });

     }

     downloadBase64File = (contentBase64, fileName) => {
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.href = contentBase64;
        downloadLink.target = '_self';
        downloadLink.download = fileName;
        downloadLink.click();
    }

    render() {
        const { options, match, classes } = this.props;
        const { addURL } = match;
        const { tree, items } = this.state
        const { headerProps, title, viewMode, designprops } = options;
        let isViewMode = viewMode;
        if (addURL == "?isadmin=Y") { isViewMode = true; }
        return (
            <Fragment>
                <div className=" example-config">
                    <FormHeader className="position-fixed" title={title}
                        handleSave={this.handleSave}
                        handleContact = {this.handleDoneOutline}
                        handleNotes={this.handleNoteUrl}
                        doneoutline= {headerProps.doneoutline}
                        handleDoneOutline= {this.handleDoneOutline}
                        sendbutton={headerProps.sendbutton}
                        contactbutton = {headerProps.contactbutton}
                        notesbutton ={headerProps.notesbutton}
                        savebutton={headerProps.savebutton && !isViewMode}
                        closebutton={headerProps.closebutton}
                        handleClose={() => this.props.onClose()}
                        designprops={designprops}
                        printbutton={this.state.notescount ? headerProps.printbutton : false}
                        handlePrint = {this.handlePrint}
                        hasclearfix={true}
                        />
                </div>
                {this.state.showvalidation && <FormHelperText error={true}><b>Please select atleast one user to send notification</b></FormHelperText>}
                <div> <ProgressBar ref={e => this.progressbar = e} /></div>
                { !isViewMode && <div className={classes.root}><div className={!designprops ? 'row  float-right p-2' : 'row col-12'}>
                    <div className="col-4">
                        <Button variant="contained" color="primary" onClick={this.checkAll} >Check all</Button>
                        <span>&nbsp;</span>
                        <Button variant="contained" color="primary" onClick={this.uncheckAll}>Uncheck all</Button>
                    </div>
                    <div className="col-3">
                        <Typography  variant="caption" align="right" color="inherit">
                           Last Sent Date
                        </Typography>
                    </div>
                    <div className="col-3">
                        <Typography  variant="caption" align="right" color="inherit">
                            Last Commented Date
                        </Typography>
                    </div>
                    <div className="col-2">
                        <Typography  variant="caption" align="right" color="inherit">
                            Download Note
                        </Typography>
                    </div>


                </div></div>}
                <div className={!designprops ? "row  col-sm-12 eps-treestyles pt-2" : ""}>
                <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        selected={this.state.selected}
                        onNodeToggle={this.handleToggle}
                        onNodeSelect={this.handleSelect}
                        expanded={this.state.initialload ? this.expandedvalue : this.state.expanded}
                        defaultExpanded={this.state.defaultExpanded}

                    >

                    {items && Array.isArray(items) && items.map((e) => {
                           return this.getRoutingControl(e, e.parentID)
                    })}

                </TreeView>
                {/* <FormControlLabel key={e.id} control={
                                <Checkbox
                                    checked={true}
                                    onChange={this.handleCheckBoxChange}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label={e.text}
                            /> */}

                    {/* <TreeView expandIcons={true} onExpandChange={this.onExpandChange}
                        // data={this.state.items}
                        data={processTreeViewItems(this.state.items, { check: this.state.check })}
                        checkboxes={true} onCheckChange={this.onCheckChange}

                    /> */}



                </div>

            </Fragment>
        );
    }



    // onCheckChange = (event) => {
    //     event.item.checked = !event.item.checked;
    //     event.item.isChecked = event.item.checked
    //     this.setState({ ...this.state.items, ...event })
    // }


    onCheckChange = (event) => {
        const settings = { checkChildren: true, checkParents: true };
        event.item.checked = !event.item.checked;
        // if(event.item.parentID){
        //    this.searchSpecifiedTreeItem(event.item.parentID, event.item.parentName, this.state.items);
        // }
        // if(event.item.items){
        //     this.makeChecked(event.item.items, event.item.checked);
        // }
        this.setState({ check: handleTreeViewCheckChange(event, this.state.check, this.state.items, settings) });
    }

    searchSpecifiedTreeItem(id, name, treeItems) {
        for (let index = 0; index < treeItems.length; index++) {
            const element = treeItems[index];
            if (element.id == id && element.text == name) {
                if (element && element.items.some(s => s.checked == false)) {
                    element.checkIndeterminate = true;
                }
                else {
                    element.checkIndeterminate = false;
                    element.checked = true;
                }
                break;
            }
            if (element.items) {
                this.searchSpecifiedTreeItem(id, name, element.items);
            }
        }
    }

    makeChecked(items, isChecked) {
        items.forEach(e => { e.checked = isChecked });
    }

    onExpandChange = (event) => {
        //  const settings = { checkChildren: true, checkParents: true };
        event.item.expanded = !event.item.expanded;
        event.item.checked = event.item.checked;
        this.setState({ check: handleTreeViewCheckChange(event, this.state.check, this.state.items, null) });

    }


    checkStatusChange = (items, status) => {
        return items.map(each => {
            each.checked = status
            if (each.items) {
                each.items = this.checkStatusChange(each.items, status);
            }
            return each
        })
    }

    checkAll = () => {
        const settings = { checkChildren: true, checkParents: true };
        let items = this.checkStatusChange(this.state.items, true);
        this.setState({ showvalidation:false,check: handleTreeViewCheckChange(this.state.check, items, settings) });
    }

    uncheckAll = () => {
        const settings = { checkChildren: true, checkParents: true };
        let items = this.checkStatusChange(this.state.items, false);
        this.setState({ showvalidation:false, check: handleTreeViewCheckChange(this.state.check, items, settings) });
        this.forceUpdate();
    }

    onSingleModeChange = ({ target: { checked } }) => {
        let { checkChildren, checkParents } = this.state;
        if (checked) {
            checkChildren = checkParents = false;
        }
        this.setState({ singleMode: checked, checkChildren, checkParents });
    }
    onCheckChildrenChange = ({ target: { checked } }) => {
        let { singleMode } = this.state;
        if (checked) {
            singleMode = false;
        }
        this.setState({ singleMode, checkChildren: checked });
    }
    onCheckParentsChange = ({ target: { checked } }) => {
        let { singleMode } = this.state;
        if (checked) {
            singleMode = false;
        }
        this.setState({ singleMode, checkParents: checked });
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage, passtabData }, dispatch);
}

CRoutingTreeView = connect(null, mapActions)(CRoutingTreeView)
CRoutingTreeView= withStyles(styles)(CRoutingTreeView);
export default withRouter(CRoutingTreeView)


///api/InternalAccessControlView/SetUserAccessControl/{userid}