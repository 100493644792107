import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { forwardRef, useEffect, useState } from 'react';
//import CloseIcon from '@material-ui/icons';
import AppBar from '@material-ui/core/AppBar';
import Fab from '@material-ui/core/Fab';
import Toolbar from '@material-ui/core/Toolbar';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DownloadInvoice } from '../../core/controls/components/custom/downloadshelper';

const drawerWidth = 840;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
      }
}));

function InvoiceSideBar(props, ref) {
    const [url, setUrl] = useState(null);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(null);
    const [fileContent, setFileContent] = useState({htmlFileContent : '<span>Loading Invoice Details</span>'});
    const classes = useStyles();
    // useImperativeHandle(ref, () => ({
    //     opendrawer: (url) => {
    //         setUrl(url);
    //     }
    // }))

    useEffect(() => {
        setOpen(props.open);
        if(props.open){
            setId(props.gridrow.InvoiceId)
            DownloadInvoice(props.gridrow.InvoiceId, props.gridrow.DownloadId).then(res => {
                setFileContent(res)
            });
        }

      }, [props.open]);

    // useEffect(
    //     () => {
    //       console.log('Invoice page entered......')
    //       setOpen(true);
    //     },
    //     [props.invoiceid],
    //   );

    const toggleDrawer = () => {
        setOpen(false);
        setId(null);
        setFileContent({htmlFileContent : '<span>Loading Invoice Details</span>'})
        props.handleInvoiceSlider();
    };

    const download = () =>{
        const downloadLink = document.createElement("a");
        downloadLink.href = fileContent.base64PDFFileContent;//dFile
        downloadLink.download = fileContent.fileName; //fileName
        downloadLink.click();
        downloadLink.remove();
    }

    return (
        <Drawer anchor={'right'} open={open} onClose={() => toggleDrawer()} classes={{
            paper: classes.drawerPaper,
        }}>
            <AppBar position="static" color='secondary'>
                <Toolbar variant="dense">
                    {/* <Typography variant="h6" color="inherit" >
                        Invoice Details
                    </Typography> */}
                    <div className="row float-right">
                        <Fab size="small" color="secondary" aria-label="Close" className="float-right" title="Close" onClick={toggleDrawer}>
                            <CloseIcon fontSize="small" />
                        </Fab>
                        <Fab size="small" color="secondary" aria-label="Download" className="float-right" title="Download" onClick={download}>
                            <GetAppIcon fontSize="small" />
                        </Fab>
                    </div>

                    {/* <IconButton onClick={toggleDrawer}>
                        <CloseIcon />
                    </IconButton>                                */}
                </Toolbar>
            </AppBar>
            {/* <div className={classes.drawerHeader}>
                <IconButton onClick={toggleDrawer}>
                    <CloseIcon />
                </IconButton>
            </div> */}
            <div id="sampleDiv" className="pl-4" style={{ overflow: 'scroll' }}>
                <div dangerouslySetInnerHTML={{__html: fileContent.htmlFileContent}} />
            </div>

        </Drawer>
    );


}

export default forwardRef(InvoiceSideBar);
