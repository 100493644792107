import { getEnvVariable } from "../../../../modules/environmentalList"

const PolicysettingsEdit = {
    "type": "popup",
    "key": "PolicysettingsEdit",
    "name": "PolicysettingsEdit",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'editOpen'
    },
    sharedValues: ['open'],
    container: {
        //"addRoute": true,
        closepopup: true,
        closepopupval: 'settings',
        values: { editInfo: null, edit: false, createduplicate: false, fullscreen: false, back: false, allgriddata: null, navigation: null },
        mapValues: {
            header: [
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'createduplicate', name: 'createduplicate' },
            ],
            EditPolicysettings: [
                { key: 'editInfo', name: 'editInfo' },
                { key: 'allgriddata', name: 'allgriddata' },
                { key: 'save', name: 'save' },
                { key: 'saveduplicate', name: 'saveduplicate' },
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'createduplicate', name: 'createduplicate' },
                { key: 'back', name: 'back' },
                { key: 'navigation', name: 'navigation' },]
        },
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },


            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'EditPolicysettings',
                    type: 'div',
                    className: 'col-12 pl-4 pt-3 m-2',
                    sub: {
                        type: 'div',
                        className: 'col-12 pl-4 pt-3 m-2',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Settings',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "View/Edit System Templates", "appendval": "Subject", "materialicon": "settings", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },

                    {
                        type: 'icon',
                        icon: 'edit',
                        name: 'edit',
                        hideon: 'createduplicate',
                        hideanother: 'edit',
                        hidevalue: true,
                        title: 'Edit System Templates'
                    },
                    {
                        type: 'icon',
                        icon: 'duplicate',
                        name: 'createduplicate',
                        hideon: 'edit',
                        hideanother: 'createduplicate',
                        hidevalue: true,
                        title: 'Clone System Templates'
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'edit',
                        hidevalue: false,
                        title: 'Save System Templates'
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'saveduplicate',
                        hideon: 'createduplicate',
                        hidevalue: false,
                        title: 'Save System Templates'
                    },
                    {
                        type: 'icon',
                        icon: 'previtem',
                        name: 'previtem',
                        hideon: 'previtem',
                        hidevalue: false,
                        title: 'Previous Item'
                    },
                    {
                        type: 'icon',
                        icon: 'nextitem',
                        name: 'nextitem',
                        hideon: 'nextitem',
                        hidevalue: false,
                        title: 'Next Item'
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hidevalue: false,
                        title: 'FullScreen'
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false,
                        title: 'Exit FullScreen'
                    }
                ],


            },
            EditPolicysettings: {
                "type": "form",
                "key": "EditPolicysettings",
                "name": "EditPolicysettings",
                aliases: {
                    'editInfo': 'editInfo',
                    'allgriddata': 'allgriddata',  //
                    'navigation': 'navigation'
                },
                sharedValues: ['editInfo', 'allgriddata', 'navigation', 'edit', 'createduplicate', 'exitfullscreen', 'fullscreen'],
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Key',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            }],

                        },
                        {
                            order: 1,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Subject',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            }],

                        },
                        {
                            order: 2,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Body',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            }],

                        },
                        {
                            order: 3,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'ShowOnUI',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            }],

                        },
                        // {
                        //     order: 4,
                        //     type: 'div',
                        //     className: 'row',
                        //     components: [{
                        //         name: 'CreatedOn',
                        //         type: 'div',
                        //         className: 'col-6',
                        //         sub: {
                        //             type: 'div',
                        //             className: 'col-6'
                        //         }
                        //     },
                        //     {
                        //         name: 'CreatedByName',
                        //         type: 'div',
                        //         className: 'col-6',
                        //         sub: {
                        //             type: 'div',
                        //             className: 'col-6'
                        //         }
                        //     },
                        //     ]
                        // },
                        // {
                        //     order: 5,
                        //     type: 'div',
                        //     className: 'row',
                        //     components: [
                        //         {
                        //             name: 'UpdatedOn',
                        //             type: 'div',
                        //             className: 'col-6',
                        //             sub: {
                        //                 type: 'div',
                        //                 className: 'col-6'
                        //             }
                        //         },
                        //         {
                        //             name: 'UpdatedByName',
                        //             type: 'div',
                        //             className: 'col-6',
                        //             sub: {
                        //                 type: 'div',
                        //                 className: 'col-6'
                        //             }
                        //         },]
                        // },
                    ],
                },
                values: {
                    Subject: '',
                    Body: '',
                    UpdatedByName: '',
                    CreatedByName: '',
                    UpdatedOn: '',
                    CreatedOn: '',
                    UpdatedBy: '',
                    CreatedBy: '',
                    Id: '',
                    editInfo: null,
                    allgriddata: null,
                    navigation: null,
                    Key:'',
                    ShowOnUI: ''
                },

                "components": {
                    Key: {
                        "type": "label",
                        "key": "Key",
                        "label": "Key",
                        "name": "Key",
                        changefieldto: "label",
                    },
                    Subject: {
                        "type": "label",
                        "key": "Subject",
                        "label": "Name",
                        "name": "Subject",
                        changefieldto: "label",
                    },
                    Body: {
                        "type": "label",
                        "key": "Body",
                        "label": "Body",
                        "name": "Body",
                        changefieldto: "editor",
                    },
                    ShowOnUI: {
                        "changefieldto": "checkbox",
                        "type": "label",
                        "key": "ShowOnUI",
                        name: "ShowOnUI",
                        "label": "Show On UI",
                        extraProps: {
                            transformProps: {
                                "Y": true,
                                "N": false,
                                true: "Y",
                                false: "N"
                            }
                        },
                    },
                    CreatedOn: {
                        "type": "label",
                        "key": "CreatedOn",
                        "label": "Created On",
                        name: "CreatedOn",
                        changefieldto: "label",
                    },
                    CreatedByName: {
                        "type": "label",
                        "key": "CreatedByName",
                        "label": "Created By",
                        name: "CreatedByName",
                        changefieldto: "label",
                    },
                    UpdatedOn: {
                        "type": "label",
                        "key": "UpdatedOn",
                        "label": "Updated On",
                        name: "UpdatedOn",
                        changefieldto: "label",
                    },
                    UpdatedByName: {
                        "type": "label",
                        "key": "UpdatedByName",
                        "label": "Updated By",
                        name: "UpdatedByName",
                        changefieldto: "label",
                    },
                },
                mapActionsToEffects: {
                    'onLoad': {
                        'init': {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'validations', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                            "read": [
                                                {
                                                    "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.emailconfigurations`,
                                                    type: 'get'
                                                }
                                            ],
                                            transformProps: {
                                                removesubfieldname: 'MASTER.EMAILCONFIGURATIONS_'
                                            }
                                        },
                                    ]
                                }
                            }]
                        }
                    },
                    'onChange': {
                        'exitfullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'exitfullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'fullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'fullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'createduplicate': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'createduplicate',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'navigation': {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'initial',
                                            "read": [
                                                {
                                                    "url": getEnvVariable('Application')+`/api/EmailConfiguration/Id?id={Id}`,
                                                    key: 'save',
                                                    "matchProps": [
                                                        {
                                                            name: "navigation.Id",
                                                            key: "Id",
                                                        }
                                                    ],
                                                    type: 'get',
                                                }
                                            ]
                                        },
                                    ]
                                }
                            }]
                        },
                        'edit': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'edit',

                                            },

                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    'afterSave': {
                        'saveduplicate': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Application')+`/api/EmailConfiguration/Add`,
                                                        type: 'post',
                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'save': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Application')+`/api/EmailConfiguration/Update`,
                                                        type: 'post',

                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    }
                }
            }
        },

    }
}

export default PolicysettingsEdit