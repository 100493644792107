import { loadModules } from 'esri-loader';
export function GeometryEngineTask(config) {
    return new Promise((resolve, reject) => {
        if (!loadModules) {
            reject('GeometryEngineTask: ArcGIS API is not loaded');
            return;
        }
        GeometryEngineTaskExecute(config).then(
            response => {
                resolve(response);
            },
            error => {
                reject(error);
            }
        );
    });
};

function GeometryEngineTaskExecute(config) {
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/geometry/geometryEngine",
            "esri/geometry/projection"
        ]).then(([geometryEngine, projection]) => {
            switch (config.type) {
                case "union":
                    UnionTaskExecute(projection, geometryEngine, config, resolve, reject);
                    break;
                case "buffer":
                    BufferTaskExecute(projection, geometryEngine, config, resolve, reject);
                    break;
                case "project":
                    ProjectionTaskExecute(projection, config, config.geometries, resolve, reject);
                    break;
                case "length":
                    LengthTaskExecute(geometryEngine, config, config.geometries, resolve, reject).then(
                        lengthResponse => {
                            resolve(lengthResponse);
                        }, lengthError => {
                            reject({ "error": lengthError });
                        });
                    break;
                case "validateBussinessRules":
                    ValidateBussinessRulesTaskExecute(geometryEngine, config, config.geometries, resolve, reject);
                    break;
                case "unionpointzoom":
                    UnionTaskExecutePointZoom(projection, geometryEngine, config, resolve, reject);
                    break;
                default: break;
            }
        });
    });
}


export function RequestTaskExecute(config) {
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/request"
        ]).then(([esriRequest]) => {
            switch (config.type) {
                case "Info":
                    InfoTaskExecute(esriRequest, config, resolve, reject);
                    break;
                case "layerinfo":
                    LayerInfoTaskExecute(esriRequest, config, resolve, reject);
                    break;
                case "layerFieldsInfo":
                    LayerFieldsInfoTaskExecute(esriRequest, config, resolve, reject);
                    break;
                case "Validatelayerinfo":
                    ValidatelayerinfoTaskExecute(esriRequest, config, resolve, reject);
                    break;
                default: break;
            }
        });
    });
}

/*
 * //Usage
 GeometryEngineTask({ "geometries": geom, "spatialReference": spatialReference, "type": "project" })
 * geometryEngine of esri/geometry/geometryEngine
 * config of union task requires geometries and type
 * resolve resolve of Promise
 * reject resolve of reject
 */
function ProjectionTaskExecute(projection, config, geometry, resolve, reject) {
    try {
        if (config && config.spatialReference) {
            //console.log('spatialReference......',config.spatialReference)
            let projectionPromise = projection.load();
            projectionPromise.then(
                response => {
                    //console.log('spatialReference......',config.spatialReference)
                    const projectedgeom = projection.project(geometry, config.spatialReference);
                    resolve(projectedgeom);
                },
                error => {
                    reject(error);
                });
        } else {
            reject({ "error": "Invalid geometries" });
        }
    } catch (error) {
        reject({ "error": error });
    }
}


/*
 * //Usage
 *  GeometryEngineTask({ "geometries": geometries, "type": "union" }).then(
    GeomEngineresponse => {
    if (GeomEngineresponse && GeomEngineresponse.centroid)
    CenterAndZoom(view, GeomEngineresponse.centroid, inThis.props.options.viewProperties.zoom);
    },
    GeomEngineerror => {
    console.log(GeomEngineerror);
    });
 * geometryEngine of esri/geometry/geometryEngine
 * config of union task requires geometries and type
 * resolve resolve of Promise
 * reject resolve of reject
 */
function UnionTaskExecute(projection, geometryEngine, config, resolve, reject) {
    try {
        //Check fo not null geoms
        //let configgeom = config.geometries.filter(a => a.geometry != null);
        if (config && config.geometries) {
            let union = geometryEngine.union(config.geometries);
            if (config.spatialReference)
                ProjectionTaskExecute(projection, config, union, resolve, reject);
            else
                resolve(union);
        } else {
            reject({ "error": "Invalid geometries" });
        }
    } catch (error) {
        reject({ "error": error });
    }
}

/*
 *  //Usage
      GeometryEngineTask({ "geometries": geometries, "type": "buffer", "distance": "10", "unit": "miles"  }).then(
        GeomEngineresponse => {
            let g=new Graphic(GeomEngineresponse[0]);
            g.symbol= inThis.mapConstants.PolygonFillSymbol;
            layer.graphics.add(g);
            if (GeomEngineresponse && GeomEngineresponse)
                CenterAndZoom(view, GeomEngineresponse, inThis.props.options.viewProperties.zoom);
        },
        GeomEngineerror => {
            console.log(GeomEngineerror);
        });
 * geometryEngine of esri/geometry/geometryEngine
 * config of union task requires geometries, type as buffer , distance and unit
 * resolve resolve of Promise
 * reject resolve of reject
 */
function BufferTaskExecute(projection, geometryEngine, config, resolve, reject) {
    try {
        if (config && config.geometries && config.distance && config.unit) {
            let bufferGeom = geometryEngine.buffer(config.geometries, config.distance, config.unit);
            if (config.spatialReference)
                ProjectionTaskExecute(projection, config, bufferGeom, resolve, reject);
            else
                resolve(bufferGeom);
        } else {
            reject({ "error": "Invalid geometries or distance or unit" });
        }
    } catch (error) {
        reject({ "error": error });
    }
}

/*
 * //Usage
 GeometryEngineTask({ "geometries": geom, "spatialReference": spatialReference, "type": "length",units:"meters | feet | kilometers | miles | nautical-miles | yards" })
 * geometryEngine of esri/geometry/geometryEngine
 * config of union task requires geometries and type
 * resolve resolve of Promise
 * reject resolve of reject
 */
function ValidateBussinessRulesTaskExecute(geometryEngine, config, graphicies, resolve, reject) {
    try {
        if (config.MapBussinessRules) {
            var geomLengthRules = config.MapBussinessRules.find(function (mr) { if (mr.fields && mr.fields.indexOf(config.ValidateOf || "GEOMETRY_LENGTH") !== -1) return mr.rules })
            let inthis = this;
            if (geomLengthRules && geomLengthRules.rules) {
                LengthTaskExecute(geometryEngine, config, graphicies, resolve, reject).then(
                    geomEngineResponse => {
                        if (geomEngineResponse) {
                            if (geomEngineResponse.length) {
                                geomLengthRules.rules.forEach(function (gr) {
                                    const checks = gr.check
                                    const run = gr.run
                                    let rulesResponses = [];
                                    if (checks && run && run.events) {
                                        checks.forEach(function (check) {
                                            if (check && check.rules && check.rules.length > 0) {
                                                check.rules.forEach(function (r) {
                                                    let isValidationFalied = false;
                                                    if (r.type === "=") {
                                                        isValidationFalied = geomEngineResponse.length === r.value
                                                    }
                                                    else if (r.type === ">") {
                                                        isValidationFalied = geomEngineResponse.length > r.value
                                                    }
                                                    else if (r.type === "<") {
                                                        isValidationFalied = geomEngineResponse.length < r.value
                                                    }
                                                    else if (r.type === ">=") {
                                                        isValidationFalied = geomEngineResponse.length >= r.value
                                                    }
                                                    else if (r.type === "<=") {
                                                        isValidationFalied = geomEngineResponse.length <= r.value
                                                    }
                                                    rulesResponses.push({ RuleId: gr.ruleId, IsValidationFalied: isValidationFalied, alertMessage: run.events.alertMessage });
                                                });
                                            }
                                        });
                                    }
                                    resolve(rulesResponses);
                                });
                            }
                            console.log('length sketch ------------------', geomEngineResponse)
                        }
                        resolve([]);
                    },
                    geomEngineerror => {
                        reject({ "error": geomEngineerror });
                    });
            } else {
                resolve([]);
            }
        } else {
            resolve([]);
        }
    } catch (error) {
        reject({ "error": error });
    }
}

/*
 * //Usage
 GeometryEngineTask({ "geometries": geom, "spatialReference": spatialReference, "type": "length",units:"meters | feet | kilometers | miles | nautical-miles | yards" })
 * geometryEngine of esri/geometry/geometryEngine
 * config of union task requires geometries and type
 * resolve resolve of Promise
 * reject resolve of reject
 */
function LengthTaskExecute(geometryEngine, config, geometry, resolve, reject) {
    return new Promise((Lengthresolve, Lengthreject) => {
        try {
            if (geometry && config && config.spatialReference) {
                let length = 0;
                if (geometry.length > 0) {
                    geometry.forEach(geom => {
                        const templength = LengthTaskWrapper(geometryEngine, geom.geometry || geom, config.units || "feet")
                        console.log(templength)
                        if (templength && templength > 0)
                            length = templength + length
                    });
                } else {
                    let geom = geometry.length ? geometry[0] : geometry
                    length = LengthTaskWrapper(geometryEngine, geom.geometry || geom, config.units || "feet")
                }
                Lengthresolve({ "length": length });
            } else {
                Lengthreject({ "error": "Invalid geometries" });
            }
        } catch (error) {
            Lengthreject({ "error": error });
        }
    });
}

function LengthTaskWrapper(geometryEngine, geometry, unit) {
    return geometryEngine.geodesicLength(geometry, unit);
}
/*
* //Usage
*  RequestTaskExecute({ "url": serviceurl, "type": "info" }).then(
response => {
if (response){

},
error => {
console.log(error);
});
* config of union task requires serviceurl and type
* resolve resolve of Promise
* reject resolve of reject
*/
function InfoTaskExecute(esriRequest, config, resolve, reject) {
    try {
        if (config && config.url) {
            let url = ParseLayerUrl(config.url, true);
            InfoTaskExecuteRequest(esriRequest, config, resolve, reject, url, true)
        }
    } catch (error) {
        reject({ "error": error });
    }
}

function InfoTaskExecuteRequest(esriRequest, config, resolve, reject, url, isinfo) {
    esriRequest(url).then(
        response => {
            InfoTaskExecuteResponse(esriRequest, config, resolve, reject, response)
        },
        error => {
            if (isinfo) {
                url = ParseLayerUrl(config.url, false);
                InfoTaskExecuteRequest(esriRequest, config, resolve, reject, url, false)
            }
            else
                reject(error);
        });
}

function InfoTaskExecuteResponse(esriRequest, config, resolve, reject, response) {
    if (response != null && response.data != null && response.data.fields != null) {
        var layerInfo = response.data.fields.map(function (f) { return { "Name": f.name, "Type": f.type } });
        resolve(layerInfo);
    } else {
        reject({ "Message": "Unable to load fields info" });
    }
}
function ParseLayerUrl(url, isinfo) {
    /*
    if (url.indexOf('/info?') === -1) {
        url = url + '/info?f=json'
        url = url.replace('/query', '/info?f=json')
            .replace('/info', '/info?f=json');
    }*/

    if (url.indexOf('/query') !== -1) {
        const urlParts = url.split('/query')
        url = urlParts[0];
    }
    if (isinfo && url.indexOf('/info?') === -1) {
        url = url + '/info?f=json'
    }
    else {
        const urlParts = url.split('/info')
        url = urlParts[0] + '?f=json'
    }
    return url;
}
function rgb2hex(rgb) {
    rgb = rgb.match(/^[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return (rgb && rgb.length === 4) ? "#" +
        ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
}


/*
* //Usage
*  RequestTaskExecute({ "url": serviceurl, "type": "layerinfo" }).then(
response => {
if (response){

},
error => {
console.log(error);
});
* config of union task requires serviceurl and type
* resolve resolve of Promise
* reject resolve of reject
*/
function LayerInfoTaskExecute(esriRequest, config, resolve, reject) {
    try {
        if (config && config.url) {
            let url = ParseLayerUrl(config.url, true);
            LayerInfoTaskExecuteRequest(esriRequest, config, resolve, reject, url, true)
        }
    } catch (error) {
        reject({ "error": error });
    }
}
function LayerInfoTaskExecuteRequest(esriRequest, config, resolve, reject, url, isinfo) {
    esriRequest(url).then(
        response => {
            LayerInfoTaskExecuteResponse(esriRequest, config, resolve, reject, response)
        },
        error => {
            if (isinfo) {
                url = ParseLayerUrl(config.url, false);
                LayerInfoTaskExecuteRequest(esriRequest, config, resolve, reject, url, false)
            }
            else
                reject(error);
        });

}
function LayerInfoTaskExecuteResponse(esriRequest, config, resolve, reject, response) {
    let layerInfo = {};
    if (response != null && response.data != null && response.data.fields != null && response.data.fields.length > 0) {
        layerInfo.fields = response.data.fields.map(function (f) { return { "Name": f.name, "Type": f.type } });
        layerInfo.type = response.data.type === "Feature Layer" ? "F" : "M";
        layerInfo.name = response.data.name;
        layerInfo.geometryType = response.data.geometryType;
        layerInfo.description = response.data.description;
        layerInfo.LayerColor = response.data.drawingInfo && response.data.drawingInfo.renderer
            && response.data.drawingInfo.renderer.symbol && response.data.drawingInfo.renderer.symbol.color && rgb2hex(response.data.drawingInfo.renderer.symbol.color.join(',')) //: '#ffffff'
        layerInfo.HighlightColor = layerInfo.LayerColor //|| '#ffffff'
        //   layerInfo.InfoTemplateFields = response.data.fields.map(function (f) { return { "Name": f.name, "Value": f.name } }) || []
        layerInfo.SelectField = response.data.fields.map(function (f) { return { "Name": f.name, "Value": f.name } }) || []
        layerInfo.SearchField = response.data.fields.map(function (f) { return { "Name": f.name, "Value": f.name } }) || []
        layerInfo.LabelExpression = response.data.fields.map(function (f) { return { "Name": f.name, "Value": f.name } }) || []
        layerInfo.AsofArchiveStart = response.data.fields.map(function (f) { return { "Name": f.name, "Value": f.name } }) || []
        layerInfo.AsofArchiveEnd = response.data.fields.map(function (f) { return { "Name": f.name, "Value": f.name } }) || []
        layerInfo.InfoTemplate = response.data.fields.map(function (f) { return { "Name": f.name, "Value": f.name } }) || []
        layerInfo.GridTemplate = response.data.fields.map(function (f) { return { "Name": f.name, "Value": f.name } }) || []
        layerInfo.EditorTemplate = response.data.fields.map(function (f) { return { "Name": f.name, "Value": f.name } }) || []
        layerInfo.FilterAttributes = response.data.fields.map(function (f) { return { "Name": f.name, "Value": f.name } }) || []
        resolve(layerInfo);
    } else {
        reject({ "Message": "Unable to load fields info" });
    }


}
//LayerFieldsInfoTaskExecute
/*
* //Usage
*  RequestTaskExecute({ "url": serviceurl, "type": "layerinfo" }).then(
response => {
if (response){

},
error => {
console.log(error);
});
* config of union task requires serviceurl and type
* resolve resolve of Promise
* reject resolve of reject
*/
function LayerFieldsInfoTaskExecute(esriRequest, config, resolve, reject) {
    try {
        if (config && config.url) {
            let url = ParseLayerUrl(config.url, true);
            LayerFieldsInfoTaskExecuteRequest(esriRequest, config, resolve, reject, url, true);
        }
    } catch (error) {
        reject({ "error": error });
    }
}
function LayerFieldsInfoTaskExecuteRequest(esriRequest, config, resolve, reject, url, isinfo) {
    esriRequest(url).then(
        response => {
            let layerInfo = [];
            if (response != null && response.data != null && response.data.fields != null && response.data.fields.length > 0) {
                layerInfo = response.data.fields.map(function (f) { return { "Name": f.name, "Value": f.name } });
                resolve(layerInfo);
            } else {
                reject({ "Message": "Unable to load fields info" });
            }

        },
        error => {
            if (isinfo) {
                url = ParseLayerUrl(config.url, false);
                LayerFieldsInfoTaskExecuteRequest(esriRequest, config, resolve, reject, url, isinfo)
            }
            else
                reject(error);
        });
}
/*
* //Usage
*  RequestTaskExecute("layers":[{"name":"layer name","url": serviceurl}], "type": "validatelayerinfo" }).then(
response => {
if (response){

},
error => {
console.log(error);
});
* config of union task requires serviceurl and type
* resolve resolve of Promise
* reject resolve of reject
*/

function ValidatelayerinfoTaskExecute(esriRequest, config, resolve, reject) {
    try {
        if (config && config.layers) {
            let layersResponses = config.layers.map(function (layerconfig) {
                return new Promise((layerresolve, layerreject) => {
                    ValidateLayerInfoTaskExecuteWrapper(esriRequest, layerconfig, layerresolve, layerreject, "status");
                });
            });
            Promise.all(layersResponses).then(
                response => {
                    resolve(response);
                }, error => {
                    resolve(error);
                });
        }
    } catch (error) {
        //type : correct: type is missing
        config["Message"] = false;
        config["Error"] = error;
        resolve(config);
    }
}


/*
* //Usage
*  RequestTaskExecute("layers":[{"name":"layer name","url": serviceurl}], "type": "validatelayerinfo" }).then(
response => {
if (response){

},
error => {
console.log(error);
});
* config of union task requires serviceurl and type
* resolve resolve of Promise
* reject resolve of reject
*/
function ValidateLayerInfoTaskExecuteWrapper(esriRequest, config, resolve, reject, type) {
    try {
        if (config && config.url) {
            let url = config.url
            if (url.indexOf('/query') !== -1) {
                const urlParts = url.split('/query')
                url = urlParts[0];
            }
            if (type === "info") {
                let url = ParseLayerUrl(config.url, true);
            }
            else if (type === "status") {
                url = url.replace('/query', '/query?where=1%3D1&returnCountOnly=true&f=json').replace('query', '/query?where=1%3D1&returnCountOnly=true&f=json')
                    .replace('/info', '/query?where=1%3D1&returnCountOnly=true&f=json').replace('info', '/query?where=1%3D1&returnCountOnly=true&f=json');
            }
            ValidateLayerInfoTaskExecuteWrapperRequest(esriRequest, config, resolve, reject, type, url, true)
        }
    } catch (error) {
        config[type + "Message"] = false;
        config[type + "Error"] = error;
        resolve(config);
    }
}
function ValidateLayerInfoTaskExecuteWrapperRequest(esriRequest, config, resolve, reject, type, url, isinfo) {
    esriRequest(url).then(
        response => {
            ValidateLayerInfoTaskExecuteWrapperResponse(config, type, response, resolve, reject)
        },
        error => {
            if (type === "info" && isinfo) {
                url = ParseLayerUrl(config.url, false);
                ValidateLayerInfoTaskExecuteWrapperRequest(esriRequest, config, resolve, reject, type, url, false)
            }
            else {
                config[type + "Message"] = false;
                config[type + "Error"] = error;
                resolve(config);
            }
        });
}
function ValidateLayerInfoTaskExecuteWrapperResponse(config, type, response, resolve, reject) {
    let layerInfo = {};
    let esriRequest = '' // correct: esriRequest is missing.compare and correct
    if (type === "status") {
        if (response.data != null && response.data.count >= 0) {
            config.statusMessage = true;
            return new Promise((inforesolve, inforeject) => {
                ValidateLayerInfoTaskExecuteWrapper(esriRequest, config, inforesolve, inforeject, "info").then(
                    inforesponse => {
                        resolve(config);
                    },
                    infoerror => {
                        resolve(config);
                    });
            });
        } else {
            config.statusMessage = false;
            config.statusError = "Layers is down.";
            resolve(config);
        }
    }
    if (type === "info") {
        if (config.name === response.data.name) {
            config.infoMessage = true;
            resolve(config);
        } else {
            config.infoMessage = false;
            config.infoError = "Layers information is changed or misconfigured.";
            resolve(config);
        }
    }
}

//Not using - UNTESTED
export function GeometryProjectionTask(config, geometry) {
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/geometry/geometryEngine",
            "esri/geometry/projection"
        ]).then(([geometryEngine, projection]) => {
            try {
                if (config && config.spatialReference) {
                    //console.log('spatialReference......',config.spatialReference)
                    projection.load().then(res => {
                        projection.project(geometry, config.spatialReference).then(projres => {
                            console.log(projres);
                        })
                    })

                } else {
                    reject({ "error": "Invalid geometries" });
                }
            } catch (error) {
                reject({ "error": error });
            }
        })
    })
}

/*
 * //Usage
 *  GeometryEngineTask({ "geometries": geometries, "type": "union" }).then(
    GeomEngineresponse => {
    if (GeomEngineresponse && GeomEngineresponse.centroid)
    CenterAndZoom(view, GeomEngineresponse.centroid, inThis.props.options.viewProperties.zoom);
    },
    GeomEngineerror => {
    console.log(GeomEngineerror);
    });
 * geometryEngine of esri/geometry/geometryEngine
 * config of union task requires geometries and type
 * resolve resolve of Promise
 * reject resolve of reject
 */
function UnionTaskExecutePointZoom(projection, geometryEngine, config, resolve, reject) {
    try {
        //Check fo not null geoms
        //let configgeom = config.geometries.filter(a => a.geometry != null);
        if (config && config.geometries) {

            GetFeaturePointOnFail(config.geometries).then(res => {
                let union = geometryEngine.union(res);
                if (config.spatialReference)
                    ProjectionTaskExecute(projection, config, union, resolve, reject);
                else
                    resolve(union);
            })
        } else {
            reject({ "error": "Invalid geometries" });
        }
    } catch (error) {
        reject({ "error": error });
    }
}

function GetFeaturePointOnFail(features) {
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/Graphic",
            "esri/geometry/Point"
        ]).then(([Graphic, Point]) => {
            let pointsArr = [];
            features.forEach(element => {
                if(element && element.geometry && element.geometry.latitude){
                    pointsArr.push(new Point({
                        latitude: element.geometry.latitude,
                        longitude: element.geometry.longitude,
                        spatialReference: element.geometry.spatialReference
                    }));
                }                
            });
            resolve(pointsArr);
        })
    })
}

