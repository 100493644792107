import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MissingIcon from '@material-ui/icons/CheckCircle';
import RefreshOutlined from '@material-ui/icons/RefreshOutlined';
import React, { useState } from 'react';
import { getEnvVariable } from '../../../../modules/environmentalList';
import { PostData } from '../../helpers/requests';
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import DefaultSettings from '../settings';
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close';

function PaperComponent(props) {
    return (
        <Draggable>
            <Paper {...props} style={{ position: 'fixed', zIndex: 1400 }} />
        </Draggable>
    )
}

export default function MissingItemsDrawer({ label, urlparams, value, fieldValues, isButton = false, variant, disabled, fieldProps, name }) {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState({})
    const [recResult, setRecResult] = useState((value && value.split(',')) || (name && fieldValues[name] && fieldValues[name].split(',')) || [])
    const [refresh, setRefresh] = useState(false)

    const params = useParams()
    const toggleDrawer = () => {
        if (localStorage.getItem('SaveCurrentRecord') === 'Y' && !params.sidebar) {
            if (DefaultSettings.getAlert() != null)
                DefaultSettings.getAlert().show("Please Save the Application Record to get the latest information to process", 'error');
        } else {
            setOpen(!open);
            let temp = !open
            if (temp) {
                handleValidateRules()
            }
        }
    };

    const handleValidateRules = () => {
        let result = (recResult && recResult.length > 0) ? recResult : ((value && value.split(',')) || (name && fieldValues[name] && fieldValues[name].split(',')) || [])
        let obj = {
            "data": fieldValues,
            "keys": (urlparams && urlparams.keys) ? urlparams.keys : [(result && result[0])],
            "type": (result && result[1]) || urlparams.type,
            'TableName': (urlparams && urlparams.TableName) || '',
            'sitePermitTypeId': fieldValues && fieldValues['SITE_PERMIT_TYPE_ID']   //params.routeid
        }
        let url = `${getEnvVariable('Application')}/api/BusinessRules/${params.sidebar ? 'ValidateRules' : 'SitePermitTypeValidateRules'}?RecordId=` + params.routeid1
        PostData(url, obj, true).then(res => {
            if (res && Object.keys(res).length === 0) {
                setOpen(false);
                if (DefaultSettings.getAlert() != null)
                    DefaultSettings.getAlert().show("No Required Fields are Available", 'info');
                setRefresh(!refresh)
            }
            else setData(res)
        }).catch(ex => {
            console.error(ex)
        })
    }

    return (
        <div>
            {refresh}
            {(isButton || value) && <Button onClick={toggleDrawer} size='small' color='secondary'
                variant={variant || 'outlined'}
                title={label || ''}
                disabled={(disabled) ? true : false}
            >
                {label}
            </Button>
                // :
                //     <IconButton title={label || ''} color={"secondary"} onClick={toggleDrawer} size='small'> <MissingIcon />  </IconButton>
            }
            {/* <Drawer anchor={'right'} open={open} onClose={toggleDrawer} PaperProps={{ style: { width: 700 } }} > */}
            <Dialog
                hideBackdrop
                disableEnforceFocus
                style={{ position: 'initial', zIndex: '1400' }}
                open={open}
                //  onClose={() => toggleDrawer(false)}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            //  disableBackdropClick
            >
                <AppBar position="static" color='secondary'>
                    <Toolbar variant="dense">
                        <div className={'flex-grow-1'}>{label}</div>
                        {/* <Typography variant="h6" color="inherit" className={'flex-grow-1'}>
                            {label}
                        </Typography> */}
                        <IconButton
                            className='float-right'
                            edge="end"
                            onClick={() => handleValidateRules()}
                            color="inherit"
                        >
                            <RefreshOutlined />
                        </IconButton>
                        &nbsp;
                        <IconButton
                            className='float-right'
                            edge="end"
                            onClick={() => { setOpen(false); setRefresh(!refresh) }}
                            color="inherit"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <ul className='my-2' style={{ height: '100%', overflow: 'auto' }}>
                    {data && Object.keys(data).length > 0 && (Object.keys(data) || []).map(each => {
                        return <small><li className='my-1'>
                            {((fieldProps[each] && (fieldProps[each].label || fieldProps[each].RequiredFieldName)) || each) + ' : ' + (data[each] ? 'Required' : '')}
                        </li>
                        </small>

                    })}
                    {data && <small className='text-secondary'>Note: Please save the record, to get updated results related to disabled fields</small>}
                    {/* {data && Object.keys(data).length === 0 && <div className='text-secondary'>
                        N/A
                    </div>
                    } */}
                </ul>
            </Dialog>
            {/* </Drawer> */}
        </div >
    );
}