/**
      *********************************************************
      Description: Textbox component. .
      Required props:  type,value.
      limitations    : N/A.
      *********************************************************
**/

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useEffect, useState, Fragment } from "react";
import DefaultSettings from './settings';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import { getColor } from '../../controls/components/custom/customcolor';

export default function TextBox(props) {
    const { value, handleFieldValue, multiline, extraProps, rowsMax, shareProps, error, required, tooltip, contexthelp, className, disabled, changefieldto, FieldIsSpellCheck, ...otherProps } = props
    const { label, helperText, name, xtype, fullWidth, inputProps, ...otherConfigs } = otherProps;

    const [currentVal, setCurrentVal] = useState(value)

    useEffect(() => {
        setCurrentVal(value)
    }, [value])

    let tlabel = (tooltip || contexthelp) ? <div style={{ fontWeight: 'bold', color: error ? 'red' : 'black' }}> {label} < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
        <HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#007bff' }} onClick={() => DefaultSettings.showDrawer(contexthelp)} />
    </Tooltip> </div> : label;
    return (
        <Box className={className}>
            <FormControl fullWidth={fullWidth ? fullWidth : true} error={error} disabled={disabled || false} required={required}  >
                <InputLabel style={{ fontWeight: 'bold', color: required ? getColor() : (error ? 'red' : 'black')}} shrink>{label}{tooltip && <Tooltip title={tooltip ? tooltip : null}
                    placement="bottom-start" ><HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#007bff', marginLeft: 5 }} onClick={(evt) => contexthelp ?
                        DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                </Tooltip>}</InputLabel>
                <div className='pt-2'></div>
                <TextField
                    error={error} required={required}
                    fullWidth={fullWidth ? fullWidth : true}
                    //   label={tlabel}
                    //   className='pt-2'
                    InputProps={{
                        inputProps: inputProps
                    }}
                    multiline={multiline || (changefieldto === 'TA' ? true : false)}
                    maxRows={rowsMax || 3}
                    disabled={disabled || false}
                    value={currentVal}
                    type={xtype || 'text'}
                    onChange={(evt) => { setCurrentVal(evt.target.value) }}
                    onBlur={() => {
                        handleFieldValue({ target: { value: currentVal, name: name, checked: false }, currentTarget: { dataset: null } })
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    name={name}
                    helperText={helperText}
                    {...otherConfigs}
                    {...extraProps}

                    spellcheck={FieldIsSpellCheck === 'Y' ? true : false}
                />
            </FormControl>
        </Box>
    )
}