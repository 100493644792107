import React, { useState, useEffect } from 'react'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

export default function CustomLayerTemplate({ dataoptions, name, label, transformProps, edit, value, handleFieldChange,dropdownvalues }) {
    let fieldName = dataoptions && dataoptions[transformProps.label] && dataoptions[transformProps.label] !== '' && dataoptions[transformProps.label].split(',') || []
    const [render, setRender] = useState(false)
    const [propVal, setval] = useState([])

    const isMatch = (temp, fieldName) => {
        let ismatched = false;
        temp.map(res => {
            if (res.fieldName === fieldName) {
                ismatched = true
            }
        })
        return ismatched
    }

    useEffect(() => {
        try {
            if (value && JSON.parse(value) !== '')
                setval(JSON.parse(value))
            //  else setval([])
        }
        catch (ex) {
            console.log(ex)
        }
    }, [value])

    useEffect(() => {
        let parseVal = value && JSON.parse(value)
        let tempval = parseVal === '' ? [] : parseVal
        if (tempval && fieldName && fieldName.length > 0 && (fieldName.length === tempval.length || fieldName.length > tempval.length)) {
            fieldName.map((field, index) => {
                let tempMatch = isMatch(tempval, field)
                if (tempval && (tempval.length === 0 || !tempMatch))
                    tempval.push({ 'id': (tempval.length + 1) || index, 'fieldName': field, 'label': field || null })
            })
            handleFieldChange({ target: { name: name, value: JSON.stringify(tempval) || '', checked: false } })
        }
        else if (fieldName && tempval && fieldName.length < tempval.length) {
            let newtempval = tempval.filter(function (item) { return fieldName.indexOf(item.fieldName) > -1 });
            handleFieldChange({ target: { name: name, value: JSON.stringify(newtempval) || '', checked: false } })
        }
        else {
            handleFieldChange({ target: { name: name, value: '', checked: false } })
        }
        setRender(!render)
    }, [dataoptions && dataoptions[transformProps.label]])


    const handleChange = (e, Id) => {
        let parseVal = value && JSON.parse(value)
        let tempVal = parseVal === '' ? [] : parseVal
        tempVal && tempVal.length > 0 && tempVal.map(res => {
            if (res.id === Id) {
                res[e.target.name] = e.target.value
            }
        })
        handleFieldChange({ target: { name: name, value: JSON.stringify(tempVal) || '', checked: false } })
    }


    console.log(label, propVal)
    return (
        <div>
            {render}
            <small className="fw-bold">{label}</small>
            <div className="row">
                <div className="col-sm-6">
                    {propVal && propVal.length > 0 && propVal.map((res, index) => {
                        return <Select
                            className="col-sm-12"
                            value={res.fieldName}
                            disabled={!edit ? true : false}
                            name={'fieldName'}
                            onChange={(e) => handleChange(e, index + 1)}
                        >
                            {fieldName && fieldName.length > 0 && fieldName.map(drop => {
                                return <MenuItem value={drop}>{drop}</MenuItem>
                            })}
                        </Select>
                    })}
                </div>
                <div className="col-sm-6">
                    {propVal && propVal.length > 0 && propVal.map((res, index) => {
                        return <TextField
                            className="col-sm-12"
                            value={res.label}
                            disabled={!edit ? true : false}
                            name={'label'}
                            onChange={(e) => handleChange(e, index + 1)}
                        />
                    })}
                </div>
            </div>
        </div>
    )

}