import { makeStyles } from '@material-ui/core/styles';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from "react-router-dom";
import { getEnvVariable } from '../../../modules/environmentalList';
import NoticeEditors from '../../../modules/notices/noticeeditor';
import { GetData } from '../helpers/requests';
import NotificationEditior from './custom/notificationeditor';
import { useDispatch, useSelector } from 'react-redux';
import { onEventStatusUpdate,onEventshowError } from '../../actions';
const drawerWidth = 840;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function NoticeEditor(props, ref) {
    const [id, setId] = useState(null);
    const [notify, setNotify] = useState(null);
    const [notifyAuto, setAutoNotify] = useState(null);
    const [noticeAuto, setAutoNotice] = useState(null);
    const [parentTableName, setParentTableName] = useState(null);
    const [values, setValues] = useState({});
    const classes = useStyles();
    const dispatch = useDispatch()
    const parentRecordVals = useSelector(state => state.sidebar && state.sidebar.modules);

    let params = useParams();
    useImperativeHandle(ref, () => ({
        openNotice: (id, isAuto, values, info) => {
            setId(id);
            setValues(values)
            setAutoNotice(isAuto)
            setParentTableName(info)
        },
        openNotification: (notify, isAuto, values, info) => {
            setNotify(notify)
            setValues(values)
            setAutoNotify(isAuto)
            setParentTableName(info)
        }
    }))

    useEffect(() => {
        if (notifyAuto) {
            let url = getEnvVariable('Application') + `/api/Application/AutoNotification/${values['key']}/${values['SITE_PERMIT_TYPE_ID']}/${values['RECORD_ID']}/${notify}`
            GetData(url)
        }
    }, [notifyAuto])

    useEffect(() => {
        if (noticeAuto) {
            let url = getEnvVariable('Application') + `/api/Notices/${values['RECORD_ID']}/${id}`
            GetData(url, 'Notice Sent Successfully')
        }
    }, [noticeAuto])


    const toggleDrawer = () => {
        setId(null);
    };

    const toggleNotifyDrawer = () => {
        setNotify(null);
    };

    const onEventTransition = () => {
        dispatch(onEventStatusUpdate(true))
    }


    const showError = (errors) => {
        dispatch(onEventshowError(errors))
    }


    return (
        <>
            {id && !noticeAuto && (values['SITE_PERMIT_TYPE_ID'] || values['routeid']) &&
                <NoticeEditors open={id ? true : false} noticetypeid={id} onClose={toggleDrawer} onEventStatus={'Record'}
                showError={showError}
                    sitepermittypeid={values['SITE_PERMIT_TYPE_ID'] || values['routeid'] || params.routeid}
                    companyid={parentRecordVals && parentRecordVals['COMPANY_ID'] ? parentRecordVals['COMPANY_ID'] : values['COMPANY_ID']}
                    recordid={values['RECORD_ID'] || values['routeid1']}
                    childTable={values} sideBarItems={values['sideBarItems']} refType='NT' ParentValues={values}
                    ParentTableName={{ ...parentTableName, ['RouteParms']: { 'RefId': values['routeid1'], 'Id': values["Id"] } }}
                    onEventStatusUpdate={onEventTransition}
                />}
            {notify && !notifyAuto && (values['SITE_PERMIT_TYPE_ID'] || values['routeid']) && <NotificationEditior openNotificationSlide={notify ? true : false}
                id={notify} handleNotificationClose={toggleNotifyDrawer}
                sitePermitTypeId={values['SITE_PERMIT_TYPE_ID'] || values['routeid'] || params.routeid} recordid={values['RECORD_ID'] || values['routeid1']} childTable={values}
                sideBarItems={values['sideBarItems']} ParentValues={values} refType='NT'
                ParentTableName={{ ...parentTableName, ['RouteParms']: { 'RefId': values['routeid1'], 'Id': values["Id"] } }} />}
        </>
    );


}

export default forwardRef(NoticeEditor);
