import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Icon from '@material-ui/core/Icon';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import React from 'react';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
    },
    icon: {
        //marginRight: theme.spacing(0.5),
        width: 25,
        height: 22,
        fontSize: 25
        //marginRight: 2,
    },
}));

function IconBreadcrumbs(props) {
    const classes = useStyles();
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb p-0 pt-1" style={{ backgroundColor: 'transparent !important' }}>
                {props.breadcrumbs && props.breadcrumbs.map((e, i) => {
                    if (e.tab) localStorage.setItem('tab', e.tab);
                    let path = ''
                    if (e.href && e.href.routes) {
                        if (Array.isArray(e.href.routes)) {
                            e.href.routes.forEach(each => {
                                const { route, routeParams, matchProps } = each
                                if (routeParams) {
                                    path += route + "/" + props.match.params[routeParams]
                                }
                                else if (matchProps) {
                                    path += route + "/" + props.results[matchProps] //---correct: unused matchprops of results
                                }
                                else {
                                    path += route
                                }
                            })
                        }
                    }
                    return <li className={e.className} key={String(i)}>
                        {/*Updated By vijaya: Added Href condition, if we exists it will display like link or else it will displays like label. */}
                        <Breadcrumbs aria-label="breadcrumb" color={e.color || 'inherit'}>
                            {(e.href) ? <Link color="inherit" href={path !== '' ? path : e.href} className={classes.link}>
                                <Icon className={classes.icon} style={{ fontSize: 20 }} > {e.materialicon}</Icon>
                                {e.link}
                            </Link> :
                                <Typography color="inherit" className={classes.link} style={{ color: e.color || 'inherit' }}>
                                    <Icon className={classes.icon} style={{ fontSize: 20 }} > {e.materialicon}</Icon>
                                    {e.link}
                                </Typography>}
                        </Breadcrumbs>

                    </li>
                })}
            </ol>
        </nav>
    )

}
export default withWidth()(withRouter(IconBreadcrumbs));

