import '@progress/kendo-data-query';
import '@progress/kendo-drawing';
import '@progress/kendo-file-saver';
import '@progress/kendo-react-charts';
import '@progress/kendo-react-inputs';
import '@progress/kendo-react-intl';
import '@progress/kendo-react-popup';
import 'hammerjs';
import React, { Component } from 'react';

import {
    Chart, ChartCategoryAxis,
    ChartCategoryAxisItem, ChartSeries,
    ChartSeriesItem, ChartTitle, ChartTooltip
} from '@progress/kendo-react-charts';

const categories = ["January", "Feburary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

class KendoLine extends Component {

    nestedTooltipRender = (point) => {
        return <div >{point.categoryText} </div>
    }

    onEvent = (eventName, event) => {
        this.props.monthval(event.category)
    }

    render() {

        return (
            <Chart onSeriesClick={(event) => this.onEvent('seriesClick', event)}  >
                <ChartTooltip shared={true} render={this.nestedTooltipRender} />
                <ChartTitle text="Year wise Calculations" />
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem title={{ text: 'Months' }} categories={categories} />
                </ChartCategoryAxis>
                <ChartSeries>
                    <ChartSeriesItem type="line" data={this.props.data} />
                </ChartSeries>
            </Chart>
        )
    }
}



export default KendoLine;

