import React, { Component, Fragment } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({

    linearColorPrimary: {
        backgroundColor: '#28a745',
    },
    linearBarColorPrimary: {
        backgroundColor: '#3dc05b',
    },
});

class ProgressBar extends Component {

    state = {
        completed: 0,
        buffer: 0,
    }

    timer = null

    start = () => {
        this.timer = setInterval(this.progress, 500);
    }

    progress = () => {
        const { completed } = this.state;
        if (completed <= 90) {
            const diff = Math.random() * 10;
            const diff2 = Math.random() * 10;
            this.setState({ completed: completed + diff, buffer: completed + diff + diff2 });
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    stop = () => {
        clearInterval(this.timer);
    }

    complete = () => {
        this.stop();
        this.animate();

    }

    /*  animate = () => {
          if (this.state.completed <= 100)
              this.setState(prevState => ({ completed: prevState.completed + 1, buffer: prevState.buffer + 1 }), this.animate)
          else
              this.setState({ completed: 0, buffer: 0 });
      }*/

    animate = () => {
        this.setState({ completed: 0, buffer: 0 });
    }

    render() {
        const { completed, buffer } = this.state;
        const { classes } = this.props;
        return (
            <Fragment>
                {buffer > 0 && (
                    <LinearProgress className={this.props.className} classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }} variant="buffer" value={completed} valueBuffer={buffer} />
                )}
            </Fragment>
        )
    }

}

export default withStyles(styles)(ProgressBar);