import { evaluate } from 'mathjs'
import moment from 'moment';
const _ = require('lodash');


const extract = ([beg, end]) => {
    const matcher = new RegExp(`${beg}(.*?)${end}`, 'gm');
    const normalise = (str) => str.slice(beg.length, end.length * -1);
    return function (str) {
        if (str)
            return (str || "").match(matcher).map(normalise);
    }
}

export const Evaluate = (value, fieldValues, currentrefIds = [], dynamicData = [], sendActualVal = false) => {
    let fieldKeys = []
    let temp = value.toString()
    let tempsendActualVal = sendActualVal || false

    try {
        if ((currentrefIds && currentrefIds.length > 0) || Object.keys(dynamicData).length > 0) {
            fieldKeys = extract(['{{', '}}'])(value)
            fieldKeys && fieldKeys.forEach(each => {
                if (!fieldValues.hasOwnProperty(each)) {
                    let obj = dynamicData[each].find(each => each.refIds.includes(currentrefIds[0]))
                    temp = _.replace(temp, new RegExp(`{{${each}}}`, "g"), obj.value || 0)
                }
                else temp = _.replace(temp, new RegExp(`{{${each}}}`, "g"), fieldValues[each] || 0)
            })
        }
        else {
            fieldKeys = Object.keys(fieldValues);
            fieldKeys && fieldKeys.forEach(each => {
                //setting current user and current date (temp)
                if (temp === '{{CURRENT_USERID}}') {
                    temp = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')).userId : ''
                    tempsendActualVal = true
                }
                else if (temp === '{{CURRENT_USERNAME}}') {
                    temp = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')).name : ''
                    tempsendActualVal = true
                }
                else if (temp === '{{CURRENT_DATE}}') {
                    temp = moment(new Date()).format('MM/DD/YYYY')
                    tempsendActualVal = true
                }
                // else if (temp.includes('{{CURRENT_DATE}}')) {
                //     let date = temp.split(',')
                //     temp = moment(new Date()).add((date && date[1]) || 0, 'days').format('MM/DD/YYYY')
                //     tempsendActualVal = true
                // }
                else if (temp === '{{CURRENT_DATETIME}}') {
                    temp = moment(new Date()).format('MM/DD/YYYY hh:mm a')
                    tempsendActualVal = true
                }
                else temp = _.replace(temp, new RegExp(`{{${each}}}`, "g"), fieldValues[each] || 0)
            })
        }
        let finalvalue = tempsendActualVal ? temp : evaluate(temp)
        if (finalvalue || finalvalue === false)
            if (isNaN(finalvalue) || finalvalue === true || finalvalue === false)
                return finalvalue //=== 0 ? 0 : finalvalue
            else return (finalvalue % 1 !== 0) ? finalvalue.toFixed(4) : finalvalue //Math.abs(
        else return finalvalue //=== 0 ? null : 0
    }
    catch (error) {
        if (temp && fieldValues && fieldValues['OverRideCalVal'] === undefined)
            return temp === true ? true : false
        else return temp
        //console.log(error)
    }
    // return 0
}

export const DynamicEvaluate = (each, fieldValues, dynamicData, fields) => {
    const { name, type, value } = each;
    let currentrefIds = [];
    if (fields && fields.length > 0 && fields.find(element => element === name)) {
        if (dynamicData[name] && dynamicData[name].length > 0 && type)
            return dynamicData[name].filter((dyn, index) => {
                if ((dyn.value ? dyn.value : '') === (typeof value === 'number' ? value.toString() : value) && type === '=') {
                    currentrefIds = [...currentrefIds, dyn.refIds]
                    return currentrefIds
                }
                if (Number(dyn.value) !== Number(value) && type === '!=') {
                    currentrefIds = [...currentrefIds, dyn.refIds]
                    return currentrefIds
                }
                if (Number(dyn.value) > Number(value) && type === '>') {
                    currentrefIds = [...currentrefIds, dyn.refIds]
                    return currentrefIds
                }
                if (Number(dyn.value) < Number(value) && type === '<') {
                    currentrefIds = [...currentrefIds, dyn.refIds]
                    return currentrefIds
                }
                if (Number(dyn.value) <= Number(value) && type === '<=') {
                    currentrefIds = [...currentrefIds, dyn.refIds]
                    return currentrefIds
                }
                if (Number(dyn.value) >= Number(value) && type === '>=') {
                    currentrefIds = [...currentrefIds, dyn.refIds]
                    return currentrefIds
                }
                if (type === 'CE')
                    return Evaluate(value, fieldValues, [], dynamicData)
                // else if (currentrefIds.length === 0 && index === dynamicData[name].length - 1) {
                //     return [false, []];
                // }
                // else return [false, []];
            })
    }
    else {
        let temp = (value && value.toString()) || ''
        let tempString = {}
        let fieldKeys = extract(['{{', '}}'])(value)
        fieldKeys && fieldKeys.forEach(each => {
            if (fieldValues.hasOwnProperty(each)) {
                _.replace(temp, new RegExp(`{{${each}}}`, "g"), fieldValues[each] || 0)
                tempString['value'] = fieldValues[each]
            }
            else tempString['name'] = each
        })
        if (temp) {
            if (temp.includes('>=')) {
                tempString['type'] = '>'
            }
            else if (temp.includes('<')) {
                tempString['type'] = '<'
            }
            else if (temp.includes('=')) {
                tempString['type'] = '='
            }
        }
        let data = DynamicEvaluate(tempString, fieldValues, dynamicData, fieldKeys.filter(e => !fieldValues[e]))
        if (data && data[0])
            return data[0].refIds
    }
}

