/**
      *********************************************************
      Description: Button component.
      Required props:  type.
      limitations    : Currently only one functionality is present (href). Can add other functionalities.
      *********************************************************
**/

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import React, { Component, Fragment } from 'react';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    }
});

class CButton extends Component {
    state = {
        globalitem: null
    }

    render() {
        const { classes } = this.props
        const { options, passer, type, rules, components, handleIconClick, onClick,...otherProps } = this.props;
        const { text, icon, color, disabled, href, size, name, variant, ...otherOptions } = options
        let iconname, iconColor, iconFontSize, iconposition
        if (icon) {
            iconname = icon.iconname
            iconColor = icon.iconColor
            iconFontSize = icon.iconFontSize
        }
        return (
            <Fragment>
                {(type === 'button' || type === 'navbutton') && <Button
                    color={color ? color : 'default'}
                    disabled={disabled}
                    href={href}
                    size={size}
                    variant={variant}
                    {...otherProps}
                    {...otherOptions}
                    name={name}
                    onClick={handleIconClick||onClick}
                >
                    {text}
                    {(iconposition === 'left') && <Icon
                        className={classes.leftIcon} color={iconColor || 'inherit'} fontSize={iconFontSize || 'default'} >{iconname}
                    </Icon>
                    }
                    {iconposition && !(iconposition === 'left') && <Icon
                        className={classes.rightIcon} color={iconColor || 'inherit'} fontSize={iconFontSize || 'default'} >{iconname}
                    </Icon>
                    }

                </Button>}
                {type === 'icon' && <Icon
                    onClick={handleIconClick}
                    color={iconColor || 'inherit'} fontSize={iconFontSize || 'default'}
                >
                    {iconname}
                </Icon>
                }
                {this.state.globalitem}
            </Fragment>

        )
    }
}

export default withStyles(styles)(CButton);






/**
 * {
 * type:'button',
 * options:{
 *  text:'alarm',
 *  icon:'alarm',
 * button:''
 * },
 * defaults:{
 *  theme:'default',
 *  custom: false
 * }
 * }
 */