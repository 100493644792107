import { getEnvVariable } from "../../../../modules/environmentalList"
//import Attachmenthistory from './Attachmenthistorygrid';
const AttachmentEdit = {
    "type": "popup",
    "key": "AttachmentEdit",
    "name": "AttachmentEdit",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'editOpen'
    },
    sharedValues: ['open'],
    container: {
        values: { editInfo: null, edit: false, createduplicate: false, fullscreen: false, back: false, allgriddata: null, navigation: null },
        mapValues: {
            header: [
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'createduplicate', name: 'createduplicate' },
                { key: 'allgriddata', name: 'allgriddata' },
            ],
            ViewAttachment: [
                { key: 'editInfo', name: 'editInfo' },
                { key: 'allgriddata', name: 'allgriddata' },
                { key: 'save', name: 'save' },
                { key: 'saveduplicate', name: 'saveduplicate' },
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'createduplicate', name: 'createduplicate' },
                { key: 'back', name: 'back' },
                { key: 'navigation', name: 'navigation' },]
        },
        //  sharedValues: ['save', 'edit'],
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },


            {
                order: 1,
                type: 'div',
                className: 'mx-4',
                components: [{
                    name: 'ViewAttachment',
                    type: 'div',
                    className: 'col-12 pl-4 m-1',
                    sub: {
                        type: 'div',
                        className: 'col-12 pl-4 m-1',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Attachment',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "View/Edit Attachment", "materialicon": "attach_file", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },

                    {
                        type: 'icon',
                        icon: 'edit',
                        name: 'edit',
                        hideon: 'createduplicate',
                        hideanother: 'edit',
                        hidevalue: true,
                        title: 'Edit Attachment'
                    },
                    // {
                    //     type: 'icon',
                    //     icon: 'duplicate',
                    //     name: 'createduplicate',
                    //     hideon: 'edit',
                    //     hideanother: 'createduplicate',
                    //     hidevalue: true,
                    //     title: 'Clone Attachment'
                    // },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'edit',
                        hidevalue: false,
                        title: 'Save Attachment',
                        color:"#008000"

                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'saveduplicate',
                        hideon: 'createduplicate',
                        hidevalue: false,
                        title: 'Save Attachment'
                    },
                    {
                        type: 'icon',
                        icon: 'previtem',
                        name: 'previtem',
                        hideon: 'previtem',
                        hidevalue: false,
                        title: 'Previous Item'
                    },
                    {
                        type: 'icon',
                        icon: 'nextitem',
                        name: 'nextitem',
                        hideon: 'nextitem',
                        hidevalue: false,
                        title: 'Next Item'
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hidevalue: false,
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false
                    },
                    {
                        type: 'icon',
                        icon: 'history',
                        name: 'history',
                        title: 'History',
                        hideon: 'history',
                        hidevalue: false,
                        historyurl: {
                            url: getEnvVariable('Application') + `/api/Files/history/{Id}?`,
                            "matchProps": [{ name: "editInfo.Id", key: "Id", },],
                            type: 'get',
                            saveAs: { key: 'data' }
                        }
                    },
                ],
            },
            ViewAttachment: {
                "type": "form",
                initRun: true,
                "key": "ViewAttachment",
                "name": "ViewAttachment",
                aliases: {
                    'editInfo': 'editInfo',
                    'allgriddata': 'allgriddata',
                    'navigateItem': 'navigateItem',
                    'edit': 'edit'
                },
                sharedValues: ['editInfo', 'allgriddata', 'navigateItem', 'navigation', 'edit', 'save', 'createduplicate', 'exitfullscreen', 'fullscreen', 'back'],
                validationMapKeys: [],
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'FileName',
                                type: 'div',
                                className: 'col-10 m-1',
                                sub: {
                                    type: 'div',
                                    className: 'col-10 m-1'
                                }
                            },

                            ]
                        },
                        {
                            order: 1,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'Notes',
                                    type: 'div',
                                    className: 'col-10 m-1',
                                    sub: {
                                        type: 'div',
                                        className: 'col-10 m-1'
                                    }
                                },
                            ]
                        },
                        {
                            order: 2,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'FileDetails',
                                type: 'div',
                                className: 'col-10 m-1',
                                sub: {
                                    type: 'div',
                                    className: 'col-10 m-1'
                                }
                            },
                            ]
                        },
                        // {
                        //     order: 3,
                        //     type: 'div',
                        //     className: 'row',
                        //     components: [{
                        //         name: 'Status',
                        //         type: 'div',
                        //         className: 'col-6',
                        //         sub: {
                        //             type: 'div',
                        //             className: 'col-6'
                        //         }
                        //     }
                        //     ]
                        // },
                        {
                            order: 4,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'CreatedOn',
                                type: 'div',
                                className: 'col-4 m-1',
                                sub: {
                                    type: 'div',
                                    className: 'col-4 m-1'
                                }
                            },
                            {
                                name: 'CreatedByName',
                                type: 'div',
                                className: 'col-4 m-1',
                                sub: {
                                    type: 'div',
                                    className: 'col-4 m-1'
                                }
                            },
                            ]
                        },
                        {
                            order: 5,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'UpdatedOn',
                                    type: 'div',
                                    className: 'col-4 m-1',
                                    sub: {
                                        type: 'div',
                                        className: 'col-4 m-1'
                                    }
                                },
                                {
                                    name: 'UpdatedByName',
                                    type: 'div',
                                    className: 'col-4 m-1',
                                    sub: {
                                        type: 'div',
                                        className: 'col-4 m-1'
                                    }
                                },]
                        },
                    ]

                },
                values: {
                    FileName: '',
                    Notes: '',
                    FileDetails: [],
                    UpdatedByName: '',
                    CreatedByName: '',
                    UpdatedOn: '',
                    CreatedOn: '',
                    UpdatedBy: '',
                    CreatedBy: '',
                    Id: '',
                    Status: '',
                    editInfo: null,
                    allgriddata: null,
                    navigation: null
                },
                "components": {
                    FileName: {
                        "type": "label",
                        "key": "FileName",
                        "label": "File Name",
                        "name": "FileName",
                        changefieldto: "textfield",
                    },
                    Notes: {
                        "type": "label",
                        "key": "Notes",
                        "label": "Notes",
                        "name": "Notes",
                        "multiline": true,
                        rowsMax: "5",
                        "rows": 6,
                        changefieldto: "textfield",
                    },
                    FileDetails: {
                        "type": "upload",
                        "key": "FileDetails",
                        "label": "Uploads",
                        "name": "FileDetails",
                        changefieldto: "upload",
                        customField: true

                    },
                    CreatedOn: {
                        "type": "label",
                        "key": "CreatedOn",
                        "label": "Created On",
                        name: "CreatedOn",
                        changefieldto: "label",
                    },
                    CreatedByName: {
                        "type": "label",
                        "key": "CreatedByName",
                        "label": "Created By",
                        name: "CreatedByName",
                        changefieldto: "label",
                    },
                    UpdatedOn: {
                        "type": "label",
                        "key": "UpdatedOn",
                        "label": "Updated On",
                        name: "UpdatedOn",
                        changefieldto: "label",
                    },
                    UpdatedByName: {
                        "type": "label",
                        "key": "UpdatedByName",
                        "label": "Updated By",
                        name: "UpdatedByName",
                        changefieldto: "label",
                    },
                    Status: {
                        "type": "label",
                        "key": "Status",
                        "label": "Status",
                        "name": "Status",
                        changefieldto: "toggle",
                        extraProps: {
                            "transformProps": {
                                "A": true,
                                "I": false,
                                "true": "A",
                                "false": "I"
                            }
                        }
                    },
                },
                mapActionsToEffects: {
                    'onLoad': {
                        'init': {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'validations', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                            "read": [
                                                {
                                                    "url": getEnvVariable('Sidebar') + `/api/ProjectMessages/RulesByName?name=modulemaster.Attachments`,
                                                    type: 'get'
                                                }
                                            ],
                                            transformProps: {
                                                removesubfieldname: getEnvVariable('ModuleKey') + 'MODULEMASTER.AttachmentS_'
                                            }
                                        },
                                    ]
                                }
                            }]
                        }
                    },
                    'onChange': {
                        'navigation': {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'initial', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                            "read": [
                                                {
                                                    url: getEnvVariable('Admin') + `/api/Files/{mid}/file/{Id}`,
                                                    key: 'save',
                                                    "matchProps": [
                                                        {
                                                            name: "mid",
                                                            key: "mid",
                                                        },
                                                        {
                                                            name: "navigation.Id",
                                                            key: "Id",
                                                        }
                                                    ],
                                                    type: 'get',
                                                }
                                            ]
                                        },
                                    ]
                                }
                            }]
                        },
                        'edit': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'edit',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'exitfullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'exitfullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'fullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'fullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'createduplicate': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'createduplicate',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    },
                    'afterSave': {
                        'save': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',// http://development.delasoft.com:8443/AMES-application/api/Property/23103/Add
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Admin') + `/api/Files/{mid}/NoticeFileUploadFiles`,
                                                        type: 'post',
                                                        message: 'Updated Sucessfully',
                                                        "matchProps": [{
                                                            name: "mid",
                                                            key: "mid",
                                                        }],
                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    },

                }
            }
        },

    }
}

export default AttachmentEdit
