import MaterialTable from "@material-table/core";
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../controls/components/header';
import { createRequest, requestApi } from '../../controls/helpers/rest';
import { getEnvVariable } from "../../../modules/environmentalList";



const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 0
    },
    dragger: {
        width: "7px",
        cursor: "ew-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 100,
        //   backgroundColor: "#f4f7f9"
    }
}));



const defaultDrawerWidth = 900;
const minDrawerWidth = 700;
const maxDrawerWidth = window.innerWidth - 220;
const fullscreenwidth = window.innerWidth

export default function Editable({ sitePermitTypeAppStatusId, close, props, ModuleJSON }) {
    let params = useParams();
    const [dropdownlist, setdropdownlist] = useState([])
    const [templookup, settempdropdownlist] = useState({})
    const [data, setData] = useState([]);
    //drawer settings
    const [drawerWidth, setDrawerWidth] = useState((props && props.width == "sm" || props && props.width == "xs" || props && props.width == "md") ? fullscreenwidth : defaultDrawerWidth);
    const [fullscreen, setFullScreen] = useState(false)
    const [fullwidth, setFullWidth] = useState(fullscreenwidth)

    const handleMouseDown = e => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let newWidth =
            document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        //  const newWidth = e.clientX - document.body.offsetLeft;
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            setDrawerWidth(newWidth);
        }
    }, []);
    //end drawer settings
    useEffect(() => {
        const read = ModuleJSON.get;
        Promise.all(read.map(each => requestApi(createRequest(each, {}, { ...params, sitePermitTypeAppStatusId })).catch(error => { return error; }))).then(res => {
            const [lookup, tempdata] = res;
            let tempLookup = {}
            lookup.map(each => {
                tempLookup[each.Id] = each.Name
            })
            setdropdownlist(lookup);
            if (tempdata && Array.isArray(tempdata) && tempdata.length > 0) {
                let finaltempdata = []
                tempdata.forEach(element => {
                    element.IsInternalVisible = element.IsInternalVisible == 'Y' ? true : false
                    element.IsMandatory = element.IsMandatory == 'Y' ? true : false
                    element.IsExternalVisible = getEnvVariable("ModuleKey") === "WRS" ? false : element.IsExternalVisible == 'Y' ? true : false
                    element.IsExternalMandatory = getEnvVariable("ModuleKey") === "WRS" ? false : element.IsExternalMandatory == 'Y' ? true : false
                    finaltempdata.push(element);
                });
                setData(finaltempdata)
            }
            settempdropdownlist(tempLookup);
        }).catch(ex => {
            console.error(ex)
        })
    }, [])

    const handleSave = () => {
        let tempdata = data.map(each => {
            each.IsInternalVisible = each.IsInternalVisible ? 'Y' : 'N'
            each.IsMandatory = each.IsMandatory ? 'Y' : 'N'
            each.IsExternalVisible =  getEnvVariable("ModuleKey") === "WRS" ? 'N' :each.IsExternalVisible ? 'Y' : 'N'
            each.IsExternalMandatory = getEnvVariable("ModuleKey") === "WRS" ? 'N' : each.IsExternalMandatory ? 'Y' : 'N'
            delete each.tableData
            return each
        })
        const read = ModuleJSON.addorupdate
        Promise.all(read.map(each => requestApi(createRequest(each, tempdata, { ...params, sitePermitTypeAppStatusId })).catch(error => { return error; }))).then(res => {
            console.log("Success");
        }).catch(ex => {
            console.error("error", ex)
        })
    }

    const addOnsByName = { addOnsByName: { back: { onClick: close }, save: { onClick: handleSave } } }
    const classes = useStyles();
    return (
        <Drawer anchor="right" open={sitePermitTypeAppStatusId ? true : false}
            disableEnforceFocus={true}
            disableAutoFocus={true}
            PaperProps={{ style: { width: fullscreen ? fullwidth : drawerWidth } }}
        >
            <div onMouseDown={e => handleMouseDown(e)} className={classes.dragger} />
            <Header {...ModuleJSON['header']} {...addOnsByName} />
            <MaterialTable
                title={""}
                columns={[
                    {
                        title: 'Name', field: ModuleJSON['id'], lookup: templookup, initialEditValue: '', validate: rowData => rowData[ModuleJSON['id']] === '' ? { isValid: false, helperText: 'Name is Required' } : true,
                        editComponent: props => (
                            <Select value={props.value || null} fullWidth onChange={(e) => props.onChange(e.target.value)}>
                                <MenuItem value={''}>{'None'}</MenuItem>
                                {dropdownlist && dropdownlist.map(each => {
                                    return <MenuItem key={each.Id.toString()} disabled={data.some(e => e[ModuleJSON['id']] === each.Id) ? true : false}
                                        value={each['Id']}>{each.Name}</MenuItem>
                                })}
                            </Select>
                        )
                    },
                    { title: 'Can Show', field: 'IsInternalVisible', type: 'boolean' },
                    { title: 'Is Required', field: 'IsMandatory', type: 'boolean' },
                    getEnvVariable("ModuleKey") === "WRS" ? {} :  { title: 'Can Show', field: 'IsExternalVisible', type: 'boolean' },
                    getEnvVariable("ModuleKey") === "WRS" ? {} : { title: 'Is Required', field: 'IsExternalMandatory', type: 'boolean' },
                ]}
                data={data}
                doubleHorizontalScroll={true}
                options={{
                    search: false,
                    paging: false,
                    emptyRowsWhenPaging: true,
                    addRowPosition: 'first',
                    actionsColumnIndex: -1
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                setData([...data, newData]);
                                resolve();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setData([...dataUpdate]);
                                resolve();
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataDelete = [...data];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setData([...dataDelete]);
                                //   handleSave([...dataDelete])
                                resolve()
                            }, 1000)
                        }),
                }}
                components={{
                    Header: props => {
                        return (
                            <TableHead style={{ backgroundColor: 'black' }}>
                                <TableRow>
                                    <TableCell style={{ color: 'white' }} rowSpan={2}>Name</TableCell>
                                    <TableCell style={{ color: 'white' }} colSpan={2} align="center">
                                        Internal
                                    </TableCell>
                                    {getEnvVariable("ModuleKey") != "WRS" && <TableCell style={{ color: 'white' }} colSpan={2} align="center">
                                        External
                                    </TableCell>}
                                    {getEnvVariable("ModuleKey") != "WRS" && <TableCell style={{ color: 'white' }} rowSpan={2}>Actions</TableCell>}
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ color: 'white' }} align="center">Can Show</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Is Required</TableCell>
                                    {getEnvVariable("ModuleKey") != "WRS" && <TableCell style={{ color: 'white' }} align="center">Can Show</TableCell>}
                                    {getEnvVariable("ModuleKey") !== "WRS" &&<TableCell style={{ color: 'white' }} align="center">Is Required</TableCell>}
                                </TableRow>
                            </TableHead>
                        );
                    },
                }}
            />
        </Drawer>
    )
}