import { Divider, Icon } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import UserProfileJSON from '../../../../json/agencyusers/userprofile';
import ExtUserProfileJSON from '../../../../json/companyInternal/Users/profilecompanyuser';
import { getEnvVariable } from '../../../../modules/environmentalList';
import { GetData } from '../../../controls/helpers/requests';
import defaultImage from '../../../resources/Default-UserImg.jpg';
import { logOut } from '../../../security/actions';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    bottomstyle: {
        position: 'absolute',
        bottom: 0,
        backgroundColor: '#0000000a',
        width: '100%',
        height: '25%'
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    split: {
        height: '100%',
        width: '40%',
        position: 'fixed',
    },
    left: {
        left: 0,
    },
    right: {
        right: 0,
    }
}));

function SimplePopover(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [user, setUser] = React.useState(props.userProfile);
    const [loading, setLoading] = React.useState(false);
    const [openSlide, setopenSlide] = React.useState(false);
    const [impersonate, setImpersonate] = React.useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {

        if (props.accessPermissions) {
            if (getEnvVariable('ModuleKey') == "WRS") {
                let url = props.accessPermissions.portal === 'I' ? getEnvVariable('MasterAdmin') + `/api/${getEnvVariable('ModuleKey')}InternalUser/${getEnvVariable('ModuleKey')}UserById/` : getEnvVariable('MasterAdmin') + `/api/ExternalUser/` + getEnvVariable('ModuleKey') + `/`;
                if (props.accessPermissions.userId) {
                    GetData(url + props.accessPermissions.userId).then(res => {
                        setUser(res);
                        setLoading(false);
                    })
                }
            }
            else {
                let url = props.accessPermissions.portal === 'I' ? getEnvVariable('MasterAdmin') + `/api/${getEnvVariable('ModuleKey')}InternalUser/UserById/` : getEnvVariable('MasterAdmin') + `/api/ExternalUser/` + getEnvVariable('ModuleKey') + `/`;
                if (props.accessPermissions.userId) {
                    GetData(url + props.accessPermissions.userId).then(res => {
                        setUser(res);
                        setLoading(false);
                    })
                }
            }

        }
        else props.history.push('/');
    }, [anchorEl]);


    useEffect(() => {
        setUser(props.userProfile)
    }, [props.userProfile])

    const profile = () => {
        if (props.accessPermissions && props.accessPermissions.portal === 'I') {
            if (props.accessPermissions.userId) {
                setopenSlide(!openSlide)
            }
            else {
                props.history.push('/accessdenied');
            }
        }
        else if (props.accessPermissions && props.accessPermissions.portal === 'E') {
            if (user && props.accessPermissions.userId) {
                setopenSlide(!openSlide)
            }
            else if (props.accessPermissions.userId) {
                setopenSlide(!openSlide)
                setLoading(true)
            }
            else {
                props.history.push('/accessdenied');
            }
        }
    }

    const signout = () => {
        // props.history.push();
         localStorage.clear();
        // logOut();
        window.location.replace(getEnvVariable('AuthorizationScreen') + 'user/logout');
    }

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleImpersonate = (value) => {
        localStorage.setItem('impersonate', value)
        window.location.reload()

    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const EachComp = props.compmap[UserProfileJSON.type]
    return (
        <div >
            {!props.mobile && <IconButton title='Profile' color="inherit" onClick={handleClick}>   <Avatar alt="Profile" src={(user && user.UserImageUI) || defaultImage} />  </IconButton>}
            {
                props.mobile && <MenuItem className=" pl-4" onClick={handleClick}>
                    <Icon>account_box</Icon> &nbsp; Profile
                </MenuItem>
            }
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        minHeight: 221,
                        minWidth: 320,
                    },
                }}
            >
                <div className='pb-2 m-2'></div>
                {/* <small className='pl-3' >{getEnvVariable('ModuleKey')}</small> */}
                <Button className='float-end mr-2' size="small" color="secondary" onClick={signout}>Log Out</Button>
                <Button size="small" color="primary" className='pl-3 mr-2' onClick={profile}>Profile</Button>
                <div className='row col-sm-12'>
                    <span className="row col-sm-6 ">
                        <Avatar alt="Profile" className={classes.large} src={(user && user.UserImageUI) || defaultImage} />
                    </span>
                    <div className="row col-sm-6 ">
                        <div className='m-2'><b>{props.accessPermissions && props.accessPermissions.name &&
                            ((props.accessPermissions.name).length > 15 ? `${(props.accessPermissions.name).substring(0, 15)}...` : `${props.accessPermissions.name}`)}</b><br />
                            {localStorage.getItem('impersonate') &&
                                getEnvVariable('AccessPortal') === 'E' && <small>Impersonated : {(JSON.parse(localStorage.getItem('profile')).impersonateAccess.find(each => each.id === JSON.parse(localStorage.getItem('impersonate'))) || {}).name}</small>}

                        </div>
                        
                        {getEnvVariable('AccessPortal') === 'E' && JSON.parse(localStorage.getItem('profile')).impersonateAccess && <Button size="small" color="primary" onClick={(event) => setImpersonate(event.currentTarget)}>Impersonate</Button>}
                        <Popover
                            open={Boolean(impersonate)}
                            anchorEl={impersonate}
                            onClose={() => setImpersonate(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}

                        >  {props.accessPermissions && props.accessPermissions.impersonateAccess && props.accessPermissions.impersonateAccess.map(each => {
                            return <MenuItem onClick={() => handleImpersonate(each.id)} value={each.id}  >         {each.name}                         </MenuItem>
                        })}

                        </Popover>
                        {loading && <div><Typography className='pl-3 pt-1 m-2' variant='caption'>Loading</Typography></div>}
                    </div>
                </div>
                <div>
                </div>
                <div className={classes.bottomstyle}>
                    <Divider />
                    <small className='pl-5 m-2' >Last Login : {JSON.parse(localStorage.getItem('loginDate'))}</small>
                </div>
            </Popover>
            {
                openSlide && UserProfileJSON && props.accessPermissions.portal === 'I' &&
                <EachComp {...UserProfileJSON} key={UserProfileJSON.key} handleSlide={profile} accessPermissions={props.accessPermissions} compmap={props.compmap} dataItem={{ ...props.accessPermissions, CompanyId: user && user.CompanyId ? user.CompanyId : null, IsOfficial: user && user.IsOfficial ? user.IsOfficial : 'N' }} />
            }
            {
                !loading && openSlide && ExtUserProfileJSON && props.accessPermissions.portal === 'E' &&
                <EachComp {...ExtUserProfileJSON} key={ExtUserProfileJSON.key} handleSlide={profile} accessPermissions={props.accessPermissions} compmap={props.compmap} dataItem={{ ...props.accessPermissions, CompanyId: user && user.CompanyId ? user.CompanyId : null, IsOfficial: user && user.IsOfficial ? user.IsOfficial : 'N' }} />
            }
        </div>
    );
}

const mapProps = (state) => {
    return { user: state.authentication.user }
}

const mapActions = dispatch => {
    return bindActionCreators({ logOut }, dispatch);
}

SimplePopover = connect(mapProps, mapActions)(SimplePopover);
export default withRouter(SimplePopover);