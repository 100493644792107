import { Upload } from '@progress/kendo-react-upload';
import React, { Fragment } from 'react';

class CUpload extends React.Component {
    constructor(props){
        super(props)
    }

    onStatusChange = (e) =>{
        this.props.onStatusChange(e)
    }

    render() {
        const { saveUrl, removeUrl, attachmentTypeId, multiple, batch } = this.props
        return (
            <Fragment>
                <Upload
                    batch= {batch}
                    multiple= {multiple}
                    defaultFiles={[]}
                    withCredentials={false}
                    saveUrl={saveUrl}
                    removeUrl = {removeUrl}
                    onStatusChange = {this.onStatusChange}
                />
            </Fragment>
        )
    }
}
export default CUpload;