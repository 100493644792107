import React, { useRef, useEffect, useState } from 'react';
//import './App.css';
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getEnvVariable } from '../../../modules/environmentalList';
import { getToken } from '../../modules/header';

export default function IframeView({ props, extra }) {
    const frameView = useRef(null);
    const [reload, setReload] = useState(false)

    useEffect(() => {

        const messageHandler = (message) => {
            console.log("message", props.url + extra)
            if (props.url + extra + ``.includes(message.origin)) {
                if (frameView.current && frameView.current.contentWindow) {
                    if (message.data === 'Initialized')
                        frameView.current.contentWindow.postMessage({ prop: { ...props, token: getToken() } }, "*")
                }
            }
        }
        window.addEventListener('message', messageHandler)
    }, [])

    // useDeepCompareEffect(() => {
    //     setTimeout(() => {
    //         if (frameView.current && frameView.current.contentWindow) {
    //             setReload(!reload)
    //             frameView.current.contentWindow.postMessage({ prop: { ...props } }, "*")
    //         }
    //     }, 400)

    // }, [props])


    return (<div style={{ height: props.height ? window.innerHeight - 50 : (getEnvVariable('AccessPortal') === 'I' ? window.innerHeight - 180 : window.innerHeight - 60), width: '100%' }}>   {reload}
        <iframe id='template-editor' src={props.url + extra} ref={frameView}
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            width="100%"
            height="100%"
            title='Indemnity'
            scrolling="auto">
        </iframe>
    </div>)
}


