//[{"Id":1087,"DomainId":20340,"Name":"Current","Value":"U"}, {"Id":1088,"DomainId":20340,"Name":"Due","Value":"D",}, {"Id":1089, "DomainId":20340, "Name":"Past Due", "Value":"PD", }, { "Id":1090, "DomainId":20340, "Name":"In Violation", "Value":"V", }, { "Id":1091, "DomainId":20340, "Name":"Paid", "Value":"P", }, { "Id":1092, "DomainId":20340, "Name":"Cancelled", "Value":"C", }, { "Id":1093, "DomainId":20340, "Name":"Waived", "Value":"W", } ]

export function IsValidPermitStatus(prevstatus, currstatus){
    
        if(prevstatus == 'U'){
            if(currstatus == 'C' || currstatus == 'W'){
                return true;
            }else{
                return false;
            }
        }
        if(prevstatus == 'C'){
            if(currstatus == 'U' || currstatus == 'W'){
                return true;
            }else{
                return false;
            }
        }
        if(prevstatus == 'W'){
            return false;
        }
    
}

export function IsValidInvoiceStatus(prevstatus, currstatus){
    
        if(prevstatus == 'U'){
            if(currstatus == 'C' || currstatus == 'W'){
                return true;
            }else{
                return false;
            }
        }
        if(prevstatus == 'C'){
            if(currstatus == 'U' || currstatus == 'W'){
                return true;
            }else{
                return false;
            }
        }
        if(prevstatus == 'W'){
            return false;
        }

        return false;
    
}