import { getEnvVariable } from "../../../modules/environmentalList"

const components = {
    "components": [{
        "type": "formcontainer",
        "key": "attachform",
        "options": {
            "totalcolumns": 2,
            "totalrows": 3,
            "validations": [
                {
                    get: {
                        "url": getEnvVariable('Sidebar')+`/api/ProjectMessages/RulesByName?name=modulemaster.attachments`
                    },
                    transformProps: {
                        name: 'Key',
                        subfieldname:getEnvVariable('ModuleKey')+`MODULEMASTER.ATTACHMENTS_`
                    }
                }
            ],
            designprops :true,
            hidescroll :true
        },
        "components": [{
            "type": "formdropdown",
            "key": "AttachmentTypeId",
            "options": {
                "label": "Attachment Type",
                "name": "AttachmentTypeId",
                "id": "attachmenttype",
                "width": 12,
                "crud": {
                    "read": {
                        "get": {
                            // "url": getEnvVariable('Admin')+`/api/AttachmentType/All/Active`
                            "url": getEnvVariable('AdminConfig')+`/api/SitePermitTypeAttachments/SitePermitTypeAttachmentsTypes`,
                            "routeProps": {
                                "value": "routeid"
                            }
                        }
                    }
                },
                "transformProps": {
                    "value": "Id",
                    "name": "Name"
                }

            }
        },
        {

            "type": "formtextbox",
            "key": "note",
            "options": {
                "width": 12,
                "label": "Notes",
                "name": "Notes"
            },
            "inputProps": {
                "maxLength": 4000
            }

        },
        ]
    },
    {
        "type": "upload",
        "key": "attachmentupload",
        "options": {
            "label": "Upload",
            "name": "Upload",
            "width": 12,

        }
    }
    ]
}
export default components