import React, { Component } from 'react';
import FormHeader from './cformheader';
import { withRouter } from 'react-router-dom';
import { getData, postRecordData, putRecordData } from "../rest";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { snackbarMessage } from '../../actions';
import ProgressBar from '../progress-bar';
import TemplateForm from '../template/template';

class NoticeTextTemplate extends Component {

    constructor(props) {
        super(props); this.state = { editorState: null, result: {} };
        this.TemplateForm = React.createRef()
    }


    componentDidMount() {
        const { options } = this.props;
        const { editorState } = this.state;
        const { crud } = options;
        const { read, update } = crud;
        let url = read.get.url + this.props.dataItem.Id
        getData(url).then(result => {
            if (result && result.NoticeText) {
                let passingId = +(result.NoticeText)
                this.setState({ resultExists: true, editorState: passingId, result: result })
            }
            else this.setState({ editorState: null, resultExists: false })
        }).catch(error => {
            this.props.snackbarMessage(JSON.stringify(error), 'error');
        })
    }

    handleEditorTemplateId = (editorVal) => {
        this.handleSave(editorVal.id);
    }

    callTemplateSave = () => {
        this.TemplateForm.current.saveTemplate();
    }

    handleSave = (editorVal) => {
        const { options } = this.props;

        const { resultExists, result } = this.state;
        const { crud } = options;
        const { read, update } = crud;
        this.progressBar.start()
        // result.noticeText = btoa(editorVal.toString());
        result.NoticeText = editorVal.toString()
        if (options && options.ismasterfield) {
            result.Name = this.props.dataItem.Name
            result.UIFieldTypeComponent = this.props.dataItem.UIFieldTypeComponent
        }

        let url = ''
        if (!resultExists) {
            // this.progressBar.start()
            if (this.props.options.tabprops) {
                url = update.put.url + this.props.dataItem.Id
            }
            else {
                url = update.put.url + this.props.dataItem.Id
            }
            putRecordData(url, result).then(res => {
                this.setState({ resultExists: false })
                this.progressBar.complete()
                this.props.snackbarMessage('Notice Text Saved Successfully', 'success');
                if (!this.props.options.tabprops) {
                    this.props.onClose();
                }

            }).catch(error => {
                this.props.snackbarMessage(JSON.stringify(error), 'error');
                this.progressBar.complete()
            })
        }
        else {
            // this.progressBar.start()
            if (this.props.options.tabprops) {
                url = update.put.url + this.props.dataItem.Id
            }
            else {
                url = update.put.url + this.props.dataItem.Id
            }
            putRecordData(url, result).then(res => {
                this.setState({ resultExists: false })
                this.progressBar.complete()
                this.props.snackbarMessage('Notice Text Updated Successfully', 'success');
                if (!this.props.options.tabprops) {
                    this.props.onClose();
                }

            }).catch(error => {
                this.props.snackbarMessage(JSON.stringify(error), 'error');
                this.progressBar.complete()
            })
        }

    }


    render() {
        const { options } = this.props;
        const { headerProps, title } = options;
        const { editorState, result } = this.state
        return (
            <div>
                <FormHeader className="mx-1" title={title} handleSave={() => this.callTemplateSave()} savebutton={headerProps.savebutton} closebutton={headerProps.closebutton} handleClose={() => this.props.onClose()} designprops={options.designprops} />
                <div className='clearfix' />
                <div> <ProgressBar ref={e => this.progressBar = e} /></div>
                <div className='pt-1'>
                    <TemplateForm templateId={editorState} isEnabled={true} mentions={null} entryData={null}
                        hasSubject={false} ref={this.TemplateForm} handleTemplateId={(editorVal) => { this.handleEditorTemplateId(editorVal) }} isadmin={true} />
                </div>
            </div>
        )
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

NoticeTextTemplate = connect(null, mapActions, null, { forwardRef: true })(NoticeTextTemplate)
export default withRouter(NoticeTextTemplate)