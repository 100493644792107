import { getEnvVariable } from '../../../modules/environmentalList';
import AddNotice from './add';
import ViewNotice from './edit';

const NoticeGrid = {
    "type": "grid",
    "key": "NoticeGrid",
    "gridkey": 'NoticeGrid',
    "name": "NoticeGrid",
    "hasreport": true,
    "addRoute": true,
    title: "Master Templates",
    "routeParams": { replace: 'masternotices', param: 'Id', routeName: 'id' },
    values: {
        addOpen: false,
        editOpen: false,
        editInfo: null,
        allgriddata: null,
        reportOpen: false,
        loadreportfilter: null,
        ReportJsonQueryStr: null,
        load: false,
        navigateItem: null,
        navigation: null,
    },
    sharedValues: ['addOpen', 'editOpen', 'editInfo', 'allgriddata', 'navigateItem', 'navigation', 'loadreportfilter', 'ReportJsonQueryStr', 'reportOpen'],
    aliases: {
        addOpen: 'addOpen',
        editOpen: 'editOpen',
        editInfo: 'editInfo',
        navigateItem: 'navigateItem',
        allgriddata: 'allgriddata',
        navigation: 'navigation',
        reportOpen: 'reportOpen',
        loadreportfilter: 'loadreportfilter',
        ReportJsonQueryStr: 'ReportJsonQueryStr'
    },
    headerProps: {
        title: "Notice",
        components: [
            {
                type: 'breadcrumbs',
                items: [
                    { "link": getEnvVariable('TitleKey') + ` Settings`, "tab": 2, "materialicon": "settings_applications", href: getEnvVariable('InternalBasepath'), className: 'breadcrumb-item text-dark', class: 'text-dark' },
                    { "link": "Master Templates", href: "#/Administration/masternotices", "materialicon": "document_scanner", className: 'breadcrumb-item ', class: '' }

                ],
            }
        ],
        actions: [
            {
                type: 'fab',
                icon: 'clear',
                name: 'clear',
                color: 'primary',
                title: 'Clear Filters',
                menucolor: '#22a9b3',
                hideon: 'clear',
                hidevalue: false
            },

            {
                type: 'menu',
                icon: 'excel',
                name: 'excel',
                color: 'primary',
                title: 'Export to Excel',
                menucolor: '#c90685',
                hideon: 'excel',
                hidevalue: false,
                items: [{
                    name: 'Export Current Page',
                    action: 'filtercurrent'
                },
                {
                    name: 'Export All Pages',
                    action: 'filterall'
                }
                ]
            },
            {
                type: 'fab',
                icon: 'reports',
                name: 'reports',
                color: 'primary',
                title: 'Save Report',
                menucolor: '#C85A54',
                hideon: 'reports',
                hidevalue: false
            },
            {
                type: 'fab',
                icon: 'add',
                name: 'add',
                color: 'primary',
                hideon: 'addButton',
                hidevalue: true,
                title: 'Add Master Template',
                menucolor: '#36088a'
            },

        ]
    },
    "columns": [
        {
            "title": "Master Template Name",
            "field": "Name",
            "show": true,
            "width": 200
        },
        {
            "title": "Sub Module",
            "field": "SubModuleKeyDesc",
            "show": true,
            "width": 220,
        },
        {
            "title": "Status",
            "field": "StatusDesc",
            "show": true,
            "width": 200
        },
        {
            "title": "Created By",
            "field": "CreatedByName",
            "show": true,
            "width": 185
        },
        {
            "title": "Created On",
            "field": "CreatedOn",
            "show": true,
            "width": 185,
            filter: "date",
            format: "{0:d}",
            convert: "MM/DD/YYYY hh:mm:ss A"
        },
        {
            "title": "Updated By",
            "field": "UpdatedByName",
            "show": true,
            "width": 185
        },
        {
            "title": "Updated On",
            "field": "UpdatedOn",
            "show": true,
            "width": 185,
            filter: "date",
            format: "{0:d}",
            convert: "MM/DD/YYYY hh:mm:ss A"
        },

    ],
    displayName: 'editInfo',
    allgriddata: 'allgriddata',
    navigateItem: 'navigateItem',
    navigation: 'navigation',
    loadreportfilter: 'loadreportfilter',
    ReportJsonQueryStr: 'ReportJsonQueryStr',
    navigateItem: 'navigateItem',
    mapActionsToEffects: {
        load: {
            rules: [{
                run: {
                    valid: [{
                        type: 'grid',
                        "read": [
                            {
                                url: getEnvVariable('TemplateAPI') + `/api/MasterTemplates/Grid?`,
                                "matchProps": [{
                                    name: "routeid1",
                                    key: "routeid1",
                                }],
                                type: 'get',
                                saveAs: {
                                    key: 'data'
                                }
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        // addOpen: {
        //     rules: [{
        //         check: [
        //             {
        //                 condition: 'AND',
        //                 rules: [
        //                     {
        //                         "name": "addOpen",
        //                         "type": "!=",
        //                         "value": true,
        //                         "rules": []
        //                     }
        //                 ]
        //             }
        //         ],
        //         run: {
        //             valid: [{
        //                 type: 'grid',
        //                 "read": [
        //                     {
        //                         url: getEnvVariable('Application') + `/api/MasterTemplates/Grid?`,
        //                         "matchProps": [{
        //                             name: "routeid1",
        //                             key: "routeid1",
        //                         }],
        //                         type: 'get',
        //                         saveAs: {
        //                             key: 'data'
        //                         }
        //                     }
        //                 ]
        //             },
        //             ]
        //         }
        //     }]
        // },
        editOpen: {
            rules: [{
                check: [
                    {
                        condition: 'AND',
                        rules: [
                            {
                                "name": "editOpen",
                                "type": "!=",
                                "value": true,
                                "rules": []
                            }
                        ]
                    }
                ],
                run: {
                    valid: [{
                        type: 'grid',
                        "read": [
                            {
                                url: getEnvVariable('TemplateAPI') + `/api/MasterTemplates/Grid?`,
                                "matchProps": [{
                                    name: "routeid1",
                                    key: "routeid1",
                                }],
                                type: 'get',
                                saveAs: {
                                    key: 'data'
                                }
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        'add': {
            rules: [
                {
                    run: {
                        valid: [
                            {
                                type: 'value',
                                key: 'addOpen',
                                result: true
                            },

                        ]
                    }
                }
            ]
        },

        'display': {
            rules: [
                {
                    run: {
                        valid: [
                            {
                                type: 'value',
                                key: 'editOpen',
                                result: true
                            },
                            {
                                type: 'resultvalue',
                                name: 'editInfo',
                            },
                            {
                                type: 'resultvalue',
                                name: 'allgriddata',
                            },
                            {
                                type: 'resultvalue',
                                name: 'navigateItem',
                            },
                            {
                                type: 'resultvalue',
                                name: 'navigation',
                            },
                        ]
                    }
                }
            ]
        },
        'report': {
            rules: [
                {
                    run: {
                        valid: [
                            {
                                type: 'value',
                                key: 'reportOpen',
                                result: true
                            },
                            {
                                type: 'resultvalue',
                                name: 'loadreportfilter',
                            },
                            {
                                type: 'resultvalue',
                                name: 'ReportJsonQueryStr',
                            },

                        ]
                    }
                }
            ]
        },
    }



}
const NoticeReport = {
    type: 'popup',
    key: 'NoticeReport',
    name: 'NoticeReport',

    anchor: 'right',
    open: false,
    aliases: {
        open: 'reportOpen'
    },
    sharedValues: ['open', 'loadreportfilter', 'ReportJsonQueryStr'],
    container: {
        values: {
            loadreportfilter: null,
            ReportJsonQueryStr: null
        },
        mapValues: {
            Report: [
                {
                    key: 'ReportJsonQueryStr',
                    name: 'ReportJsonQueryStr'
                },
                {
                    key: 'loadreportfilter',
                    name: 'loadreportfilter'
                },
            ],
        },
        mapActionsToEffects: {},
        layout: {
            group: [
                {
                    order: 0,
                    type: 'div',
                    className: '',
                    components: [
                        {
                            name: 'Report'
                        },
                    ]
                }
            ]

        },
        components: {
            Report: {
                "type": "form",
                "key": "Report",
                "name": "Report",
                aliases: {

                },
                sharedValues: ['ReportJsonQueryStr', 'loadreportfilter'],
                values: {
                    loadreportfilter: null,
                    ReportJsonQueryStr: null
                },
                mapActionsToEffects: {},
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: '',
                            components: [{
                                name: 'ReportJsonQueryStr',
                                type: 'div',

                            },
                            {
                                name: 'loadreportfilter',
                                type: 'div',

                            }],

                        },
                    ],
                },
                "components": {
                    "ReportJsonQueryStr": {
                        "type": "gridreport",
                        "gridkey": 'NoticeGrid',
                    },
                },
            }
        }
    }
}

const NoticeContainer = {
    type: 'container',
    key: 'Noticecontainer',
    values: {
        addOpen: false,
        editOpen: false,
        editInfo: null,
        allgriddata: null,
        navigateItem: null,
        navigation: null,
        loadreportfilter: null,
        ReportJsonQueryStr: null,
        reportOpen: false,
    },
    mapValues: {
        AddNotice: [
            {
                key: 'addOpen',
                name: 'open'
            }
        ],
        ViewNotice: [
            {
                key: 'editOpen',
                name: 'open'
            },
            {
                key: 'editInfo',
                name: 'editInfo'
            },
            {
                key: 'navigateItem',
                name: 'navigateItem'
            },
            {
                key: 'allgriddata',
                name: 'allgriddata'
            },
            {
                key: 'navigation',
                name: 'navigation'
            },
        ],
        NoticeGrid: [
            {
                key: 'editOpen',
                name: 'editOpen'
            },
            {
                key: 'addOpen',
                name: 'addOpen'
            },
            {
                key: 'reportOpen',
                name: 'reportOpen'
            },
            {
                key: 'ReportJsonQueryStr',
                name: 'ReportJsonQueryStr'
            },
            {
                key: 'loadreportfilter',
                name: 'loadreportfilter'
            },
        ],
        NoticeReport: [
            {
                key: 'reportOpen',
                name: 'open'
            },
            {
                key: 'loadreportfilter',
                name: 'loadreportfilter'
            },
            {
                key: 'ReportJsonQueryStr',
                name: 'ReportJsonQueryStr'
            },

        ]

    },
    mapActionsToEffects: {
        'addOpen': {
            rules: [
                {
                    check: [
                        {
                            condition: 'AND',
                            rules: [
                                {
                                    "name": "addOpen",
                                    "type": "!=",
                                    "value": true,
                                    "rules": []
                                }
                            ]
                        }
                    ],
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'removelayout',
                                name: 'AddNotice',
                            }
                        ],
                        inValid: [
                            {
                                type: 'addlayout',
                                name: 'AddNotice',
                            }
                        ]
                    }
                }
            ]
        },
        'editOpen': {
            rules: [
                {
                    check: [
                        {
                            condition: 'AND',
                            rules: [
                                {
                                    "name": "editOpen",
                                    "type": "!=",
                                    "value": true,
                                    "rules": []
                                }
                            ]
                        }
                    ],
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'removelayout',
                                name: 'ViewNotice',
                            }

                        ],
                        inValid: [
                            {
                                type: 'addlayout',
                                name: 'ViewNotice',
                            }
                        ]
                    }
                }
            ]
        },
        'reportOpen': {
            rules: [
                {
                    check: [
                        {
                            condition: 'AND',
                            rules: [
                                {
                                    "name": "reportOpen",
                                    "type": "!=",
                                    "value": true,
                                    "rules": []
                                }
                            ]
                        }
                    ],
                    uncheck: true,
                    run: {
                        valid: [
                            // {
                            //     type: 'removelayout',
                            //     name: 'NoticeReport',
                            // }

                        ],
                        inValid: [
                            {
                                type: 'addlayout',
                                name: 'NoticeReport',
                            }
                        ]
                    }
                }
            ]
        },
    },
    layout: {
        group: [
            {
                order: 0,
                type: 'div',
                className: 'row col-12',
                components: [
                    {
                        name: 'NoticeGrid'
                    },

                ]
            }
        ]

    },
    components: {
        NoticeGrid: NoticeGrid,
        NoticeReport: NoticeReport,
        AddNotice: AddNotice,
        ViewNotice: ViewNotice
    }
}

export default NoticeContainer