import React, { useEffect } from 'react'
import NumberFormat from '../numberformat';

export default function DomainCalVal(props) {
    const { required, label, value = null, items = [], name, helperText, error, fieldValues, disabled, handleFieldValue } = props;

    useEffect(() => {
        if (fieldValues['LandUse'] && fieldValues['Unit'] && items && items.length > 0) {
            let item = items.find(each => each.Value === fieldValues['LandUse'])
            let val = item ? fieldValues['Unit'] * item.CalculatedValue : fieldValues['Unit'];
            let events = { target: { value: val.toFixed(4), name: name, checked: false } }
            handleFieldValue(events);
        }
    }, [fieldValues['LandUse'], fieldValues['Unit']])


    return (
        <NumberFormat xtype='DN' label={label} value={value} name={name} required={required} error={error} helperText={helperText} disabled={disabled}
            handleFieldValue={handleFieldValue} />
    )
}
