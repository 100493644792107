import Axios from './default';

export default function Request(url) {
    return {
        get: () => Axios().get(url),
        getById: (id) => Axios().get(url + '/' + id),
        add: (data) => Axios().post(url, data),
        update: (data) => Axios().put(url, data),
        delete: (id) => Axios().delete(url + '/' + id)
    }
}
