import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { GetData, DeleteRecord, PutData } from '../../../../controls/helpers/requests';
import SimpleGrid from '../../../Grid/cgrid'
import Drawer from '@material-ui/core/Drawer';
import Header from '../../../../controls/components/header';
import GridHeader from '../../../../controls/components/kendoheader';
import Rule from './rule';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import { getEnvVariable } from '../../../../../modules/environmentalList';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
const defaultDrawerWidth = 1200;
const minDrawerWidth = 700;
const maxDrawerWidth = window.innerWidth - 220;
const fullscreenwidth = window.innerWidth
const checkwidth = 960

const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 0
    },
    dragger: {
        width: "7px",
        cursor: "ew-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        top: 0,
        left: 5,
        bottom: 0,
        zIndex: 100,
        //   backgroundColor: "#f4f7f9"
    }
}));


const header = {
    name: "header",
    type: 'header',
    title: 'Business Rules',
    components: [
        {
            type: 'breadcrumbs',
            items: [
                { "link": "Business Rules", "materialicon": "assignment_turned_in", className: 'eps-breadcrumb-item ', class: 'text-light' },
            ]
        }
    ],
    actions: [
        {
            type: 'icon',
            icon: 'back',
            name: 'back',
            hideon: 'back',
            hidevalue: false
        },
    ],
}


const kendoheader = {
    actions: [
        {
            type: 'fab',
            icon: 'clear',
            name: 'clear',
            color: 'primary',
            title: 'Clear Filters',
            menucolor: '#22a9b3',
            hideon: 'clear',
            hidevalue: false
        },
        {
            type: 'fab',
            icon: 'add',
            name: 'add',
            hideon: 'add',
            hidevalue: false,
            menucolor: '#22a9b3',
            title: 'Add Global Business Rule',
            color: 'primary',
        },

    ],
}

function ValidationRules(props) {
    const [items, setItems] = useState([])
    const [columns, setColumns] = useState([])
    const [pathKey, setPathKey] = useState([])
    const [total, setTotal] = useState(0)
    const [datastate, setDatastate] = useState({ skip: 0, take: 20 })
    const [item, setItem] = useState(null)
    const [reload, setReload] = useState(false)
    const params = useParams();
    const { eachsection, section, open } = props;

    let screensize = (props.width === "sm" || props.width === "xs" || props.width === "md") ? false : true
    const [drawerWidth, setDrawerWidth] = useState((props.width === "sm" || props.width === "xs" || props.width === "md") ? fullscreenwidth : defaultDrawerWidth);
    const [fullscreen, setFullScreen] = useState(false)
    const [fullwidth, setFullWidth] = useState(fullscreenwidth)

    React.useEffect(() => {
        window.addEventListener("resize", updateWidth);
        setPathKey("/BusinessRules")
        return () => window.removeEventListener("resize", updateWidth);
    });


    const updateWidth = () => {
        if (window.innerWidth < checkwidth) {
            setDrawerWidth(window.innerWidth);
        }
        else {
            setDrawerWidth(defaultDrawerWidth)
        }
    };

    React.useEffect(() => {
        if (!screensize) {
            let width = window.innerWidth
            setDrawerWidth(width);
            setFullWidth(width)
        }
        else {
            setDrawerWidth(defaultDrawerWidth);
            // setFullWidth(defaultDrawerWidth)
        }
    }, [props.width]);


    const handleMouseDown = e => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let newWidth =
            document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        //  const newWidth = e.clientX - document.body.offsetLeft;
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            setDrawerWidth(newWidth);
        }
    }, []);

    useEffect(() => {
        IntialLoad()
    }, [])

    const IntialLoad = (customDataState) => {
        let tempDatastate = customDataState ? customDataState : datastate
        let pathName = window.location.hash.replace('#/', '/')
        let queryStr = ""
        pathName = pathName + pathKey;
        if ((localStorage.getItem(pathName) !== null)) {
            let ds = JSON.parse(localStorage.getItem(pathName))
            queryStr = `${toDataSourceRequestString(ds)}`;
            setDatastate(ds)
        }
        else queryStr = toDataSourceRequestString(tempDatastate)
        const { routeid } = params;
        let gColumns = []
        let url = ''
        if (eachsection) {
            url = getEnvVariable('AdminConfig') + `/api/BusinessRules/${routeid}/All/Grid?sitePermitTypeSectionId=${section.Id}`
        }
        else url = getEnvVariable('AdminConfig') + `/api/BusinessRules/${routeid}/All/Grid`

        let urlPath = `${url}${(url.indexOf("?") > -1) ? '&' : '?'}${queryStr}`;
        GetData(urlPath).then(kendoresponse => {
            //let response = (kendoresponse && kendoresponse.data) || []
            let response = ((kendoresponse && kendoresponse.data) || []).map(item => {
                item.Status = item.Status === 'A' ? 'Active' : 'Inactive'
                //   item.RuleType = item.RuleType === 'S' ? 'Data' : 'Role'
                item.isCalcualtedQuery = 'Y'
                return item
            })
            setItems(response);
            if (response && response.length > 0) {
                (Object.getOwnPropertyNames(response[0])).map(col => {
                    if (col === 'RuleOrder' || col === 'Status')//col === 'Id' || 
                        gColumns.push({ field: col, title: col, show: true, filtericon: true, width: 75, })
                    else {
                        if (col === 'Name')
                            gColumns.push({ field: col, title: col, show: true, filtericon: true, width: 350, })
                    }
                })
                gColumns.unshift({ field: 'Custom', title: '', show: true, width: 30, })
                setColumns(gColumns)
                setTotal(kendoresponse.total)                
            }
            setDatastate(tempDatastate)
            setReload(!reload)
        })
    }
    const clearFilters = () => {
        let ds = {
            skip: 0, take: 20
        }
        let pathName = window.location.hash.replace('#/', '/')
        pathName = pathName + pathKey;
        if ((localStorage.getItem(pathName) !== null)) {
            localStorage.removeItem(pathName)
         //   setDatastate(ds)
            IntialLoad(ds);
        }
    }
    const handleBack = () => {
        props.handleBackSlider(false)
    }

    const handleAdd = () => {
        let newItem = {}
        newItem['RuleType'] = 'S'
        newItem['Status'] = 'I'
        newItem['EventOn'] = 'OC'
        newItem['EventType'] = 'E'
        newItem['isCalcualtedQuery'] = 'Y'
        newItem['PreConditionCheck'] = 'V'
        setItem(newItem)
    }

    const addOnsByName = { addOnsByName: { back: { onClick: handleBack } } }

    const gridAddOnsByName = { addOnsByName: { add: { onClick: handleAdd }, clear: { onClick: clearFilters } } }

    const handleEdit = (dataItem) => {
        let url = getEnvVariable('AdminConfig') + `/api/BusinessRules/ById/${dataItem.Id}`
        GetData(url, '').then(res => {
            res.query = res.JsonQueryStr !== '' ? JSON.parse(res.JsonQueryStr) : '';
            res.RuleType = res.RuleType ? res.RuleType : 'S';
            res.isCalcualtedQuery = 'Y'
            setItem(res)
            setReload(!reload)
        })
    }

    const onUp = (dataItem) => {
        PutData(getEnvVariable('AdminConfig') + `/api/BusinessRules/${dataItem.Id}/UP`, dataItem).then(res => {
            IntialLoad()
        })
    }

    const onDown = (dataItem) => {
        PutData(getEnvVariable('AdminConfig') + `/api/BusinessRules/${dataItem.Id}/DOWN`, dataItem).then(res => {
            IntialLoad()
        })
    }

    const onDelete = (dataItem) => {
        DeleteRecord(getEnvVariable('AdminConfig') + `/api/BusinessRules/${dataItem.Id}`, '').then(res => {
            IntialLoad()
        })

    }

    const handleBackSlider = () => {
        setItem(null)
        IntialLoad()
    }

    const classes = useStyles();
    return (
        <div>
            {reload}
            <Drawer anchor="right" open={open}
                disableEnforceFocus={true}
                disableAutoFocus={true}
                PaperProps={{ style: { width: fullscreen ? fullwidth : drawerWidth } }}
            >
                <div onMouseDown={e => handleMouseDown(e)} className={classes.dragger} />
                <Header {...header} {...addOnsByName} />
                {reload}
                <div className='col-12'>
                    <GridHeader {...kendoheader} {...gridAddOnsByName} />
                    {columns && columns.length > 0 ?
                        <SimpleGrid
                            onUp={onUp} handleEdit={handleEdit} datastate={datastate} total={total} allowPropsData={true}
                            onDown={onDown} onDelete={onDelete} pathKey={pathKey} onCustomDataChange={(e) => IntialLoad(e)}
                            data={items || []} columns={columns} useComp={true} sidepopupgridheight={true}
                            options={{ headerProps: null, gridcolumns: columns }} />

                        : "No Business Rules available"}
                </div>
                {item && Object.keys(item).length > 0 && <Rule businessrules={items} item={item} handleClose={handleBackSlider} eachsection={eachsection} section={section} handleBack={handleBack} />}
            </Drawer>
        </div>
    )
}

export default withWidth()(ValidationRules)