import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import React, { useCallback, useEffect, useState } from 'react';
import useContainer from './container';

const defaultDrawerWidth = 900;
const minDrawerWidth = 700;
const maxDrawerWidth = window.innerWidth - 220;
const fullscreenwidth = window.innerWidth
const checkwidth = 960

const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 0
    },
    dragger: {
        width: "7px",
        cursor: "ew-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 100,
        //   backgroundColor: "#f4f7f9"
    }
}));

function SidePopUp(props) {

    const { type, name, aliases, label, container, extraProps, dispatchSharedValueChange, anchor } = props
    const sharedProps = props.sharedProps || {}
    const sharedValues = props.sharedValues || []
    const accessPermissions = props.accessPermissions
    //open is not passed to container
    const { open, ...otherContainerProps } = sharedProps
    //resizable
    let screensize = (props.width === "sm" || props.width === "xs" || props.width === "md") ? false : true
    const [drawerWidth, setDrawerWidth] = useState((props.width === "sm" || props.width === "xs" || props.width === "md") ? fullscreenwidth : defaultDrawerWidth);
    const [fullscreen, setFullScreen] = useState(false)
    const [fullwidth, setFullWidth] = useState(fullscreenwidth)
    React.useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    });

    const updateWidth = () => {
        if (window.innerWidth < checkwidth) {
            setDrawerWidth(window.innerWidth);
        }
        else {
            setDrawerWidth(defaultDrawerWidth)
        }
    };

    React.useEffect(() => {
        if (!screensize) {
            let width = window.innerWidth
            setDrawerWidth(width);
            setFullWidth(width)
        }
        else {
            setDrawerWidth(defaultDrawerWidth);
            // setFullWidth(defaultDrawerWidth)
        }
    }, [props.width]);


    const handleMouseDown = e => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let newWidth =
            document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        //  const newWidth = e.clientX - document.body.offsetLeft;
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            setDrawerWidth(newWidth);
        }
    }, []);

    const [openDrawer, setOpenDrawer] = useState(() => {
        let open = false
        if (sharedProps && (sharedProps.open === true || sharedProps.open === false))
            open = sharedProps.open
        else open = props.open
        return open
    });

    const toggleDrawer = (input) => {
        setOpenDrawer(input);
    };

    useEffect(() => {
        if (sharedValues && sharedValues.length > 0 && dispatchSharedValueChange) {
            dispatchSharedValueChange({
                type: 'value',
                name: aliases['open'],
                value: openDrawer
            })
        }
    }, [openDrawer])

    //sharedProps.open === false reason: multiple times layout is created for side popup
    useEffect(() => {
        if (sharedProps && Object.keys(sharedProps).length > 0 && sharedProps.open === false)
            setOpenDrawer(sharedProps.open);
    }, [sharedProps.open])

    const actions = {
        toggleDrawer: toggleDrawer,
    }

    const handleScreenWidth = (screen) => {
        if (screen === 'fullscreen') {
            setFullScreen(true)
        }
        else {
            setFullScreen(false)
        }
    }

    const [content] =  useContainer({ ...container, sharedProps: { ...otherContainerProps } ,accessPermissions : accessPermissions, actions, handleScreenWidth })

    const classes = useStyles();
    return (
        <div >
            {content && <Drawer disableEnforceFocus={true} anchor="right"
                disableAutoFocus={true}
                disableBackdropClick
                PaperProps={{ style: { width: fullscreen ? fullwidth : drawerWidth } }} open={openDrawer} onClose={() => toggleDrawer(false)}
            >
                <div onMouseDown={e => handleMouseDown(e)} className={classes.dragger} />
                {content}
            </Drawer>}
        </div >
    );
}

export default withWidth()(SidePopUp)