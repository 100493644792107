import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn, GridColumnMenuFilter } from "@progress/kendo-react-grid";
import React, { useRef, useState, Fragment, useEffect } from 'react';
import { createRequest, requestApi } from '../../controls/helpers/rest';
import { CustomColumnMenu } from './customColumnMenu';
import Header from './header';
import GridHeader from './kendoheader';
import { historyJson } from '../../../controls/jsons/history'
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
import { useParams } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import moment from 'moment';
const _ = require('lodash');

export default function History({ url = '', fieldValues = null, sharedProps = null }) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0)
    const [isexport, setExport] = useState(false);
    const _export = useRef(null)
    const [historyOpen, setHistoryOpen] = useState(false)
    const [columns, setColumns] = useState(historyJson.columns)
    const [datastate, setDataState] = useState({
        skip: 0, take: 10
    })
    const handleExport = (actiontype) => {
        GridResults(datastate, actiontype)
    }
    const params = useParams()

    useEffect(() => {
        if (historyOpen) {
            GridResults(datastate, false)
        }
    }, [historyOpen])

    const GridResults = (dataState, actiontype) => {
        let req = url
        const queryStr = `${toDataSourceRequestString(dataState)}`;
        let querystractiontype = actiontype && actiontype == 'filterall' && dataState && dataState.filter && !(_.isEmpty(dataState.filter)) ? queryStr.split('&')[0] : queryStr
        let request = [{
            url: actiontype && actiontype == 'filterall' && !(dataState && dataState.filter && !(_.isEmpty(dataState.filter))) ? req.url : req.url + `${querystractiontype}`,
            type: req.type,
            routeProps: req.routeProps,
            matchProps: req.matchProps
        }]
        Promise.all(request.map(each => requestApi(createRequest(each, fieldValues ? fieldValues : sharedProps, params)).catch(error => { return error; }))).then(response => {
            const [table] = response

            setTotal(table.total)
            if (actiontype) {
                setExport(true)
                let dataval = actiontype == "filterall" ? table.data : data
                _export.current.save(dataval, columns);
            }
            else {
                table && table.data && table.data.map(res => {
                    if (res.CreatedOn) {
                        res.CreatedOn = res.CreatedOn !== null ? moment(res.CreatedOn).format('MM/DD/YYYY hh:mm:ss A') : ""
                    }
                })
                setData(table.data)
                setDataState(dataState)
                setExport(false)
            }

        })
    }

    const handleDataStateChange = (changeEvent) => {
        let tempDatastate = changeEvent.dataState;
        setDataState(tempDatastate)
        GridResults(tempDatastate, false)
    }

    const NoDataDisplay = (props, field) => {
        if (props.rowType === "groupHeader") {
            return null;
        }
        else if (props.dataItem['Id'] !== 0 && (props.dataItem[field] == null || props.dataItem[field] == '')) {
            return <td style={{ color: '#adabaa' }}> N/A </td>
        }
        else return <td > {props.dataItem['Id'] !== 0 ? props.dataItem[field] : ""} </td>
    }

    const DisplayValue = (props, field) => {
        if (props.rowType === "groupHeader") {
            return null;
        }
        else if (field == 'OldValueDesc') {
            if ((props.dataItem['OldValueDesc'] == null || props.dataItem['OldValueDesc'] == ''))
                return <td > {props.dataItem['OldValue']}</td>
            else return <td > {props.dataItem['OldValueDesc']}</td>
        }
        else if (field == 'NewValueDesc') {
            if ((props.dataItem['NewValueDesc'] == null || props.dataItem['NewValueDesc'] == ''))
                return <td > {props.dataItem['NewValue']}</td>
            else return <td > {props.dataItem['NewValueDesc']}</td>
        }
        else return <td >  </td>
    }

    const clearFilters = () => {
        setDataState({ skip: 0, take: 10 })
        GridResults({ skip: 0, take: 10 }, false);
    }

    const onColumnsSubmit = (columnsState) => {
        setColumns(columnsState)
    }

    return <Fragment>
        {url.fabicon && <Fab size="small" color="secondary" aria-label="History" className="float-end mr-4"  title="History" onClick={() => setHistoryOpen(true)}>
            <HistoryIcon fontSize="small" />
        </Fab>}
        {!url.fabicon && <IconButton className=" zoom m-1" style={{ backgroundColor: 'white', color: '#808080' }} onClick={() => setHistoryOpen(true)} title={'History'} color={"inherit"}> <HistoryIcon fontSize="small" />        </IconButton>}

        <SwipeableDrawer disableEnforceFocus={true}
            anchor={'right'}
            open={historyOpen}
            onClose={() => setHistoryOpen(false)}
            PaperProps={{ style: { width: 900 } }}

        >
            <Header  {...historyJson.header} {...{ addOnsByName: { back: { onClick: () => setHistoryOpen(false) } } }} />
            {historyJson.headerProps && <GridHeader {...historyJson.headerProps} {...{ addOnsByName: { clear: { onClick: clearFilters }, excel: { onClick: handleExport } } }} />}
            <div className="col" style={{ height: window.innerHeight - 100, overflow: 'auto' }}>
                <ExcelExport
                    data={data}
                    fileName={url.title || 'History.xlsx'}
                    ref={_export}
                >

                    <Grid
                        reorderable
                        sortable={true}
                        //     className='col-sm-12'
                        onDataStateChange={handleDataStateChange}
                        //ref={_grid}
                        resizable
                        data={data || []}
                        total={total || 0}
                        {...datastate}
                        style={{ height: '100%', width: '100%' }}
                        pageable={{
                            buttonCount: 2,
                            info: true,
                            type: 'numeric',
                            pageSizes: true,
                            previousNext: true
                        }}
                    >
                        {(columns || []).map((each, index) => {
                            if (each.show && (each.field == 'UpdatedOn' || each.field == 'UpdatedByName' || each.field == 'CreatedOn' || each.field == 'CreatedByName'))
                                return <GridColumn {...each} filter={each.filter}
                                    title={isexport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (datastate && datastate.filter)) ? 'text-primary' : ''}>{each.title}</span>}
                                    key={index} datastate
                                    cell={e => NoDataDisplay(e, each.field)} columnMenu={
                                        props =>
                                            <CustomColumnMenu
                                                {...props}
                                                columns={columns}
                                                onColumnsSubmit={onColumnsSubmit}
                                            />
                                    } />
                            if (each.show && (each.field == 'OldValueDesc' || each.field == 'NewValueDesc'))
                                return <GridColumn {...each} filter={each.filter}
                                    title={isexport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (datastate && datastate.filter)) ? 'text-primary' : ''}>{each.title}</span>}
                                    key={index} datastate
                                    cell={e => DisplayValue(e, each.field)} columnMenu={
                                        props =>
                                            <CustomColumnMenu
                                                {...props}
                                                columns={columns}
                                                onColumnsSubmit={onColumnsSubmit}
                                            />
                                    } />
                            else if (each.show) return <GridColumn {...each} filter={each.filter} key={index}
                                title={isexport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (datastate && datastate.filter)) ? 'text-primary' : ''}>{each.title}</span>}
                                datastate cell={e => NoDataDisplay(e, each.field)}
                                columnMenu={
                                    props =>
                                        <CustomColumnMenu
                                            {...props}
                                            columns={columns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                } />
                        })}
                    </Grid>
                </ExcelExport>
            </div>
        </SwipeableDrawer>
    </Fragment>
}
