import { Map } from '@esri/react-arcgis';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEnvVariable } from '../../../modules/environmentalList'; //src\modules\environmentalList.js
import { snackbarMessage } from '../../actions';
import { createRequest, requestApi } from '../../controls/helpers/rest';
import { ApplyEditsFeatures, ApplyEditsGeometry } from './eps/applyEditsFeaturesTaskKEPT';
import { FeaturesLayerGraphicsTask, FeaturesLayerGraphicsTaskAddLookup } from './eps/featuresLayersTaskKEPT'; //featuresLayersTask
import { MapSettings } from './eps/mapSettings';
import { TaxParcelCenter } from './eps/taxParcelCenter';
import { TaxParcelTaskFeatureLayer, TaxParcelLayerTask } from './eps/taxParcelTask';
import { MapConstants } from './util/mapConstants';
import WidgetsConfig from './widgets/widgetsconfig';
//import { compmap } from '../../wrappers/commap'

class CMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: null,
            gisDomainLookupData: [],
            SubModuleKey: this.props.SubModuleKey,
            view: null,
            map: null,
            prevSITE_PERMIT_TYPE_ID: null,
            layers: [],
            allmapsettings: [],
            mapbussinesRules: null,
            sectionmapsettings: null
        };

        this.OnMapLoad = this.OnMapLoad.bind(this);
        this.mapConstants = new MapConstants();
    }

    componentDidMount() {
        //this.OnMapLoad()
        let read = [{
            url: getEnvVariable('Admin') + `/api/MapAppSettings/AllMapSettings`,
            type: 'get'
        },
        {
            url: getEnvVariable('Admin') + `/api/Layers/All/Active`,
            type: 'get'
        }]
        if (this.props.options && this.props.options.SITE_PERMIT_TYPE_ID) {
            read.push({ url: getEnvVariable('AdminConfig') + `/api/SitePermitTypeSection/MapSettings/${this.props.options.SITE_PERMIT_TYPE_ID}`, type: 'get' })
            read.push({ url: getEnvVariable('Application') + `/api/BusinessRules/MapRules/${this.props.options.SITE_PERMIT_TYPE_ID}`, type: 'get' })
        }
        Promise.all(read.map(each => requestApi(createRequest(each, {}, null)).catch(error => { return error; }))).then(resp => {
            const [allmapsettingsReq, layersReq, sectionmapsettingsReq, mapbussinesRulesReq] = resp;
            this.setState({
                prevSITE_PERMIT_TYPE_ID: this.props.SITE_PERMIT_TYPE_ID, layes: layersReq,
                allmapsettings: allmapsettingsReq, sectionmapsettings: sectionmapsettingsReq, mapbussinesRules: mapbussinesRulesReq
            }, () => {
                //performance fix for layers and mapallsettings
                this.handleMapSettings(layersReq, allmapsettingsReq, sectionmapsettingsReq, mapbussinesRulesReq);
            })
        })
    }

    handleMapSettings = (layers = null, allmapsettings = null, sectionmapsettings = null, mapbussinesRules = null) => {
        let tempLayers = layers || this.state.layers || [];
        let tempallmalsettings = allmapsettings || this.state.allmapsettings || []
        let tempmapbussinesRules = mapbussinesRules || this.state.mapbussinesRules || null;
        let tempsectionmapsettings = sectionmapsettings || this.state.sectionmapsettings || null
        this.SelectGeomInsteadOfDraw = getEnvVariable('SelectGeomInsteadOfDraw')
        this.HasMultiLocationSelector = getEnvVariable('HasMultiLocationSelector')
        MapSettings(this.props.options, this.props.SubModuleKey, tempLayers, tempallmalsettings, tempsectionmapsettings, tempmapbussinesRules).then(applyResponses => {
            this.setState({ options: applyResponses, gisDomainLookupData: this.props.gisDomainLookupData })
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedtaxparcel !== prevProps.selectedtaxparcel) {
            //this.handleMapSettings();
            this.taxParcelCenterAndZoom(this.props.selectedtaxparcel);
            this.OnMapLoad(this.state.map, this.state.view);
        }
        if (this.props.mapClear !== prevProps.mapClear && this.props.mapClear === true) {
            this.clearGraphics()
        }
        if (this.props.SITE_PERMIT_TYPE_ID && prevState.prevSITE_PERMIT_TYPE_ID && (prevState.prevSITE_PERMIT_TYPE_ID !== this.props.SITE_PERMIT_TYPE_ID)) {

            this.setState({ prevSITE_PERMIT_TYPE_ID: this.props.SITE_PERMIT_TYPE_ID }, () => {
                this.handleMapSettings();
            })
        }
        if (this.props.TAX_PARCEL_LAYER && this.props.TAX_PARCEL_LAYER !== prevProps.TAX_PARCEL_LAYER) {
            this.OnMapLoad(this.state.map, this.state.view);
        }
    }



    OnMapLoad(map, view) {
        this.setState({ view: view, map: map }, () => {
            console.log(map, "Mapppppppppppppppppppppppppppppppppppppppppppppp");
            this.centerAndZoom(view.center, view, this.state.options.viewProperties.initialzoom);//view.center this.state.options.viewProperties.extent
            if (this.state.options.FeatureLayers && this.state.options.FieldsData) {
                if (this.props.isAdd) {
                    let lookupdatabytype = this.props.gisDomainLookupData;
                    let fetchGeomType = this.props.fetchGeomType || []

                    lookupdatabytype = lookupdatabytype.filter(a => fetchGeomType.includes(a.FieldFetchGeomType) || a.FieldFetchGeomType == 'B');
                    FeaturesLayerGraphicsTaskAddLookup(view, this.state.options, this.mapConstants, this.state.options.viewProperties.zoom, lookupdatabytype, this.state.SubModuleKey).then(
                        response => {
                            this.props.onSaveorUpdateMapResponse(response);
                        },
                        error => {
                            console.log(error);
                        });

                } else {
                    FeaturesLayerGraphicsTask(view, this.state.options, this.mapConstants, this.state.options.viewProperties.zoom, this.state.SubModuleKey).then(
                        response => {
                        },
                        error => {
                            console.log(error);
                        });
                }

            }
            this.showTaxParcelLayer();
        })
    }


    showTaxParcelLayer = () => {
        if (this.state.options && this.state.options.tasks && this.state.options.tasks.TaxParcels) {
            let TaxParcels = this.state.options.tasks.TaxParcels;
            if (this.state.options && Array.isArray(this.state.options.SelectLayerListOptions)) {
                let selectedlayer = this.state.options.SelectLayerListOptions.find(a => a.Url.includes(this.state.options.tasks.TaxParcels.url))

                let SelectField = selectedlayer ? selectedlayer['SelectField'] : 'MapTaxlot';
                let features = { attributes: { [SelectField]: TaxParcels.queryExpression } }
                if (TaxParcels.url) {
                    TaxParcelLayerTask(this.state.view, this.state.options, TaxParcels, features, this.mapConstants).then(taxpres => {
                        console.log(taxpres);
                    })
                }
            }

        }
    }

    centerAndZoom = (extent, view, zoom) => {
        if (extent && view && zoom) {
            view.goTo(extent).then(function () {
                if (zoom > view.zoom)
                    view.zoom = zoom
            });

        }
    }
    taxParcelCenterAndZoom = (taxparcel) => {
        if (this.state.options && this.state.options.tasks)
            TaxParcelCenter(this.state.view, this.state.options.tasks, taxparcel, this.mapConstants, this.state.options.viewProperties.zoom).then(
                response => {
                });
    }

    QueryTaxParcel = (queryexpression, isReset) => {
        if (this.state.options && this.state.options.tasks)
            return new Promise((resolve, reject) => {
                //let queryExpressionref = this.state.options.tasks.TaxParcels.queryExpression
                //Specific to AMES - using TaxParcelTaskFeatureLayer instead of TaxParcelTask
                TaxParcelTaskFeatureLayer(this.state.view, this.state.options.tasks, queryexpression, this.mapConstants, this.state.options.viewProperties.zoom, isReset).then(
                    response => {
                        if (this.props.taxparcelresponse)
                            this.props.taxparcelresponse(response)
                        if (response && response.graphics && response.graphics.length > 0) {
                            let objIndex = this.props.options.FieldsData.findIndex((obj => obj.Fieldkey == "TAX_PARCEL_IDS_GEOM"));
                            if (objIndex > 0)
                                this.props.options.FieldsData[objIndex].FieldValue = response.graphics
                        }
                        resolve(response);
                    },
                    error => {
                        console.log(error);
                        reject({ "error": error });
                    });
            });
    }


    onSaveorUpdate = (layer) => {
        return new Promise((resolve, reject) => {
            //Last true/false : AMES specific for propsed/existing data
            let fetchGeomType = this.props.fetchGeomType || []
            let isTwoWay = (fetchGeomType.includes("E") && fetchGeomType.includes("P")) ? true : false;
            this.setLoader(true);
            ApplyEditsFeatures(this.state.options, this.mapConstants, layer, this.props.userId, fetchGeomType, this.props.gisDomainLookupData/*this.state.view.spatialReference*/, this.state.SubModuleKey).then(
                applyObjectids => {
                    this.props.onSaveorUpdateMapResponse(applyObjectids, this.state.options.viewProperties.extent.spatialReference);
                    this.props.snackbarMessage('Map Information Saved Successfully', 'success');
                    this.setLoader(false);
                    resolve(applyObjectids);
                },
                error => {
                    console.log(error);
                    this.props.snackbarMessage('Invalid data. Please check the information', 'error');
                    reject({ "error": error });
                });
        });
    }

    getLoaderHTML() {
        var dom = document.createElement('div');
        dom.className = "esri-saving-message-info";
        dom.innerHTML = '<div className="Saving-Info" style="width:100%; color: #01ff0c; background: #0000005e; text-align: center; display:block"> <b> <label className="Search-box-layerInfo-label">Please wait processing GIS domain lookup ... </label> </b> </div>';
        return dom;
    }

    setLoader(show) {
        var parentNodes = document.getElementsByClassName('esri-ui');
        var elements = document.getElementsByClassName('esri-saving-message-info');
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
        if (show)
            parentNodes[0].prepend(this.getLoaderHTML());
    }

    onSelectSaveorUpdate = (grapohics, type) => {
        try {
            ApplyEditsGeometry(this.state.options, this.mapConstants, grapohics, this.props.userId, type, this.props.gisDomainLookupData, this.state.SubModuleKey).then(applyObjectids => {
                this.props.onSaveorUpdateMapResponse(applyObjectids, this.state.options.viewProperties.extent.spatialReference);
                this.props.snackbarMessage('Map Information Saved Successfully', 'success');
                console.log(applyObjectids);
            })
        } catch (error) {
            console.log(error);
        }
    }

    clearGraphics() {
        //this.state.map.graphics.clear();
        //this.state.view.graphics.removeAll()
        //let mview = this.state.view

        this.state.map.layers.filter(l => l.title === "Sketch Layer").forEach(element => {
            element.graphics.removeAll();
        });
        let mmap = this.state.map
        this.setState({ map: mmap })
        this.props.onSaveorUpdateMapResponse({})
    }

    addTaxParcels(inthis, data) {
        if (this.props.addTaxParcels) {
            this.props.addTaxParcels(data)
        } else {
            let propdata = { "TAX_PARCEL_IDS": data }
            this.props.onSaveorUpdateMapResponse(propdata);
        }
    }

    multipleLocationHandler(inthis, data) {
        if (inthis.props.options.MultipleLocationData)
            inthis.props.options.MultipleLocationData.push(data);

        let currentoptions = inthis.state.options;
        currentoptions.MultipleLocationData.push(data);
        inthis.setState({ options: currentoptions });
        if (inthis.props.multipleLocationHandler)
            inthis.props.multipleLocationHandler(data);
    }

    //id={this.state.options.containerID}

    getViewProperties() {
        const stateoptions = this.state.options;
        const viewProperties = stateoptions.viewProperties;
        const basemapviewprops = viewProperties;
        let basemapprops = { ...basemapviewprops }
        /*if (stateoptions && basemapprops) {
            const basemapextent = basemapviewprops.extent
            let spatialref = basemapextent['basemap_spatialReference'];
            if (typeof spatialref === 'number') {
                console.log('Base map spatial reference ---------', spatialref);
                basemapprops.extent = {}
                let customextent = { ...basemapextent }
                customextent['spatialReference'] = spatialref;                
                basemapprops.extent = customextent;
                //basemapprops.extent['spatialReference'] = spatialref
            }
        }*/
        //console.log('getViewProperties----------',this.state.options, basemapprops);
        return basemapprops;
    }

    render() {
        const { width, compmap, customMapHeight = null } = this.props;
        const height = `calc(120vh - ${customMapHeight ? customMapHeight + 'px' : '305px'})`;
        if (this.state.options) {
            console.log(this.state.options.basemap, 'BASEMAP Changes')
        }
        console.log('Base map spatial reference ---------', this.state);

        return (
            <div>
                {this.state.options &&
                    <Map className="full-screen-map"
                        onLoad={this.OnMapLoad}
                        mapProperties={{ spatialReference: this.state.options.viewProperties.extent.spatialReference }}
                        viewProperties={this.getViewProperties()}
                        style={{ width: width ? width : '100%', height: height ? height : '84vh' }}>

                        {
                            this.state.view && <WidgetsConfig
                                handleSeclectionfromMap={this.props.handleSeclectionfromMap}
                                SubModuleKey={this.props.SubModuleKey}
                                gisDomainLookupData={this.props.gisDomainLookupData}
                                SelectGeomInsteadOfDraw={this.SelectGeomInsteadOfDraw}
                                HasMultiLocationSelector={this.HasMultiLocationSelector}
                                options={this.state.options}
                                onSaveorUpdate={this.onSaveorUpdate}
                                view={this.state.view}
                                mapConstants={this.mapConstants}
                                mapdata={this.props.mapdata}
                                selectiondata={this.props.selectiondata}
                                addTaxParcels={(data) => this.addTaxParcels(this, data)}
                                setStateMapInformation={this.props.setStateMapInformation}
                                isGridView={this.props.isGridView}
                                fetchGeomType={this.props.fetchGeomType}
                                canEdit={this.props.canEdit}
                                compmap={compmap}
                                onSaveorUpdateMapResponse={this.props.onSaveorUpdateMapResponse}
                                multipleLocationHandler={(data) => this.multipleLocationHandler(this, data)}
                                handlelayerFilter={this.props.handlelayerFilter}
                                fields={this.props.fields || []}
                                clearsketch={this.props.clearsketch}
                                permitTypeId={this.props.permitTypeId}
                                layerCustomFilters={this.props.layerCustomFilters}
                                onSelectSaveorUpdate={this.onSelectSaveorUpdate}
                            />
                        }
                    </Map>}
                {!this.state.options && <div> </div>}
            </div>
        );
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}
CMap = connect(null, mapActions)(CMap)
export default CMap;

