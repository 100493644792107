import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 450,
    },
    // input: {
    //     marginLeft: theme.spacing(4),
    //     flex: 1,
    // },

}));

export default function CustomizedInputBase(props) {
    const classes = useStyles();

    return (
        <div className = "ms-3" >
        <Paper className={classes.root}>
            <SearchIcon />
            <InputBase
                // className={classes.input}
                placeholder="Search"
                value={props.search}
                onChange={props.handleSearchChange}
            />
        </Paper>
        </div>
    );
}
