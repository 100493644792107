import MaterialTable from '@material-table/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import { getEnvVariable } from '../../../src/modules/environmentalList';
import { DownloadInvoice } from '../../core/controls/components/custom/downloadshelper';
import FormHeader from '../../core/modules/form/cformheader';
import { GetInvoiceById, GetInvoicePaymentInformation, GetInvoicePermitDetails, GetInvoicePermitDetailsList, GetInvoicesList, GetInvoiceViewById, GetStateDomainValues, GetTotalDue, GetTotalDueMultiPayments, GetTotalFee, InsertInvoicePhysicalCheckPayment } from './billingservice';
const _ = require('lodash');

const drawerWidth = 1000;

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0),
    },
    drawerPaper: {
        width: drawerWidth,
    },
    root: {
        width: '100%'
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    },
}));

function PhysicalCheckSideBar(props, ref) {
    const [open, setOpen] = useState(false);
    const [itemEdit, setItemEdit] = useState(false);
    const [billingInfo, setBillinfInfo] = useState({ PayeeName: '', PayeeLastName: '', PayeeAddressLine1: '', PayeeAddressLine2: '', PayeeCity: '', PayeeState: '', PayeeZip: '', PayeeEmail: '' });

    const classes = useStyles();
    const [currentCheckData, setCurrentCheckData] = useState({ Id: 0, Type: 'Check', TransactionDate: new Date(), Status: 'Paid', Quantity: 1, ProcessingFee: 0, CheckNumber: '', CheckDepositNumber: '', CheckBankNumber: '', CheckDepositDate: '', TotalAmount: '', CheckDepositedBy: '' });
    const [initialCheckData, setInitialCheckData] = useState([])
    const [checkData, setCheckData] = useState([])
    const [paymentInformation, setPaymentInformation] = useState({ InvoiceId: 0, ApplicantId: 0, ApplicationType: '', TotalFee: 0, BalanceDue: 0, PermitNumber: '' })
    const [helperText, setHelperText] = useState(null)
    const [billingHelperText, setBillingHelperText] = useState(null)
    const [amountHelperText, setAmountHelperText] = useState(null)
    const [zipHelperText, setZipHelperText] = useState(null)
    const [balanceAmount, setBalanceAmount] = useState(null)
    const [statedropdownvalues, setStatedropdownvalues] = useState([])
    const [firstnameHelperText, setFirstnameHelperText] = useState(null)
    const [lastnameHelperText, setLastnameHelperText] = useState(null)
    const [addressline1HelperText, setAddressline1HelperText] = useState(null)
    const [addressline2HelperText, setAddressline2HelperText] = useState(null)
    const [cityHelperText, setCityHelperText] = useState(null)
    const [editedData, setEditedData] = useState(null)
    const [invoicePermits, setInvoicePermits] = useState({})
    const [ACHCCPaymentDetails, setACHCCPaymentDetails] = useState({ ProcessingFee: 0, TotalAmount: 0 , TransactionId: 'NA', PaymentDate: 'NA'})

    useEffect(() => {
        GetStateDomainValues().then(res => {
            setStatedropdownvalues(res);
        });
        console.log("Hello");
        if (props.viewtype == 'BILLING') {
            LoadBillingData();
        } else if (props.viewtype == 'INVOICE') {
            LoadInvoiceData();
        }
        else if (props.viewtype == 'PAYMENT') {
            LoadInvoicesData();
        }
        LoadInvoicePermitDetailsList()

    }, []);

    const LoadBillingData = () => {
        let recordid = props.match.params.routeid1
        if (props.billingid) {
            let invoiceids = props.billingid.split(',');
            if (invoiceids.length > 0) {
                GetInvoicePaymentInformation(recordid, invoiceids).then(res => {
                    console.log(res);
                });
            }
        }
    }

    const LoadInvoicesData = () => {
        if(props.invoiceid && Array.isArray(props.invoiceid)){
            GetInvoicesList(props.invoiceid).then(res => {
                console.log(res)
                if (res) {
                    setBalanceAmount(GetTotalDueMultiPayments(res));
                    let appdata = props.appdata || {}
                    let billingpaymentdetails = [];
                    let totalFeeAmount = 0;
                    let invmerged = res[0];
                    let invdetailsmerged = [];
                    let invlineitemsmerged = [];
                    let invbillingpaymentsmerged = [];
                    let invbillingpaymentdetailsmerged = [];
                    if(Array.isArray(res)){
                        res.forEach(inv => {
                            invdetailsmerged.push(...inv.InvoiceDetails)
                            invlineitemsmerged.push(...inv.InvoiceLineItems)
                            //if(invbillingpaymentsmerged.some(e => e.BillingPaymentDetailId === 'Magenic'))
                            invbillingpaymentsmerged.push(...inv.BillingPayments);
                            inv.BillingPayments.forEach(blg => {
                                invbillingpaymentdetailsmerged.push(blg.BillingPaymentDetails)
                            });
                            //totalFeeAmount =totalFeeAmount + GetTotalFee(inv)
                        });
                    }
                    invmerged.InvoiceDetails = invdetailsmerged;
                    invmerged.InvoiceLineItems = invlineitemsmerged;
                    //Remove duplicate data
                    invbillingpaymentdetailsmerged = invbillingpaymentdetailsmerged.filter((tag, index, array) => array.findIndex(t => t.Id == tag.Id && t.Type == tag.Type) == index);
                    // let uniquebillingpayments = invbillingpaymentsmerged[0];
                    // uniquebillingpayments.BillingPaymentDetails = invbillingpaymentdetailsmerged[0];
                    //invmerged.BillingPayments = [uniquebillingpayments]

                    setPaymentInformation({ InvoiceId: invmerged.Id, ApplicantId: (invmerged && Array.isArray(invmerged.InvoiceLineItems)) ? invmerged.InvoiceLineItems[0].OwnerId : appdata["COMPANY_ID"], ApplicationType: props.match.params.key, TotalFee: GetTotalFee(invmerged), BalanceDue: GetTotalDue(invmerged), PermitNumber: appdata["PERMIT_NO"] })

                    billingpaymentdetails = invbillingpaymentdetailsmerged;
                    // invmerged.BillingPayments.forEach(payment => {
                    //     if (payment.BillingPaymentDetails)
                    //         billingpaymentdetails.push(payment.BillingPaymentDetails);
                    // })
                    if (billingpaymentdetails.length > 0) {
                        let billinginfo = billingpaymentdetails[0];
                        let achepayments = billingpaymentdetails.filter(a => a.Type == 'ACH');
                        setCheckData(billingpaymentdetails.filter(a => a.Type == 'Check'));
                        setBillinfInfo({ PayeeName: billinginfo.PayeeName, PayeeLastName: billinginfo.PayeeLastName, PayeeAddressLine1: billinginfo.PayeeAddressLine1, PayeeAddressLine2: billinginfo.PayeeAddressLine2, PayeeCity: billinginfo.PayeeCity, PayeeState: billinginfo.PayeeState, PayeeZip: billinginfo.PayeeZip, PayeeEmail: billinginfo.PayeeEmail });
                        if(achepayments && Array.isArray(achepayments) && achepayments.length > 0)
                            setACHCCPaymentDetails({ ProcessingFee: achepayments[0].ProcessingFee, TotalAmount: achepayments[0].TotalAmount , TransactionId: achepayments[0].TransactionId, PaymentDate: moment(achepayments[0].TransactionDate).format('MM/DD/YYYY')})
                    }
                }
            })
        }
    }

    const LoadInvoiceData = () => {
        GetInvoiceById(props.invoiceid).then(res => {
            console.log(res)
            if (res) {
                let appdata = props.appdata || {}
                let billingpaymentdetails = []
                setPaymentInformation({ InvoiceId: res.Id, ApplicantId: (res && Array.isArray(res.InvoiceLineItems)) ? res.InvoiceLineItems[0].OwnerId : appdata["COMPANY_ID"], ApplicationType: props.match.params.key, TotalFee: GetTotalFee(res), BalanceDue: GetTotalDue(res), PermitNumber: appdata["PERMIT_NO"] })
                setBalanceAmount(GetTotalDue(res));
                res.BillingPayments.forEach(payment => {
                    if (payment.BillingPaymentDetails)
                        billingpaymentdetails.push(payment.BillingPaymentDetails);
                })
                if (billingpaymentdetails.length > 0) {
                    let billinginfo = billingpaymentdetails[0];
                    let achepayments = billingpaymentdetails.filter(a => a.Type == 'ACH');
                    setCheckData(billingpaymentdetails.filter(a => a.Type == 'Check'));
                    setBillinfInfo({ PayeeName: billinginfo.PayeeName, PayeeLastName: billinginfo.PayeeLastName, PayeeAddressLine1: billinginfo.PayeeAddressLine1, PayeeAddressLine2: billinginfo.PayeeAddressLine2, PayeeCity: billinginfo.PayeeCity, PayeeState: billinginfo.PayeeState, PayeeZip: billinginfo.PayeeZip, PayeeEmail: billinginfo.PayeeEmail });
                    if(achepayments && Array.isArray(achepayments) && achepayments.length > 0)
                        setACHCCPaymentDetails({ ProcessingFee: achepayments[0].ProcessingFee, TotalAmount: achepayments[0].TotalAmount , TransactionId: achepayments[0].TransactionId, PaymentDate: moment(achepayments[0].TransactionDate).format('MM/DD/YYYY')})
                }
            }
        })
    }

    const LoadInvoicePermitDetails = () => {
        GetInvoicePermitDetails(props.invoiceid).then(res => {
            console.log(res)
            if (res && Array.isArray(res)) {
                setInvoicePermits(res)
            }
        })
    }

    const LoadInvoicePermitDetailsList = () => {
        let invlist = props.viewtype == 'PAYMENT' ? props.invoiceid : [props.invoiceid]
        GetInvoicePermitDetailsList(invlist).then(res => {
            setInvoicePermits(res)
        })
    }

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const toggleDrawer = () => {
        setOpen(false);
        props.physicalcheckSlider(false, '', '');
    };

    const handleSave = () => {
        if (billingInfo.PayeeName == '' || billingInfo.PayeeLastName == '' || billingInfo.PayeeAddressLine1 == '' || billingInfo.PayeeCity == '' || billingInfo.PayeeState == '' || billingInfo.PayeeZip == '') {
            setBillingHelperText("Please fill mandatory fields")
        }
        else if (billingInfo.PayeeName.length > 100) {
            setFirstnameHelperText("First Name length should be in the range of (1-100)")
        }
        else if (billingInfo.PayeeLastName.length > 100) {
            setLastnameHelperText("Last Name length should be in the range of (1-100)")
        }
        else if (billingInfo.PayeeAddressLine1.length > 1000) {
            setAddressline1HelperText("Address Line 1 length should be in the range of (1-1000)")
        }
        else if (billingInfo.PayeeAddressLine2.length > 1000) {
            setAddressline2HelperText("Address Line 2 length should be in the range of (1-1000)")
        }
        else if (billingInfo.PayeeCity.length > 200) {
            setCityHelperText("City length should be in the range of (1-200)")
        }
        else if (!(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(billingInfo.PayeeZip))) {
            setZipHelperText("Please enter valid Zip")
        }
        else if (props.viewtype == 'INVOICE' || props.viewtype == 'PAYMENT') {
            saveInvoicePayments();
        }
        // call request, data to pass : checkData and BillingInfo

    }

    const saveInvoicePayments = () => {
        if (checkData && Array.isArray(checkData)) {
            let billinginformation = []
            checkData.forEach(checkinfo => {
                billinginformation.push({ ...checkinfo, ...billingInfo })
            })
            InsertInvoicePhysicalCheckPayment(props.invoiceid, billinginformation).then(res => {
                console.log(res);
                toggleDrawer();
            })
        }
    }

    const handleFieldChange = (event, name) => {
        setZipHelperText(null)
        setFirstnameHelperText(null)
        setLastnameHelperText(null)
        setAddressline1HelperText(null)
        setAddressline2HelperText(null)
        setCityHelperText(null)
        setBillinfInfo({ ...billingInfo, [name]: event.target.value });
        setBillingHelperText(null)
    }

    const handleCheckChange = (event, name) => {
        let valuedata = event.target.value
        if (name == "TotalAmount") {
            setAmountHelperText(null)
            let num = event.target.value.split(".");
            if(num && Array.isArray(num) && num.length>1) {
                const myArray = valuedata.split(".");
                if(myArray[1].length > 2){
                    let dectext = myArray[1].substring(0, 2)
                    valuedata = myArray[0]+'.'+dectext
                }
            }
        }
        setCurrentCheckData({ ...currentCheckData, [name]: valuedata });
        setHelperText(null);
    }

    const insert = () => {
        setItemEdit(true);
    }

    const discard = () => {
        setCurrentCheckData({ Id: 0, Type: 'Check', TransactionDate: new Date(), Status: '', Quantity: 1, SubTotal: '', ProcessingFee: 0, CheckNumber: '', CheckDepositNumber: '', CheckBankNumber: '', CheckDepositDate: '', TotalAmount: '', CheckDepositedBy: '' });
        setItemEdit(false);
        setHelperText(null);
        setAmountHelperText(null);
        setEditedData(null)
    }

    const addcheck = () => {
        if (currentCheckData.CheckBankNumber == "" || currentCheckData.CheckDepositDate == "" || currentCheckData.CheckDepositNumber == "" || currentCheckData.TotalAmount == "" || currentCheckData.CheckNumber == "") {
            setHelperText("Please fill all the mandatory fields.")
            setAmountHelperText(null)
        }
        else if (currentCheckData.TotalAmount > balanceAmount) {
            setAmountHelperText("Check Amount cannot exceed Balance(due)")
        }
        else {
            let totalcheckdata = [...checkData];
            let blncamount
            if(editedData) {
                blncamount = Number(balanceAmount) + Number(editedData.TotalAmount)
                totalcheckdata = totalcheckdata.map(x => _.isEqual(x, editedData) ? currentCheckData : x)
            }
            else {
            let savedCheckData = { Type: 'Check', TransactionDate: new Date(), Status: 'Paid', Quantity: 1, SubTotal: currentCheckData.TotalAmount, ProcessingFee: 0, Invoices: (props.viewtype == "PAYMENT" ? props.invoiceid : [props.invoiceid]), ...currentCheckData, ...billingInfo }
            totalcheckdata.push(savedCheckData);
            }
            setCheckData(totalcheckdata);
            blncamount = Number(balanceAmount) - Number(currentCheckData.TotalAmount)
            //setBalanceAmount(blncamount)
            setCurrentCheckData({ Type: 'Check', TransactionDate: new Date(), Status: '', Quantity: 1, SubTotal: '', ProcessingFee: 0, CheckNumber: '', CheckDepositNumber: '', CheckBankNumber: '', CheckDepositDate: '', TotalAmount: '', CheckDepositedBy: '' });
            setItemEdit(false);
            setAmountHelperText(null)
            setEditedData(null)
        }
    }

    const deleteCheck = (x) => {
        var array = [...checkData];
        var finalarray = _.remove(array, function (n) {
            return !(_.isEqual(n, x));
        });
        setCheckData(finalarray);
        setAmountHelperText(null);
        let blncamount = Number(balanceAmount) + Number(x.TotalAmount)
        setBalanceAmount(blncamount)
    }

    const EditCheck = (x) => {
        console.log(x);
        setCurrentCheckData({...x});
        setEditedData({...x})
        setItemEdit(true);
    }

    const cellWithEditing = (propdata) => {
        return <td>
            <IconButton onClick={() => EditCheck(propdata.dataItem)} color="primary" title="Edit Check">
                <EditIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={() => deleteCheck(propdata.dataItem)} disabled={ propdata.dataItem.Id > 0 ? true : _.some(initialCheckData, propdata.dataItem)} color="primary" title="Delete Check">
                <DeleteIcon fontSize="small" />
            </IconButton>
            {/* {propdata && propdata.dataItem && propdata.dataItem.Id && !propdata.dataItem.Id > 0 &&
                    <IconButton onClick={() => deleteCheck(propdata.dataItem)} disabled={_.some(initialCheckData, propdata.dataItem)} color="primary" title="Delete Check">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            } */}

        </td>
    }
    const DateCustomCell = (props) => {
        return (
            <td>
               {props.dataItem[props.field] ? moment(props.dataItem[props.field]).format('MM/DD/YYYY') : ""}
            </td>
        );
    }

    const handleDownload = () => {
        GetInvoiceViewById(paymentInformation.InvoiceId).then(res =>{
            if(res && res.ReceiptDownloadId){
                DownloadInvoice(paymentInformation.InvoiceId, res.ReceiptDownloadId).then(data => {
                    const downloadLink = document.createElement("a");
                    downloadLink.href = data.base64PDFFileContent;//dFile
                    downloadLink.download = data.fileName; //fileName
                    downloadLink.click();
                    downloadLink.remove();
                })

            }else{
                alert('No setting mapped')
            }
        })
        console.log("test");
    }

    const InvoiceLineItemsForm = (key, data) => {
        return (
            <div className={classes.root}>
                <Accordion style={{border: '1px solid', color: '#1976d2'}}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                            <Typography>Line Items Of {lable_baseCAP} - <b>{key}</b> </Typography>
                        </AccordionSummary>

                        <AccordionDetails style={{'display':'block'}}>
                            <MaterialTable
                                title=""
                                columns={[
                                    { title: 'Permit Number', field: 'PermitNumber' },
                                    { title: 'District', field: 'District' },
                                    { title: 'Applicant Name', field: 'ApplicantName' },
                                    { title: 'Date Submitted', field: 'SubmittedDate' },
                                    { title: 'Authorized By', field: 'AuthorizedBy' },
                                    { title: 'Authorized Date', field: 'AuthorizedDate' }
                                ]}
                                data={data}
                                />
                        </AccordionDetails>
                </Accordion>
            </div>
          )
    }

    const InvoiceLineItemsFormControl = (invoicePermits) => {
        let content = []
        Object.keys(invoicePermits).forEach(function(key) {
                content.push(InvoiceLineItemsForm(key, invoicePermits[key]))
        })
        return content;
    }

    const checkform = () => {
        return (
            <div>
                <div className="row pl-5 pt-2">
                    <TextField
                        label="Check Number"
                        className={'col-sm-5 pr-3'}
                        value={currentCheckData.CheckNumber}
                        onChange={(e) => handleCheckChange(e, 'CheckNumber')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="number"
                        required
                    />
                    <TextField
                        label="Check Deposit Number"
                        className={'col-sm-5 pr-2'}
                        value={currentCheckData.CheckDepositNumber}
                        onChange={(e) => handleCheckChange(e, 'CheckDepositNumber')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="number"
                        required
                    />
                </div>
                <div className="row pl-5">
                    <TextField
                        label="Bank Number"
                        className={'col-sm-5 pr-3'}
                        value={currentCheckData.CheckBankNumber}
                        onChange={(e) => handleCheckChange(e, 'CheckBankNumber')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="number"
                        required
                    />
                    <TextField
                        label="Deposit Date"
                        className={'col-sm-5 pr-2'}
                        value={currentCheckData.CheckDepositDate}
                        onChange={(e) => handleCheckChange(e, 'CheckDepositDate')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="date"
                        required
                    />
                </div>
                <div className="row pl-5">
                {/* <NumericTextBox
                    label="Check Amount *"
                    width={400}
                    required={true}
                    value={currentCheckData.TotalAmount && currentCheckData.TotalAmount == "" ? 0 : currentCheckData.TotalAmount}
                    onChange={(e) => handleCheckChange(e, 'TotalAmount')}
                    format="c2"
                    min={0}
                    required
                    error={amountHelperText ? true : false}
                    helperText={amountHelperText} />
                    {amountHelperText && <FormHelperText error={true}>{amountHelperText}</FormHelperText>} */}
                    <TextField
                        label="Check Amount"
                        className={'col-sm-5 pr-3'}
                        value={currentCheckData.TotalAmount}
                        onChange={(e) => handleCheckChange(e, 'TotalAmount')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="number"
                        required
                        error={amountHelperText ? true : false}
                        helperText={amountHelperText}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                    />
                    <TextField
                        label="Deposited By"
                        className={'col-sm-5 pr-2'}
                        value={currentCheckData.CheckDepositedBy}
                        onChange={(e) => handleCheckChange(e, 'CheckDepositedBy')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className="row pl-5 pt-2">
                    {helperText && <FormHelperText error={true}>{helperText}</FormHelperText>}
                </div>
                <div className="row pl-5 pt-2">
                    <Button color="primary" className="pr-3" variant="contained" size="small" onClick={discard} startIcon={<DeleteOutlineIcon />}>Discard</Button>
                    <span className="pl-3" ><Button color="primary" variant="contained" size="small" onClick={addcheck} startIcon={<CheckIcon />}>Add Check</Button></span>
                </div>
            </div>
        )
    }

    const lable_baseCAP = props.appdata && props.appdata["KEY"] == 'VIOLATIONS' ? "Fine" : "Invoice";

    return (
        <Drawer anchor={'right'} disableBackdropClick open={open} onClose={() => toggleDrawer()} classes={{
            paper: classes.drawerPaper,
        }}>
            <FormHeader className="row" title={props.viewtype && props.viewtype == "PAYMENT" ? "Payment Information" : "Physical Check"} savebutton={(getEnvVariable('AccessPortal') === 'I' && balanceAmount > 0) ? true : false} handleSave={handleSave} closebutton={true} handleClose={toggleDrawer} handleDownload={handleDownload} downloadbutton={true}/>
            <Typography variant="subtitle2" color="primary" className="pl-3 pt-2" gutterBottom>
                <b>Payment Information</b>
            </Typography>
            <div>
                <Typography className="float-right pr-3 pt-2" variant="body2" gutterBottom>Payment Total :$ {paymentInformation.TotalFee}</Typography>
                <Typography display="block" className="pl-3 pt-2" variant="body2" gutterBottom>Invoice Id : { props.viewtype == "PAYMENT" ? (Array.isArray(props.invoiceid)  ? props.invoiceid.join() : props.invoiceid) : paymentInformation.InvoiceId}</Typography>
                <Typography className="float-right pr-3 pt-2" variant="body2" gutterBottom>Balance(Due) :$ {balanceAmount}</Typography>
                <Typography display="block" className="pl-3 pt-2" variant="body2" gutterBottom>Applicant Id : {paymentInformation.ApplicantId}</Typography>
                {/* <Typography display="block" className="pl-3 pt-2" variant="body2" gutterBottom>Payment Total :$ {paymentInformation.TotalFee}</Typography> */}
                {/* <Typography display="block" className="pl-3 pt-2" variant="body2" gutterBottom>Record/Permit Number : {paymentInformation.PermitNumber}</Typography> */}
                {/* <Typography display="block" className="pl-3 pt-2" variant="body2" gutterBottom>Balance(Due) :$ {balanceAmount}</Typography> */}
                {/* <Typography display="block" className="pl-3" variant="body2" gutterBottom>Application Type : {paymentInformation.ApplicationType}</Typography> */}


                {props.viewtype && props.viewtype == "PAYMENT" &&
                    <div>
                        <Typography variant="subtitle2" color="primary" className="pl-3 pt-2" gutterBottom>
                            <b>ACH/CC Payment Details</b>
                        </Typography>
                        <Typography className="float-right pr-3 pt-2" variant="body2" gutterBottom>Total Amount :$ {ACHCCPaymentDetails.TotalAmount}</Typography>
                        <Typography display="block" className="pl-3 pt-2" variant="body2" gutterBottom>Processing Fee :$ {ACHCCPaymentDetails.ProcessingFee}</Typography>
                        <Typography className="float-right pr-3 pt-2" variant="body2" gutterBottom>Payment Date : {ACHCCPaymentDetails.PaymentDate}</Typography>
                        <Typography display="block" className="pl-3 pt-2" variant="body2" gutterBottom>Transaction Id : {ACHCCPaymentDetails.TransactionId}</Typography>
                    </div>}
            </div>
            {InvoiceLineItemsFormControl(invoicePermits)}

            {/* {invoicePermits && InvoiceLineItemsForm(invoicePermits)} */}
            <Typography variant="subtitle2" color="primary" className="pl-3 pt-3" gutterBottom>
                <b className="pr-3">Physical Check</b>
                <IconButton color="primary" title="Add Check" disabled={balanceAmount <= 0} aria-label="add" onClick={insert}>
                    <AddCircleIcon />
                </IconButton>
            </Typography>
            <div>
                <Grid data={checkData} style={{ height: '180px' }} reorderable resizable>
                    <Column title="Edit" cell={cellWithEditing} width="100px" />
                    <Column field="CheckNumber" title="Check Number" width="150px" />
                    <Column field="CheckDepositNumber" title="Check Deposit Number" width="200px" />
                    <Column field="CheckBankNumber" title="Bank Number" width="150px" />
                    <Column field="CheckDepositDate" title="Deposit Date" width="150px" cell={DateCustomCell}/>
                    <Column field="TotalAmount" title="Check Amount" width="150px" />
                    <Column field="CheckDepositedBy" title="Deposited By" width="150px" />
                </Grid>
            </div>
            {itemEdit && checkform()}
            <Typography variant="subtitle2" color="primary" className="pl-3 pt-3" gutterBottom>
                <b>Billing Information</b>
            </Typography>
            <div className="row pl-5">
                <TextField
                    label="First Name"
                    className={'col-sm-5 pr-3'}
                    value={billingInfo.PayeeName}
                    onChange={(e) => handleFieldChange(e, 'PayeeName')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    required
                    error={firstnameHelperText ? true : false}
                    helperText={firstnameHelperText}
                    disabled={balanceAmount <= 0}
                />
                <TextField
                    label="Last Name"
                    className={'col-sm-5 pr-2'}
                    value={billingInfo.PayeeLastName}
                    onChange={(e) => handleFieldChange(e, 'PayeeLastName')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    required
                    error={lastnameHelperText ? true : false}
                    helperText={lastnameHelperText}
                    disabled={balanceAmount <= 0}
                />
            </div>
            <div className="row pl-5">
                <TextField
                    label="Address Line 1"
                    className={'col-sm-10 pr-2'}
                    value={billingInfo.PayeeAddressLine1}
                    onChange={(e) => handleFieldChange(e, 'PayeeAddressLine1')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    required
                    error={addressline1HelperText ? true : false}
                    helperText={addressline1HelperText}
                    disabled={balanceAmount <= 0}
                />
            </div>
            <div className="row pl-5">
                <TextField
                    label="Address Line 2"
                    className={'col-sm-10 pr-2'}
                    value={billingInfo.PayeeAddressLine2}
                    onChange={(e) => handleFieldChange(e, 'PayeeAddressLine2')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={addressline2HelperText ? true : false}
                    helperText={addressline2HelperText}
                    disabled={balanceAmount <= 0}
                />
            </div>
            <div className="row pl-5">
                <TextField
                    label="City"
                    className={'col-sm-4 pr-3 pb-2'}
                    value={billingInfo.PayeeCity}
                    onChange={(e) => handleFieldChange(e, 'PayeeCity')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    required
                    error={cityHelperText ? true : false}
                    helperText={cityHelperText}
                    disabled={balanceAmount <= 0}
                />
                <FormControl className={'col-sm-3 pr-3 pb-2'} required>
                    <InputLabel shrink required >State</InputLabel>
                    <Select
                        value={billingInfo.PayeeState}
                        onChange={(e) => handleFieldChange(e, 'PayeeState')}
                        required
                        disabled={balanceAmount <= 0}
                    >
                        {statedropdownvalues && Array.isArray(statedropdownvalues) && statedropdownvalues.map((each, index) => {
                            const value = each.Value
                            const name = each.Name
                            const key = each.key || value
                            return <MenuItem key={key || ('item' + value)} value={value}>{name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                {/* <TextField
                    label="State"
                    className={'col-sm-3 pr-3 pb-2'}
                    value={billingInfo.PayeeState}
                    onChange={(e) => handleFieldChange(e, 'PayeeState')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    required
                /> */}
                <TextField
                    error={zipHelperText ? true : false}
                    label="Zip"
                    className={'col-sm-3 pr-2 pb-2'}
                    value={billingInfo.PayeeZip}
                    onChange={(e) => handleFieldChange(e, 'PayeeZip')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    required
                    helperText={zipHelperText}
                    disabled={balanceAmount <= 0}
                />
            </div>
            <div className="pl-5 pb-2">
                {billingHelperText && <FormHelperText error={true}>{billingHelperText}</FormHelperText>}
            </div>
        </Drawer>
    );
}

export default forwardRef(PhysicalCheckSideBar);
