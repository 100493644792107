import Drawer from '@material-ui/core/Drawer';
//import TemplateSettings from '../sitepermittypes/templatesettings';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getEnvVariable } from '../../../modules/environmentalList';
import DropDown from '../../controls/components/dropdown';
import Header from '../../controls/components/header';
import Switch from '../../controls/components/switch';
import { PostData } from '../../controls/helpers/requests';
import { createRequest, requestApi } from '../../controls/helpers/rest';

const TemplateSettingsheader = {
    name: "header",
    type: 'header',
    title: 'Template Settings for Application Status',
    components: [
        {
            type: 'breadcrumbs',
            items: [
                { "link": "Template Settings for Permit Status", "materialicon": "assignment_turned_in", className: 'eps-breadcrumb-item ', class: 'text-light' },
            ]
        }
    ],
    actions: [
        {
            type: 'icon',
            icon: 'back',
            name: 'back',
            hideon: 'back',
            hidevalue: false
        },
        {
            type: 'icon',
            icon: 'save',
            name: 'save',
            hideon: 'save',
            hidevalue: false
        },
    ],
}

const defaultDrawerWidth = 900;
const minDrawerWidth = 700;
const maxDrawerWidth = window.innerWidth - 220;
const fullscreenwidth = window.innerWidth
const checkwidth = 960

const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 0
    },
    dragger: {
        width: "7px",
        cursor: "ew-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 100,
        //   backgroundColor: "#f4f7f9"
    }
}));

export default function TemplateSettings({ sitePermitTypeAppStatusId, closeTemplate, props }) {
    let params = useParams();
    const [appDownloadsHelperText, setAppDownloadsHelperText] = useState(null)
    const [permitDownloadsHelperText, setPermitDownloadsHelperText] = useState(null)
    const [data, setData] = useState([]);
    const [result, setResult] = useState({})
    const [dataexists, setDataExists] = useState(false)
    const [templatesettings, setTemplateSettings] = useState([])
    const [buttonsettings, setButtonsettings] = useState([])
    const [reload, setReload] = useState(false)


    //drawer settings
    const [drawerWidth, setDrawerWidth] = useState((props && props.width == "sm" || props && props.width == "xs" || props && props.width == "md") ? fullscreenwidth : defaultDrawerWidth);
    const [fullscreen, setFullScreen] = useState(false)
    const [fullwidth, setFullWidth] = useState(fullscreenwidth)



    const handleMouseDown = e => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let newWidth =
            document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        //  const newWidth = e.clientX - document.body.offsetLeft;
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            setDrawerWidth(newWidth);
        }
    }, []);
    //end drawer settings
    useEffect(() => {
        const read = [
            {
                url: getEnvVariable('AppDownloadAPI') + `/Template/All/${getEnvVariable('ModuleKey')}`,
                type: 'get'
            },
            {
                url: getEnvVariable('AdminConfig') + `/api/SitePermitTypeTemplateSettings/` + params.Id + '/' + sitePermitTypeAppStatusId + `/GetAll`,
                type: 'get'
            }
            //add another request
        ]
        Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(res => {
            const [buttonsettings, templatesettings] = res;
            setTemplateSettings(templatesettings)
            if (templatesettings.length > 0) {
                setResult(templatesettings[0]);
            }
            setButtonsettings(buttonsettings)
        })
    }, [])

    const handleFieldChange = (event, type, id) => {
        const { name, value } = event.target;
        if (name === 'AppDownloadsId') {
            //setAppDownloadDropdown(value);
            setAppDownloadsHelperText(null);
        }
        if (name === 'PermitDownloadsId') {
            //setPermitDownloadDropdown(value);
            setPermitDownloadsHelperText(null);
        }
        if (type === 'add') {
            result[name] = value;
            setResult(result)
            setReload(!reload)
        }
        else if (type === 'editdownloadtemplate') {
            data.map(final => {
                if (final.Id === id) {
                    final[name] = value
                }
            })
            setData(data)
            setReload(!reload)
        }
    }

    const handleFieldValue = (event, type, id) => {
        const { name, checked } = event.target;
        if (name === "IsApplicationDownload" && checked == false) {
            //setAppDownloadToggle(checked)
            setAppDownloadsHelperText(null);
        }
        if (name === "IsPermitDownload" && checked == false) {
            //setPermitDownloadToggle(checked)
            setPermitDownloadsHelperText(null);
        }
        if (type === 'add') {
            result[name] = checked ? 'Y' : 'N';
            data && data.length > 0 && data.map(final => {
                final[name] = 'N'
            })
            setData(data)
            setResult(result)
            setReload(!reload)
        }
        else {
            data.map(final => {
                if (final.Id === id) {
                    final[name] = checked ? 'Y' : 'N';
                }
                else final[name] = checked ? 'N' : final[name]
            })
            setData(data)
            setReload(!reload)
        }
    }

    const handleSave = () => {
        let tempdata = result;
        result.IsApplicationDownload = result.IsApplicationDownload ? result.IsApplicationDownload : 'N';
        result.IsPermitDownload = result.IsPermitDownload ? result.IsPermitDownload : 'N';
        if (result.IsApplicationDownload == "Y" && result.AppDownloadsId == undefined) {
            setAppDownloadsHelperText("required")
        }
        if (result.IsPermitDownload == "Y" && result.PermitDownloadsId == undefined) {
            setPermitDownloadsHelperText("required")
        }
        if (result.AppDownloadsId != undefined) {
            result.AppDownloadsGroup = buttonsettings.filter(a => a.Id == result.AppDownloadsId)[0].Module
        }
        if (result.PermitDownloadsId != undefined) {
            result.PermitDownloadsGroup = buttonsettings.filter(a => a.Id == result.PermitDownloadsId)[0].Module
        }
        if (!(result.IsApplicationDownload == "Y" && result.AppDownloadsId == undefined) && !(result.IsPermitDownload == "Y" && result.PermitDownloadsId == undefined)) {
            let url = getEnvVariable('AdminConfig') + `/api/SitePermitTypeTemplateSettings/` + params.Id + '/' + sitePermitTypeAppStatusId + `/AddOrUpdate`
            PostData(url, result).then(res => {
                // setAdd(false);
                // handleTransision();
            })
        }
    }

    const addOnsByName = { addOnsByName: { back: { onClick: closeTemplate }, save: { onClick: handleSave } } }
    const classes = useStyles();
    return (
        <Drawer anchor="right" open={sitePermitTypeAppStatusId ? true : false}
            disableEnforceFocus={true}
            disableAutoFocus={true}
            PaperProps={{ style: { width: fullscreen ? fullwidth : drawerWidth } }}
        >
            <div onMouseDown={e => handleMouseDown(e)} className={classes.dragger} />
            <Header {...TemplateSettingsheader} {...addOnsByName} />

            <div>
                <FormControl component="fieldset" className="col-sm-4 pl-3 pt-3">
                    {reload}

                    <DropDown name='AppDownloadsId' items={buttonsettings || []} label="Template" value={(result && result.AppDownloadsId) || null} //otherProps={label= "Template"}
                        error={appDownloadsHelperText ? true : false}
                        handleFieldValue={(e) => handleFieldChange(e, "add")} extraProps={{
                            transformProps: {
                                label: 'FileName',
                                value: 'Id'
                            }
                        }} />
                    {appDownloadsHelperText && <FormHelperText error={true}>{appDownloadsHelperText}</FormHelperText>}
                    <Switch label='Application Download' name="IsApplicationDownload" value={result && result.IsApplicationDownload || null} handleFieldValue={(e) => handleFieldValue(e, "add")} extraProps={{
                        transformProps: {
                            "Y": true,
                            "N": false,
                            true: "Y",
                            false: "N"
                        }
                    }} />

                    <DropDown name='PermitDownloadsId' items={buttonsettings||[]} label="Template" value={(result && result.PermitDownloadsId) || null}
                        handleFieldValue={(e) => handleFieldChange(e, "add")} extraProps={{
                            transformProps: {
                                label: 'FileName',
                                value: 'Id'
                            }
                        }} />
                    {permitDownloadsHelperText && <FormHelperText error={true}>{permitDownloadsHelperText}</FormHelperText>}
                    <Switch label='Permit Download' name="IsPermitDownload" value={result && result.IsPermitDownload || null} handleFieldValue={(e) => handleFieldValue(e, "add")} extraProps={{
                        transformProps: {
                            "Y": true,
                            "N": false,
                            true: "Y",
                            false: "N"
                        }
                    }} />
                </FormControl>
            </div>


        </Drawer>
    )
}