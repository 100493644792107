/**
      *********************************************************
          deprecated
      *********************************************************
**/

import React, { Component, Fragment } from 'react';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { requestApi as oldrequestApi } from './utilities'
import { HashRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import SubForm from './csubform'
import Typography from '@material-ui/core/Typography';
import SnackBar from '../viewport/snackbar'
import FormHeader from './cformheader'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { snackbarMessage } from '../../actions';
import InputLabel from '@material-ui/core/InputLabel';
import { createRequest, requestApi } from '../../controls/helpers/rest';

const checkboxtransformprops = {
    "N": false,
    "Y": true,
    "true": "Y",
    "false": "N"
}

class CFormMultiDualListBox extends Component {
    constructor(props) {
        super(props)
        console.log(props);
    }
    state = {
        firstboxselected: [],
        firstboxavailable: [],
        secondboxselected: [],
        secondboxavailable: [],
        firstboxresults: null,
        secondboxresults: null,
        currentfirstboxselecteditem: null,
        currentsecondboxselecteditem: null,
        changedFieldName: [],
        opensnackbar: false,
        snackbarmessageType: '',
        snackbarmessage: '',
        firstboxformresults: {},
        formControls: {}
    };

    onFirstBoxChange = (selected) => {
        const { firstboxselected } = this.state
        const { options, components } = this.props
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const firstboxtranformProps = firstboxoptions ? firstboxoptions.transformProps : null

        const { firstboxresults } = this.state
        const firstboxresultsavailable = firstboxresults[firstboxtranformProps.available.key]
        const firstboxresultsselected = firstboxresults[firstboxtranformProps.selected.key]
        let newselecteditems = []
        if (selected.length > firstboxselected.length) {
            const addeditemsvalue = selected.filter(each => {
                const val = firstboxselected.find(e => e === each)
                if (!val) return true
                else return false
            })

            firstboxresultsavailable.forEach(each => {
                const val = each[firstboxtranformProps.available.value]
                if (addeditemsvalue.find(e => e === val) !== undefined) {
                    const temp = { ...each, [firstboxtranformProps.selected.label]: each[firstboxtranformProps.available.label], [firstboxtranformProps.selected.value]: each[firstboxtranformProps.available.value] }
                    newselecteditems.push(temp)
                }
            })
            newselecteditems = firstboxresultsselected.concat(newselecteditems)
        }
        if (selected.length < firstboxselected.length) {
            firstboxresultsselected.forEach(each => {
                const val = each[firstboxtranformProps.selected.value]
                if (selected.find(e => e === val) !== undefined) {
                    newselecteditems.push(each)
                }
            })
        }
        console.log('previous selectd, new selected', firstboxresultsselected, newselecteditems)
        if (options.customsave) {
            this.setState({
                firstboxselected: selected,
                firstboxresults: { ...this.state.firstboxresults, [firstboxtranformProps.selected.key]: newselecteditems }
            }, () => {
                this.onhandleSave()
            })
        }
        else {
            this.setState({
                firstboxselected: selected,
                firstboxresults: { ...this.state.firstboxresults, [firstboxtranformProps.selected.key]: newselecteditems }
            })
        }
    };


    onSecondBoxChange = (selected) => {
        this.setState({
            secondboxselected: selected
        })
    }

    componentDidMount() {
        const { options, components, eventOn } = this.props
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const firstboxtranformProps = firstboxoptions ? firstboxoptions.transformProps : null
        const firstboxcrud = firstboxoptions ? firstboxoptions.crud : null
        const firstboxread = firstboxcrud ? firstboxcrud.read : null
        const firstboxget = firstboxread ? firstboxread.get : null
        //const firstboxrouteProps = firstboxget ? firstboxget.routeProps : null
        // id = firstboxrouteProps ? this.props.match.params[firstboxrouteProps.value] : null

        const { matchProps, routeProps, extraProps, customextraProps } = firstboxget ? firstboxget : {}

        let ids = [], id
        if (Array.isArray(routeProps)) {
            routeProps.forEach(each => {
                const { value } = each
                ids.push({ key: value, value: this.props.match.params[value] })
            })
        }
        else if (routeProps) {
            id = routeProps ? this.props.match.params[routeProps.value] : null
        }
        else if (matchProps) {
            if (Array.isArray(matchProps)) {
                // matchProps.forEach(each => {
                //     const { value } = each
                //     ids.push({ key: value, value: this.props.extraProps ? this.props.extraProps[matchProps[0].value] : null })
                // })
            }

            //if(results) id = results[matchProps.value]

        }
        if (extraProps) {
            id = this.props.extraProps[extraProps.value]
            ids.push({ key: extraProps.value, value: id })
        }
        if (customextraProps) {
            id = this.props.dataItem[customextraProps.customvalue]
            ids.push({ key: customextraProps.value, value: id })
        }
        if (eventOn) {
            var val = ''
            switch (eventOn) {
                case "APPLICATION_STATUS_CHANGE":
                    val = 'APPLICATION_STATUS';
                    break;
                case "FIELD_VALUE_CHANGE":
                    val = 'FIELD_VALUE';
                    break;
                case "PERMIT_STATUS_CHANGE":
                    val = 'PERMIT_STATUS';
                    break;
                case "WORKFLOW_STATUS_CHANGE":
                    val = 'WORKFLOW_STATUS';
                    break;
                case "RESOLUTION_STATUS":
                    val = 'RESOLUTION_STATUS';
                    break;
                default:
                    val = '';
                    break;
            }
            firstboxget.urlpath = firstboxget.urlpath + val;
            firstboxget && oldrequestApi(firstboxread, (ids.length > 0 ? ids : id)).then(results => {
                this.setState({
                    firstboxavailable: results[firstboxtranformProps.available.key].map(each => {
                        return { value: each[firstboxtranformProps.available.value], label: each[firstboxtranformProps.available.label] }
                    }),
                    firstboxselected: results[firstboxtranformProps.selected.key].map(each => {
                        // return { value: each[firstboxtranformProps.available.value], label: each[firstboxtranformProps.available.label] }
                        //return each[firstboxtranformProps.selected.value]
                        return { value: each[firstboxtranformProps.selected.value], label: each[firstboxtranformProps.selected.label] }
                    }),
                    firstboxresults: results
                })

            })
        }
        else {
            firstboxget && oldrequestApi(firstboxread, (ids.length > 0 ? ids : id)).then(results => {
                let tempfirstboxavailable = results[firstboxtranformProps.available.key].map(each => {
                    return { value: each[firstboxtranformProps.available.value], label: each[firstboxtranformProps.available.label] }
                })
                let tempfirstboxselected = results[firstboxtranformProps.selected.key].map(each => {
                    //return { value: each[firstboxtranformProps.available.value], label: each[firstboxtranformProps.available.label] }
                    //return { value: each[firstboxtranformProps.selected.value], label: each[firstboxtranformProps.selected.label] }
                    return { value: each[firstboxtranformProps.selected.value], label: each[firstboxtranformProps.selected.label] }

                })
                let tempselectedid = results[firstboxtranformProps.selected.key].map(each => {
                    //return { value: each[firstboxtranformProps.available.value], label: each[firstboxtranformProps.available.label] }
                    //return { value: each[firstboxtranformProps.selected.value], label: each[firstboxtranformProps.selected.label] }
                    return each[firstboxtranformProps.selected.value]
                })
                this.setState({
                    firstboxavailable: [...tempfirstboxavailable, ...tempfirstboxselected], firstboxselected: tempselectedid,
                    firstboxresults: results
                })
                // if (this.state.firstboxresults.SelectedCollection.length > 0) {
                //     for (let index = 0; index < this.state.firstboxresults.SelectedCollection.length; index++) {
                //         const element = this.state.firstboxresults.SelectedCollection[index];
                //         if (!element.name) {
                //             let elementName = this.state.firstboxresults.AvailableCollection.filter(s => s.id == element.id);
                //             if (elementName) {
                //                 element.name = elementName[0].name;
                //             }
                //         }
                //     }
                // }
            })
        }



        this.firstselectedbox.addEventListener('click', (event) => {
            const { firstboxresults, formControls, firstboxformresults } = this.state;
            let tempformControls = {}
            let tempchangedFieldName = []
            const selectedvalue = Number(event.target.value)
            const selecteditem = firstboxresults[firstboxtranformProps.selected.key].find(each => selectedvalue === each[firstboxtranformProps.selected.value])
            if (secondbox) {
                const secondboxoptions = secondbox ? secondbox.options : null
                const secondboxtranformProps = secondboxoptions ? secondboxoptions.transformProps : null
                const secondboxboxcrud = secondboxoptions ? secondboxoptions.crud : null
                const secondboxread = secondboxboxcrud ? secondboxboxcrud.read : null
                const secondboxget = secondboxread ? secondboxread.get : null
                const secondboxrouteProps = secondboxget ? secondboxget.routeProps : null
                const id = secondboxrouteProps ? this.props.match.params[secondboxrouteProps.value] : null
                const { ids } = secondboxget ? secondboxget : null
                let subids = [id]
                if (ids) {
                    ids.forEach(each => {
                        subids.push(selecteditem[each])
                    })
                }
                secondboxread && oldrequestApi(secondboxread, ids ? subids : id).then(results => {
                    this.setState({
                        secondboxavailable: results[secondboxtranformProps.available.key].map(each => {
                            return { value: each[secondboxtranformProps.available.value], label: each[secondboxtranformProps.available.label] }
                        }),
                        secondboxselected: results[secondboxtranformProps.selected.key].map(each => {
                            return each[secondboxtranformProps.selected.value]
                        }),
                        secondboxresults: results
                    })
                })
                this.secondselectedbox.addEventListener('click', (event) => {
                    console.log('second box')
                })
            }
            let selectedformresults = firstboxformresults[selecteditem.Id]
            let finalselecteditem = { ...selecteditem, ...selectedformresults }
            // Object.keys(selecteditem).forEach(each => {
            //  //   tempformControls[each] = { name: each, value: selecteditem[each], edit: true, error: false }
            //   //  tempchangedFieldName.push({ name: each, value: selecteditem[each] })
            // })
            firstbox.components.map(firstcomp => {
                tempformControls[firstcomp.options.name] = { name: firstcomp.options.name, value: finalselecteditem[firstcomp.options.name], edit: true, error: false }
                tempchangedFieldName.push({ name: firstcomp.options.name, value: finalselecteditem[firstcomp.options.name] })
            })
            this.setState({
                currentfirstboxselecteditem: finalselecteditem,
                changedFieldName: tempchangedFieldName,
                formControls: tempformControls
            })
        });
    }

    // changeResults = (objProperty, len, splitName, value) => {
    //     if (len === 0) return value
    //     else {
    //         const index = splitName.length - len
    //         const name = splitName[index]
    //         if (name) return { ...objProperty, [name]: this.changeResults(objProperty[name], --len, splitName, value) }
    //     }
    // }

    handleFirstBoxFieldChange = (event) => {
        const { name, value, checked, type } = event.target
        const { options, components, extraProps } = this.props
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const firstboxtranformProps = firstboxoptions ? firstboxoptions.transformProps : null
        let val
        if (type === 'checkbox') val = checkboxtransformprops[checked]
        else val = value
        const currentfirstboxselecteditem = this.state.currentfirstboxselecteditem
        console.log('multiduallist box------------------', currentfirstboxselecteditem)

        if (currentfirstboxselecteditem) {
            this.setState({
                changedFieldName: [{ name: name, value: val }],
                firstboxformresults: {
                    ...this.state.firstboxformresults, [currentfirstboxselecteditem[firstboxtranformProps.selected.value]]:
                    {
                        ...this.state.firstboxformresults[currentfirstboxselecteditem[firstboxtranformProps.selected.value]],
                        'AttachmentTypeId': currentfirstboxselecteditem[firstboxtranformProps.selected.value],
                        'Name': currentfirstboxselecteditem['Name'], [name]: val, ...extraProps
                    }
                }
            });
        }
        else {
            this.setState({
                changedFieldName: [{ name: name, value: val }],
                firstboxformresults: { ...this.state.firstboxformresults, [name]: val, ...extraProps }
            });
        }
    }

    onhandleSave = () => {
        const id = this.props.match.params.routeid
        const { options, components } = this.props
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const firstboxcrud = firstboxoptions ? firstboxoptions.crud : null
        const firstboxsave = firstboxcrud ? firstboxcrud.update : null
        const { firstboxresults, secondboxresults, firstboxformresults, firstboxselected } = this.state
        const firstboxformresultskeys = Object.keys(firstboxformresults)
        const savefirstboxresults = firstboxformresultskeys.map(each => {
            return firstboxformresults[each]
        })

        const { matchProps, customextraProps } = firstboxsave.post || {}
        let ids = []
        matchProps && matchProps.forEach(each => {
            const { value } = each
            ids.push({ key: value, value: this.props.extraProps[value] })
        })
        customextraProps && customextraProps.forEach(each => {
            const { value, customvalue } = each
            ids.push({ key: value, value: this.props.dataItem[customvalue] })
        })

        if (firstboxsave && !options.customsave) {
            let newurl = firstboxsave.post.url + firstboxsave.post.urlpath
            ids.forEach(each => {
                const { key, value } = each;
                newurl = `${newurl.replace(`{${key}}`, value)}`;
            })
            let saveurl = {
                url: options.replaceidvalue ? newurl : firstboxsave.post.url,
                "matchProps": [{
                    name: "Id",
                    key: "Id",
                }],
                type: 'post',
            }
            //  requestApi(saveurl, ids, savefirstboxresults).
            requestApi(createRequest(saveurl, savefirstboxresults, (id || ids))).then(results => {
                //    console.log('after save---', results);
                //    this.props.snackbarMessage('Saved Successfully', 'success')
            }).catch(ex => {
            })
        } else if (options.customsave) {
            this.props.customnoticesave(firstboxselected, options.customsaveref)
        }
    }


    handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ opensnackbar: false });
    };


    render() {
        const { firstboxselected, firstboxavailable, secondboxselected, secondboxavailable, currentfirstboxselecteditem } = this.state
        const { options, components, compmap } = this.props
        const { multiboxes, save, title } = options ? options : {}
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const secondboxoptions = secondbox ? secondbox.options : null
        const firstboxprops = {}, secondboxprops = {}
        if (multiboxes) {
            if (firstboxoptions) {
                firstboxprops.width = firstboxoptions.width || 6
                firstboxprops.helperText = firstboxoptions.helperText || ''
                firstboxprops.name = firstboxoptions.name || 'firstbox'
            }
            if (secondboxoptions) {
                secondboxprops.width = secondboxoptions.width || 6
                secondboxprops.helperText = secondboxoptions.helperText || ''
                secondboxprops.name = secondboxoptions.name || 'secondbox'
            }
        }
        else {
            firstboxprops.width = firstboxoptions.width || 6
            firstboxprops.helperText = firstboxoptions.helperText || ''
            firstboxprops.name = firstboxoptions.name || 'firstbox'
        }
        const { opensnackbar, snackbarmessage, snackbarmessageType } = this.state
        const { headerProps, designprops } = options
        const { savebutton, closebutton } = headerProps ? headerProps : {}

        return (
            <Fragment>
                {headerProps && <FormHeader title={title} handleSave={this.onhandleSave} savebutton={savebutton} handleClose={this.props.onClose} closebutton={closebutton} designprops={designprops} />}
                <div className="row mt-3">
                    {options.helpertitle && <InputLabel className="ml-3" shrink={true}> {options.helpertitle}   </InputLabel>}
                    {firstbox && <div className={"col-" + firstboxprops.width}>
                        <DualListBox
                            options={firstboxavailable}
                            selected={firstboxselected}
                            onChange={this.onFirstBoxChange}
                            selectedRef={(element) => { this.firstselectedbox = element }}
                            showHeaderLabels={true}
                            icons={{
                                moveLeft: '<',
                                moveAllLeft: '<<',
                                moveRight: '>',
                                moveAllRight: '>>'
                            }}
                        />
                        <Typography>
                            {firstboxprops.helperText} <span className="text-primary">{currentfirstboxselecteditem && currentfirstboxselecteditem.Id && currentfirstboxselecteditem.Name}</span>
                        </Typography>
                        {firstbox.components && <SubForm {...firstbox} compmap={compmap} changedFieldName={this.state.changedFieldName} handleFieldChange={this.handleFirstBoxFieldChange} results={this.state.formControls} res={this.state.currentfirstboxselecteditem} />}
                    </div>}
                    {secondbox && <div className={"col-" + secondboxprops.width}>
                        <DualListBox
                            options={secondboxavailable}
                            selected={secondboxselected}
                            onChange={this.onSecondBoxChange}
                            selectedRef={(element) => { this.secondselectedbox = element; }}
                            showHeaderLabels={true}
                            icons={{
                                moveLeft: '<',
                                moveAllLeft: '<<',
                                moveRight: '>',
                                moveAllRight: '>>'
                            }}
                        />
                        <Typography>
                            {secondboxprops.helperText}
                        </Typography>
                        {secondbox.components && <SubForm {...secondbox} compmap={compmap} changedFieldName={this.state.changedFieldName} handleFieldChange={this.handleSecondBoxFieldChange} />}
                    </div>}
                </div>
                {/* {
                    save && <div className="row">
                        {save && <div className="ml-4 mb-3 pt-2"> <Button variant="contained" onClick={this.onhandleSave} color="secondary" > Save</Button></div>}
                    </div>

                } */}
                {
                    <SnackBar messageType={snackbarmessageType} message={snackbarmessage} open={opensnackbar} handleSnackBarClose={this.handleSnackBarClose} />
                }

            </Fragment>
        )
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

CFormMultiDualListBox = connect(null, mapActions)(CFormMultiDualListBox)

export default withRouter(CFormMultiDualListBox)

