import { getEnvVariable } from '../../modules/environmentalList';
import newsavedreportstypes from '../reports/savedreports';
import standarddescriptionofconstruction from './standarddescriptionofconstruction';


const StandardDescConsgrid = {
    "type": "descofconsgrid",
    "key": "descofconsgrid",
    "gridkey": 'descofconsgrid',
    "order": 1,
    "options": {
        sidepopupgridheight: true,
        "routes": [
            {
                "route": "/Administration/applicationtypes",
                "routeParams": ":routeid",
                exactRoute: true
            },
            {
                "route": "/descofconsgrid",
            },

        ],
        "crud": {
            "update": {
                "link": [
                    {
                        "link": "/Administration/applicationtypes",
                        "matchProps": { value: "routeid" },
                    },
                    {
                        "link": "/descofconsgrid/view",
                        "matchProps": { value: "Id" }
                    }
                ]
            },
            "read": {
                "get": {
                    "url": getEnvVariable('AdminConfig')+`/api/SitePermitTypeDescriptionOfConstruction/{Id}/Grid`,
                    "customProps": {
                        "value": "Id"
                    }
                }
            }
        },
        "back": {
            "routes": [
                {
                    "route": "/Administration/applicationtypes",
                    "routeParams": "routeid",
                },
            ],
        },
        "sidebar": true,
        nonautoopen: true,
        "formgrid": true,
        
        headerProps: {
            customheader: true,
            headerProps:{
            //designprops: true,
            kendogridprops: true,
            title: "Standard Description Of Construction",
            actions: [
                {
                    type: 'fab',
                    icon: 'clear',
                    name: 'clear',
                    color: 'primary',
                    title: 'Clear Filters',
                    menucolor: '#22a9b3'
                },

                {
                    type: 'menu',
                    icon: 'excel',
                    name: 'excel',
                    color: 'primary',
                    title: 'Export to Excel',
                    menucolor: '#c90685',
                    items: [{
                        name: 'Export Current Page',
                        action: 'filtercurrent'
                    },
                    {
                        name: 'Export All Pages',
                        action: 'filterall'
                    }
                    ]
                },
                {
                    type: 'fab',
                    icon: 'add',
                    name: 'add',
                    color: 'primary',
                    title: 'Add Standard Description Of Construction ',
                    menucolor: '#36088a'
                },

            ]
        }
        },
       
        savedreports: {
            ...newsavedreportstypes
        },
        "components": [
            standarddescriptionofconstruction
        ],
        "addcomponents": [
            standarddescriptionofconstruction
        ],
        "gridcolumns": [
          
            {
                "title": "Subject",
                "field": "Subject",
                "show": true
            },
            {
                "title": "Rank",
                "field": "Rank",
                "show": true
            },
            {
                "title": "Custom",
                "field": "Custom",
                "show": true
            },
        ]
    }
}
export default StandardDescConsgrid