import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { getEnvVariable } from '../../../modules/environmentalList';
import { asyncgetData, GetData } from '../helpers/requests';
//import { getEnvVariable } from '../../../modules/environmentalList';

export const getOptions = (data, columns, dataState, url) => {
    let gridoptions = {
        excel: {
            fileName: "Excel.xlsx",
            allPages: true,
            // filterable: true
        },
        withdatastateformat: dataState,
        dataSource: {
            type: "webapi",
            transport: {
                read: {
                    url: url + '?',
                    datastate: `${toDataSourceRequestString(dataState)}`,
                    // headers: this.headers
                }
            },
            change: () => {
            },
            pageSize: 20,
            total: data.length,
            skip: 0,
            take: 20,
            serverPaging: true,
            serverSorting: true,
            serverFiltering: true,
            serverGrouping: true,
            serverAggregates: true,
            schema: {
                data: "data",
                total: "total",
                errors: "errors"
            }
        },
        // change: () => this.onChange(),
        pageable: {
            refresh: true,
            buttonCount: 5,
            pageSizes: [10, 20, 30, 40, 50, 100],
        },
        //dataBound: () => this.onLoad(),
        selectable: 'single row',
        columnMenu: true,
        sortable: true,
        resizable: true,
        scrollable: true,
        reorderable: true,
        columns: (columns || []).filter(each => {
            if (each.show)
                return { ...each, width: each.width || 200, filterable: each.filterable || { multi: true, search: true } }
        }),
        noRecords: {
            template: "<p class='lead'>No data available.</p>"
        },
    }
    return gridoptions
}

// export const loadReports = async (id, readurl, matchparams) => {
//     let existingReportsURL = getEnvVariable('Report')+`/Reports/Report?id=${id}`
//     let otherrequest
//    await getData(existingReportsURL).then(res => {
//         let anotherrequest
//         if (res) {
//             let opts = JSON.parse(res.ReportJsonQueryStr)
//             let readurlvalue = readurl[0]
//             let gridurl = readurlvalue['gridreporturl']
//             let ids = []
//             readurlvalue['matchProps'].forEach(each => {
//                 const { key, name } = each
//                 ids.push({ key: key, value: matchparams[name] })
//             })
//             ids.forEach(each => {
//                 const { key, value } = each;
//                 gridurl = `${gridurl.replace(`{${key}}`, value)}`;
//             })
//             let url = gridurl;
//             let queryStr = opts.dataSource.transport.read.datastate || null;
//             if (queryStr)
//                 url = `${url}${queryStr}`
//             anotherrequest = {
//                 load: {
//                     rules: [{
//                         run: {
//                             valid: [{
//                                 type: 'grid',
//                                 "read": [
//                                     {
//                                         url: url,
//                                         "matchProps": [{
//                                             name: "routeid1",
//                                             key: "routeid1",
//                                         }],
//                                         type: 'get',
//                                         saveAs: {
//                                             key: 'data'
//                                         }
//                                     }
//                                 ]
//                             },
//                             ]
//                         }
//                     }]
//                 }
//             }

//         }
//         otherrequest = anotherrequest
//     }
//     )
//     return otherrequest
// }

const loadreportinfo = async (Id) => {
    let existingReportsURL = getEnvVariable('Report') + `/api/Reports/Report?id=${Id}`
    let datastate;
    let columnsdata
    await GetData(existingReportsURL, false, '', true).then(res => {
        if (res) {
            let opts = JSON.parse(res.ReportJsonQueryStr)
            let newdatastateval = opts.withdatastateformat
            columnsdata = opts.columns
            console.log(newdatastateval)
            datastate = newdatastateval
        }
        else {
            datastate = { skip: 0, take: 20 }
            columnsdata = null
        }
    })
    return { datastate, columnsdata }
}

export const loadreportdata = async (gridkey, Id) => {
    let existingReportsURL = getEnvVariable('Report') + `/api/Reports/Default/${gridkey}`
    let datastate
    if (Id) {
        datastate = await loadreportinfo(Id)
    }
    else {
        await asyncgetData(existingReportsURL, true).then(async res => {
            if (res && res.Id > 0) {
                datastate = await loadreportinfo(res.Id)
            } else {
                datastate = (localStorage.getItem(gridkey) && JSON.parse(localStorage.getItem(gridkey))) || { skip: 0, take: 20 }
            }
        })
    }
    return datastate
}