import { getEnvVariable } from "../../modules/environmentalList";

const map = {
    "type": "map",
    "key": "EPS_map_ADD",
    "order": 32,
    "options": {        
        "mapkey":"EPS_map_ADD",
        "routes": {
            "route": "/"
        },
        "hastaxlotlayer":"N",
        "alignitem": "left",
        "widgets": ["Home", "Search", "Zoom", "Sketch", "Select", "BasemapGallery", "LayerList","Measurement","TaxlotSelector", "BufferCalc", "Print","LayerFilter","DatTable"], // ["Locate", "Home", "Search", "Zoom", "Measurement", "Draw", "Sketch", "BasemapGallery", "LayerList", "Bookmark", "SearchBookmark"], 
        "basemap": "streets",//"streets", "satellite", "hybrid", "terrain", "topo", "gray", "dark-gray", "oceans", "national-geographic", "osm", "dark-gray-vector", "gray-vector", "streets-vector", "topo-vector", "streets-night-vector", "streets-relief-vector", "streets-navigation-vector"
        "viewProperties": {
            // "extent": {
            //     "xmax": -8057114.558988379,
            //     "xmin": -8679311.969229413,
            //     "ymax": 4872179.908902848,
            //     "ymin": 4605567.554244282,
            //     "spatialReference": 102100 
            // },
            "zoom": 12,
            "center": [-8368213.264108896,4738873.731573565],
            "ui": {
                "components": ["attribution"]// ["compass", "attribution"] // empty the UI, except for attribution
            }
        },
        "printOptions":{
            "serviceURL":"http://development.delasoft.com:8443/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task"         
        },
        "searchOptions": {
            "State": "OR"
        },
        "zoomOptions": {
            "maxZoomed": false,
            "minZoomed": false
        },
        "drawOptions": {
            "Tools": ["point", "polyline", "polygon", "rectangle", "circle"],//["point", "polyline", "polygon", "rectangle", "circle"]
            "AllowMultiple":{"Points":false, "Lines":false,"Polygons":false},
            "LayerIndex": 1
        },
        'searchbookmarks':{
            "drawTool":' Hello I ma me',
            "url":"http://development.delasoft.com:9443/arcgis/rest/services/AWS_KEPT_Test/Bookmarks/FeatureServer/0",
            "defaultChoice":'E',
            "defaultName":'Bookmark #',
            "defaultSymbol":{
                type: "simple-fill",
                outline: {
                    style: "dash",
                    miterLimit: 16,
                    color: [0, 112, 255, 1]
                },
                color: [115, 223, 255, 0.24]
            },
            "defaultMessage":'Draw Box on Map for Bookmark',
            'appname':'INQUERY',
            'username':'13384'
        },
        'tasks': {
            "TaxParcels": {
                "url": "",
                "queryExpression": "1!=1",//PIN = '433-6.18-12.00' //"queryExpression": "PIN = '433-6.18-12.00'",
                "LayerIndex":0
            }
        },
        "LayerOptionsConfig":
        {
            "Category": ["F"],
            "MapType": ["A","R"]   //A-Add Record,L-Landing,G-Grid,R-Record review               
        },  
        "LayerOptions":[
         ],
        "FeatureLayerListOptions":[],
        "CountyLayerOptions":[ ],
        "FeatureLayers": {
            "POINT": getEnvVariable('ArcGIS')+`/rest/services/EPS/MasterTable_Geom_Service/FeatureServer/0`,
            "MULTIPOINT": getEnvVariable('ArcGIS')+`/rest/services/EPS/MasterTable_Geom_Service/FeatureServer/1`,
            "LINE": getEnvVariable('ArcGIS')+`/rest/services/EPS/MasterTable_Geom_Service/FeatureServer/2`,
            "POLYGON": getEnvVariable('ArcGIS')+`/rest/services/EPS/MasterTable_Geom_Service/FeatureServer/3`,
            "LayerIndex":2//For Adding GraphicsLayer
        },
        "MasterLookupFields":[],
        "FieldsData": [],
        "MapSettings": {},
        "MultipleLocationData":[], // Specific to KEPT project for stroing the multiple location data, if any.
        "UserId":"1"
    }
};

export default map;