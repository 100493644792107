/**
      *********************************************************
      Currenlty not used anywhere
      *********************************************************
**/

import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const CLink = (props) => {

    const { options, type, handleFieldChange, error, value, touched, dropdownvalues, effect, ...otherProps } = props;
    const { text, name } = options;
    let url = window.location.href
    return (
        <Fragment>
            {
                type === 'link' && <Link to='#' component={RouterLink} onClick={handleFieldChange} name={name} {...otherProps}>
                    {text}
                </Link>
            }
            {
                type === 'routelink' && <a href={url.split('#')[0]}>{text}</a>
            }
            {
                type === 'navlink' && <Link to='#' component={RouterLink} onClick={handleFieldChange} name={name} {...otherProps}>
                    {text}
                </Link>
            }
        </Fragment>

    )


}

export default CLink;

/**
 * {
 *  type:'txtlink',
 * text:'alarm',
 * options:{
 *
 * },
 * mobileoptions:{
 * },
 * suboptions:{
 * href:''
 * }
 * }
 */