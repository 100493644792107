import { getEnvVariable } from "../../../modules/environmentalList"
import HolidayAdd from "./newholidays"
//import notehistory from './notehistorygrid';
const EditHoliday = {
    "type": "popup",
    "key": "EditHoliday",
    "name": "EditHoliday",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'editOpen'
    },
    sharedValues: ['open'],
    container: {
        closepopup: true,
        closepopupval: 'holidays',
        "addRoute": true,
        "routeParams": { replace: 'holidays', param: 'Id', routeName: 'id' },
        'removelayout': 'EditHoliday',
        values: { editInfo: null, edit: false, createduplicate: false, fullscreen: false, back: false, allgriddata: null, navigation: null },
        mapValues: {
            header: [
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'createduplicate', name: 'createduplicate' },
                { key: 'allgriddata', name: 'allgriddata' },
            ],
            ViewHoliday: [
                { key: 'editInfo', name: 'editInfo' },
                { key: 'allgriddata', name: 'allgriddata' },
                { key: 'save', name: 'save' },
                // { key: 'saveduplicate', name: 'saveduplicate' },
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'createduplicate', name: 'createduplicate' },
                { key: 'back', name: 'back' },
                { key: 'navigation', name: 'navigation' },]
        },
        //  sharedValues: ['save', 'edit'],
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },
            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'ViewHoliday',
                    type: 'div',
                    className: 'col-12 pl-4 m-4',
                    sub: {
                        type: 'div',
                        className: 'col-12 pl-4 m-4',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Holidays',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "View/Edit Holiday", "materialicon": "today", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },

                    {
                        type: 'icon',
                        icon: 'edit',
                        name: 'edit',
                        hideon: 'createduplicate',
                        hideanother: 'edit',
                        hidevalue: true,
                        title: 'Edit Holiday'
                    },
                    // {
                    //     type: 'icon',
                    //     icon: 'duplicate',
                    //     name: 'createduplicate',
                    //     hideon: 'edit',
                    //     hideanother: 'createduplicate',
                    //     hidevalue: true,
                    //     title: 'Clone'
                    // },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'edit',
                        hidevalue: false,
                        title: 'Save Holiday',
                        color:"#008000"
                    },
                    // {
                    //     type: 'icon',
                    //     icon: 'save',
                    //     name: 'saveduplicate',
                    //     hideon: 'createduplicate',
                    //     hidevalue: false,
                    //     title: 'Save'
                    // },
                    {
                        type: 'icon',
                        icon: 'previtem',
                        name: 'previtem',
                        hideon: 'previtem',
                        hidevalue: false,
                        title: 'Previous Item'
                    },
                    {
                        type: 'icon',
                        icon: 'nextitem',
                        name: 'nextitem',
                        hideon: 'nextitem',
                        hidevalue: false,
                        title: 'Next Item'
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hidevalue: false,
                        title: 'Full Screen'
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false,
                        title: 'Exit FullScreen'
                    },
                    {
                        type: 'icon',
                        icon: 'history',
                        name: 'history',
                        title: 'History',
                        hideon: 'history',
                        hidevalue: false,
                        historyurl: {
                            title : 'Holiday History',
                             url: getEnvVariable('Admin') + `/api/Holidays/history/{Id}?`,
                            //"url":"http://localhost:6004/api/Holidays/{Id}?",
                            "routeProps": [{ name: "id", key: "Id", },],
                            type: 'get',
                            saveAs: { key: 'data' }
                        }
                    },
                ],
            },
            ViewHoliday: {
                "type": "form",
                initRun: true,
                "key": "ViewHoliday",
                "name": "ViewHoliday",
                aliases: {
                    'editInfo': 'editInfo',
                    'allgriddata': 'allgriddata',
                    'navigateItem': 'navigateItem',
                    'edit': 'edit'
                },
                sharedValues: ['editInfo', 'allgriddata', 'navigateItem', 'navigation', 'edit', 'save', 'createduplicate', 'exitfullscreen', 'fullscreen', 'back'],
                validationMapKeys: [],
                layout: {
                    group: [
                        // {
                        //     order: 0,
                        //     type: 'div',
                        //     className: 'row',
                        //     components: [{
                        //         name: 'header',
                        //         type: 'div',
                        //         className: 'col-12 ',
                        //         sub: {
                        //             type: 'div',
                        //             className: 'col-12',
                        //         }
                        //     }],
            
                        // },            
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'HolidayDay',
                                type: 'div',
                                className: 'col-10 m-1',
                                sub: {
                                    type: 'div',
                                    className: 'col-10 m-1'
                                }
                            },

                            ]
                        },
                        {
                            order: 1,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'HolidayDate',
                                    type: 'div',
                                    className: 'col-6 m-1',
                                    sub: {
                                        type: 'div',
                                        className: 'col-6 m-1'
                                    }
                                },
                            ]
                        },
                        {
                            order: 2,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Status',
                                type: 'div',
                                className: 'col-10 m-1',
                                sub: {
                                    type: 'div',
                                    className: 'col-10 m-1'
                                }
                            },
                            ]
                        },
                        {
                            order: 3,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'CreatedOn',
                                type: 'div',
                                className: 'col-6',
                                sub: {
                                    type: 'div',
                                    className: 'col-6'
                                }
                            },
                            {
                                name: 'CreatedByName',
                                type: 'div',
                                className: 'col-6',
                                sub: {
                                    type: 'div',
                                    className: 'col-6'
                                }
                            },
                            ]
                        },
                        {
                            order: 4,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'UpdatedOn',
                                    type: 'div',
                                    className: 'col-6',
                                    sub: {
                                        type: 'div',
                                        className: 'col-6'
                                    }
                                },
                                {
                                    name: 'UpdatedByName',
                                    type: 'div',
                                    className: 'col-6',
                                    sub: {
                                        type: 'div',
                                        className: 'col-6'
                                    }
                                },]
                        },
                    ]

                },
                values: {
                    HolidayDay: '',
                    HolidayDate: '',
                    Status: '',
                    UpdatedByName: '',
                    CreatedByName: '',
                    UpdatedOn: '',
                    CreatedOn: '',
                    UpdatedBy: '',
                    CreatedBy: '',
                    Id: '',
                    editInfo: null,
                    allgriddata: null,
                    navigation: null
                },
                "components": {
                    // Id: {
                    //     "type": "textfield",
                    //     "key": "Id",
                    //     "label": "Id",
                    //     "name": "Id",
                    //     // changefieldto: "textfield",
                    // },
                    HolidayDay: {
                        "type": "label",
                        "key": "HolidayDay",
                        "label": "Day",
                        "name": "HolidayDay",
                         changefieldto: "textfield",
                    },
                    HolidayDate: {
                        "type": "label",
                        "key": "HolidayDate",
                        "label": "Date",
                        "name": "HolidayDate",
                        changefieldto: "datemonth",
                    },
                    Status: {
                        "type": "label",
                        "key": "Status",
                        "label": "Status",
                        "name": "Status",
                        changefieldto: "toggle",
                        extraProps: {
                            "transformProps": {
                                "A": true,
                                "I": false,
                                "true": "A",
                                "false": "I"
                            }
                        },
                        LabeltransformProps: {
                            I: 'In-Active',
                            A: 'Active',
                            true: 'Active',
                           false: 'In-Active',
                        },
                        
                    },
                    CreatedOn: {
                        "type": "label",
                        "key": "CreatedOn",
                        "label": "Created On",
                        name: "CreatedOn",
                        changefieldto: "label",
                    },
                    CreatedByName: {
                        "type": "label",
                        "key": "CreatedByName",
                        "label": "Created By",
                        name: "CreatedByName",
                        changefieldto: "label",
                    },
                    UpdatedOn: {
                        "type": "label",
                        "key": "UpdatedOn",
                        "label": "Updated On",
                        name: "UpdatedOn",
                        changefieldto: "label",
                    },
                    UpdatedByName: {
                        "type": "label",
                        "key": "UpdatedByName",
                        "label": "Updated By",
                        name: "UpdatedByName",
                        changefieldto: "label",
                    },
                },
                mapActionsToEffects: {
                    'onLoad': {
                        'init': {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'validations', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                            "read": [
                                                {
                                                    url: getEnvVariable('Application') + `/api/ProjectMessages/RulesByName?name=WRSadmin.holidays`,
                                                    //"url":"http://localhost:6004/api/ProjectMessages/RulesByName?name=AMESADMIN.GroupEmails",
                                                    type: 'get'
                                                }
                                            ],
                                            transformProps: {
                                                removesubfieldname: 'WRSADMIN.HOLIDAYS_'
                                            }
                                        },
                                        {
                                            type: 'businessrules',
                                            "read": [
                                                {
                                                     url: getEnvVariable('Application') + `/api/BusinessRules/Grid/WRSADMIN.Holidays`,
                                                    //url:"http://localhost:6004/api/BusinessRules/Grid/AMESADMIN.GroupEmails",
                                                    type: 'get',
                                                }
                                            ],
                                        },
                                    ]
                                }
                            }]
                        }
                    },
                    'onChange': {
                        'navigation': {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'initial', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                            "read": [
                                                {
                                                    url: getEnvVariable('Admin') + `/api/Holidays/{Id}`,
                                                    //"url":"http://localhost:6004/api/ZEmailGroups/{Id}",
                                                    key: 'save',
                                                    "matchProps": [
                                                        {
                                                            name: "routeid1",
                                                            key: "routeid1",
                                                        },
                                                        {
                                                            name: "navigation.Id",
                                                            key: "Id",
                                                        }
                                                    ],
                                                    type: 'get',
                                                }
                                            ]
                                        },
                                    ]
                                }
                            }]
                        },
                        'edit': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'edit',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'exitfullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'exitfullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'fullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'fullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'createduplicate': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'createduplicate',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    },
                    'afterSave': {
                        'saveduplicate': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',
                                                "read": [
                                                    {
                                                        url: getEnvVariable('Admin') + `/api/Holidays/Add`,
                                                        //"url":"http://localhost:6004/api/Holidays/Add",
                                                        type: 'post',
                                                        "matchProps": [{
                                                            name: "routeid1",
                                                            key: "routeid1",
                                                        }]
                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },

                        'save': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',// http://development.delasoft.com:8443/${getEnvVariable('ModuleKey')}-application/api/Property/23123/Add
                                                "read": [
                                                    {
                                                        url: getEnvVariable('Admin') + `/api/Holidays/Update`,
                                                        //"url":"http://localhost:6004/api/Holidays/Update",
                                                        type: 'put',
                                                        "matchProps": [{
                                                            name: "routeid1",
                                                            key: "routeid1",
                                                        },
                                                        {
                                                            name: "Id",
                                                            key: "Id",
                                                        }
                                                        ]
                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    },

                }
            }
        },

    }
}

export default EditHoliday
