import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import withWidth from '@material-ui/core/withWidth';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getEnvVariable } from '../../../modules/environmentalList';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
    },
    icon: {
        //marginRight: theme.spacing(0.5),
        width: 25,
        height: 22,
        paddingTop: 4

    },
}));

const useStyleLink = makeStyles((theme)=> ({
    link:{
        display:'flex',
    },
    icon:{
        display:'flex',
        justifyContent:'center',
        paddingBottom:3,
        width: 25,

    }
}));

function BreadCrumbs(props) {

    const CSSColorClasses = (style = '') => {
        let module = getEnvVariable('ModuleKey')
        switch (module) {
            case 'WRS':
                return '#0076A5'
            case 'FORWARD':
                return '#074078'
            case 'UPS':
                return '#337ab7'
            // case 'AMES':
            //     return 'rgb(41,51,82) !important'
            default:
                return '#6200ea';
        }
    }

    const classes = useStyles();
    const classes2 = useStyleLink();
    return <Breadcrumbs aria-label="breadcrumb" className={props.className + ' ' || ''} style={{ padding: '0px' }}>
        {props.items && props.items.map((e, i) => {
            if (!e.ishide) {
                if (e.tab)
                    localStorage.setItem('tab', e.tab);
                let path = ''
                if (e.href && e.href.routes) {
                    if (Array.isArray(e.href.routes)) {
                        e.href.routes.forEach(each => {
                            const { route, routeParams, matchProps } = each
                            if (routeParams) {
                                path += route + "/" + props.match.params[routeParams]
                            }
                            else if (matchProps) {
                                path += route + "/" + props.results && props.results[matchProps]
                            }
                            else {
                                path += route
                            }
                        })
                    }
                }
                return (e.href) ? <Link href={path !== '' ? path : e.href} className={classes.link + e.className} style={{ color: e.color ? 'white' : '' }}         >
                    {e.materialicon && <Icon size='small' className={classes.icon} style={{ fontSize: e.fontSize || '20px' }} >{e.materialicon}</Icon>}
                    {e.link} {
                        props.moreicon && <IconButton aria-haspopup="true" onClick={props.handleBreadcrumbMenu} >
                            <ExpandMoreIcon className={classes.icon} style={{ color: e.color ? e.color : CSSColorClasses() }} />
                        </IconButton>
                    }
                </Link> :
                    <Typography className={classes2.link} style={{ color: (e.class === 'text-light') ? 'white' : (e.color || CSSColorClasses()) }}>
                        {e.materialicon && <Icon size='small' className={classes2.icon} style={{ fontSize: e.fontSize || '20px' }}>{e.materialicon}</Icon>}
                        {e.link}
                    </Typography>


            }

        })}
    </Breadcrumbs >

}

export default withWidth()(withRouter(BreadCrumbs));