  /**        
        *********************************************************
            deprecated
        *********************************************************
 **/  
export function delcomp(components,items) {
    return  components.filter(each => {
        const founditem = items.find(e => {
            return e.key === each.key
        })
        if (!founditem) return true
        else return false
    })
}

export function addcomp(components,items) {
    return components.concat(items)
}


export function updatecomp() {

}