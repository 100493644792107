/**
      *********************************************************
      Description: Handles grid component.
      Required props:  type.
      limitations    : just displays the grid data. on double click on the row changes the route.
      *********************************************************
**/

import { Divider, Icon } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ColorIcon from '@material-ui/icons/Brightness1';
import ClearIcon from '@material-ui/icons/Clear';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/GetApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { groupBy, process, toDataSourceRequestString, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import {
    setExpandedState,
    setGroupIds
} from "@progress/kendo-react-data-tools";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { number } from 'mathjs';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getEnvVariable } from '../../../modules/environmentalList';
import { setRecordData, snackbarMessage } from '../../actions';
import { getOptions } from '../../controls/components/savegridreport';
import { downloadNoticeData } from '../../controls/helpers/actions';
import { GetData } from '../../controls/helpers/requests';
import { requestApi as newRequestApi } from '../../controls/helpers/rest';
import { handlecustomCondition } from '../../modules/form/conditions';
import { handlePrimaryCheck } from '../../modules/form/customlocationvalidation';
import FormHeader from '../form/cformheader';
import { requestApi } from '../form/utilities';
import { getToken } from '../header';
import Dialog from '../report/dialog';
import GridHeader from './cgridheader';
import { CustomColumnMenu } from './customColumnMenu.jsx';
const _ = require('lodash');


const processWithGroups = (data, group) => {
    const newDataState = groupBy(data, group);
    setGroupIds({
        data: newDataState,
        group: group,
    });
    return newDataState;
};


class CGrid extends Component {
    constructor(props) {
        super(props);
        this.token = getToken()
        this.state = {
            dataState: this.props.dataState || { skip: 0, take: 20 },
            data: this.props.useComp ? this.props.data.slice(0, 20 + 0) : [],
            total: !this.props.useComp ? 0 : (this.props.total || this.props.data.length),
            exportdata: false,
            columns: this.props.options && this.props.options.gridcolumns,
            pageable: {
                buttonCount: 2,
                info: true,
                type: 'numeric',
                pageSizes: [10, 20, 30, 40, 50, 100],
                previousNext: true
            },
            passDataItem: null,
            presentItemIndex: null,
            available: false,
            rowselected: null,
            setMinWidth: false,
            gridCurrent: 0,
            hasDefaultReport: false,
            anchorEl: null,
            filteredfield: [],
            rowrenderedslide: false,
            initialloadextrainfo: null,
            collapsedState: [],

        };
    }
    minGridWidth = 0;
    /**
      *********************************************************
      Description: Handles the double click on the grid row. Update prop on the json is used to generate the link and change the route
      Required props:  grid needs to have data.
      limitations    : only double click event is handled.
      *********************************************************
**/

    //group start
    handleGroupChange = (event) => {
        const newDataState = processWithGroups((this.props.data || []), event.group);
        this.setState({
            result: newDataState,
            group: event.group,
        });
    };

    expandChange = (event) => {
        const item = event.dataItem;

        if (item.groupId) {
            const newCollapsedIds = !event.value
                ? [...this.state.collapsedState, item.groupId]
                : this.state.collapsedState.filter(
                    (groupId) => groupId !== item.groupId
                );
            this.setState({
                collapsedState: newCollapsedIds,
            });
        }
    };
    //group end


    rowRender = (trElement, dataItem) => {
        const { options, compmap, accessPermissions, accessLevelPermissions } = this.props
        const { crud, sidebar, components, stopRowClick, custompropsvalue } = options
        const { create, update, read } = crud || {}
        //    const del = crud.delete
        const nocolor = { fontWeight: "normal", fontStyle: 'normal' };
        const green = { fontWeight: "bold", fontStyle: 'italic' };

        const trProps = {
            ...trElement.props,
            style: this.state.rowselected && ((dataItem.dataItem === this.state.rowselected) || (dataItem === this.state.rowselected) || (dataItem && dataItem.dataItem && dataItem.dataItem.Id && dataItem.dataItem.Id === this.state.rowselected.Id)) ? green : nocolor,
            onDoubleClick: () => {
                if (stopRowClick) {
                    return;
                }
                if (sidebar) {
                    let checkindexeddata = this.state.data
                    let checkindexdata = Array.isArray(checkindexeddata) ? checkindexeddata : checkindexeddata.data
                    let idx = checkindexdata.indexOf(dataItem.dataItem);
                    if (localStorage.getItem('griddataItem') !== null) {
                        localStorage.removeItem('griddataItem')
                    }
                    // if (this.props.location.pathname === '/Administration/applicationtypes') {
                    //     localStorage.setItem('griddataItem', JSON.stringify(dataItem.dataItem));
                    // }
                    if (this.props.custompropsvalue || custompropsvalue) {
                        let pathname = this.props.location.pathname
                        this.props.match.params.Id = dataItem[this.props.custompropsvalue || custompropsvalue] || dataItem.dataItem[this.props.custompropsvalue || custompropsvalue]
                        pathname = pathname + '/' + this.props.match.params.Id;
                        this.props.history.push(pathname);
                    }
                    this.setState({ currentDataItem: dataItem, presentItemIndex: idx, openSlide: true, rowselected: dataItem.dataItem, rowrenderedslide: true })

                }
                else {

                    if (this.props.useComp) {
                        this.props.handleEdit(dataItem.dataItem)
                    }
                    else if (update) {
                        let pushLink = ''
                        const { link, matchProps, type } = update.link
                        if (Array.isArray(update.link)) {
                            update.link.forEach(each => {
                                const { link, matchProps } = each
                                pushLink += link + '/' + (this.props.match.params[matchProps.value] ?
                                    this.props.match.params[matchProps.value] : dataItem.dataItem[matchProps.value])

                            })
                        }
                        else {
                            if (matchProps) pushLink += link + '/' + dataItem.dataItem[matchProps.value]
                        }
                        this.props.history.push(`${pushLink}`)
                    }
                }
            },
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    componentDidMount() {
        this.DidMount();
    }

    componentDidUpdate(prevProps, prevState,) {
        const { dataState } = this.state;
        if ((prevProps.data !== this.props.data) && this.props.useComp || (prevProps.total !== this.props.total)) {
            this.setState({
                data: this.props.allowPropsData ? this.props.data :
                    this.props.data.slice(dataState.skip, dataState.take + dataState.skip), total: this.props.total
            })
        }
    }

    async DidMount() {
        const { useComp, gridkey, options } = this.props;
        const { crud } = options
        //const { recordinfo } = crud
        if (crud && crud.customcondition) {
            let initialloadextrainfodata = await handlecustomCondition(this.props)
            this.setState({ initialloadextrainfo: initialloadextrainfodata })
        }
        if (this.props && this.props.options && this.props.options.crud && this.props.options.crud.recordinfo && !(this.props.header && this.props.header.recordidinfo && this.props.header.recordidinfo === this.props.match.params.routeid1)) {
            const { get, transPropsurl } = this.props.options.crud.recordinfo
            const { routeProps, } = get
            let url = get.url;
            let id;
            routeProps.forEach(each => {
                const { key } = each
                id = routeProps ? this.props.match.params[key] : null
                url = `${url.replace(`{${key}}`, id)}`

            })
            GetData(url).then(res => {
                this.props.setRecordData(this.props.match.params.routeid1, res);
            })
        }
        this.grid = document.querySelector('.k-grid');
        window.addEventListener('resize', this.handleResize);
        //   if (useComp) this.props.columns.map(item => this.minGridWidth += item.width);
        this.props.options.gridcolumns.map(item => this.minGridWidth += item.width)
        this.setState({
            gridCurrent: this.grid.offsetWidth,
            setMinWidth: this.grid.offsetWidth < this.minGridWidth
        });
        this.loadDefaultsGridData();
        // let subgrid = this.props && this.props.options && this.props.options.childGrid ? true : false
        // if ((localStorage.getItem(this.props.location.pathname) !== null) && !subgrid && !useComp) {
        //     this.loadDefaultsGridData();
        // }
    }

    intialLoadData = () => {
        //Fetch grid persistance for the current logged in user
        const { options } = this.props
        let existingReportsURL = getEnvVariable('Report') + `/api/Reports/Default/${this.props.attachparamgridkey ? this.props.match.params.key + this.props.gridkey : this.props.gridkey}`
        GetData(existingReportsURL, null, null, false).then(res => {
            if (res && res.Id > 0) {
                this.setState({ hasDefaultReport: true }, () => {
                    // if (DefaultSettings.getAlert() != null && alert)
                    //     DefaultSettings.getAlert().show('Default filters applied to the grid','success');
                    this.loadReportFilter(this, res.Id)
                })
            }
        })
    }

    LoadGrid = (noReportFilter) => {
        let subgrid = this.props && this.props.options && this.props.options.childGrid ? true : false
        if (!this.props.useComp && noReportFilter) {
            this.loadDefaultsGridData();
        }//(localStorage.getItem(this.props.location.pathname) !== null) &&
        else {
            if (!subgrid) {
                this.intialLoadData()
            }
        }
    }

    loadDefaultsGridData() {
        let datastate = null
        let subgrid = this.props && this.props.options && this.props.options.childGrid ? true : false
        let pathName = this.props.location.pathname
        if (this.props.pathKey)
            pathName = pathName + this.props.pathKey;
        if ((localStorage.getItem(pathName) !== null) && !subgrid) {
            datastate = JSON.parse(localStorage.getItem(pathName))
        }
        else datastate = this.state.dataState
        this.fetchData(datastate);
    }

    /**
        *********************************************************
        Description: For responsive grid based on broswer size
        limitations    : N/A
        *********************************************************
        **/

    handleResize = () => {
        if (this.grid.offsetWidth < this.minGridWidth && !this.state.setMinWidth) {
            this.setState({
                setMinWidth: true
            });
        } else if (this.grid.offsetWidth > this.minGridWidth) {
            this.setState({
                gridCurrent: this.grid.offsetWidth,
                setMinWidth: false
            });
        }
    }

    // setWidth = (minWidth) => {
    //     let width = this.state.setMinWidth ? minWidth :
    //         minWidth + (this.state.gridCurrent - this.minGridWidth) / this.props.options.gridcolumns.length;
    //     return width;
    // }

     setWidth = (title) => {
        if (title) {
            const baseWidthPerCharacter = 13; // This is an arbitrary value; adjust based on your font
            const standardWidth = 200; // Standard width for titles with less than 10 characters
            const titleLength = title.length;
            const calculatedWidth = titleLength >= 15 ? titleLength * baseWidthPerCharacter : standardWidth;
            return calculatedWidth
        }
        // let width = minWidth ? minWidth : (window.innerWidth / columns.length);
        // return width;
    }


    handleRowClick = (gridRowClickEvent) => {
    }
    handleDataPageState = () => {
        let deepCopy = _.cloneDeep(this.state.dataState);
        deepCopy.skip = 0
        deepCopy.take = this.state.total
        return deepCopy
    }

    handleDataStateChange = (changeEvent) => {
        if (this.props.useComp) {
            const { childGrid } = this.props.options
            let dataState = changeEvent.dataState

            this.setState({
                data: process(this.props.data, dataState),
                dataState: dataState
            }, () => {
                if (changeEvent.dataState && changeEvent.dataState.filter && !childGrid) {
                    let filterkey = this.props.location.pathname
                    if (this.props.pathKey)
                        filterkey = filterkey + this.props.pathKey;
                    localStorage.setItem(filterkey, JSON.stringify(changeEvent.dataState))
                }
                if (this.props.onCustomDataChange) {
                    this.props.onCustomDataChange(changeEvent.dataState)
                }
            });

        }
        else {
            const { childGrid } = this.props.options
            let exportdata = changeEvent.dataState && changeEvent.dataState.filter ? true : false;
            if (changeEvent.dataState && changeEvent.dataState.filter && !childGrid) {
                let filterkey = this.props.location.pathname
                if (this.props.pathKey)
                    filterkey = filterkey + this.props.pathKey;
                localStorage.setItem(filterkey, JSON.stringify(changeEvent.dataState))
            }
            else {
                if (!childGrid) {
                    let filterkey = this.props.location.pathname
                    if (this.props.pathKey)
                        filterkey = filterkey + this.props.pathKey;
                    localStorage.removeItem(filterkey);
                }
            }
            this.setState({ dataState: changeEvent.dataState, exportdata: exportdata });

            // delete changeEvent.dataState['group']
            this.fetchData(changeEvent.dataState, true);
        }
    }


    /**
     *********************************************************
     Description: Handles the coloring of the status column
     Required props:  grid needs to have data.
     limitations    : N/A
     *********************************************************
**/



    handleGridForm = (name, transformProps) => (event) => {
        const val = name ? name.split(".").reduce((res, prop) => { return res && res[prop] }, event.dataItem) : null;
        return <td>{transformProps[val]}</td>
    }

    /**
     *********************************************************
     Description: Handles the date transformation
     Required props:  N/A
     limitations    : N/A
     *********************************************************
**/
    handleDate = (name) => (event) => {
        let newDate = new Date(event.dataItem[event.field]);
        if (event.field === 'LoginDate') {
            let wTime = newDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            return <td>{moment(newDate).format('MM/DD/YYYY hh:mm:ss A') + ' ' + wTime}</td>
        }
        return <td>{moment(newDate).format('MM/DD/YYYY hh:mm:SS A')}</td>
    }
    /**
      *********************************************************
      Description: Handles the color transformation
      Required props:  N/A
      limitations    : N/A
      *********************************************************
 **/
    handleColor = (event) => {
        return <td>{event.dataItem[event.field] ? <ColorIcon style={{ color: event.dataItem[event.field] }} fontSize="small" /> : ""}</td>
    }

    /**
     *********************************************************
     Description: Handles the data for the grid. creates the url to fetch either with query or without query.
     Required props:  options, components. Within options crud.read is needed to fetch data.
     limitations    : N/A
     *********************************************************
**/

    fetchData(dataState, isfilter = false) {
        isfilter = !isfilter ? dataState.filter != null : isfilter
        const queryStr = `${toDataSourceRequestString(dataState)}`; // Serialize the state
        const hasGroups = dataState.group && dataState.group.length;
        const { options, components, defaults, order, type, compmap, rules, accessPermissions, accessLevelPermissions, dataItem, ...otherProps } = this.props;
        const { gridcolumns } = options
        const { routes, crud } = options
        const { read, gridread, create, update, domain } = crud || {}
        //   const del = crud.delete
        const { get, transPropsurl } = read || gridread || {}
        const { routeProps, matchProps, noquery, hidepath, parentIdValue, customProps, isLSProfile } = get ? get : {}
        let url = (get && get.url) || null;
        let id;
        let hasfilterfields = [];
        hasfilterfields = this.props && this.props.options && this.props.options.childGrid ? this.state.filteredfield.map(field => {
            if (GridColumnMenuFilter.active(field, dataState.filter)) {
                return field;
            }
        }) : []
        if (url) {
            if (Array.isArray(routeProps)) {
                routeProps.forEach(each => {
                    const { value } = each
                    if (hidepath) {
                        id = this.props.accessPermissions.userId
                    }
                    else id = routeProps ? this.props.match.params[value] : null
                    url = `${url.replace(`{${value}}`, id)}`

                })
            }
            else if (isLSProfile) {
                id = JSON.parse(localStorage.getItem('profile'))[isLSProfile];
                url = `${get.url.replace(`{Id}`, id)}`;
            }
            else if (Array.isArray(customProps)) {
                customProps.forEach(each => {
                    const { value, custom } = each
                    if (custom) {
                        id = dataItem[custom]
                        url = `${url.replace(`{${custom}}`, id)}`
                    }
                    else {
                        id = customProps ? this.props.match.params[value] : null
                        url = `${url.replace(`{${value}}`, id)}`
                    }
                })
            }
            else if (parentIdValue) {
                id = this.props.parentId
                url = `${url.replace(`{${parentIdValue.value}}`, id)}`
            }
            else if (routeProps) {
                if (hidepath) {
                    id = this.props.accessPermissions.userId
                }
                else
                    id = routeProps ? this.props.match.params[routeProps.value] : null
                url = `${get.url.replace(`{${routeProps.value}}`, id)}`;
            }
            else if (customProps) {
                if (hidepath) {
                    id = this.props.accessPermissions.userId
                }
                else
                    id = customProps ? dataItem[get.customProps.value] || dataItem.Id : null
                url = `${get.url.replace(`{${customProps.value}}`, id)}`;
            };

            let urlPath;
            if (noquery) {
                urlPath = routeProps ? `${url}?${queryStr}` :
                    `${url}${(url.indexOf("?") > -1) ? '&' : '?'}${queryStr}`;
            }
            else {
                urlPath = routeProps ? `${url}${(url.indexOf("?") > -1) ? '&' : '?'}${queryStr}` :
                    `${url}${(url.indexOf("?") > -1) ? '&' : '?'}${queryStr}`;

            }
            GetData(urlPath).then((res) => {
                if (res && res.data) {
                    const { data, total } = res
                    if (data) {
                        data.map(res => {
                            if (this.props.options.htmlconvert && this.props.options.htmlconvert.includes(res.FieldLabel)) {
                                let convertOldValue = atob(res.OldValue);
                                let convertNewValue = atob(res.NewValue);
                                let regex = /(<([^>]+)>)/ig;
                                res.OldValue = convertOldValue.replace(regex, "");
                                res.NewValue = convertNewValue.replace(regex, "");
                            }
                            if (res.NewValue) {
                                res.NewValue = res.NewValueDesc && res.NewValueDesc !== null ? res.NewValueDesc : moment(res.NewValue).format('MM/DD/YYYY') === 'Invalid date' ? res.NewValue : moment(res.NewValue).format('MM/DD/YYYY')
                            }
                            if (res.OldValue) {
                                res.OldValue = res.OldValueDesc && res.OldValueDesc !== null ? res.OldValueDesc : moment(res.OldValue).format('MM/DD/YYYY') === 'Invalid date' ? res.OldValue : moment(res.OldValue).format('MM/DD/YYYY')
                            }
                            if (res.CreatedOn) {
                                res.CreatedOn = res.CreatedOn !== null ? moment(res.CreatedOn).format('MM/DD/YYYY hh:mm:ss A') : ""
                            } if (res.UpdatedOn) {
                                res.UpdatedOn = res.UpdatedOn !== null ? moment(res.UpdatedOn).format('MM/DD/YYYY hh:mm:ss A') : ""
                            }
                            if (res.LoginDate) {
                                res.LoginDate = res.LoginDate !== null ? moment(res.LoginDate).format('MM/DD/YYYY hh:mm:ss A') : ""
                            } if (res.NoticeDate) {
                                res.NoticeDate = res.NoticeDate !== null ? moment(res.NoticeDate).format('MM/DD/YYYY') : ""
                            }
                        })
                    }
                    this.setState({
                        passDataItem: this.props.dataItem, filteredfield: dataState && dataState.filter != null ? hasfilterfields : [], data: hasGroups ? translateDataSourceResultGroups(data) : data, total, dataState
                    }, () => {
                        if (!isfilter)
                            this.LoadGrid(false)
                        if (this.props.match.params && this.props.match.params.Id && this.props.custompropsvalue) {
                            let dataItemObj
                            if (this.props.options && this.props.options.selectdataitem) {
                                let selectedDataItem = _.find(data, { [this.props.custompropsvalue]: Number(this.props.match.params.Id) });
                                dataItemObj = selectedDataItem ? selectedDataItem : { [this.props.custompropsvalue]: Number(this.props.match.params.Id) };
                            }
                            else {
                                dataItemObj = this.props.checkLocation && this.props.location && this.props.location.state && Object.keys(this.props.location.state).length > 0 ?
                                    { [this.props.custompropsvalue]: Number(this.props.match.params.Id), ['Status']: this.props.location.state.User.Status } :
                                    { [this.props.custompropsvalue]: Number(this.props.match.params.Id) }
                            }

                            this.setState({ currentDataItem: dataItemObj, openSlide: true })
                        }

                    });
                }
            });
        }
    }

    onClearColumnsSubmit = () => {
        this.setState({
            columns: this.props && this.props.options && this.props.options.gridcolumns,
            data: this.state.data
        });
    }

    onColumnsSubmit = (columnsState) => {
        this.setState({
            columns: columnsState,
            data: this.state.data
        });
    }
    /**
           *********************************************************
           Description: save  the grid component as new report.
           Required props:  options, components. Within options gridcolumns are set to columns in state whicha re needed to create columns.
           limitations    : N/A
           *********************************************************
     **/

    saveReport = (cols) => {
        const { options } = this.props
        const { crud } = options
        const { read } = crud
        let url = read.get.url
        let gridoptions = getOptions(this.state.data, this.state.columns, this.state.dataState, url);
        this.setState({ savereprt: true, gridoptions: JSON.stringify(gridoptions) })
    }


    /**
         *********************************************************
         Description: Excel export for all the grids.
         Required props:  N/A
         limitations    : N/A
         *********************************************************
   **/
    _export;
    _grid;
    export = (action) => {
        const { options } = this.props
        const { crud } = options
        const { read, gridread } = crud
        const { get } = read || gridread
        const { routeProps, matchProps, noquery, hidepath, parentIdValue, customProps } = get ? get : {}
        let url = get.url;
        let exportalldatastate = action == 'filterall' ? this.handleDataPageState() : null
        const queryStr = exportalldatastate ? `${toDataSourceRequestString(exportalldatastate)}` : `${toDataSourceRequestString(this.state.dataState)}`; // Serialize the state
        let id;
        if (Array.isArray(routeProps)) {
            routeProps.forEach(each => {
                const { value } = each
                if (hidepath) {
                    id = this.props.accessPermissions.userId
                }
                else id = routeProps ? this.props.match.params[value] : null
                url = `${url.replace(`{${value}}`, id)}`

            })
        }
        else if (Array.isArray(customProps)) {
            customProps.forEach(each => {
                const { value, custom } = each
                if (custom === 'Type') {
                    id = this.props.dataItem["Type"]
                    url = `${url.replace(`{${custom}}`, id)}`
                }
                else if (custom) {
                    id = this.props.dataItem["Id"]
                    url = `${url.replace(`{${custom}}`, id)}`
                }
                else {
                    id = customProps ? this.props.match.params[value] : null
                    url = `${url.replace(`{${value}}`, id)}`
                }
            })
        }
        else if (parentIdValue) {
            id = this.props.parentId
            url = `${url.replace(`{${parentIdValue.value}}`, id)}`
        }
        else if (routeProps) {
            if (hidepath) {
                id = this.props.accessPermissions.userId
            }
            else
                id = routeProps ? this.props.match.params[routeProps.value] : null
            url = `${get.url.replace(`{${routeProps.value}}`, id)}`;
        }
        else if (customProps) {
            id = customProps ? this.props.dataItem[customProps.value || "Id"] : null
            url = `${get.url.replace(`{${customProps.value}}`, id)}`;
        }
        let urlPath
        if (queryStr) {
            urlPath = routeProps ? `${url}?${queryStr}` :
                `${url}?${queryStr}`;
        }
        else {
            urlPath = routeProps ? `${url}` :
                `${url}`;

        }
        if (action === 'filterall') {

                let tempString = urlPath
                GetData(tempString).then(result => {
    
                result.data.map(res => {
                    if (res.CreatedOn) {
                        res.CreatedOn = res.CreatedOn !== null ? moment(res.CreatedOn).format('MM/DD/YYYY hh:mm:ss A ') : ""
                    } if (res.UpdatedOn) {
                        res.UpdatedOn = res.UpdatedOn !== null ? moment(res.UpdatedOn).format('MM/DD/YYYY hh:mm:ss A') : ""
                    }
                    if (res.LoginDate) {
                        res.LoginDate = res.LoginDate !== null ? moment(res.LoginDate).format('MM/DD/YYYY hh:mm:ss A') : ""
                    } if (res.NoticeDate) {
                        res.NoticeDate = res.NoticeDate !== null ? moment(res.NoticeDate).format('MM/DD/YYYY') : ""
                    }
                    if (res.Body) {
                        let bodyval = res.Body !== null ? atob(res.Body) : ""
                        res.Body = bodyval.replace(/<\/?[^>]+(>|$)/g, "")
                    }
                })
                this._export.save(result, this._grid.columns);
            })
        }
        else {
            let exportdata = this.state.data.data
            let tempData = exportdata && exportdata.map(res => {
                if (res.Body) {
                    let bodyval = res.Body !== null ? atob(res.Body) : ""
                    res.Body = bodyval.replace(/<\/?[^>]+(>|$)/g, "")
                    return res
                }
                else return res;
            })
            this._export.save(tempData, this._grid.columns);
        }
    }


    columnProps(field) {
        if (!this.state.filteredfield.includes(field)) {
            if (this.isColumnActive(field, this.state.dataState)) {
                this.setState({ filteredfield: [...this.state.filteredfield, field] })
            }
        }
    }

    isColumnActive(field, dataState) {
        return GridColumnMenuFilter.active(field, dataState.filter)
    }

    handleSlide = () => {
        if ((localStorage.getItem('griddataItem') !== null) && !this.props.options.nonautoopen) {
            localStorage.removeItem('griddataItem')
        }
        this.setState({ openSlide: false, currentDataItem: null, rowselected: null }, () => {
            if (!this.props.match.params && !this.props.match.params.Id && !this.props.custompropsvalue) {
                this.LoadGrid(true);
            }
            else if (this.props.match.params && !(this.props.match.params.Id || this.props.match.params.id)) {
                this.LoadGrid(true);
            }
            else if (this.props.match.params && (this.props.match.params.Id || this.props.match.params.id) && this.props.custompropsvalue) {
                let dataItemObj = { [this.props.custompropsvalue]: Number(this.props.match.params.id || this.props.match.params.Id) };
                this.setState({ currentDataItem: dataItemObj, openSlide: false, rowrenderedslide: false }, () => {
                    let pathname = this.props.location.pathname
                    if (this.props && this.props.match && this.props.match.params && (this.props.match.params.Id || this.props.match.params.id)) {
                        // this.props.match.params.Id = undefined
                        pathname = pathname.substring(0, pathname.lastIndexOf("/"))
                    }
                    this.props.history.push(pathname)
                    this.LoadGrid(true);
                })
            }
            else if (this.props.match.params && this.props.match.params.Id && !this.props.custompropsvalue) {
                this.LoadGrid(true);
            }
        })
    }

    // for handling next item in sliding page
    onNextClick = (dataItemVal) => {
        this.setState({ rowselected: dataItemVal })
        if (this.props.custompropsvalue) {
            let pathname = this.props.location.pathname
            this.props.match.params.Id = dataItemVal[this.props.custompropsvalue] || dataItemVal.dataItemVal[this.props.custompropsvalue]
            pathname = pathname.substring(0, pathname.lastIndexOf("/"))
            pathname = pathname + '/' + this.props.match.params.Id;
            this.props.history.push(pathname);
        }
        this.rowRender({ props: { children: '' } }, dataItemVal)
    }

    // for handling prev item in sliding page
    onPrevClick = (dataItemVal) => {
        this.setState({ rowselected: dataItemVal })
        if (this.props.custompropsvalue) {
            let pathname = this.props.location.pathname
            this.props.match.params.Id = dataItemVal[this.props.custompropsvalue] || dataItemVal.dataItemVal[this.props.custompropsvalue]
            pathname = pathname.substring(0, pathname.lastIndexOf("/"))
            pathname = pathname + '/' + this.props.match.params.Id;
            this.props.history.push(pathname);
        }
        this.rowRender({ props: { children: '' } }, dataItemVal)
    }


    HTMLCell = (props) => {
        return <td >{this.isHTML(atob(props.dataItem[props.field])) ?
            <div dangerouslySetInnerHTML={{ __html: atob(props.dataItem[props.field]) }} /> : props.dataItem[props.field]} </td>
    }

    HTMLCellSize = (props) => {
        try {
            return props && props.dataItem[props.field] && this.isHTML(atob(props.dataItem[props.field])) ?
                <td>  <div dangerouslySetInnerHTML={{ __html: atob(props.dataItem[props.field]).replace(/(<([^>]+)>)/g, "") }} /> </td> : <td>{props.dataItem[props.field]} </td>
        }
        catch (ex) {
            return <td>{props.dataItem[props.field]}</td>
        }
    }


    //Handling only for the notices download. Extend this if required
    downloadCell = (props) => {
        if (props.rowType === "groupHeader") {
            return null;
        }
        if (props.dataItem && props.dataItem.TemplateId)
            return <td > <DownloadIcon onClick={() => downloadNoticeData(props.dataItem.TemplateId)} /> </td>
        else return <td > <DownloadIcon onClick={() => this.handleActionEvents(props.dataItem)} /> </td>

    }

    NoDataDisplay = (props, field) => {
        if (props.rowType === "groupHeader") {
            return null;
        }
        if (props.dataItem[field] === null || props.dataItem[field] === '') {
            return <td style={{ color: '#adabaa' }}> N/A </td>
        }
        return <td > {props.dataItem[field]} </td>

    }

    /**
         *********************************************************
         Description: Handle Back to navigate to custom and history screen
         Required props:  back url
         limitations    : N/A
         *********************************************************
   **/
    handleBack = () => {
        const { options } = this.props
        const { back } = options;
        const { routes } = back;
        if (Array.isArray(routes)) {
            let path = ''
            routes.forEach(each => {
                const { route, routeParams, matchProps } = each
                if (routeParams) {
                    path += route + "/" + this.props.match.params[routeParams]
                }
                else if (matchProps) {
                    path += route + "/" + this.props.results[matchProps] //--correct:unused
                }
                else {
                    path += route
                }
            })
            this.props.history.push(path)
        }
    }


    deleteCell = (props) => {
        if (props.rowType === "groupHeader") {
            return null;
        }
        return <td >
            <DeleteIcon onClick={() => this.handleActionEvents(props)} fontSize="small" style={{ cursor: 'pointer' }} />
        </td>
    }

    customcell = (dataitem) => {
        const { dataItem } = dataitem
        const { anchorEl, selecteddataItem } = this.state;
        // if (props.rowType === "groupHeader") {
        //     return null;
        // }
        return <td >
            <div className="row col-2" key={dataItem.Id}>
                <div className='col-sm-1'>
                    <IconButton onClick={(event) => this.setState({ anchorEl: event.currentTarget, selecteddataItem: dataItem })} className='float-right'>
                        <MoreVertIcon />
                    </IconButton>
                    <div className='clearfix' />
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => { this.setState({ anchorEl: null }) }}>
                        {this.props.handleEdit && <MenuItem style={{ margin: "auto" }} onClick={() => { this.setState({ anchorEl: null }, () => this.props.handleEdit(selecteddataItem)) }}>
                            <ListItemIcon>
                                <CreateIcon />
                            </ListItemIcon>
                            <ListItemText inset primary="Edit" />
                        </MenuItem>}
                        <Divider />
                        {this.props.onUp && <MenuItem onClick={() => { this.setState({ anchorEl: null }, () => this.props.onUp(selecteddataItem)) }}>
                            <ListItemIcon>
                                <ArrowUpwardIcon />
                            </ListItemIcon>
                            <ListItemText inset primary="Move Up" /></MenuItem>}
                        <Divider />
                        {this.props.onDown && <MenuItem onClick={() => { this.setState({ anchorEl: null }, () => this.props.onDown(selecteddataItem)) }}>
                            <ListItemIcon>
                                <ArrowDownwardIcon />
                            </ListItemIcon>
                            <ListItemText inset primary="Move Down" /></MenuItem>}
                        <Divider />
                        {this.props.onDelete && <MenuItem onClick={() => { this.setState({ anchorEl: null }, () => this.props.onDelete(selecteddataItem)) }}>
                            <ListItemIcon>
                                <ClearIcon />
                            </ListItemIcon>
                            <ListItemText inset primary="Remove" /></MenuItem>}
                    </Menu>
                </div>
            </div>
        </td>
    }


    isHTML(str) {
        try {
            var doc = new DOMParser().parseFromString(str, "text/html");
            return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
        }
        catch (ex) {
            return str;
        }
    }

    clearFilters = () => {

        let dataState = {
            skip: 0, take: 20
        }
        this.onClearColumnsSubmit();
        let pathname = this.props.location.pathname
        if (this.props.pathKey)
            pathname = pathname + this.props.pathKey;
        if (pathname)
            localStorage.removeItem(pathname);
        this.setState({ hasDefaultReport: false, filteredfield: [], dataState: dataState })

        if (this.props.pathKey) {
            this.props.clearFilters()
        } else {
            this.fetchData(dataState, true);

        }
    }

    setPercentage = (width, percentage) => {
        return Math.round(width / 100) * percentage;
    }

    /******************************************
     * Load saved reports or grid filters
     *
     */
    loadReportFilter(inthis, id) {
        let existingReportsURL = getEnvVariable('Report') + `/api/Reports/Report?id=${id}`
        GetData(existingReportsURL).then(res => {
            if (res) {
                //let gridOptions = JSON.parse(res.ReportJsonQueryStr);
                let opts = JSON.parse(res.ReportJsonQueryStr)
                let gridurl = opts.dataSource.transport.read.url;
                let newdatastateval = opts.withdatastateformat

                let columnsinfo = opts.columns.flatMap(i => i.field)
                let hasfilterfields = []
                hasfilterfields = columnsinfo.map(field => {
                    if (GridColumnMenuFilter.active(field, newdatastateval.filter)) {
                        return field;
                    }
                })
                let ids = []
                ids = Object.entries(this.props.match.params)
                ids.forEach(each => {
                    const key = each[0];
                    const value = each[1]
                    gridurl = `${gridurl.replace(`{${key}}`, value)}`;
                })
                let url = gridurl;
                let queryStr = opts.dataSource.transport.read.datastate || null;
                if (queryStr)
                    url = `${url}${queryStr}`

                GetData(url).then(res1 => {
                    if (res1 && res1.data) {
                        let gridoptions = JSON.parse(res.ReportJsonQueryStr);
                        res1.data.map(res => {
                            if (res.CreatedOn) {
                                res.CreatedOn = res.CreatedOn !== null ? moment(res.CreatedOn).format('MM/DD/YYYY hh:mm:ss A ') : ""
                            } if (res.UpdatedOn) {
                                res.UpdatedOn = res.UpdatedOn !== null ? moment(res.UpdatedOn).format('MM/DD/YYYY hh:mm:ss A') : ""
                            }
                            if (res.LoginDate) {
                                res.LoginDate = res.LoginDate !== null ? moment(res.LoginDate).format('MM/DD/YYYY hh:mm:ss A') : ""
                            } if (res.NoticeDate) {
                                res.NoticeDate = res.NoticeDate !== null ? moment(res.NoticeDate).format('MM/DD/YYYY') : ""
                            }
                        })
                        inthis.setState({
                            filteredfield: newdatastateval && newdatastateval.filter != null ? hasfilterfields : [],
                            savereprt: false, kendoStructure: res.ReportJsonQueryStr, data: res1, total: res1.total, dataState: gridoptions.withdatastateformat, ...gridoptions
                        }, () => {
                            if (this.props.match.params && this.props.match.params.Id && this.props.custompropsvalue) {
                                let dataItemObj = { [this.props.custompropsvalue]: Number(this.props.match.params.Id) };
                                this.setState({ currentDataItem: dataItemObj, openSlide: true })
                            }
                        })
                    }
                })
            }
        }).catch(ex => {
            console.log(ex)
        })
    }

    handleCheckBox = (e, props) => {
        if (props.field === 'IsPrimary') handlePrimaryCheck(e, props, this.props.match.params, e.target.checked);
        else this.handleActionEvents(props.dataItem);
    }

    handleActionEvents = (props) => {
        Promise.all((props.field.request || []).map(each => requestApi(newRequestApi(each, props, null)))).then(res => {
            this.fetchData(this.state.dataState, true);
        })
    }


    cellRender = (td, props) => {
        if (td && td.props.children && props.rowType === "groupHeader") {
            let children = (
                <span>
                    {td.props.children.props.children}
                </span>
            );
            return React.cloneElement(td, td.props, children);
        }
        return td;
    };

    handleRefresh = () => {
        if (this.props.dataItem && this.props.dataItem.Key) {
            let key = this.props.dataItem && this.props.dataItem.Key
            let url = getEnvVariable('Admin') + `/api/Domains/RefreshDomain/${key}`
            GetData(url, true, 'Refreshing Cache is Successful').then(res => {
                console.log("refresh cache")
            })
        }
    }

    /**
         *********************************************************
         Description: renders the grid component.
         Required props:  options, components. Within options gridcolumns are needed to create columns.
         limitations    : N/A
         *********************************************************
   **/
    render() {
        const { options, defaults, order, type, compmap, rules, accessPermissions, accessLevelPermissions, parentId, customId, hideEdit, hideEditCheckVal,
            key, ...otherProps } = this.props;
        const { headerProps = {}, sidebar, gridstyle, formgrid, addcomponents, components, sidepopupgridheight, extragridinfo, conditions, extrainfo, customHeight } = options || {};
        const { columns, openSlide, dataState, isExport = false } = this.state;
        let accessfrom = getEnvVariable('ConfigType') === 'R' ? 'moduleRegionAccess' : getEnvVariable('ConfigType') === 'D' ? 'moduleDistrictAccess' : 'moduleSiteAccess'
        let roleaccess = this.props.accessPermissions && this.props.accessPermissions[accessfrom] && this.props.accessPermissions[accessfrom] ? Object.values(this.props.accessPermissions[accessfrom]).includes('Add') ? 'CanAdd' : Object.values(this.props.accessPermissions[accessfrom]).includes('Update') ? 'CanEdit' : Object.values(this.props.accessPermissions[accessfrom]).includes('Read') ? 'CanRead' : 'CanAdd' : 'CanAdd'
        let portal = this.props.accessPermissions ? this.props.accessPermissions.portal : null
        let isAdmin = this.props.accessPermissions && this.props.accessPermissions.isAdmin ? this.props.accessPermissions.isAdmin : 'N'
        let canAdd = isAdmin == 'Y' ? true : (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanAdd" ? true : (portal === "E") ? true : false
        let canEdit = isAdmin == 'Y' ? true : (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanEdit" ? true : (portal === "E") ? true : false
        let canRead =(!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanRead" ? true : (portal === "E") ? true : false;
        //    let gridDataState = { ...this.state.dataState, take: this.state.pageable.pageSizes.includes(this.state.dataState['take']) ? this.state.dataState.take : 'All' }
        // let gridPageable = { ...this.state.pageable, ['info']: this.state.pageable.pageSizes.includes(this.state.dataState['take']) ? true : false }
        return (
            <React.Fragment >
                {/* <ReactResizeDetector handleWidth handleHeight>
                    {({ width, height }) => */}
                < div className='col' >
                    {!formgrid && !this.props.useComp && <GridHeader initialloadextrainfo={this.state.initialloadextrainfo} headerProps={headerProps}
                        reportapplied={this.state.hasDefaultReport} compmap={compmap} accessPermissions={accessPermissions} accessLevelPermissions={accessLevelPermissions}
                        dataItem={this.props.dataItem ? this.props.dataItem : null} clearFilters={this.clearFilters} saveReport={this.saveReport}
                        export={(evt) => {
                            this.setState({ isExport: true }, () => {
                                this.export(evt)
                            })
                        }}
                        backbutton={headerProps.backbutton} handleBack={this.handleBack}
                        addrecord={this.addrecord} components={addcomponents} handleFormGrid={this.intialLoadData} sidebar={sidebar} handleSlide={this.handleSlide}
                        customprops={parentId} customId={customId} />}
                    {
                        formgrid && <FormHeader {...headerProps} accessPermissions={accessPermissions} accessLevelPermissions={accessLevelPermissions}
                            components={addcomponents} handleFormGrid={this.intialLoadData} dataItem={this.state.passDataItem ? this.state.passDataItem : this.props.dataItem} handleBack={this.handleBack} handleCustomBack={this.props.handleCustomBack}
                            sidebar={sidebar} compmap={compmap} title={headerProps.title} handleSlide={this.handleSlide} addbutton={hideEdit ?
                                (this.props.edit ? true : false) : true} customprops={parentId} customId={customId}
                            handleRefresh={this.handleRefresh} refreshbutton={this.props.dataItem && this.props.dataItem['DomainType'] &&
                                this.props.dataItem['DomainType'] === 'A' ? headerProps.refreshbutton : null}
                            clearFilters={this.clearFilters} saveReport={this.saveReport} export={(evt) => {
                                this.setState({ isExport: true }, () => {
                                    this.export(evt)
                                })
                            }}
                            reportbutton={headerProps.reportbutton} excelbutton={headerProps.excelbutton} filterbutton={headerProps.filterbutton} />
                    }
                    <ExcelExport
                        data={this.state.data}
                        fileName={(headerProps && headerProps.title && `${headerProps.title}.xlsx`) || 'ExcelFile.xlsx'}
                        ref={exporter => this._export = exporter}
                    >
                        <Grid
                            reorderable
                            sortable={true}
                            onPageChange={this.pageChange}
                            total={this.state.total}
                            pageable={this.state.pageable}
                            cellRender={this.cellRender}
                            data={this.state.data}
                            {...this.state.dataState}
                            onDataStateChange={this.handleDataStateChange}
                            onRowClick={(options && !options.disablerender) && (!this.props.useComp || this.props.enablerender) && this.handleRowClick}
                            rowRender={(options && !options.disablerender) && (!this.props.useComp || this.props.enablerender) && this.rowRender}
                            style={sidepopupgridheight ? { height: window.innerHeight - 220, width: 'w-90' } :
                                (gridstyle ? { height: '550px', width: 'w-90' } :
                                    { height: customHeight ? customHeight + 'px' : window.innerHeight - 125, width: 'w-90' })}
                            resizable
                            ref={grid => this._grid = grid}
                            groupable={options && options.initialGroup && options.initialGroup.length > 0 ? true : false} group={options && options.initialGroup || []} onExpandChange={this.expandChange} expandField="expanded"
                        >
                            {columns && columns.map((each, index) => {
                                const { name, transformProps, date } = each
                                if (each.fieldtype && each.fieldtype === 'Checkbox')
                                    return <GridColumn {...each} filter={each.filter} title={isExport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (dataState && dataState.filter)) ? 'text-primary' : ''}>{each.title}</span>}
                                        width={this.setWidth(each.title)} key={index}
                                        cell={props =>
                                            props.rowType === "groupHeader" ? null :
                                                <td>
                                                    <input type="checkbox" disabled={each.hidecondition ?
                                                        handlecustomCondition({ 'options': { 'hidecondition': each.hidecondition } }).condition : (canAdd || canEdit) ?
                                                            false : true}
                                                        checked={props.dataItem[each.field] === 'Y'} onChange={(e) => this.handleCheckBox(e, props, each.field)} />
                                                </td>}
                                        columnMenu={
                                            props =>
                                                <CustomColumnMenu
                                                    {...props}
                                                    columns={columns}
                                                    saveReport={this.saveReport}
                                                    onColumnsSubmit={this.onColumnsSubmit}
                                                />
                                        } />
                                else if ((each.field === 'CreatedOn' || each.field === 'LoginDate' || each.field === 'NoticeDate') && each.show)
                                    return <GridColumn {...each} key={index} width={this.setWidth(each.title)} filter={'date'}
                                        title={isExport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (dataState && dataState.filter)) ? 'text-primary' : ''}>{each.title}</span>}
                                        columnMenu={  //cell={this.handleDate(date)} format={'0:MM-dd-yyyy'}
                                            props =>
                                                <CustomColumnMenu
                                                    {...props}
                                                    columns={columns}
                                                    saveReport={this.saveReport}
                                                    onColumnsSubmit={this.onColumnsSubmit}
                                                />
                                        } />
                                else if ((each.field === 'UpdatedOn' || each.field === 'UpdatedByName') && each.show)
                                    return <GridColumn {...each} filter={each.field === 'UpdatedOn' ? 'date' : each.filter}
                                        title={isExport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (dataState && dataState.filter)) ? 'text-primary' : ''}>{each.title}</span>} key={index}
                                        width={this.setWidth(each.title)} cell={e => this.NoDataDisplay(e, each.field)} columnMenu={  //cell={this.handleDate(date)} format={'0:MM-dd-yyyy'}
                                            props =>
                                                <CustomColumnMenu
                                                    {...props}
                                                    columns={columns}
                                                    saveReport={this.saveReport}
                                                    onColumnsSubmit={this.onColumnsSubmit}
                                                />
                                        } />
                                else if ((each.field === 'Color' || each.field === 'HighlightColor' || each.field === 'LayerColor') && each.show)
                                    return <GridColumn {...each} filter={each.filter} title={isExport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (dataState && dataState.filter)) ? 'text-primary' : ''}>{each.title}</span>} width={this.setWidth(each.title)} key={index} cell={this.handleColor}
                                        columnMenu={
                                            props =>
                                                <CustomColumnMenu
                                                    {...props}
                                                    columns={columns}
                                                    saveReport={this.saveReport}
                                                    onColumnsSubmit={this.onColumnsSubmit}
                                                />
                                        } />
                                else if ((each.field === 'Body') && each.isHTMLcolumn && each.show)
                                    return <GridColumn {...each} filter={each.filter} title={isExport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (dataState && dataState.filter)) ? 'text-primary' : ''}>{each.title}</span>} key={index} width={this.setWidth(each.title)} cell={this.HTMLCell} columnMenu={
                                        props =>
                                            <CustomColumnMenu
                                                hidefilter={true}
                                                {...props}
                                                columns={columns}
                                                saveReport={this.saveReport}
                                                onColumnsSubmit={this.onColumnsSubmit}
                                            />
                                    } />

                                else if ((each.field === 'Body' || each.field === 'BobyStr' || each.field === 'StandardComments' || each.field === 'NotificationBody' || each.field === 'OldValue' || each.field === 'NewValue') && each.encoded)
                                    return <GridColumn {...each} filter={each.filter} encoded={each.encoded} title={<span className={GridColumnMenuFilter.active(each.field, (dataState && dataState.filter)) ? 'text-primary' : ''}>{each.title}</span>} key={index} width={this.setWidth(each.title)} cell={this.HTMLCellSize} columnMenu={
                                        props =>
                                            <CustomColumnMenu
                                                hidefilter={true}
                                                {...props}
                                                columns={columns}
                                                saveReport={this.saveReport}
                                                onColumnsSubmit={this.onColumnsSubmit}
                                            />
                                    } />
                                else if ((each.field === 'Download') && each.show && each.isdownload)
                                    return <GridColumn {...each} title={isExport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (dataState && dataState.filter)) ? 'text-primary' : ''}>{each.title}</span>} key={index} cell={this.downloadCell}
                                    />
                                else if ((each.field === 'Delete') && each.show && each.isdelete)
                                    return <GridColumn {...each} title={isExport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (dataState && dataState.filter)) ? 'text-primary' : ''}>{each.title}</span>}
                                        key={index} width={this.setWidth(each.title)} cell={this.deleteCell} />

                                else if ((each.field === 'Custom') && each.show)
                                    return <GridColumn {...each} key={index} width={this.setWidth(each.title)} cell={this.customcell} title={isExport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (dataState && dataState.filter)) ? 'text-primary' : ''}>{each.title}</span>} />
                                else
                                    if (each.show) return <GridColumn {...each} title={isExport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (dataState && dataState.filter)) ? 'text-primary' : ''}>{each.title}</span>} width={this.setWidth(each.title)} filter={each.filter} key={index} columnMenu={
                                        props =>
                                            <CustomColumnMenu
                                                {...props}
                                                columns={columns}
                                                saveReport={this.saveReport}
                                                onColumnsSubmit={this.onColumnsSubmit}
                                            />
                                    } />
                            }
                                //     }
                            )}
                        </Grid>
                    </ExcelExport>
                </div>
                {/* }
                </ReactResizeDetector> */}
                {
                    this.state.savereprt && <Dialog results={{ ReportJsonQueryStr: this.state.gridoptions }}
                        compmap={compmap} close={() => this.setState({ savereprt: false })} open={this.state.savereprt}
                        gridkey={this.props.attachparamgridkey ? this.props.match.params.key + this.props.gridkey : this.props.gridkey} accessPermissions={accessPermissions} iscgrid={true}
                        loadReport={(id) => this.loadReportFilter(this, id)} />
                }
                {
                    openSlide && components && components.map(each => {
                        const EachComp = compmap[each.type]
                        return <EachComp {...each} initialloadextrainfo={this.state.initialloadextrainfo} key={each.key} handleSlide={this.handleSlide} onNextClick={this.onNextClick} onPrevClick={this.onPrevClick} compmap={compmap}
                            accessPermissions={accessPermissions} accessLevelPermissions={accessLevelPermissions}
                            dataItem={this.state.rowrenderedslide && options.extrainfoobj ?
                                { [options.extrainfoobj]: { ...this.state.currentDataItem.dataItem }, ...this.state.passDataItem } : extragridinfo ?
                                    { ...this.state.currentDataItem.dataItem, RefId: this.state.passDataItem[extrainfo] }
                                    : this.state.currentDataItem.dataItem || this.state.currentDataItem} parentId={parentId} customId={customId} completedata={this.state.data}
                            presentItemIndex={this.state.presentItemIndex} selectedrow={this.state.rowselected}
                            rowrenderedslide={this.state.rowrenderedslide} handleCustomBack={this.props.handleCustomBack} />
                    })
                }
            </React.Fragment >
        );
    }
}

const mapProps = (state) => {
    return state
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage, setRecordData }, dispatch);
}

CGrid = connect(mapProps, mapActions)(CGrid)
export default withRouter(CGrid)