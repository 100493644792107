
import React, { Fragment, useEffect, useRef } from "react"
import Container from '../../../core/controls/components/container'
import AuthorizationsGrid from '../../jsons/authorizations'


const AuthorizationsContainer = (props) => {

    return (
        <Container {...AuthorizationsGrid} {...props} />
    )
}

export default AuthorizationsContainer



