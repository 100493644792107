import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { lazy, Suspense } from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import ErrorHandler from './core/modules/ErrorHandler';
import reducers from './core/reducers';
import { getEnvVariable } from "./modules/environmentalList";
import { theme, aps_theme, wrs_theme } from './styles/material-theme';

const store = createStore(reducers, applyMiddleware(thunk));

function component(id, classes) {
    var element = document.createElement('div');
    element.setAttribute("id", id);
    classes.forEach(e => element.classList.add(e))
    return element;
};

function GetApp(moduleKey) {
    if (moduleKey === 'AMES')
        return lazy(() => import('./app/App_AMES'))
    if (moduleKey === 'WRS')
        return lazy(() => import('./app/App_WRS'))
}

function GetTheme(moduleKey) {
    if (moduleKey === 'AMES')
        return theme
    else if (moduleKey === 'WRS')
        return wrs_theme
    else return theme
}

const MaterialTheme = GetTheme(getEnvVariable('ModuleKey'))
const App = GetApp(getEnvVariable('ModuleKey'));


document.body.appendChild(component('ames-backdrop', ['ames-class']));
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <MuiThemeProvider theme={MaterialTheme}>
        <CssBaseline>
            <ErrorHandler>
                <Provider store={store}>
                    <Suspense fallback={<div ></div>}>
                        <App />
                    </Suspense>
                </Provider>
            </ErrorHandler>
        </CssBaseline>
    </MuiThemeProvider>
);



