import React, { Fragment, useEffect } from 'react';
//import '@progress/kendo-theme-material/dist/all.css';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DropDownIcon from '@material-ui/icons/VerticalAlignBottom';
import { ExternalDropZone, Upload, UploadFileStatus } from '@progress/kendo-react-upload';
import { OldPostMutiFormData } from '../../core/controls/helpers/requests';
import { requestApi } from '../../core/modules/form/utilities';
import { getEnvVariable } from '../environmentalList';


export default function S3Upload(props) {
  const { value, handleFieldValue, handleButtonClick, handleButtonSave, extraProps, changefieldto, shareProps, filetype, ...otherProps } = props
  const [files, setFiles] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  const [filePreviews, setFilePreviews] = React.useState({});
  const [affectedFiles, setAffectedFiles] = React.useState([]);

  const uploadRef = React.createRef();


  const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
  ];

  const handleClick = (e) => {

  }

  const onAdd = (event) => {
    setFiles(event.newState);
    console.log("fiels here", files)
    setEvents([...events, `File selected: ${event.affectedFiles[0].name}`]);
    setAffectedFiles(event.affectedFiles);
    if (props.onAddCallBack) {
      props.onAddCallBack(event.newState)
    }
  };

  const onCancel = (e) => {

  }

  const onProgress = (event) => {
    setFiles(event.newState);
    setEvents([...events, `On Progress: ${event.affectedFiles[0].progress} %`]);
  };


  const onRemove = (event) => {
    let newFilePreviews = { ...filePreviews };
    event.affectedFiles.forEach((file) => {
      delete newFilePreviews[file.uid];
    });
    setFiles(event.newState);
    setEvents([...events, `File removed: ${event.affectedFiles[0].name}`]);
    setFilePreviews(newFilePreviews);
  };

  const onStatusChange = (event) => {
    const file = event.affectedFiles[0];
    setFiles(event.newState);
    setEvents([
      ...events,
      `File '${file.name}' status changed to: ${fileStatuses[file.status]}`,
    ]);
  };

  const onRemoveRequest = (e) => {

  }
  const onSaveRequest = (e) => {

  }

  const onSuccess = (e) => {

  }

  React.useEffect(() => {
    affectedFiles
      .filter((file) => !file.validationErrors)
      .forEach((file) => {
        const reader = new FileReader();

        reader.onloadend = (ev) => {
          setFilePreviews({ ...filePreviews, [file.uid]: ev.target.result });
        };

        if (file && file.getRawFile) {
          reader.readAsDataURL(file.getRawFile());
        }
      });
  }, [affectedFiles, filePreviews]);

  useEffect(() => {
    if (props.canSave && files.length > 0) {
      console.log('AWS saving -------------')
      handleUploadClick();
    }
  }, [props.canSave])

  useEffect(() => {
    console.log('multiplefiles xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx', props.passresults)
  }, [props.passresults])

  const handleUploadClick = (e) => {
    let realFiles = [];
    var formData = new FormData();
    //formData.set('files', files.map(file => file.getRawFile()));
    files.forEach(file => {
      realFiles.push(file.getRawFile())
      formData.append('files', file.getRawFile(), file.name);
    }) //remove 2
    OldPostMutiFormData({ post: { url: getEnvVariable('Sidebar') + `/api/FileSystem/2/Upload` } }, null, null, formData, true, "Documents uploaded. Continue on save.")
      .then(res => {
        console.log(res);
        if (props.attachmentDetailsCallBack) {
          props.attachmentDetailsCallBack(res);
          setFiles([])
        }
        //alert('document uploaded.')
      })
  }

  const downloadAttachments = (file) => {
    console.log('downloading the file', file);
    let fileModle = { FileName: '2_' + file.FileReference + file.FileType, Folder: 'Attachments' }
    requestApi({ post: { url: getEnvVariable('Sidebar') + `/api/FileSystem/2/Download` } }, null, fileModle, null, true, "Document downloaded.")
      .then(res => {
        console.log(res);

      })
  }

  const fileDescriptions = () => {
    let returnfiles = [];
    if (files) {
      returnfiles = [...returnfiles, ...files];
    }
    if (props.passresults && Array.isArray(props.passresults.AttachmentDetails)) {
      props.passresults.AttachmentDetails.map((file, i) => {
        returnfiles.push({
          name: file.FileName,
          extension: file.ContentType,
          size: file.Size,
          progress: 0,
          status: UploadFileStatus.Initial,
          uid: file.FileReference,
        })
      })
    }
    return returnfiles;
  }


  const saveUrl = getEnvVariable('Sidebar') + `/api/FileSystem/2/Upload`;
  const removeUrl = getEnvVariable('Sidebar') + `/api/FileSystem/2/Remove`;
  const attachFileExtensions = getEnvVariable('AttachmentExtensions') ? getEnvVariable('AttachmentExtensions').split(",") : [];
  const imageFileExtensions = getEnvVariable('ImageExtensions') ? getEnvVariable('ImageExtensions').split(",") : [];
  const allowedExtensions = props.filetype == "Attachments" ? attachFileExtensions : props.filetype == "Images" ? imageFileExtensions : [];
  let defaultFiles = fileDescriptions();
  return (
    <div >
      <ExternalDropZone uploadRef={uploadRef}
        customHint={<small className='text-secondary'>Drag and drop files here to upload .</small>}
        customNote={(Array.isArray(allowedExtensions) && allowedExtensions.length > 0) ?
          (<small className='text-secondary'> Only {allowedExtensions.join("| ")} formats are allowed. </small>) :
          (<small className='text-secondary'> No specific file extension restrictions added. Any formats are allowed. </small>)}
      />
      <Upload ref={uploadRef}
        batch={false}
        showActionButtons={false}
        withCredentials={false}
        saveUrl={saveUrl}
        removeUrl={removeUrl}
        autoUpload={false}
        restrictions={{
          allowedExtensions: allowedExtensions,
        }}
        onAdd={onAdd}
        onRemove={onRemove}
        onProgress={onProgress}
        onStatusChange={onStatusChange}
        defaultFiles={[]}
        files={files}

      />
      {props.passresults && Array.isArray(props.passresults.AttachmentDetails) && props.passresults.AttachmentDetails.map((file, i) => {
        return <Fragment>
          <ListItem key={i} dense >
            <ListItemText alignItems="flex-start">{file.FileName || file.Id || 'Document ' + (i + 1)} </ListItemText>
            <ListItemSecondaryAction>
              <IconButton edge="end" color="primary" onClick={() => downloadAttachments(file)}>
                <DropDownIcon />
              </IconButton>
              {/* {this.props.edit && <IconButton edge="end" color="secondary" onClick={() => this.OpenConfirmationMessage(i)}>
                          <RemoveIcon />
                      </IconButton>} */}
            </ListItemSecondaryAction>
          </ListItem>
        </Fragment>
      })}
      {/* <button className="k-button" onClick={handleUploadClick}>
          My custom upload button
        </button> */}
      {/* <div
            className={"example-config"}
            style={{
            marginTop: 20,
            }}
        >
            <ul className={"event-log"}>
            {events.map((event, index) => (
                <li key={index}>{event}</li>
            ))}
            </ul>
        </div> */}
      {props.filetype == "Images" && files.length ? (
        <div className={"img-preview example-config"}>
          <div>Preview selected images</div>
          {Object.keys(filePreviews).map((fileKey, index) => (
            <img
              src={filePreviews[fileKey]}
              alt={"image preview"}
              style={{
                width: 200,
                margin: 10,
              }}
              key={index}
            />
          ))}
        </div>
      ) : undefined}
    </div >
  );




  // const UploadWrapper = () => <Upload
  //     batch={false}
  //     multiple={true}
  //     defaultFiles={[]}
  //     withCredentials={false}
  //     saveUrl={'https://demos.telerik.com/kendo-ui/service-v4/upload/save'}
  //     removeUrl={'https://demos.telerik.com/kendo-ui/service-v4/upload/remove'}
  //     />

  // const UploadWrapperProps = () => {
  //     return <Upload
  //     batch={false}
  //     multiple={true}
  //     defaultFiles={[]}
  //     withCredentials={false}
  //     //saveUrl={'https://demos.telerik.com/kendo-ui/service-v4/upload/save'}
  //     //removeUrl={'https://demos.telerik.com/kendo-ui/service-v4/upload/remove'}
  //     autoUpload={true}
  //     onAdd={onAdd}
  //     onCancel={onCancel}
  //     onProgress={onProgress}
  //     onRemove={onRemove}
  //     onStatusChange={onStatusChange}
  //     removeUrl={onRemoveRequest}
  //     saveUrl={onSaveRequest}
  //     />

  // }
}