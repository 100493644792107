/**
       *********************************************************
       Description: Layout for the header component. Needs to be modified a little
       Required props:   options, components, compmap. Each component in components has a alignitem prop to layout the component.
       Another configuration is responsive can be added in the json to make the component responsive. When the screen size
       decreases all the header components that has isresponsive are show as a menu.
       limitations    : N/A
       *********************************************************
       **/
       import AppBar from '@material-ui/core/AppBar';
       import Badge from '@material-ui/core/Badge';
       import Divider from '@material-ui/core/Divider';
       import IconButton from '@material-ui/core/IconButton';
       import Menu from '@material-ui/core/Menu';
       import MenuItem from '@material-ui/core/MenuItem';
       import { withStyles } from '@material-ui/core/styles';
       import Toolbar from '@material-ui/core/Toolbar';
       import Typography from '@material-ui/core/Typography';
       import UserIcon from '@material-ui/icons/AccountCircle';
       import SupportIcon from '@material-ui/icons/ContactSupport';
       import MoreIcon from '@material-ui/icons/MoreVert';
       import NotificationIcon from '@material-ui/icons/NotificationsActive';
       import moment from 'moment';
       import PropTypes from 'prop-types';
       import React, { Component, Fragment } from 'react';
       import { connect } from 'react-redux';
       import { withRouter } from 'react-router-dom';
       import { bindActionCreators } from 'redux';
       import { getProjectMessages, setNotifications } from '../../../actions';
       import { getEnvVariable } from '../../../../modules/environmentalList';
       import { getSignInToken } from '../../../modules/header';
       import { getLandingPagelogo } from './ImageLogos';
       import RightDrawer from './profilepopover';
       import HomeIcon from '@material-ui/icons/Home';
       import { GetData } from '../../../controls/helpers/requests';
       import { setProfile } from '../../../security/actions';
       
       const styles = theme => ({
           grow: {
               flexGrow: 1,
           },
       
           sectionDesktop: {
               display: 'none',
               [theme.breakpoints.up('md')]: {
                   display: 'contents',
               },
               height: 'inherit',
           },
           sectionNonDesktop: {
               // display: 'none',
               [theme.breakpoints.up('md')]: {
                   display: 'contents',
               },
               height: 'inherit',
           },
           sectionMobile: {
               display: 'flex',
               [theme.breakpoints.up('md')]: {
                   display: 'none',
               },
               height: 'inherit',
           },
       });
       
       class MuiEdgeLayout extends Component {
           state = {
               anchorEl: null,
               mobileMoreAnchorEl: null,
               userProfile: {},
               landinglogo: ''
           };
       
       
           componentDidMount() {
               this.loadInitialGrid();
           }
       
           componentDidUpdate(prevProps, prevState) {
               if (this.props.profile) {
                   let url = getEnvVariable('AccessPortal') === 'I' ? getEnvVariable('MasterAdmin') + `/api/${getEnvVariable('ModuleKey')}InternalUser/UserById/` : getEnvVariable('MasterAdmin') + `/api/ExternalUser/` + getEnvVariable('ModuleKey') + `/`;
                   let user = localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))
                   if (user.userId) {
                       GetData(url + user.userId).then(res => {
                           this.props.setProfile(false)
                           this.setState({ userProfile: res })
                       })
                   }
               }
           }
       
           loadInitialGrid = () => {
               let logo = getLandingPagelogo();
               this.setState({ landinglogo: logo }, () => {
                   let token = getSignInToken();
                   if (token)
                       this.props.setNotifications();
               })
           }
       
           handleNavigation = (url) => {
               this.props.history.push(url)
           }
       
           handleMenuClose = () => {
               this.setState({ anchorEl: null });
               this.handleMobileMenuClose();
           };
       
           handleMobileMenuOpen = event => {
               this.setState({ mobileMoreAnchorEl: event.currentTarget });
           };
       
           handleMobileMenuClose = () => {
               this.setState({ mobileMoreAnchorEl: null });
           };
       
           handleNotificationClick = () => {
               this.props.history.push('/notifications')
           }
       
           CSSBackgroundClasses = (style = '') => {
               let module = getEnvVariable('ModuleKey')
               switch (module) {
                   case 'APS':
                       return { background: 'linear-gradient(to right bottom, #ede7f6, rgba(0, 53, 95, 255))' }
                   case 'WRS':
                       return { background: 'linear-gradient(to right bottom,  #074078, #074078)' }
                       case 'FORWARD':
                        return { background: 'linear-gradient(to right bottom,  #074078, #074078)' }
                   // case 'AMES':
                   //     return { background: 'linear-gradient(to right bottom,  rgb(12, 15, 26), rgb(41,51,82))' }
                   case 'UPS':
                       return { background: 'linear-gradient(to right bottom,  #004785, #337ab7)' }
                   default:
                       return { background: '#2b3a42' };
               }
           }
       
           CSSColorClasses = (style = '') => {
               let module = getEnvVariable('ModuleKey')
               switch (module) {
                   case 'APS':
                       return 'rgba(0, 53, 95, 255)'
                   case 'AMES':
                       return '#fff'
                   default:
                       return '';
               }
           }
           /**
               *********************************************************
               Description: Renders the header.
               Required props:   options, components, compmap. Each component in components has a alignitem prop to layout the component.
                   Alignitem has left, right, title for aligning. Isresponsive property  puts the component inside the menu in the mobile screen.
                   Alignitem drawer needs to be removed.
               limitations    : N/A
               *********************************************************
               **/
       
           render() {
               const { options, components, defaults, order, key, type, compmap, handleDrawerOpen, notification, headerTitle } = this.props;
               const { mobileMoreAnchorEl, anchorEl } = this.state;
               const { classes, theme } = this.props;
               const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
               const { toolbaropt, menuopt, menuitemopt, iconbtnopt, ...otherOptions } = options
               let alignchildren = {
                   left: [],
                   right: [],
                   title: [],
                   drawer: [],
                   isresponsive: [],
                   isnotresponsive: [],
                   inhead: []
               }
               components.forEach(each => {
                   const { type, defaults, options } = each
                   const { alignitem, isresponsive } = options
                   const EachComp = compmap[each.type]
                   const child = <EachComp {...each} key={each.key} compmap={compmap} />
                   if (alignitem === 'left') {
                       alignchildren.left.push(child)
                       if (isresponsive === undefined) {
                           alignchildren.isnotresponsive.push(child)
                       }
                   }
                   if (alignitem === 'right') {
                       alignchildren.right.push(child)
                       if (isresponsive === undefined) {
                           alignchildren.isresponsive.push(child)
                       }
                   }
                   if (alignitem === 'title') {
                       alignchildren.title.push(child)
                       if (isresponsive === undefined) {
                           alignchildren.inhead.push(child)
                       }
                   }
                   if (alignitem === 'drawer') {
                       alignchildren.drawer.push(child)
                       if (isresponsive === undefined) {
                           alignchildren.inhead.push(child)
                       }
                   }
                   if (isresponsive) {
                       alignchildren.isresponsive.push(child)
                   }
                   else {
                       if (isresponsive === false) {
                           alignchildren.isnotresponsive.push(child)
                       }
                   }
               })
       
               /**
              *********************************************************
              Description: Handles rendering the header for the mobiles.
              Required props:   options, components, compmap. Each component in components has a alignitem prop to layout the component.
                  Another configuration is responsive can be added in the json to make the component responsive. When the screen size
                  decreases all the header components that has isresponsive are pushed into a menu on button.
              limitations    : N/A
              *********************************************************
              **/
               const isMenuOpen = Boolean(anchorEl);
       
       
       
               const renderMobileMenu = (
                   <Menu
                       anchorEl={mobileMoreAnchorEl}
                       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                       transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                       open={isMobileMenuOpen}
                       onClose={this.handleMenuClose}
                   >
                       <MenuItem disabled>
                           <IconButton color="inherit">
                               <UserIcon />
                           </IconButton>
                           {(this.props.accessPermissions && this.props.accessPermissions.name)}
                       </MenuItem>
                       <Divider />
                       <MenuItem onClick={() => window.open(getEnvVariable('HelpUI'))}>
                           <IconButton color="inherit">
                               <SupportIcon />
                           </IconButton>
                           <p> Help</p>
                       </MenuItem>
                       <Divider />
                       <MenuItem onClick={() => this.handleNavigation('/notifications')}>
                           <IconButton color="inherit">
                               <Badge max="9999" badgeContent={(notification && (notification === 0 ? '0' : notification)) || '0'} color="secondary">
                                   <NotificationIcon />
                               </Badge>
                           </IconButton>
                           <p>&nbsp;Notifications</p>
                       </MenuItem>
                       <Divider />
                       <RightDrawer accessPermissions={this.props.accessPermissions} mobile={true} compmap={this.props.compmap} userProfile={this.state.userProfile || null} />
                   </Menu>
               );
       
               return (
                   <div className={classes.grow}>
                       <AppBar position="static" {...otherOptions} style={this.CSSBackgroundClasses()}>
                           <Toolbar variant="dense" {...toolbaropt}>
                               <div className={this.props.accessPermissions && getEnvVariable('ModuleKey') !== 'WRS' ? classes.sectionDesktop : classes.sectionNonDesktop} >
                                   {headerTitle}
                                   {getEnvVariable('ModuleKey') === 'WRS'? !this.props.user && <Fragment height='80px'>
                                     
                                            </Fragment>:
                                            !this.props.user && <Fragment><img src={this.state.landinglogo || ''} className="d-inline-block" width='auto' height='80px' style={{ padding: '2px' }} />
                                            <label className="pt-2 pl-3" style={{ 'line-height' : '1',fontSize: '32px', color: this.CSSColorClasses() }}>{getEnvVariable('Title')}<br />
                                                <small style={{ fontSize: '20px', color: this.CSSColorClasses() }}>{getEnvVariable('SubTitle')}
                                                    {/* | <a className="btn btn-dark btn-sm" style={{ color: 'inherit' }} target={'_blank'} href={getEnvVariable('HelpUI')}>Help Center</a> */}
                                                </small></label>  </Fragment>
                                   }
                                   {alignchildren.left}
                                   {/* {getEnvVariable('ModuleKey') === 'APS' && <label style={{ fontSize: '32px', color: 'rgba(0, 53, 95, 255)' }}>{alignchildren.left}</label>} */}
                                   <div style={{ flexGrow: 1 }} />
       
                                   <div />
                                   {alignchildren.right}
                                   {this.props.user && <Fragment>
                                       <IconButton color="inherit" className="px-2 pb-2" title="Home" onClick={() => this.props.history.push('/')}>
                                           <HomeIcon />
                                       </IconButton>
                                       {'Welcome ' + (this.state.userProfile && this.state.userProfile.User && this.state.userProfile.User.FullName || (this.props.accessPermissions && this.props.accessPermissions.name)) + ' |'}
                                       &nbsp; {moment().format('LL')}  {/* &nbsp;{moment().format('HH:mm:ss')*/} |
                                       <a title="Help" className="ps-1" style={{ color:'inherit', textDecoration:'underline'}} href={getEnvVariable('HelpUI')} target='_blank'color="inherit">Help</a>

                                       <IconButton color="inherit" title="Unread Notifications" className="px-2" onClick={e => this.handleNotificationClick(e)}>
                                           <Badge max="9999" badgeContent={(notification && (notification === 0 ? '0' : notification)) || '0'} color="secondary">
                                               <NotificationIcon />
                                           </Badge>
                                       </IconButton>
                                       {/* <IconButton title="Help" className="px-3" style={{ color: 'inherit' }} href={getEnvVariable('HelpUI')} target='_blank' color="inherit">
                                           <SupportIcon />
                                       </IconButton> */}
                                       {/* <i className="" onClick={() => this.props.history.goBack()}><IconButton color="inherit" title="Back">
                                           <BackIcon />
                                       </IconButton></i> */}
                                       <RightDrawer accessPermissions={this.props.accessPermissions} compmap={compmap} userProfile={this.state.userProfile || null} />
                                   </Fragment>
                                   }
                               </div>
                               {this.props.accessPermissions && <div className={classes.sectionMobile}>
                                   {alignchildren.inhead}
                                   <div />
                                   <Typography variant={'h6'} className="pt-2">{getEnvVariable('TitleKey')}</Typography>
                                   <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit" {...iconbtnopt}>
                                       <MoreIcon />
                                   </IconButton>
       
                               </div>}
                           </Toolbar>
                           {this.props.accessPermissions && renderMobileMenu}
                       </AppBar>
                   </div>
               )
       
           }
       
       }
       
       MuiEdgeLayout.propTypes = {
           classes: PropTypes.object.isRequired,
       };
       MuiEdgeLayout = withStyles(styles)(MuiEdgeLayout);
       const mapProps = (state) => {
           return { user: state.authentication.user, profile: state.authentication.profile, notification: (state.header && state.header.notifications) || 0 }
       }
       const mapActions = dispatch => {
           return bindActionCreators({ setNotifications, getProjectMessages, setProfile }, dispatch);
       }
       
       MuiEdgeLayout = connect(mapProps, mapActions)(MuiEdgeLayout);
       export default withRouter(MuiEdgeLayout);
       
       
       
       
       
       
       
       
       
       
       
       
       
       