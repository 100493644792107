import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { loadModules } from 'esri-loader';
class CHome extends Component {
    constructor(props) {
        super(props); 
        this.state =  { 
            view: this.props.view
        } 
        this.startup(
            this.props
        ); 
    }   
     
    startup = (config) => {
        loadModules([
            "esri/widgets/Home"
        ]).then(([Home]) => {
            this.homecontainer = ReactDOM.findDOMNode(this);
            const home = new Home({ 
                view: config.view,
                container: this.homecontainer
            }); 
            config.view.ui.add(home, "top-left");
            this.props.widgetRenderCallBack("Home")
        });
    } 

  render() {
    return (
        <div className="home-box" style={{order:this.props.order}}>
          
        </div>
    );
  }
}

export default CHome;