import React, { Component } from 'react'
import Cron from './lib'
/*
Source : https://github.com/dealmeddevs/react-cron-generator;
Initial setup: Pooja
extended by : Narendra
*/
class CronComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value })
    }
  }

  render() {

    return (<div >
      <Cron
        onChange={(e) => { this.props.onChange(e) }}
        value={this.state.value}
        showResultText={true}
        showResultCron={true}
      />
    </div>)
  }

}

export default CronComponent
