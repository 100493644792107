import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import { PostMutiFormData } from '../../../../controls/helpers/requests';
import KendoGrid from '../../../simplegrid'
import Drawer from '@material-ui/core/Drawer';
import Header from '../../../../controls/components/header';
import ViewComfy from '@material-ui/icons/ViewComfy';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import { getEnvVariable } from '../../../../../modules/environmentalList';

const header = {
    name: "header",
    type: 'header',
    title: 'Excel Data',
    components: [
        {
            type: 'breadcrumbs',
            items: [
                { "link": "Excel Data", "materialicon": "excel", className: 'eps-breadcrumb-item ', class: 'text-light' },
            ]
        }
    ],
    actions: [
        {
            type: 'icon',
            icon: 'back',
            name: 'back',
            hideon: 'back',
            hidevalue: false
        },
    ],
}

export default function DynamicFilter({ result, handleDynamicResult }) {
    const [open, setOpen] = useState(false)
    const [columns, setColumns] = useState([])

    const handleExcelSave = () => {
        let url = getEnvVariable('AdminConfig')+`/api/BusinessRules/UploadFile?businessRuleId=` + (result.Id || 0)
        let file = document.getElementById('myFile');
        let formData = new FormData()
        formData.append(`File`, file.files[0]);
        PostMutiFormData(url, formData, true, true,'').then(res => {
            handleDynamicResult(res)
            //  handleColumns(res)
        }).catch(ex => console.log(ex));
    }

    const handleBack = () => {
        setOpen(false)
    }

    useEffect(() => {
        if ((result && result.CustomDataClxn && result.CustomDataClxn.length > 0)) {
            let gColumns = []
            let colObj = result && result.CustomDataClxn.length > 0 ? result.CustomDataClxn : null;
            if (colObj) {
                (Object.getOwnPropertyNames(colObj[0])).map(res => {
                    gColumns.push({ field: res, show: true, title: res })
                })
                setColumns(gColumns)
            }
        }
    }, [open])

    const addOnsByName = { addOnsByName: { back: { onClick: handleBack } } }

    return <div className="col-sm-12 row ">
        <div className="row col-sm-12 pb-2">
            <Button variant="contained" color="primary" component="label" >Upload Excel File<input type="file" id="myFile" accept=".xlsx, .xls, .csv" style={{ display: "none" }} onChange={handleExcelSave} /> </Button>
            {(result && result.CustomDataClxn && result.CustomDataClxn.length > 0) &&
                <div>
                    &nbsp;    <Chip color="primary" size="small" label={'Uploaded'} /> &nbsp;
                <IconButton color="primary" onClick={() => { setOpen(true) }} size="small" title={'View Imported Details'}> <ViewComfy /> </IconButton>
                    {open && columns.length > 0 &&
                        <Drawer anchor={'right'}
                            disableEnforceFocus={true}
                            disableAutoFocus={true}
                            open={open} onClose={() => setOpen(false)}
                            PaperProps={{ style: { width: (window.innerWidth / 2) } }}>
                            <Header {...header} {...addOnsByName} />
                            {(result && result.CustomDataClxn && result.CustomDataClxn.length > 0) &&
                                <KendoGrid data={result.CustomDataClxn} gridFields={columns} rules={true} />}
                        </Drawer>
                    }
                </div>
            }       </div>
    </div >
}