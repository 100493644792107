import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { GetData, PostData, PutData } from "../../core/controls/helpers/requests";
import { snackbarMessage } from '../../core/actions';
import FormHeader from '../../core/modules/form/cformheader';
import TemplateForm from '../../core/modules/template/template';

class TermsandConditions extends Component {

    constructor(props) {
        super(props); this.state = { editorState: null, result: {} };
        this.TemplateForm = React.createRef()
    }


    componentDidMount() {
        const { options } = this.props;
        const { editorState } = this.state;
        const { crud } = options;
        const { read, update } = crud;
        let url = read.get.url + this.props.dataItem['Id'];
        GetData(url).then(result => {
            if (result && result.Id) {
                this.setState({ resultExists: true, editorState: parseInt(atob(result.TermsAndConditionsStr)), result: result })
            }
            else this.setState({ editorState: null, resultExists: false })
        }).catch(error => {
           
        })
    }

    handleEditorTemplateId = (editorVal) => {
        this.handleSave(editorVal.id);
    }

    callTemplateSave = () => {
        this.TemplateForm.current.saveTemplate();
    }

    handleSave = (editorVal) => {
        const { options } = this.props;

        const { resultExists, result } = this.state;
        const { crud } = options;
        const { read, update } = crud;
        
        result.TermsAndConditionsStr = btoa(editorVal.toString());
        let url = ''
        if (!resultExists) {
            // 
            if (this.props.options.tabprops) {
                url = update.put.url + this.props.dataItem['Id'] + '/Add';
            }
            else {
                url = update.put.url + this.props.match.params.routeid + '/Add';
            }
            PostData(url, result).then(res => {
                this.setState({ resultExists: false })                
               
                if (!this.props.options.tabprops) {
                    this.props.onClose();
                }

            }).catch(error => {
                
                
            })
        }
        else {
            // 
            if (this.props.options.tabprops) {
                url = update.put.url + this.props.dataItem['Id'] + '/Update';
            }
            else {
                url = update.put.url + this.props.match.params.routeid + '/Update';
            }
            PutData(url, result).then(res => {
                this.setState({ resultExists: false })
                
                
                if (!this.props.options.tabprops) {
                    this.props.onClose();
                }

            }).catch(error => {
               
                
            })
        }

    }


    render() {
        const { options } = this.props;
        const { headerProps, title } = options;
        const { editorState, result } = this.state
        return (
            <div>
                <div className ="" Style= "position: relative ; bottom: 12px">
                <FormHeader title={title} handleSave={() => this.callTemplateSave()} savebutton={headerProps.savebutton} closebutton={headerProps.closebutton} handleClose={() => this.props.onClose()} designprops={options.designprops} />
                </div>
                <div>
                            <TemplateForm templateId={editorState} isEnabled={true} mentions={null} entryData={null} showComments={false}
                    hasSubject={false} ref={this.TemplateForm} handleTemplateId={(editorVal) => { this.handleEditorTemplateId(editorVal) }} isadmin={true} />
                </div>
            </div>
        )
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

TermsandConditions = connect(null, mapActions, null, { forwardRef: true })(TermsandConditions)
export default withRouter(TermsandConditions)