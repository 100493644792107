import { loadModules } from 'esri-loader';
import { QueryTask } from '../util/queryTask';



export function validateTaxParcelTask(config, queryexpression, parcelId) {
    return new Promise((resolve, reject) => {
        if (!loadModules) {
            reject('TaxParcelTask: ArcGIS API is not loaded');
            return;
        }

        if (config && config.TaxParcels) {
            if (queryexpression != null && queryexpression != "")
                config.TaxParcels.queryExpression = queryexpression;
            config.spatialReference = 4326;
            console.log('spatialReference here......4326')
            if (config.TaxParcels.queryExpression == null || config.TaxParcels.queryExpression == "" || config.TaxParcels.queryExpression == "1!=1") {
                resolve({ "Message": "Invalid Expression" });
            } else {
                QueryTask(config.TaxParcels, config.TaxParcels.queryExpression, config.spatialReference).then(
                    response => {
                        let parcelFeatures = response.features
                        if (parcelFeatures) {
                            console.log("-----------tax parcels are available here-----------")
                            if (parcelFeatures.length >= 1) {
                                let FinalPIN = null;
                                let ParcelIDwithoutsplchar = parcelId.replace(/[^\w\s]/gi, '');
                                for (let i = 0; i <= parcelFeatures.length; i++) {
                                    if (parcelFeatures[i] != undefined) {
                                        let PINwithoutsplchar = parcelFeatures[i].attributes.PIN.replace(/[^\w\s]/gi, '');
                                        //console.log(PINwithoutsplchar);
                                        if (ParcelIDwithoutsplchar == PINwithoutsplchar) {
                                            FinalPIN = PINwithoutsplchar;
                                            let grpcentroid = parcelFeatures[i].geometry.centroid;
                                            console.log(grpcentroid);
                                            console.log("--center is here---")
                                            resolve({ "taxParcelFlag": true, "grpcentroid": grpcentroid, "hidPIN": parcelFeatures[i].attributes.PIN });

                                        }
                                    }
                                }
                                if (ParcelIDwithoutsplchar !== FinalPIN) {
                                    //---correct: there is no confirm function
                                 //   let r = confirm("Tax Parcel ID entered is not valid. Do you want to override the validation?", true);
                                    if (true) {
                                        resolve({ "taxParcelFlag": true, "hidPIN": parcelId });
                                        return;
                                    } else  {
                                        resolve({ "taxParcelFlag": false });
                                        return;
                                    }
                                }

                            }
                            else {
                                //let r = confirm("Tax Parcel ID entered is not valid. Do you want to override the validation?",true);
                                if (true) {
                                    resolve({ "taxParcelFlag": true });
                                } else {
                                    resolve({ "taxParcelFlag": false });
                                }
                            }

                        }
                        else {
                            resolve({ "Message": "Tax Parcels not found" });
                        }
                    },
                    error => {
                        console.log(error);
                        reject({ "error": error });
                    }
                );
            }
        }
    });
};

