import React, { Fragment, useEffect, useState, useRef } from "react"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme) => ({
    root: {
        //width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));


export default function Tags(props) {
    const { name, Name, value, handleFieldChange, customnoticesave, options, dropdownvalues, dataItem, defaultSelValue, tooltip, edit } = props
    const classes = useStyles();
    let items = dropdownvalues || []
    let defaultitems = value || [];

    const handleChange = (event, values) => {
        let resultdata = values.map(a => a.Id);
        let stringformatvalues = [...new Set(resultdata)].join()

        const e = {
            target: {
                name: name || Name || (options && options.name),
                value: values

            }
        }
        defaultitems = values
        if (options && options.savestringformat) {
            const e1 = {
                target: {
                    name: name || Name || options.name,
                    value: stringformatvalues
                }
            }
            props.handleFieldChange(e1)
            defaultitems = dropdownvalues.filter(a => value.split(',').includes(a.Id.toString()))
        } else
            props.handleFieldChange(e)
    }

    if (options && options.savestringformat) {
        defaultitems = dropdownvalues ? dropdownvalues.filter(a => value.split(',').includes(a.Id.toString())) : []
    }


    if (dataItem && options && options.itemname) {
        if (dataItem[options.itemname] && dataItem[options.itemname] != "") {
            let dataitemvalue = dataItem[options.itemname];
            if (dataitemvalue) {
                dataitemvalue = dataitemvalue.toString();
                let dataitemarray = dataitemvalue.split(",");
                if (Array.isArray(dataitemarray)) {
                    defaultitems = items.filter(a => dataitemarray.includes(a.id.toString()))
                }
            }
        }
    } else if (defaultSelValue) {
        let selitemarray = defaultSelValue.split(",");
        if (Array.isArray(selitemarray)) {
            defaultitems = items.filter(a => selitemarray.includes(a.id.toString()))
        }
    }


    if (edit === false) {
        let resultdata = defaultitems.map(a => a.Name);
        let displayvalues = [...new Set(resultdata)].join()
        let getvalue = (items) => items.name || items.Name
        return <Fragment>
            {tooltip && <div > <Typography variant={'caption'} className="text-secondary">{options && options.label}&nbsp;
                 < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                    <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
                </Tooltip>  </Typography>  <Typography className="fw-bold" variant={'body2'} color={'textPrimary'} className="fw-bold">{displayvalues}   </Typography>
            </div>}
            {!tooltip && <div > <Typography variant={'caption'} className="text-secondary">{options && options.label}&nbsp; </Typography>   <Typography className="fw-bold" variant={'body2'} color={'textPrimary'} className="fw-bold">{displayvalues}   </Typography>
            </div>}
        </Fragment >
    }
    else {
        return (
            <div className={classes.root}>
                <Autocomplete
                    key={name || options && options.name}
                    fullWidth
                    multiple
                    name={name || Name}
                    id="tags-standard"
                    options={items}
                    getOptionLabel={(items) => items.name || items.Name}
                    onChange={handleChange}
                    filterSelectedOptions
                    defaultValue={items[0]}
                    value={defaultitems}
                    disabled={options && options.disabled}
                    renderInput={(params) => (
                        <TextField
                            fullWidth
                            {...params}
                            onChange={handleFieldChange}
                            name={name || Name}
                            variant="standard"
                            label={options && options.label}
                            placeholder={options && options.placeholder}
                            InputLabelProps={{
                                shrink: true,
                            }
                            }
                        />
                    )}
                />
            </div>
        );
    }
}

