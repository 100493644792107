import { createLayout } from './layout'
import Stepper from './stepper'
import Tabs from './tabs'
import Panel from './panel';

//arrangement- placemnets. (not componenets) // containers (popup , card, paper)
export const layoutTypes = {
    default: createLayout,
    stepper: Stepper,
    tab: Tabs,
    panel: Panel,
}