import React from 'react'
import { DIV } from '../helpers/native'

const deafultTypes = {
    div: DIV
}

export const createLayout = ({ layout, fields }) => {
    const { group } = layout || {}
    return (group || []).map((each, index) => {
        const { order, type, components, ...otherProps } = each
        return <div {...otherProps} key={order || index}>
            {components.map((each, index) => {
                const { name, sub, group, type, ...otherLayoutProps } = each
                // console.log('comp', fields[name])
                if (fields) {
                    // remove sub code and remove sub from json. sub is for nesting div's which seems to be rare
                    if (sub) {
                        const { type, key, className, ...otherProps } = sub
                        const { props } = fields[name]
                        return <div className={className + ' ' + props.className} {...otherProps} key={key || index} >
                            {fields[name]}
                        </div>
                    }
                    else if (group) {
                        const { type, key, className, ...otherProps } = each
                        return <div className={className} {...otherProps} key={key || index}>
                            <div className='border border-primary'>
                                {createLayout({ layout: each, fields: fields })}
                            </div>
                        </div>

                    }
                    else
                        if (type) {
                            const EachComp = deafultTypes[type]
                            return <div {...otherProps}>
                                {fields[name]}
                            </div>
                        }
                        else return fields[name]
                }
                else return null

            })}
        </div>
    })

}