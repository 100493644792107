import { Typography } from '@material-ui/core';
import React, { Component } from 'react';
export class Success extends Component {


  render() {
    return (
      <div >
        <Typography paragraph>Thank you! Your registration request has been submitted successfully.
          You will be receiving an email shortly.</Typography>
        <Typography variant="caption" display="block" gutterBottom color='secondary'>
          Disclaimer: If you can not find your confirmation/registration approval emails in your normal inbox, please check in your spam or junk mail section.
        </Typography>
      </div>
    );
  }
}

export default Success;