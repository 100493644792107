
export const GridFilters = (key) => {
    let type = 'text'
    switch (key) {
        case "bigint":/* bigint of Number Validation*/
            type = 'numeric'
            break;
        case "P":/* MasterFields:P-Percentage*/
            type = 'numeric'
            break;
        case "DN":/* MasterFields:DN-Decimal Number*/
        case "C":/* MasterFields:C-Currency*/
            type = 'numeric'
            break;
        case "int":/* int of Number Validation*/
        case "real":/* real of Number Validation*/
        case "N":/* MasterFields:N-Number*/
            type = 'numeric'
            break;
        case "char":/*char of String Validation*/
        case "varchar":/*varchar of String Validation*/
        case "nvarchar":/*nvarchar of String Validation*/
        case "TB":/*MasterFields:TB-TextBox*/
        case "TA":/*MasterFields:TA-Text Area (Multi Line)*/
        case "RB":/*MasterFields:RB-Radio Buttons (Single Choice)*/
        case "RC":/*MasterFields:RC-Radio Buttons (Yes/No)*/
        case "CB":/*MasterFields:CB- Check boxes (Multiple Choice)*/
        case "B":/* MasterFields:B-Binary (Yes/No)*/
        case "DS":/* MasterFields:DS-Dropdown (Single Choice)*/
        case "DM":/* MasterFields:DM-Dropdown (Multiple Choice)*/
        case "RT":/* MasterFields:RT-RichText (Allows Formatting)*/
        case "CM":/* MasterFields:CM-Combo Box (Multiple Value Input)*/
        case "CF":/* MasterFields:CF-Check Box Field*/
            type = 'text'
            break;
        case "date":/* Date Validation*/
        case "DT":/* MasterFields:DT-Date And Time*/
            type = 'date'
            break;
        case "D": /* MasterFields:D - Date Only(No Time)*/
            type = 'date'
            break;
        case "T":/* MasterFields:T- Time Only (No Date)*/
            type = 'date'
            break;
        case "varbinary":/* varbinary of blob Validation*/
            type = 'boolean'
            break;
        default:
            break;         
    }
    return type
}