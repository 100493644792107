import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CForm from '../../core/modules/form/cform';
import FormHeader from '../../core/modules/form/cformheader';
import { requestApi } from '../../core/modules/form/utilities';



class NotificationSettings extends Component {

    constructor(props) {
        super(props)
        this.state = {
            title: "Notification Settings",

            embedform: this.props.options.embedform,
            key: "nsform",
        }
        this.results = null;
        this.i = 0

    }

    handleState = (inputState) => {
        if (this.results === null && inputState.results.NotificationType === "SCHEDULED_NOTIFICATION") {
            this.results = inputState.results;
            let components = [...this.state.embedform.components]
            components.find(v => v.key === "nssubmodule").options.className = 'hideNSField'
            components.find(v => v.key === "nssitepermitype").options.className = 'hideNSField'
            components.find(v => v.key === "nseventon").options.className = 'hideNSField'
            components.find(v => v.key === "nseventdetails").options.className = 'hideNSField'
            components.find(v => v.key === "nseventtemplate").options.className = 'hideNSField'
            components.find(v => v.key === "nsschedulenotificationtemplate").options.className = 'showNSField'
            this.setState({
                embedform: {
                    ...this.state.embedform,
                    components: components
                },
                key: `nsform${this.i}`
            })
            console.log(this.state.embedform);
        }
        else if (this.results === null && (inputState.results.NotificationType === "EVENT_NOTIFICATION" || inputState.results.NotificationType === "BUSINESS_RULES_NOTIFICATION")) {
            this.results = inputState.results;
            let components = [... this.state.embedform.components]
            components.find(v => v.key === "nssubmodule").options.className = ' col-4 showNSField'
            components.find(v => v.key === "nssitepermitype").options.className = ' col-4 showNSField'
            components.find(v => v.key === "nseventon").options.className = ' col-4 showNSField'
            components.find(v => v.key === "nseventdetails").options.className = ' col-4 showNSField'
            components.find(v => v.key === "nseventtemplate").options.className = ' col-4 showNSField'
            components.find(v => v.key === "nsschedulenotificationtemplate").options.className = 'hideNSField'
            this.setState({
                embedform: {
                    ...this.state.embedform,
                    components: components
                }
            })
            console.log(this.state.embedform);
        }
        else if (this.results && this.results != inputState.results) {
            this.results = inputState.results;
            if (inputState.results.NotificationType === "SCHEDULED_NOTIFICATION") {

                let components = [... this.state.embedform.components]
                components.find(v => v.key === "nssubmodule").options.className = 'hideNSField'
                components.find(v => v.key === "nssitepermitype").options.className = 'hideNSField'
                components.find(v => v.key === "nseventon").options.className = 'hideNSField'
                components.find(v => v.key === "nseventdetails").options.className = 'hideNSField'
                components.find(v => v.key === "nseventtemplate").options.className = 'hideNSField'
                components.find(v => v.key === "nsschedulenotificationtemplate").options.className = ' col-4 showNSField'
                this.setState({
                    embedform: {
                        ...this.state.embedform,
                        components: components
                    }
                })
                console.log(this.state.embedform);
            }
            else if (inputState.results.NotificationType === "EVENT_NOTIFICATION" || inputState.results.NotificationType === "BUSINESS_RULES_NOTIFICATION") {
                let components = [... this.state.embedform.components]
                components.find(v => v.key === "nssubmodule").options.className = ' col-4 showNSField'
                components.find(v => v.key === "nssitepermitype").options.className = ' col-4 showNSField'
                components.find(v => v.key === "nseventon").options.className = ' col-4 showNSField'
                components.find(v => v.key === "nseventdetails").options.className = ' col-4 showNSField'
                components.find(v => v.key === "nseventtemplate").options.className = ' col-4 showNSField'
                components.find(v => v.key === "nsschedulenotificationtemplate").options.className = 'hideNSField'
                this.setState({
                    embedform: {
                        ...this.state.embedform,
                        components: components
                    }
                })
                console.log(this.state.embedform);
            }
        }
    }

    handleSave = (event) => {
        const { options } = this.props

        const { crud } = options
        const save = crud.update

        let ids = [], id
        if (Array.isArray(save.put.matchProps)) {
            save.put.matchProps.forEach(each => {
                const { value } = each
                ids.push({ key: value, value: this.props.match.params[value] })
            })
        }
        else {
            id = this.props.match.params.routeid
        }

        if (save) {

            requestApi(save, (id || ids), this.state.results).then(results => {

            }).catch(error => {
                console.log('eroor-----------------', error)
                error.then(err => {
                    console.log('error---', err)
                    if (err.errors) this.onError(err.errors)
                    else this.onError(err)
                })
            })
        }

    }

    render() {
        const { compmap, options } = this.props;
        // / breadcrumbs={breadcrumbs} correct: not defined
        return <div>
            <FormHeader title={this.state.title} handleSave={this.handleSave} savebutton={true} />
            <CForm  {...this.state.embedform} compmap={compmap} getState={this.handleState} />
        </div>
    }
}
export default withRouter(NotificationSettings)
