import { createTheme } from '@material-ui/core/styles';

const colors = {
    white: '#fafafa !important',
}
export const theme = createTheme({
    palette: {
        //   primary: index
    },
    typography: {
        fontFamily: [
            'Montserrat', 'sans-serif'
        ].join(','),
        subheading: {
            fontFamily: [
                'Montserrat', 'sans-serif',
            ].join(',')
        },
        h6: {
            color: "inherit"
        }
    },
    overrides: {
        MuiToolbar :{
            dense: {
                   minHeight: '43px !Important' 
            }
        },
        MuiCssBaseline: {
            '@global': {
                '*::-webkit-scrollbar': {
                    // width: '0.6em',
                    // height: '0.45em'
                },
                '*::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.5)',
                    outline: '1px solid slategrey'
                },
            }
        },
        MuiDrawer: {

        },
        MuiFab: {
            secondary: {
                backgroundColor: '#1976d2 !important'
            },

        },
        MuiButton: {
            text: {
                textTransform: 'none',
            },
            containedPrimary: {
                color: '#fafafa !important',
                backgroundColor: '#074078 !important'
            },

            root: {
                textTransform: 'none'
            }
        },
        // MuiIconButton: {
        //     /*  colorSecondary: {
        //           backgroundColor: '#212529 !important'
        //       }*/
        // },
        MuiIcon: {
            root: {
                lineHeight: 'initial'
            }
        },

        MuiCheckbox: {
            colorPrimary: {
                color: '#1976d2 !important',
            },
            colorSecondary: {
                color: '#1976d2 !important',
            }
        },
        // MuiSwitch: {
        //     colorPrimary: {
        //         color: '#1976d2 !important',
        //     },
        //     colorSecondary: {
        //         color: '#1976d2 !important',
        //     }
        // },
        MuiAppBar: {
            colorPrimary: {
                color: colors.white,
                backgroundColor: '#212529 !important'
            },
            colorSecondary: {
                color: colors.white,
                backgroundColor: '#1976d2 !important'
            },
            colorDefault: {
                //color: '#fafafa !important',
                color: colors.white,
                backgroundColor: '#42a5f5 !important'
            }
        },
        MuiTypography: {
            colorPrimary: {
                color: '#074078'
            },
            colorSecondary: {
                color: '#1f1d1d !important'
            },
            section:{
                color: '#ffffff !important'
            }

        },
        MuiFormControl: {
            root: {
                zIndex: 'auto'
            }
        },
        MuiExpansionPanel: {
            root: {
                MuiExpanded: false
            }
        },
        MuiInputBase: {
            root: {
                marginTop: '0px',
                color: 'grey'
            }
        },
        MuiInputLabel: {
            formControl: {
                marginTop: '0px'
            }
        },
        // MuiInput:{
        //     formControl: {
        //         marginTop: '1px !important'
        //     }
        // },
        MuiLinearProgress: {
            root: {
                height: 5
            }
        },
        MuiTab: {
            root: {
                'text-transform': 'capitalize'
            }
        },
        MuiIconButton: {
            root: {
                padding: '3px'
            },
            colorPrimary: {
                color: '#074078 !important',
            },
        },
        MuiAccordionDetails: {
            root: {
                padding: '0px'
            }
        },
        MuiFormLabel: {
            root: {
                color: 'black',
                fontWeight: 'bold'
            },
            asterisk: {
                color: 'red',
            }
        },
        MuiMenu: {
            paper: {
                maxHeight: '250px'
            }
        }
    }
});

export const aps_theme = createTheme({
    palette: {
        //   primary: index
    },
    typography: {
        fontFamily: [
            'Montserrat', 'sans-serif'
        ].join(','),
        subheading: {
            fontFamily: [
                'Montserrat', 'sans-serif',
            ].join(',')
        },
        h6: {
            color: "inherit"
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*::-webkit-scrollbar': {
                    // width: '0.6em',
                    // height: '0.45em'
                },
                '*::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.5)',
                    outline: '1px solid slategrey'
                },
            }
        },
        MuiDrawer: {
            
        },
        // added for WRS landing page -Vijaya
        MuiToolbar :{
            dense: {
                   minHeight: '43px !Important' 
            }
        },
        MuiFab: {
            secondary: {
                backgroundColor: '#1976d2 !important'
            },

        },
        MuiButton: {
            text: {
                textTransform: 'none',
            },
            containedPrimary: {
                color: '#fafafa !important',
                backgroundColor: '#074078 !important'
            },

            root: {
                textTransform: 'none'
            }
        },
        MuiIconButton: {
            /*  colorSecondary: {
                  backgroundColor: '#212529 !important'
              }*/
        },
        MuiIcon: {
            root: {
                lineHeight: 'initial'
            }
        },

        MuiCheckbox: {
            colorPrimary: {
                color: '#1976d2 !important',
            },
            colorSecondary: {
                color: '#1976d2 !important',
            }
        },
        // MuiSwitch: {
        //     colorPrimary: {
        //         color: '#1976d2 !important',
        //     },
        //     colorSecondary: {
        //         color: '#1976d2 !important',
        //     }
        // },
        MuiAppBar: {
            colorPrimary: {
                color: colors.white,
                backgroundColor: '#212529 !important'
            },
            colorSecondary: {
                color: colors.white,
                backgroundColor: '#3E7044 !important'
            },
            colorDefault: {
                //color: '#fafafa !important',
                color: colors.white,
                backgroundColor: '#42a5f5 !important'
            }
        },
        MuiTypography: {
            colorPrimary: {
                color: '#074078'
            },
            colorSecondary: {
                color: '#1f1d1d !important'
            },
            section: {
                color: '#ffffff !important'
            }

        },
        MuiFormControl: {
            root: {
                zIndex: 'auto'
            }
        },
        MuiExpansionPanel: {
            root: {
                MuiExpanded: false
            }
        },
        MuiInputBase: {
            root: {
                marginTop: '0px',
                color: 'grey'
            }
        },
        MuiInputLabel: {
            formControl: {
                marginTop: '0px'
            },
            asterisk:{
                color: 'red',
            }
        },
        // MuiInput:{
        //     formControl: {
        //         marginTop: '1px !important'
        //     }
        // },
        MuiLinearProgress: {
            root: {
                height: 5
            }
        },
        MuiTab: {
            root: {
                'text-transform': 'capitalize'
            }
        },
        MuiIconButton: {
            root: {
                padding: '3px'
            },
            colorPrimary: {
                color: '#074078 !important',
            },
        },
        MuiAccordionDetails: {
            root: {
                padding: '0px'
            }
        },
        MuiFormLabel: {
            root: {
                color: 'black',
                fontWeight: 'bold'
            }
        },
        MuiMenu: {
            paper: {
                maxHeight: '250px'
            }
        }
    }
});

export const wrs_theme = createTheme({
    palette: {
        //   primary: index
    },
    typography: {
        fontFamily: [
            'Montserrat', 'sans-serif'
        ].join(','),
        subheading: {
            fontFamily: [
                'Montserrat', 'sans-serif',
            ].join(',')
        },
        h6: {
            color: "inherit"
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*::-webkit-scrollbar': {
                    width: '0.6em',
                    height: '0.7em'
                },
                '*::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: '#074078 !important',//'rgba(0,0,0,.5)',
                    outline: '1px solid #074078'
                },
            }
        },
        MuiDrawer: {
            paper: {
                overflow: 'hidden'
            }
        },
        MuiFab: {
            secondary: {
                backgroundColor: '#1976d2 !important'
            },

        },
        MuiToolbar :{
            dense: {
                   minHeight: '43px !Important' 
            }
        },
        MuiButton: {
            text: {
                textTransform: 'none',
            },
            containedPrimary: {
                color: '#fafafa !important',
                backgroundColor: '#074078 !important'
            },

            root: {
                textTransform: 'none'
            }
        },
        // MuiIconButton: {
        //     /*  colorSecondary: {
        //           backgroundColor: '#212529 !important'
        //       }*/
        // },
        MuiIcon: {
            root: {
                lineHeight: 'initial'
            }
        },
        MuiRadio: {
            colorPrimary: {
                color: '#074078 !important',
            },
            colorSecondary: {
                color: '#074078 !important',
            }
        },
        MuiCheckbox: {
            colorPrimary: {
                color: '#074078 !important',
            },
            colorSecondary: {
                color: '#074078 !important',
            }
        },
        MuiToolbar :{
            dense: {
                   minHeight: '43px !Important' 
            }
        },
        MuiFab: {
            primary: {
                backgroundColor: '#074078 !important',
                color: 'white !important'
            },
            secondary: {
                backgroundColor: '#074078 !important',
                color: 'white !important'
            }
        },
        // MuiSwitch: {
        //     colorPrimary: {
        //         color: '#1976d2 !important',
        //     },
        //     colorSecondary: {
        //         color: '#1976d2 !important',
        //     }
        // },
        MuiAppBar: {
            colorPrimary: {
                color: colors.white,
                backgroundColor: '#074078 !important'
            },
            colorSecondary: {
                color: colors.white,
                backgroundColor: '#074078 !important'
            },
            colorDefault: {
                //color: '#fafafa !important',
                color: colors.white,
                backgroundColor: '#074078 !important'
            }
        },
        MuiTypography: {
            colorPrimary: {
                color: '#074078'
            },
            colorSecondary: {
                color: '#1f1d1d !important'
            },
            section: {
                color: '#ffffff !important'
            }
            // colorPrimary: { color: '#0076A5 !important' }

        },
        // MuiTab: {
        //     textColorPrimary: {
        //         MuiSelected: { color: '#0076A5 !important' }
        //     }
        // },
        MuiBox: {
            root: {
                padding: '20px !important'
            }
        },
        MuiFormControl: {
            root: {
                zIndex: 'auto'
            },
        },
        MuiExpansionPanel: {
            root: {
                MuiExpanded: false
            }
        },
        MuiInputBase: {
            root: {
                marginTop: '0px',
                color: 'grey'
            }
        },
        MuiInputLabel: {
            formControl: {
                marginTop: '0px'
            },
            asterisk:{
                color:'red'
            }
        },
        // MuiInput:{
        //     formControl: {
        //         marginTop: '1px !important'
        //     }
        // },
        MuiLinearProgress: {
            root: {
                height: 5
            }
        },
        MuiTab: {
            root: {
                'text-transform': 'capitalize'
            }
        },
        MuiIconButton: {
            root: {
                padding: '3px'
            },
            colorPrimary: {
                color: '#074078 !important',
            },
        },
        MuiAccordionDetails: {
            root: {
                padding: '0px'
            }
        },
        MuiFormLabel: {
            root: {
                color: 'black',
                fontWeight: 'bold'
            },
            asterisk:{
                color:'red'
            }

        },
        MuiMenu: {
            paper: {
                maxHeight: '250px'
            }
        },
    }
});
