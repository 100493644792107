import React, { Component, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import defaultImage from '../../core/resources/Default-UserImg.jpg'
import defaultSigImage from '../../core/resources/imageplaceholder.jpg'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { snackbarMessage } from '../../core/actions';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import DropDownIcon from '@material-ui/icons/VerticalAlignBottom';
import ConfirmationDialog from "../../core/controls/components/confirmationPopup";
import { getEnvVariable } from '../..//modules/environmentalList';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',

    },
    title: {
        color: '#f44336',
    },
    subtitle: {
        lineHeight: 1,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
});


class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openConfirmation: false,
            imagename: "",
            imageFormats: getEnvVariable("ImageFormats").split(",")

        };
    }

    handleFileUpload = (event) => {
        const name = event.target.name;
        const filename = event.target.files[0].name
        this.toBase64(event)
            .then(result => {
                var extension = filename.split(".")
                if (this.state.imageFormats.includes(extension[1].toLowerCase())) { //while spliting filename with '.' we get only 2 names(substrings) i.e., arr[0]:name & arr[1]:extension
                    var e = {
                        target: {
                            name: name,
                            value: result
                        }
                    }
                    this.props.handleFieldChange(e)
                }
                else {
                    this.props.snackbarMessage('Please upload files with correct extension, supported file extensions are JPEG, PNG, JPG and IMG.', 'error')
                }
            })
            .catch(ex => console.log(ex));
    }

    handleImageUpload = (event) => {
        const name = event.target.name;
        const filename = event.target.files[0].name
        this.toBase64(event)
            .then(result => {
                var extension = filename.split(".")
                if (this.state.imageFormats.includes(extension[1].toLowerCase())) { //while spliting filename with '.' we get only 2 names(substrings) i.e., arr[0]:name & arr[1]:extension
                    var e = {
                        target: {
                            name: name,
                            value: filename + ';//' + result
                        }
                    }
                    this.props.handleFieldChange(e)

                }
                else {
                    this.props.snackbarMessage('Please upload files with correct extension, supported file extensions are JPEG, PNG, JPG and IMG.', 'error')
                }
            })
            .catch(ex => console.log(ex));
    }
    toBase64 = (event) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        })
    };

    RemoveImage = (event) => {
        this.setState({ openConfirmation: true, imagename: event.target.name })
    }

    DownloadImage = (event) => {
        let image = this.props.value ? this.props.value.split(';//') : [];
        if (image.length > 0) {
            let Name = image[0]
            let ContentStr = image[1];

            const linkSource = ContentStr;
            const downloadLink = document.createElement("a");
            const fileName = Name;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }
    }

    ConfirmDelete = () => {
        this.setState({ openConfirmation: false })
        var e = {
            target: {
                name: this.state.imagename,
                value: ""
            }
        }
        this.props.handleFieldChange(e)
    }

    handleCancelDelete = () => {
        this.setState({ openConfirmation: false })
    }

    render() {
        const { classes, error, required, helpertext } = this.props;
        let image = this.props.value ? this.props.value.split(';//') : [];
        return (
            <Fragment>
                <InputLabel shrink={true} required={required} error={error}>Upload Image</InputLabel>
                <GridList className={classes.gridList} >
                    <GridListTile style={{
                   //     height: '100%',
                        width: '100%'
                    }}>
                        <img style={{ display: 'block',}} src={image && image.length > 0 ? (image[1]) : this.props.profile ? defaultImage : defaultSigImage} alt="Image" />
                        {this.props.value && <GridListTileBar
                            title={image && image.length > 0 ? (image[0]) : ('')}
                            titlePosition="top"
                            classes={{
                                root: classes.titleBar,
                                title: classes.title,
                            }}
                        />
                        }
                        {this.props.value && <GridListTileBar
                            classes={{
                                root: classes.titleBar,
                                title: classes.title,
                            }}
                            actionIcon={
                                this.props.edit && <span><Fab size="small" component="label" style={{ color: '#0091ea' }} >
                                    <DropDownIcon /> <input type="button" onClick={this.DownloadImage} name={this.props.name} hidden />
                                </Fab>
                                    <Fab size="small" component="label" style={{ color: '#0091ea' }} >
                                        <DeleteIcon /> <input type="button" onClick={this.RemoveImage} name={this.props.name} hidden />
                                    </Fab></span>

                            }
                        />
                        }
                        {!this.props.value && <GridListTileBar
                            title={this.props.text}
                            classes={{
                                root: classes.titleBar,
                                title: classes.title,
                            }}
                            actionIcon={
                                this.props.edit && <Fab size="small" component="label" style={{ color: '#0091ea' }}>
                                    <CloudUploadIcon /> <input type="file" name={this.props.name} onChange={this.props.imagesupload ? this.handleImageUpload : this.handleFileUpload} hidden />
                                </Fab>
                            }
                        />
                        }
                    </GridListTile>
                </GridList>
                {this.props.helperText && <FormHelperText error={error} >{this.props.helperText}</FormHelperText>}
                {!this.props.imagesupload && this.props.edit && <span className="row col text-secondary">Supported file type extensions are png and jpeg.</span>}
                {this.state.openConfirmation && <ConfirmationDialog value={this.state.openConfirmation} title="Are you sure you want to delete?" onSubmit={this.ConfirmDelete} onClose={this.handleCancelDelete} />}
            </Fragment>
        )
    }
}

const mapProps = (state) => {
    return state
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

FileUpload = connect(null, mapActions)(FileUpload)

export default withStyles(styles)(FileUpload);
