import React, { Fragment, useEffect, useState, useRef } from "react"
import Autocomplete from '../components/checkautocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ListItemSecondaryAction } from "@material-ui/core";
import FormHelperText from '@material-ui/core/FormHelperText';
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {
        //width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));



export default function Tags(props) {
    const { name, Name, value, handleFieldChange, formattype, customnoticesave, options, dropdownvalues, dataItem, defaultSelValue, tooltip, edit, transformProps, label, required, helperText, error } = props
    const classes = useStyles();
    let items = dropdownvalues || []
    //let defaultitems = value || [];

    // const handleChange = (event, values) => {
    // let resultdata = values.map(a => a.Id);
    // let stringformatvalues = [...new Set(resultdata)].join()

    // const e = {
    //     target: {
    //         name: name || Name || options.name,
    //         value: values
    //     }}

    // }
    // defaultitems = values
    // if (options.savestringformat) {
    //     const e1 = {
    //         target: {
    //             name: name || Name || options.name,
    //             value: stringformatvalues
    //         }
    //     }
    //     props.handleFieldChange(e1)
    //     defaultitems = dropdownvalues.filter(a => value.split(',').includes(a.id.toString()))
    // } else
    //     props.handleFieldChange(e)
    // }

    // if (options.savestringformat) {
    //     defaultitems = dropdownvalues ? dropdownvalues.filter(a => value.split(',').includes(a.id.toString())) : []
    // }


    // if (dataItem && options.itemname) {
    //     if (dataItem[options.itemname] && dataItem[options.itemname] != "") {
    //         let dataitemvalue = dataItem[options.itemname];
    //         if (dataitemvalue) {
    //             dataitemvalue = dataitemvalue.toString();
    //             let dataitemarray = dataitemvalue.split(",");
    //             if (Array.isArray(dataitemarray)) {
    //                 defaultitems = items.filter(a => dataitemarray.includes(a.id.toString()))
    //             }
    //         }
    //     }
    // } else if (defaultSelValue) {
    //     let selitemarray = defaultSelValue.split(",");
    //     if (Array.isArray(selitemarray)) {
    //         defaultitems = items.filter(a => selitemarray.includes(a.id.toString()))
    //     }
    // }



    let displayname
    if (transformProps) {
        if (value && value !== '' && !Array.isArray(value) && Array.isArray(items)) {
            let val = value && value.split(',');
            let valarray = items && items.filter(function (item) { return val.indexOf((item[transformProps.value]).toString()) > -1 });
            displayname = (_.map(valarray, transformProps.label)).join(', ')
        }
        else if (value && value !== '' && Array.isArray(value) && Array.isArray(items) && items && items.length > 0 && items[0].Id) {
            let val = value
            let valarray = []
            items.forEach(item => {
                if (val.includes(item.Id) || val.includes(String(item.Id))) {
                    valarray.push(item.Name)
                }
            });
            displayname = valarray.join(',')
        }
    }

    const handleFieldAutoChange = (evt) => {
        if (formattype === "A") {
            handleFieldChange({ target: { value: evt.target.value.split(','), name: evt.target.name, checked: false }, currentTarget: { dataset: null } })
        }
        else handleFieldChange(evt)
    }

    if (edit === false) {
        // let resultdata = defaultitems.map(a => a.name || a.Name);
        // let displayvalues = [...new Set(resultdata)].join()
        //let getvalue = (items) => items.name || items.Name
        return <Fragment>
            {tooltip && <div > <Typography variant={'caption'} className="fw-bold">{options.label}&nbsp;
                < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                    <HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#007bff' }} />
                </Tooltip>  </Typography>  <Typography className="text-secondary" variant={'body2'} color={'textPrimary'} >{displayname} </Typography>
            </div>}
            {!tooltip && <div > <Typography variant={'caption'} className="fw-bold">{options.label}&nbsp; </Typography>   <Typography className="text-secondary" variant={'body2'} color={'textPrimary'} >{displayname}  </Typography>
            </div>}
        </Fragment >
    }
    else {
        return (
            <div className={classes.root}>
                <Autocomplete
                    label={label}
                    error={error}
                    name={name || Name}
                    required={required}
                    ismulti={true}
                    value={value ? (formattype === 'A' ? value.join() : value) : ""}
                    items={(Array.isArray(items) && items) || []}
                    handleFieldValue={handleFieldAutoChange}
                    extraProps={{ transformProps: transformProps }}

                />
                {helperText && <FormHelperText className='mt-1' error={true}>{helperText}</FormHelperText>}

            </div>
        );
    }
}

