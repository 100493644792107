import MaterialTable from '@material-table/core';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { green, pink, teal } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import moment from 'moment';
import { Fragment } from "react";
import WorkflowUse from "../../../modules/workflow/workflowuse";
import CircularStatic from './daystimer';
import DefaultSettings from './settings';
import Editor from '../../modules/form/cwysiwyg'
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    teal: {
        color: theme.palette.getContrastText(teal[500]),
        backgroundColor: teal[500],
    },
}));


export default function Label(props) {
    const { value, handleFieldValue, handleButtonClick, extraProps, subname, shareProps, type, error, required, tooltip, contexthelp, changefieldto, color, changeTypefieldto, ...otherProps } = props
    const { label, helperText, name, items, xtype, fullWidth, ismultiple, datedisplay, disabled, isIcon, displayonedit, icon, className, variant, format, style, ...otherConfigs } = otherProps
    let { transformProps, nested, columns } = extraProps || {}
    let hidelabel = (name === 'UpdatedByName' || name === 'CreatedByName' || name === 'UpdatedOn' || name === 'CreatedOn') ? true : false
    let checktype = props && props.sharedProps && props.sharedProps.createduplicate && hidelabel ?
        'hidedisplay' : datedisplay ? 'historydate' : (type ? type : (changefieldto || changeTypefieldto))
    let val = ((value === null) || (value === undefined) || (value === '')) ? 0 : value;
    let labelval = ((value === null) || (value === undefined) || (value === '')) ? '' : value
    let finallabel = label
    let dateFormat = extraProps && extraProps.dateFormat ? extraProps.dateFormat : "MM/DD/YYYY"
    let dateMonthFormat = extraProps && extraProps.dateFormat ? extraProps.dateFormat : "MM/DD"
    
    let allitems = []


    if (items && Array.isArray(items)) {
        if (subname)
            allitems = items[subname]
        else allitems = items
    }
    else if (items && typeof items === 'object') {
        if (subname && Array.isArray(items[subname]))
            allitems = items[subname]
        else allitems.push(items)
    }
    else allitems = items;

    if (name === 'CreatedOn' || name === 'UpdatedOn') {
        labelval = value ? moment(value, "YYYY-MM-DDTHH:mm:ss").format("MM/DD/YYYY hh:mm:ss A") : '';
    }
    if (name === 'UpdatedOn' || name === 'UpdatedBy' || name === 'UpdatedByName') {
        finallabel = ((value === null) || (value === undefined) || (value === '')) ? '' : label
    }

    let checkedval
    if (checktype === 'CF' || 'checkbox') {
        checkedval = value ? (value === 'Y' ? 'Yes' : 'No') : ''
    }

    let dateval = ((value === null) || (value === undefined) || (value === '')) ? '' : value
    if (checktype === 'date') dateval = dateval ? moment(dateval).format(dateFormat) : dateval
    if (checktype === 'datemonth') dateval = dateval ? moment(dateval).format("MM/DD")  : '';
    if (checktype === 'time') dateval = dateval ? moment(dateval, "HH:mm:ss").format("hh:mm A") : '';
    if (checktype === 'timer') dateval = dateval ? moment(dateval, "HH:mm:ss").format("hh:mm A") : '';
    if (checktype === 'datetimepicker') dateval = dateval ? moment(dateval, "MM/DD/YYYY hh:mm:ss A").format("MM/DD/YYYY hh:mm:ss A") : '';


    let pvalue = 0
    if (xtype === 'P') {
        pvalue = value !== 0 ? value : 0
    }
    if (xtype === 'C') {
        let cformat = format ? format > 100 ? 2 : format : 2
        val = '$' + parseFloat(val).toFixed(cformat)
    }
    if (xtype === 'DN' || checktype === 'domainCalVal') {
        let cformat = format ? format > 100 ? 2 : format : 2
        val = parseFloat(val).toFixed(cformat)
    }
    if (xtype === 'P') {
        val = val + '%';
    }

    let displayname;
    if (transformProps) {
        if ((ismultiple || checktype === 'CB') && val && val !== '') {
            val = val && val.split(',');
            let valarray = (allitems || []).filter(function (item) { return val.indexOf((item[transformProps.value]).toString()) > -1 });
            displayname = (_.map(valarray, transformProps.label)).join(', ')
        } else {
            const selected = allitems && allitems.find(each => {
                if (value === null) {
                    return null
                }
                else if (value && !isNaN(value) && (each[transformProps.alternatevalue] === Number(value))) {
                    return each[transformProps.alternatevalue] === Number(value)
                }
                else if (value && (each[transformProps.alternatevalue] === value)) {
                    return each[transformProps.alternatevalue] === value
                }
                else if (typeof value === 'number' && each[transformProps.value])
                    return each[transformProps.value] === value
                else return (each[transformProps.value] && each[transformProps.value].toString()) === value
            })
            if (selected) {
                if (nested) {
                    displayname = selected[nested][transformProps.label]
                }

                else displayname = selected[transformProps.label]
            }
            else displayname = (allitems && allitems.length > 0) ? '' : value
        }
    }
    else {
        if (checktype !== 'TM' && checktype !== 'domainCalVal')
            if (Array.isArray(value)) {
                displayname = value && value.length > 0 ? value.join(', ') : value
            }
            else displayname = (allitems && allitems.length > 0 && value && ((allitems.find(each => each.value.toString() === value.toString()) || {}).name)) || value
    }
    // if(label === 'Status'){
    //     console.log(displayname)
    // }
    switch (checktype) {
        case 'customLabel':
            return (
                <FormControl fullWidth={fullWidth ? fullWidth : true} error={error} required={required}>
                    <Fragment>
                        <Typography variant={'caption'} color={color ? color : "#007bff"} className="fw-bold">{finallabel}&nbsp;
                        </Typography> </Fragment>

                </FormControl>
            )
        case 'DS':
        case 'AC':
        case 'RB':
        case 'RG':
        case 'CB':
        case 'dropdown':
        case 'checkboxgroup':
        case 'radiogroup':
        case 'autocomplete':
        case 'B':
        case 'LB':
        case 'DM':
        case 'checkauto':

            return (
                <FormControl fullWidth={fullWidth ? fullWidth : true} error={error} required={required}>
                    {
                        tooltip && <Fragment> <Typography variant={'caption'} className="fw-bold" >{label}&nbsp;
                            < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                                <HelpOutlineIcon fontSize="small" style={{ width: '0.6em', color: '#007bff' }} onClick={(evt) => contexthelp ?
                                    DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                            </Tooltip>
                        </Typography>
                        </Fragment>
                    }
                    {
                        !tooltip && <Fragment> <Typography className="fw-bold" variant={'caption'}  >{label} &nbsp;         </Typography>    </Fragment>
                    }
                    <Typography variant={'body2'} className="text-secondary" >{displayname}</Typography>
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
            )
        case 'hidedisplay':
            return null
        case 'date':
        case 'time':
        case 'timer':
        case 'datetimepicker':
        case 'datelabel':
        case 'datemonth':
        case 'D':
            return <Fragment>
                <div > <Typography variant={'caption'} className="fw-bold" >{label} &nbsp;</Typography>
                    <Typography variant={'body2'} className="text-secondary" >{dateval || ''}  </Typography>
                </div>
            </Fragment >   
        case 'DT':
                return <Fragment>
                    <div > <Typography variant={'caption'} className="fw-bold" >{label} &nbsp;</Typography>
                        <Typography variant={'body2'} className="text-secondary" >{dateval || ''}  </Typography>
                    </div>
                </Fragment >    
        case 'numberformat':
        case 'domainCalVal':
            return (
                <div>
                    {tooltip && <Fragment> <Typography variant={'caption'} className="fw-bold">{label} </Typography> &nbsp;
                        < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                            <HelpOutlineIcon fontSize="small" style={{ width: '0.6em', color: '#007bff' }} onClick={(evt) => contexthelp ? DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                        </Tooltip>  <Typography variant={'body2'} className="text-secondary">{val}   </Typography>  </Fragment>}
                    {!tooltip && <Fragment><Typography variant={'caption'} className="fw-bold">{label}&nbsp; </Typography>
                        <Typography variant={'body2'} className="text-secondary" >{val}   </Typography>  </Fragment>}
                </div>
            )
        case 'CF':
        case 'CSC':
        case 'checkbox':
            return (
                <FormControl fullWidth={fullWidth ? fullWidth : true} error={error} required={required}>
                    {tooltip && <Fragment> <Typography variant={'caption'} className="fw-bold"  >{label}&nbsp;
                        <Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                            <HelpOutlineIcon fontSize="small" style={{ width: '0.6em', color: '#007bff' }} onClick={(evt) => contexthelp ? DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                        </Tooltip> </Typography> </Fragment>
                    }
                    {!tooltip && <Fragment>
                        <Typography variant={'caption'} className="fw-bold">{label}&nbsp;
                        </Typography> </Fragment>
                    }
                    <Typography variant={'body2'} className="text-secondary" >{checkedval}   </Typography>
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
            )
        case 'multiadd':
            return (
                <FormControl fullWidth={fullWidth ? fullWidth : true} error={error} required={required}>
                    <MaterialTable
                        title=""
                        // style={{ height: '250px', overflow: 'auto' }}
                        columns={columns}
                        data={value}
                        options={{
                            search: false,
                            //  paging: false,
                            emptyRowsWhenPaging: true,
                            toolbar: false
                        }}
                    />
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
            )
        case 'button':
        case 'LB-BU':
        case 'RBT':
            if (displayonedit) {
                return null
            }
            else if (isIcon) {
                return <Fab onClick={handleButtonClick} name={name} title={label} disabled={true}  {...otherConfigs}>
                    < Icon > {icon}</Icon >
                </Fab >
            }
            else return (
                <div>
                    <Button
                        color={'default'}
                        variant={'outlined'}
                        disabled
                    //   onClick={handleButtonClick}
                    >
                        {label}
                    </Button>
                    {tooltip && <Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                        <HelpOutlineIcon fontSize="small" style={{ width: '0.6em', color: '#007bff' }} onClick={(evt) => contexthelp ? DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                    </Tooltip>}
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </div>
            )
        case 'historydate':
            return (
                <FormControl fullWidth={fullWidth ? fullWidth : true} error={error} required={required}>
                    <Fragment>
                        <Typography variant={'caption'} className="fw-bold">{finallabel}&nbsp;{labelval}
                        </Typography>
                    </Fragment>
                    {/* <Typography variant={'caption'} className="text-secondary" >{labelval}   </Typography> */}
                </FormControl>
            )
        case 'toggle':
            {
                let showvalue = 'In-Active';
                if (transformProps[value] === 'Y' || value === "Y") showvalue = 'Yes';
                if (transformProps[value] === 'N' || value === "N") showvalue = 'No';
                if (transformProps[value] === 'A' || value === "A") showvalue = 'Active';
                if (transformProps[value] === 'I' || value === "I") showvalue = 'In-Active';
                return <Fragment>
                    <div > <Typography variant={'caption'} className="fw-bold">{label} &nbsp;
                    </Typography>  <Typography variant={'body2'} className="text-secondary"  >
                            {showvalue}  </Typography>
                    </div>
                </Fragment >
            }
        case 'BU':
            return ""
        case 'TM':
            return <CircularStatic extraProps={extraProps} tooltip={tooltip} error={error} required={required} contexthelp={contexthelp} value={value} view={true} label={label} />
        case 'workflowuse': return (
            <WorkflowUse  {...props} />
        )
        case 'L':
        case 'link':
            return (
                <div style={{ color: '#007bff', fontSize: '12px' }} onClick={() => { window.open(value, '_blank').focus(); }} className="pointer" > <u>{label} &nbsp;</u>       </div>
            )
        case 'editor':
            return (
                <FormControl className={className} fullWidth={fullWidth ? fullWidth : true} error={error} required={required}>
                    <Editor name={name} edit={false} value={value} label={label} />
                </FormControl>
            )
        case 'SGN':
        case 'Signature':
            return (value ? <img src={value} /> : null)
        case 'SH':
            return (
                <FormControl className={className} color={'red'} fullWidth={fullWidth ? fullWidth : true} error={error} required={required}>
                    {tooltip && <Fragment> <Typography variant={'caption'} className="fw-bold" style={{color: '#074078' }}>{finallabel}&nbsp;
                        <Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                            <HelpOutlineIcon fontSize="small" style={{ width: '0.6em', color: '#007bff' }} onClick={(evt) => contexthelp ? DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                        </Tooltip> </Typography> </Fragment>
                    }
                    {!tooltip && <Fragment>
                        <Typography variant={'caption'} style={{color: '#074078' }} className="fw-bold" >{finallabel}&nbsp;
                        </Typography> </Fragment>
                    }
                    <Typography variant={'caption'} className="text-primary" style={{ overflowWrap: 'break-word' }}>{labelval}   </Typography>
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
            )    
        case 'phoneformat':
        case 'textfield':
        case 'TA':
        default:
            return (
                <FormControl className={className} fullWidth={fullWidth ? fullWidth : true} error={error} required={required}>
                    {tooltip && <Fragment> <Typography variant={'caption'} className="fw-bold" style={style}>{finallabel}&nbsp;
                        <Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                            <HelpOutlineIcon fontSize="small" style={{ width: '0.6em', color: '#007bff' }} onClick={(evt) => contexthelp ? DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                        </Tooltip> </Typography> </Fragment>
                    }
                    {!tooltip && <Fragment>
                        <Typography variant={'caption'} className="fw-bold" >{finallabel}&nbsp;
                        </Typography> </Fragment>
                    }
                    <Typography variant={'body2'} className="text-secondary" style={{ overflowWrap: 'break-word' }}>{transformProps ? displayname : labelval}   </Typography>
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
            )
    }
}


