/**        
      *********************************************************
      Description: Handles radio button. 
      Required props: type.
      limitations    : N/A
      *********************************************************
**/

import React, { Component, Fragment } from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CRadio = (props) => {
    const { handleFieldChange, value, name, color, label, labelPlacement } = props
    return (
        <Fragment>
            <FormControlLabel
                control={<Radio
                    color={color || 'primary'}
                    checked={value || false}
                    onChange={handleFieldChange}
                    name={name}
                />}
                label={label}
                labelPlacement={labelPlacement || 'end'}
            />
        </Fragment>
    )
}


export default CRadio;


