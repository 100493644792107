import * as React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import CSectionsContainer from './cSectionsContainer'


const CBuildDragDropContext = ({ droppables, errors, config, getSubSections, onDragEnd, OnAddSubSection, OnAddRow, OnMoveDown, OnMoveUp, CanShowMoveUp, CanShowMoveDown, OnSectionSettings, OnRowSettings, OnSectionRemove }) =>
    <div className="mainDragDropContext col-sm-12 pt-1" style={{ height: window.innerHeight - 115, overflow: 'auto' }}>
        <DragDropContext onDragEnd={onDragEnd}>
            <CSectionsContainer OnAddSubSection={OnAddSubSection} getSubSections={getSubSections} OnAddRow={OnAddRow} mainSections={config.mainSections} sections={config.sections} OnSectionSettings={OnSectionSettings} OnRowSettings={OnRowSettings} OnSectionRemove={OnSectionRemove} droppableId={config.droppable}
                clazzName={config.clazzName} wrapperWlazzName={config.wrapperWlazzName} droptype={config.droptype} direction={config.direction} OnMoveDown={OnMoveDown} OnMoveUp={OnMoveUp}
                CanShowMoveUp={CanShowMoveUp} CanShowMoveDown={CanShowMoveDown} errors={errors} isSectionsOpen={config.isSectionsOpen}
            />
        </DragDropContext>
    </div>

export default CBuildDragDropContext;
