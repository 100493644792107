import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { snackbarMessage } from '../../core/actions';
import { CustomColumnMenu } from '../../core/controls/components/customColumnMenu';
const _ = require('lodash');

function BillingGrid(props) {
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [gridColumns, setGridColumns] = useState([])
    //const [selectionValue, setSelectionValue] = useState(null)
    const [hasCheckbox, setHasCheckbox] = useState(props.hascheckbox)
    const [check, setcheck] = useState([])
    let submodulemenu = localStorage.getItem('submodule') && JSON.parse(localStorage.getItem('submodule')) ? JSON.parse(localStorage.getItem('submodule')) : []
    const [submodule, setSubmodule] = useState(submodulemenu)

    const rowRender = (trElement, row) => {
        const trProps = {
            ...trElement.props,
            onDoubleClick: () => {
                props.rowRenderData(row)
            }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    useEffect(() => {
        let checkdata = []
        submodule.forEach(element => {
            checkdata.push(element.key)
        });
        setcheck(checkdata)
    }, [submodule])


    useEffect(() => {
        if (data !== props.data) {
            setData(props.data);
        }
        setTotal(props.total);
        setGridColumns(props.gridColumns);
        setHasCheckbox(props.hascheckbox);
    }, [props])

    const handleSelection = (event) => {
        let finalitems = []
        if (check.includes(event.target.value)) {
            finalitems = _.remove(check, function (n) {
                return n !== event.target.value;
            });
        }
        else {
            finalitems = [...check]
            finalitems.push(event.target.value)
        }
        setcheck(finalitems);
        props.gridselectionLoad(finalitems);
    }


    const selectionChange = (event, propsdata) => {
        let selectData = data
        // const checked = event.syntheticEvent.target.checked;
        const datavalues = selectData.map(item => { //all records with selected : true, false
            if (propsdata && propsdata.dataItem && propsdata.dataItem.Id ? item.Id === propsdata.dataItem.Id : item.Id === event.dataItem.Id) {
                item.selected = propsdata && propsdata.dataItem && propsdata.dataItem.Id ? !propsdata.dataItem.selected : !event.dataItem.selected;
            }
            return item;
        });
        let selectionmapdata = datavalues.filter(res => { //selected records
            if (res.selected)
                return res.Id
        })
        let selectionmapdataid = selectionmapdata.map(res => { // selected record ids
            return res.Id
        })

        setData(datavalues);
        props.gridSelection(datavalues, selectionmapdata) //need confirmation selectionmapdata or selectionmapdataid
    }

    const headerSelectionChange = (event, gridprops) => {
        let selectData = data.map(a => ({ ...a }));
        let selectDataVal = data.map(a => ({ ...a }));
        const checked = event.syntheticEvent.target.checked;
        const dataValues = selectDataVal.map(item => {
            item.selected = checked;
            return item;
        });
        const datavalues = selectData.map(item => { //all records with selected : true, false
            if (item.Status !== "U") {
                item.selected = false;
            }
            else {
                item.selected = checked;
            }

            return item;
        });
        let selectionmapdata = datavalues.filter(res => { //selected records
            if (res.selected)
                return res.Id
        })
        let selectionmapdataid = selectionmapdata.map(res => { // selected record ids
            return res.Id
        })

        setData(dataValues);
        props.gridSelection(datavalues, selectionmapdata) //need confirmation selectionmapdata or selectionmapdataid
    }

    const StatusCustomCell = (props) => {
        return (
            <td>
                {props.dataItem[props.field] === 'U' ? "UnPaid" : props.dataItem[props.field] === 'P' ? "Paid" : props.dataItem[props.field] === 'C' ? "Cancelled" : "Partial Payment"}
            </td>
        );
    };

    const DateCustomCell = (props) => {
        return (
            <td>
                {props.dataItem[props.field] ? moment(props.dataItem[props.field]).format('MM/DD/YYYY') : ""}
            </td>
        );
    };

    const ColumnName = (str) => {
        const str2 = str.charAt(0).toUpperCase() + str.slice(1);

    }

    const GridTitle = (each) => {
        let title = each.charAt(0).toUpperCase() + each.slice(1);
        return title.replace(/([A-Z])/g, ' $1').trim()
    }

    return (
        <ReactResizeDetector handleWidth handleHeight>
            {({ width, height }) =>
                <div className="col-sm-12 col-xs-12 ">
                    <Grid
                        reorderable
                        style={{ height: window.innerHeight - 520, width: 'w-90' }}
                        resizable
                        data={data}
                        total={total}
                        rowRender={rowRender}
                        selectedField="selected"
                        onSelectionChange={selectionChange}
                        onHeaderSelectionChange={headerSelectionChange}
                    >
                        {props.issidebar && <GridToolbar >
                            <div className="col row pl-5">
                                <FormControl component="fieldset" >
                                    <FormGroup>
                                        <div className="row pl-2 ">
                                            {submodule.map(each => {
                                                return <FormControlLabel value={each.key} control={<Checkbox onChange={handleSelection} checked={check.includes(each.key)} size="small" />} label={<span style={{ fontSize: '14px' }}>{each.title}</span>} />
                                            })}
                                        </div>
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </GridToolbar>}
                        {hasCheckbox && <GridColumn
                            className="selected"
                            field="selected"
                            width="75px"
                            headerSelectionValue={
                                data && data.length > 0 && data.findIndex(dataItem => dataItem.selected === false) === -1

                            }
                            cell={props => <td>
                                <input type="checkbox" checked={props.dataItem['Status'] !== 'U' ? false : props.dataItem['selected']} disabled={props.dataItem['Status'] !== 'U'} onChange={(e) => selectionChange(e, props)} />
                            </td>}
                            columnMenu={
                                props =>
                                    <CustomColumnMenu
                                        {...props}
                                        // columns={datagridcol} //datagridcol: no declaration datagridcol
                                        saveReport={this.saveReport}
                                        onColumnsSubmit={this.onColumnsSubmit}
                                    />}
                        />}
                        {gridColumns && gridColumns.length > 0 && gridColumns.map((each, index) => {
                            if (each === 'Status')
                                return <GridColumn width={'200px'} field={each} title={GridTitle(each)} key={index} cell={StatusCustomCell} />
                            else if (each.includes("Date"))
                                return <GridColumn width={'200px'} field={each} title={GridTitle(each)} key={index} cell={DateCustomCell} />
                            else
                                return <GridColumn width={'200px'} field={each} title={GridTitle(each)} key={index} />
                        })}
                    </Grid>
                </div>
            }
        </ReactResizeDetector>
    )
}

const mapProps = (state) => {
    return state
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

BillingGrid = connect(mapProps, mapActions)(BillingGrid)
export default withRouter(BillingGrid)