/**
      *********************************************************
      Description: Grid header component. Handles creation of new records. Changes the route when the add icon is clicked
      Required props:  N/A.
      limitations    : N/A.
      *********************************************************
**/

import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import KendoHeader from '../../controls/components/kendoheader';
import { getEnvVariable } from '../../../modules/environmentalList';

const CGridHeader = ((props) => {

    const [openSlide, setOpenSlide] = useState(false);
    const { headerProps, actions, compmap, components, title, icon, accessPermissions, accessLevelPermissions,
        materialicon, add, breadcrumbs, backbutton, customprops, customId, customAdd } = props
    const { isAdmin } = accessPermissions ? accessPermissions : {}
    const hidereport = getEnvVariable('AccessPortal') === "E" ? true : false
    const addaccessPermissions = getEnvVariable('AccessPortal') === "I" ? (isAdmin ? (isAdmin === 'Y') : null) : (true)

    const CreateNewRecord = (options) => (event) => {
        const { crud } = options
        const { create } = crud
        const { link } = create;
        if (link && Array.isArray(link)) {
            let pushLink = '';
            link.forEach(each => {
                const { link, matchProps } = each
                pushLink += link + (matchProps ? (props.match.params[matchProps.value] && ('/' + props.match.params[matchProps.value])) : '');
                link && props.history.replace(`${pushLink}`)
            })
        }
        else if (link.type === 'sublink') {
            props.history.push(`${props.location.pathname}${link.link}`)
        }
        else {
            link && props.history.push(`${link.link}`)
        }

    }

    const handleAdd = () => {
        if (customAdd)
            props.handleAdd()
        else setOpenSlide(true)
        // props.handleFormGrid()
    }
    const handleSlide = (open) => {
        setOpenSlide(open)
        props.handleSlide()
    }


    return (
        <div >
            {headerProps && <KendoHeader initialloadextrainfo = {props.initialloadextrainfo} accessPermissions={accessPermissions} {...headerProps} reportapplied={props.reportapplied} {...{
                addOnsByName: {
                    add: {
                        onClick:
                            headerProps.addtype === 'CreateNewRecord' ? CreateNewRecord(headerProps.addactions[0].options) : handleAdd
                    }, excel: { onClick: props.export }, reports: { onClick: props.saveReport }, clear: { onClick: props.clearFilters }, back: { onClick: props.handleBack }, save: { onClick: props.handleSave }
                }
            }} />}
            {
                openSlide && components && components.map((each, index) => {
                    const EachComp = compmap[each.type]
                    return <Fragment key={index.toString()}><EachComp initialloadextrainfo = {props.initialloadextrainfo} className={each.key} {...each} key={each.key} handleSlide={handleSlide} addSubGrid={true} compmap={compmap} accessPermissions={accessPermissions}
                        accessLevelPermissions={accessLevelPermissions} customprops={customprops} customId={customId} /></Fragment>
                })
            }
        </div>

    );

}

)
export default withWidth()(withRouter(CGridHeader));
