import { getEnvVariable } from "../../../modules/environmentalList"

export const getbyModuleKey = () => {
    let key = getEnvVariable('ModuleKey')
    switch (key) {
        case 'KEPT':
        case 'AMES':
        case 'WRS':
            return false
        default: return true
    }
}
export const getbyModuleKeyYN = () => {
    let key = getEnvVariable('ModuleKey')
    switch (key) {
        case 'KEPT':
        case 'AMES':
        case 'WRS':
            return 'N'
        default: return 'Y'
    }
}

export const showGridToolBar = (list, item) => {
    let key = getEnvVariable('ModuleKey')
    switch (key) {
        case 'KEPT':
        case 'AMES':
        case 'WRS':
            if (list && list.length > 0 && item) {
                let bool = list.some(each => { return each.Id === parseInt(item) && (each.Stage === 'Submitted' || each.Stage === 'InProgress') })
                // if (getEnvVariable('AccessPortal') === 'E')
                //     return bool ? true : false
                return true
            }
            else return true
        default: return true
    }
}
export const showCanEdit = (list, item) => {
    let key = getEnvVariable('ModuleKey')
    switch (key) {
        case 'KEPT':
            //case 'AMES':
            if (list && list.length > 0 && item) {
                let boolItem = list.find(each => { return each.Id === parseInt(item) && (each.Stage === "Not_Submitted" || each.Stage === 'InProgress') })
                if (getEnvVariable('AccessPortal') === 'E')
                    return boolItem ? true : false
                else return true
            }
            else return true
        default: return true
    }
}
export const checkReferenceKey = (refercekey) => {
    if (refercekey != 'VIOLATIONS') {
        return true
    }

}
export const showEdit = (portal, isview, canedit) => {
    let key = getEnvVariable('ModuleKey')
    switch (key) {
        case 'KEPT':
        case 'AMES':
        case 'WRS':
            if (getEnvVariable('AccessPortal') === 'I') {
                return true
            }
            else if (getEnvVariable('AccessPortal') === 'E' && canedit) {
                if (portal === 'I')
                    return false;
                else if (portal === 'E' && isview === 'Y')
                    return true
                else if (portal === 'E' && isview === 'N')
                    return false
            }
            else return false
        default: return true
    }
}