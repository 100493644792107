import React, { useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Request from '../REST/groups';
import CreateInvoice from './create';
import ItemView from './items';
import Button from '@material-ui/core/Button';
import { getSchema } from '../expressions';
import { getEnvVariable } from '../../../src/modules/environmentalList';

const SCHEMA_URL = getEnvVariable('PaymentAPI') +'schema'
const INVOICE_URL = getEnvVariable('PaymentAPI') + 'invoicetype'

export default function ExpressionView({ group }) {

    const [selected, setSelected] = useState('')
    const [options, setOptions] = useState([]);
    const [schema, setSchema] = useState(null);

    useEffect(() => {
        Request(SCHEMA_URL).get().then(resp => {
            if (Array.isArray(resp.data))
                setOptions(resp.data.filter(e => e.groupId === group).map(e => { return { label: e.name, value: e.id, required: e.required } }))
        })
    }, [group])

    const handleChange = (evt) => {
        setSelected(evt.target.value)
    }

    useEffect(() => {
        if (selected !== '')
            getSchema(selected).then(resp => {
                setSchema(resp)
            })
    }, [selected])

    console.log(schema)

    return <div className='row'>
        <div className='col-12'>
            <FormControl fullWidth>
                <InputLabel>Select Schema</InputLabel>
                <Select value={selected} onChange={handleChange}>
                    {options.map((e, i) => {
                        return <MenuItem key={e.value} value={e.value}>{e.label}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </div>
        {selected !== '' && <div className='col-10 pt-3'>
            <InvoiceList schema={schema} id={selected} />
        </div>}
    </div>
}

function InvoiceList({ schema, id }) {

    const [list, setList] = useState([]);
    const [selected, setSelected] = useState(null);
    const [item, setItem] = useState(null);

    useEffect(() => {
        if (selected === null)
            Request(INVOICE_URL).getById(id).then(resp => {
                if (Array.isArray(resp.data))
                    setList(resp.data)
            })
    }, [id, selected])

    const handleSelect = (val) => {
        setSelected(val);
    }

    const handleItem = (val) => {
        setItem(val);
    }

    if (item)
        return <ItemView schema={schema} id={item} handleClose={() => setItem(null)} />

    if (selected)
        return <CreateInvoice group={id} schema={schema} json={list.find(e => e.id === selected)} handleClose={() => setSelected(null)} />


    return <table className='table table-hover table-bordered'>
        <thead>
            <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Key</th>
                <th><Button variant='outlined' size='small' color='secondary' onClick={() => handleSelect(-1)}>Create New</Button></th>
            </tr>
        </thead>
        <tbody>
            {list.map((e, idx) => {
                return <tr key={idx}>
                    <td>{e.name}</td>
                    <td>{e.description}</td>
                    <td><code>{e.id}</code></td>
                    <td>
                        <Button size='small' variant='outlined' onClick={() => handleSelect(e.id)}>Edit</Button>
                        <Button size='small' variant='outlined' onClick={() => handleItem(e.id)}>View Expressions</Button>
                    </td>
                </tr>
            })}
            {list.length === 0 && <tr><td colSpan={3}>No Data</td></tr>}
        </tbody>
    </table>

}

