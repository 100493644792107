

import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import TourIcon from '@material-ui/icons/LocalLibraryTwoTone';
import TermIcon from '@material-ui/icons/PlaylistAddCheck';
import StartIcon from '@material-ui/icons/Subtitles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showTour } from '../../core/actions';
import DefaultSettings from '../../core/controls/components/settings';
import { createRequest, requestApi as requestApiNew } from '../../core/controls/helpers/rest';
import { requestApi } from '../../core/modules/form/utilities';
import Map from '../../core/modules/map/map';
import { amesmap } from '../../json/map/ameslandingmap';
import SummaryJSON from '../../json/newapplication/newapplicationreview';
import { getEnvVariable } from '../../modules/environmentalList';
import WRSApplicationProperties from './create_record/wrs';
import { AMESMAPFIELDS, AMESStateFields } from './newentrance_fieldvalues';
import TermsAndCond from './termsconditions'
import { GetGISFields } from './gisdomainlookuphelper';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
const _ = require('lodash');


/**
*********************************************************
Description: Initializaing the application.
  formcontrolsconfigs: contains the intial configuration details of all the fields
  changedFieldName: Contains the fields whose values has been changed by the user.
  mapofficialusernames: Map id's of all offical users for the a particular owner to FirstName and LastName. This FirstName and LastName are used to prefill the
       fields in the owner section.
  mapIdPermitTypeId: maps siteid to permittype ids.
  applicationsite: Contains all sites.
  applicationsitevalue: Selected site value.
  sitepermittype: contains the sitepermittypes for a particular site.
  SITE_PERMIT_TYPE_ID. selected sitepermittype value.
  officialfirstname/officiallastname: handles owner section prefilling values with owner address. FirstName and LastName
          are filled with officialfirstname and officiallastname.


Required props:  Urls for official user, preparer, owner address, active sites, sitepermittypesites, validations, section fields, domain values, status change, fileupload.
limitations    :  Can be used only to create new application.
*********************************************************
**/
class NewEntrancePermitApplication extends Component {
    constructor(props) {
        super(props);
        this.mapIdPermitTypeId = {};
        if (!this.props.createrecordopen)
            this.state = {
                subModuleKey: props.match.params.key, mapchangedFieldName: [],
                applicationsite: [], applicationsitevalue: '', sitepermittype: [],
                formControls: {}, header: {}, map: false, taxparcelexp: '', applicationregion: [], applicationdistricts: [],
                mapClear: false, fetchGeomType: ['E'], userId: 1, mapsettings: { ...amesmap }, SITE_PERMIT_TYPE_ID: '', SUB_MODULE_ID: '', changedFieldvalues: {},
                isAdd: false, APPROACH_ADDRESS: '', MultipleLocationData: [], clearsketch: false, currentsitepermittypeid: null, configType: this.props.accessPermissions.configType,
                GISDomainLookupFields: [],townvillagecity:[],townvillagecityvalue:'',interstatehwy:[],interstatehwyvalue:'', disable:true,
                //fields
                ...AMESStateFields
            }
        else {
            this.state = this.props.createState
        }
        this.TemplateForm = React.createRef()
    }
    /**
        *********************************************************
        Description: Get all sites.
        Required props:  Url for getting all sites.
        limitations:  N/A
        *********************************************************
    **/
    componentDidMount() {
        const { allSites, allRegions, allProcessTypes, allDistricts, SitePermitTypesSubModuleKey, applicationProcessTypes, generalApplicationTypes,TownVillageCity,InterStateHwy } = SummaryJSON.options.allurls;
        if (this.props.match.params.key) {
            localStorage.setItem('TOUR_KEY', this.props.match.params.key)
        }
        let configurl = allSites;
        if (this.props.accessPermissions.configType === 'R')
            configurl = allRegions;
        else if (this.props.accessPermissions.configType === 'D')
            configurl = allDistricts;
        let arrayUrl = [
            { url: configurl.get.url, type: 'get' },
            { url: SitePermitTypesSubModuleKey.get.url, type: 'get' },
            { url: allDistricts.get.url, type: 'get' },
            { url: allSites.get.url, type: 'get' },
            { url :TownVillageCity.get.url, type: 'get'},
            { url: InterStateHwy.get.url, type: 'get'}
        ]
        if (getEnvVariable('ModuleKey') === 'WRS')
            arrayUrl.push({ url: applicationProcessTypes.get.url, type: 'get' }, { url: allProcessTypes.get.url, type: 'get' }, { url: generalApplicationTypes.get.url, type: 'get' })
        Promise.all(arrayUrl.map(each => requestApiNew(createRequest(each)))).then(res => {
            const [configs, types, districts, sites, townvillagecity=[],interstatehwy=[], proocess = [], whatwould = [], generalappltypes = []] = res;
            this.setState({
                applicationsite: sites,
                applicationregion: configs,
                applicationdistricts: districts,
                sitepermittype: types && types.length > 0 && types.filter(each => each.SubModuleKey === this.state.subModuleKey),
                townvillagecity:townvillagecity,
                interstatehwy: Array.isArray(interstatehwy) ? interstatehwy.sort((a, b) => a.name.localeCompare(b.name)) : [],
                processtype: proocess,
                whatwldTypes: whatwould,
                generalapptypes: generalappltypes,
                mapsettings: { ...amesmap }
            }, () => { this.loadMapSectionDefaultInformation(); this.getDefaultSitePermitType(this.state.sitepermittype) })
        }).catch(ex => {
            console.error(ex)
        })
    }
    getDefaultSitePermitType = (res) => {
        let defaultsitepermittypeid = ''
        if (res && res.length > 0) {
            let defaultvalue = _.find(res, { 'IsDefault': 'Y', 'SubModuleKey': this.state.subModuleKey });
            defaultsitepermittypeid = defaultvalue ? defaultvalue.Id : ''
            let mapconfig = this.state.mapsettings;
            let def_sitepermittypeid = defaultvalue ? defaultsitepermittypeid : res[0].Id;
            mapconfig.options.SITE_PERMIT_TYPE_ID = def_sitepermittypeid;
            this.setState({ SITE_PERMIT_TYPE_ID: def_sitepermittypeid, mapsettings: mapconfig },
                this.getSiteandPermitInfo('SITE_PERMIT_TYPE_ID', mapconfig.options.SITE_PERMIT_TYPE_ID));
            this.getSitePermitTypeGisFieldsData(def_sitepermittypeid);
        }
    }
    getSitePermitTypeGisFieldsData(sitepermittypeid) {
        if (sitepermittypeid) {
            GetGISFields(sitepermittypeid).then(res => {
                if (res && Array.isArray(res)) {
                    console.log('Gis fields data for the current sitepermit type', res);
                    this.setState({ GISDomainLookupFields: res });
                }
            })
        }
    }
    loadMapSectionDefaultInformation = () => {
        let mapdata = { ...amesmap };
        mapdata.options.FieldsData.forEach((each, index) => {
            each.FieldValue = ''
        })
        if (mapdata.options && mapdata.options.LayerOptions.length > 0)
            mapdata.options.LayerOptions[0].url = ""
        if (mapdata.options && mapdata.options.tasks && mapdata.options.tasks.TaxParcels) {
            mapdata.options.tasks.TaxParcels.url = "";
            mapdata.options.tasks.TaxParcels.queryexpression = "";
        }
        this.setState({ mapsettings: mapdata, REGION_ID: '', SITE_PERMIT_TYPE_ID: '', WHAT_WOULD_YOU_LIKE_TO_DO_: '' });
    }

    addTaxParcels = (taxparcelexp) => {
        let premapsettings = this.state.mapsettings
        if (taxparcelexp) {
            //Find index of specific object using findIndex method.
            let objIndex = premapsettings.options.FieldsData.findIndex((obj => obj.Fieldkey === "TAX_PARCEL_IDS"));
            if (objIndex > 0)
                premapsettings.options.FieldsData[objIndex].FieldValue = taxparcelexp
            this.setState({
                taxparcelexp: taxparcelexp, mapsettings: premapsettings,
                taxparcelallexp: taxparcelexp, ['TAX_PARCEL_IDS']: taxparcelexp
                // changedFieldvalues: { ...this.state.changedFieldvalues, ['TAX_PARCEL_IDS']: taxparcelexp }
            })
        }
        else this.setState({ taxparcelexp: '', ['TAX_PARCEL_IDS']: taxparcelexp, }) //changedFieldvalues: { ...this.state.changedFieldvalues, ['TAX_PARCEL_IDS']: '' }
    }
    getSiteandPermitInfo = (name, value) => {
        const { SitePermitTypesRegions, } = SummaryJSON.options.allurls
        const read = [{
            url: SitePermitTypesRegions.get.url,
            type: 'get'
        },]
        let regioninitials
        let configId = this.state.configType === 'R' ? this.state.REGION_ID : this.state.configType === 'S' ? this.state.EXISTING_COUNTY : this.state.DISTRICT_ID;
        if (name === 'EXISTING_COUNTY' || name === 'REGION_ID' || name === 'SITE_ID') {
            requestApi({ get: { url: SitePermitTypesRegions.get.url, routeProps: {} } }, value).then(retresults => {
                let results = retresults || [];
                let siteinitials = this.state.applicationregion.find(each => {
                    return each.Id === configId
                }).Initials
                results.forEach(each => {
                    const { Id, PermitTypeId } = each
                    this.mapIdPermitTypeId[Id] = PermitTypeId
                })
                this.setState({
                    sitepermittype: results,
                    regionInitials: siteinitials
                })
            })
        }
    }
    handleFieldChange = (event) => {
        const { name, value } = event.target
        const interstateprops = {}
        let mapconfig = this.state.mapsettings;
        if (name === 'SITE_PERMIT_TYPE_ID') {
            mapconfig.options.SITE_PERMIT_TYPE_ID = value
        }
        if(name==='TOWN_VILLAGE_CITY')
        {
            this.onTypeSlectionChange(value, false)
        }
        if(name==='INTERSTATE_HWY' || name==='INTERSTATE_HIGHWAY_NETWORK')
        {
            this.onTypeSlectionChange(value, false);
            interstateprops['interstatehwyvalue'] = value;
            interstateprops['INTERSTATE_HWY'] = value;
            interstateprops['UI_INTERSTATE_HWY'] = value;
        }
        this.setState({
            [name]: value, agreement: name === 'SITE_PERMIT_TYPE_ID' ?
                false : this.state.agreement, clearsketch: name === 'SITE_PERMIT_TYPE_ID' ? !this.state.clearsketch : this.state.clearsketch,
            mapsettings: mapconfig,
            ...interstateprops
        }, () => {
            if (name === 'SITE_PERMIT_TYPE_ID') {
                this.getSitePermitTypeGisFieldsData(event.target.value);
            }
            if (name === 'REGION_ID' || name === 'EXISTING_COUNTY' || name === 'SITE_PERMIT_TYPE_ID' || name === 'INQUIRY_SITE_PERMIT_TYPE_ID') {
                this.getSiteandPermitInfo(name, value);
                this.onTypeSlectionChange(false)
            }
            else
                if (name === 'WHAT_WOULD_YOU_LIKE_TO_DO_') {
                    this.onTypeSlectionChange(value, false)
                }
        });
    }
    moveToSections = () => {
        const { mapchangedFieldName, WHAT_WOULD_YOU_LIKE_TO_DO_ } = this.state;
        let proceed = true;
        let tempchangedFieldvalues = this.state;
        let fetchGeomType = this.getFetchGeomType(WHAT_WOULD_YOU_LIKE_TO_DO_);
        //Remove un used county data
        if (fetchGeomType.length === 1) {
            if (fetchGeomType.includes("E")) {
                tempchangedFieldvalues['PROPOSED_COUNTY'] = ""
            } else if (fetchGeomType.includes("P")) {
                tempchangedFieldvalues['EXISTING_COUNTY'] = ""
            }
        }
        if (this.state['CREATE_APPLICATION'] && this.state['CREATE_APPLICATION'] != 'AE') {
            tempchangedFieldvalues['SITE_ID'] = this.state.EXISTING_COUNTY
        }
        if (this.state['CREATE_APPLICATION'] && this.state['CREATE_APPLICATION'] == 'AE') {
            tempchangedFieldvalues['INQUIRY_STATUS'] = this.state.APPLICATION_STATUS
        }
        //  tempchangedFieldvalues['state'] = this.state
        if (getEnvVariable('AccessPortal') === 'E')
            proceed = this.state.agreement ? true : false
        if (proceed)
            this.setState({
                ...tempchangedFieldvalues,
            }, () => this.props.LoadInitialPage(tempchangedFieldvalues))
        else {
            if (DefaultSettings.getAlert() != null)
                DefaultSettings.getAlert().show('Please Agree Terms and Conditions to Proceed', 'error');
        }
        //  }
    }
    //Auto Populate Initail Page Dropdown Values --Wrs-Project
    InitialPageDropDown(name,valueobj){
        if(name==='MUNICIPALITY_TYPE')
        {
            let m_value =valueobj.MUNICIPALITY_TYPE
            if(m_value=='C'){
                return this.setState({townvillagecityvalue:'1'});
            }
           else if(m_value=='T'){
                return this.setState({townvillagecityvalue:'3'});
            }
            else if(m_value=='V'){
                return this.setState({townvillagecityvalue:'2'});
            }
        }
        
    }
    onSaveorUpdateMapResponse = (maplocation) => {
        console.log('onSaveorUpdateMapResponse-------------');
        //   let tempchangedFieldvalues = this.state.changedFieldvalues;
        let premapsettings = this.state.mapsettings
        const maplocationkeys = Object.keys(maplocation)
        const newheader = {}, newformControls = {}, newchangedFieldName = {}
        if (maplocationkeys && maplocationkeys.length > 0) {
            console.log('Map information selected-------------', maplocation)
            // if (DefaultSettings.getAlert() != null)
            //     DefaultSettings.getAlert().show('Map points Saved Successfully', 'success');
            maplocation['PROPOSED_MAP_CENTER_LATITUDE'] = maplocation['PROPOSED_MAP_CENTER_LATITUDE'] ? maplocation['PROPOSED_MAP_CENTER_LATITUDE'].toFixed(6) : maplocation['PROPOSED_MAP_CENTER_LATITUDE']
            maplocation['PROPOSED_MAP_CENTER_LONGITUDE'] = maplocation['PROPOSED_MAP_CENTER_LONGITUDE'] ? maplocation['PROPOSED_MAP_CENTER_LONGITUDE'].toFixed(6) : maplocation['PROPOSED_MAP_CENTER_LONGITUDE']
            maplocation['MAP_CENTER_LATITUDE'] = maplocation['MAP_CENTER_LATITUDE'] ? maplocation['MAP_CENTER_LATITUDE'].toFixed(6) : maplocation['MAP_CENTER_LATITUDE']
            maplocation['MAP_CENTER_LONGITUDE'] = maplocation['MAP_CENTER_LONGITUDE'] ? maplocation['MAP_CENTER_LONGITUDE'].toFixed(6) : maplocation['MAP_CENTER_LONGITUDE']
          
            maplocationkeys.forEach(each => {
                newheader[each] = maplocation[each]
                premapsettings.options.FieldsData.map(fd => {
                    if (fd.Fieldkey === each) {
                        fd.FieldValue = maplocation[each];
                    }
                })
                newformControls[each] = { ...this.state.formControls[each], value: maplocation[each] }
                newchangedFieldName[each] = maplocation[each]
            })
            if (maplocation['SELECT_LAYER_URL']) {
                newchangedFieldName['TAX_PARCEL_LAYER'] = maplocation['SELECT_LAYER_URL'];
                if (premapsettings.options.tasks && premapsettings.options.tasks.TaxParcels) {
                    premapsettings.options.tasks.TaxParcels.url = maplocation["SELECT_LAYER_URL"]
                    premapsettings.options.tasks.TaxParcels.queryexpression = maplocation["TAX_PARCEL_IDS"]
                    premapsettings.options.tasks.TaxParcels["PRIMARY_TAX_PARCEL_IDS"] = maplocation["PRIMARY_TAX_PARCEL_IDS"]
                }
            }
            if(getEnvVariable('ModuleKey')==='WRS' && maplocation)
            {
                this.InitialPageDropDown('MUNICIPALITY_TYPE',maplocation);// Method Call for Auto Populate Dropdown Values --Wrs-Project
                //this.InitialPageDropDown('INTERSTATE_HWY',maplocation)
            }
            this.setState({
                header: { ...this.state.header, ...newheader },
                formControls: { ...this.state.formControls, ...newformControls },
                mapchangedFieldName: { ...this.state.formControls, ...newformControls },
                mapsettings: premapsettings,
                mapClear: false,
                interstatehwyvalue:maplocation.INTERSTATE_HWY,// Interstate Highway value in wrs initial page
                ...newchangedFieldName
            })
        } else {
            let mapdata = { ...amesmap };
            mapdata.options.FieldsData.forEach((each, index) => {
                each.FieldValue = ''
            })
            this.clearMapChangedFields();
            this.setState({
                header: { ...this.state.header, ...newheader },
                formControls: {},
                mapchangedFieldName: {},
                mapsettings: mapdata,
                mapClear: false
            })
        }
    }
    clearMapChangedFields() {
        const { changedFieldvalues } = this.state;
        let tempchangedFieldvalues = {};
        AMESMAPFIELDS.map(each => {
            tempchangedFieldvalues[each] = ''
        })
        this.setState({ ...tempchangedFieldvalues })
    }
    getMapInformation = () => {
        return {
            ...this.state.mapchangedFieldName,
            taxparcelexp: this.state.taxparcelexp,
            taxparcelallexp: this.state.taxparcelexp,
            isnewObjectId: this.state.isnewObjectId ? 1 : 0
        }
    }
    getTaxParcelCustomNumber = (regionid) => {
        const { applicationregion } = this.state
        if (getEnvVariable('ModuleKey') === 'AMES') {
            let current = applicationregion.find(each => each.Id.toString() === regionid.toString())
            if (current)
                return current.Initials + ('000000000000').substring(current.Initials.length);
        }
        return ''
    }
    setStateMapInformation = (data) => {
        console.log('setStateMapInformation----');
        let tempchangedFieldvalues = {};
        if (data && Object.keys(data).length > 0 && Object.keys(data)[0] !== 'undefined') {
            let regionid = this.getByGISlayerID("region", data["REGION_GISID"]);
            let districtid = ((this.state.applicationdistricts || []).find(each => each.Id === this.getByGISlayerID("district", data["DISTRICT_GISID"])) || {}).Id;
            let siteid = this.getByGISlayerID("site", data["SITE_GISID"]);
            let taxparclelayer = data["TAX_PARCEL_LAYER"] || this.state['TAX_PARCEL_LAYER']
            let approachaddress = data["APPROACH_ADDRESS"];
            let taxparcelexp = data["TAX_PARCEL_IDS"];
            //.toFixex(6)
            if (!this.state["TAX_PARCEL_IDS"]) {
                data["TAX_PARCEL_IDS"] = this.getTaxParcelCustomNumber(regionid);
            }
            let configType = this.props.accessPermissions.configType
            let configId = siteid
            let subModuleKey = this.state.subModuleKey;
                        //let isuserhasaccess = this.hasUserConfigAccess(siteid+'', this.props.accessPermissions)

            // if (regionid !== this.state.REGION_ID || districtid !== this.state.DISTRICT_ID || siteid !== this.state.EXISTING_COUNTY) 
            let isadmin = this.props.accessPermissions.isAdmin;

           let isuserhasaccess = this.hasUserConfigAccess(siteid+'', this.props.accessPermissions)
            if(!isuserhasaccess && isadmin && isadmin != "Y")
            {
                DefaultSettings.getAlert().show('Access Denied for Selected County.', 'error');
                this.setState({
                    mapClear: true,
                    REGION_ID: '',
                    SITE_ID:'',
                    DISTRICT_ID: '',
                    EXISTING_COUNTY: '',
                    PROPOSED_COUNTY: ''
                });
            }
            else if (regionid !== this.state.REGION_ID || districtid !== this.state.DISTRICT_ID || siteid !== this.state.EXISTING_COUNTY) 
            {
                    tempchangedFieldvalues['REGION_ID'] = regionid
                    tempchangedFieldvalues['DISTRICT_ID'] = districtid
                    tempchangedFieldvalues['SITE_ID'] = siteid
                    tempchangedFieldvalues['EXISTING_COUNTY'] = siteid
                    tempchangedFieldvalues['PROPOSED_COUNTY'] = siteid
                    tempchangedFieldvalues['TAX_PARCEL_LAYER'] = taxparclelayer
                    tempchangedFieldvalues['APPROACH_ADDRESS'] = approachaddress
                    const { SitePermitTypesBasedOnConfigType } = SummaryJSON.options.allurls;
                    requestApi({ get: { url: SitePermitTypesBasedOnConfigType.get.url + "/" + configId + "/" + configType + "/" + subModuleKey, routeProps: {} } }, null).
                        then(results => {
                            let regioninitials = "";
                            results.forEach(each => {
                                const { Id, PermitTypeId } = each
                                this.mapIdPermitTypeId[Id] = PermitTypeId
                            })
                            let premapsettings = this.state.mapsettings;
                            if (premapsettings.options && premapsettings.options.LayerOptions.length > 0) {
                                premapsettings.options.LayerOptions[0].url = taxparclelayer
                            }
    
                            this.setState({
                                ...tempchangedFieldvalues,
                                sitepermittype: results,
                                regionInitials: regioninitials,
                                REGION_ID: regionid,
                                DISTRICT_ID: districtid,
                                EXISTING_COUNTY: siteid,
                                PROPOSED_COUNTY: siteid,
                                SITE_ID: siteid,
                                TAX_PARCEL_LAYER: taxparclelayer,
                                APPROACH_ADDRESS: approachaddress,
                                ...data,
                                mapsettings: premapsettings,
                                taxparcelallexp: taxparcelexp,
                            })
                        }).catch(ex => { })
            }
        } else {
            this.showUserAccessAlert();
        }
    }
    showUserAccessAlert(){
        if (DefaultSettings.getAlert() != null) {
            DefaultSettings.getAlert().show('User do not have access or Selected location is out of state boundaries.', 'error');
            this.setState({
                mapClear: true,
                REGION_ID: '',
                DISTRICT_ID: '',
                EXISTING_COUNTY: '',
                PROPOSED_COUNTY: ''
            });
        }
    }
    
    getByGISlayerID = (name, gisid) => {
        try {
            if (name === "region") {
                if (gisid && gisid > 0) {
                    return this.state.applicationregion.filter(a => (a.ArcGISBoundaryId && a.ArcGISBoundaryId) == gisid)[0].Id
                } else return this.state["REGION_ID"]
            } else if (name === "district") {
                if (gisid) {
                    return this.state.applicationdistricts.filter(a => (a.ArcGISBoundaryId && a.ArcGISBoundaryId) == gisid)[0].Id
                } else return this.state["DISTRICT_ID"]
            } else if (name === "site") {
                if (gisid && gisid > 0) {
                    var gisid = gisid.replace(/^0+/,""); //regex to remove 0 from start for single digit county to match argis id-Manoj Thiparapu
                    return this.state.applicationsite.filter(a => (a.ArcGISBoundaryId && a.ArcGISBoundaryId) == gisid)[0].Id
                } else return this.state["EXISTING_COUNTY"]
            }
        } catch (ex) {
            return gisid
        }
    }
    //Check user has access to current sitelocation
    hasUserConfigAccess(id, accessPermissions){
        let configType = accessPermissions.configType;
        if(configType == 'S' && accessPermissions.moduleSiteAccess){
            return Object.keys(accessPermissions.moduleSiteAccess).includes(id);
        }
        if(configType == 'R' && accessPermissions.moduleRegionAccess){
            return Object.keys(accessPermissions.moduleRegionAccess).includes(id);
        }
        if(configType == 'D' && accessPermissions.moduleDistrictAccess){
            return Object.keys(accessPermissions.moduleDistrictAccess).includes(id);
        }
        return false;
    }
    hasAccessToConfig(id, listarr){
    }
    taxparcelURLChange(url) {
        let tempmapsettings = this.state.map
        tempmapsettings.options.tasks.TaxParcels.url = url;
        this.setState({ mapsettings: { ...tempmapsettings } });
    }
    onTypeSlectionChange(mapreset, dialogconfirm) { //refvalue,
        //   let setname = 'WHAT_WOULD_YOU_LIKE_TO_DO_'
        let mapset = { ...amesmap };
        let fetchGeomType = ''
        if (mapreset !== "") {
            fetchGeomType = this.getFetchGeomType(mapreset);
        }
        else {
            fetchGeomType = this.getFetchGeomType(-1);
        }
        if (mapreset && dialogconfirm) {
            mapset.options.FieldsData.forEach((each, index) => {
                each.FieldValue = ''
            })
            this.setState({
                mapchangedFieldName: {}, formControls: {}, newformControls: {},
                mapsettings: mapset,
                confirmClickedEvent: false,
                //     [mapreset]: mapreset,
                mapClear: true, fetchGeomType: fetchGeomType
            }); //WHAT_WOULD_YOU_LIKE_TO_DO_:refvalue,
        } else {
            this.setState({
                mapsettings: mapset,
                confirmClickedEvent: false,
                mapClear: false, fetchGeomType: fetchGeomType
            }); //WHAT_WOULD_YOU_LIKE_TO_DO_: refvalue,
        }
    }
    getFetchGeomType(type) {
        //Two Points  1, //Existing 2,3, //Proposed 4,5
        //As Discussed with Deepa, Updated this (20/07/2022): All the time available - Exisitng and proposed pins.
        return ['E']
    }
    /**
     * loads exisiting inquiry information
     */
    loadFieldsData = () => {
        const { inquiryrecordData } = SummaryJSON.options.allurls;
        requestApi({ get: { url: inquiryrecordData.get.url, routeProps: {} } }, this.state.INQUIRY_RECORD_NUMBER).then(results => {
            if (results) {
                const data = results;
                this.setState({ ...data[0], ['SITE_PERMIT_TYPE_ID']: this.state.SITE_PERMIT_TYPE_ID, taxparcelallexp: 'all' }, () => {
                    let temp = data[0]
                    temp['SITE_PERMIT_TYPE_ID'] = this.state.SITE_PERMIT_TYPE_ID
                    this.setMapLocationIfnformationCont(temp);
                })
            }
        })
    }
    setMapLocationIfnformationCont(maplocation) {
        console.log('setMapLocationIfnformationCont--------------');
        // let tempchangedFieldvalues = this.state.changedFieldvalues;
        let premapsettings = this.state.mapsettings
        if (maplocation) {
            //let mapsettings = { ...amesmap };
            const newheader = {}, newformControls = {}, newchangedFieldName = {}
            const maplocationkeys = Object.keys(maplocation)
            maplocationkeys.forEach(each => {
                premapsettings.options.FieldsData.map(fd => {
                    if (fd.Fieldkey === each) {
                        fd.FieldValue = maplocation[each];
                    }
                })
                newformControls[each] = { ...this.state.formControls[each], value: maplocation[each] }
                newchangedFieldName[each] = maplocation[each]
            })
            premapsettings.options.tasks.TaxParcels.url = maplocation["TAX_PARCEL_LAYER"];
            premapsettings.options.tasks.TaxParcels.queryexpression = maplocation["TAX_PARCEL_IDS"];
            premapsettings.options.tasks.TaxParcels['PRIMARY_TAX_PARCEL_IDS'] = maplocation["PRIMARY_TAX_PARCEL_IDS"];
            //this.mapsettings.options.tasks.TaxParcels.queryexpression = maplocation["TAX_PARCEL_IDS"]
            premapsettings.options.LayerOptions[0].url = maplocation["TAX_PARCEL_LAYER"]
            this.setState({
                header: { ...this.state.header, ...newheader },
                formControls: { ...this.state.formControls, ...newformControls },
                mapchangedFieldName: { ...this.state.formControls, ...newformControls },
                mapsettings: premapsettings, mapClear: false, userId: this.state.userId + 1,
                taxparcelexp: maplocation.TAX_PARCEL_IDS, taxparcelallexp: maplocation.TAX_PARCEL_IDS, ...newchangedFieldName
            })
        }
    }
    checkStartOpen = () => {
        const { REGION_ID,SITE_ID, PROPOSED_COUNTY, EXISTING_COUNTY, SITE_PERMIT_TYPE_ID, WHAT_WOULD_YOU_LIKE_TO_DO_, PROPOSED_MAP_CENTER_LATITUDE, PROPOSED_MAP_CENTER_LONGITUDE, MAP_CENTER_LATITUDE, MAP_CENTER_LONGITUDE } = this.state
       
        if(getEnvVariable('ModuleKey') === 'WRS'){
           if (this.state.subModuleKey === "APPLICATIONS") {
                return (SITE_PERMIT_TYPE_ID && SITE_ID && MAP_CENTER_LATITUDE && MAP_CENTER_LONGITUDE) ? true : false
            }
        }
    }
    getMapEdit = () => {
        // Without selection of what would you like to do- user can select pin information - confirmed with Deepa.
        // if (getEnvVariable('ModuleKey') === 'AMES') {
        //     return this.state.WHAT_WOULD_YOU_LIKE_TO_DO_ ? true : false
        // }
        return true
    }

    render() {
        const { compmap } = this.props
        const { SITE_PERMIT_TYPE_ID, openTerms, agreement, TAX_PARCEL_LAYER, GISDomainLookupFields } = this.state
        return (
            <div className='col-sm-12'>
                {/* <Fab size='small' variant="contained" color="primary" title={'Tour'} className="float-md-right" onClick={() => { this.props.showTour(true) }}>
                    <TourIcon />
                </Fab> &nbsp; */}
                <Fab disabled={!this.checkStartOpen()} size='small' variant="contained" color={!this.checkStartOpen() ? "disabled" :"primary"} title={'Start Record Process'} className="float-end startprocess my-3"
                    onClick={this.moveToSections} >                    <StartIcon />                    </Fab>&nbsp;
                <div className="float-end">&nbsp;</div>
                {SITE_PERMIT_TYPE_ID && getEnvVariable('AccessPortal') == 'E' && <Fab variant="contained" size='small' color="primary" onClick={() => { this.setState({ openTerms: true }) }}
                    title={'Terms and Conditions'} className="float-md-right termscondtions"><TermIcon />                    </Fab>}&nbsp;
                {getEnvVariable('ModuleKey') === 'WRS' && <WRSApplicationProperties handleFieldChange={this.handleFieldChange} state={this.state}
                    loadFieldsData={this.loadFieldsData} />}
                {openTerms && <TermsAndCond url={SummaryJSON.options.allurls.TermsAndConditions} handleClose={() => { this.setState({ openTerms: false }) }}
                    handleAgreement={(e) => { this.setState({ agreement: e.target.checked, openTerms: false }) }} openTerms={openTerms} agreement={agreement} SITE_PERMIT_TYPE_ID={SITE_PERMIT_TYPE_ID} />}
                <br />
                {/* <div className='row float-right pr-3'>
                    <Icon title="Existing Driveway" fontSize='small' className='float-right' style={{ color: green[600], fontSize: 15 }}>{"square"}</Icon>
                    <span className='float-right pr-2' style={{ fontSize: '10px' }}>Existing Driveway</span>
                    <Icon title="Proposed Driveway" fontSize='small' className='float-right' style={{ color: red[600], fontSize: 15 }}>{"square"}</Icon>
                    <span className='float-right' style={{ fontSize: '10px' }}>Proposed Driveway</span>
                </div> */}
                {this.state.mapsettings && SITE_PERMIT_TYPE_ID && <Map {...this.state.mapsettings}
                    userId={this.state.userId} onSaveorUpdateMapResponse={this.onSaveorUpdateMapResponse} queryexpression={this.state.taxparcelallexp}
                    selectedtaxparcel={this.state.taxparcelexp} taxparcelresponse={this.taxparcelresponse} addTaxParcels={this.addTaxParcels}
                    setStateMapInformation={this.setStateMapInformation} SubModuleKey={this.state.subModuleKey} width={'100% '} height={'80vh'}
                    mapClear={this.state.mapClear} fetchGeomType={this.state.fetchGeomType} canEdit={this.getMapEdit()} compmap={compmap} SITE_PERMIT_TYPE_ID={SITE_PERMIT_TYPE_ID}
                    TAX_PARCEL_LAYER={TAX_PARCEL_LAYER}
                    gisDomainLookupData={GISDomainLookupFields}
                />}
            </div>
        )
    }
}
const mapActions = dispatch => {
    return bindActionCreators({ showTour }, dispatch);
}
NewEntrancePermitApplication = connect(null, mapActions, null, { forwardRef: true })(NewEntrancePermitApplication)
export default (withRouter(NewEntrancePermitApplication));
