import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { loadModules } from 'esri-loader';
import { TaxParcelTask, TaxParcelTaskFeatureLayer } from '../eps/taxParcelTask';
import { MapConstants } from '../util/mapConstants';
import { GISLookupTask } from '../eps/GISLookupTask';
import { QueryTaskExecuteWithrelation,QueryTaskExecuteZoomToReponse,QueryTaskExecuteZoomAddLayerToResponse } from '../util/queryTask';
import { getEnvVariable } from '../../../../modules/environmentalList';
//import { all } from 'ramda';


class CSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: this.props.view,
            options: this.props.options,
            approachaddress: ''
        }
        this.mapConstants = new MapConstants();
        this.startup(
            this.props
        );
    }


    startup = (config) => {
        loadModules([
            "esri/core/watchUtils",
            "esri/widgets/Expand",
            "esri/widgets/Search",
            "esri/tasks/Locator",
            "esri/layers/FeatureLayer"
        ]).then(([watchUtils, Expand, Search, Locator, FeatureLayer]) => {
            let modulekey = getEnvVariable('ModuleKey');
            const inThis = this;
            let searchSources=[];
            let searchSuffix = (config.options && config.options.searchOptions &&  config.options.searchOptions.State) ? " "+config.options.searchOptions.State : " "
            // Address search always on top
            searchSources.push({
                locator: new Locator("//geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer"),              
                suffix: `${searchSuffix},USA`,
                countryCode:"USA",
                singleLineFieldName: "SingleLine",
                outFields: ["Addr_type"],
                name: "Address Search",
                placeholder: "Address Search",
                withinViewEnabled: true,
            });

            if(config.options.SearchLayerListOptions &&  config.options.SearchLayerListOptions.length>0)
            {
                config.options.SearchLayerListOptions.forEach(function (searchLayer) {
                    let searchresprefix = searchLayer.title.slice(0, 6) +"..";
                    let searchfeatureLayer = new FeatureLayer({
                    url: searchLayer.url,
                    id:searchLayer.id,
                    outFields: ["*"],
                        popupTemplate: {
                            // autocasts as new PopupTemplate()
                            title:  searchLayer.SearchField+": {"+searchLayer.SearchField+"} </br>{"+searchLayer.SearchField+"}",
                            overwriteActions: true
                        }
                    });
                    searchSources.push({
                        featureLayer: searchfeatureLayer,
                        searchFields: [searchLayer.SearchField],
                        displayField:  searchLayer.SearchField,
                        exactMatch: false,
                        outFields: ["*"],
                        name: searchLayer.title+" Search",
                        placeholder: searchLayer.SearchField+" Search",
                        suggestionsEnabled: true,
                        minSuggestCharacters: 0,
                        resultGraphicEnabled: false,
                        withinViewEnabled: true,
                        //suggestionTemplate: searchLayer.SearchField //searchresprefix + "{"+searchLayer.SearchField+"}",
                    })
                });
            }
            this.searchcontainer = ReactDOM.findDOMNode(this);
            const search = new Search({
                view: config.view,
                includeDefaultSources: false,
                sources:searchSources,
                searchAllEnabled: false,
                //container: this.searchcontainer
                //locationEnabled : true
            });
            search.viewModel.defaultSymbol = {
                type: "picture-marker",
                url: "/dist/images/pin-icon.jpg",
                size: 24,
                width: 24,
                height: 24,
                xoffset: 0,
                yoffset: 0
            };
            search.on("search-start", function (event) {
               //console.log('search-start')              
            });

            search.on("search-focus", function(event){
                //console.log("Search input textbox is focused.");
                inThis.setInfoMessage(event);         
            });

            search.viewModel.on("suggest-start", function (event) {
                //console.log('suggest-start');
                inThis.setLoadingMessage(event, true);
            });
            search.viewModel.on("suggest", function (event) {
                //console.log('suggest')
            });
            search.on("search-complete", function (event) {
                //console.log('search-complete')
            });
            search.viewModel.on("select-result", function (event) {
                //console.log('search-result')
                //inThis.QueryTaxParcel("all", true);
                let approachaddress = (event && event.result) ? event.result.name : ""
                inThis.setState({ approachaddress : approachaddress } , () =>{
                    //First default address search
                    if (event.target.activeSourceIndex === 0){
                        inThis.AddressSearchForLayers(event.result.feature)
                    }else{
                        inThis.setLoadingMessage(event, false);
                        inThis.presentTaxLotLayerWithRenderer(event.result.feature)
                    }
                })
            });
            /*
            search.goToOverride = function (view, goToParams) {
                return view.goTo(goToParams.target, goToParams.options);
            };
            */
            search.viewModel.allSources.on("change", ({ item }) => {
                //console.log(item)
            })

            const searchExpand = new Expand({
                collapseTooltip: "Collapse Search",
                expandTooltip: "Expand Search",
                view: config.view,
                content: search
            });
            config.view.ui.add(searchExpand, "top-right");
            inThis.props.widgetRenderCallBack("Search")


            watchUtils.pausable(searchExpand, "expanded", function (newValue, oldValue) {
                inThis.props.onexpanded(searchExpand, newValue);
            });
            let mapType = config && config.options.LayerOptionsConfig ? config.options.LayerOptionsConfig.MapType : [];

            if((modulekey == 'AMES' || modulekey == 'MPS' || modulekey == 'UPS') && mapType.includes("A")){
                config.view.watch('zoom', (newValue, oldValue, property, object) => this.onZoomLevelChange(newValue, oldValue, property, object,  this));
            }
           
        });
    }

    setInfoMessage(event){        
        //console.log('Info message started', event, '-',event.target.activeSource.name)
        if(this.searchcontainer){
            if(event && event.target.activeSource && event.target.activeSource.name)
            var parentNodes = document.getElementsByClassName('esri-ui');
            if(parentNodes[0]){
                var childlable = parentNodes[0].querySelector(".Search-box-layerInfo-label");
                if(childlable){
                    childlable.innerHTML = "selected layer: "+ event.target.activeSource.name;
                }else{
                    let search_info = this.searchcontainer.getElementsByClassName("Search-box-layerInfo");
                    //let search_infolbl = this.searchcontainer.getElementsByClassName("Search-box-layerInfo-label");
                    //search_infolbl[0].innerHTML = "Selected:"+ event.target.activeSource.name;
                    //search_info[0].innerHTML = search_infolbl[0];
                    search_info[0].style.display = "inline-block";
                    parentNodes[0].prepend(search_info[0]);
                }
            }            
        }
    }

    setLoadingMessage(event, isSet){
        let parentNodes = document.getElementsByClassName('esri-ui');
        let childlable = parentNodes[0].querySelector(".Search-box-layerInfo-label");     
        parentNodes[0].querySelector(".Search-box-layerInfo-label").innerHTML.replace("Searching data from", "");
        let lblInnerHTML = parentNodes[0].querySelector(".Search-box-layerInfo-label").innerHTML;        
        lblInnerHTML = lblInnerHTML.replace("Searching data from ", "");
        if(childlable){
            if(isSet){                
                parentNodes[0].querySelector(".Search-box-layerInfo-label").innerHTML = "Searching data from " + lblInnerHTML;
            }else{
                parentNodes[0].querySelector(".Search-box-layerInfo-label").innerHTML = lblInnerHTML;
            }
        }
    }

    onZoomLevelChange(newValue, oldValue, property, object,  inthis){
            // //console.log("New value: ", newValue,
            //     "<br>Old value: ", oldValue,
            //     "<br>Watched property: ", property,
            //     "<br>Watched object: ", object);
            if(newValue > 14){
                //console.log(inthis, "<br>  Extent: ", inthis.props.view.extent)
                loadModules([
                    "esri/Graphic",
                    "esri/geometry/Point",
                ]).then(([Graphic, Point]) => {
                    let queryEXp = this.props.options && this.props.options.tasks ?  this.props.options.tasks.TaxParcels.queryExpression : '';
                    var graphic = new Graphic({
                        geometry: new Point({
                            x: object.center.longitude,
                            y: object.center.latitude
                        }),
                        attributes: { MapTaxlot : queryEXp }
                    })
                    inthis.AddressSearchForZoom(graphic);
                })
            }
    }

    QueryForCountyInformationByLayers(options, feature) {
        let inThis = this

        let taxlayersinformation = options.CountyLayerOptions;
        let allPromise = []
        if (taxlayersinformation && taxlayersinformation.length > 0) {
            for (var i = 0; i < taxlayersinformation.length; i++) {
                let eachpromise = this.QueryTaskPromise(taxlayersinformation[i], "1=1", "", feature)
                allPromise.push(eachpromise);
            }
        }
        Promise.all(allPromise).then((data) => {
            //console.log('promise data out for finding taxlot layer ', data);
            let layermatch = data.filter(a => a.count && a.count > 0)
            if (layermatch && layermatch.length > 0) {
                let layeraddressed = data.filter(a => a.count && a.count > 0)[0];
                let sourcetaxloturl = layeraddressed.sourcetaxloturl;
                //console.log(sourcetaxloturl);
                if (sourcetaxloturl) {
                   // this.afterSearch(sourcetaxloturl, ['all'], geom, null)
                   this.presentTaxLotLayerWithRenderer(sourcetaxloturl, feature);
                }
            }
            //Added condition for KEPT not to pass taxlot url
            else{
                //console.log('Search geom', feature);
                //this.afterSearch("", ['all'], geom, null)
            }
        })
    }

    AddressSearchForZoom(feature) {
        let inThis = this
        let allPromise = [];
        let allCountyTaxlotLayers = this.state.options.CountyLayerOptions;
        if (allCountyTaxlotLayers && allCountyTaxlotLayers.length > 0) {
            for (var i = 0; i < allCountyTaxlotLayers.length; i++) {
                let eachpromise = this.QueryTaskPromise(allCountyTaxlotLayers[i], null, "", feature)
                allPromise.push(eachpromise);
            }
        }
        Promise.all(allPromise).then((data) => {
            let layermatch = data.filter(a => a.count && a.count > 0)
            if (layermatch && layermatch.length > 0) {
                let layeraddressed = data.filter(a => a.count && a.count > 0)[0];
                let sourcetaxloturl = (layeraddressed && layeraddressed.sourcetaxloturl) ? layeraddressed.sourcetaxloturl : null;
                if (sourcetaxloturl) {
                    //console.log('source taxlot found..', feature, sourcetaxloturl);
                    let customfeature = feature;
                    if(feature && feature.layer)
                        customfeature.layer.url = sourcetaxloturl;
                    else
                        customfeature.layer = { url : sourcetaxloturl};

                    inThis.presentTaxLotLayerWithRenderer(customfeature, false);
                }
            }
        }).then((error) => {
            //console.log('promise all after search ----' + error);
        })
    }


    AddressSearchForLayers(feature) {
        let inThis = this
        let allPromise = [];
        let allCountyTaxlotLayers = this.state.options.CountyLayerOptions;
        if (allCountyTaxlotLayers && allCountyTaxlotLayers.length > 0) {
            for (var i = 0; i < allCountyTaxlotLayers.length; i++) {
                let eachpromise = this.QueryTaskPromise(allCountyTaxlotLayers[i], null, "", feature)
                allPromise.push(eachpromise);
            }
        }
        Promise.all(allPromise).then((data) => {
            let layermatch = data.filter(a => a.count && a.count > 0)
            if (layermatch && layermatch.length > 0) {
                let layeraddressed = data.filter(a => a.count && a.count > 0)[0];
                let sourcetaxloturl = (layeraddressed && layeraddressed.sourcetaxloturl) ? layeraddressed.sourcetaxloturl : null;
                if (sourcetaxloturl) {
                    //console.log('source taxlot found..', feature, sourcetaxloturl);
                    let customfeature = feature;
                    if(feature && feature.layer)
                        customfeature.layer.url = sourcetaxloturl;
                    else
                        customfeature.layer = { url : sourcetaxloturl};

                    inThis.presentTaxLotLayerWithRenderer(customfeature);
                }
            }
        }).then((error) => {
            //console.log('promise all after search ----' + error);
        })
    }

    presentTaxLotLayerWithRenderer(feature, zoomonly= true){
        //console.log('presentTaxLotLayerWithRenderer----------');
        if(feature && feature.layer && feature.layer.url){
            let config = { url: feature.layer.layerId ? `${feature.layer.url}/${feature.layer.layerId}` : `${feature.layer.url}`}
            QueryTaskExecuteZoomAddLayerToResponse(this.state.view,this.state.options, config, feature, this.mapConstants,"SearchField",zoomonly )
                .then(res => {
                    if(this.props.setSearchLayerConfigData){
                        this.props.setSearchLayerConfigData(config.url, feature)
                    }
                    // if(this.props.setDefaultSelectWidgetLayer)
                    //     this.props.setDefaultSelectWidgetLayer(config.url);
                    //console.log(res);
            })
        }
    }

    QueryForInformationByLayers(Layeroptions, searchterm) {
        let inThis = this
        let allPromise = [];
        let querycondition = Layeroptions.SearchField+" = '" + searchterm + "'"
        let eachpromise = this.QueryTaskPromise(Layeroptions, querycondition, "", null)
        allPromise.push(eachpromise);
        Promise.all(allPromise).then((data) => {
            let layermatch = data.filter(a => a.count && a.count > 0)
            if (layermatch && layermatch.length > 0) {
               this.afterSearch(Layeroptions, [searchterm, 'all'], null, searchterm)
            }
        }).then((error) => {
            //console.log('promise all after search ----' + error);
        })
    }

    QueryForTaxLotInformationByLayers(options, searchterm) {
        let inThis = this
        let taxlayersinformation = options.CountyLayerOptions;
        let allPromise = [];
        let searchtermquery = searchterm.split('').join('%');
        let querycondition = "MapTaxlot = '" + searchterm + "'"
        if (taxlayersinformation && taxlayersinformation.length > 0) {
            for (var i = 0; i < taxlayersinformation.length; i++) {
                let eachpromise = this.QueryTaskPromise(taxlayersinformation[i], querycondition, "", null)
                allPromise.push(eachpromise);
            }
        }
        Promise.all(allPromise).then((data) => {
            let layermatch = data.filter(a => a.count && a.count > 0)
            if (layermatch && layermatch.length > 0) {
                let layeraddressed = data.filter(a => a.count && a.count > 0)[0];
                let sourcetaxloturl = (layeraddressed && layeraddressed.sourcetaxloturl) ? layeraddressed.sourcetaxloturl : null;
                if (sourcetaxloturl) {
                    this.afterSearch(sourcetaxloturl, [searchterm, 'all'], null, searchterm)
                }
            }
        }).then((error) => {
            //console.log('promise all after search ----' + error);
        })
    }

    afterSearch(layerOptions, searchQueries, geom, searchterm) {
        //let searchQueries = [searchterm, 'all'];
        let allProm = [];
        if (searchQueries && searchQueries.length > 0) {
            for (var i = 0; i < searchQueries.length; i++) {
                allProm.push(this.ShowMapLayers(searchQueries[i],layerOptions, true, searchterm));

            }
        }
        Promise.all(allProm).then((data) => {
            this.GetMasterGISFieldsData(layerOptions, searchterm, geom);
        })

    }

    QueryTaskPromise(config, querycondition, spatialReference, inputgeom) {
        return new Promise((resolve, reject) => {
            loadModules([
                "esri/tasks/support/Query", "esri/tasks/QueryTask", "esri/geometry/Point", "esri/geometry/SpatialReference"
            ]).then(([Query, QueryTask, Point, SpatialReference]) => {
                const query = new Query();
                if(querycondition){
                    if(config.defaultExpression)
                        querycondition=(!querycondition || querycondition=="()" || querycondition=="1=1")?(config.defaultExpression):(("("+config.defaultExpression+")")+" and "+querycondition)
                    query.where = querycondition;
                }else{
                    query.where = "1=1"
                }
                query.outFields = ['*'];
                query.spatialReference = spatialReference || 2992;
                //query.returnGeometry = false;
                query.outSpatialReference = spatialReference || 2992;
                //query.returnIdsOnly = false;

                if (inputgeom && inputgeom != null) {
                    query.geometry = inputgeom.geometry;
                    //query.spatialRelationship  =  "intersects";
                    query.spatialRelationship = "esriSpatialRelEnvelopeIntersects";
                }

                const queryTask = new QueryTask({
                    url: config.url
                });

                queryTask.executeForCount(query).then(
                    count => {
                        let response = {
                            count: count,
                            sourcetaxloturl: config.url
                        }
                        //response.sourcetaxloturl = config.url;
                        resolve(response);
                    }
                    , error => {
                        //console.log("WebMap: error", error);
                        resolve({});
                    }
                );
            })
        });
    }


    GetMasterGISFieldsData(url, taxlotnumber, geom) {
        let lookupdata = this.props.options.MasterLookupFields
        let inThis = this;
        let approachaddress = this.state.approachaddress;
        //console.log('lookup data', lookupdata);
        if (lookupdata && lookupdata.length > 0) {

            if (geom) {
                GISLookupTask(this.props.options,geom, lookupdata).then((masterdataresponse) => {
                    let mapinformation = {
                        'TAX_PARCEL_LAYER': url,
                        'TAX_PARCEL_IDS': taxlotnumber,
                        'APPROACH_ADDRESS': approachaddress,
                        ...masterdataresponse
                    }
                    if(inThis.props.setStateMapInformation)
                        inThis.props.setStateMapInformation(mapinformation);
                })
            } else {
                let queryExpression = "MapTaxlot = '" + taxlotnumber + "'"
                let config = { url: url }
                QueryTaskExecuteWithrelation(config, queryExpression, this.props.options.viewProperties.extent.spatialReference , null).then(
                    response => {
                        let geom = response.features[0].geometry;
                        GISLookupTask(this.props.options,geom, lookupdata).then((masterdataresponse) => {
                            let mapinformation = {
                                'TAX_PARCEL_LAYER': url,
                                'TAX_PARCEL_IDS': taxlotnumber,
                                'APPROACH_ADDRESS': approachaddress,
                                ...masterdataresponse
                            }
                            if(inThis.props.setStateMapInformation)
                                inThis.props.setStateMapInformation(mapinformation);
                        })
                    }
                )
            }
        }
    }


    render() {
        return (
            <div className="Search-box" style={{order:this.props.order}}>                    
                <div className="Search-box-layerInfo" style={{"width": "100%","color": "#01ff0c", "background": "#0000005e", 
                    "text-align": "center","display":"none"}}> 
                    <label className="Search-box-layerInfo-label">selected layer: Address search</label> 
                </div>        
            </div>
        );
    }
}

export default CSearch;