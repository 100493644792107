import React from 'react';
import { getEnvVariable } from '../environmentalList';
import { RadioList } from './CompanyTypeDrop';


function Heading(props) {
    return (
            <h6 className="py-3 fw-bold text-dark" >
                {props.CompanyType ? ((RadioList[getEnvVariable('ModuleKey')] || RadioList['Other']).find(each => { return each.value === props.CompanyType }) || {}).Title +
                ' Company Information' : props.subheading}
            </h6>
    );
}

export default Heading