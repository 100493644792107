// *https://www.registers.service.gov.uk/registers/country/use-the-api*
//import fetch from 'cross-fetch';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { GetFetchProps, getToken } from '../../modules/header';
import { createRequest, requestApi } from '../helpers/rest';
import DefaultSettings from './settings';
//import { prop } from 'ramda';

export default function Asynchronous(props) {
    const { value, handleFieldValue, extraProps, shareProps, tooltip, disabled, contexthelp, error, required, className, isAvoid, ...otherProps } = props
    const { label, name, items, fullWidth, helperText, handleEffectUpdates, handleEffect, defaultdataset, ...otherconfigs } = otherProps
    let { transformProps, optionsurl, selecturl } = extraProps || {}
    let autoOptions = []
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [prevVal, setValue] = React.useState(value ? value : null);
    const [options, setOptions] = React.useState([]);
    const [originalOptions, setOriginalOptions] = React.useState([]);

    if (items && Array.isArray(items)) {
        items.map((each, index) => {
            const { label, value } = transformProps
            const name = each[label]
            const key = each[value]
            autoOptions.push({ label: name, value: key })

        })
    }
    else if (items && typeof items === 'object') {
        const { label, value } = transformProps
        const name = items[label]
        const key = items[value]
        autoOptions.push({ label: name, value: key })
    }

    let curVal = props.value ? props.value : null
    if (items && items.length === 0 && selecturl && prevVal !== curVal) {
        let selectedItems = [];
        setValue(props.value)
        selecturl['body'] = { [name]: value }
        requestApi(createRequest(selecturl, selecturl.body, null)).then(res => {
            if (res && res[name]) {
                selectedItems.push(res[name])
                selectedItems && Array.isArray(selectedItems) && selectedItems.map((each, index) => {
                    const { label, value } = transformProps
                    const name = each[label]
                    const key = each[value]
                    autoOptions.push({ label: name, value: key })
                })
            }
        })
    }

    //  setOptions(autoOptions)
    // const loading = open && autoOptions.length === 0;


    const handleInputChange = (event, value, reason) => {
        if (isAvoid) {
            setValue(value)
        }
        else {
            let autoResults = [];
            if (value.length > 0 && optionsurl && (reason !== 'reset')) {
                setLoading(true)
                var active = true;
                (async () => {
                    const response = await fetch(`${optionsurl.url + value}`, {
                        method: 'GET',
                        ...GetFetchProps()
                    })
                    const results = await response.json();
                    if (active) {
                        results && Array.isArray(results) && results.map((each, index) => {
                            const { label, value } = transformProps
                            const name = each[label]
                            const key = each[value]
                            autoResults.push({ label: name, value: key })
                        })
                        setOriginalOptions(results)
                        autoOptions = autoResults
                        setOptions(autoResults);
                        setValue(value)
                        setLoading(false)
                    }
                })();
            }
            return () => {
                active = false;
            };
        }
    }

    const handleChangeAuto = (event, option) => {
        console.log(event, option)
        if (handleEffectUpdates && handleEffect)
            handleEffectUpdates([{ ...handleEffect, items: originalOptions }])
        handleFieldValue({ target: { name: name, value: option ? option.value : '', checked: false }, currentTarget: { dataset: null } })
    }


    React.useEffect(() => {
        if (!open) {
            // setOptions([]);
            autoOptions = []
        }
    }, [open]);
    let assignoptions = autoOptions || []
    if (options && options.length > 0) {
        assignoptions = options;
    }
    let fargmentval = props.value && props.value !== '' && assignoptions && assignoptions.length > 0 && assignoptions.find(each => { if (each.value && props.value && (each.value).toString() === (props.value).toString()) return each }) || null
    return (
        <FormControl fullWidth={fullWidth || true} required={required} error={error} >
            <InputLabel style={{ fontWeight: 'bold', color: error ? 'red' : 'black' }} shrink> {label} {tooltip && <Tooltip title={tooltip ? tooltip : null}
                placement="bottom-start" ><HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#007bff', marginLeft: 5 }} onClick={(evt) => contexthelp ? DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
            </Tooltip>} </InputLabel>
            <Autocomplete
                className='pt-1'
                //value={value}
                //value={value && value !== '' && autoOptions && autoOptions.length > 0 && autoOptions.find(each => each.value === value)}
                disabled={disabled || false}
                value={fargmentval || ''}
                defaultValue={fargmentval}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                getOptionSelected={(option, value) => option.value === value.value}
                getOptionLabel={(option) => option.label || ""}
                onInputChange={handleInputChange}
                onChange={(event, option) => handleChangeAuto(event, option)}
                options={options && options.length > 0 ? options : autoOptions}
                loading={loading}
                renderInput={params => (
                    <TextField
                        placeholder='Please enter text to search'
                        {...params}
                        error={error}
                        {...otherconfigs}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}

        </FormControl>
    );
}
