import React, { Component, Fragment } from 'react'
import DualListBox from '../../core/modules/form/cmultiduallistbox'
import Header from '../../core/modules/form/cformheader'
import { Typography } from '@material-ui/core'

class UsersNContacts extends Component {
    state = {
        save: false
    }

    handleSave = () => {
        this.setState({
            save: true
        })
        this.refs.child.onhandleSave();
    }

    // getValueFromChild = (value) => {
    //     console.log('value usersncontacts',value)
    // }

    render() {
        const { options, components, compmap } = this.props
        const { headerProps } = options

        return (
            <Fragment>
                <Header {...headerProps} handleSave={this.handleSave} />
                {components.map(each => {
                    const { type, options } = each
                    const { title } = options
                    const EachComp = compmap[type]
                    return <Fragment>
                        <Typography className="mt-5">
                            {title}
                        </Typography>
                        <EachComp {...each} ref="child" save={this.state.save} />
                    </Fragment>
                })}
            </Fragment>
        )
    }

}

export default UsersNContacts