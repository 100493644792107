/**
 * @file Manages the functionalities of User Access Control based on Groups->Roles->Counties.
 * @author Sri Pooja <sri.pooja@delasoft.com>
 */
import { Chip, Fab } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getEnvVariable } from '../../../../modules/environmentalList';
import { createRequest, requestApi } from '../../helpers/rest';
import CheckboxesTags from '../checkautocomplete';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export default function UserAccessControl(props) {
    const params = useParams();
    const classes = useStyles();
    const [data, setData] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const [user, setuser] = useState(null)
    const [authDetails, setAuthDetails] = useState(null)
    const [groupIndeterminate, setGroupIndeterminate] = useState(false)
    const { options, components, extraProps, match, dataItem } = props
    const { read } = options ? options : {}
    var get = read.filter(e => e.type === 'get')
    var customProps = get.filter(e => e.customProps ? e : null)
    let ids = [], id;
    var urls = [];


    /**
     * Initial Request to get the data
     */
    useEffect(() => {
        if (customProps.length > 0) {
            id = customProps ? (dataItem.Id || dataItem.userId) : null
        }
        if (options && options.read) {
            let read = options.read.filter(each => { if (each.type === 'get') return each })
            read = read.filter(each => each.customProps ? each.customProps : each.routeProps)
            if (id && read && customProps.length > 0) {
                read.forEach(e => {
                    var urls = e.url.slice(-1);
                    e.url = urls == "/" ? e.url + '' + id : e.url
                })
            }
            Promise.all(read.map(each => requestApi(createRequest(each, {}, params)))).then(resp => {
                const [accesscontrol, user] = resp
                setData(accesscontrol)
                setuser(user)
            })
        }
    }, [params.Id])

    useEffect(() => {
        if (getEnvVariable('ModuleKey') === 'WRS') {
            let read = [
                {
                    url: getEnvVariable('MasterAdmin') + `/api/IPUsersAuthorizations/GetRequestByUserId/`+props.dataItem.userId ? props.dataItem.userId : props.dataItem.Id,
                    type: 'get',
                    // routeProps: [
                    //     {
                    //         name: "Id",
                    //         key: "Id",
                    //     },
                    // ]
                }
            ]
            Promise.all(read.map(each => requestApi(createRequest(each, {}, params)))).then(resp => {
                const [reqAuthDetails] = resp
                setAuthDetails(reqAuthDetails)
                setRefresh(!refresh)
            })
        }
    }, [params.Id])

    const handleSave = () => {
        let url = options.read.filter(each => { if (each.type === 'post') return each })
        requestApi(createRequest(url[0], data, params)).then(resp => {
            if (resp)
                setData(data)
        })
    }

    const handleSubItems = (items, check) => {
        return items.map(each => {
            if (each && each.items && each.items.length > 0) {
                each.checked = check;
                handleSubItems(each.items, check)
            }
            else each.checked = check;
            return each
        })
    }

    /**
   * on click on group , selecting all the sub roles and counties
   */
    const checkGroupSection = (evt, index) => {
        evt.stopPropagation()
        let each = data[index];
        if (each && each.items && each.items.length > 0) {
            each.checked = !each.checked
            each.items = handleSubItems(each.items, each.checked);
        }
        else {
            each.checked = !each.checked
        }
        data[index] = each;
        setData(data);
        setRefresh(!refresh)
    }

    const checkRoleSection = (evt, index, parent) => {
        evt.stopPropagation()
        let each = data[parent]['items'][index];
        if (each && each.items && each.items.length > 0) {
            each.checked = !each.checked
            each.items = handleSubItems(each.items, each.checked);
        }
        else {
            each.checked = !each.checked
        }
        data[parent]['items'][index] = each;
        if (data[parent]) {
            if (data[parent]['items'].every(item => item.checked === true))
                data[parent].checked = true
            else data[parent].checked = false
        }
        setData(data);
        setRefresh(!refresh)
    }

    const handleCountyChange = (evt, parent, index) => {
        const { name, value } = evt.target
        let each = data[parent]['items'][index];
        let tempval = value.split(',');
        if (each && each.items && each.items.length > 0)
            each.items.map(item => {
                if (tempval.includes(item.id.toString())) {
                    item.checked = true;
                }
                else item.checked = false
            })
        data[parent]['items'][index] = each;
        if (data[parent] && data[parent]['items'][index] && data[parent]['items'][index].items) {
            if (data[parent]['items'][index].items.every(item => item.checked === true)) {
                data[parent].checked = true
                data[parent]['items'][index].checked = true
            }
            else {
                data[parent].checked = false
                data[parent]['items'][index].checked = false
            }
        }
        setGroupIndeterminate({ [parent]: data[parent]['items'][index].items.some(item => item.checked === true) ? true : false })

        setData(data);
        setRefresh(!refresh)
    }

    const handleIndetermineGroupCheck = (group) => {
        let indeterminate = false
        if (group && group.items && group.items.length > 0) {
            indeterminate = group.items.some(each => {
                return each.checked === true
            })
            if (indeterminate === false) {
                indeterminate = group.items.some(each => {
                    if (each && each.items && each.items.length > 0) {
                        return each.items.some(eachItem => {
                            return eachItem.checked === true
                        })
                    }
                })
            }
            return indeterminate
        }
        else return false
    }

    return <div className='col-12 py-1'>
        <Typography component={'title'}>{options.title || 'User Access Control'}</Typography>
        {refresh}
        {authDetails && <Accordion defaultExpanded={true}>
            <AccordionSummary
                style={{ backgroundColor: getEnvVariable('ModuleKey') === 'WRS' ? '#074078' : '#1976d2', color: 'white', maxHeight: '45px', minHeight: '45px' }}
                expandIcon={<ExpandMoreIcon color='inherit' style={{ color: 'white' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>Authorization Details : {" " + authDetails.FirstName + " " + authDetails.LastName} </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-6'><small className='text-secondary'>FirstName : </small> {" " + authDetails.FirstName}</div>
                        <div className='col-6'><small className='text-secondary'>LastName : </small> {" " + authDetails.LastName}</div>
                    </div>
                    <div className='row col-12'><small className='text-secondary'>Subject : </small> {" " + authDetails.Subject}</div>
                    <div className='row col-12'><small className='text-secondary'>Body : </small> {" " + authDetails.Body}</div>
                </div>
            </AccordionDetails>
        </Accordion>}
        {user && user.IsAdmin !== 'Y' && customProps && customProps.length <= 0 && <Fab className='float-end m-2' size='small' color='primary' onClick={handleSave}><SaveIcon /></Fab>}
        <div class="clearfix py-2"></div>
        <div className={classes.root}>
            {data && data.length > 0 && (data || []).map((each, index) => {
                return <Accordion key={'parent-' + index}>
                    <AccordionSummary
                        style={{ backgroundColor: getEnvVariable('ModuleKey') === 'WRS' ? '#074078' : '#1976d2', color: 'white', maxHeight: '45px', minHeight: '45px' }}
                        expandIcon={<ExpandMoreIcon color='inherit' style={{ color: 'white' }} />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                    >
                        {user && user.IsAdmin !== 'Y' ? (customProps.length <= 0 ? <FormControlLabel

                            aria-label="Acknowledge"
                            onFocus={(event) => event.stopPropagation()}
                            onClick={(event) => checkGroupSection(event, index)}
                            control={<Checkbox checked={each.checked || (each.items.every(item => item.checked === true) ? true : false)} color='inherit' style={{ color: 'white' }}
                                indeterminate={each.items.every(item => item.checked === true) ? false :
                                    handleIndetermineGroupCheck(each) ? true : groupIndeterminate[index]}
                            />}
                            label={<small className='fw-bold '>{each.text}</small>}
                        /> : <div className='fw-bold'>{each.text}</div>) : <div className='fw-bold'>{each.text}</div>}
                    </AccordionSummary>
                    <div className='col-12'>
                        <div className='row fw-bold' >
                            <div className=" col-3">Roles</div>
                            <div className="col-7" >{getEnvVariable('ModuleKey') === 'AMES' ? 'Regions' : 'Counties'}</div>
                        </div>
                        {/* style={{ color: 'white', backgroundColor: 'rgb(4 133 185)', }} */}
                    </div>
                    {each.items && each.items.length > 0 && (each.items || []).map((subeach, subindex) => {
                        return <AccordionDetails key={'child-' + subindex}>
                            <div className='row col-12 py-2'>
                                {user && user.IsAdmin !== 'Y' ? (customProps.length <= 0 ? <div className='col-3'><FormControlLabel
                                    aria-label="Acknowledge"
                                    onFocus={(event) => event.stopPropagation()}
                                    onClick={(event) => checkRoleSection(event, subindex, index)}
                                    control={<Checkbox checked={subeach.checked || subeach.items.every(item => item.checked === true)}
                                        style={{ color: 'white' }}
                                        indeterminate={subeach.items.every(item => item.checked === true) ? false :
                                            (subeach.items.some(item => item.checked === true) ? true : false)}
                                    />}
                                    label={subeach.text}
                                /></div> : <div className='col'>{subeach.text}</div>) : <div className='col'>{subeach.text}</div>}
                                {user && user.IsAdmin !== 'Y' ? (customProps.length <= 0 ? <div className='col-7' >
                                    <CheckboxesTags limitTags={2} value={(subeach.items.map(function (a) { if (a.checked === true) return a.id })).join(',') || ''}
                                        ismulti={true} items={subeach.items} handleFieldValue={(evt) => handleCountyChange(evt, index, subindex)} extraProps={{
                                            transformProps: {
                                                label: 'text',
                                                value: 'id'
                                            }
                                        }}
                                    />
                                </div> : <div className='col-7' >{subeach.items.map(a => { if (a.checked === true) return <Chip label={a.text} className='ml-1' variant="outlined" /> })}</div>) :
                                    <div className='col-7' >{subeach.items.map(a => { if (a.checked != null) return <Chip label={a.text} className='ml-1' variant="outlined" /> })}</div>}
                            </div>
                        </AccordionDetails>
                    })}
                </Accordion>
            })}
        </div>
    </div>
}