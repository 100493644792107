import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ViewFields from './viewfields';
import ResultsCard from './addfield';
import { DeleteRecord } from '../../../../controls/helpers/requests';
import { getEnvVariable } from '../../../../../modules/environmentalList';

const useStyles = makeStyles((theme) => ({
    root: {
        // padding: 345,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

export default function Fields({ fields, result, handleFieldValue, isSidebar = false }) {
    const classes = useStyles();
    const [expandedvalid, setExpandedValid] = useState(true);
    const [expandedInvalid, setExpandedInValid] = useState(true);
    const [validFields, setvalidFields] = useState(fields);
    const [invalidFields, setinvalidFields] = useState(fields);
    const [validadd, setValid] = useState(false);
    const [invalidadd, setInvalid] = useState(false);

    useEffect(() => {
        changeBusinessFields()
    }, [fields])

    const changeBusinessFields = () => {
        let vbusinessrules = {}
        let ibusinessrules = {}
        result && result.BusinessRuleFields && result.BusinessRuleFields.length > 0 && result.BusinessRuleFields.map(rb => {
            if (rb.RuleType === 'V')
                vbusinessrules[rb.MasterFieldId] = rb.MasterFieldId
            else if (rb.RuleType === 'I')
                ibusinessrules[rb.MasterFieldId] = rb.MasterFieldId
        })

        if (Object.keys(vbusinessrules).length !== 0) {
            let valids = []
            fields.filter(cf => {
                if (!vbusinessrules[cf.id])
                    valids.push(cf)
            })
            setvalidFields(valids)
        }
        else {
            setvalidFields(fields)
        }
        if (Object.keys(ibusinessrules).length !== 0) {
            let invalids = []
            fields.filter(cf => {
                if (!ibusinessrules[cf.id])
                    invalids.push(cf)
            })
            setinvalidFields(invalids)
        }
        else {
            setinvalidFields(fields)
        }
    }

    const handleChange = (panel) => (event, isExpanded, type) => {
        if (type == 'valid')
            setExpandedValid(isExpanded ? panel : false);
        if (type == 'invalid')
            setExpandedInValid(isExpanded ? panel : false);
    };

    const addNewRule = (resultset) => {
        let temp = result
        if (temp && temp.BusinessRuleFields)
            temp.BusinessRuleFields.push(resultset)
        else {
            let business = [];
            business.push(resultset)
            temp['BusinessRuleFields'] = business
        }
        changeBusinessFields()
        handleFieldValue({ target: { value: temp.BusinessRuleFields, name: 'BusinessRuleFields', checked: false }, currentTarget: { dataset: null } })
    }

    const editRule = (resultset) => {
        let temp = result
        temp.BusinessRuleFields.map(t => {
            if (t.MasterFieldId === resultset.MasterFieldId) {
                t = resultset
            }
        })
        handleFieldValue({ target: { value: temp.BusinessRuleFields, name: 'BusinessRuleFields', checked: false }, currentTarget: { dataset: null } })
    }


    const handleClose = (ruleType) => {
        if (ruleType === 'V')
            setValid(false)
        else setInvalid(false)
    }

    const getMasterInputType = (field) => {
        let returntype = fields && fields.length > 0 && fields.filter(a => a.id === field)
        if (returntype && returntype.length > 0 && returntype[0].editorType)
            return returntype[0].editorType
        else return 'text'
    };

    const getMasterIdValues = (field) => {
        let returntype = fields && fields.length > 0 && fields.filter(a => a.id === field)
        if (returntype && returntype.length > 0 && returntype[0].editorType && returntype[0].values && returntype[0].values.length > 0)
            return returntype[0].values
        else return []
    };

    const handleDelete = (selected) => {
        if (!isSidebar) {
            let bussinessrule = result && result.BusinessRuleFields.filter(rule => {
                if (rule.MasterFieldId === selected.MasterFieldId) {
                    if (selected.RuleType !== rule.RuleType) {
                        return rule
                    }
                }
                else if ((rule.MasterFieldId !== selected.MasterFieldId))
                    return rule
            })
            result.BusinessRuleFields = bussinessrule;
            if (selected.Id) {

                let url = getEnvVariable('AdminConfig') + `/api/BusinessRuleFields/${selected.Id}`
                DeleteRecord(url, '').then(res => {
                    changeBusinessFields()
                    handleFieldValue({ target: { value: result.BusinessRuleFields, name: 'BusinessRuleFields', checked: false }, currentTarget: { dataset: null } })
                })
            }
            else {
                changeBusinessFields()
                handleFieldValue({ target: { value: result.BusinessRuleFields, name: 'BusinessRuleFields', checked: false }, currentTarget: { dataset: null } })
            }
        }
    }

    return (<div style={{ overflow: 'auto', width: '800px' }}>
        <Accordion expanded={expandedvalid} onChange={handleChange('panel1', 'valid')} >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}                >
                <Typography className={classes.heading} color='primary'>Valid &nbsp;  <IconButton size="small" onClick={() => setValid(true)} ><AddIcon />  </IconButton></Typography>
            </AccordionSummary>
            {validadd && <AccordionDetails>
                <ResultsCard result={result} handleFieldValue={addNewRule} ruleType={'V'} fields={validFields.filter(field => { if (field.isSectionField) return field })} handleClose={handleClose} />
            </AccordionDetails>}
            <AccordionDetails>
                <div className="row col-sm-12" style={{ maxHeight: '250px', overflow: 'auto' }}>
                    {result && result.hasOwnProperty('BusinessRuleFields') && result.BusinessRuleFields && result.BusinessRuleFields.length > 0 &&
                        result.BusinessRuleFields.map((val, i) => {
                            if (val.RuleType === 'V') {
                                let type = getMasterInputType(val.MasterFieldId)
                                let values = getMasterIdValues(val.MasterFieldId)
                                return <ViewFields isLabel={i == 0 ? true : false} viewResults={val} result={result} handleFieldValue={editRule} fields={fields} type={type} values={values} isSidebar={isSidebar}
                                    handleDelete={handleDelete} />
                            }
                        })}
                </div>
            </AccordionDetails>
        </Accordion>
        {result.IsTriggerAnotherRuleOnFailure !== 'Y' && <Accordion expanded={expandedInvalid} onChange={handleChange('panel2', 'invalid')} >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}            >
                <Typography className={classes.heading} color='primary'>Invalid &nbsp;<IconButton size="small" onClick={() => setInvalid(true)} ><AddIcon />  </IconButton></Typography>
            </AccordionSummary>
            {invalidadd && <AccordionDetails>
                <ResultsCard result={result} handleFieldValue={addNewRule} ruleType={'I'} fields={invalidFields.filter(field => { if (field.isSectionField) return field })} handleClose={handleClose} />
            </AccordionDetails>}
            <AccordionDetails>
                <div className="row col-sm-12" style={{ maxHeight: '250px', overflow: 'auto' }}>
                    {result && result.hasOwnProperty('BusinessRuleFields') && result.BusinessRuleFields && result.BusinessRuleFields.length > 0 && result.BusinessRuleFields.map((val, i) => {
                        if (val.RuleType === 'I') {
                            let type = getMasterInputType(val.MasterFieldId)
                            let values = getMasterIdValues(val.MasterFieldId)
                            return <ViewFields viewResults={val} isLabel={i == 0 ? true : false} result={result} handleFieldValue={editRule}
                                isSidebar={isSidebar} fields={fields} type={type} values={values} handleDelete={handleDelete} />
                        }
                    })}
                </div>
            </AccordionDetails>
        </Accordion>}
    </div>)
}

