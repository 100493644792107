import { Divider } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PostAdd from '@material-ui/icons/PostAdd';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SaveIcon from '@material-ui/icons/Save';
import ShareIcon from '@material-ui/icons/Share';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEnvVariable } from '../../../modules/environmentalList';
import { snackbarMessage } from '../../actions';
import { GetData, PostData, PutData } from '../../controls/helpers/requests';
import { getData, postRecordData, putRecordData } from '../rest';
import SaveReport from './savereports';
import ShareOptions from './shareoptions';
import Icon from '@material-ui/core/Icon';
const _ = require('lodash');



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}


const url = getEnvVariable('Report') + '/api/Reports/Add/'
const updateurl = getEnvVariable('Report') + '/api/Reports/Update/'

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1) * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const drawerWidth = 600;

class CustomizedDialogDemoContent extends React.Component {

    constructor(props) {
        super(props);
        this.LayoutForm = React.createRef()
        this.ShareOptionsForm = React.createRef()
    }
    state = {
        open: true,
        new: false,
        tabIndex: 0,
        savedfilters: [],
        dialogOpen: true,
        openShareOptions: false,
        anchorEl: null,
        selectedReportId: '',
        optionsOpen: false,
        isInternal: getEnvVariable('AccessPortal') == "I" ? true : false,
        hascustomvalidations: false,
        errordata: null,
        checkval: false
    };

    componentDidMount() {
        console.log(this.props.gridkey)
        this.setState({ dialogOpen: true }, () => {
            this.requestMaster()
        })
    }

    requestMaster = () => {
        let url = getEnvVariable('Report') + '/api/ReportMaster/All'
        GetData(url).then(res => {
            let result = res.filter(e => {
                if (e.Key === 'SAVED_QUERIES') {
                    return e
                }
            })
            this.setState({ masterreports: result })
        });

    }

    handleSimpleClose = () => {
        this.setState({ open: false }, () => {
            this.props.close()
        });
    }

    handleClose = () => {
        this.setState({ close: false }, () => {
            if (this.props && this.props.close)
                this.props.close()
        })
    };

    //ALL_DATA_USERS All Users Reports
    handleSave = () => {
        let LayoutForm = this.LayoutForm.current.savePermit();
        let strlengthreport = LayoutForm && LayoutForm.Name && LayoutForm.Name != null ? LayoutForm.Name.length : ""
        let strlengthreportdesc = LayoutForm && LayoutForm.Description && LayoutForm.Description != null ? LayoutForm.Description.length : ""
        if (LayoutForm.Name && LayoutForm.Name != "" && (LayoutForm.Name.charAt(0) === " " || LayoutForm.Name.charAt(0) === '\n' || LayoutForm.Name.charAt(strlengthreport - 1) === " ")) {
            let errordata = this.LayoutForm.current.customValidation(LayoutForm);
            if (!(_.isEmpty(errordata))) {
                this.setState({ hascustomvalidations: true, errordata: errordata, checkval: true })
            }
            //this.props.snackbarMessage('Leading or trailing spaces are not allowed', 'error');
        }
        else if (LayoutForm.Description && LayoutForm.Description != "" && (LayoutForm.Description.charAt(0) === " " || LayoutForm.Description.charAt(0) === '\n' || LayoutForm.Description.charAt(strlengthreportdesc - 1) === " ")) {
            let errordata = this.LayoutForm.current.customValidation(LayoutForm);
            if (!(_.isEmpty(errordata))) {
                this.setState({ hascustomvalidations: true, errordata: errordata, checkval: true })
            }
            //this.props.snackbarMessage('Leading or trailing spaces are not allowed', 'error');
        }
        else if (strlengthreport > 100) {
            let errordata = this.LayoutForm.current.customValidation(LayoutForm);
            if (!(_.isEmpty(errordata))) {
                this.setState({ hascustomvalidations: true, errordata: errordata, checkval: true })
            }
            //this.props.snackbarMessage('Name length should be in the range of (1-100)', 'error');
        }
        else if (strlengthreportdesc > 4000) {
            let errordata = this.LayoutForm.current.customValidation(LayoutForm);
            if (!(_.isEmpty(errordata))) {
                this.setState({ hascustomvalidations: true, errordata: errordata, checkval: true })
            }
            //this.props.snackbarMessage('Name length should be in the range of (1-100)', 'error');
        }
        else if (LayoutForm) {
            let profile = localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))
            let api = url + this.state.masterreports[0].Id;
            LayoutForm.MasterId = this.state.masterreports[0].Id;
            LayoutForm.GroupKey = this.props.gridkey;
            LayoutForm.OwnerName = profile.name || 'system';
            LayoutForm.SharedTo = 'N';
            LayoutForm.IsDefault = 'N';
            PostData(api, LayoutForm).then(response => {
                if (response.Id) {
                    this.getSavedFilters()
                    //   this.props.snackbarMessage('Saved Successfully', 'success')
                    // if (this.props.iscgrid) {
                    //     this.setState({ open: false }, () => {
                    //         this.props.close()
                    //     });
                    // }
                }
                else {
                    if (response.Name) {
                        let msg = Array.isArray(response.Name) && response.Name.length > 0 ? response.Name[0] : 'Please fill correct details'
                        this.setState({ hascustomvalidations: true, errordata: response, checkval: true })

                    }
                    else {
                        this.props.snackbarMessage('Please fill correct details', 'error');
                    }

                }
            }).catch(error => {
                error.then(err => {
                    if (err) {
                        this.setState({ hascustomvalidations: true, errordata: err, checkval: true })
                    }
                })
                        
                //this.props.snackbarMessage('Unable to save the report', 'error');
            })
        } else {
            // this.props.snackbarMessage('Please fill the details', 'error');
        }
    }

    setNew = (val) => {
        this.setState({ new: val })
    }

    handleChange = (event, newValue) => {
        if (newValue == 1)
            this.getSavedFilters();
        else
            this.setState({ tabIndex: newValue });
    }

    getSavedFilters() {
        let existingReportsURL = getEnvVariable('Report') + `/api/Reports/AllByGroup/${this.props.gridkey}`
        GetData(existingReportsURL).then(res => {
            //   console.log(res);
            if (res && Array.isArray(res)) {
                this.setState({ savedfilters: res, tabIndex: 1 })
            }
        }).catch(ex => {
            console.log(ex)
        })
    }

    loadReport = (id, view = false) => {
        if (this.props.loadReport) {
            this.props.loadReport(id, view);
        }
    }

    closeDialog = () => {
        this.setState({
            dialogOpen: false
        }, () => {
            if (this.props && this.props.close)
                this.props.close()
        })
    }

    shareReport = (evt, id) => {
        console.log(id)
        this.setState({ openShareOptions: true, selectedReportId: id })
    }

    shareDialogClose = () => {
        this.setState({ openShareOptions: false })
    }

    handleUpdate = () => {
        let opt = this.ShareOptionsForm.current.getShareOptions();
        let api = updateurl + this.state.masterreports[0].Id;
        if (opt.SharedTo === 'R' && !opt.SharedToRoles) {
            this.props.snackbarMessage('Select roles', 'error');
            return false;
        }
        PutData(api, opt).then(response => {
            if (response) {
                this.props.snackbarMessage('Saved Successfully', 'success')
            }
        }).catch(error => {
            error.then(err => {
                if (err) {
                    this.setState({ hascustomvalidations: true, errordata: err, checkval: true })
                }
            })
        })
    }

    handleDefaultReport = (report) => {
        let def = report.IsDefault == "N" ? "Y" : "N";
        let id = report.Id
        const updatedefaultvalurl = getEnvVariable('Report') + `/api/Reports/SetDefault/${id}/${report.GroupKey}?defaultvalue=${def}`

        postRecordData(updatedefaultvalurl, null).then(response => {
            if (response) {
                let allreports = this.state.savedfilters;
                var foundIndex = allreports.findIndex(x => x.Id == id);
                allreports[foundIndex].IsDefault = def;
                this.setState({ savedfilters: allreports }, () => {
                    this.props.snackbarMessage('Saved Successfully', 'success');
                    this.loadReport(id);
                });
            }
        }).catch(error => {
            this.props.snackbarMessage('Unable to save the report', 'error');
        })
    }

    setQuickView = (report) => {
        let def = report.IsQuickView == "N" ? "Y" : "N";
        let id = report.Id
        const updatedefaultvalurl = getEnvVariable('Report') + `/api/Reports/SetQuickView/${id}/${report.GroupKey}?IsQuickView=${def}`
        GetData(updatedefaultvalurl,).then(response => {
            if (response) {
                this.props.snackbarMessage('Saved Successfully', 'success');
            }
        }).catch(error => {
            this.props.snackbarMessage('Unable to set this to quick view', 'error');
        })
    }

    callbackfunc = () => {
        this.setState({ checkval: false })
    }

    render() {
        return (

            <div>
                {this.state.masterreports && this.state.masterreports.length > 0 && <div>

                    <AppBar position="sticky" color='secondary'>

                        <Toolbar variant="dense">
                            <div >
                                <Typography variant="h6" color="inherit" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                    <PostAdd /><p1> Reports </p1>
                                </Typography>
                            </div>
                            <div className="col float-right">&nbsp;</div>
                            {
                                <i className="zoom m-1" onClick={this.props.back && this.props.back['onClick'] ? this.props.back['onClick'] : this.props.close} ><IconButton className="zoom m-1" title="Back" color="inherit">
                                    <ChevronLeftIcon fontSize="small" />
                                </IconButton></i>
                            }
                            {this.state.tabIndex == 0 &&
                                <i className="zoom m-1" onClick={this.handleSave} ><IconButton className="zoom m-1" title="Save" color="inherit">
                                    <SaveIcon fontSize="small" />
                                </IconButton></i>
                            }
                        </Toolbar>

                    </AppBar>

                    <Tabs value={this.state.tabIndex} onChange={this.handleChange} indicatorColor="primary"
                        textColor="primary">
                        <Tab label="New Report" />
                        <Tab label="Load" />
                    </Tabs>

                    <TabPanel className="p-1 m-4" value={this.state.tabIndex} index={0}>
                        <Typography className="col" style={{position : 'relative' , right: '35px'}}>
                            <div className="col-sm-12">
                                <span className="pl-1 m-4 text-primary">Report Group : </span>{this.state.masterreports[0].Name}
                            </div>
                            <SaveReport {...this.props} callbackfunc={this.callbackfunc} checkval={this.state.checkval} errordata={this.state.errordata} hascustomvalidations={this.state.hascustomvalidations} compmap={this.state.compmapdata} ref={this.LayoutForm} />

                        </Typography>
                    </TabPanel>

                    <TabPanel className="col-12 p-1 m-3" value={this.state.tabIndex} index={1}>
                        <Typography>
                            {this.state.savedfilters && this.state.savedfilters.map((element, index) => {
                                return (<div><div className="row pt-1 mr-4">
                                    <div className="col-md-11 col-sm-11 col-xs-11" style={element.IsDefault == 'Y' ? { "color": "green" } : { "color": "black" }}>{element.Title}</div>
                                    <div className="col-md-1 col-sm-1 col-xs-1">
                                        <IconButton size={'small'} name={element.Title}
                                            onClick={(event) => this.setState({ anchorEl: event.currentTarget, optionsOpen: index })}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={this.state.anchorEl}
                                            open={index === this.state.optionsOpen}
                                            onClick={() => this.setState({ anchorEl: null, optionsOpen: false })}
                                        >
                                            <MenuItem onClick={() => this.loadReport(element.Id, true)}>
                                                <ViewCarouselIcon color="primary" /> &nbsp;View
                                            </MenuItem>
                                            <Divider />
                                            {this.state.isInternal && <MenuItem onClick={(evt) => this.shareReport(evt, element.Id)}>
                                                <ShareIcon color="primary" /> &nbsp;Share
                                            </MenuItem>}
                                            <Divider />
                                            <MenuItem onClick={() => { this.handleDefaultReport(element) }}>
                                                <CheckIcon color="primary" /> &nbsp;
                                                {element.IsDefault == 'Y' ? 'Remove Default' : 'Set as Default'}
                                            </MenuItem>
                                            <Divider />
                                            {/* <MenuItem onClick={() => this.setToQuickView(element.Id, true)}>
                                                <ViewCarouselIcon color="primary" /> &nbsp;Quick View
                                            </MenuItem> */}
                                            <Divider />
                                        </Menu>
                                    </div>

                                </div>
                                    <Divider /></div>)
                            })}
                            <Dialog onClose={this.shareDialogClose} fullWidth aria-describedby="share-report" aria-labelledby="simple-dialog-title" open={this.state.openShareOptions}>

                                <DialogTitle id="simple-dialog-title" onClose={this.shareDialogClose}>Share</DialogTitle>
                                <DialogContent>
                                    <ShareOptions {...this.props} ref={this.ShareOptionsForm} id={this.state.selectedReportId} />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleUpdate} color="primary">
                                        Save
                                    </Button>
                                </DialogActions>

                            </Dialog>

                        </Typography>
                    </TabPanel>
                </div>
                }
            </div >

        );
    }
}
const mapProps = (state) => {
    return { compmapdata: state.compmapvalues }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

CustomizedDialogDemoContent = connect(mapProps, mapActions)(CustomizedDialogDemoContent)
export default CustomizedDialogDemoContent;