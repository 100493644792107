import SettingstabView from '../settings/settingstabview'
import { getEnvVariable } from '../../../modules/environmentalList';
 const Settingstab = {
    type: 'container',
    key: 'Settingstab',
    name: 'Settingstab',
    values: {
    },
    mapValues: {
    },
    mapActionsToEffects: {

    },
    layout: {
        group: [
            {
                order: 0,
                type: 'div',
                className: 'row w-100',
                components: [
                    {
                        name: 'SettingsHeader',
                        type: 'div',
                        className: 'col-12 ',
                        sub: {
                            type: 'div',
                            className: 'col-12 ',
                        }
                    },
                    {
                        name: 'SettingstabView',
                        type: 'div',
                        className: 'col-12 mx-3',
                        sub: {
                            type: 'div',
                            className: 'col-12 mx-3',
                        }
                    },
                ]
            }
        ]

    },
    components: {
        SettingsHeader: {
            name: "SettingsHeader",
            type: 'kendoheader',
            title: "Settings",
            components: [
                {
                    type: 'breadcrumbs',
                    items: [
                        { "link": `Site Settings`, "tab": 3, "materialicon": "engineering", href: getEnvVariable('InternalBasepath'), className: 'breadcrumb-item text-dark', class: 'text-dark' },
                        { "link": "Configurations", href: "#/Administration/settings", "materialicon": "settings_suggest", className: 'breadcrumb-item ', class: '' }
                    ],
                }
            ],
            layout: {},
            actions: [
            ],
        },

        SettingstabView: SettingstabView
    }
}






export default Settingstab