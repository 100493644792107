import { getEnvVariable } from "../../../../../modules/environmentalList"

const BusiessRulesAdd = {
    "type": "popup",
    "key": "BusiessRulesAdd",
    "name": "BusiessRulesAdd",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'addOpen'
    },
    sharedValues: ['open'],
    container: {
        values: { save: false, back: false, fullscreen: false, },
        mapValues: {
            header: [
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
            ],
            AddBusiessRules: [{ key: 'save', name: 'save' },
            { key: 'exitfullscreen', name: 'exitfullscreen' },
            { key: 'fullscreen', name: 'fullscreen' },]
        },
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },


            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'AddBusiessRules',
                    type: 'div',
                    className: 'col-12 pl-4 m-4',
                    sub: {
                        type: 'div',
                        className: 'col-12 pl-4 m-4',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Business Rule Settings',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "Add Business Rules Message", "materialicon": "settings", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'save',
                        hidevalue: false,
                        title: 'Save Business Rules Message'
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hidevalue: false,
                        title: 'FullScreen'
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false,
                        title: 'Exit FullScreen'
                    }
                ],


            },
            AddBusiessRules: {
                "type": "form",
                "key": "AddBusiessRules",
                "name": "AddBusiessRules",
                noParams: true,
                // "routeParams": { replace: 'settings', param: 'Id', routeName: 'TableName' },
                aliases: {
                    save: 'save',
                },
                sharedValues: ['save', 'exitfullscreen', 'fullscreen'],
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Type',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8'
                                }
                            },
                            ]
                        },
                        {
                            order: 1,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Message',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            },
                            ]
                        },
                        {
                            order: 2,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'TableName',
                                type: 'div',
                                className: 'col-md-6',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-6',
                                }
                            },
                            {
                                name: 'Status',
                                type: 'div',
                                className: 'col-md-6',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-6',
                                }
                            },
                            ]
                        },
                    ],
                },
                values: {
                    Type: '',
                    Message: '',
                    Key: getEnvVariable('ModuleKey') + 'Module.BUSINESS_RULES_ALERTS',
                    TableName: getEnvVariable('ModuleKey') + 'Module.BUSINESS_RULES_ALERTS',
                    Status: 'A'
                },

                "components": {
                    Type: {
                        "type": "dropdown",
                        "key": "Type",
                        "label": "Message Type",
                        "name": "Type",
                        extraProps: {
                            transformProps: {
                                label: 'Name',
                                value: 'Value'
                            },
                        }
                    },
                    Message: {
                        "type": "textfield",
                        "key": "Message",
                        "label": "Message",
                        "name": "Message",
                    },
                    Status: {
                        "type": "label",
                        "key": "Status",
                        "label": "Status",
                        "name": "Status",
                    },
                    TableName: {
                        "type": "label",
                        "key": "TableName",
                        "label": "TableName",
                        "name": "TableName",
                    },
                },
                mapActionsToEffects: {
                    'onLoad': {
                        init: {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'load',
                                            name: 'Type',
                                            "read": [
                                                {
                                                    url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=PROJECT_MESSAGE_TYPE`,
                                                    type: 'get',
                                                    saveAs: {
                                                        key: 'items'
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            type: 'validations',
                                            "read": [
                                                {
                                                    "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.projectmessages`,
                                                    type: 'get'
                                                }
                                            ],
                                            transformProps: {
                                                removesubfieldname: 'MASTER.PROJECTMESSAGES_'
                                            }
                                        },
                                        { type: 'prop', name: 'TableName', isVisible: false },
                                        { type: 'prop', name: 'Status', isVisible: false },
                                    ]
                                }
                            }]
                        }
                    },
                    'onChange': {
                        'exitfullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'exitfullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'fullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'fullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },

                    },
                    'afterSave': {
                        'save': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Admin') + `/api/ProjectMessages/Add/{name}`,
                                                        type: 'post',
                                                        key: 'save',
                                                        "matchProps": [
                                                            {
                                                                name: "TableName",
                                                                key: "name",
                                                            }
                                                        ],
                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },

                    }
                }
            }
        },

    }
}

export default BusiessRulesAdd