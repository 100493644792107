import React, { forwardRef, useImperativeHandle, useRef, useState, Fragment } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import ReactDOM from "react-dom";

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: 1500,
        maxWidth: 350,
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        width: 350,
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    //  success: { backgroundColor: 'rgb(237, 247, 237)' },
    //error: { backgroundColor: 'rgb(253, 236, 234)' },
    warning: { color: '#b71c1c', backgroundColor: '#ffb74d' },
    info: { backgroundColor: '#64b5f6' },
}))

function Alert(props, ref) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useImperativeHandle(ref, () => ({
        showAlert: (message, variant) => {
            if (typeof message === 'string') {
                if (variant) {
                    enqueueSnackbar(message, { persist: variant === 'success' ? false : true, variant, key: Math.floor((Math.random() * 100) + 1) + '_' + variant })
                }
                else
                    enqueueSnackbar(message, { persist: true, });
            }
        },
        closeAlert: (key) => {
            closeSnackbar(key)
        }
    }))

    return (
        <div />
    );
}

const AlertView = forwardRef(Alert)

function IntegrationNotistack(count = 3, ref) {
    const [open, setOpen] = useState(false);
    const alertView = useRef(null);
    const classes = useStyles();

    useImperativeHandle(ref, () => (
        {
            show: (message, variant) => {
                if (alertView && alertView.current) {
                    alertView.current.showAlert(message, variant)
                    if (variant !== 'success') {
                        Counter.addCount();
                        setOpen(true);
                    }
                }
            }
        }
    ))

    const onClickDismiss = key => () => {
        alertView.current.closeAlert(key);
        if (!(key || '').includes('success'))
            Counter.reduceCount();
        if (Counter.getCount() === 0)
            setOpen(false)
    }

    return ReactDOM.createPortal(
        <Fragment>
            <Backdrop className={classes.backdrop} open={open} />
            <SnackbarProvider maxSnack={4} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} action={(key) => (
                <IconButton aria-label="delete" onClick={onClickDismiss(key)} size="small">
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            )}
                className={classes.root}
                classes={{
                    variantSuccess: classes.success,
                    variantError: classes.error,
                    variantWarning: classes.warning,
                    variantInfo: classes.info,
                }}
            >
                <AlertView ref={alertView} />
            </SnackbarProvider>
        </Fragment>,
        document.getElementById('ames-backdrop')
    );
}

class Counter {
    static count = 0;

    static getCount() {
        return this.count;
    }

    static addCount() {
        this.count = this.count + 1;
    }

    static reduceCount() {
        if (this.count > 0)
            this.count = this.count - 1;
    }
}

export default forwardRef(IntegrationNotistack);