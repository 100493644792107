import React from 'react'
//import { makeStyles } from '@material-ui/core/styles'
import { range } from 'ramda'
import TextField from '@material-ui/core/TextField'

// const useStyles = makeStyles(theme => ({
//     endTimeSelect: {
//         marginLeft: '10px'
//     }
// }))

const ChooseTime = (props) => {
   // const classes = useStyles()

    return (
        <div className="row col-sm-12">
            <TextField
                select
                value={props.hour}
                onChange={props.onAtHourChange}
                SelectProps={{
                    disabled: props.disabled,
                    native: true,
                }}
                margin="normal"
            >
                {range(0, 24).map(m => <option key={m} id={m}>{m.toString().length < 2 ? `0${m}` : m}</option>)}
            </TextField>
           <div className="pt-3"> &nbsp; hour(s) on minute &nbsp;</div>
            <TextField
                select
                value={props.minute}
                onChange={props.onAtMinuteChange}
             
                SelectProps={{
                    disabled: props.disabled,
                    native: true,
                }}
                margin="normal"
            >
                {range(0, 60).map(m => <option key={m} id={m}>{m.toString().length < 2 ? `0${m}` : m}</option>)}
            </TextField>
        </div>
    )
}

export default ChooseTime
