import { getEnvVariable } from "../../modules/environmentalList";

export const getToken = () => {
    let token = localStorage.getItem('token') || null;
    if (!token)
        token = getEnvVariable('AccessPortal') === 'I' ? localStorage.getItem('internaltoken') : localStorage.getItem('externaltoken');
    return token || null
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const GetHeaders = (urlencoded = false, noHeader = false) => {
    var token = getToken()
    let headers = {};
    let IWAAUTH = localStorage.getItem('IWAAUTH')
    if ((window.location && window.location.href.includes('Record')) || IWAAUTH) {
        headers = {}
    }
    if (token && IWAAUTH !== 'Y') {
        headers['Authorization'] = token
        headers['Cache-Control'] = 'no-store,no-cache'
        headers['Pragma'] = 'no-cache'
        if (urlencoded) {
            headers['Content-Type'] = 'application/x-www-form-urlencoded'
        } else {
            if (!noHeader) {
                headers['Content-Type'] = 'application/json';
                headers['accept'] = '*/*';
            }
        }
    }
    else {
        if (urlencoded) {
            headers['Content-Type'] = 'application/x-www-form-urlencoded'
        }
    }
    return headers
}

export const GetFetchProps = (urlencoded = false, noHeader = false) => {
    let fetchProps = {};
    let IWAAUTH = localStorage.getItem('IWAAUTH')
    if (IWAAUTH === 'Y') {
        fetchProps['mode'] = 'cors'
        fetchProps['headers'] = GetHeaders(urlencoded)
        fetchProps['credentials'] = 'include'
    }
    else {
        //  fetchProps['mode'] = 'cors'
        fetchProps['headers'] = GetHeaders(urlencoded, noHeader)
    }
    return fetchProps
}

export const getSignInToken = () => {
    let token = localStorage.getItem('token');
    if (!token) {
        token = getToken();
    }
    return token || null
}


export const getBasePath = () => {
    var basePath = process.env.REACT_APP_basePath;
    if (getEnvVariable('IsBasePath') === 'Y') {
        if (getEnvVariable('AccessPortal') === 'I') {
            basePath = ((getEnvVariable('InternalBasepath') && getEnvVariable('InternalBasepath')) || '/AMES-UI-Internal/#/');
        }
        else {
            basePath = getEnvVariable('ExternalBasepath') && getEnvVariable('ExternalBasepath') || '/AMES-UI-External/#/';
        }
    }
    return basePath
}

export const getAccessById = (SiteId) => {
    let profile = JSON.parse(localStorage.getItem('profile'));
    if (profile && profile.isAdmin == 'Y') {
        return true
    }
    else {

        if (SiteId && SiteId != '-1') {
            let accesskeys = getAccess()
            let access = profile && Object.keys(accesskeys).find(e => e === SiteId)
            if (accesskeys[access] === 'Add')
                return true
            else if (access === 'Read')
                return false
        }
        else return false

    }
}

export const getAccessKeys = () => {
    // and if ConfigType is S  then use ModuleSiteAccess
    // and if ConfigType is R  then use moduleRegionAccess
    // and if ConfigType is D  then use ModuleDistrictAccess to restrict access level in  ames record and side bar
    let profile = JSON.parse(localStorage.getItem('profile'));
    const { configType } = profile
    if (configType === 'D')
        return profile.ModuleDistrictAccess[Object.keys(profile.ModuleDistrictAccess)[0]]
    if (configType === 'R')
        return profile.moduleRegionAccess[Object.keys(profile.moduleRegionAccess)[0]]
    if (configType === 'S')
        return profile.ModuleSiteAccess[Object.keys(profile.ModuleSiteAccess)[0]]
}

export const getAccess = () => {
    // and if ConfigType is S  then use ModuleSiteAccess
    // and if ConfigType is R  then use moduleRegionAccess
    // and if ConfigType is D  then use ModuleDistrictAccess to restrict access level in  ames record and side bar
    let profile = JSON.parse(localStorage.getItem('profile'));
    const { configType } = profile
    if (configType === 'D')
        return profile.moduleDistrictAccess
    if (configType === 'R')
        return profile.moduleRegionAccess
    if (configType === 'S')
        return profile.moduleSiteAccess
}