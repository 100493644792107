
import { isRequired as IsRequired } from './validations'
import { isNumber as IsNumber } from './validations'
import { minLength as MinLength } from './validations'
import { maxLength as maxLength } from './validations'
import { isPercentage as IsPercentage } from './validations'
import { isString as IsString } from './validations'
import { regExp as RegExpression } from './validations'
import { isRange as IsRange } from './validations'
import { urlData as urlData } from './validations'
import { phoneMask as phoneMask } from './validations'
import { stringLength as stringLength } from './validations'
//import { emailAddress as IsEmail } from './validations'

const validationrules = {
    Type: {
        R: {
            IsRequired: IsRequired
        },
        D: {
            Mask: {
                P: IsPercentage,
                BI: IsNumber,
                DN: IsNumber,
                I: IsNumber,
                R: IsNumber,
                N: IsNumber,
                C: IsNumber,
                VC: IsString,
                NVC: IsString,
                L: IsNumber,
                X: RegExpression
                // TB: IsEmail,
                //nvarchar: IsString
            }
        },
        L: {
            Minlen: MinLength,
            Maxlen: maxLength,
        },
        X: {
            RegExp: RegExpression
        },
        G: {
            IsRange: IsRange
        },
        M: {
            Mask: phoneMask
        },
        U: {
            Url: urlData
        },
        Z: {
            Str: stringLength
        },
    }
}


export const globalValidate = (validations, fieldValues) => {
    const errors = {}
    const validationskeys = Object.keys(validations).map(each => each.toUpperCase())
    const fieldValueskeys = Object.keys(fieldValues)
    const uppercasefieldValueskeys = fieldValueskeys.map(each => each.toUpperCase())
    validationskeys.forEach(each => {
        const index = uppercasefieldValueskeys.indexOf(each)
        const fieldName = fieldValueskeys[index]
        const eachfieldvalidations = validations[fieldName]
        if (eachfieldvalidations) {
            const requiredRule = eachfieldvalidations.filter(r => r.Type == "R");
            const isRequired = (requiredRule != null && requiredRule.length > 0)
            eachfieldvalidations.some(e => {
                const fieldValue = fieldValues[fieldName]
                if (fieldName && ((!fieldValue && isRequired) || (fieldValue))) {
                    errors[fieldName] = globaleachvalidate(fieldName, e, fieldValue)
                    if (errors[fieldName].error) return true

                }

            })
        }
    })
    return errors
}
/**
      *********************************************************
      Description: Handles one validation based on type.
      Required props:  fieldName, validation, fieldValue.
      limitations    : N/A
      *********************************************************
**/

// export const fieldValidate = (rules, value) => {
//     let errorObj = null
//     if (rules && rules.length > 0) {
//         for (let i = 0; i < rules.length; i++) {
//             let rule = rules[i]
//             errorObj = globaleachvalidate(rule, value)
//             if (errorObj.error) {
//                 return errorObj
//             }
//         }
//     }
//     else return false
// }

export const fieldValidate = (rules, value) => {
    let errorObj = null
    if (rules && rules[0] && Array.isArray(rules[0])) {
        for (let rule of rules[0]) {
            errorObj = globaleachvalidate(rule, value)
            if (errorObj.errorText !== '') {
                return errorObj
            }
        }
    }
    else {
        rules && rules.some(rule => {
            errorObj = globaleachvalidate(rule, value)
            if (errorObj.errorText !== '') return true
            else return false
        })
    }
    return errorObj
}

export const globaleachvalidate = (validation, fieldValue) => {
    let errorText = ''
    let error = false
    const validationtype = validation.Type
    let validationtypevariants
    let callvalidationfn
    if (validation.Type === 'D') {
        validationtypevariants = validationrules['Type'][validationtype].Mask
        callvalidationfn = validation.Mask
        if (validationtypevariants[callvalidationfn]) {
            errorText = validationtypevariants[callvalidationfn](fieldValue, validation['Mask'][callvalidationfn], validation.Message)
            if (errorText) {
                return {
                    error: true,
                    errorText: errorText
                }
            }
            else {
                return {
                    error: error,
                    errorText: errorText
                }
            }
        }
    }
    if (validation.Type === 'L') {
        validationtypevariants = validationrules['Type'][validationtype]
        if (validation.Maxlen > 0) callvalidationfn = 'Maxlen'
        if (validation.Minlen > 0) callvalidationfn = 'Minlen'

    }
    if (validation.Type === 'R') {
        validationtypevariants = validationrules['Type'][validationtype]
        callvalidationfn = 'IsRequired'

    }
    if (validation.Type === 'G') {
        validationtypevariants = validationrules['Type'][validationtype]
        callvalidationfn = 'IsRange'

    }
    if (validation.Type === 'U') {
        validationtypevariants = validationrules['Type'][validationtype]
        callvalidationfn = 'Url'

    }
    if (validation.Type === 'X') {
        validationtypevariants = validationrules['Type'][validationtype]
        callvalidationfn = 'RegExp'

    }

    if (validation.Type === 'M') {
        validationtypevariants = validationrules['Type'][validationtype]
        callvalidationfn = 'Mask'

    }
    if (validation.Type === 'Z') {
        validationtypevariants = validationrules['Type'][validationtype]
        callvalidationfn = 'Str'
    }
    if (validationtypevariants) {
        //const validationtypekeys = Object.keys(validationtypevariants)
        //error = validationtypekeys.some(each => {
        if (validationtypevariants[callvalidationfn]) {
            let callvalidationAttr = callvalidationfn
            if (callvalidationfn === 'RegExp')
                callvalidationAttr = 'Mask';
            errorText = validationtypevariants[callvalidationfn](fieldValue, validation[callvalidationAttr], validation.Message, validation.Minlen, validation.Maxlen)
            if (errorText) {
                return {
                    error: true,
                    errorText: errorText
                }
            }
            else {
                return {
                    error: error,
                    errorText: errorText
                }
            }
        }
        //})
    }
    return { errorText, error }

}


