/**
      *********************************************************
      Description: Textbox component. xtype can be used to handle the types such as password, email etc.
      Required props:  type, value.
      limitations    : N/A
      *********************************************************
**/
import React, { Component, Fragment, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DefaultSettings from '../../controls/components/settings';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { InputLabel } from '@material-ui/core';

const CFormTextBox = (props) => {
    const { handleFieldChange, onKeyPress, defaultValue, format, value, name, id, tooltip, error, helperText, edit, editduplicate, options, conditions, disabled, accessPermissions, accessLevelPermissions, label, contexthelp, ...otherProps } = props
    let disabledstatus = conditions && conditions.enable ? conditions.enable : disabled
    const { xtype, inputProps, password, required, minnumber, minval, hasdecimalvalue } = otherProps;
    let textProps = otherProps
    let val = (value === undefined) ? '' : value;
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }
    const handleMouseDownPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleNumberChange = (event) => {
        if (minval && event.target.value != '' && event.target.value < minnumber) {
            event.target.value = ''
            props.handleFieldChange(event)
        }
        else if (hasdecimalvalue) {
            props.handleFieldChange(event)
        }
        else {
            event.target.value = parseFloat(event.target.value)
            props.handleFieldChange(event)
        }
    }

    let urlval
    if (val != '' && format === 'URL' && typeof val === 'string') {
        urlval = val.includes("http://") || val.includes("https://") ? val : "http://" + val
    }
    else if (val && typeof val === 'string' && val.includes('?view=embedded')) {
        let temp = val.split('?view=embedded')
        val = temp[0]
    }
    if (edit === false) {
        if (format === 'URL') {
            return <Fragment>
                {(tooltip || contexthelp) && <div > <Typography variant={'caption'} className="fw-bold" color={'textPrimary'}>{label}&nbsp;
                    < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                        <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} onClick={() => DefaultSettings.showDrawer(contexthelp)} />
                    </Tooltip>  </Typography>  <Typography variant={'body2'}  >{val}   </Typography>
                </div>}
                {!tooltip && <div > <Typography variant={'caption'} className="fw-bold">{label}&nbsp; </Typography>  <br />
                    <a target={'_blank'} href={urlval}  >{val}   </a>
                </div>}
            </Fragment >
        }
        else return <Fragment>
            {(tooltip || contexthelp) && <div > <Typography variant={'caption'} className="fw-bold" color={'textPrimary'} >{label}&nbsp;
                < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                    <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} onClick={() => DefaultSettings.showDrawer(contexthelp)} />
                </Tooltip>  </Typography>  <Typography className="text-secondary">{val}   </Typography>
            </div>}
            {!tooltip && <div > <Typography variant={'caption'} className="fw-bold" color={'textPrimary'}>{label}&nbsp; </Typography>   <Typography
                className="eps-content-wrap text-secondary">{val}   </Typography>
            </div>}
        </Fragment >
    }
    else {
        let tlabel = (tooltip || contexthelp) ? <Fragment> {label} < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
            <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} onClick={() => DefaultSettings.showDrawer(contexthelp)} />
        </Tooltip> </Fragment> : label;
        textProps = { ...textProps }
        return <>
        <InputLabel style={{ fontWeight: 'bold', color : error ? 'red' : 'black' }} shrink>{tlabel}</InputLabel>
        <TextField onChange={handleFieldChange}
            value={val || defaultValue}
            name={name}
           // label={variant ? '' : tlabel}
            id={id || name}
            fullWidth
            required={required}
            helperText={helperText}
            {...textProps}
            error={error}
            disabled={editduplicate ? false : disabledstatus}
            onKeyPress={onKeyPress}
            type={(password || xtype == "password") ? (showPassword ? 'text' : "password") : (xtype || 'text')}
            {...inputProps}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                endAdornment: xtype == "password" ? (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                ) : ""
            }} />

        </>

    }
}
export default CFormTextBox;

// changed from xtype && xtype to xtype || type ( type = passowrod or email)