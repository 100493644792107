import { loadModules } from 'esri-loader';
import { QueryTask, CenterAndZoom } from '../util/queryTask';
import { GeometryEngineTask } from '../util/geometryEngineTask';
import { GISLookupTask, GISLookupTaskWithGeom } from './GISLookupTask'


export function FeaturesLayerGraphicsTask(view, config, mapConstants, zoom, SubModuleKey) {
    return new Promise((resolve, reject) => {
        if (!loadModules) {
            reject('FeaturesLayersTask: ArcGIS API is not loaded');
            return;
        }
        if (view && config && zoom && config.drawOptions && config.drawOptions.Tools && config.FeatureLayers && config.FieldsData) {
            var isPointAllowMultiple = config.drawOptions.AllowMultiple && config.drawOptions.AllowMultiple.Points;
            var isLinesAllowMultiple = config.drawOptions.AllowMultiple && config.drawOptions.AllowMultiple.Lines;
            var isPolygonssAllowMultiple = config.drawOptions.AllowMultiple && config.drawOptions.AllowMultiple.Polygons;
            var promises = []
            const tools = config.drawOptions.Tools;
            let type = "POINT"
            if (tools.indexOf("point") > -1) {
                type = "POINT";
                if (isPointAllowMultiple)
                    type = "MULTIPOINT";
                promises.push(FeaturesLayerGraphicsTaskByType(view, config, mapConstants, zoom, type, isPointAllowMultiple, SubModuleKey));
            }
            if (tools.indexOf("polyline") > -1) {
                type = "LINE";
                promises.push(FeaturesLayerGraphicsTaskByType(view, config, mapConstants, zoom, type, isLinesAllowMultiple, SubModuleKey));
            }
            if (tools.indexOf("polygon") > -1 || tools.indexOf("rectangle") > -1 || tools.indexOf("circle") > -1) {
                type = "POLYGON";
                promises.push(FeaturesLayerGraphicsTaskByType(view, config, mapConstants, zoom, type, isPolygonssAllowMultiple, SubModuleKey));
            }
            // Specific to map selection with 'SELECT TOOL' and no map point available. Select tool always a polygon.
            if (promises && promises.length < 1 && Array.isArray(config.SelectLayerListOptions)
                && config.SelectLayerListOptions.length > 0) {
                type = "POLYGON";
                promises.push(FeaturesLayerGraphicsTaskByType(view, config, mapConstants, zoom, type, isPolygonssAllowMultiple, SubModuleKey));
            }

            Promise.all(promises).then(applyViewResponses => {
                if (applyViewResponses) {
                    const pointgeomApplyViewResponse = applyViewResponses[0]; //Point
                    const linegeomApplyViewResponse = applyViewResponses[1]; //Line
                    const polygongeomApplyViewResponse = applyViewResponses[2]; //Polygon

                    let applyViewGraphics = { ...pointgeomApplyViewResponse, ...linegeomApplyViewResponse, ...polygongeomApplyViewResponse };
                    resolve(applyViewGraphics);
                }
            },
                error => {
                    console.log(error);
                    reject({ "error": "mapview ,zoom, drawOptions.Tools, config.FeatureLayers and config.FieldsData are not found " });
                });
        } else {
            reject({ "error": "mapview ,zoom, drawOptions.Tools, config.FeatureLayers and config.FieldsData are not found " });
        }
    });
};

function GetQueryValue(stringval) {
    let result = "";
    if (stringval) {
        console.log(stringval);
        let arr = stringval.toString().split(',');
        if (arr && arr.length > 0) {
            arr.forEach(element => {
                if (result === "") {
                    result = "" + element + ""
                } else {
                    result = result + "," + element + "";
                }
            });
        }
    }
    return result;
}

function FeaturesLayerGraphicsTaskByType(view, config, mapConstants, zoom, type, isAllowMultiple, SubModuleKey) {
    return new Promise((resolve, reject) => {
        let preType = type;

        const graphicsLayerIndex = config.FeatureLayers["LayerIndex"] || 3;
        let FDObjectid = config.FieldsData.filter(f => {
            return (f.Fieldkey == type + "_GEOM_OBJECT_ID");
        }).map(f => f.FieldValue);
        if ((FDObjectid[0] == 0 || FDObjectid[0] == null) && type == "MULTIPOINT" && isAllowMultiple) {
            type = "POINT";
            FDObjectid = config.FieldsData.filter(f => {
                return (f.Fieldkey == type + "_GEOM_OBJECT_ID");
            }).map(f => f.FieldValue);
        }


        let proposed_objectid = []

        let proposed_objectid_point = config.FieldsData.filter(f => {
            return (f.Fieldkey.includes("PROPOSED_" + type + "_GEOM_OBJECT_ID") && f.FieldValue > 0);
        }).map(f => f.FieldValue)

        let proposed_objectid_multipoint = config.FieldsData.filter(f => {
            return (f.Fieldkey.includes("PROPOSED_MULTIPOINT_GEOM_OBJECT_ID") && f.FieldValue > 0);
        }).map(f => f.FieldValue)
        proposed_objectid = [...proposed_objectid_point, ...proposed_objectid_multipoint]


        //Get unique values
        proposed_objectid = [...new Set(proposed_objectid)]

        let allGeomObjectIds = [...proposed_objectid, ...FDObjectid]

        const featureUrl = config.FeatureLayers[type];
        const layerDef = { "queryExpression": "1=-1", "url": featureUrl };

        if (allGeomObjectIds && allGeomObjectIds.length > 0) {
            let searchEqu = "";
            for (var i = 0; i < allGeomObjectIds.length; i++) {
                if (searchEqu === "") {
                    if (allGeomObjectIds[i]) {
                        searchEqu = GetQueryValue(allGeomObjectIds[i]);
                    }
                } else {
                    if (allGeomObjectIds[i])
                        searchEqu = searchEqu + "," + GetQueryValue(allGeomObjectIds[i]);
                }
            }
            if (searchEqu && searchEqu != "")
                layerDef.queryExpression = "ObjectId in (" + searchEqu + ")";
            else
                layerDef.queryExpression = "ObjectId in (-1)";
        }


        QueryTask(layerDef, layerDef.queryExpression, view.spatialReference).then(
            response => {
                if (response && response.features) {
                    loadModules([
                        "esri/layers/GraphicsLayer",
                        "esri/Graphic",
                        "esri/geometry/Point",
                        "esri/geometry/Multipoint",
                        'esri/geometry/Polyline',
                        'esri/geometry/Polygon',
                        'esri/symbols/PictureMarkerSymbol', 'esri/geometry/SpatialReference',
                        "esri/layers/FeatureLayer"
                    ]).then(([GraphicsLayer, Graphic, Point, Multipoint, Polyline, Polygon,
                        PictureMarkerSymbol, SpatialReference, FeatureLayer]) => {

                        let layer = view.map.layers.find(function (layer) {
                            return layer.title === "Sketch Layer";
                        });

                        if (!layer) {
                            layer = new GraphicsLayer({
                                "title": "Sketch Layer", listMode: 'hide'
                            });
                        }

                        let geometries = [];
                        response.features.forEach(function (item) {
                            let type = item.geometry.type;
                            type = (type === "point" || type == "multipoint" ? "POINT" : (type === "polyline" ? "LINE" : (type === "polygon" ? "POLYGON" : null)));
                            let geom = (type === "LINE" ? new Polyline(item.geometry) : (type === "POLYGON" ? new Polygon(item.geometry) : null));

                            if (type === "POINT") {
                                if (item.geometry && item.geometry.points != null && item.geometry.points.length > 0) {
                                    geom = new Multipoint(item.geometry);

                                } else {
                                    geom = new Point(item.geometry);
                                }
                            }

                            let geomSymbol = (type === "POINT" || type == "MULTIPOINT" ? ((SubModuleKey == "COLLISION" || SubModuleKey == "INVESTIGATION") ? mapConstants.getSVGPointtSymbol() : mapConstants.ExistingPointSymbol) : (type === "LINE" ? mapConstants.LineSymbol : (type === "POLYGON" ? mapConstants.PolygonFillSymbol : null)));
                            if (proposed_objectid && proposed_objectid.length > 0) {
                                if (proposed_objectid[0] == item.attributes.OBJECTID) {
                                    geomSymbol = (type === "POINT" || type == "MULTIPOINT" ? ((SubModuleKey == "COLLISION" || SubModuleKey == "INVESTIGATION") ? mapConstants.getSVGHighlighPointtSymbol() : mapConstants.PointSymbol) : (type === "LINE" ? mapConstants.LineSymbol : (type === "POLYGON" ? mapConstants.PolygonFillSymbol : null)));
                                }
                            }
                            if (config && config.MultipleLocationData && Array.isArray(config.MultipleLocationData) && config.MultipleLocationData.length > 0) {
                                let currgeomobjectid = item.attributes.OBJECTID;
                                let primaryobjectidRes = config.MultipleLocationData.filter(a => a.IsPrimary == 'Y');
                                if (primaryobjectidRes && Array.isArray(primaryobjectidRes) && primaryobjectidRes.length > 0) {
                                    let primaryobjectid = primaryobjectidRes[0].ObjectId;
                                    if (currgeomobjectid == primaryobjectid) {
                                        geomSymbol = (type === "POINT" || type == "MULTIPOINT" ? ((SubModuleKey == "COLLISION" || SubModuleKey == "INVESTIGATION") ? mapConstants.getSVGHighlighPointtSymbol() : mapConstants.PointSymbol) : (type === "LINE" ? mapConstants.LineSymbol : (type === "POLYGON" ? mapConstants.PolygonFillSymbolSecondary : null)));
                                    }
                                }
                            }


                            geometries.push(geom);
                            let g = new Graphic({
                                geometry: geom,
                                attributes: item.attributes,
                                symbol: geomSymbol
                            });
                            if (layer && layer.graphics)
                                layer.graphics.add(g);
                        });
                        view.map.layers.add(layer, graphicsLayerIndex);
                        if (geometries) {

                            if (geometries.length > 1) {
                                GeometryEngineTask({ "geometries": geometries, "spatialReference": view.spatialReference, "type": "union" }).then(
                                    GeomEngineresponse => {
                                        if (GeomEngineresponse && GeomEngineresponse.centroid)
                                            CenterAndZoom(view, GeomEngineresponse.centroid, zoom);
                                        else
                                            CenterAndZoom(view, geometries[0], zoom);
                                    },
                                    GeomEngineerror => {
                                        CenterAndZoom(view, geometries[0], zoom);
                                        console.log(GeomEngineerror);
                                    });
                            } else if (geometries.length === 1) {
                                CenterAndZoom(view, geometries[0], zoom);
                            }

                        }


                    });
                }
                resolve(true);
            },
            error => {
                console.log(error);
                reject({ "error": error });
            }
        );
    });
}


export function FeaturesLayerGraphicsTaskAddLookup(view, config, mapConstants, zoom, lookupdata, SubModuleKey) {

    var isPointAllowMultiple = config.drawOptions.AllowMultiple && config.drawOptions.AllowMultiple.Points; //this.props.options.AllowMultiple && this.props.options.AllowMultiple.Points;
    var isLinesAllowMultiple = config.drawOptions.AllowMultiple && config.drawOptions.AllowMultiple.Lines; //this.props.options.AllowMultiple && this.props.options.AllowMultiple.Lines;
    var isPolygonssAllowMultiple = config.drawOptions.AllowMultiple && config.drawOptions.AllowMultiple.Polygons; //this.props.options.AllowPolygons && this.props.options.AllowMultiple.Polygons;

    var promises = []
    const tools = config.drawOptions.Tools;
    let type = "POINT"
    if (tools.indexOf("point") > -1) {
        type = "POINT";
        if (isPointAllowMultiple)
            type = "MULTIPOINT";
        promises.push(FeaturesLayerGraphicsTaskAddLookupByType(view, config, mapConstants, zoom, lookupdata, type, isPointAllowMultiple, SubModuleKey));
    }
    if (tools.indexOf("polyline") > -1) {
        type = "LINE";
        promises.push(FeaturesLayerGraphicsTaskAddLookupByType(view, config, mapConstants, zoom, lookupdata, type, isLinesAllowMultiple, SubModuleKey));
    }
    if (tools.indexOf("polygon") > -1 || tools.indexOf("rectangle") > -1 || tools.indexOf("circle") > -1) {
        type = "POLYGON";
        promises.push(FeaturesLayerGraphicsTaskAddLookupByType(view, config, mapConstants, zoom, lookupdata, type, isPolygonssAllowMultiple, SubModuleKey));
    }
    // Specific to map selection with 'SELECT TOOL' and no map point available. Select tool always a polygon.
    if (promises.length < 1 && Array.isArray(config.SelectLayerListOptions)
        && config.SelectLayerListOptions.length > 0) {
        type = "POLYGON";
        promises.push(FeaturesLayerGraphicsTaskByType(view, config, mapConstants, zoom, type, isPolygonssAllowMultiple, SubModuleKey));
    }


    return Promise.all(promises).then(applyViewResponses => {
        if (applyViewResponses) {
            const pointgeomApplyViewResponse = applyViewResponses[0]; //Point
            const linegeomApplyViewResponse = applyViewResponses[1]; //Line
            const polygongeomApplyViewResponse = applyViewResponses[2]; //Polygon

            let applyViewGraphics = { ...pointgeomApplyViewResponse, ...linegeomApplyViewResponse, ...polygongeomApplyViewResponse };
            return applyViewGraphics;
            //resolve(applyViewGraphics);
        }
    }, error => {
        console.log(error);
        //'reject' is not defined  no-undef correct:
        //  reject({ "error": "mapview ,zoom, drawOptions.Tools, config.FeatureLayers and config.FieldsData are not found " });
    });
};
function FeaturesLayerGraphicsTaskAddLookupByType(view, config, mapConstants, zoom, lookupdata, type, isAllowMultiple, SubModuleKey) {
    return new Promise((resolve, reject) => {
        if (!loadModules) {
            reject('FeaturesLayersTask: ArcGIS API is not loaded');
            return;
        }
        if (view && config && zoom && config.drawOptions && config.drawOptions.Tools && config.FeatureLayers && config.FieldsData) {

            const graphicsLayerIndex = config.FeatureLayers["LayerIndex"] || 3;
            let FDObjectid = config.FieldsData.filter(f => {
                return f.Fieldkey.includes(type + "_GEOM_OBJECT_ID");
            }).map(f => f.FieldValue);
            if ((FDObjectid[0] == 0 || FDObjectid[0] == null) && type == "MULTIPOINT" && isAllowMultiple) {
                type = "POINT";
                FDObjectid = config.FieldsData.filter(f => {
                    return f.Fieldkey.includes(type + "_GEOM_OBJECT_ID");
                }).map(f => f.FieldValue);
            }


            let proposed_objectid = []

            let proposed_objectid_point = config.FieldsData.filter(f => {
                return (f.Fieldkey.includes("PROPOSED_" + type + "_GEOM_OBJECT_ID"));
            }).map(f => f.FieldValue)

            let proposed_objectid_multipoint = config.FieldsData.filter(f => {
                return (f.Fieldkey.includes("PROPOSED_MULTIPOINT_GEOM_OBJECT_ID"));
            }).map(f => f.FieldValue)

            proposed_objectid = [...proposed_objectid_point, ...proposed_objectid_multipoint]

            // if(type==='POINT' || type==='MULTIPOINT'){

            // }else{
            //     proposed_objectid = [...proposed_objectid_point]
            // }
            //Get unique values
            proposed_objectid = [...new Set(proposed_objectid)]
            let allGeomObjectIds = [...proposed_objectid, ...FDObjectid]

            const featureUrl = config.FeatureLayers[type];
            const layerDef = { "queryExpression": "1=-1", "url": featureUrl };
            console.log('all geom object ids', allGeomObjectIds);

            if (allGeomObjectIds && allGeomObjectIds.length > 0) {
                let searchEqu = "";
                for (var i = 0; i < allGeomObjectIds.length; i++) {
                    console.log(allGeomObjectIds[i], '-----searchEqu---------', searchEqu)
                    if (searchEqu === "") {
                        if (allGeomObjectIds[i]) {
                            searchEqu = GetQueryValue(allGeomObjectIds[i]);
                        }
                    } else {
                        if (allGeomObjectIds[i])
                            searchEqu = searchEqu + "," + GetQueryValue(allGeomObjectIds[i]);
                    }
                }
                if (searchEqu && searchEqu != "")
                    layerDef.queryExpression = "ObjectId in (" + searchEqu + ")";
                else
                    layerDef.queryExpression = "ObjectId in (-1)";
            }


            // if (FDObjectid && FDObjectid.length > 0) {
            //     let searchEqu = "";
            //     for (var i = 0; i < FDObjectid.length; i++) {
            //         if (searchEqu === "") {
            //             if (FDObjectid[i] && FDObjectid[i].length > 0)
            //                 searchEqu = GetQueryValue(FDObjectid[i]);
            //         } else {
            //             if (FDObjectid[i] && FDObjectid[i].length > 0)
            //                 searchEqu = searchEqu + "," + GetQueryValue(FDObjectid[i]);
            //         }
            //     }
            //     if (searchEqu && searchEqu != "")
            //         layerDef.queryExpression = "ObjectId in (" + searchEqu + ")";
            //     else
            //         layerDef.queryExpression = "ObjectId in (-1)";
            // }

            QueryTask(layerDef, layerDef.queryExpression, view.spatialReference).then(
                response => {
                    if (response && response.features) {
                        loadModules([
                            "esri/layers/GraphicsLayer",
                            "esri/Graphic",
                            "esri/geometry/Point",
                            "esri/geometry/Multipoint",
                            'esri/geometry/Polyline',
                            'esri/geometry/Polygon',
                            'esri/symbols/PictureMarkerSymbol', 'esri/geometry/SpatialReference',
                            "esri/layers/FeatureLayer"
                        ]).then(([GraphicsLayer, Graphic, Point, Multipoint, Polyline, Polygon,
                            PictureMarkerSymbol, SpatialReference, FeatureLayer]) => {

                            let layer = view.map.layers.find(function (layer) {
                                return layer.title === "Sketch Layer";
                            });

                            if (!layer) {
                                layer = new GraphicsLayer({
                                    "title": "Sketch Layer", listMode: 'hide'
                                });
                            }

                            let geometries = [];
                            response.features.forEach(function (item) {
                                let type = item.geometry.type;
                                type = (type === "point" || type == "multipoint" ? "POINT" : (type === "polyline" ? "LINE" : (type === "polygon" ? "POLYGON" : null)));
                                let geom = (type === "LINE" ? new Polyline(item.geometry) : (type === "POLYGON" ? new Polygon(item.geometry) : null));

                                if (type === "POINT") {
                                    if (item.geometry && item.geometry.points != null && item.geometry.points.length > 0) {
                                        geom = new Multipoint(item.geometry);

                                    } else {
                                        geom = new Point(item.geometry);
                                    }
                                }
                                let geomSymbol = (type === "POINT" || type == "MULTIPOINT" ? ((SubModuleKey == "COLLISION" || SubModuleKey == "INVESTIGATION") ? mapConstants.getSVGPointtSymbol() : mapConstants.ExistingPointSymbol) : (type === "LINE" ? mapConstants.LineSymbol : (type === "POLYGON" ? mapConstants.PolygonFillSymbol : null)));
                                if (proposed_objectid && proposed_objectid.length > 0) {
                                    if (proposed_objectid[0] == item.attributes.OBJECTID) {
                                        geomSymbol = (type === "POINT" || type == "MULTIPOINT" ? mapConstants.PointSymbol : (type === "LINE" ? mapConstants.LineSymbol : (type === "POLYGON" ? mapConstants.PolygonFillSymbol : null)));
                                    }
                                }
                                if (config && config.MultipleLocationData && Array.isArray(config.MultipleLocationData) && config.MultipleLocationData.length > 0) {
                                    let currgeomobjectid = item.attributes.OBJECTID;
                                    let primaryobjectidRes = config.MultipleLocationData.filter(a => a.IsPrimary == 'Y');
                                    if (primaryobjectidRes && Array.isArray(primaryobjectidRes) && primaryobjectidRes.length > 0) {
                                        let primaryobjectid = primaryobjectidRes[0].ObjectId;
                                        if (currgeomobjectid == primaryobjectid) {
                                            geomSymbol = (type === "POINT" || type == "MULTIPOINT" ? mapConstants.PointSymbol : (type === "LINE" ? mapConstants.LineSymbol : (type === "POLYGON" ? mapConstants.PolygonFillSymbolSecondary : null)));
                                        }
                                    }
                                }


                                geometries.push(geom);
                                let g = new Graphic({
                                    geometry: geom,
                                    attributes: item.attributes,
                                    symbol: geomSymbol
                                });

                                layer.graphics.add(g);
                            });
                            view.map.layers.add(layer, graphicsLayerIndex);
                            if (geometries) {

                                if (geometries.length > 1) {
                                    GeometryEngineTask({ "geometries": geometries, "spatialReference": view.spatialReference, "type": "union" }).then(
                                        GeomEngineresponse => {
                                            if (GeomEngineresponse && GeomEngineresponse.centroid)
                                                CenterAndZoom(view, GeomEngineresponse.centroid, zoom);
                                            else
                                                CenterAndZoom(view, geometries[0], zoom);
                                        },
                                        GeomEngineerror => {
                                            console.log(GeomEngineerror);
                                        });
                                } else if (geometries.length === 1) {
                                    CenterAndZoom(view, geometries[0], zoom);
                                }

                                if (lookupdata && lookupdata.length > 0 && geometries.length > 0) {
                                    // let lookupDataresult = GISLookupTaskWithGeom(geometries, lookupdata);
                                    // console.log('before adding----'+lookupdata);
                                    // resolve(lookupDataresult);
                                    GISLookupTaskWithGeom(config, geometries, lookupdata).then(function (lookupDataresult) {
                                        resolve(lookupDataresult);
                                    })
                                } else {
                                    resolve({})
                                }

                            }
                        });
                    }
                },
                error => {
                    console.log(error);
                    reject({ "error": error });
                }
            );
        } else {
            reject({ "error": "mapview ,zoom, drawOptions.Tools, config.FeatureLayers and config.FieldsData are not found " });
        }
    });
}


export function FeaturesLayerGraphicsTaskAddLookup_NoDraw(config, geometries, lookupdata) {
    return new Promise((resolve, reject) => {
        GISLookupTaskWithGeom(config, geometries, lookupdata).then(function (lookupDataresult) {
            resolve(lookupDataresult);
        })
    })
}