import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { loadModules } from 'esri-loader';
//import proj4 from "proj4";
class CBasemapGallery extends Component {
    constructor(props) {
        super(props); 
        this.state =  { 
            view: this.props.view
        } 
        //proj4.defs("EPSG:3071","+proj=tmerc +lat_0=0 +lon_0=-90 +k=0.9996 +x_0=520000 +y_0=-4480000 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs");               
        //proj4.defs("ESRI:102100","+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs");
        //proj4.defs("EPSG:4326","+proj=longlat +datum=WGS84 +no_defs +type=crs");
        this.startup(
            this.props
        ); 
    }  
   
     
    startup = (config) => {
        loadModules([
            "esri/core/watchUtils",
            "esri/widgets/BasemapGallery",
            "esri/widgets/Expand",
            "esri/Basemap",
            "esri/layers/TileLayer"
        ]).then(([watchUtils,BasemapGallery, Expand, Basemap, TileLayer]) => {            

            const basemapGallery = new BasemapGallery({
                view: config.view,
                //source: [customBasemap]
            });
            this.basemapcontainer = ReactDOM.findDOMNode(this);
            const basemapExpand = new Expand({
                collapseTooltip: "Collapse Basemap Gallery",
                expandTooltip: "Expand Basemap Gallery",
                view: config.view,
                content: basemapGallery,
                container: this.basemapcontainer
            }); 
            config.view.ui.add(basemapExpand, "top-right");
            this.props.widgetRenderCallBack("BasemapGallery");
            const inThis = this;
            watchUtils.pausable(basemapExpand, "expanded", function (newValue, oldValue) { 
                inThis.props.onexpanded(basemapExpand, newValue);
            });
        });
    }

    tileFunction=(urlTemplate,level, row, col) => {
        var tileUrl = urlTemplate
          .replace("{level}", level);
          //.replace("{col}", col)
          //.replace("{row}", row);
    
          console.log("before :", [col,row])
        // Transform the tile coordinates from custom projection to Web Mercator
        var transformedTileCoords = null; //proj4("EPSG:102100","EPSG:3071", [col,row]);
        console.log("transformedTileCoords",transformedTileCoords)
        var transformedCol = Math.floor(transformedTileCoords[0]);
        var transformedRow = Math.floor(transformedTileCoords[1]);
    
        return tileUrl
          .replace("{col}", transformedCol)
          .replace("{row}", transformedRow);
    }

    startup_custom_1 = (config) =>{

        loadModules([
            "esri/core/watchUtils",
            "esri/widgets/BasemapGallery",
            "esri/Basemap",
            "esri/widgets/Expand",
            "esri/portal/Portal",
            "esri/layers/TileLayer",
            "esri/layers/WebTileLayer",
            "esri/geometry/SpatialReference",            
            "esri/geometry/Extent",
            "esri/layers/MapImageLayer",
        ]).then(([watchUtils,BasemapGallery,Basemap, Expand,Portal,TileLayer,WebTileLayer,SpatialReference,Extent, MapImageLayer]) => {
                let inThis = this;

                var customSpatialReference = new SpatialReference({
                    wkid: 3071
                });
                config.view.spatialReference = customSpatialReference;
                config.view.map.spatialReference = 3071
                const basemap = new Basemap({
                    baseLayers: [
                      new TileLayer({
                        url: "https://di-wisdot.img.arcgis.com/arcgis/rest/services/Basemap/WisconsinGeneralBasemapCache/MapServer",
                        title: "Basemap"
                      })
                    ],
                    title: "basemap"                    
                });

                const customLayer = new TileLayer({
                    url: "https://di-wisdot.img.arcgis.com/arcgis/rest/services/Basemap/WisconsinGeneralBasemapCache/MapServer",
                    spatialReference: {wkid: 102100}
                });

                const customBasemap = new Basemap({
                    baseLayers: [customLayer],
                    title: "Custom Basemap",                    
                    //spatialReference: customSpatialReference
                });
                //config.view.map.basemap = customBasemap;
                //config.view.map.layers.add(customLayer,99);
            //     const GeneralBaseMap = new Basemap({spatialReference: config.view.spatialReference,
            //         title: "General Base Map",
            //         portalItem: {
            //             id: "b3b907def1374c6e89f75ef8c7551f9c",
            //         },
            //         //spatialReference: config.view.spatialReference,
            //     });

            
              
            //   // Define the full extent of the custom basemap in the specified spatial reference
            //   var customFullExtent = new Extent({
            //     xmin: 332885.3621727978,
            //     ymin: 353254.30098708975,
            //     xmax: 773710.778192797,
            //     ymax:658713.7130523947,
            //     spatialReference: customSpatialReference
            //   }); 
            //   const inThis = this;
            //   var mapBaseLayer = new WebTileLayer({
            //     urlTemplate: "https://dotmaps.wi.gov/arcgis/rest/services/cache/dbmGBMAPmsC/MapServer/tile/{level}/{row}/{col}?blankTile=false",
            //     //fullExtent: customFullExtent,
            //     //spatialReference: customSpatialReference,
            //     id: "custommapBaseLayer",
            //     //getTileUrl:function (level, row, col) { return inThis.tileFunction("https://dotmaps.wi.gov/arcgis/rest/services/cache/dbmGBMAPmsC/MapServer/tile/{level}/{col}/{row}?blankTile=false",level, row, col)}

            //   });
                
            //   var webTileLayerBasemap = new Basemap({
            //     id: "webTileLayer",
            //     title: "Custom Basemap",
            //     thumbnailUrl: "https://stamen-tiles.a.ssl.fastly.net/terrain/10/177/409.png",
            //     baseLayers: [
            //         mapBaseLayer
            //     ]
            //   }); 


            // var mapBaseLayer2 = new WebTileLayer({
            //     urlTemplate: "https://dotmaps.wi.gov/arcgis/rest/services/cache/dbmNAIPCYmsC/MapServer/tile/{level}/{col}/{row}?blankTile=false",
            //     //fullExtent: customFullExtent,
            //     //spatialReference: customSpatialReference,
            //     id: "custommapBaseLayer2",
            //     getTileUrl:function (level, row, col) {  return inThis.tileFunction("https://dotmaps.wi.gov/arcgis/rest/services/cache/dbmNAIPCYmsC/MapServer/tile/{level}/{col}/{row}?blankTile=false",level, row, col)}
            // });
            // var webTileLayerBasemap1 = new Basemap({
            //     id: "webTileLayer2",
            //     title: "Custom Basemap2",
            //     thumbnailUrl: "https://wisdot.maps.arcgis.com/sharing/rest/content/items/9ca058681b4e40afa6e81b7d2a2da390/info/thumbnail/thumbnail1604678225947.png",
            //     baseLayers: [
            //         mapBaseLayer2
            //     ]
            // }); 

            var basemapsSource = [
                customBasemap,
                //webTileLayerBasemap1,
                //GeneralBaseMap,
                //GeneralBaseMap2,
                Basemap.fromId("topo"),
                Basemap.fromId("streets"),
            ];
              
            const basemapGallery = new BasemapGallery({
                view: config.view,                
                source: basemapsSource
            });
            //basemapGallery.source.basemaps.push(basemapbyportal);
            //config.view.map.basemap=GeneralBaseMap
            //config.view.spatialReference=config.view.map.basemap.spatialReference;

            this.basemapcontainer = ReactDOM.findDOMNode(this);
            const basemapExpand = new Expand({
                collapseTooltip: "Collapse Basemap Gallery",
                expandTooltip: "Expand Basemap Gallery",
                view: config.view,
                content: basemapGallery,
                container: this.basemapcontainer
            }); 
            config.view.ui.add(basemapExpand, "top-right");
            this.props.widgetRenderCallBack("BasemapGallery")
            watchUtils.pausable(basemapExpand, "expanded", function (newValue, oldValue) { 
                inThis.props.onexpanded(basemapExpand, newValue);
            });
        });
    

    }

    startup_custom = (config) =>{

        loadModules([
            "esri/core/watchUtils",
            "esri/widgets/BasemapGallery",
            "esri/Basemap",
            "esri/widgets/Expand",
            "esri/portal/Portal",
            "esri/layers/TileLayer",
            "esri/layers/WebTileLayer",
            "esri/geometry/SpatialReference",            
            "esri/geometry/Extent"
        ]).then(([watchUtils,BasemapGallery,Basemap, Expand,Portal,TileLayer,WebTileLayer,SpatialReference,Extent]) => {
           
                const GeneralBaseMap = new Basemap({spatialReference: config.view.spatialReference,
                    title: "General Base Map",
                    portalItem: {
                        id: "b3b907def1374c6e89f75ef8c7551f9c",
                    },
                    //spatialReference: config.view.spatialReference,
                });

               var customSpatialReference = new SpatialReference({
                    wkid: 3071
                });
              
              // Define the full extent of the custom basemap in the specified spatial reference
              var customFullExtent = new Extent({
                xmin: 332885.3621727978,
                ymin: 353254.30098708975,
                xmax: 773710.778192797,
                ymax:658713.7130523947,
                spatialReference: customSpatialReference
              }); 
              const inThis = this;
              var mapBaseLayer = new WebTileLayer({
                urlTemplate: "https://dotmaps.wi.gov/arcgis/rest/services/cache/dbmGBMAPmsC/MapServer/tile/{level}/{row}/{col}?blankTile=false",
                //fullExtent: customFullExtent,
                //spatialReference: customSpatialReference,
                id: "custommapBaseLayer",
                //getTileUrl:function (level, row, col) { return inThis.tileFunction("https://dotmaps.wi.gov/arcgis/rest/services/cache/dbmGBMAPmsC/MapServer/tile/{level}/{col}/{row}?blankTile=false",level, row, col)}

              });
                
              var webTileLayerBasemap = new Basemap({
                id: "webTileLayer",
                title: "Custom Basemap",
                thumbnailUrl: "https://stamen-tiles.a.ssl.fastly.net/terrain/10/177/409.png",
                baseLayers: [
                    mapBaseLayer
                ]
              }); 


            var mapBaseLayer2 = new WebTileLayer({
                urlTemplate: "https://dotmaps.wi.gov/arcgis/rest/services/cache/dbmNAIPCYmsC/MapServer/tile/{level}/{col}/{row}?blankTile=false",
                //fullExtent: customFullExtent,
                //spatialReference: customSpatialReference,
                id: "custommapBaseLayer2",
                getTileUrl:function (level, row, col) {  return inThis.tileFunction("https://dotmaps.wi.gov/arcgis/rest/services/cache/dbmNAIPCYmsC/MapServer/tile/{level}/{col}/{row}?blankTile=false",level, row, col)}
            });
            var webTileLayerBasemap1 = new Basemap({
                id: "webTileLayer2",
                title: "Custom Basemap2",
                thumbnailUrl: "https://wisdot.maps.arcgis.com/sharing/rest/content/items/9ca058681b4e40afa6e81b7d2a2da390/info/thumbnail/thumbnail1604678225947.png",
                baseLayers: [
                    mapBaseLayer2
                ]
            }); 

            var basemapsSource = [
                webTileLayerBasemap,
                webTileLayerBasemap1,
                //GeneralBaseMap,
                //GeneralBaseMap2,
                Basemap.fromId("topo"),
                Basemap.fromId("streets"),
              ];
              
            const basemapGallery = new BasemapGallery({
                view: config.view,                
                source: basemapsSource
            });
            //basemapGallery.source.basemaps.push(basemapbyportal);
            //config.view.map.basemap=GeneralBaseMap
            //config.view.spatialReference=config.view.map.basemap.spatialReference;

            this.basemapcontainer = ReactDOM.findDOMNode(this);
            const basemapExpand = new Expand({
                collapseTooltip: "Collapse Basemap Gallery",
                expandTooltip: "Expand Basemap Gallery",
                view: config.view,
                content: basemapGallery,
                container: this.basemapcontainer
            }); 
            config.view.ui.add(basemapExpand, "top-right");
            this.props.widgetRenderCallBack("BasemapGallery")
            watchUtils.pausable(basemapExpand, "expanded", function (newValue, oldValue) { 
                inThis.props.onexpanded(basemapExpand, newValue);
            });
        });
    

    }
     

  render() {
    return (
        <div className="BasemapGallery-box" style={{order:this.props.order}}>
          
        </div>
    );
  }
}

export default CBasemapGallery;