import { getEnvVariable } from '../../modules/environmentalList';
import newsavedreportstypes from '../reports/savedreports';
const abc = {
    "type": "grid",
    "key": "agencyusersloginhistorygrid",
    "gridkey": 'agencyusersloginhistorygrid',
    "order": 1,
    "options": {
        receiveValues: [{ paramname: 'userId', valuefromparent: 'userId' }],
        sidepopupgridheight: true,
        "crud": {
            "read": {
                "get": {
                    "url": getEnvVariable('MasterAdmin')+`/api/InternalUser/LoginHistory/Grid/`+getEnvVariable('ModuleKey')+`/{userId}`,
                    "customProps": {
                        "value": "userId"
                    }
                }
            }
        },
        "back": {
            "routes": [
                {
                    "route": "/Administration/agencyusers",
                    "routeParams": "routeid",
                },
            ],
        },
        headerProps: {
            title: "User(s) Login History",
            actions: [
                {
                    type: 'fab',
                    icon: 'clear',
                    name: 'clear',
                    color: 'primary',
                    title: 'Clear Filters',
                    menucolor: '#22a9b3'
                },

                  {
                    type: 'menu',
                    icon: 'excel',
                    name: 'excel',
                    color: 'primary',
                    title: 'Export to Excel',
                    menucolor: '#c90685',
                    items: [{
                        name: 'Export Current Page',
                        action: 'filtercurrent'
                    },
                    {
                        name: 'Export All Pages',
                        action: 'filterall'
                    }
                    ]
                },
                // {
                //     type: 'fab',
                //     icon: 'reports',
                //     name: 'reports',
                //     color: 'primary',
                //     title: 'Save Report',
                //     menucolor: '#C85A54'
                // },

            ]
        },
        savedreports: {
            ...newsavedreportstypes
        },
        "gridcolumns": [
            {
                "title": "IP Address",
                "field": "Ip",
                "show": true,
                //width: 200,
            },
            {
                "title": "Is Success",
                "field": "IsSuccessDesc",
                "show": true,
                //width: 100,
            },
            {
                "title": "Login Date",
                "field": "LoginDate",
                "show": true,
                //width: 200,
            }
        ]
    }
}
export default abc