import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { DeleteRecord, GetData, PostData, PostFormData, PutData } from './requests';

export const createRequest = (request, results, routeValues) => {
    let ids = [];
    let tempBody = null
    if (request) {
        const { type, url, routeProps, matchProps, initialloadextrainfoProps, body, replaceBody, customBody, alert, message, word, condition, alternateUrl, loaderMessage, ...otherProps } = request;
        let tempUrl = url;
        if (type === 'post' && replaceBody) {
            tempBody = createBody(body, matchProps, results, word)
            if (condition) {
                let isTrue = condition.every(each => {
                    if (each.value === each.name.split(".").reduce((res, prop) => { return res && res[prop] }, tempBody[0],))
                        return true
                })
                if (isTrue) {
                    tempUrl = alternateUrl;
                }
            }
            return { url: tempUrl, body: tempBody[0], type: type, id: ids, alert: alert, message: message, loaderMessage: loaderMessage }

        }
        else {
            ids = ids.concat(createIds(routeProps, routeValues))
            ids = ids.concat(createIds(matchProps, results))
            if (results && results.initialloadextrainfo && results.initialloadextrainfo.results) {
                ids = ids.concat(createIds(initialloadextrainfoProps, results.initialloadextrainfo.results))
            }
            if (condition) {
                let isTrue = condition.every(each => {
                    if (each.value === each.name.split(".").reduce((res, prop) => { return res && res[prop] }, results))
                        return true
                })
                if (isTrue) {
                    tempUrl = alternateUrl;
                }
            }
            return { url: tempUrl, body: customBody ? body : results, type: type, id: ids, alert: alert, message: message, loaderMessage: loaderMessage }
        }

    }
    else return null
}

export const createGridRequest = (request, results, routeValues, extras) => {
    let ids = []
    let queryStr = ''
    if (request) {
        const { type, url, alternateUrl, condition, routeProps, matchProps, alert, message, body, word, removeObjParams, replaceBody, isNotQueryStr, verify, loaderMessage, ...otherProps } = request
        let tempUrl = url
        if (type === 'post' && replaceBody) {
            let tempBody = createBody(body, matchProps, results, word);
            if (removeObjParams && removeObjParams.length > 0) {
                removeObjParams.map(each => {
                    delete tempBody[0][each]
                })
            }
            if (condition) {
                let isTrue = condition.every(each => {
                    if (each.value === each.name.split(".").reduce((res, prop) => { return res && res[prop] }, tempBody[0],))
                        return true
                })
                if (isTrue) {
                    tempUrl = alternateUrl;
                }
            }
            return { url: tempUrl, body: tempBody[0], type: type, id: ids, alert: alert, message: message, loaderMessage: loaderMessage }
        }
        else {
            ids = ids.concat(createIds(routeProps, routeValues))
            ids = ids.concat(createIds(matchProps, results))
            queryStr = !isNotQueryStr ? `${toDataSourceRequestString(extras)}` : '';
            if (removeObjParams && removeObjParams.length > 0) {
                removeObjParams.map(each => {
                    delete results[each]
                })
            }
            if (condition) {
                let tempBody = (verify && results[verify] ? results : body) || results;
                let isTrue = condition.every(each => {
                    if (each.value === each.name.split(".").reduce((res, prop) => { return res && res[prop] }, tempBody))
                        return true
                })
                if (isTrue) {
                    tempUrl = alternateUrl;
                }
            }
            return { url: tempUrl + queryStr, body: (verify && results[verify] ? results : body) || results, type: type, id: ids, alert: alert, message: message, loaderMessage: loaderMessage }
        }
    }
    else return null
}

export const createBody = (body, props, results, word) => {
    if (Array.isArray(props)) {
        return props && props.length > 0 && props.map(each => {
            const { key, name } = each;
            let temp = name.split(".").reduce((res, prop) => { return res && res[prop] }, results)
            return { [`${word}`]: temp };
        })
    }
    else return body
}

export const createIds = (props, results) => {
    if (Array.isArray(props)) {
        return props.map(each => {
            const { key, name, removeSpace } = each;
            let temp = name.split(".").reduce((res, prop) => { return res && res[prop] }, results)
            return { key: key, value: removeSpace ? temp.replace(/\s/g, '') : temp } //name.split(".").reduce((res, prop) => { return res && res[prop] }, results)
        })
    }
    else return []
}

export const requestApi = (request, customAlert = false) => {
    if (request) {
        let {
            type,
            body,
            url, //query part of url
            id, alert, message, loaderMessage
        } = request

        id && id.forEach(each => {
            const { key, value } = each
            if (url) url = `${url.replace(`{${key}}`, value)}`;
        });

        switch (type) {
            case 'get':
                return GetData(url, alert, message, true, loaderMessage)
            case 'put':
                return PutData(url, body, alert = customAlert, message, loaderMessage)
            case 'post':
                return PostData(url, body, alert, message, loaderMessage)
            case 'postfromdata':
                return PostFormData(url, body)
            case 'delete':
                return DeleteRecord(url)
            default:
                return true
        }
    }
    else return null
}