/**
      *********************************************************
      Description: Handles two layer drawer component. The second layer drawer component has a back button.
      Required props:  type.
      limitations    : Doesn't handle any state except for the first layer drawer. Second layer drawer back button state needs to be handle within the parent. Changedresp
        not used anywhere
      *********************************************************
**/

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import { Typography } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import WorkFlow from '../../../modules/workflow/workflow';
import AddWorkFlow from '../../../modules/workflow/addworkflow';
import { withRouter } from 'react-router-dom';

const styles = {
    drawerPaper: {
        width: 900,
    },
    paperWidth: {
        width: window.innerWidth - 280
    },
    fullscreen: {
        width: window.innerWidth
    },
    dragger: {
        width: '7px',
        cursor: 'ew-resize',
        padding: '4px 0 0',
        borderTop: '1px solid #ddd',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: '100',
        //backgroundColor: '#f4f7f9'
    }
};
const drawerPapers = 900;
const paperWidths = window.innerWidth - 280;
const fullscreens = window.innerWidth;
const checkwidth = 960

class CSlidingPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleMousemove.bind(this);
        this.state = {
            right: this.props.open || false,
            fullscreen: false,
            exitfullscreen: true,
            isResizing: false,
            lastDownX: 0,
            newWidth: null,
            dataItemVal: this.props.dataItem,
            ItemIndex: this.props.presentItemIndex,
            isDragging: false,
            width: window.innerWidth
        }
    }

    updateDimensions = () => {
        if (window.innerWidth < checkwidth) {
            this.setState({ width: window.innerWidth });
        }
        else {
            this.setState({ width: drawerPapers });
        }
    };

    componentDidMount() {
        document.addEventListener('mousemove', e => this.handleMousemove(e));
        document.addEventListener('mouseup', e => this.handleMouseup(e));
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }


    toggleDrawer = (side, open) => () => {
        const { dataItem, addSubGrid } = this.props;
        const { dataItemVal } = this.state
        this.setState({
            [side]: open,
        }, () => {
            if (!this.state.right && (dataItemVal || dataItem || addSubGrid)) {
                this.props.handleSlide(open);
            }
        });
    };

    /****for resizing drawer *******/
    handleMousedown = e => {
        this.setState({ isResizing: true, lastDownX: e.clientX });
    };

    handleMousemove = e => {
        // we don't want to do anything if we aren't resizing.
        if (!this.state.isResizing) {
            return;
        }
        let offsetRight =
            document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        let minWidth = 700;
        let maxWidth = window.innerWidth - 200

        if (offsetRight > minWidth && offsetRight < maxWidth) {
            this.setState({ newWidth: { width: offsetRight }, isDragging: true });
        }
        if (offsetRight > minWidth && offsetRight < maxWidth) {
            localStorage.setItem(this.props.location.pathname + "-panelwidth", JSON.stringify({ width: offsetRight }));
        }
    };

    handleMouseup = e => {
        if (this.state.isDragging) {
            this.setState({ isResizing: false, isDragging: false });
        }
    };


    changedresp = (resp) => {
        this.props.changedresp(resp)
    }

    onFullScreen = () => {
        this.setState({ fullscreen: true, exitfullscreen: false })
    }

    onExitFullScreen = () => {
        this.setState({ fullscreen: false, exitfullscreen: true })
    }

    onNextItemClick = () => {
        const { completedata } = this.props
        let fulldata = Array.isArray(completedata) ? completedata : completedata.data
        const { ItemIndex } = this.state
        if (ItemIndex >= 0 && ItemIndex < fulldata.length - 1) {
            let nextItem = fulldata[ItemIndex + 1]
            if (this.props.location.pathname == '/Administration/applicationtypes') {
                let resultantdata = JSON.parse(localStorage.getItem('griddataItem'));
                if (resultantdata !== null && resultantdata.Id != nextItem.Id) {
                    localStorage.removeItem('griddataItem')
                    localStorage.setItem('griddataItem', JSON.stringify(nextItem));
                }
            }
            this.setState({ dataItemVal: nextItem, ItemIndex: ItemIndex + 1 },
                () => {
                    this.props.onNextClick(nextItem);
                })
        }
    }
    onPrevItemClick = () => {
        const { completedata } = this.props
        let fulldata = Array.isArray(completedata) ? completedata : completedata.data
        const { ItemIndex } = this.state
        if (ItemIndex > 0 && ItemIndex <= fulldata.length - 1) {
            let prevItem = fulldata[ItemIndex - 1]
            if (this.props.location.pathname == '/Administration/applicationtypes') {

                let resultantdata = JSON.parse(localStorage.getItem('griddataItem'));
                if (resultantdata !== null && resultantdata.Id != prevItem.Id) {
                    localStorage.removeItem('griddataItem')
                    localStorage.setItem('griddataItem', JSON.stringify(prevItem));
                    console.log(resultantdata);
                    console.log(prevItem);
                }
            }

            this.setState({ dataItemVal: prevItem, ItemIndex: ItemIndex - 1 },
                () => {
                    this.props.onPrevClick(prevItem);
                })
        }
    }

    render() {
        const { classes, initialresp, togglebackslider, extraProps, edit, dataItem, customprops, customId, parentId, addSubGrid, completedata, presentItemIndex } = this.props;
        const { options, components, compmap, paperWidth } = this.props
        const { buttonprops, name, back, icon } = options;
        const { newWidth, dataItemVal } = this.state
        let resizewidth = JSON.parse(localStorage.getItem(this.props.location.pathname + "-panelwidth"));
        let resizedwidthnum = resizewidth && resizewidth['width']
        return (
            <div>
                {!back &&
                    <Fragment>
                        {!icon && !this.props.open && <div className={!edit ? "row" : ""}>
                            <Button disabled={!edit ? true : false} variant="contained" color={edit ? 'primary' : 'default'}
                                onClick={this.toggleDrawer('right', true)} key="buttontoslide">{name}</Button></div>}
                        {icon && !this.props.open &&
                            <Tooltip title={this.props.options.name} aria-label="add">
                                <MenuItem style={{ margin: 0 }} color="primary" onClick={this.toggleDrawer('right', true)}>

                                    <Icon title={name} >{this.props.materialicon}</Icon>
                                    {this.props.options.customstyling && <Typography variant="caption" display="block" style={{ color: "#000000" }} className="pl-1 pt-1" gutterBottom>
                                        {this.props.options.name}
                                    </Typography>}
                                    {!this.props.options.customstyling && <ListItemText style={{ color: 'black' }}>{this.props.options.name}</ListItemText>}
                                </MenuItem>
                            </Tooltip>}
                        <Drawer anchor="right" disableBackdropClick open={this.state.right} onClose={this.toggleDrawer('right', false)}
                            key="slider" className="col-sm-4"
                            classes={{
                                paper: ((dataItemVal || dataItem || addSubGrid) && !paperWidth) ? (!this.state.fullscreen ? (resizewidth ? resizewidth : classes.drawerPaper) : classes.fullscreen) : classes.paperWidth,
                            }}
                            PaperProps={{
                                style: {
                                    width: this.state.width > checkwidth ? (!this.state.fullscreen ? (resizedwidthnum ? resizedwidthnum : drawerPapers)
                                        : fullscreens) : this.state.width
                                }
                            }}
                            // PaperProps={{ style: !this.state.fullscreen ? resizewidth : '' }}
                            disableEnforceFocus={true}
                            disableAutoFocus={true}
                        >
                            <div
                                onMouseDown={event => {
                                    this.handleMousedown(event);
                                }}
                                className={classes.dragger}
                            />
                            {components && components.map((each, index) => {
                                const { options, type, key } = each
                                if (type == 'workflow') {
                                    return <WorkFlow dataItem={dataItem} compmap={compmap} onClose={this.toggleDrawer('right', false)} key={key || index}
                                        {...this.props}
                                        extraProps={{ ...extraProps }} {...each}
                                        customprops={customprops}
                                        customId={customId}
                                        parentId={parentId}
                                        initialresp={initialresp} changedresp={this.changedresp} handleClose={this.props.handleClose} />
                                }
                                if (type == 'addworkflow') {
                                    return <AddWorkFlow dataItem={dataItem} compmap={compmap} onClose={this.toggleDrawer('right', false)} key={key || index}
                                        {...this.props}
                                        extraProps={{ ...extraProps }} {...each}
                                        customprops={customprops}
                                        customId={customId}
                                        parentId={parentId}
                                        initialresp={initialresp} changedresp={this.changedresp} handleClose={this.props.handleClose} />
                                }
                                else {
                                    const EachComp = compmap[type]
                                    return <EachComp dataItem={dataItemVal || dataItem} compmap={compmap} onClose={this.toggleDrawer('right', false)} key={key || index}
                                        accessPermissions={this.props.accessPermissions}
                                        initialloadextrainfo={this.props.initialloadextrainfo}
                                        configType={this.props.accessPermissions.configType}
                                        extraProps={{ ...extraProps }} {...each}
                                        customprops={customprops}
                                        customId={customId}
                                        parentId={parentId}
                                        initialresp={initialresp} changedresp={this.changedresp} onFullScreen={this.onFullScreen} onExitFullScreen={this.onExitFullScreen} fullscreen={this.state.fullscreen} exitfullscreen={this.state.exitfullscreen} onNextItemClick={this.onNextItemClick} onPrevItemClick={this.onPrevItemClick}
                                        rowrenderedslide={this.props.rowrenderedslide}
                                        handleCustomBack={this.props.handleCustomBack} />
                                }
                            })}
                            {this.props.children}
                        </Drawer>
                    </Fragment>
                }
                {back &&
                    <Drawer anchor="right" disableBackdropClick open={togglebackslider} key="backslider" classes={{
                        paper: classes.drawerPaper,
                    }} disableEnforceFocus={true}
                        disableAutoFocus={true}>
                        <div
                            onMouseDown={event => {
                                this.handleMousedown(event);
                            }}
                            className={classes.dragger}
                        />
                        {<div className="pl-2 "><IconButton onClick={this.props.handleBackSlider(false)} aria-label="back">
                            <BackIcon />
                        </IconButton></div>}
                        {components && components.map((each, index) => {
                            const { options, type, key } = each
                            const EachComp = compmap[type]
                            return <EachComp dataItem={dataItemVal || dataItem} compmap={compmap} key={key || index}
                                extraProps={{ ...extraProps }} {...each} initialresp={initialresp} changedresp={this.changedresp} customprops={customprops} parentId={parentId}
                                onFullScreen={this.onFullScreen} onExitFullScreen={this.onExitFullScreen} fullscreen={this.state.fullscreen} exitfullscreen={this.state.exitfullscreen} onNextItemClick={this.onNextItemClick} onPrevItemClick={this.onPrevItemClick} rowrenderedslide={this.props.rowrenderedslide} />
                        })}
                        {this.props.children}
                    </Drawer>
                }
            </div>
        );
    }
}

CSlidingPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(CSlidingPage));


