import React, { Component } from 'react';
import { FeaturesLayerGraphicsTaskAddLookup_NoDraw } from '../eps/featuresLayersTaskKEPT';
//import { TaxParcelTaskFeatureLayer,TaxParcelLayerTask } from '../eps/taxParcelTask';
//import TaxParcelSearch from './TaxParcelSearch';
import { AddLayers } from '../util/layers';
import { CenterAndZoomWithUnionPointZoom, QuerySelectedTask, QueryTask } from "../util/queryTask";
import ActionsLayerList from './actionsLayerList';
import BasemapGallery from './basemapGallery';
import CDatTable from './dataTable';
import Home from './home';
import LayerFilter from './layerfilter';
import Locate from './locate';
import LocationCompare from './locationcompare';
import LocationZoom from './locationzoom';
import Measurement from './measurement';
import MultiLocationSelector from './multilocationselector';
import Print from './print';
import Search from './search';
import SearchBookmark from './searchbookmark';
import CSelect from './select';
import Sketch from './sketch';
import Selector from './taxlotselector';
import Zoom from './zoom';
import { GISLookupTask } from '../eps/GISLookupTask';
import { getEnvVariable } from '../../../../modules/environmentalList';
import { createRequest, requestApi } from '../../../controls/helpers/rest';


/*
import Loadable from 'react-loadable';

function Loading(props) {
    return <div>Loading...</div>;
}

function fakeAPI() {
    return Promise.resolve({ title: "Yolo", body: "This is a cool post" });
}

const LoadableBar = Loadable.Map({
    loader: {
        Post: () => import("./measurement"),
        Draw3: () => import("./draw"),
        Data: () => fakeAPI(),
    },
    render(loaded, props) {
        const Post = loaded.Post.default;
        const Draw3 = loaded.Draw3.default;
        const Data = loaded.Data;
        return <div><Post  {...props} /><Draw3  {...props} /></div>;
    },
    loading: Loading
});
*/
class CConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            view: this.props.view,
            options: this.props.options,
            SubModuleKey: this.props.SubModuleKey,
            spatialReference: this.props.options.viewProperties.extent.spatialReference,//this.state.view.spatialReference
            mapConstants: this.props.mapConstants,
            mapdata: this.props.mapdata,
            selectiondata: this.props.selectiondata,
            highlightdata: [],
            highlighttaxlotdata: [],
            selectedTaxLots: [],
            components: [],
            graphiccoordinates: {}, // on geometry selection
            highlightedGraphics: null,
            widgetrender: [],
            defaultSelectLayer: ''
        };
        this.startup(
            this.state.view,
            this.state.options,
            this.state.mapdata,
            this.state.selectiondata
        );
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.selectiondata && (this.props.selectiondata !== prevState.selectiondata)) {
            this.setState({
                view: this.props.view,
                options: this.props.options,
                mapdata: this.props.mapdata,
                selectiondata: this.props.selectiondata,
                SubModuleKey: this.props.SubModuleKey
            }, () => {
                this.highlightSelectedData(this.state.selectiondata);
            })
        }
        if (this.props.mapdata && !this.arraysEqualCheck(this.props.mapdata,prevState.mapdata)) {
            this.AddMapLayers(this.props.view, this.props.options, this.props.mapdata, true);
            this.setState({
                view: this.props.view,
                options: this.props.options,
                mapdata: this.props.mapdata,
                selectiondata: this.props.selectiondata,
                SubModuleKey: this.props.SubModuleKey
            }, () => {
                console.log('Grid fileter ---', this.props.mapdata);
                if(!this.isRecordLayerAdded(this.state.view, this.state.options))
                    this.AddMapLayers(this.state.view, this.state.options, this.state.mapdata, true);                
            })
        }

        if ((this.props.permitTypeId && this.props.permitTypeId !== prevProps.permitTypeId) ||
            (this.props.layerCustomFilters && this.props.layerCustomFilters !== prevProps.layerCustomFilters) ||
            (this.props.layerCustomFilters)) {
            this.filterRecordLayers(this.props.SubModuleKey, this.props.permitTypeId)
        }
    }

    isRecordLayerAdded(view, options){
        try{
            let recordlayers = view.map.layers.filter(function (layer) {
                return layer.LayerOptions && (layer.LayerOptions.IsRecord === "Y");
            });
            let recordFeatureLayerListOptions = options.FeatureLayerListOptions.filter(a =>  a.IsRecord === 'Y' );
            return recordlayers.length  == recordFeatureLayerListOptions.length;
        }catch(e){
            console.log(e);
        }
        return false;
    }


    arraysEqualCheck(a, b) {
        //if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;
      
        a.sort();
        b.sort();
      
        for (let i = 0; i < a.length; ++i) {
          if (a[i] !== b[i]) return false;
        }
        return true;
    }

    filterRecordLayers = (subModuleKey, permitTypeId) => {
        let inthis = this;

        let layers = this.state.view.map.layers.filter(function (layer) {
            return layer.LayerOptions && (layer.LayerOptions.IsRecord === "Y");
        });
        if (subModuleKey === 'VIOLATIONS' && this.props.layerCustomFilters != 'VIOLATIONS') {
            layers = this.state.view.map.layers.filter(function (layer) {
                return layer.LayerOptions && (layer.LayerOptions.IsRecord === "Y"
                    || layer.LayerOptions.Category.includes('S'));
            });
        }
        console.log('Layers to be filtered ---------', layers);
        let mappedSubModuleKey = (subModuleKey === 'VIOLATIONS' ? this.props.layerCustomFilters : subModuleKey)
        let definitionExpression = inthis.GetUserLayerExpression(subModuleKey, permitTypeId);
        let selectRecordDefinitionExpression = inthis.GetUserLayerExpression(mappedSubModuleKey, permitTypeId);

        layers.map(function (lyr) {
            //console.log('mapping layer---------',lyr);
            let layerDefinitionExpressionFilter = (lyr.LayerOptions.Category.includes("S") && subModuleKey === 'VIOLATIONS') ? selectRecordDefinitionExpression : definitionExpression
            let defaultLayerExpression = lyr.LayerOptions.defaultExpression;
            let layerDefinitionExpression = (!layerDefinitionExpressionFilter || layerDefinitionExpressionFilter === "()" || layerDefinitionExpressionFilter === "1=1") ? (defaultLayerExpression) : (("(" + defaultLayerExpression + ")") + " and " + layerDefinitionExpressionFilter);
            lyr.definitionExpression = layerDefinitionExpression;
        })
        if (subModuleKey === 'VIOLATIONS' && mappedSubModuleKey) {
            this.hideLayersFromList(subModuleKey, permitTypeId, mappedSubModuleKey);
        }
        if (this.state.highlightedGraphics) {
            this.state.highlightedGraphics.remove();
        }
    }

    hideLayersFromList(subModuleKey, permitTypeId, mappedSubModuleKey) {
        let hidelayers = this.state.view.map.layers.filter(function (layer) {
            return layer.LayerOptions && (layer.LayerOptions.Category.includes('S') && !layer.LayerOptions.SelectionSubModules.includes(mappedSubModuleKey));
        });
        let showlayers = this.state.view.map.layers.filter(function (layer) {
            return layer.LayerOptions && (layer.LayerOptions.Category.includes('S') && layer.LayerOptions.SelectionSubModules.includes(mappedSubModuleKey));
        });
        hidelayers.map(function (lyr) {
            console.log('Hidden --------------' + lyr.LayerOptions.DisplayName, lyr.LayerOptions.SelectionSubModules, lyr.LayerOptions.IsRecord)
            lyr.definitionExpression = "1 <> 1";
            lyr.listMode = "hide";
        })
        showlayers.map(function (lyr) {
            console.log('Showing --------------' + lyr.LayerOptions.DisplayName, lyr.LayerOptions.SelectionSubModules, lyr.LayerOptions.IsRecord)
            lyr.listMode = "show";
        })
    }

    GetUserLayerExpression(subModuleKey, permitTypeId) {
        let definition = "1=1"
        let profile = localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))
        let userExpression = this.GetUserAccessQueryExpressions(profile);
        let isInternal = profile && profile.portal === "I";
        if (subModuleKey && permitTypeId > 0 && isInternal) {
            definition = " SubModuleKey = '" + subModuleKey + "' AND Site_Permit_Type_Id = '" + permitTypeId + "' ";
        } else if (subModuleKey) {
            definition = " SubModuleKey = '" + subModuleKey + "' ";
        }
        if (userExpression) {
            definition = definition + " AND " + userExpression;
        }
        return definition;
    }

    GetUserAccessQueryExpressions(profile) {
        if (profile) {
            let isInternal = profile && profile.portal === "I";
            if (isInternal && profile.configType == 'S' && profile.moduleSiteAccess) {
                let sites = Object.keys(profile.moduleSiteAccess);
                if (Array.isArray(sites) && sites.length > 0) {
                    return " Site_Id in (" + sites.join(",") + ")";
                }
            } else if (isInternal && profile.configType == 'D' && profile.moduleDistrictAccess) {
                let districts = Object.keys(profile.moduleDistrictAccess);
                if (Array.isArray(districts) && districts.length > 0) {
                    return " District_Id in (" + districts.join(",") + ")";
                }
            } else if (isInternal && profile.configType == 'R' && profile.moduleRegionAccess) {
                let regions = Object.keys(profile.moduleRegionAccess);
                if (Array.isArray(regions) && regions.length > 0) {
                    return " Region_Id in (" + regions.join(",") + ")";
                }
            } else if (!isInternal) {
                return " Owner ='" + profile.modulePermissions[0].companyId + "'";
            }
        }
        return null;
    }

    componentDidMount() {
        const { compmap } = this.props;
        if (compmap)
            this.state.options.widgets.map(async (each) => await this.addComponent(each))

        this.setWidgetOptions();
    }

    addComponent = async type => {
        const { compmap } = this.props;
        this.setState({
            components: this.state.components.push(compmap[type])
        })
    };

    //for fetching the required url
    getFeatureLayerURL(tasks, url) {
        let customTaxLot = tasks;
        customTaxLot.TaxParcels.url = url
        return customTaxLot
    }

    startup = (view, options, mapdata) => {
        this.AddMapLayers(view, options, mapdata);
    }

    AddMapLayers(view, options, mapdata, hasrecordlayers=false){
        if (view && options) {            
            //Record/Grid layers will be loaded based on the pagination
            let featureLayerListOptions = options.FeatureLayerListOptions.filter(a => (!a.SubModuleKey || a.SubModuleKey === 'COMMON' || a.SubModuleKeys.includes(this.state.SubModuleKey)) && a.IsRecord !== 'Y' && (a.KEY !== 'RECORD_HIGHLIGHT_LAYER' && a.key !== 'RECORD_HIGHLIGHT_LAYER'));//this.props.SubModuleKey
            if (this.state.options && this.state.options.mapkey === 'landing_map') {
                featureLayerListOptions = options.FeatureLayerListOptions.filter(a => a.Category.includes('L'));
            }
            if (this.state.options && (this.state.options.mapkey === 'EPS_map_ADD' || this.state.options.mapkey === 'AMES_MapSelect')) {
                featureLayerListOptions = featureLayerListOptions.filter(a => a.Category.includes('A'));                
            }
            if (this.state.options && this.state.options.mapkey === 'EPS_map_EDIT' ) {
                featureLayerListOptions = featureLayerListOptions.filter(a => a.Category.includes('R'));                
            }
            if (this.state.options && this.state.options.mapkey === 'EPS_map_GRID') {
                featureLayerListOptions = featureLayerListOptions.filter(a => a.Category.includes('G'));                
            }
            if(hasrecordlayers || (this.state.options && this.state.options.mapkey === 'EPS_map_GRID')){
                let recordFeatureLayerListOptions = options.FeatureLayerListOptions.filter(a => (!a.SubModuleKey || a.SubModuleKey === 'COMMON' || 
                a.SubModuleKeys.includes(this.state.SubModuleKey)) && (a.IsRecord === 'Y' || (a.KEY === 'RECORD_HIGHLIGHT_LAYER' || a.key === 'RECORD_HIGHLIGHT_LAYER')) );//this.props.SubModuleKey
                console.log('Grid fileter ---', recordFeatureLayerListOptions);
                featureLayerListOptions = featureLayerListOptions.concat(recordFeatureLayerListOptions);
            }

            // Add layers and actions to the map
            AddLayers(view, featureLayerListOptions, mapdata, options.mapkey, this.props.mapConstants, this.state.SubModuleKey,this.state.layersList,this.state.statusList).then(
                response => {
                    let highlightrenderer = {
                        type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
                        color: "green",
                        size: 8,
                        outline: { // autocasts as new SimpleLineSymbol()
                            width: 0.5,
                            color: "green"
                        }
                    };
                    this.setState({ view: view })
                    let mapLayers = view.map.layers;
                    let reqLayer = mapLayers.filter(function (ly) { if (ly.Key === 'CenterGeomService') { return ly; } });

                    let inThis = this;

                    //This binding is only for the Grid view row and pin selector
                    if (this.props.isGridView || this.props.addMapJson) {
                        view.on("click", function (event) {
                            let screenPoint = { x: event.x, y: event.y };
                            // Search for graphics at the clicked location
                            view.hitTest(event).then(function (response) {
                                console.log('HIT TEST*********************')
                                //AMES specific highlighting the polygon
                                //response.results[0].graphic.geometry.type
                                if (response.results.length > 0) {
                                    const isPolygon = (response.results[0].graphic && response.results[0].graphic.geometry
                                        && response.results[0].graphic.geometry.type === "polygon");

                                    if (isPolygon) {


                                    } else {
                                        if (response.results.length) {
                                            view.graphics.removeAll();
                                            let graphic = response.results.filter(function (result) {
                                                // check if the graphic belongs to the layer of interest
                                                return result.graphic.layer === reqLayer._items[0];
                                            })[0].graphic;
                                            // do something with the result graphic
                                            if (graphic) {
                                                //view.graphics.remove(graphic);
                                                if (!inThis.state.highlightdata.includes(graphic.attributes.ObjectId)) {
                                                    inThis.state.highlightdata.push(graphic.attributes.ObjectId);
                                                }
                                                inThis.props.handleSeclectionfromMap(inThis.state.highlightdata);
                                                graphic.symbol = highlightrenderer;
                                                graphic.attributes.ishighlight = "true";
                                                // graphic.attributes.Permit_Type_Id = "-999";
                                                view.graphics.remove(graphic);
                                                view.graphics.add(graphic);
                                            }
                                        }
                                    }

                                }

                            });
                        }.bind(this));
                    }
                    this.QuerySelectedData();
                },
                error => {
                    console.log(error);
                }
            );

        }

    }

    // AddRecordLayers = (view, options, mapdata) => {
    //     let featureLayerListOptions = options.FeatureLayerListOptions.filter(a => (!a.SubModuleKey || a.SubModuleKey === 'COMMON' || 
    //     a.SubModuleKeys.includes(this.state.SubModuleKey)) && (a.IsRecord === 'Y' || (a.KEY === 'RECORD_HIGHLIGHT_LAYER' || a.key === 'RECORD_HIGHLIGHT_LAYER')) );//this.props.SubModuleKey
    //     console.log('Grid fileter ---', featureLayerListOptions);
    //     // Add layers and actions to the map
    //     AddLayers(view, featureLayerListOptions, mapdata, options.mapkey, this.props.mapConstants, this.state.SubModuleKey,this.state.layersList,this.state.statusList).then(
    //         response => {
    //             let highlightrenderer = {
    //                 type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
    //                 color: "green",
    //                 size: 8,
    //                 outline: { // autocasts as new SimpleLineSymbol()
    //                     width: 0.5,
    //                     color: "green"
    //                 }
    //             };
    //             this.setState({ view: view })
    //             let mapLayers = view.map.layers;
    //             let reqLayer = mapLayers.filter(function (ly) { if (ly.Key === 'CenterGeomService') { return ly;} });
    //             //let centerzoomlayer = mapLayers.filter(a => a.LayerOptions.KEY == "RECORD_HIGHLIGHT_LAYER" )

    //             let inThis = this;

    //             //This binding is only for the Grid view row and pin selector
    //             if (this.props.isGridView || this.props.addMapJson) {
    //                 view.on("click", function (event) {
    //                     let screenPoint = { x: event.x, y: event.y };
    //                     // Search for graphics at the clicked location
    //                     view.hitTest(event).then(function (response) {

    //                         //AMES specific highlighting the polygon
    //                         //response.results[0].graphic.geometry.type
    //                         if (response.results.length > 0) {
    //                             const isPolygon = (response.results[0].graphic && response.results[0].graphic.geometry
    //                                 && response.results[0].graphic.geometry.type === "polygon");

    //                             if (isPolygon) {


    //                             } else {
    //                                 if (response.results.length) {
    //                                     view.graphics.removeAll();
    //                                     let graphic = response.results.filter(function (result) {
    //                                         // check if the graphic belongs to the layer of interest
    //                                         return result.graphic.layer === reqLayer._items[0];
    //                                     })[0].graphic;
    //                                     // do something with the result graphic
    //                                     if (graphic) {
    //                                         //view.graphics.remove(graphic);
    //                                         if (!inThis.state.highlightdata.includes(graphic.attributes.ObjectId)) {
    //                                             inThis.state.highlightdata.push(graphic.attributes.ObjectId);
    //                                         }

    //                                         inThis.props.handleSeclectionfromMap(inThis.state.highlightdata);
    //                                         graphic.symbol = highlightrenderer;
    //                                         graphic.attributes.ishighlight = "true";
    //                                         // graphic.attributes.Permit_Type_Id = "-999";
    //                                         view.graphics.remove(graphic);
    //                                         view.graphics.add(graphic);

    //                                     }
    //                                 }
    //                             }

    //                         }

    //                     });
    //                 }.bind(this));
    //             }
    //             // if( centerzoomlayer){
    //             //     centerzoomlayer.queryExtent().then((response) => {                        
    //             //         CenterAndZoomWithUnionPointZoom(view, response.features, inThis.state.options.viewProperties.zoom);
    //             //     })
    //             // }

    //             this.QuerySelectedData();
    //         },
    //         error => {
    //             console.log(error);
    //         }
    //     );
    // }

    highlightSelectedData = (selectiondata) => {
        let layers = this.state.view.map.layers.filter(function (layer) {
            return layer.LayerOptions && (layer.LayerOptions.KEY === "CenterGeomService" || 
            layer.LayerOptions.IsRecord === "Y" || 
            layer.LayerOptions.KEY === "RECORD_HIGHLIGHT_LAYER");
        });

        if (this.state.highlightedGraphics) {
            this.state.highlightedGraphics.remove();
        }
        layers.forEach(layer => {
            this.highlightSelectedDataWrapper(layer, selectiondata);
        });

    }

    highlightSelectedDataWrapper = (layer, selectiondata) => {
        let inthis = this
        if (layer && selectiondata && selectiondata.length > 0) {
            this.state.view.whenLayerView(layer).then(function (lyrView) {
                let querycondition = "Id in(" + selectiondata.toString() + ")";
                console.log('highlightSelectedDataWrapper----------spatialReference', inthis.state.view.spatialReference)
                QueryTask(layer.LayerOptions, querycondition, inthis.state.view.spatialReference).then((response) => {
                    response.features.forEach((s) => {
                        if (s && s.geometry)
                            s.symbol = inthis.state.mapConstants.getHighlightsymbol(inthis.state.SubModuleKey, s.geometry.type, s.attributes, layer.LayerOptions);
                        else
                            s.symbol = inthis.state.mapConstants.getHighlightsymbol(inthis.state.SubModuleKey, "point", s.attributes, layer.LayerOptions);
                    });
                    if (response.features.length > 0 && lyrView.layer)
                        inthis.setState({ highlightedGraphics: lyrView.highlight(response.features) });
                    else {
                        inthis.setState({ highlightedGraphics: null });
                    }
                    if (layer.LayerOptions && (layer.LayerOptions.KEY === "CenterGeomService" || 
                    layer.LayerOptions.IsRecord === "Y" || 
                    layer.LayerOptions.KEY === "RECORD_HIGHLIGHT_LAYER")){                       
                        CenterAndZoomWithUnionPointZoom(inthis.state.view, response.features, inthis.state.options.viewProperties.zoom)
                    }
                        
                });
            });
        } else {
            inthis.setState({ highlightedGraphics: null });
        }
    }

    QuerySelectedData = () => {
        if (this.props.SelectGeomInsteadOfDraw === "Y") {
            if (this.props.options && this.props.options.FieldsData && this.props.options.FieldsData.length > 0 && this.state.options.SelectLayerListOptions && this.state.options.SelectLayerListOptions && this.state.options.SelectLayerListOptions.length > 0) {
                let allPromise = []
                let inthis = this;
                this.state.options.SelectLayerListOptions.forEach(function (layerOptions) {
                    const { SelectField, SubscribedMasterFieldsKey, defaultExpression } = layerOptions
                    if (SelectField && SubscribedMasterFieldsKey) {
                        let objIndex = inthis.props.options.FieldsData.findIndex((obj => obj.Fieldkey === SubscribedMasterFieldsKey));
                        if (objIndex >= 0) {
                            let selectedField = inthis.props.options.FieldsData[objIndex]
                            if (selectedField && selectedField.FieldValue) {
                                const fieldValues = selectedField.FieldValue.split(",");
                                let fieldValuesStr = fieldValues.join("','")
                                let queryexpression = SelectField + " in ('" + fieldValuesStr + "')";
                                if (defaultExpression && defaultExpression !== "1=1") {//Sql query with default Expression
                                    queryexpression = (("(" + defaultExpression + ")") + " and " + queryexpression);
                                }
                                let promise = QuerySelectedTask(inthis.state.SubModuleKey, layerOptions, inthis.state.view, inthis.state.mapConstants, queryexpression, inthis.state.view.spatialReference, inthis.state.options.viewProperties.zoom)//inthis.state.options.viewProperties.extent.spatialReference,
                                allPromise.push(promise);
                            }
                        }
                    }
                })
                Promise.all(allPromise).then((data) => {
                    //Pass geometries in array directly here...
                    console.log('Selected Geom map response ', data);
                    let lookupdatabytype = this.props.gisDomainLookupData
                    if (lookupdatabytype != null && data != null && data[0] != null && data[0].features[0] != null) {
                        var selectrecordGeom = data[0].features[0].geometry;
                        if (data[0].geometryType != "point") {
                            if (selectrecordGeom && selectrecordGeom.extent !== undefined || selectrecordGeom.extent !== null)
                                selectrecordGeom = selectrecordGeom.extent.center;
                        }
                        FeaturesLayerGraphicsTaskAddLookup_NoDraw(this.state.options, [selectrecordGeom], lookupdatabytype).then(response => {
                            console.log('GIS hitting map response ', response);
                            //this.state.graphiccoordinates.latitude &&
                            //this.state.graphiccoordinates.longitude &&
                            response["MAP_CENTER_LATITUDE"] = selectrecordGeom.latitude;
                            response["MAP_CENTER_LONGITUDE"] = selectrecordGeom.longitude;
                            this.props.onSaveorUpdateMapResponse(response);
                        },
                            error => {
                                console.log(error);
                            })
                    }
                })
            }
        }
    }

    OnExpanded = (widget, value) => {
        this.state.view.popup.close();
        if (value === true && this.ExpandedWidget && this.ExpandedWidget.expanded && this.ExpandedWidget !== widget) {
            this.ExpandedWidget.collapse();
        }
        if (value === true)
            this.ExpandedWidget = widget;
    }

    onGraphicSelection = (coordinates) => {
        console.log(coordinates)
        this.setState({ graphiccoordinates: coordinates });
    }

    onAreaSelection = (coordinates, selectedLayerOptions) => {
        this.setState({ selectedArea: coordinates, selectedLayerOptions: selectedLayerOptions }, () => {
            let coordinatesfilter = coordinates;
            coordinatesfilter.cleargraphic = false;
            this.setState({ selectedArea: coordinatesfilter })
        });
    }

    onAreaClear = () => {
        this.setState({ clearAreaSelection: !this.state.clearAreaSelection });
    }

    onClearSelection = () => {
        if (this.state.featureTable)
            this.state.featureTable.clearSelection();
    }

    onDataTableLoad = (featureTable, selectedLayer) => {
        this.setState({ featureTable: featureTable, selectedLayer: selectedLayer });
    }
    handlelayerFilter = (query) => {
        this.setState({ layerFilterQuery: query, showLayerFilter: false })
    }

    getMapCenterData() {
        if (this.state.options && this.state.options.FieldsData) {
            let latlong = this.state.options.FieldsData.filter(f => {
                return (f.Fieldkey === ("MAP_CENTER_LATITUDE") || f.Fieldkey === ("MAP_CENTER_LONGITUDE"));
            }).map(f => f.FieldValue)
            return latlong.filter(function (el) {
                return el != null && el != '';
            });
        }
        return [];
    }

    setWidgetOptions = () => {
        if (this.state.options && Array.isArray(this.state.options.widgets)) {
            let widgetrenderer = [];
            widgetrenderer.push({ "Zoom": false })
            let read = [{
                url: getEnvVariable('Admin') + `/api/MasterPermitTypes/All`,
                type: 'get'
            },
            {
                url: getEnvVariable('Admin') + `/api/ApplicationStatus/All`,
                type: 'get'
            }]
            Promise.all(read.map(each => requestApi(createRequest(each, {}, null)).catch(error => { return error; }))).then(resp => {
                const [layersList, statusList,] = resp;
                this.setState({ widgetrender: widgetrenderer, layersList: layersList, statusList: statusList })
            })

        }
    }

    widgetRenderCallBack = (name) => {
        //NOT USING CAN BE EXTENDED IN FUTURE

    }
    // Map search with lookup fileds added.
    setSearchLayerConfigData = (lyr, feature) => {
        //console.log(feature);        
        let searchdomainlookup = this.props && Array.isArray(this.props.gisDomainLookupData) ? this.props.gisDomainLookupData.filter(a => a.FieldFetchGeomType == "S") : [];
        if (Array.isArray(searchdomainlookup) && searchdomainlookup.length > 0) {
            GISLookupTask(this.state.options, { geometry: feature.geometry }, searchdomainlookup).then((gisres) => {
                console.log('GIS search result featuers----', gisres, feature);
                if (this.props.onSaveorUpdateMapResponse)
                    this.props.onSaveorUpdateMapResponse(gisres);
            })
        }
        this.setState({ defaultSelectLayer: lyr });
    }

    // <LoadableBar options={this.state.options.drawOptions} view={this.state.view} />
    render() {
        const { options, showLayerFilter, layerFilterQuery, layerId, widgetrender } = this.state
        const { widgets } = options
        const { canEdit, isGridView } = this.props;
        let mapcenterlatlong = this.getMapCenterData();

        return (
            <div id="Widgets-Config" className="Widgets-Config">
                {
                    this.state.options.widgets.indexOf("Zoom") > -1 && <Zoom options={this.state.options.zoomOptions} view={this.state.view} widgetRenderCallBack={this.widgetRenderCallBack} order={this.state.options.widgets.indexOf("Zoom")} />
                }
                {
                    this.state.options.widgets.indexOf("Home") > -1 && <Home view={this.state.view} widgetRenderCallBack={this.widgetRenderCallBack} order={this.state.options.widgets.indexOf("Home")} />
                }
                {
                    this.state.options.widgets.indexOf("Locate") > -1 && <Locate view={this.state.view} widgetRenderCallBack={this.widgetRenderCallBack} order={this.state.options.widgets.indexOf("Locate")} />
                }
                {
                    this.state.options.widgets.indexOf("Search") > -1 && <Search options={this.state.options} view={this.state.view} spatialReference={this.state.spatialReference}
                        onexpanded={this.OnExpanded} setStateMapInformation={this.props.setStateMapInformation}
                        canEdit={canEdit} widgetRenderCallBack={this.widgetRenderCallBack}
                        order={this.state.options.widgets.indexOf("Search")}
                        setDefaultSelectWidgetLayer={(lyr) => { this.setState({ defaultSelectLayer: lyr }) }}
                        setSearchLayerConfigData={this.setSearchLayerConfigData} />
                }
                {
                    this.state.options.widgets.indexOf("BasemapGallery") > -1 && <BasemapGallery options={this.state.options.basemap} view={this.state.view} onexpanded={this.OnExpanded} widgetRenderCallBack={this.widgetRenderCallBack} order={this.state.options.widgets.indexOf("BasemapGallery")} />
                }
                {
                    // this.state.options.LayerOptions && this.state.options.widgets.indexOf("LayerList") > -1 && <LayerList options={this.state.options.LayerOptions} view={this.state.view} onexpanded={this.OnExpanded} />
                }
                {
                    this.state.options.LayerOptions && this.state.options.widgets.indexOf("LayerList") > -1 && <ActionsLayerList
                        options={this.state.options}
                        view={this.state.view}
                        onexpanded={this.OnExpanded}
                        addTaxParcels={this.props.addTaxParcels}
                        parentcal="actionlayer"
                        layerFilter={(id) => { this.setState({ showLayerFilter: true, layerId: id }) }}
                        layerFilterQuery={layerFilterQuery}
                        spatialReference={this.state.spatialReference} widgetRenderCallBack={this.widgetRenderCallBack} order={this.state.options.widgets.indexOf("LayerList")} />
                }
                {
                    this.state.options.widgets.indexOf("Measurement") > -1 && <Measurement options={this.state.options.drawOptions} view={this.state.view} onexpanded={this.OnExpanded} widgetRenderCallBack={this.widgetRenderCallBack} order={this.state.options.widgets.indexOf("Measurement")} />
                }
                {
                    (this.state.options.widgets.indexOf("TaxlotSelector") > -1 && canEdit && this.state.options.hastaxlotlayer === "Y") && <Selector options={this.state.options} view={this.state.view} addTaxParcels={this.props.addTaxParcels} widgetRenderCallBack={this.widgetRenderCallBack} order={this.state.options.widgets.indexOf("TaxlotSelector")} />
                }
                {/* {
                    this.state.options.widgets.indexOf("BufferCalc") > -1 && <BufferCalc view={this.state.view} options={this.state.options} onexpanded={this.OnExpanded} addTaxParcels={this.props.addTaxParcels} parentcal="buffercalc" canEdit={canEdit} />
                } */}
                {
                    this.state.options.widgets.indexOf("Print") > -1 && <Print view={this.state.view} options={this.state.options} onexpanded={this.OnExpanded} widgetRenderCallBack={this.widgetRenderCallBack} order={this.state.options.widgets.indexOf("Print")} />
                }
                {
                    (this.state.options.widgets.indexOf("Sketch") > -1 && canEdit) && <Sketch options={this.state.options}
                        view={this.state.view} onexpanded={this.OnExpanded}
                        SelectGeomInsteadOfDraw={this.props.SelectGeomInsteadOfDraw != "N"}
                        hasMultiLocationSelector={this.props.HasMultiLocationSelector === "Y"} //{this.state.options.widgets.indexOf("MultiLocationSelector") > -1}
                        onSave={this.props.onSaveorUpdate}
                        mapConstants={this.state.mapConstants}
                        spatialReference={this.state.spatialReference}
                        ref={(sketch) => { this.sketch = sketch; }}
                        fetchGeomType={this.props.fetchGeomType}
                        onGraphicSelection={this.onGraphicSelection}
                        SubModuleKey={this.state.SubModuleKey}
                        setStateMapInformation={this.props.setStateMapInformation}
                        clearsketch={this.props.clearsketch} widgetRenderCallBack={this.widgetRenderCallBack}
                        order={this.state.options.widgets.indexOf("Sketch")}
                    />
                }
                {
                    this.state.options && this.state.options.searchbookmarks && this.state.options.widgets.indexOf("SearchBookmark") > -1 && <SearchBookmark //options={this.state.options.searchbookmarks}
                        view={this.state.view} options={this.state.options}
                        onexpanded={this.OnExpanded} widgetRenderCallBack={this.widgetRenderCallBack}
                        order={this.state.options.widgets.indexOf("SearchBookmark")} />
                }
                {this.state.options.widgets.indexOf("MultiLocationSelector") && this.props.HasMultiLocationSelector === "Y" &&
                    this.state.graphiccoordinates &&
                    this.state.graphiccoordinates.latitude &&
                    this.state.graphiccoordinates.longitude &&
                    <MultiLocationSelector view={this.state.view} options={this.state.options}
                        onexpanded={this.OnExpanded} addTaxParcels={this.props.addTaxParcels}
                        parentcal="MultiLocationSelector" canEdit={canEdit}
                        coordinates={this.state.graphiccoordinates}
                        multipleLocationHandler={this.props.multipleLocationHandler}
                        clearsketch={this.props.clearsketch} widgetRenderCallBack={this.widgetRenderCallBack}
                        order={this.state.options.widgets.indexOf("MultiLocationSelector")} />
                }
                {(this.state.options.widgets.indexOf("LayerFilter") > -1) && <LayerFilter showLayerFilter={showLayerFilter} layerId={layerId} layerFilter={(id) => { this.setState({ showLayerFilter: !this.state.showLayerFilter, layerId: id }) }}
                    handlelayerFilter={this.handlelayerFilter} widgetRenderCallBack={this.widgetRenderCallBack}
                    order={this.state.options.widgets.indexOf("LayerFilter")} />
                }
                {
                    (this.state.options.widgets.indexOf("Select") > -1  &&
                        this.state.options.SelectLayerListOptions && this.state.options.SelectLayerListOptions.length > 0
                        && this.props.layerCustomFilters !== 'VIOLATIONS')
                    && <CSelect options={this.state.options}
                        view={this.state.view} onexpanded={this.OnExpanded}
                        spatialReference={this.state.spatialReference}
                        mapConstants={this.state.mapConstants}
                        ref={(csselect) => { this.csselect = csselect; }}
                        onAreaSelection={this.onAreaSelection}
                        SelectGeomInsteadOfDraw={this.props.SelectGeomInsteadOfDraw != "N"}
                        clearsketch={this.props.clearsketch} widgetRenderCallBack={this.widgetRenderCallBack}
                        order={this.state.options.widgets.indexOf("Select")}
                        layerCustomFilters={this.props.layerCustomFilters}
                        SubModuleKey={this.state.SubModuleKey}
                        defaultSelectLayer={this.state.defaultSelectLayer}
                        onAreaClear={this.onAreaClear}
                        onSelectSaveorUpdate={this.props.onSelectSaveorUpdate}
                    />
                }
                {
                    this.state.options.widgets.indexOf("DatTable")  &&
                    this.state.selectedArea &&
                    this.state.selectedArea.counter &&
                    this.state.selectedLayerOptions &&
                    this.state.selectedLayerOptions.Id &&
                    <CDatTable view={this.state.view} options={this.state.options}
                        onexpanded={this.OnExpanded} canEdit={canEdit} isGridView={isGridView}
                        onDataTableLoad={this.onDataTableLoad}
                        mapConstants={this.state.mapConstants}
                        spatialReference={this.state.spatialReference}
                        selectedLayerOptions={this.state.selectedLayerOptions}
                        onSaveorUpdateMapResponse={this.props.onSaveorUpdateMapResponse}
                        selectedArea={this.state.selectedArea}
                        SubModuleKey={this.state.SubModuleKey}
                        SelectGeomInsteadOfDraw={this.props.SelectGeomInsteadOfDraw != "N"}
                        setStateMapInformation={this.props.setStateMapInformation}
                        clearsketch={this.props.clearsketch} widgetRenderCallBack={this.widgetRenderCallBack}
                        order={this.state.options.widgets.indexOf("DatTable")}
                        clearAreaSelection={this.state.clearAreaSelection}
                    />
                }
                {
                    mapcenterlatlong.length > 0 && <LocationZoom view={this.state.view} widgetRenderCallBack={this.widgetRenderCallBack} />
                }
                {
                    (this.state.options.widgets.indexOf("MultiLocationSelector") > -1 &&
                        this.state.options &&
                        Array.isArray(this.state.options.MultipleLocationData) &&
                        this.state.options.MultipleLocationData.length > 0) && <LocationCompare view={this.state.view} options={this.state.options} widgetRenderCallBack={this.widgetRenderCallBack} order={this.state.options.widgets.indexOf("MultiLocationSelector")} />
                }
            </div>
        );
    }
}

export default CConfig;