import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,'(x',/\d/, /\d/, /\d/, /\d/,')']}
            placeholderChar={'\u2000'}
            showMask
            guide={false}
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,

};

function formatText(text) {
    if (text) {
        if (this.props.mask == 'phone')
            return text.replace(/[^A-Z0-9]/ig, "");
        if (this.props.mask == 'amount')
            return text
        return text
    }
    return undefined
}

export default function FormattedInputs(props) {
    console.log(props)
    const { handleFieldChange, tooltip, value, name, id, edit, options, conditions, disabled, ...otherProps } = props
    let disabledstatus = conditions && conditions.enable ? conditions.enable : disabled
    const { label, xtype, inputProps, password, error, helperText,required } = otherProps
    const val = ((value === null) || (value === undefined)) ? '' : value
    if (edit === true) {
        return (
            <div >
                <FormControl fullWidth error={error} >
                    {tooltip && <InputLabel htmlFor="formatted-text-mask-input">{label}  &nbsp;    < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                        <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
                    </Tooltip></InputLabel>}
                    {!tooltip && <InputLabel htmlFor="formatted-text-mask-input">{label}   </InputLabel>}
                    <Input
                        name={name}
                        value={value}
                        onChange={handleFieldChange}
                        id="formatted-text-mask-input"
                        required={required}
                        inputComponent={TextMaskCustom}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {helperText && <FormHelperText >{helperText}</FormHelperText>}
                </FormControl>

            </div>
        );
    }
    else {
        return (
            <div className="row">
                {tooltip && <Fragment><Typography variant={'subtitle2'} color={'primary'}>{label}&nbsp;< Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                    <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
                </Tooltip> </Typography>&nbsp; :&nbsp; <Typography variant={'body2'} color={'textPrimary'}>{val}   </Typography></Fragment>}
                {!tooltip && <Fragment><Typography variant={'subtitle2'} color={'primary'}>{label} </Typography>&nbsp; :&nbsp; <Typography variant={'body2'} color={'textPrimary'}>{val}   </Typography></Fragment>}
            </div>
        );
    }
}
