import DefaultSettings from '../components/settings';

const handleMessage = (alertMessage) => {
    if (DefaultSettings.getAlert() != null && alertMessage && alertMessage.message !== '')
        DefaultSettings.getAlert().show((alertMessage && alertMessage.message), (alertMessage && alertMessage.type || 'info'));
}

//alert
const handleNotices = (id, isAuto = false, values, info) => {
    id && DefaultSettings.showNotice(id, isAuto, values, info);
}

const handleNotifications = (notify, isAuto = false, values, info) => {
    notify && DefaultSettings.showNotify(notify, isAuto, values, info);
}

const handleConfirmation = (alertMessage) => {
    alertMessage && DefaultSettings.displayConfirmation(alertMessage);
}

export default function handleEvents(events, fieldValues, info) {
    let values = fieldValues;
    const { alertMessage, notificationId, noticesId, isAutoNotice, isAutoNotification, messageType, decodeType, sideBarItems = [] } = events;
    values['sideBarItems'] = (values['sideBarItems'] && values['sideBarItems'].length > 0) ? values['sideBarItems'] : sideBarItems || [];
    if (alertMessage) {
        if (alertMessage.decodeType && alertMessage.decodeType === 'C')
            handleConfirmation(alertMessage.message)
        else handleMessage(alertMessage);
    }
    handleNotices(noticesId, isAutoNotice, values, info)
    handleNotifications(notificationId, isAutoNotification, values, info)
}

