import React, { useEffect, useState, Fragment } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Request from '../REST/groups';
import CreateExpression from './create';
import Button from '@material-ui/core/Button';
import { getEnvVariable } from '../../../src/modules/environmentalList';

const SCHEMA_URL = getEnvVariable('PaymentAPI') + 'schema'
const EXPR_URL = getEnvVariable('PaymentAPI') +'expression'
const FIELD_URL = getEnvVariable('PaymentAPI') +'field'
const FIELD_OPTION_URL = getEnvVariable('PaymentAPI') +'fieldoption'

export default function ExpressionView({ group }) {

    const [selected, setSelected] = useState('')
    const [options, setOptions] = useState([]);
    const [schema, setSchema] = useState(null);

    useEffect(() => {
        Request(SCHEMA_URL).get().then(resp => {
            if (Array.isArray(resp.data))
                setOptions(resp.data.filter(e => e.groupId === group).map(e => { return { label: e.name, value: e.id, required: e.required } }))
        })
    }, [group])

    const handleChange = (evt) => {
        setSelected(evt.target.value)
    }

    useEffect(() => {
        if (selected !== '')
            getSchema(selected).then(resp => {
                setSchema(resp)
            })
    }, [selected])

    console.log(schema)

    return <div className='row'>
        <div className='col-12'>
            <FormControl fullWidth>
                <InputLabel>Select Schema</InputLabel>
                <Select value={selected} onChange={handleChange}>
                    {options.map((e, i) => {
                        return <MenuItem key={e.value} value={e.value}>{e.label}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </div>
        {selected !== '' && <div className='col-10 pt-3'>
            <ExpressionList schema={schema} id={selected} />
        </div>}
    </div>
}

function ExpressionList({ schema, id }) {

    const [list, setList] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (selected === null)
            Request(EXPR_URL).getById(id).then(resp => {
                if (Array.isArray(resp.data))
                    setList(resp.data)
            })
    }, [id, selected])

    const handleSelect = (val) => {
        setSelected(val);
    }

    if (selected)
        return <Fragment>
            <CreateExpression group={id} schema={schema} json={list.find(e => e.id === selected)} />
            <Button onClick={() => setSelected(null)}>Cancel</Button>
        </Fragment>


    return <table className='table table-hover table-bordered'>
        <thead>
            <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Key</th>
                <th><Button variant='outlined' size='small' color='secondary' onClick={() => handleSelect(-1)}>Create New</Button></th>
            </tr>
        </thead>
        <tbody>
            {list.map((e, idx) => {
                return <tr key={idx}>
                    <td>{e.name}</td>
                    <td>{e.description}</td>
                    <td>{e.expressionKey}</td>
                    <td><Button size='small' variant='outlined' onClick={() => handleSelect(e.id)}>View</Button></td>
                </tr>
            })}
            {list.length === 0 && <tr><td colSpan={4}>No Data</td></tr>}
        </tbody>
    </table>

}

export const getSchema = (schemaId) => {

    const getType = (type) => {
        switch (type) {
            case 1:
                return 'string'
            case 2:
                return 'number'
            case 3:
                return 'object'
            case 4:
                return 'date'
            case 5:
                return 'boolean'
            default:
                return 'string'
        }
    }

    return new Promise((resolve, reject) => {
        let counter = 0;
        Promise.all([Request(SCHEMA_URL).getById(schemaId), Request(FIELD_URL).getById(schemaId)]).then(([schema, resp]) => {
            if (Array.isArray(resp.data)) {
                const ids = resp.data.map(e => e.id)
                Promise.all(ids.map(e => Request(FIELD_OPTION_URL).getById(e))).then(result => {
                    const temp = {};
                    resp.data.forEach((e, i) => {
                        temp[e.name] = { type: getType(e.fieldType) }
                        if (e.fieldType === 'object') {
                            counter++;
                            getSchema(e.schemaId).then(res => {
                                const { properties, required } = res;
                                temp[e.name]['properties'] = properties
                                temp[e.name]['required'] = required;
                                counter--;
                                if (counter === 0)
                                    resolve({ type: 'object', properties: temp, required: schema.data.required.split(',') });
                            })
                        }
                        result[i].data.forEach(x => {
                            if (x.name !== 'enum')
                                temp[e.name][x.name] = x.value
                            else
                                temp[e.name][x.name] = x.value.split(',')
                        })
                        if (counter === 0)
                            resolve({ type: 'object', properties: temp, required: schema.data.required.split(',') });
                    })
                })
            }
        })
    })
}