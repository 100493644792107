import React, { Fragment, useEffect, useState, useRef } from "react"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useDispatch } from "react-redux";
import { passtabData } from '../../../actions';
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {
        //width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));


export default function Tags(props) {
    const { name, Name, value, handleFieldChange,  options, dropdownvalues, dataItem, defaultSelValue, tooltip, edit, defaultdataset,  error, required, helperText } = props
    let items = dropdownvalues && options.sublist ? dropdownvalues[options.sublist] : dropdownvalues ?  dropdownvalues : []
    let namevalue = name || Name
    let passtabinfo = defaultdataset ? props.header[defaultdataset] : null
    let passtabinfoval = passtabinfo ? passtabinfo[namevalue] : []
    let [defaultitems, setDefaultitems] = useState(value || [])
    const dispatch = useDispatch();

    useEffect(() => {
        if (passtabinfoval && items.length > 0 && Array.isArray(passtabinfoval) && passtabinfoval.length > 0 ) {
            let result = [];
            _.forEach(items, function (n, key) {
                _.forEach(passtabinfoval, function (n2, key2) {
                    if (n.id === n2.id) {
                        result.push(n);
                    }
                });
            });

            if(result.length === 0){
                _.forEach(passtabinfoval, function (n2, key2) {
                    result.push(n2);
                });
            }
            result = [...new Set(result)]
            setDefaultitems(result);
            const e = {
                target: {
                    name: name || Name || options.name,
                    value: result
                }
            }
            if(props.handleFieldChange)
                props.handleFieldChange(e)


            dispatch(passtabData(null));
        }

    }, [dropdownvalues])



    const handleChange = (event, values) => {
        let resultdata = values.map(a => a.Id);
        let stringformatvalues = [...new Set(resultdata)].join()

        const e = {
            target: {
                name: name || Name || options.name,
                value: values

            }
        }
        setDefaultitems(values)
        //defaultitems = values
        if (options.savestringformat) {
            const e1 = {
                target: {
                    name: name || Name || options.name,
                    value: stringformatvalues
                }
            }
            props.handleFieldChange(e1)
            defaultitems = dropdownvalues.filter(a => value.split(',').includes(a.Id.toString()))
        } else
            props.handleFieldChange(e)
    }

    if (options.savestringformat) {
        defaultitems = dropdownvalues ? dropdownvalues.filter(a => value.split(',').includes(a.Id.toString())) : []
       // setDefaultitems(val) ---correct: unused value
    }


    if (dataItem && options.itemname) {
        if (dataItem[options.itemname] && dataItem[options.itemname] !== "") {
            let dataitemvalue = dataItem[options.itemname];
            if (dataitemvalue) {
                dataitemvalue = dataitemvalue.toString();
                let dataitemarray = dataitemvalue.split(",");
                if (Array.isArray(dataitemarray)) {
                    defaultitems = items.filter(a => dataitemarray.includes(a.id.toString()))
                }
            }
        }
    } else if (defaultSelValue) {
        let selitemarray = defaultSelValue.split(",");
        if (Array.isArray(selitemarray)) {
            defaultitems = items.filter(a => selitemarray.includes(a.id.toString()))
        }
    }


    if (edit === false) {
        let resultdata = defaultitems.map(a => a.Name);
        let displayvalues = [...new Set(resultdata)].join()
      //  let getvalue = (items) => items.name || items.Name //--correct: unused value
        return <Fragment>
            {tooltip && <div > <Typography variant={'caption'} className="text-secondary">{options.label}&nbsp;
                 < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                    <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
                </Tooltip>  </Typography>  <Typography className="fw-bold" variant={'body2'} color={'textPrimary'} >{displayvalues}   </Typography>
            </div>}
            {!tooltip && <div > <Typography variant={'caption'} className="text-secondary">{options.label}&nbsp; </Typography>
               <Typography className="fw-bold" variant={'body2'} color={'textPrimary'} >{displayvalues}   </Typography>
            </div>}
        </Fragment >
    }
    else {
        return (
                <Autocomplete
                    key={name || options.name}
                    fullWidth
                    multiple
                    name={namevalue}
                    id="tags-standard"
                    options={items}
                    getOptionLabel={(items) => items.name || items.Name}
                    onChange={handleChange}
                    filterSelectedOptions
                    defaultValue={items[0]}
                    value={defaultitems}
                    disabled={options.disabled}
                    renderInput={(params) => (
                        <TextField
                            required={required}
                            error={error}
                            fullWidth
                            {...params}
                            onChange={handleFieldChange}
                            name={namevalue}
                            variant="standard"
                            label={options.label}
                            placeholder={options.placeholder}
                            helperText={helperText}
                            InputLabelProps={{
                                shrink: true,
                            }
                            }
                        />
                    )}
                />

        );
    }
}

