import React, { useState, useEffect } from 'react'
import { range } from 'ramda'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core/styles';
import StartTime from './chooseTime'
import Card from './card'

const styles = theme => ({
    root: {
        padding: '20px',
    },
    secondaryPaper: {
        padding: '20px',
        marginTop: '20px'
    },
    radioBtn: {
        marginTop: '20px'
    }
})

const Daily = (props) => {
    const [every, setEvery] = useState()
    const [value, setValue] = useState(props.value)

    useEffect(() => {
        setValue(props.value)

    }, [props.value])

    useEffect(() => {
        const value = props.value
        if (value[3] === '?') {
            setEvery(false)
        } else {
            setEvery(true)
        }
    }, [])

    const onDayChange = (e) => {
        if ((e.target.value > 0 && e.target.value < 32) || e.target.value === '') {
            let val = [value[0] === '*' ? '0' : value[0], value[1] === '*' ? '0' : value[1], '*', '*', '*'];
            if (e.target.value === '') {
                val[2] = '1/1';
            } else {
                val[2] = `1/${e.target.value}`;
            }
            props.onChange(val)
        }

    }
    const onAtHourChange = (e) => {
        let val = value;
        val[1] = `${e.target.value}`;
        props.onChange(val)
    }
    const onAtMinuteChange = (e) => {
        let val = value;
        val[0] = `${e.target.value}`;
        props.onChange(val)
    }

    const classes = props;
    return (
        <div>
            <Paper className={classes.root}>
                <div className="pl-1">
                    <Radio
                        className={classes.radioBtn}
                        checked={every ? true : false}
                        onClick={(e) => { setEvery(true); props.onChange() }}
                        value="1"
                        name="DailyRadio"
                        inputProps={{ 'aria-label': 'DailyRadio' }}
                    />
                    <TextField
                        id="outlined-number"
                        label="Every day(s)"
                        value={value[2].split('/')[1] ? value[2].split('/')[1] : ''}
                        onChange={onDayChange}
                        type="number"
                        InputLabelProps={{
                            disabled: every ? false : true,
                            shrink: true,
                        }}
                        margin="normal"
                    />
                </div>
                <div className="col">
                    <FormControlLabel
                        value="top"
                        //className="col"
                        control={<Radio
                            onClick={(e) => { setEvery(false); props.onChange([value[0], value[1], '*', '*', 'MON-FRI']) }}
                            value="2"
                            name="DailyRadio"
                            checked={every ? false : true}
                            inputProps={{ 'aria-label': 'DailyRadio' }}
                        />}
                        label="Every week day"
                        labelPlacement="right"
                    />
                </div>
            </Paper>            
                <Card label="Start time">
                    <StartTime hour={value[1]} minute={value[0]} onAtMinuteChange={onAtMinuteChange} onAtHourChange={onAtHourChange} />
                </Card>
        </div>
    )
}

export default withStyles(styles)(Daily)
