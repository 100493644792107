import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Close'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

export default function (props) {
    const { title, icon, text, name, handleCloseClick, handleSaveClick, extraProps } = props;
    const { save, clear, color } = extraProps
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AppBar position="static" color={color}>
                <Toolbar variant="dense">
                    <div>
                        {title}
                    </div>
                    <div className='flex-grow-1' />
                    {save && <IconButton color="inherit" aria-label="menu" edge="end" onClick={handleSaveClick}>
                        <SaveIcon />
                    </IconButton>}
                    {clear && <IconButton color="inherit" aria-label="menu" edge="end" onClick={handleCloseClick}>
                        <ClearIcon />
                    </IconButton>
                    }
                </Toolbar>

            </AppBar>

            {/* <div className="fw-bold"></div>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={"float-md-right"}
                startIcon={<SaveIcon />}

                name={name}
            >
                {text}
            </Button> */}
        </div>
    )

}