import React, { useState, useEffect, Fragment } from 'react';
import Switch from '../../../../controls/components/switch';
import Dropdown from '../../../../controls/components/dropdown';
import { createRequest, requestApi } from '../../../../controls/helpers/rest';
import { useParams } from 'react-router-dom';
import MultiCheckAutoComplete from '../../../../controls/components/checkautocomplete';
import { getEnvVariable } from '../../../../../modules/environmentalList'
import RadioButtonsGroup from '../../../../controls/components/radiogroup';
import Scheduler from '../../../form/cscheduler';

const transformProps = {
    "Y": true,
    "N": false,
    true: "Y",
    false: "N"
}


const extraProps = {
    transformProps: {
        label: 'Name',
        value: 'Value'
    },
}



export default function ExtraProperties({ result, section, handleFieldValue, isSidebar = false }) {
    const [notifications, setNotifications] = useState([]);
    const [notices, setNotices] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [rules, setRules] = useState([]);
    const [eventsList, setEvents] = useState([])
    const [message_types, setMessagetype] = useState([])
    const params = useParams()
    const [reports, setReports] = useState([])

    useEffect(() => {
        const read = [
            { //
                url: getEnvVariable('AdminConfig') + `/api/ProjectMessages/RulesByName?name=Module.BUSINESS_RULES_ALERTS`,
                type: 'get'
            },
            {
                url: getEnvVariable('Admin') + `/api/NoticeTypes/All${params.routeid ? '/' + params.routeid : ''}/Active`,
                type: 'get'
            },
            {
                url: params.routeid ? getEnvVariable('Notification') + `/api/NotificationSettings/AllByType/${params.routeid}/BUSINESS_RULES_NOTIFICATION` :
                    getEnvVariable('Notification') + `/api/NotificationSettings/All/Active`,
                type: 'get'
            },
            {
                url: getEnvVariable('AdminConfig') + `/api/Domains/values?domainkey=APPLICATION_TYPES_EVENTS&domainid=0`,
                type: 'get'
            },
            {
                url: getEnvVariable('AdminConfig') + `/api/Domains/values?domainkey=PROJECT_MESSAGE_TYPE&domainid=0`,
                type: 'get'
            },
            {
                url: getEnvVariable('Report') + `/api/Reports/All`,
                type: 'get'
            },
        ];
        if (isSidebar === false) {
            read.push({
                url: section && section.Id ? getEnvVariable('AdminConfig') + `/api/BusinessRules/${params.routeid}/All?sitePermitTypeSectionId=${section.Id}` :
                    getEnvVariable('AdminConfig') + `/api/BusinessRules/${params.routeid}/All`,
                type: 'get'
            })
        }
        else read.push({
            url: getEnvVariable('Admin') + `/api/SystemSystemBusinessRules/All?tableName=${result.TableName}`,
            type: 'get'
        })
        Promise.all(read.map(each => requestApi(createRequest(each, {})).catch(error => { return error; }))).then(response => {
            let [alerts, notices, notifications, eventlist, messagetypes, reportlist, rules = []] = response;
            let noneItem = { "Message": "None", "Id": 0, 'ID': 0, Name: 'None', ProjectMessageId: 0, }
            alerts.unshift(noneItem)
            notices.unshift(noneItem)
            Array.isArray(notifications) && notifications.unshift(noneItem)
            rules.unshift(noneItem)
            setNotifications(notifications || [])
            setNotices(notices)
            setAlerts(alerts)
            setRules(rules)
            setEvents(eventlist)
            setMessagetype(messagetypes)
            setReports(reportlist)
        }).catch(ex => {
            console.error(ex)
        })
    }, [])


    const handleChange = (event) => {
        const { name, value } = event.target;
        let val = value
        if ((name === 'CanOverwriteGlobalRule') || (name === 'IsTriggerAnotherRuleOnFailure')) {
            val = (event.target.checked) ? 'Y' : 'N'
        }
        handleFieldValue({ target: { value: val, name: name, checked: false }, currentTarget: { dataset: null } })
    }


    return (
        <div>
            {!isSidebar && <RadioButtonsGroup handleFieldValue={handleChange} name={'EventType'} extraProps={extraProps} value={result.EventType || 'E'}
                items={[{ Name: 'On Event', Value: 'E' },
                { Name: 'On Schedule', Value: 'S' }]} />}
            {((result && result.EventType === 'E') || isSidebar) && <Fragment>
                <MultiCheckAutoComplete label={'Trigger On'} name={'EventOn'} ismulti={true} value={result.EventOn} extraProps={{
                    transformProps: { label: 'Name', value: 'Value' },
                }} items={eventsList || []} handleFieldValue={handleChange} />
                <Switch label='Override Global Rule?' name="CanOverwriteGlobalRule" value={(result && result.CanOverwriteGlobalRule) || 'N'} handleFieldValue={handleChange}
                    extraProps={{
                        transformProps: transformProps
                    }} />
                {result && result.RuleType === 'S' && <div className="row" >
                    <div className="col-sm-6" >   <Switch name='IsTriggerAnotherRuleOnFailure' label="Trigger Another Rule On Failure?"
                        value={(result && result.IsTriggerAnotherRuleOnFailure) || null} handleFieldValue={handleChange} extraProps={{
                            transformProps: transformProps
                        }} />
                    </div>
                    {result && result['IsTriggerAnotherRuleOnFailure'] === 'Y' && <div className="col-sm-6" > <Dropdown name="TriggerAnotherRuleOnFailure"
                        value={(result && result.TriggerAnotherRuleOnFailure) || 'N'} items={rules}
                        handleFieldValue={handleChange} extraProps={{
                            transformProps: {
                                label: 'Name',
                                value: 'Id'
                            }
                        }} /></div>
                    }
                    {result && result['EventOn'].includes('E') && <div className='row col-12'>
                        <div className="col-sm-4" >
                            <RadioButtonsGroup handleFieldValue={handleChange} label={'Pre Condition Valid/InValid'} name={'PreConditionCheck'}
                                extraProps={extraProps} value={result.PreConditionCheck || 'V'}
                                items={[{ Name: 'Valid', Value: 'V' },
                                { Name: 'In-Valid', Value: 'I' }]} />
                        </div>
                        <div className="col-sm-8" > <Dropdown name="PreConditionRuleId"
                            value={result && result.PreConditionRuleId} items={rules}
                            label={'Pre Condition'}
                            handleFieldValue={handleChange} extraProps={{
                                transformProps: {
                                    label: 'Name',
                                    value: 'Id'
                                }
                            }} />
                        </div>
                    </div>}
                </div>}
            </Fragment>}
            {result && result.EventType === 'S' && <Fragment>
                <Scheduler value={result && result.ScheduleAt || ''} name='ScheduleAt' handleFieldValue={handleChange} />
            </Fragment>}
            <div className="row">
                <div className="col-sm-9">
                    <Dropdown label='Message' name="ProjectMessagesId" value={result && result.ProjectMessagesId || ""} items={alerts}
                        handleFieldValue={handleChange} extraProps={{
                            transformProps: {
                                label: 'Message',
                                value: 'ProjectMessageId'
                            }
                        }} />   </div>
                <br />
            </div>
            <div className="row">
                <div className="col-sm-9">
                    <Dropdown label='Notification' name="NotificationSettingsId" value={result && result.NotificationSettingsId || ''} items={notifications}
                        handleFieldValue={handleChange} extraProps={{
                            transformProps: {
                                label: 'Name',
                                value: 'Id'
                            }
                        }} />     </div> <Switch label='Is Auto' name="NotificationSettingsIsAuto" value={result && result.NotificationSettingsIsAuto || null} handleFieldValue={handleChange} extraProps={{
                            transformProps: transformProps
                        }} /><br />
            </div>
            <div className="row">
                <div className="col-sm-9">
                    <Dropdown label='Notice' name="NoticesId" value={(result && result.NoticesId) || ''} items={notices}
                        handleFieldValue={handleChange} extraProps={{
                            transformProps: {
                                label: 'Name',
                                value: 'Id'
                            }
                        }} />   </div>   <Switch label='Is Auto' name="NoticesIsAuto" value={result && result.NoticesIsAuto || ''} handleFieldValue={handleChange} extraProps={{
                            transformProps: transformProps
                        }} /><br />
            </div>
            {/* <div className="row">
                <div className="col-sm-9">
                    <Dropdown label='Reports' name="ReportId " value={result && result.ReportId || ''} items={reports}
                        handleFieldValue={handleChange} extraProps={{
                            transformProps: {
                                label: 'Description',
                                value: 'Id'
                            }
                        }} />   </div>   <Switch label='Is Auto' name="ReportIsAuto " value={result && result.ReportIsAuto || null} handleFieldValue={handleChange} extraProps={{
                            transformProps: transformProps
                        }} /><br />
            </div> */}
            {/* {result && result.EventType === 'S' && <div className="row">
                <div className="col-sm-9">
                    <Dropdown label='Reports' name="ReportId" value={result && result.ReportId || null} items={reports}
                        handleFieldValue={handleChange} extraProps={{
                            transformProps: {
                                label: 'Description',
                                value: 'Id'
                            }
                        }} />   </div>

                        <br />
            </div>} */}
        </div>
    );
}


//.filter(each =>!(each.EventTriggerOn.includes('OC') || each.EventTriggerOn.includes('OL') || each.EventTriggerOn.includes('AS')))