import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { GetData, PostData, PutData } from '../../core/controls/helpers/requests';
import { snackbarMessage } from '../../core/actions';
import { getEnvVariable } from '../environmentalList';
import CFormDropDown from '../../core/modules/form/cformdropdown';
import FormHeader from '../../core/modules/form/cformheader';
import CFormSwitch from '../../core/modules/form/cformswitch';
import CFormTextBox from '../../core/modules/form/cformtextbox';
import CMulti from '../../core/modules/form/cmultiduallistbox2';
import Confirmation from '../../core/modules/form/confirmationdialog';
import Scheduler from '../../core/modules/form/cscheduler';
import nsComponents from '../../json/notificationsettings/nsComponents';
import NotificationEvents from './notificationevents';
import NsTemplate from './nstemplate';
const _ = require('lodash');


const transformProps = {
    "value": "Id",
    "name": "Name",
    "key": "Id"
}
const submoduletransformProps = {
    "value": "Key",
    "name": "DisplayName",
    "key": "Key"
}
const transformPropsWithKey = {
    "value": "Key",
    "name": "Name",
    "key": "Key"
}

//Updated By: vijaya. comments : Added Breadcrumbs.
const addbreadcrumbs = [
    { "link": "Add Notification", "materialicon": "notifications_active", className: 'eps-breadcrumb-item ', class: 'text-light' }
]
const editbreadcrumbs = [
    { "link": "Notification", appendval: 'Name', "materialicon": "notifications_active", className: 'eps-breadcrumb-item ', class: 'text-light' }
]



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

// const styles = theme => ({
//     root: {
//         flexGrow: 1,
//         width: '100%',
//         backgroundColor: theme.palette.background.paper,
//         "& .MuiTab-root": {
//             'text-transform': 'capitalize'
//         },
//     },
// }));

class NS extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sitesJson: [],
            regionsJson: [],
            notificationTypeJson: [],
            submoduleJson: [],
            showSubModule: false,
            sitePermitTypeJson: [],
            showSitePermitType: false,
            showSite: false,
            notificationEventOnJson: [],
            showEventOn: false,
            showEventDetailsButton: false,
            showScheduleNotitificationTemplateButton: false,
            showSchedulerButton: false,
            NotificationType: "EVENT_NOTIFICATION",
            SubModuleKey: null,
            SiteID: null,
            RegionId: null,
            SitePermitTypeId: 0,
            EventOn: null,
            BeforeDueDays: 0,
            results: { NotificationType: "EVENT_NOTIFICATION", Status: 'I' },
            Name: '',
            Description: '',
            Status: 'I',
            CreatedOn: '',
            CreatedBy: '',
            UpdatedOn: '',
            UpdatedBy: '',
            edit: false,
            editbutton: true,
          //  duplicatebutton: true,
          //  iseditduplicate: false,
            updateRecord: this.props.dataItem ? (!this.props.dataItem.ID ? false : true) : false,
            error_name: '',
            error_description: '',
            error_submodulekey: '',
            error_Sitepermittype: '',
            error_regionId: '',
            error_EventOn: '',
            disabled: this.props.dataItem ? (!this.props.dataItem.ID ? false : true) : false,
            value: 0,
            progresscomplete: false,
            checkresults: { NotificationType: "EVENT_NOTIFICATION", Status: 'I' },
            openconfirmation: false,
            compvalidation: null,
            hideeventrecipients: false,
            hideeventstabs: false,
            isapplicantcheck: true
        }
        this.nsComponentsArray = { ...nsComponents };
        this.schedulerPopup = this.nsComponentsArray.components.find(x => x.key == "nsscheduler");
        this.sitesPopup = this.nsComponentsArray.components.find(x => x.key == "nssites");
        this.eventTemplatePopup = this.nsComponentsArray.components.find(x => x.key == "eventtemplate");
        this.eventTemplatePopup = this.nsComponentsArray.components.find(x => x.key == "eventtemplate");
        this.recipientsPopup = this.nsComponentsArray.components.find(x => x.key == "nsrecipients");
        this.eventDetailsPopup = this.nsComponentsArray.components.find(x => x.key == "nseventdetails");
        this.statusJson = this.nsComponentsArray.components.find(x => x.key == "nsstatus").options;
        this.url1 = getEnvVariable('Notification') + `/api/Domains/values`;
        this.url2 = getEnvVariable('MasterAdmin') + `/api/module/` + getEnvVariable('ModuleKey') + `/submodule/All/Active/IsConfigurable`
        this.url3 = getEnvVariable('Admin') + `/api/Sites/` + getEnvVariable('ModuleKey') + `/All/Active`
        this.url4 = getEnvVariable('AdminConfig') + `/api/sitepermittypes/Sites/Active`
        this.url5 = getEnvVariable('Notification') + `/api/Domains/values`
        this.url6 = this.props.dataItem ? getEnvVariable('Notification') + `/api/NotificationSettings/${this.props.dataItem.ID}` : getEnvVariable('Notification') + `/api/NotificationSettings/${this.props.match.params.routeid}`
        this.url7 = getEnvVariable('Notification') + `/api/NotificationSettings/Update`
        this.url8 = getEnvVariable('Notification') + `/api/NotificationSettings/Add`
        this.scheduleReportsPopup = this.nsComponentsArray.components.find(x => x.key == "nsschedulereportsbtn")
        this.url9 = getEnvVariable('AdminConfig') + `/api/SitePermitTypes/Regions/Active`
        this.url10 = getEnvVariable('Admin') + `/api/Regions/` + getEnvVariable('ModuleKey') + `/All/Active`
        this.url11 = getEnvVariable('AdminConfig') + `/api/SitePermitTypes/SubModuleKey/Active`
        this.url12 = this.props.dataItem ? getEnvVariable('Notification') + `/api/NotificationRecipients/Recipient/${this.props.dataItem.ID}` : getEnvVariable('Notification') + `/api/NotificationRecipients/Recipient/${this.props.match.params.routeid}`
    }
    handleFieldChange = (event) => {
        if (event.target.name == 'NotificationType') {
            this.setState({
                [event.target.name]: event.target.value,
                showSubModule: (event.target.value == 'EVENT_NOTIFICATION' || event.target.value == 'BUSINESS_RULES_NOTIFICATION') ? true : false,
                showSitePermitType: (event.target.value == 'EVENT_NOTIFICATION' || event.target.value == 'BUSINESS_RULES_NOTIFICATION') ? true : false,
                showSite: (event.target.value == 'EVENT_NOTIFICATION' || event.target.value == 'BUSINESS_RULES_NOTIFICATION') ? true : true,
                showEventOn: (event.target.value == 'EVENT_NOTIFICATION') ? true : false,
                showEventDetailsButton: (event.target.value == 'BUSINESS_RULES_NOTIFICATION') ? false : true,//(event.target.value == 'EVENT_NOTIFICATION' || event.target.value == 'BUSINESS_RULES_NOTIFICATION') ? true : false,
                showScheduleNotitificationTemplateButton: (event.target.value == 'EVENT_NOTIFICATION' || event.target.value == 'BUSINESS_RULES_NOTIFICATION') ? false : true,
                showSchedulerButton: (event.target.value == 'EVENT_NOTIFICATION' || event.target.value == 'BUSINESS_RULES_NOTIFICATION') ? false : true,
                SubModuleKey: (event.target.value == 'EVENT_NOTIFICATION' || event.target.value == 'BUSINESS_RULES_NOTIFICATION') ? this.state.SubModuleKey : null,
                SitePermitTypeId: (event.target.value == 'EVENT_NOTIFICATION' || event.target.value == 'BUSINESS_RULES_NOTIFICATION') ? this.state.SitePermitTypeId : null,
                EventOn: (event.target.value == 'EVENT_NOTIFICATION' || event.target.value == 'BUSINESS_RULES_NOTIFICATION') ? this.state.EventOn : null,
                results: {
                    ...this.state.results,
                    [event.target.name]: event.target.value,
                    SubModuleKey: (event.target.value == 'EVENT_NOTIFICATION' || event.target.value == 'BUSINESS_RULES_NOTIFICATION') ? this.state.SubModuleKey : '',
                    Site: (event.target.value == 'EVENT_NOTIFICATION' || event.target.value == 'BUSINESS_RULES_NOTIFICATION') ? this.state.Site : 0,
                    SitePermitTypeId: (event.target.value == 'EVENT_NOTIFICATION' || event.target.value == 'BUSINESS_RULES_NOTIFICATION') ? this.state.SitePermitTypeId : 0,
                    EventOn: (event.target.value == 'EVENT_NOTIFICATION' || event.target.value == 'BUSINESS_RULES_NOTIFICATION') ? this.state.EventOn : null,
                }
            })
        }
        else if (event.target.name == 'SubModuleKey') {

            this.setState({
                [event.target.name]: event.target.value,
                showSitePermitType: (event.target.value == 'OWNERS') ? false : true,
                showSite: event.target.value == 'OWNERS' ? false : true,
                results: {
                    ...this.state.results,
                    [event.target.name]: event.target.value
                }
            }, () => {
                if (event.target && [event.target.name] == 'SubModuleKey')
                    this.loadEventOn(this.state.SubModuleKey, '')
                this.loadSubModulePermitType(this.state.SubModuleKey, 0);
            })
        }
        else if (event.target.name == 'EventOn') {
            this.handleEventOn(event.target.value);
            this.setState({
                [event.target.name]: event.target.value,
                results: {
                    ...this.state.results,
                    [event.target.name]: event.target.value
                }
            })
        }
        else {
            if (event && event.persist) {
                event.persist();
            }

            this.setState({
                [event.target.name]: event.target.value,
                results: {
                    ...this.state.results,
                    [event.target.name]: event.target.value
                }
            }, () => {
                if (event.target && [event.target.name] == 'SiteID')
                    this.loadSitePermitType(this.state.SiteID, 0);
                // if (event.target && [event.target.name] == 'RegionId')
                //     this.loadRegionPermitType(this.state.RegionId, 0);
                if (event.target && [event.target.name] == 'SubModuleKey')
                    this.loadEventOn(this.state.SubModuleKey, '')


            })
        }

    }
    handleEventOn = (targetVal) => {

        let val = false;

        switch (targetVal) {
            case "APPLICATION_STATUS_CHANGE":
                val = true;
                break;
            case "FIELD_VALUE_CHANGE":
                val = true;
                break;
            case "PERMIT_STATUS_CHANGE":
                val = true;
                break;
            case "WORKFLOW_STATUS_CHANGE":
                val = true;
                break;
            case "RESOLUTION_STATUS":
                val = true;
                break;
            default:
                val = false;
                break;
        }
        this.setState({
            showEventDetailsButton: val
        })

        this.changeEventDetailProps(targetVal);

    }

    changeEventDetailProps(targetVal) {
        if (targetVal == "FIELD_VALUE_CHANGE") {
            this.eventDetailsPopup = this.nsComponentsArray.components.find(x => x.key == "nsapplicationeventdetails");
        } else {
            this.eventDetailsPopup = this.nsComponentsArray.components.find(x => x.key == "nseventdetails");
        }
        console.log(this.eventDetailsPopup);
    }


    componentDidMount() {
        this.didmountinitialload();
    }
    didmountinitialload() {
        // this.progressBar.start()
        console.trace()

        this.fetchData('GET', this.url1, 'domainkey=NOTIFICATION_TYPE', null).then(data => this.setState({
            notificationTypeJson: data, progresscomplete: true
        }, () => {
            // this.progressBar.complete()
        }))
        this.fetchData('GET', this.url2, null, null).then(data => this.setState({
            submoduleJson: data
        }))
        this.fetchData('GET', this.url3, null, null).then(data => this.setState({
            sitesJson: data
        }))
        this.fetchData('GET', this.url10, null, null).then(data => this.setState({
            regionsJson: data
        }))
        //let updatedRecord = this.props.match.params.routeid && (this.props.match.params.routeid == 'add' ? false : true)
        if (this.state.updateRecord) { //|| updatedRecord
            this.fetchData('GET', this.url6, null, null).then(data => this.setState({
                Name: data.Name,
                Description: data.Description,
                NotificationType: data.NotificationType,
                SubModuleKey: data.SubModuleKey,
                SiteID: data.SiteID,
                RegionId: data.RegionId,
                SitePermitTypeId: data.SitePermitTypeId,
                EventOn: data.EventOn,
                Status: data.Status,
                CreatedOn: data.CreatedOn,
                CreatedBy: data.CreatedBy,
                checkresults: data,
                results: data,
                //results: data,
                // UpdatedByName: "EPS Admin",
                // UpdatedOn: "2019-12-12T02:20:01.7772703",
                // CreatedByName: "EPS Admin",
                // CreatedOn: "2019-12-04T10:47:17.7690128",
                showSubModule: (data.NotificationType == 'EVENT_NOTIFICATION' || data.NotificationType == 'BUSINESS_RULES_NOTIFICATION') ? true : false,
                showSite: (data.NotificationType == 'EVENT_NOTIFICATION' || data.NotificationType == 'BUSINESS_RULES_NOTIFICATION') ? true : false,
                showSitePermitType: (data.NotificationType == 'EVENT_NOTIFICATION' || data.NotificationType == 'BUSINESS_RULES_NOTIFICATION') ? true : false,
                showEventOn: (data.NotificationType == 'EVENT_NOTIFICATION') ? true : false,
                showEventDetailsButton: (data.NotificationType == 'BUSINESS_RULES_NOTIFICATION') ? false : true, //(data.NotificationType == 'EVENT_NOTIFICATION' || data.NotificationType == 'BUSINESS_RULES_NOTIFICATION') ? true : false,
                showScheduleNotitificationTemplateButton: (data.NotificationType == 'EVENT_NOTIFICATION' || data.NotificationType == 'BUSINESS_RULES_NOTIFICATION') ? false : true,
                showSchedulerButton: (data.NotificationType == 'EVENT_NOTIFICATION' || data.NotificationType == 'BUSINESS_RULES_NOTIFICATION') ? false : true,
                hideeventstabs: (data.NotificationType == 'BUSINESS_RULES_NOTIFICATION') ? true :
                    (data.NotificationType == 'EVENT_NOTIFICATION') ? data.EventOn &&
                        (data.EventOn == "ATTACHMENTS_CHANGE" || data.EventOn == "IMAGES_CHANGES" || data.EventOn == "INSPECTIONS_CHANGE" || data.EventOn == "NOTES_CHANGE" || data.EventOn == "NOTICES_CHANGE" || data.EventOn == "WORK_NOTIFICATION"
                            || data.EventOn == "SHARED_DISCUSSIONS_CHANGE" || data.EventOn == 'REQUEST_EXCHANGE' || data.EventOn == 'REMOVAL_COMPLETE'
                            || data.EventOn == 'INTERNAL_STATUS_TIMER_NOTIFICATION' || data.EventOn == 'APPEAL_STATUS_TIMER_NOTIFICATION' || data.EventOn == 'EXTERNAL_STATUS_TIMER_NOTIFICATION'
                            || data.EventOn == 'RW_STATUS_TIMER_NOTIFICATION')
                        ? true : false : false,
                hideeventrecipients: (data.NotificationType == 'EVENT_NOTIFICATION') ? (data.EventOn && (data.EventOn == 'ROUTING_NOTIFICATION' || data.EventOn == 'WORKFLOW_DUE_DATE') ? true : false) : false
                //showSitePermitType: data.SubModuleKey == 'OWNERS' ? false : true,
                //showSite: data.SubModuleKey == 'OWNERS' ? false : true
            }, () => {
                if (this.state.SiteID > 0)
                    this.loadSitePermitType(this.state.SiteID, data.SitePermitTypeId);
                if (this.state.SubModuleKey != "") {
                    this.loadSubModulePermitType(this.state.SubModuleKey, data.SitePermitTypeId);
                    this.loadEventOn(this.state.SubModuleKey, data.EventOn)
                    this.loadSideBarTables(data.SubModuleKey);
                }
                if (!this.state.hideeventrecipients) {
                    this.callRecipients()
                }


            }))
        }
        else {

            this.setState({
                showSubModule: true,
                showSitePermitType: true,
                showEventOn: (this.state.NotificationType == 'BUSINESS_RULES_NOTIFICATION') ? false : true,
                showEventDetailsButton: (this.state.NotificationType == 'BUSINESS_RULES_NOTIFICATION') ? false : true,
                showScheduleNotitificationTemplateButton: false,
                showSchedulerButton: false,
            })
        }
    }

    callRecipients = () => {
        this.fetchData('GET', this.url12, null, null).then(data => {
            this.setState({
                isapplicantcheck: data && data.notificationToApplicantOwner && data.notificationToApplicantOwner == 'Y' ? true : false
            })
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dataItem != this.props.dataItem) {
            //this.progressBar.complete();
            this.setState({ value: 0 })
            this.url6 = this.props.dataItem ? getEnvVariable('Notification') + `/api/NotificationSettings/${this.props.dataItem.ID}` : getEnvVariable('Notification') + `/api/NotificationSettings/${this.props.match.params.routeid}`
            this.didmountinitialload();
        }
    }

    loadSitePermitType = (siteId, selectedId) => {
        this.fetchData('GET', `${this.url4}/${siteId}`, null, null)
            .then(data => {
                this.setState({
                    sitePermitTypeJson: data,
                    SitePermitTypeId: selectedId
                })

            })
    }


    loadSideBarTables(submodulekey) {
        if (submodulekey) {
            this.fetchData('GET', getEnvVariable('Application') + `/api/SubModuleSideBar/All/${submodulekey}`, null, null)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        let tabls = data.map(a => ({ id: a.Id, name: a.TableName, value: a.Id }))
                        this.setState({
                            sideBarTablesRecords: tabls
                        })
                    }
                })
        }
    }

    loadRegionPermitType = (regionId, selectedId) => {
        this.fetchData('GET', `${this.url9}/${regionId}`, null, null)

            .then(data => {

                this.setState({
                    sitePermitTypeJson: data,
                    SitePermitTypeId: selectedId
                })
                //this.progressBar.complete();
            })
    }

    loadSubModulePermitType = (SubModuleKey, selectedId) => {
        this.fetchData('GET', `${this.url11}/${SubModuleKey}`, null, null)
            .then(data => {
                let notificationType = this.state.NotificationType
                let defaultrecord = _.find(data, { 'IsDefault': 'Y' });
                let id = defaultrecord && defaultrecord.Id && this.state.updateRecord == false ? defaultrecord.Id : this.state.SitePermitTypeId
                this.setState({
                    sitePermitTypeJson: data,
                    SitePermitTypeId: id,
                    showSitePermitType: (notificationType == 'EVENT_NOTIFICATION' || notificationType == 'BUSINESS_RULES_NOTIFICATION') ? true : false,
                    results: { ...this.state.results, SitePermitTypeId: id }
                })
            })
    }



    loadEventOn = (SubModuleKey, selectedKey) => {
        //added type
        this.fetchData('GET', this.url5, `domainkey=NOTIFICATION_ONEVENT&type=${SubModuleKey}`, null)
            .then(data => {
                let jsn = {};
                switch (SubModuleKey) {
                    case 'APPLICATIONS':
                        jsn = data.filter(x => x.Type && x.Type.indexOf('APPLICATIONS') !== -1);
                        break;
                    case 'INQUIRY':
                        jsn = data.filter(x => x.Type && x.Type.indexOf('INQUIRY') !== -1);
                        break;
                    case 'RECYCLERS':
                        jsn = data.filter(x => x.Type && x.Type.indexOf('RECYCLERS') !== -1);
                        break;
                    case 'OUTDOOR':
                        jsn = data.filter(x => x.Type && x.Type.indexOf('OUTDOOR') !== -1);
                        break;
                    case 'ENCROACHMENTS':
                        jsn = data.filter(x => x.Type && x.Type.indexOf('ENCROACHMENTS') !== -1);
                        break;
                    case 'VIOLATIONS':
                        jsn = data.filter(x => x.Type && x.Type.indexOf('VIOLATIONS') !== -1);
                        break;
                    case 'PERMITS':
                        jsn = data.filter(x => x.Type == 'Permits');
                        break;
                    case 'OWNERS':
                        jsn = data.filter(x => x.Type == 'Owners');
                        break;
                    case 'COLLISION':
                        jsn = data.filter(x => x.Type == 'COLLISION');
                        break;
                    case 'INVESTIGATION':
                        jsn = data.filter(x => x.Type == 'INVESTIGATION');
                        break;
                    default:
                        break;
                }
                let defaultrecord = selectedKey
                if (jsn && jsn.length > 0) {
                    defaultrecord = _.find(jsn, { 'IsDefault': 'Y' });
                    defaultrecord = defaultrecord && this.state.updateRecord == false ? defaultrecord.Key : selectedKey
                }

                this.setState({
                    notificationEventOnJson: jsn,
                    EventOn: defaultrecord,
                    results: { ...this.state.results, EventOn: defaultrecord }
                }, () => {
                    this.changeEventDetailProps(this.state.EventOn)
                })
            })
    }

    handleStatusChange = (event) => {
        const status = this.statusJson.transformProps ? this.statusJson.transformProps[event.target.checked] : event.target.checked;
        this.setState({
            Status: status,
            results: {
                ...this.state.results,
                "Status": status
            }
        })
    }

    // componentWillUnmount() {
    //     this.setState({ updateRecord: false })
    // }

    validate = () => {
        const { results } = this.state
        let error_name = true;
        // let error_description = true;
        let error_submodulekey = true;
        let error_siteID = false;
        let error_regionId = true;
        let error_Sitepermittype = false;
        let error_EventOn = true;
        if (results.NotificationType === "EVENT_NOTIFICATION") {
            if (results && results.Name) {
                error_name = false
            }
            // if (results && results.Description) {
            //     error_description = false
            // }
            if (results && results.SubModuleKey) {
                error_submodulekey = false
            }
            if (results.SubModuleKey !== 'OWNERS') {
                if (results && results.SiteID) {
                    error_siteID = false
                }
                // if (results && results.RegionId) {
                //     error_regionId = false
                // }
                if (results && results.SitePermitTypeId) {
                    error_Sitepermittype = false
                }
            } else {
                //Owners will not have region selection and permit type selction
                //error_regionId = false;
                error_Sitepermittype = false;
            }

            if (results && results.EventOn) {
                error_EventOn = false
            }
            if (error_name || error_submodulekey || error_Sitepermittype || error_EventOn) {
                this.setState({ error_name: error_name, error_submodulekey: error_submodulekey, error_Sitepermittype: error_Sitepermittype, error_EventOn: error_EventOn, helperText: "Field is required" }, () => {
                })
            }
            else {
                this.setState({ error_name: error_name, error_submodulekey: error_submodulekey, error_Sitepermittype: error_Sitepermittype, error_EventOn: error_EventOn });
                return true;
            }
        }
        else {
            if (results && results.Name) {
                error_name = false
            }
            // if (results && results.Description) {
            //     error_description = false
            // }
            if (results && results.SiteID) {
                error_siteID = false
            }

            if (error_name || error_siteID) {
                this.setState({ error_name: error_name, error_siteID: error_siteID, helperText: "Field is required" }, () => {
                })
            }
            else {
                this.setState({ error_name: error_name, error_siteID: error_siteID });
                return true;
            }
        }


    }
    handleBack = (e, closeconfirmation, stayInSamePage) => {
        let unsavedchanges = !(_.isEqual(this.state.results, this.state.checkresults))
        if (!closeconfirmation && !stayInSamePage && !this.state.duplicatebutton && this.state.iseditduplicate) {
            this.setState({ openconfirmation: true })
        }
        else if (stayInSamePage && !this.state.duplicatebutton && this.state.isduplicate) {
            this.setState({ openconfirmation: false })
        }
        else if (unsavedchanges && !closeconfirmation && !stayInSamePage) {
            this.setState({ openconfirmation: true })
        }
        else if (unsavedchanges && stayInSamePage) {
            this.setState({ openconfirmation: false })
        }
        else {
            this.props.onClose()
            let pathname = this.props.location.pathname
            if (this.props && this.props.match && this.props.match.params && this.props.match.params.Id) {
                this.props.match.params.Id = undefined
                pathname = pathname.substring(0, pathname.lastIndexOf("/"))
            }
            this.props.onClose()
            this.props.history.push(pathname)
        }
    }
    handleSave = (event, duplicaterecord) => {
        //this.progressBar.start()
        const { results } = this.state
        console.log(this.state.results);
        let validatestatus = this.validate();
        let finalresults = duplicaterecord ? { ...this.state.results, IsDuplicate: "Y" } : this.state.results
        if (validatestatus) {
            if (this.state.updateRecord && !duplicaterecord) {
                this.fetchData('PUT', this.url7, null, this.state.results)
                    .then(data => {
                        // this.props.onClose();
                        // this.progressBar.complete()
                        this.setState({
                            results: data,
                            checkresults: data,
                            edit: false,
                            editbutton: true,
                          //  duplicatebutton: true,

                        })
                    }).catch(errors => {
                        console.log(errors)
                        errors.then(err => {
                            if (err['Name']) {
                                this.setState({ error_name: err['Name'][0], helperText: err['Name'][0] });
                            }
                            if (err['Description']) {
                                this.setState({ error_description: err['Description'][0], helperText1: err['Description'][0] });
                            }
                        })
                    })
            }
            else {
                this.fetchData('POST', this.url8, null, finalresults)
                    .then(data => {
                        let pathname = this.props.location.pathname
                        if (duplicaterecord) {
                            let final = pathname.substring(0, pathname.lastIndexOf("/"))
                            pathname = final
                        }
                        else if (this.state.results && this.state.results.IsDuplicate == 'Y' && data && this.props && this.props.match && this.props.match.params && data.ID) {
                            this.props.match.params.Id = data.ID
                            let final = pathname.substring(0, pathname.lastIndexOf("/"))
                            pathname = final + '/' + this.props.match.params.Id
                        }
                        else if (data && this.props && this.props.match && this.props.match.params && data.ID) {
                            this.props.match.params.Id = data.ID
                            pathname = pathname + '/' + this.props.match.params.Id
                        }

                        this.props.history.push(pathname);
                        this.props.onClose();
                        //  this.progressBar.complete()
                        this.setState({
                            results: data,
                            checkresults: data,
                            edit: false,
                            editbutton: true,
                          //  duplicatebutton: true,
                            updateRecord: true,
                            disabled: true
                        }, () => {
                            this.props.history.push('/administration/notificationsettings/' + data.ID)
                        })
                    }).catch(errors => {
                        console.log(errors)
                        errors.then(err => {
                            if (err['Name']) {
                                this.setState({ error_name: err['Name'][0], helperText: err['Name'][0] });
                            }
                            if (err['Description']) {
                                this.setState({ error_description: err['Description'][0], helperText1: err['Description'][0] });
                            }
                        })
                    })
            }
        }
        else {
            this.props.snackbarMessage('Please verify information', 'error')

        }

    }

    fetchData(requestType, url, queryParam, body) {
        const queryStr = queryParam != null ? `${url}?${queryParam}` : `${url}`
        if (requestType == "GET") {
            return GetData(queryStr);
        }
        else if (requestType == "POST") {
            return PostData(queryStr, body);
        }
        else if (requestType == "PUT")
            return PutData(queryStr, body);

    }

    handleEdit = (e, duplicate) => {
        if (duplicate) {
            this.setState({ edit: true, editbutton: false, duplicatebutton: false, iseditduplicate: true })
        }
        else {
            this.setState({ edit: true, editbutton: false, duplicatebutton: false })
        }

    }



    handleChange = (event, newValue) => {
        let hideval = this.state.hideeventstabs ? 2 : this.state.hideeventrecipients ? 1 : 3
        if (newValue == hideval) {
            this.setState({ value: newValue }, () => this.callRecipients())
        }
        else {
            this.setState({ value: newValue })
        }

    };

    render() {
        const { compmap, onFullScreen, onExitFullScreen, fullscreen, exitfullscreen, onNextItemClick, onPrevItemClick } = this.props;
        const { editbutton, duplicatebutton, iseditduplicate, updateRecord, error_name, error_description, error_submodulekey, error_Sitepermittype, error_regionId, error_EventOn, helperText, helperText1, disabled, value } = this.state;
        let edit = updateRecord ? this.state.edit : true
        return (
            <div style={{ height: window.innerHeight - 50 }}>
                {!updateRecord && <FormHeader title="Notifications" handleSave={this.handleSave} handleEdit={this.handleEdit} editbutton={updateRecord ? editbutton : false} savebutton={edit ? true : false} breadcrumbs={addbreadcrumbs} backbutton={true} handleBack={this.handleBack} onFullScreen={onFullScreen} onExitFullScreen={onExitFullScreen} fullscreen={fullscreen} exitfullscreen={exitfullscreen} />}
                {updateRecord && <FormHeader title="Notifications" breadcrumbs={editbreadcrumbs} backbutton={true} handleBack={this.handleBack} dataItem={this.props.dataItem} onFullScreen={onFullScreen} onExitFullScreen={onExitFullScreen} fullscreen={fullscreen} exitfullscreen={exitfullscreen} navigateup={true} navigatedown={true} onNextItemClick={onNextItemClick} onPrevItemClick={onPrevItemClick} rowrenderedslide={this.props.rowrenderedslide} />}

                {updateRecord && <AppBar position="static" color="white">
                    <Tabs
                        value={value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="View/Edit Notification" {...a11yProps(0)} />
                        {this.state.progresscomplete && this.state.showEventDetailsButton && !this.state.showSchedulerButton && !this.state.hideeventstabs && !this.state.hideeventrecipients && <Tab label="Event Details" {...a11yProps(1)} />}
                        {this.state.progresscomplete && this.state.showSchedulerButton && <Tab label="Schedule" {...a11yProps(2)} />}
                        {this.state.progresscomplete && !this.state.hideeventrecipients && <Tab label="Recipients" {...a11yProps(3)} />}
                        {this.state.progresscomplete && <Tab label="Notification Template" {...a11yProps(4)} />}
                        {/* {this.state.progresscomplete && this.state.showEventDetailsButton && <Tab label="Event Details" {...a11yProps(4)} />} */}
                    </Tabs>
                </AppBar>}
                {this.state.openconfirmation && <Confirmation open={this.state.openconfirmation} handleBack={this.handleBack} />}
                <TabPanel value={value} index={0}>
                    {updateRecord && <FormHeader handleSave={this.handleSave} handleEdit={this.handleEdit} editbutton={updateRecord ? editbutton : false} duplicatebutton={updateRecord ? duplicatebutton : false} iseditduplicate={iseditduplicate} savebutton={edit ? true : false} designprops={true} hasclearfix={true} />}
                    <div className="col-12 m-4">
                        <div className="row col pt-1 ">
                            <div className="col-md-12 pb-2 pl-1">
                                <CFormTextBox value={this.state.Name} name="Name" label="Notification Name"
                                    handleFieldChange={this.handleFieldChange} edit={edit} required={true} error={error_name} helperText={error_name && helperText} />
                            </div>
                            <div className="col-md-12 pb-2 pl-1">
                                <CFormTextBox value={this.state.Description} name="Description" label="Description" error={error_description} helperText={error_description && helperText1} multiline={true} rows="4"
                                    handleFieldChange={this.handleFieldChange} edit={edit} />
                            </div>

                        </div>
                        <div className="row col">
                            <div className="col-md-12 pb-2 pl-1">
                                <CFormDropDown value={this.state.NotificationType} disabled={this.state.disabled} dropdownvalues={this.state.notificationTypeJson}
                                    name="NotificationType" label="Notification Type" transformProps={transformPropsWithKey} handleFieldChange={this.handleFieldChange} edit={edit} />
                            </div>
                        </div>
                        <div className="row col">
                            {this.state.showSubModule ? (<div className="col-md-12 pb-2 pl-1"><CFormDropDown value={this.state.SubModuleKey} disabled={this.state.disabled} dropdownvalues={this.state.submoduleJson}
                                name="SubModuleKey" label="Sub Module" transformProps={submoduletransformProps} handleFieldChange={this.handleFieldChange} edit={edit} required={true} error={error_submodulekey} helperText={error_submodulekey && helperText} />
                            </div>) : ("")}


                            {this.state.showSitePermitType ? (<div className="col-md-12 pb-2 pl-1"><CFormDropDown value={this.state.SitePermitTypeId} disabled={this.state.disabled} dropdownvalues={this.state.sitePermitTypeJson}
                                name="SitePermitTypeId" label="Application Type" transformProps={transformProps} handleFieldChange={this.handleFieldChange} edit={edit} required={false} error={error_Sitepermittype} helperText={error_Sitepermittype && helperText} />
                            </div>) : ("")}
                        </div>
                        <div className="row col">
                            {this.state.showEventOn ?
                                (<div className={updateRecord ? "col-md-12 pb-2 pl-1" : "col-md-12 pb-2 pl-1"}>
                                    <CFormDropDown value={this.state.EventOn} disabled={this.state.disabled} dropdownvalues={this.state.notificationEventOnJson}
                                        name="EventOn" label="Event On" transformProps={transformPropsWithKey} handleFieldChange={this.handleFieldChange} edit={edit} required={true} error={error_EventOn} helperText={error_EventOn && helperText} />
                                </div>) : ("")}
                        </div>
                        <div className="row col">
                            <div className="col-md-4 pb-2 pl-1">
                                {this.state.showEventOn && (this.state.EventOn === 'WORKFLOW_Due_Date' || this.state.EventOn === 'CONSTRUCTION_WINDOW') && <CFormTextBox value={this.state.BeforeDueDays}
                                    name="BeforeDueDays" label="Before Due Days" xtype="number" InputProps={{ min: 1 }} handleFieldChange={this.handleFieldChange} edit={edit} />}
                            </div>
                        </div>
                        <div className="col pl-1">
                            {this.state.updateRecord && <CFormSwitch {...this.statusJson} handleFieldChange={this.handleStatusChange} value={this.state.Status} edit={edit} />}
                        </div>
                        <div className="row col pt-1">
                        </div>
                        {updateRecord && <div className="row pt-3 pl-1">
                            <div className="col-sm-6 pb-3" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D' }}>Created By : </span> {this.state.results.CreatedByName}</div>
                            <div className="col-sm-6 pb-3" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D' }}>Created On : </span> {moment(this.state.results.CreatedOn).format('MM/DD/YYYY hh:mm:ss A')}</div>
                            {this.state.results.UpdatedBy === null ? ('') : (<div className="col-sm-6 pb-3" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D' }}>Updated By : </span> {this.state.results.UpdatedByName}</div>)}
                            {this.state.results.UpdatedOn === null ? ('') : (<div className="col-sm-6 pb-3" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D' }}>Updated On : </span> {moment(this.state.results.UpdatedOn).format('MM/DD/YYYY hh:mm:ss A')}</div>)}
                        </div>}
                    </div>
                </TabPanel>
                <TabPanel value={value} index={(this.state.hideeventstabs || this.state.showSchedulerButton || this.state.hideeventrecipients) ? 5 : 1}>
                    <div className="col-md-12 m-4">
                        {this.state.showEventDetailsButton && this.state.updateRecord && <NotificationEvents compmap={compmap} {...this.eventDetailsPopup} edit={edit} eventOn={this.state.EventOn} extraProps={{ SitePermitTypeId: this.state.SitePermitTypeId }} dataItem={this.props.dataItem} />}
                    </div>
                    {/* <div className="col-md-12">
                        {this.state.showSchedulerButton && this.state.updateRecord && <Scheduler compmap={compmap} {...this.schedulerPopup} dataItem={this.props.dataItem} edit={edit} />}
                    </div> */}
                </TabPanel>
                <TabPanel value={value} index={this.state.hideeventstabs ? 5 : this.state.showSchedulerButton ? 1 : 2}>
                    {/* <div className="col-md-12">
                        {this.state.showEventDetailsButton && this.state.updateRecord && <NotificationEvents compmap={compmap} {...this.eventDetailsPopup} edit={edit} eventOn={this.state.EventOn} extraProps={{ SitePermitTypeId: this.state.SitePermitTypeId }} dataItem={this.props.dataItem} />}
                    </div> */}
                    <div className="col-md-12">
                        {this.state.showSchedulerButton && this.state.updateRecord && <Scheduler handleFieldValue={this.handleFieldChange} compmap={compmap} {...this.schedulerPopup} dataItem={this.props.dataItem} edit={edit} />}
                    </div>
                </TabPanel>
                <TabPanel value={value} index={this.state.hideeventstabs ? 1 : this.state.showSchedulerButton ? 2 : 2}>
                    <div className="col-12 m-4">
                        {this.state.updateRecord && <CMulti compmap={compmap} {...this.recipientsPopup} edit={edit} dataItem={this.props.dataItem} notificationtype={this.state.NotificationType} />}
                    </div>
                </TabPanel>
                <TabPanel value={value} index={this.state.hideeventstabs ? 2 : this.state.hideeventrecipients ? 1 : 3}>
                    <div className="col-12 m-4">
                        {this.state.updateRecord && <NsTemplate compmap={compmap} {...this.eventTemplatePopup}
                            extraProps={{
                                SubModuleKey: this.state.SubModuleKey,
                                SitePermitTypeId: this.state.SitePermitTypeId,
                                NotificationType: this.state.NotificationType,
                                sideBarTablesRecords: this.state.sideBarTablesRecords || [],
                                EventOn: this.state.EventOn,
                                hideexternal: !(this.state.isapplicantcheck)
                            }} edit={edit}
                            dataItem={this.props.dataItem} />}
                    </div>
                </TabPanel>
                {/* <TabPanel value={value} index={4}>
                    <div className="col-md-12">
                        {this.state.showEventDetailsButton && this.state.updateRecord && <NotificationEvents compmap={compmap} {...this.eventDetailsPopup} edit={edit} eventOn={this.state.EventOn} extraProps={{ SitePermitType: this.state.SitePermitType }} dataItem={this.props.dataItem} />}
                    </div>
                    <div className="col-md-12">
                        {this.state.showSchedulerButton && this.state.updateRecord && <CPopUp compmap={compmap} {...this.schedulerPopup} edit={edit} />}
                    </div>
                </TabPanel> */}
            </div>
            // </div>

        )
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

NS = connect(null, mapActions)(NS)
//export default withStyles(styles)(withRouter(NS))
export default withRouter(NS)