import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { GetData, PostData, PutData } from '../../core/controls/helpers/requests';
import { createRequest, requestApi } from '../../core/controls/helpers/rest';
import { snackbarMessage } from '../../core/actions';
import MultipleDropdown from '../../core/controls/components/checkautocomplete';
import { getEnvVariable } from '../environmentalList';
import CFormCheckBox from '../../core/modules/form/cformcheckbox';
import CFormDropDown from '../../core/modules/form/cformdropdown';
import FormHeader from '../../core/modules/form/cformheader';
import CFormSwitch from '../../core/modules/form/cformswitch';
import CFormTextBox from '../../core/modules/form/cformtextbox';
import Confirmation from '../../core/modules/form/confirmationdialog';
import CText from '../../core/modules/form/ctext';
import { globalvalidate } from '../../core/modules/form/validations';
import Template from '../../core/modules/template/template';
import nsComponents from '../../json/notificationsettings/nsComponents';
import ConfirmationPopup from "../../core/controls/components/confirmationPopup";
const _ = require('lodash');


const transformProps = {
    "value": "Id",
    "name": "Name",
    "key": "Id"
}
const submoduletransformProps = {
    "value": "Key",
    "name": "DisplayName",
    "key": "Key"
}
const transformPropsWithKey = {
    "value": "Key",
    "name": "Name",
    "key": "Key"
}

//Updated By: vijaya. comments : Added Breadcrumbs.
const addbreadcrumbs = [
    { "link": "Add Notice Type", "materialicon": "tab", className: 'eps-breadcrumb-item ', class: 'text-light' }
]

const editbreadcrumbs = [
    { "link": "Notice Type", appendval: 'Name', "materialicon": "tab", className: 'eps-breadcrumb-item ', class: 'text-light' }
]


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

class Notices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sitesJson: [],
            regionsJson: [],
            submoduleJson: [],
            sitePermitTypeJson: [],
            SubModuleKey: null,
            SiteID: null,
            RegionId: null,
            SitePermitType: 0,
            results: { Status: 'I', TemplateId: 0 },
            Name: '',
            Description: '',
            Status: 'I',
            showSitePermitType: this.props.dataItem ? (!this.props.dataItem.Id ? false : true) : false,
            showTemplate: false,
            TemplateId: 0,
            edit: false,
            editbutton: true,
            duplicatebutton: true,
            updateRecord: this.props.dataItem ? (!this.props.dataItem.Id ? false : true) : false,
            error_NAME: '',
            error_DESCRIPTION: '',
            error_SUBMODULEKEY: '',
            error_SITEPERMITTYPE: '',
            error_SITEID: '',
            error_REGIONID: '',
            error_NAME_helpertext: '',
            error_DESCRIPTION_helpertext: '',
            error_SUBMODULEKEY_helpertext: '',
            error_SITEPERMITTYPE_helpertext: '',
            error_SITEID_helpertext: '',
            error_REGIONID_helpertext: '',
            CanEditNoticeDate: "N",
            CanEditPastNoticeDate: "N",
            CanEditFutureNoticeDate: "N",
            CanEditPastNoticeDate: "N",
            disabled: true,
            submoduleselectedtables: '',
            submoduleselectedtablesresults: [],
            sideBarTablesRecords: [],
            SideBarTables: "",
            isduplicate: false,
            value: 0,
            checkresults: { Status: 'I', TemplateId: 0 },
            openconfirmation: false,
            compvalidation: null,
            openconfirmationpopup:false,
            temp:"",
            tempname:""
        }//demo
        this.nsComponentsArray = { ...nsComponents };
        this.sitesPopup = this.nsComponentsArray.components.find(x => x.key == "nssites");
        this.statusJson = this.nsComponentsArray.components.find(x => x.key == "nsstatus").options;
        this.url2 = getEnvVariable('MasterAdmin')+`/api/module/`+getEnvVariable('ModuleKey')+`/submodule/All/Active/IsConfigurable`
        this.url3 = getEnvVariable('Admin') + `/api/Sites/` + getEnvVariable('ModuleKey') + `/All/Active`
        this.url4 = getEnvVariable('AdminConfig') + `/api/sitepermittypes/Sites/Active`
        this.url6 = this.props.dataItem ? getEnvVariable('Admin') + `/api/NoticeTypes/${this.props.dataItem.Id}` : getEnvVariable('Admin') + `/api/NoticeTypes/${this.props.match.params.routeid}`
        this.url7 = getEnvVariable('Admin') + `/api/NoticeTypes/Update`
        this.url8 = getEnvVariable('Admin') + `/api/NoticeTypes/Add`
        this.TemplateForm = React.createRef()
        this.transformProps = {
            "Y": true,
            "N": false,
            true: "Y",
            false: "N"
        }
        this.url9 = getEnvVariable('AdminConfig') + `/api/SitePermitTypes/Regions/Active`

        this.url10 = getEnvVariable('Admin') + `/api/Regions/` + getEnvVariable('ModuleKey') + `/All/Active`
        this.url11 = getEnvVariable('AdminConfig') + `/api/SitePermitTypes/SubModuleKey/Active`
        this.sidebartablesurl = getEnvVariable('Application') + `/api/SubModuleSideBar/All`
    }

    handleFieldChange = (event) => {
        if(event.target.name == "SubModuleKey"){
            this.setState({temp : event.target.value, tempname:event.target.name, openconfirmationpopup : true} )
        }
        else{
        let error, helperText
        let targetname = event.target.name;
        let nameinuppercase = targetname.toUpperCase()
        if (this.state.compvalidation && this.state.compvalidation[nameinuppercase]) {
            const err = globalvalidate({ [targetname]: this.state.compvalidation[nameinuppercase] }, { [targetname]: event.target.value })

            if (err && err[targetname]) {
                error = err[targetname].error
                helperText = err[targetname].errortext
                let errorname = "error_" + nameinuppercase
                let errorhelpertext = "error_" + nameinuppercase + "_helpertext"
                this.setState({ [errorname]: error, [errorhelpertext]: helperText });

            }
        }
        let resetSitepermittype = targetname == 'SubModuleKey' && targetname != 'SitePermitType' && this.state.SubModuleKey != event.target.value ? true : false
        this.setState({
            SitePermitType: !resetSitepermittype ? this.state.SitePermitType : null,
            [event.target.name]: event.target.value,
            results: {
                ...this.state.results,
                SitePermitType: !resetSitepermittype ? this.state.SitePermitType : null,
                [event.target.name]: event.target.value
            }
        }, () => {
            if (targetname == 'SiteID')
                this.loadSitePermitType(this.state.SiteID, 0);
            if (targetname == 'SubModuleKey') {
                this.loadSubModulePermitType(this.state.SubModuleKey, 0);
                this.loadSideBarTables(this.state.SubModuleKey);
            }
            if (targetname == 'SitePermitType')
                this.getBusinessRules(event.target.value)
        })
    }
    }

    getBusinessRules = (value, sidebarItems) => {
        let sideUrl = '';
        sidebarItems && (sidebarItems !== '') && sidebarItems.split(',') && sidebarItems.split(',').length > 0 && sidebarItems.split(',').map(each => {
            sideUrl = sideUrl + '&SideBarItems=' + (this.state.sideBarTablesRecords.find(item => (item.value).toString() === each)).name
        })
        const read = [
            {
                url: getEnvVariable('Sidebar') + `/api/BusinessRules/Builder/Template?SitePermitTypeId=${value}${sideUrl}`,
                type: 'get'
            },
        ]
        Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(response => {
            let [fields] = response
            this.setState({
                showTemplate: true,
                fields: fields.map(res => { res.name = res.name.replace(/\s/g,''); res.label = res.label.replace(/\s/g,''); return res })
            })
        })
    }

    getValidations = () => {
        const read = [
            {
                "url": getEnvVariable('Admin') + `/api/ProjectMessages/RulesByName?name=admin.noticetypes`,
                type: 'get',
                transformProps: {
                    name: 'Key',
                    subfieldname: `${getEnvVariable('ModuleKey')}ADMIN.NOTICETYPES_`
                }

            },
        ]
        Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(response => {
            let values = response
            let xvalidations = {}
            values && values.length > 0 && values.forEach((each, index) => {
                const transformProps = {
                    name: 'Key',
                    subfieldname: `${getEnvVariable('ModuleKey')}ADMIN.NOTICETYPES_`
                }
                const { subfieldname, addsubfieldname } = transformProps ? transformProps : {}
                const subfieldnamelength = subfieldname ? subfieldname.length : 0
                each && each.forEach(each => {
                    let validationname = each[transformProps.name].slice(subfieldnamelength)
                    if (addsubfieldname) validationname = addsubfieldname + validationname
                    if (Array.isArray(xvalidations[validationname])) {
                        const len = xvalidations[validationname].length
                        const lastvalidation = xvalidations[validationname][len - 1]
                        if (each.Order > lastvalidation.Order) xvalidations[validationname].push(each)
                        else xvalidations[validationname].unshift(each)
                    }
                    else {
                        xvalidations[validationname] = [each]
                    }
                })
                this.setState({
                    compvalidation: xvalidations
                })
                console.log(xvalidations);
            })
        })
    }
    componentDidMount() {
        this.getValidations();
        this.didmountinitialload();
    }


    didmountinitialload() {
        const { updateRecord } = this.state;
        let read = [
            {
                url: this.url2,
                type: 'get'
            },
            {
                url: this.url3,
                type: 'get',
            },
            {
                url: this.url10,
                type: 'get'
            },
        ];
        if (this.state.updateRecord) {
            read.push({
                url: this.url6,
                type: 'get'
            })
        }
        Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(res => {
            const [submoduleJson, sitesJson, regionsJson, data] = res;
            if (data) {
                this.setState({
                    submoduleJson: submoduleJson,
                    sitesJson: sitesJson,
                    regionsJson: regionsJson,
                    Name: data.Name,
                    Description: data.Description,
                    SubModuleKey: data.SubModuleKey,
                    SiteID: data.SiteId,
                    RegionId: data.RegionId,
                    SitePermitType: data.SitePermitType,
                    Status: data.Status,
                    TemplateId: data.TemplateId,
                    CanEditNoticeDate: data.CanEditNoticeDate,
                    CanEditPastNoticeDate: data.CanEditPastNoticeDate,
                    CanEditCurrentNoticeDate: data.CanEditCurrentNoticeDate,
                    CanEditFutureNoticeDate: data.CanEditFutureNoticeDate,
                    results: data,
                    checkresults: data,
                    disabled: data.CanEditNoticeDate == "Y" ? false : true,
                    showTemplate: data.SitePermitType ? true : false,
                    SideBarTables: data.SideBarTables ? data.SideBarTables : ''
                }, () => {
                    if (data.SubModuleKey != "") {
                        this.loadSubModulePermitType(data.SubModuleKey, data.SitePermitType);
                        this.loadSideBarTables(data.SubModuleKey);
                    }
                })
            }
            else this.setState({
                submoduleJson: submoduleJson,
                sitesJson: sitesJson,
                regionsJson: regionsJson,
            })
        })
    }

    loadSitePermitType = (siteId, selectedId) => {
        this.fetchData('GET', `${this.url4}/${siteId}`, null, null)
            .then(data => {
                this.setState({
                    sitePermitTypeJson: data,
                    SitePermitType: selectedId,
                    showSitePermitType: true,
                })
            })
    }

    loadRegionPermitType = (regionId, selectedId) => {
        this.fetchData('GET', `${this.url9}/${regionId}`, null, null)
            .then(data => {
                this.setState({
                    sitePermitTypeJson: data,
                    SitePermitType: selectedId,
                    showSitePermitType: true,
                })

            })
    }

    loadSubModulePermitType = (SubModuleKey, selectedId) => {
        this.fetchData('GET', `${this.url11}/${SubModuleKey}`, null, null)
            .then(data => {
                this.setState({
                    sitePermitTypeJson: data,
                    SitePermitType: selectedId,
                    showSitePermitType: true,
                })

            })
    }

    loadSideBarTables(submodulekey) {
        if (submodulekey) {
            this.fetchData('GET', `${this.sidebartablesurl}/${submodulekey}`, null, null)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        let tabls = data.map(a => ({ id: a.Id, name: a.TableName, value: a.Id }))
                        this.setState({
                            sideBarTablesRecords: tabls
                        }, () => {
                            if (this.state.SitePermitType) {
                                this.getBusinessRules(this.state.SitePermitType, this.state.SideBarTables);
                                this.setSideBarTablesData(this.state.SideBarTables)
                            }
                        })
                    }
                })
        }
    }
    setSideBarTablesData(sidebarItems) {
        if (sidebarItems && (sidebarItems !== '') && sidebarItems.split(',') && sidebarItems.split(',').length > 0) {
            let resultdatasplit = sidebarItems.split(",")
            let selectedRecords = this.state.sideBarTablesRecords.filter(a => resultdatasplit.includes(a.id.toString()))
            this.setState({ submoduleselectedtablesresults: selectedRecords})
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dataItem != this.props.dataItem) {
            this.url6 = this.props.dataItem ? getEnvVariable('Admin') + `/api/NoticeTypes/${this.props.dataItem.Id}` : getEnvVariable('Admin') + `/api/NoticeTypes/${this.props.match.params.routeid}`
            this.didmountinitialload();
        }
    }
    handleEditNoticeDate = (event) => {
        const results = this.state.results
        const value = this.transformProps ? this.transformProps[event.target.checked] : event.target.value;
        let name = event.target.name;
        results[name] = value
        if (this.state.results.CanEditNoticeDate == 'N') {
            results['CanEditPastNoticeDate'] = 'N'
            results['CanEditCurrentNoticeDate'] = 'N'
            results['CanEditFutureNoticeDate'] = 'N'
        }
        this.setState({
            [event.target.name]: value,
            results: results,
            disabled: this.state.results.CanEditNoticeDate == 'Y' ? false : true,
            CanEditPastNoticeDate: this.state.results.CanEditNoticeDate == 'N' ? 'N' : results.CanEditPastNoticeDate,
            CanEditCurrentNoticeDate: this.state.results.CanEditNoticeDate == 'N' ? 'N' : results.CanEditCurrentNoticeDate,
            CanEditFutureNoticeDate: this.state.results.CanEditNoticeDate == 'N' ? 'N' : results.CanEditFutureNoticeDate
        }, () => {
            //this.results.CanEditNoticeDate = value
        })
    }

    validate = () => {
        const { results } = this.state
        let error_NAME = true;
        //let error_DESCRIPTION = true;
        let error_SUBMODULEKEY = true;
        let error_SITEID = false;
        //let error_REGIONID = true;
        let error_SITEPERMITTYPE = true;

        if (results && results.Name) {
            error_NAME = false
        }
        // if (results && results.Description) {
        //     error_DESCRIPTION = false
        // }
        if (results && results.SubModuleKey) {
            error_SUBMODULEKEY = false
        }
        if (results && (results.SiteId || results.SiteID)) {
            error_SITEID = false
        }
        // if (results && (results.RegionId || results.RegionID)) {
        //     error_REGIONID = false
        // }
        //if (results && (results.RegionId || results.RegionID)) {
        if (results && results.SitePermitType) {
            error_SITEPERMITTYPE = false
        }
        // }

        if (error_NAME) {//|| error_REGIONID
            this.setState({ error_NAME: error_NAME, error_NAME_helpertext: "Field is required" }) //error_REGIONID: error_REGIONID,
        }
        if (error_SUBMODULEKEY) {//|| error_REGIONID
            this.setState({ error_SUBMODULEKEY: error_SUBMODULEKEY, error_SUBMODULEKEY_helpertext: "Field is required" }) //error_REGIONID: error_REGIONID,
        }
        if (error_SITEPERMITTYPE) {//|| error_REGIONID
            this.setState({ error_SITEPERMITTYPE: error_SITEPERMITTYPE, error_SITEPERMITTYPE_helpertext: "Field is required" }) //error_REGIONID: error_REGIONID,
        }
        else {
            this.setState({ error_NAME: error_NAME, error_SUBMODULEKEY: error_SUBMODULEKEY, error_SITEPERMITTYPE: error_SITEPERMITTYPE }) //error_REGIONID: error_REGIONID,
            return true
        }
    }


    //Updated by vijaya: Added snack bar to display the messages.
    handleSaveWithTemplate = (event) => {
        var resultsData = this.state.results;
        if (this.state.isduplicate) {
            this.setState({
                results: { ...this.state.results, IsDuplicate: "Y" },
                checkresults: { ...this.state.results, IsDuplicate: "Y" },
            })
        }
        let validatestatus = this.validate();
        if (validatestatus) {
            if (this.state.value == 0) {
                this.handleSave();
            } else {
                if (this.TemplateForm.current) {
                    this.TemplateForm.current.saveTemplate();
                }
            }
        } else {
            this.props.snackbarMessage('Invalid Data, Cannot be saved', 'error')
        }
    }

    handleFormSave = (event) => {
        if (this.state.isduplicate) {
            this.setState({
                results: { ...this.state.results, IsDuplicate: "Y" }
            }, () => this.handleSave())
        }
        else {
            this.handleSave()
        }
    }

    //Updated by vijaya: Added snack bar to display the messages.
    handleSave = (event) => {
        var resultsData = this.state.results;

        resultsData.canEditNoticeDate = this.state.canEditNoticeDate
        let validatestatus = this.validate();
        if (validatestatus) {

            if (this.state.updateRecord && !this.state.isduplicate) {
                this.fetchData('PUT', this.url7, null, this.state.results)
                    .then(data => {
                        // this.props.onClose();

                        this.setState({
                            checkresults: data,
                            results: data,
                            edit: false,
                            editbutton: true,
                            duplicatebutton: true
                        })
                    }).catch(errors => {
                        errors.then(err => {
                            errors.then(err => {
                                if (err['Name']) {
                                    this.setState({ error_NAME: err['Name'][0], error_NAME_helpertext: err['Name'][0] });
                                }
                                if (err['NoticeName']) {
                                    this.setState({ error_NAME: err['NoticeName'][0], error_NAME_helpertext: err['NoticeName'][0] });
                                }
                                if (err['Description']) {
                                    this.setState({ error_DESCRIPTION: err['Description'][0], error_DESCRIPTION_helpertext: err['Description'][0] });
                                }
                            })
                        })
                    })
            }
            else {
                this.fetchData('POST', this.url8, null, this.state.results)
                    .then(data => {

                        //  this.props.onClose();
                        let pathname = this.props.location.pathname
                        if (this.state.results && this.state.results.IsDuplicate == 'Y' && data && this.props && this.props.match && this.props.match.params && data.Id) {
                            this.props.match.params.Id = data.Id
                            let final = pathname.substring(0, pathname.lastIndexOf("/"))
                            pathname = final + '/' + this.props.match.params.Id
                        }
                        else if (data && this.props && this.props.match && this.props.match.params && data.Id) {
                            this.props.match.params.Id = data.Id
                            pathname = pathname + '/' + this.props.match.params.Id
                        }
                        this.props.onClose();
                        this.props.history.push(pathname);
                        this.setState({
                            checkresults: data,
                            results: data,
                            edit: false,
                            editbutton: true,
                            updateRecord: true,
                            duplicatebutton: true
                        }, () => {
                            this.props.onClose();
                        })
                    }).catch(errors => {
                        errors.then(err => {
                            if (err['Name']) {
                                this.setState({ error_NAME: err['Name'][0], error_NAME_helpertext: err['Name'][0] });
                            }
                            if (err['NoticeName']) {
                                this.setState({ error_NAME: err['NoticeName'][0], error_NAME_helpertext: err['NoticeName'][0] });
                            }
                            if (err['Description']) {
                                this.setState({ error_DESCRIPTION: err['Description'][0], error_DESCRIPTION_helpertext: err['Description'][0] });
                            }
                        })
                    })
            }
        }
    }

    handleStatusChange = (event) => {
        const status = this.statusJson.transformProps ? this.statusJson.transformProps[event.target.checked] : event.target.checked;
        this.setState({
            Status: status,
            results: {
                ...this.state.results,
                "Status": status
            }
        })
    }

    handleTemplateId = (data) => {
        this.setState({
            TemplateId: data.id,
            results: {
                ...this.state.results,
                "TemplateId": data.id
            }
        }, () => { this.handleSave(); })
    }

    fetchData(requestType, url, queryParam, body) {
        const queryStr = queryParam != null ? `${url}?${queryParam}` : `${url}`

        if (requestType == "GET") {
            return GetData(queryStr);
        }
        else if (requestType == "POST") {
            return PostData(queryStr, body);
        }
        else if (requestType == "PUT")
            return PutData(queryStr, body);
    }

    handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ opensnackbar: false });
    };
    handleBack = (e, closeconfirmation, stayInSamePage) => {
        let unsavedchanges = !(_.isEqual(this.state.results, this.state.checkresults))
        if (!closeconfirmation && !stayInSamePage && !this.state.duplicatebutton && this.state.isduplicate) {
            this.setState({ openconfirmation: true })
        }
        else if (stayInSamePage && !this.state.duplicatebutton && this.state.isduplicate) {
            this.setState({ openconfirmation: false })
        }
        else if (unsavedchanges && !closeconfirmation && !stayInSamePage) {
            this.setState({ openconfirmation: true })
        }
        else if (unsavedchanges && stayInSamePage) {
            this.setState({ openconfirmation: false })
        }
        else {
            this.props.onClose()
            let pathname = this.props.location.pathname
            if (this.props && this.props.match && this.props.match.params && this.props.match.params.Id) {
                this.props.match.params.Id = undefined
                pathname = pathname.substring(0, pathname.lastIndexOf("/"))
            }
            this.props.onClose()
            this.props.history.push(pathname)
        }
    }
    handleEdit = (e, duplicate) => {
        this.setState({ edit: true, editbutton: false, duplicatebutton: false, duplicatebutton: false, isduplicate: duplicate ? true : false })
    }

    handlemultidropdown = (tags) => {
        let resultdata = tags.target.value
        let resultdatasplit = resultdata.split(",")
        let selectedRecords = this.state.sideBarTablesRecords.filter(a => resultdatasplit.includes(a.id.toString()))
        this.setState({
            SideBarTables: resultdata,
            submoduleselectedtables: resultdata,
            submoduleselectedtablesresults: selectedRecords,
            results: {
                ...this.state.results,
                [tags.target.name]: tags.target.value
            }
        }, () => {
            this.getBusinessRules(this.state.SitePermitType, resultdata)
        })
    }

    handleconfirmation = () => {
        let error, helperText
        let targetname = this.state.tempname;
        let nameinuppercase = targetname.toUpperCase()
        if (this.state.compvalidation && this.state.compvalidation[nameinuppercase]) {
            const err = globalvalidate({ [targetname]: this.state.compvalidation[nameinuppercase] }, { [targetname]: this.state.temp })

            if (err && err[targetname]) {
                error = err[targetname].error
                helperText = err[targetname].errortext
                let errorname = "error_" + nameinuppercase
                let errorhelpertext = "error_" + nameinuppercase + "_helpertext"
                this.setState({ [errorname]: error, [errorhelpertext]: helperText });

            }
        }
        let resetSitepermittype = targetname == 'SubModuleKey' && targetname != 'SitePermitType' && this.state.SubModuleKey != this.state.temp ? true : false
        this.setState({
            SitePermitType: !resetSitepermittype ? this.state.SitePermitType : null,
            [this.state.tempname]: this.state.temp,openconfirmationpopup : false,
            results: {
                ...this.state.results,
                SitePermitType: !resetSitepermittype ? this.state.SitePermitType : null,
                [this.state.tempname]: this.state.temp
            }
        }, () => {
            if (targetname == 'SiteID')
                this.loadSitePermitType(this.state.SiteID, 0);
            if (targetname == 'SubModuleKey') {
                this.loadSubModulePermitType(this.state.SubModuleKey, 0);
                this.loadSideBarTables(this.state.SubModuleKey);
            }
        })
    }

    handleclose = () => {
        this.setState({openconfirmationpopup : false, temp : null})
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue })
    };

    render() {
        const { compmap, onFullScreen, onExitFullScreen, fullscreen, exitfullscreen, onNextItemClick, onPrevItemClick } = this.props;
        const { errors, opensnackbar, updateRecord, snackbarmessage, snackbarmessageType, editbutton, duplicatebutton, results, error_NAME, error_DESCRIPTION, error_SUBMODULEKEY, error_SITEPERMITTYPE, error_REGIONID, canEditNoticeDate, fields, submoduleselectedtables, submoduleselectedtablesresults, SideBarTables, sideBarTablesRecords, value, error_NAME_helpertext, error_DESCRIPTION_helpertext, error_SUBMODULEKEY_helpertext, error_SITEPERMITTYPE_helpertext, error_SITEID_helpertext, error_REGIONID_helpertext, } = this.state
        let edit = updateRecord ? this.state.edit : true;
        let multitransformProps = {
            transformProps: {
                label: "name",
                value: "id"
            }
        }
        return (
            <div>
                {!updateRecord && <FormHeader title="Entrance Permit - Notices" handleSave={this.handleSave} handleEdit={this.handleEdit} editbutton={updateRecord ? editbutton : false} savebutton={updateRecord && editbutton === true ? false : true} breadcrumbs={addbreadcrumbs} backbutton={true} handleBack={this.handleBack} onFullScreen={onFullScreen} onExitFullScreen={onExitFullScreen} fullscreen={fullscreen} exitfullscreen={exitfullscreen} />}
                {updateRecord && <FormHeader title="Entrance Permit - Notices" breadcrumbs={editbreadcrumbs} backbutton={true} handleBack={this.handleBack} dataItem={this.props.dataItem} onFullScreen={onFullScreen} onExitFullScreen={onExitFullScreen} fullscreen={fullscreen} exitfullscreen={exitfullscreen} navigateup={true} navigatedown={true} onNextItemClick={onNextItemClick} onPrevItemClick={onPrevItemClick} rowrenderedslide={this.props.rowrenderedslide} />}
                {updateRecord && <AppBar position="static" color="white">
                    <Tabs
                        value={value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Notice Details" {...a11yProps(0)} />
                        <Tab label="Notice Template" {...a11yProps(1)} />

                    </Tabs>
                </AppBar>}
                {/* {openconfirmation && <Confirmation open={this.state.openconfirmation} handleBack={this.handleBack} navigatingnext={this.state.navigatingnext} navigatingprev={this.state.navigatingprev} nextItem={this.nextItem} prevItem={this.prevItem} />} */}
                {this.state.openconfirmation && <Confirmation open={this.state.openconfirmation} handleBack={this.handleBack} />}
                <TabPanel value={value} index={0}>
                    {updateRecord && <FormHeader title="Entrance Permit - Notices" designprops={true} handleSave={this.handleFormSave} handleEdit={this.handleEdit} editbutton={updateRecord ? editbutton : false} duplicatebutton={updateRecord ? duplicatebutton : false} savebutton={updateRecord && editbutton === true ? false : true} hasclearfix={true} />}
                    <div className="clearfix" />
                    <div className="col-12">
                        <div className="row col pt-1">
                            <div className="col-md-12 pb-2">
                                <CFormTextBox value={this.state.Name} name="Name" label="Notice Name"
                                    handleFieldChange={this.handleFieldChange} edit={edit} required={true} error={error_NAME} helperText={error_NAME && (error_NAME_helpertext ? error_NAME_helpertext : error_NAME_helpertext)} maxLength={100} />
                            </div>
                            <div className="col-md-12 pb-2">
                                <CFormTextBox value={this.state.Description} name="Description" label="Description" multiline={true} error={error_DESCRIPTION} rows="4" helperText={error_DESCRIPTION && error_DESCRIPTION_helpertext}
                                    handleFieldChange={this.handleFieldChange} edit={edit} />
                            </div>
                        </div>
                        <div className="row col">
                            <div className="col-md-6 pb-2">
                            {this.state.openconfirmationpopup && <ConfirmationPopup value={this.state.openconfirmationpopup} title={"On changing the Sub Module notice template parameters should be updated"} /*description={"submodule"}*/ onSubmit={() => this.handleconfirmation()} onClose={() => this.handleclose()} />}
                                <CFormDropDown value={this.state.SubModuleKey} dropdownvalues={this.state.submoduleJson}
                                    name="SubModuleKey" label="Sub Module" transformProps={submoduletransformProps} handleFieldChange={this.handleFieldChange} edit={edit} required={true} error={error_SUBMODULEKEY} helperText={error_SUBMODULEKEY && error_SUBMODULEKEY_helpertext} />
                                {/* <CFormDropDown value={this.state.SiteID} dropdownvalues={this.state.sitesJson}
                                name="SiteID" label="County" transformProps={transformProps} handleFieldChange={this.handleFieldChange} edit={edit} required={true} error={error_SITEID} helperText={error_SITEID && helperText} /> */}
                            </div>
                            {/* <div className="col-md-12 pb-2">
                            <CFormDropDown value={this.state.RegionId} dropdownvalues={this.state.regionsJson}
                                name="RegionId" label="Region" transformProps={transformProps} handleFieldChange={this.handleFieldChange} edit={edit} required={true} error={error_REGIONID} helperText={error_REGIONID && helperText} />
                        </div> */}
                            <div className="col-md-6 pb-2 ">
                                {this.state.showSitePermitType && <CFormDropDown value={this.state.SitePermitType} dropdownvalues={this.state.sitePermitTypeJson}
                                    name="SitePermitType" label="Application Type" transformProps={transformProps} handleFieldChange={this.handleFieldChange} edit={edit} required={true} error={error_SITEPERMITTYPE} helperText={error_SITEPERMITTYPE && error_SITEPERMITTYPE_helpertext} />}
                            </div>
                        </div>
                        <div className="col">
                            <CFormCheckBox value={this.state.CanEditNoticeDate} checked={this.state.CanEditNoticeDate} name="CanEditNoticeDate" label="Edit notice date" handleFieldChange={this.handleEditNoticeDate} transformProps={this.transformProps} edit={edit} />
                            <CText type="dtext" value="(If date modification is not allowed, notices get generated with current date)" />
                        </div>
                        <div className="col pl-5">
                            <CFormCheckBox value={this.state.CanEditPastNoticeDate} checked={this.state.CanEditPastNoticeDate} name="CanEditPastNoticeDate" label="Past dates" handleFieldChange={this.handleEditNoticeDate} transformProps={this.transformProps} edit={edit} disablecheckbox={this.state.disabled} />
                    &nbsp; &nbsp;&nbsp; &nbsp;
                        <CFormCheckBox value={this.state.CanEditCurrentNoticeDate} checked={this.state.CanEditCurrentNoticeDate} name="CanEditCurrentNoticeDate" label="Current date" handleFieldChange={this.handleEditNoticeDate} transformProps={this.transformProps} edit={edit} disablecheckbox={this.state.disabled} />
                        &nbsp; &nbsp;&nbsp; &nbsp;
                        <CFormCheckBox value={this.state.CanEditFutureNoticeDate} checked={this.state.CanEditFutureNoticeDate} name="CanEditFutureNoticeDate" label="Future dates" handleFieldChange={this.handleEditNoticeDate} transformProps={this.transformProps} edit={edit} disablecheckbox={this.state.disabled} />
                        </div>

                        <div className="col pl-3">
                            {!this.state.updateRecord && <CFormSwitch {...this.statusJson} handleFieldChange={this.handleStatusChange} value={this.state.Status} edit={edit} disabled={true} />}
                            {this.state.updateRecord && <CFormSwitch {...this.statusJson} handleFieldChange={this.handleStatusChange} value={this.state.Status} edit={edit} />}
                        </div>
                        {updateRecord && <div className="row col">
                            <div className="col-sm-6 pt-2" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D' }}>Created By : </span> {this.state.results.CreatedByName}</div>
                            <div className="col-sm-6 pt-2" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D' }}>Created On : </span> {moment(this.state.results.CreatedOn).format('MM/DD/YYYY')}</div>
                            {this.state.results.UpdatedBy === null ? ('') : (<div className="col-sm-6 pt-2" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D' }}>Updated By : </span> {this.state.results.UpdatedByName}</div>)}
                            {this.state.results.UpdatedOn === null ? ('') : (<div className="col-sm-6 pt-2" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D' }}>Updated On : </span> {moment(this.state.results.UpdatedOn).format('MM/DD/YYYY')}</div>)}
                        </div>}
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div className="row">
                        <MultipleDropdown
                            label="Select table(s)"
                            name="SideBarTables"
                            className="col-sm-11"
                            items={sideBarTablesRecords}
                            handleFieldValue={(e) => this.handlemultidropdown(e)}
                            value={SideBarTables || ''} //&& result.transmisionStatus.join(',')
                            // extraProps={extraProps}
                            extraProps={multitransformProps}
                            ismulti={true}
                            disabled={!edit}
                        />{updateRecord && <FormHeader title="Notices" designprops={true} handleSave={this.handleSaveWithTemplate} handleEdit={this.handleEdit}
                            editbutton={updateRecord ? editbutton : false} savebutton={updateRecord && editbutton === true ? false : true} />}
                    </div>
                    <Template ref={this.TemplateForm} moduleId={this.state.SubModuleKey}
                        sitePermitTypeId={this.state.SitePermitType}
                        // handleTemplateId={(id) => this.handleTemplateId(id)}
                        handleTemplateId={this.handleTemplateId}
                        templateId={this.state.TemplateId}
                        isadmin={true}
                        isEnabled={edit}
                        sideBarTables={submoduleselectedtablesresults}
                        fields={fields || []}
                        showHistory={true}
                    />

                </TabPanel>
            </div>

        )
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

Notices = connect(null, mapActions)(Notices)
export default withRouter(Notices)