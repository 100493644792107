import React, { useReducer, useEffect, useRef, useState, Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { snackbarMessage } from '../../core/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { RunInvoiceCycle } from '../billing/invoicecycleservice';
import { requestApi } from '../../../src/core/modules/form/utilities';
import { getEnvVariable } from '../../../src/modules/environmentalList';

function InvoiceSettings(props) {
    const [subModules, setSubModules] = useState([])

    useEffect(() => {        
        requestApi({ get: { url: getEnvVariable('MasterAdmin') + `/api/module/` + getEnvVariable('ModuleKey') + `/submodule/All/Active/IsConfigurable` } }, null, null).then(results => {
            setSubModules(results);
        })       
    }, [])

    const handleButton1Click = (submodule) => {
        props.snackbarMessage(`This will take a while to complete`, 'info');  
        RunInvoiceCycle(submodule).then(res => {
            props.snackbarMessage(`Invoice run completed for module ${submodule}`, 'success'); 
        })
    }  

    return (
        <div className="col-sm-12 col-xs-12 pt-2"> 
            <Fragment className = "pl-2 pt-2" >
            {subModules.map(function(module, index){
                    return (<Button color={'primary'} size={'medium'} variant={'contained'} name={'button1'} onClick={() => handleButton1Click(module.Key)}>
                                {module.DisplayName}
                            </Button>)
            })}
                      
            </Fragment>
        </div>
    )
}

const mapProps = (state) => {
    return state
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

InvoiceSettings = connect(mapProps, mapActions)(InvoiceSettings)
export default withRouter(InvoiceSettings)
