import { JsonForms } from '@jsonforms/react';
import {
    materialRenderers,
    materialCells,
} from '@jsonforms/material-renderers';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Request from '../REST/groups';
import DeleteIcon from '@material-ui/icons/Delete';
import { getEnvVariable } from '../../../src/modules/environmentalList';

const FIELD_URL = getEnvVariable('PaymentAPI') +'field'
const OPTION_URL = getEnvVariable('PaymentAPI') + 'fieldoption'

function FieldSettings({ parentId }, ref) {
    const [fields, setFields] = useState([]);
    const [refs, setRefs] = useState([]);

    const addField = () => {
        setFields(fields.concat({}))
    }

    const deleteField = (idx) => {
        setFields(fields.filter((e, i) => i !== idx))
    }

    useEffect(() => {
        Request(FIELD_URL).getById(parentId).then(resp => {
            if (Array.isArray(resp.data))
                setFields(resp.data)
        })
    }, [parentId])

    useEffect(() => {
        setRefs(fields.map(e => React.createRef()));
    }, [fields])

    useImperativeHandle(ref, () => ({
        validate: () => {
            if (refs.every(e => e.current.validate())) {
                let fields = refs.map(e => e.current.validate());
                return fields;
            }
            return false;
        }
    }))

    //let refs = useRef([]);

    return <div className='row'>
        <div className='col-12'>
            <div className='w-100'>
                <div className='float-left'>
                    <p className='lead'>Schema Fields</p>
                </div>
                <div className='float-right'>
                    <Button onClick={addField} variant='outlined'>Add</Button>
                </div>
                <div className='clearfix' />
            </div>
            <div className='row'>
                {fields.map((e, i) => {
                    return <div key={i} className='col-6'>
                        <div className='col-12 jumbotron'>
                            <IconButton className='float-right' onClick={() => deleteField(i)} variant='outlined'><DeleteIcon /></IconButton>
                            <div className='clearfix' />
                            <FieldViewRef ref={refs[i]} json={e} fieldId={e.id} />
                        </div>
                    </div>
                })}
            </div>
            {fields.length === 0 && <p>No Fields. Click on Add to add them</p>}
        </div>
    </div>

}

const FieldSettingsRef = forwardRef(FieldSettings);

export default FieldSettingsRef;

const FieldViewRef = forwardRef(FieldView);

function FieldView({ list = [], json = {}, fieldId }, ref) {
    const [data, setData] = useState(json);
    const [mode, setMode] = useState('ValidateAndHide');
    const [error, setError] = useState({})
    const [options, setOptions] = useState([]);
    const [refs, setRefs] = useState([]);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        setData(json)
        setIsChanged(false)
    }, [json])

    useEffect(() => {
        if (fieldId)
            Request(OPTION_URL).getById(fieldId).then(resp => {
                if (Array.isArray(resp.data))
                    setOptions(resp.data)
            })
    }, [fieldId])

    useEffect(() => {
        setRefs(options.map(e => React.createRef()))
    }, [options])

    const schema = {
        properties: {
            name: {
                type: 'string'
            },
            fieldType: {
                type: 'number',
                oneOf: [
                    { const: 1, title: 'String' },
                    { const: 2, title: 'Number' },
                    { const: 3, title: 'Object' },
                    { const: 4, title: 'Date' },
                    { const: 5, title: 'Boolean' },
                ]
                // enumNames: [{label}]
            },
            schemaId: {
                type: 'string',
                oneOf: list.map(e => {
                    return { const: e.value, title: e.label }
                }).concat([{ const: '-1', title: 'None' }]),
                default: '-1'
            }
        },
        required: data && data.fieldType === 3 ? ['name', 'fieldType', 'schemaId'] : ['name', 'fieldType']
    }

    const uischema = {
        "type": "VerticalLayout",
        "elements": [
            {
                "type": "Control",
                "scope": "#/properties/name"
            },
            {
                "type": "Control",
                "scope": "#/properties/fieldType"
            },
            {
                "type": "Control",
                "scope": "#/properties/schemaId",
                "rule": {
                    "effect": "SHOW",
                    "condition": {
                        "scope": "#/properties/fieldType",
                        "schema": {
                            oneOf: [
                                { const: 3, title: 'Object' }
                            ]
                        }
                    }
                }
            }
        ]
    }

    useImperativeHandle(ref, () => ({
        validate: () => {
            setMode('ValidateAndShow')
            if (Object.keys(error).length === 0 || !isChanged) {
                //return data;
                console.log(data);
                if (data.schemaId === -1 || data.schemaId === '-1')
                    data.schemaId = null
                if (refs.every(e => e.current.validate())) {
                    let options = refs.map(e => e.current.validate())
                    return { ...data, options };
                }
            }
            console.log(error, data)
            return false;
        }
    }))

    const addOption = () => {
        setOptions(options.concat([{}]))
    }

    const deleteOption = (idx) => {
        setOptions(options.filter((e, i) => i !== idx))
    }

    //let refs = useRef([]);

    return <div className='row'>
        <div className='col-12'>
            <JsonForms schema={schema} uischema={uischema} data={data} validationMode={mode} renderers={materialRenderers} cells={materialCells} onChange={({ data, errors }) => { setData(data); setError(errors); setIsChanged(true) }} />
        </div>
        <div className='col-12 pt-3'>
            <div className='w-100'>
                <div className='float-left'>
                    <h6>Field Options</h6>
                </div>
                <div className='float-right'>
                    <Button size='small' onClick={addOption} variant='outlined'>Add</Button>
                </div>
                <div className='clearfix' />
            </div>
            {options.map((e, i) => {
                return <div key={i} className='row'>
                    <div className='col-8'>
                        <OptionViewRef ref={refs[i]} json={e} />
                    </div>
                    <div className='col-4 py-3'>
                        <Button size='small' onClick={() => deleteOption(i)} variant='outlined'>Delete</Button>
                    </div>
                </div>
            })}
            {options.length === 0 && <p>No Options. Click on Add to add them</p>}
        </div>
    </div>
}

const OptionViewRef = forwardRef(OptionView);

function OptionView({ json = {} }, ref) {
    const [data, setData] = useState(json);
    const [mode, setMode] = useState('ValidateAndHide');
    const [error, setError] = useState({})
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        setData(json)
        setIsChanged(false)
    }, [json])

    useImperativeHandle(ref, () => ({
        validate: () => {
            setMode('ValidateAndShow')
            if (Object.keys(error).length === 0 || !isChanged)
                return data;
            console.log(error)
            return false;
        }
    }))

    const schema = {
        properties: {
            name: {
                type: 'string'
            },
            value: {
                type: 'string'
            }
        },
        required: ["name", "value"]
    }

    const uischema = {
        "type": "HorizontalLayout",
        "elements": [
            {
                "type": "Control",
                "scope": "#/properties/name"
            },
            {
                "type": "Control",
                "scope": "#/properties/value"
            }
        ]
    }

    return <JsonForms schema={schema} uischema={uischema} data={data} validationMode={mode} renderers={materialRenderers} cells={materialCells} onChange={({ data, errors }) => { setData(data); setError(errors); setIsChanged(true) }} />

}