/**
      *********************************************************
      Description: Handles paper component. It is used only once where all the created applications are shown. This is a custom file and contains a dropdown and grid.
        When the value in the dropdown changes the grid is updated.
      Required props: type.
      limitations    : N/A
      *********************************************************
**/

import { withStyles } from '@material-ui/core/styles';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { GetData } from '../../controls/helpers/requests';
import { getEnvVariable } from '../../../modules/environmentalList';
import GridHeader from '../Grid/cgridheader';
import { CustomColumnMenu } from '../Grid/customColumnMenu.jsx';
import { requestApi } from './utilities';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
});

const dropdowntransformProps = {
    name: 'Name',
    value: 'Id'
}


function CStatus(props) {
    return (
        <td>
            <span className="badge badge-pill badge-success"> {this.props.dataItem[this.props.field]}</span>
        </td>
    )
}

class CPaper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            values: null,
            value: '',
            dataState: { skip: 0 },
            add: true,
            loadText: "Please wait the information is loading... ",
            initialdata: [],
            datagridcol: [],
            pageable: {
                buttonCount: 5,
                info: true,
                type: 'numeric',
                previousNext: true
            },
        }
    }
    /**
        *********************************************************
        Description: Fetches the data for the dropdown. Handles the permission to create application based on the user rights.
        Required props: crud.read for the dropdown
        limitations    : N/A
        *********************************************************
  **/

    componentDidMount() {
        const { options, components, accessPermissions, accessLevelPermissions } = this.props
        const firstcomponent = components[0]
        const crud = firstcomponent.options.crud;
        const { modulePermissions } = accessPermissions ? accessPermissions : {}

        requestApi(crud.read).then(results => {
            this.mapIdstoSiteIds = {}
            this.mapSiteIdstoPermissions = {}
            results.forEach(each => {
                const { Id, SiteId } = each
                this.mapIdstoSiteIds[Id] = SiteId
                if (modulePermissions) {
                    const accessrights = modulePermissions.find(e => e.siteId === SiteId)
                    if (accessrights) this.mapSiteIdstoPermissions[SiteId] = accessrights
                }
            })
            results.unshift({ Id: -1, Name: 'All' })
            this.setState({
                values: results
            }, () => {
                this.getIntialSitePermits()
            })
        })
    }

    getIntialSitePermits = () => {
        const urlPath = getEnvVariable('Application') + '/api/Application/OwnerPermits/' + this.props.dataItem['Id'] + '';
        GetData(urlPath)
            .then((data) => {
                console.log('grid data--', data);
                if (data && data.length > 0) {
                    console.log('grid data--', Object.getOwnPropertyNames(data[0]));
                    let colnames = Object.getOwnPropertyNames(data[0]);
                    let allgridcols = []
                    colnames.forEach(col => {
                        allgridcols.push({ title: col, show: true })
                    })
                    this.setState({
                        initialdata: data,
                        gridoptions: [],
                        allgridcols: allgridcols,
                        data: [], datagridcol: [],
                        value: ''
                    });
                }
                else {
                    this.setState({
                        loadText: "No Data Found"
                    });
                }
            })
            .catch(err => {
                console.log('err-------------------', err)
            });
    }
    /**
      *********************************************************
      Description: Handles the double click on the grid row. Opens the created application for review.
      Required props: update prop on the grid components with link prop.
      limitations    : N/A
      *********************************************************
**/
    rowRender = (trElement, dataItem) => {
        const { value } = this.state
        const { options, components, compmap } = this.props
        const [firstcomponent, secondcomponent] = components

        const trProps = {
            ...trElement.props,
            onDoubleClick: () => {
                console.log('Double click on ', dataItem)
                const pushLink = secondcomponent.options.crud.update.link.link + '/' + dataItem.dataItem['Site Permit Type Id'] + '/' + dataItem.dataItem.Id //value or dataItem.dataItem['Site Permit Type Id']
                // localStorage.setItem('entranceexists', 'N');
                // localStorage.setItem('resultskeys', null)
                // localStorage.setItem('results', null)
                // localStorage.setItem('allvalidations', null)
                this.props.history.push(`${pushLink}`)
            }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }
    /**
      *********************************************************
      Description: Handles the change in dropdown values.
      Required props: event. Add button is controlled by the permissions
      limitations    : N/A
      *********************************************************
**/

    handleFieldChange = (event) => {
        const { name, value } = event.target
        const siteId = this.mapIdstoSiteIds[value]
        const urlPath = getEnvVariable('Application') + '/api/SitePermitSectionFieldsView/GridFields/' + (value) + `${localStorage.getItem('impersonate') ? '?impersonateFor=' + localStorage.getItem('impersonate'):''}`

        GetData(urlPath)
            .then((data) => {
                data.map(m => {
                    m.show = true;
                    m.title = m.fieldName
                })
                this.setState({
                    value: value,
                    add: this.mapSiteIdstoPermissions[siteId] ? (this.mapSiteIdstoPermissions[siteId].accessLevel === 'Add') : true,
                    datagridcol: data
                });
            })
            .catch(err => {
                console.log('err-------------------', err)
            });

        this.fetchData(this.state.dataState, value)
    }

    /**
    *********************************************************
    Description: Fetches data for the grid.
    Required props: type. url to fetch the grid values
    limitations    : N/A
    *********************************************************
**/

    fetchData(dataState, value) {
        const queryStr = `${toDataSourceRequestString(dataState)}`; // Serialize the state
        const hasGroups = dataState.group && dataState.group.length;

        const { options, components, compmap } = this.props
        const [firstcomponent, secondcomponent] = components


        const urlPath = secondcomponent.options.crud.read.get.url + value
        GetData(urlPath)
            .then((data) => {
                console.log('grid data--', data);
                this.setState({
                    data: data
                });
            })
            .catch(err => {
                console.log('err-------------------', err)
            });
    }
    /**
         *********************************************************
         Description: Handle Back to navigate to custom and history screen
         Required props:  back url
         limitations    : N/A
         *********************************************************
   **/
    handleBack = () => {
        const { options } = this.props
        const { back } = options;
        const { routes } = back;
        if (Array.isArray(routes)) {
            let path = ''
            routes.forEach(each => {
                const { route, routeParams, matchProps } = each
                if (routeParams) {
                    path += route + "/" + this.props.match.params[routeParams]
                }
                else if (matchProps) {
                    path += route + "/" + this.props.results[matchProps]
                }
                else {
                    path += route
                }
            })
            this.props.history.push(path)
        }
    }

    /**
      *********************************************************
      Description: Columns modify and save to report.
      Required props: event. Add button is controlled by the permissions
      limitations    : N/A
      *********************************************************
**/
    onColumnsSubmit = (columnsState) => {
        this.setState({
            datagridcol: columnsState
        });
    }

    onColumnsInitialSubmit = (columnsState) => {
        this.setState({
            allgridcols: columnsState
        });
    }

    _export;
    export = () => {
        this._export.save();
    }
    render() {
        const { classes } = this.props;
        const { options, components, compmap, accessPermissions, accessLevelPermissions } = this.props
        const [firstcomponent, secondcomponent] = components
        const FirstComp = compmap[firstcomponent.type]
        const SecondComp = compmap[secondcomponent.type]
        const { value, add, datagridcol, initialdata, allgridcols } = this.state
        const gridoptions = secondcomponent.options
        const { conditions } = gridoptions

        console.log(datagridcol)
        return (
            <div className="col w-100">
                <div>
                    <GridHeader accessPermissions={accessPermissions} accessLevelPermissions={accessLevelPermissions} {...secondcomponent.options.headerProps} add={add} export={this.export} handleBack={this.handleBack} />
                    {initialdata &&
                        <ExcelExport
                            data={(datagridcol && datagridcol.length > 0) ? this.state.data : initialdata}
                            ref={exporter => this._export = exporter}
                        >
                            {(this.state.data && this.state.data.length > 0) || (initialdata && initialdata.length > 0) && <Grid
                                pageable={true}
                                total={this.state.total}
                                data={(datagridcol && datagridcol.length > 0) ? this.state.data : initialdata}
                                {...this.state.dataState}
                                onDataStateChange={this.handleDataStateChange}
                                onRowClick={this.handleRowClick}
                                rowRender={this.rowRender}
                                style={{ height: '350px' }}
                                resizable
                            >
                                <GridToolbar >
                                    <div className="row col">
                                        <div className="col-sm-12">
                                            <FirstComp className="pb-2" {...firstcomponent.options} handleFieldChange={this.handleFieldChange} value={this.state.value} dropdownvalues={this.state.values} transformProps={dropdowntransformProps} />
                                        </div>

                                    </div>
                                </GridToolbar>
                                {datagridcol && datagridcol.length > 0 && datagridcol.map((each, index) => {
                                    if (each.fieldName == 'Status') {
                                        if (each.show) return <GridColumn {...each} key={index} cell={this.handleStatusStyle} columnMenu={
                                            props =>
                                                <CustomColumnMenu
                                                    {...props}
                                                    columns={datagridcol}
                                                    onColumnsSubmit={this.onColumnsSubmit}
                                                />} />
                                    }
                                    else if (each.fieldName == 'CreatedOn' || each.fieldName == 'UpdatedOn') {
                                        if (each.show) return <GridColumn {...each} key={index} filter={'date'} format="{0:d}" columnMenu={
                                            props =>
                                                <CustomColumnMenu
                                                    {...props}
                                                    columns={datagridcol}
                                                    onColumnsSubmit={this.onColumnsSubmit}
                                                />} />
                                    }
                                    else {
                                        if (each.show) return <GridColumn {...each} width={'300px'} field={each.fieldName} title={each.fieldName} key={index} columnMenu={
                                            props =>
                                                <CustomColumnMenu
                                                    {...props}
                                                    columns={datagridcol}
                                                    onColumnsSubmit={this.onColumnsSubmit}
                                                />} />
                                    }
                                })}{datagridcol && datagridcol.length === 0 &&
                                    initialdata && initialdata.length > 0 && allgridcols.map((each, index) => {
                                        if (each.show) return <GridColumn width='200px' field={each.title} title={each.title} columnMenu={
                                            props =>
                                                <CustomColumnMenu
                                                    {...props}
                                                    columns={allgridcols}
                                                    onColumnsSubmit={this.onColumnsInitialSubmit}
                                                />} />
                                    })}
                            </Grid>
                            }{
                                !this.state.data && <div className="col-sm-12 text-primary">{this.state.loadText}</div>
                            }
                        </ExcelExport>
                    }
                </div>
            </div>
        );
    }

}

CPaper.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(withRouter(CPaper));
