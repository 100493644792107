/**
    *********************************************************
    Description:map all the components.
    Required props: N/A.
    limitations    : N/A
    *********************************************************
**/

import Skeleton from '@material-ui/lab/Skeleton';
import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AutocompletePopover from '../core/controls/components/autocompletepopover';
import AutocompleteTags from '../core/controls/components/autocompletetags';
import card from '../core/controls/components/card';
import Customlayerfiltertype from '../core/controls/components/custom/adminlayer';
import AutocompleteTagsNotification from '../core/controls/components/custom/autocompletetagsnotification';
import CompanyAccessControl from '../core/controls/components/custom/companyaccesscontrol';
import CustomLabelExpression from '../core/controls/components/custom/labelexpression';
import MultipleSelect from '../core/controls/components/custom/multipleselect';
import PhaseTypes from '../core/controls/components/custom/phasetypes';
import SubModuleSelection from '../core/controls/components/custom/submoduleselection';
import IframeView from '../core/controls/components/iframe';
import Otp from '../core/controls/components/otpinput';
import Backdrop from '../core/modules/backdrop';
import Form from '../core/modules/form';
//import AutoComplete from '../core/modules/form/cautocomplete';
import Button from '../core/modules/form/cbutton';
import CheckBox from '../core/modules/form/ccheckbox';
import CheckBoxGroup from '../core/modules/form/ccheckboxgroup';
import ConfirmDialog from '../core/modules/form/cconfirmationpopup';
import DateTimePicker from '../core/modules/form/cdatetimepicker';
import Date_MonthPicker from '../core/controls/components/date_daymonth.js';
import Divider from '../core/modules/form/cdivider';
import DropDown from '../core/modules/form/cdropdown';
import DropDownWithAdd from '../core/modules/form/cdropdownwithaddnewitem';
import DualListBox from '../core/modules/form/cduallistbox';
import FormAutoComplete from '../core/modules/form/cformautocomplete';
import FormButton from '../core/modules/form/cformbutton';
import FormCheckBox from '../core/modules/form/cformcheckbox';
import FormColorPicker from '../core/modules/form/cformcolorpicker';
import FormDiscussionPanel from '../core/modules/form/cformdiscussionpanel';
import FormDropDown from '../core/modules/form/cformdropdown';
import FormFieldsGroup from '../core/modules/form/cformfieldsgroup';
import InputFieldHidden from '../core/modules/form/cforminputhidden';
import FormMultiDualListBox from '../core/modules/form/cformmultiduallistbox';
import PhoneExtn from '../core/modules/form/cformphnextntext';
import PhoneText from '../core/modules/form/cformphonetext';
import FormSwitch from '../core/modules/form/cformswitch';
import FormText from '../core/modules/form/cformtext';
import FormTextField from '../core/modules/form/cformtextbox';
import GeneralPaper from '../core/modules/form/cgeneralpaper';
import CLabel from '../core/modules/form/clabel';
import Link from '../core/modules/form/clink';
import MultiDualListBox from '../core/modules/form/cmultiduallistbox';
import MultiDualListBox2 from '../core/modules/form/cmultiduallistbox2';
import MultiSelect from '../core/modules/form/cmultiselect';
import NoticeTextTemplate from '../core/modules/form/cnoticetexttemplate';
import CNumberFormat from '../core/modules/form/cnumberformats';
import Radio from '../core/modules/form/cradio';
import RadioGroup from '../core/modules/form/cradiogroup';
import Scheduler from '../core/modules/form/cscheduler';
import SectionsCard from '../core/modules/form/csectionscard';
import SignUpForm from '../core/modules/form/csignupform';
import SimpleLink from '../core/modules/form/csimplelink';
import SlidingPage from '../core/modules/form/cslidingpage';
import Switch from '../core/modules/form/cswitches';
import Tab from '../core/modules/form/ctabs';
import Text from '../core/modules/form/ctext';
import TextBox from '../core/modules/form/ctextbox';
import ToggleButton from '../core/modules/form/ctogglebutton';
import TreeView from '../core/modules/form/ctreeview';
import Upload from '../core/modules/form/cupload';
import CustomArcgisMultiselect from '../core/modules/form/customArcgisMultiselect';
import CustomLayerTemplate from '../core/modules/form/customlayertemplate';
import DiscussionsForm from '../core/modules/form/discussionsform';
import KeyboardDatePickerExample from '../core/modules/form/materialdatepicker';
import MaterialDateTimePicker from '../core/modules/form/materialpicker';
import InlineTimePickerDemo from '../core/modules/form/materialtimepicker';
import MultiSelectAutoComplete from '../core/modules/form/multidropdown';
import InterchangableGrid from '../core/modules/Grid/cinterchangablegrid';
import SimpleGrid from '../core/modules/Grid/csimplegrid';
import Header from '../core/modules/header/index';
import Sidebar from '../core/modules/sidebar/sidebar';
import PopUpButton from '../core/modules/viewport/cpopup';

import InvoiceSettings from '../custommodules/invoicesettings/invoicesettings';

import ImageUpload from '../custommodules/newentrancepermit/imageupload';
//import Reports from '../modules/reports/index.js';
import CustomStandardDescOfConstruction from '../custommodules/sitepermittypes/standarddescofconstruction';
//import NotificationTemplate from '../core/modules/form/cnotificationTemplate'; -- not used
import CustomTermsConditions from '../custommodules/sitepermittypes/termsandconditions';
import FileAttachments from '../modules/attachments/attachments';
import FileImages from '../modules/attachments/images';
//import AccessControl from '../core/modules/form/caccesscontrol';
import GridButton from '../modules/buttons/GridButton';

import CTemplateLoader from '../modules/form/ctemplateloader';
import CRoutingTreeView from '../modules/form/routingtreeview';
import FileUpload from '../modules/Uploads/FileUpload';
//import Backdrop from '../core/modules/backdrop';

////////
import PageNotFound from '../core/modules/systemmessages/404issue';
import Logout from '../core/modules/systemmessages/logout';
import UnAuthorize from '../core/modules/systemmessages/unauthorize';
//Component imports
import Banner from '../core/modules/viewport/banner';
import Content from '../core/modules/viewport/content';
//import PopUpButton from '../core/modules/viewport/cpopup';
import { setLogin } from '../core/security/actions';
import AssociatedApplicants from '../custommodules/applicant/associatedapplicants';
import CompanyIframeCheck from '../custommodules/applicant/iframe';
import Billing from '../custommodules/billing/billingform';
import CompanyBilling from '../custommodules/billing/ownerbilling';
//import InvoiceSettings from '../custommodules/invoicesettings/invoicesettings';
import MasterFieldsList from '../custommodules/masterfields/masterfieldlist';
import EPSNewApplication from '../custommodules/newentrancepermit/index_wrs';
//import ImageUpload from '../custommodules/newentrancepermit/imageupload';
import NewApplicationInspection from '../custommodules/newentrancepermit/inspections';
import NewApplicationMap from '../custommodules/newentrancepermit/map';
import TaxParcelBtn from '../custommodules/newentrancepermit/taxparcelbutton';
import UsersNContacts from '../custommodules/newentrancepermit/usersNcontacts';
import EPSNotifications from '../custommodules/notifications/epsnotifications';
import EPSSectionsFields from '../custommodules/sitepermittypes/sitepermitsectionsfields';
//import Reports from '../modules/reports/index.js';
//import CustomStandardDescOfConstruction from '../custommodules/sitepermittypes/standarddescofconstruction';
//import NotificationTemplate from '../core/modules/form/cnotificationTemplate'; -- not used
//import CustomTermsConditions from '../custommodules/sitepermittypes/termsandconditions';
import ApplicationStatus from '../core/modules/applicationstatus';
import DescOfConstructionGrid from '../json/sitepermittypes/descofconsgrid';
import Administration from '../modules/administration';
//import FileAttachments from '../modules/attachments/attachments';
//import KeptFileAttachments from '../modules/attachments/attachments_kept';
//import FileImages from '../modules/attachments/images';
//import AccessControl from '../core/modules/form/caccesscontrol';
//import GridButton from '../modules/buttons/GridButton';
import SystemStatistics from '../modules/dashboard/dashboardchart';
import Billingbr from '../modules/dnd/billingbr';
import CustomConfig from '../modules/dnd/configcustom';
//import CRoutingTreeView from '../modules/form/croutingtreeview';
//import CTemplateLoader from '../modules/form/ctemplateloader';
import NoticeCommon from '../modules/notices/commonnotices';
import Notices from '../modules/notices/notices';
import NotificationSettings from '../modules/notificationsettings/notificationsettings';
import NS from '../modules/notificationsettings/ns';
import NsPopUp from '../modules/notificationsettings/nspopup';
import NSTemplate from '../modules/notificationsettings/nstemplate';
import OwnerRegistration from '../modules/ownerregistration/OwnerRegistration';
import Consultant from '../modules/registrationrequest/consultant';
import RegistrationRequestApproval from '../modules/registrationrequest/requestapproval';
//import FileUpload from '../modules/Uploads/FileUpload';
import AddWorkFlow from '../modules/workflow/addworkflow';
import WorkFlow from '../modules/workflow/workflow';
import WorkFlowUse from '../modules/workflow/workflowuse';
import UserAccessControl from '../core/controls/components/custom/useraccesscontrol'
import AuthenticationLogin from '../core/controls/components/authenticationlogin'
//import Map from '../core/modules/map/map';
// import FormContainer from '../core/modules/form/cform';
import CBuildDND from '../modules/dnd/cbuilddnd';
//import Dashboard from '../modules/dashboard/dashboardGrid';
//import Editor from '../core/modules/form/cwysiwyg';
//import Grid from '../core/modules/Grid/cgrid';
import Paper from '../core/modules/form/cpaper';
import LayerAuthorization from '../core/controls/components/custom/layerauthorization';
const Grid = lazy(() => import('../core/modules/Grid/cgrid'));
const Editor = lazy(() => import('../core/modules/form/cwysiwyg'));
const Dashboard = lazy(() => import('../modules/dashboard/dashboardGrid'));
//const Paper = lazy(() => import('../core/modules/form/cpaper'));

// const SignUpForm = lazy(() => import('../core/modules/form/csignupform'));
// const Sidebar = lazy(() => import('../core/modules/sidebar/sidebar'));

//const CBuildDND = lazy(() => import('../modules/dnd/cbuilddnd'));
const Map = lazy(() => import('../core/modules/map/map'));
const FormContainer = lazy(() => import('../core/modules/form/cform'));
//import AViewPort from '../core/modules/viewport/aviewport';

const AViewPort = lazy(() => import('../core/modules/viewport/aviewport'));
//const Modules = lazy(() => import("./moduleimports"));

const compmap = {
    ns: NS,//M
    addworkflow: AddWorkFlow,//M
    administration: Administration,//M
    applicationstatus: ApplicationStatus,//M
    banner: Banner, //M
    billing: Billing,//M
    billingbr: Billingbr,//M
    cnoticetexttemplate: NoticeTextTemplate,//M
    companybilling: CompanyBilling,//M
    companyiframe: CompanyIframeCheck,//M
    configcomp: CustomConfig,//M
    dashboard: Dashboard, //M
    descofconsgrid: DescOfConstructionGrid,//M
    discussionsform: DiscussionsForm,//M
    'eps-masterfieldslist': MasterFieldsList,//M
    'eps-newapplication': EPSNewApplication,//M
    'eps-newapplication-inspection': NewApplicationInspection,//M
    'eps-notifications': EPSNotifications,//M
    epssectionsfields: EPSSectionsFields,//M
    header: Header, //M
    logout: Logout, //M
    newApplicationMap: NewApplicationMap,//M
    noticecommon: NoticeCommon,//M
    notices: Notices,//M
    notificationsettings: NotificationSettings,//M
    nspopup: NsPopUp,//M
    nstemplate: NSTemplate,//M
    OwnerRegistration: OwnerRegistration,//M
    pagenotfound: PageNotFound, //M
    phasetypes: PhaseTypes,//M
    scheduler: Scheduler,//M
    signupform: SignUpForm, //M
    submoduleselection: SubModuleSelection,//M
    systemstatistics: SystemStatistics,//M
    taxparcelbutton: TaxParcelBtn,//M
    unauthorize: UnAuthorize, //M
    usersncontacts: UsersNContacts,//M
    workflow: WorkFlow,//M
    workflowuse: WorkFlowUse,//M
    sitepermitdnd: CBuildDND,//M
    registrationrequestapproval: RegistrationRequestApproval,//M
    drawer: Sidebar,//L
    paper: Paper,//L
    popupbutton: PopUpButton,//L
    slidingbutton: SlidingPage,//L
    content: Content, // L
    //  autocomplete: AutoComplete, //C
    autocompletepopover: AutocompletePopover, //C
    button: Button,//C
    card: card,//C
    cautocompletetags: AutocompleteTags,//C
    cautocompletetagsnotification: AutocompleteTagsNotification,//C
    cconfirmpopup: ConfirmDialog,//C
    cformautocomplete: FormAutoComplete,//C
    cformcolorpicker: FormColorPicker,//C
    cformdiscussionspanel: FormDiscussionPanel,//C
    cformphoneextn: PhoneExtn,//C
    cformphonetext: PhoneText,//C
    cformtext: FormText,//C
    checkbox: CheckBox,//C
    checkboxgroup: CheckBoxGroup,//C
    clabel: CLabel,//C
    cnumberFormat: CNumberFormat,//C
    colorpicker: FormTextField,//C
    companyaccesscontrol: CompanyAccessControl,//C
    confirmationpopupbutton: PopUpButton,//C
    ctab: Tab,//C
    customArcgisMultiselect: CustomArcgisMultiselect,//CM
    customlabelexpression: CustomLabelExpression,//CM
    customlayerfiltertype: Customlayerfiltertype,//CM
    customlayertemplate: CustomLayerTemplate,//CM
    customTermsandConditions: CustomTermsConditions,//CM
    // keptfileattachments: KeptFileAttachments,//CM
    invoicesettings: InvoiceSettings,//CM
    map: Map,//CM
    standarddescofconstruction: CustomStandardDescOfConstruction,//CM
    date: DateTimePicker,//C
    'date-material': KeyboardDatePickerExample,//C
    'datetime-local': MaterialDateTimePicker,//C
    datetimetext: Text,//C
    datemonth:Date_MonthPicker,
    divider: Divider,//C
    dropdown: DropDown,//C
    dropdownwithadd: DropDownWithAdd,//C
    dtext: Text,//C
    duallistbox: DualListBox,//C
    dynamiclayoutform: Form,//C
    editor: Editor,//C
    etext: Text,//C
    fileattachments: FileAttachments,//C
    fileimages: FileImages,//C
    fileupload: FileUpload,//C
    form: Form,//C
    formbutton: FormButton,//C
    formcheckbox: FormCheckBox,//C
    formcontainer: FormContainer,//C
    formdropdown: FormDropDown,//C
    formdropdownwithadd: DropDownWithAdd,//C
    formfieldsgroup: FormFieldsGroup,//C
    formmultiduallistbox: FormMultiDualListBox,//C
    formswitch: FormSwitch,//C
    formtextbox: FormTextField,//C
    generalpaper: GeneralPaper,//C
    grid: Grid,//C
    gridbutton: GridButton,//C
    historydate: Text,//C
    historyuser: Text,//C
    iframe: IframeView,//C
    imageupload: ImageUpload,//C
    infotext: Text,//C
    inputFieldHidden: InputFieldHidden,//C
    interchangablegrid: InterchangableGrid,//C
    link: Link,//C
    multiduallistbox: MultiDualListBox,//C
    multiduallistbox2: MultiDualListBox2,//C
    multipleslect: MultipleSelect,//C
    multiselect: MultiSelect,//C
    multiselectautocomplete: MultiSelectAutoComplete,//C
    navbutton: Button,//C
    navformbutton: FormButton,//C
    navlink: Link,//C
    routelink: Link,
    otp: Otp,//C
    radio: Radio,//C
    radiogroup: RadioGroup,//C
    routingtreeview: CRoutingTreeView,//C
    simplegrid: SimpleGrid,//C
    simplelink: SimpleLink,//C
    sectionscard: SectionsCard,//C
    switch: Switch,//C
    templateloader: CTemplateLoader,//C
    text: Text,//C
    textbox: TextBox,//C
    time: DateTimePicker,//C
    'time-material': InlineTimePickerDemo,//C
    togglebutton: ToggleButton,//C
    toggleicon: ToggleButton,//C
    treeview: TreeView,//C
    upload: Upload,//C
    associatedapplicants: AssociatedApplicants,
    regisconsultants: Consultant,
    accessControl: UserAccessControl,
    layerauth: LayerAuthorization,
    authenticationlogin:AuthenticationLogin

}

export const compmapReducer = (state = compmap, action) => {

    switch (action.type) {
        case 'PASS_COMPMAP':
            return state
        default:
            return state
    }
}


class SignInViewPortWrapper extends Component {
    render() {
        return (
            <Suspense fallback={<div className="col">
                <Backdrop open={true} />
                <Skeleton variant="text" width='100%' height={50} />
                <Skeleton variant="rect" width='100%' height={800} />
                <Skeleton variant="text" width='100%' height={50} />
            </div>}>
                <AViewPort compmap={compmap} accessPermissions={this.props.accessPermissions}
                    accessLevelPermissions={this.props.accessLevelPermissions}
                    AccessPortal={this.props.AccessPortal} {...this.props} />
            </Suspense>

        )

    }
}


const mapProps = (state) => {
    return { user: state.authentication.user }
}

const mapActions = dispatch => {
    return bindActionCreators({ setLogin }, dispatch);
}

SignInViewPortWrapper = connect(mapProps, mapActions)(SignInViewPortWrapper);
export default SignInViewPortWrapper;
