  /**
        *********************************************************
            deprecated
        *********************************************************
 **/

import React, { Component, Fragment } from 'react';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { withRouter } from 'react-router-dom';
import { requestApi } from './utilities';

class CDualListBox extends Component {

    state = {
        selected: [],
        available: [],
        showselected: [],
        opts: []
    };

    onChange = (selected) => {
        const { options } = this.props
        const { name, transformProps } = options
        const foundresults = {
            [transformProps.selected]: this.state.results[transformProps.options].filter(each => { return selected.find(e => e === (each.value || each.id)) }),
            //[transformFile.options]:
        }
        // this.setState({
        //     selected: selected,
        //     results: foundresults

        // });
        if (this.props.setFormFieldValue) {
            this.props.setFormFieldValue(name, foundresults)
        }
        // if (this.props.setAdditionalFormFieldValue) {
        //     this.props.setAdditionalFormFieldValue(name, { availableCollection: this.state.opts, selectedCollection: this.state.selected })
        // }
    };

    componentDidMount() {
        const { options } = this.props
        const { crud } = options
        const { name, sendProps, receiveProps } = options
        // const { parentProps } = this.props
        // const { name } = parentProps
        const { transformProps } = options
        let showselected = []
        let available = []
        let selected = []
        this.selectedListbox.addEventListener('click', (event) => {
            console.log(event);
            const selectedvalue = sendProps ? (sendProps.selectedvalue ? event.target.value : null) : null
            this.props.passPropsToOtherFields(selectedvalue)
        });
        if (crud) {
            const { read } = crud
            const { get } = read
            let id
            const { routeProps, customProps } = read.get;
            if (routeProps) {
            id = this.props.match.params[get.routeProps.value]
            }
            else if (customProps){
                id = this.props.dataItem[customProps.custom]
            }
            requestApi(read, id).then(results => {
                console.log('dulalistbox results---', results);
                if (this.props.setFormFieldValue) {
                    this.props.setFormFieldValue(name, results)
                }
            })
        }
    }

    render() {
        const { options, components, compmap, order, type, rules, currentValue, ...otherProps } = this.props;

        const { transformProps } = options
        const value = currentValue ? currentValue.value : null

        const availableCollection = value ? value[transformProps.options] : null
        const selectedCollection = value ? value[transformProps.selected] : null
        const selected = selectedCollection ? selectedCollection.map(each => {
            return each[transformProps.value]
        }) : []
        const opts = availableCollection ? availableCollection.map(each => {
            return { value: each[transformProps.value], label: each[transformProps.label] }
        }) : []


        return (
            <Fragment>
                <DualListBox
                    options={opts}
                    //selected={showselected}
                    selected={selected}
                    onChange={this.onChange}
                    selectedRef={(element) => { this.selectedListbox = element; }}
                    showHeaderLabels={true}
                />
            </Fragment>
        )
    }
}

export default withRouter(CDualListBox)