import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import * as DNDSettings from './util/dndSettings'
import FieldsConfig from './util/fieldsConfig'

class CRowDraggable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovering: false
        }
    }
    OnMouseHover = () => {
        this.setState({ isHovering: true })
    }
    onMouseLeave = () => {
        this.setState({ isHovering: false })
    }

    render() {
        const { section, direction, dropclazzName, dragclazzName, splitName, field, draggableIndex, showFieldActions, OnSettings } = this.props;
        // const substring = (splitName === true && field.Name.length >= 200) ? field.Name.substring(0, 200) + "..." : field.Name;
        // const title = (splitName === true && field.Name.length >= 200) ? field.Name : "";
        return (
            <Draggable
                key={field.dndId}
                draggableId={`RowDG-${field.dndId} `}
                index={draggableIndex}
                className={dropclazzName}
            >
                {(rp, rs) => (
                    <div
                        onMouseEnter={this.OnMouseHover}
                        onMouseLeave={this.onMouseLeave}
                        ref={rp.innerRef}
                        {...rp.draggableProps}
                        {...rp.dragHandleProps}
                        className={dragclazzName}
                        title={field.Name}
                        style={DNDSettings.getFieldComponentStyle(
                            rs.isDragging,
                            rp.draggableProps.style,
                            showFieldActions
                        )}>
                        <div className="fieldActionsWrapper">
                            {showFieldActions && <FieldsConfig options={{ "FieldType": "TB", "FieldName": field.Name, section: section, field: field }} OnSettings={OnSettings} />}
                            {!showFieldActions && field.Name}
                        </div>
                        {rp.placeholder}
                    </div>
                )}
            </Draggable>
        );
    }
}
export default CRowDraggable;
