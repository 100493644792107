/**
      *********************************************************
      Description: Handles radigroup. In the view mode only the selected item label is shown.
      Required props: type,items.
      limitations    : N/A
      *********************************************************
**/

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { Fragment } from 'react';
import DefaultSettings from '../../controls/components/settings';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFormLabel-root": {
            fontSize: '0.7em'
        },
        "&  .MuiFormGroup-root": {
            display: 'block'
        }
    }
}));

const CRadioGroup = (props) => {
    const classes = useStyles();
    const { options, value, defaultValue, dropdownvalues, handleFieldChange, tooltip, edit,contexthelp } = props;
    const { label, labelcomp, controlcomp, items, name, select, disabled, transformProps, ...otherOptions } = options ? options : {}
    let choosen = transformProps || null;
    let lists = items && items.length > 0 ? items : dropdownvalues && dropdownvalues.length > 0 ? dropdownvalues : []
    const selecteditem = lists && lists.find(each => {
        if (choosen) {
            // const { key, value, label } = choosen
            return each[transformProps.value] === value
        }
        else return each.options.value === value
    }
    )
    const selecteditemlabel = selecteditem ? (choosen ? transformProps && selecteditem[transformProps.label || transformProps.name] : selecteditem.options.label) : ''
    if (edit === false) {
        return <Fragment>
            {tooltip && <div > <Typography variant={'caption'} className="fw-bold">{label}  &nbsp; < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} onClick={(evt) => contexthelp ?
                    DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()}/>
            </Tooltip></Typography><br />
                <Typography variant={'body2'} className="text-secondary" color={'textPrimary'}>{selecteditem ? selecteditemlabel : null}          </Typography></div>
            }
            {!tooltip && <div> <Typography variant={'caption'} className="fw-bold">{label}
            </Typography><br /> <Typography className="text-secondary" variant={'body2'} color={'textPrimary'}>{selecteditem ? selecteditemlabel : null}  </Typography>
            </div>}
        </Fragment >
    }
    else return (
        //Updated by vijaya : Added the key 'error' in formhelpertext to display the message in red color.
        <Fragment>
            <FormControl component="fieldset" required={props.required} className={classes.root} >
                {tooltip &&
                    <FormLabel component={labelcomp}>{label}&nbsp;< Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                        <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }}  onClick={(evt) => contexthelp ?
                    DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()}/>
                    </Tooltip></FormLabel>}
                {!tooltip &&
                    <FormLabel component={labelcomp}>{label}</FormLabel>}
                <RadioGroup
                    name={name} className="text-secondary"
                    value={value || (defaultValue ? defaultValue : '')}
                    onChange={handleFieldChange}
                >
                    {lists && lists.map(each => {
                        const { options, key } = each
                        //const { value, label, name } = options
                        let value, label
                        if (transformProps) {
                            value = each[transformProps.value]
                            label = each[transformProps.label || transformProps.name]
                        }
                        else {
                            value = options.value
                            label = options.label
                        }
                        return <FormControlLabel key={key || value + 'item'} value={value} control={<Radio />} label={<span style={{ fontSize: '12px' }}>{label}</span>} />
                    })}

                    <FormHelperText error>{props.error && props.helperText}</FormHelperText>
                </RadioGroup>
            </FormControl>
        </Fragment>
    )

}



export default CRadioGroup;



/**
* {
* type: 'radiogroup',
* options:{
    label:'',
    labelcomp: "legend",
    controlcomp:'fieldset',

    items:[{
        type:'radio',
        options:{

        }
    }]
* label: '',
        * },
* defaults:{
* theme: 'default'
        * }
        * }
*/