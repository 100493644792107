import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { loadModules } from 'esri-loader';
class CPrint extends Component {
    constructor(props) {
        super(props); 
        this.state =  { 
            view: this.props.view
        } 
        this.startup(
            this.props
        ); 
    }     
    startup = (config) => {
        loadModules([
            "esri/core/watchUtils",
            "esri/widgets/Expand",
            "esri/widgets/Print"           
        ]).then(([watchUtils, Expand, Print]) => { 
            let prntServiceURL = "";
            if(config.options && config.options.printOptions && config.options.printOptions.serviceURL)
                prntServiceURL =config.options.printOptions.serviceURL;

            var print = new Print({
                view: config.view,
                // specify your own print service
                printServiceUrl: prntServiceURL
            });

            this.printcontainer = ReactDOM.findDOMNode(this);            
            const printExpand = new Expand({
                collapseTooltip: "Collapse Print Widget",
                expandTooltip: "Expand Print Widget",
                view: config.view,
                content: print,
                container: this.printcontainer
            });
            config.view.ui.add(printExpand, "top-right");
            this.props.widgetRenderCallBack("Print");
            const inThis = this;
            watchUtils.pausable(printExpand, "expanded", function (newValue, oldValue) { 
                inThis.props.onexpanded(printExpand, newValue);
            });
        });
    }
     

    render() {
        return (
            <div className="Print-box" style={{order:this.props.order}}>
                
            </div>
        );
    }
}

export default CPrint;