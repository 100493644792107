import { getEnvVariable } from "../../../../modules/environmentalList"

const GeneralsettingsAdd = {
    "type": "popup",
    "key": "GeneralsettingsAdd",
    "name": "GeneralsettingsAdd",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'addOpen'
    },
    sharedValues: ['open'],
    container: {
        values: { save: false, back: false, fullscreen: false, },
        mapValues: {
            header: [
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
            ],
            AddGeneralsettings: [{ key: 'save', name: 'save' },
            { key: 'exitfullscreen', name: 'exitfullscreen' },
            { key: 'fullscreen', name: 'fullscreen' },]
        },
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },


            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'AddGeneralsettings',
                    type: 'div',
                    className: 'col-12 pl-5 pt-1',
                    sub: {
                        type: 'div',
                        className: 'col-12 pl-5 pt-1',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'settings',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "Add Settings", "materialicon": "settings", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'save',
                        hidevalue: false,
                        title: 'Save Settings'
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hidevalue: false,
                        title: 'FullScreen'
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false,
                        title: 'Exit FullScreen'
                    }
                ],


            },
            AddGeneralsettings: {
                "type": "form",
                "key": "AddGeneralsettings",
                "name": "AddGeneralsettings",
                "routeParams": { replace: 'settings', param: 'Id', routeName: 'TableName' },
                aliases: {
                    save: 'save',
                },
                sharedValues: ['save', 'exitfullscreen', 'fullscreen'],
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Name',
                                type: 'div',
                                className: 'col-md-10 col-12',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-12',
                                }
                            }],

                        },

                        {
                            order: 1,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Description',
                                type: 'div',
                                className: 'col-md-10 col-12',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-12',
                                }
                            },
                           
                           ]
                        },
                        {
                            order: 2,
                            type: 'div',
                            className: 'row',
                            components: [
                            {
                                name: 'Type',
                                type: 'div',
                                className: 'col-md-10 col-12',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-12',
                                }
                            },
                            ]
                        },
                        {
                            order: 3,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Value',
                                type: 'div',
                                className: 'col-md-10 col-12',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-12'
                                }
                            },
                         ]
                        },
                        // {
                        //     order: 4,
                        //     type: 'div',
                        //     className: 'row',
                        //     components: [{
                        //         name: 'Status',
                        //         type: 'div',
                        //         className: 'col-md-10 col-12',
                        //         sub: {
                        //             type: 'div',
                        //             className: 'col-md-4 col-lg-3'
                        //         }
                        //     },
                        //  ]
                        // },
                    ],
                },
                values: {
                    Name: '',
                    Description: '',
                    Type: '',       
                    Value: '',
                    Status: 'I'
                },

                "components": {
                    Name: {
                        "type": "textfield",
                        "key": "Name",
                        "label": "Name",
                        "name": "Name",                  
                    },
                    Description: {
                        "type": "textfield",
                        "key": "Description",
                        "label": "Description",
                        "name": "Description",
                        multiline : true
                    },
                    Type: {
                        "type": "dropdown",
                        "key": "Type",
                        "label": "Type",
                        "name": "Type",
                        extraProps: {
                            transformProps: {
                                label: 'Name',
                                value: 'Value'
                            },
                        }
                    },
                    Value: {
                        "type": "textfield",
                        "key": "Value",
                        "label": "Value",
                        "name": "Value",
                    },
                    // Status: {
                    //     "type": "textfield",
                    //     "key": "Section",
                    //     "label": "Section",
                    //     "name": "Section",
                    // },
                },
                mapActionsToEffects: {
                    'onLoad': {
                    init: {
                        rules: [{
                            run:
                            {
                                valid: [{
                                    type: 'load',
                                    name: 'Type',
                                    "read": [
                                        {
                                            url: getEnvVariable('Admin')+`/api/Domains/values?domainkey=SETTING_TYPE`,
                                            type: 'get',
                                            saveAs: {
                                                key: 'items'
                                            }
                                        }
                                    ]
                                },
                                {
                                    type: 'validations', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                    "read": [
                                        {
                                            "url": getEnvVariable('MasterAdmin')+`/api/ProjectMessages/RulesByName?name=master.appsettings`,
                                            type: 'get'
                                        }
                                    ],
                                    transformProps: {
                                        removesubfieldname: 'MASTER.APPSETTINGS_'
                                    }
                                },
                                ]
                            }
                        }]
                    }
                },
                'onChange': {
                    'exitfullscreen': {
                        rules: [
                            {
                                run: {
                                    valid: [
                                        {
                                            type: 'exitfullscreen',

                                        },

                                    ]
                                }
                            }
                        ]
                    },
                    'fullscreen': {
                        rules: [
                            {
                                run: {
                                    valid: [
                                        {
                                            type: 'fullscreen',

                                        },

                                    ]
                                }
                            }
                        ]
                    },
                
                },
                'afterSave': {'save': {
                        rules: [
                            {
                                run: {
                                    valid: [
                                        {
                                            type: 'save',
                                            "read": [
                                                {
                                                    "url": getEnvVariable('Admin')+`/api/AppSettings/Add`,
                                                    type: 'post',
                                            
                                                }
                                            ],
                                        },

                                    ]
                                }
                            }
                        ]
                    },
                }}
            }
        },

    }
}

export default GeneralsettingsAdd