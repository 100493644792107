import React, { useRef, useEffect, useState } from 'react';
//import './App.css';
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getEnvVariable } from '../../../modules/environmentalList';

export default function TemplateView({ callBack, templateFormatsCallBack, setSubject, setAttachment,setReportsVal, ...props }) {
  const frameView = useRef(null);
  const [reload, setReload] = useState(false)

  useEffect(() => {
    const messageHandler = (message) => {
      if (getEnvVariable('TemplateURL') + ``.includes(message.origin)) {
        if (frameView.current && frameView.current.contentWindow) {
          console.log(message)
          if (message.data === 'Initialized')
            frameView.current.contentWindow.postMessage({ prop: { ...props } }, "*")
          if (message.data && message.data.messagetype === 'templateformat')
            templateFormatsCallBack(message.data)
          else if (message.data && message.data.messagetype === 'subject')
            setSubject(message.data)
          else if (message.data && message.data.id)
            callBack(message.data)
          else if (message.data && message.data.messagetype === 'attachments')
          setAttachment(message.data)
          else if (message.data && message.data.messagetype === 'reports')
          setReportsVal(message.data)
          else if (message.data && message.data.messagetype === 'validtemplate'){
            console.log(message.data);
          }

        }
      }
    }
    window.addEventListener('message', messageHandler)
  }, [])

  useDeepCompareEffect(() => {
    setTimeout(() => {
      if (frameView.current && frameView.current.contentWindow) {
        setReload(!reload)
        frameView.current.contentWindow.postMessage({ prop: { ...props } }, "*")
      }
    }, 400)

  }, [props])

  // useEffect(() => {
  //   if (props.save && frameView.current && frameView.current.contentWindow) {
  //     setReload(!reload)
  //     frameView.current.contentWindow.postMessage({ prop: { ...props } }, "*")
  //   }
  // }, [props.save])

  // useEffect(() => {
  //   if (frameView.current && frameView.current.contentWindow) {
  //     setReload(!reload)
  //     frameView.current.contentWindow.postMessage({ prop: { ...props } }, "*")
  //   }
  // }, [props.isEnabled])

  return (<div style={{ height: props.fullheight ? window.innerHeight : window.innerHeight - 210 }}>
    {reload}
    <iframe id='template-editor' src={getEnvVariable('TemplateURL') + ``} ref={frameView}
      frameBorder="0"
      marginHeight="0"
      marginWidth="0"
      width="100%"
      height="100%"
      title='Template Editor'
      scrolling="auto">
    </iframe>
  </div>)
}

// class Fancy extends React.Component {
//   constructor(props) {
//     super(props)
//     this.TemplateForm = React.createRef()
//   }

//   componentDidMount() {
//   }

//   saveTemplate = () => {
//     this.TemplateForm.current.saveTemplate();
//   }

//   GetTemplateFormats = () => {
//     this.TemplateForm.current.GetTemplateFormats();
//   }

//   render() {

//     return (
//       <div>
//         <SyncEditor ref={this.TemplateForm} {...this.props}/>

//       </div>
//     );
//   }
// }

// export default Fancy;


