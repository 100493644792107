/**
      *********************************************************
     deprecated
      *********************************************************
**/

import React, { Component, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

class CTextBox extends Component {
    constructor(props) {
        super(props);
        this.subform = {};
        this.state = {
            globalitem: null
        }
    }


    handleChange = (name, validations) => event => {
        const target = event.target;
        if (target) {
            const value = target && target.value;
            let invalid = ''
            if (validations && (validations.length > 0)) {
                validations && validations.forEach(each => {
                    if (validations[each]) {
                        const returnval = validations[each](value)
                        if (returnval) invalid += returnval + '/n'

                    }
                    if (invalid !== '') {
                        if (this.props.setFormFieldValue) {
                            this.props.setFormFieldValue(name, value, true, invalid)
                        }
                    }
                    else {
                        if (this.props.setFormFieldValue) {
                            this.props.setFormFieldValue(name, value)
                        }
                    }
                })
            }
            else {
                if (this.props.setFormFieldValue) {
                    this.props.setFormFieldValue(name, value)
                }
            }
        }
    }


    render() {
        const { options, rules } = this.props;
        const { id, label, name, type, placeholder, helperText, validations, disabled, required, menuProps, inputLabelProps, inputattrprops, inputprops, inputadornmentprops, margin, ...otherOptions } = options
        const { subform, initialvalue } = options
        const { value, error } = this.props.currentValue
        let helpfultext
        if (error) helpfultext = this.props.currentValue.helperText
        let ainputadornment = {}
        if (inputadornmentprops) {
           let  position = inputadornmentprops.position || 'start'
            ainputadornment = (position === 'start') && <InputAdornment {...inputadornmentprops} />
            if (position === 'end') {
                ainputadornment = {
                    endAdornment: <InputAdornment position={position}>
                        {inputadornmentprops.children}
                    </InputAdornment>
                }
            }
            else {
                ainputadornment = {
                    endAdornment: <InputAdornment position={position}>
                        {inputadornmentprops.children}
                    </InputAdornment>
                }
            }
        }


        return (
            <Fragment>
                <TextField
                    fullWidth
                    disabled={disabled}
                    error={error}
                    type={type}
                    required={required}
                    label={label}
                    margin={margin || 'none'}
                    value={value || initialvalue || ''}
                    name={name}
                    onChange={this.handleChange(name, validations)}
                    placeholder={placeholder}
                    helperText={helpfultext || helperText}
                    InputProps={{ ...ainputadornment, ...inputprops }}
                    inputProps={inputattrprops}
                    InputLabelProps={{shrink: true}}
                    {...otherOptions}
                />
                {this.state.globalitem}
            </Fragment>
        )
    }
}

export default CTextBox;


/**
 * {
 * type:'textbox',
 * options:{
 * id:'standard-name',
 * label:'name',
 * value:'textbox',
 * name:'textbos'
 * },
 * defaults:{
 * theme:'material',
 * }
 * }
 */


        // if (target || initialvalue) {
        //     const value = target && target.value;
        //     this.setState({
        //         [name]: value || initialvalue
        //     });
        // }

        // if (rules) {
        //     const changedval = checkcomp(value, rules, 'onChange', this.props.passer)
        //     this.setState({
        //         globalitem: changedval
        //     })
        // }

// if (rules) {
//     const { add, del, edit, styling } = rules

//     if (add || del || edit || styling) {
//         const { mapeventstovalues, scope, items } = add
//         if (mapeventstovalues.onChange) {
//             passaddcomp(mapeventstovalues.onChange)
//             if (scope) {
//                 const { passer, passertype, passervar } = this.props
//                 const SpecificPasser = passer
//                 let passeraction
//                 if (passer) {
//                     this.setState({
//                         globalitem: <SpecificPasser type={passertype} message={items} />
//                     })
//                 }
//                 this.setState({
//                     textelement: <Fragment>
//                         {this.state.globalitem}
//                     </Fragment>
//                 })

//             }
//             else {
//                 this.props.dropdownchange(items)
//             }
//         }
//         else return
//     }
//     if (del) {
//         const { mapeventstovalues, scope, items } = del
//         if (mapeventstovalues.onChange) {
//             if (scope) {
//                 const { passer, passertype, passervar } = this.props
//                 const SpecificPasser = passer
//                 let passeraction
//                 if (passer) {
//                     this.setState({
//                         globalitem: <SpecificPasser type={passertype} message={items} />
//                     })
//                 }
//                 this.setState({
//                     textelement: <Fragment>
//                         {this.state.globalitem}
//                     </Fragment>
//                 })

//             }
//             else {
//                 this.props.dropdownchange(items)
//             }
//         }
//         else return
//     }
//     if (edit) {
//         const { mapeventstovalues, scope, items } = edit
//         if (mapeventstovalues.onChange) {
//             if (scope) {
//                 const { passer, passertype, passervar } = this.props
//                 const SpecificPasser = passer
//                 let passeraction
//                 if (passer) {
//                     this.setState({
//                         globalitem: <SpecificPasser type={passertype} message={items} />
//                     })
//                 }
//                 this.setState({
//                     textelement: <Fragment>
//                         {this.state.globalitem}
//                     </Fragment>
//                 })

//             }
//             else {
//                 this.props.dropdownchange(items)
//             }
//         }
//         else return
//     }
//     if (styling) {
//         const { mapeventstovalues, scope, items } = styling
//         if (mapeventstovalues.onChange) {
//             if (scope) {
//                 const { passer, passertype, passervar } = this.props
//                 const SpecificPasser = passer
//                 let passeraction
//                 if (passer) {
//                     passeraction = <SpecificPasser type={passertype} message={items} />
//                 }
//                 this.setState({
//                     textelement: <Fragment>
//                         {this.state.globalitem}
//                     </Fragment>
//                 })

//             }
//             else {
//                 this.props.dropdownchange(items)
//             }
//         }
//         else return
//     }
// }