/**
      *********************************************************
      Description: handles the request to getdata, post data or put data from the service.
      Required props:  url is required for all the functions.
      limitations    : N/A
      *********************************************************
**/

import axios from "axios";
import { getToken, GetFetchProps } from "../header";



export const GetData = (url, queryval) =>
    fetch(`${url + queryval}`, {
        method: 'GET',
        ...GetFetchProps()
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            }
            else {
                throw response.json();
            }
        })


export const PostData = (url, bodyval) => {
    return fetch((`${url}`), {
        method: 'POST',
        ...GetFetchProps(),
        body: JSON.stringify(bodyval)

    }).then(response => {
        if (response.ok) { console.log('ok response', response); return response.json() }
        else {
            throw response.json();
        }
        //return response.json()
    })
}

export const PostDataNoBody = (url) => {
    return fetch((`${url}`), {
        method: 'POST',
        ...GetFetchProps(),
    }).then(response => {
        if (response.ok) { console.log('ok response', response); return response.json() }
        else {
            throw response.json();
        }
        //return response.json()
    })
}

export const PostFormData = (url, bodyval) => {
    let formData = new URLSearchParams();
    const bodyvalkeys = Object.keys(bodyval)
    bodyvalkeys.forEach(each => {
        formData.set(each, bodyval[each])
    })

    return fetch((`${url}`), {
        method: 'POST',
        ...GetFetchProps(),
        body: formData

    }).then(response => {
        if (response.ok) { console.log('ok response', response); return response.json() }
        else {
            throw response.json();
        }
        //return response.json()
    })
}

export const PutData = (url, bodyval) => {
    if (bodyval) {
        return fetch((`${url}`), {
            method: 'PUT',
            ...GetFetchProps(),
            body: JSON.stringify(bodyval)

        }).then(response => {
            if (response.ok) { console.log('ok response', response); return response.json() }
            else {
                throw response.json();
            }
            //return response.json()
        })
    }
    else {
        return fetch((`${url}`), {
            method: 'PUT',
            ...GetFetchProps(),
            body: {}

        }).then(response => {
            if (response.ok) { console.log('ok response', response); return response.json() }
            else {
                throw response.json();
            }
            //return response.json()
        })
    }
    // else {
    //     return fetch((`${url}`), {
    //         method: 'PUT',
    //         headers: {
    //             //'Content-Type': 'application/x-www-form-urlencoded',
    //             'Authorization': token || '',
    //
    //         }

    //     }).then(response => response.json()).catch(res => res)
    // }

}

export const PutFormData = (url, bodyval) => {
    let formData = new URLSearchParams();
    const bodyvalkeys = bodyval && Object.keys(bodyval)
    bodyvalkeys && bodyvalkeys.forEach(each => {
        formData.set(each, JSON.stringify(bodyval[each]))
    })
    if (bodyval) {
        return fetch((`${url}`), {
            method: 'PUT',
            ...GetFetchProps(),
            body: JSON.stringify(bodyval)

        }).then(response => {
            if (response.ok) { console.log('ok response', response); return response.json() }
            else {
                throw response.json();
            }
            //return response.json()
        })
    }
    else {
        return fetch((`${url}`), {
            method: 'PUT',
            ...GetFetchProps(),
            body: {}

        }).then(response => {
            if (response.ok) { console.log('ok response', response); return response.json() }
            else {
                throw response.json();
            }
            //return response.json()
        })
    }
    // else {
    //     return fetch((`${url}`), {
    //         method: 'PUT',
    //         headers: {
    //             //'Content-Type': 'application/x-www-form-urlencoded',
    //             'Authorization': token || '',
    //
    //         }

    //     }).then(response => response.json()).catch(res => res)
    // }

}
// export const UpdateRecord = (route, record) => {
//     return axios.put(`${api}/${route}`, record, {
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     }).then(res => res.data).catch(ex => { throw ex })
// }

export const DeleteRecord = (url, queryval) =>
    fetch(`${url + queryval}`, {
        method: 'DELETE',
          ...GetFetchProps(),
    }).then(response => {
        if (response.ok) {
            return response.json()
        }
        else {
            throw response.json();
        }
    })
