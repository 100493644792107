/**
      *********************************************************
      Description: Dropdown component. .
      Required props:  type,value,dropdownvalues.
      limitations    : N/A.
      *********************************************************
**/

import {
    InputAdornment, ListSubheader,
    TextField, Tooltip, Box
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SearchIcon from '@material-ui/icons/Search';
import React, { useMemo, useState } from "react";
import DefaultSettings from './settings';
import Typography from '@material-ui/core/Typography';
import { getColor } from '../../controls/components/custom/customcolor';
const _ = require('lodash');

const defaults = {
    transformProps: {
        label: 'Name',
        value: 'Id'
    }
}
export default function Dropdown(props) {
    const { value, handleFieldValue, hidedefault, extraProps, shareProps, error, required, tooltip, contexthelp, menuDisabled, className, IsSelectable, disablekey, ...otherProps } = props
    const { label, helperText, items, subname, name, fullWidth, disabled } = otherProps
    let { transformProps, dataAttributes, nested, } = extraProps || {}
    let allitems = subname && items ? items[subname] : items;
    transformProps = transformProps || defaults.transformProps
    dataAttributes = dataAttributes || [];
    let val = value;
    let defval = null;
    const [searchText, setSearchText] = useState("");

    const handleFieldChange = (e) => {
        if (!(_.isUndefined(e.target.value))) {
            handleFieldValue(e);
        }
    }

    if (!(allitems || []).some((o) => o[transformProps.value] === "") && (allitems || []).some((o) => o['isDefault'])) {
        (allitems || []).unshift({ [transformProps.label]: "emptyspace", [transformProps.value]: "", })
    }



    if (allitems && allitems.length > 0 && (!value || value === '' || value === undefined)) { //transformProps.default &&
        let defObj = hidedefault ? null : (allitems || []).find(i => i['IsDefault'] === 'Y' || i['isDefault'] === 'Y' || i['IsConfigurable']==='Y')
        if (defObj) {
            defval = defObj[transformProps.value] ? defObj[transformProps.value] : ('' || null)
            if (defval && (val === undefined || value === undefined || val === null || val === '' || value === "") && props.name) {
                let e = { target: { name: name, value: defval }, currentTarget: { dataset: null } }
                handleFieldChange(e);
            }
        }

    }


    const containsText = (text, searchText) => {
        if (text)
            return text && text.toString().toLowerCase().indexOf(searchText.toString().toLowerCase()) > -1;
    }

    const displayedOptions = useMemo(() => (allitems || []).filter((option) => {
        return containsText((nested && option[nested]) ? option[nested][transformProps.label] : option[transformProps.label], searchText)
    }), [searchText, allitems]);

    const isDisabled = (key) => {
        if (menuDisabled) {
            return menuDisabled && menuDisabled[key] ? true : false;
        }
        else if (disablekey) {
            return disablekey && Array.isArray(disablekey) && disablekey.includes(key) ? true : false
        }
        else if (IsSelectable) {
            return IsSelectable === 'Y' ? true : false
        }
        else {
            return false
        }
    }

    return (
        <Box className={className} >
            <FormControl fullWidth={fullWidth ? fullWidth : true} error={error} disabled={disabled || false} required={required}  >
                <InputLabel style={{ fontWeight: 'bold', color: required ? getColor() : (error ? 'red' : 'black') }} shrink>{label}{tooltip && <Tooltip title={tooltip ? tooltip : null}
                    placement="bottom-start" ><HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#007bff', marginLeft: 5 }} onClick={(evt) => contexthelp ?
                        DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                </Tooltip>}</InputLabel>
                <Select
                    value={(val || val === "") ? val : (defval || '')} //|| defaultvalue
                    onChange={handleFieldChange}
                    inputProps={{
                        name: name
                    }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        autoFocus: false,
                        getContentAnchorEl: null
                    }}
                    error={error}
                    onClose={() => setSearchText("")}
                    className="text-secondary"
                >
                    <ListSubheader style={{ position: 'inherit' }}>
                        <TextField
                            size="small"
                            // Autofocus on textfield
                            variant='outlined'
                            autoFocus
                            placeholder="Type to search..."
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                    e.stopPropagation();
                                }
                            }}
                        />
                    </ListSubheader>
                    {displayedOptions && displayedOptions.length > 0 && displayedOptions.map((each, index) => {
                        const { label, value } = transformProps;
                        let nestedSelected = null
                        const name = nested ? each[nested][label] : each[label]
                        const key = nested ? each[nested][value] : each[value]
                        if (nested && each[nested] && each[nested].IsSelectable)
                            nestedSelected = each[nested].IsSelectable
                        let isSelectable = each.isSelectable || each.IsSelectable || nestedSelected || null
                        const dataAttr = dataAttributes.reduce((accum, e) => {
                            const { key, name } = e
                            accum[key] = each[name]
                            return accum
                        }, {})
                        return <MenuItem value={key || ''}
                            disabled={isSelectable ? (isSelectable === 'Y' ? false : true) : isDisabled(key)}
                            {...dataAttr}><Typography variant="inherit" >  <pre style={{ fontFamily: 'inherit', margin: '0px', color: val === key ? 'grey' : 'black' }}>
                                {name === "emptyspace" ? "" : name}
                            </pre></Typography> </MenuItem> // {//index }
                    })}
                </Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </Box>
    )
}