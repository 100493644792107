import React, { Fragment, Component } from "react";
import { DateTimePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DefaultSettings from "../../controls/components/settings";
import DateTimeePicker from '../../controls/components/datetimepicker'

class InlineTimePickerDemo extends Component {

    handleChange = (e) => {
        //const { handleFieldChange, value, name, id, edit, options, ...otherProps } = this.props
        //  let convertMoment = e ? moment(e, 'MM/DD/YYYY hh:mm:ss a').format('YYYY-MM-DDThh:mm') : null
        //let events = { target: { value: e, name: name, checked: false } }
        this.props.handleFieldChange(e);
    }

    render() {
        const { className, type, FieldProps, tooltip, helperText, required, error, handleFieldChange, name, options, value, edit, contexthelp } = this.props
        const { label, disabled } = options;
        if (edit === false) {
            return <Fragment>
                {(tooltip || contexthelp) && <div > <Typography variant={'caption'} className="text-secondary">{label}&nbsp;
                    < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                        <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} onClick={() => DefaultSettings.showDrawer(contexthelp)} />
                    </Tooltip>  </Typography>   <Typography variant={'body2'} color={'textPrimary'} className="fw-bold">{value && moment(value).format('MM/DD/YYYY hh:mm:ss a') || ''}   </Typography>
                </div>}
                {!(tooltip || contexthelp) && <div > <Typography variant={'caption'} className="text-secondary">{label}&nbsp; </Typography>   <Typography variant={'body2'} color={'textPrimary'} className="fw-bold">{value && moment(value).format('MM/DD/YYYY hh:mm:ss a') || ''}   </Typography>
                </div>}
            </Fragment >
        }
        else return (           
                <DateTimeePicker
                    variant="filled"
                    value={value}
                    disabled={disabled || false}                 
                    handleFieldValue={this.handleChange}
                    label={label}
                    name={name}
                    FieldProps={FieldProps && JSON.stringify(FieldProps) || null}
                    contexthelp ={contexthelp}
                    required={required}
                    error={error}
                    helperText={helperText}
                />
        );
    }
}

export default InlineTimePickerDemo;