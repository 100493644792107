import { getEnvVariable } from '../../../../modules/environmentalList';
//import { requestApi } from '../../form/utilities'
import { createRequest, requestApi } from '../../../controls/helpers/rest';
import {GetLookUpValues} from './lookupDataTaskKEPT'

export function SaveMultipleLocationsSingle(data, id) {
  return new Promise((resolve, reject) => {
          console.log('Saving multiple location information ', data, id)       
          let datakeys = [{
              url: getEnvVariable('Application') + `/api/MultipleLocation/${id}/AddMultiple`,
              "routeProps": [],
              type: 'post',
              alert: true,
              body: [data]
          }]
          Promise.all(datakeys.map(each => requestApi(createRequest(each, each.body, null)))).then(multiplelocationdata => {
              console.log(multiplelocationdata, ' Saved')
              resolve(multiplelocationdata)
          }).catch(error => {
              console.error('Error in saving multiple locations')
              console.log(error);
              reject(error)
          })
  })
}

export function SaveMultipleLocations(data, id) {
  return new Promise((resolve, reject) => {
    console.log('Saving multiple location information ', data, id)
        if (Array.isArray(data) && data.length > 0) {
          let datakeys = [{
              url: getEnvVariable('Application') + `/api/MultipleLocation/${id}/AddMultiple`,
              "routeProps": [],
              type: 'post',
              alert: true,
              body: data
          }]
          Promise.all(datakeys.map(each => requestApi(createRequest(each, each.body, null)))).then(multiplelocationdata => {
              console.log(multiplelocationdata, ' Saved')
              resolve(multiplelocationdata)
          }).catch(error => {
              console.error('Error in saving multiple locations')
              console.log(error);
              reject(error)
          })
      }
      resolve({})
  })        
}

export function UpdateMultipleLocations(id, refid, data) {
    return new Promise((resolve, reject) => {
      console.log('Saving multiple location information ', data, id)
      let datakeys = [{
          url: getEnvVariable('Application') + `/api/MultipleLocation/${refid}/Update/${id}`,
          "routeProps": [],
          type: 'put',
          alert: true,
          body: data
      }]
      Promise.all(datakeys.map(each => requestApi(createRequest(each, each.body, null)))).then(multiplelocationdata => {
          console.log(multiplelocationdata, ' Saved')
          resolve(multiplelocationdata)
      }).catch(error => {
          console.error('Error in updating multiple locations')
          console.log(error);
          reject(error)
      })      
    })        
}

export function DeleteMultipleLocations(objctids, id) {
  return new Promise((resolve, reject) => {
    console.log('Deleting multiple location information ', objctids)
        if (Array.isArray(objctids) && objctids.length > 0) {
          let datakeys = [{
              url: getEnvVariable('Application') + `/api/MultipleLocation/${id}/Delete`,
              "routeProps": [],
              type: 'post',
              alert: false,
              body: objctids
          }]
          Promise.all(datakeys.map(each => requestApi(createRequest(each, each.body, null)))).then(multiplelocationdata => {
              console.log(multiplelocationdata, ' Saved')
              resolve(multiplelocationdata)
          }).catch(error => {
              console.error('Error in saving multiple locations')
              console.log(error);
              reject(error)
          })
      }
      resolve({})
  })
}

export function SetLocationIsPrimary(parentid, id) {
  return new Promise((resolve, reject) => {
        console.log('Setting primary for the location id ', parentid, id)
        let datakeys = [{
            url: getEnvVariable('Application') + `/api/MultipleLocation/${parentid}/SetPrimary/${id}/Y`,
            "routeProps": [],
            type: 'get',
            //alert: true              
        }]
        Promise.all(datakeys.map(each => requestApi(createRequest(each, {}, null)))).then(multiplelocationdata => {
            console.log(multiplelocationdata, ' Saved')
            resolve(multiplelocationdata)
        }).catch(error => {
            console.error('Error in saving multiple locations')
            console.log(error);
            reject(error)
        })
  })
}

export function SetApplicationFieldsData(sitepermittypeid, refid, rowdata,validation){
  return new Promise((resolve, reject) => {
    let ref = GetRecordValuesFromPrimaryLocation(rowdata);
    ref['LATITUDE'] = rowdata.GeomLatitude
    ref['LONGITUDE'] = rowdata.GeomLongitude
    ref['DISTRICT_ID'] = validation[0].Id
      

    UpdateApplicationGISFields(sitepermittypeid, refid, ref).then(appres => {              
          SetLocationIsPrimary(refid,rowdata.Id).then(locdata => {
            console.log(locdata);
            resolve(locdata)
          })
    })
  })
}

export function GetRouteIndexDefinition(index, rtq){
  try {
    let rtqsplitter = rtq.split('-');
    if(rtqsplitter.length > 0){
      return rtqsplitter[index].trim()
    }
  } catch (error) { }
  return ""
}

export function StringTruncate(num, length){
  try {
      return num.toFixed(length)
  } catch (error) {
    
  }
  return num;
}

export function GetRecordValuesFromPrimaryLocation(rowdata){ 
  const regex = new RegExp("^0+(?!$)",'g');
  let routeuniquenumberval = rowdata.RouteUniqueNumber
    if(rowdata.RouteUniqueNumber) {
      routeuniquenumberval = (GetRouteIndexDefinition(2, rowdata.RouteUniqueNumber)).toString()
      routeuniquenumberval = routeuniquenumberval.replaceAll(regex, "");
    }
    let ref = { }
    ref['LATITUDE_ROAD_POSITION'] = rowdata.Latitude
    ref['LONGITUDE_ROAD_POSTION'] = rowdata.Longitude    
    ref['APPROX_YARD_SIZE_BASED_ON_MAP_IN_ACRES_'] = StringTruncate(rowdata.GeomArea,4) 
    ref['ROAD_CLASS'] = rowdata.FunctionalClassValue
    ref['BEGINNING_MILE_POINT'] = rowdata.MilePoint
    ref['ROUTE_UNIQUE_NUMBER'] = rowdata.RouteUniqueNumber    
    ref['COUNTY'] = rowdata.CountyName
    ref['ROUTE_NAME'] = rowdata.Routename
    //ref['DISTRICT_ID'] = rowdata.District
    //ref['ROUTE_TYPE'] = GetRouteIndexDefinition(1, rowdata.RouteUniqueNumber)
    ref['ROUTE_NUMBER'] = GetRouteIndexDefinition(2, rowdata.RouteUniqueNumber)
    ref['ROUTE_SECTION'] = GetRouteIndexDefinition(1, rowdata.RouteUniqueNumber) +' '+ routeuniquenumberval 
    return ref;
}

export function SetApplicationFieldsData_WITHGIS(sitepermittypeid, refid, rowdata){
  return new Promise((resolve, reject) => {
    GetGISFieldsData(sitepermittypeid, {X: rowdata.X, Y:rowdata.Y}).then(ref =>{
      ref['LATITUDE_ROAD_POSITION'] = rowdata.Latitude
      ref['LONGITUDE_ROAD_POSTION'] = rowdata.Longitude
      ref['LATITUDE'] = rowdata.GeomLatitude
      ref['LONGITUDE'] = rowdata.GeomLongitude
      ref['APPROX_YARD_SIZE_BASED_ON_MAP_IN_ACRES_'] = StringTruncate(rowdata.GeomArea,4) 
      ref['ROAD_CLASS'] = rowdata.FunctionalClassValue
      ref['BEGINNING_MILE_POINT'] = rowdata.MilePoint
      ref['ROUTE_UNIQUE_NUMBER'] = rowdata.RouteUniqueNumber
     // ref['DISTRICT_ID'] = rowdata.District
      UpdateApplicationGISFields(sitepermittypeid, refid, ref).then(appres => {              
            SetLocationIsPrimary(refid,rowdata.Id).then(locdata => {
              console.log(locdata);
              resolve(locdata)
            })
      })
    })
  })
} 

export function UpdateApplicationGISFields(sitepermittypeid, refid, data){
  return new Promise((resolve, reject) => {
    let datakeys = [{
          url: getEnvVariable('Application') + `/api/Application/UpdateFields/${sitepermittypeid}/${refid}`,
          type: 'put',
          alert: true,
          body: data
      }]
      Promise.all(datakeys.map(each => requestApi(createRequest(each, each.body, null)))).then(appresults => {
          resolve(appresults)
      }).catch(error => {
          console.error('Error in saving multiple locations')
          console.log(error);
          reject(error)
      })      
  })
}

export function GetGISFieldsData(sitepermittypeid, coordinates) {
  return new Promise((resolve, reject) => {
    let datakeys = [{
          url: getEnvVariable('Application') + `/api/SitePermitSectionFieldsView/AllFieldsGroupBySection/${sitepermittypeid}`,
          "routeProps": [],
          type: 'get'              
      }]
      Promise.all(datakeys.map(each => requestApi(createRequest(each, each.body, null)))).then(totalsections => {
          console.log(totalsections, ' Get');
          let sectionKeys = Object.keys(totalsections);
          let sections = totalsections;
          let GISDomainLookupFields = [];
          let GISLookupData = []
          sectionKeys.forEach((each, index) => {
            let allSectionNames = Object.keys(sections[each])
            allSectionNames.map((sectionname,inx) =>{ 
              sections[each][sectionname].sort((a, b) => a.FieldRowDisplayOrder - b.FieldRowDisplayOrder).map((section, i) => {
                const { FieldKey, Key, FieldValue, FieldName, FieldDisplayName, UIFieldTypeComponent, FieldDescription,
                   SectionName, SectionKey, FieldDecimalPlace, FieldContextHelp,FieldRowDisplayOrder, FieldColDisplayOrder, 
                   FieldMin, FieldMax, IsHidden, FieldFetchDomainKey, FieldFetchGeomType, IsLabel, IsInternal, 
                   FieldIsSpellCheck, FieldDomainKey, FieldProps, FieldIsBuffer, FieldBufferDistance, FieldBufferUnits } = section;
                    if (FieldFetchDomainKey) {
                      let eachDomainLookup = {
                          FieldFetchDomainKey: FieldFetchDomainKey,
                          FieldFetchGeomType: FieldFetchGeomType,
                          FieldKey: FieldKey,
                          FieldIsBuffer: FieldIsBuffer,
                          FieldBufferDistance: FieldBufferDistance,
                          FieldBufferUnits: FieldBufferUnits,
                          UIFieldTypeComponent: UIFieldTypeComponent
                      }
                      GISDomainLookupFields.push(eachDomainLookup);
                      //GISKeys[FieldKey] = each.FieldFetchDomainKey
                  }
                })
            })            
          })
          console.log(GISDomainLookupFields);
          let allkeys = GISDomainLookupFields.map(each => each.FieldFetchDomainKey);
          GetGISFieldsDomainLookup(allkeys).then((results) => {
            GISDomainLookupFields.forEach(function (master) {
                    results[0].forEach(function (domain) {
                        if (domain.Layer && domain.Layer.Url && master.FieldFetchDomainKey === domain.Key) {
                            let obj = {
                                'Id': domain.Id, //MasterField
                                'ServiceUrl': domain.Layer.Url, //Domain
                                'FieldKey': master.FieldKey, //Masterfield
                                'NameReference': domain.NameReference,//Domain
                                'ValueReference': domain.ValueReference, //Domain,
                                'FieldFetchGeomType': master.FieldFetchGeomType,
                                'FieldIsBuffer': master.FieldIsBuffer,
                                'FieldBufferDistance': master.FieldBufferDistance,
                                'FieldBufferUnits': master.FieldBufferUnits,
                                'UIFieldTypeComponent': master.UIFieldTypeComponent,  //-- Feild UI type for tectbox only name should come
                            }
                            GISLookupData.push(obj)
                        }
                    });
                });
                console.log(GISLookupData);
                 //Send for fetching fields data
                 GetLookUpValues(coordinates, GISLookupData).then(lookupres => {
                    console.log(lookupres);
                    resolve(lookupres);
                })
          })
          
      }).catch(error => {
          console.error('Error in saving multiple locations')
          console.log(error);
          reject(error)
      })      
  })
}

export function GetGISFieldsDomainLookup(keys){
  return new Promise((resolve, reject) => {
    let datakeys = [{
          url: getEnvVariable('Application') + '/api/Domains/Keys?domaintype=A',
          type: 'post',
          alert: true,
          body: keys
      }]
      Promise.all(datakeys.map(each => requestApi(createRequest(each, each.body, null)))).then(domainlookup => {
          resolve(domainlookup)
      }).catch(error => {
          console.error('Error in saving multiple locations')
          console.log(error);
          reject(error)
      })      
  })
}





