
//import React from "react";
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from "react-router";
import MapJson from '../../../json/map/map';
import { getEnvVariable } from "../../../modules/environmentalList";
import MapSection from '../../modules/map/map';
import { GetData, PostData } from "../helpers/requests";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { snackbarMessage } from '../../../core/actions';
import { SaveMultipleLocations, SaveMultipleLocationsSingle, DeleteMultipleLocations, UpdateApplicationGISFields } from '../../modules/map/eps/multipleLocationServices'

function MapComponent(props) {
    const { FieldsData, MapSettings, GISLookupData, isAdd, handleMapChanges, TAX_PARCEL_LAYER, fetchGeomType, edit = true, params,
        MultipleLocationData, SitePermitTypeDistricts, fieldValues, statusList } = props
    const [clearSketch, setclearSketch] = useState(false)
    const [status, setStatuses] = useState(statusList || [])

    let MapJsonExtend = {
        ...MapJson,
        options: {
            ...mapJsonOptions(MapJson),
            ///...MapJson.options,
            FieldsData,
            MapSettings,
            MultipleLocationData
        }
    }

    useEffect(() => {
        if (statusList && statusList.length === 0) {
            let url = getEnvVariable('Admin') + '/api/ApplicationStatus/Active';
            GetData(url).then(res => {
                if (res && res.length > 0)
                    setStatuses(res)
            })
        }
    }, [])

    function mapJsonOptions(mapJson) {
        let mapJsonOptions = mapJson.options;
        let taxparcelfield = FieldsData.find(each => each.Fieldkey === "TAX_PARCEL_LAYER")
        let taxparcellayer = TAX_PARCEL_LAYER || (taxparcelfield && taxparcelfield.FieldValue ? taxparcelfield.FieldValue : "")
        if (taxparcellayer && taxparcellayer != "") {
            let taxparcelslots = FieldsData.find(each => each.Fieldkey === "TAX_PARCEL_IDS");
            if (taxparcelslots && taxparcelslots.FieldValue)
                mapJsonOptions.tasks.TaxParcels.queryExpression = taxparcelslots.FieldValue

            let primarytaxparcelslots = FieldsData.find(each => each.Fieldkey === "PRIMARY_TAX_PARCEL_IDS");
            if (primarytaxparcelslots && primarytaxparcelslots.FieldValue)
                mapJsonOptions.tasks.TaxParcels['PRIMARY_TAX_PARCEL_IDS'] = primarytaxparcelslots.FieldValue

            mapJsonOptions.tasks.TaxParcels.url = taxparcellayer;
            mapJsonOptions['LayerOptions'] && mapJsonOptions['LayerOptions'].push({ url: taxparcellayer, })
        }
        let sitepermittype = FieldsData.find(each => each.Fieldkey === "SITE_PERMIT_TYPE_ID");
        if (sitepermittype) {
            mapJsonOptions["SITE_PERMIT_TYPE_ID"] = sitepermittype.FieldValue;
        }
        return mapJsonOptions;
    }

    function handleMapChange(res, wkid = '') {
        if (params.key === 'COLLISION') {
            let url = `${getEnvVariable('GDOTWhatishere')}x=${res.MAP_CENTER_LONGITUDE ? res.MAP_CENTER_LONGITUDE : (FieldsData.find(each => each.Fieldkey === 'MAP_CENTER_LONGITUDE')).FieldValue}
            &y=${res.MAP_CENTER_LATITUDE ? res.MAP_CENTER_LATITUDE : (FieldsData.find(each => each.Fieldkey === 'MAP_CENTER_LATITUDE')).FieldValue}&srid=${4326}`
            //  &lat=${res.MAP_CENTER_LATITUDE}&lon=${res.MAP_CENTER_LONGITUDE}`
            GetData(url).then(ressearch => {
                res['LOCATION_TYPE'] = '5'
                if (ressearch.result && ressearch.result.length > 0 && ressearch.result[0].intersections && ressearch.result[0].intersections.length > 0) {
                    res['LOCATION_TYPE'] = '5'
                }
                else {
                    if (ressearch.result && ressearch.result.length > 0 && ressearch.result[0].road)
                        res['LOCATION_TYPE'] = ressearch.result[0].road.RD_TYPE || '5'
                }
                handleMapChanges(res)
            })
            //LOCATION_TYPE
        }
        else handleMapChanges(res)
    }
    function multipleLocationHandler(data) {
        console.log('from core------', data, params);
        if (params && params.routeid1) {
            SaveMultipleLocationsSingle(data, params.routeid1).then(res => {
                console.log('locations saved', res)
            });
        }
    }

    function multipleLocationRangeHandler(data) {
        console.log('from core------', data, params);
        if (params && params.routeid1) {
            SaveMultipleLocations(data, params.routeid1).then(res => {
                console.log('locations saved', res)
            });
        }
    }

    function handleMapChangesWrapper(res) {
        if (res && Object.keys(res).length > 0 && Object.keys(res)[0] !== 'undefined') {
            if (res && res["DISTRICT_GISID"] && (params.key === 'RECYCLERS' || params.key === 'OUTDOOR' || params.key === 'AAHVOLUNTEER'
                || params.key === 'ENCROACHMENTS' || params.key === 'VIOLATIONS')) {
                let hasdistrictvalue = SitePermitTypeDistricts.filter(a => a.ArcGISBoundaryId == res["DISTRICT_GISID"])
                if (hasdistrictvalue.length > 0) {
                    updateMapChanges(res)
                } else {
                    setclearSketch(!clearSketch);
                    props.snackbarMessage('Location is out of boundaries. Please Select a location within the range.', 'error');
                }
            } else {
                updateMapChanges(res)
            }
        } else {
            setclearSketch(!clearSketch);
            props.snackbarMessage('Location is out of boundaries. Please Select a location within the range.', 'error');
        }
    }

    function updateMapChanges(response) {
        let res = response
        console.log('updated map changes -------', res);
        res['PROPOSED_MAP_CENTER_LATITUDE'] = res['PROPOSED_MAP_CENTER_LATITUDE'] ? res['PROPOSED_MAP_CENTER_LATITUDE'].toFixed(6) : res['PROPOSED_MAP_CENTER_LATITUDE']
        res['PROPOSED_MAP_CENTER_LONGITUDE'] = res['PROPOSED_MAP_CENTER_LONGITUDE'] ? res['PROPOSED_MAP_CENTER_LONGITUDE'].toFixed(6) : res['PROPOSED_MAP_CENTER_LONGITUDE']
        res['MAP_CENTER_LATITUDE'] = res['MAP_CENTER_LATITUDE'] ? res['MAP_CENTER_LATITUDE'].toFixed(6) : res['MAP_CENTER_LATITUDE']
        res['MAP_CENTER_LONGITUDE'] = res['MAP_CENTER_LONGITUDE'] ? res['MAP_CENTER_LONGITUDE'].toFixed(6) : res['MAP_CENTER_LONGITUDE']
        if(res &&  res['SITE_GISID']) {
        res['SITE_ID'] = res['SITE_GISID']
    }
        if (res && res.MultipleLocationData && Array.isArray(res.MultipleLocationData) && res.MultipleLocationData.length > 0) {
            multipleLocationRangeHandler(res.MultipleLocationData);
            res.MultipleLocationData = []
        }
        if (res && res["DELETED_GEOM_OBJECT_ID"] && Array.isArray(res["DELETED_GEOM_OBJECT_ID"]) && params.routeid1 && getEnvVariable('ModuleKey') !== 'AMES') {
            DeleteMultipleLocations(res["DELETED_GEOM_OBJECT_ID"], params.routeid1);
        }
        if (res && (res['POINT_GEOM_OBJECT_ID'] || res['LINE_GEOM_OBJECT_ID'] ||
            res['POLYGON_GEOM_OBJECT_ID'] || res['MULTIPOINT_GEOM_OBJECT_ID'])) {
            let mapupdatefields = {
                'POINT_GEOM_OBJECT_ID': res['POINT_GEOM_OBJECT_ID'],
                'LINE_GEOM_OBJECT_ID': res['LINE_GEOM_OBJECT_ID'],
                'POLYGON_GEOM_OBJECT_ID': res['POLYGON_GEOM_OBJECT_ID'],
                'MULTIPOINT_GEOM_OBJECT_ID': res['MULTIPOINT_GEOM_OBJECT_ID'],
                'CENTER_GEOM_OBJECT_ID': res['CENTER_GEOM_OBJECT_ID']
            }
            if (params.routeid && params.routeid1) {
                UpdateApplicationGISFields(params.routeid, params.routeid1, mapupdatefields).then(res => {
                    console.log('Application gis fields update on single click', res)
                });
            } else {
                handleMapChange(res)
            }

        }
        handleMapChange(res)
    }

    const checkStatus = (id) => {
        let portal = getEnvVariable('AccessPortal');
        let returnVal = true;
        if (status.length > 0) {
            let obj = status.find(each => each.Id.toString() === id.toString() && (each.StatusName === 'Approved' || each.StatusName == 'Completed' ||
                each.StatusName === 'Submitted')) || null //|| (each.StatusName === 'Submitted' && portal == "E")
            if (obj) {
                return returnVal = false
            }
        }
        else return returnVal
    }

    function canEditMap() {
        //  application view or edit
        //  approved == no edit -- done
        //exter after submit = no edit -- done
        //user access level view or edit -- done
        //let stagedetails = localStorage.getItem('stagedetails')
        let portal = getEnvVariable('AccessPortal');
        let returnval = true
        let profile = JSON.parse(localStorage.getItem('profile'))
        let readPermissionsList = (profile && profile.moduleRegionAccess) ? profile.moduleRegionAccess : {}
        let readPermissions = true
        if (Object.keys(readPermissionsList).length > 0) {
            Object.keys(readPermissionsList).forEach(each => {
                if (fieldValues && fieldValues['REGION_ID'] && Object.keys(readPermissionsList).includes(fieldValues['REGION_ID'])
                    && readPermissionsList[each] !== 'Add')
                    readPermissions = false
            })
        }
        // if (portal == "E" && stagedetails != 'Not_Submitted' && edit !== true) {
        //     return false;
        // } else// portal == "I" && 
        if ((readPermissions === false && portal === 'I') || (fieldValues && (checkStatus(fieldValues['APPLICATION_STATUS']) === false)) || (edit !== true)) {
            return false;
        }
        return returnval;
    }

    return (
        <MapSection {...MapJsonExtend}
            queryexpression={props.value}
            onSaveorUpdateMapResponse={(res) => handleMapChangesWrapper(res)}
            taxparcelresponse={() => { }}
            gisDomainLookupData={GISLookupData}
            SubModuleKey={params.key}
            isAdd={isAdd}
            fetchGeomType={fetchGeomType}
            setStateMapInformation={(res) => handleMapChangesWrapper(res)}
            //canEdit={edit ? edit : false}
            canEdit={canEditMap()}
            width='calc(100% - 20px)'
            multipleLocationHandler={(res) => multipleLocationHandler(res)}
            userId={props.accessPermissions ? props.accessPermissions.userId : "1"}
            //mapClear={this.state.mapClear}
            clearsketch={clearSketch}
        />

    )
}
const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

MapComponent = connect(null, mapActions)(MapComponent)
export default withRouter(MapComponent)
