import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

const drawerWidth = 840;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function HelpSideBar(props, ref) {
    const [url, setUrl] = useState(null);
    const classes = useStyles();
    useImperativeHandle(ref, () => ({
        opendrawer: (url) => {
            if (url)
                setUrl(url);
        }
    }))


    const toggleDrawer = () => {
        setUrl(null);
    };

    return (
        <Drawer anchor={'right'} open={url !== null} onClose={() => toggleDrawer()} classes={{ paper: classes.drawerPaper }}>
            <iframe title='iFrameName' width={classes.drawerPaper} height={window.innerHeight - 90} src={url} name="iFrameName" ></iframe>
        </Drawer>
    );


}

export default forwardRef(HelpSideBar);
