import { AppBar, Toolbar } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import Materialicon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import MoreIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import LeftSideBar from '../../../custommodules/newentrancepermit/applicationdrawer';
import { ExternalApplicationSidebar } from '../../../json/newapplication/externalapplicationsidebar';
import { NewApplicationSidebar } from '../../../json/newapplication/newapplicationsidebar';
import { getEnvVariable } from '../../../modules/environmentalList';
import { snackbarMessage } from '../../actions';
import { conditionsHandle } from '../../modules/form/conditions';
import SearchTextBox from '../components/custom/searchtextbox';
import BreadCrumb from './breadcrumbs';
import Button from './buttons';
import RegistrationRequestNav from './custom/registrationrequestnav';
import Fab from './fab';
import Icon from './icons';
import Menus from './menu';
import Text from './text';
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    split: {
        height: '100%',
        width: '40%',
        position: 'fixed',
    },
    left: {
        left: 0,
    },
    right: {
        right: 0,
    }
}));

const fieldTypes = {
    text: Text,
    icon: Icon,
    button: Button,
    fab: Fab,
    breadcrumbs: BreadCrumb,
    searchtextbox: SearchTextBox,
    menu: Menus,
    registrationrequestnav: RegistrationRequestNav
}
const expressionFormats = {
    appendslash: "${label} : ${value} /",
    default: "${label} : ${value}",
}
function KendoHeader(props) {
    const { title, actions, initialloadextrainfo, hassidebartabs, addOnsByName, components, extracomponent, extraactions, extracomponents, reportapplied, sharedProps } = props;
    let openMenuDisplay = (props.width == "sm" || props.width == "xs" || props.width == "md") ? false : true
    const [openMenu, setOpenMenu] = React.useState(openMenuDisplay);
    const [openMenuList, setOpenMenuList] = React.useState(openMenuDisplay);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEld, setMobileMoreAnchorEld] = React.useState(null);
    const [mobileBreadcrumbAnchorEl, setMobileBreadcrumbAnchorEl] = React.useState(null);
    const isBreadcrumbMenu = Boolean(mobileBreadcrumbAnchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isMobileSubMenuOpen = Boolean(mobileMoreAnchorEld);
    const classes = useStyles();
    const dispatch = useDispatch();
    let configtypeAccess = props && props.accessPermissions ? props.accessPermissions.configType == 'R' ? props.accessPermissions.moduleRegionAccess : props.accessPermissions.configType == 'D' ? props.accessPermissions.moduleDistrictAccess : props.accessPermissions.moduleSiteAccess : false

    let roleaccess = configtypeAccess && configtypeAccess ? Object.values(configtypeAccess).includes('Add') ? 'CanAdd' : Object.values(configtypeAccess).includes('Update') ? 'CanEdit' : 'CanRead' : 'CanRead'

    let portal = props.accessPermissions ? props.accessPermissions.portal : null;
    let isAdmin = props.accessPermissions && props.accessPermissions.isAdmin ? props.accessPermissions.isAdmin : 'N'
    let canAdd = isAdmin == 'Y' ? true : !roleaccess && !portal ? true : portal == "I" && roleaccess == "CanAdd" ? true : (portal == "E") ? true : false
    let canEdit = isAdmin == 'Y' ? true : !roleaccess && !portal ? true : portal == "I" && roleaccess == "CanEdit" ? true : (portal == "E") ? true : false
    let canRead = !roleaccess && !portal ? true : portal == "I" && roleaccess == "CanRead" ? true : (portal == "E") ? true : false

    React.useEffect(() => {
        setOpenMenu(openMenuDisplay);
        handleMobileMenuClose();
    }, [props.width]);


    const handleMobileMenuOpen = (event, name) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleBreadcrumbMenu = event => {
        setMobileBreadcrumbAnchorEl(event.currentTarget);
        setOpenMenuList(true)
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
        setMobileBreadcrumbAnchorEl(null);
    };

    const handleMobileSubMenuOpen = (event, name) => {
        setMobileMoreAnchorEld(event.currentTarget);
    }

    const handleMobileSubMenuClose = () => {
        setMobileMoreAnchorEld(null);
    }

    const navigatebreadcrumbmenu = (href) => {
        let path = ''
        if (href && href.routes) {
            if (Array.isArray(href.routes)) {
                href.routes.forEach(each => {
                    const { route, routeParams, matchProps } = each
                    if (routeParams) {
                        path += route + "/" + props.match.params[routeParams]
                    }
                    else if (matchProps) {
                        path += route + "/" + props.results[matchProps]  //correct: results ???  results[matchProps]
                    }
                    else {
                        path += route
                    }
                })
            }
        }
        else {
            path = href
        }
        props.history.push(path)
    }
    if (props.hideinmenu !== true && !openMenu) {
        return (
            <div className="">
                {hassidebartabs && <AppBar position="sticky" color='secondary'>
                    {components && components.map(each => {
                        const { type, name, items, ...otherProps } = each
                        const EachComp = fieldTypes[type]
                        let arr = items ? _.chunk(items, _.findLastIndex(items)) : null
                        if (type == 'breadcrumbs' && arr && arr.length > 1) {
                            return (
                                <div className="row col-sm-11 pl-1 breadcrumbs">
                                    <EachComp items={arr[1]} {...addOnsByName[name]} handleBreadcrumbMenu={handleBreadcrumbMenu} moreicon={true} sharedProps={sharedProps} />
                                    <Menu
                                        anchorEl={mobileBreadcrumbAnchorEl}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        open={isBreadcrumbMenu}
                                        onClose={handleMobileMenuClose}
                                    >
                                        {arr[0].map(each => {
                                            return (
                                                <div >
                                                    < MenuItem onClick={(e) => navigatebreadcrumbmenu(each.menuhref ? each.menuhref : each.href)}>
                                                        <Materialicon style={{ color: "#130080" }}>{each.materialicon}</Materialicon>
                                                        <Typography className="pl-3" style={{ color: "#130080" }} variant={'body2'}>{each.link}</Typography>
                                                    </MenuItem>
                                                    <Divider />
                                                </div>
                                            )
                                        })}
                                    </Menu>
                                </div>
                            )
                        }
                        else return <div><EachComp items={items} {...addOnsByName[name]} sharedProps={sharedProps} /></div>
                    })}
                    {extracomponents && initialloadextrainfo && initialloadextrainfo.results && <div className='flex-grow-1' />}
                    {extracomponents && initialloadextrainfo && initialloadextrainfo.results && extracomponents.map(each => {
                        if (initialloadextrainfo.results[each.value]) {
                            let valuedata = initialloadextrainfo.results[each.value]
                            if (each.trimvalue) {
                                valuedata = (initialloadextrainfo.results[each.value].length > 16 ? `${(initialloadextrainfo.results[each.value]).substring(0, 16)}...` : `${initialloadextrainfo.results[each.value]}`)

                            }
                            let ExpressionType = each.exptype ? expressionFormats[each.exptype] : expressionFormats['default']
                            let labelreplace = ExpressionType.replace("${label}", each.label);
                            let valuereplace = labelreplace.replace("${value}", valuedata);

                            return <Typography variant="body2" gutterBottom>{valuereplace}&nbsp;&nbsp;</Typography>
                        }
                        return null
                    })}
                    <div className='flex-grow-1' />
                    {extraactions && extraactions.map(each => {
                        const { type, name, title, changecolor, hidecondition, hideconditionvalue, hasrolebasedcondition, ...otherProps } = each
                        const EachComp = fieldTypes[type]
                        return <EachComp {...otherProps} name={name} changecolor={changecolor} reportapplied={reportapplied} title={title} {...addOnsByName[name]} sharedProps={sharedProps} matchparams={props.match.params} />
                    })}
                </AppBar>}
                {hassidebartabs &&
                    <LeftSideBar {...((props.AccessPortal == 'E') || (props.accessPermissions && props.accessPermissions.portal == 'E')) ? ExternalApplicationSidebar[getEnvVariable('ModuleKey') + props.match.params.key] : NewApplicationSidebar[getEnvVariable('ModuleKey') + props.match.params.key]}>
                    </LeftSideBar>
                }

                <div className="col-sm-12 col-xs-12 mt-1 grid-actions row">
                    {!extracomponents && components && components.map(each => {
                        const { type, name, items, ...otherProps } = each
                        const EachComp = fieldTypes[type]
                        let arr = items ? _.chunk(items, _.findLastIndex(items)) : null
                        if (type == 'breadcrumbs' && arr && arr.length > 1) {
                            return (
                                <div className="row col-sm-11 pl-1 breadcrumbs">
                                    <EachComp items={arr[1]} {...addOnsByName[name]} handleBreadcrumbMenu={handleBreadcrumbMenu} moreicon={true} sharedProps={sharedProps} />
                                    <Menu
                                        anchorEl={mobileBreadcrumbAnchorEl}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        open={isBreadcrumbMenu}
                                        onClose={handleMobileMenuClose}
                                    >
                                        {arr[0].map(each => {
                                            return (
                                                <div >
                                                    < MenuItem onClick={(e) => navigatebreadcrumbmenu(each.menuhref ? each.menuhref : each.href)}>
                                                        <Materialicon style={{ color: "#130080" }}>{each.materialicon}</Materialicon>
                                                        <Typography className="pl-3 m-3" style={{ color: "#130080" }} variant={'body2'}>{each.link}</Typography>
                                                    </MenuItem>
                                                    <Divider />
                                                </div>
                                            )
                                        })}
                                    </Menu>
                                </div>
                            )
                        }
                        else return <div><EachComp items={items} {...addOnsByName[name]} sharedProps={sharedProps} /></div>
                    })}
                    <div className='flex-grow-1' />
                    {actions && actions.length > 0 && !props.hideinmenu && <IconButton aria-haspopup="true" title="More Items" onClick={handleMobileMenuOpen} color="inherit">
                        <MoreIcon />
                    </IconButton>}
                    <Menu
                        anchorEl={mobileMoreAnchorEl}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={isMobileMenuOpen}
                        onClose={handleMobileMenuClose}
                    >
                        {actions && actions.map(each => {
                            const { type, name, menucolor, hideon, hidevalue, hidecondition, hasrolebasedcondition, hideinexternal, hideconditionvalue, items, ...otherProps } = each
                            let onClickEvents = addOnsByName[name]
                            if (hasrolebasedcondition && !canAdd) {
                                return null
                            }
                            if (hideinexternal && getEnvVariable('AccessPortal') === 'E') {
                                return null
                            }
                            if (hidecondition && initialloadextrainfo && initialloadextrainfo.results) {
                                let condition = null;
                                condition = conditionsHandle({ ...props, "options": { "hidecondition": hidecondition } }, initialloadextrainfo.results)
                                let adminuser = props && props.accessPermissions && (props.accessPermissions.isAdmin == "Y" || props.accessPermissions.isSuperAdmin == "Y") ? true : false
                                if (!adminuser && condition && condition.condition) {
                                    return null
                                }
                                else {
                                    return (
                                        <div className={name}>
                                            < MenuItem onClick={(onClickEvents && onClickEvents.onClick) ? onClickEvents.onClick : ''} >
                                                <Icon icon={each.icon} menudisplay={true} color={menucolor} />
                                                <Typography className="pl-3" variant={'body'} >{otherProps.title}</Typography>
                                            </MenuItem>
                                            <Divider />
                                        </div>
                                    )
                                }
                            }
                            else if (sharedProps && (sharedProps[hideon] === hidevalue)) {
                                return null
                            }
                            else if (hideconditionvalue && getEnvVariable('AccessPortal') === 'E' && JSON.parse(localStorage.getItem('externallock') == "Y")) {
                                return null
                            }
                            else {
                                if (type == "menu") {
                                    return (
                                        <div className={name}>
                                            < MenuItem onClick={handleMobileSubMenuOpen} >
                                                <Icon icon={each.icon} onClick={(e) => handleMobileSubMenuOpen(e)} menudisplay={true} color={menucolor} />
                                                <Typography className="pl-3" variant={'body'} >{otherProps.title}</Typography>
                                            </MenuItem>
                                            {isMobileSubMenuOpen && <Menu
                                                anchorEl={mobileMoreAnchorEld}
                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                open={isMobileSubMenuOpen}
                                                onClose={handleMobileSubMenuClose}
                                            >
                                                {items && items.map(eachs => {
                                                    return (
                                                        <div >
                                                            < MenuItem onClick={(onClickEvents && onClickEvents.onClick) ? (e) => onClickEvents.onClick(eachs.action) : ''} >
                                                                <Typography className="pl-3" variant={'body'} >{eachs.name}</Typography>
                                                            </MenuItem>
                                                            <Divider />
                                                        </div>
                                                    )
                                                })}
                                            </Menu>}
                                            <Divider />
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <div className={name}>
                                            < MenuItem onClick={(onClickEvents && onClickEvents.onClick) ? onClickEvents.onClick : ''} >
                                                <Icon icon={each.icon} menudisplay={true} color={menucolor} />
                                                <Typography className="pl-3" variant={'body'} >{otherProps.title}</Typography>
                                            </MenuItem>
                                            <Divider />
                                        </div>
                                    )
                                }

                            }
                        })
                        }
                    </Menu>
                    {extracomponent && <div className='flex-grow-1' />}
                    {extracomponent && extracomponent.map(each => {
                        const { type, name, title, ...otherProps } = each
                        const EachComp = fieldTypes[type]
                        return <EachComp {...otherProps} name={name} title={title} {...addOnsByName[name]} handleSearchChange={props.handleSearchChange} search={props.search} sharedProps={sharedProps} />
                    })}
                </div>
            </div>
        )
    }
    else {
        return (
            <>
                {hassidebartabs &&
                    <AppBar position="sticky" color='secondary'>
                        <Toolbar variant="dense">
                            {components && components.map(each => {
                                const { type, name, ...otherProps } = each
                                const EachComp = fieldTypes[type]
                                return <div className={type}><EachComp {...otherProps} {...addOnsByName[name]} sharedProps={sharedProps} /></div>
                            })}
                            {extracomponents && initialloadextrainfo && initialloadextrainfo.results && <div className='flex-grow-1' />}
                            {extracomponents && initialloadextrainfo && initialloadextrainfo.results && extracomponents.map(each => {
                                if (initialloadextrainfo.results[each.value]) {
                                    let valuedata = initialloadextrainfo.results[each.value]
                                    if (each.trimvalue) {
                                        valuedata = (initialloadextrainfo.results[each.value].length > 16 ? `${(initialloadextrainfo.results[each.value]).substring(0, 16)}...` : `${initialloadextrainfo.results[each.value]}`)

                                    }
                                    let ExpressionType = each.exptype ? expressionFormats[each.exptype] : expressionFormats['default']
                                    let labelreplace = ExpressionType.replace("${label}", each.label);
                                    let valuereplace = labelreplace.replace("${value}", valuedata);
                                    return <Typography variant="body2" gutterBottom>{valuereplace}&nbsp;&nbsp;</Typography>
                                }
                                return null
                            })}
                            <div className='flex-grow-1' />
                            {extraactions && extraactions.map(each => {
                                const { type, name, title, changecolor, hidecondition, hideconditionvalue, hasrolebasedcondition, ...otherProps } = each
                                const EachComp = fieldTypes[type]
                                return <EachComp {...otherProps} name={name} changecolor={changecolor} reportapplied={reportapplied} title={title} {...addOnsByName[name]} sharedProps={sharedProps} matchparams={props.match.params} />
                            })}
                        </Toolbar></AppBar>
                }
                {hassidebartabs &&
                    <LeftSideBar {...((props.AccessPortal == 'E') || ( props.accessPermissions && props.accessPermissions.portal == 'E')) ? ExternalApplicationSidebar[getEnvVariable('ModuleKey') + props.match.params.key] : NewApplicationSidebar[getEnvVariable('ModuleKey') + props.match.params.key]}>
                    </LeftSideBar>
                }
                <div className="d-flex my-1" style={{ marginRight: '20px', marginLeft: '10px' }}>
                    {!extracomponents && components &&
                        <div>
                            {components.map(each => {
                                const { type, name, ...otherProps } = each
                                const EachComp = fieldTypes[type]
                                return <div className={type}><EachComp {...otherProps} {...addOnsByName[name]} sharedProps={sharedProps} /></div>
                            })}
                        </div>}
                    {actions &&
                        <div className="ms-auto">
                            <div className="row" style={{ marginRight: '5px' }}>
                                {actions.map(each => {
                                    const { type, name, title, changecolor, hidecondition, hideconditionvalue, hasrolebasedcondition, hideinexternal, ...otherProps } = each
                                    if (hasrolebasedcondition && !canAdd) {
                                        return null
                                    }
                                    if (hidecondition && initialloadextrainfo && initialloadextrainfo.results) {
                                        let condition = null;
                                        condition = conditionsHandle({ ...props, "options": { "hidecondition": hidecondition } }, initialloadextrainfo.results)
                                        let adminuser = props && props.accessPermissions && (props.accessPermissions.isAdmin == "Y" || props.accessPermissions.isSuperAdmin == "Y") ? true : false
                                        if (!adminuser && condition && condition.condition) {
                                            return null
                                        }
                                    }
                                    if (hideinexternal && getEnvVariable('AccessPortal') === 'E') {
                                        return null
                                    }
                                    //temp fix
                                    if (hideconditionvalue && getEnvVariable('AccessPortal') === 'E' && JSON.parse(localStorage.getItem('externallock') == "Y")) {
                                        return null
                                    }
                                    const EachComp = fieldTypes[type]
                                    return <EachComp {...otherProps} name={name} changecolor={changecolor} reportapplied={reportapplied} title={title} {...addOnsByName[name]} sharedProps={sharedProps} matchparams={props.match.params} />
                                })}
                            </div>
                        </div>}
                    {/* {extracomponent && <div className='flex-grow-1' />} */}
                    {extracomponent && extracomponent.map(each => {
                        const { type, name, title, ...otherProps } = each
                        const EachComp = fieldTypes[type]
                        return <EachComp {...otherProps} name={name} title={title} {...addOnsByName[name]} handleSearchChange={props.handleSearchChange} search={props.search} sharedProps={sharedProps} />
                    })}
                </div>
            </>
        )
    }
}



const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

KendoHeader = connect(null, mapActions)(KendoHeader)
export default withWidth()(withRouter(KendoHeader));