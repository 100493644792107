import Button from '@material-ui/core/Button';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MultiAdd from '../../core/controls/components/multiadd';
import { PostData } from '../../core/controls/helpers/requests';
import { createRequest, requestApi } from '../../core/controls/helpers/rest';
import { getEnvVariable } from '../../modules/environmentalList';
import S3NativeUpload from '../../modules/Uploads/S3NativeUpload';

const extraProps = {
    transformProps: {
        label: 'Name',
        value: 'Value'
    },
}

export default function AssociatedApplicants(props) {
    const [nonassociated, setNonassociated] = useState([])
    const [associatedResult, setAssociatedResult] = useState([])
    const [openUpload, setOpenUpload] = useState(false)
    const [reference, setReference] = useState(null)
    const [reload, setReload] = useState(false)
    const [information, setInformation] = useState(props.dataItem)
    const params = useParams()

    useEffect(() => {
        localStorage.removeItem('reference')
        getAll()
    }, [])

    const getAll = () => {
        const dropLists = [
            {
                url: getEnvVariable('MasterAdmin') + `/api/ExternalUsersConsultant/AllNonAssociated/${getEnvVariable('ModuleKey')}?ConsultantCompanyId=${params.Id ? params.Id : (information && information.CompanyId)}`,//${company.OfficalUserId}`,
                type: 'get'
            },
            {
                url: getEnvVariable('MasterAdmin') + `/api/ExternalUsersConsultant/allAssociated?ConsultantCompanyId=${params.Id ? params.Id : (information && information.CompanyId)}`,//${company.OfficalUserId}`,
                type: 'get'
            },
        ]
        Promise.all(dropLists.map(each => requestApi(createRequest(each, {}, null)).catch(error => { return error; }))).then(response => {
            const [nonassociate, associate] = response;
            // if (Object.keys(nonassociate).length > 0) {
            //     let mapData = Object.keys(nonassociate).map(each => {
            //         return { Name: nonassociate[each], Value: each }
            //     }) || []
            setAssociatedResult(associate)
            setNonassociated(nonassociate)
            setReference(null);
            setReload(!reload)

            // }
        })
    }


    const handleUpdate = (result) => {
        let newData = result
        newData['RequestFileReference'] = reference ? JSON.stringify(reference) : newData['RequestFileReference'];
        const update = [
            {
                url: getEnvVariable('MasterAdmin') + `/api/ExternalUsersConsultant/StatusUpdateForInternal/${getEnvVariable('ModuleKey')}/${newData.RequestStatus}`,
                type: 'put',
            }
        ]
        Promise.all(update.map(each => requestApi(createRequest(each, newData, null)).catch(error => { return error; }))).then(response => {
            if (response) {
                setReference(null);
                localStorage.removeItem('reference')
                getAll();
            }

        })
    }


    const handleDelete = (newData) => {
        const del = [
            {
                url: getEnvVariable('MasterAdmin') + `/api/ExternalUsersConsultant/DeleteForInternal/${getEnvVariable('ModuleKey')}/${newData.CompanyId}/${newData.ConsultantCompanyId}`,
                type: 'get',
            }
        ]
        Promise.all(del.map(each => requestApi(createRequest(each, {}, null)).catch(error => { return error; }))).then(response => {
            if (response) {
                setReference(null);
                localStorage.removeItem('reference')
                getAll();
            }
        })
    }

    const handleSave = (result) => {
        let data = result
        data['RequestFileReference'] = (reference && JSON.stringify(reference)) || '';
        data['ConsultantCompanyId'] = params.Id || (information && information.CompanyId) || null

        const addupdate = [
            {
                url: getEnvVariable('MasterAdmin') + `/api/ExternalUsersConsultant/RequestFor${getEnvVariable('AccessPortal') === 'I' ? 'Internal' :
                    'External'}/${getEnvVariable('ModuleKey')}`,
                type: 'post',
            }
        ]
        Promise.all(addupdate.map(each => requestApi(createRequest(each, data, null)).catch(error => { return error; }))).then(response => {
            if (response) {
                localStorage.removeItem('reference')
                getAll();
            }
        })
    }


    const handleDownload = (dataItemTemp) => {
        let dataItem = JSON.parse(dataItemTemp)
        if (dataItem) {
            let obj = {
                'fileReference': dataItem.FileReference, 'contentType': dataItem.ContentType, "folder": "Attachments",
                'fileName': dataItem.FileName
            }
            let url = getEnvVariable('Application') + `/api/FileSystem/${params.Id}/DownloadRef`
            PostData(url, obj, true).then(response => {
                setReference(null);
                localStorage.removeItem('reference')
                if (response && response.FileId) {
                    if (dataItem.ContentType === "pdf") {
                        let downloadurl = getEnvVariable('Application') + `/api/FileSystem/View/${response.FileId}`
                        window.open(downloadurl, '_blank')//.focus();
                    }
                    else {

                        let downloadurl = getEnvVariable('Application') + `/api/FileSystem/Downloadcontent/${response.FileId}`
                        window.open(downloadurl, '_blank')//.focus();
                    }
                }
            }).catch(ex => {
                console.log(ex)
            })
        }

    }

    const handleUploads = (rowData, upload) => {
        if (associatedResult && associatedResult.length > 0) {
            let dataItemTemp = associatedResult.map(each => {
                if (rowData.CompanyId === each.CompanyId) {
                    rowData['RequestFileReference'] = JSON.stringify(upload[0])
                    return rowData
                }
                else return each
            });
            setAssociatedResult(dataItemTemp)
        }
        localStorage.setItem('reference', JSON.stringify(upload[0]))
        setReference(upload[0])
        setReload(!reload)
    }

    const getReference = (rowData) => {
        if ((rowData && !rowData.RequestFileReference) || localStorage.getItem('reference')) {
            let ref = JSON.parse(localStorage.getItem('reference'))
            return ref && ref.FileName;
        }
        else if (rowData && rowData.RequestFileReference) {
            return (JSON.parse(rowData.RequestFileReference) || {}).FileName
        }
        else return null
    }

    return <Fragment >
        {reload}
        {(getEnvVariable("AccessPortal") === 'E' &&
            JSON.parse(localStorage.getItem('profile')).isConsultant === 'Y' && information.IsOfficial === 'Y') && <small className='col-12'>Please use the <a style={{ textDecoration: 'underline' }}
                href='https://transportation.ky.gov/Organizational-Resources/Forms/TC%2016-4.pdf' target="_blank">{getEnvVariable('ModuleKey')} Link </a>
                to download the form
            </small>}
        {(getEnvVariable('AccessPortal') === 'I') && <small className='col-12'>Please use the <a style={{ textDecoration: 'underline' }}
            href='https://transportation.ky.gov/Organizational-Resources/Forms/TC%2016-4.pdf' target="_blank"> {getEnvVariable('ModuleKey')}  Link </a>
            to download the form
        </small>}
        <div className='col px-2'>
            <MultiAdd
                className='col'
                edit={getEnvVariable('AccessPortal') === 'I' ? true : (getEnvVariable("AccessPortal") === 'E' &&
                    JSON.parse(localStorage.getItem('profile')).isConsultant === 'Y' && information.IsOfficial === 'Y') ? true : false}
                //  isDelete={getEnvVariable('AccessPortal') === 'I' ? true : false}
                customsave={true}
                toolbar={true}
                isUpdate={getEnvVariable('AccessPortal') === 'I' ? true : false}
                handleUpdate={handleUpdate}
                //  handleDelete={handleDelete}
                handleSave={handleSave}
                columns={[
                    {
                        title: 'Name', field: 'CompanyId', lookup: nonassociated, width: 120,
                        editable: getEnvVariable('AccessPortal') === 'I' ? 'onAdd' : (getEnvVariable("AccessPortal") === 'E' && JSON.parse(localStorage.getItem('profile')).isConsultant === 'Y' && information.IsOfficial === 'Y') ? 'onAdd' : 'never',
                        validate: (row) => (row.CompanyId || "").length !== 0,
                        render: rowData => rowData && rowData['CompanyId'] &&
                            <span>
                                {((getEnvVariable("AccessPortal") === 'E' && JSON.parse(localStorage.getItem('profile')).isConsultant === 'Y') || getEnvVariable("AccessPortal") === 'I') ? (rowData['Companies'] && rowData['Companies']['Name'])
                                    : (rowData && (rowData['ConsultantCompany'] && rowData['ConsultantCompany']['Name']))}
                            </span>,
                    },
                    {
                        title: 'Document(s)', width: 300,
                        field: 'RequestFileReference', editable: getEnvVariable('AccessPortal') === 'I' ? 'always' : (getEnvVariable("AccessPortal") === 'E' &&
                            JSON.parse(localStorage.getItem('profile')).isConsultant === 'Y' && information.IsOfficial === 'Y') ? 'always' : 'never',
                        validate: rowData => {
                            if (getEnvVariable("AccessPortal") === 'E' && (rowData && localStorage.getItem('reference') === null))
                                if (rowData['RequestFileReference'] === undefined)
                                    return { isValid: false, helperText: "Please Upload Document" };
                            return true
                        },
                        render: rowData => rowData && rowData['RequestFileReference'] &&
                            <span >
                                <Button autoFocus onClick={() => handleDownload(rowData['RequestFileReference'])} color="primary" >
                                    {rowData ? (rowData['RequestFileReference'] !== "null" && (JSON.parse(rowData['RequestFileReference'] || {})).FileName)
                                        : getReference(rowData) || ''
                                    }</Button>
                            </span>,
                        editComponent: props =>
                        (props.rowData &&
                            <small>{getReference(props.rowData) ? <div>{getReference(props.rowData)}
                                {getEnvVariable("AccessPortal") === 'I' &&  < S3NativeUpload title={'Replace Document'}
                                    refid={(params && params.Id) ? params.Id : (information && information.CompanyId)} uploadType="A" isCustom={true}
                                    handleUploads={(upload) => handleUploads(props.rowData, upload)} multiple={false}
                                    reference={reference || JSON.parse(localStorage.getItem('reference'))}
                                    handleDownload={() => handleDownload(props.rowData['RequestFileReference'], 'download')} isButton={true}
                                />} </div> : < S3NativeUpload title={'Upload Document'} refid={(params && params.Id) ? params.Id : (information && information.CompanyId)}
                                    uploadType="A" isCustom={true}
                                    handleUploads={(upload) => handleUploads(props.rowData, upload)} multiple={false}
                                    reference={reference || JSON.parse(localStorage.getItem('reference'))}
                                    handleDownload={() => handleDownload(props.rowData['RequestFileReference'], 'download')}  isButton={true}/>}
                            </small>
                        )
                    },
                    {
                        title: 'Request Status', width: 100,
                        field: 'RequestStatus', lookup: { 'A': 'Approved', 'P': 'Pending', 'D': 'Denied', }, editable: getEnvVariable('AccessPortal') === 'I' ? 'onUpdate' : 'never'
                    },
                ]}
                value={associatedResult || []}
            />
        </div>
    </Fragment >
}