import { GetData } from "../../../core/controls/helpers/requests"
import { getEnvVariable } from "../../../modules/environmentalList"

export const getDropdown = async (type) => {
    if (type) {
        let url = getEnvVariable('Admin') + `/api/DomainsMapper/Grid/${type}` //getEnvVariable('Admin') + `/api/Domains/values?domainkey=${type}`
        return await GetData(url).then(res => {
            return res;
            // let dropdown = {};
            // for (let index = 0; index < res.length; index++) {
            //     const element = res[index];
            //     dropdown[element.value] = element.name;
            // }
            // return dropdown || {}
        })
    }
    return undefined
}