import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import MultipleFileUpload from '../Uploads/MultipleFileUpload.js';
import moment from 'moment';
import { requestApi } from '../../core/modules/form/utilities'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import historygrid from '../../json/common/attachments/attachmenthistory'
import Grid from '../../core/modules/Grid/cgrid'
import FormHeader from '../../core/modules/form/cformheader'
import { getEnvVariable } from '../environmentalList';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}


class ExtendFileAttachments extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            getallattachementtypes: [], AttachmentTypeId: '', notes: '', AttachmentType: 'U', externallink: '', downloadContent: null, downloadName: null,
            ContentStr: null, ContentType: null, FileName: null, attachedfiles: [], firstIterate: true, tabvalue: 0,
            recordid: null, includeremovedfiles: []

        }
        if (this.props.updateAttachment) {
            this.attachmentComponentsArray = { ...historygrid };
            this.historygridjson = this.attachmentComponentsArray.options
        }
    }

    componentDidMount() {
        const getallattachements = {
            get: {
                url: getEnvVariable('AdminConfig') + `` + `/api/SitePermitTypeAttachments/SitePermitTypeAttachmentsTypes/${this.props.match.params.routeid}`
            }
        }
        //const  getallattachements = getEnvVariable('Admin')+`/api/SitePermitTypeAttachments/SitePermitTypeAttachmentsTypes/276`
        requestApi(getallattachements).then(results => {
            this.setState({
                getallattachementtypes: results,
            })
        })
    }

    componentDidUpdate(prevState, prevProps) {
        const { attachedfiles, firstIterate, recordid } = this.state
        if (prevProps.dataItem != this.props.dataItem) {
            if ((this.props.passresults) && (recordid != this.props.passresults.Id)) {
                this.setState({
                    recordid: this.props.dataItem.Id,
                    attachedfiles: this.props.passresults.AttachmentDetails,
                    includeremovedfiles: this.props.passresults.AttachmentDetails,
                    AttachmentType: this.props.passresults.AttachmentType,
                    notes: this.props.passresults.Notes,
                    AttachmentTypeId: this.props.passresults.AttachmentTypeId,
                }, () => this.getResult())
            }
        }
    }


    getResult = () => {
        let files = []
        if (this.state.AttachmentType === 'U') {
            let attachment = {}
            attachment.AttachmentTypeIdVal = this.state.AttachmentTypeId;
            attachment.notesVal = this.state.notes;
            this.props.getallresultsInfo(attachment)
        }
        else if (this.state.AttachmentType === 'P') {
            let attachment = {}
            attachment.AttachmentTypeIdVal = this.state.AttachmentTypeId;
            attachment.notesVal = this.state.notes;
            this.props.getallresultsInfo(files, attachment)
        }

    }


    // RemoveImage = (event) => {
    //     var e = {
    //         target: {
    //             name: event.target.name,
    //             value: ""
    //         }
    //     }
    //     this.props.handleFieldChange(e)
    // }

    // handleAttachmentSet = (uploadResults) => {
    //     const { AttachmentType, externallink, attachedfiles, includeremovedfiles } = this.state
    //     let newattachedfile = [];
    //     let newfile = [];
    //     newattachedfile.push(attachedfiles);
    //     newfile.push(includeremovedfiles);
    //     let res = { AttachmentType: AttachmentType, externallink: externallink, ...uploadResults }
    //     newfile.push(res);
    //     let finalincluderemovedfiles = _.flattenDeep(newfile);
    //     newattachedfile.push(res);
    //     let finalattachedfiles = _.flattenDeep(newattachedfile);
    //     this.setState({ attachedfiles: finalattachedfiles, includeremovedfiles: finalincluderemovedfiles }, () => this.getResult())
    // }

    handleDelete = (newattachedfile, allfile) => {
        this.setState({ attachedfiles: newattachedfile, includeremovedfiles: allfile }, () => this.getResult())
    }

    handleFieldChange = name => event => {
        this.setState({ [name]: event.target.value }, () => this.getResult());
    }

    handleChange = (event, newValue) => {
        this.setState({ tabvalue: newValue })
    }

    render() {
        const { passresults, updateAttachment, editformat, classes, archiveParentId, docmissing, urlmissing, urlhelpertext, typemissing, dataItem } = this.props
        const { AttachmentType, externallink, attachedfiles, AttachmentTypeId, notes, getallattachementtypes, tabvalue } = this.state;
        return (
            <div>
                {this.props.updateAttachment && <AppBar position="static" color="white">
                    <Tabs
                        value={tabvalue}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="View/Edit Attachment" {...a11yProps(0)} />
                        <Tab label="Attachment History" {...a11yProps(1)} />

                    </Tabs>
                </AppBar>}

                <TabPanel value={tabvalue} index={0}>
                    <div className="col " style={{ marginLeft: "18px" }}>
                        {this.props.updateAttachment && <FormHeader className="row" savebutton={true} editbutton={false} handleEdit={this.props.handleEdit} handleSave={this.props.handleSave} designprops={true} />}
                        <FormControl component="fieldset" className="col pl-1">
                            <InputLabel shrink required={true}>Attachment Type</InputLabel>
                            <Select
                                value={!passresults ? AttachmentTypeId : passresults.AttachmentTypeId}
                                //value={AttachmentTypeId || passresults.AttachmentTypeId}
                                onChange={this.handleFieldChange('AttachmentTypeId')}
                                className="float-md-right col-sm-6"
                                disabled={this.props.updateAttachment ? true : false}
                            >
                                <MenuItem value=""> None </MenuItem>
                                {getallattachementtypes && getallattachementtypes.map(menu => {
                                    return <MenuItem value={menu.Id}> {menu.Name} </MenuItem>
                                })}
                            </Select>
                            {typemissing && <div style={{ color: "#F44336" }}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    Please Select Attachment Type
                                </Typography>
                            </div>}
                            <TextField
                                label="Notes"
                                className={'col-sm-6'}
                                // helperText="Please enter valid Url"
                                value={notes}

                                // value={notes || passresults.Notes}
                                onChange={this.handleFieldChange('notes')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <FormLabel className="pt-3"><small >Select Document Type</small> &nbsp;{<small className="text-warning"> (Changing the document type will remove the added information)</small>}</FormLabel>
                            <RadioGroup value={AttachmentType || 'U'} onChange={this.handleFieldChange('AttachmentType')} >
                                <div>
                                    <FormControlLabel className="row col-12 " value="U" control={<Radio />} label={<Box component="div" fontSize={12}>
                                        Upload Digital Document
                                    </Box>} disabled={this.props.updateAttachment ? true : false} />
                                    <FormControlLabel className="row col-12 " value="L" control={<Radio />} label={<Box component="div" fontSize={12}>
                                        External Link
                                    </Box>} disabled={this.props.updateAttachment ? true : false} />
                                    <FormControlLabel className="row col-12 " value="P" control={<Radio />} label={<Box component="div" fontSize={12}>
                                        Physical Paper Document
                                    </Box>} disabled={this.props.updateAttachment ? true : false} />
                                </div>
                            </RadioGroup>
                        </FormControl>
                        {(this.state.AttachmentType === "U") && <div className={"row col-sm-12"}>
                            {docmissing && <div style={{ color: "#F44336" }}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    Please upload atleast one document
                                </Typography>

                            </div>}
                            {!passresults && <InputLabel shrink required={true}>Upload Documents</InputLabel> && <MultipleFileUpload updateAttachment={updateAttachment} stateprops={this.state}
                                multiplefiles={attachedfiles} handleDelete={this.handleDelete} handleAttachmentSet={this.handleAttachmentSet} />}
                            {passresults && <InputLabel shrink required={true}>Upload Documents</InputLabel> && <MultipleFileUpload updateAttachment={updateAttachment} stateprops={this.state}
                                multiplefiles={attachedfiles} handleDelete={this.handleDelete} handleAttachmentSet={this.handleAttachmentSet} zipDownload={true}
                                archiveParentId={this.props.archiveParentId} downloadAttachId={this.props.passresults.Id} />}
                        </div>}
                        {this.state.AttachmentType === "L" &&
                            <div>
                                <TextField
                                    label="URL"
                                    required={true}
                                    className={'col-sm-6'}
                                    // helperText="Please enter valid Url"
                                    value={externallink}
                                    onChange={this.handleFieldChange('externallink')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {urlmissing && <div style={{ color: "#F44336" }}>
                                    <Typography variant="caption" display="block" gutterBottom>Please enter URL</Typography>
                                </div>}
                                {urlhelpertext && <div style={{ color: "#F44336" }}>
                                    <Typography variant="caption" display="block" gutterBottom>{urlhelpertext}</Typography>
                                </div>}
                            </div>
                        }


                        {passresults && <div className="row pt-3">
                            <div className="col-12"> <div className="col-sm-6 float-md-right" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D', fontSize: '12px' }}>Created By : </span> {passresults.CreatedByName}</div>
                                <div className="col-sm-6 " style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D', fontSize: '12px' }}>Created On : </span> {moment(passresults.CreatedOn).format('MM/DD/YYYY hh:mm:ss A')}</div>
                            </div>
                            <div className="col-12">
                                {passresults.UpdatedBy === null ? ('') : (<div className="col-sm-6 float-md-right" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D', fontSize: '12px' }}>Updated By : </span> {passresults.UpdatedByName}</div>)}
                                {passresults.UpdatedOn === null ? ('') : (<div className="col-sm-6" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D', fontSize: '12px' }}>Updated On : </span> {moment(passresults.UpdatedOn).format('MM/DD/YYYY hh:mm:ss A')}</div>)}
                            </div>
                        </div>}
                    </div>
                </TabPanel>
                <TabPanel value={tabvalue} index={1}>
                    <Grid options={this.historygridjson} dataItem={dataItem} />
                </TabPanel>
            </div>
        )
    }
}
export default withRouter(ExtendFileAttachments)