import React, { useEffect, Fragment, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import { requestApi } from '../../../modules/form/utilities'
import SimpleGrid from '../../../modules/Grid/cgrid'
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function PhaseTypeSeclection(props) {
    const classes = useStyles();
    const [phase, setPhase] = React.useState('');
    const [phaseItems, setPhaseItems] = React.useState([]);
    const [gridItems, setGridItems] = React.useState(null);
    const [phaseSelection, setPhaseSelection]  = React.useState(false);
    const [selectedPhaseTypes, setSelectedPhaseTypes] = React.useState([]);
    const handleChange = (event) => {
        setPhase(event.target.value);
    };

    const addPhaseType = (event) => {
        setPhaseSelection(true)
    }

    const savePhaseType = (event) => {
        let addurl = props.options.add
        let idreplace = addurl.post.routeProps
        let requestUrl = addurl.post.url
        let id = []
        if (addurl.post.routeProps) {
            idreplace.forEach(each => {
            let idval = props && props.dataItem ? props.dataItem[each.value] : null
            let pushid = {key:each.value, value:idval}
            id.push(pushid);
        })}
        id.forEach(each => {
            const { key, value } = each;
            requestUrl = `${requestUrl.replace(`{${key}}`, value)}`;
        })
        let resultdata = { PhaseId: phase, SitePermitTypeId: props.dataItem.Id }

        requestApi({post:{url : requestUrl}}, '', resultdata).then(res => {
            setPhaseSelection(false);
            setPhase('');
            IntialLoad();
        })


    }

    useEffect(() => {
        let phasetypedropdown = props.options.phasetypedropdown
        requestApi(phasetypedropdown, '').then(res => {
            setPhaseItems(res)
        })
        IntialLoad();
    }, [])

    const onUp = (dataItem) => {
        let orderurl = props.options.changeorder
        let idreplace = orderurl.post.routeProps
        let requestUrl = orderurl.post.url
        let id = []
        if (orderurl.post.routeProps) {
            idreplace.forEach(each => {
            let idval = each.value!= "direction" ? props && props.dataItem ? props.dataItem[each.value] : null : "UP"
            let pushid = {key:each.value, value:idval}
            id.push(pushid);
        })}
        id.forEach(each => {
            const { key, value } = each;
            requestUrl = `${requestUrl.replace(`{${key}}`, value)}`;
        })
        requestApi({post:{url : requestUrl}}).then(res => {
            IntialLoad();
        })
    }

    const onDown = (dataItem) => {
        let orderurl = props.options.changeorder
        let idreplace = orderurl.post.routeProps
        let requestUrl = orderurl.post.url
        let id = []
        if (orderurl.post.routeProps) {
            idreplace.forEach(each => {
            let idval = each.value!= "direction" ? props && props.dataItem ? props.dataItem[each.value] : null : "DOWN"
            let pushid = {key:each.value, value:idval}
            id.push(pushid);
        })}
        id.forEach(each => {
            const { key, value } = each;
            requestUrl = `${requestUrl.replace(`{${key}}`, value)}`;
        })
        requestApi({post:{url : requestUrl}}).then(res => {
            IntialLoad();
        })
    }

    const onDelete = (dataItem) => {
        IntialLoad();
        console.log(dataItem);
    }

    const IntialLoad = () => {
        let gridread = props.options.gridread
        let idreplace = gridread.get.routeProps
        let requestUrl = gridread.get.url
        let id = []
        if (gridread.get.routeProps) {
            idreplace.forEach(each => {
            let idval = props && props.dataItem ? props.dataItem[each.value] : null
            let pushid = {key:each.value, value:idval}
            id.push(pushid);
        })}
        id.forEach(each => {
            const { key, value } = each;
            requestUrl = `${requestUrl.replace(`{${key}}`, value)}`;
        })
        requestApi({get:{url : requestUrl}}, '').then(res => {
            let selectedphasetypes = res && res.data ? _.map(res.data, 'PhaseId') : []
            setSelectedPhaseTypes(selectedphasetypes)
            setGridItems(res)
        })
    }

    return (
        <div>
            <div>
                <FormControl className={classes.formControl}>
                    <InputLabel shrink>Select Phase Type</InputLabel>
                    <Select
                        value={phase}
                        onChange={handleChange}
                        disabled = {phaseSelection ? false : true}
                    >
                        <option value="">None</option>
                        {phaseItems.map(each => {
                            let disableditem = selectedPhaseTypes.includes(Number(each.Value));
                            return (
                                <option value={each.Value} disabled={disableditem} name={each.Name}>{each.Name}</option>
                            )
                        })}
                    </Select>
                </FormControl>
                {phase =='' && <IconButton title='Add' color="primary" onClick={addPhaseType}> <AddIcon />
                </IconButton>}
                {phase !='' && <IconButton title='Save' color="primary" onClick={savePhaseType}> <SaveIcon />
                </IconButton>}
                {/* <IconButton title='Save' disabled={phase == '' ? true : false} color="primary" onClick={savePhaseType}> <SaveIcon />
                </IconButton> */}
            </div>
            <div className="pt-8">
                {gridItems && gridItems.data.length > 0 ? <SimpleGrid
                    onUp={onUp}
                    onDown={onDown} onDelete={onDelete}
                    data={gridItems || []} columns={props.options.gridcolumns} useComp={true}
                    options={{ headerProps: null, gridcolumns: props.options.gridcolumns, sidepopupgridheight: true, }} />
                    :
                    <Typography variant="body" display="block">
                        No Phase Types selected
                  </Typography>}
            </div>
        </div>
    );
}
