import { AppBar, Toolbar, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import MobileStepper from '@material-ui/core/MobileStepper';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React, { Component } from 'react';
import PhoneFormat from '../../core/controls/components/phoneformat';
import TextField from '../../core/controls/components/textbox';
import Editor from '../../core/modules/form/cwysiwyg';
import { requestApi } from '../../core/modules/form/utilities';
import { getEnvVariable } from '../environmentalList';
import Heading from './heading';
import { withStyles } from '@material-ui/core/styles';
const styles = {
  root: {
    maxWidth: window.innerwidth,
    flexGrow: 1,
  },
};


export class UserDetails extends Component {
  state = {
    activeStep: 2,
    openpolicy: false,
    editorval: null
  };

  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };


  componentDidMount() {
    // requestApi({ get: { url: getEnvVariable('Application') + `/api/EmailConfiguration/Key?key=EMAILPOLICY_DOTTNC` } }, null, null).then(results => {
    //   this.setState({
    //     editorval: results.Body
    //   })
    // })
  }

  handlecontactcheckbox = (e) => {
    this.props.handleCheckChange('Officephonecheck', e)
    if (e.target.checked && this.props.values.Phone) {
      let value = { value: this.props.values.Phone }
      let event = {}
      event.target = value
      this.props.handleCheckChange('UserPhone', event)
    }
  }

  toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ openpolicy: open })
  };

  handleFieldChange = (event) => {
    console.log(event);
  }


  render() {
    const { values, classes, handleChange, errors } = this.props;
    let hideDepPolicy = true;
    return (
      <div className='col-sm-12'>
        <Heading subheading="Contact Information : " />
        <div className="row">
          <div className="col-sm-5">  <TextField error={(errors.FirstName && errors.FirstName.error) ? errors.FirstName.error : false} label="First Name" handleFieldValue={handleChange('FirstName')} value={values.FirstName} required helperText={(errors.FirstName && errors.FirstName.error) ? errors.FirstName.errortext : ""} />          </div>
          <div className="col-sm-5">            <TextField error={(errors.LastName && errors.LastName.error) ? errors.LastName.error : false} label="Last Name" handleFieldValue={handleChange('LastName')} value={values.LastName} required helperText={(errors.LastName && errors.LastName.error) ? errors.LastName.errortext : ""} />          </div>
        </div>
        <div className="row">
          <div className="col-sm-10">            <TextField error={(errors.Title && errors.Title.error) ? errors.Title.error : false} label="Title" handleFieldValue={handleChange('Title')} value={values.Title} helperText={(errors.Title && errors.Title.error) ? errors.Title.errortext : ""} />          </div>
        </div>
        <div className="row">
          <div className="col-sm-10">            <TextField error={(errors.Email && errors.Email.error) ? errors.Email.error : false} label="Email" handleFieldValue={handleChange('Email')} value={values.Email} required helperText={(errors.Email && errors.Email.error) ? errors.Email.errortext : ""} />          </div>
        </div>
        <div className="row col-sm-10">
          <PhoneFormat error={(errors.UserPhone && errors.UserPhone.error) ? errors.UserPhone.error : false} required name="Contact Number" value={values.UserPhone}
            handleFieldValue={handleChange('UserPhone')} label="Primary Contact Phone" helperText={(errors.UserPhone && errors.UserPhone.error) ? errors.UserPhone.errortext : ""} />
          {this.props.values.Phone && <FormControlLabel control={<Checkbox checked={values.Officephonecheck == "Y"} onChange={(e) =>
            this.handlecontactcheckbox(e)} />} label={<Box component="div" fontSize={12}>              Use Office Phone Number            </Box>}          >
          </FormControlLabel>}
        </div>
        <div className="row">
          <MobileStepper
            variant="dots"
            steps={4}
            position="static"
            className={classes.root}
            activeStep={this.state.activeStep}
            nextButton={
              <Button size="small" onClick={this.continue} >
                Next
                {<KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={this.back}>
                {<KeyboardArrowLeft />}
                Back
              </Button>
            }
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(UserDetails);
