import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from '@material-ui/styles';
import { loadModules } from 'esri-loader';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { getEnvVariable } from "../../../../modules/environmentalList";
import DndList from './dndlist';

const styles = theme => ({
    button: {

    },
    containerbg: {
        backgroundColor: '#fff',
        width: '30em'
    },
    listing: {
        padding: '0.5em 0',
        height: "20em"
    },
    root: {

    },
    txtf: {
        width: '24em',
        padding: '0 1em 0 0'
    },
    highlight: { color: 'green[500]' },
    centeralign: { textAlign: "center", padding: "2em 0" },
    scrollList: { height: "20em" }
});

class BookmarkControl extends Component {
    constructor(props) {
        super(props);
        this.bcrdom = React.createRef();
        this.url = getEnvVariable('ArcGIS') + `/rest/services/AWS_KEPT_Test/Bookmarks/FeatureServer/0`;
        this.state = {
            options: this.props.options,
            choice: 'E',
            newgraphic: null,
            drawcounter: 0,
            allbookmarks: [],
            loading: true,
            highlight: false,
            choisemssage: 'Draw Box on Map for Bookmark',
            listupdated: new Date().getTime()
        }

        this.startup(
            this.props
        );
    }

    startup = (config) => {

        loadModules([
            "esri/widgets/Expand",
            "esri/layers/GraphicsLayer",
            "esri/views/2d/draw/Draw",
            "esri/Graphic",
            "esri/geometry/Extent", "esri/layers/FeatureLayer", "esri/geometry/Polyline",
            "esri/geometry/support/webMercatorUtils"
        ]).then(([Expand, GraphicsLayer, Draw, Graphic, Extent, FeatureLayer, Polyline, webMercatorUtils]) => {

            this.url = this.state.options.searchbookmarks.url ? this.state.options.searchbookmarks.url : this.url;
            this.Graphic = Graphic;
            this.Polyline = Polyline;
            this.Extent = Extent;
            this.webMercatorUtils = webMercatorUtils;
            this.sbcontainer = ReactDOM.findDOMNode(this);
            let defi = '';
            if (this.state.options.searchbookmarks.appname) {
                defi = defi + " APPNAME ='" + this.state.options.searchbookmarks.appname + "'";
            }
            if (this.state.options.searchbookmarks.username) {
                defi = defi + " AND (USERNAME ='" + this.state.options.searchbookmarks.username +
                    "' OR SHARE='Y' )";
            } else {
                defi = defi + " AND SHARE='Y' ";
            }

            this.bookmarkLayer = new FeatureLayer({
                id: "bookmark_feature_layer",
                url: this.url,
                outFields: ["*"],
                definitionExpression: defi,
                visible: false
            });

            this.glayer = new GraphicsLayer({ id: 'searchbookmarklyrid' });

            this.props.view.map.add(this.glayer);

            this.draw = new Draw({
                view: this.props.view
            });

            this.getAll();
        });
    }

    onSearchStart = () => {
        this.setState({
            newgraphic: null,
            newbookmarkname: null,
            saved: false,
            highlight: {}
        });

        var action = this.draw.create("rectangle");
        action.on(["vertex-add", "vertex-remove", "cursor-update", "redo",
            "undo"
        ], this.updateVertices);
        action.on(["draw-complete"
        ], this.drawComplete);
        this.props.view.focus();
    }

    updateVertices = (event) => {
        // create a polyline from returned vertices
        const graphic = this.createGraphic(event);
        this.addtoDrawLayer(graphic);
    }

    createGraphic = (event, towebmarker, docenter) => {
        const vertices = event.vertices;

        if (vertices && vertices.length > 1) {
            let geometry = null;
            if (towebmarker) {
                geometry = new this.Extent({
                    xmin: vertices[0][0],
                    xmax: vertices[1][0],
                    ymin: vertices[0][1],
                    ymax: vertices[1][1],
                    spatialReference: { wkid: 4326 }
                });
                geometry = this.webMercatorUtils.geographicToWebMercator(geometry)
            } else {
                geometry = new this.Extent({
                    xmin: vertices[0][0],
                    xmax: vertices[1][0],
                    ymin: vertices[0][1],
                    ymax: vertices[1][1],
                    spatialReference: { wkid: this.props.options.viewProperties.extent.spatialReference }
                });
            }

            // a graphic representing the polyline that is being drawn
            let symbl = this.state.options.searchbookmarks.defaultSymbol;
            const graphic = new this.Graphic({
                geometry: geometry,
                symbol: symbl
            });

            return graphic;
        } else {
            return null;
        }
    }
    addtoDrawLayer(graphic, docenter) {
        this.glayer.removeAll();

        if (graphic) {
            this.glayer.add(graphic);
            if (docenter) this.props.view.goTo([graphic]);
        }
    }
    drawComplete = (event) => {
        var graphic = this.createGraphic(event, false, false);
        this.addtoDrawLayer(graphic, false);
        let counter = this.state.drawcounter + 1;
        let nme = this.state.options.searchbookmarks.defaultName ?
            this.state.options.searchbookmarks.defaultName : 'Bookmark #';
        this.setState({
            newgraphic: graphic,
            newbookmarkname: nme + counter,
            drawcounter: counter
        });

    }

    handleChoices = (event) => {
        let msg = this.state.options.searchbookmarks.defaultMessage ?
            this.state.options.searchbookmarks.defaultMessage :
            'Draw Box on Map for Bookmark';
        if (event.target.value == 'S') {
            msg = 'Draw Box on Map for Search & Bookmark'

        }
        this.setState({
            choice: event.target.value,
            choisemssage: msg
        });
    }

    changeValue(e, type) {
        const value = e.target.value;
        const nextState = {};

        if (!value) {
            let err = 'Name is Empty.';
            nextState['noName'] = err
        } else {
            nextState[type] = value;
        }
        this.setState(nextState);
    }

    handleSaveBookmark = () => {
        if (this.state.newgraphic) {

            const name = this.state.newbookmarkname;
            const geometry = this.state.newgraphic.geometry;
            const mgeom = this.webMercatorUtils.webMercatorToGeographic(geometry);
            const paths = [[mgeom.xmin, mgeom.ymin], [mgeom.xmax, mgeom.ymax]];
            const line = new this.Polyline({ hasZ: false, hasM: false, paths: paths, spatialReference: { wkid: 4326 } });
            const graphic = new this.Graphic({
                geometry: line,
                attributes: {
                    NAME: name,
                    APPNAME: this.state.options.searchbookmarks.appname ?
                        this.state.options.searchbookmarks.appname : '',
                    SHARE: 'N',
                    USERNAME: this.state.options.searchbookmarks.username
                }
            });
            this.addtoDrawLayer(null);
            this.addToLayer(graphic);
        }

    }
    deleteFromLayer(graphic) {
        let g = { ...graphic };
        delete g.extent;

        let todelete = new this.Graphic({ geometry: graphic.extent, attributes: g });
        this.setState({ loading: true }, () => {
            this.bookmarkLayer.applyEdits({
                deleteFeatures: [todelete],

            }).then((res) => {

                let bookmarks = [...this.state.allbookmarks];
                let idx = bookmarks.findIndex((x) => { return x.OBJECTID == g.OBJECTID })
                bookmarks.splice(idx, 1);
                this.setState({ loading: false, allbookmarks: bookmarks, listupdated: new Date().getTime() });
            })
        });


    }

    addToLayer(graphic) {
        this.setState({ loading: true }, () => {

            this.bookmarkLayer.applyEdits({
                addFeatures: [graphic],

            }).then((res) => {
                let added = res.addFeatureResults[0];

                let addedfinal = { OBJECTID: added.objectId, extent: graphic.geometry, ...graphic.attributes }
                let bookmarks = [...this.state.allbookmarks];

                bookmarks = bookmarks.concat([addedfinal]);

                this.setState({ loading: false, allbookmarks: bookmarks, newgraphic: null, newbookmarkname: null, listupdated: new Date().getTime() });
            })

        });


    }
    getAll() {

        let query = this.bookmarkLayer.createQuery();
        this.bookmarkLayer.queryFeatures(query).then((response) => {
            var features = response.features;
            features = features.map((q) => {
                return { extent: q.geometry, ...q.attributes }
            });

            this.setState({ loading: false, allbookmarks: features, listupdated: new Date().getTime() });
        });
    }
    componentDidMount() {
        this.node = ReactDOM.findDOMNode(this);
    }


    handleShow = (evt, data) => {
        const paths = data.extent.paths;
        const e = { vertices: paths[0] }
        var g = this.createGraphic(e, true, true);
        this.addtoDrawLayer(g, true);
        this.setState({
            highlight: data
        })
    }
    handleTrash = (evt, data) => {
        this.deleteFromLayer(data);
    }
    handleReorder = (data) => {
        let temp = [...data];
        this.setState({
            allbookmarks: temp,
        })
    }
    handleShare = (evt, data) => {
        let g = { ...data };
        delete g.extent;
        g.SHARE = g.SHARE == 'Y' ? 'N' : 'Y';
        data.SHARE = g.SHARE;
        let toupdate = new this.Graphic({ geometry: data.extent, attributes: g });
        this.setState({ loading: true }, () => {
            this.bookmarkLayer.applyEdits({
                updateFeatures: [toupdate],

            }).then((res) => {

                let bookmarks = [...this.state.allbookmarks];
                let idx = bookmarks.findIndex((x) => { return x.OBJECTID == g.OBJECTID })
                bookmarks.splice(idx, 1, data);

                this.setState({ loading: false, allbookmarks: bookmarks, listupdated: new Date().getTime() });
            })
        });
    }
    render() {
        const { classes } = this.props;
        if (this.state.loading) {
            return (<div className="searchbookmark-box" ref={this.bcrdom} className={classes.containerbg}><h3 className={classes.centeralign} >Loading...</h3></div>)
        }
        return (
            <div className="searchbookmark-box" ref={this.bcrdom} className={classes.containerbg} style={{ order: this.props.order }}>
                <div style={{ padding: '1em ' }}>
                    <div>
                        <h5>Bookmarks</h5>
                    </div>
                    <RadioGroup row aria-label="bookmarkChoices" name="bookmarkChoices" style={{ 'display': 'none' }}
                        value={this.state.choice} onChange={this.handleChoices}>
                        <FormControlLabel value="E" control={<Radio />} label="Extent" />
                        <FormControlLabel value="S" control={<Radio />} label="Search" />
                    </RadioGroup>
                    <IconButton variant="outlined"
                        className={classes.button}
                        onClick={this.onSearchStart} > <Icon className="esri-icon-sketch-rectangle" /> </IconButton>
                    <div style={{ padding: '0 0.5em ' }}> <p>  {this.state.options.searchbookmarks ?
                        this.state.options.searchbookmarks.defaultMessage :
                        'Draw Box on Map for Bookmark'}</p></div>
                    {this.state.newgraphic &&
                        (<form className={classes.root} noValidate autoComplete="off">
                            <TextField
                                hintText="Enter Name"
                                floatingLabelText="Name"
                                className={classes.txtf}
                                type="text"
                                name="newbookmarkname"
                                errorText={this.state.noName}
                                defaultValue={this.state.newbookmarkname}
                                onChange={e => this.changeValue(e, 'newbookmarkname')}
                            />
                            <IconButton edge="end"
                                variant="outlined"
                                onClick={this.handleSaveBookmark} >
                                <SaveIcon />
                            </IconButton>
                        </form>)

                    }

                    <div className={classes.listing} style={{ overflow: 'auto' }} >
                        {/* <ScrollArea horizontal={false} className={classes.scrollList}> */}
                        <DndList data={this.state.allbookmarks} key={this.state.listupdated}
                            username={this.state.options.searchbookmarks.username}
                            appname={this.state.options.searchbookmarks.appname}
                            highlight={this.state.highlight}
                            highlightClass={classes.highlight}
                            handleShare={this.handleShare.bind(this)}
                            handleTrash={this.handleTrash.bind(this)}
                            handleShow={this.handleShow.bind(this)} handleReorder={this.handleReorder.bind(this)}></DndList>
                        {/* </ScrollArea> */}
                    </div>
                </div>
            </div>
        );
    }
}

BookmarkControl.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BookmarkControl);