//fields
import GridReport from '../../modules/report/dialogdrawercontent';
import AutoComplete from '../components/autocomplete';
import Button from '../components/buttons';
import Checkautocomplete from '../components/checkautocomplete';
import Checkbox from '../components/checkbox';
import Confirmation from '../components/confirmationPopup';
import useContainer from '../components/container';
import DatePicker from '../components/datepicker';
import DateMonth from '../components/date_daymonth';
import DateTimePicker from '../components/datetimepicker';
import Dropdown from '../components/dropdown';
//hooks
import useForm from '../components/form';
import Header from '../components/header';
import KendoGrid from '../components/kendogrid';
import kendoHeader from '../components/kendoheader';
import Label from '../components/label';
import Link from '../components/link';
import MultiAdd from '../components/multiadd';
import Notify from '../components/custom/notificationtemplate';
import NumberFormat from '../components/numberformat';
import PhoneFormat from '../components/phoneformat';
import RadioGroup from '../components/radiogroup';
import SaveButton from '../components/savebutton';
import useSections from '../components/sections';
import SidePopUp from '../components/sidepopup';
import switchcomponent from '../components/switch';
import TextField from '../components/textbox';
import appdownloadstab from '../components/custom/appdownloads'
import timer from '../components/timepicker'
import Editor from '../components/wysiwyg'
import Fab from '../components/fab'
import CustomProvision from '../components/custom/designprovisions'
import TemplateBusinessRule from '../components/custom/masternoticebr';
import Template from '../components/template';
import MissingItemsDrawer from '../components/custom/missinglists';
import Upload from '../components/multipleuploads'
import TransferList from '../components/transferlist';
import DomainCalVal from '../components/custom/domaincalval';
import SubHeader from '../components/subheader';

export const fieldTypes = {
    dropdown: Dropdown,
    textfield: TextField,
    checkbox: Checkbox,
    button: Button,
    grid: KendoGrid,
    phoneformat: PhoneFormat,
    radiogroup: RadioGroup,
    label: Label,
    link: Link,
    numberformat: NumberFormat,
    datetimepicker: DateTimePicker,
    date: DatePicker,
    datemonth:DateMonth,
    multiadd: MultiAdd,
    popup: SidePopUp,
    save: SaveButton,
    header: Header,
    notify: Notify,
    kendoheader: kendoHeader,
    autocomplete: AutoComplete,
    gridreport: GridReport,
    //newcontainer: useContainer, --dont add this. Please let me know when u are adding
    toggle: switchcomponent,
    checkauto: Checkautocomplete,
    confirmation: Confirmation,
    timer: timer,
    editor: Editor,
    fab: Fab,
    template: Template,
    //custom components
    appdownloads: appdownloadstab,
    customprovision: CustomProvision,
    templatebusiness: TemplateBusinessRule,
    required: MissingItemsDrawer,
    upload: Upload,
    tranferlist: TransferList,
    domainCalVal: DomainCalVal,
    subheader:SubHeader,

}

export const hookTypes = {
    form: useForm,
    container: useContainer,
    sections: useSections,
}
