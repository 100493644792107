import { getEnvVariable } from "../../../../modules/environmentalList"

const PolicysettingsAdd = {
    "type": "popup",
    "key": "PolicysettingsAdd",
    "name": "PolicysettingsAdd",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'addOpen'
    },
    sharedValues: ['open'],
    container: {
        values: { save: false, back: false, fullscreen: false, },
        mapValues: {
            header: [
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
            ],
            AddPolicysettings: [{ key: 'save', name: 'save' },
            { key: 'exitfullscreen', name: 'exitfullscreen' },
            { key: 'fullscreen', name: 'fullscreen' },]
        },
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },


            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'AddPolicysettings',
                    type: 'div',
                    className: 'col-12 pl-4 m-2',
                    sub: {
                        type: 'div',
                        className: 'col-12 pl-4 m-2',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'settings',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "Add System Templates", "materialicon": "settings", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'save',
                        hidevalue: false,
                        title: 'Save System Templates'
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hidevalue: false,
                        title: 'FullScreen'
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false,
                        title: 'Exit FullScreen'
                    }
                ],


            },
            AddPolicysettings: {
                "type": "form",
                "key": "AddPolicysettings",
                "name": "AddPolicysettings",
                //"routeParams": { replace: 'settings', param: 'Id', routeName: 'TableName' },
                aliases: {
                    save: 'save',
                },
                sharedValues: ['save', 'exitfullscreen', 'fullscreen'],
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'subject',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            }],

                        },
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'body',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            }],

                        },
                        {
                            order: 1,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'ShowOnUI',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            }],

                        },
                    ],
                },
                values: {
                    subject: '',
                    body:'',
                    Key:'',
                    showOnUI:''
                },

                "components": {
                    subject: {
                        "type": "textfield",
                        "key": "subject",
                        "label": "Name",
                        "name": "subject",
                    },
                    body: {
                        "type": "editor",
                        "key": "body",
                        "label": "Body",
                        "name": "body",
                    },
                    ShowOnUI: {
                        "type": "checkbox",
                        "key": "ShowOnUI",
                        name: "ShowOnUI",
                        "label": "Show On UI",
                        extraProps: {
                            transformProps: {
                                "Y": true,
                                "N": false,
                                true: "Y",
                                false: "N"
                            }
                        },
                    },
                },
                mapActionsToEffects: {
                    'onLoad': {
                    init: {
                        rules: [{
                            run:
                            {
                                valid: [
                                    {
                                        type: 'validations', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                        "read": [
                                            {
                                                "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.emailconfigurations`,
                                                type: 'get'
                                            }
                                        ],
                                        transformProps: {
                                            removesubfieldname: 'MASTER.EMAILCONFIGURATIONS_'
                                        }
                                    },



                                ]
                            }
                        }]
                    }
                },
                'onChange': {
                    'exitfullscreen': {
                        rules: [
                            {
                                run: {
                                    valid: [
                                        {
                                            type: 'exitfullscreen',

                                        },

                                    ]
                                }
                            }
                        ]
                    },
                    'fullscreen': {
                        rules: [
                            {
                                run: {
                                    valid: [
                                        {
                                            type: 'fullscreen',

                                        },

                                    ]
                                }
                            }
                        ]
                    },

                },
                'afterSave': {'save': {
                        rules: [
                            {
                                run: {
                                    valid: [
                                        {
                                            type: 'save',
                                            "read": [
                                                {
                                                    "url": getEnvVariable('Application')+`/api/EmailConfiguration/Add`,
                                                    type: 'post',

                                                }
                                            ],
                                        },

                                    ]
                                }
                            }
                        ]
                    },
                }}
            }
        },

    }
}

export default PolicysettingsAdd