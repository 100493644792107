import React from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from "react-router-dom";
import Icon from '@material-ui/core/Icon';

class GridButton extends React.Component {
    handleClickOpen = () => {
        const { link } = this.props.options;
        let path = '';
        link.forEach(each => {
            const { route, routeParams, customParams } = each
            if (routeParams || customParams) {
                const id = routeParams ? this.props.match.params[routeParams] : this.props.dataItem.Id;
                path += route + "/" + id
            }
            else {
                path += route
            }

        })
        this.props.history.push(`${path}`)
    };

    render() {
        const { options, type, actions, compmap, components, edit, parentProps } = this.props;
        const { id, name, dialogtitle, buttonprops, dialogprops, ...otherOptions } = options ? options : {}
        const { text, icon, color, disabled, size, variant, ...otherButtonProps } = buttonprops ? buttonprops : {}
        let iconname, iconColor, iconFontSize, iconposition, otherIconProps
        if (icon) {
            iconname = icon.iconname
            iconColor = icon.iconColor
            iconFontSize = icon.iconFontSize
        }
        let disable = disabled
        if (edit === false) {
            disable = true
        }
        return (
            <div>
                <Button onClick={this.handleClickOpen}
                    color={!disable ? color : 'secondary'}
                    disabled={disable}
                    size={size}
                    variant={variant}
                    {...otherButtonProps}
                >
                    {text}
                    {(iconposition === 'left') && <Icon
                        color={iconColor || 'inherit'} fontSize={iconFontSize || 'default'} {...otherIconProps}>{iconname}
                    </Icon>
                    }
                    {iconposition && !(iconposition === 'left') && <Icon
                        color={iconColor || 'inherit'} fontSize={iconFontSize || 'default'} {...otherIconProps}>{iconname}
                    </Icon>
                    }
                </Button>
            </div>
        );
    }
}

export default withRouter(GridButton);
