
import { addcomp, delcomp, updatecomp } from './utilities'

const headerReducer = (state = null, action) => {
    const items = action.message
    switch (action.type) {
        case 'HEADER_STATUS_SAVE':
            return { ...state, ...items }
        case 'HEADER_STATUS_DEL':
            return { ...state, components: delcomp(state.components, items) }
        case 'HEADER_STATUS_ADD':
            return { ...state, components: addcomp(state.components, items) }
        case 'HEADER_STATUS_UPDATE':
            return { ...state }
        case 'NOTIFICATIONS':
            return { ...state, notifications: action.notifications }
        case 'OPEN_TOUR':
            return { ...state, isTourOpen: action.isTourOpen }
        case 'CLOSE_TOUR':
            return { ...state, isTourOpen: action.isTourOpen }
        case 'WORKFLOW_RELOAD':
            return { ...state, workflowreload: action.workflowreload }
        case 'RECORDS_PREVVALUE':
            return { ...state, recordssetvalue: action.recordssetvalue }
        case 'UNSAVED_DATA':
            return { ...state, unsaveddata: action.unsaveddata, initialdata: action.initialdata }
        case 'PROJMESSAGES':
            let messages = {}
            action.projmessages.forEach(e => messages[e.Key] = e.Message)
            return { ...state, projmessages: messages }
        case 'PASSTAB_DATA':
            return { ...state, passtabdata: action.passtabdata, initialdata: action.initialdata }
        case 'RECORD_DATA':
            return { ...state, recordidinfo: action.recordidinfo, recorddata: action.recorddata }
        case 'RESET_VALUES':
            return { ...state, resetval: action.isreset }
        case 'ONEVENT_STATUS_TRANS':
            return { ...state, onEventTransition: action.onEventTransition }
        case 'ONEVENT_SHOW_ERRORS':
            return { ...state, onEventshowError: action.onEventshowError }
        case 'TRIGGER_ONLOAD_SAVE':
            return { ...state, onTriggerSave: action.onTriggerSave }
        case 'SITE_PERMIT_TYPE_STATUS':
            return { ...state, onSitePermitTypesStatus: action.onSitePermitTypesStatus}
        case 'TRIGGER_AFTER_SAVE':
                return { ...state, onTirggerAfterSave: action.onTirggerAfterSave}
        default:
            return state

    }
}

export default headerReducer