/**
    *********************************************************
    Description: Handles Pop up component.
    Required props:   type
    limitations    : N/A
    *********************************************************
    **/
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withRouter } from 'react-router-dom';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReorderIcon from '@material-ui/icons/Reorder';
import Fab from '@material-ui/core/Fab';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Icon from '@material-ui/core/Icon';
import { getEnvVariable } from '../../../modules/environmentalList';
import { GetData } from '../../controls/helpers/requests';

const fieldTypes = {
    permdatasettingicon: PermDataSettingIcon,
    dollaricon: AttachMoneyIcon
}
/**
    *********************************************************
    Description: Handles Pop up title and closing popup.
    Required props:   N/A
    limitations    : N/A
    *********************************************************
    **/
const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1) * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const checkwidth = 960
class CPopUp extends React.Component {
    state = {
        // open: false,
        open: this.props.options.open || false,
        responsivefullscreen: (window.innerWidth < checkwidth) ? true : false
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleNavigate = (path) => {
        this.props.history.push(path)
    }

    updateDimensions = () => {
        if (window.innerWidth < checkwidth) {
            this.setState({ responsivefullscreen: true });
        }
        else {
            this.setState({ responsivefullscreen: false });
        }
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }


    handleButtonClick = () => {     
         this.handleClickOpen()
    }


    /**
    *********************************************************
    Description: Handles rendering of the component.
    Required props:   N/A
    limitations    : N/A
    *********************************************************
    **/

    render() {
        const { options, type, actions, compmap, accessPermissions, accessLevelPermissions, components, parentProps, edit, onResultDisable, extraProps, eventOn, helperText } = this.props;
        const { id, name, dialogtitle, buttonprops, hideon, title, dialogprops, styles, contentstyles, routes, showFullScreen, disableBackdropClick, disableEscapeKeyDown, responsivescreen, ...otherOptions } = options ? options : {}
        const { text, icon, color, disabled, size, variant, buttonlink, sidepopupbuttonlink, routeval, fabicon, isConfig, customstyles, materialicon, ...otherButtonProps } = buttonprops ? buttonprops : {}
        //const {  error } = this.props.currentValue
        let iconname, iconColor, iconFontSize, iconposition, otherIconProps
        let path = '';
        let issystemadmin = getEnvVariable('AccessPortal') === 'I' ? (accessPermissions && accessPermissions.isSystemAdmin === 'Y') ? true : false : false   
        const EachComp = materialicon ? fieldTypes[materialicon] : null
        if (icon) {
            iconname = icon.iconname
            iconColor = icon.iconColor
            iconFontSize = icon.iconFontSize
        }
        let closefunc
        if (type !== 'confirmationpopupbutton') closefunc = { onClose: this.handleClose }
        let disable = disabled
        if (edit === false) {
            // disable = true
        }
        if (buttonlink) {
            path = this.props.location.pathname + buttonlink
        }
        if (sidepopupbuttonlink) {
            let pathval = this.props.location.pathname
            pathval = pathval.substring(0, pathval.lastIndexOf("/"))
            let finalpath = pathval.includes("applicationtypes")
            if (finalpath) {
                path = this.props.location.pathname + sidepopupbuttonlink
            }
            else {
                path = this.props.location.pathname + '/' + this.props.dataItem[routeval] + sidepopupbuttonlink
            }
        }
        let btncolor = helperText ? 'secondary' : color
        return (
            <div className={!disable ? "" : "row"}>

                {this.props.buttonprops && fabicon && isConfig && !this.props.options.button &&
                    <i>&nbsp;&nbsp;&nbsp;<Fab size={size} className={customstyles ? customstyles : "float-end"} onClick={this.handleClickOpen} title={this.props.buttonprops.text ? this.props.buttonprops.text : ''} color={!disable ? btncolor : 'secondary'} {...otherButtonProps}> <EachComp />
                    </Fab>
                    </i>}
                {
                    !this.props.options.button && !isConfig && !buttonlink && !sidepopupbuttonlink && <Button onClick={() => this.handleButtonClick()}
                        color={!disable ? btncolor : 'secondary'}
                        disabled={disable}
                        size={size}
                        variant={variant}
                        {...otherButtonProps}
                    >
                        {text}
                        {(iconposition === 'left') && <Icon
                            color={iconColor || 'inherit'} fontSize={iconFontSize || 'default'} {...otherIconProps}>{iconname}
                        </Icon>
                        }
                        {iconposition && !(iconposition === 'left') && <Icon
                            color={iconColor || 'inherit'} fontSize={iconFontSize || 'default'} {...otherIconProps}>{iconname}
                        </Icon>
                        }

                    </Button>
                }
                <div>
                    {(helperText) && <FormHelperText error>{helperText}</FormHelperText>}
                </div>
                {/**
                    *********************************************************
                    Description:  Buttons onclick navigate to new page
                    Required props:   buttonlink,route
                    limitations    : N/A
                    *********************************************************
                     **/
                }
                {
                    !this.props.options.button && (buttonlink || sidepopupbuttonlink) && !fabicon && <Button onClick={() => this.handleNavigate(path)}
                        color={!disable ? color : 'secondary'}
                        disabled={disable}
                        size={size}
                        variant={variant}
                        {...otherButtonProps}
                    >

                        {text}
                    </Button>
                }
                {
                    ((hideon && issystemadmin) || !hideon) && this.props.buttonprops && fabicon && !isConfig && !this.props.options.button && (buttonlink || sidepopupbuttonlink) &&
                    <i>&nbsp;&nbsp;&nbsp;<Fab size={size} className={sidepopupbuttonlink ? "float-end" : "" } style={{ marginRight: '11px' }} onClick={() => this.handleNavigate(path)} title={this.props.buttonprops.text ? this.props.buttonprops.text : ''} color={!disable ? btncolor : 'secondary'} {...otherButtonProps}> <ReorderIcon />
                    </Fab>
                    </i>
                }


                {
                    !isConfig && <Dialog
                        style={styles}
                        disableBackdropClick={disableBackdropClick ? true : false}
                        disableEscapeKeyDown={disableEscapeKeyDown ? true : false}
                        aria-labelledby={id || name}
                        open={this.state.open}
                        fullWidth
                        {...dialogprops}
                        {...closefunc}
                        fullScreen={(responsivescreen && this.state.responsivefullscreen) || showFullScreen ? true : false}

                    >
                        {dialogtitle && <DialogTitle id={id} onClose={this.handleClose}>
                            {dialogtitle}
                        </DialogTitle>}
                        {((responsivescreen && this.state.responsivefullscreen) || showFullScreen) &&
                            <AppBar position="static">
                                <Toolbar variant="dense">
                                    <div className="row col-sm-12">
                                        <div className="col-sm-11"> <Typography className="pt-2" variant="h6" > {title}  </Typography></div>
                                        <div className="col-sm-1">
                                            <IconButton className="float-end" color="inherit" onClick={this.handleClose} aria-label="close">
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </Toolbar>
                            </AppBar>
                        }
                        <DialogContent {...otherOptions} className="pt-6" style={contentstyles}>
                            {components && components.map(each => {
                                const EachComp = compmap[each.type]
                                return <EachComp {...each} key={each.key} compmap={compmap} handleSlide={this.handleSlide} accessPermissions={accessPermissions} accessLevelPermissions={accessLevelPermissions} extraProps={extraProps} eventOn={eventOn} onClose={this.handleClose} dataItem={this.props.dataItem} />
                            })}
                        </DialogContent>
                    </Dialog>
                }
                {
                    this.state.open && isConfig && components && components.map(each => {
                        const EachComp = compmap[each.type]
                        return <EachComp {...each} key={each.key} compmap={compmap} handleSlide={this.handleClose} accessPermissions={accessPermissions} accessLevelPermissions={accessLevelPermissions} extraProps={extraProps} eventOn={eventOn} onClose={this.handleClose} dataItem={this.props.dataItem} />
                    })
                }
            </div >
        );
    }
}

export default withRouter(CPopUp);
