import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { GetData, PostData, PutData } from '../../core/controls/helpers/requests';
import { getEnvVariable } from '../environmentalList';
import CFormDropDown from '../../core/modules/form/cformdropdown';
import CUpload from '../../core/modules/form/cupload';
import atComponents from '../../json/common/Images/ImagesComponents';

export const fetchData = (requestType, url, queryParam, body) => {

    const queryStr = queryParam != null ? `${url}?${queryParam}` : `${url}`

    if (requestType === "GET") {
        return GetData(queryStr);
    }
    else if (requestType === "POST") {
        return PostData(queryStr, body);
    }
    else if (requestType === "PUT")
        return PutData(queryStr, body);
}


class FileImages extends React.Component {
    constructor(props) {
        super(props)
        const { read, update } = props.options.crud

        let saveUrl = '', removeUrl = '';
        console.log("here")
        if (update) {
            const { post } = update
            const { url, routeProps, matchProps } = post
            saveUrl = url
             removeUrl = url;
            routeProps.forEach(each => {
                const { value } = each
                saveUrl.replace(`{${value}}`, this.props.match.params[value])
            });
            matchProps.forEach(each => {
                const { value } = each
                saveUrl.replace(`{${value}}`, this.props[value])
            });
        }

        this.state = {
            imageTypeId: this.props.imageTypeId || 0,
            imageId: 0,
            imageTypedd: [],
            saveUrl: saveUrl || '',
            removeUrl: removeUrl || '',
            parentId: this.props.ParentId || this.props.match.params.routeid1,
            uploadedimages: [],
            updateimage: this.props.options.updateimage || false
        }
        this.componentsArray = { ...atComponents };
        this.atTypeOptions = this.componentsArray.components.find(x => x.key === "imageType").options;
        this.atUploadOptions = this.componentsArray.components.find(x => x.key === "imageupload").options;
        this.url1 = getEnvVariable('Application')+`/api/Images/${this.props.match.params.routeid1}/image/${this.props.match.params.routeid2}`;
        this.url2 = getEnvVariable('Admin')+`/api/ImageType/All/Active`;
    }
    handleFieldChange = (e) => {
        console.log(e)
        let sUrl = getEnvVariable('Application')+`/api/Images/${this.props.match.params.routeid1}/UploadFiles?imageId=0&imageTypeId=${e.target.value}`;
        this.setState({ imageTypeId: e.target.value, saveUrl: sUrl })
    }
    componentDidMount() {

        fetchData('GET', this.url2, null, null)

            .then(data => this.setState({
                imageTypedd: data,
            },() => {
                //Fetching data for
                if (this.state.updateimage) {
                    fetchData('GET', this.url1, null, null)

                        .then(data1 => this.setState({
                            imageTypeId: data1.imageTypeId,
                            uploadedimages: data1.imageDetails,
                            saveUrl: getEnvVariable('Application')+`/api/${this.props.match.params.routeid1}/UploadFiles?imageid=${this.props.match.params.routeid2}&imagetypeid=${data.imageTypeId}`
                        }))
                }
            }))
    }
    onStatusChange = (e) => {
        console.log(e)
    }
    render() {
        return (
            <Fragment>
                <div className="col-12">
                    <CFormDropDown value={this.state.imageTypeId} {...this.atTypeOptions}
                        handleFieldChange={this.handleFieldChange} dropdownvalues={this.state.imageTypedd} />

                    {this.state.imageTypeId > 0 &&
                        <Fragment>

                            <List>
                                {this.state.updateimage ? this.state.uploadedimages.map(each => {
                                    const { FileName, Id, imageId, } = each
                                    console.log(each)
                                    return <ListItem>
                                        <ListItemText
                                            primary={FileName}
                                        />
                                         <img src={`data:${each.ContentType};base64,${each.ContentStr}`} height ={200} width={200} />
                                        <ListItemSecondaryAction>
                                            <IconButton aria-label="Download">
                                                <DownloadIcon />
                                            </IconButton>
                                            <IconButton aria-label="Delete">
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                }) : <div></div>}


                            </List>

                            <InputLabel htmlFor="my-input" className="mt-5">Upload Files</InputLabel>
                            <CUpload saveUrl={this.state.saveUrl} removeUrl={this.state.removeUrl}
                                imageTypeId={this.state.imageTypeId} {...this.atUploadOptions}
                                onStatusChange={this.onStatusChange} batch={false} multiple={false} />
                        </Fragment>
                    }

                </div>

            </Fragment>
        )
    }
}
export default withRouter(FileImages);