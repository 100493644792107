import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withRouter } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DefaultSettings from '../components/settings';
import { getEnvVariable } from '../../../modules/environmentalList';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(2),
      },

      accordion: {
        border: '1px solid #ccc', // Add a border to the Accordion
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(1), // Adjust the margin between Accordions
      },

    heading: {
        fontSize: theme.typography.pxToRem(16),
        flexBasis: '63.33%',
        flexShrink: 0,
        color: 'rgb(13, 71, 161)',
        fontWeight: 400
    },
    error: {
        fontSize: theme.typography.pxToRem(16),
        flexBasis: '33.33%',
        flexShrink: 0,
        color: '#fafafa',
        fontWeight: 400
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

function Accordions(props) {
    const { steps, view, sections, SectionContext, sectionKeysData, errorSections, hideSections } = props
    const classes = useStyles();
    const SectionName = getEnvVariable('ModuleKey')=='WRS'? `Summary Section` : `Record Information`
    return (
        <div className={classes.root}>
            {sections.map((section, index) => {
                if (hideSections.includes(steps[index])) {
                    return null
                }
                return  <Accordion
                key={index}
                className={`${classes.accordion} ${steps[index]} ms-1`}
                defaultExpanded={
                  section.length > 0 &&
                  sectionKeysData &&
                  sectionKeysData[section[0].props.SectionKey] === true
                }
                        >
                    <AccordionSummary
                        style={{backgroundColor: errorSections[steps[index]] === true ? '#f44336' : '#358ecc' }}
                        expandIcon={<ExpandMoreIcon  />}
                    >
                       <Typography style={{  color: 'white'}} className={errorSections[steps[index]] === true ? classes.error :
                            classes.heading}>{steps[index] === 'Header' ? SectionName : steps[index]} &nbsp;
                            {SectionContext && steps[index] && SectionContext[steps[index]] &&
                                < Tooltip title={SectionContext[steps[index]] ? SectionContext[steps[index]].SectionDescription : null} placement="bottom-start" >
                                    <HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#ffffff' }} onClick={() => DefaultSettings.showDrawer(SectionContext[steps[index]].SectionContextHelp)} />
                                </Tooltip>}
                        </Typography>
                    </AccordionSummary>
                    <div className="col-sm-12 m-1">
                        {section.map((eachsec, idx) => {
                            return <AccordionDetails key={idx} className={eachsec.props.className}>
                                <div className="col-sm-12 m-1">{eachsec}</div>
                            </AccordionDetails>
                        })}
                    </div>
                </Accordion>
            })}
        </div>
    );
}
export default withRouter(Accordions)