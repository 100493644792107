    /**
      *********************************************************
          deprecated
      *********************************************************
**/

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { Component } from 'react';
import { checkcomp, requestApi } from './utilities';

class CDropDown extends Component {

    constructor(props) {
        super(props);
        const { name, initialvalue } = props.options
        this.subform = {}
        this.state = {
            globalitem: null
        }

    }

    componentDidMount() {
        const { options, initialvalue, checkkey } = this.props
        const { crud, values, name, transformProps } = options
        const { value, validations, error } = this.props.currentValue
        let valueKey, valueName
        if (transformProps) {
            valueKey = transformProps.value
            valueName = transformProps.name
        }
        else {
            valueKey = 'id'
            valueName = 'name'
        }
        if (crud) {
            const { create, read, update } = crud
            const del = crud.delete
            if (!values) {
                requestApi(read).then(results => {
                    const dropdownvalues = results
                    this.setState({
                        items: results.map(each => {
                            return { value: each[valueKey], name: each[valueName] }
                        })
                    })
                })
            }

        }
        if (this.props.setFormFieldValue && value) {
            this.props.setFormFieldValue(name, value, false, '', this.subform[value])
        }
    }

    handleChange = (name, rules, passer) => event => {
        const target = event.target;
        if (target) {
            const value = target && target.value;
            if (this.props.setFormFieldValue) {
                this.props.setFormFieldValue(name, value, false, '', this.subform[value])

                if (rules) {
                    const changedval = checkcomp(value, rules, 'onChange', passer)
                    this.setState({
                        globalitem: changedval
                    })
                }
            }

        }
    };

    handleBlur = () => (event) => {
        const { options } = this.props
        const { validations } = options
        const target = event.target;
        if (target) {
            const value = target && target.value;
            let invalid = ''
            if (validations && (validations.length > 0)) {
                validations && validations.forEach(each => {
                    // if (validationmap[each]) { // --correct validationmap is missing
                    //     const returnval = validationmap[each](value)
                    //     if (returnval) invalid += returnval + '/n'

                    //  }
                    if (invalid !== '') {
                        if (this.props.setFormFieldValue) {
                            this.props.setFormFieldValue(target.name, value, true, invalid)
                        }
                    }
                    else {
                        if (this.props.setFormFieldValue) {
                            this.props.setFormFieldValue(target.name, value)
                        }
                    }
                })
            }
            else {
                if (this.props.setFormFieldValue) {
                    this.props.setFormFieldValue(target.name, value)
                }
            }

        }
    }

    handleOpen = (name, rules, passer) => event => {

    }
    handleClose = (name, rules, passer) => event => {

    }


    render() {
        const { options, rules, passer, type, reCalculateFieldLayout } = this.props;
        const { values, initialvalue, transformProps } = options
        const { id, label, htmlForLabel, name, placeholder, validations, helperText, disabled, required, inputLabelProps, inputattrprops, inputprops, inputadornmentprops, margin, tooltip, contexthelp, ...otherOptions } = options
        const dropdownvalues = values || this.state.items
        const { value, error } = this.props.currentValue
        let helpfultext
        if (error) helpfultext = this.props.currentValue.helperText
        return (
            <FormControl disabled={disabled} error={error} required={required} fullWidth>
                <InputLabel shrink htmlFor={htmlForLabel}>{label}</InputLabel>&nbsp;
                {/* <Fragment>
                    {(tooltip || contexthelp) && <div > <Typography variant={'caption'} className="text-secondary">{label}&nbsp;
                        < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                            <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} onClick={() => DefaultSettings.showDrawer(contexthelp)} />
                        </Tooltip>  </Typography>  <Typography className="fw-bold" variant={'body2'} color={'textPrimary'} className="fw-bold">{value}   </Typography>
                    </div>}
                    {!tooltip && <div > <Typography variant={'caption'} className="text-secondary">{label}&nbsp; </Typography>   <Typography className="fw-bold eps-content-wrap" variant={'body2'} color={'textPrimary'} className="fw-bold eps-content-wrap">{value}   </Typography>
                    </div>}
                </Fragment > */}
                <Select
                    value={value || initialvalue || ''}
                    onChange={this.handleChange(name, rules, passer)}
                    onOpen={this.handleOpen(name, rules, passer)}
                    onClose={this.handleClose(name, rules, passer)}
                    //onBlur={this.handleBlur()}
                    // input={<Input {...inputprops} />}
                    inputProps={{
                        name: name,
                        id: id || name,
                        ...inputattrprops
                    }}
                >
                    {dropdownvalues && dropdownvalues.map((each, index) => {
                        const { value, name, subform, key } = each
                        if (subform) {
                            this.subform[value] = subform
                        }
                        return <MenuItem key={key || ('item' + value)} value={value}>{name}</MenuItem>
                    })}
                </Select>
                {(helpfultext || helperText) && <FormHelperText>{helpfultext || helperText}</FormHelperText>}
                {this.state.globalitem}
            </FormControl>
        )
    }
}

export default CDropDown;




/**
 * {
 * type:'dropdown',
 * label:'dropdown',
 * values:[1,2,4],
 * options:{
 *
 * },
 * rules:[{
 *      'forvalue':[1,2,4],
 *      'onevent':['onchange','onblur','onfocus'],
 *      'effect': 'create',
 *      'condition':[
 *      {
 *          type:'textbox',
 *          label:'textbox',
 *          value:'textbox'
 *        },
 *        {
 *          type:'textbox',
 *          label:'textbox',
 *          value:'textbox'
 *        }
 *
 *  ]
 * }],
 * defaults:{
 *      theme:'material',
 * }
 * }
 */






         // if (add) {
        //     const { mapeventstovalues, scope, items } = add
        //     if (mapeventstovalues.onChange) {
        //         if (scope) {
        //             const { passer, passertype, passervar } = this.props
        //             let passeraction
        //             if (passer) {
        //                 passeraction = <SpecificPasser type={passertype} message={items} />
        //             }
        //             this.setState({
        //                 textelement: <Fragment>
        //                     {this.state.textelement}
        //                     {passeraction}
        //                 </Fragment>
        //             })

        //         }
        //         else {
        //             this.props.dropdownchange(items)
        //         }
        //     }
        //     else return
        // }
        // if (del) {
        //     const { mapeventstovalues, items } = del
        //     if (mapeventstovalues.onChange) {
        //         const values = mapeventstovalues.onChange
        //         const foundval = values.length > 0 && values.find(each => {
        //             const { value } = each.value
        //             return targetval === value
        //         })
        //         if (foundval) {
        //             const scope = foundval.scope
        //             if (scope.length > 0) {
        //                 scope.foreach(each => {
        //                     const { passer, passertype } = this.props
        //                     let passeraction
        //                     const { name, items } = each
        //                     if (passer) {
        //                         passeraction = <SpecificPasser type={name} message={items} />
        //                     }
        //                     this.setState({
        //                         textelement: <Fragment>
        //                             {this.state.textelement}
        //                             {passeraction}
        //                         </Fragment>
        //                     })
        //                 })
        //             }
        //             else {
        //                 this.props.dropdownchange(items)
        //             }
        //             // if (scope) {
        //             //     const { passer, passertype, passervar } = this.props
        //             //     let passeraction
        //             //     if (passer) {
        //             //         passeraction = <SpecificPasser type={passertype} message={items} />
        //             //     }
        //             //     this.setState({
        //             //         textelement: <Fragment>
        //             //             {this.state.textelement}
        //             //             {passeraction}
        //             //         </Fragment>
        //             //     })

        //             // }
        //             // else {
        //             //     this.props.dropdownchange(items)
        //             // }
        //         }
        //     }
        //     else return
        // }
        // if (edit) {
        //     const { mapeventstovalues, scope, items } = edit
        //     if (mapeventstovalues.onChange) {
        //         if (scope) {
        //             const { passer, passertype, passervar } = this.props
        //             let passeraction
        //             if (passer) {
        //                 passeraction = <SpecificPasser type={passertype} message={items} />
        //             }
        //             this.setState({
        //                 textelement: <Fragment>
        //                     {this.state.textelement}
        //                     {passeraction}
        //                 </Fragment>
        //             })

        //         }
        //         else {
        //             this.props.dropdownchange(items)
        //         }
        //     }
        //     else return
        // }
        // if (styling) {
        //     const { mapeventstovalues, scope, items } = styling
        //     if (mapeventstovalues.onChange) {
        //         if (scope) {
        //             const { passer, passertype, passervar } = this.props
        //             let passeraction
        //             if (passer) {
        //                 passeraction = <SpecificPasser type={passertype} message={items} />
        //             }
        //             this.setState({
        //                 textelement: <Fragment>
        //                     {this.state.textelement}
        //                     {passeraction}
        //                 </Fragment>
        //             })

        //         }
        //         else {
        //             this.props.dropdownchange(items)
        //         }
        //     }
        //     else return
        // }