import React, { Fragment, useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import useDeepCompareEffect from 'use-deep-compare-effect';
import WorkFlowUse from '../../../modules/workflow/workflowuse';
import { onTirggerAfterSave, setResetValue } from '../../actions';
import HeaderAlert from '../../modules/form/calertbox';
import { Evaluate } from '../effects/calculate';
import { handleActionsToEffects } from '../effects/effects';
import { fieldValidate } from '../helpers/checkers';
import { groupByType } from '../helpers/components';
import { fieldTypes as customTypes, hookTypes } from '../helpers/fieldtypes';
import { layoutTypes } from '../layout/types';
import { objectConversion } from '../wrappers/helpers/converters';
import AutoComplete from './autocomplete';
import Button from './buttons';
import MultiDropdown from './checkautocomplete';
import Checkbox from './checkbox';
import CheckboxesGroup from './checkboxgroup';
import MissingItemsDrawer from './custom/missinglists';
import DatePicker from './datepicker';
import DateTimePicker from './datetimepicker';
//wrs holidays date
import DateMonth from './date_daymonth';import Timer from './daystimer';
import Dropdown from './dropdown';
import Label from './label';
import Link from './link';
import MapSection from './map';
import MultiAdd from './multiadd';
import NumberFormat from './numberformat';
import PhoneFormat from './phoneformat';
import RadioGroup from './radiogroup';
import PopUp from './sidepopup';
import Signature from './signature';
import TextField from './textbox';
import TextBox from './textfield_box';
import TimePicker from './timepicker';
import SubHeader from './subheader';
const _ = require('lodash');

const fieldTypes = {
    DS: Dropdown,
    TB: TextField,
    TA: TextField,
    DN: NumberFormat,
    N: NumberFormat,
    P: NumberFormat,
    RB: RadioGroup,
    CB: CheckboxesGroup,
    CF: Checkbox,
    C: NumberFormat,
    button: PopUp,
    RG: RadioGroup,
    // DM: Label,
    D: DatePicker,
    DT: DateTimePicker,
    DDM:DateMonth,
    T: TimePicker,
    AC: AutoComplete,
    CM: Label,
    LB: Label,
    PH: PhoneFormat,
    RC: Label,
    B: RadioGroup,
    Map: MapSection,
    CSC: Checkbox,
    ULB: Label,
    DM: MultiDropdown,
    TM: Timer,
    G: MultiAdd,
    TBB: TextBox,
    SGN: Signature,
    'workflowuse': WorkFlowUse,
    HM: HeaderAlert,
    L: Link,
    BU: Button,
    'LB-BU': Button,
    RBT: MissingItemsDrawer,
    SH:SubHeader,
}

const checkBoxTransformProps = {
    N: false,
    Y: true,
    'true': 'Y',
    'false': 'N'

}

function DefaultValues(fieldDetails, prevVal, name) {
    const { items, extraProps } = fieldDetails || {};
    if (extraProps) {
        const { transformProps } = extraProps;
        if (items && items.length > 0) {
            let item = (items || []).find(i => i['IsDefault'] === 'Y' || i['isDefault'] === 'Y')
            return (item && item[transformProps.value]) ? item[transformProps.value] : ('' || null)
        }
    }
    else return prevVal[name]
}


function handleFieldChange(state, action) {
    const { type, ...otherProps } = action;
    console.log(otherProps)
    try {
        if (type === 'init') {
            const { values } = otherProps
            return { ...state, fieldValues: { ...values } }
        }
        if (type === 'value') {
            const { name, value, dataAttr } = otherProps
            return { ...state, fieldValues: { ...state.fieldValues, [name]: value }, dataAttr: { ...state.dataAttr, [name]: dataAttr } }
        }
        if (type === 'prop') {
            const { name, value, dataAttr } = otherProps
            let newstate = { ...state }
            newstate = { ...state, fieldProps: { ...state.fieldProps, [otherProps.name]: { ...state.fieldProps[otherProps.name], ...otherProps.value } }, dataAttr: { ...state.dataAttr, [otherProps.name]: dataAttr } }
            return newstate
        }
        if (type === 'props') {
            const fields = otherProps
            // let newstate = { ...state }
            fields.forEach(each => {
                state = { ...state, fieldProps: { ...state.fieldProps, [each]: { ...state.fieldProps[each], ...otherProps[each] } } }
            })
            return state
        }
        if (type === 'inital') {
            const resultskeys = Object.keys(state.fieldValues)
            //  let newstate = { ...state }
            resultskeys.forEach(each => {
                state = { ...state, fieldValues: { ...state.fieldValues, [each]: otherProps.updates[each] } }
            })
            return state
        }
        if (type === 'required') {
            //  const { fields } = otherProps
            const resultskeys = Object.keys(otherProps.fields)
            // let newstate = { ...state }
            resultskeys.forEach(each => {
                state = { ...state, fieldProps: { ...state.fieldProps, [each]: { ...state.fieldProps[each], ...otherProps.fields[each] } } }
            })
            return state
        }
        if (type === 'validations') {
            state = { ...state, validations: { ...otherProps } }
        }

        if (type === 'updates') {
            let newstate = { ...state }
            const { updates, triggersave } = otherProps
            const { prop, value, layout, validations, required, businessrules, map, nestedlist, error, initial, BRHiddenState,
                edit, createduplicate, loadDefaultValues, domainmapper, savesuccess, nestedlistArrayValues,autocompleteselectonload, BRActivity } = updates //getLocalStorageData
            if (nestedlistArrayValues) { // change the structure of this code write for infinity
                nestedlistArrayValues.forEach(nestedlistArrayValues_each => {
                    const { items, name, itemfilterval, otherProps } = nestedlistArrayValues_each
                    const { mapNameList, nested, subnested, valuefrom } = otherProps
                    let itemslist;
                    if (items && items.length > 0 && itemfilterval && state && state.fieldValues && state.fieldValues[itemfilterval]) {
                        itemslist = itemfilterval ? _.find(items, { 'Id': newstate.fieldValues[itemfilterval] }) : items[0]
                    }
                    else if (valuefrom && items && items.length > 0 && itemfilterval && state && state.fieldValues && state.fieldValues[valuefrom] && state.fieldValues[valuefrom][itemfilterval]) {
                        itemslist = itemfilterval ? _.find(items, { 'Id': newstate.fieldValues[valuefrom][itemfilterval] }) : items[0]
                    }
                    else if (items && !Array.isArray(items)) {
                        itemslist = items
                    }
                    itemslist && name.forEach(each => {
                        const { key, value, nestedname, name, nonnested } = each
                        let nestedvalue = nestedname ? nestedname : (nested ? nested : null)
                        if (name && nonnested) {
                            state = { ...state, fieldValues: { ...state.fieldValues, [name]: itemslist[mapNameList[name]] } }
                        }
                        else if (name) {
                            state = { ...state, fieldValues: { ...state.fieldValues, [name]: nestedvalue ? itemslist[nestedvalue][mapNameList[name]] : itemslist[mapNameList[name]] } }
                        }
                        else if (subnested) {
                            state = { ...state, fieldValues: { ...state.fieldValues, [each]: nestedvalue ? itemslist[nestedvalue][subnested][mapNameList[each]] : itemslist[subnested][mapNameList[each]] } }
                        }
                        else
                            state = { ...state, fieldValues: { ...state.fieldValues, [each]: nestedvalue ? itemslist[nestedvalue][mapNameList[each]] : itemslist[mapNameList[each]] } }
                    })
                    newstate = { ...state }
                })
            }
            if (autocompleteselectonload) { // added code for WRS Assigned To Dropdown by Manoj Thiparapu
                autocompleteselectonload.forEach(autocompleteselectonload_each => {
                    const { items, name, value, itemfilterval, otherProps } = autocompleteselectonload_each
                    const { mapNameList, nested, subnested, valuefrom } = otherProps
                    let itemslist;
                    let assignedids=[]
                    let ids=''
                    if (items && items.length > 0) {
                        assignedids= items.filter(e=>e.IsSendEmailNotification=="Y")
                        itemslist = assignedids.map(a => a.UserId);
                        for(let i=0;i<itemslist.length;i++)
                        {
                            ids = itemslist.join(",")
                        }
                    if( value[name] && ids)
                    { //selected value
                        let val = value[name]
                        var noprimeuser = items.find(e => val.includes(e.UserId));
                        ids = ids == val ? ids : val.includes(ids) ? val : ids != val ? (noprimeuser !=null ? val : ids) : val
                    }
                }
                    items.forEach(each => {
                        const { key, value, nestedname, nonnested } = each
                        state={...state,fieldProps:{...state.fieldProps,[name]:{...state.fieldProps[name],items:items}},fieldValues:{...state.fieldValues,[name]:ids}}
                        })
                    newstate = { ...state }
                })
            }
            if (prop) {
                prop.forEach(each => {
                    const { name, ...otherPropProperties } = each
                    if (otherPropProperties && otherPropProperties.hasOwnProperty('allowedValues') && otherPropProperties.allowedValues.length > 0) {
                        let transformValue = (state.fieldProps[name] && state.fieldProps[name].extraProps && state.fieldProps[name].extraProps.transformProps &&
                            state.fieldProps[name].extraProps.transformProps.value) || 'value';
                        const defaultItems = state.fieldProps[name] && state.fieldProps[name].defaultItems && state.fieldProps[name].defaultItems.length > 0 ?
                            state.fieldProps[name].defaultItems : ((state.fieldProps[name] && state.fieldProps[name].items) ? state.fieldProps[name].items : [])
                        if ((state.fieldProps[name] && state.fieldProps[name].defaultItems && state.fieldProps[name].defaultItems &&
                            state.fieldProps[name].defaultItems.length > 0) || (state.fieldProps[name] && state.fieldProps[name].items && state.fieldProps[name].items.length > 0)) {
                            const filterItems = (state.fieldProps[name].defaultItems && state.fieldProps[name].defaultItems) ||
                                ((state.fieldProps[name] && state.fieldProps[name].items) ? state.fieldProps[name].items : []).filter(function (item) {
                                    return otherPropProperties.allowedValues.indexOf((item[transformValue]).toString()) > -1
                                })
                            state = {
                                ...state, fieldProps: {
                                    ...state.fieldProps, [name]: {
                                        ...state.fieldProps[name],
                                        items: filterItems || [],
                                        defaultItems: defaultItems || []

                                    }
                                },
                            }
                        }
                        else {
                            state = {
                                ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], ...otherPropProperties } },
                            }
                        }
                        newstate = { ...state }
                    }
                    if (otherPropProperties && otherPropProperties.hasOwnProperty('hasValue') && otherPropProperties.hasValue
                        && (otherPropProperties.valueType === 'D' || otherPropProperties.valueType === 'C' || otherPropProperties.valueType === 'R')) {

                        state = {
                            ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], ...otherPropProperties, className: 'd-block', } },
                            fieldValues: {
                                ...state.fieldValues, [name]:
                                    otherPropProperties.valueType === 'D' ?
                                        (otherPropProperties.value === '' || otherPropProperties.value === undefined) ?
                                            DefaultValues(state.fieldProps[name], state.prevFieldvalues, name) : (otherPropProperties.value || (otherPropProperties.disabled ? 'Y' : 'N')) :
                                        otherPropProperties.valueType === 'C' ? Evaluate(otherPropProperties.value, state.fieldValues,
                                            otherPropProperties.currentrefIds, otherPropProperties.dynamicData, (state.fieldProps[name] && state.fieldProps[name].sendActualVal && state.fieldProps[name].sendActualVal === true) ? true : false)
                                            : otherPropProperties.valueType === 'R' ? state.prevFieldvalues[name] : state.fieldValues[name]
                            }
                        }
                        //      console.log("name",name)            
                        newstate = { ...state }
                    }
                    if (otherPropProperties && otherPropProperties.hasOwnProperty('isVisible')) {
                        if (!otherPropProperties.isVisible)
                            state = {
                                ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], ...otherPropProperties, className: 'd-none' } },
                            }

                        else state = {
                            ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], ...otherPropProperties, className: 'd-block' } },
                        }
                        newstate = { ...state }
                    }
                    if (name === 'domainitems') {
                        let tempDomains = {};
                        if (otherPropProperties && otherPropProperties.domainitems)
                            tempDomains = otherPropProperties.domainitems
                        else tempDomains = otherPropProperties
                        let listKeys = Object.keys(tempDomains)
                        for (let i = 0; i < listKeys.length; i++) {
                            if (state.fieldProps[listKeys[i]])
                                state = {
                                    ...state, fieldProps: {
                                        ...state.fieldProps, [listKeys[i]]: {
                                            ...state.fieldProps[listKeys[i]],
                                            items: tempDomains[listKeys[i]] || []
                                        }
                                    },
                                }
                        }
                        newstate = { ...state }
                    }
                    if (name === 'edit') {
                        const resultskeys = Object.keys(state.fieldProps)
                        resultskeys.map(name => {
                            state = {
                                ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], type: 'label', edit: !edit || true } },
                            }
                        })
                        newstate = { ...state }
                    }
                    else {
                        state = {
                            ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], ...otherPropProperties } },
                        }
                        newstate = { ...state }
                    }
                })
            }
            if (domainmapper) {
                domainmapper.forEach(each => {
                    const { name } = each;
                    let splitNames = name.split(',')
                    if (splitNames.length > 1) {
                        splitNames.map(splitname => {
                            state = {
                                ...state, fieldValues: { ...state.fieldValues, [splitname]: (each[splitname] && each[splitname].length > 0) ? each[splitname][0].name : '' },
                            }
                        })
                    }
                    else state = {
                        ...state, fieldValues: { ...state.fieldValues, [name]: (each[name] && each[name].length > 0) ? each[name][0].name : '' },
                    }
                })
                newstate = { ...state }

            }
            if (edit || createduplicate) {
                const resultskeys = Object.keys(state.fieldProps)
                let roleList = JSON.parse(localStorage.getItem('roles'));
                let found = false;
                let show = false;
                let unique = []
                if (roleList && roleList.length > 0) {
                    let array = roleList
                    unique = [...new Set(array.map(item => item.RoleType))];
                }
                resultskeys.map(name => {
                    if (state.fieldProps[name].readOnly) {
                        found = unique.find(element => element === state.fieldProps[name].readOnly);
                        if (found) {
                            state = {
                                ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], type: 'label', edit: !edit || true } },
                            }
                        }
                        else {
                            state = createduplicate ? {
                                ...state, fieldProps: {
                                    ...state.fieldProps, [name]: {
                                        ...state.fieldProps[name], type: state.fieldProps[name].changefieldto ?
                                            state.fieldProps[name].changefieldto : 'label', createduplicate: !createduplicate || true
                                    }
                                },
                            } : {
                                ...state, fieldProps: {
                                    ...state.fieldProps, [name]: {
                                        ...state.fieldProps[name], type: (state.fieldProps[name].changefieldto && !state.fieldProps[name].noEdit) ?
                                            state.fieldProps[name].changefieldto : 'label', edit: !edit || true
                                    }
                                },
                            }
                        }
                    }
                    else if (state.fieldProps[name].showOnly) {
                        show = unique.find(element => element === state.fieldProps[name].showOnly);
                        if (show) {
                            state = {
                                ...state, fieldProps: {
                                    ...state.fieldProps, [name]: {
                                        ...state.fieldProps[name], type: state.fieldProps[name].changefieldto ?
                                            state.fieldProps[name].changefieldto : 'label', edit: !edit || true
                                    }
                                },
                            }
                        }
                        else {
                            state = {
                                ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], type: 'label', edit: !edit || true } },
                            }
                        }
                    }
                    else
                        state = createduplicate ? {
                            ...state, fieldProps: {
                                ...state.fieldProps, [name]: {
                                    ...state.fieldProps[name],
                                    type: (state.fieldProps[name].changefieldto) ? state.fieldProps[name].changefieldto : 'label',
                                    createduplicate: !createduplicate || true
                                }
                            },
                        } : {
                            ...state, fieldProps: {
                                ...state.fieldProps, [name]: {
                                    ...state.fieldProps[name],
                                    type: (state.fieldProps[name].changefieldto && !state.fieldProps[name].noEdit) ? state.fieldProps[name].changefieldto : 'label', edit: !edit || true
                                }
                            },
                        }
                })
                newstate = { ...state }
            }

            if (initial && initial.length > 0 && state && state.fieldValues) {
                let resultskeys = Object.keys(state.fieldValues)
                let initalfieldValues = state.fieldValues;

                initial.forEach(init => {
                    if (init) {
                        resultskeys.forEach(each => {
                            let value = init[each] ? init[each] : objectConversion(init, each)
                            state = { ...state, fieldValues: { ...state.fieldValues, [each]: ((value || value === 0) && value !== ' ') ? value : initalfieldValues[each] } } //each.split(".").reduce((res, prop) => { return res && res[prop] }, initialdata)
                        })
                        initalfieldValues = state.fieldValues;
                        newstate = { ...state }
                        // dispatch(saveFindings(initalfieldValues))
                    }
                })
            }
            if (loadDefaultValues) {
                loadDefaultValues.forEach(each => {
                    const { defaultCondition, value, checkDefaultValue, savetype } = each.transformProps
                    let allItems = (initial && initial[0]) ? initial[0] : state.fieldValues.navigation ? state.fieldValues.navigation : null
                    let defaultval
                    if (savetype === 'add' && defaultCondition && value && checkDefaultValue && allItems && allItems.length) {
                        defaultval = allItems.find(i => i[defaultCondition] === checkDefaultValue)
                    }
                    if (defaultval ? defaultval : allItems) {
                        let objval = defaultval ? defaultval : allItems
                        if (each.datasetval) {
                            let items = each.datasetval
                            let dataAttrVal = {}
                            for (let key in items) {
                                let itemkey = items[key]
                                let itemval = objval[key]
                                dataAttrVal[itemkey] = itemval
                            }
                            state = {
                                ...state, fieldValues: { ...state.fieldValues, [each.name]: objval[value] }, dataAttr: { ...state.dataAttr, [each.name]: dataAttrVal },
                            }
                            newstate = { ...state }
                        }
                        else {
                            state = {
                                ...state, fieldValues: { ...state.fieldValues, [each.name]: objval[value] }
                            }
                            newstate = { ...state }
                        }
                    }
                })
            }

            if (value) {
                value.forEach(each => {
                    const { key, result, resultKey } = each
                    state = { ...state, fieldValues: { ...state.fieldValues, [key]: resultKey ? state.fieldValues[resultKey] : result.value } }

                })
                newstate = { ...state }
            }
            if (BRActivity) {
                newstate = { ...state, 'triggerBRActivity': false }
            }
            if (nestedlistArrayValues) { // change the structure of this code write for infinity
                nestedlistArrayValues.forEach(nestedlistArrayValues_each => {
                    const { items, name, itemfilterval, otherProps } = nestedlistArrayValues_each
                    const { mapNameList, nested, subnested, valuefrom } = otherProps
                    let itemslist=[];
                    if (items && items.length > 0 && itemfilterval && state && state.fieldValues && state.fieldValues[itemfilterval]) {
                        itemslist = itemfilterval ? _.find(items, { 'Id': newstate.fieldValues[itemfilterval] }) : items[0]
                    }
                    else if (valuefrom && items && items.length > 0 && itemfilterval && state && state.fieldValues && state.fieldValues[valuefrom] && state.fieldValues[valuefrom][itemfilterval]) {
                        itemslist = itemfilterval ? _.find(items, { 'Id': newstate.fieldValues[valuefrom][itemfilterval] }) : items[0]
                    }
                    else if (items && !Array.isArray(items)) {
                        itemslist = items
                    }
                    itemslist && name.forEach(each => {
                        const { key, value, nestedname, name, nonnested } = each
                        let nestedvalue = nestedname ? nestedname : (nested ? nested : null)
                        if (name && nonnested) {
                            state = { ...state, fieldValues: { ...state.fieldValues, [name]: itemslist[mapNameList[name]] } }
                        }
                        else if (name) {
                            state = { ...state, fieldValues: { ...state.fieldValues, [name]: nestedvalue ? itemslist[nestedvalue][mapNameList[name]] : itemslist[mapNameList[name]] } }
                        }
                        else if (subnested) {
                            state = { ...state, fieldValues: { ...state.fieldValues, [each]: nestedvalue ? itemslist[nestedvalue][subnested][mapNameList[each]] : itemslist[subnested][mapNameList[each]] } }
                        }
                        else
                            state = { ...state, fieldValues: { ...state.fieldValues, [each]: nestedvalue ? itemslist[nestedvalue][mapNameList[each]] : itemslist[mapNameList[each]] } }
                    })
                    newstate = { ...state }
                })
            }
            if (savesuccess) {
                let values = state.fieldValues
                if (savesuccess[0] && savesuccess[0].fieldValues)
                    values = savesuccess[0].fieldValues
                if (state.fieldValues['edit']) {
                    const resultskeys = Object.keys(state.fieldProps)
                    resultskeys.map(name => {
                        state = {
                            ...state, fieldProps: {
                                ...state.fieldProps, [name]: {
                                    ...state.fieldProps[name], value: values[name],
                                    type: (state.fieldProps[name].customField || (savesuccess[0] && savesuccess[0].noEdit)) ?
                                        state.fieldProps[name].type : 'label', helperText: '', error: false,
                                    edit: (savesuccess[0] && savesuccess[0].noEdit) ? true : false
                                }
                            }, triggerBRActivity: savesuccess[0] ? true : false
                        }
                    })
                    newstate = {
                        ...state, back: state.fieldValues['back'] && state.fieldValues['back'] === true ? true : false,
                        triggerBRActivity: savesuccess[0] ? true : false,
                        fieldValues: {
                            ...state.fieldValues, ...values, ['save']: false,
                            ['edit']: (savesuccess[0] && savesuccess[0].noEdit) ? true : false
                        }
                    }
                    // dispatch(saveFindings(values))
                }
                else if (state.fieldValues['createduplicate']) {
                    newstate = { ...state, back: true }
                }
                else {
                    let data = savesuccess && savesuccess.length > 0 && savesuccess[0].fieldValues || {}
                    data = _.merge(state.fieldValues, data)
                    newstate = {
                        ...state, addData: { ...state.fieldValues, ...data }, fieldValues: {
                            ...data, ['save']: false, ['edit']: true,
                        }, back: savesuccess && savesuccess.length > 0 && savesuccess[0].back === false ? false : true, triggerBRActivity: savesuccess[0] ? true : false,
                    }
                }
            }
            if (error) {
                let fielderrors = error[0].error
                if (fielderrors && fielderrors.errors) {
                    fielderrors = fielderrors.errors
                }
                let errorKeys = Object.keys(fielderrors);
                errorKeys.forEach(err => {
                    state = {
                        ...state, fieldProps: { ...state.fieldProps, [err]: { ...state.fieldProps[err], error: true, helperText: fielderrors[err][0] } },
                    }
                    newstate = { ...state }
                })
                newstate = { ...newstate, fieldValues: { ...newstate.fieldValues, ['save']: false } }
            }
            if (map) {
                map.forEach(each => {
                    const { key, value } = each
                    state = { ...state, fieldValues: { ...state.fieldValues, [key]: value } }
                })
                newstate = { ...state }

            }
            if (nestedlist) {
                const { items, name, otherProps } = nestedlist[0]
                const { mapNameList, nested, subnested } = otherProps
                name.forEach(each => {
                    const { key, value } = each
                    state = { ...state, fieldValues: { ...state.fieldValues, [each]: subnested ? (items && items[nested][subnested][mapNameList[each]]) : (items && items[nested][mapNameList[each]]) } }
                })
                newstate = { ...state }
            }
            if (validations) {
                if (Array.isArray(validations)) {
                    validations.forEach(valids => {
                        if (valids['validations']) {
                            state = { ...state, fieldValidations: valids['validations'] }
                            newstate = { ...state }
                        }
                        if (valids['required']) {
                            valids['required'].forEach(each => {
                                let name = (state.validationRequiredMapKeys && state.validationRequiredMapKeys[each]) || each
                                state = { ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], required: true } } }
                            })
                            newstate = { ...state, requiredFields: valids['required'] }
                        }
                        if (valids['tooltip']) {
                            if (Object.keys(valids['tooltip']).length > 0)
                                Object.keys(valids['tooltip']).forEach(each => {
                                    state = { ...state, fieldProps: { ...state.fieldProps, [each]: { ...state.fieldProps[each], tooltip: valids['tooltip'][each] } } }
                                })
                            newstate = { ...state }
                        }
                        if (valids['contexthelp']) {
                            if (Object.keys(valids['contexthelp']).length > 0)
                                Object.keys(valids['contexthelp']).forEach(each => {
                                    state = { ...state, fieldProps: { ...state.fieldProps, [each]: { ...state.fieldProps[each], contexthelp: valids['contexthelp'][each] } } }
                                })
                            newstate = { ...state }
                        }
                        if (valids['sectionToolTips']) {
                            if (Object.keys(valids['sectionToolTips']).length > 0) {
                                let SectionContext = state.sections.SectionContext;
                                Object.keys(valids['sectionToolTips']).forEach(each => {
                                    Object.keys(state.sections.SectionContext).forEach(section => {
                                        if (state.sections.SectionContext[section].key === each)
                                            SectionContext[section] = { ...state.sections.SectionContext[section], 'SectionDescription': valids['sectionToolTips'][each] }
                                    })
                                    state = { ...state, sections: { ...state.sections, SectionContext: SectionContext } }
                                })
                            }
                            newstate = { ...state }
                        }
                    })
                }
                else {
                    state = { ...state, fieldValidations: validations }
                    newstate = { ...state }
                }

            }
            if (businessrules) {
                newstate = {
                    ...state, conditions: {
                        ...state.conditions, 'afterSave': {
                            ...state.conditions['afterSave'], ...businessrules[0]['afterSave']
                        },
                        'onChange': {
                            ...state.conditions['onChange'], ...businessrules[0]['onChange']
                        }, 'onLoad': {
                            ...state.conditions['onLoad'], ...businessrules[0]['onLoad']
                        },
                        'auto': { ...state.conditions['auto'], ...businessrules[0]['auto'] },
                        'onEvent': {
                            ...state.conditions['onEvent'], ...businessrules[0]['onEvent']
                        },
                        'info': {
                            ...state.conditions['info'], ...businessrules[0]['info']
                        },
                    }
                }
            }
            if (BRHiddenState) {
                if (BRHiddenState && BRHiddenState[0]) {
                    localStorage.setItem('RecordHiddenState', JSON.stringify(Object.values(BRHiddenState[0])))
                }
            }
            if (required) {
                required.forEach(each => {
                    let name = (state.validationRequiredMapKeys && state.validationRequiredMapKeys[each]) || each
                    state = { ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], required: true } } }
                })
                newstate = { ...state, requiredFields: required }
            }
            if (layout) {
            }
            if (newstate) {
                newstate = { ...newstate, triggersave: triggersave || false }
                return newstate
            }
            else {
                return { ...state, triggersave: triggersave || false }
            }
        }
        if (type === 'customfields') {
            const { layout, components } = otherProps
            const { group, ...othersLayout } = layout
            return {
                ...state, fieldProps: { ...state.fieldProps, ...components }, fieldsLayout: {
                    ...state.fieldsLayout, ...othersLayout,
                    group: state.fieldsLayout.group.concat(group).reduce((acc, current) => {
                        const x = acc.find(item => item.order === current.order);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            const { position, components } = current
                            x.components.splice(position, 0, ...components)
                            return acc;
                        }
                    }, [])
                }
            }
        }
    }
    catch (ex) {
        console.log("sections : " + ex)
    }
    return state
}


export default function useSections(props) {
    const { key, components, layout, routeParams, values, displayName, aliases, removeValues, mapActionsToEffects, dispatchSharedValueChange,
        validations, required, dataAttributes, sections, validationMapKeys, fieldsinSectionNames, validationRequiredMapKeys, fieldsinSection, mapSettingsJson,
        fieldLabelsinSection, fieldKeysValues, fieldMappedColName, initRun } = props
    let layoutType = null
    let sectionType = (sections && sections.type) || '';
    const counter = useRef(0)
    const sharedProps = props.sharedProps || {}
    const sharedValues = props.sharedValues || []
    const errors = useRef(false)
    let params = useParams();
    let errorSections = {}
    let hideSections = []
    const prevState = useRef(sharedProps);
    const resetval = useSelector(state => state.header && state.header.resetval);
    const parentRecordVals = useSelector(state => state.sidebar && state.sidebar.modules);
    const dispatch = useDispatch()
    const [prevFieldvalues, setPrevFeildValues] = useState(values);
    const [currValue, setCurrValue] = useState(null);
    const [fields, dispatchFieldsChange] = useReducer(
        handleFieldChange,
        {
            fieldValues: values, fieldProps: components, fieldsLayout: layout, fieldValidations: validations || {}, requiredFields: required || [],
            conditions: mapActionsToEffects, dataAttr: dataAttributes, validationMapKeys: validationMapKeys, validationRequiredMapKeys: validationRequiredMapKeys,
            fieldsinSection: fieldsinSection, fieldLabelsinSection: fieldLabelsinSection, mapSettingsJson: mapSettingsJson, fieldKeysValues: fieldKeysValues, back: false,
            fieldsinSectionNames: fieldsinSectionNames, addData: null, prevFieldvalues: prevFieldvalues, sections: sections, triggerBRActivity: false,
            fieldMappedColName: fieldMappedColName, triggersave: false
        }
    )
    const { fieldValues, fieldProps, fieldsLayout, fieldValidations, conditions, dataAttr, back, addData, triggerBRActivity, triggersave } = fields //loadAfterPreInit

    useEffect(() => {
        let tempVal = [values]
        updateSections({
            initial: tempVal
        })
    }, [values])

    useEffect(() => {
        if (triggerBRActivity) {
            handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: { ...fieldValues, ...params },
                    conditions: (conditions.afterSave['businessrulesactivity']),
                })
            )
        }
    }, [triggerBRActivity])

    useEffect(() => {
        console.log("TriggerSave", triggersave)
        if (triggersave) {
            dispatch(onTirggerAfterSave(fieldValues))
        }
    }, [triggersave])




    useEffect(() => {
        if (resetval && resetval.name && fieldValues && Object.keys(fieldValues).indexOf(resetval.name) > -1) {
            const { name, isReset } = resetval
            if (isReset) {
                let tempConditions = _.cloneDeep(conditions)
                let values = []
                Object.keys(fieldValues).map(each => {
                    if (fieldValues[each] !== prevFieldvalues[each])
                        values.push({
                            key: each,
                            result: {
                                value: prevFieldvalues[each]
                            }
                        })
                })
                if (name && conditions && conditions.onChange) {
                    let sectionname = fieldsinSection ? fieldsinSection[currValue] : currValue;
                    let eachCondition = (conditions.onChange[sectionname] && conditions.onChange[sectionname].fields) || null;
                    let globalCondition = (conditions.onChange.GLOBAL && conditions.onChange.GLOBAL.fields) || null;

                    //each filter
                    let filterEachRules = [];
                    // eslint-disable-next-line no-unused-expressions
                    tempConditions.onChange[sectionname] && tempConditions.onChange[sectionname].rules.map(each => {
                        if (each.fields && each.fields.includes(currValue)) {
                            filterEachRules.push(each)
                        }
                    }) || [];
                    let OnCondition = tempConditions.onChange[sectionname] || {};
                    OnCondition['rules'] = filterEachRules;
                    // Global filter
                    let filterGlobalRules = [];
                    tempConditions.onChange.GLOBAL && tempConditions.onChange.GLOBAL.rules.map(each => {
                        if (each.fields && each.fields.includes(currValue)) {
                            filterGlobalRules.push(each)
                        }
                    })
                    let OnGlbalCondition = tempConditions.onChange.GLOBAL || {};
                    OnGlbalCondition['rules'] = filterGlobalRules;
                    if ((eachCondition && eachCondition.includes(currValue)) || (globalCondition && globalCondition.includes(currValue))) {
                        handleEffectUpdates(
                            handleActionsToEffects({
                                fieldValues: { ...prevFieldvalues, ...params },
                                conditions: OnCondition || [],
                                global: OnGlbalCondition,
                                EventType: 'OC'
                            }))
                    }
                }
                dispatch(setResetValue({}))
                dispatchFieldsChange({
                    type: 'updates',
                    updates: {
                        value: values,
                    }
                })
            }
        }
    }, [resetval && resetval.isReset])

    useEffect(() => {
        let reduxVals = []
        let tempFieldValues = fieldValues
        if (tempFieldValues && tempFieldValues['GetParentValues']) {
            reduxVals = parentRecordVals
            tempFieldValues = { ...tempFieldValues, ...reduxVals }
        }
        let updatedSharedValues = sharedValues.reduce((accum, each) => {
            accum[each] = tempFieldValues[each]
            return accum
        }, {})

        //const names = currentField.current
        const names = Object.keys(updatedSharedValues)
        if (counter.current > 0 && names.length > 0 && dispatchSharedValueChange) {
            dispatchSharedValueChange({
                type: 'values',
                values: names.map(each => {
                    return {
                        name: aliases[each] || each,
                        value: updatedSharedValues[each]
                    }
                })
            })
        }

        counter.current++
        // currentField.current = []
    }, [fieldValues])


    useDeepCompareEffect(() => {
        let sharedkeys = [];
        let prevStateShaedProps = prevState.current
        let tempConditions = (conditions && conditions.onChange) || {};
        if (tempConditions && (sharedProps['save'] !== prevStateShaedProps['save'])) {
            sharedkeys.push('save');
            tempConditions = conditions.afterSave;
            if (tempConditions)
                tempConditions['GLOBAL'] = conditions.afterSave.GLOBAL;
        }
        else if (sharedProps['saveduplicate'] === true) {
            sharedkeys.push('saveduplicate');
            tempConditions = conditions.onChange['saveduplicate'] || conditions.afterSave
        }
        else if (sharedProps['edit'] === true || sharedProps['createduplicate'] === true) {
            let prevStateProps = Object.keys(sharedProps).filter(each => sharedProps[each] !== prevStateShaedProps[each])
            sharedkeys = (prevStateProps)
        }

        else sharedkeys = Object.keys(sharedProps)
        if (sharedkeys && tempConditions)
            (sharedkeys || []).map(share => {
                if (tempConditions && (tempConditions[share] && (sharedProps[share] !== false)) ||
                    (sharedProps[share] === false && tempConditions['GLOBAL'])) { //we took global for executing aftersave need to verify for edit
                    let reduxVals = {}
                    if (fieldValues['GetParentValues']) {
                        reduxVals = parentRecordVals
                    }
                    let sendvalues = { ...fieldValues, ...sharedProps, ...params, ...reduxVals }
                    let proceed = true;
                    if (sharedProps[share] && (share === 'save' || share === 'saveduplicate')) {
                        //   {
                        proceed = !saveFields();
                        localStorage.setItem('SaveCurrentRecord', 'N')
                        //   }
                        if (!proceed) {
                            counter.current = 0
                            let names = share === 'saveduplicate' ? ['saveduplicate'] : ['save']
                            dispatchSharedValueChange({
                                type: 'values',
                                values: names.map(each => {
                                    return {
                                        name: each,
                                        value: false
                                    }
                                })
                            })
                            dispatchFieldsChange({
                                type: 'value',
                                name: share,
                                value: sendvalues[share],
                                dataAttr: dataAttr
                            })
                        }

                        let tempsendvalues = share === 'saveduplicate' ? { ...fieldValues, ...sharedProps, ...params, IsDuplicate: "Y" } :
                            { ...fieldValues, ...sharedProps, ...params }
                        sendvalues = tempsendvalues;
                        if (removeValues) {
                            removeValues.forEach(fields => {
                                delete sendvalues[fields]
                            })
                        }
                    }
                    if ((share === 'navigation' || share === 'subnavigation') && sharedProps.navigation) {
                        sendvalues = { ...sharedProps.navigation, ...sharedProps, ...params }
                    }
                    proceed && handleEffectUpdates(
                        handleActionsToEffects({
                            fieldValues: sendvalues,
                            conditions: (sharedProps[share] || (props.tempconditions && props.tempconditions.includes(share))) ? tempConditions[share] : [],
                            global: (!sharedProps[share] || fieldValues['RunAfterSave']) ? (tempConditions && tempConditions.GLOBAL) : [],
                            extras: { runEvents: tempConditions[share] && tempConditions[share].runEvents ? true : false, },// based on onload and onsave,
                            EventType: 'OC'
                        })
                    )
                }
            })
        prevState.current = sharedProps;
    }, [sharedProps])

    useDeepCompareEffect(() => {
        try {
            if (sharedProps && Object.keys(sharedProps).length > 0)
                dispatchFieldsChange({
                    type: 'updates',
                    updates: {
                        value: Object.keys(sharedProps).map(each => {
                            return {
                                key: each,
                                result: {
                                    value: sharedProps[each]
                                }
                            }
                        })
                    }
                })
        }
        catch (ex) {
            console.error("console error in section" + ex)
        }
    }, [sharedProps])



    useEffect(() => {
        if (addData)
            if (routeParams && routeParams.replace) {
            }
            else {
                props.handleBack(true)
            }
    }, [addData])

    useEffect(() => {
        if (back) {
            props.handleBack(true)
        }
    }, [back])

    useEffect(() => {
        if (key) {
            localStorage.setItem('TOUR_KEY', key)
        }
        if (conditions && conditions.onLoad && conditions.onLoad['init']) { // dependency and bussiness rules checking
            let reduxVals = {}
            if (fieldValues['GetParentValues']) {
                /**  GetParentValues:  should be add to jsons to data ( applicant, compnay) get from parent data */
                reduxVals = parentRecordVals
            }
            handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: { ...fieldValues, ...sharedProps, ...params, ...reduxVals },
                    conditions: (conditions.onLoad['init']),
                    extras: { ...params },
                    name: 'GLOBAL',
                    EventType: 'OL'
                })
            )
        }
    }, [])



    useEffect(() => {
        if (!initRun && conditions && conditions.onLoad) {
            let secKeys = Object.keys(conditions.onLoad)
            let reduxVals = {}
            if (fieldValues['GetParentValues']) {
                /**  GetParentValues:  should be add to jsons to data ( applicant, compnay) get from parent data */
                reduxVals = parentRecordVals
            }
            handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: { ...fieldValues, ...params, ...reduxVals },
                    conditions: {},
                    global: (conditions && conditions.onLoad.GLOBAL) || [],
                    extras: { runEvents: false, },
                    name: 'GLOBAL',
                    EventType: 'OL'
                }), true
            )

            secKeys.map(sec => {
                if (sec !== 'GLOBAL' && sec !== 'init' && !(conditions.onLoad[sec].onSectionKey))
                    handleEffectUpdates(
                        handleActionsToEffects({
                            fieldValues: { ...fieldValues, ...params, ...reduxVals },
                            conditions: conditions.onLoad[sec],
                            global: [],
                            extras: { runEvents: false, },
                            name: 'GLOBAL',
                            EventType: 'OL'
                        })
                    )
            })
        }
    }, [])

    const handleEffectUpdates = async (res) => {
        if (res)
            await Promise.all(res).then(results => {
                dispatchFieldsChange({
                    type: 'updates',
                    updates: groupByType(results)
                })
            })
    }

    const handleMapChanges = (results) => {
        let objKeys = Object.keys(results);
        let result = []
        objKeys.forEach(res => {
            let obj = {
                key: res,
                value: results[res]
            }
            result.push(obj)
        })
        dispatchFieldsChange({
            type: 'updates',
            updates: ({
                map: result

            })

        })
    }

    const handleFieldValue = (e, runBussinessRule = true) => {
        const { name, value, checked } = e.target

        const { dataset } = e && e.currentTarget ? e.currentTarget : { dataset: null };

        let tempConditions = _.cloneDeep(conditions)
        let validaterules = [];
        let errorExists = (fieldProps[name] && fieldProps[name].error) || null
        let validmapkeys = fieldValidations ? fieldValidations : null
        if (validationMapKeys && validationMapKeys[name] && fieldValidations[validationMapKeys[name]]) {
            validaterules.push(fieldValidations[validationMapKeys[name]])
        }
        else if (validmapkeys && validmapkeys[name]) {
            validaterules.push(fieldValidations[name])
        }
        const errorObj = fieldValidate(validaterules, value)
        if (errorObj) {
            if (errorObj.error)
                errors.current = true
            dispatchFieldsChange({
                type: 'prop',
                name: name,
                value: {
                    error: errorObj.error,
                    helperText: errorObj.errorText
                },
                dataAttr: dataset
            })
        }
        errors.current = false;
        let sectionname = fieldsinSection ? fieldsinSection[name] : name;
        let proceed = true;
        dispatch(setResetValue({ name: name, isReset: null }))
        if (runBussinessRule && ((conditions && conditions.onChange && conditions.onChange[sectionname] && conditions.onChange[sectionname].fields) || (conditions && conditions.onChange && conditions.onChange.GLOBAL && conditions.onChange.GLOBAL.fields))) {
            setPrevFeildValues(fieldValues);
            let eachCondition = (conditions.onChange[sectionname] && conditions.onChange[sectionname].fields) || null;
            let globalCondition = (conditions.onChange.GLOBAL && conditions.onChange.GLOBAL.fields) || null;

            //each filter
            let filterEachRules = [];
            // eslint-disable-next-line no-unused-expressions
            tempConditions.onChange[sectionname] && tempConditions.onChange[sectionname].rules.map(each => {
                if (each.fields && each.fields.includes(name)) {
                    filterEachRules.push(each)
                }
            }) || [];
            let OnCondition = tempConditions.onChange[sectionname] || {};
            OnCondition['rules'] = filterEachRules;
            // Global filter
            let filterGlobalRules = [];
            tempConditions.onChange.GLOBAL && tempConditions.onChange.GLOBAL.rules.map(each => {
                if (each.fields && each.fields.includes(name)) {
                    filterGlobalRules.push(each)
                }
            })
            let OnGlbalCondition = tempConditions.onChange.GLOBAL || {};
            OnGlbalCondition['rules'] = filterGlobalRules;
            if ((eachCondition && eachCondition.includes(name)) || (globalCondition && globalCondition.includes(name))) {
                const val = handleValue(name, value, checked);
                let reduxVals = {}
                if (fieldValues['GetParentValues']) {
                    /**  GetParentValues:  should be add to jsons to data ( applicant, compnay) get from parent data */
                    reduxVals = parentRecordVals
                }
                if (name === 'save') {
                    proceed = !saveFields();
                    localStorage.setItem('SaveCurrentRecord', 'N')
                }
                proceed && handleEffectUpdates(
                    handleActionsToEffects({
                        fieldValues: { ...fieldValues, [name]: val, ...params, ...reduxVals },
                        conditions: OnCondition || [],
                        global: OnGlbalCondition,
                        extras: { [name]: dataset, dataAttr: { ...dataAttr, [name]: dataset }, runEvents: true, },
                        onEvent: name !== 'save' ? (conditions.onEvent && conditions.onEvent.GLOBAL) : {},
                        info: conditions.info ? conditions.info : null,
                        name: name === 'save' || name === 'edit' ? null : name,
                        EventType: 'OC'
                    })
                )
                dispatchFieldsChange({
                    type: 'value',
                    name: name,
                    value: val,
                    dataAttr: dataset
                })
                if (!errorObj || !errorObj.error) {
                    dispatchFieldsChange({
                        type: 'prop',
                        name: name,
                        value: {
                            error: errorExists ? false : fieldProps[name].error,
                            helperText: errorExists ? null : fieldProps[name].errorText,
                        },
                        dataAttr: dataset,
                    })
                }

                dispatchSharedValueChange && sharedProps[name] && dispatchSharedValueChange({
                    type: 'update',
                    name: sharedProps[name],
                    value: val
                })

            }
            else if (errorObj && errorObj.error) {
            }
            else {
                const val = handleValue(name, value, checked)
                dispatchFieldsChange({
                    type: 'value',
                    name: name,
                    value: val,
                    dataAttr: dataset
                })
                dispatchFieldsChange({
                    type: 'prop',
                    name: name,
                    value: {
                        error: errorExists ? false : fieldProps[name].error,
                        helperText: errorExists ? null : fieldProps[name].errorText,
                    },
                    dataAttr: dataset
                })


                dispatchSharedValueChange && sharedProps[name] && dispatchSharedValueChange({
                    type: 'update',
                    name: sharedProps[name],
                    value: val,
                    dataAttr: dataset
                })
            }
        }
        else {
            const val = handleValue(name, value, checked)
            dispatchFieldsChange({
                type: 'value',
                name: name,
                value: val,
                dataAttr: dataset
            })
            if (!errorObj)
                dispatchFieldsChange({
                    type: 'prop',
                    name: name,
                    value: {
                        error: errorExists ? false : fieldProps[name].error,
                        helperText: errorExists ? null : fieldProps[name].errorText,
                    },
                    dataAttr: dataset
                })

            dispatchSharedValueChange && sharedProps[name] && dispatchSharedValueChange({
                type: 'update',
                name: sharedProps[name],
                value: val
            })
        }
    }



    // change the handlefieldvalue code
    const handleValue = (name, value, checked) => {
        const { changefieldto, type, custom } = components[name]
        let changeType = changefieldto || type;
        let val = value
        switch (changeType) {
            case 'CF':
            case 'CSC':
            case 'checkbox': {
                return checkBoxTransformProps[checked.toString()]
            }
            default:
                return value;
        }
    }

    const saveFields = () => {
        let errorlist = {};
        let saveStatus = false
        if (errors.current)
            console.log('Please Fix errors')
        else {
            let fields = Object.keys(fieldProps)
            const errorFields = fields.filter(each => {
                if (fieldProps[each].required) {
                    if (fieldValues[each] && fieldValues[each] !== '') {
                        if (fieldProps[each].error) {
                            saveStatus = true
                            return true
                        }
                        else {
                            return false
                        }
                    }
                    else {
                        saveStatus = true
                        return true
                    }
                }
                if (fieldProps[each].error) {
                    saveStatus = true
                    return true
                }
                else return false
            })
            errorFields.map(each => {
                if (!(fieldProps && fieldProps[each] && fieldProps[each].error) && fieldProps[each].required) {
                    return errorlist[each] = {
                        error: true,
                        helperText: `${fieldLabelsinSection ? fieldLabelsinSection[each] : fieldProps[each].label ? fieldProps[each].label : each} is Required`,
                    }
                }
                else {
                    return errorlist[each] = {
                        error: true,
                        helperText: fieldProps[each].helperText ? fieldProps[each].helperText : '',
                    }
                }

            })
            dispatchFieldsChange({
                type: 'required',
                fields: errorlist
            })
        }
        return saveStatus
    }


    //custom components (editor/ multiselect)
    const updateSections = (res, triggersave = false) => {
        dispatchFieldsChange({
            type: 'updates',
            updates: res,
            triggersave: triggersave
        })
    }



    function setCustomFields(customprops) {
        dispatchFieldsChange({
            type: 'customfields',
            ...customprops
        })
    }



    const handleButtonClick = (e, callRequest) => {
        const { name } = e.currentTarget
        let proceed = true;
        let sectionname = fieldsinSection ? fieldsinSection[name] : name;
        if ((conditions && conditions.onChange && conditions.onChange[name]) || (conditions && conditions.onChange && conditions.onChange[sectionname] && conditions.onChange[sectionname].fields)) {
            counter.current++
            if (name === 'save' || name === 'IsLocked' || callRequest) {
                proceed = !saveFields();
                localStorage.setItem('SaveCurrentRecord', 'N')
            }
            proceed && handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: { ...fieldValues, ...sharedProps, ...params, IsDuplicate: name === 'saveduplicate' ? 'Y' : 'N' },
                    conditions: (conditions.onChange[name] || conditions.onChange[sectionname]) || [],
                    extras: { runEvents: true, },
                    onEvent: name !== 'save' ? (conditions.onEvent && conditions.onEvent[name]) : {},
                    info: conditions.info ? conditions.info : null,
                    EventType: 'OC'
                })
            )
        }
    }

    const handleButtonSave = (e) => {
        const { name } = e.currentTarget
        let proceed = true;
        let tempConditions = conditions.afterSave
        let sendvalues = { ...fieldValues, ...sharedProps, ...params }
        proceed = !saveFields();
        if (!proceed) {
            dispatchFieldsChange({
                type: 'value',
                name: name,
                value: sendvalues[name],
                dataAttr: dataAttr
            })
        }
        let tempsendvalues = { ...fieldValues, ...sharedProps, ...params }
        sendvalues = tempsendvalues;
        if (removeValues && removeValues[name]) {
            removeValues[name].forEach(fields => {
                delete sendvalues[fields]
            })
        }


        proceed && handleEffectUpdates(
            handleActionsToEffects({
                fieldValues: sendvalues,
                conditions: tempConditions[name],
                global: [], //(tempConditions && tempConditions.GLOBAL) ||
                extras: { runEvents: tempConditions[name].runEvents ? true : false, },// based on onload and onsave,
                EventType: 'AS'
            })
        )

    }

    const handlerowClick = (e, rowData) => {
        const { name } = e.target
        if (conditions && conditions.onChange && conditions.onChange[name]) {
            counter.current++
            handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: { ...fieldValues, ...sharedProps, [displayName]: rowData, ...params },
                    conditions: conditions.onChange[name] || [],
                    EventType: 'OC'

                })
            )
        }
    }

    const onConfirmationSubmit = () => {
        let sendvalues = { ...fieldValues, ...sharedProps, ...params };
        if (removeValues) {
            removeValues.forEach(fields => {
                delete sendvalues[fields]
            })
        }
        if (conditions && conditions.onChange && conditions.onChange['confirmation']) {
            handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: sendvalues,
                    conditions: conditions.onChange['confirmation'] || [],
                    extras: { runEvents: conditions.onChange['confirmation'].runEvents ? true : false, },
                    EventType: 'OC'
                })
            )
        }
    }

    const onConfirmationClose = () => {
        dispatchFieldsChange({
            type: 'value',
            name: 'confirmation',
            value: false,
        })
        dispatchFieldsChange({
            type: 'value',
            name: 'save',
            value: false,
        })
        let sendvalues = { ...fieldValues, ...sharedProps, ...params };
        if (removeValues) {
            removeValues.forEach(fields => {
                delete sendvalues[fields]
            })
        }
        sendvalues['confirmation'] = false
        if (conditions && conditions.onChange && conditions.onChange['confirmation']) {
            handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: sendvalues,
                    conditions: conditions.onChange['confirmation'] || [],
                    extras: { runEvents: conditions.onChange['confirmation'].runEvents ? true : false, },
                    EventType: 'OC'
                })
            )
        }
    }


    // need to remove the code here and extend the functionality function
    useEffect(() => {
        let oldconditionsarr = [];
        let newconditionarr = [];
        if (mapActionsToEffects && conditions) {
            for (let key in mapActionsToEffects.onLoad) {
                oldconditionsarr.push(key);
            };
            for (let key in conditions.onLoad) {
                newconditionarr.push(key);
            };
            let oldvalues = newconditionarr.filter(x => !oldconditionsarr.includes(x));
            // if (oldvalues.length === 0)
            //     oldvalues = newconditionarr// removed the code because every request is been called multiple times
            let tempFieldValues = fieldValues
            if (tempFieldValues && tempFieldValues['GetParentValues']) {
                let reduxVals = parentRecordVals
                tempFieldValues = { ...tempFieldValues, ...reduxVals }
            }
            for (let i = 0; i < oldvalues.length; i++) {
                let onloadname = oldvalues[i]
                if (conditions && conditions.onLoad && conditions.onLoad[onloadname]) { // dependency and bussiness rules checking
                    handleEffectUpdates(
                        handleActionsToEffects({
                            fieldValues: { ...tempFieldValues, ...sharedProps, ...params },
                            conditions: (conditions.onLoad[onloadname]),
                            global: conditions.onLoad.GLOBAL,
                            onEvent: (conditions.onEvent && conditions.onEvent['GLOBAL']) ? conditions.onEvent['GLOBAL'] : {},
                            name: 'GLOBAL',
                            EventType: 'OL'
                        })
                    )
                }
            }
        }
        // counterval = 1;
    }, [(conditions && conditions.onLoad)])

    //important ex:
    const componentsCreation = (fieldNames, values) => {
        if (fieldProps)
            try {
                return fieldNames.reduce((accum, each) => {
                    const { type, custom, ...otherProps } = fieldProps[each]
                    //  console.log(type, fieldProps[each])
                    const EachComp = custom ? (customTypes[type] || hookTypes[type]) : (fieldTypes[type] || hookTypes[type])
                    if (type === 'Map')
                        accum[each] = <EachComp  {...otherProps} {...mapSettingsJson} value={values[each]} handleMapChanges={handleMapChanges}
                            handlerowClick={handlerowClick} params={params} accessPermissions={props.accessPermissions} fieldValues={values} />
                    else accum[each] = <EachComp sharedProps={sharedProps} {...otherProps} value={values[each]} handleFieldValue={handleFieldValue} onClose={onConfirmationClose}
                        onSubmit={onConfirmationSubmit} handleButtonClick={handleButtonClick} handleButtonSave={handleButtonSave} handlerowClick={handlerowClick}
                        handleEffectUpdates={handleEffectUpdates} accessPermissions={props.accessPermissions} fieldValues={values} fieldProps={fieldProps} />
                    return accum
                }, {})
            } catch (ex) {
                console.error("exception " + ex)
            }
    }

    const openedSectionsRules = (sections, prevSections) => {
        let secKeys = (conditions && conditions.onLoad && Object.keys(conditions.onLoad)) || []
        if (secKeys.length > 0)
            (secKeys || []).map(sec => {
                if (sec !== 'GLOBAL' && (prevSections[sec] != sections[sec]) && conditions.onLoad[sec].onSectionKey)
                    handleEffectUpdates(
                        handleActionsToEffects({
                            fieldValues: { ...fieldValues, ...params },
                            conditions: conditions.onLoad[sec],
                            global: [],
                            onEvent: (conditions.onEvent && conditions.onEvent['GLOBAL']) ? conditions.onEvent['GLOBAL'] : {},
                            extras: { runEvents: false, },
                            EventType: 'OL'
                        })
                    )
            })
    }

    //important
    const sectionsCreation = (sections) => {
        const { type, sectionNames, ...otherProps } = sections
        return {
            ...otherProps,
            sections: sectionNames.map(each => {
                const { group, ...otherNames } = layout[each]
                return layoutType && layoutTypes[layoutType] ?
                    layoutTypes[layoutType]({
                        layout: fieldsLayout[each],
                        fields: componentsCreation(group.reduce((accum, each) => {
                            return accum.concat(
                                each.components.map(comp => {
                                    return comp.name
                                })
                            )
                        }, []), fieldValues)
                    }) :
                    layoutTypes.default({
                        layout: fieldsLayout[each],
                        fields: componentsCreation(group.reduce((accum, each) => {
                            return accum.concat(
                                each.components.map(comp => {
                                    return comp.name
                                })
                            )
                        }, []), fieldValues)
                    })
            })
        }
    }

    if (fieldsinSectionNames) {
        let hiddenfieldssection = {}
        let allfieldssections = Object.values(fieldsinSectionNames)
        const fieldssectioncount = _.values(_.groupBy(allfieldssections)).map(d => ({ [d[0]]: d.length }));
        Object.keys(fieldProps).map(field => {

            if (!fieldProps[field].isVisible && _.isBoolean(fieldProps[field].isVisible)) {
                if (hiddenfieldssection[fieldsinSectionNames[field]]) {
                    hiddenfieldssection[fieldsinSectionNames[field]] = hiddenfieldssection[fieldsinSectionNames[field]] + 1
                }
                else {
                    hiddenfieldssection[fieldsinSectionNames[field]] = 1
                }

            }
            // new error
            //exisiting section
            if (!errorSections[fieldsinSectionNames[field]] || errorSections[fieldsinSectionNames[field]] === false || errorSections[fieldsinSectionNames[field]] !== true) {
                errorSections[fieldsinSectionNames[field]] = fieldProps[field].error ? true : false;
            }
        })
        for (const key in hiddenfieldssection) {
            if (_.find(fieldssectioncount, { [key]: hiddenfieldssection[key] })) {
                hideSections.push(key);
            }

        }

    }

    return [
        <Fragment>
            {sectionType && layoutTypes[sectionType] ?
                layoutTypes[sectionType]({
                    ...sectionsCreation({ ...sections, openedSectionsRules, errorSections, hideSections })
                }) :
                layoutTypes.default({
                    layout: fieldsLayout,
                    fields: componentsCreation(fieldProps, fieldValues)
                })
            }
        </Fragment>,
        fieldValues,
        fieldProps,
        fieldKeysValues,
        updateSections,
        handleFieldValue,
        setCustomFields,
        saveFields,
        conditions,
        fieldMappedColName
    ]
}

