/**
      *********************************************************
      Description:Simple grid to display columns from individual components without json format
      Required props:  type.
      limitations    : just displays the grid data.
      *********************************************************
**/

import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import * as React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { withRouter } from 'react-router-dom';
import { CustomColumnMenu } from '../Grid/customColumnMenu.jsx';
import { getData } from '../rest';

class SimpleGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataState: { skip: 0, take: 20 },
            data: [],
            columns: this.props.gridFields || []
        };
    }


    componentDidMount() {
        if (this.props.rules)
            this.setState({ data: this.props.data })
        else this.getResults();
    }

    getResults = () => {
        getData(this.props.url).then(res => {
            this.setState({ data: res })
        }).catch(ex => {
            console.log(ex)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.rules && (prevProps.url !== this.props.url)) {
            this.getResults();
        }
    }

    handleDataStateChange = (changeEvent) => {
        this.setState({
            dataState: changeEvent.dataState,
            data: process(this.props.data, changeEvent.dataState),
        });
    }

    onColumnsSubmit = (columnsState) => {
        this.setState({
            columns: columnsState,
            data: this.state.data
        });
    }
    /**
         *********************************************************
         Description: renders the grid component.
         Required props:  options, components. Within options gridcolumns are needed to create columns.
         limitations    : N/A
         *********************************************************
   **/
    render() {
        const { data, columns } = this.state;
        return (
            <div className="p-1">
                <h6 className="font-weight-light text-primary">{this.props.title}</h6>
                <ReactResizeDetector handleWidth handleHeight>
                    {({ width, height }) =>
                        <Grid
                            sortable={true}
                            pageable={true}
                            total={this.state.total}
                            data={data || []}
                            {...this.state.dataState}
                            onDataStateChange={this.handleDataStateChange}
                            resizable
                            style={{ height: this.props.rules ? window.innerHeight - 110 : '350px', width: 'w-90' }}
                        >
                            {columns && columns.length > 0 && columns.map((each, index) => {
                                return <GridColumn field={each.field} title={each.field} columnMenu={
                                    props =>
                                        <CustomColumnMenu
                                            {...props}
                                            columns={columns}
                                            onColumnsSubmit={this.onColumnsSubmit}
                                        />
                                } />
                            })}
                            {
                                !data || data.length === 0 && <div>No records found</div>
                            }
                        </Grid>}
                </ReactResizeDetector>
            </div>

        );
    }
}

export default withRouter(SimpleGrid);

