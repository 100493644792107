import React, { useState, useCallback } from 'react'
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Header from '../../../../controls/components/header';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Editor from './editor';
import Checkbox from '@material-ui/core/Checkbox';
import MultiCheckAutoComplete from '../../../../controls/components/checkautocomplete';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import Dropdown from '../../../../controls/components/dropdown';
import RadioButtonsGroup from '../../../../controls/components/radiogroup';

const defaultDrawerWidth = 900;
const minDrawerWidth = 700;
const maxDrawerWidth = window.innerWidth - 220;
const fullscreenwidth = window.innerWidth
const checkwidth = 960

const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 0
    },
    dragger: {
        width: "7px",
        cursor: "ew-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 100,
        //   backgroundColor: "#f4f7f9"
    }
}));

const extraProps = {
    transformProps: {
        label: 'name',
        value: 'value'
    },
}

const header = {
    name: "header",
    type: 'header',
    title: 'Field Settings',
    components: [
        {
            type: 'breadcrumbs',
            items: [
                { "link": "Field Settings", "materialicon": "dns", className: 'eps-breadcrumb-item ', class: 'text-light' },
            ]
        }
    ],
    actions: [
        {
            type: 'icon',
            icon: 'back',
            name: 'back',
            hideon: 'back',
            hidevalue: false
        },
    ],
}



const FieldSettings = (props) => {
    let screensize = (props.width === "sm" || props.width === "xs" || props.width === "md") ? false : true
    const [drawerWidth, setDrawerWidth] = useState((props.width === "sm" || props.width === "xs" || props.width === "md") ? fullscreenwidth : defaultDrawerWidth);
    const [fullscreen, setFullScreen] = useState(false)
    const [fullwidth, setFullWidth] = useState(fullscreenwidth)

    React.useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    });


    const updateWidth = () => {
        if (window.innerWidth < checkwidth) {
            setDrawerWidth(window.innerWidth);
        }
        else {
            setDrawerWidth(defaultDrawerWidth)
        }
    };

    React.useEffect(() => {
        if (!screensize) {
            let width = window.innerWidth
            setDrawerWidth(width);
            setFullWidth(width)
        }
        else {
            setDrawerWidth(defaultDrawerWidth);
            // setFullWidth(defaultDrawerWidth)
        }
    }, [props.width]);


    const handleMouseDown = e => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let newWidth =
            document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        //  const newWidth = e.clientX - document.body.offsetLeft;
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            setDrawerWidth(newWidth);
        }
    }, []);

    const handleBack = () => {
        props.closeFieldSettings()
    }

    const addOnsByName = { addOnsByName: { back: { onClick: handleBack } } }
    const handleCheckChange = (name, event) => {
        const { validresultset, fields } = props
        props.fieldSettingsValue({ target: { value: event.target.checked ? 'Y' : 'N', name: name, checked: false }, currentTarget: { dataset: null } })
    };

    const handleCheckGroupChange = (name, event, value) => {
        props.fieldSettingsValue({ target: { value: value, name: name, checked: false }, currentTarget: { dataset: null } })
    };

    const handleDateChange = (date) => {
        const { validresultset, fields } = props
        props.fieldSettingsValue({ target: { value: moment(date).format('MM/DD/YYYY'), name: 'Value', checked: false }, currentTarget: { dataset: null } })
    }

    const handleCurrentDateChange = (e) => {
        const { value, name, checked } = e.target
        props.fieldSettingsValue({ target: { value: checked === true ? JSON.stringify({ 'CurrentDefaultDate': 'Y' }) : null, name: 'AllowedValues', checked: false }, currentTarget: { dataset: null } })
    }

    const classes = useStyles();
    return (
        <Drawer anchor={'right'} open={props.fieldopen} onClose={() => props.closeFieldSettings()}
            PaperProps={{ style: { width: fullscreen ? fullwidth : drawerWidth } }}
        >
            <div onMouseDown={e => handleMouseDown(e)} className={classes.dragger} />
            <Header {...header} {...addOnsByName} />
            <div className="row m-2">
                {(props.type === 'select' || props.type === 'radio' || props.type === 'checkboxgroup') && <FormControl fullWidth={true}>
                    <MultiCheckAutoComplete extraProps={extraProps} ismulti={true} className="col-sm-12" label={'Filter Lookup'} name=""
                        value={(props && props['AllowedValues']) || ''} items={props.values || []}
                        handleFieldValue={(evt) => props.fieldSettingsValue({ target: { value: evt.target.value, name: 'AllowedValues', checked: false }, currentTarget: { dataset: null } })} />
                </FormControl>}
                <FormControl fullWidth={true}>
                    <RadioButtonsGroup label="" value={props.ValueType || 'D'} name="ValueType" items={[
                        { Name: 'Calculated', Value: 'C' },
                        { Name: 'Default', Value: 'D' },
                        { Name: 'Reset', Value: 'R' },
                        { Name: 'None', Value: 'Z' }
                    ]} extraProps={{
                        transformProps: {
                            label: 'Name',
                            value: 'Value'
                        }
                    }} handleFieldValue={props.fieldSettingsValue} />
                    {/* // <FormGroup row> */}
                    {/* <FormControlLabel
                            control={<Switch checked={props.ValueType === 'D'} name="ValueType" />}
                            label="Default Value"
                            onChange={(event) => props.fieldSettingsValue({
                                target: { value: event.target.checked ? 'D' : 'N', name: 'ValueType', checked: false },
                                currentTarget: { dataset: null }
                            })}
                        /> */}
                    {/* <FormControlLabel
                            control={<Switch checked={props.ValueType === 'C'} name="ValueType" />}
                            label="Calculated Value"
                            onChange={(event) => props.fieldSettingsValue({
                                target: { value: event.target.checked ? 'C' : 'N', name: 'ValueType', checked: false },
                                currentTarget: { dataset: null }
                            })}
                        /> */}
                    {/* <FormControlLabel
                            control={<Switch checked={props.ValueType === 'R'} name="ValueType" />}
                            label="Reset Value"
                            onChange={(event) => props.fieldSettingsValue({
                                target: { value: event.target.checked ? 'C' : 'N', name: 'ValueType', checked: false },
                                currentTarget: { dataset: null }
                            })}
                        /> */}
                    {/* </FormGroup> */}
                </FormControl>
                {props.ValueType === 'D' && <CustomComponents defaultValue={props.Value} handleChange={(e) => { props.fieldSettingsValue({ target: { value: e.target.value, name: 'Value', checked: false }, currentTarget: { dataset: null } }) }}
                    handleCheckChange={(e) => handleCheckChange('Value', e)} AllowedValues={props['AllowedValues']}
                    handleCheckGroupChange={(e, value) => handleCheckGroupChange('Value', e, value)} handleDateChange={handleDateChange}
                    handleCurrentDateChange={handleCurrentDateChange} type={props.type} values={props.values} />
                }
                {props.ValueType === 'C' && <Editor items={[7, 8, 9, '+', '>', 4, 5, 6, '-', '<', 1, 2, 3, '/', '=', 0, '.', '[', ']', '*', '(', ')', '%', 'del', 'Insert']}
                    rows={6} options={props.allfields.filter(fld => fld.type === 'number' || fld.name === 'PREVIOUS_STATUS')} Value={props.Value} changeValue={(value) => { props.fieldSettingsValue({ target: { value: value, name: 'Value', checked: false }, currentTarget: { dataset: null } }) }} />}
            </div>
        </Drawer>
    )
}

export default withWidth()(FieldSettings)


const CustomComponents = (props) => {
    const { defaultValue, AllowedValues, type, values, handleChange, handleCheckChange, handleCheckGroupChange, handleDateChange, handleCurrentDateChange } = props
    switch (type) {
        case 'text':
            return <TextField className="col-sm-12" type={type} value={defaultValue} onChange={(e) => props.handleChange(e)} />
        case 'radio':
            return <div className="col-sm-12">
                {values && values.length > 0 && values.map(vals => {
                    return <RadioGroup className="col-sm-12" value={defaultValue} onChange={props.handleChange} >
                        <FormControlLabel className="col-sm-6" value={vals.value} control={<Radio />} label={vals.name} />
                    </RadioGroup>
                })}
                {values && values.length === 0 && <RadioGroup className="col-sm-12" value={defaultValue} onChange={(e) => handleChange(e)} >
                    <FormControlLabel labelPlacement="end" value={'Y'} control={<Radio />} className="col-sm-6" label={'Yes'} />
                    <FormControlLabel labelPlacement="end" value={'N'} control={<Radio />} className="col-sm-6" label={'No'} />
                </RadioGroup>
                }
            </div>
        case 'select':
            return <div className="col-sm-12">
                <Select className="col-sm-12" value={defaultValue} onChange={(e) => handleChange(e)}     >
                    <MenuItem value={""}>{''}</MenuItem>
                    {values && values.length > 0 && values.map(vals => {
                        return <MenuItem value={vals.value}>{vals.name}</MenuItem>
                    })}

                </Select>
            </div>
        case 'checkbox':
            return <div className="col-sm-12">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={(defaultValue === 'Y' || defaultValue === true) ? true : false}
                            onChange={(e) => handleCheckChange(e)}
                            color="primary"
                            className="col-sm-12"

                        />
                    }
                />
            </div>
        case 'checkboxgroup':
            return <div className="col-sm-12">
                <FormGroup>
                    {values && values.length > 0 && values.map(vals => {
                        return <FormControlLabel
                            control={<Checkbox checked={vals.value === defaultValue} onChange={(e) => handleCheckGroupChange(e, vals.value)} name={vals.name} />}
                            label={vals.name}
                        />
                    })}
                </FormGroup>
            </div>
        case 'date':
            return <div className="col-sm-12">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar

                        variant="inline"
                        format="MM/DD/YYYY"
                        margin="normal"
                        value={defaultValue === '' ? null : defaultValue}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <br />
                <FormControlLabel
                    control={
                        <Checkbox
                            name={'AllowedValues'}
                            checked={AllowedValues ? true : false}
                            onChange={(e) => handleCurrentDateChange(e)}
                            color="primary"
                        />
                    }
                    label={'Set Current Date'}
                />
            </div>
        default:
            return <TextField className="col-sm-12" type={type || 'text'} value={defaultValue} onChange={(e) => props.handleChange(e)} />
    }
}