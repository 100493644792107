import React, { Fragment } from "react";
import Draft, { SelectionState } from "draft-js";
import createHighlightPlugin from 'draft-js-highlight-plugin';
import "./crichtexteditor.css";
import FormHeader from './cformheader';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Typography } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PrintIcon from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton';

const highlightPlugin = createHighlightPlugin({
  background: 'purple',
  color: 'yellow',
  border: '1px solid black',
});

const { Editor, EditorState, RichUtils, getDefaultKeyBinding, convertToRaw, convertFromRaw, convertFromHTML, ContentState, Modifier } = Draft;


class CRichTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayhelpertextdata: null,
      helpertextdata: false,
      value: //.props.value ?
        // EditorState.createWithContent(this.props.value) :
        EditorState.createEmpty()
    };
    this.focus = () => this.refs.editor.focus();
  }

  handleKeyCommand = (command, value) => {
    const newState = RichUtils.handleKeyCommand(value, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        this.state.value,
        4 /* maxDepth */
      );
      if (newEditorState !== this.state.value) {
        this.onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }

  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.value, blockType));
  }

  toggleInlineStyle = (inlineStyle) => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.value, inlineStyle)
    );
  }

  onChange = (value) => {
    this.setState({ value })
  }

  // onChange = (value) => {
  //   const currentContent = value.getCurrentContent();
  //   const currentContentLength = currentContent.getPlainText('').length
  //   if (currentContentLength > 4000) {
  //     console.log(currentContentLength)
  //   }
  //   else {
  //     this.setState({ value }, () => {
  //       if (!this.props.form) {
  //         this.handleFieldChange();
  //       }
  //     })
  //   }
  // }

  handleFieldChange = () => {
    if (!this.props.form) {
      let finalval = '';
      let val = ''
      const { handleFieldChange, name, id, edit, options, ...otherProps } = this.props;
      if (this.state.value !== '') {
        val = draftToHtml(convertToRaw(this.state.value.getCurrentContent()));
        console.log(val)
        finalval = btoa(unescape(encodeURIComponent(val)))
      }
      let events = { target: { value: finalval, name: name, checked: false }, currentTarget: {} }

      if (this.props && this.props.options && this.props.options.checkvalidation) {
        // var regex = '/(<([^>]+)>)/'
        var hasText = val.replace(/<\s*br[^>]?>/, '\n').replace(/(<([^>]+)>)/g, "").replace(/(&nbsp;)/g, "").replace(/(\n)/g, "").length;
        // manipulate val
        events = hasText == 0 ? { target: { value: null, name: name, checked: false }, currentTarget: {} } : { target: { value: finalval, name: name, checked: false }, currentTarget: {} }
      }
      this.props.handleFieldChange(events);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;
    if (prevProps.value !== this.props.value) {
      if (value) {
        let tempval = value;
        // if (value.startsWith("PHA+") || value.startsWith("data:")||value.startsWith("PGgx")) {
        tempval = escape(window.atob(value))
        // }
        let val = htmlToDraft(decodeURIComponent(tempval));
        //let val = htmlToDraft(decodeURIComponent(escape(window.atob(value))));
        let state = ContentState.createFromBlockArray(val.contentBlocks,
          value.entityMap);
        const newState = EditorState.createWithContent(state)
        this.setState({ value: EditorState.moveFocusToEnd(newState) })
      }
      else this.setState({ value: EditorState.createEmpty() })
    }
  }


  componentDidMount() {
    const { value } = this.props;
    if (value) {
      let val = htmlToDraft(decodeURIComponent(escape(window.atob(value))));
      let state = ContentState.createFromBlockArray(val.contentBlocks,
        value.entityMap);
      const newState = EditorState.createWithContent(state)
      this.setState({ value: EditorState.moveFocusToEnd(newState) })
    }
    else this.setState({ value: EditorState.createEmpty() })
  }

  getResult = () => {
    const { value } = this.state;
    if (value.getCurrentContent().getPlainText().trim().length > 0) {
      let finalval = draftToHtml(convertToRaw(value.getCurrentContent()))
      return btoa(unescape(encodeURIComponent(finalval)));
    }
    else {
      return false;
    }
  }

  clearResult() {
    let value = this.state.value;
    let contentState = value.getCurrentContent();
    const firstBlock = contentState.getFirstBlock();
    const lastBlock = contentState.getLastBlock();
    const allSelected = new SelectionState({
      anchorKey: firstBlock.getKey(),
      anchorOffset: 0,
      focusKey: lastBlock.getKey(),
      focusOffset: lastBlock.getLength(),
      hasFocus: true
    })
    contentState = Modifier.removeRange(contentState, allSelected, 'backward');
    value = EditorState.push(value, contentState, 'remove-range');
    this.setState({ value });
  }

  handleParseData = () => {
    const { value } = this.state;
    let finalval = draftToHtml(convertToRaw(value.getCurrentContent()))
    return <div>{this.htmlEntities(finalval)}</div>
  }

  htmlEntities = (str) => {
    return <div dangerouslySetInnerHTML={{ __html: str }} />
  }

  printDiv = () => {
    let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
    // for title --mywindow.document.write(`<html><head><title>${title}</title>`);
    //mywindow.document.write(`<html><head><title>${title}</title>`);
    let val = draftToHtml(convertToRaw(this.state.value.getCurrentContent()));
    mywindow.document.write(`<html><head><title></title>`);
    mywindow.document.write('</head><body >');
    mywindow.document.write(val);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    // mywindow.close();
    //  return true;
  }
  render() {
    const { value } = this.state;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = "RichEditor-editor";
    var contentState = value.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== "unstyled"
      ) {
        className += " RichEditor-hidePlaceholder";
      }
    }//
    const { handleFieldChange, name, label, id, edit, options, tooltip, required, error, helperText, ...otherProps } = this.props;
    if (edit == false) {
      let result = this.handleParseData()
      return (
        <div >
          {tooltip && <Fragment><Typography variant={'caption'} className="fw-bold">{label}< Tooltip title={tooltip ? tooltip : null} arrow placement="bottom-start" ><HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
          </Tooltip></Typography> &nbsp;<Typography variant={'inherit'} className="eps-content-wrap text-secondary" color={'textPrimary'}>{result}</Typography></Fragment>}
          {!tooltip && <Fragment><Typography variant={'caption'} className="fw-bold">{label}</Typography> &nbsp;<Typography className="eps-content-wrap text-secondary" variant={'inherit'} color={'textPrimary'}>{result}</Typography></Fragment>}
        </div>
      )
    }
    else return (
      <Fragment>
        {name && tooltip && <div className="text-secondary pt-1 pb-1" >{label}< Tooltip title={tooltip ? tooltip : null} arrow placement="bottom-start" ><HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
        </Tooltip></div>}
        {name && !tooltip && <div className="text-secondary pt-1 pb-1" ><InputLabel shrink={true} required={required} error={error}>{label}</InputLabel></div>}
        <div className="RichEditor-root text-secondary">
          <BlockStyleControls
            editorState={value}
            onToggle={this.toggleBlockType}
          />
          <InlineStyleControls
            editorState={value}
            onToggle={this.toggleInlineStyle}
          />
          {this.props.readonlyandprint && <IconButton className="float-right" color="inherit" onClick={this.printDiv} aria-label="close">
            <PrintIcon />
          </IconButton>}
          <div className={className} onClick={this.focus}>
            <Editor
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={value}
              handleKeyCommand={this.handleKeyCommand}
              keyBindingFn={this.mapKeyToEditorCommand}
              onChange={this.onChange}
              onBlur={()=>this.handleFieldChange()}
              ref="editor"
              spellCheck={true}
              plugins={[highlightPlugin]}
              readOnly={this.props.readonlyandprint ? true : false}
            />
          </div>
        </div>
        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
        {this.state.helpertextdata && <FormHelperText error={true}>{this.state.displayhelpertextdata}</FormHelperText>}
      </Fragment>
    );
  }
}



export default CRichTextEditor;
// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}
class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}
const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  { label: "Blockquote", style: "blockquote" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" },
  { label: "Code Block", style: "code-block" }
];
const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
var INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" },
  { label: "Monospace", style: "CODE" }
];
const InlineStyleControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};


