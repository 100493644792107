import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { loadModules } from 'esri-loader';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { Paper } from '@material-ui/core';
import BookmarkControl from './bookmarkcontrol'
class SearchBookmark extends Component {
    constructor(props) {
        super(props); 
        this.bcrdom = React.createRef();
        this.state =  { 
            view: this.props.view,
            options:this.props.options
        } 

        this.startup(
            this.props
        ); 
    }  

    
    startup = (config) => {

        loadModules([
            "esri/widgets/Expand",
            "esri/core/watchUtils"
        ]).then(([Expand,watchUtils]) => {
            this.sbcontainer = ReactDOM.findDOMNode(this);
            const basemapExpand = new Expand({
                collapseTooltip: "Collapse Search & Bookmark",
                expandTooltip: "Expand Search & Bookmark",
                view: config.view,
                content: this.bcrdom.current.node,
                expandIconClass: 'esri-icon-bookmark'
            }); 
            config.view.ui.add(basemapExpand, "top-right");
            this.props.widgetRenderCallBack("SearchBookmark");
            const inThis = this;
            watchUtils.pausable(basemapExpand, "expanded", function (newValue, oldValue) {  
                inThis.props.onexpanded(basemapExpand, newValue);
				if(!newValue)inThis.bcrdom.current.addtoDrawLayer(null)
            });
        });
    }


    render() {
        return (
           <BookmarkControl options={this.state.options.searchbookmarks} 
           view={this.state.view}  options={this.state.options} ref={this.bcrdom} style={{order:this.props.order}}></BookmarkControl>
        );
      }
}
export default SearchBookmark