import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getEnvVariable } from '../..//modules/environmentalList';
import ConfirmationDialog from "../../core/controls/components/confirmationPopup";
import DropDown from '../../core/controls/components/dropdown';
import { DeleteRecord, PostData, PutData } from '../../core/controls/helpers/requests';
import { createRequest, requestApi } from '../../core/controls/helpers/rest';
import FormHeader from '../../core/modules/form/cformheader';

const defaultDrawerWidth = 900;
const minDrawerWidth = 700;
const maxDrawerWidth = window.innerWidth - 220;
const fullscreenwidth = window.innerWidth
const checkwidth = 960

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0
  },
  dragger: {
    width: "7px",
    cursor: "ew-resize",
    padding: "4px 0 0",
    borderTop: "1px solid #ddd",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 100,
    //   backgroundColor: "#f4f7f9"
  }
}));

export default function ConfigType(props) {
  const [configtypes, setconfigtype] = useState([])
  const [MasterPermitTypes, setMasterPermitTypes] = useState([])
  const [sitepermittypedata, setsitepermittypedata] = useState([])
  const [MasterPermitTypeId, setMasterPermitTypeId] = useState([])
  const [data, setData] = useState([])
  const [add, setAdd] = useState(false)
  const [result, setResult] = useState({})
  const [reload, setReload] = useState(false)
  const [menuDisabled, setMenuDisabled] = useState({})
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState(null)
  const [configTypeData, setConfigTypeData] = useState(props && props.accessPermissions && props.accessPermissions.configType ? props.accessPermissions.configType == "D" ? 'DistrictId' : props.accessPermissions.configType == "S" ? 'SiteId' : 'RegionId' : 'RegionId')
  const [enabledItems, setEnabledItems] = useState([])

  const params = useParams();
  useEffect(() => {
    let type = 'Districts'
    if (props.accessPermissions.configType === 'S')
      type = 'Sites'
    else if (props.accessPermissions.configType === 'R')
      type = 'Regions'
    else type = 'Districts'
    const read = [
      {
        url: getEnvVariable('Admin') + `/api/${type}/` + getEnvVariable('ModuleKey') + `/All/Active`,
        type: 'get'
      },
      {
        url: getEnvVariable('AdminConfig') + `/api/SitePermitTypesConfig/${props.dataItem.Id}/All`,
        type: 'get'
      },
      {
        url: getEnvVariable('AdminConfig') + `/api/SitePermitTypesConfig/ConfigTypesDropdown/${props.dataItem.Id}`,
        type: 'get'
      },
    ]
    Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(res => {
      const [active, configdata, configdropdown] = res;
      var idarray = configdropdown.map(a => a.Id);
      setEnabledItems(idarray);
      setconfigtype(active)
      setData(configdata)
    })
  }, [])

  const handleAdd = () => {
    setAdd(!add);
    setResult({})
  }

  const handleFieldChange = (event, type, id) => {
    const { name, value } = event.target;
    if (type === 'add') {
      result[name] = value;
      setResult(result)
      setReload(!reload)
    }

    else if (type === 'editconfigItems') {
      data.map(final => {
        if (final[configTypeData] === id) {
          final[name] = value
        }
      })
      setData(data)
      setReload(!reload)
    }
  }

  const handleDelete = (e, id) => {
    setDeleteItemId(id);
    setOpenConfirmation(true);
  }

  const ConfirmDelete = () => {
    let tempData = data.filter(final => {
      if (final.Id == deleteItemId) {
        let url = getEnvVariable('AdminConfig') + `/api/SitePermitTypesConfig/${props.dataItem.Id}/` + final.DistrictId + `/Delete`
        return DeleteRecord(url, '').then(res => {
          // var idarray = res.map(a => a.Id);
          // setEnabledItems(idarray);
          setDeleteItemId(null);
          handleTransision();
          setOpenConfirmation(false);
          setData(tempData)
          setReload(!reload)
        })
      }
    })
  }

  const handleCancelDelete = () => {
    setOpenConfirmation(false);
    setDeleteItemId(null);
  }

  const handleSave = (type, each) => {
    if (type === 'add') {
      let url = getEnvVariable('AdminConfig') + `/api/SitePermitTypesConfig/${props.dataItem.Id}/` + result[configTypeData] + `/Add`
      PostData(url, result).then(res => {
        //var idarray = res.map(a => a.Id);
        //setEnabledItems(idarray);
        setAdd(false);
        handleTransision();
      })
    }
    else {
      let url = getEnvVariable('AdminConfig') + `/api/SitePermitTypesConfig/${props.dataItem.Id}/` + each[configTypeData] + `/Update`
      PutData(url, each).then(res => {
        //var idarray = res.map(a => a.Id);
        //setEnabledItems(idarray);
        setAdd(false);
        handleTransision();
      })
    }
  }

  const handleTransision = () => {
    let idval = params.Id ? params.Id : params.routeid
    const read = [{
      url: getEnvVariable('AdminConfig') + `/api/SitePermitTypesConfig/${idval}/All`,
      type: 'get'
    }]
    Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(res => {
      const [data] = res
      setData(data);
    })
  }

  const handleBack = () => {
    props.onClose();
  }

  return (
    <Fragment>
      <FormHeader title="Configurations" handleAdd={handleAdd} handleBack={handleBack}
        addbutton={true} backbutton={true} />
      {reload}
      {/* <div className="float-md-right"> <Fab edge="start" size="small" color="primary" onClick={handleAdd} > <AddIcon /></Fab></div> */}
      <div className="clear-fix" />
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col"> {props.accessPermissions && props.accessPermissions.configType && props.accessPermissions.configType == "R" ? 'Region' : props.accessPermissions.configType == "S" ? 'Site' : 'District'}  </th>
            <th scope="col"> Action </th>
          </tr>
        </thead>
        <tbody>
          {add && <tr>
            <th scope="row">
              <DropDown name={configTypeData} items={configtypes} value={(result && result[configTypeData]) || null} menuDisabled={menuDisabled}
                handleFieldValue={(e) => handleFieldChange(e, "add")} extraProps={{
                  transformProps: {
                    label: 'Name',
                    value: 'Id'
                  }
                }} />
            </th>
            <td>
              <IconButton color="primary" onClick={() => handleSave('add')}>
                <SaveIcon />
              </IconButton>
              <IconButton color="primary" onClick={handleAdd}>
                <CloseIcon />
              </IconButton>
            </td>
          </tr>}
          {data && data.map(each => {
            return <tr>
              <th scope="row">
                <DropDown name={configTypeData} disabled={true} items={configtypes} value={(each && each[configTypeData]) || null} menuDisabled={menuDisabled}
                  handleFieldValue={(e) => handleFieldChange(e, "editconfigItems", each[configTypeData])} extraProps={{
                    transformProps: {
                      label: 'Name',
                      value: 'Id'
                    }
                  }} />
              </th>
              <td>
                {/* <IconButton tooltip="Save" color="primary" aria-label="upload picture" component="span" onClick={(e) => handleSave('update', each)}>
                  <SaveIcon />
                </IconButton> */}
                <IconButton tooltip="Delete" color="primary" aria-label="upload picture" component="span" onClick={(e) => handleDelete(e, each.Id)}>
                  <DeleteIcon />
                </IconButton>
                {openConfirmation && <ConfirmationDialog value={openConfirmation} title="Are you sure you want to delete?" onSubmit={ConfirmDelete} onClose={handleCancelDelete} />}
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </Fragment>
  );

}
