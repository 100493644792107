import React, { Component } from 'react';
import { loadModules } from 'esri-loader';
import ReactDOM from "react-dom";
import { Icon } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

class CZoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxZoomed: false,
            minZoomed: false,
            view: this.props.view
        }
        this.startup(
            this.props
        );
    }


    startup = (config) => {
        loadModules([
            "esri/core/watchUtils",
            "esri/widgets/Zoom/ZoomViewModel"
        ]).then(([watchUtils, ZoomViewModel]) => {
            try {
                let vm = new ZoomViewModel();
                vm.view = config.view;
                this.setState({ vm: vm });
                watchUtils.init(config.view, "zoom", this.onZoomChange);
                const node = ReactDOM.findDOMNode(this);
                config.view.ui.add(node, "top-left");
            } catch (error) {
                console.log(error);
            }
            this.props.widgetRenderCallBack("Zoom")
        });
    }


    onZoomChange = (value, oldValue, property, object) => {
        this.setState({
            maxZoomed: value === this.state.view.constraints.maxZoom,
            minZoomed: value === this.state.view.constraints.minZoom
        });
    };

    zoomIn = () => {
        if (!this.state.maxZoomed) {
            this.state.vm.zoomIn();
        }
    };

    zoomOut = () => {
        if (!this.state.minZoomed) {
            this.state.vm.zoomOut();
        }
    };

    render() {
        const maxstate = this.state.maxZoomed ? "map-button circle raised disable" : "map-button circle raised";
        const minstate = this.state.minZoomed ? "map-button circle raised disable" : "map-button circle raised";
        return (
            <span className="map-zoom-btns">
                <Tooltip title="Zoom In" placement="bottom-start">
                    <Icon className={maxstate} onClick={this.zoomIn}>add</Icon>
                </Tooltip>

                <br />
                <Tooltip title="Zoom Out" placement="bottom-start">
                    <Icon className={minstate} onClick={this.zoomOut}>remove</Icon>
                </Tooltip>

            </span>
        );
    }
}

export default CZoom;