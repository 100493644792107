/**
      *********************************************************
      Description: Switch component.
      Required props:  type, value
      limitations    : N/A
      *********************************************************
**/
import React, { Component, Fragment } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography';
import { green, red } from '@material-ui/core/colors';
import FormControl from '@material-ui/core/FormControl';

const styles = {
    root: {
        flexGrow: 1,
    },
};

const GreenSwitch = withStyles({
    switchBase: {
        color: red[800],
        '&$checked': {
            color: green[700],
        },
        '&$checked + $track': {
            backgroundColor: green[700],
        },
    },
    checked: {},
    track: {},
})(Switch);

const FormSwitch = (props) => {

    const { handleFieldChange, value, name, color, label, transformProps, disabled, edit, conditions, helperText, LabeltransformProps } = props;
    let disabledstatus = conditions && conditions.enable ? conditions.enable : disabled;
    let showvalue = 'In-Active';
    if (edit === false) {
        if (transformProps[value] === 'Y' || value === "Y") showvalue = 'Yes';
        if (transformProps[value] === 'N' || value === "N") showvalue = 'No';
        if (transformProps[value] === 'A' || value === "A") showvalue = 'Active';
        if (transformProps[value] === 'I' || value === "I") showvalue = 'In-Active';
        return <Fragment>
            <div > <Typography variant={'caption'} className="fw-bold">{label}
            </Typography>  <Typography variant={'body2'} color={'textPrimary'} className="text-secondary">
                    {showvalue}  </Typography>
            </div>
        </Fragment >
    }
    else return (
        <Fragment>
             <FormControl required={props.required}  error={props.errors || false}>
            <FormControlLabel
                control={
                    <GreenSwitch
                        checked={value ? (transformProps ? transformProps[value] : value) : false}
                        onChange={handleFieldChange}
                        //color={color || "primary"}
                        //size='small'
                        name={name}
                        disabled={disabledstatus || false}
                    />
                }
                label={<span style={{ fontSize: '12px' }}>{value ? (LabeltransformProps ? LabeltransformProps[value] : (label || '')) : label}</span>}
            />
            {helperText && <FormHelperText error>{helperText}</FormHelperText>}
            </FormControl>
        </Fragment>
    );
}


export default withStyles(styles)(FormSwitch);