import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import React, { Fragment, useEffect, useState } from 'react';
import Dropdown from '../dropdown';
import RadioButtonsGroup from '../radiogroup';
import Textbox from '../textbox';
import { GetData, PostData } from '../../helpers/requests';
import { getEnvVariable } from '../../../../modules/environmentalList';
import Checkbox from '../checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { useParams } from 'react-router'


export default function CusromProvisions({ name, label, value = [], handleFieldValue, helperText, error, disabled, extraProps, edit, fieldValues }) {
    const params = useParams()
    const [type, setType] = useState([])
    //const [currenterror, setError] = useState(null)
    const [items, setItems] = useState([])
    const [IsCustomProvision, setIsCustomProvision] = useState('N')
    const [custom, setCustom] = useState({})
    const [reload, setReload] = useState(false)
    const [hold, setHold] = useState({
        Provision: '',
        ProvisionId: null,
        Type: '3',
        Status: 'A'
    });
    const [errors, setError] = useState([])
    const [errorState, setErrorState] = useState({})

const GetDisabledType=(type)=>{
        if(type.length > 0)
        {
        let tempType = type;
        tempType.map(each => {
            if (each.Name === "Construction" || each.Name === "Both")
                each['IsSelectable'] = (fieldValues['ProcessType'] === "4" || fieldValues['ProcessType'] === "7") ? 'N' : 'Y'
            return each
        })
       return tempType
    }
    }

    useEffect(() => {
        let url = getEnvVariable('Admin') + `/api/Domains/values?domainkey=DESIGN_PROVISION_TYPE`
        GetData(url).then(res => {
            setType(res);
            getItems()
        })
    }, [])

    const getItems = () => {
        let url = getEnvVariable('Application') + `/api/Provisions/AllEffective`
        GetData(url).then(res => {
            setItems(res)
        })
    }

    const setHoldData = () => {
        setHold({
            Provision: '',
            ProvisionId: null,
            Type: '3',
            Status: 'A'
        })
    }

    const handleCustomProvision = (holddata, dataitems) => {
        let temp = value || []
        if (holddata && (holddata['Provision'])) {
            let add = (value || []).find(e => e.Provision === holddata['Provision'] && e.Type === holddata.Type) || null;
            let prov = dataitems.find(each => each.STD_PROV_REFNAME === holddata.Provision)
            if (!add) {
                setError(false);
                temp.push({ ...holddata, ProvisionId: (prov && prov.Id) || 0, Provision: holddata['Provision'], ProvisionDescription: prov['STD_PROV_TXT'], ProvisionShortText: prov['STD_PROV_SHORT_TXT'] })
                setHoldData()
                handleFieldValue({ target: { value: temp, name: name, checked: false }, currentTarget: { dataset: null } })
            }
            else {
                if (add && add.Status === 'I')
                    handleDelete(add)
                else setErrorState({ ...errorState, 'PROVISION_SELECT_ERROR': 'Provision already exisits' })
            }
        }
        else setErrorState({ ...errorState, 'PROVISION_SELECT_ERROR': 'Choose Provision before add' }) //setError('Choose Provision before add')
    }

    const handleDelete = (current) => {
        let temp = value;
        if ((temp || []).some(e => e.Provision === current.Provision && e.Type === current.Type)) {
            for (let index = 0; index < temp.length; index++) {
                const element = temp[index];
                if (element.Provision === current.Provision && element.Type === current.Type) {
                    element.Status = element.Status === 'A' ? "I" : 'A'
                }
            }
            setHoldData()
            handleFieldValue({ target: { value: temp, name: name, checked: false }, currentTarget: { dataset: null } })
        }
    }

    const handleProvisionSave = () => {
        if (custom && IsCustomProvision === 'Y') {
            custom['PERMIT_TYP_CD'] = 'APP'
            custom['permiT_CATEG_CD'] = 'A'
            custom['Status'] = 'A'
            let url = getEnvVariable('Application') + `/api/Provisions/${params.routeid1}/Add`
            PostData(url, custom).then(res => {
                getItems();
                let holddata = { ...hold, ['Provision']: res['STD_PROV_REFNAME'], ...res, Id: 0 };
                setHold(holddata);
                let tempItems = items;
                tempItems.push(res);
                setItems(tempItems);
                handleCustomProvision(holddata, tempItems);
                setCustom({ ['STD_PROV_NO']: '', ['STD_PROV_SHORT_TXT']: '', ['STD_PROV_TXT']: '' })
                setIsCustomProvision('N')
                setReload(!reload)
            }).catch(error => { console.log(error); return error; }).then(response => {
                console.log(response);
                if (response) {
                    let errorobj = {}
                    setError(true);
                    for (const [key, value] of Object.entries(response)) {
                        console.log(key, value);
                        errorobj[key] = value.toString();
                    }
                    setErrorState(errorobj);
                } else setError(false);
            })

            // .catch(error => {
            //     console.log(error);
            //     let errorobj = {}                
            //     setError(true);
            //     for (const [key, value] of Object.entries(error)) {
            //         console.log(key, value);
            //         errorobj[key] = value.toString();
            //     }
            //     setErrorState(errorobj);
            // })
        } else {
            let tempItems = items;
            custom['STD_PROV_NO'] = custom['STD_PROV_NO'] ? custom['STD_PROV_NO'] : params.routeid1;
            //let itemval = { ['STD_PROV_REFNAME']: custom['STD_PROV_NO'] + '-' + custom['STD_PROV_SHORT_TXT'], ['STD_PROV_TXT']: custom['STD_PROV_TXT'],['STD_PROV_SHORT_TXT']: custom['STD_PROV_SHORT_TXT'] }
            let itemval = {
                ['STD_PROV_REFNAME']: custom['STD_PROV_NO'] + '-' + custom['STD_PROV_SHORT_TXT'],
                ['STD_PROV_TXT']: custom['STD_PROV_TXT'],
                ['STD_PROV_SHORT_TXT']: custom['STD_PROV_SHORT_TXT']
            }
            tempItems.push(itemval);
            setItems(tempItems)
            let holddata = { ...hold, ['Provision']: itemval['STD_PROV_REFNAME'], ...itemval, Id: 0 };
            setHold(holddata);
            handleCustomProvision(holddata, tempItems)
            setCustom({ ['STD_PROV_NO']: '', ['STD_PROV_SHORT_TXT']: '', ['STD_PROV_TXT']: '' })
            setIsCustomProvision('N')
            setReload(!reload)
        }
    }

    useEffect(() => {
        console.log('custom changed');
        setError(false)
        setErrorState({})
    }, [custom])

    const getDropDownItems = () => {
        if (Array.isArray(items) && Array.isArray(value) && value.length > 0 && items.length > 0) {
            return items.filter(f => !value.map(ele => ele.Provision).includes(f.STD_PROV_REFNAME))
        }
        return items;
    }

    return <Grid container spacing={3}>
        {reload}

        <Grid item xs={4} className={edit ? "" : 'd-none'}>
            <Dropdown items={getDropDownItems()} name="Provision" label="Choose Provision" value={hold.Provision} extraProps={{
                transformProps: {
                    label: 'STD_PROV_REFNAME',
                    value: 'STD_PROV_REFNAME'
                }
            }}
                handleFieldValue={(e) => { setHold({ ...hold, ['Provision']: e.target.value }) }} />
        </Grid>
        <Grid item xs={2} className={edit ? "" : 'd-none'}>
            <RadioButtonsGroup name="Type" label="Choose Type" value={hold.Type} items={GetDisabledType(type)}
                handleFieldValue={(e) => { setHold({ ...hold, ['Type']: e.target.value }) }} />
        </Grid>
        <Grid item xs={1} className={edit ? "pt-5" : 'd-none'} >
            <Button color='primary' size='small' variant='contained' onClick={() => handleCustomProvision(hold, items)}>Insert</Button>
        </Grid>
        <Grid item xs={5}>
            <small className='fw-bold'>Assigned</small>
            <Paper component="ul" className='col-12' style={{ height: '200px', overflowY: 'auto', width: '100%' }}>
                {((Array.isArray(value) && value) || []).filter(e => e.Status === 'A').map((data) => {
                    return (
                        <Chip
                            className='m-1'
                            variant="outlined" size="small"
                            label={data.Provision + ' (' + (type.find(e => e.Value.toString() === data.Type.toString()) || {}).Name + ')'}
                            onDelete={edit ? () => handleDelete(data) : null}
                        />
                    );
                })}
            </Paper>
            {errorState['PROVISION_SELECT_ERROR'] && <div className='text-danger'>{errorState['PROVISION_SELECT_ERROR']}</div>}
        </Grid>
        <Divider />
        <Grid item xs={12} className={edit ? "" : 'd-none'}>
            <small className='fw-bold'>Custom Provisions</small>
            <div className='row col-12 my-3'>
                <div className='row col-12'>
                    {/* <Textbox className="col-4" name="STD_PROV_NO" label="Provision Number" value={custom.STD_PROV_NO} 
                        handleFieldValue={(e) => { setCustom({ ...custom, ['STD_PROV_NO']: e.target.value }) }} helperText={errorState['STD_PROV_NO']} error={errors} /> */}
                    <Textbox className="col-4" name="STD_PROV_SHORT_TXT" label="Short Description" value={custom.STD_PROV_SHORT_TXT}
                        handleFieldValue={(e) => { setCustom({ ...custom, ['STD_PROV_SHORT_TXT']: e.target.value }) }} helperText={errorState['STD_PROV_SHORT_TXT']} error={errors} />
                    <Checkbox className='col-3' label='Save Custom Provision' name="IsCustomProvision" value={IsCustomProvision || null}
                        handleFieldValue={(e) => { setIsCustomProvision(e.target.checked ? 'Y' : 'N') }} extraProps={{
                            transformProps: { "Y": true, "N": false, true: "Y", false: "N" }
                        }} />
                    <IconButton className='col-1' color='primary' variant='contained' size='small' disabled={!(custom.STD_PROV_TXT && custom.STD_PROV_SHORT_TXT)}
                        onClick={() => handleProvisionSave()}><SaveAltIcon />
                    </IconButton>
                </div>
                <div className='row col-12'>
                    <Textbox className="col-11" multiline={true} rowsMax={2} name="STD_PROV_TXT" label="Description" value={custom.STD_PROV_TXT}
                        handleFieldValue={(e) => { setCustom({ ...custom, ['STD_PROV_TXT']: e.target.value }) }} helperText={errorState['STD_PROV_TXT']} error={errors} />


                </div>
            </div>
        </Grid>

        {/* {hold && hold.CustomProv && <Grid item xs={4} className={edit ? "" : 'd-none'}>
            <Checkbox className='col-4 ' label='Save Custom Provision' name="IsCustomProvision" value={hold && hold.IsCustomProvision || null}
                handleFieldValue={(e) => { setHold({ ...hold, ['IsCustomProvision']: e.target.checked ? 'Y' : 'N' }) }} extraProps={{
                    transformProps: { "Y": true, "N": false, true: "Y", false: "N" }
                }} />
        </Grid>} */}
        {errorState.helperText && <FormHelperText>{errorState.helperText}</FormHelperText>}
    </Grid >
}