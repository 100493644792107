import React, { useState, useEffect } from 'react';
import IframeView from '../../core/controls/components/iframe';
import { getEnvVariable } from '../../modules/environmentalList';
import { useParams } from 'react-router-dom';

export default function CompanyIframeCheck({ options, extra }) {
    let params = useParams();
    return <div>
        <IframeView props={{ ...options}}
            extra={extra || '/indemnity/' + getEnvVariable('AccessPortal') + '/' +
                (params.Id !== 'null'
                    ? params.Id
                    : localStorage.getItem('profile') &&
                    JSON.parse(localStorage.getItem('profile')).modulePermissions[0].companyId) +
                '/indemnities'
            }
        />
    </div>
}