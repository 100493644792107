export const AUTHORIZE = 'AUTHORIZE';
export const LOGOUT = 'LOGOUT';
export const PROFILE = 'PROFILE';

export const setLogin = (user) => {
    return {
        type: AUTHORIZE,
        user
    }
}

export const setProfile = (profile) => {
    return {
        type: PROFILE,
        profile
    }
}

export const logOut = (user) => {
    return {
        type: LOGOUT,
        user
    }
}
