
const snackbarReducer = (state = null, action) => {

    switch (action.type) {
        case 'SNACKBAR_MESSAGE':
            return { ...state, snackbarmessage: action.snackmessage, snackbarmessageType: action.snackbarmessageType }

        default:
            return state

    }
}

export default snackbarReducer