

export const historyJson = {
    headerProps: {
        title: "History",

        actions: [
            {
                type: 'fab',
                icon: 'clear',
                name: 'clear',
                color: 'primary',
                title: 'Clear Filters',
                menucolor: '#22a9b3',
                hideon: 'clear',
                hidevalue: false
            },

            {
                type: 'menu',
                icon: 'excel',
                name: 'excel',
                color: 'primary',
                title: 'Export to Excel',
                menucolor: '#c90685',
                hideon: 'excel',
                hidevalue: false,
                items: [{
                    name: 'Export Current Page',
                    action: 'filtercurrent'
                },
                {
                    name: 'Export All Pages',
                    action: 'filterall'
                }
                ]
            },

        ]
    },

    header: {
        name: "header",
        type: 'header',
        title: 'History',
        components: [
            {
                type: 'breadcrumbs',
                items: [
                    { "link": "History", "materialicon": "history", className: 'eps-breadcrumb-item ', class: 'text-light' },
                ]
            }
        ],
        actions: [
            {
                type: 'icon',
                icon: 'back',
                name: 'back',
                hideon: 'back',
                hidevalue: false
            },
        ],
    },
    columns: [
        {
            "title": "Field Name",
            "field": "FieldName",
            "show": true,
            "width": 200,
        },
        {
            "title": "Old Value",
            "field": "OldValue",
            "show": true,
            "width": 150,
        },
        {
            "title": "New Value",
            "field": "NewValue",
            "show": true,
            "width": 150,

        },
        {
            "title": "Updated By",
            "field": "CreatedByName",
            "show": true,
            "width": 200,

        },
        {
            "title": "Updated On",
            "field": "CreatedOn",
            "show": true,
            "width": 200,
            filter: "date",
            convert: "MM/DD/YYYY hh:mm:ss A"
        },
    ],
}

export const ApplicationhistoryJson = {
    headerProps: {
        title: "History",

        actions: [
            {
                type: 'fab',
                icon: 'clear',
                name: 'clear',
                color: 'primary',
                title: 'Clear Filters',
                menucolor: '#22a9b3',
                hideon: 'clear',
                hidevalue: false
            },

            {
                type: 'menu',
                icon: 'excel',
                name: 'excel',
                color: 'primary',
                title: 'Export to Excel',
                menucolor: '#c90685',
                hideon: 'excel',
                hidevalue: false,
                items: [{
                    name: 'Export Current Page',
                    action: 'filtercurrent'
                },
                {
                    name: 'Export All Pages',
                    action: 'filterall'
                }
                ]
            },

        ]
    },

    header: {
        name: "header",
        type: 'header',
        title: 'History',
        components: [
            {
                type: 'breadcrumbs',
                items: [
                    { "link": "History", "materialicon": "history", className: 'eps-breadcrumb-item ', class: 'text-light' },
                ]
            }
        ],
        actions: [
            {
                type: 'icon',
                icon: 'back',
                name: 'back',
                hideon: 'back',
                hidevalue: false
            },
        ],
    },
    columns: [
        {
            "title": "Field Name",
            "field": "Field",
            "show": true,
            "width": 150,
        },
        {
            "title": "Old Value",
            "field": "OldValue",
            "show": true,
            "width": 150,
        },
        {
            "title": "New Value",
            "field": "NewValue",
            "show": true,
            "width": 150,

        },
        {
            "title": "Updated By",
            "field": "UpdatedBy",
            "show": true,
            "width": 200,

        },
        {
            "title": "Updated On",
            "field": "UpdatedOn",
            "show": true,
            "width": 200,
            filter: "date",
            convert: "MM/DD/YYYY hh:mm:ss A"
        },
    ],
}