import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CBuildDragDropContext from '../../core/modules/dnd/widgets/cBuildDragDropContext';
import * as DNDSettings from '../../core/modules/dnd/widgets/util/dndSettings';
//import SitePermitsSectionsFields from './custommodules/sitepermittypes/sitepermitsectionsfields'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ValidateIcon from '@material-ui/icons/AssignmentTurnedIn';
import SaveIcon from '@material-ui/icons/Save';
//import { postRecordData } from '../../rest';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { snackbarMessage } from '../../core/actions';
//import { PostData } from '../../form/formsapi';
import DefaultSettings from '../../core/controls/components/settings';
import { GetData, PostData } from '../../core/controls/helpers/requests';
import ValidationsRules from '../../core/modules/dnd/widgets/validationrules';
import FormHeader from '../../core/modules/form/cformheader';

class CBuildDND extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: [],
      options: this.props.options,
      togglebackslider: false,
      errors: null
    }
    this.onDragEnd = this.onDragEnd.bind(this);
    this.OnAddRow = this.OnAddRow.bind(this);
  }

  componentDidMount() {
    this.startup(
      this.props.options
    );
  }

  startup = async (options) => {
    if (options) {
      const { crud, settings, allfields } = options;
      const { read } = crud;
      const id = this.props.match.params.routeid
      GetData(read.get.url + '/' + id + '/' + read.get.suburl).then(SectionFields => {
        if (SectionFields) {
          console.log("SectionFields,SectionFields", SectionFields)
          const { availableFields, availableSections, sections } = SectionFields
          allfields.rows[0].fields = availableFields;
          let sectionData = [];
          if (sections != null) {
            let updatedsections = sections.map(sec => {
              let Rows = sec.rows.map(row => {
                return { ...settings.row, ...row };
              });
              let ssection = { ...sec.section, ...settings.section }
              let secrows = { ...ssection, rows: Rows }
              return secrows;
            });
            sectionData = updatedsections;
          }
          sectionData.unshift(allfields);
          this.setState({
            availableSections: availableSections,
            sections: sectionData,
            finalsection: sectionData
          });
        }
      }).catch(ex => {
        console.log(ex)
      })
    }
  }

  onError = errors => {
    console.log(errors);
    const { sections } = this.state;
    this.setState({ errors: errors, sections: sections })
  }

  saveSitePermits = () => {
    let saveresponse = this.beforeSave();
    const { crud, title } = this.props.options;
    const { AddUpdate } = crud;
    console.log(saveresponse, this.state.finalsection);
    if (saveresponse.datSave && saveresponse.datSave.length > 0) {
      let url = `${AddUpdate.url}` + this.props.match.params.routeid + '/AddOrUpdate';
      PostData(url, saveresponse.datSave).then(res => {
        this.startup(this.props.options)
      }).catch(error => {
        error.then(err => {
          if (err.errors) this.onError(err.errors)
          else this.onError(err)
        })
      })
    }
    else {
      if (DefaultSettings.getAlert() != null)
        DefaultSettings.getAlert().show("Please Add Sections and Fields to Save", 'error');
    }
  }

  beforeSave = () => {
    let norows = false;
    let filteredSecctions = this.state.sections.filter(sec => {
      return sec.dndId !== "allfields"
    });

    let updatedSecctions = filteredSecctions.map(sec => {
      let secfields = {}
      let rowOrder = 1;
      if (sec.rows && sec.rows.length > 0) {
        let secrows = sec.rows.map(row => {
          row.RowOrder = rowOrder++;
          let colOrder = 1;
          let Fields = row.fields.map(field => {
            field.ColOrder = colOrder++;
            return field;
          });
          row.fields = Fields;
          return row;
        });
        secfields.rows = secrows;
        secfields.section = sec;
        return secfields;
      }
      else norows = true;
    });
    let datatosave = norows ? ({ datSave: false, sections: this.state.finalsection }) : ({ datSave: updatedSecctions, sections: this.state.finalsection })
    return datatosave;
  }

  onDragEnd(result) {
    let currentsectiondata = null
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    let sourceParentId = result.source.droppableId;
    let destParentId = result.destination.droppableId;
    if (sourceParentId)
      sourceParentId = sourceParentId.replace("SecDP-", "").replace("SecDG-", "").replace("RowDP-", "").replace("RowDG-", "");
    if (destParentId)
      destParentId = destParentId.replace("SecDP-", "").replace("SecDG-", "").replace("RowDP-", "").replace("RowDG-", "");
    if (result.type === "section") {
      const sections = DNDSettings.reorder(this.state.sections, sourceIndex, destIndex);

      this.setState({ sections, finalsection: sections });
    } else if (result.type === "row") {
      const sectionsubItemMap = this.state.sections.reduce((acc, item) => {
        acc[item.dndId] = item.rows;
        return acc;
      }, {});

      const sourcerows = sectionsubItemMap[sourceParentId] || [];
      const destrows = sectionsubItemMap[destParentId] || [];

      let newsections = [...this.state.sections];

      /** In this case rows are reOrdered inside same Parent */
      if (DNDSettings.IsEqual(sourceParentId, destParentId)) {
        const reorderedrows = DNDSettings.reorder(
          sourcerows,
          sourceIndex,
          destIndex
        );
        newsections = newsections.map(item => {
          if (DNDSettings.IsEqual(item.dndId, sourceParentId)) {
            item.rows = reorderedrows;
          }
          return item;
        });
        this.setState({
          sections: newsections,
          finalsection: this.state.sections
        });
      } else {
        let newSourcerows = [...sourcerows];
        const [draggedItem] = newSourcerows.splice(sourceIndex, 1);
        let newDestrows = [...destrows];
        newDestrows.splice(destIndex, 0, draggedItem);
        newsections = newsections.map(item => {
          if (DNDSettings.IsEqual(item.dndId, sourceParentId)) {
            item.rows = newSourcerows;
          } else if (DNDSettings.IsEqual(item.dndId, destParentId)) {
            item.rows = newDestrows;
          }
          return item;
        });
        this.setState({
          sections: newsections,
          finalsection: this.state.sections
        });
      }
    } else if (result.type === "field") {
      const sectionfieldsMap = this.state.sections.reduce((acc, item) => {
        item.rows.reduce((field, row) => {
          acc[row.dndId] = row.fields;
        }, {});
        return acc;
      }, {});
      const sourcerows = sectionfieldsMap[sourceParentId] || [];
      const destrows = sectionfieldsMap[destParentId] || [];
      let newsections = [...this.state.sections];
      /** In this case rows are reOrdered inside same Parent */
      if (DNDSettings.IsEqual(sourceParentId, destParentId)) {
        const reorderedrows = DNDSettings.reorder(
          sourcerows,
          sourceIndex,
          destIndex
        );
        newsections = newsections.map(section => {
          let rows = section.rows.map(row => {
            if (DNDSettings.IsEqual(row.dndId, sourceParentId)) {
              row.fields = reorderedrows;
            }
          })
          return section;
        });

        this.setState({
          sections: newsections,
          finalsection: this.state.sections
        });
      } else {
        let newSourcerows = [...sourcerows];
        const [draggedItem] = newSourcerows.splice(sourceIndex, 1);
        let newDestrows = [...destrows];
        newDestrows.splice(destIndex, 0, draggedItem);
        console.log(newDestrows)
        newsections = newsections.map(section => {
          let currentsection = false
          let rows = section.rows.map(row => {
            if (DNDSettings.IsEqual(row.dndId, sourceParentId)) {
              row.fields = newSourcerows;
            } else if (DNDSettings.IsEqual(row.dndId, destParentId)) {
              row.fields = newDestrows;
              currentsection = true
            }
          })
          if (currentsection) currentsectiondata = section
          return section;
        });
        this.setState({
          sections: newsections,
          finalsection: newsections
        }, () => {
          this.OnRowSettings(null, currentsectiondata, draggedItem)
        });
      }
    }
  }


  OnSectionSettings = (event, err, section) => {
    this.setState({
      selectedsection: section,
      togglebackslider: true,
      opensection: true,
      isNewSection: false,
      openfield: false,
      err: err
    })
    console.log('current selected section---', section)
  }

  OnRowSettings = (event, section, field) => {
    this.setState({
      selectedfield: field,
      togglebackslider: true,
      opensection: false,
      openfield: true,
      selectedsection: section
    })
    console.log('current selected field---', section, field)
  }



  OnAddSection = (event, parentSection) => {
    console.log('sectiondata--------------', this.state.sections)
    console.log('Available sectiondata--------------', this.state.availableSections)
    const { availableSections, sections } = this.state;
    let filteravailable = availableSections.filter(o => !sections.some(v => v.SectionId === o.SectionId));
    console.log("availavle+" + filteravailable)
    this.setState(state => ({
      togglebackslider: true,
      opensection: true,
      openfield: false,
      isNewSection: true,
      selectedParentSection: parentSection,
      sections: [
        ...state.sections
      ],
      availableSections: filteravailable,
      finalsection: [
        ...state.sections
      ],
    }));
  }


  OnAddNewSection = (event, addedSection, selectedParentSection) => {
    let sectiondroppableid = `${btoa(Math.random())}`;
    const allsections = this.state.sections;
    const { settings } = this.state.options
    let newSection = { ...DNDSettings.Clone(settings.section), ...addedSection };
    newSection.DisplayOrder = Math.max.apply(Math, allsections.map(function (o) { return o.DisplayOrder || 0; })) + 1
    newSection.dndId = sectiondroppableid;
    if (selectedParentSection)
      newSection.parentDndId = selectedParentSection.dndId
    this.setState(state => ({
      selectedsection: newSection,
      togglebackslider: false,
      opensection: false,
      openfield: false,
      isNewSection: false,
      sections: [
        ...state.sections,
        { ...newSection }
      ],
      finalsection: [
        ...state.sections,
        { ...newSection }
      ],

    }));
  }

  OnAddRow = (event, section) => {
    let droppableid = `${btoa(Math.random())}`;
    const { settings } = this.state.options
    let newRow = DNDSettings.Clone(settings.row);
    newRow.dndId = droppableid;
    let newsections = this.state.sections.map(s => {
      if (DNDSettings.IsEqual(s.dndId, section.dndId)) {
        s.rows.push({ ...newRow });
      }
      return s;
    });
    this.setState({
      sections: newsections,
      finalsection: newsections
    });
  }

  OnSectionRemove = (event, section) => {
    //Getting section for all fields
    let allFieldssections = this.state.sections.filter(s => {
      return DNDSettings.IsEqual(s.dndId, "allfields");
    });
    //Adding deleting section fields to available fields
    if (allFieldssections != null) {
      let allFieldssectionsRowfields = allFieldssections[0].rows[0].fields
      section.rows.map(row => {
        row.fields.map(field => {
          allFieldssectionsRowfields.push(field)
        });
      });
      allFieldssections[0].rows[0].fields = allFieldssectionsRowfields
    }
    //deleting section
    let nondeletedsections = this.state.sections.filter(s => {
      return !DNDSettings.IsEqual(s.dndId, section.dndId);
    });
    //replacing updated available section
    /*
      let updatedsections = nondeletedsections.map(s => {
        if (DNDSettings.IsEqual(s.dndId, "allfields")) {
          return s;//allFieldssections;
        }
        return s;
      });
  */
    //Adding deleted section to available sections
    section.rows = [];
    let newavailableSections = this.state.availableSections;
    newavailableSections.push(section);
    this.setState({
      sections: nondeletedsections,
      finalsection: nondeletedsections,
      availableSections: newavailableSections
    });
  }


  mainSections = (sections) => {
    return sections.filter(sec => {
      return !sec.parentDndId
    });
  }

  getSubSections = (sections, parentSection) => {
    return sections.filter(sec => {
      if (sec.parentDndId)
        return sec.parentDndId == parentSection.dndId;
    });
  }

  CanShowMoveDown = (section) => {
    let upsections = this.state.sections.filter(s => {
      return DNDSettings.IsEqual(s.DisplayOrder, section.DisplayOrder + 1);
    });
    return upsections != null && upsections.length != 0;
  }

  OnMoveDown = (event, section) => {
    let upsections = this.state.sections.filter(s => {
      return DNDSettings.IsEqual(s.DisplayOrder, section.DisplayOrder + 1);
    });
    if (upsections == null || upsections.length == 0)
      return;
    let upsection = upsections[0];
    this.updateSectionOrder(upsection, section);
  }

  CanShowMoveUp = (section) => {
    let downsections = this.state.sections.filter(s => {
      return DNDSettings.IsEqual(s.DisplayOrder, section.DisplayOrder - 1);
    });
    return downsections != null && downsections.length != 0;
  }

  OnMoveUp = (event, section) => {
    let downsections = this.state.sections.filter(s => {
      return DNDSettings.IsEqual(s.DisplayOrder, section.DisplayOrder - 1);
    });
    if (downsections == null || downsections.length == 0)
      return;
    let downsection = downsections[0];
    this.updateSectionOrder(section, downsection);
  }

  updateSectionOrder = (upsection, downsection) => {
    let displayOrder = upsection.DisplayOrder;
    upsection.DisplayOrder = downsection.DisplayOrder;
    downsection.DisplayOrder = displayOrder;
    let updatedsections = this.state.sections.map(s => {
      if (DNDSettings.IsEqual(s.dndId, upsection.dndId)) {
        return downsection;
      }
      if (DNDSettings.IsEqual(s.dndId, downsection.dndId)) {
        return upsection;
      }
      return s;
    });
    this.setState({
      sections: updatedsections,
      finalsection: updatedsections
    });
  }


  handleBackSlider = (open) => (event) => {
    this.setState({
      togglebackslider: open
    })
  }

  getState = (settingsstate, type, selectedsection) => {
    console.log('settingsstate----', settingsstate, type, selectedsection)
    const { results } = settingsstate;
    if (type === 'field') {
      let updatedsec = this.state.sections
      updatedsec = updatedsec.map(sec => {
        return {
          ...sec, rows: sec.rows.map(row => {
            return {
              ...row, fields: row.fields.map(field => {
                if (DNDSettings.IsEqual(field.dndId, results.dndId)) {
                  return { ...field, ...results }
                } else return field;
              })
            }
          })
        }
      });
      console.log(updatedsec)
      this.setState({
        sections: updatedsec,
        finalsection: updatedsec
      });
    }
    else if (type === 'newsection') {
      let updatedsections = this.state.sections.map(s => {
        if (DNDSettings.IsEqual(s.dndId, results.dndId)) {
          return results;
        }
        return s;
      });
      if (selectedsection) {
        selectedsection = { ...selectedsection, ...results }
      }
      this.setState({
        sections: updatedsections,
        finalsection: updatedsections,
        selectedsection: selectedsection
      });
    }
    else {
      let updatedsections = this.state.sections.map(s => {
        if (DNDSettings.IsEqual(s.dndId, results.dndId)) {
          return results;
        }
        return s;
      });
      this.setState({
        sections: updatedsections,
        finalsection: updatedsections
      });
    }
  }

  handleBack = () => {
    const { options } = this.props
    const { back } = options;
    const { routes } = back;
    if (Array.isArray(routes)) {
      let path = ''
      routes.forEach(each => {
        const { route, routeParams, matchProps } = each
        if (routeParams) {
          path += route + "/" + this.props.match.params[routeParams]
        }
        else if (matchProps) {
          path += route + "/" + this.props.results[matchProps]
        }
        else {
          path += route
        }
      })
      this.props.history.push(path)
    }
  }

  handleValidateBackSlider = () => {
    this.setState({ openValidate: false })
  }

  render() {
    const { options, compmap, components } = this.props
    const { availableSections, sections } = this.state
    const { dndconfig, styles, title, headerProps } = options
    let config = { mainSections: this.mainSections(this.state.sections), sections: this.state.sections, ...dndconfig };
    return (
      <div style={{ ...styles }} className="h-100 w-100">
        {headerProps && <FormHeader title={'Section(s)'} savebutton={true} handleAdd={this.OnAddSection}
          addbutton={dndconfig.canAddSections ? true : false} handleSave={this.saveSitePermits} breadcrumbs={headerProps.breadcrumbs} custombutton={true} backbutton={headerProps.backbutton} handleBack={this.handleBack}
          handleCustom={() => this.setState({ openValidate: true })} customicon="assignment_turned_in"  customtitle = {'Bussiness Rules'}/>}
        {!headerProps && <div>
          <div className="row">
            <div className="col-sm-12 ">
              <Fab onClick={this.saveSitePermits} className="float-right p-1" title="Save Sections" size="small" color="secondary" aria-label="save" >
                <SaveIcon />
              </Fab>
              <span className="float-right p-1"  > &nbsp;</span>
              {dndconfig.canAddSections && <Fab onClick={this.OnAddSection} className="float-right p-1" title="Add Section" size="small" color="secondary" aria-label="add" >
                <AddIcon />
              </Fab>
              }
              <span className="float-right p-1"  > &nbsp;</span>
              {
                this.state.sections && this.state.sections.length > 0 && <Fab onClick={() => this.setState({ openValidate: true })}
                  className="float-right p-1" title="All Sections Validation  Rules" size="small" color="secondary" aria-label="add" >
                  <ValidateIcon />
                </Fab>
              }
            </div>
          </div>
        </div>}
        <CBuildDragDropContext config={config} getSubSections={this.getSubSections} onDragEnd={this.onDragEnd} OnAddSection={this.OnAddSection}
          OnAddSubSection={this.OnAddSection} OnAddRow={this.OnAddRow} OnSectionSettings={this.OnSectionSettings} OnSectionRemove={this.OnSectionRemove}
          OnRowSettings={this.OnRowSettings} OnMoveDown={this.OnMoveDown} OnMoveUp={this.OnMoveUp} CanShowMoveUp={this.CanShowMoveUp}
          CanShowMoveDown={this.CanShowMoveDown} errors={this.state.errors}
        ></CBuildDragDropContext>
        {
          components.map(each => {
            const { options, type, key } = each
            const EachComp = compmap[type]
            return <EachComp key={key} {...each}
              compmap={compmap} sectionsettings={this.state.selectedsection} fieldsettings={this.state.selectedfield}
              togglebackslider={this.state.togglebackslider}
              handleBackSlider={this.handleBackSlider}
              getState={this.getState}
              opensection={this.state.opensection}
              openfield={this.state.openfield}
              isNewSection={this.state.isNewSection}
              err={this.state.err}
              sectionData={this.state.sections}
              availableSections={this.state.availableSections}
              OnAddNewSection={this.OnAddNewSection}
              selectedParentSection={this.state.selectedParentSection}
            />
          })
        }
        {this.state.openValidate && <ValidationsRules section={null} eachsection={false} open={this.state.openValidate} handleBackSlider={this.handleValidateBackSlider} />}

      </div>
    );
  }
};

const mapActions = dispatch => {
  return bindActionCreators({ snackbarMessage }, dispatch);
}
CBuildDND = connect(null, mapActions)(CBuildDND)
export default withRouter(CBuildDND);
