import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React, { Component, Fragment } from 'react';
import MultiCheckAutoComplete from '../../core/controls/components/checkautocomplete';
import Dropdown from '../../core/controls/components/dropdown';
import PhoneFormat from '../../core/controls/components/phoneformat';
import TextField from '../../core/controls/components/textbox';
import Heading from './heading';
import ModuleSelection from './ModuleSelection';
import { getEnvVariable } from '../environmentalList';

const styles = {
  root: {
    maxWidth: window.innerwidth,
    flexGrow: 1,
  },
};


const extraProps = {
  transformProps: {
    label: 'Name',
    value: 'Value'
  },
}

const comextraProps = {
  transformProps: {
    label: 'Name',
    value: 'Id'
  },
}


const checkextraProps = {
  transformProps: {
    "Y": true,
    "N": false,
    true: "Y",
    false: "N"
  }
}
export class CompanyDetails extends Component {
  state = {
    activeStep: 1,

  };
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };


  render() {
    const { classes, values, handleChange, handleCheckChange, errors, } = this.props;
    let hidesubmodules = getEnvVariable('HideSubmodules') == "Y" ? true : false
    var stateList = values.StateList ? values.StateList : [];
    return (
      < div className=' col-sm-12' >
        {/* <Heading CompanyType={values && values.CompanyType} /> */}
        <Heading subheading="Applicant Information : " />
        <Fragment>
          <div className="row">
            <div className="col-sm-10">
              <TextField
                error={(errors.Name && errors.Name.error) ? errors.Name.error : false}
                label={values.CompanyType === 'T' ? 'Consultant Company Name' : 'Applicant Name'}
                handleFieldValue={handleChange('Name')}
                value={values.Name}
                required={true}
                helperText={(errors.Name && errors.Name.error) ? errors.Name.errortext : ""}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10">
              <TextField
                error={(errors.AddressLine1 && errors.AddressLine1.error) ? errors.AddressLine1.error : false}
                label="Address Line 1"
                handleFieldValue={handleChange('AddressLine1')}
                value={values.AddressLine1}
                required={true}
                helperText={(errors.AddressLine1 && errors.AddressLine1.error) ? errors.AddressLine1.errortext : ""}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10">
              <TextField
                error={(errors.AddressLine2 && errors.AddressLine2.error) ? errors.AddressLine2.error : false}
                label="Address Line 2"
                handleFieldValue={handleChange('AddressLine2')}
                value={values.AddressLine2}
                helperText={(errors.AddressLine2 && errors.AddressLine2.error) ? errors.AddressLine2.errortext : ""}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5">
              <TextField
                error={(errors.City && errors.City.error) ? errors.City.error : false}
                label="City"
                handleFieldValue={handleChange('City')}
                required={true}
                value={values.City}
                helperText={(errors.City && errors.City.error) ? errors.City.errortext : ""}
              />
            </div>
            <div className="col-sm-5">
              <Dropdown label={'State'} value={values.State || 'OR'} handleFieldValue={handleChange('State')} error={(errors.State && errors.State.error) ? errors.State.errortext : ""}
                helperText={(errors.State && errors.State.error) ? errors.State.errortext : ""}
                items={stateList}
                extraProps={extraProps} required={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5">
              <TextField
                error={(errors.Zip && errors.Zip.error) ? errors.Zip.error : false}
                label="Zip"
                handleFieldValue={handleChange('Zip')}
                value={values.Zip}
                required={true}
                helperText={(errors.Zip && errors.Zip.error) ? errors.Zip.errortext : ""}
                />
            </div>
          <div className="col-sm-5">
              <TextField
                error={(errors.Country && errors.Country.error) ? errors.Country.error : false}
                label="Country"
                handleFieldValue={handleChange('Country')}
                required={true}
                value={values.Country || 'USA'}
                helperText={(errors.Country && errors.Country.error) ? errors.Country.errortext : ""}
                />
            </div>
            </div>
          <div className="row">
            <div className="col-sm-5">
              <PhoneFormat error={(errors.Phone && errors.Phone.error) ? errors.Phone.error : false} name="Office Phone" required={true}
                value={values.Phone}
                handleFieldValue={handleChange('Phone')} label="Office Phone" helperText={(errors.Phone && errors.Phone.error) ? errors.Phone.errortext : ""} />
            </div>
            <div className="col-sm-5">
              <PhoneFormat error={(errors.SecondaryPhone && errors.SecondaryPhone.error) ? errors.SecondaryPhone.error : false} name="Mobile Phone"
                value={values.SecondaryPhone}
                handleFieldValue={handleChange('SecondaryPhone')} label="Mobile Phone" helperText={(errors.SecondaryPhone && errors.SecondaryPhone.error) ? errors.SecondaryPhone.errortext : ""} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5">
              <PhoneFormat error={(errors.HomePhone && errors.HomePhone.error) ? errors.HomePhone.error : false} name="Home Phone"
                value={values.HomePhone}
                handleFieldValue={handleChange('HomePhone')} label="Home Phone" helperText={(errors.HomePhone && errors.HomePhone.error) ? errors.HomePhone.errortext : ""} />
            </div>
            <div className="col-sm-5">
              <PhoneFormat error={(errors.Fax && errors.Fax.error) ? errors.Fax.error : false} name="Fax"
                value={values.Fax}
                handleFieldValue={handleChange('Fax')} label="Fax/Others" helperText={(errors.Fax && errors.Fax.error) ? errors.Fax.errortext : ""} />
            </div>
          </div>
        </Fragment>
        {hidesubmodules && <ModuleSelection
          nextStep={this.props.nextStep}
          handleChange={this.props.handleChange}
          moduleChecked={this.props.moduleChecked}
          values={values}
          prevStep={this.props.prevStep}
          onClose={this.props.onClose}
        />}

        <div className="row">
          <MobileStepper className={classes.root} variant="dots" steps={4} position="static" activeStep={this.state.activeStep}
            nextButton={<Button size="small" onClick={this.continue} >                Next                  {<KeyboardArrowRight />}              </Button>}
            backButton={<Button size="small" onClick={this.back} disabled>                {<KeyboardArrowLeft />}                  Back                </Button>}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CompanyDetails);
