/**
 * Determines if this is a Rule or RuleGroup
 * @param {RuleType|RuleGroupType} ruleOrGroup
 * @returns {boolean}
 */
const isCalculated = (ruleOrGroup) => {
    return !!(ruleOrGroup.calculate);
  };
  
  export default isCalculated;
  