/**
      *********************************************************
      Description: Label component.
      Required props:   value.
      limitations    : N/A
      *********************************************************
**/
import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormControl } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const CLabel = (props) => {
    const { defaultValue, value, error, helperText, name, id, edit, tooltip, options, conditions, disabled, accessPermissions,accessLevelPermissions, ...otherProps } = props
    const { label } = otherProps
    const val = ((value === null) || (value === undefined)) ? '' : value
    return <Fragment>
        <FormControl error={error || false}>
            <Fragment>
                {tooltip &&
                    < div>
                        <Typography variant={'caption'} className="fw-bold">{label}    < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                            <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
                        </Tooltip> </Typography>
                        <div variant={'body2'} className="text-secondary" color={'textPrimary'}>{val.split("/n").map((line, idx) => <p key={idx}>{line}</p>)}   </div>
                    </div>}
                {!tooltip && < div >
                    <Typography variant={'caption'} className="fw-bold">{label}
                    </Typography>
                    <div variant={'body2'} className="text-secondary" color={'textPrimary'}>{val.split("/n").map((line, idx) => <p key={idx}>{line}</p>)}   </div>
                </div>}
            </Fragment>
            {error && <FormHelperText id="component-error-text" color={'secondary'}>{helperText}</FormHelperText>}
        </FormControl >
    </Fragment >
}
export default CLabel;
