import React, { Component } from 'react';
import OtpInput from 'react-otp-input';

export default class Otp extends Component {

    handleChange = (otp) => {
        const { handleFieldChange, name } = this.props
        let events = { target: { value: otp, name: name, checked: false } }
        handleFieldChange(events);
    }

    render() {
        const { value } = this.props
        return (
            <div>
                <OtpInput
                    value={value}
                    onChange={this.handleChange}
                    numInputs={6}
                    separator={<span>-</span>}
                    inputStyle={{ width: '3em', height: '3em' }}
                />
            </div>
        );
    }
}