import React, { Fragment } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Box from '@material-ui/core/Box';
import DefaultSettings from './settings';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTypography-body1": {
            //color: 'rgba(0, 0, 0, 0.54)',
            fontSize: 12,
            fontWeight: 'bold'
        },
        "& .MuiSvgIcon-root": {
            height: '1 rem'
        },


    }
}));


const defaults = {
    transformProps: {
        label: 'Name',
        value: 'Value'
    }
}

export default function CheckBox(props) {
    const { value, handleFieldValue, extraProps, shareProps, tooltip, contexthelp, required, className, disabled, error, ...otherProps } = props
    const { name, label, helperText, items, fullWidth, ...hiddenProps } = otherProps
    let { transformProps } = extraProps || {}
    transformProps = transformProps || defaults.transformProps
    let selecteditem = []
    let fieldval = (value === 'Y' || value === true) ? true : false;
    let fieldname = value ? (transformProps ? transformProps[value] : value) : false
    if (items && items.length > 0) {
        selecteditem = items && (items || []).filter((each) => {
            return (each[transformProps.value]) === value
        })
        fieldval = selecteditem && selecteditem.length > 0 && selecteditem[0].value || 'N'
        fieldname = selecteditem && selecteditem.length > 0 && selecteditem[0].name || false
    }
    const classes = useStyles();
    let tlabel = (tooltip || contexthelp) ? <div style={{ fontWeight: 'bold', color: props.errors ? 'red' : disabled ? disabled : 'black', fontSize: '0.8rem' }}> {label}&nbsp; < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
        <HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#007bff' }} onClick={(evt) => contexthelp ?
            DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
    </Tooltip> </div> : label;
    return (
        <Box className={className}>
            <FormControl fullWidth={fullWidth ? fullWidth : false} error={error} required={required} className={classes.root}>
                <FormGroup >
                    <FormControlLabel
                        control={<Checkbox
                            value={fieldval}
                            checked={selecteditem && selecteditem.length > 0 ? (fieldname === 'true' || ((fieldname === 'Yes') ? true : false)) :
                                (fieldname || ((fieldval === "N" || fieldval === false) ? false : true))}
                            onChange={(evt) => {
                                handleFieldValue({ target: { value: evt.target.value, name: name, checked: evt.target.checked }, currentTarget: { dataset: null } })
                            }}
                            name={name}
                            color={disabled ? '' : 'primary'}
                            className={classes.root}
                        />
                        }
                        style={{
                            //color: 'rgba(0, 0, 0, 0.54)',
                            fontSize: 12
                        }}
                        label={tlabel}
                        className={classes.root}
                        disabled={disabled}
                        {...hiddenProps}
                    />

                </FormGroup >
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </Box>
    )
}