import MomentUtils from '@date-io/moment';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import FormHeader from '../../core/modules/form/cformheader';
import { snackbarMessage } from '../../core/actions';
import { GetData, PostData, PutData } from '../../core/controls/helpers/requests';
import { getEnvVariable } from '../environmentalList';
import CFormDropDown from '../../core/modules/form/cformdropdown';
import CFormTextBox from '../../core/modules/form/cformtextbox';
import { globalvalidate as GlobalValidate } from '../../core/modules/form/validations';
import Template from '../../core/modules/template/template';
import historygrid from '../../json/common/notices/noticehistorygrid';
import TextBox from '../../core/controls/components/textbox';
import { Button } from '@material-ui/core';

const transformProps = {
    "value": "Id",
    "name": "Name",
    "key": "Id"
}

const addbreadcrumbs = [
    { "link": "Notice", "materialicon": "chrome_reader_mode", className: 'eps-breadcrumb-item', class: 'text-light' }
]
const editbreadcrumbs = [
    { "link": "Notice", appendval: 'NoticeTypeIdDesc', "materialicon": "chrome_reader_mode", className: 'eps-breadcrumb-item', class: 'text-light' }
]

const styles = theme => ({
    paperAnchorRight: {
        top: 'auto',
        maxHeight: '100%',
        width: '70%',
        maxWidth: '70%',
    },
    root: {
        width: '70%',
        maxWidth: '70%',
    }
});

class NoticeCommon extends Component {

    constructor(props) {
        super(props);
        this.state = {
            noticeTypeJson: [],
            results: { TemplateId: 0, NoticeTypeId: this.props.noticetypeid, NoticeDate: moment().format('MM/DD/YYYY'), CertifiedMailNo: "", NoticeDescription: "" },
            showTemplate: false,
            TemplateId: 0,
            ParentId: 0,
            NoticeTypeId: this.props.noticetypeid || '',
            updateRecord: this.props.updateRecord,
            noticeEntryData: [],
            NoticeDate: moment().format('MM/DD/YYYY'),
            CertifiedMailNo: "",
            NoticeDescription: "",
            ProjectMessages: [],
            errors: {},
            SendToOwner: false,
            tabvalue: 0,
            EmailModel: { Subject: '', Body: '', MailTo: [], TemplateList: [] },
            open: this.props.open || true,
            sideBarTablesRecords: [],
            sideBarTables: [],
            openSendNotice: false,
            sendNoticeObj: {}
        }
        this.masterTableId = this.props.recordid || this.props.match.params.routeid1// ApplicationId, PermitId, CompanyId
        this.sitePermitTypeId = this.props.sitepermittypeid || this.props.match.params.routeid
        this.fieldValues = {};
        if (this.state.updateRecord) {
            this.noticeComponentsArray = { ...historygrid };
            this.historygridjson = this.noticeComponentsArray.options
        }

        this.url1 = getEnvVariable('Admin') + `/api/NoticeTypes/All/${this.props.sitepermittypeid || this.props.match.params.routeid}`
        this.url2 = this.props.dataItem && this.props.dataItem.Id ? getEnvVariable('Application') + `/api/Notices/${this.props.recordid}/Notice/${this.props.Id}` :
            getEnvVariable('Application') + `/api/Notices/${this.props.recordid}/Notice/${this.props.routeid2}`
        this.url3 = this.props.dataItem && this.props.dataItem.Id ? getEnvVariable('Application') + `/api/Notices/${this.props.recordid}/Update/${this.props.Id}` :
            getEnvVariable('Application') + `/api/Notices/${this.props.recordid}/Update/${this.props.routeid2}`
        this.url4 = getEnvVariable('Application') + `/api/Notices/${this.props.recordid || this.props.match.params.routeid1}/Add`
        this.url5 = getEnvVariable('Application') + `/api/Application/FieldValues`;
        this.noticeporjectmessage = getEnvVariable('Application') + `/api/ProjectMessages/RulesByName?name=modulemaster.notices`;
        this.sidebartablesurl = getEnvVariable('Application') + `/api/SubModuleSideBar/All`
        this.TemplateForm = React.createRef()
    }



    handleFieldChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        if (name === 'NoticeTypeId') {
            let noticeType = this.state.noticeTypeJson.find(x => x.Id === value)
            let sdtables = noticeType.SideBarTables ? noticeType.SideBarTables.split(',') : [];
            let sdtablesfilter = this.state.sideBarTablesRecords.filter(a => sdtables.includes(a.id.toString()));
            let sdtablesloadmap = sdtablesfilter ? sdtablesfilter.map(a => (a.name)) : [];

            this.setState({
                [name]: value,
                ParentId: noticeType.TemplateId,
                showTemplate: true,
                sideBarTables: sdtablesloadmap,
                results: {
                    ...this.state.results,
                    [name]: value
                }
            }, () => {
                this.loadNoticeEntryData(this.state.sideBarTables)
                this.validateFieldChange(name, value)
            })
        }
        else if (name === 'NoticeDate') {
            this.setState({
                [name]: moment(value).format('MM/DD/YYYY'),
                results: {
                    ...this.state.results,
                    [name]: value
                }
            }, () => this.validateFieldChange(name, value))
        }
        else {
            this.setState({
                [name]: value,
                results: {
                    ...this.state.results,
                    [name]: value
                }
            }, () => this.validateFieldChange(name, value))
        }
    }


    handleNoticeDateChange = (e) => {
        let convertMoment = e ? moment(e, 'MM/DD/YYYY').format('MM/DD/YYYY') : null
        this.setState({
            "NoticeDate": convertMoment,
            results: {
                ...this.state.results,
                "NoticeDate": convertMoment
            }
        }, () => this.validateFieldChange("NoticeDate", convertMoment))
    }

    componentDidMount() {
        this.didmountinitialload();
    }


    didmountinitialload() {
        this.fetchData('GET', this.noticeporjectmessage, null, null).then(data => this.setState({
            ProjectMessages: data
        }))

        this.fetchData('GET', this.url1, null, null).then(data => this.setState({
            noticeTypeJson: data, ParentId: data.find(x => x.Id == this.props.noticetypeid).TemplateId, NoticeTypeId: this.props.noticetypeid
        }))
        let SideBarDataItems = {};
        this.props.sideBarItems && this.props.sideBarItems.length > 0 && this.props.sideBarItems.forEach(element => {
            SideBarDataItems[element] = this.props.childTable.Id
        })
        this.fetchData('POST', this.url5, null, {
            'sitePermitTypeId': this.props.sitepermittypeid,
            'masterTableId': this.props.recordid,
            'SideBarDataItems': SideBarDataItems,
            'sideBarItems': this.props.sideBarItems || [],
            'Values': this.props.ParentValues || '',
            'Info': (this.props.ParentValues && this.props.ParentValues['AvoidSaveRecord'] && this.props.ParentValues['AvoidSaveRecord']) !== 'Y' ? (this.props.ParentTableName || '') : {}
        }, true).then(data => {
            this.setState({ noticeEntryData: data });
        })

        // this.fetchData('GET', this.url5, null, null).then(data => {
        //     this.setState({ noticeEntryData: data });
        // })

        this.fetchData('GET', `${this.sidebartablesurl}`, null, null)
            .then(data => {
                if (data && Array.isArray(data)) {
                    let tabls = data.map(a => ({ id: a.Id, name: a.TableName, value: a.Id }))
                    this.setState({
                        sideBarTablesRecords: tabls
                    })
                }
            })

        if (this.state.updateRecord) {
            this.fetchData('GET', this.url2, null, null)
                .then(data => {
                    this.setState({
                        TemplateId: data.TemplateId,
                        NoticeTypeId: data.NoticeTypeId,
                        NoticeDate: data.NoticeDate,
                        CertifiedMailNo: data.CertifiedMailNo,
                        NoticeDescription: data.NoticeDescription,
                        results: data,
                        showTemplate: true
                    })
                })
        }
    }

    loadNoticeEntryData(tables) {
        let urlcon = "";
        let body = {};
        let SideBarDataItems = {}
        let sideBarItems = tables;
        sideBarItems.forEach(element => {
            urlcon = urlcon + 'table=' + element + '&';
            if (this.props.childTable)
                SideBarDataItems[element] = this.props.childTable.Id
        });
        body['sitePermitTypeId'] = this.props.sitepermittypeid;
        body['masterTableId'] = this.props.recordid;
        body['SideBarDataItems'] = SideBarDataItems;
        body['sideBarItems'] = sideBarItems;
        body['Values'] = this.props.ParentValues || '';
        body['Info'] = this.props.ParentValues['AvoidSaveRecord'] === 'Y' ? {} : (this.props.ParentTableName || '');

        let url = this.url5 + "?" + urlcon;
        this.fetchData('POST', url, null, body).then(data => {
            this.setState({ noticeEntryData: data });
        })
    }

    handleSaveWithTemplate = (event) => {
        var isValid = this.validateData(this.state.results);
        if (isValid && this.TemplateForm && this.TemplateForm.current && this.TemplateForm.current.saveTemplate) {
            this.TemplateForm.current.saveTemplate();
        } else {
            this.props.snackbarMessage('Invalid Data, Cannot be saved', 'error')
        }
    }

    handleSave = (event) => {
        if (this.state.updateRecord) {
            this.fetchData('PUT', this.url3, null, this.state.results)
                .then(data => {
                    this.setState({
                        results: data,
                        opensnackbar: true
                    })
                    this.props.onClose();
                })
        }
        else {
            this.fetchData('POST', this.url4, null, this.state.results)
                .then(data => {
                    this.setState({
                        results: data,
                        opensnackbar: true,
                        openSendNotice: this.props.recordid ? true : false
                    })
                })
        }
    }
    handleBack = () => {
        this.props.onClose()
    }

    validateData = (resultdata) => {
        var allErrors = this.validateStateResults(resultdata);
        if (Object.keys(allErrors).length > 0) {
            this.setState({ errors: allErrors })
            return false
        } else {
            this.setState({ errors: {} })
            return true
        }
    }

    validateStateResults = (resultdata) => {
        var allErrors = {};
        for (let [key, value] of Object.entries(resultdata)) {
            var errorJson = {}
            errorJson = { [key]: this.getDBValidationMessages(key) };
            const error = GlobalValidate(errorJson, { [key]: value })
            var isError = (Object.keys(error).length > 0 && error[key]['error']) ? error[key]['error'] : false;
            if (isError) {
                allErrors[key] = { error: true, errortext: error[key]['errortext'] };
            }
        }
        return allErrors;
    }

    validateFieldChange(key, value) {
        var allErrors = this.state.errors;
        var errorJson = {}
        errorJson = { [key]: this.getDBValidationMessages(key) };
        const error = GlobalValidate(errorJson, { [key]: value })
        var isError = (Object.keys(error).length > 0 && error[key]['error']) ? error[key]['error'] : false;
        if (isError) {
            allErrors[key] = { error: true, errortext: error[key]['errortext'] };
        } else {
            delete allErrors[key];
        }
        this.setState({ errors: allErrors });
    }

    getDBValidationMessages(key) {
        var keyinuppercase = key.toUpperCase();
        var validationSearchKey = "_" + keyinuppercase;
        var matchingKeys = this.state.ProjectMessages.filter(f => f['Key'].includes(validationSearchKey));;
        return matchingKeys;
    }

    //This is the template Id
    handleTemplateId = (data) => {
        this.setState({
            TemplateId: data.id,
            ParentId: 0,
            results: {
                ...this.state.results,
                "TemplateId": data.id
            }
        }, () => { this.handleSave(); })
    }

    fetchData(requestType, url, queryParam, body, alert = false) {
        const queryStr = queryParam != null ? `${url}?${queryParam}` : `${url}`
        if (requestType == "GET") {
            return GetData(queryStr);
        }
        else if (requestType == "POST") {
            return PostData(queryStr, body, alert, 'Notice Sent Successfully');
        }
        else if (requestType == "PUT")
            return PutData(queryStr, body);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dataItem != this.props.dataItem) {
            this.setState({ tabvalue: 0 })
            this.url2 = getEnvVariable('Application') + `/api/Notices/${this.props.routeid1}/Notice/${this.props.Id}`
            this.url3 = getEnvVariable('Application') + `/api/Notices/${this.props.routeid1}/Update/${this.props.Id}`
            this.didmountinitialload();
        }
        if (prevState.open !== this.props.open) {
            this.setState({ open: this.props.open, results: { TemplateId: 0, NoticeTypeId: this.props.noticetypeid, NoticeDate: "", CertifiedMailNo: "", NoticeDescription: "" }, }, () => {
                this.didmountinitialload();
            })
        }
    }
    handleClose = () => {
        this.setState({ open: false }, () => {
            this.props && this.props.onClose();
        })
    }

    handleFieldValue = (evt) => {
        const { name, value } = (evt && evt.target) || {};
        this.setState({ sendNoticeObj: { ...this.state.sendNoticeObj, [name]: value } })
    }

    handleNoticeSend = () => {
        const { sendNoticeObj } = this.state
        if (sendNoticeObj && sendNoticeObj.Subject && sendNoticeObj.Body) {
            let compUrl = getEnvVariable('MasterAdmin') + `/api/CompanyInternal/` + getEnvVariable('ModuleKey') + `/` + (this.props && this.props.companyid ? this.props.companyid : this.props.ParentValues && this.props.ParentValues['COMPANY_ID'])
            GetData(compUrl, false).then(resp => {
                if (resp && resp.Contacts && resp.Contacts.PrimaryContactAddress && resp.Contacts.PrimaryContactAddress.Email) {
                    let mailContent = { ...sendNoticeObj, "MailTo": [resp.Contacts.PrimaryContactAddress.Email], "Notices": [this.props.noticetypeid] }
                    let emaiLUrl = getEnvVariable('TemplateAPI') + `/api/Template/SendNotice`;
                    PostData(emaiLUrl, mailContent, true, 'Notice sent to the User via Email').then(res => {
                        this.handleClose()
                    })
                }
            })
        }
        else {
            this.setState({ errorSendNotice: true })
        }
    }

    render() {
        const { compmap, onFullScreen, onExitFullScreen, fullscreen, exitfullscreen, onNextItemClick, onPrevItemClick, classes } = this.props;
        const { NoticeDate, updateRecord, sendNoticeObj, openSendNotice, open, sideBarTables, errorSendNotice } = this.state;
        const caneditfields = true;
        const noticeTypeError = this.state.errors && this.state.errors.NoticeTypeId && this.state.errors.NoticeTypeId.error ? this.state.errors.NoticeTypeId.errortext : null
        const certifiedMailError = this.state.errors && this.state.errors.CertifiedMailNo && this.state.errors.CertifiedMailNo.error ? this.state.errors.CertifiedMailNo.errortext : null
        const noticeDateError = this.state.errors && this.state.errors.NoticeDate && this.state.errors.NoticeDate.error ? this.state.errors.NoticeDate.errortext : null
        const noticeDescriptionError = this.state.errors && this.state.errors.NoticeDescription && this.state.errors.NoticeDescription.error ? this.state.errors.NoticeDescription.errortext : null
        return (
            <Fragment>
                <Drawer
                    open={open}
                    anchor="right"
                    classes={{
                        paperAnchorRight: classes.paperAnchorRight,
                        root: classes.root
                    }}
                    onClose={this.handleClose}>
                    <FormHeader title="Notices" handleSave={this.handleSaveWithTemplate} savebutton={(updateRecord || openSendNotice) ? false : caneditfields}
                        // sendbutton={openSendNotice ? true : false}
                        breadcrumbs={updateRecord ? editbreadcrumbs : addbreadcrumbs} accessLevelPermissions={this.props.accessLevelPermissions}
                        onFullScreen={onFullScreen} onExitFullScreen={onExitFullScreen} fullscreen={fullscreen} exitfullscreen={exitfullscreen}
                        backbutton={true} handleBack={this.handleBack} dataItem={this.props.dataItem} navigateup={updateRecord ? true : false}
                        navigatedown={updateRecord ? true : false} onNextItemClick={onNextItemClick} onPrevItemClick={onPrevItemClick} />
                    {!openSendNotice && <div className="col-12">
                        <div className="row mt-3">
                            <div className="col-md-12">
                                {/* {updateRecord && <FormHeader title="Notices" handleSave={this.handleSaveWithTemplate} sendbutton={caneditfields}
                                 breadcrumbs={editbreadcrumbs} accessLevelPermissions={this.props.accessLevelPermissions} backbutton={true} handleBack={this.handleBack} designprops={true} dataItem={this.props.dataItem} />} */}

                                <CFormDropDown value={this.state.NoticeTypeId} dropdownvalues={this.state.noticeTypeJson} required={true}
                                    name="NoticeTypeId" label="Notice Type" transformProps={transformProps}
                                    handleFieldChange={this.handleFieldChange} disabled={!caneditfields} helperText={noticeTypeError}
                                    error={noticeTypeError ? true : false} edit={false} />

                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        clearable
                                        variant="filled"
                                        ampm={false}
                                        value={((NoticeDate && NoticeDate === '') ? new Date() : NoticeDate) || null}
                                        disablePast={false}
                                        disableFuture={false}
                                        onChange={this.handleNoticeDateChange}
                                        label={"Notice Date"}
                                        name={"NoticeDate"}
                                        format="MM/DD/YYYY"
                                        InputLabelProps={{
                                            shrink: true,
                                            required: true
                                        }} disabled={!caneditfields}
                                        error={noticeDateError ? true : false}
                                        helperText={noticeDateError}
                                    />
                                </MuiPickersUtilsProvider>
                                <CFormTextBox value={this.state.CertifiedMailNo} /*required={true}*/
                                    name="CertifiedMailNo" label="Certified Mail Number"
                                    handleFieldChange={this.handleFieldChange} disabled={!caneditfields} helperText={certifiedMailError} error={certifiedMailError ? true : false} />

                                <CFormTextBox value={this.state.NoticeDescription}
                                    name="NoticeDescription" label="Notice Description"
                                    handleFieldChange={this.handleFieldChange} multiline={true} disabled={!caneditfields} helperText={noticeDescriptionError} error={noticeDescriptionError ? true : false} />
                            </div>
                        </div>
                        <div className="col-4 mt-2" dir="rtl">
                        </div>
                        <div className="col-12 mt-2">
                            {(this.state.noticeEntryData
                                && Array.isArray(this.state.noticeEntryData)
                                && this.state.noticeEntryData.length > 0)
                                && <Template ref={this.TemplateForm} handleTemplateId={this.handleTemplateId}
                                    entryData={this.state.noticeEntryData}
                                    parentid={this.state.ParentId || this.props.recordid}
                                    isAdmin={false}
                                    templateId={this.state.TemplateId}
                                    isEnabled={getEnvVariable('AccessPortal') === 'E' ? false : true}
                                    sideBarTables={sideBarTables}
                                    refId={this.props.noticetypeid}
                                    refType={this.props.refType}
                                />}

                        </div>
                    </div>}
                    {openSendNotice && <div className='m-2'>
                        <small className='text-primary my-3'>Send Notice to the Users</small>
                        <div className='pt-4' />
                        <TextBox className='col-8' name="Subject" label="Subject" required={errorSendNotice && !sendNoticeObj['Subject'] ? true : false}
                            helperText={errorSendNotice && !sendNoticeObj['Subject'] ? 'Subject is Required' : ''} value={sendNoticeObj && sendNoticeObj.Subject} handleFieldValue={(e) => { this.handleFieldValue(e) }} />
                        <TextBox className='col-8' name="Body" label="Body" value={sendNoticeObj && sendNoticeObj.Body}
                            handleFieldValue={(e) => { this.handleFieldValue(e) }} required={errorSendNotice && !sendNoticeObj['Body'] ? true : false}
                            helperText={errorSendNotice && !sendNoticeObj['Body'] ? 'Body is Required' : ''}
                            multiline={true} rowsMax={7} />
                        <div className='pt-4' />
                        <Button variant='contained' color='primary' size='small' onClick={() => { this.handleNoticeSend() }}>Send Notice via Email</Button>

                    </div>}
                </Drawer>
            </Fragment >
        )
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

NoticeCommon = connect(null, mapActions)(NoticeCommon)
NoticeCommon = withStyles(styles)(NoticeCommon)
export default withRouter(NoticeCommon)