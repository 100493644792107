import React, { Component, Fragment, useEffect, useState } from 'react';
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import capitalize from 'lodash/capitalize';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Request from '../REST/groups';

const URL = 'parameter'

const InitialConfig = MaterialConfig;

// You need to provide your own config. See below 'Config format'
//const schema = person.schema;


export const getConfig = (json, result, type = '!group') => {
    if (json.type === 'object') {
        const { properties } = json;
        Object.keys(properties).forEach(e => {
            if (properties[e].type === 'object') {
                result[e] = { label: capitalize(e), type: type, subfields: getConfig(properties[e], {}) }
            }
            if (properties[e].type === 'string') {
                result[e] = { label: capitalize(e), type: Array.isArray(properties[e].enum) ? 'select' : 'text', valueSources: ['value'], fieldSettings: {} }
                if (Array.isArray(properties[e].enum)) {
                    result[e]['fieldSettings']['listValues'] = properties[e].enum.map(e => {
                        return { title: e, value: e }
                    })
                }
                if (properties[e].format === 'date' || properties[e].format === 'datetime')
                    result[e]['type'] = properties[e].format
            }
            if (properties[e].type === 'date')
                result[e] = { label: capitalize(e), type: 'date', valueSources: ["value"], fieldSettings: { dateFormat: properties[e].format } }
            if (properties[e].type === 'boolean') {
                result[e] = { label: capitalize(e), type: 'boolean', operators: ['equal'], valueSources: ['value'] }
            }
            if (properties[e].type === 'integer' || properties[e].type === 'number') {
                result[e] = { label: capitalize(e), type: 'number', valueSources: ['value'], preferWidgets: ['number'], fieldSettings: {} }
                if (properties[e]['maximum'] !== null || properties[e]['maximum'] !== undefined)
                    result[e]['fieldSettings']['max'] = properties[e]['maximum']
                if (properties[e]['minimum'] !== null || properties[e]['minimum'] !== undefined)
                    result[e]['fieldSettings']['min'] = properties[e]['minimum']
            }
        })
    }
    console.log(result)
    return result;
}
// const fields = getConfig(schema, {});
// console.log(fields)
// const config = {
//     ...InitialConfig,
//     fields: { ...fields }
// };

export const getFieldConfig = (schema) => {
    return {
        ...InitialConfig, fields: { ...getConfig(schema, {}) }
    }
}
// You can load query value from your backend storage (for saving see `Query.onChange()`)
const queryValue = { "id": QbUtils.uuid(), "type": "group" };


export class QueryBuilder extends Component {

    constructor(props) {
        super(props);
        let config = getFieldConfig(this.props.schema);
        this.state = {
            tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
            config: config
        };
    }

    componentDidMount() {
        let config = getFieldConfig(this.props.schema);
        this.setState({ tree: QbUtils.checkTree(QbUtils.loadTree(this.props.query), config) }, () => {
            console.log(QbUtils.jsonLogicFormat(this.state.tree, config), this.state.tree)
        })
    }

    componentDidUpdate(prevProps) {
        const prev = JSON.stringify(prevProps.query);
        const curr = JSON.stringify(this.props.query);
        let config = getFieldConfig(this.props.schema);
        if (prev !== curr)
            this.setState({ tree: QbUtils.checkTree(QbUtils.loadTree(this.props.query), config) })
    }

    render = () => {
        let config = getFieldConfig(this.props.schema);
        return <Query
            {...config}
            value={this.state.tree}
            onChange={this.onChange}
            renderBuilder={this.renderBuilder}
        />
    }

    renderBuilder = (props) => (
        <div className="query-builder-container" style={{ padding: '10px' }}>
            <div className="query-builder qb-lite">
                <Builder {...props} />
            </div>

        </div>
    )

    renderResult = ({ tree: immutableTree, config }) => {
        console.log(immutableTree)
        return (
            <div className="query-builder-result">
                <div>Query string: <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre></div>
                <div>MongoDb query: <pre>{JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}</pre></div>
                <div>SQL where: <pre>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre></div>
                <div>JsonLogic: <pre>{JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}</pre></div>
            </div>)
    }

    onChange = (immutableTree, config) => {
        // Tip: for better performance you can apply `throttle` - see `examples/demo`
        // this.setState({ tree: immutableTree, config: config });
        const jsonTree = QbUtils.getTree(immutableTree);
        console.log(jsonTree);
        this.props.onChange(jsonTree)
        // `jsonTree` can be saved to backend, and later loaded to `queryValue`
    }
}


export default function QueryConfig({ schema, id, group }) {
    const [data, setData] = useState({ name: '', key: '', default: '', conditions: [] });

    useEffect(() => {
        if (id !== -1) {
            Request(URL + '/data').getById(id).then(resp => {
                if (resp.data)
                    setData({ name: resp.data.description, key: resp.data.name, default: resp.data.value, conditions: JSON.parse(resp.data.conditions) })
            })
        }
    }, [id])

    const handleChage = (key, value) => {
        setData({ ...data, [key]: value })
    }

    const handleValue = (idx, value, query) => {
        const config = { ...data };
        config.conditions[idx] = { value, query }
        setData(config)
    }

    const addCondition = () => {
        const config = { ...data };
        config.conditions = config.conditions.concat([{ query: queryValue, value: '' }])
        setData(config)
    }

    const deleteCondition = (idx) => {
        const config = { ...data };
        config.conditions = config.conditions.filter((e, i) => i !== idx)
        setData(config)
    }

    const saveConfig = () => {
        const entity = {
            name: data.key,
            description: data.name,
            value: data.default,
            conditions: JSON.stringify(data.conditions),
            groupId: group
        }
        if (id && id !== -1 && id !== '-1')
            Request(URL).update({ ...entity, id }).then(resp => {

            })
        else
            Request(URL).add(entity).then(resp => {

            })
    }

    return <Fragment>
        <h4 className='lead'>{data.name || 'Create Parameters'}</h4>
        <div className='row'>
            <div className='col'>
                <TextField size='medium' onChange={(e) => handleChage('name', e.target.value)} value={data.name} label='Item Description' />
            </div>
            <div className='col'>
                <TextField onChange={(e) => handleChage('key', e.target.value)} value={data.key} label='Item Key' />
            </div>
            <div className='col'>
                <TextField onChange={(e) => handleChage('default', e.target.value)} value={data.default} label='Default Value' />
            </div>
            <div className='col pt-3'>
                <Button variant='outlined' onClick={addCondition}>Add Condition</Button>
            </div>
            {data.conditions.map((e, idx) => {
                return <div key={idx} className='col-12'>
                    <QueryBuilder query={e.query} onChange={q => handleValue(idx, e.value, q)} schema={schema} />
                    <div className='row'>
                        <div className='col-8'>
                            <TextField margin='normal' onChange={evt => handleValue(idx, evt.target.value, e.query)} value={e.value} label='Condition Value' />
                        </div><div className='col-4 pt-3'>
                            <Button onClick={() => deleteCondition(idx)} size='small' color='secondary' variant='outlined'>Delete</Button>
                        </div>
                    </div>
                </div>
            })}

            <div className='col-12 pt-3'>
                <Button variant='outlined' onClick={saveConfig}>Save Parameters</Button>
            </div>
        </div>
    </Fragment >
}



