import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { loadModules } from 'esri-loader';
import { CenterAndZoom, QueryTask, QueryTaskBufferWithGeom } from '../util/queryTask';
import loadingimg from '../../../resources/gis-loading.gif'
import { GeometryProjectionTask } from '../util/geometryEngineTask';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

let highlight, editFeature;
let grid;
let queryResponse;
class CActionsLayerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: this.props.view,
      spatialReference: this.props.spatialReference,
      tabValue: 0,
      selectedLayer: null,
      setuplayer: null,
      selectedLayerKey: 0,
      distance: 1,
      units: 'miles'
    }
    this.startup(
      this.props
    );
  }

  listItemCreated = (event) => {
    let item = event.item;
    if (!item.title || item.title === "" || item.title === null || item.title === undefined || item.title === "Untitled layer") {
      item.layer.listMode = "hide";

    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.layerFilterQuery !== this.props.layerFilterQuery) {
      console.log(this.props.layerFilterQuery)
      this.applyLayerFilter(this.ActiveLayer, this.props.layerFilterQuery, this.props.view.extent.center)
    }
  }

  startup = (config) => {
    loadModules([
      "esri/core/watchUtils",
      "esri/widgets/Expand",
      "esri/widgets/LayerList",
      "esri/layers/FeatureLayer"
    ]).then(([watchUtils, Expand, LayerList, FeatureLayer]) => {
      // Disable popup
      //config.view.popup.autoOpenEnabled = false;
      config.view.map.layers.forEach(function (item) {
        if (!item.title || item.title === "" || item.title === null || item.title === undefined || item.title === "Untitled layer") {
          item.layer.listMode = "hide";
        }
      })

      const layerList = new LayerList({
        view: config.view,
        layers: config.view.map.layers || [],
        listItemCreatedFunction: (evt) => this.getLayerPanelActions(evt, this)
      });
      this.layerListcontainer = ReactDOM.findDOMNode(this);
      const layerListExpand = new Expand({
        collapseTooltip: "Collapse Layers List",
        expandTooltip: "Expand Layers List",
        view: config.view,
        content: layerList,
        container: this.layerListcontainer
      });
      config.view.ui.add(layerListExpand, "top-right");

      const inThis = this;
      watchUtils.pausable(layerListExpand, "expanded", function (newValue, oldValue) {
        inThis.props.onexpanded(layerListExpand, newValue);
        // Hide the form and show the info div
        if (newValue && newValue === true) {
          // if(document.getElementById("actionsLayerListupdate"))
          //     document.getElementById("actionsLayerListupdate").classList.add("esri-hidden");
          // if(document.getElementById("actionLayerTabsDiv"))
          //     document.getElementById("actionLayerTabsDiv").classList.remove("esri-hidden");
        } else {
          // if(document.getElementById("actionLayerTabsDiv"))
          //   document.getElementById("actionLayerTabsDiv").classList.add("esri-hidden");
        }
      });
      this.loadEditFeatures();

    });
  }

  triggerAction(action, config, visibleLayerid) {
    // The layer visible in the view at the time of the trigger.
    let visibleLayer = config.view.map.layers.find(function (layer) {
      if (layer.id === visibleLayerid) {
        return layer.id === visibleLayerid;
      }
    });
    this.ActiveLayer = visibleLayer;
    if (visibleLayer) {
      // Capture the action id.
      if (action === "full-extent") {
        this.getLayerFullExtent(config, visibleLayer).then(queryExtentRes => {
          console.log(queryExtentRes);
          config.view.goTo(queryExtentRes.extent).then(function (resp) {// Layer extent
          }).catch(function (error) {
            console.log(error);
          });
        })
        // visibleLayer.when(function () {
        //   return visibleLayer.queryExtent();
        // }).then(function (response) {
        //   if (response.extent) {
        //     let modExtent = response.extent;
        //     // GeometryProjectionTask(config,modExtent).then(projres =>{
        //     //   console.log(projres);
        //     // })
        //     //modExtent.spatialReference.latestWkid = config.view.extent.spatialReference.latestWkid
        //     //modExtent.spatialReference.wkid = config.view.extent.spatialReference.wkid
        //     // //config.view.extent = modExtent
        //     config.view.goTo(modExtent).then(function (resp) {// Layer extent
        //     }).catch(function (error) {
        //       console.log(error);
        //     });
        //   }
        // });

      } else if (action === "information") {
        // if the information action is triggered, then  open the item details page of the service layer
        window.open(visibleLayer.url);
      } else if (action === "increase-opacity") {
        // if the increase-opacity action is triggered, then increase the opacity of the GroupLayer by 0.25
        if (visibleLayer.opacity < 1) {
          visibleLayer.opacity += 0.25;
        }
      } else if (action === "decrease-opacity") {
        // if the decrease-opacity action is triggered, then decrease the opacity of the GroupLayer by 0.25
        if (visibleLayer.opacity > 0) {
          visibleLayer.opacity -= 0.25;
        }
      }
      else if (action === "layer-filter") {
        this.props.layerFilter(visibleLayer.id);
      }
      else if (action === "Labels-On/Off") {
        // if Labels-On/Off is on and off labels
        if (visibleLayer.labelsVisible) {
          visibleLayer.labelsVisible = false;
        } else
          visibleLayer.labelsVisible = true;
      }
    }
  }

  getLayerFullExtent(config, visibleLayer) {
    return new Promise((resolve, reject) => {
      loadModules([
        "esri/geometry/geometryEngine",
        "esri/geometry/projection",
        "esri/tasks/support/Query",
        "esri/tasks/QueryTask"
      ]).then(([geometryEngine, projection, Query, QueryTask]) => {
        visibleLayer.when(function () {
          var query = new Query();
          query.where = visibleLayer.definitionExpression || "1=1";  //Filter where condtion
          query.outSpatialReference = config.spatialReference;
          return visibleLayer.queryExtent(query);
        }).then(function (response) {
          resolve(response)
        })
      })
    })
  }

  applyLayerFilter = (layer, layerfilter, geometry) => {
    if (layer) {
      let queryExpression = layerfilter.query;
      const defaultExpression = layer.LayerOptions.defaultExpression || "1=1"
      let searchOptions = {}
      if (layerfilter.Asofdate) {//filter by Date
        console.log(layerfilter.Asofdate)
        const { AsofArchiveStart, AsofArchiveEnd } = layer.LayerOptions
        const asofqueryExpression = "(" + AsofArchiveStart + "<='" + layerfilter.Asofdate + "' AND " + AsofArchiveEnd + ">'" + layerfilter.Asofdate + "')"
        queryExpression = (!queryExpression || queryExpression == "()" || queryExpression == "1=1") ? (asofqueryExpression) : (("(" + queryExpression + ")") + " and " + asofqueryExpression)
      }
      if (queryExpression || defaultExpression) {//filter by value
        queryExpression = (!queryExpression || queryExpression == "()" || queryExpression == "1=1") ? (defaultExpression) : (("(" + defaultExpression + ")") + " and " + queryExpression);
      }
      if (layerfilter.Distance && layerfilter.Units) {//filter by Distance
        searchOptions.queryexpression = queryExpression
        searchOptions.geometry = geometry;
        searchOptions.distance = layerfilter.Distance;
        searchOptions.units = layerfilter.Units;
        this.geometrySearch(layer, searchOptions)
        console.log(layerfilter.Distance + " " + layerfilter.Units)
      }
      else if (queryExpression) {//filter by value
        layer.definitionExpression = queryExpression;
      }
    }
  }

  geometrySearch = (activeLayer, searchOptions) => {
    QueryTaskBufferWithGeom(activeLayer.LayerOptions, searchOptions.queryexpression, this.state.spatialReference, searchOptions.geometry, searchOptions.distance, searchOptions.units).then(
      response => {
        if (response && response.features && response.features.length > 0) {
          this.props.view.whenLayerView(activeLayer).then(function (layerView) {
            let record = response.features[0].attributes
            let objidkey = Object.keys(record).find(k => k === "OBJECTID")
            if (!objidkey)
              objidkey = Object.keys(record).find(k => k === "objectId")
            let geomSearchHighlight = response.features.map(function (item) {
              return item.attributes[objidkey]
            });
            if (geomSearchHighlight && geomSearchHighlight.length > 0)
              activeLayer.definitionExpression = objidkey + " in (" + geomSearchHighlight.join(",") + ")";
          });
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  /***********************************************************/

  getLayerPanelActions(event, inthis) {
    let item = event.item;
    if (inthis.props.options.AllFeatureLayerListOptions) {
      let layerConfig = inthis.props.options.AllFeatureLayerListOptions.filter(a => {
        return a.id.toString() === item.layer.id.toString()
      });
      if (layerConfig && layerConfig[0]) {
        inthis.setState({ setuplayer: layerConfig[0], selectedLayerKey: item.layer.id }, () => {
          let hasBuffer = (layerConfig[0].GridTemplate != null && layerConfig[0].GridTemplate.length > 0) ? true : false;
          let hasFilter = (layerConfig[0].CanFilter != null && layerConfig[0].CanFilter == "Y") ? true : false;
          inthis.panelAction(event, inthis, hasBuffer, hasFilter, layerConfig[0]);
        })
      }
    }
  }

  panelAction(event, inthis, hasBuffer, hasFilter, callayer) {
    let item = event.item;
    if (!item.title || item.title === "" || item.title === null || item.title === undefined || item.title === "Untitled layer") {
      item.layer.listMode = "hide";
    }
    let content = document.getElementById("actionLayerTabsDiv");
    let divElement = document.createElement('div');
    divElement.className = "eachActionLayer";
    divElement.innerHTML = content.innerHTML;
    let allelem = divElement.getElementsByClassName('action-click');
    let labelsele = divElement.getElementsByClassName('esri-layer-list-labels');
    if (labelsele && item.layer.labelingInfo && item.layer.labelingInfo.length > 0 && item.layer.labelingInfo[0] && item.layer.labelingInfo[0].labelExpressionInfo && item.layer.labelingInfo[0].labelExpressionInfo.expression) {
      labelsele[0].style.display = "";
    }
    if (allelem && allelem.length > 0) {
      for (let i = 0; i < allelem.length; i++) {
        let arealabeled = allelem[i].getAttribute("aria-label")
        allelem[i].onclick = () => inthis.triggerAction(arealabeled, inthis.props, item.layer.id);
      }
    }
    let tabchangerdom = divElement.getElementsByClassName('action-anttabs');
    if (tabchangerdom && tabchangerdom.length > 0) {
      for (let i = 0; i < tabchangerdom.length; i++) {
        let arealabeled = tabchangerdom[i].getAttribute("aria-label");
        tabchangerdom[i].onclick = (evt) => inthis.openTab(evt, arealabeled, divElement, callayer)
      }
    }
    if (!hasFilter) {
      divElement.getElementsByClassName('action-anttabs-filter')[0].remove();
    }
    if (!hasBuffer) {
      divElement.getElementsByClassName('action-anttabs-buffer')[0].remove();
    } else {
      //  //Add div element to the grid
      let gridDiv = divElement.getElementsByClassName("gridDisplay")[0];
      let gridId = "grid" + callayer.id
      gridDiv.innerHTML = '<div id="' + gridId + '"></div>'
      inthis.addBufferEvents(inthis, divElement, callayer)
    }
    item.panel = {
      content: divElement,
      className: "esri-icon-configure-popup",
      open: item.visible
    };

  }

  addBufferEvents(inthis, domElem, callayer) {
    try {
      var bufferdoms = domElem.getElementsByClassName("buffersearch");
      if (bufferdoms && bufferdoms.length > 0) {
        for (let i = 0; i < bufferdoms.length; i++) {
          bufferdoms[i].onclick = () => inthis.bufferSearch(inthis, domElem, callayer)
        }
      }
      var selecttaxlotsdoms = domElem.getElementsByClassName("selecttaxlots");
      if (selecttaxlotsdoms && selecttaxlotsdoms.length > 0) {
        for (let i = 0; i < selecttaxlotsdoms.length; i++) {
          selecttaxlotsdoms[i].onclick = () => inthis.selectTaxlotWithinBuffer(inthis, domElem, callayer)
        }
      }

    } catch (ex) {
      console.log('unable to add event listener', ex)
    }
  }

  updateState(event, inthis) {
    let name = event.target.name
    let val = event.target.value
    inthis.setState({ [name]: val })
  }

  bufferSearchStartup(inThis, divElement, callayer) {
    loadModules([
      "dgrid/OnDemandGrid",
      "dgrid/extensions/ColumnHider",
      "dgrid/Selection",
      'dojo/domReady!'
    ]).then(([OnDemandGrid, ColumnHider, Selection]) => {

      let gridid = "grid" + callayer.id;
      //document.getElementById("buffererror").innerHTML = ""
      //document.getElementById("featureCount").innerHTML = ""
      let columns = callayer && callayer.GridTemplate ? callayer.GridTemplate : []

      // create a new onDemandGrid with its selection and columnhider extensions. Set the columns of the grid to display attribute
      grid = new (OnDemandGrid.createSubclass([Selection, ColumnHider]))(
        { columns: columns },
        gridid
      );
      // add a row-click listener on the grid. This will be used to highlight the corresponding feature on the view
      grid.on("dgrid-select", (event) => inThis.selectFeatureFromGrid(event, inThis));
    });
  }

  selectFeatureFromGrid(event, inthis) {
    const firstRecord = queryResponse.features[0];
    if (firstRecord) {
      const geom = firstRecord.geometry
      CenterAndZoom(inthis.props.view, geom, 15);
    }
  }

  bufferSearch = (inthis, domElem, callayer) => {
    domElem.getElementsByClassName('loadingImg')[0].style.display = 'block';
    domElem.getElementsByClassName('gridSucessInfo')[0].innerHTML = '';

    let searchTaskURL = inthis.props.options.LayerOptions[0].url ? inthis.props.options.LayerOptions[0].url : '';
    let pretaxlotdata = ""
    let objIndex = inthis.props.options.FieldsData.findIndex((obj => obj.Fieldkey === "TAX_PARCEL_IDS"));
    if (objIndex > 0) {
      pretaxlotdata = this.props.options.FieldsData[objIndex].FieldValue
    }
    let parcelIdarra = pretaxlotdata.split(",");
    if (parcelIdarra && parcelIdarra.length > 0 && parcelIdarra[0] != "" && inthis.state.distance > 0) {
      let queryTaxlotsString = ''
      for (var i = 0; i < parcelIdarra.length; i++) {
        if (queryTaxlotsString === "") {
          if (parcelIdarra[i] && parcelIdarra[i] != null)
            queryTaxlotsString = "'" + parcelIdarra[i] + "'";
        } else {
          if (parcelIdarra[i] && parcelIdarra[i] != null)
            queryTaxlotsString = queryTaxlotsString + ",'" + parcelIdarra[i] + "'";
        }
      }
      let querycondition = "PIN in (" + queryTaxlotsString + ")";
      let queryTaskConfigTaxLot = {
        url: searchTaskURL
      }
      let bufferCalcLayerUrl = (callayer && callayer.url) ? callayer.url : searchTaskURL;
      let queryTaskConfigRef = {
        url: bufferCalcLayerUrl
      }
      console.log('bufferSearch----QueryTask');
      QueryTask(queryTaskConfigTaxLot, querycondition, 4326).then(
        response => {
          if (response && response.features && response.features.length > 0) {
            let geom = response.features[0].geometry
            QueryTaskBufferWithGeom(queryTaskConfigRef, "1=1", this.props.options.viewProperties.extent.spatialReference, geom, inthis.state.distance, inthis.state.units).then(
              response => {
                if (response && response.features && response.features.length > 0) {
                  inthis.processResponse(response)
                  domElem.getElementsByClassName('loadingImg')[0].style.display = 'none';
                  domElem.getElementsByClassName('gridSucessInfo')[0].innerHTML =
                    "<b>Search revealed " +
                    response.features.length.toString() +
                    " records </b>";
                }
              },
              error => {
                console.log(error);
              }
            )
          }
        })
    } else {
      // Show errors and messages
      try {
        domElem.getElementsByClassName('buffererror')[0].innerHTML = 'Select primary taxlot'
        domElem.getElementsByClassName('gridSucessInfo')[0].innerHTML = ''
        domElem.getElementsByClassName('loadingImg')[0].style.display = 'none'
      } catch (ex) { console.log(ex) }
    }
  }



  processResponse(response) {
    queryResponse = response
    let allAttributes = response.features.map(a => a.attributes);
    this.projectGridData(allAttributes)
  }

  projectGridData(data) {
    loadModules([
      "dojo/store/Memory",
      "dstore/legacy/StoreAdapter",
      "dgrid/OnDemandGrid",
      "dgrid/extensions/ColumnHider",
      "dgrid/Selection"
    ]).then(([Memory, StoreAdapter, OnDemandGrid, ColumnHider, Selection]) => {

      const dataStore = new StoreAdapter({
        objectStore: new Memory({
          idProperty: "OBJECTID"
        })
      });
      dataStore.objectStore.data = data;
      grid.set("collection", dataStore);

      // document.getElementById("featureCount").innerHTML =
      //     "<b>Search revealed " +
      //     data.length.toString() +
      //     " records </b>";
    })
  }

  openTab(evt, action, dom, callayer) {
    var i, tabcontent, tablinks;
    tabcontent = dom.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = dom.getElementsByClassName("action-anttabs");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    dom.getElementsByClassName(action)[0].style.display = "block";
    evt.currentTarget.className += " active";
    if (action === 'buffer') {
      this.bufferSearchStartup(this, dom, callayer)
      dom.getElementsByClassName("selectdistance").onchange = (event) => this.updateState(event, this)
      dom.getElementsByClassName("selectunits").onchange = (event) => this.updateState(event, this)
    }
  }

  panelHandleChange = (newValue, layer, domelem) => {
    let value = newValue === 0 ? 1 : 0;
    domelem.tabIndex = value;
    this.setState({ tabValue: value, selectedLayer: layer })
  }



  loadEditFeatures() {
    loadModules([
      "esri/widgets/FeatureForm",
      "esri/layers/FeatureLayer",
      "esri/widgets/FeatureTemplates"
    ]).then(([FeatureForm, FeatureLayer, FeatureTemplates]) => {
      let msgDOM = document.getElementById("actionMessageSuccess");
      if (msgDOM) {
        msgDOM.style.display = "none"
      }
      let inthis = this;
      let featureLayersIds = []
      if (this.props.options && this.props.options.AllFeatureLayerListOptions && this.props.options.AllFeatureLayerListOptions.length > 0) {
        let layersWithEditorConfig = inthis.props.options.AllFeatureLayerListOptions.filter(a => {
          return a.id && a.EditorTemplate != null
        })
        if (layersWithEditorConfig && layersWithEditorConfig.length > 0) {
          featureLayersIds = layersWithEditorConfig.map(a => {
            return a.id
          })
        }
      }

      let featureLayer = this.props.view.map.layers.find(function (layer) {
        return featureLayersIds.includes(parseInt(layer.id));
      });

      if (featureLayer) {
        let fieldConfig = (this.props.options
          && this.props.options.AllFeatureLayerListOptions
          && this.props.options.AllFeatureLayerListOptions[0].EditorTemplate)
          ? this.props.options.AllFeatureLayerListOptions[0].EditorTemplate : this.EPSfeatureLayerFieldConfig()

        // Add a new feature form with grouped fields
        const form = new FeatureForm({
          container: "actionsLayerListform",
          groupDisplay: "sequential", // only display one group at a time
          layer: featureLayer,
          fieldConfig: fieldConfig //|| inthis.EPSfeatureLayerFieldConfig()
        });

        this.props.view.on("click", function (event) {
          // Unselect any currently selected features
          inthis.unselectFeature();

          // Listen for when the user clicks on the view
          inthis.props.view.hitTest(event).then(function (response) {
            // If user selects a feature, select it
            const results = response.results;
            if (
              results.length > 0 &&
              results[0].graphic &&
              results[0].graphic.layer
            ) {

              let selectedFeatureLayer = inthis.props.view.map.layers.find(function (layer) {
                return layer.id === results[0].graphic.layer.id;
              });
              if (selectedFeatureLayer) {
                let selectedFeatureLayerFieldConfig = inthis.props.options.AllFeatureLayerListOptions.filter(a => a.id === results[0].graphic.layer.id)[0].EditorTemplate;
                if (selectedFeatureLayerFieldConfig) {
                  featureLayer = selectedFeatureLayer
                  form.fieldConfig = selectedFeatureLayerFieldConfig
                  let refobjectid = results[0].graphic.attributes[featureLayer.objectIdField]
                  if (refobjectid)
                    inthis.selectFeature(refobjectid, featureLayer, form)
                }
              }

            } else {
              // Hide the form and show the info div
              if (document.getElementById("btnactionsLayerListUpdate")) {
                document.getElementById("actionsLayerListupdate").classList.add("esri-hidden");
              }
            }
          });
        });

        // Listen to the feature form's submit event.
        form.on("submit", (evt) => inthis.featureFormSubmit(featureLayer, form));

        if (document.getElementById("btnactionsLayerListUpdate")) {
          document.getElementById("btnactionsLayerListUpdate").onclick = function () {
            // Fires feature form's submit event.
            form.submit();
          };
        }
        this.props.view.ui.add("actionsLayerListupdate", "top-right");
      }
    })
  }

  // Load Default if any
  EPSfeatureLayerFieldConfig() {
    return null;
  }



  // Function to unselect features
  unselectFeature() {
    if (highlight) {
      highlight.remove();
    }
  }

  // Highlight the clicked feature and display
  // its attributes in the featureform.
  selectFeature(objectId, featureLayer, form) {
    let inthis = this;
    let msgDOM = document.getElementById("actionMessageSuccess");
    if (msgDOM) {
      msgDOM.style.display = "none"
    }
    // query feature from the server
    featureLayer
      .queryFeatures({
        objectIds: [objectId],
        outFields: ["*"],
        returnGeometry: true
      })
      .then(function (results) {
        if (results.features.length > 0) {
          editFeature = results.features[0];

          // display the attributes of selected feature in the form
          form.feature = editFeature;

          // highlight the feature on the view
          inthis.props.view.whenLayerView(editFeature.layer).then(function (layerView) {
            highlight = layerView.highlight(editFeature);
          });

          if (
            document
              .getElementById("actionsLayerListupdate")
              .classList.contains("esri-hidden")
          ) {
            //document.getElementById("actionsLayerListinfo").classList.add("esri-hidden");
            document
              .getElementById("actionsLayerListupdate")
              .classList.remove("esri-hidden");
          }
        }
      });
  }


  featureFormSubmit(featureLayer, form) {
    if (editFeature) {
      // Grab updated attributes from the form.
      const updated = form.getValues();

      // Loop through updated attributes and assign
      // the updated values to feature attributes.
      Object.keys(updated).forEach(function (name) {
        editFeature.attributes[name] = updated[name];
      });

      // Setup the applyEdits parameter with updates.
      const edits = {
        updateFeatures: [editFeature]
      };
      //applyAttributeUpdates(edits);
      this.applyAttributeUpdates(edits, featureLayer, form)
    }
  }


  // Call FeatureLayer.applyEdits() with specified params.
  applyAttributeUpdates(params, featureLayer, form) {
    let inthis = this;
    document.getElementById("btnactionsLayerListUpdate").style.cursor = "progress";
    featureLayer
      .applyEdits(params)
      .then(function (editsResult) {
        // Get the objectId of the newly added feature.
        // Call selectFeature function to highlight the new feature.
        if (editsResult.addFeatureResults.length > 0) {
          const objectId = editsResult.addFeatureResults[0].objectId;
          inthis.selectFeature(objectId, featureLayer, form)
          highlight = null;
          editFeature = null
          //alert('Success!! Attributes added' + objectId)
        } else if (editsResult.updateFeatureResults.length > 0) {
          //alert('Success!! Attributes update')
          document.getElementById("actionMessageSuccess").style.display = ""
        }
        document.getElementById("btnactionsLayerListUpdate").style.cursor = "pointer";
      })
      .catch(function (error) {
        console.error(
          "[ applyEdits ] FAILURE: ",
          error.code,
          error.name,
          error.message,
          error
        );
        console.log("error = ", error);
        document.getElementById("btnactionsLayerListUpdate").style.cursor = "pointer";
      });
  }

  onlayeredit(evt) {
    console.log('can edit', evt)
  }

  bufferCalcLayer() {
    //console.log('bufferCalcLayer', this.state.setuplayer);
    if (this.state.setuplayer)
      return this.state.setuplayer
    else
      return null
    //else
    //return (this.props.options && this.props.options.FeatureLayerListOptions.length > 0) ? this.props.options.FeatureLayerListOptions[0] : {};
  }

  closeEditPanel() {
    let dom = document.getElementById("actionsLayerListupdate")
    if (dom)
      dom.classList.add("esri-hidden");
  }


  render() {

    return (
      <div className="ActionLayerList-box" style={{ order: this.props.order }}>
        <div id="actionLayerTabsDiv" className="esri-hidden" >
          {/* <input type="checkbox" id="canedit" name="canedit" value="canedit" onClick={this.onlayeredit}/>
              <label for="canedit"> Can Edit </label> */}

          <div id="actions" className="actions" style={{ "paddingLeft": "5px" }}>
            {/* <h6>Actions</h6> */}
            <ul className="esri-layer-list__item-actions-list list-group-horizontal ">
              <li className="esri-layer-list__item-action action-click" tabIndex="0" role="button" title="Go to full extent" aria-label="full-extent" onClick={() => this.triggerAction("full-extent", this.props)}>
                <span aria-hidden="true" className="esri-layer-list__item-action-icon material-icons">zoom_out_map</span>
              </li>
              <li className="esri-layer-list__item-action action-click esri-layer-list-labels" style={{ "display": "none" }} tabIndex="0" role="button" title="Labels On/Off" aria-label="Labels-On/Off" onClick={() => this.triggerAction("Labels-On/Off", this.props)}>
                <span aria-hidden="true" className="esri-layer-list__item-action-icon material-icons">label</span>
                {/* <span aria-hidden="true"  className="esri-layer-list__item-action-icon esri-icon-maps"></span> */}
              </li>
              <li className="esri-layer-list__item-action action-click" tabIndex="0" role="button" title="Layer information" aria-label="information" onClick={() => this.triggerAction("information", this.props)}>
                {/* <span aria-hidden="true"  className="esri-layer-list__item-action-icon esri-icon-description"></span> */}
                <span aria-hidden="true" className="esri-layer-list__item-action-icon material-icons">description</span>
              </li>
              <li className="esri-layer-list__item-action action-click" tabIndex="0" role="button" title="Increase opacity" aria-label="increase-opacity" onClick={() => this.triggerAction("increase-opacity", this.props)}>
                {/* <span aria-hidden="true"  className="esri-layer-list__item-action-icon esri-icon-plus"></span> */}
                <span aria-hidden="true" className="esri-layer-list__item-action-icon material-icons">add_circle_outline</span>
              </li>
              <li className="esri-layer-list__item-action action-click" tabIndex="0" role="button" title="Decrease opacity" aria-label="decrease-opacity" onClick={() => this.triggerAction("decrease-opacity", this.props)}>
                {/* <span aria-hidden="true"  className="esri-layer-list__item-action-icon esri-icon-minus"></span> */}
                <span aria-hidden="true" className="esri-layer-list__item-action-icon material-icons">remove_circle_outline</span>
              </li>
              <li className="esri-layer-list__item-action action-click action-anttabs-filter" tabIndex="0" role="button" title="Layer Filter" aria-label="layer-filter" onClick={() => this.triggerAction("layer-filter", this.props)}>
                {/* <span aria-hidden="true"  className="esri-layer-list__item-action-icon esri-icon-review"></span> */}
                <span aria-hidden="true" className="esri-layer-list__item-action-icon material-icons">filter_alt</span>
              </li>
            </ul>
          </div>
          <div className="tab" style={{ "display": "none", "padding": "5px" }}>
            <button className="action-anttabs action-anttabs-buffer" onclick="openTab(event, 'buffer')" aria-label="buffer">Buffer</button>
          </div>


          <div id="buffer" className="tabcontent buffer" style={{ "display": "none", "padding": "5px" }}>
            <h6>Buffer</h6>
            {/* <ComponentBufferCalc key={this.state.selectedLayerKey} layerkey={this.state.selectedLayerKey}  {...this.props} bufferCalcLayer={this.bufferCalcLayer()} />  */}
            <div id="cbuffereditArea" className="map-customexpand-container Buffer-box ">
              <div id="addFeatureDiv" >
                <h6 className="list-heading">Search and select within buffer</h6>
                <div id="addTemplatesDivBuffer1" style={{ "marginTop": "2px" }}>
                  <label id="buffererror" className="buffererror" style={{ "color": "red" }}></label>
                </div>
                <div id="addTemplatesDivBuffer2" style={{ "marginTop": "1px" }}>
                  <label style={{ "width": "15%" }}>Distance:</label>
                  <input style={{ "width": "20%", "height": "30px" }} id="distance" className="selectdistance" type="number" name="distance" min="1" value={this.state.distance} onChange={(event) => this.updateState(event, this)} />

                  &nbsp;

                  <label style={{ "width": "10%" }}>Units:</label>
                  <select style={{ "width": "20%", "height": "30px" }} id="units" name="units" className="selectunits" value={this.state.units} onChange={(event) => this.updateState(event, this)}>
                    <option value="feet">Feet (ft)</option>
                    <option value="meters">Meters (m)</option>
                    <option value="kilometers">Kilometers (km)</option>
                    <option value="miles">Miles (mi)</option>
                    <option value="us-nautical-miles">Nautical Miles (NM -US)</option>
                    <option value="nautical-miles">Nautical Miles (NM)</option>
                  </select>
                  <button id="buffersearch" style={{ "marginLeft": "4px" }} type="button" className="my_button buffersearch" onClick={(inthis) => this.bufferSearch(this)}>Search</button>
                  <button id="selecttaxlots" style={{ "marginLeft": "4px" }} type="button" className="my_button selecttaxlots" onClick={(inthis) => this.clearTaxLots(this)}>Clear</button>

                  <img id="loadingImg" className="loadingImg" src={loadingimg}
                    style={{ "height": "30px", "display": "none" }} />
                </div>
                <div id="addTemplatesDivBuffer3" style={{ "marginTop": "3px" }}>

                </div>
                <div id="gridSuccessInfoDisplay">
                  <span className="gridSucessInfo" id="featureCount" style={{ "color": "blue" }}></span>
                </div>
                <div id="gridDisplay" className="gridDisplay">
                  <div id="buffergrid"></div>
                </div>
              </div>
            </div>
          </div>

          {/* <AntTabs className="action-anttabs" value={this.state.tabValue}  aria-label="">
                    <AntTab label="Actions" />

                    <AntTab label="Buffer" />
                </AntTabs>

                <TabPanel value={this.state.tabValue} index={0}>

                        <ul  className="esri-layer-list__item-actions-list">
                                <li  className="esri-layer-list__item-action action-click"  tabIndex="0" role="button" title="Go to full extent" aria-label="full-extent" onClick={() => this.triggerAction("full-extent",this.props)}>
                                    <span aria-hidden="true"  className="esri-layer-list__item-action-icon esri-icon-zoom-out-fixed"></span>
                                    <span  className="esri-layer-list__item-action-title">Go to full extent</span>
                                </li>
                                <li  className="esri-layer-list__item-action action-click"  tabIndex="0" role="button" title="Layer information" aria-label="information" onClick={() => this.triggerAction("information",this.props)}>
                                    <span aria-hidden="true"  className="esri-layer-list__item-action-icon esri-icon-description"></span>
                                    <span  className="esri-layer-list__item-action-title">Layer information</span>
                                </li>
                        </ul>
                        <ul  className="esri-layer-list__item-actions-list">
                                <li  className="esri-layer-list__item-action action-click"  tabIndex="0" role="button" title="Increase opacity" aria-label="increase-opacity" onClick={() => this.triggerAction("increase-opacity",this.props)}>
                                    <span aria-hidden="true"  className="esri-layer-list__item-action-icon esri-icon-up"></span>
                                    <span  className="esri-layer-list__item-action-title">Increase opacity</span>
                                </li>
                                <li  className="esri-layer-list__item-action action-click"  tabIndex="0" role="button" title="Decrease opacity" aria-label="decrease-opacity" onClick={() => this.triggerAction("decrease-opacity",this.props)}>
                                    <span aria-hidden="true"  className="esri-layer-list__item-action-icon esri-icon-down"></span>
                                    <span  className="esri-layer-list__item-action-title">Decrease opacity</span>
                                </li>
                        </ul>
                </TabPanel>


                <TabPanel value={this.state.tabValue} index={1} style={{"font-size":"14px"}}>
                        <ComponentBufferCalc  {...this.props} bufferCalcLayer={this.bufferCalcLayer()} />
                </TabPanel> */}


        </div>

        <div id="layerListEditparent">
          <div id="actionsLayerListviewDiv"></div>
          {/* <div id="actionsLayerListinfo" className="esri-widget esri-hidden">
                        <h6>Select a feature to begin editing</h6>
                         <h6>
                            Sample info for tempate editing
                            <p>
                                TBD: Write good info
                            </p>
                        </h6>
                    </div> */}
          <div id="actionsLayerListupdate" className="esri-widget esri-hidden">
            <div id="actionsLayerListform" className="scroller esri-component">
              <div>
                <label id="actionMessageSuccess" style={{ "color": "green", "display": "none" }}> Updated successfully</label>
                <input
                  type="button"
                  className="esri-button"
                  value="Update assessment"
                  id="btnactionsLayerListUpdate" style={{ "width": "95%", "display": "inline-block" }} />
                <input id="btnactionsLayerListUpdateClose" type="button" value="X" style={{ "position": "absolute", "right": "0" }} onClick={this.closeEditPanel} />
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default CActionsLayerList;
