import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AddIcon from '@material-ui/icons/Add';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import RemoveIcon from '@material-ui/icons/Delete';
import DropDownIcon from '@material-ui/icons/VerticalAlignBottom';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { getEnvVariable } from '../../../modules/environmentalList';
import { GetData } from '../helpers/requests';
import DefaultSettings from './settings';


export default function MultipleFileUpload({ edit, ismultiple = true, name, value, handleFieldValue }) {
    const [multiplefiles, setMultipleFiles] = useState(value || [])
    const fileInput = useRef(null)

    useEffect(() => {
        setMultipleFiles(value)
    }, [value])

    const handleFileUpload = (event) => {
        let file = []
        event.preventDefault();
        if (fileInput && fileInput.current) {
            const fname = fileInput.current.files[0].name;
            const size = fileInput.current.files[0].size;
            toBase64(event)
                .then(result => {
                    var e = {
                        target: {
                            name: fname,
                            value: result
                        }
                    }
                    let attach = result.split(',')
                    if (attach.length === 2)
                        file.push({ UploadType: 'U', ContentStr: attach[1], FileName: fname, Size: size, FileType: attach[0] });
                    let files = [...multiplefiles, ...file];

                    //500MB
                    if (size > 500000) { //change to 5 MB
                        if (DefaultSettings.getAlert() != null && alert)
                            DefaultSettings.getAlert().show('Uploading size exceeded 500MB', 'error');
                    } else {
                        setMultipleFiles(files)
                        let events = { target: { value: files, name: name, checked: false }, currentTarget: { dataset: null } }
                        handleFieldValue(events);
                    }

                }).catch(ex => console.log(ex));
        }
    }

    const toBase64 = (event) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        })
    };


    const handleDelete = (i) => {
        let tempfiles = multiplefiles.filter((each, index) => {
            return each.Id !== i
        })
        let events = { target: { value: tempfiles, name: name, checked: false }, currentTarget: { dataset: null } }
        let url = getEnvVariable('Admin') + '/api/Files/RemoveFile/' + i
        GetData(url).then(resp => {
            if (resp) {
                setMultipleFiles(tempfiles)
                handleFieldValue(events);
            }
        })
    }


    const DownloadAttachment = (Name, ContentStr, filetype) => {
        const linkSource = filetype + ContentStr;
        const downloadLink = document.createElement("a");
        const fileName = Name;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }


    const downloadAttachments = (Id) => {
        if (Id >= 0) {
            GetData(getEnvVariable('Admin') + `/api/Files/DownloadFile/${Id}`).then(resp => {
                if (resp && resp.FileId) {
                    DownloadAttachment(resp.FileName, resp.ContentStr, resp.FileType)
                }
            })
        }
    }


    return (
        <div className='col-12 row mt-4' style={{ height: '500px', overflow: 'auto' }}>
            <Paper elevation={1} className="col-12">
                {multiplefiles && multiplefiles.length === 0 && <div className="text-center eps-content-center">
                    <AttachFileIcon color="default" style={{ fontSize: '80px', marginTop:'20px' }} /><br />
                    <div classes="text-secondary">No attachments have been added</div><br />
                    {edit && <Button variant="contained" component="label" color="primary"> <AddIcon /> Add Attachment
                        <input
                            type="file"
                            ref={fileInput}
                            onChange={(e) => handleFileUpload(e)}
                            hidden
                        />
                    </Button>}
                </div>
                }{multiplefiles && multiplefiles.length > 0 &&
                    <div>
                        <h6 className="pt-4 col fw-bold">Attachments&nbsp;&nbsp;
                            {/* {multiplefiles && multiplefiles.length > 0 && <IconButton edge="end" color="primary" onClick={() => zipAttachDownload()}>
                                <ArchiveIcon />
                            </IconButton>} */}
                        </h6>
                        <br />
                        <Divider />
                        {edit && ismultiple && <Button component="label" > <AddIcon color="primary" /> Add Attachment
                            <input
                                type="file"
                                ref={fileInput}
                                onChange={handleFileUpload}
                                hidden
                            />
                        </Button>}
                        <Divider /></div>
                }
                {multiplefiles && multiplefiles.length > 0 && <Fragment>
                    <List>
                        <ListItem dense >
                            <ListItemText alignItems="flex-start" ><Typography component="span"
                                variant="subtitle2"
                                color="primary">Name</Typography> </ListItemText>
                            <ListItemSecondaryAction>
                                <Typography component="span"
                                    variant="subtitle2"
                                    color="primary">Actions</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        {(multiplefiles || []).map((file, i) => {
                            return <ListItem key={i} dense >
                                <ListItemText alignItems="flex-start">{file.FileName || file.Id || 'Document ' + (i + 1)} </ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" color="primary" onClick={() => downloadAttachments(file.Id)}>
                                        <DropDownIcon />
                                    </IconButton>
                                    {edit && <IconButton edge="end" color="secondary" onClick={() => handleDelete(file.Id)}>
                                        <RemoveIcon />
                                    </IconButton>}
                                </ListItemSecondaryAction>
                            </ListItem>
                        })}
                    </List>
                </Fragment>
                }
            </Paper>
        </div >

    );
}

