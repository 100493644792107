import ProjectmessagesEdit from '../projectmessages/projectmessagesview'
import ProjectMessagesAdd from '../projectmessages/projectmessagesadd';
import { getEnvVariable } from '../../../../modules/environmentalList';
import BusiessRulesAdd from './businessrules/businessrulesadd';
import BusiessRulesEdit from './businessrules/businessrulesedit';

const SelectProjectMgs = {
    "type": "form",
    "key": "SelectProjectMgs",
    "name": "SelectProjectMgs",
    aliases: {
        save: 'save',
        GROUP_NAME: 'GROUP_NAME'
    },
    sharedValues: ['save', 'GROUP_NAME'],
    layout: {
        group: [
            {
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'GROUP_NAME',
                    type: 'div',
                    className: 'col-md-10 col-lg-8',
                    sub: {
                        type: 'div',
                        className: 'col-md-10 col-lg-8',
                    }
                }],

            },
        ],
    },
    values: {
        GROUP_NAME: 'Module',
    },

    "components": {
        GROUP_NAME: {
            "type": "dropdown",
            "key": "GROUP_NAME",
            "label": "Select Group Type",
            "name": "GROUP_NAME",
            extraProps: {
                transformProps: {
                    label: 'Name',
                    value: 'Value'
                },
            }
        },
    },
    mapActionsToEffects: {
        onLoad: {
            init: {
                rules: [{
                    run:
                    {
                        valid: [{
                            type: 'load',
                            name: 'GROUP_NAME',
                            "read": [
                                {
                                    url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=TABLE_NAMES`,
                                    type: 'get',
                                    saveAs: {
                                        key: 'items'
                                    }
                                }
                            ]
                        },

                            // {
                            //     type: 'validations', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                            //     "read": [
                            //         {
                            //             "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.appsettings`,
                            //             type: 'get'
                            //         }
                            //     ],
                            //     transformProps: {
                            //         removesubfieldname: 'MASTER.APPSETTINGS_'
                            //     }
                            // },
                        ]
                    }
                }]
            },
        }
    }
}

const ProjectmessagesGrid = {
    "type": "grid",
    title: "Project Messages",
    "key": "ProjectmessagesGrid",
    "gridkey": 'ProjectmessagesGrid',
    "name": "ProjectmessagesGrid",
    customizedheight: 450,
    //"addRoute": true,
    "nosubgridrouting": true,
    //"routeParams": { replace: 'settings', param: 'Value' },
    values: {
        addOpen: false,
        editOpen: false,
        editInfo: null,
        load: false,
        allgriddata: null,
        navigateItem: null,
        navigation: null,
        reportOpen: false,
        loadreportfilter: null,
        ReportJsonQueryStr: null,
    },
    sharedValues: ['addOpen', 'editOpen', 'editInfo', 'allgriddata', 'navigateItem', 'navigation', 'loadreportfilter', 'ReportJsonQueryStr', 'reportOpen'],
    aliases: {
        addOpen: 'addOpen',
        editOpen: 'editOpen',
        editInfo: 'editInfo',
        allgriddata: 'allgriddata',
        navigateItem: 'navigateItem',
        navigation: 'navigation',
        reportOpen: 'reportOpen',
        loadreportfilter: 'loadreportfilter',
        ReportJsonQueryStr: 'ReportJsonQueryStr',
    },
    headerProps: {
        title: "Project Messages",

        actions: [

            {
                type: 'fab',
                icon: 'clear',
                name: 'clear',
                color: 'primary',
                title: 'Clear Filters',
                menucolor: '#22a9b3',
                hideon: 'clear',
                hidevalue: false
            },

            {
                type: 'menu',
                icon: 'excel',
                name: 'excel',
                color: 'primary',
                title: 'Export to Excel',
                menucolor: '#c90685',
                hideon: 'excel',
                hidevalue: false,
                items: [{
                    name: 'Export Current Page',
                    action: 'filtercurrent'
                },
                {
                    name: 'Export All Pages',
                    action: 'filterall'
                }
                ]
            },
            {
                type: 'fab',
                icon: 'reports',
                name: 'reports',
                color: 'primary',
                title: 'Save Report',
                menucolor: '#C85A54',
                hideon: 'reports',
                hidevalue: false
            },
        ]
    },
    "columns": [
        {
            "title": "Name",
            "field": "Name",
            "show": true,
        },
    ],
    displayName: 'editInfo',
    allgriddata: 'allgriddata',
    navigateItem: 'navigateItem',
    navigateItem: 'navigateItem',
    navigation: 'navigation',
    loadreportfilter: 'loadreportfilter',
    ReportJsonQueryStr: 'ReportJsonQueryStr',
    mapActionsToEffects: {
        load: {
            rules: [{
                run: {
                    valid: [{
                        type: 'grid',
                        "read": [
                            {
                                url: getEnvVariable('Report') + `/api/ReportQuery/QueryLoad?masterkey=APPLICATION_UTIL_QUERIES&kendoGrid=K&reportkey=ALL_Tables_ProjectMessagesGroupName&`,
                                type: 'post',
                                alert: true,
                                key: 'save',
                                body: {
                                    "GROUP_NAME": "Module"
                                },
                                verify: 'GROUP_NAME',
                                saveAs: {
                                    key: 'data'
                                }
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        GROUP_NAME: {
            rules: [{
                run: {
                    valid: [{
                        type: 'grid',
                        "read": [
                            {//ALL_Tables_ProjectMessagesGroupName
                                url: getEnvVariable('Report') + `/api/ReportQuery/QueryLoad?masterkey=APPLICATION_UTIL_QUERIES&kendoGrid=K&reportkey=ALL_Tables_ProjectMessagesGroupName&`,
                                type: 'post',
                                alert: true,
                                key: 'save',
                                removeObjParams: ['allgriddata', 'editInfo', 'navigation', 'TableName'],
                                saveAs: {
                                    key: 'data'
                                }
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        addOpen: {
            rules: [{
                check: [
                    {
                        condition: 'AND',
                        rules: [
                            {
                                "name": "addOpen",
                                "type": "!=",
                                "value": true,
                                "rules": []
                            }
                        ]
                    }
                ],
                run: {
                    valid: [{
                        type: 'grid',
                        "read": [
                            {
                                url: getEnvVariable('Report') + `/api/ReportQuery/QueryLoad?masterkey=APPLICATION_UTIL_QUERIES&kendoGrid=K&reportkey=ALL_Tables_ProjectMessagesGroupName&`,
                                type: 'post',
                                alert: true,
                                key: 'save',
                                saveAs: {
                                    key: 'data'
                                }
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        editOpen: {
            rules: [{
                check: [
                    {
                        condition: 'AND',
                        rules: [
                            {
                                "name": "editOpen",
                                "type": "!=",
                                "value": true,
                                "rules": []
                            }
                        ]
                    }
                ],
                run: {
                    valid: [{
                        type: 'grid', //loadtemp
                        "read": [
                            {
                                url: getEnvVariable('Report') + `/api/ReportQuery/QueryLoad?masterkey=APPLICATION_UTIL_QUERIES&kendoGrid=K&reportkey=ALL_Tables_ProjectMessagesGroupName&`,
                                type: 'post',
                                alert: true,
                                key: 'save',
                                saveAs: {
                                    key: 'data'
                                },
                                removeObjParams: ['allgriddata', 'editInfo', 'navigation', 'TableName'],
                                "matchProps": [
                                    {
                                        name: "editInfo.GroupName",
                                        key: "name",
                                    }
                                ],
                                sublist: 'GROUP_NAME',
                                body: {
                                    "GROUP_NAME": "editInfo.GroupName"
                                },
                                replaceBody: true,
                                word: "GROUP_NAME"
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        'add': {
            rules: [
                {
                    run: {
                        valid: [
                            {
                                type: 'value',
                                key: 'addOpen',
                                result: true
                            },

                        ]
                    }
                }
            ]
        },
        'display': {
            rules: [

                {
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'value',
                                key: 'editOpen',
                                result: true
                            },
                            {
                                type: 'resultvalue',
                                name: 'editInfo',
                            },
                            {
                                type: 'resultvalue',
                                name: 'allgriddata',
                            },
                            {
                                type: 'resultvalue',
                                name: 'navigateItem',
                            },
                            {
                                type: 'resultvalue',
                                name: 'navigation',
                            },


                        ]
                    }
                }
            ]
        },
        'report': {
            rules: [
                {
                    run: {
                        valid: [
                            {
                                type: 'value',
                                key: 'reportOpen',
                                result: true
                            },
                            {
                                type: 'resultvalue',
                                name: 'loadreportfilter',
                            },
                            {
                                type: 'resultvalue',
                                name: 'ReportJsonQueryStr',
                            },

                        ]
                    }
                }
            ]
        },
    }



}

const ProjectMessagesReport = {
    type: 'popup',
    key: 'ProjectMessagesReport',
    name: 'ProjectMessagesReport',

    anchor: 'right',
    open: false,
    aliases: {
        open: 'reportOpen'
    },
    sharedValues: ['open', 'loadreportfilter', 'ReportJsonQueryStr'],
    container: {
        values: {
            loadreportfilter: null,
            ReportJsonQueryStr: null
        },
        mapValues: {
            Report: [
                {
                    key: 'ReportJsonQueryStr',
                    name: 'ReportJsonQueryStr'
                },
                {
                    key: 'loadreportfilter',
                    name: 'loadreportfilter'
                },
            ],
        },
        mapActionsToEffects: {},
        layout: {
            group: [
                {
                    order: 0,
                    type: 'div',
                    className: '',
                    components: [
                        {
                            name: 'Report'
                        },
                    ]
                }
            ]

        },
        components: {
            Report: {
                "type": "form",
                "key": "Report",
                "name": "Report",
                aliases: {

                },
                sharedValues: ['ReportJsonQueryStr', 'loadreportfilter'],
                values: {
                    loadreportfilter: null,
                    ReportJsonQueryStr: null
                },
                mapActionsToEffects: {},
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: '',
                            components: [{
                                name: 'ReportJsonQueryStr',
                                type: 'div',

                            },
                            {
                                name: 'loadreportfilter',
                                type: 'div',

                            }],

                        },
                    ],
                },
                "components": {
                    "ReportJsonQueryStr": {
                        "type": "gridreport",
                        "gridkey": 'ProjectmessagesGrid',
                    },
                },
            }
        }
    }
}
const ProjectMessagesContainer = {
    type: 'container',
    custom: true,
    key: 'ProjectMessagesContainer',
    values: {
        addOpen: false,
        editOpen: false,
        editInfo: null,
        allgriddata: null,
        navigateItem: null,
        navigation: null,
        loadreportfilter: null,
        ReportJsonQueryStr: null,
        reportOpen: false,
    },
    mapValues: {
        ProjectMessagesAdd: [
            {
                key: 'addOpen',
                name: 'open'
            }
        ],
        ProjectmessagesEdit: [
            {
                key: 'editOpen',
                name: 'open'
            },
            {
                key: 'editInfo',
                name: 'editInfo'
            },
            {
                key: 'allgriddata',
                name: 'allgriddata'
            },
            {
                key: 'navigateItem',
                name: 'navigateItem'
            },
            {
                key: 'navigation',
                name: 'navigation'
            },
        ],
        ProjectmessagesGrid: [
            {
                key: 'editOpen',
                name: 'editOpen'
            },
            {
                key: 'addOpen',
                name: 'addOpen'
            },
            {
                key: 'reportOpen',
                name: 'reportOpen'
            },
            {
                key: 'ReportJsonQueryStr',
                name: 'ReportJsonQueryStr'
            },
            {
                key: 'loadreportfilter',
                name: 'loadreportfilter'
            },
            {
                key: 'GROUP_NAME',
                name: 'GROUP_NAME'
            },
        ],
        ProjectMessagesReport: [
            {
                key: 'reportOpen',
                name: 'open'
            },
            {
                key: 'loadreportfilter',
                name: 'loadreportfilter'
            },
            {
                key: 'ReportJsonQueryStr',
                name: 'ReportJsonQueryStr'
            },

        ],
        SelectProjectMgs: [
            {
                key: 'GROUP_NAME',
                name: 'GROUP_NAME'
            }
        ],

    },
    mapActionsToEffects: {
        'addOpen': {
            rules: [
                {
                    check: [
                        {
                            condition: 'AND',
                            rules: [
                                {
                                    "name": "addOpen",
                                    "type": "!=",
                                    "value": true,
                                    "rules": []
                                }
                            ]
                        }
                    ],
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'removelayout',
                                name: 'ProjectMessagesAdd',
                            }

                        ],
                        inValid: [
                            {
                                type: 'addlayout',
                                name: 'ProjectMessagesAdd',
                            }
                        ]
                    }
                }
            ]
        },
        'editOpen': {
            rules: [
                {
                    check: [
                        {
                            condition: 'AND',
                            rules: [
                                {
                                    "name": "editOpen",
                                    "type": "!=",
                                    "value": true,
                                    "rules": []
                                }
                            ]
                        }
                    ],
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'removelayout',
                                name: 'ProjectmessagesEdit',
                            }

                        ],
                        inValid: [
                            {
                                type: 'addlayout',
                                name: 'ProjectmessagesEdit',
                            }
                        ]
                    }
                }
            ]
        },
        'reportOpen': {
            rules: [
                {
                    check: [
                        {
                            condition: 'AND',
                            rules: [
                                {
                                    "name": "reportOpen",
                                    "type": "!=",
                                    "value": true,
                                    "rules": []
                                }
                            ]
                        }
                    ],
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'removelayout',
                                name: 'ProjectMessagesReport',
                            }

                        ],
                        inValid: [
                            {
                                type: 'addlayout',
                                name: 'ProjectMessagesReport',
                            }
                        ]
                    }
                }
            ]
        },
    },
    layout: {
        group: [
            {
                order: 0,
                type: 'div',
                className: 'row',
                components: [
                    {
                        name: 'SelectProjectMgs',
                        type: 'div',
                        className: 'col-md-10 col-lg-8',
                        sub: {
                            type: 'div',
                            className: 'col-md-10 col-lg-8',
                        }
                    },
                ]
            },
            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [
                    {
                        name: 'ProjectmessagesGrid'
                    },
                ]
            }
        ]

    },
    components: {
        SelectProjectMgs: SelectProjectMgs,
        ProjectmessagesGrid: ProjectmessagesGrid,
        ProjectMessagesAdd: ProjectMessagesAdd,
        ProjectmessagesEdit: ProjectmessagesEdit,
        ProjectMessagesReport: ProjectMessagesReport
    }
}

const BusiessRulesGrid = {
    "type": "grid",
    title: "Project Messages",
    "key": "BusiessRulesGrid",
    "gridkey": 'BusiessRulesGrid',
    "name": "BusiessRulesGrid",
    customizedheight: 350,
    //"addRoute": true,
    "nosubgridrouting": true,
    //"routeParams": { replace: 'settings', param: 'Value' },
    values: {
        addOpen: false,
        editOpen: false,
        editInfo: null,
        load: false,
        allgriddata: null,
        navigateItem: null,
        navigation: null,
        reportOpen: false,
        loadreportfilter: null,
        ReportJsonQueryStr: null,
    },
    sharedValues: ['addOpen', 'editOpen', 'editInfo', 'allgriddata', 'navigateItem', 'navigation', 'loadreportfilter', 'ReportJsonQueryStr', 'reportOpen'],
    aliases: {
        addOpen: 'addOpen',
        editOpen: 'editOpen',
        editInfo: 'editInfo',
        allgriddata: 'allgriddata',
        navigateItem: 'navigateItem',
        navigation: 'navigation',
        reportOpen: 'reportOpen',
        loadreportfilter: 'loadreportfilter',
        ReportJsonQueryStr: 'ReportJsonQueryStr'
    },
    headerProps: {
        title: "Business Rules",
        actions: [
            {
                type: 'fab',
                icon: 'clear',
                name: 'clear',
                color: 'primary',
                title: 'Clear Filters',
                menucolor: '#22a9b3',
                hideon: 'clear',
                hidevalue: false
            },

            {
                type: 'menu',
                icon: 'excel',
                name: 'excel',
                color: 'primary',
                title: 'Export to Excel',
                menucolor: '#c90685',
                hideon: 'excel',
                hidevalue: false,
                items: [{
                    name: 'Export Current Page',
                    action: 'filtercurrent'
                },
                {
                    name: 'Export All Pages',
                    action: 'filterall'
                }
                ]
            },
            {
                type: 'fab',
                icon: 'reports',
                name: 'reports',
                color: 'primary',
                title: 'Save Report',
                menucolor: '#C85A54',
                hideon: 'reports',
                hidevalue: false
            },
            {
                type: 'fab',
                icon: 'add',
                name: 'add',
                color: 'primary',
                hideon: 'addButton',
                hidevalue: true,
                title: 'Add Business Rule',
                menucolor: '#36088a'
            },
        ]
    },
    "columns": [
        {
            "title": "Message",
            "field": "Message",
            "show": true,
            "width": 300
        },
        {
            "title": "Type",
            "field": "TypeDesc",
            "show": true,
        },
        {
            "title": "Created By",
            "field": "CreatedByName",
            "show": true,
        },
        {
            "title": "Created On",
            "field": "CreatedOn",
            "show": true,
            filter: "date",
            format: "{0:d}",
            convert: "MM/DD/YYYY hh:mm:ss A"
        },
        {
            "title": "Updated By",
            "field": "UpdatedByName",
            "show": true,
        },
        {
            "title": "Updated On",
            "field": "UpdatedOn",
            "show": true,
            filter: "date",
            format: "{0:d}",
            convert: "MM/DD/YYYY hh:mm:ss A"
        },
    ],
    displayName: 'editInfo',
    allgriddata: 'allgriddata',
    navigateItem: 'navigateItem',
    navigateItem: 'navigateItem',
    navigation: 'navigation',
    loadreportfilter: 'loadreportfilter',
    ReportJsonQueryStr: 'ReportJsonQueryStr',
    mapActionsToEffects: {
        load: {
            rules: [{
                run: {
                    valid: [{
                        type: 'grid',
                        "read": [
                            {
                                url: getEnvVariable('Admin') + `/api/ProjectMessages/${getEnvVariable('ModuleKey')}Module.BUSINESS_RULES_ALERTS/Grid?`,
                                type: 'get',
                                key: 'save',
                                saveAs: {
                                    key: 'data'
                                }
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        addOpen: {
            rules: [{
                check: [
                    {
                        condition: 'AND',
                        rules: [
                            {
                                "name": "addOpen",
                                "type": "!=",
                                "value": true,
                                "rules": []
                            }
                        ]
                    }
                ],
                run: {
                    valid: [{
                        type: 'grid',
                        "read": [
                            {
                                url: getEnvVariable('Admin') + `/api/ProjectMessages/${getEnvVariable('ModuleKey')}Module.BUSINESS_RULES_ALERTS/Grid?`,
                                type: 'get',
                                key: 'save',
                                saveAs: {
                                    key: 'data'
                                }
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        editOpen: {
            rules: [{
                check: [
                    {
                        condition: 'AND',
                        rules: [
                            {
                                "name": "editOpen",
                                "type": "!=",
                                "value": true,
                                "rules": []
                            }
                        ]
                    }
                ],
                run: {
                    valid: [{
                        type: 'loadtemp', //loadtemp
                        "read": [
                            {
                                url: getEnvVariable('Admin') + `/api/ProjectMessages/${getEnvVariable('ModuleKey')}Module.BUSINESS_RULES_ALERTS/Grid?`,
                                type: 'get',
                                key: 'save',
                                saveAs: {
                                    key: 'data'
                                }
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        'add': {
            rules: [
                {
                    run: {
                        valid: [
                            {
                                type: 'value',
                                key: 'addOpen',
                                result: true
                            },

                        ]
                    }
                }
            ]
        },
        'display': {
            rules: [

                {
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'value',
                                key: 'editOpen',
                                result: true
                            },
                            {
                                type: 'resultvalue',
                                name: 'editInfo',
                            },
                            {
                                type: 'resultvalue',
                                name: 'allgriddata',
                            },
                            {
                                type: 'resultvalue',
                                name: 'navigateItem',
                            },
                            {
                                type: 'resultvalue',
                                name: 'navigation',
                            },


                        ]
                    }
                }
            ]
        },
        'report': {
            rules: [
                {
                    run: {
                        valid: [
                            {
                                type: 'value',
                                key: 'reportOpen',
                                result: true
                            },
                            {
                                type: 'resultvalue',
                                name: 'loadreportfilter',
                            },
                            {
                                type: 'resultvalue',
                                name: 'ReportJsonQueryStr',
                            },

                        ]
                    }
                }
            ]
        },
    }
}


const BusiessRulesContainer = {
    type: 'container',
    custom: true,
    key: 'BusiessRulesContainer',
    values: {
        addOpen: false,
        editOpen: false,
        editInfo: null,
        allgriddata: null,
        navigateItem: null,
        navigation: null,
        loadreportfilter: null,
        ReportJsonQueryStr: null,
        reportOpen: false,
    },
    mapValues: {
        BusiessRulesAdd: [
            {
                key: 'addOpen',
                name: 'open'
            }
        ],
        BusiessRulesEdit: [
            {
                key: 'editOpen',
                name: 'open'
            },
            {
                key: 'editInfo',
                name: 'editInfo'
            },
            {
                key: 'allgriddata',
                name: 'allgriddata'
            },
            {
                key: 'navigateItem',
                name: 'navigateItem'
            },
            {
                key: 'navigation',
                name: 'navigation'
            },
        ],
        BusiessRulesGrid: [
            {
                key: 'editOpen',
                name: 'editOpen'
            },
            {
                key: 'addOpen',
                name: 'addOpen'
            },
            {
                key: 'reportOpen',
                name: 'reportOpen'
            },
            {
                key: 'ReportJsonQueryStr',
                name: 'ReportJsonQueryStr'
            },
            {
                key: 'loadreportfilter',
                name: 'loadreportfilter'
            },
        ],
        ProjectMessagesReport: [
            {
                key: 'reportOpen',
                name: 'open'
            },
            {
                key: 'loadreportfilter',
                name: 'loadreportfilter'
            },
            {
                key: 'ReportJsonQueryStr',
                name: 'ReportJsonQueryStr'
            },

        ]

    },
    mapActionsToEffects: {
        'addOpen': {
            rules: [
                {
                    check: [
                        {
                            condition: 'AND',
                            rules: [
                                {
                                    "name": "addOpen",
                                    "type": "!=",
                                    "value": true,
                                    "rules": []
                                }
                            ]
                        }
                    ],
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'removelayout',
                                name: 'BusiessRulesAdd',
                            }

                        ],
                        inValid: [
                            {
                                type: 'addlayout',
                                name: 'BusiessRulesAdd',
                            }
                        ]
                    }
                }
            ]
        },
        'editOpen': {
            rules: [
                {
                    check: [
                        {
                            condition: 'AND',
                            rules: [
                                {
                                    "name": "editOpen",
                                    "type": "!=",
                                    "value": true,
                                    "rules": []
                                }
                            ]
                        }
                    ],
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'removelayout',
                                name: 'BusiessRulesEdit',
                            }

                        ],
                        inValid: [
                            {
                                type: 'addlayout',
                                name: 'BusiessRulesEdit',
                            }
                        ]
                    }
                }
            ]
        },
        'reportOpen': {
            rules: [
                {
                    check: [
                        {
                            condition: 'AND',
                            rules: [
                                {
                                    "name": "reportOpen",
                                    "type": "!=",
                                    "value": true,
                                    "rules": []
                                }
                            ]
                        }
                    ],
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'removelayout',
                                name: 'ProjectMessagesReport',
                            }

                        ],
                        inValid: [
                            {
                                type: 'addlayout',
                                name: 'ProjectMessagesReport',
                            }
                        ]
                    }
                }
            ]
        },
    },
    layout: {
        group: [
            {
                order: 0,
                type: 'div',
                className: 'row',
                components: [
                    {
                        name: 'BusiessRulesGrid'
                    },
                ]
            }
        ]

    },
    components: {
        BusiessRulesGrid: BusiessRulesGrid,
        BusiessRulesAdd: BusiessRulesAdd,
        BusiessRulesEdit: BusiessRulesEdit,
        ProjectMessagesReport: ProjectMessagesReport
    }
}

const ProjectMessagesSectionTab = {
    type: "sections",
    "key": "ProjectMessagesSectionTab",
    name: "ProjectMessagesSectionTab",
    aliases: {
    },
    initRun: true,
    sharedValues: [],
    validationMapKeys: [],
    layout: {
        'Business Rule Messages': {
            group: [
                {
                    order: 0,
                    type: 'div',
                    className: 'row',
                    components: [{
                        name: 'BusinessRulesContainer',
                        type: 'div',
                        className: 'col-12',
                        sub: {
                            type: 'div',
                            className: 'col-12'
                        }
                    },]
                },
            ],
        },
        'Tables': {
            group: [
                {
                    order: 0,
                    type: 'div',
                    className: 'row',
                    components: [{
                        name: 'ProjectMessagesContainer',
                        type: 'div',
                        className: 'col-12',
                        sub: {
                            type: 'div',
                            className: 'col-12'
                        }
                    },]
                },
            ],
        },
    },
    values: {
        ProjectMessagesContainer: '',
        ProjectMessagesGrid: '',
    },

    sections: {
        type: 'tab',
        sectionNames: ['Business Rule Messages', 'Tables',],
        steps: ['Business Rule Messages', 'Tables'],
    },
    components: {
        BusinessRulesContainer: BusiessRulesContainer,
        ProjectMessagesContainer: ProjectMessagesContainer
    },
    mapActionsToEffects: {


    }

}


const ProjectMessagesTabs = {
    type: 'container',
    custom: true,
    key: 'ProjectMessagesTabs',
    values: {

    },
    mapValues: {

    },
    mapActionsToEffects: {

    },
    layout: {
        group: [
            {
                order: 0,
                type: 'div',
                className: 'row col',
                components: [
                    {
                        name: 'ProjectMessagesSectionTab'
                    },
                ]
            }
        ]

    },
    components: {
        ProjectMessagesSectionTab: ProjectMessagesSectionTab,
    }
}

export default ProjectMessagesTabs

