import { getEnvVariable } from "../../modules/environmentalList";

const map = {
    "type": "map",
    "key": "EPS_map_GRID",   
    "order": 32,
    "options": {
        "mapkey":"EPS_map_GRID", 
        "routes": {
            "route": "/",
        },
        "hastaxlotlayer":"N",
        "alignitem": "left",
        "widgets": ["Home","Zoom", "BasemapGallery", "Search", "LayerList", "Measurement", "Print", "Select","LayerFilter"], // ["Locate", "Home", "Search", "Zoom", "Measurement", "Draw", "Sketch", "BasemapGallery", "LayerList", "Bookmark"], 
        "basemap": "streets",//"streets", "satellite", "hybrid", "terrain", "topo", "gray", "dark-gray", "oceans", "national-geographic", "osm", "dark-gray-vector", "gray-vector", "streets-vector", "topo-vector", "streets-night-vector", "streets-relief-vector", "streets-navigation-vector"
         "viewProperties": {
            // "extent": {
            //     "xmax": -8057114.558988379,
            //     "xmin": -8679311.969229413,
            //     "ymax": 4872179.908902848,
            //     "ymin": 4605567.554244282,
            //     "spatialReference": 102100 
            // },
            "zoom": 12,
            "center": [-8424171.646132585,4680123.621274437],
            "ui": {
                "components": ["attribution"]// ["compass", "attribution"] // empty the UI, except for attribution
            }
        },
        "printOptions":{
            "serviceURL":"https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task"         
        },         
        "zoomOptions": {
            "maxZoomed": false,
            "minZoomed": false
        }, 
        'searchbookmarks':{
            "drawTool":' Hello I ma me',
            "url":getEnvVariable('ArcGIS')+`/rest/services/AWS_KEPT_Test/Bookmarks/FeatureServer/0`,
            "defaultChoice":'E',
            "defaultName":'Bookmark #',
            "defaultSymbol":{
               type: "simple-fill",
               outline: {
                  style: "dash",
                  miterLimit: 16,
                  color: [0, 112, 255, 1]
               },
               color: [115, 223, 255, 0.24]
            },
            "defaultMessage":'Draw Box on Map for Bookmark',
            'appname':'INQUERY',
            'username':'13384'
      },
        "LayerOptionsConfig":
         {
            "Category": ["A","F"],
            "MapType": ["G"]   //A-Add Record,L-Landing,G-Grid,R-Record review            
         },
        "LayerOptions": [ 
                {
                    "ExtentZoom": true,//CenterAndZoom after load
                    "title": "CenterGeomService",
                    "type": "F",//F-FeatureLayer,G-GraphicsLayer
                    "url": getEnvVariable('ArcGIS')+`/rest/services/EPS/MasterTable_Center_Geom_Service/MapServer/0`,
                    "outFields": ["*"]          
                    //popupTemplate : {} //https://developers.arcgis.com/javascript/latest/api-reference/esri-PopupTemplate.html
                }
                // ,{
                //     "ExtentZoom": true,//CenterAndZoom after load
                //     "title": "STATE_MAINTAINED",
                //     "type": "M",//F-FeatureLayer,G-GraphicsLayer
                //     "url": `https://firstmap.gis.delaware.gov/arcgis/rest/services/Transportation/DE_STATE_MAINTAINED_ROADS/MapServer`,
                //     "outFields": ["*"]    
                // },               
        ],
        "FeatureLayerListOptions":[],
    }
};

export default map;