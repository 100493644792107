import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setSectionsReload, snackbarMessage } from '../../core/actions';
import ConfirmationDialog from "../../core/controls/components/confirmationPopup";
import FormHeader from '../../core/modules/form/cformheader';
import { GetData, PutData } from '../../core/modules/form/formsapi';
import { requestApi } from '../../core/modules/form/utilities';
import { getEnvVariable } from '../../modules/environmentalList';
const _ = require('lodash');

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

class CWorkflow extends Component {
    constructor(props) {
        super(props);
        this.isAdmin = this.props.options.params.isAdmin
        this.state = {
            options: this.props.options,
            SiteId: this.isAdmin && this.props && this.props.dataItem && this.props.dataItem['SiteId'] ? this.props.dataItem['SiteId'] : (this.props && this.props.extraProps && this.props.extraProps.SiteId ? this.props.extraProps.SiteId : null),
            RegionId: this.isAdmin && this.props && this.props.dataItem && this.props.dataItem['RegionId'] ? this.props.dataItem['RegionId'] : (this.props && this.props.extraProps && this.props.extraProps.RegionId ? this.props.extraProps.RegionId : null),
            DistrictId: this.isAdmin && this.props && this.props.dataItem && this.props.dataItem['DistrictId'] ? this.props.dataItem['DistrictId'] : (this.props && this.props.extraProps && this.props.extraProps.DistrictId ? this.props.extraProps.DistrictId : null),
            WorkflowId: this.isAdmin && this.props && this.props.dataItem && this.props.dataItem['WorkflowId'] ? this.props.dataItem['WorkflowId'] : (this.props && this.props.extraProps && this.props.extraProps.WorkflowId ? this.props.extraProps.WorkflowId : ""),
            //...this.props.options.params
            confirmationopen: false,
            workflowval: this.isAdmin && this.props && this.props.dataItem && this.props.dataItem['ParentWorkflowId'] ? this.props.dataItem['ParentWorkflowId'] : (this.props && this.props.extraProps && this.props.extraProps.ParentWorkflowId ? this.props.extraProps.ParentWorkflowId : ""),
            //---correct: duplicate use of same value:  workflowappealval: this.isAdmin && this.props && this.props.dataItem && this.props.dataItem['ParentWorkflowId'] ? this.props.dataItem['ParentWorkflowId'] : (this.props && this.props.extraProps && this.props.extraProps.ParentWorkflowId ? this.props.extraProps.ParentWorkflowId : ""),
            workflowitems: [],
            disableddropdown: false,
            resetbutton: false,
            appealresetbutton: false,
            ParentWorkflowId: this.isAdmin && this.props && this.props.dataItem && this.props.dataItem['ParentWorkflowId'] ? this.props.dataItem['ParentWorkflowId'] : (this.props && this.props.extraProps && this.props.extraProps.ParentWorkflowId ? this.props.extraProps.ParentWorkflowId : ""),
            defaultworkflow: "",
            tabvalue: 0,
            workflowappealval: this.isAdmin && this.props && this.props.dataItem && this.props.dataItem['ParentAppealWorkflowId'] ? this.props.dataItem['ParentAppealWorkflowId'] : (this.props && this.props.extraProps && this.props.extraProps.ParentAppealWorkflowId ? this.props.extraProps.ParentAppealWorkflowId : ""),
            ParentWorkflowappealId: this.isAdmin && this.props && this.props.dataItem && this.props.dataItem['ParentAppealWorkflowId'] ? this.props.dataItem['ParentAppealWorkflowId'] : (this.props && this.props.extraProps && this.props.extraProps.ParentAppealWorkflowId ? this.props.extraProps.ParentAppealWorkflowId : ""),
            disabledappealdropdown: false,
            appealWorkflowId: this.isAdmin && this.props && this.props.dataItem && this.props.dataItem['AppealWorkflowId'] ? this.props.dataItem['AppealWorkflowId'] : (this.props && this.props.extraProps && this.props.extraProps.AppealWorkflowId ? this.props.extraProps.AppealWorkflowId : ""),
            //  reloadWorkflowId: 0
        }
        this.configType = this.isAdmin ? this.props.accessPermissions.configType : this.props.extraProps.configType;
        this.configId = this.configType === 'R' ? this.state.RegionId : (this.configType === 'S' ? this.state.SiteId : (this.configType === 'D' ? this.state.DistrictId : '-1'));
        this.RoleRegionsurl = `/InternalAccessControlView/GetUserRoleConfigType/` + getEnvVariable('ModuleKey') + `/${this.configType}/?isAdmin=${this.isAdmin}&configIds=${this.configId}`;
        console.log("CWorkflow", this.state)
    }

    paramvalue = (getvaluefromtype, paramname, value) => {
        switch (getvaluefromtype) {
            case 'route':
                return this.props.match.params[paramname]
            case 'value':
            case 'props':
            default: return value
        }
    }

    componentDidMount() {
        //Load the the ID's based on the admin/application flow
        //var isAdmin = false;
        this.handleInitialState()

    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.refresh !=== this.props.refresh) {
    //         this.setState({ reloadWorkflowId: this.props.reloadWorkflowId }, () => {
    //             this.props.snackbarMessage('Please wait, Information is reloading ', 'info')
    //             this.handleInitialState()
    //         })
    //     }

    // }

    handleInitialState = () => {
        //Load the the ID's based on the admin/application flow
        //var isAdmin = false;
        const { options, extraProps } = this.props
        const { params } = options

        if (this.props && this.props.dataItem && this.props.dataItem.Id && (!this.state.SiteId || !this.state.RegionId || !this.state.DistrictId)) {
            let inforeadurl = {
                "url": getEnvVariable('AdminConfig') + `/api/SitePermitTypes/${this.props.dataItem.Id}`
            }
            requestApi({ get: inforeadurl }, '').then(res => {
                this.configId = this.configType === 'R' ? res.RegionId : (this.configType === 'S' ? res.SiteId : (this.configType === 'D' ? res.DistrictId : '-1'));
                this.RoleRegionsurl = `/InternalAccessControlView/GetUserRoleConfigType/` + getEnvVariable('ModuleKey') + `/${this.configType}/?isAdmin=${this.isAdmin}&configIds=${this.configId}`;
                this.setState({ SiteId: res.SiteId ? res.SiteId : null, RegionId: res.RegionId ? res.RegionId : null, DistrictId: res.DistrictId ? res.DistrictId : null })
            })
        }
        let tempparams = {}

        params && Object.keys(params).forEach(each => {
            if (typeof params[each] === 'object') {
                const { getvaluefromtype, paramname, value } = params[each]
                tempparams[each] = this.paramvalue(getvaluefromtype, paramname, value || (extraProps ? extraProps[paramname] : ''))
            }
            else tempparams[each] = params[each]
        })
        console.log('workflow params, extraProps---', tempparams, extraProps)
        //Load sitepermit types
        var sitepermittypeId
        if (this.isAdmin)
            sitepermittypeId = this.props.match.params && this.props.match.params.Id ? this.props.match.params.Id : (tempparams.SitePermitTypeId || this.props.dataItem['Id']);
        else
            sitepermittypeId = tempparams.SitePermitTypeId || this.props.extraProps.SitePermitTypeAppSettingsId;

        if (sitepermittypeId) {
            var sourceURL = getEnvVariable('AdminConfig') + `/api/SitePermitTypeAppSettings/${sitepermittypeId}/All`;
            //this.props.snackbarMessage('Please wait, Information is processing', 'info');
            GetData(sourceURL, "").then(results => {
                if (results && results.Id && results.Id > 0) {
                    tempparams.SitePermitTypeAppSettingsId = results.Id;
                    tempparams.sitepermittypeId = sitepermittypeId;
                    this.setPageState(tempparams);
                } else {
                    tempparams.SitePermitTypeAppSettingsId = -1;
                    tempparams.sitepermittypeId = sitepermittypeId;
                    this.setPageState(tempparams);
                }
            }).catch(ex => {
                console.log(ex);
                //this.props.snackbarMessage('Unable to process the workflow ', 'error')
            })

        } else {
            this.setPageState(tempparams);
        }
    }

    setPageState(tempparams) {
        this.setState({
            ...tempparams
        }, () => {
            if (this.isAdmin) {
                this.loadAdminflow();
            } else {
                this.loadMasterflow();
            }
        })
    }



    loadAdminflow() {
        const {  sitepermittypeId } = this.state;
        var sourceURL = getEnvVariable('AdminConfig') + `/api/SitePermitTypeWorkflow/${sitepermittypeId}/All`;
        this.props.snackbarMessage('Please wait, Information is processing', 'info');
        GetData(sourceURL, "").then(results => {
            if (results && results.length > 0) {
                var firstResult = results[0];
                this.setState({ ...firstResult });
            }
            this.workflowprocessAdmin();
        }).catch(ex => {
            this.props.snackbarMessage('Unable to process the workflow ', 'error')
        })
    }

    loadMasterflow() {
        const { WorkflowId, appealWorkflowId, ParentWorkflowappealId, ParentWorkflowId, ApplicationID,  sitepermittypeId /*sitePermitTypeId*/ } = this.state;
        var sourceURL = getEnvVariable('AdminConfig') + "/api/SitePermitTypeWorkflow/" + sitepermittypeId + `/${this.configId}/SitePermitWorkFlows`;
        if (sitepermittypeId && ApplicationID) {
            this.props.snackbarMessage('Please wait, Information is processing', 'info');
            GetData(sourceURL, "").then(results => {
                if (results && results.length > 0) {
                    let defaultworkflowval = null
                    let defaultworkflowitem = _.find(results, { IsDefault: 'Y' })
                    defaultworkflowval = defaultworkflowitem ? defaultworkflowitem.WorkflowId : null
                    let loadworkflow = WorkflowId === "" ? _.find(results, { IsDefault: 'Y' }) : ParentWorkflowId !== 0 ? _.find(results, { "WorkflowId": Number(ParentWorkflowId) }) : null
                    var firstResult = loadworkflow ? loadworkflow : results[0];
                    let loadappealworkflow = appealWorkflowId === "" ? _.find(results, { IsDefault: 'Y' }) : ParentWorkflowappealId !== 0 ? _.find(results, { "WorkflowId": Number(ParentWorkflowappealId) }) : null
                    var firstappealResult = loadappealworkflow ? loadappealworkflow : results[0];
                    this.setState({
                        "ParentWorkflowId": WorkflowId ? WorkflowId : firstResult.WorkflowId, workflowitems: results, workflowval: loadworkflow ? firstResult.WorkflowId : "", disableddropdown: WorkflowId ? true : false, resetbutton: WorkflowId ? true : false, defaultworkflow: defaultworkflowval,
                        "ParentWorkflowappealId": appealWorkflowId ? appealWorkflowId : firstappealResult.WorkflowId, workflowitems: results, workflowappealval: loadappealworkflow ? firstappealResult.WorkflowId : "", disabledappealdropdown: appealWorkflowId ? true : false, appealresetbutton: appealWorkflowId ? true : false, defaultworkflow: defaultworkflowval
                    });
                } else {
                    /* Loading workflow without default workflow */
                    this.setState({ "ParentWorkflowId": 0, 'ParentWorkflowappealId': 0, disabledappealdropdown: false, disableddropdown: false, resetbutton: false, appealresetbutton: false, workflowval: "", workflowappealval: "" });
                }
                if (this.state.tabvalue === 0) {
                    this.workflowprocessMain();
                }
                else {
                    this.workflowAppealprocessMain();
                }

            }).catch(ex => {
                this.props.snackbarMessage('Unable to process the workflow ', 'error')
            })
        } else this.props.snackbarMessage('Unable to process the workflow ', 'error')
    }


    workflowprocessAdmin = () => {
        let inthis = this;
        const { WorkflowId, reloadWorkflowId, SitePermitTypeAppSettingsId, ApplicationID/*sitePermitTypeId*/, RegionId } = inthis.state;
        var isAdd = (WorkflowId) <= 0;

        if (typeof window.workflowObj !== "undefined") {
            //For administration save
            if (isAdd) {
                inthis.props.snackbarMessage('Please wait, Information is processing', 'info');
                window.workflowObj.render({
                    isAdmin: inthis.isAdmin,
                    el: "#play", userdata: inthis.RoleRegionsurl, success: function (e) {
                        console.log(e);
                        if (e.id) {
                            let resultdata = {
                                isDefault: 'N',
                            }
                            var sourceURL = getEnvVariable('AdminConfig') + "/api/SitePermitTypeWorkflow/" + SitePermitTypeAppSettingsId + "/" + e.id + "/add";
                            let finalurl = { url: sourceURL }
                            let url = { post: finalurl }
                            requestApi(url, [], resultdata).then(response => {
                                inthis.props.snackbarMessage('Workflow Saved Successfully', 'success')
                                if (ApplicationID) { }
                                //inthis.props.setSectionsReload(true) //window.location.reload();
                                else inthis.props.onClose();
                            }).catch(ex => {
                                inthis.props.snackbarMessage('Unable to process the workflow ', 'error')
                            })

                        }
                    }
                });
            } else {
                inthis.props.snackbarMessage('Please wait, Information is processing', 'info');
                window.workflowObj.render({
                    isAdmin: inthis.isAdmin,
                    id: inthis.state.WorkflowId,
                    el: "#play", userdata: inthis.RoleRegionsurl, success: function (e) {
                        console.log(e);
                        inthis.props.snackbarMessage('Please wait, Information is processing', 'info');
                        if (ApplicationID) { }
                        //inthis.props.setSectionsReload(true) //window.location.reload();
                        else {
                            if (inthis.props.onClose)
                                inthis.props.onClose();
                            else
                                inthis.props.snackbarMessage('Updated sucessfully', 'success');
                        }

                    }
                });
            }

        }
    }

    reload = () => {
        const current = this.props.location.pathname;
        this.props.history.replace(`/reload`);
        setTimeout(() => {
            this.props.history.replace(current);
        });
    }

    handleBack = () => {
        //this.props.onClose();
        // this.props.handleClose();
        // this.props.setSectionsReload(true)
        this.reload();
    }
    workflowprocessMain = () => {
        let inthis = this;
        const { WorkflowId, reloadWorkflowId, ParentWorkflowId, SitePermitTypeAppSettingsId, ApplicationID, RegionId, sitepermittypeId } = inthis.state;
        var isAdd = (WorkflowId) <= 0;
        if (typeof window.workflowObj !== "undefined") {
            //When loading from the application/permit will have parentid
            if (isAdd) {
                inthis.props.snackbarMessage('Please wait, Information is processing', 'info');
                window.workflowObj.render({
                    parentid: ParentWorkflowId,
                    isAdmin: inthis.isAdmin,
                    el: "#play", userdata: inthis.RoleRegionsurl, success: function (e) {
                        var parentworkflowidval = inthis.state.ParentWorkflowId !== "" ? inthis.state.ParentWorkflowId : 0
                        let workflowid = e.id ? e.id : inthis.state.WorkflowId
                        var applicationUpdateUrl = getEnvVariable('Application') + "/api/Application/UpdateWorkflow/" + sitepermittypeId + "/" + ApplicationID + "?&status=&workflowid=" + workflowid + "&parentworkflowid=" + parentworkflowidval;
                        inthis.setState({ WorkflowId: e.id ? e.id : inthis.state.WorkflowId, reloadWorkflowId: e.id ? e.id : inthis.state.WorkflowId }, () => {
                            PutData(applicationUpdateUrl, null).then(function (res) {
                                window.workflowObj.data.submit(e.id, function () {
                                    //  inthis.props.handlereload(inthis.state.reloadWorkflowId)
                                    inthis.props.snackbarMessage('Workflow Saved Successfully', 'success');
                                    //window.location.reload();
                                    inthis.props.setSectionsReload(true);
                                    inthis.handleBack()

                                }, function () {
                                    inthis.props.snackbarMessage('Unable to process the workflow ', 'error')
                                });
                            });
                        })
                    }
                });
            }
            //     PutData(applicationUpdateUrl, null).then(function (res) {

            //         inthis.setState({disableddropdown: true, resetbutton: true})
            //         inthis.props.snackbarMessage('Workflow Saved Successfully', 'success');
            //         window.workflowObj.data.submit(e.id, function () {
            //             window.workflowObj.data.getTasks(function (q) {
            //                 var currTask = null;
            //                 for (var i = 0; i < q.length; i++) {
            //                     var task = q[i];
            //                     if (task.flowid === e.id) {
            //                         currTask = task;
            //                         break;
            //                     }
            //                 }
            //                 // if (currTask !== null) {
            //                 //     var workflowChangeObj = { task: task, comment: "No Comment", action: "Started" }
            //                 //     window.workflowObj.data.complete(workflowChangeObj, function () {
            //                 //         let workflowStatusUpdateUrl = getEnvVariable('Application') + "/api/Application/UpdateWorkflow/" + sitepermittypeId + "/" + ApplicationID + "?&status=" + "Started" + "&workflowid=" + workflowid;
            //                 //         PutData(workflowStatusUpdateUrl, null).then(function (res1) {
            //                 //             inthis.props.snackbarMessage('Workflow Saved Successfully', 'success');
            //                 //             //window.location.reload();
            //                 //             inthis.props.setSectionsReload(true)
            //                 //         })
            //                 //     })
            //                 // }
            //             })

            //         })



            //     }, function () {
            //         inthis.props.snackbarMessage('Unable to process the workflow ', 'error')
            //     });
            // });
            else {

                inthis.props.snackbarMessage('Please wait, Information is processing', 'info');
                window.workflowObj.render({
                    isAdmin: inthis.isAdmin,
                    id: WorkflowId,
                    parentid: ParentWorkflowId,
                    el: "#play", userdata: inthis.RoleRegionsurl, success: function (e) {
                        //window.workflowObj.data.submit(e.id, function () {
                        inthis.props.snackbarMessage('Workflow Saved Successfully', 'success');
                        inthis.props.setSectionsReload(true)
                        //window.location.reload();
                        //}, function () { inthis.props.snackbarMessage('Unable to process the workflow ', 'error'); }
                        // )
                    }
                });
            }
        }
    }

    workflowAppealprocessMain = () => {
        let inthis = this;
        const { appealWorkflowId, reloadWorkflowId, ParentWorkflowappealId, SitePermitTypeAppSettingsId, ApplicationID, RegionId, sitepermittypeId } = inthis.state;
        var isAdd = (appealWorkflowId) <= 0;
        if (typeof window.workflowObj !== "undefined") {
            if (isAdd) {
                inthis.props.snackbarMessage('Please wait, Information is processing', 'info');
                window.workflowObj.render({
                    parentid: ParentWorkflowappealId ? ParentWorkflowappealId : 0,
                    isAdmin: inthis.isAdmin,
                    el: "#play", userdata: inthis.RoleRegionsurl, success: function (e) {
                        var parentworkflowidval = inthis.state.ParentWorkflowappealId && inthis.state.ParentWorkflowappealId !== "" ? inthis.state.ParentWorkflowappealId : 0
                        let workflowid = e.id ? e.id : inthis.state.appealWorkflowId
                        var applicationUpdateUrl = getEnvVariable('Application') + "/api/Application/UpdateAppealWorkflow/" + sitepermittypeId + "/" + ApplicationID + "?&status=&appealWorkflowid=" + workflowid + "&parentAppealworkflowid=" + parentworkflowidval;
                        inthis.setState({ appealWorkflowId: e.id ? e.id : inthis.state.appealWorkflowId, reloadWorkflowId: e.id ? e.id : inthis.state.appealWorkflowId }, () => {
                            PutData(applicationUpdateUrl, null).then(function (res) {
                                window.workflowObj.data.submit(e.id, function () {
                                    inthis.props.snackbarMessage('Workflow Saved Successfully', 'success');
                                    inthis.props.setSectionsReload(true);
                                    inthis.handleBack()
                                }, function () {
                                    inthis.props.snackbarMessage('Unable to process the workflow ', 'error')
                                });
                            });
                        })
                    }
                });
            }
            else {
                inthis.props.snackbarMessage('Please wait, Information is processing', 'info');
                window.workflowObj.render({
                    isAdmin: inthis.isAdmin,
                    id: appealWorkflowId,
                    parentid: ParentWorkflowappealId,
                    el: "#play", userdata: inthis.RoleRegionsurl, success: function (e) {
                        inthis.props.snackbarMessage('Workflow Saved Successfully', 'success');
                        inthis.props.setSectionsReload(true)
                    }
                });
            }
        }
    }


    handleReset = () => {
        this.setState({ confirmationopen: true })
    }

    handleClose = () => {
        this.setState({ confirmationopen: false })
    };

    confirmation = () => {
        if (this.state.tabvalue === 0) {
            this.setState({ confirmationopen: false, disableddropdown: false, workflowval: this.state.defaultworkflow, ParentWorkflowId: this.state.defaultworkflow, WorkflowId: "" }, () => this.workflowprocessMain())
        }
        else {
            this.setState({ confirmationopen: false, disabledappealdropdown: false, workflowappealval: this.state.defaultworkflow, ParentWorkflowappealId: this.state.defaultworkflow, appealWorkflowId: "" }, () => this.workflowAppealprocessMain())
        }
        //set parent workflowid to null
    }

    handleDropdownChange = (e) => {
        this.setState({ workflowval: e.target.value, ParentWorkflowId: e.target.value, disableddropdown: false, WorkflowId: "" }, () => this.workflowprocessMain());
    }

    handleTabChange = (event, newValue) => {
        this.setState({ tabvalue: newValue }, () => this.handleInitialState())
    }

    handleAppealsDropdownChange = (e) => {
        this.setState({ workflowappealval: e.target.value, ParentWorkflowappealId: e.target.value, disabledappealdropdown: false, appealWorkflowId: "" }, () => this.workflowAppealprocessMain());
    }

    displayworkflow = () => {
        const { styles, designprops, headerProps } = this.props.options
        const { siteID, opensnackbar, RegionId, workflowitems } = this.state
        const { backbutton } = headerProps ? headerProps : {}
        console.log('siteId------', siteID, RegionId)
        return (
            <div>
                <FormControl fullWidth className="col-sm-6 pl-3 pt-2">
                    <InputLabel shrink className="col-sm-6 pl-3 pt-2">Select Workflow</InputLabel>
                    <Select
                        value={this.state.tabvalue === 0 ? this.state.workflowval : this.state.workflowappealval}
                        onChange={this.state.tabvalue === 0 ? this.handleDropdownChange : this.handleAppealsDropdownChange}
                        displayEmpty
                        disabled={this.state.tabvalue === 0 ? this.state.disableddropdown : this.state.disabledappealdropdown}
                    >
                        <MenuItem value="">
                            <em>Create new workflow </em>
                        </MenuItem>
                        {workflowitems.map(each => {
                            return (
                                < MenuItem value={each.WorkflowId}>{each.WorkflowName}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <div id="play" className="workflow-wrapper">
                </div>
            </div>
        )
    }

    render() {
        const { styles, designprops, headerProps } = this.props.options
        const { siteID, opensnackbar, RegionId, workflowitems } = this.state
        const { backbutton } = headerProps ? headerProps : {}
        console.log('siteId------', siteID, RegionId)
        return (
            <Fragment>
                <div className="sketch_ToolBox_Widget" style={{ ...styles }} >
                    {!designprops && <FormHeader title={`Workflow`} backbutton={backbutton} handleBack={this.handleBack} resetbutton={this.props && this.props.extraProps &&
                        this.props.extraProps.ApprovedFlag && this.props.extraProps.ApprovedFlag === 'Y' ? false : true} handleReset={this.handleReset} />}
                    <ConfirmationDialog value={this.state.confirmationopen} description="After reset if new workflow is not saved then previous workflow will be saved."
                        title="Do you want to reset Workflow?" onSubmit={this.confirmation} onClose={this.handleClose} />

                    <AppBar position="static" color="white">
                        <Tabs
                            value={this.state.tabvalue}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="on"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label="Workflow" {...a11yProps(0)} />
                            {this.props.extraProps.FileAppeal === "Y" && <Tab label="Appeals" {...a11yProps(1)} />}
                        </Tabs>
                    </AppBar>

                    <TabPanel value={this.state.tabvalue} index={0}>{this.displayworkflow()}</TabPanel>
                    <TabPanel value={this.state.tabvalue} index={1}>{this.displayworkflow()}</TabPanel>
                </div>
            </Fragment>

        )

    }


    componentDidCatch(error, info) {
        console.log(error);
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage, setSectionsReload }, dispatch);
}

CWorkflow = connect(null, mapActions)(CWorkflow)
export default withRouter(CWorkflow);
