import { getCalculatedAmount } from '../../../src/payment/manager';
import { getEnvVariable } from '../../../src/modules/environmentalList';
import { requestApi } from '../../../src/core/modules/form/utilities';
import moment from 'moment';

export function GetRecordDataByKeys(sitepermittypeid, id, keys) {
    return new Promise((resolve, reject) => {
        let reqpath = `${getEnvVariable('Application')}/api/SitePermitSectionFieldsView/DataByKeys/${sitepermittypeid}/${id}`;
        requestApi({ post: { url: reqpath } }, null, keys, null, true)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetDomainValues(key) {
    return new Promise((resolve, reject) => {
        let path = getEnvVariable('Admin') + `/api/Domains/values?domainkey=${key}`;
        requestApi({ get: { url: path } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetStateDomainValues() {
    return new Promise((resolve, reject) => {
        let path = getEnvVariable('MasterAdmin') + `/api/Domains/values?domainkey=US_STATES`;
        requestApi({ get: { url: path } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetSitePermitType(id) {
    return new Promise((resolve, reject) => {
        let sitepermittypepath = getEnvVariable('AdminConfig') + `/api/${getEnvVariable('ModuleKey')}SitePermitTypes/` + `${id}`;
        requestApi({ get: { url: sitepermittypepath } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetInvoiceCycle(id) {
    return new Promise((resolve, reject) => {
        let url = getEnvVariable('Admin') + `/api/InvoiceCycle/${id}`;
        requestApi({ get: { url: url } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetRenewalPermits(invoiceid, ownerid, invoicetype, submodulekey) {
    return new Promise((resolve, reject) => {
        //Records/{invoiceid}/{ownerid}/{invoicetype}
        let recordpath = getEnvVariable('Payment') + `/api/InvoiceCycle/Records/${submodulekey}/${invoiceid}/${ownerid}/${invoicetype}`;
        requestApi({ get: { url: recordpath } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetRenewalPermitsOfType(invoiceid, ownerid, invoicetype, submodulekey, sitepermittype) {
    return new Promise((resolve, reject) => {
        //Records/{invoiceid}/{ownerid}/{invoicetype}
        let recordpath = getEnvVariable('Payment') + `/api/InvoiceCycle/Records/${submodulekey}/${invoiceid}/${ownerid}/${invoicetype}/${sitepermittype}`;
        requestApi({ get: { url: recordpath } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetInvoiceCycleDetails(cycle, appdata) {
    let nextInvoiceCycleDate = '';
    let invoiceDueDate = '';
    let datePrintedOnInvoice = '';
    let returndetails = { NextInvoiceCycleDate: '', InvoiceDueDate: '', DatePrintedOnInvoice: '' };
    let authorizeddate = new Date();
    if (appdata['AUTHORIZED_DATE']) {
        authorizeddate = stringToDate(appdata['AUTHORIZED_DATE'], "mm/dd/yyyy", "/");
    }
    let authorizedyear = authorizeddate.getFullYear();

    if (cycle) {
        let datecal = '';
        let invoiceperiod = cycle.InvoicePeriod; // Number
        let partinvoicedate = cycle.GenerateInvoiceDate;
        let cycleinvoiceon = cycle.GenerateInvoiceOn; //P-Permitted, or specific date
        let invoicecyclespec = cycle.InvoiceType //M/Y/D
        //TBD for every
        if (cycle.IsInvoiced === 'Y') {
            if (cycleinvoiceon === 'D') {
                datecal = cycleinvoiceon + '/' + invoiceperiod
            }
        }

        // Invoice On
        let currentDate = new Date();
        let currentyear = new Date().getFullYear();
        let evenyear = isEven(currentyear) ? currentyear : currentyear + 1;
        let oddyear = isOdd(currentyear) ? currentyear : currentyear + 1;

        if (cycle.IsInvoiced === 'N') {

            if (cycle.InvoiceOn === 'E') {
                if (cycleinvoiceon === 'D')
                    datecal = cycle.GenerateInvoiceDate + '/' + evenyear.toString();
                if (cycleinvoiceon === 'P') {
                    // Check authrozied is even year and date is
                    datecal = authorizeddate.setFullYear(evenyear).toString('mm/dd/yyyy');
                }
            }
            if (cycle.InvoiceOn === 'O') {
                if (cycleinvoiceon === 'D')
                    datecal = cycle.GenerateInvoiceDate + '/' + oddyear.toString()
                if (cycleinvoiceon === 'P') {
                    datecal = authorizeddate.setFullYear(oddyear).toString('mm/dd/yyyy');
                }
            }
            let caldate = stringToDate(datecal, "mm/dd/yyyy", "/");
            nextInvoiceCycleDate = caldate < currentDate ? AddYearToDate(caldate, 2) : caldate;
            returndetails.NextInvoiceCycleDate = nextInvoiceCycleDate;
        }

        //Date Printed On Invoice
        if (cycle.DateOnInvoice === 'D') {
            let datetoprintoninvoice = '';
            if (cycle.InvoiceOn === 'E')
                datetoprintoninvoice = cycle.DateToPrintOnInvoice + '/' + evenyear.toString();
            if (cycle.InvoiceOn === 'O')
                datetoprintoninvoice = cycle.DateToPrintOnInvoice + '/' + oddyear.toString();

            if (!datetoprintoninvoice || datetoprintoninvoice === '') {
                datetoprintoninvoice = cycle.DateToPrintOnInvoice + '/' + currentyear
            }
            let caldate = stringToDate(datetoprintoninvoice, "mm/dd/yyyy", "/");
            returndetails.DatePrintedOnInvoice = caldate < currentDate ? AddYearToDate(caldate, 1) : caldate;
        }
        if (cycle.DateOnInvoice === 'P') {
            returndetails.DatePrintedOnInvoice = currentDate;
        }

        //Payment Due On Invoice
        if (cycle.PaymentDueOnInvoice === 'P') {
            let invduedate = currentDate;
            invduedate.setDate(invduedate.getDate() + cycle.PaymentDueDays);
            returndetails.InvoiceDueDate = invduedate;
        }
        if (cycle.PaymentDueOnInvoice === 'D') {
            let invduedate = cycle.PaymentDueDate + '/' + currentyear;
            let caldate = stringToDate(invduedate, "mm/dd/yyyy", "/");
            returndetails.InvoiceDueDate = caldate < currentDate ? AddYearToDate(caldate, 1) : caldate;
        }

    }
    return returndetails;
}

export function GetNextInvoiceCycleDate(cycle) {
    let nextInvoiceCycleDate = '';
    let invoiceDueDate = '';
    let datePrintedOnInvoice = '';
    let returndetails = { NextInvoiceCycleDate: '', InvoiceDueDate: '', DatePrintedOnInvoice: '' };

    if (cycle) {
        let datecal = '';
        let invoiceperiod = cycle.InvoicePeriod; // Number
        let partinvoicedate = cycle.GenerateInvoiceDate;
        let cycleinvoiceon = cycle.GenerateInvoiceOn; //P-Permitted, or specific date
        let invoicecyclespec = cycle.InvoiceType //M/Y/D
        //TBD for every
        if (cycle.IsInvoiced === 'Y') {
            if (cycleinvoiceon === 'D') {
                datecal = cycleinvoiceon + '/' + invoiceperiod
            }
        }

        // Invoice On
        if (cycle.IsInvoiced === 'N') {
            let currentyear = new Date().getFullYear();
            let evenyear = isEven(currentyear) ? currentyear : currentyear + 1;
            let oddyear = isOdd(currentyear) ? currentyear : currentyear + 1;
            if (cycle.InvoiceOn === 'E') {
                if (cycleinvoiceon === 'D')
                    datecal = cycle.GenerateInvoiceDate + '/' + evenyear.toString();
                if (cycleinvoiceon === 'P')
                    datecal = cycle.GenerateInvoiceDate + '/' + evenyear.toString();
            }
            if (cycle.InvoiceOn === 'O') {
                if (cycleinvoiceon === 'D')
                    datecal = cycle.GenerateInvoiceDate + '/' + oddyear.toString()
                if (cycleinvoiceon === 'P')
                    datecal = cycle.GenerateInvoiceDate + '/' + oddyear.toString()
            }
            let caldate = stringToDate(datecal, "mm/dd/yyyy", "/");
            nextInvoiceCycleDate = caldate < new Date() ? AddYearToDate(caldate, 2) : caldate;
        }
    }
    return nextInvoiceCycleDate;
}


function isEven(n) {
    return n % 2 === 0;
}

function isOdd(n) {
    return Math.abs(n % 2) === 1;
}

function IsPassedDate(datestring) {
    let date = stringToDate(datestring, "mm/dd/yyyy", "/");
    return date <= new Date()
}

function AddYearToDate(d, adder) {
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    return new Date(year + adder, month, day);
}

function AddDaysToDate(date, days) {
    let mdate = moment(date).add(days, 'days');
    // var result = new Date(date);
    // result.setDate(result.getDate() + days);
    // return result;
    return mdate.format('MM/DD/YYYY');
}

function stringToDate(_date, _format, _delimiter) {
    if (_date) {
        var formatLowerCase = _format.toLowerCase();
        var formatItems = formatLowerCase.split(_delimiter);
        var dateItems = _date.split(_delimiter);
        var monthIndex = formatItems.indexOf("mm");
        var dayIndex = formatItems.indexOf("dd");
        var yearIndex = formatItems.indexOf("yyyy");
        var month = parseInt(dateItems[monthIndex]);
        month -= 1;
        var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
        return formatedDate;
    } else { return new Date() }

}

function groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export function ReGenerateInvoice(RegenerateProps) {
    // let invoiceId, applicantid, type, recordid, isCancel, submodulekey
    return new Promise((resolve, reject) => {
        //Get any business rules available for the fee. If no, generate using static fee.

        GetRenewalPermitsOfType(RegenerateProps.InvoiceId, RegenerateProps.ApplicantId, RegenerateProps.InvoiceType, RegenerateProps.SubModuleKey, RegenerateProps.SitePermitTypeId).then(renewalres => {
            let renewalpermits = renewalres;
            if (renewalpermits && Array.isArray(renewalpermits) && renewalpermits.length > 0) {
                let groupbyowner = groupArrayOfObjects(renewalpermits, "SitePermitTypeId");
                if (groupbyowner) {
                    let promises = [];
                    renewalpermits.forEach(element => {
                        promises.push(GetComputedFee(element['SitePermitTypeId'], 'SIGN_INFORMATION', { "Id": element["Id"] }))
                    });
                    // for (const [key, value] of Object.entries(groupbyowner)) {
                    //     promises.push(GetComputedFee(key, 'SIGN_INFORMATION', { "SITE_PERMIT_TYPE_ID": key }))
                    // }
                    Promise.all(promises).then(allres => {
                        console.log(allres);
                        //If business rules exists modifing the fee details else processing from the static fee data.
                        if (allres && Array.isArray(allres)) {
                            var mergedRef = [].concat.apply([], allres);
                            var merged = mergedRef.filter(value => Object.keys(value).length !== 0);
                            if (merged && merged.length > 0) {
                                renewalpermits.forEach(permit => {
                                    let BRFee = merged[0][permit.Id]
                                    if (BRFee) {
                                        let dynamicpermitfee = findPropFromObject(BRFee, 'DYNAMIC_PERMIT_FEE');
                                        permit.PermitRenewalFee = dynamicpermitfee[0]
                                    }
                                })
                            }
                        }
                        let wrapperProps = { ...RegenerateProps, RenewalPermits: renewalpermits }
                        ReGenerateInvoiceWrapper(wrapperProps).then(invoiceres => {
                            resolve(invoiceres)
                        })
                    })
                }
            } else {
                //reject['NORECORDS']  // correct: Expected an assignment or function call and instead saw an expression  no-unused-expressions
            }
        })

    })
}

export function ReGenerateInvoiceWrapper(props) {
    //let applicantid, recordid,invoiceId,isCancel, renewalpermits
    return new Promise((resolve, reject) => {
        const dataSet = []
        let dset = []
        props.RenewalPermits && props.RenewalPermits.forEach(permit => {
            let appdata = { "Id": permit.Id, "AUTHORIZED_DATE": permit.AuthorizedDate }
            let invoicecycleDetails = GetInvoiceCycleDetails(permit, appdata);
            console.log(permit.Id, invoicecycleDetails);
            let NextInvoiceCycleDate = props.InvoiceDate ? props.InvoiceDate : invoicecycleDetails.NextInvoiceCycleDate;
            let DatePrintedOnInvoice = props.InvoiceDate ? props.InvoiceDate : invoicecycleDetails.DatePrintedOnInvoice;
            let InvoiceDueDate = props.InvoiceDueDays ? AddDaysToDate(DatePrintedOnInvoice, props.InvoiceDueDays) : invoicecycleDetails.InvoiceDueDate;
            dset.push({
                "RecordID": permit.Id,
                "ApplicantID": permit.OwnerId,
                "SitePermitTypeId": permit.sitepermittypeid,
                "PermitNumber": permit.PermitNo,
                "District": permit.DistrictName,
                "County": permit.County || "",
                "PermitFee": permit.PermitRenewalFee,
                "InvoiceType": 'PermitRenewal',
                "RegenerateId": props.InvoiceId > 0 ? props.InvoiceId : null,
                "CancelParent": props.CancelParent || "N",
                "InvoiceCyleDate": NextInvoiceCycleDate,
                "InvoiceCyleDueDate": InvoiceDueDate,
                "DatePrintedOnInvoice": DatePrintedOnInvoice,
            })
        });
        dataSet.push(dset);
        if (dset.length > 0) {
            const invoicesRef = props.RenewalPermits[0].PermitInvoiceSchema //"c6dccc56-3156-4efd-817d-b53ca554c957";
            let propsrecordid = props.RecordId ? props.RecordId : 0
            GenerateInvoiceWrapper(props.ApplicantId, propsrecordid, dataSet, invoicesRef).then(res => {
                resolve(res);
            })
        }
    })
}

export function GetComputedFee(sitepermittypeid, sectionkey, filters) {
    return new Promise((resolve, reject) => {
        let urlpath = `${getEnvVariable('Application')}/api/BusinessRules/SitePermitTypeCalculated/${sitepermittypeid}?sectionKey=${sectionkey}&OnDemand=Y`;
        requestApi({ post: { url: urlpath } }, null, filters, null, true)
            .then(res => {
                //let resdetials = findPropFromObject(res,feekey)
                resolve(res)
            }).catch(ex => { resolve({}) })
    })
}

export function findPropFromObject(obj, prop) {
    var result = [];
    function recursivelyFindProp(o, keyToBeFound) {
        Object.keys(o).forEach(function (key) {
            if (typeof o[key] === 'object') {
                recursivelyFindProp(o[key], keyToBeFound);
            } else {
                if (key === keyToBeFound) result.push(o[key]);
            }
        });
    }
    recursivelyFindProp(obj, prop);
    return result;
}

export function GenerateInvoice(appdata, type) {
    return new Promise((resolve, reject) => {
        GetSitePermitType(appdata["SITE_PERMIT_TYPE_ID"]).then(sitepermittype => {
            if (sitepermittype && sitepermittype.Id > 0) {
                // Check for further settings
                if (sitepermittype.BillingOption === 'Y') {
                    let sitepermittypedetails = sitepermittype;

                    if ((type === 'APPLICATION' && sitepermittype.ApplicationFeeType === 'D') ||
                        (type === 'PRORATE' && sitepermittype.ProrateFeeType === 'D') ||
                        (type === 'PERMITRENEWAL' && sitepermittype.PermitRenewalFeeType === 'D')) {

                        // Check if fee has any dynamic fee and generate fee
                        GetComputedFee(appdata["SITE_PERMIT_TYPE_ID"], 'SIGN_INFORMATION', { "Id": appdata["Id"] }).then(computedfeeres => {
                            console.log(computedfeeres);
                            if (computedfeeres) {
                                let dynamicappfee = findPropFromObject(computedfeeres, 'DYNAMIC_APPLICATION_FEE');
                                let dynamicproratefee = findPropFromObject(computedfeeres, 'DYNAMIC_PRORATE_FEE');
                                let dynamicpermitfee = findPropFromObject(computedfeeres, 'DYNAMIC_PERMIT_FEE');

                                if (dynamicappfee.length > 0 && sitepermittype.ApplicationFeeType === 'D')
                                    sitepermittypedetails.ApplicationFee = dynamicappfee[0];
                                if (dynamicproratefee.length > 0 && sitepermittype.ProrateFeeType === 'D')
                                    sitepermittypedetails.PermitRenewalFee = dynamicproratefee[0];
                                if (dynamicpermitfee.length > 0 && sitepermittype.PermitRenewalFeeType === 'D')
                                    sitepermittypedetails.ProrateFee = dynamicpermitfee[0];
                            }
                            GenerateInvoiceController(type, appdata, sitepermittypedetails).then(invres => {
                                resolve(invres)
                            }).catch(ex => { reject(ex) })

                        })
                    } else {
                        GenerateInvoiceController(type, appdata, sitepermittypedetails).then(invres => {
                            resolve(invres)
                        }).catch(ex => { reject(ex) })
                    }

                } else {
                    reject({ "Error": "NOBILLING" })
                }
            } else {
                reject({ "Error": "NOSETTING" })
            }
        })
    })
}

export function GenerateInvoiceController(type, appdata, sitepermittypedetails) {
    return new Promise((resolve, reject) => {
        if (type === 'APPLICATION') {
            GenerateApplicationFeeInvoice(appdata, sitepermittypedetails).then(res => {
                resolve(res);
            }).catch(ex => { reject(ex) })
        }
        if (type === 'PRORATE') {
            GenerateProrateFeeInvoice(appdata, sitepermittypedetails).then(res => {
                resolve(res);
            }).catch(ex => { reject(ex) })
        }
        if (type === 'PERMITRENEWAL') {
            GeneratePermitFeeInvoice(appdata, sitepermittypedetails).then(res => {
                resolve(res);
            }).catch(ex => { reject(ex) })
        }
    })
}

export function GenerateApplicationFeeInvoice(appdata, sitepermittype) {
    return new Promise((resolve, reject) => {
        if (sitepermittype.HasApplicationFee === 'Y' && sitepermittype.ApplicationFee
            && sitepermittype.ApplicationFee > 0 && sitepermittype.ApplicationInvoiceSchema) {
            const dataSet = [
                [
                    {
                        "RecordID": appdata["Id"],
                        "ApplicantID": appdata["COMPANY_ID"],
                        "SitePermitTypeId": appdata["SITE_PERMIT_TYPE_ID"],
                        "PermitNumber": appdata["PERMIT_NO"],
                        "District": appdata["DISTRICT_ID"],
                        "County": appdata["COUNTY"],
                        "PermitFee": sitepermittype.ApplicationFee,
                        "InvoiceType": 'Application',
                        "RefType": appdata["KEY"]
                    }
                ]
            ]
            const invoicesRef = sitepermittype.ApplicationInvoiceSchema //"c6dccc56-3156-4efd-817d-b53ca554c957";
            GenerateInvoiceWrapper(appdata["COMPANY_ID"], appdata["Id"], dataSet, invoicesRef).then(res => {
                resolve(res);
            })
        } else {
            reject({ "Error": "NOAPPLICATIONFEE" })
        }
    })
}

export function GenerateProrateFeeInvoice(appdata, sitepermittype) {
    return new Promise((resolve, reject) => {
        if (sitepermittype.HasPorate === 'Y' && sitepermittype.ProrateFee && sitepermittype.ProrateFee > 0
            && sitepermittype.PorateInvoiceCycle && sitepermittype.ProrateInvoiceSchema) {
            GetInvoiceCycle(sitepermittype.PorateInvoiceCycle).then(invoicecycleres => {
                if (invoicecycleres) {
                    //let nextInvoiceCycleDate = GetNextInvoiceCycleDate(invoicecycleres);
                    let invoicecycleDetails = GetInvoiceCycleDetails(invoicecycleres, appdata);
                    console.log(invoicecycleDetails);

                    const dataSet = [
                        [
                            {
                                "RecordID": appdata["Id"],
                                "ApplicantID": appdata["COMPANY_ID"],
                                "SitePermitTypeId": appdata["SITE_PERMIT_TYPE_ID"],
                                "PermitNumber": appdata["PERMIT_NO"],
                                "District": appdata["DISTRICT_ID"],
                                "County": appdata["COUNTY"],
                                "PermitFee": sitepermittype.ProrateFee,
                                "CurrentDate": new Date(),
                                "InvoiceCyleDate": invoicecycleDetails.NextInvoiceCycleDate,
                                "InvoiceCyleDueDate": invoicecycleDetails.InvoiceDueDate,
                                "DatePrintedOnInvoice": invoicecycleDetails.DatePrintedOnInvoice,
                                "InvoiceType": 'Prorate',
                                "RefType": appdata["KEY"]
                            }
                        ]
                    ]
                    const invoicesRef = sitepermittype.ProrateInvoiceSchema //"52eff645-5222-4517-ae78-cee95276539d";

                    GenerateInvoiceWrapper(appdata["COMPANY_ID"], appdata["Id"], dataSet, invoicesRef).then(res => {
                        resolve(res);
                    })
                }

            })
        } else {
            reject({ "Error": "NOPRORATEFEE" })
        }
    })
}

export function GeneratePermitFeeInvoice(appdata, sitepermittype) {
    return new Promise((resolve, reject) => {

        if (sitepermittype.PermitRenewal === 'Y' && sitepermittype.PermitRenewalFee
            && sitepermittype.PermitRenewalFee > 0 && sitepermittype.PermitInvoiceSchema) {
            if (appdata.InvoiceDate) {
                const dataSet = [
                    [
                        {
                            "RecordID": appdata["Id"],
                            "ApplicantID": appdata["COMPANY_ID"],
                            "SitePermitTypeId": appdata["SITE_PERMIT_TYPE_ID"],
                            "PermitNumber": appdata["PERMIT_NO"],
                            "District": appdata["DISTRICT_ID"],
                            "County": appdata["COUNTY"],
                            "PermitFee": sitepermittype.PermitRenewalFee,
                            "InvoiceType": 'PermitRenewal',
                            "InvoiceCyleDate": appdata["InvoiceDate"],
                            "InvoiceCyleDueDate": AddDaysToDate(new Date(), appdata["InvoiceDueDays"]),
                            "DatePrintedOnInvoice": appdata["InvoiceDate"],
                            "RefType": appdata["KEY"]
                        }
                    ]
                ]
                const invoicesRef = sitepermittype.PermitInvoiceSchema //"c6dccc56-3156-4efd-817d-b53ca554c957";
                GenerateInvoiceWrapper(appdata["COMPANY_ID"], appdata["Id"], dataSet, invoicesRef).then(res => {
                    resolve(res);
                })
            } else {
                GetInvoiceCycle(sitepermittype.PermitInvoiceCycle).then(invoicecycleres => {
                    if (invoicecycleres) {
                        //let nextInvoiceCycleDate = GetNextInvoiceCycleDate(invoicecycleres);
                        let invoicecycleDetails = GetInvoiceCycleDetails(invoicecycleres, appdata);
                        console.log(invoicecycleDetails);

                        const dataSet = [
                            [
                                {
                                    "RecordID": appdata["Id"],
                                    "ApplicantID": appdata["COMPANY_ID"],
                                    "SitePermitTypeId": appdata["SITE_PERMIT_TYPE_ID"],
                                    "PermitNumber": appdata["PERMIT_NO"],
                                    "District": appdata["DISTRICT_ID"],
                                    "County": appdata["COUNTY"],
                                    "PermitFee": sitepermittype.PermitRenewalFee,
                                    "InvoiceType": 'PermitRenewal',
                                    "InvoiceCyleDate": invoicecycleDetails.NextInvoiceCycleDate,
                                    "InvoiceCyleDueDate": invoicecycleDetails.InvoiceDueDate,
                                    "DatePrintedOnInvoice": invoicecycleDetails.DatePrintedOnInvoice,
                                }
                            ]
                        ]
                        const invoicesRef = sitepermittype.PermitInvoiceSchema //"c6dccc56-3156-4efd-817d-b53ca554c957";
                        GenerateInvoiceWrapper(appdata["COMPANY_ID"], appdata["Id"], dataSet, invoicesRef).then(res => {
                            resolve(res);
                        })
                    }
                })
            }

        } else {
            reject({ "Error": "NOPERMITRENEWALFEE" })
        }
    })
}




export function GenerateInvoiceWrapper(ownerid, refid, dataSetIN, invoicesIN) {
    return new Promise((resolve, reject) => {
        const dataSet = dataSetIN;
        const invoices = invoicesIN.split(",");
        getCalculatedAmount(invoices, dataSet).then(resp => {
            console.log(resp);
            InsertInvoice(ownerid, refid, invoicesIN, resp).then(invoiceres => {
                console.log('invoice resp ----------', invoiceres);
                resolve(invoiceres)
            })
        })
    }).catch(ex => {
        console.error('Error in processing the invoice', ex)
    })
}

//Inserting invoice data into the database.
export function InsertInvoice(ownerid, refid, invoicesIN, resultdata) {
    return new Promise((resolve, reject) => {
        let invoiceentity = GetInvoiceEntityResult(ownerid, refid, invoicesIN, resultdata)
        let InvoiceCreatePath = `${getEnvVariable('Payment')}/api/Invoice/Create/${refid}`;
        requestApi({ post: { url: InvoiceCreatePath } }, null, invoiceentity, null, true)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function InsertInvoicePhysicalCheckPayment(invoiceid, paymentdetails) {
    return new Promise((resolve, reject) => {
        let InvoiceCreatePath = `${getEnvVariable('Payment')}/api/Invoice/PayChecks/${invoiceid}`;
        requestApi({ post: { url: InvoiceCreatePath } }, null, paymentdetails, null, true)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetInvoiceById(invoiceid) {
    return new Promise((resolve, reject) => {
        let InvoiceGetPath = `${getEnvVariable('Payment')}/api/Invoice/${invoiceid}`;
        requestApi({ get: { url: InvoiceGetPath } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetInvoicesList(invoiceidlist) {
    return new Promise((resolve, reject) => {
        let InvoiceGetPath = `${getEnvVariable('Payment')}/api/Invoice/GetList`;
        requestApi({ post: { url: InvoiceGetPath } }, null, invoiceidlist, null, true)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetInvoiceViewById(invoiceid) {
    return new Promise((resolve, reject) => {
        let InvoiceGetPath = `${getEnvVariable('Payment')}/api/InvoiceView/${invoiceid}`;
        requestApi({ get: { url: InvoiceGetPath } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetInvoice(refid) {
    return new Promise((resolve, reject) => {
        let InvoiceGetPath = `${getEnvVariable('Payment')}/api/Invoice/Record/${refid}`;
        requestApi({ get: { url: InvoiceGetPath } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetRecordInvoiceByType(refid, type) {
    return new Promise((resolve, reject) => {
        let InvoiceGetPath = `${getEnvVariable('Payment')}/api/Invoice/Record/${refid}/${type}`;
        requestApi({ get: { url: InvoiceGetPath } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetOwnerInvoice(refid, reftype) {
    return new Promise((resolve, reject) => {
        let InvoiceGetPath = `${getEnvVariable('Payment')}/api/Invoice/Owner/${refid}/${reftype}`+ `${localStorage.getItem('impersonate') ? '?impersonateFor=' + localStorage.getItem('impersonate') : ''}`;
        requestApi({ get: { url: InvoiceGetPath } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetOwnerInvoiceView(refid, reftype, modules) {
    return new Promise((resolve, reject) => {
        let InvoiceGetPath = `${getEnvVariable('Payment')}/api/InvoiceView/Owner/${refid}/${reftype}` + `${localStorage.getItem('impersonate') ? '?impersonateFor=' + localStorage.getItem('impersonate') : ''}`;
        requestApi({ post: { url: InvoiceGetPath } }, null, modules, null, true)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetPaymentInformation(refid) {
    return new Promise((resolve, reject) => {
        let InvoiceGetPath = `${getEnvVariable('Payment')}/api/Invoice/PaymentInformation/${refid}`;
        requestApi({ get: { url: InvoiceGetPath } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetOwnerPaymentsInformation(refid) {
    return new Promise((resolve, reject) => {
        let InvoiceGetPath = `${getEnvVariable('Payment')}/api/Invoice/Owner/PaymentInformation/${refid}`+ `${localStorage.getItem('impersonate') ? '?impersonateFor=' + localStorage.getItem('impersonate') : ''}`;
        requestApi({ get: { url: InvoiceGetPath } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetInvoicePaymentInformation(refid, invids) {
    return new Promise((resolve, reject) => {
        let InvoiceGetPath = `${getEnvVariable('Payment')}/api/Invoice/InvoicePaymentInformation/${refid}`;
        requestApi({ post: { url: InvoiceGetPath } }, null, invids, null, false)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetBaseRecordDetail(refid) {
    return new Promise((resolve, reject) => {
        let InvoiceGetPath = `${getEnvVariable('Payment')}/api/InvoiceCycle/RecordDetail/${refid}`;
        requestApi({ get: { url: InvoiceGetPath } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

function DateTimeToZone(date) {
    return date.toLocaleString('en-US', { timeZone: 'America/New_York' });
}

export function GetInvoiceEntityResult(ownerid, refid, invoicesIN, resultdata) {
    let currentDate = new Date();
    let dueDateCalc = new Date();
    let extendedDate = new Date(dueDateCalc.setMonth(dueDateCalc.getMonth() + 6)); //currentDate.setMonth(currentDate.getMonth() + 6)
    let invoicingfor = resultdata[0].lineitems[0].InvoiceType
    let regenerateid = resultdata[0].lineitems[0].RegenerateId || null
    let cancelParent = resultdata[0].lineitems[0].CancelParent || null
    let InvoiceCyleDueDate = resultdata[0].lineitems[0].InvoiceCyleDueDate || extendedDate
    let DatePrintedOnInvoice = resultdata[0].lineitems[0].DatePrintedOnInvoice || currentDate
    let submodule = resultdata[0].lineitems[0].RefType;
    let reftyp = submodule ? submodule.charAt(0) : 'A';

    let invoice = {
        invoiceTypeKey: invoicesIN,
        invoiceDate: DatePrintedOnInvoice ? DateTimeToZone(DatePrintedOnInvoice) : null,
        status: 'U',
        paymentReferenceId: '',
        dueDate: InvoiceCyleDueDate ? DateTimeToZone(InvoiceCyleDueDate) : null,
        invoiceType: invoicingfor,
        invoiceLineItems: [],
        invoiceDetails: [],
        regenerateId: regenerateid,
        cancelParent: cancelParent
    }
    if (resultdata && Array.isArray(resultdata)) {
        let invoiceLineItems = []
        let invoiceDetails = []
        resultdata.forEach(element => {
            let lineitems = element.lineitems;
            if (lineitems && Array.isArray(lineitems)) {
                lineitems.forEach(litem => {
                    invoiceLineItems.push({ ...litem, refId: litem.RecordID, refType: reftyp, ownerId: ownerid, invoice: '' });
                    let liteminvoicedetails = litem.invoice;
                    if (liteminvoicedetails && Array.isArray(liteminvoicedetails)) {
                        liteminvoicedetails.forEach(invoicedetail => {
                            invoiceDetails.push(invoicedetail)
                        });
                    }
                });
            }
        });
        invoice.invoiceLineItems = invoiceLineItems;
        invoice.invoiceDetails = invoiceDetails;
    }
    return invoice;
}


export function IsMandatoryAttachmentsUploaded(sitepermittype, refid) {
    return new Promise((resolve, reject) => {
        let InvoiceCreatePath = `${getEnvVariable('Application')}/api/Application/IsMandatoryAttachmentsUploaded/${sitepermittype}/${refid}`;
        requestApi({ post: { url: InvoiceCreatePath } }, null, null, null, true)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve(true)
    })
}

export function GetTotalFee(invoice) {
    if (invoice && invoice.InvoiceDetails && Array.isArray(invoice.InvoiceDetails)) {
        let totalAmount = 0;
        invoice.InvoiceDetails.forEach(element => {
            totalAmount = totalAmount + element.Amount
        });
        return totalAmount
    }
    return 0;
}

export function GetTotalDue(invoice) {
    if (invoice && invoice.InvoiceDetails && Array.isArray(invoice.InvoiceDetails)) {
        let totalAmount = 0;
        invoice.InvoiceDetails.forEach(element => {
            totalAmount = totalAmount + element.Amount
        });
        let paidAmount = 0;
        invoice.BillingPayments && invoice.BillingPayments.forEach(payment => {
            paidAmount = paidAmount + payment.BillingPaymentDetails.SubTotal;
        })
        return (totalAmount - paidAmount)
    }
    return 0;
}

export function GetTotalDueMultiPayments(invoices) {
    if (invoices && Array.isArray(invoices)) {
        let invdetailsmerged = [];
        let invlineitemsmerged = [];
        let invbillingpaymentsmerged = [];
        let invbillingpaymentdetailsmerged = [];
        invoices.forEach(inv => {
            invdetailsmerged.push(...inv.InvoiceDetails)
            invlineitemsmerged.push(...inv.InvoiceLineItems)
            //if(invbillingpaymentsmerged.some(e => e.BillingPaymentDetailId === 'Magenic'))
            invbillingpaymentsmerged.push(...inv.BillingPayments);
            inv.BillingPayments.forEach(blg => {
                invbillingpaymentdetailsmerged.push(blg.BillingPaymentDetails)
            });
        });
        invbillingpaymentdetailsmerged = invbillingpaymentdetailsmerged.filter((tag, index, array) => array.findIndex(t => t.Id === tag.Id && t.Type === tag.Type) === index);
        let totalAmount = 0;
        invdetailsmerged.forEach(element => {
            totalAmount = totalAmount + element.Amount
        });
        let paidAmount = 0;
        invbillingpaymentdetailsmerged.forEach(payment => {
            paidAmount = paidAmount + payment.SubTotal;
        })
        return (totalAmount - paidAmount)
    }
    return 0;
}

export function GetInvoicePermitDetails(invoiceid) {
    return new Promise((resolve, reject) => {
        let InvoiceGetPath = `${getEnvVariable('Payment')}/api/Invoice/Permits/${invoiceid}`;
        requestApi({ get: { url: InvoiceGetPath } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export function GetInvoicePermitDetailsList(invoiceidlist) {
    return new Promise((resolve, reject) => {
        let InvoiceGetPath = `${getEnvVariable('Payment')}/api/Invoice/PermitsList`;
        requestApi({ post: { url: InvoiceGetPath } }, null, invoiceidlist, null, true)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}


export function UpdateInvoiceStatus(invoiceid, status) {
    return new Promise((resolve, reject) => {
        let invoiceupdateurl = `${getEnvVariable('Payment')}/api/Invoice/Status/${invoiceid}/${status}`;
        requestApi({ get: { url: invoiceupdateurl } }, null, null)
            .then(res => {
                resolve(res)
            })
    }).catch(ex => {
      //  resolve([])
    })
}

export const testInvoiceResult = [
    {
        "lineitems": [
            {
                "RecordID": "23789",
                "ApplicantID": "101",
                "PermitNumber": "23789-A1",
                "SubType": "Private",
                "District": "District8",
                "County": "Lexington",
                "Route": "CLSR",
                "PermitFee": "50",
                "invoice": [
                    {
                        "name": "PermitFee",
                        "amount": 50,
                        "description": "PermitFee"
                    }
                ]
            }
        ],
        "otheritems": []
    }
]