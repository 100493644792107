import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import React, { useEffect, useState, useCallback } from 'react';
import { formatQuery as Formatter } from 'react-querybuilder';
import { useParams } from 'react-router-dom';
import Dropdown from '../../core/controls/components/dropdown';
import Header from '../../core/controls/components/header';
import Kendoheader from '../../core/controls/components/kendoheader';
import Switch from '../../core/controls/components/switch';
import Textbox from '../../core/controls/components/textbox';
import { DeleteRecord, GetData, PostData, PutData } from '../../core/controls/helpers/requests';
import { createRequest, requestApi } from '../../core/controls/helpers/rest';
import StaticFilter from '../../core/modules/dnd/widgets/validationrules/static';
import Simplegrid from '../../core/modules/Grid/cgrid';
import { getEnvVariable } from '../../modules/environmentalList';

const defaultDrawerWidth = 900;
const minDrawerWidth = 700;
const maxDrawerWidth = window.innerWidth - 220;
const fullscreenwidth = window.innerWidth
const checkwidth = 960


const transformProps = {
    "A": true,
    "I": false,
    true: "A",
    false: "I"
}

const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 0
    },
    dragger: {
        width: "7px",
        cursor: "ew-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 100,
        //   backgroundColor: "#f4f7f9"
    }
}));



const headerProps = {
    title: "Sidebar Rules",
    components: [
        {
            type: 'breadcrumbs',
            items: [
                { "link": `Site Settings`, "tab": 3, "materialicon": "engineering", href: getEnvVariable('InternalBasepath'), className: 'breadcrumb-item text-dark', class: 'text-dark' },
                { "link": "Sidebar Rules", href: "#/Administration/sidebarbr", "materialicon": "ballot", className: 'breadcrumb-item ', class: '', fontSize: '25px' }

            ],
        }
    ],
    actions: [

        {
            type: 'fab',
            icon: 'add',
            name: 'add',
            color: 'primary',
            title: 'Add Sidebar Rules',
            menucolor: '#36088a'
        },

    ]
}

const header = {
    name: "header",
    type: 'header',
    title: 'Business Rules',
    components: [
        {
            type: 'breadcrumbs',
            items: [
                { "link": "Add Sidebar Rules", "materialicon": "ballot", className: 'eps-breadcrumb-item ', class: 'text-light' },
            ]
        }
    ],
    actions: [
        {
            type: 'icon',
            icon: 'save',
            name: 'save',
            hideon: 'save',
            hidevalue: false,
            title: 'Save Sidebar Business Rules'
        },
        {
            type: 'icon',
            icon: 'back',
            name: 'back',
            hideon: 'back',
            hidevalue: false
        },
    ],
}

function ValidationSideRules(props) {
    const [items, setItems] = useState([])
    const [columns, setColumns] = useState([])
    const [item, setItem] = useState({})
    const [reload, setReload] = useState(false)
    const [tablenames, setTableNames] = useState([])
    const [sidePop, setSidePop] = useState(false)
    const [total, setTotal] = useState(0)
    const { eachsection, section, open } = props;

    let screensize = (props.width === "sm" || props.width === "xs" || props.width === "md") ? false : true
    const [drawerWidth, setDrawerWidth] = useState((props.width === "sm" || props.width === "xs" || props.width === "md") ? fullscreenwidth : defaultDrawerWidth);
    const [fullscreen, setFullScreen] = useState(false)
    const [fullwidth, setFullWidth] = useState(fullscreenwidth)

    React.useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    });


    const updateWidth = () => {
        if (window.innerWidth < checkwidth) {
            setDrawerWidth(window.innerWidth);
        }
        else {
            setDrawerWidth(defaultDrawerWidth)
        }
    };

    React.useEffect(() => {
        if (!screensize) {
            let width = window.innerWidth
            setDrawerWidth(width);
            setFullWidth(width)
        }
        else {
            setDrawerWidth(defaultDrawerWidth);
            // setFullWidth(defaultDrawerWidth)
        }
    }, [props.width]);


    const handleMouseDown = e => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let newWidth =
            document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        //  const newWidth = e.clientX - document.body.offsetLeft;
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            setDrawerWidth(newWidth);
        }
    }, []);


    useEffect(() => {
        const read = [
            {
                url: getEnvVariable('Report') + `/api/ReportQuery/reporttables`,
                type: 'get'
            },
        ]
        Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(response => {
            const [tablename = []] = response;
            setTableNames(tablename.map(each => { return { Name: each, Value: each } }))
        })
    }, [])

    useEffect(() => {
        if (item && item.TableName) {
            getData()
        }
    }, [item && item.TableName])

    const getData = () => {
        let gColumns = []
        const queryStr = `${toDataSourceRequestString({
            skip: 0, take: 424
        })}`;
        let url = getEnvVariable('Admin') + `/api/SystemSystemBusinessRules/Grid?${queryStr}&tablename=${(item && item.TableName) || ''}`
        GetData(url).then(response => {
            if (response.data.length > 0) {
                response.data.map(item => {
                    item.Status = item.Status === 'A' ? 'Active' : 'Inactive'
                    item.RuleType = item.RuleType === 'S' ? 'Data' : 'Role'
                    item.isCalcualtedQuery = 'Y'
                    item.BusinessRuleFields = item.SystemBusinessRulesDependencyFields || []
                })

                if (response && response.data.length > 0) {
                    (Object.getOwnPropertyNames(response.data[0])).map(col => {
                        if (col === 'Name' || col === 'RuleOrder' || col === 'Status' || col === 'Id'|| col === 'SqlQuery')
                            gColumns.push({ field: col, title: col === 'RuleOrder' ? 'Order' : col, show: true, filtericon: true, width: 150, })
                    })
                    gColumns.push({ field: 'Custom', title: 'Custom', show: true, width: 150, })
                    setItems(response.data);
                    setColumns(gColumns)
                    setReload(!reload);
                    setTotal(response.total)
                }

            }
        })
    }

    const handleFieldValue = (evt) => {
        const { value, name } = evt.target;
        setItem({ ...item, [name]: value })
    }


    const handleAdd = () => {
        let newItem = item;
        newItem['RuleType'] = 'S'
        newItem['Status'] = 'I'
        newItem['EventOn'] = 'OC'
        newItem['EventType'] = 'E'
        newItem['isCalcualtedQuery'] = 'Y';
        setSidePop(true)
        setItem(newItem)
    }

    const addOnsByName = { addOnsByName: { add: { onClick: handleAdd } } }

    const handleEdit = (dataItem) => {
        let url = getEnvVariable('Admin') + `/api/SystemSystemBusinessRules/ById/${dataItem.Id}`
        GetData(url, '').then(res => {
            res.query = res.JsonQueryStr !== '' ? JSON.parse(res.JsonQueryStr) : '';
            res.RuleType = res.RuleType ? res.RuleType : 'S';
            res.isCalcualtedQuery = 'Y'
            res.BusinessRuleFields = res.SystemBusinessRulesDependencyFields || []
            setItem(res)
            setSidePop(true)
            setReload(!reload)
        })
    }

    const onUp = (dataItem) => {
        PutData(getEnvVariable('Admin') + `/api/SystemSystemBusinessRules/${dataItem.Id}/UP`, dataItem).then(res => {
            getData()
        })
    }

    const onDown = (dataItem) => {
        PutData(getEnvVariable('Admin') + `/api/SystemSystemBusinessRules/${dataItem.Id}/DOWN`, dataItem).then(res => {
            getData()
        })
    }

    const onDelete = (dataItem) => {
        DeleteRecord(getEnvVariable('Admin') + `/api/SystemSystemBusinessRules/${dataItem.Id}`, '').then(res => {
            getData()
        })

    }

    const handleBackSlider = () => {
        setItem({ ['TableName']: item.TableName })
        setSidePop(false)
        getData()
    }

    const classes = useStyles();
    return (
        <div className='col-12' style={{ height: window.innerHeight - 100 }}>
            {reload}
            <div onMouseDown={e => handleMouseDown(e)} className={classes.dragger} />
            <Kendoheader {...headerProps} {...addOnsByName} />
            <div className='col-12 pl-1'>
                <Dropdown label={'Table Name'} name={'TableName'}
                    value={(item && item.TableName) || ''} items={tablenames || []} handleFieldValue={(evt) => handleFieldValue(evt)} extraProps={{
                        transformProps: {
                            label: 'Name',
                            value: 'Value'
                        }
                    }} />
                <br />
                {columns && columns.length > 0 && <Simplegrid total={total}
                    onUp={onUp} handleEdit={handleEdit}
                    onDown={onDown} onDelete={onDelete}
                    data={items || []} columns={columns} useComp={true} options={{ gridcolumns: columns }} gridFields={columns} rules={true} enablerender={true} />
                }
                <Drawer anchor="right" open={sidePop}
                    disableEnforceFocus={true}
                    disableAutoFocus={true}
                    PaperProps={{ style: { width: fullscreen ? fullwidth : drawerWidth } }}
                >
                    {item && Object.keys(item).length > 0 && <Rule businessrules={items} item={item} handleClose={handleBackSlider}
                        tablenames={tablenames} />}
                </Drawer>
            </div>
        </div>
    )
}

export default ValidationSideRules

function Rule(props) {
    const [result, setResult] = useState(props.item || { isCalcualtedQuery: 'Y' })
    const [fields, setFields] = useState([])
    const [uploaded, setUploaded] = useState(false)
    const [reload, setReload] = useState(false)
    const [queryError, setqueryError] = useState({ error: false, helperText: '' })
    const [nameError, setnameError] = useState({ error: false, helperText: '' })
    const [descError, setDescError] = useState({ error: false, helperText: '' })
    const [columnnames, setColumnNames] = useState([])
    const params = useParams();


    useEffect(() => {
        if (props.item && props.item.TableName) {
            const url = getEnvVariable('Application') + `/api/SystemBusinessRulesData/Builder?tableName=${result.TableName.replace(getEnvVariable('ModuleKey'), "")}`
            GetData(url).then(fieldset => {
                let tempColumns = [];
                fieldset.filter(each => {
                    //         if (each.isVirtual === false)
                    tempColumns.push({ Name: each.name, Value: each.name })
                })
                tempColumns.push({ Name: 'Global', Value: 'GLOBAL' })
                setColumnNames(tempColumns)
                setFields(fieldset)
                setReload(!reload)
            }).catch(ex => {
                console.log(ex)
            })
        }
    }, [props.item.TableName])

    const handleFieldValue = (event) => {
        const { name, value } = event.target;
        if (name === 'Status')
            result[name] = event.target.checked ? 'A' : 'I'
        else if (name === 'EventType') {
            if (value === 'S') {
                result['NoticesIsAuto'] = 'Y'
                result['ReportIsAuto'] = 'Y'
                result['NotificationSettingsIsAuto'] = 'Y'
                result[name] = value
            }
            else {
                result['NoticesIsAuto'] = 'N'
                result['ReportIsAuto'] = 'N'
                result['NotificationSettingsIsAuto'] = 'N'
                result[name] = value
            }
        }
        else result[name] = value
        setResult(result)
        setReload(!reload)
    }

    const handleDynamicResult = (dynResponse) => {
        let tempResult = result;
        if (dynResponse) {
            if (dynResponse.CvsDateItems && dynResponse.CvsDateItems.length > 0)
                tempResult['CustomDataClxn'] = dynResponse.CvsDateItems;
            if (dynResponse.HashKey) {
                tempResult['HashKey'] = dynResponse.HashKey
            }
            setResult(tempResult)
            setUploaded(!uploaded)
        }
    }

    const onError = (errors) => {
        let errorstate = false, errorval = ''
        if (errors["Name"]) {
            if (Array.isArray(errors['Name'])) {
                errorstate = true
                errorval = errors['Name'][0]
            }
            else {
                errorstate = errors['Name'].error
                errorval = errors['Name'].errortext
            }
            setnameError({ error: errorstate, helperText: errorval })
        }
        if (errors["SqlQuery"]) {
            if (Array.isArray(errors['SqlQuery'])) {
                errorstate = true
                errorval = errors['SqlQuery'][0]
            }
            else {
                errorstate = errors['SqlQuery'].error
                errorval = errors['SqlQuery'].errortext
            }
            setqueryError({ error: errorstate, helperText: errorval })
        }
        if (errors["JsonQueryStr"]) {
            if (Array.isArray(errors['JsonQueryStr'])) {
                errorstate = true
                errorval = errors['JsonQueryStr'][0]
            }
            else {
                errorstate = errors['JsonQueryStr'].error
                errorval = errors['JsonQueryStr'].errortext
            }
            setqueryError({ error: errorstate, helperText: errorval })
        }
        if (errors["Description"]) {
            if (Array.isArray(errors['Description'])) {
                errorstate = true
                errorval = errors['Description'][0]
            }
            else {
                errorstate = errors['Description'].error
                errorval = errors['Description'].errortext
            }
            setDescError({ error: errorstate, helperText: errorval })
        }
    }

    const handleSave = () => {
        let tempResult = result
        if (tempResult && tempResult.Name) {
            tempResult.JsonQueryStr = JSON.stringify(result.query) //tempResult.RuleType === 'S' ? : ''
            tempResult.SqlQuery = Formatter(result.query, 'sql') // tempResult.RuleType === 'S' ?: ''
            tempResult.IsTriggerAnotherRuleOnFailure = tempResult.IsTriggerAnotherRuleOnFailure === 'Y' ? 'Y' : 'N';
            tempResult.NoticesEventOn = 'N';
            tempResult.NoticesOnLoad = 'N';
            tempResult.NotificationSettingsEventOn = 'N';
            tempResult.NotificationSettingsOnLoad = 'N';
            tempResult.ProjectMessagesEventOn = 'N';
            tempResult.ProjectMessagesOnLoad = 'N';
            tempResult.NotificationSettingsIsAuto = tempResult.NotificationSettingsIsAuto ? tempResult.NotificationSettingsIsAuto : 'N';
            tempResult.NoticesIsAuto = tempResult.NoticesIsAuto ? tempResult.NoticesIsAuto : 'N';
            tempResult.ReportIsAuto = tempResult.ReportIsAuto ? tempResult.ReportIsAuto : 'N';
            tempResult.SystemBusinessRulesDependencyFields = tempResult.BusinessRuleFields || []
            delete tempResult.BusinessRuleFields
            if (!tempResult.Id) {
                tempResult.Status = 'A';
                let url = getEnvVariable('Admin') + `/api/SystemSystemBusinessRules/Add?tableName=${result.TableName}`
                PostData(url, tempResult).then(res => {
                    props.handleClose(false)
                }).catch(error => {
                    error.then(err => {
                        if (err) {
                            tempResult.query = JSON.parse(tempResult.JsonQueryStr)
                            setResult(tempResult)
                            onError(err);
                        }
                    })
                })
            }
            else {
                let url = getEnvVariable('Admin') + `/api/SystemSystemBusinessRules/${tempResult.Id}/Update`
                PutData(url, tempResult).then(res => {
                    props.handleClose(false)
                }).catch(error => {
                    error.then(err => {
                        if (err) {
                            tempResult.query = JSON.parse(tempResult.JsonQueryStr)
                            setResult(tempResult)
                            onError(err)
                        }
                    })
                })
            }
        }
        else {
            if (!result.Name) {
                setnameError({ error: false, helperText: null })
                setqueryError({ error: true, helperText: 'Field is required' })
            }
            if (!result.query) {
                if (!result.InternalRoles || !result.ExernalRoles) {
                    setqueryError({ error: true, helperText: 'Field is required' })
                    setnameError({ error: false, helperText: null })
                }
            }
        }
    }

    const addOnsByName = { addOnsByName: { save: { onClick: handleSave }, back: { onClick: props.handleClose } } }
    return (
        <div className='h-100' style={{ overflow: 'auto' }}>
            <Header {...header} {...addOnsByName} />
            <div className='col-12 pt-3'>
                <Dropdown label={'Table Name'} name={'TableName'}
                    value={result.TableName} items={props.tablenames || []} handleFieldValue={(evt) => handleFieldValue(evt)} extraProps={{
                        transformProps: {
                            label: 'Name',
                            value: 'Value'
                        }
                    }} />
                {result && result.TableName && <Dropdown label={'Column Name'} name={'ColumnName'}
                    value={result.ColumnName} items={columnnames || []} handleFieldValue={(evt) => handleFieldValue(evt)} extraProps={{
                        transformProps: {
                            label: 'Name',
                            value: 'Value'
                        }
                    }} />}
            </div>
            {result && result.TableName && result.ColumnName && <div className="col-sm-12">
                {reload}
                <Textbox label='Name' name='Name' value={(result && result.Name) || null} handleFieldValue={handleFieldValue} error={nameError.error} helperText={nameError.helperText} required={true} />
                <Textbox label='Description' name='Description' value={(result && result.Description) || null} handleFieldValue={handleFieldValue} error={descError.error} helperText={descError.helperText} />
                <Switch label='Status' name="Status" value={(result && result.Status) || null} handleFieldValue={handleFieldValue} extraProps={{
                    transformProps: transformProps
                }} /><br />
                <StaticFilter result={result || {}}
                    handleFieldValue={handleFieldValue} handleDynamicResult={handleDynamicResult} fields={fields}
                    error={queryError.error} helperText={queryError.helperText} isSidebar={true} />
            </div>}
        </div>
    )
}

