import React, { Component } from 'react';
import ReactDOM from "react-dom";

import { loadModules } from 'esri-loader';
import { GeometryEngineTask } from '../util/geometryEngineTask';

//AMES specific for pointing existing selection
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
//import { GetMasterLookUpValues, GetMasterGISFieldsData } from '../eps/lookupDataTaskKEPT'
import { GISLookupTask, GISLookupTaskWithGeom } from '../eps/GISLookupTask'

import { snackbarMessage, Confirmation } from '../../../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { SketchDistanceValidatorTask } from '../eps/SketchDistanceValidator'

class CSketch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: this.props.view,
            MapBussinessRules: this.props.options.MapBussinessRules,
            mapConstants: this.props.mapConstants,
            spatialReference: this.props.spatialReference,
            showPointType: false,
            pointType: '',
            ProposedPointGeom: [],
            ExistingPointGeom: [],
            enableTaxlot: false,
            anchorEl: null,
            showGraphicFieldEdit: false,
            anchorEl1: null,
            graphicFieldEditName: '',
            graphicFieldEditDesc: '',
            selectedGraphicType: ''
        }
        this.startup(
            this.props
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.clearsketch != prevProps.clearsketch && this.layer) {
            this.layer.removeAll();
        }
    }



    RemoveNode = (clazzName) => {
        let node = this.sketchcontainer.getElementsByClassName(clazzName);
        if (node !== null && node.length > 0)
            node[0].remove();
    }

    RemoveTools = (Tools) => {
        if (Tools.indexOf("point") === -1)
            this.RemoveNode("esri-icon-map-pin");
        if (Tools.indexOf("polyline") === -1)
            this.RemoveNode("esri-icon-polyline");
        if (Tools.indexOf("polygon") === -1) {
            this.RemoveNode("esri-icon-polygon");
            this.RemoveNode("esri-sketch__button esri-icon-checkbox-unchecked");
            this.RemoveNode("esri-sketch__button esri-icon-radio-unchecked");
        }

    }
    startup = (config) => {
        loadModules([
            "esri/core/watchUtils",
            "esri/widgets/Expand",
            "esri/widgets/Sketch",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/layers/GraphicsLayer",
            "esri/layers/FeatureLayer"
        ]).then(([watchUtils, Expand, Sketch, SketchViewModel, GraphicsLayer, FeatureLayer]) => {
            if (config.options.drawOptions.Tools && config.options.drawOptions.Tools.length > 0) {
                //if (1==1) {
                this.graphic = null;
                this.layer = config.view.map.layers.find(function (layer) {
                    return layer.title === "Sketch Layer";
                });

                if (!this.layer) {
                    this.layer = new GraphicsLayer({
                        "title": "Sketch Layer", listMode: 'hide'
                    });
                }
                //this.state.graphicsLayer
                this.sketchVM = new SketchViewModel({
                    //updateOnGraphicClick: false,
                    pointSymbol: (this.props.SubModuleKey == "COLLISION" || this.props.SubModuleKey == "INVESTIGATION") ? this.state.mapConstants.getSVGPointtSymbol() : this.state.mapConstants.ExistingPointSymbol,
                    polylineSymbol: this.state.mapConstants.LineSymbol,
                    polygonSymbol: this.state.mapConstants.PolygonFillSymbol,
                    view: config.view,
                    layer: this.layer
                });
                this.sketch = new Sketch({
                    layer: this.layer,
                    viewModel: this.sketchVM,
                    availableCreateTools: config.options.drawOptions.Tools, //config.options.drawOptions.Tools
                    creationMode: "single",
                    defaultUpdateOptions: { tool: "move" }
                });
                this.sketch.on("delete", this.onGraphicDelete);
                this.sketchcontainer = ReactDOM.findDOMNode(this);

                const sketchExpand = new Expand({
                    collapseTooltip: "Collapse Draw Tool",
                    expandTooltip: "Expand Draw Tool",
                    view: config.view,
                    container: this.sketchcontainer,
                    content: this.sketch
                });
                config.view.map.layers.add(this.layer, (config.options.drawOptions.LayerIndex || 1));
                // watchUtils.whenDefinedOnce(this.sketchcontainer, "ready", function(evt){
                //     console.info('sketch widget loaded ---------------');
                //     alert('sketch loaded ')
                // });
                config.view.ui.add(this.sketchcontainer, "top-right");

                // Listen to sketchViewModel's events.
                this.sketchVM.on("create", this.onGraphicCreate);
                this.sketchVM.on("update", this.onGraphicUpdate);
                this.sketchVM.on("delete", this.onGraphicDelete);
                watchUtils.pausable(this.sketchVM, "activeTool", this.onActiveTool);
                this.props.widgetRenderCallBack("Sketch");
                const inThis = this;
                watchUtils.pausable(sketchExpand, "expanded", function (newValue, oldValue) {
                    inThis.RemoveTools(config.options.drawOptions.Tools);
                    inThis.props.onexpanded(sketchExpand, newValue);
                });
                // watchUtils.whenOnce(this.sketchVM, "ready",function(evt){
                //     console.log(evt);
                //     alert('Widget is ready to use')
                // })
            }
        });
    }

    onActiveTool = (tooltype) => {
        if (tooltype != null) {
            let fetchGeomType = this.props.fetchGeomType || []
            let isTwoWayPoint = (fetchGeomType.includes("E") && fetchGeomType.includes("P")) ? true : false;
            var isAllowMultiple = false;
            if (this.props.options.drawOptions.AllowMultiple) {
                if (tooltype == "point")
                    isAllowMultiple = this.props.options.drawOptions.AllowMultiple.Points;
                else if (tooltype == "polyline")
                    isAllowMultiple = this.props.options.drawOptions.AllowMultiple.Lines;
                else if (tooltype == "polygon" || tooltype == "rectangle" || tooltype == "circle")
                    isAllowMultiple = this.props.options.drawOptions.AllowMultiple.Polygons;
            }
            //this.props.options.AllowMultiple !== true
            if (tooltype && (this.props.options.drawOptions.Tools.indexOf(tooltype) !== -1) && isAllowMultiple !== true && isTwoWayPoint !== true)
                this.layer.graphics.removeAll();// Remove as per Gemo Type

            if ((tooltype === "point" || tooltype == "polyline" || tooltype == "polygon" || tooltype == "rectangle" || tooltype == "circle") && isTwoWayPoint === true) {
                let anchorEl = ReactDOM.findDOMNode(this).getElementsByClassName("esri-sketch")[0]
                let anchorEl1 = ReactDOM.findDOMNode(this).getElementsByClassName("sketch_ToolBox_Widget")[0]
                this.setState({ anchorEl: anchorEl, anchorEl1: anchorEl1 })
                this.setPointTypeChange(this.state.pointType, true)
            } else if ((tooltype === "point" || tooltype == "polyline" || tooltype == "polygon" || tooltype == "rectangle" || tooltype == "circle") && isTwoWayPoint !== true) {
                this.setState({ pointType: fetchGeomType[0] })
            }
        } else { this.setState({ pointType: '' }) }

    }

    onGraphicCreate = (event) => {
        //  event.stopPropagation(); 
        var flag = false;
        if (event.state === "complete") {
            if (this.props.view.zoom < 15) {
                this.props.snackbarMessage('Please zoom in further', 'info');
                this.layer.remove(event.graphic);
            } else {
                if(this.props.options && Array.isArray(this.props.options.SketchValidationLayers) 
                  && this.props.options.SketchValidationLayers.length > 0 ){
                    this.validateSketchDistance(event, flag); 
                  }else{
                    this.onGraphicCreateComplete(event, flag);
                  }                               
            }
        }
        this.checkForSaveorUpdate(flag, false);
    }


    validateSketchDistance(event, flag){
        SketchDistanceValidatorTask(this.props.options, event.graphic, this.props.options.SketchValidationLayers).then((gisres) => {
            if(gisres && Array.isArray(gisres)){
                let errLayers = [];
                let errText = "";
                gisres.forEach(function (lres) {
                    console.log(lres.Layer.Name,'-----------------------',lres.ResponseCount);
                    if(lres && lres.ResponseCount === 0){
                        errLayers.push(lres.Layer);
                        errText = errText + ""+lres.Layer.DisplayName+"("+lres.Layer.ValidationDistance+"ft) "
                    }                    
                });
                if(errLayers && errLayers.length > 0){
                    this.props.snackbarMessage('Selected location(map pin) is beyond the allowed range of the '+errText , 'info');
                    this.layer.remove(event.graphic);
                }else{
                    this.onGraphicCreateComplete(event, flag);
                }                
            }else{
                this.onGraphicCreateComplete(event, flag);
            }
            console.log(gisres)
        })
    }

    onGraphicCreateComplete(event, flag) {
        //Changing the second point color here : AMES
        if (event.tool && event.tool === "point") {
            console.log(event.graphic,"Ponttttttttttttttttttttttttttttttttttt")
            if (this.state.pointType === "P") {
                event.graphic.symbol = this.state.mapConstants.PointSymbol;
                this.state.ProposedPointGeom.push(event.graphic);
            } else {
                this.state.ExistingPointGeom.push(event.graphic)
            }
        }
        if (this.state.spatialReference) {
            console.log('spatialReference----', this.state.spatialReference)
            let graphicies = [event.graphic.geometry]
            GeometryEngineTask({
                "geometries": graphicies, "spatialReference": this.state.spatialReference, "type": "project"
            }).then(
                geomEngineResponse => {
                    if (geomEngineResponse) {
                        console.log('Projection after sketch ------------------', geomEngineResponse)
                    }
                },
                geomEngineerror => {
                });
            flag = true;
        }

        if (this.state.MapBussinessRules) {
            let inthis = this;
            let graphicies = [event.graphic.geometry];
            console.log('spatialReference----', this.state.spatialReference)
            GeometryEngineTask({
                "geometries": graphicies, "spatialReference": this.state.spatialReference, MapBussinessRules: this.props.options.MapBussinessRules, ValidateOf: "GEOMETRY_LENGTH", "type": "validateBussinessRules", units: "miles"
            }).then(
                validateBussinessRulesResponse => {
                    if (validateBussinessRulesResponse && validateBussinessRulesResponse.length > 0) {
                        validateBussinessRulesResponse.forEach(vbr => {
                            if (vbr.IsValidationFalied) {
                                inthis.props.snackbarMessage(vbr.alertMessage.message, vbr.alertMessage.type);
                                inthis.layer.remove(event.graphic);
                            }
                        });
                    }
                },
                validateBussinessRuleseerror => {
                });
        }
        if (this.props.options && this.props.options.MasterLookupFields) {
            GISLookupTask(this.props.options, event.graphic, this.props.options.MasterLookupFields).then((gisres) => {
                if (this.props.setStateMapInformation)
                    this.props.setStateMapInformation(gisres);
            })
        }
        //Graphic fetch
        if (this.props.hasMultiLocationSelector == true && this.props.onGraphicSelection) {
            this.props.onGraphicSelection(this.getSelectionData(event.graphic.geometry.type, event.graphic));
            //Remove no location selected geometry (Privously drawn geometry has location selection and remove it)
            let graphicitems = this.layer.graphics.items;
            let nolocationgraphics = graphicitems.filter((a, index) => (index + 1) != graphicitems.length && (typeof (a.attributes) === "undefined" || a.attributes === null));
            if (nolocationgraphics && Array.isArray(nolocationgraphics) && nolocationgraphics.length > 0) {
                nolocationgraphics.forEach(grph => {
                    this.layer.graphics.remove(grph);
                });
            }

        }

    }

    onGraphicUpdate = (event) => {
        let currgraphic = event.graphics[0];
        this.graphic = currgraphic;
        if (event.state == "cancel" && this.props.onGraphicSelection && currgraphic.attributes) {
            if (this.props.hasMultiLocationSelector == true
                && this.props.options
                && Array.isArray(this.props.options.MultipleLocationData)) {
                let hasgraphic = this.layer.graphics.items.filter(a => a.attributes.OBJECTID == currgraphic.attributes.OBJECTID);
                let isprimary = this.props.options.MultipleLocationData.filter(a => a.ObjectId == currgraphic.attributes.OBJECTID && a.IsPrimary == 'Y')
                if (hasgraphic && hasgraphic.length <= 0 && isprimary.length > 0) {
                    this.layer.graphics.add(this.graphic);
                    this.props.snackbarMessage('Primary location cannot be deleted', 'error');
                }
            } else {
                this.props.Confirmation(this.onDelete, this.onCancelConfirmation, 'Are You sure you want to remove the map location?', true);
            }
        }
        else {
            this.checkForSaveorUpdate(false, false);
            let currentgraphicType = event.graphics[0].geometry.type
            if (currgraphic.attributes) {
                let name = currgraphic.attributes.Name
                let desc = currgraphic.attributes.Description
                //this.showEditTemplate(true, currentgraphicType, name, desc);
            }
        }
    }

    removeGraphic(graphic) {
        if (graphic) {
            this.layer.graphics.remove(graphic);
        }
    }

    onCancelConfirmation = () => {
        this.props.Confirmation(null, null, null, false);
    }

    onDelete = () => {
        this.props.Confirmation(null, null, null, false);
    }

    onGraphicDelete = (event) => {
        //alert(event)
    }


    //Specific to KEPT for selecting multiple locations
    getSelectionData(type, graphic) {
        if (type == 'point')
            return { type: type, OBJECTID: (graphic.attributes ? graphic.attributes.OBJECTID : -1), MasterTable_Id: (graphic.attributes ? graphic.attributes.MasterTable_Id : -1), latitude: graphic.geometry.latitude, longitude: graphic.geometry.longitude, graphic: graphic }
        if (type == 'polygon')
            return { type: type, OBJECTID: (graphic.attributes ? graphic.attributes.OBJECTID : -1), MasterTable_Id: (graphic.attributes ? graphic.attributes.MasterTable_Id : -1), latitude: graphic.geometry.centroid.latitude, longitude: graphic.geometry.centroid.longitude, graphic: graphic }
        if (type == 'polyline')
            return { type: type, OBJECTID: (graphic.attributes ? graphic.attributes.OBJECTID : -1), MasterTable_Id: (graphic.attributes ? graphic.attributes.MasterTable_Id : -1), latitude: graphic.geometry.extent.center.latitude, longitude: graphic.geometry.extent.center.longitude, graphic: graphic }
        if (type == 'multipoint')
            return { type: type, OBJECTID: (graphic.attributes ? graphic.attributes.OBJECTID : -1), MasterTable_Id: (graphic.attributes ? graphic.attributes.MasterTable_Id : -1), latitude: graphic.geometry.extent.center.latitude, longitude: graphic.geometry.extent.center.longitude, graphic: graphic }

        return {}
    }

    showEditTemplate(check, currgraphic, name, desc) {
        this.setState({
            showGraphicFieldEdit: check,
            selectedGraphicType: currgraphic,
            graphicFieldEditName: name,
            graphicFieldEditDesc: desc
        })
    }

    checkForSaveorUpdate = (eventflag, hideflag) => {
        let fetchGeomType = this.props.fetchGeomType || []
        let isTwoWayPoint = (fetchGeomType.includes("E") && fetchGeomType.includes("P")) ? true : false;
        if (isTwoWayPoint === true) {
            let hasProposedGeom = false;
            let hasExistsGeom = false;
            let svgPointtSymbol = this.state.mapConstants.getSVGPointtSymbol()
            this.layer.graphics.forEach(element => {
                if (element && (element.symbol.url === this.state.mapConstants.ExistingPointSymbol.url || (svgPointtSymbol && element.symbol.url === svgPointtSymbol.url))) {
                    hasExistsGeom = true;
                } else if (element && element.symbol.url === this.state.mapConstants.PointSymbol.url) {
                    hasProposedGeom = true;
                }
            });
            let flag = eventflag ? eventflag : this.layer.graphics !== null && this.layer.graphics.length > 0;
            if (hasProposedGeom === true || hasExistsGeom === true) {
                //Enable save button.(see above condition)
                flag = hideflag ? !hideflag : flag;
            } else {
                flag = false;
            }
            this.setState({
                enableSave: flag
            });
        } else {
            let flag = eventflag ? eventflag : this.layer.graphics !== null && this.layer.graphics.length > 0;
            flag = hideflag ? !hideflag : flag;
            this.setState({
                enableSave: flag
            });
        }
    }

    onSaveorUpdate = () => {
        //this.layer.graphics.items.filter(a => !a.attributes)
        if (this.layer.graphics !== null && this.layer.graphics.length > 0
            && this.props.hasMultiLocationSelector == true) {
            if (this.layer.graphics.items.filter(a => !a.attributes).length > 0) {
                this.props.snackbarMessage('Please select primary location for the geometry', 'error');
                return;
            }
        }
        this.setState({ enableSave: false });
        this.props.onSave(this.layer).then(
            response => {
                this.checkForSaveorUpdate(false, true);
                if (this.props.hasMultiLocationSelector) {
                    this.layer.graphics.forEach(graphicTo => {
                        if (graphicTo && graphicTo.attributes
                            && graphicTo.attributes.MultipleLocations) {
                            graphicTo.attributes.MultipleLocations = null;
                        }
                    })
                }
            },
            error => {
                console.log("onSaveorUpdate:: error:: ", error);
            }
        );
    }

    onTaxlotSelect = () => {
        //alert('Please populate taxlot information');
    }

    setPointTypeChange(value, show) {
        this.setState({
            ...this.state,
            pointType: value,
            showPointType: show
        })
    }

    pointTypeChange = (event) => {
        this.clearGraphicsTwoWay(event.target.value)
        this.setPointTypeChange(event.target.value, false)
    }

    onRequestClose = () => {
        this.setPointTypeChange(this.state.pointType, false)
    }

    clearGraphicsTwoWay(type) {
        if (type === "P") {

            //this.layer.graphics.removeMany(this.layer.graphics.filter(a => a.symbol === this.state.mapConstants.PointSymbol));
            this.layer.graphics.forEach(element => {
                if (element && element.symbol.url === this.state.mapConstants.PointSymbol.url) {
                    this.layer.graphics.remove(element)
                }

            });
        } else if (type === "E") {
            //this.layer.graphics.removeMany(this.layer.graphics.filter(a => a.symbol === this.state.mapConstants.ExistingPointSymbol));
            this.layer.graphics.forEach(element => {
                if (element && element.symbol.url === this.state.mapConstants.ExistingPointSymbol.url) {
                    this.layer.graphics.remove(element)
                }
            });
        }
    }

    saveGraphicFieldEdit(inthis) {
        if (inthis.layer.graphics !== null && inthis.layer.graphics.length > 0) {
            let graphic = inthis.layer.graphics.items.filter(g => { return g.geometry.type == inthis.state.selectedGraphicType })[0]
            if (graphic) {
                inthis.layer.graphics.items.filter(g => { return g.geometry.type == inthis.state.selectedGraphicType })[0].attributes.Name = inthis.state.graphicFieldEditName;
                inthis.layer.graphics.items.filter(g => { return g.geometry.type == inthis.state.selectedGraphicType })[0].attributes.Description = inthis.state.graphicFieldEditDesc;
                inthis.onSaveorUpdate();
            }
        }
        inthis.showEditTemplate(false, '', '', '');
    }

    render() {
        return (
            <div className="sketch_ToolBox_Widget" style={{ display: 'flex', order: this.props.order }}>
                {
                    this.state.enableSave &&
                    <div aria-label="Save" title="Save" role="button" tabIndex="0" className="esri-widget--button">
                        <div className="sketch_ToolBox_save esri-icon-save" onClick={() => this.onSaveorUpdate()}></div>
                    </div>
                }
                {
                    this.state.enableTaxlot &&
                    <div aria-label="Taxlot" title="Select Taxlot" role="button" tabIndex="1" className="esri-widget--button">
                        <div className="sketch_ToolBox_polygon esri-icon-link" onClick={this.onTaxlotSelect}></div>
                    </div>
                }

                {
                    this.state.showGraphicFieldEdit &&
                    <div class="">
                        <Popover className="sketch-fieldeditor"
                            //id={id}
                            open={this.state.showGraphicFieldEdit}
                            anchorEl={this.state.anchorEl1}
                            //onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                            }}
                            onClose={() => this.showEditTemplate(false, '', '', '')}

                        >
                            <FormControl component="fieldset" className="popover-custom" style={{ padding: '5px', minWidth: '400px' }}>
                                <div className="col" slot="end">
                                    <Button variant="contained" color="primary" onClick={() => this.saveGraphicFieldEdit(this)} >
                                        Update
                                    </Button>
                                </div>
                                <div className="col">
                                    <TextField id="standard-basic" label="Name" value={this.state.graphicFieldEditName} onChange={(evt) => this.setState({ graphicFieldEditName: evt.target.value })} />
                                </div>
                                <div className="col">
                                    <TextField
                                        id="standard-multiline-flexible"
                                        label="Description"
                                        multiline
                                        rowsMax={4}
                                        onChange={(evt) => this.setState({ graphicFieldEditDesc: evt.target.value })}
                                        value={this.state.graphicFieldEditDesc}
                                    />
                                </div>
                            </FormControl>


                        </Popover>
                    </div>
                }

                {
                    this.state.showPointType 
                    // <div class="">
                    //     <Popover className="sketch-twoway"
                    //         //id={id}
                    //         open={this.state.showPointType}
                    //         anchorEl={this.state.anchorEl}
                    //         //onClose={handleClose}
                    //         anchorOrigin={{
                    //             vertical: 'bottom',
                    //             horizontal: 'left',
                    //         }}
                    //         transformOrigin={{
                    //             vertical: 'bottom',
                    //             horizontal: 'left',
                    //         }}
                    //         onClose={this.onRequestClose}
                    //     >
                    //         <FormControl component="fieldset" className="popover-custom" style={{ padding: '5px' }}>
                    //             <FormLabel component="legend" style={{ padding: '5px' }}>Select type</FormLabel>
                    //             <RadioGroup aria-label="Selection" name="Selection" value={this.state.pointType} onChange={this.pointTypeChange}>
                    //                 <FormControlLabel value="P" control={<Radio />} label="Proposed" />
                    //                 <FormControlLabel value="E" control={<Radio />} label="Existing" />
                    //             </RadioGroup>
                    //         </FormControl>
                    //     </Popover>
                    // </div>
                }
            </div>
        );
    }
}


const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage, Confirmation }, dispatch);
}

CSketch = connect(null, mapActions)(CSketch)
export default CSketch;