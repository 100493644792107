import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { requestApi } from './utilities'
import FormHeader from './cformheader'
import { bindActionCreators } from 'redux'
import { snackbarMessage } from '../../actions';
import CRichTextEditor from './cwysiwyg';
import CFormTextBox from './cformtextbox';
import CFormDiscussionPanel from './cformdiscussionpanel';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { connect } from 'react-redux';
import Autocompletetags from '../../controls/components/custom/autocompletetagsnotification'
import FormHelperText from '@material-ui/core/FormHelperText'
import { getEnvVariable } from '../../../modules/environmentalList';
const _ = require('lodash');

//comment
class DiscussionsForm extends Component {
    constructor(props) {
        super(props);
        this.LayoutForm = React.createRef();
        this.effects = {};
        this.state = {
            InternalUsersDetails: [],
            AppContactsDetails: [],
            InternalUsers: '',
            AppContacts: '',
            subhelpertext: null,
            helpertextbody: null,
            compareresults: {},
            results: {},
            showsend: false
        };
        //this.requiredFields=["Subject","Body","InternalUsers","AppContacts"]
    }
    componentDidMount() {
        this.didmountinitialload();
    }

    didmountinitialload() {
        //this.progressBar.start()
        const { options, components, extraProps, dataItem } = this.props
        const { crud } = options ? options : {}
        const { read } = crud ? crud : {}
        const { get } = read ? read : {}
        const { routeProps, hidepath } = get ? get : {}
        let ids = [], id
        if (hidepath) {
            id = this.props.accessPermissions.userId;
        }
        else {
            if (Array.isArray(routeProps)) {
                routeProps.forEach(each => {
                    const { value, custom } = each;
                    if (custom) {
                        ids.push({ key: custom, value: dataItem[custom] })
                    }
                    else ids.push({ key: value, value: this.props.match.params[value] })
                })
            }
            else if (routeProps) {
                id = routeProps ? this.props.match.params[routeProps.value] : null
            }
        }
        if (read) {
            requestApi(read, (ids.length > 0 ? ids : id)).then(result => {
                let InverseParentDiscussion = { InverseParentDiscussion: _.reverse(result.InverseParentDiscussion) }
                this.setState({
                    PermitNumber: this.props && this.props.initialloadextrainfo && this.props.initialloadextrainfo.results["REFERENCE_KEY"] == "VIOLATIONS" ? this.props.initialloadextrainfo.results["Id"] : this.props.initialloadextrainfo.results["REFERENCE_KEY"] != "VIOLATIONS" && this.props.initialloadextrainfo.results["REFERENCE_KEY"] != undefined ? this.props.initialloadextrainfo.results["RECORDS_REF"] : this.props.initialloadextrainfo.results["PERMIT_NO"],
                    showsend: true,
                    results: { ...result, ...InverseParentDiscussion, PermitNumber: this.props && this.props.initialloadextrainfo && this.props.initialloadextrainfo.results["REFERENCE_KEY"] == "VIOLATIONS" ? this.props.initialloadextrainfo.results["Id"] : this.props.initialloadextrainfo.results["REFERENCE_KEY"] != "VIOLATIONS" && this.props.initialloadextrainfo.results["REFERENCE_KEY"] != undefined ? this.props.initialloadextrainfo.results["RECORDS_REF"] : this.props.initialloadextrainfo.results["PERMIT_NO"] },
                    compareresults: _.isEmpty({}, this.state.compareresults) ? result : this.state.compareresults
                }, () => this.loadContacts());
                // this.progressBar.complete();
            })
                .catch(err => {
                    this.props.snackbarMessage('Invalid Data. Please verify.', 'error');
                })
        } else {
            this.setState({
                results: { PermitNumber: this.props && this.props.initialloadextrainfo && this.props.initialloadextrainfo.results["REFERENCE_KEY"] == "VIOLATIONS" ? this.props.initialloadextrainfo.results["Id"] : this.props.initialloadextrainfo.results["REFERENCE_KEY"] != "VIOLATIONS" && this.props.initialloadextrainfo.results["REFERENCE_KEY"] != undefined ? this.props.initialloadextrainfo.results["RECORDS_REF"] : this.props.initialloadextrainfo.results["PERMIT_NO"] },
                PermitNumber: this.props && this.props.initialloadextrainfo && this.props.initialloadextrainfo.results["REFERENCE_KEY"] == "VIOLATIONS" ? this.props.initialloadextrainfo.results["Id"] : this.props.initialloadextrainfo.results["REFERENCE_KEY"] != "VIOLATIONS" && this.props.initialloadextrainfo.results["REFERENCE_KEY"] != undefined ? this.props.initialloadextrainfo.results["RECORDS_REF"] : this.props.initialloadextrainfo.results["PERMIT_NO"]
            })
            this.loadContacts()
            // this.progressBar.complete();
        }
    }

    loadContacts() {
        const { results } = this.state
        let InternalUsers, AppContacts
        if (results) {
            InternalUsers = results.InternalUsers
            AppContacts = results.AppContacts
        }
        let propssiteid = this.props.initialloadextrainfo && this.props.initialloadextrainfo.results && this.props.initialloadextrainfo.results["SITE_ID"] ? this.props.initialloadextrainfo.results["SITE_ID"] : null
        let propsregionid = this.props.initialloadextrainfo && this.props.initialloadextrainfo.results && this.props.initialloadextrainfo.results["REGION_ID"] ? this.props.initialloadextrainfo.results["REGION_ID"] : null
        let propsdistrictid = this.props.initialloadextrainfo && this.props.initialloadextrainfo.results && this.props.initialloadextrainfo.results["DISTRICT_ID"] ? this.props.initialloadextrainfo.results["DISTRICT_ID"] : null
        let propsrecordid = this.props.match.params["routeid1"]
        let propscontactid = this.props.initialloadextrainfo && this.props.initialloadextrainfo.results && this.props.initialloadextrainfo.results["SELECT_USER"] ? this.props.initialloadextrainfo.results["SELECT_USER"] : null
        let configType = this.props.accessPermissions.configType
        let configid = this.props.accessPermissions.configType == 'R' ?  propsregionid : this.props.accessPermissions.configType == 'S' ? propssiteid : this.props.accessPermissions.configType == 'D' ? propsdistrictid : null


        let internalUsersRead = { "get": { "url": getEnvVariable('MasterAdmin')+`/api/InternalAccessControlView/GetUsersListByConfigType/`+getEnvVariable('ModuleKey')+`/${configid}/${configType}` } }
        let appContacts
        if (propscontactid) {
            appContacts = { "get": { "url": getEnvVariable('Application') + `/api/AppContacts/${propsrecordid}/${propscontactid}/ApplicationContactsList` } }
        }
        else {
            this.props.snackbarMessage('No Applicant Found', 'error')

        }
        requestApi(internalUsersRead).then(results => {
            if (results && results.length > 0) {
                let internalusers = Array.from(new Set(results.map(s => s.id))).map(id => {
                    return {
                        id: id,
                        name: results.find(a => a.id === id).name,
                        description: results.find(a => a.id === id).description
                    }
                })
                let preSelectedUsers = (InternalUsers && InternalUsers.length > 0) ? internalusers.filter(f => InternalUsers.split(',').includes(f.id.toString())) : []
                let internalUserDetailResults = this.state.results || {}
                internalUserDetailResults["InternalUsersDetails"] = preSelectedUsers
                this.setState({
                    InternalUsersDetails: internalusers,
                    results: internalUserDetailResults
                })
            }
        })
        if (appContacts) {
            requestApi(appContacts).then(results => {
                if (results && results.length > 0) {
                    let preSelectedUsers = (AppContacts && AppContacts.length > 0) ? results.filter(f => AppContacts.split(',').includes(f.id.toString())) : []
                    let appcontactDetailResults = this.state.results || {}
                    appcontactDetailResults["AppContactsDetails"] = preSelectedUsers
                    //let resdata = _.filter(results, { 'refType ': 1 });
                    this.setState({
                        AppContactsDetails: results,
                        // AppContacts: String(resdata[0]['id']),
                        // results: { ...this.state.results, AppContacts: String(resdata[0]['id']) }


                    })
                }
            })
        }
    }

    handleSubjectChanged = (event) => {
        if (this.state.results == undefined || this.state.results == null) {
            this.state.results = {};
        }
        let results = this.state.results
        results[event.target.name] = event.target.value;
        this.setState({ results: results, subhelpertext: null });
    }

    handleCommentsChange = (event) => {
        this.state.results.Body = event.target.value;
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dataItem != this.props.dataItem) {
            this.didmountinitialload();
        }

    }


    handleBack = () => {
        // let checkcond = this.state.results && this.state.compareresults ? !(_.isEqual(this.state.results, this.state.compareresults)) : false
        // if (checkcond) {
        //     this.setState({ openconfirmation: true })
        // }
        // else {
        //     this.setState({ openconfirmation: false })
        //     this.props.onClose();
        // }
        this.props.onClose();
    }
    handleSave = () => {
        // this.progressBar.start();
        const { options, dataItem } = this.props
        const { crud } = options
        const save = crud.update
        const { put, post } = save || {}
        const { conditions } = put || {}
        let ids = [], id
        let strlength = this.state.results && this.state.results.Subject && this.state.results.Subject != null ? this.state.results.Subject.length : ""
        let subInValid = this.state.results && this.state.results.Subject ? this.state.results.Subject.trim() : null
        let editorVal = this.LayoutForm.current.getResult();
        //  if(subInValid && subInValid.length==0 && this.state.results.Subject!=""){
        //     this.setState({subhelpertext: 'Subject is not valid'})
        // }
        // else if(this.state.results.Subject && this.state.results.Subject != "" && (this.state.results.Subject.charAt(0) === " " || this.state.results.Subject.charAt(strlength - 1) === " "))
        // {
        //     this.setState({ subhelpertext: 'Leading or trailing spaces are not allowed' })
        // }
        if (!editorVal) {
            this.setState({ helpertextbody: "Body is Required." })
        }

        if (strlength === "") {
            this.setState({ subhelpertext: 'Subject is required' })
        }
        else if (strlength > 100) {
            this.setState({ subhelpertext: 'Subject test length should be in the range of (1-100)' })
        }
        else if (((atob(editorVal)).length > 4000)) {
            this.setState({ helpertextbody: 'Body length should be in the range of (1-4000)' })
        }
        // if (this.state.results.AppContactsDetails && this.state.results.AppContactsDetails.length == 0 && this.props.accessPermissions.portal == "E" && this.props.match.params.key != 'VIOLATIONS') {
        //     this.setState({ helpertextcontact: 'Field is required' })
        // }
        // else if (this.state.results.InternalUsersDetails && this.state.results.InternalUsersDetails.length == 0 && this.props.accessPermissions.portal == "I") {
        //     this.setState({ helpertextinternaluser: 'Field is required' })
        // }
        else if (this.state.results && this.state.results.Subject) {

            if (!editorVal)
                this.setState({ helpertextbody: "Body is Required." })

            else if (((atob(editorVal)).length > 4000)) {
                this.setState({ helpertextbody: 'Body length should be in the range of (1-4000)' })
            } else
                this.setState({ helpertextbody: null })
            if (editorVal && this.state.results) {
                let vald = ((editorVal));
                this.state.results.Body = vald;
            }
            if (put && editorVal) {
                let createput = true
                if (conditions) {
                    if (!this.state.results[conditions.results]) createput = false
                }
                if (createput) {
                    const { routeProps, matchProps } = save.put
                    if (save.put && (save.put.matchProps || save.put.routeProps) && Array.isArray(save.put.matchProps || save.put.routeProps)) {
                        const tempsaveprops = save.put.matchProps || save.put.routeProps
                        tempsaveprops.forEach(each => {
                            const { value, custom } = each
                            if (custom) {
                                ids.push({ key: custom, value: dataItem[custom] })
                            }
                            else ids.push({ key: value, value: this.props.match.params[value] })
                        })
                    }
                    else {
                        id = this.props.match.params.routeid
                    }
                }
                else {
                    const { routeProps, matchProps } = save.post
                    if (save.post && (save.post.matchProps || save.post.routeProps) && Array.isArray(save.post.matchProps || save.post.routeProps)) {
                        const tempsaveprops = save.post.matchProps || save.post.routeProps
                        tempsaveprops.forEach(each => {
                            const { value } = each
                            ids.push({ key: value, value: this.props.match.params[value] })
                        })
                    }
                    else {
                        const { routeProps, matchProps } = save.post
                        id = routeProps ? this.props.match.params[routeProps.value] : null
                    }
                }

            }
            else if (post && editorVal) {
                const { routeProps, matchProps } = save.post
                if (save.post && (save.post.matchProps || save.post.routeProps) && Array.isArray(save.post.matchProps || save.post.routeProps)) {
                    const tempsaveprops = save.post.matchProps || save.post.routeProps
                    tempsaveprops.forEach(each => {
                        const { value } = each
                        ids.push({ key: value, value: this.props.match.params[value] })
                    })
                }
                else {
                    const { routeProps, matchProps } = save.post
                    id = routeProps ? this.props.match.params[routeProps.value] : null
                }
            }


            if (save && editorVal) {
                const { results, formControls, errors, errfieldnames } = this.state
                const requiredFields = this.requiredFields
                const requiredFieldsKeys = requiredFields && Object.keys(requiredFields)
                let changedFieldName = []
                let err
                if (requiredFieldsKeys) {
                    !errors && requiredFieldsKeys.forEach(each => {
                        if (requiredFields[each]) {
                            const value = formControls[each].value
                            if ((value === null) || (value === undefined) || (value === '')) {
                                err = true
                                changedFieldName.push({ ...this.state.formControls[each], name: each, error: true, helperText: 'Field is Required' })
                            }
                        }

                    })
                }
                if (err) {
                    console.log('errors----------------', changedFieldName)
                    this.setState({
                        errors: true,
                        changedFieldName: changedFieldName
                    })
                }
                else {
                    const { components, options, formgridadd, sidepopupadd } = this.props
                    const { addtoresults, title } = options ? options : { addtoresults: {} }
                    let res = { ...this.state.results, ...addtoresults }
                    components.forEach(each => {
                        const { options } = each
                        const { excludefromresults, name } = options ? options : {}
                        excludefromresults && delete res[name]
                    })
                    !errors && requestApi(save, (id || ids), res).then(results => {
                        const { headerProps } = options
                        const { navigateview, navigateadd } = headerProps;
                        if (navigateview) {
                            this.LayoutForm.current.clearResult();
                            this.setState({ showview: true, results: results, compareresults: results, showsend: true, showsavebtn: false }, () => {
                                // this.props.snackbarMessage(title + ' Updated Successfully', 'success')
                                //this.progressBar.complete();
                            })
                        }
                        if (navigateadd) {
                            if (formgridadd) {
                                // this.props.snackbarMessage(title + ' Saved Successfully', 'success');
                                // this.progressBar.complete();
                            }
                            if (sidepopupadd) {
                                //  this.props.snackbarMessage(title + ' Saved Successfully', 'success');
                                this.props.onClose();
                            }
                            else {
                                const { afterSaveroutes } = options;
                                const { connectingroute, connectingrouteid, routeparams, routeparams2, route, existparams } = afterSaveroutes;
                                if (!routeparams2 === '2' && routeparams && connectingroute && connectingrouteid) {
                                    this.props.history.push(route + routeparams && results[routeparams] + routeparams2 && results[routeparams2] + connectingroute + results[connectingrouteid])
                                }
                                else if (routeparams2 === '2') {
                                    this.props.history.push(route + this.props.match.params['routeid'] + '/' + this.props.match.params['routeid1'] + connectingroute + results[connectingrouteid])
                                }
                                else if (connectingroute) {
                                    if (existparams) {
                                        this.props.history.push(route + this.props.match.params[existparams] + connectingroute)
                                    }
                                    else
                                        if (connectingrouteid) {
                                            this.props.history.push(route + results[routeparams] + connectingroute + results[connectingrouteid])
                                        } else {
                                            this.props.history.push(route + results[routeparams] + connectingroute)
                                        }
                                }
                                else this.props.history.push(afterSaveroutes.route + results[afterSaveroutes.routeparams])
                                this.props.snackbarMessage(title + ' Saved Successfully', 'success')
                                // this.progressBar.complete();
                            }
                        }
                    }).catch(error => {
                        error.then(err => {
                            if (err && err.Subject) {
                                this.setState({ subhelpertext: err.Subject[0] })
                            }
                            if (err && err.Body) {
                                this.setState({ helpertextbody: err.Body[0] + ". " })
                            }
                        })
                    })
                }
            }
        }
        else {
            this.setState({ subhelpertext: 'Subject is required' })
        }
    }

    isHTML(str) {
        var doc = new DOMParser().parseFromString(str, "text/html");
        return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
    }

    autcompletetagshelpusers = {
        "options": {
            "label": "Involve Internal User(s)",
            "placeholder": "",
            "row": 0,
            "col": 0,
            "width": 12,
            "name": "InternalUsers",
            "itemname": "UserId",
            disabled: false,
            conditions: {
                hideonnull: { 'UserId': null }
            },
            "defaultSelected": true
        }
    }

    autcompletetagshelpcontacts = {
        "options": {
            "label": "Involve External Contact(s)",
            "placeholder": "",
            "row": 0,
            "col": 0,
            "width": 12,
            "name": "AppContacts",
            "itemname": "ContactId",
            disabled: false,
            conditions: {
                hideonnull: { 'ContactId': null }
            },
            "defaultSelected": true
        }
    }

    autcomcompletetagschange = (tags) => {
        console.log(tags)
        let resultdata = tags.target.value.map(a => a.id);
        let name = tags.target.name
        if (name == 'AppContacts') {
            this.setState({ helpertextcontact: null })
        }
        if (name == 'InternalUsers') {
            this.setState({ helpertextinternaluser: null })
        }
        let value = [...new Set(resultdata)].join()
        let stateresults = this.state.results || {}
        stateresults[name] = value
        stateresults[name + "Details"] = tags.target.value
        this.setState({
            [name]: [...new Set(resultdata)].join(),
            results: stateresults
        })
    }

    sendEmail = (resultsss, names, savetypes) => {
        const { options, dataItem } = this.props
        const { crud } = options
        const save = crud.send
        const { put, post } = save || {}
        const { conditions } = put || {}
        let ids = [], id
        if (put) {
            let createput = true
            if (conditions) {
                if (!this.state.compareresults[conditions.compareresults]) createput = false
            }
            if (createput) {
                const { routeProps, matchProps } = save.put
                if (save.put && (save.put.matchProps || save.put.routeProps) && Array.isArray(save.put.matchProps || save.put.routeProps)) {
                    const tempsaveprops = save.put.matchProps || save.put.routeProps
                    tempsaveprops.forEach(each => {
                        const { value, custom } = each
                        if (custom) {
                            ids.push({ key: custom, value: dataItem[custom] })
                        }
                        else ids.push({ key: value, value: this.props.match.params[value] })
                    })
                }
                else {
                    id = this.props.match.params.routeid
                }
            }
            else {
                const { routeProps, matchProps } = save.post
                if (save.post && (save.post.matchProps || save.post.routeProps) && Array.isArray(save.post.matchProps || save.post.routeProps)) {
                    const tempsaveprops = save.post.matchProps || save.post.routeProps
                    tempsaveprops.forEach(each => {
                        const { value } = each
                        ids.push({ key: value, value: this.props.match.params[value] })
                    })
                }
                else {
                    const { routeProps, matchProps } = save.post
                    id = routeProps ? this.props.match.params[routeProps.value] : null
                }
            }

        }
        // if(this.state.results.AppContactsDetails.length == 0 && this.state.results.InternalUsersDetails.length == 0 &&  this.props.accessPermissions.portal == "I"){
        //     this.setState({helpertextcontact: 'Field is required', helpertextinternaluser : 'Field is required'})
        // }
        // else if(this.state.results.AppContactsDetails.length == 0 &&  this.props.accessPermissions.portal == "E"){
        //     this.setState({helpertextcontact: 'Field is required'})
        // }
        // if ((this.state.results.AppContactsDetails && this.props.accessPermissions.portal == "E")||this.state.results.AppContactsDetails.length == 0) {
        //   this.setState({helpertextcontact: 'Field is required'})
        // }
        // if ((this.state.results.InternalUsersDetails && this.props.accessPermissions.portal == "I")|| this.state.results.InternalUsersDetails.length == 0) {
        //     this.setState({helpertextinternaluser : 'Field is required'})

        // }
        if ((save && this.state.results['AppContacts'] && this.state.results['AppContacts'] != '' && this.props.accessPermissions.portal == "E") || (save && this.state.results['InternalUsers'] && this.state.results['InternalUsers'] != '' && this.props.accessPermissions.portal == "I")) {
            const { results, formControls, errors, errfieldnames } = this.state
            const requiredFields = this.requiredFields
            const requiredFieldsKeys = requiredFields && Object.keys(requiredFields)
            let changedFieldName = []
            let err
            if (requiredFieldsKeys) {
                !errors && requiredFieldsKeys.forEach(each => {
                    if (requiredFields[each]) {
                        const value = formControls[each].value
                        if ((value === null) || (value === undefined) || (value === '')) {
                            err = true
                            changedFieldName.push({ ...this.state.formControls[each], name: each, error: true, helperText: 'Field is Required' })
                        }
                    }

                })
            }
            if (err) {
                console.log('errors----------------', changedFieldName)
                this.setState({
                    errors: true,
                    changedFieldName: changedFieldName
                })
            }
            else {
                const { components, options, formgridadd, sidepopupadd } = this.props
                const { addtoresults, title } = options ? options : { addtoresults: {} }
                let finalresult = { ...this.state.compareresults, AppContacts: this.state.results.AppContacts, InternalUsers: this.state.results.InternalUsers }
                let res = { ...this.state.compareresults, AppContacts: this.state.results.AppContacts, InternalUsers: this.state.results.InternalUsers, AppContactsDetails: this.state.results.AppContactsDetails.length > 0 ? this.state.results.AppContactsDetails : this.state.AppContactsDetails, InternalUsersDetails: this.state.results.InternalUsersDetails, PermitNumber: this.state.results.PermitNumber ? this.state.results.PermitNumber : this.state.PermitNumber }
                components.forEach(each => {
                    const { options } = each
                    const { excludefromresults, name } = options ? options : {}
                    excludefromresults && delete res[name]
                })
                !errors && requestApi(save, (id || ids), res,'',false,save.put.message).then(results => {
                    const { headerProps } = options
                    const { navigateview, navigateadd } = headerProps;
                    if (navigateview) {
                        this.LayoutForm.current.clearResult();
                        this.setState({ showsavebtn: false }, () => {
                            // this.props.snackbarMessage(title + ' Updated Successfully', 'success')
                            //this.progressBar.complete();
                        })
                    }
                    if (navigateadd) {
                        if (formgridadd) {
                            // this.props.snackbarMessage(title + ' Saved Successfully', 'success');
                            // this.progressBar.complete();
                        }
                        if (sidepopupadd) {
                            //  this.props.snackbarMessage(title + ' Saved Successfully', 'success');
                            this.props.onClose();
                        }
                        else {
                            const { afterSaveroutes } = options;
                            const { connectingroute, connectingrouteid, routeparams, routeparams2, route, existparams } = afterSaveroutes;
                            if (!routeparams2 === '2' && routeparams && connectingroute && connectingrouteid) {
                                this.props.history.push(route + routeparams && results[routeparams] + routeparams2 && results[routeparams2] + connectingroute + results[connectingrouteid])
                            }
                            else if (routeparams2 === '2') {
                                this.props.history.push(route + this.props.match.params['routeid'] + '/' + this.props.match.params['routeid1'] + connectingroute + results[connectingrouteid])
                            }
                            else if (connectingroute) {
                                if (existparams) {
                                    this.props.history.push(route + this.props.match.params[existparams] + connectingroute)
                                }
                                else
                                    if (connectingrouteid) {
                                        this.props.history.push(route + results[routeparams] + connectingroute + results[connectingrouteid])
                                    } else {
                                        this.props.history.push(route + results[routeparams] + connectingroute)
                                    }
                            }
                            else this.props.history.push(afterSaveroutes.route + results[afterSaveroutes.routeparams])
                            this.props.snackbarMessage(title + ' Saved Successfully', 'success')
                            // this.progressBar.complete();
                        }
                    }
                }).catch(error => {
                    error.then(err => {
                        if (err && err.Subject) {
                            this.setState({ subhelpertext: err.Subject[0] })
                        }
                        if (err && err.Body) {
                            this.setState({ helpertextbody: err.Body[0] + ". " })
                        }
                    })
                })
            }
        }
    }

    render() {
        const { options, defaults, order, type, compmap, className, styles, accessPermissions, accessLevelPermissions, dataItem, onFullScreen, onExitFullScreen, fullscreen, exitfullscreen, onNextItemClick, onPrevItemClick, ...otherProps } = this.props;
        const { crud, title, headerProps, savebtn } = options ? options : {}
        const { savebutton, editbutton, backbutton, breadcrumbs, notdisableitem } = headerProps ? headerProps : {}
        const save = crud ? crud.update : null
        const { isedited, showsavebtn, InternalUsersDetails, AppContactsDetails, InternalUsers, AppContacts, showsend } = this.state
        let res = this.state.results != null ? this.state.results : {};
        let optns = { "width": 4 }
        let approvedflag = this.props.initialloadextrainfo && this.props.initialloadextrainfo.results && this.props.initialloadextrainfo.results["APPROVED_FLAG"] ? this.props.initialloadextrainfo.results["APPROVED_FLAG"] : 'N'
        let savebuttondisplay = true;
        let roleaccess = this.props.accessPermissions && this.props.accessPermissions.moduleDistrictAccess && this.props.accessPermissions.moduleDistrictAccess ? Object.values(this.props.accessPermissions.moduleDistrictAccess).includes('Add') ? 'CanAdd' : Object.values(this.props.accessPermissions.moduleDistrictAccess).includes('Update') ? 'CanEdit' : Object.values(this.props.accessPermissions.moduleDistrictAccess).includes('Read') ? 'CanRead' : 'CanAdd' : 'CanAdd'
        let portal = this.props.accessPermissions ? this.props.accessPermissions.portal : null
        let canAdd = (!roleaccess || !portal) ? true : portal == "I" && roleaccess == "CanAdd" ? true : (portal == "E") ? true : false
        let canEdit = (!roleaccess || !portal) ? true : portal == "I" && roleaccess == "CanEdit" ? true : (portal == "E") ? true : false
        let canRead = (!roleaccess || !portal) ? true : portal == "I" && roleaccess == "CanRead" ? true : (portal == "E") ? true : false
        return (
            <Fragment>
                <div>
                    {headerProps && <FormHeader accessPermissions={accessPermissions} accessLevelPermissions={accessLevelPermissions} breadcrumbs={breadcrumbs} title={title} handleSave={this.handleSave} SaveTemplateInfo={this.sendEmail} sendtemplatebutton={showsend ? (canAdd || canEdit) ? savebuttondisplay : false : false} savebutton={(canAdd || canEdit) ? savebuttondisplay : false} backbutton={backbutton} isedited={isedited} handleBack={this.handleBack}
                        onFullScreen={onFullScreen} onExitFullScreen={onExitFullScreen} fullscreen={fullscreen} exitfullscreen={exitfullscreen} onNextItemClick={onNextItemClick} onPrevItemClick={onPrevItemClick}
                        dataItem={dataItem} navigateup={dataItem ? (dataItem.Id ? true : false) : false} navigatedown={dataItem ? (dataItem.Id ? true : false) : false} />}
                    {/* <div> <ProgressBar ref={e => this.progressBar = e} /></div><br /> */}
                    <div className="col-md-12">
                    <div className="mt-3"><CFormTextBox value={res.Subject} name="Subject" label="Subject" options={optns} required={true} disabled={!notdisableitem ? (res.Subject != null ? true : false) : false} handleFieldChange={this.handleSubjectChanged} error={this.state.subhelpertext ? this.state.subhelpertext : ''} /></div>
                        {this.state.subhelpertext && <FormHelperText style={{ color: "#F44336" }}>{this.state.subhelpertext}</FormHelperText>}<br />
                        <CRichTextEditor ref={this.LayoutForm} form={true} handleFieldChange={this.handleCommentsChange} /><br />
                        {this.state.helpertextbody && <FormHelperText style={{ color: "#F44336" }}>{this.state.helpertextbody}</FormHelperText>}<br />
                        <CFormDiscussionPanel value={res.InverseParentDiscussion} name="" />
                        {res.Body && <Card> <CardContent>

                            <Typography color="#9FA682" >
                                {res.CreatedByName ? res.CreatedByName : ""}  commented on {moment(res.CreatedOn).format('MM/DD/YYYY')}
                            </Typography>

                            <Typography className="" variant="body2" component="p" gutterBottom>

                                {this.isHTML(atob(res.Body)) ?
                                    <div dangerouslySetInnerHTML={{ __html: atob(res.Body) }} /> : res.Body}
                                <br />
                            </Typography>

                        </CardContent>
                        </Card>}
                        {this.props.accessPermissions.portal != 'E' && <Autocompletetags
                            // required = {true}
                            dropdownvalues={InternalUsersDetails}
                            {...this.autcompletetagshelpusers}
                            handleFieldChange={this.autcomcompletetagschange}
                            defaultSelValue={res.InternalUsers}
                            defaultSelected={true}
                        />}
                        {this.state.helpertextinternaluser && <FormHelperText style={{ color: "#F44336" }}>{this.state.helpertextinternaluser}</FormHelperText>}<br />
                         <Autocompletetags
                            //required = {this.props.accessPermissions.portal == 'E' ? true : false}
                            dropdownvalues={AppContactsDetails}
                            {...this.autcompletetagshelpcontacts}
                            handleFieldChange={this.autcomcompletetagschange}
                            defaultSelValue={res.AppContacts}
                        />
                        {this.state.helpertextcontact && <FormHelperText style={{ color: "#F44336" }}>{this.state.helpertextcontact}</FormHelperText>}<br />

                        {/* navigatingnext={this.state.navigatingnext} navigatingprev={this.state.navigatingprev} nextItem={this.nextItem} prevItem={this.prevItem}  */}
                        {/* {this.state.openconfirmation && <Confirmation open={this.state.openconfirmation} handleBack={this.handleBack} />} */}
                    </div>
                </div>
            </Fragment>
        );
    }

}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

DiscussionsForm = connect(null, mapActions)(DiscussionsForm)
export default withRouter(DiscussionsForm)
