import withWidth from '@material-ui/core/withWidth';
import React, { Component } from 'react';
import { GetData, PutData } from '../../core/controls/helpers/requests';
import { getEnvVariable } from '../../modules/environmentalList';
import SimpleGrid from '../../core/modules/Grid/cgrid';
import descconsgridjson from '../../json/sitepermittypes/descconsgridjson';
const _ = require('lodash');

class DescCons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items : []
        };
        this.jsoninfo = { ...descconsgridjson }
    }



    componentDidMount() {
        this.IntialLoad();
    }

   IntialLoad = () => {

        let url = getEnvVariable('AdminConfig') + `/api/SitePermitTypeDescriptionOfConstruction/${this.props.dataItem.Id}/Grid`;
        GetData(url).then(response => {
            this.setState({items : response.data})
        })

    }

    handleCustomBack = () => {
        this.IntialLoad();
    }

     onUp = (dataItem) => {
        let url = getEnvVariable('AdminConfig') + `/api/SitePermitTypeDescriptionOfConstruction/${dataItem.Id}/UP`;
        PutData(url).then(res => {
            if (res.isMoved) {
               this.IntialLoad()
            }
        })

    }
     onDown = (dataItem) => {
        let url = getEnvVariable('AdminConfig') + `/api/SitePermitTypeDescriptionOfConstruction/${dataItem.Id}/DOWN`;
        PutData(url).then(res => {
            if (res.isMoved) {
                this.IntialLoad()
            }
        })
    }
    componentDidUpdate(prevProps, prevState)  {
        if ((!(prevState.items.length > 0)) && !(_.isEqual(prevState.items, this.state.items))) {
            this.IntialLoad()
        }
        if(!(_.isEqual(prevState.items, this.state.items))) {
            this.IntialLoad()
        }
    }
    render() {
    return (
        <div>
            <SimpleGrid
                onUp={this.onUp}
                onDown={this.onDown}
                data={this.state.items}
                columns={this.jsoninfo.options.gridcolumns} useComp={true} enablerender={true} {...descconsgridjson} {...this.props} handleCustomBack = {this.handleCustomBack} upanddowndisplaycondition = {true} />
        </div>
    )
    }
}

export default withWidth()(DescCons)
