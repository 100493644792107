import React, { useState, useEffect, Fragment } from 'react'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles';
import StartTime from './chooseTime'
import Card from './card'

const styles = theme => ({
    root: {
        padding: '20px',
    },
    secondaryPaper: {
        padding: '20px',
        marginTop: '20px'
    },
    radioBtn: {
        marginTop: '20px'
    }
})

const Monthly = (props) => {
    const [every, setEvery] = useState()
    const [value, setValue] = useState(props.value)

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    useEffect(() => {
        const { value } = props
        if (value[2] === 'L')
            setEvery("2")
        else if (value[2] === 'LW')
            setEvery("3")
        else if (value[2].startsWith('L'))
            setEvery("4")
        else
            setEvery("2")
    }, [])


    const onDayChange = (e) => {
        if (((parseInt(e.target.value) > 0 && parseInt(e.target.value) <= 31)) || e.target.value === "") {
            let val = [value[0] === '*' ? '0' : value[0], value[1] === '*' ? '0' : value[1], value[2], value[3], '*'];
            if (e.target.value === '') {
                val[2] = '1'
            } else {
                val[2] = `${e.target.value}`;
            }
            props.onChange(val)
        }
    }

    const onMonthChange = (e) => {
        if (((parseInt(e.target.value) > 0 && parseInt(e.target.value) <= 12)) || e.target.value === "") {
            let val = [value[0] === '*' ? '0' : value[0], value[1] === '*' ? '0' : value[1], value[2], '1/1', '*'];
            if (e.target.value === '') {
                val[3] = '1/1'
            } else {
                val[3] = `1/${e.target.value}`;
            }
            props.onChange(val)
        }
    }

    const onLastDayChange = (e) => {
        if (((parseInt(e.target.value) >> 0 && parseInt(e.target.value) <= 31)) || e.target.value === "") {
            let val = [value[0] === '*' ? '0' : value[0], value[1] === '*' ? '0' : value[1], value[2], '1/1', '*'];
            if (e.target.value === '') {
                val[2] = 'L-1'
            } else {
                val[2] = `L-${e.target.value}`;
            }
            props.onChange(val)
        }
    }
    const onAtHourChange = (e) => {
        let val = value;
        val[1] = `${e.target.value}`;
        props.onChange(val)
    }
    const onAtMinuteChange = (e) => {
        let val = value;
        val[0] = `${e.target.value}`;
        props.onChange(val)
    }

    const classes = props
    return (<div className="tab-pane" >
        <Paper className={classes.root}>
            <Radio style={{ paddingLeft: '14px' }}
                className={classes.radioBtn}
                onChange={(e) => { setEvery(e.target.value); props.onChange([value[0] === '*' ? '0' : value[0], value[1] === '*' ? '0' : value[1], '1', value[3] === '*' ? '1/1' : value[3], '*']) }}
                value="1"
                name="MonthlyRadio"
                checked={every === "1" ? true : false}
                inputProps={{ 'aria-label': 'DailyRadio' }}
            />
            <span> &nbsp; On the &nbsp;</span>
            <TextField
                id="outlined-number"
                label="Day of"
                disabled={every === "1" ? false : true}
                value={value[2]}
                onChange={onDayChange}
                type="number"
                InputLabelProps={{
                    readOnly: every !== "1",
                    shrink: true,
                }}
                margin="normal"
            />
            <span> &nbsp; of every &nbsp;</span>
            <TextField
                id="outlined-monthly-number"
                label="Month(s)"
                disabled={every === "1" ? false : true}
                value={value[3].split('/')[1] ? value[3].split('/')[1] : ''}
                onChange={onMonthChange}
                type="number"
                InputLabelProps={{
                    readOnly: every !== "1",
                    shrink: true,
                }}
                margin="normal"
            />
            <div className="col">
                <FormControlLabel
                    value="top"
                    control={<Radio
                        onChange={(e) => { setEvery(e.target.value); props.onChange([value[0] === '*' ? '0' : value[0], value[1] === '*' ? '0' : value[1], 'L', '*', '*']) }}
                        value="2"
                        name="DailyRadio"
                        checked={every === "2" ? true : false}
                        inputProps={{ 'aria-label': 'DailyRadio' }}
                    />}
                    label="Last day of every month"
                    labelPlacement="right"
                />
            </div>
            <div className="col">
                <FormControlLabel
                    value="top"
                    control={<Radio
                        onChange={(e) => { setEvery(e.target.value); props.onChange([value[0] === '*' ? '0' : value[0], value[1] === '*' ? '0' : value[1], 'LW', '*', '*']) }}
                        value="3"
                        name="DailyRadio"
                        checked={every === "3" ? true : false}
                        inputProps={{ 'aria-label': 'DailyRadio' }}
                    />}
                    label="On the last weekday of every month"
                    labelPlacement="right"
                />
            </div>
            <div className='pl-1'>
                <Radio
                    className={classes.radioBtn}
                    onChange={(e) => { setEvery(e.target.value); props.onChange([value[0] === '*' ? '0' : value[0], value[1] === '*' ? '0' : value[1], `L-${1}`, '*', '*']) }}
                    value="4"
                    name="MonthlyRadio"
                    checked={every === "4" ? true : false}
                    inputProps={{ 'aria-label': 'MonthlyRadio' }}
                />
                <TextField
                    id="outlined-number"
                    label="Day(s) before the end of the month"
                    value={value[2].split('-')[1]}
                    onChange={onLastDayChange}
                    type="number"
                    InputLabelProps={{
                        readOnly: every !== "4",
                        shrink: true,
                    }}
                    margin="normal"
                />
            </div>
            &nbsp;
        </Paper>
        <br />
        <Card label="Start time">
            <StartTime hour={value[1]} minute={value[0]} onAtMinuteChange={onAtMinuteChange} onAtHourChange={onAtHourChange} />
        </Card>
    </div>)
}

export default withStyles(styles)(Monthly)
