import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setSectionsReload, snackbarMessage } from '../../core/actions';
import ConfirmationPopup from '../../core/controls/components/confirmationPopup';
import { GetData, PutData } from '../../core/modules/form/formsapi';
import { getEnvVariable } from '../environmentalList';

var inthis;
class CWorkflow extends Component {
    constructor(props) {
        super(props);
        //  const { options } = props
        const { WorkflowId, SitePermitTypeId, ApplicationID } = props ? props : { WorkflowId: 0, SitePermitTypeId: 0, ApplicationID: 0 }
        this.state = {
            SitePermitTypeId: SitePermitTypeId,//this.props.apptypeId,
            WorkflowId: WorkflowId,
            // ParentWorkflowId: 0,
            ApplicationID: ApplicationID,//this.props.applicationid,
            // siteID: 0,
            workflowOptions: [],
            workflowStatus: 0,
            isApprover: false,
            isAssigned: true,
            currentTaskAssigned: 0,
            workflowStage: '',
            workflowTask: {},
            isForceInspection: false,
            currentStatus: '',
            message: '',
            statusOpen: false,
            prevStatusVal: ''
        }
    }

    componentDidMount() {
        inthis = this;
        if (inthis.props.value !== '' && parseInt(inthis.props.value) > 0 && inthis.props.edit)
        this.loadWorkflowObject(inthis.props.value);
        else this.loadWorkflowObject();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.FieldKey === "WORKFLOW_STATUS" && this.props && this.props.value && this.props.value !== '' && prevProps.value !== this.props.value) {
            inthis = this;
            this.loadWorkflowObject(inthis.props.value);
        }
    }

    reload = () => {
        const current = this.props.location.pathname;
        this.props.history.replace(`/reload`);
        setTimeout(() => {
            this.props.history.replace(current);
        });
    }

    loadWorkflowObject(tworkflowId) {
        let tempWorkflowId = tworkflowId ? tworkflowId : inthis.state.WorkflowId
        if (typeof window.workflowObj !== "undefined" && tempWorkflowId && tempWorkflowId > 0) {
            try {        //Verify the workflow access
                console.log("tworkflowId" + tworkflowId + "state tworkflowId" + inthis.state.WorkflowId)

                window.workflowObj.data.getTasks(function (q) {
                    window.workflowObj.data.getFlow(tempWorkflowId, function (retFlow) {
                        var currFlow = retFlow.currflow;
                        if (q.length === 0) {
                            inthis.setState({ workflowStatus: currFlow.status, workflowOptions: currFlow.options, WorkflowId: tworkflowId ? tworkflowId : inthis.state.WorkflowId }, () => {
                                inthis && inthis.props && inthis.props.handleFieldValue({ target: { value: '', name: 'WORKFLOW_STATUS', checked: false }, currentTarget: { dataset: null } })
                            });
                        } else {
                            //From currflow check assigneduserid (userid) and disable workflow dropdown.
                            var currTask = null;
                            for (var i = 0; i < q.length; i++) {
                                var task = q[i];
                                if (task.flowid === tempWorkflowId) {
                                    currTask = task;
                                    break;
                                }
                            }
                            if (currTask !== null) {
                                console.log(task.approver, task.investigator, 'task.investigator --- Inspection check');
                                inthis.setState({
                                    workflowTask: currTask,
                                    isApprover: currTask.approver,
                                    isAssigned: currTask.assignee === inthis.props.accessPermissions.userId, //(task.assignee === localStorage.username),//Compare with current loggedin user
                                    workflowStatus: currFlow.status,
                                    workflowOptions: currFlow.options,
                                    currentTaskAssigned: currTask.assignee,
                                    workflowStage: currFlow.group,
                                    isForceInspection: currTask.investigator,
                                    WorkflowId: tworkflowId ? tworkflowId : inthis.state.WorkflowId
                                }, () => {
                                    inthis && inthis.props && inthis.props.handleFieldValue({ target: { value: '', name: 'WORKFLOW_STATUS', checked: false }, currentTarget: { dataset: null } })
                                });
                            } else {
                                let isApprover = null
                                let xml = retFlow.xml
                                let regexp = /flowable:assignee="([a-z]*)" flowable:approver="true"/g
                                let match = regexp.exec(xml)
                                while (match !== null) {
                                    // matched text: match[0]
                                    // match start: match.index
                                    // capturing group n: match[n]
                                    isApprover = match[1]
                                    break
                                }
                                isApprover = inthis.props.accessPermissions.userId === isApprover //Compare with current loggedin user
                                inthis.setState({
                                    workflowStatus: currFlow.status,
                                    isApprover: isApprover,
                                    workflowOptions: currFlow.options,
                                    currentTaskAssigned: currFlow.userid,
                                    workflowStage: currFlow.group,
                                    isAssigned: false,
                                    WorkflowId: tworkflowId ? tworkflowId : inthis.state.WorkflowId
                                }, () => {
                                    inthis && inthis.props && inthis.props.handleFieldValue({ target: { value: '', name: 'WORKFLOW_STATUS', checked: false }, currentTarget: { dataset: null } })
                                });
                            }
                        }
                    });
                })
            }
            catch (ex) {
                this.props.handleFieldValue({ target: { value: '', name: 'WORKFLOW_STATUS', checked: false } })
            }
        }
    }

    handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    };



    hasInspection(e) {
        //Check application has any inspection
        var sourceURL = getEnvVariable('Application') + `/api/InspectionSimple/${this.state.ApplicationID}/Grid`;
        GetData(sourceURL, "").then(results => {
            console.log(results);
            if (results && results.data && Array.isArray(results.data) && results.data.length > 0) {
                this.changeWorkflowStatus(e);
            } else {
                inthis.props.snackbarMessage(`Inspection Check : Please add inspection to continue workflow`, 'error');
            }
        })
    }

    changeWorkflowStatus(e) {
        let workflowChangeObj = { task: this.state.workflowTask, comment: "No Comment", action: e.target.value }
        //status changed to approved while workflow recommended
        let workflowStatusUpdateUrl = getEnvVariable('Application') + "/api/Application/UpdateWorkflow/" + this.state.SitePermitTypeId + "/" + this.state.ApplicationID + "?&status=" + e.target.value + "&workflowid=" + this.state.WorkflowId;
        if (e.target.value === "Recommended") {
            let ApplicationStatusUpdateUrl = ""
            if (this.state.workflowTask.approver === true) {
                ApplicationStatusUpdateUrl = getEnvVariable('Application') + `/api/Application/UpdateStatus/` + this.state.SitePermitTypeId + "/" + this.state.ApplicationID + `?stage=Approved&isUpdateStatus=true`
            }
            else if (this.state.workflowTask.issuer === true) {
                ApplicationStatusUpdateUrl = getEnvVariable('Application') + `/api/Application/UpdateStatus/` + this.state.SitePermitTypeId + "/" + this.state.ApplicationID + `?stage=Issued&isUpdateStatus=true`
            }
            try {
                //  workflowChangeObj.task.name = "Investigate";
                this.props.snackbarMessage(`Please wait Workflow status is changing to ${e.target.value} `, 'info')
                window.workflowObj.data.complete(workflowChangeObj, function (q) {
                    PutData(workflowStatusUpdateUrl, null).then(res => {
                        if (ApplicationStatusUpdateUrl !== "") {
                            PutData(ApplicationStatusUpdateUrl, null).then(rappes => {
                                inthis.props.snackbarMessage(`Workflow status changed to ${e.target.value} Successfully`, 'success')
                                inthis.setState({ workflowStatus: e.target.value }, () => {
                                    inthis.reload();
                                    inthis.props.setSectionsReload(true)
                                    inthis.loadWorkflowObject()
                                })
                            })
                        }
                        else {
                            inthis.props.snackbarMessage(`Workflow status changed to ${e.target.value} Successfully`, 'success')
                            inthis.setState({ workflowStatus: e.target.value }, () => {
                                inthis.reload();
                                inthis.props.setSectionsReload(true)
                                inthis.loadWorkflowObject()
                            })
                        }

                    }).catch(ex => {
                        console.log("here workflow excep")
                        inthis.props.snackbarMessage(`Workflow status changed to ${e.target.value} Successfully`, 'success')
                        inthis.setState({ workflowStatus: e.target.value }, () => {
                            inthis.reload();
                            inthis.props.setSectionsReload(true)
                            inthis.loadWorkflowObject()
                        })
                        // inthis.props.snackbarMessage(`Workflow status failed to  changed to ${e.target.value} `, 'error')
                    })
                    // inthis.setState({ workflowStatus: e.target.value }, () => {
                    //     inthis.props.setSectionsReload(true)
                    //     inthis.loadWorkflowObject()
                    // })
                }, function (e) {
                    inthis.props.snackbarMessage(`Workflow status changed to ${e.target.value} Successfully`, 'success')
                    inthis.setState({ workflowStatus: e.target.value }, () => {
                        inthis.reload();
                        inthis.props.setSectionsReload(true)
                        inthis.loadWorkflowObject()
                    })
                    //inthis.props.snackbarMessage(`Workflow status failed to  changed to ${e.target.value} `, 'error')
                });
            } catch (e) { inthis.props.snackbarMessage(`Workflow status failed to  changed to ${e.target.value} `, 'error') }

        }
        else {
            try {
                this.props.snackbarMessage(`Please wait Workflow status is changing to ${e.target.value} `, 'info')
                window.workflowObj.data.complete(workflowChangeObj, function (q) {
                    PutData(workflowStatusUpdateUrl, null).then(res => {
                        inthis.props.snackbarMessage(`Workflow status changed to ${e.target.value} Successfully`, 'success')
                        inthis.setState({ workflowStatus: e.target.value }, () => {
                            inthis.reload();
                            inthis.props.setSectionsReload(true)
                            inthis.loadWorkflowObject()
                        })
                    }).catch(ex => {
                        inthis.props.snackbarMessage(`Workflow status changed to ${e.target.value} Successfully`, 'success')
                        inthis.setState({ workflowStatus: e.target.value }, () => {
                            inthis.reload();
                            inthis.props.setSectionsReload(true)
                            inthis.loadWorkflowObject()
                        })
                        // inthis.props.snackbarMessage(`Workflow status failed to  changed to ${e.target.value} `, 'error')
                    })
                    // inthis.setState({ workflowStatus: e.target.value }, () => {
                    //     inthis.props.setSectionsReload(true)
                    //     inthis.loadWorkflowObject()
                    // })
                }, function (e) {
                    inthis.props.snackbarMessage(`Workflow status changed to ${e.target.value} Successfully`, 'success')
                    inthis.setState({ workflowStatus: e.target.value }, () => {
                        inthis.reload();
                        inthis.props.setSectionsReload(true)
                        inthis.loadWorkflowObject()
                    })
                    //inthis.props.snackbarMessage(`Workflow status failed to  changed to ${e.target.value} `, 'error')
                });
            } catch (e) { inthis.props.snackbarMessage(`Workflow status failed to  changed to ${e.target.value} `, 'error') }
        }

    }


    handleStatusClose = () => {
        this.setState({ statusOpen: false, currentStatus: "", prevStatusVal: '', message: '' });
    }

    //Permit approver - yes show only label
    //he is recommending, You are the permit approver,recommending will approve this Application - Are u sure u want to recommend
    //  he is not recommend ->You are the permit approver,Not recommending will deny the Application - Are u sure u want to not recommend
    //extender - yes
    //inspection needed - yes


    handleDropdownChange = (e) => {
        let prevVal = this.state.workflowStatus;
        let mgs = this.state.workflowStage === 'Permit Approver' ? `You are the permit approver, ${e.target.value} will ${e.target.value === 'Recommended' ? 'Approve' : "Deny"} Application.` : ""
        this.setState({
            statusOpen: true, prevStatusVal: prevVal, currentStatus: e, message: `${mgs} Are you sure to change the status to ${e.target.value}`
        });
    }

    handleSelectChange = (e) => {
        if (this.state.isForceInspection)
            this.hasInspection(e);
        else
            this.changeWorkflowStatus(e);
    }

    handleStatusSubmit = () => {
        this.setState({ statusOpen: false, prevStatusVal: '', message: '' }, () => {
            this.handleSelectChange(this.state.currentStatus)
        });
    }

    render() {
        const { edit, value, tooltip } = this.props;
        let valueindex = 0;
        let newworkflowoptions = this.state.workflowOptions
        let removedworkflowoptions = ["Not Started", "Not Forwarded", "Waiting", "Approved", "Denied"]
        if (this.state.workflowOptions && this.state.workflowOptions.length > 0) {
            newworkflowoptions = this.state.workflowOptions.filter(element => {
                if (!removedworkflowoptions.includes(element.name)) {
                    return element
                }
            });
            valueindex = this.state.workflowOptions.findIndex(x => x.value === (this.state.workflowStatus || value));
        }

        return (
            <div className="sketch_ToolBox_Widget" >
                <ConfirmationPopup value={this.state.statusOpen || false} onSubmit={this.handleStatusSubmit} onClose={this.handleStatusClose}
                    title="Status Change" description={this.state.message || ""} />
                {this.state.WorkflowId && parseInt(this.state.WorkflowId) > 0 ? (
                    <span >
                        {edit &&
                            <FormControl className="col-sm-12" >
                                <InputLabel >{'Workflow Status'}</InputLabel>
                                <Select
                                    disabled={!this.state.isAssigned || this.state.applicationStatus === 30 || this.state.applicationStatus === 50 || (this.props.accessPermissions.isAdmin === "N" && !this.state.isAssigned)}
                                    value={this.state.workflowStatus}
                                    onChange={this.handleDropdownChange}
                                    className="col-sm-12"
                                >
                                    {newworkflowoptions.map(m => { return <MenuItem disabled={m.disabled} value={m.value}> {m.name}</MenuItem> })}
                                </Select>
                            </FormControl>
                        }
                        {!edit && <div>
                            <Typography variant={'caption'} className="fw-bold" >{'Workflow Status'}    < Tooltip title={tooltip ? tooltip : 'Workflow status'} placement="bottom-start" >
                                <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
                            </Tooltip> </Typography><br />
                            <Typography variant={'body2'} className="text-secondary" color={'textPrimary'}> {(this.state.workflowOptions && this.state.workflowOptions[valueindex]) ? this.state.workflowOptions[valueindex].name : 'No Status'}  </Typography>
                        </div>}
                    </span>
                ) : (< div >
                    <Typography variant={'caption'} className="text-secondary">{'Workflow Status'}    < Tooltip title={tooltip ? tooltip : 'Workflow status'} placement="bottom-start" >
                        <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
                    </Tooltip> </Typography><br />
                    <Typography variant={'body2'} className="fw-bold" color={'textPrimary'}>{'N/A'}   </Typography>
                </div>)
                }
            </div>
        );
    }


    componentDidCatch(error, info) {
        console.log(error);
    }
}


const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage, setSectionsReload }, dispatch);
}

CWorkflow = connect(null, mapActions)(CWorkflow)
export default withRouter(CWorkflow);


//      /*disabled={!this.state.isAssigned} */
/*disabled = {!this.state.isAssigned || this.state.applicationStatus === 30 || this.state.applicationStatus === 50}*/