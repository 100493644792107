import React, { useState, Fragment } from 'react'
import CheckBox from '../../../../controls/components/checkbox';
import Dropdown from '../../../../controls/components/dropdown';
import FieldSettings from './fieldsettings';
import DNSIcon from '@material-ui/icons/Dns'
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationDialog from "../../../../controls/components/confirmationPopup";

const transformProps = {
    transformProps: {
        "Y": true,
        "N": false,
        true: "Y",
        false: "N"
    }
}

export default function ViewFields({ values, type, viewResults, handleDelete, fields, handleFieldValue, isLabel, isSidebar = false }) {
    const [open, setOpen] = useState(false)
    const [confirmation, setConfirmation] = useState(false)
    const EachResultChange = (event) => {
        let newSet = viewResults
        const { name, value } = event.target;
        if (name === 'IsVisible') {
            newSet['IsRequired'] = !event.target.checked ? 'N' : newSet['IsRequired']
            newSet['IsEnabled'] = !event.target.checked ? 'N' : newSet['IsEnabled']
        }
        if (name === 'IsEnabled') {
            newSet['IsRequired'] = !event.target.checked ? 'N' : newSet['IsRequired']
        }
        let each = null
        if (viewResults && viewResults.MasterFieldId)
            each = fields.find(each => each.id === viewResults.MasterFieldId)
        if (each && each.isVirtual) {
            newSet['IsVirtual'] = true;
            newSet['ColumnName'] = each.name;
            newSet['MasterFieldId']= null
            newSet['MasterFieldName']= each.name
        }
        else newSet['SystemBusinessRuleFieldId'] = null;
        newSet[name] = event.target.checked ? 'Y' : 'N'
        handleFieldValue(newSet)
    }

    const handleFieldSettings = (event) => {
        let newSet = viewResults
        const { name, value } = event.target;
        newSet[name] = value
        handleFieldValue(newSet)
    }

    const ConfirmDelete = () => {
        handleDelete(viewResults)
        setConfirmation(false);
    }

    const handleCancelDelete = () => {
        setConfirmation(false);
    }
    const Delete = () => {
        setConfirmation(true);
    }

    let selectValues = (fields && fields.length > 0) && (fields.find(f => viewResults.SystemBusinessRuleId ?
        f.name === viewResults.FieldName : f.id === parseInt(viewResults.MasterFieldId)));

    let viewFieldType = 'text'
    if (viewResults) {
        if (viewResults.SystemBusinessRuleId)
            viewFieldType = fields && fields.length > 0 && (fields.find(f => (f.id === viewResults.FieldName)) || {}).editorType
        else viewFieldType = fields && fields.length > 0 && (fields.find(f => (f.id === viewResults.MasterFieldId)) || {}).editorType
    }
    return (<div className="col-sm-12">
        <div className="row col-sm-12">
            <div className="col-sm-5 text-secondary">
                {isLabel ? 'Name' : null} {isLabel && <br />}
                {viewResults.MasterFieldId ?
                    <small> {fields && fields.length > 0 && fields.map(menu => { if (viewResults.isVirtual ? (viewResults.MasterFieldName) : (parseInt(viewResults.MasterFieldId)) === (viewResults.isVirtual ? menu.ColumnName : menu.id)) return menu.label })}</small>
                    : <small>{viewResults.FieldName}</small>}
            </div>
            <small className="col-sm-2" >
                <CheckBox labelPlacement="top" name="IsVisible" label={isLabel ? 'Show' : null} handleFieldValue={EachResultChange} value={viewResults && viewResults.IsVisible} extraProps={transformProps} />
            </small>
            <div className="col-sm-3 row">
                <div className="row"><small className="col-sm-7">
                    <CheckBox labelPlacement="top" label={isLabel ? 'Enabled' : null} name="IsEnabled" disabled={viewResults.IsVisible && viewResults.IsVisible == 'Y' ? false : true} handleFieldValue={EachResultChange} value={viewResults && viewResults.IsEnabled} extraProps={transformProps} /></small>
                    <small className="col-sm-4">
                        <CheckBox labelPlacement="top" handleFieldValue={EachResultChange} disabled={viewResults.IsVisible && viewResults.IsVisible === 'Y' ? (viewResults.IsEnabled && viewResults.IsEnabled == 'Y' ? false : true) : true} label={isLabel ? 'Required' : null}
                            name="IsRequired" extraProps={transformProps} value={viewResults && viewResults.IsRequired} />
                    </small>
                </div>
            </div>
            {((viewResults && (viewResults.MasterFieldId || viewResults.SystemBusinessRuleId)) || open) && <FieldSettings
                values={(selectValues && selectValues.values) ? selectValues.values : []}
                type={viewFieldType || 'text'}
                {...viewResults} fieldopen={open} allfields={fields}
                closeFieldSettings={() => { setOpen(false) }} fieldSettingsValue={handleFieldSettings} />
            }
            <label htmlFor="icon-button-file" className={isLabel ? "col-sm-2 pt-4 text-center" : "col-sm-2 text-center"}>
                <IconButton color="primary" component="span" onClick={() => { setOpen(true) }} > <DNSIcon /></IconButton>
                {!isSidebar && <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => Delete()}><DeleteIcon /> </IconButton>}
                {confirmation && <ConfirmationDialog value={confirmation} title="Are you sure you want to delete?" onSubmit={ConfirmDelete} onClose={handleCancelDelete} />}
            </label>
        </div>
    </div >)
}