import { getEnvVariable } from "../../modules/environmentalList";
import { PostData } from "../controls/helpers/requests";
import { GetFetchProps, getToken } from "../modules/header"

const SHOW_POPUP = 'SHOW_POPUP';
const HIDE_POPUP = 'HIDE_POPUP';
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const SNACKBAR_MESSAGE = 'SNACKBAR_MESSAGE';
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const SNACKBAR_MESSAGE_TYPE = 'SNACKBAR_MESSAGE_TYPE';
export const PROJMESSAGES = 'PROJMESSAGES';
export const PASS_COMPMAP = 'PASS_COMPMAP';
export const OPEN_TOUR = 'OPEN_TOUR';
export const CLOSE_TOUR = 'CLOSE_TOUR'
export const WORKFLOW_RELOAD = 'WORKFLOW_RELOAD';
export const UNSAVED_RESULTS = 'UNSAVED_RESULTS';
export const UNSAVED_DATA = 'UNSAVED_DATA';
export const PASSTAB_DATA = 'PASSTAB_DATA';
export const RECORD_DATA = 'RECORD_DATA';
export const RESET_VALUES = 'RESET_VALUES';
export const CONFIRMATION = 'CONFIRMATION';
export const BUSINESS_RULES_ONEVENT = 'BUSINESS_RULES_ONEVENT';
export const ONEVENT_STATUS_TRANS = 'ONEVENT_STATUS_TRANS';
export const ONEVENT_SHOW_ERRORS = 'ONEVENT_SHOW_ERRORS'
export const TRIGGER_ONLOAD_SAVE = 'TRIGGER_ONLOAD_SAVE'
export const SITE_PERMIT_TYPE_STATUS = 'SITE_PERMIT_TYPE_STATUS'
export const TRIGGER_AFTER_SAVE = 'TRIGGER_AFTER_SAVE'
export const showTour = (isTourOpen) => {
    return {
        type: OPEN_TOUR,
        isTourOpen: isTourOpen,
    }
}

export const onEventStatusUpdate = (onEventTransition = false) => {
    return {
        type: ONEVENT_STATUS_TRANS,
        onEventTransition: onEventTransition
    }
}
export const SitePermitTypesStatus = (onSitePermitTypesStatus = null) => {

    return {

        type: SITE_PERMIT_TYPE_STATUS,

        onSitePermitTypesStatus: onSitePermitTypesStatus

    }

}

 

export const onTirggerAfterSave = (onTirggerAfterSave = null) => {

    return {

        type: TRIGGER_AFTER_SAVE,

        onTirggerAfterSave: onTirggerAfterSave

    }

}
export const onEventshowError = (onEventshowError = null) => {
    return {
        type: ONEVENT_SHOW_ERRORS,
        onEventshowError: onEventshowError
    }
}

export const onTirggeronloadSave = (onTriggerSave = null) => {
    return {
        type: TRIGGER_ONLOAD_SAVE,
        onTriggerSave: onTriggerSave
    }
}

export const closeTour = (isTourOpen) => {
    return {
        type: CLOSE_TOUR,
        isTourOpen: isTourOpen,
    }
}

export const unsavedData = (unsaveddata, initialdata) => {
    return {
        type: UNSAVED_DATA,
        unsaveddata: { ...unsaveddata },
        initialdata: { ...initialdata }
    }
}


export function ShowPopUp(control, force = false) {
    return { type: SHOW_POPUP, control, force };
}

export function HidePopUp() {
    return { type: HIDE_POPUP };
}

export const showAlert = (alerttype, alertmessage) => {
    return {
        type: SHOW_ALERT,
        alerttype: alerttype,
        alertmessage: alertmessage
    }
}

export const hideAlert = (alert) => {
    return {
        type: HIDE_ALERT
    }
}

export const setNotify = (notifications) => {
    return {
        type: NOTIFICATIONS,
        notifications
    }
}

export const DrawerState = (drawertype, drawerstate) => {
    return {
        type: drawertype,
        drawermessage: drawerstate
    }
}

export const snackbarMessage = (snackmessage, snackbarmessageType) => {
    return {
        type: SNACKBAR_MESSAGE,
        snackmessage: snackmessage,
        snackbarmessageType: snackbarmessageType
    }
}

export const Confirmation = (onyes, onno, description, value) => {
    return {
        type: CONFIRMATION,
        onyes: onyes,
        onno: onno,
        description: description,
        value: value
    }
}
export const unsavedResult = (unsavedresults) => {
    return {
        type: UNSAVED_RESULTS,
        unsavedresults
    }
}

export const setSectionsReload = (workflowreload) => {
    return {
        type: WORKFLOW_RELOAD,
        workflowreload
    }
}


export const setResetValue = (isreset) => {
    return {
        type: RESET_VALUES,
        isreset
    }
}



export const compmapdata = () => {
    return {
        type: PASS_COMPMAP
    }
}
export const getProjMessagesfromService = (projmessages) => {
    return {
        type: PROJMESSAGES,
        projmessages
    }
}

export const setBusinessRulesOnEvent = (effects = []) => {
    return {
        type: BUSINESS_RULES_ONEVENT,
        effects
    }
}

export const setRecordData = (recordidinfo, recorddata) => {
    return {
        type: RECORD_DATA,
        recordidinfo: recordidinfo,
        recorddata: { ...recorddata }
    }
}

export const setNotifications = (data) => {
    return (dispatch) => {
        //  dispatch(setNotify(data))
        let url = getEnvVariable('Report') + `/api/ReportQuery/DashboardNotifications`
        if (getEnvVariable('ModuleKey') === 'AMES')
            url = getEnvVariable('Report') + `/api/ReportQuery/DashboardNotificationsCount`
        const body = { "IsRead": 'N', "Dismissed": 'N' }
        PostData(url, body, true).then(response => {
            if (getEnvVariable('ModuleKey') === 'AMES') {
                if (response && response.totalCount) {
                    dispatch(setNotify(response.totalCount))
                }
            }
            else {
                if (response && response.value && response.value.total) {
                    dispatch(setNotify(response.value.total))
                }
            }
        }).catch(ex => {
            console.log(ex)
        })

    }
}

export const getProjectMessages = () => {
    return (dispatch) => {
        let url = getEnvVariable('Application') + '/api/ProjectMessages/All';
        const init = {
            method: 'GET', ...GetFetchProps()
        };
        fetch(`${url}`, init).then(response => response.json()).then(response => {
            if (response) {
                dispatch(getProjMessagesfromService(response))
            }
        }).catch(ex => {
            console.log(ex)
        })
    }
}

export const passtabData = (passtabdata, initialdata) => {
    return {
        type: PASSTAB_DATA,
        passtabdata: { ...passtabdata },
        initialdata: { ...initialdata }
    }
}

