import React, { Component } from 'react';

export default  class Cron extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (<div>
            
                yearly
        </div>)
    }
}
