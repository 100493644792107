import { getEnvVariable } from '../../../modules/environmentalList';
import DefaultSettings from '../../controls/components/settings';
import { SetApplicationFieldsData } from '../map/eps/multipleLocationServices';
import { requestApi } from './utilities';
const _ = require('lodash');


export const handlecustommultiplelocation = async (gisdistrictid, sitepermittypeid) => {
    let configtypedomains = {
        "get": {
            url: getEnvVariable('Admin') + `/api/Districts/` + getEnvVariable('ModuleKey') + `/All/Active`,
        }
    }
    let configtypedropdown = {
        url: getEnvVariable('AdminConfig') + `/api/SitePermitTypesConfig/${sitepermittypeid}/All`
    }
    let isvalid = null;
    await requestApi({ get: configtypedropdown }, '').then(res => {
        let selectedconfigtypes = res ? _.map(res, 'DistrictId') : []
        isvalid = selectedconfigtypes
    })
    return isvalid;
}

export const handlecustommultiplelocationreq = async (isvalid, gisdistrictid) => {
    let configtypedomains = {
        "get": {
            url: getEnvVariable('Admin') + `/api/Districts/` + getEnvVariable('ModuleKey') + `/All/Active`,
        }
    }
    let validres = null;
    await requestApi(configtypedomains, '').then(res => {
        let savedconfigitems = isvalid
        let domainres = res.map(x => savedconfigitems.includes(Number(x.Id)) ? x : false);
        var availabledistricts = domainres.filter(Boolean);
        validres = availabledistricts.filter(a => a.ArcGISBoundaryId == gisdistrictid);
        return validres
    })
    return validres;
}

export const handlePrimaryCheck = async (e, props, routes, checked) => {

    let isvalid = await handlecustommultiplelocation(props.dataItem.District, routes.routeid)
    let validation = await handlecustommultiplelocationreq(isvalid, props.dataItem.District)
    if (checked && validation.length > 0) {
        if (getEnvVariable('ModuleKey') == "KEPT")
            if (DefaultSettings.getAlert() != null && alert)
                DefaultSettings.getAlert().show(`Please wait Primary location is changing`, 'info');
        let refid = routes.routeid1, permittypeid = routes.routeid;
        //callback function ()
        SetApplicationFieldsData(permittypeid, refid, props.dataItem, validation).then(ref => { })
    }
    else if (checked == false) {
        if (DefaultSettings.getAlert() != null && alert)
            DefaultSettings.getAlert().show(`At least one primary should be checked to update primary location`, 'info');
    }
    else {
        if (DefaultSettings.getAlert() != null && alert)
            DefaultSettings.getAlert().show(`Selected Primary location is out of boundaries`, 'error')
    }
}