import React, { Fragment, Component } from "react";
import { TimePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DefaultSettings from "../../controls/components/settings";
import Timepicker from "../../controls/components/timepicker";

class BasicDateTimePicker extends Component {

    handleChange = (e) => {
        //const { handleFieldChange} = this.props
         //const { handleFieldChange, value, name, id, edit, options, ...otherProps } = this.props
        // let convertMoment = e ? moment(e).format('MM/DD/YYYY hh:mm a') : null
        // let events = { target: { value: convertMoment, name: name, checked: false } }
        this.props.handleFieldChange(e);
    }

    render() {
        const { name, options, tooltip, value, edit,required,error,helperText,contexthelp } = this.props
        const { label, disabled } = options;
         if (edit === false) {
            return <Fragment>
                {(tooltip || contexthelp) && <div > <Typography variant={'caption'} className="text-secondary">{label}&nbsp;
          < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                        <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} onClick={() => DefaultSettings.showDrawer(contexthelp)}/>
                    </Tooltip>  </Typography>   <Typography variant={'body2'} color={'textPrimary'} className="fw-bold">{value && moment(value).format(' HH:mm a') || ''}   </Typography>
                </div>}
                {!(tooltip || contexthelp) && <div > <Typography variant={'caption'} className="text-secondary">{label}&nbsp; </Typography>   <Typography variant={'body2'} color={'textPrimary'} className="fw-bold">{value && moment(value).format(' HH:mm a') || ''}   </Typography>
                </div>}
            </Fragment >
        }
        else return (
            <Fragment>
                <Timepicker
                    variant="filled"
                    disabled={disabled || false}
                    ampm={true}
                    label={label}
                    name={name}
                    error={error}
                    value={value}
                    required={required}
                    contexthelp ={contexthelp}
                    helperText={helperText}
                    handleFieldValue={this.handleChange}
                />
                {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                        disabled={disabled || false}
                        variant="filled"
                        ampm={true}
                        value={val}
                        handleFieldValue={this.handleChange}
                        label={tlabel}
                        name={name}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </MuiPickersUtilsProvider>*/}
            </Fragment>
        );
    }
}

export default BasicDateTimePicker;