/**
      *********************************************************
      Description: Contains different validations
      Required props:  text or value.
      limitations    : N/A
      *********************************************************
**/
export const isRequired = (value, required, message) => {
    if (required === 'Y') {
        if ((value == null) || (value === '')) return message
        return null
    }
    else return null

}

export const minLength = (text, length, message) => {
    if (!text || (text.length < length))
        return message
    return null
}

export const maxLength = (text, length, message) => {
    if (text && (text.length > length))
        return message
    return null
}

export const isPercentage = (value, percent, message) => {
    if (value >= 0 && value < 1) return null
    else return message
}

export const isRange = (value, range, message, min, max) => {
    value = Number(value)
    if (value >= min && value <= max) return null
    else return message
}


export const isNumber = (value, num, message) => {
    //console.log('parseFloat(value)',parseFloat(value))
    const exp = isNaN(Number(value))
    if (exp)
        return message
    return null;
}

export const isString = (value, str, message) => {
    const letters = /^[A-Za-z]+$/;
    if (value.match(letters)) {
        return null;
    }
    else return message
}

export const isInteger = (value, int, message) => {
    const val = Number.isInteger(value)
    if (val) return null
    else return message
}

export const regExp = (value, exp, message) => {
    if (message.includes("Email")) {
        let emailexp = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,2})(\]?)$/;
        if (value != null && value != "" && !emailexp.test(value)) {
            return message
        }
        return null
    }
    else {
        let newreg = new RegExp(exp)
        if (value != null && value != "" && !newreg.test(value))
            return message
        return null
    }
}


export const zipCode = (value, zip, message) => {
    let exp = /(^\d{5}$)|(^\d{5}-\d{4}$)/
    if (value != null && value != "" && !exp.test(value))
        return message
    return null
}

export const zipCode4 = (value, zip, message) => {
    let exp = /(^\d{4}$)|(^\d{4}-\d{3}$)/
    if (value != null && value != "" && !exp.test(value))
        return message
    return null
}


export const emailAddress = (text, email, message) => {
    let exp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (text != null && text != "" && !exp.test(text)) {
        return message
    }
    return null
}


export const ipAddress = (ipaddress, addr, message) => {
    let exp = (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress))
    if (!exp)
        return message
    return null;
}
export const urlData = (urlValue, url, message) => {
    let exp = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
    if (!exp.test(urlValue))
        return message
    return null;
}
export const phoneMask = (value, phone, message) => {
    let exp = ('(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/);
    if (!exp.test(value))
        return message
    return null;
}

export const stringLength = (value, range, message, min, max) => {
    if (value !== null) {
        if (typeof value === 'number') {
            if (value >= min && value <= max)
                return null
        }
        else if (value.length >= min && value.length <= max) return null
        return message
    }
}