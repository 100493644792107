import React from "react"
import SidebarBR from '../../../custommodules/sidebarbr'


const SidebarBusinessRules = () => {

    return (
        <div style={{ height: '100%', overflow: 'auto' }}>
            <SidebarBR />
        </div>
    )
}
export default SidebarBusinessRules