import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { getEnvVariable } from '../environmentalList';
import { AdminList } from '../../json/administration/adminlist';
import { NoAdminList } from '../../json/administration/noadminlist';
import { ExternalList } from '../../json/administration/externallist';
import { NotAdminRegList } from '../../json/administration/notadminreglist';
import { NotRegList } from '../../json/administration/noregistrationlist';
import { SuperAdminList } from '../../json/administration/superadminlist';
import { getSignInToken } from '../../core/modules/header';
import { PreparerExternalList } from '../../json/administration/preparerexternallist';
import { SystemAdminList } from '../../json/administration/Systemadminlist';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
 
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}
 
export default function PaperSheet(props) {
    let permissions = props.accessPermissions;
    const [displaySettingsTabList, setDisplaySettingsTabList] = React.useState(!(props.AccessPortal === 'E' || (props.AccessPortal === 'I' &&
        permissions && permissions.isSuperAdmin === "N") || (props.AccessPortal === 'I' && permissions && permissions.isSystemAdmin === "N")))
    let printlist =
        (props.AccessPortal == 'I' ?
            (permissions.isSystemAdmin === 'Y' ? SystemAdminList[getEnvVariable('ModuleKey')] : permissions.isAdmin === 'Y' &&
                permissions.isSuperAdmin === 'Y' ? SuperAdminList[getEnvVariable('ModuleKey')] : permissions.isAdmin === 'Y' &&
                    permissions.isRequestAdmin === 'Y' ? AdminList[getEnvVariable('ModuleKey')] : permissions.isAdmin === 'Y' ?
                NotRegList[getEnvVariable('ModuleKey')] : permissions.isRequestAdmin === 'Y' ? NotAdminRegList[getEnvVariable('ModuleKey')] :
                    NoAdminList[getEnvVariable('ModuleKey')]) : permissions && permissions.roles && permissions.roles.includes("Official") ? ExternalList[getEnvVariable('ModuleKey')] :
                PreparerExternalList[getEnvVariable('ModuleKey')]);
 
    if (printlist && printlist.epsgeneral) {
        ((printlist && printlist.epsgeneral) || []).map((e, index) => {
            if (e.title === 'Records') {
                let submodule = JSON.parse(localStorage.getItem('submodule'))
                if (submodule)
                    printlist.epsgeneral.splice(index, 1, ...submodule);
            }
        })
    }
 
    if (!getSignInToken()) {
        window.location.reload(true)
    }
 
    const [value, setValue] = React.useState((
        localStorage.getItem('tab') === 'D' ? 0 : parseInt(localStorage.getItem('tab'))) || 0);
 
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }; //eps-img
    return (
        <div className="pt-2 ames-tab-dashboard w-99">
            <Tabs
                value={value}
                className='col-12'
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon label tabs example"
            >
                <Tab label='Dashboard' />
                {props.AccessPortal !== 'E' && permissions.isAdmin === 'Y' && <Tab label="Administration" />}
                {props.AccessPortal !== 'E' && displaySettingsTabList && <Tab label={getEnvVariable('ModuleKey')+` Settings`} />}
                {props.AccessPortal !== 'E' && displaySettingsTabList && <Tab label={`Site Settings`} />}
            </Tabs>
            <TabPanel className=" col-sm-12 p-1" value={value} index={0}>
                <div className="row ames-tabpanel">
                    {((printlist && printlist.epsgeneral) || []).map(e => {
                        return <div className=" col-lg-4 col-md-5 col-sm-4 col-xs-12 p-1">
                            <a href={e.title=='Reports' ? e.link:'#' + e.link} > <Paper href={e.link} elevation={4} className="p-1" style={{ backgroundColor: '#212121' }}>
                                <Typography variant="h1" component="h1" className='text-center'>
                                    <Icon style={e.style || { color: e.color }} className=' dashboard-icons'> {e.materialicon}</Icon>
                                </Typography>
                                <Typography component="h5" variant="title" className='text-center' style={{ color: '#ffffff' }}>
                                    {e.title}
                                </Typography>
                            </Paper>
                            </a>
                        </div>
                    })}
                </div>
            </TabPanel>
            <TabPanel className=" col-sm-12 p-1" value={value} index={1}>
                <div className="row">
                    {(printlist && printlist.Adminlist || []).map(e => {
                        return <div className="  col-lg-4 col-md-5 col-sm-4 col-xs-12 p-1">
                            <a href={e.link}> <Paper href={e.link} elevation={4} className="p-1" style={{ backgroundColor: '#212121' }}>
                                <Typography variant="h1" component="h1" className='text-center'>
                                    <Icon style={e.style} className=' dashboard-icons'> {e.materialicon}</Icon>
                                </Typography>
                                <Typography component="h5" variant="title" className='text-center' style={{ color: '#ffffff' }}>
                                    {e.title}
                                </Typography>
                            </Paper>
                            </a>
                        </div>
                    })}
                </div>
            </TabPanel>
            <TabPanel className=" col-sm-12 p-1" value={value} index={2}>
                <div className="row">
                    {(printlist && printlist.settings || []).map(e => {
                        return <div className=" col-lg-4 col-md-5 col-sm-4 col-xs-12 p-1">
                            <a href={e.link}> <Paper href={e.link} elevation={4} className="p-1" style={{ backgroundColor: '#212121' }}>
                                <Typography variant="h1" component="h1" className='text-center'>
                                    <Icon style={e.style} className=' dashboard-icons'> {e.materialicon}</Icon>
                                </Typography>
                                <Typography component="h5" variant="title" className='text-center' style={{ color: '#ffffff' }}>
                                    {e.title}
                                </Typography>
                            </Paper>
                            </a>
                        </div>
                    })}
                </div>
            </TabPanel>
            <TabPanel className=" col-sm-12 p-1" value={value} index={3}>
                <div className="row">
                    {(printlist && printlist.sitesettings || []).map(e => {
                        return <div className="col-lg-4 col-md-5 col-sm-4 col-xs-12 p-1">
                            <a href={e.link}> <Paper href={e.link} elevation={4} className="p-1" style={{ backgroundColor: '#212121' }}>
                                <Typography variant="h1" component="h1" className='text-center'>
                                    <Icon style={e.style} className=' dashboard-icons'> {e.materialicon}</Icon>
                                </Typography>
                                <Typography component="h5" variant="title" className='text-center' style={{ color: '#ffffff' }}>
                                    {e.title}
                                </Typography>
                            </Paper>
                            </a>
                        </div>
                    })}
                </div>
            </TabPanel>
        </div>
    );
}