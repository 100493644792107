import * as React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="eps-content-center">
          <div className="text-center ">
            <Icon className="text-center " color="primary" style={{ fontSize: '9rem' }}>live_help</Icon></div>
          <h1 className="text-center"> Contact Support Team </h1>
          <div className="text-center p-2">
            <Button variant="contained" size="small" color="primary" target="_self" onClick={() => { window.location.replace('#'); window.location.reload() }}>Home</Button>
          </div>
        </div>
      );
    }
    // Render children if there's no error
    return this.props.children;
  }
}

export default ErrorHandler;   