export const PreparerExternalList = {

    WRS: {
        "epsgeneral":
            [
                {
                    "type": "menu",
                    "title": "Notifications",
                    "icon": "notifications_active",
                    "materialicon": "notifications_active",
                    "link": "#/notifications",
                    "key": "Notifications",
                    "style": {
                        'color': '#d50000'
                    }
                },

                {
                    "type": "menu",
                    "title": "Records",
                    "key": "newapplicationdetails",
                    "link": "#/Records/RECYCLERS",
                    "icon": "fa fa-file-text",
                    "materialicon": "new_releases",
                    "style": {
                        'color': '#9c27b0'
                    }
                },
                {
                    "type": "menu",
                    "title": "Contacts",
                    "key": "contacts",
                    "link": "#/Administration/externalcontacts",
                    "icon": "fa fa-user-circle-o",
                    "materialicon": "contacts",
                    "style": {
                        'color': '#006064'
                    }
                }
            ],
    },
}


