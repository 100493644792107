import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { loadModules } from 'esri-loader';
class CLocate extends Component {
    constructor(props) {
        super(props); 
        console.log("Locate:: ",this.props);
        this.state =  { 
            view: this.props.view
        } 
        this.startup(
            this.props
        ); 
    }   
     
    startup = (config) => {
        loadModules([
            "esri/widgets/Locate"
        ]).then(([Locate]) => {
            this.locatecontainer = ReactDOM.findDOMNode(this);
            const locate = new Locate({ 
                view: config.view,
                container: this.locatecontainer
            }); 
            config.view.ui.add(locate, "top-left");
            this.props.widgetRenderCallBack("Locate")
        });
    } 

  render() {
    return (
        <div className="locate-box" style={{order:this.props.order}}>
          
        </div>
    );
  }
}

export default CLocate;