//import HolidayAdd from "./newholidays"

import { getEnvVariable } from "../../../modules/environmentalList"

//import notehistory from './notehistorygrid';
const EditAuthorization = {
    "type": "popup",
    "key": "EditAuthorization",
    "name": "EditAuthorization",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'editOpen'
    },
    sharedValues: ['open'],
    container: {
        closepopup: true,
        closepopupval: 'authorizations',
        "addRoute": true,
        "routeParams": { replace: 'authorizations', param: 'Id', routeName: 'id' },
        'removelayout': 'EditAuthorization',
        values: { editInfo: null, edit: false, createduplicate: false, fullscreen: false, back: false, allgriddata: null, navigation: null },
        mapValues: {
            header: [
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'createduplicate', name: 'createduplicate' },
                { key: 'allgriddata', name: 'allgriddata' },
            ],
            ViewAuthorization: [
                { key: 'editInfo', name: 'editInfo' },
                { key: 'allgriddata', name: 'allgriddata' },
                { key: 'save', name: 'save' },
                // { key: 'saveduplicate', name: 'saveduplicate' },
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'createduplicate', name: 'createduplicate' },
                { key: 'back', name: 'back' },
                { key: 'navigation', name: 'navigation' },]
        },
        //  sharedValues: ['save', 'edit'],
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },
            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'ViewAuthorization',
                    type: 'div',
                    className: 'col-12 pl-4 m-3',
                    sub: {
                        type: 'div',
                        className: 'col-12 pl-4 m-3',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Authorizations',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "View Authorization", "materialicon": "vpn_key", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },
                    {
                        type: 'icon',
                        icon: 'edit',
                        name: 'edit',
                        hideanother: 'edit',
                        hidevalue: true,
                        title: 'Edit Authorization'
                    },
                    {
                        type: 'icon',
                        icon: 'previtem',
                        name: 'previtem',
                        hideon: 'previtem',
                        hidevalue: false,
                        title: 'Previous Item'
                    },
                    {
                        type: 'icon',
                        icon: 'nextitem',
                        name: 'nextitem',
                        hideon: 'nextitem',
                        hidevalue: false,
                        title: 'Next Item'
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hidevalue: false,
                        title: 'Full Screen'
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false,
                        title: 'Exit FullScreen'
                    },
                ],
            },
            ViewAuthorization: {
                "type": "form",
                initRun: true,
                "key": "ViewAuthorization",
                "name": "ViewAuthorization",
                aliases: {
                    'editInfo': 'editInfo',
                    'allgriddata': 'allgriddata',
                    'navigateItem': 'navigateItem',
                    'edit': 'edit'
                },
                sharedValues: ['editInfo', 'allgriddata', 'navigateItem', 'navigation', 'edit', 'save', 'createduplicate', 'exitfullscreen', 'fullscreen', 'back'],
                validationMapKeys: [],
                layout: {
                    group: [

                        {
                            order: 1,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'FirstName',
                                type: 'div',
                                className: 'col-10 m-1',
                                sub: {
                                    type: 'div',
                                    className: 'col-10 m-1'
                                }
                            },

                            ]
                        },
                        {
                            order: 2,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'LastName',
                                    type: 'div',
                                    className: 'col-6 m-1',
                                    sub: {
                                        type: 'div',
                                        className: 'col-6 m-1'
                                    }
                                },
                            ]
                        },
                        {
                            order: 3,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Email',
                                type: 'div',
                                className: 'col-10 m-1',
                                sub: {
                                    type: 'div',
                                    className: 'col-10 m-1'
                                }
                            },
                            ]
                        },
                        {
                            order: 4,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Subject',
                                type: 'div',
                                className: 'col-10 m-1',
                                sub: {
                                    type: 'div',
                                    className: 'col-10 m-1'
                                }
                            },
                            ]
                        },
                        {
                            order: 5,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Body',
                                type: 'div',
                                className: 'col-10 m-1',
                                sub: {
                                    type: 'div',
                                    className: 'col-10 m-1'
                                }
                            },
                            ]
                        },
                        {
                            order: 6,
                            type: 'div',
                            className: 'row ',
                            components: [
                                {
                                    name: 'IsApproved',
                                    type: 'div',
                                    className: 'col-2 m-1',
                                    sub: {
                                        type: 'div',
                                        className: 'col-2 m-1'
                                    }
                                },

                            ]
                        },
                        {
                            order: 7,
                            type: 'div',
                            className: 'row ',
                            components: [{
                                name: 'Approved',
                                type: 'div',
                                className: 'col- m-1',
                                sub: {
                                    type: 'div',
                                    className: 'col-2 m-1'
                                }
                            }, {
                                name: 'Denied',
                                type: 'div',
                                className: 'col-2 m-1',
                                sub: {
                                    type: 'div',
                                    className: 'col-2 m-1'
                                }
                            }, {
                                name: 'GotoUser',
                                type: 'div',
                                className: 'col-3 m-1',
                                sub: {
                                    type: 'div',
                                    className: 'col-3 m-1'
                                }
                            },
                            ]
                        },
                        {
                            order: 8,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'CreatedOn',
                                type: 'div',
                                className: 'col-6',
                                sub: {
                                    type: 'div',
                                    className: 'col-6'
                                }
                            },
                            {
                                name: 'CreatedByName',
                                type: 'div',
                                className: 'col-6',
                                sub: {
                                    type: 'div',
                                    className: 'col-6'
                                }
                            },
                            ]
                        },
                        {
                            order: 9,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'UpdatedOn',
                                    type: 'div',
                                    className: 'col-6',
                                    sub: {
                                        type: 'div',
                                        className: 'col-6'
                                    }
                                },
                                {
                                    name: 'UpdatedByName',
                                    type: 'div',
                                    className: 'col-6',
                                    sub: {
                                        type: 'div',
                                        className: 'col-6'
                                    }
                                },]
                        },

                    ]

                },
                values: {
                    FirstName: '',
                    LastName: '',
                    Email: '',
                    Subject: '',
                    Body: '',
                    GotoUser: '',
                    IsApproved: '',
                    Approved: '',
                    Denied: '',
                    UpdatedByName: '',
                    CreatedByName: '',
                    UpdatedOn: '',
                    CreatedOn: '',
                    UpdatedBy: '',
                    CreatedBy: '',
                    Id: '',
                    UserId: '',
                    userId: '',
                    editInfo: null,
                    allgriddata: null,
                    navigation: null
                },
                "components": {
                    FirstName: {
                        "type": "label",
                        "key": "FirstName",
                        "label": "First Name",
                        "name": "FirstName",
                        changefieldto: "label",
                    },
                    LastName: {
                        "type": "label",
                        "key": "LastName",
                        "label": "LastName",
                        "name": "Last Name",
                        changefieldto: "label",
                    },
                    Email: {
                        "type": "label",
                        "key": "Email",
                        "label": "Email",
                        "name": "Email",
                        changefieldto: "label",
                    },
                    Subject: {
                        "type": "label",
                        "key": "Subject",
                        "label": "Subject",
                        "name": "Subject",
                        changefieldto: "label",
                    },
                    Body: {
                        "type": "label",
                        "key": "Body",
                        "label": "Body",
                        "name": "Body",
                        changefieldto: "label",
                    },
                    GotoUser: {
                        "type": "label",
                        changefieldto: "button",
                        "key": "GotoUser",
                        size: 'small',
                        "label": "Grant Access",
                        name: "GotoUser",
                        buttontype: 'routing',
                        navurl: '/Administration/agencyusers/{UserId}',
                        optionalRoutes: 'userId',
                        useFieldVals: true,
                        extraProps: {
                            color: 'primary',
                            variant: "contained",
                        },
                },
                    Approved: {
                        "type": "label",
                        changefieldto: "button",
                        "key": "Approved",
                        size: 'small',
                        "label": "Approve",
                        name: "Approved",
                        extraProps: {
                            color: 'primary',
                            variant: "contained",
                        },
                    },
                    Denied: {
                        "type": "label",
                        changefieldto: "button",
                        "key": "Denied",
                        size: 'small',
                        "label": "Deny",
                        name: "Denied",
                        extraProps: {
                            color: 'secondary',
                            variant: "contained",
                        },
                    },
                    IsApproved: {
                        "type": "dropdown",
                        changefieldto: "dropdown",
                        "key": "IsApproved",
                        "label": "Status",
                        name: "IsApproved",
                        disabled: true,
                        extraProps: {
                            transformProps: {
                                label: 'Name',
                                value: 'Value'
                            },
                        }
                    },
                    CreatedOn: {
                        "type": "label",
                        "key": "CreatedOn",
                        "label": "Created On",
                        name: "CreatedOn",
                        changefieldto: "label",
                    },
                    CreatedByName: {
                        "type": "label",
                        "key": "CreatedByName",
                        "label": "Created By",
                        name: "CreatedByName",
                        changefieldto: "label",
                    },
                    UpdatedOn: {
                        "type": "label",
                        "key": "UpdatedOn",
                        "label": "Updated On",
                        name: "UpdatedOn",
                        changefieldto: "label",
                    },
                    UpdatedByName: {
                        "type": "label",
                        "key": "UpdatedByName",
                        "label": "Updated By",
                        name: "UpdatedByName",
                        changefieldto: "label",
                    },
                },
                mapActionsToEffects: {
                    'onLoad': {
                        'init': {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'validations',
                                            "read": [
                                                {
                                                    url: getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.IPUsersAuthorizations`,
                                                    type: 'get'
                                                }
                                            ],
                                            transformProps: {
                                                removesubfieldname: 'master.IPUsersAuthorizations_'
                                            }
                                        },
                                        {
                                            type: 'businessrules',
                                            "read": [
                                                {
                                                    url: getEnvVariable('MasterAdmin') + `/api/BusinessRules/Grid/master.IPUsersAuthorizations`,
                                                    type: 'get',
                                                }
                                            ],
                                        },
                                        {
                                            type: 'load',
                                            name: 'IsApproved',
                                            "read": [
                                                {
                                                    url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=AUTHORIZATION_STATUS`,
                                                    type: 'get',
                                                    saveAs: {
                                                        key: 'items'
                                                    }
                                                }
                                            ]
                                        },
                                        { type: "prop", name: "GotoUser", isVisible: true },
                                        { type: "prop", name: "Approved", isVisible: false },
                                        { type: "prop", name: "Denied", isVisible: false },
                                    ]
                                }
                            },
                            ]
                        }
                    },
                    'onChange': {
                        'navigation': {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'initial', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                            "read": [
                                                {
                                                    url: getEnvVariable('MasterAdmin') + `/api/IPUsersAuthorizations/GetRequest/{Id}`,
                                                    key: 'save',
                                                    "matchProps": [
                                                        {
                                                            name: "routeid1",
                                                            key: "routeid1",
                                                        },
                                                        {
                                                            name: "navigation.Id",
                                                            key: "Id",
                                                        }
                                                    ],
                                                    type: 'get',
                                                }
                                            ]
                                        },
                                    ]
                                }
                            }]
                        },
                        GLOBAL: {
                            rules: [
                                {
                                    canOverwrite: false,
                                    check: [
                                        {
                                            condition: "AND",
                                            rules: [
                                                { name: "IsApproved", type: "=", value: "A", rules: [] },
                                            ]
                                        }
                                    ],
                                    fields: ["IsApproved"],
                                    ruleOrder: 0,
                                    run: {
                                        valid: [
                                            {
                                                type: "prop", name: "IsApproved", isVisible: true, hasValue: true, value: 'A', valueType: 'D'
                                            },

                                        ],

                                    },
                                    uncheck: true
                                },
                                {
                                    canOverwrite: false,
                                    check: [
                                        {
                                            condition: "OR",
                                            rules: [
                                                { name: "IsApproved", type: "=", value: "P", rules: [] },
                                            ]
                                        }
                                    ],
                                    fields: ["IsApproved"],
                                    ruleOrder: 0,
                                    run: {
                                        valid: [
                                            {
                                                type: "prop", name: "IsApproved", isVisible: true, hasValue: true, value: 'P', valueType: 'D'
                                            },

                                        ],

                                    },
                                    uncheck: true
                                },
                                {
                                    canOverwrite: false,
                                    check: [
                                        {
                                            condition: "AND",
                                            rules: [
                                                { name: "IsApproved", type: "=", value: "D", rules: [] },
                                            ]
                                        }
                                    ],
                                    fields: ["IsApproved"],
                                    ruleOrder: 0,
                                    run: {
                                        valid: [
                                            {
                                                type: "prop", name: "IsApproved", isVisible: true, hasValue: true, value: 'D', valueType: 'D'
                                            },

                                        ],

                                    },
                                    uncheck: true
                                },
                                // {
                                //     canOverwrite: false,
                                //     check: [
                                //         {
                                //             condition: "AND",
                                //             rules: [
                                //                 { name: "userId", type: "is not null", rules: [] },
                                //             ]
                                //         }
                                //     ],
                                //     fields: ["userId"],
                                //     ruleOrder: 0,
                                //     run: {
                                //         valid: [
                                //             {
                                //                 type: "prop", name: "UserId", hasValue: true, value: '{{userId}}', valueType: 'C'
                                //             },

                                //         ],

                                //     },
                                //     uncheck: true
                                // },
                            ]
                        },
                        Approved: {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'initial', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                                "read": [
                                                    {
                                                        url: getEnvVariable('MasterAdmin') + `/api/IPUsersAuthorizations/ApproveRequest/{Id}?moduleKey=${getEnvVariable('ModuleKey')}`,
                                                        key: 'save',
                                                        "matchProps": [
                                                            {
                                                                name: "routeid1",
                                                                key: "routeid1",
                                                            },
                                                            {
                                                                name: "navigation.Id",
                                                                key: "Id",
                                                            }
                                                        ],
                                                        type: 'put',
                                                    }
                                                ]
                                            },
                                            { type: "prop", name: "GotoUser", isVisible: true },
                                            { type: "prop", name: "Approved", isVisible: false },
                                            { type: "prop", name: "Denied", isVisible: false },
                                            {
                                                type: "prop", name: "IsApproved", isVisible: true, hasValue: true, value: 'A', valueType: 'D'
                                            },
                                        ],

                                    },
                                },
                            ]
                        },
                        Denied: {
                            rules: [

                                {
                                    // canOverwrite: false,
                                    // check: [
                                    //     {
                                    //         condition: "AND",
                                    //         rules: [
                                    //             { name: "Denied", type: "=", value: "D", rules: [] },
                                    //         ]
                                    //     }
                                    // ],
                                    // fields: ["Denied"],
                                    // ruleOrder: 0,
                                    run: {
                                        valid: [
                                            {
                                                type: 'initial', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                                "read": [
                                                    {
                                                        url: getEnvVariable('MasterAdmin') + `/api/IPUsersAuthorizations/DenyRequest/{Id}`,
                                                        key: 'save',
                                                        "matchProps": [
                                                            {
                                                                name: "routeid1",
                                                                key: "routeid1",
                                                            },
                                                            {
                                                                name: "navigation.Id",
                                                                key: "Id",
                                                            }
                                                        ],
                                                        type: 'put',
                                                    }
                                                ]
                                            },
                                            { type: "prop", name: "GotoUser", isVisible: false },
                                            { type: "prop", name: "Approved", isVisible: true },
                                            { type: "prop", name: "Denied", isVisible: true, disabled: true },
                                            {
                                                type: "prop", name: "IsApproved", hasValue: true, value: 'D', valueType: 'D'
                                            },
                                        ],

                                    },
                                },
                            ]
                        },
                        'edit': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'edit',

                                            },

                                        ]
                                    }
                                },
                                {
                                    canOverwrite: false,
                                    check: [
                                        {
                                            condition: "AND",
                                            rules: [
                                                { name: "IsApproved", type: "=", value: "A", rules: [] },
                                            ]
                                        }
                                    ],
                                    fields: ["IsApproved"],
                                    ruleOrder: 0,
                                    run: {
                                        valid: [
                                            { type: "prop", name: "GotoUser", isVisible: true },
                                            { type: "prop", name: "Approved", isVisible: false },
                                            { type: "prop", name: "Denied", isVisible: false },
                                        ],

                                    },
                                    uncheck: true
                                },
                                {
                                    canOverwrite: false,
                                    check: [
                                        {
                                            condition: "OR",
                                            rules: [
                                                { name: "IsApproved", type: "=", value: "P", rules: [] },
                                                { name: "IsApproved", type: "=", value: "D", rules: [] },
                                            ]
                                        }
                                    ],
                                    fields: ["IsApproved"],
                                    ruleOrder: 0,
                                    run: {
                                        valid: [
                                            { type: "prop", name: "GotoUser", isVisible: false },

                                        ],

                                    },
                                    uncheck: true
                                },
                                {
                                    canOverwrite: false,
                                    check: [
                                        {
                                            condition: "AND",
                                            rules: [
                                                { name: "IsApproved", type: "=", value: "D", rules: [] },
                                            ]
                                        }
                                    ],
                                    fields: ["IsApproved"],
                                    ruleOrder: 0,
                                    run: {
                                        valid: [
                                            { type: "prop", name: "GotoUser", isVisible: false },
                                            { type: "prop", name: "Approved", isVisible: true },
                                            { type: "prop", name: "Denied", isVisible: true, disabled: true },

                                        ],

                                    },
                                    uncheck: true
                                },
                                {
                                    canOverwrite: false,
                                    check: [
                                        {
                                            condition: "AND",
                                            rules: [
                                                { name: "IsApproved", type: "=", value: "P", rules: [] },
                                            ]
                                        }
                                    ],
                                    fields: ["IsApproved"],
                                    ruleOrder: 0,
                                    run: {
                                        valid: [
                                            { type: "prop", name: "Approved", isVisible: true },
                                            { type: "prop", name: "Denied", isVisible: true },
                                        ],

                                    },
                                    uncheck: true
                                },
                            ]
                        },
                        'exitfullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'exitfullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'fullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'fullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },

                    },
                }
            }
        },

    }
}

export default EditAuthorization
