import React from 'react';
import Editor from '../editor';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiAccordionSummary-root": {
            maxHeight: "20px",
            minHeight: "20px"
        },
    }
}));

const Rule = ({
    id,
    parentId,
    field,
    operator,
    value,
    translations,
    schema: {
        classNames,
        controls,
        fields,
        onPropChange,
        onRuleRemove,
        getLevel,
    }
}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    let tempVal = value
    const onElementChanged = (property, value) => {
        onPropChange(property, value, id);
    };

    const onValueChanged = (value) => {
        tempVal = value
        onElementChanged('field', 'CalculatedExpression');
        onElementChanged('operator', '#CE#');
        onElementChanged('value', value);
    };

    const removeRule = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onRuleRemove(id, parentId);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? id : false);
    };

    const level = getLevel(id);
    return (
        <div className={`rule ${classNames.rule}`} data-rule-id={id} data-level={level}>
            <div className="row">
                <label className="col-sm-3">Calculated Expression</label>
                <div className="col-sm-8">
                    <Accordion className={classes.root} expanded={expanded === id} onChange={handleChange(id)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        > <small>{tempVal}</small>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="m-4">
                                <Editor items={[7, 8, 9, '+', '>', 4, 5, 6, '-', '<', 1, 2, 3, '/', '=', 0, '.', '[', ']', '*', '%', 'del', 'Insert']}
                                    rows={6} options={fields} //.filter(fld => fld.type === 'number')
                                    Value={tempVal}
                                    changeValue={(value) => { onValueChanged(value) }} />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="col-sm-1">
                    <controls.removeRuleAction
                        label={translations.removeRule.label}
                        title={translations.removeRule.title}
                        className={`rule-remove ${classNames.removeRule}`}
                        handleOnClick={removeRule}
                        level={level}
                    />
                </div>
            </div>
        </div >
    );
};

Rule.defaultProps = {
    id: null,
    parentId: null,
    field: null,
    operator: null,
    value: null,
    schema: null
};

Rule.displayName = 'Rule';

export default Rule;
