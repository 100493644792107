import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteIcon from '@material-ui/icons/Delete';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ShareIcon from '@material-ui/icons/Share';
// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    primary: `item ${k}`,
    secondary: k % 2 === 0 ? `Whatever for ${k}` : undefined
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
});

const getListStyle = isDraggingOver => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

class DndList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.data
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );
    
    this.props.handleReorder(items);
     this.setState({
      items
    }); 
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef}>
              <List style={getListStyle(snapshot.isDraggingOver)}>
                {this.state.items.map((item, index) => (
                  <Draggable key={item.OBJECTID} draggableId={item.OBJECTID} index={index}>
                    {(provided, snapshot) => (
                      <ListItem button onClick={(evt)=> {this.props.handleShow(evt, item)}} 
                        ContainerComponent="li"
                        ContainerProps={{ ref: provided.innerRef }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <ListItemIcon>
                        <BookmarkBorderIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={item.NAME}
                        />
                        <ListItemSecondaryAction>
                            {/* { this.props.username == item.USERNAME && 
                              <IconButton edge="end" 
                                  style={item.SHARE == 'Y'? { color: '#00f708' }:{}}
                                  variant="outlined"
                                  onClick={(evt)=> {this.props.handleShare(evt, item)}}>
                                <ShareIcon />
                              </IconButton>
                            } */}
                            { this.props.username == item.USERNAME && 
                              <IconButton edge="end" aria-label="delete" onClick={(evt)=> {this.props.handleTrash(evt, item)}}>
                                <DeleteIcon />
                              </IconButton>
                            }
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DndList;