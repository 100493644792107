import { Fragment } from "react"
import Container from '../../../core/controls/components/container'
import MasterTemplate from '../../jsons/mastertemplate'


const MasterTemplateContainer = (props) => {

    return (
        <Fragment>
            <Container {...MasterTemplate}  {...props} />
        </Fragment>
    )
}
export default MasterTemplateContainer