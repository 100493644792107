/**
      *********************************************************
      Description: Checkbox component. Value received is converted using transformProps to true or false.
      Required props:  type,value.
      limitations    : N/A.
      *********************************************************
**/

import React, { Component, Fragment, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ConfirmationDialog from "../../../core/controls/components/confirmationPopup";
import DefaultSettings from '../../controls/components/settings';

const CFormCheckbox = (props) => {
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [currenteventvalue, setCurrenteventvalue] = useState(null)
    const { handleFieldChange, value, edit, name, disablecheckbox, color, tooltip, label, labelPlacement, contexthelp, required, helperText,
        transformProps, onlyLabel, disabled, hidecond, hideval } = props
    let disabledstatus = disabled;

    const handleFieldChangeValue = (e) => {
        let event = { target: {} }
        event.target.name = e.target.name
        event.target.value = e.target.value
        event.target.checked = e.target.checked
        event.target.type = e.target.type
        setCurrenteventvalue(event);
        setConfirmOpen(true);
    }
    const handleSaveConfirmation = () => {
        handleFieldChange(currenteventvalue);
        setCurrenteventvalue(null);
        setConfirmOpen(false);
    }

    const handleClose = () => {
        setCurrenteventvalue(null);
        setConfirmOpen(false);
    }

    if (hidecond && hideval && hidecond == hideval) {
        return null
    }
    else if (edit === false || onlyLabel === true) {
        return <div>
            {(tooltip || contexthelp) && <Fragment>
                <Typography variant={'caption'} className="fw-bold">{label}  &nbsp; < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                    <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} onClick={() => DefaultSettings.showDrawer(contexthelp)} />
                </Tooltip></Typography><Typography className="text-secondary" variant={'body2'} color={'textPrimary'}>{value ? (transformProps ?
                    String((transformProps[value] === 'Y' || transformProps[value] === true) ? 'Yes' : 'No') : String(value)) : 'No'}         </Typography>
            </Fragment>}
            {!(tooltip || contexthelp) && <Fragment>
                <Typography variant={'caption'} className="fw-bold">{label} </Typography><Typography className="text-secondary" variant={'body2'} color={'textPrimary'}>{value ? (transformProps ?
                    String((transformProps[value] === 'Y' || transformProps[value] === true) ? 'Yes' : 'No') : String(value)) : 'No'}           </Typography>
            </Fragment>}
        </div>
    }
    else {
        let tlabel = <div style={{ fontWeight: 'bold', color: props.errors ? 'red' : disabled ? disabled : 'black' ,fontSize:'0.8rem'}} shrink> {label}&nbsp; {tooltip && <Tooltip title={tooltip ? tooltip : null}
            placement="bottom-start" ><HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} onClick={() => DefaultSettings.showDrawer(contexthelp)} />
        </Tooltip>} </div>
        return <Fragment>
            <FormControl required={props.required} disabled={disabledstatus} error={props.errors || props.error || false}>
                <div className="row pl-3">
                    {/* {props.required && <FormLabel ></FormLabel>} */}
                    <FormGroup >
                        <FormControlLabel className="text-secondary"
                            control={<Checkbox
                                checked={value ? (transformProps ? transformProps[value] : value) : false}
                                onChange={(e) => props.options && props.options.hasconfirmation ? handleFieldChangeValue(e) : handleFieldChange(e)}
                                color={disabled ? "#808080":(color || "primary")}
                              // color={"red"}
                                name={name}
                                disabled={disablecheckbox}
                            />}
                            label={tlabel}
                            labelPlacement={labelPlacement || 'end'}
                        />
                    </FormGroup >
                </div>
                {props.options && props.options.helperText && <FormHelperText>{props.options.helperText}</FormHelperText>}
                {props.helperText && <FormHelperText error={props.error}>{props.helperText}</FormHelperText>}
                {props.options && props.options.hasconfirmation && confirmOpen && <ConfirmationDialog value={confirmOpen} description={props.options.description} title={props.options.confirmtitle} onSubmit={() => handleSaveConfirmation()} onClose={() => handleClose()} />}
            </FormControl>
        </Fragment>
    }
}

export default CFormCheckbox;

