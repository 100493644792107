import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setSectionsReload, snackbarMessage } from '../../core/actions';
import ConfirmationDialog from "../../core/controls/components/confirmationPopup";
import Switch from '../../core/controls/components/switch';
import FormHeader from '../../core/modules/form/cformheader';
import { GetData, PutData } from '../../core/modules/form/formsapi';
import { requestApi } from '../../core/modules/form/utilities';
import { getEnvVariable } from '../environmentalList';
const _ = require('lodash');


class CWorkflow extends Component {
    constructor(props) {
        super(props);
        this.isAdmin = this.props.options.params.isAdmin
        this.state = {
            options: this.props.options,
            SiteId: this.isAdmin && this.props && this.props.dataItem && this.props.dataItem['SiteId'] ? this.props.dataItem['SiteId'] : (this.props && this.props.extraProps && this.props.extraProps.SiteId ? this.props.extraProps.SiteId : null),
            RegionId: this.isAdmin && this.props && this.props.dataItem && this.props.dataItem['RegionId'] ? this.props.dataItem['RegionId'] : (this.props && this.props.extraProps && this.props.extraProps.RegionId ? this.props.extraProps.RegionId : null),
            DistrictId: this.isAdmin && this.props && this.props.dataItem && this.props.dataItem['DistrictId'] ? this.props.dataItem['DistrictId'] : (this.props && this.props.extraProps && this.props.extraProps.DistrictId ? this.props.extraProps.DistrictId : null),
            workflowName: this.props && this.props.dataItem && this.props.dataItem.workflowinfo ? this.props.dataItem.workflowinfo.WorkflowName : null,
            workflowDescription: this.props && this.props.dataItem && this.props.dataItem.workflowinfo ? this.props.dataItem.workflowinfo.WorkflowDescription : null,
            workflownameerror: false,
            IsDefault: this.props && this.props.dataItem && this.props.dataItem.IsDefault && this.props.dataItem.workflowinfo && this.props.dataItem.workflowinfo.IsDefault ? this.props.dataItem.workflowinfo.IsDefault : 'N',
            workflownamehelpertext: null,
            addworkflowid: null,
            config: this.props && this.props.dataItem && this.props.dataItem.workflowinfo ? this.props.dataItem.workflowinfo.ConfigIds : null,
            filteredconfigItems: [],
            //configstring: this.props && this.props.dataItem && this.props.dataItem.workflowinfo ? this.props.dataItem.workflowinfo.ConfigIds : "",
            ParentWorkflowIdValue: this.props && this.props.dataItem && this.props.dataItem.workflowinfo ? this.props.dataItem.workflowinfo.WorkflowId : 0,
            confirmationopen: false,
            currentconfigId: null
            //...this.props.options.params
        }
        this.configType = this.isAdmin ? this.props.accessPermissions.configType : this.props.extraProps.configType;
        this.configtypeidval = this.configType === 'R' ? 'RegionId' : this.configType === 'S' ? 'SiteId' : this.configType === 'D' ? 'DistrictId' : 'DistrictId';
        this.configtypename = this.configType === 'R' ? 'Regions' : this.configType === 'S' ? 'Sites' : this.configType === 'D' ? 'Districts' : 'Districts';
        this.configId = this.configType === 'R' ? this.state.RegionId : (this.configType === 'S' ? this.state.SiteId : (this.configType === 'D' ? this.state.DistrictId : '-1'));
        this.RoleRegionsurl = `/InternalAccessControlView/GetUserRoleConfigType/${this.configId}/` + getEnvVariable('ModuleKey') + `/${this.configType}/?isAdmin=${this.isAdmin}`;
        console.log("CWorkflow", this.state)
    }

    paramvalue = (getvaluefromtype, paramname, value) => {
        switch (getvaluefromtype) {
            case 'route':
                return this.props.match.params[paramname]
            case 'value':
            case 'props':
            default: return value
        }
    }

    componentDidMount() {
        //Load the the ID's based on the admin/application flow
        //var isAdmin = false;
        const { options, extraProps } = this.props
        const { params } = options

        if (this.props && this.props.dataItem && this.props.dataItem.Id && (!this.state.SiteId || !this.state.RegionId || !this.state.DistrictId)) {
            let inforeadurl = {
                "url": getEnvVariable('AdminConfig') + `/api/SitePermitTypes/` + `${this.props.dataItem.Id}`
            }
            requestApi({ get: inforeadurl }, '').then(res => {
                this.configId = this.configType === 'R' ? res.RegionId : (this.configType === 'S' ? res.SiteId : (this.configType === 'D' ? res.DistrictId : '-1'));
                this.RoleRegionsurl = `/InternalAccessControlView/GetUserRoleConfigType/${this.configId}/` + getEnvVariable('ModuleKey') + `/${this.configType}/?isAdmin=${this.isAdmin}`;
                this.setState({ SiteId: res.SiteId ? res.SiteId : null, RegionId: res.RegionId ? res.RegionId : null, DistrictId: res.DistrictId ? res.DistrictId : null })
            })
        }

        let tempparams = {}
        let configtypedomains = {
            "get": {
                url: getEnvVariable('Admin') + `/api/${this.configtypename}/` + getEnvVariable('ModuleKey') + `/All/Active`,
                //url: getEnvVariable('Admin') + `/api/Districts/` + getEnvVariable('ModuleKey') + `/All/Active`,
            }
        }
        let configtypedropdown = {
            url: getEnvVariable('AdminConfig') + `/api/SitePermitTypesConfig/${this.props.dataItem.Id}/All`,
        }
        requestApi({ get: configtypedropdown }, '').then(res => {
            let selectedconfigtypes = res ? _.map(res, this.configtypeidval) : []
            this.setState({ configItems: selectedconfigtypes });
            requestApi(configtypedomains, '').then(res => {
                let configarray = [];
                let savedconfigitems = this.state.configItems;
                let domainres = res && res.map(x => savedconfigitems.includes(Number(x.Id)) ? x : false);
                var filtered = domainres.filter(Boolean);
                // let configidsdata = this.state.configstring.split(',');
                // if (configidsdata && configidsdata.length > 0) {
                //     let configarraylist = filtered.map(x => configidsdata.includes(String(x.Id)) ? x : false);
                //     configarray = configarraylist.filter(Boolean);
                // }
                if (this.state.config) {
                    this.setState({ filteredconfigItems: filtered });
                }
                else {
                    this.setState({ filteredconfigItems: filtered });
                }
                console.log(filtered);
            }
            );

        })
        params && Object.keys(params).forEach(each => {
            if (typeof params[each] === 'object') {
                const { getvaluefromtype, paramname, value } = params[each]
                tempparams[each] = this.paramvalue(getvaluefromtype, paramname, value || (extraProps ? extraProps[paramname] : ''))
            }
            else tempparams[each] = params[each]
        })
        console.log('workflow params, extraProps---', tempparams, extraProps)
        //Load sitepermit types
        var sitepermittypeId
        if (this.isAdmin)
            sitepermittypeId = this.props.match.params && this.props.match.params.Id ? this.props.match.params.Id : (tempparams.SitePermitTypeId || this.props.dataItem['Id']);
        else
            sitepermittypeId = tempparams.SitePermitTypeId || this.props.extraProps.SitePermitTypeAppSettingsId;

        if (sitepermittypeId) {
            var sourceURL = getEnvVariable('AdminConfig') + `/api/SitePermitTypeAppSettings/${sitepermittypeId}/All`;
            //this.props.snackbarMessage('Please wait, Information is processing', 'info');
            GetData(sourceURL, "").then(results => {
                if (results && results.Id && results.Id > 0) {
                    tempparams.SitePermitTypeAppSettingsId = results.Id;
                    tempparams.sitepermittypeId = sitepermittypeId;
                    this.setPageState(tempparams);
                } else {
                    tempparams.SitePermitTypeAppSettingsId = -1;
                    tempparams.sitepermittypeId = sitepermittypeId;
                    this.setPageState(tempparams);
                }
            }).catch(ex => {
                console.log(ex);
                //this.props.snackbarMessage('Unable to process the workflow ', 'error')
            })

        } else {
            // this.setPageState(tempparams);
        }

    }


    setPageState(tempparams) {
        this.setState({
            ...tempparams
        }, () => {
            if (this.isAdmin) {
                if (this.props && this.props.dataItem && this.props.dataItem.workflowinfo && this.props.dataItem.workflowinfo.Id) {
                    this.loadAdminflow();
                }

            } else {
                this.loadMasterflow();
            }
        })
    }



    loadAdminflow() {
        const { SitePermitTypeAppSettingsId, sitepermittypeId } = this.state;
        var sourceURL = getEnvVariable('AdminConfig') + "/api/SitePermitTypeWorkflow/" + sitepermittypeId + "/All";
        this.props.snackbarMessage('Please wait, Information is processing', 'info');
        GetData(sourceURL, "").then(results => {
            if (results && results.length > 0 && !this.props.options.addnewworkflow) {
                let resultval = _.find(results, { 'Id': this.props.dataItem.workflowinfo.Id })
                var firstResult = resultval;
                this.setState({ ...firstResult });
            }
            this.workflowprocessAdmin();
        }).catch(ex => {
            this.props.snackbarMessage('Unable to process the workflow ', 'error')
        })
    }

    loadMasterflow() {
        const { WorkflowId, ParentWorkflowId, ApplicationID, SitePermitTypeAppSettingsId, sitepermittypeId /*sitePermitTypeId*/ } = this.state;
        var sourceURL = getEnvVariable('AdminConfig') + "/api/SitePermitTypeWorkflow/" + sitepermittypeId + "/SitePermitWorkFlows";
        if (sitepermittypeId && ApplicationID) {
            this.props.snackbarMessage('Please wait, Information is processing', 'info');
            GetData(sourceURL, "").then(results => {
                if (results && results.length > 0) {
                    var firstResult = results[0];
                    this.setState({ "ParentWorkflowId": firstResult.WorkflowId });
                } else {
                    /* Loading workflow without default workflow */
                    this.setState({ "ParentWorkflowId": 0 });
                }
                this.workflowprocessMain();
            }).catch(ex => {
                this.props.snackbarMessage('Unable to process the workflow ', 'error')
            })
        } else this.props.snackbarMessage('Unable to process the workflow ', 'error')
    }


    workflowprocessAdmin = () => {
        this.RoleRegionsurl = `/InternalAccessControlView/GetUserRoleConfigType/` + getEnvVariable('ModuleKey') + `/${this.configType}/?isAdmin=${this.isAdmin}&configIds=${this.state.config}`;
        //this.RoleRegionsurl = `/InternalAccessControlView/GetUserRoleConfigType/7/` + getEnvVariable('ModuleKey') + `/${this.configType}/?isAdmin=${this.isAdmin}`;
        let inthis = this;
        const { WorkflowId, ParentWorkflowIdValue, SiteId, SitePermitTypeAppSettingsId, ApplicationID/*sitePermitTypeId*/, RegionId } = inthis.state;
        var isAdd = WorkflowId <= 0 && ParentWorkflowIdValue <= 0;

        if (typeof window.workflowObj !== "undefined") {
            //For administration save
            if (isAdd) {
                inthis.props.snackbarMessage('Please wait, Information is processing', 'info');
                window.workflowObj.render({
                    isAdmin: inthis.isAdmin,
                    el: "#play", userdata: inthis.RoleRegionsurl, success: function (e) {
                        console.log(e);
                        if (e.id || inthis.state.addworkflowid) {
                            inthis.setState({ addworkflowid: e.id ? e.id : inthis.state.addworkflowid });
                            if (inthis.state.workflowName) {
                                let configdata = inthis.state.config

                                let resultdata = {
                                    workflowName: inthis.state.workflowName,
                                    workflowDescription: inthis.state.workflowDescription,
                                    IsDefault: inthis.state.IsDefault,
                                    workflowId: e.id ? e.id : inthis.state.addworkflowid,
                                    ConfigIds: configdata,
                                }
                                if (ParentWorkflowIdValue > 0) {
                                    resultdata['Id'] = inthis.state.Id;
                                }

                                var sourceURL = ParentWorkflowIdValue <= 0 ? getEnvVariable('AdminConfig') + "/api/SitePermitTypeWorkflow/" + `${inthis.state.SitePermitTypeAppSettingsId}` + "/" + resultdata.workflowId + "/add" : getEnvVariable('AdminConfig') + "/api/SitePermitTypeWorkflow/" + `${inthis.state.SitePermitTypeAppSettingsId}` + "/update";
                                let finalurl = { url: sourceURL }
                                let url = ParentWorkflowIdValue <= 0 ? { post: finalurl } : { put: finalurl }
                                requestApi(url, [], resultdata).then(response => {
                                    //inthis.props.snackbarMessage('Workflow Saved Successfully', 'success')
                                    if (ApplicationID) {

                                    }
                                    else inthis.props.onClose();
                                }).catch(errors => {
                                    errors.then(err => {
                                        if (err['WorkflowName']) {
                                            inthis.setState({ workflownameerror: true, workflownamehelpertext: err['WorkflowName'][0] });
                                        }
                                        //inthis.props.snackbarMessage('Unable to process the workflow ', 'error')
                                    })
                                })

                            }
                            else {
                                inthis.setState({ workflownameerror: inthis.state.workflowName ? false : true })
                            }
                        }
                    }
                });
            } else {
                inthis.props.snackbarMessage('Please wait, Information is processing', 'info');
                window.workflowObj.render({
                    isAdmin: inthis.isAdmin,
                    id: inthis.state.WorkflowId,
                    el: "#play", userdata: inthis.RoleRegionsurl, success: function (e) {
                        console.log(e);
                        inthis.props.snackbarMessage('Please wait, Information is processing', 'info');
                        if (ApplicationID) {

                        }
                        else {
                            if (inthis.state.workflowName) {
                                let configdata = inthis.state.config
                                let resultdata = {
                                    workflowName: inthis.state.workflowName,
                                    workflowDescription: inthis.state.workflowDescription,
                                    IsDefault: inthis.state.IsDefault,
                                    workflowId: inthis.state.WorkflowId,
                                    Id: inthis.state.Id,
                                    ConfigIds: configdata,
                                }
                                var sourceURL = getEnvVariable('AdminConfig') + "/api/SitePermitTypeWorkflow/" + `${inthis.state.SitePermitTypeAppSettingsId}` + "/update";
                                let finalurl = { url: sourceURL }
                                let url = { put: finalurl }
                                requestApi(url, [], resultdata).then(response => {
                                    //inthis.props.snackbarMessage('Workflow Saved Successfully', 'success')
                                    if (ApplicationID) {

                                    }
                                    else inthis.props.onClose();
                                }).catch(errors => {
                                    errors.then(err => {
                                        if (err['workflowName']) {
                                            inthis.setState({ workflownameerror: true, workflownamehelpertext: err['workflowName'][0] });
                                        }
                                        //inthis.props.snackbarMessage('Unable to process the workflow ', 'error')
                                    })
                                })

                            }
                            else {
                                inthis.setState({ workflownameerror: inthis.state.workflowName ? false : true })
                            }
                        }

                    }
                });
            }

        }
    }
    handleBack = () => {
        let pathname = this.props.location.pathname
        this.props.history.push(pathname)
        this.props.onClose();
        //this.props.handleClose();
        //this.props.setSectionsReload(true)
    }
    workflowprocessMain = () => {
        let inthis = this;
        const { WorkflowId, SiteId, ParentWorkflowId, ParentWorkflowIdValue, SitePermitTypeAppSettingsId, ApplicationID, RegionId, sitepermittypeId } = inthis.state;
        var isAdd = WorkflowId <= 0 && ParentWorkflowIdValue <= 0;
        if (typeof window.workflowObj !== "undefined") {
            //When loading from the application/permit will have parentid
            if (isAdd) {
                inthis.props.snackbarMessage('Please wait, Information is processing', 'info');
                window.workflowObj.render({
                    parentid: ParentWorkflowId,
                    isAdmin: inthis.isAdmin,
                    el: "#play", userdata: inthis.RoleRegionsurl, success: function (e) {
                        var applicationUpdateUrl = getEnvVariable('Application') + "/api/Application/UpdateWorkflow/" + sitepermittypeId + "/" + ApplicationID + "?&status=&workflowid=" + e.id;
                        inthis.setState({ WorkflowId: e.id }, () => {
                            PutData(applicationUpdateUrl, null).then(function (res) {
                                window.workflowObj.data.submit(e.id, function () {
                                    inthis.props.snackbarMessage('Workflow Saved Successfully', 'success');
                                    window.location.reload();
                                    //inthis.props.setSectionsReload(true)
                                }, function () {
                                    inthis.props.snackbarMessage('Unable to process the workflow ', 'error')
                                });
                            });
                        })
                    }
                });
            } else {

                inthis.props.snackbarMessage('Please wait, Information is processing', 'info');
                window.workflowObj.render({
                    isAdmin: inthis.isAdmin,
                    id: WorkflowId,
                    parentid: ParentWorkflowId,
                    el: "#play", userdata: inthis.RoleRegionsurl, success: function (e) {
                        //window.workflowObj.data.submit(e.id, function () {
                        inthis.props.snackbarMessage('Workflow Saved Successfully', 'success');
                        inthis.props.setSectionsReload(true)
                        //window.location.reload();
                        //}, function () { inthis.props.snackbarMessage('Unable to process the workflow ', 'error'); }
                        // )
                    }
                });
            }
        }
    }

    handlechange = (event) => {
        this.setState({ [event.target.name]: event.target.value, workflownameerror: false, workflownamehelpertext: null })
    }

    handleToggleChange = (event) => {
        this.setState({ IsDefault: event.target.value });
    }

    // handleConfigChange = (event, values) => {
    //     // let resultdata = values.map(a => a.Id);
    //     // let stringformatvalues = [...new Set(resultdata)].join()
    //     this.setState({ config: event.target.value });
    // }

    handleConfigChange = (event) => {
        if (this.state.ParentWorkflowIdValue > 0) {
            this.setState({ confirmationopen: true, currentconfigId: event.target.value })
        }
        else {
            this.setState({ currentconfigId: event.target.value, config: event.target.value }, () => this.workflowprocessAdmin())

        }
    }

    confirmation = () => {
        if (this.state.ParentWorkflowIdValue > 0) {
            this.setState({ WorkflowId: 0, confirmationopen: false, config: this.state.currentconfigId, ParentWorkflowIdValue: 0 }, () => this.workflowprocessAdmin())
        }
        else {
            this.setState({ config: this.state.currentconfigId }, () => this.workflowprocessAdmin())
        }
    }

    handleClose = () => {
        this.setState({ confirmationopen: false })
    }

    render() {
        const { styles, designprops, headerProps } = this.props.options
        const { SiteId, opensnackbar, RegionId } = this.state
        const { backbutton } = headerProps ? headerProps : {}
        let extraProps = {
            "transformProps": {
                "Y": true,
                "N": false,
                "true": "Y",
                "false": "N"
            }
        }
        console.log('siteId------', SiteId, RegionId)
        return (
            <div className="sketch_ToolBox_Widget" style={{ ...styles }} >
                {!designprops && <FormHeader title={`Workflow`} backbutton={backbutton} handleBack={this.handleBack} />}
                {this.state.confirmationopen && <ConfirmationDialog value={this.state.confirmationopen} description="Workflow will be reset" title="Do you want to reset Workflow?" onSubmit={this.confirmation} onClose={this.handleClose} />}
                <div className="pl-5 row">
                    <TextField className="pr-5 col-sm-10"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                        label="Name" name="workflowName" value={this.state.workflowName} onChange={this.handlechange} error={this.state.workflownameerror} helperText={this.state.workflownameerror ? this.state.workflownamehelpertext ? this.state.workflownamehelpertext : "Name is required" : ""} />
                </div>
                <div className="pl-5 row">
                    <TextField className="pr-5 col-sm-10"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        label="Description" name="workflowDescription" multiline onChange={this.handlechange} value={this.state.workflowDescription} />
                </div>
                {/* </div> */}
                {/* <div className="pl-5 col-sm-8 row"> */}
                <FormControl className="pl-5 col-sm-8">
                    {/*  */}
                    <InputLabel className="pl-5" shrink required={true}>{this.configtypename}</InputLabel>
                    <Select
                        value={this.state.config}
                        onChange={this.handleConfigChange}
                    //className="col-sm-8"
                    >
                        {this.state.filteredconfigItems && this.state.filteredconfigItems.map(menu => {
                            return <MenuItem value={menu.Id}> {menu.Name} </MenuItem>
                        })}
                    </Select>
                </FormControl>
                {/* </div> */}
                <div className="pl-5 row">
                    <Switch handleFieldValue={this.handleToggleChange} value={this.state.IsDefault} name="IsDefault" label="Default Workflow" extraProps={extraProps} />
                </div>
                <div id="play" className="workflow-wrapper"></div>
            </div>
        );
    }


    componentDidCatch(error, info) {
        console.log(error);
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage, setSectionsReload }, dispatch);
}

CWorkflow = connect(null, mapActions)(CWorkflow)
export default withRouter(CWorkflow);