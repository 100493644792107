export default class DefaultSettings {

    static drawer = null;
    static loader = null;
    static alert = null;
    static notice = null;
    static confirmation = null;

    static setLoader(item) {
        this.loader = item
    }

    static getLoader() {
        return this.loader;
    }

    static setAlert(item) {
        this.alert = item
    }

    static setConfirmation(item) {
        this.confirmation = item
    }


    static getAlert() {
        return this.alert;
    }

    static setDrawer(element) {
        this.drawer = element
    }

    static showDrawer(url) {
        if (this.drawer !== null) {
            this.drawer.opendrawer(url)
        }
    }
    static showAlert(message, variant) {
        if (this.alert !== null) {
            this.alert.show(message, variant);
        }
    }

    static setNotices(item) {
        this.notice = item
    }

    static showNotice(id, isAuto, values, info) {
        if (this.notice !== null) {
            console.log(id, isAuto, values)
            this.notice.openNotice(id, isAuto, values, info)
        }
    }

    static showNotify(notify, isAuto, values, info) {
        if (this.notice !== null) {
            this.notice.openNotification(notify, isAuto, values, info)
        }
    }

    static displayConfirmation(message) {
        if (this.confirmation !== null) {
            this.confirmation.show(message);
        }
    }

}