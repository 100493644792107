
export const RadioList = {
    'AMES': [{ value: 'C', label: 'Applicant', Title: 'Applicant' },
    { value: 'I', label: 'Individual', Title: 'Individual' },
    { value: 'T', label: 'Consultant', Title: 'Applicant' }],
    'WRS': [{ value: 'C', label: 'Applicant', Title: 'Applicant' },
    { value: 'I', label: 'Individual', Title: 'Individual' },
    { value: 'T', label: 'Consultant', Title: 'Applicant' }],
    'Other': [{ value: 'C', label: 'Company', Title: 'Company' },
    { value: 'I', label: 'Individual', Title: 'Individual' },
    { value: 'T', label: 'Consultant', Title: 'Consultant' }]
}