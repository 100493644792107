/**
      *********************************************************
          deprecated
      *********************************************************
**/
import { combineReducers } from 'redux'
import authentication from '../security/authentication'
import { compmapReducer } from '../../wrappers/viewport'
import adminformReducer from './adminform'
import headerReducer from './header'
import sidebarReducer from './sidebar'
import snackbar from './snackbar'
import confirmation from './confirmation'

export default combineReducers({
    header: headerReducer,
    sidebar: sidebarReducer,
    adminform: adminformReducer,
    authentication: authentication,
    snackbar: snackbar,
    compmapvalues: compmapReducer,
    confirmation: confirmation
})

