export const AMESMAPFIELDS = [
    'MAP_CENTER_LATITUDE',
    'MAP_CENTER_LONGITUDE',
    'PROPOSED_MAP_CENTER_LATITUDE',
    'PROPOSED_MAP_CENTER_LONGITUDE',
    'PROPOSED_POINT_GEOM_OBJECT_ID',
    'LINE_GEOM_OBJECT_ID',
    'PROPOSED_LINE_GEOM_OBJECT_ID',
    'POLYGON_GEOM_OBJECT_ID',
    'PROPOSED_POLYGON_GEOM_OBJECT_ID',
    'MULTIPOINT_GEOM_OBJECT_ID',
    'PROPOSED_MULTIPOINT_GEOM_OBJECT_ID',
    'CENTER_GEOM_OBJECT_ID',
    'PROPOSED_CENTER_GEOM_OBJECT_ID']
export const AMESADDFEILDVALUES = [{
    'WHAT_WOULD_YOU_LIKE_TO_DO_': '',
    //  'SUBMISSION_TYPE': 'E',
    'PERMIT_SITE': 160,
    'APPLICATION_STATUS': 1,
    'REGION_ID': '',
    'subModuleKey': '',
    'PROCESS_TYPE': '',
    'SITE_ID': '1',
    'MultipleLocationData': '',
    'SITE_PERMIT_TYPE_ID': '',
    'SUB_MODULE_ID': '',
    'RECORDS_REF': '',
    //  'PAPER_APPLICATION_NUMBER': '',
    'REFERENCE_KEY': '',
    'TAX_PARCEL_IDS': '',
    'PRIMARY_TAX_PARCEL_IDS': '',
    'TOWN_VILLAGE_CITY':'',
    'INTERSTATE_HWY':''
}]

export const AMESStateFields = [{
    REGION_ID: '', DISTRICT_ID: '', EXISTING_COUNTY: '', PROPOSED_COUNTY: '', SITE_ID: '', CREATE_APPLICATION: 'AE', 'PROCESS_TYPE': '', 'SITE_ID': '',
    WHAT_WOULD_YOU_LIKE_TO_DO_: '', whatwldTypes: [], permittypes: [], submoduletypes: [], processtype: [],
    refWhatWouldYouLikeToDo: '',TOWN_VILLAGE_CITY:'',INTERSTATE_HWY:'',
}]
