import { getEnvVariable } from "../../modules/environmentalList"

const nsComponents = {
    "components": [
        {
            type: 'scheduler',
            key: 'nsscheduler',
            "options": {
                "row": 1,
                "col": 0,
                "width": 12,
                title: 'Schedule',
                headerProps: {
                    "editbutton": false,
                    "savebutton": true,
                    "backbutton": false,
                    "closebutton": true
                }
            }
        },

        {
            "type": "multiduallistbox",
            "key": "nseventdetails",
            "options": {
                "width": 12,
                "row": 0,
                "col": 0,
                multiboxes: false,
                save: true,
                title: 'Event Details',
                designprops: true,
                headerProps: {
                    "editbutton": false,
                    "savebutton": true,
                    "backbutton": false,
                    "closebutton": false
                },
                "transformProps": {
                    "options": "availableCollection",
                    "selected": "selectedCollection",
                    "value": "id",
                    "label": "name"
                },
                title: 'Event Details',
                headerProps: {
                    "editbutton": false,
                    "savebutton": true,
                    "backbutton": false,
                    "closebutton": true
                }
            },
            components: [
                {
                    type: 'duallistbox',
                    key: 'firstduallistbox',
                    options: {
                        width: 12,
                        totalrows: '8',
                        totalcolumns: '4',
                        name: 'firstbox',
                        transformProps: {
                            available: {
                                key: 'availableCollection',
                                label: 'name',
                                value: 'key'

                            },
                            selected: {
                                key: 'selectedCollection',
                                label: 'name',
                                value: 'key'
                            }
                        },
                        "crud": {
                            "read": {
                                "get": {
                                    "url": getEnvVariable('Notification')+`/api/NotificationEvents/EventDetails`,
                                    "urlpath": `?notificationId={ID}&eventOnkey={eventOn}`,
                                    "routeProps": [{
                                        "custom": "ID"
                                    }],
                                    "matchProps": [{
                                        "value": "eventOn"
                                    }]
                                }
                            },
                            "update": {
                                "put": {
                                    "url": getEnvVariable('Notification')+`/api/NotificationEvents/SaveEventDetails`,
                                }
                            },
                            "appstatusavailable": {
                                "get": {
                                    "url": getEnvVariable('Sidebar')+`/api/SitePermitTypesStatus/BySitePermitTypeId`,
                                    "urlpath": `/{SitePermitTypeId}`,
                                    "matchProps": [{
                                        "value": "SitePermitTypeId"
                                    }]
                                }
                            },
                        },
                    }
                },
            ],
            "customclass": "dual-list-box-customlg"
        },
        /**Added for applicaiton event details - start */

        {
            "type": "multiduallistbox",
            "key": "nsapplicationeventdetails",
            "options": {
                "width": 12,
                "row": 0,
                "col": 0,
                multiboxes: false,
                save: true,
                title: 'Event Details',
                designprops: true,
                headerProps: {
                    "editbutton": false,
                    "savebutton": true,
                    "backbutton": false,
                    "closebutton": true
                },
                "transformProps": {
                    "options": "availableCollection",
                    "selected": "selectedCollection",
                    "value": "id",
                    "label": "name"
                },
                title: 'Event Details',
                headerProps: {
                    "editbutton": false,
                    "savebutton": true,
                    "backbutton": false,
                    "closebutton": false
                }
            },
            components: [
                {
                    type: 'duallistbox',
                    key: 'firstduallistbox',
                    options: {
                        width: 12,
                        totalrows: '8',
                        totalcolumns: '4',
                        name: 'firstbox',
                        transformProps: {
                            available: {
                                key: 'availableCollection',
                                label: 'name',
                                value: 'key'

                            },
                            selected: {
                                key: 'selectedCollection',
                                label: 'name',
                                value: 'key'
                            }
                        },
                        "crud": {
                            "read": {
                                "get": {
                                    "url": getEnvVariable('Application')+`/api/Application/ApplicationFields`,
                                    "urlpath": `/{SitePermitTypeId}`,
                                    "matchProps": [{
                                        "value": "SitePermitTypeId"
                                    }]
                                }
                            },
                            "update": {
                                "put": {
                                    "url": getEnvVariable('Notification')+`/api/NotificationEvents/SaveEventDetails`,
                                }
                            },
                            "available": {
                                "get": {
                                    "url": getEnvVariable('Application')+`/api/Application/ApplicationFields`,
                                    "urlpath": `/{SitePermitTypeId}`,
                                    "matchProps": [{
                                        "value": "SitePermitTypeId"
                                    }]
                                }
                            },                            
                            "selected": {
                                "get": {
                                    "url": getEnvVariable('Notification')+`/api/NotificationEvents/EventDetails`,
                                    "urlpath": `?notificationId={ID}&eventOnkey={eventOn}`,
                                    "routeProps": [{
                                        "custom": "ID"
                                    }],
                                    "matchProps": [{
                                        "value": "eventOn"
                                    }]
                                }
                            },
                        },
                    }
                },
            ]
        },

        /**Added for application event details - end */


        {
            "type": "formswitch",
            "key": "nsstatus",
            "options": {
                "row": 7,
                "col": 0,
                "width": 2,
                "label": "Status",
                "name": "Status",
                "initialvalue": "I",
                "transformProps": {
                    "A": true,
                    "I": false,
                    true: "A",
                    false: "I"
                },
                LabeltransformProps: {
                    I: 'In-Active',
                    A: 'Active',
                    true: 'Active',
                   false: 'In-Active',
                },
            }
        },
        {
            "type": "popupbutton",
            "key": "nssites",
            "options": {
                "row": 8,
                "col": 0,
                "width": 3,
                "label": "Counties",
                "name": "Counties",
                "buttonprops": {
                    "text": "sites",
                    "size": "large",
                    "color": "primary",
                    "variant": "contained"
                }
            },
            "components": [
                {
                    "type": "multiduallistbox",
                    "key": "duallistsites",
                    "options": {
                        "width": 12,
                        "row": 0,
                        "col": 0,
                        multiboxes: false,
                        save: true,
                        "transformProps": {
                            "options": "availableCollection",
                            "selected": "selectedCollection",
                            "value": "id",
                            "label": "name"
                        },
                        title: 'Counties',
                        headerProps: {
                            "editbutton": false,
                            "savebutton": true,
                            "backbutton": false,
                            "closebutton": true
                        }
                    },
                    components: [
                        {
                            type: 'duallistbox',
                            key: 'firstduallistbox',
                            options: {
                                width: 12,
                                totalrows: '8',
                                totalcolumns: '4',
                                name: 'firstbox',
                                transformProps: {
                                    available: {
                                        key: 'availableCollection',
                                        label: 'name',
                                        value: 'id'

                                    },
                                    selected: {
                                        key: 'selectedCollection',
                                        label: 'name',
                                        value: 'id'
                                    }
                                },
                                "crud": {
                                    "read": {
                                        "get": {
                                            "url": getEnvVariable('Notification')+`/api/NotificationSettings/Sites`,
                                            "routeProps": {
                                                "value": "routeid"
                                            }
                                        }
                                    },
                                    "update": {
                                        "put": {
                                            "url": getEnvVariable('Notification')+`/api/NotificationSettings/SaveSites`,

                                        }
                                    },
                                },
                            }
                        },
                    ]
                }
            ]
        },
        {

            type: "nstemplate",
            key: "eventtemplate",
            "components": [
            ]
        },
        {
            "type": "popupbutton",
            "key": "nsschedulenotificationtemplate",
            "options": {
                "row": 8,
                "col": 2,
                "width": 3,
                "label": "Notification Template",
                "name": "ScheduleNotificationTemplate",
                "buttonprops": {
                    "text": "Notification Template",
                    "size": "large",
                    "color": "primary",
                    "variant": "contained"
                }
            },
            "components": [
            ]
        },
        {

            "type": "multiduallistbox2",
            "key": "nsrecipients",
            "options": {
                "width": 12,
                "row": 0,
                "col": 0,
                multiboxes: false,
                save: true,
                "transformProps": {
                    "options": "availableCollection",
                    "selected": "selectedCollection",
                    "value": "id",
                    "label": "name"
                },
                save: true,
                title: 'Recipients',
                headerProps: {
                    "editbutton": false,
                    "savebutton": true,
                    "backbutton": false,
                    "closebutton": true
                }
            },
            components: [
                {
                    type: 'duallistbox',
                    key: 'firstduallistbox',
                    options: {
                        width: 6,
                        totalrows: '8',
                        totalcolumns: '4',
                        name: 'firstbox',
                        transformProps: {
                            available: {
                                key: 'availableCollection',
                                label: 'name',
                                value: 'id'

                            },
                            selected: {
                                key: 'selectedCollection',
                                label: 'name',
                                value: 'id'
                            }
                        },
                        "crud": {
                            "read": {
                                "get": {
                                    "url": getEnvVariable('Notification')+`/api/NotificationRecipients/Recipient`,
                                    "routeProps": {
                                        "value": "ID"
                                    }
                                }
                            },
                            "update": {
                                "put": {
                                    "url": getEnvVariable('Notification')+`/api/NotificationRecipients/SaveRecipients`,
                                }
                            },
                        },
                    }
                },
                {
                    type: 'duallistbox',
                    key: 'secondduallistbox',
                    options: {
                        helperText: '', //Once saved sections cannot be removed. They can only be hidden
                        width: 6,
                        totalrows: '8',
                        totalcolumns: '4',
                        name: 'second box',
                        transformProps: {
                            available: {
                                key: 'availableCollection',
                                label: 'name',
                                value: 'id'

                            },
                            selected: {
                                key: 'selectedCollection',
                                label: 'name',
                                value: 'id'
                            }
                        },
                        "crud": {
                            "read": {
                                "get": {
                                    "url": getEnvVariable('Notification')+`/api/NotificationSettings/Sites`,
                                    "routeProps": {
                                        "value": "routeid"
                                    }
                                }
                            },
                            "update": {
                                "put": {
                                    "url": getEnvVariable('Admin')+`/api/NotificationSettings/SaveSites`,

                                }
                            },
                        },
                    }
                },
            ]
        }


    ]
}
export default nsComponents