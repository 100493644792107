import { AppBar, Toolbar } from "@material-ui/core"
import Typography from '@material-ui/core/Typography'
import withWidth from '@material-ui/core/withWidth'
import React, { Fragment } from "react"
import { withRouter } from 'react-router-dom'
import LeftSideBar from '../../../custommodules/newentrancepermit/applicationdrawer'
import { ExternalApplicationSidebar } from '../../../json/newapplication/externalapplicationsidebar'
import { NewApplicationSidebar } from '../../../json/newapplication/newapplicationsidebar'
import { getEnvVariable } from '../../../modules/environmentalList'
import CloneItems from "../components/cloneitemspopup"
import DateTimePicker from './custom/datetimepicker'
import BreadCrumb from './breadcrumbs'
import Button from './buttons'
import Icon from './icons'
import Text from './text'
import Fab from './fab'
const _ = require('lodash');

const fieldTypes = {
    breadcrumbs: BreadCrumb,
    text: Text,
    icon: Icon,
    button: Button,
    datetime: DateTimePicker,
    clonemenu: CloneItems,
    fab: Fab
}

const expressionFormats = {
    appendslash: "${label} : ${value} /",
    default: "${label} : ${value}",
    beforeslash: "/ ${label} : ${value}"
}

const Header = ((props) => {
    const { components, labelitems, actions, addOnsByName, buttons, sharedProps, hassidebartabs, style, fieldValues, fieldProps = {} } = props
    let openMenuDisplay = (props.width === "sm" || props.width === "xs") ? false : true
    const [openMenu, setOpenMenu] = React.useState(openMenuDisplay);
    let type = props && props.accessPermissions ? (props.accessPermissions.configType == 'S' ? props.accessPermissions.moduleSiteAccess : props.accessPermissions.configType == 'R' ? props.accessPermissions.moduleRegionAccess :  props.accessPermissions.moduleDistrictAccess) : null
    let roleaccess = props.accessPermissions && type ?
        Object.values(type).includes('Add') ? 'CanAdd' :
            Object.values(type).includes('Update') ? 'CanEdit' :
                Object.values(type).includes('Read') ? 'CanRead' : 'CanAdd' : 'CanAdd'
    let portal = props.accessPermissions ? props.accessPermissions.portal : null
    let isAdmin = props.accessPermissions && props.accessPermissions.isAdmin ? props.accessPermissions.isAdmin : 'N' 
    let canAdd = isAdmin == 'Y' ? true : (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanAdd" ? true : (portal === "E") ? true : false
    let canEdit = isAdmin == 'Y' ? true : (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanEdit" ? true : (portal === "E") ? true : false
    let canRead = (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanRead" ? true : (portal === "E") ? true : false
    React.useEffect(() => {
        setOpenMenu(openMenuDisplay);
    }, [props.width]);


    const handleBreadcrumbMenu = event => {
        //   setMobileBreadcrumbAnchorEl(event.currentTarget); --correct: ununsed assignemnt
        //   setOpenMenuList(true) --correct: ununsed assignemnt
    };

    if (!openMenu) {
        return (
            <Fragment>
                <AppBar position="sticky" color={style ? '' : 'secondary'} style={style} elevation={0}>
                    <Toolbar variant="dense" >
                        <div className="col-sm-12 col-xs-12 m-1 grid-actions">
                            <div className="col row">
                                {components && components.map(each => {
                                    const { type, name, items, ...otherProps } = each
                                    const EachComp = fieldTypes[type]
                                    let arr = items ? _.chunk(items, _.findLastIndex(items)) : null
                                    if (type === 'breadcrumbs' && arr && arr.length > 1) {
                                        return (
                                            <div className="row col-sm-11 pl-1 breadcrumbs">
                                                <EachComp items={arr[1]} {...addOnsByName[name]} handleBreadcrumbMenu={handleBreadcrumbMenu} moreicon={true} fieldValues={fieldValues} fieldProps={fieldProps}
                                                    sharedProps={sharedProps} />
                                            </div>
                                        )
                                    }
                                    else return <div><EachComp fieldProps={fieldProps} items={items} {...addOnsByName[name]} sharedProps={sharedProps} fieldValues={fieldValues} /></div>
                                })}
                                <div className='flex-grow-1' />
                                {labelitems && labelitems[0].items.map(each => {
                                    const { name, value, customname } = each
                                    if (props && props[value] && addOnsByName[value]) {
                                        if (customname) {
                                            let ExpressionType = each.exptype ? expressionFormats[each.exptype] : expressionFormats['default']
                                            let labelreplace = ExpressionType.replace("${label}", props[name]);
                                            let valuereplace = labelreplace.replace("${value}", props[value]);
                                            return <Typography variant="body2" gutterBottom>{valuereplace}&nbsp;</Typography>
                                        }
                                        else {
                                            let ExpressionType = each.exptype ? expressionFormats[each.exptype] : expressionFormats['default']
                                            let labelreplace = ExpressionType.replace("${label}", name);
                                            let valuereplace = labelreplace.replace("${value}", props[value]);
                                            return <Typography variant="body2" gutterBottom>{valuereplace}&nbsp;</Typography>
                                        }
                                    }
                                    else if (hassidebartabs && localStorage.getItem(value)) {
                                        let valuedata = localStorage.getItem(value)
                                        if (each.trimvalue) {
                                            valuedata = (valuedata.length > 12 ? `${(valuedata).substring(0, 12)}...` : `${valuedata}`)
                                        }
                                        let ExpressionType = each.exptype ? expressionFormats[each.exptype] : expressionFormats['default']
                                        let labelreplace = ExpressionType.replace("${label}", name);
                                        let valuereplace = labelreplace.replace("${value}", valuedata);
                                        return <Typography variant="body2" gutterBottom>{valuereplace}&nbsp;</Typography>
                                    }
                                    else return null

                                })}
                                <div className='flex-grow-1' />
                                {actions.map(each => {
                                const { type, hidecondition, name,haseditrecord, hasrolebasedcondition, ...otherProps } = each
                                const EachComp = fieldTypes[type]
                                if ( haseditrecord && canRead )     
                                {
                                   return null  
                                } 
                            if (hasrolebasedcondition && !(canAdd )) {
                                return null
                            }
                                    if (hidecondition) {
                                        let condition = null;
                                        hidecondition.forEach(element => {
                                            const { section, FieldKey, FieldValue, accessPortal, dataItemName, dataItemValue, prevcondition, routeparams } = element
                                            if (accessPortal) {
                                                condition = prevcondition && prevcondition === 'or' && condition === true ? true : prevcondition === 'and'
                                                    && condition === false ? false : (props.accessPermissions.portal === accessPortal) ? true : false
                                            }
                                        });
                                        if (condition) {
                                            return null
                                        }
                                        else {
                                            return <EachComp {...otherProps} name={name} sidebaritemsinfo={props.sidebaritemsinfo}
                                                {...addOnsByName[name]} sharedProps={sharedProps} fieldValues={fieldValues} fieldProps={fieldProps} />
                                        }
                                    }
                                    if (each.name === 'fullscreen' || each.name === 'exitfullscreen') {
                                        return null
                                    }

                                    else
                                        return <EachComp {...otherProps} name={name} sidebaritemsinfo={props.sidebaritemsinfo}
                                            {...addOnsByName[name]} sharedProps={sharedProps} fieldValues={fieldValues} fieldProps={fieldProps} />
                                })}

                            </div>
                        </div>
                    </Toolbar>
                </AppBar >
                {hassidebartabs &&
                    <LeftSideBar {...(props.AccessPortal === 'E') ? ExternalApplicationSidebar[getEnvVariable('ModuleKey') + props.match.params.key] : NewApplicationSidebar[getEnvVariable('ModuleKey') + props.match.params.key]}>
                    </LeftSideBar>}
            </Fragment>
        )
    }
    else {
        return (
            <Fragment>
                <AppBar position="sticky" color={style ? '' : 'secondary'} style={style} elevation={0}>
                    <Toolbar variant="dense">
                        {components.map(each => {
                            const { type, name, ...otherProps } = each
                            const EachComp = fieldTypes[type]
                            return <div><EachComp {...otherProps} {...addOnsByName[name]} sharedProps={sharedProps} /></div>
                        })}
                        <div className='flex-grow-1' />
                        {labelitems && labelitems[0].items.map(each => {
                            const { name, value, customname, trimvalue } = each
                            if (props && props[value] && addOnsByName[value]) {
                                let valuedata = props[value]
                                if (trimvalue) {
                                    valuedata = (props[value].length > 12 ? `${(props[value]).substring(0, 12)}...` : `${props[value]}`)

                                }
                                if (customname) {
                                    let ExpressionType = each.exptype ? expressionFormats[each.exptype] : expressionFormats['default']
                                    let labelreplace = ExpressionType.replace("${label}", props[name]);
                                    let valuereplace = labelreplace.replace("${value}", valuedata);
                                    return <Typography variant="body2" gutterBottom>{valuereplace}&nbsp;</Typography>
                                }
                                else {
                                    let ExpressionType = each.exptype ? expressionFormats[each.exptype] : expressionFormats['default']
                                    let labelreplace = ExpressionType.replace("${label}", name);
                                    let valuereplace = labelreplace.replace("${value}", valuedata);
                                    return <Typography variant="body2" gutterBottom>{valuereplace}&nbsp;</Typography>
                                }
                            }
                            else if (hassidebartabs && localStorage.getItem(value)) {
                                let valuedata = localStorage.getItem(value)
                                if (each.trimvalue) {
                                    valuedata = (valuedata.length > 12 ? `${(valuedata).substring(0, 12)}...` : `${valuedata}`)
                                }
                                let ExpressionType = each.exptype ? expressionFormats[each.exptype] : expressionFormats['default']
                                let labelreplace = ExpressionType.replace("${label}", name);
                                let valuereplace = labelreplace.replace("${value}", valuedata);
                                return <Typography variant="body2" gutterBottom>{valuereplace}&nbsp;</Typography>
                            }
                            else return null

                        })}
                        <div className='flex-grow-1' />
                        {buttons && buttons.map(each => {
                            const { type, name, ...props } = each
                            const EachComp = fieldTypes[type]
                            return <EachComp {...props} {...addOnsByName[name]} />
                        })}
                        {actions.map(each => {
                            const { type, hidecondition, name,haseditrecord, hasrolebasedcondition, ...otherProps } = each
                            const EachComp = fieldTypes[type]
                            if (haseditrecord && canRead) {
                                return null
                            }
                            if (hasrolebasedcondition && !(canAdd )) {
                                return null
                            }
                            if (hidecondition) {
                                let condition = null;
                                hidecondition.forEach(element => {
                                    const { section, FieldKey, FieldValue, accessPortal, dataItemName, dataItemValue, prevcondition, routeparams } = element
                                    if (accessPortal) {
                                        condition = prevcondition && prevcondition === 'or' && condition === true ? true : prevcondition === 'and' && condition === false ? false : (props.accessPermissions.portal === accessPortal) ? true : false
                                    }
                                });
                                if (condition) {
                                    return null
                                }
                                else {
                                    return <EachComp {...otherProps} name={name} sidebaritemsinfo={props.sidebaritemsinfo} {...addOnsByName[name]} sharedProps={sharedProps} fieldValues={fieldValues} fieldProps={fieldProps}/>
                                }
                            }
                            else return <EachComp {...otherProps} name={name} sidebaritemsinfo={props.sidebaritemsinfo} {...addOnsByName[name]} sharedProps={sharedProps} fieldValues={fieldValues} fieldProps={fieldProps}/>
                        })}

                    </Toolbar>
                </AppBar>
                {hassidebartabs &&
                    <LeftSideBar {...((props.AccessPortal === 'E' || props && props.accessPermissions && props.accessPermissions.portal === 'E')) ? ExternalApplicationSidebar[getEnvVariable('ModuleKey') + props.match.params.key] : NewApplicationSidebar[getEnvVariable('ModuleKey') + props.match.params.key]}>
                    </LeftSideBar>}
            </Fragment>
        )

    }
}
)
export default withWidth()(withRouter(Header));