import {
    Grid,
    GridColumn as Column
} from "@progress/kendo-react-grid";
import { loadModules } from 'esri-loader';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { snackbarMessage } from '../../../actions';
import { GISLookupTask } from '../eps/GISLookupTask';
import { GeometryEngineTask } from '../util/geometryEngineTask';
import { CenterAndZoom, QueryTaskBufferWithGeom } from '../util/queryTask';

// const DATA_ITEM_KEY = "OBJECTID";
// const SELECTED_FIELD = "selected";
class CDatTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: this.props.view,
            options: this.props.options,
            canEdit: this.props.canEdit,
            isGridView: this.props.isGridView,
            mapConstants: this.props.mapConstants,
            selectedLayerOptions: this.props.selectedLayerOptions,
            selectedArea: this.props.selectedArea,
            selectedRecords: {},
            spatialReference: this.props.spatialReference,
            units: "feet",
            distance: "3000",
            selected: [],
            selectedState: {},
            isDataTableExpand: false,
            showGrid: true,
            primarySelectedRecord: ''
        }
        this.startup(
            this.props
        );
        //this.bufferSearch = this.bufferSearch.bind(this);
    }

    

    startup = (config) => {
        loadModules([
            "esri/core/watchUtils",
            "esri/widgets/Expand",
        ]).then(([watchUtils, Expand]) => {
            let domEle = document.getElementsByClassName("map-datatable-container");
            let dataGridDivNode = document.getElementsByClassName("dataGridDiv");
            if (domEle) {
                this.DataTableExpand = new Expand({
                    collapseTooltip: "Collapse Table",
                    expandTooltip: "Expand Table",
                    view: config.view,
                    //content: node,
                    content: domEle[0],
                    //container: this.buffercalccontainer,
                    expandIconClass: "esri-icon-table",
                    expanded: true
                });
                config.view.ui.add(this.DataTableExpand, "bottom-right");
                this.props.widgetRenderCallBack("DatTable");
                const inThis = this;
                this.selectedLayer = config.view.map.layers.find(function (layer) {
                    return layer.Id === inThis.state.selectedLayerId;
                });
                watchUtils.pausable(this.DataTableExpand, "expanded", function (newValue, oldValue) {
                    inThis.props.onexpanded(this.DataTableExpand, newValue);
                });
                this.loadDataBySelectedArea();
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        //if (prevProps && this.props.selectedArea && this.props.selectedArea.graphic && this.props.selectedArea.graphic.geometry && prevProps.selectedArea && prevProps.selectedArea.graphic && prevProps.selectedArea.graphic.geometry && prevProps.selectedArea.graphic.geometry !== this.props.selectedArea.graphic.geometry) {
        if (prevProps && this.props.selectedArea && prevProps.selectedArea && this.props.selectedArea.counter > prevProps.selectedArea.counter) {
            if (this.props.selectedArea.graphic) {
                this.setState({ selectedArea: this.props.selectedArea, selectedLayerOptions: this.props.selectedLayerOptions }, () => {
                    this.loadDataBySelectedArea(this.props.selectedArea)
                })
            }
            else
                this.clearGridData()
        } else if (this.props.selectedArea.cleargraphic) {
            this.clearGridData()
        }
        if (this.props.clearsketch != prevProps.clearsketch) {
            this.clearGridData()
        }
        if (this.props.clearAreaSelection != prevProps.clearAreaSelection) {
            this.clearGridData();
            if (this.layer && this.layer.graphics) {
                this.layer.graphics.removeAll();
                let recordsReponse = {}
                recordsReponse[this.state.selectedLayerOptions.SubscribedMasterFieldsKey] = "";
                recordsReponse["PRIMARY_"+this.state.selectedLayerOptions.SubscribedMasterFieldsKey] = "";
                recordsReponse['SELECT_LAYER_URL'] = this.state.selectedLayerOptions.Url;
                this.setState({ primarySelectedRecord : ''})
                if(this?.props?.onSaveorUpdateMapResponse)
                    this.props.onSaveorUpdateMapResponse(recordsReponse);
            }
        }
    }

    getPrimarySelectedRecord(){
        if(this.props.options && this.props.options.tasks && this.state && this.state.selectedLayerOptions)
            return this.props.options.tasks.TaxParcels["PRIMARY_"+this.state.selectedLayerOptions.SubscribedMasterFieldsKey]
        else
            return ""
    }

    onSelectionChange(event, inthis, isselected) {
        let selectrecordGeom = null;
        loadModules([
            "esri/Graphic",
            "esri/layers/GraphicsLayer"
        ]).then(([Graphic, GraphicsLayer]) => {
            let row = event.dataItem || event.dataItems[0];
            let newGeoms = [];
            let selectedRecords = inthis.state.selectedRecords[inthis.state.selectedLayerOptions.id] || [];
            let isTaxlot = inthis.props.selectedLayerOptions ? inthis.state.selectedLayerOptions.IsTaxlot == "Y" : false;
            let layerfield = inthis.state.selectedLayerOptions.SelectField

            let primarySelectedRecord = this.getPrimarySelectedRecord();

            this.layer = inthis.state.view.map.layers.find(function (layer) {
                return layer.title === "Data Table Seleced Records_" + inthis.state.selectedLayerOptions.id;
            });
            let isNew = !this.layer;
            if (isNew) {
                this.layer = new GraphicsLayer({
                    "title": "Data Table Seleced Records_" + inthis.state.selectedLayerOptions.id, listMode: 'hide'
                });
            }
            const allrecords = !row || row.length > 0;
            if (allrecords)
                row = inthis.state.gridData[0]          

            let objidkey = Object.keys(row).find(k => k == "OBJECTID")
            if (!objidkey)
                objidkey = Object.keys(row).find(k => k == "objectId")
            if (!objidkey)
                objidkey = Object.keys(row).find(k => k == inthis.state.selectedLayerOptions.SearchField)

            const objectId = row[objidkey];
            let selectedrecord = row[inthis.state.selectedLayerOptions.SelectField];
            if (selectedrecord && typeof selectedrecord === 'string')
                selectedrecord = selectedrecord.trim()
            if (!isTaxlot) { inthis.state.view.popup.close(); }

            if (!allrecords) {
                let selectrecord = inthis.gridresponse.features.find(function (data) {
                    return data.attributes[objidkey] == objectId;
                });
                if (selectrecord) {
                    if (row.selected) {
                        let graphic = null
                        let valid = inthis.isSelectionValid(inthis.state.selectedLayerOptions, selectrecord, inthis);
                        if (valid) {
                            selectrecord.attributes['selected'] = true
                            selectrecordGeom = selectrecord.geometry;
                            graphic = new Graphic({
                                geometry: selectrecord.geometry,
                                attributes: selectrecord.attributes,
                                symbol: inthis.state.mapConstants.getHighlightsymbol(inthis.props.SubModuleKey, selectrecord.geometry.type, selectrecord.attributes, inthis.state.selectedLayerOptions)//inthis.state.mapConstants.getsymbol(selectrecord.geometry.type,true,inthis.state.selectedLayerOptions)
                            });
                            CenterAndZoom(inthis.state.view, selectrecord.geometry);
                            selectedRecords.push(selectedrecord)
                            document.getElementsByClassName("selectdFeatures")[0].innerHTML = " " + selectedrecord + " Selected "
                            newGeoms.push(graphic)
                        }
                    } else {
                        selectedRecords = selectedRecords.filter(a => a != selectedrecord)
                        document.getElementsByClassName("selectdFeatures")[0].innerHTML = " " + selectedrecord + " De-selected "
                        let selectgraphic = inthis.layer.graphics.find(function (data) {
                            return data.attributes[objidkey] == objectId;
                        });
                        if (selectgraphic)
                            selectgraphic.attributes['selected'] = false
                        inthis.layer.graphics.remove(selectgraphic);
                    }
                }
            }
            else {
                if (row.selected)  //All records Selected
                {
                    document.getElementsByClassName("selectdFeatures")[0].innerHTML = " All records Selected "
                    selectedRecords = this.state.gridData.map(function (gd) { return gd[inthis.state.selectedLayerOptions.SelectField] })
                    let geometries = inthis.gridresponse.features.map(function (data) {
                        return data.geometry;
                    });
                    inthis.gridresponse.features.forEach(function (item, index) {
                        let valid = inthis.isSelectionValid(inthis.state.selectedLayerOptions, item, inthis);
                        if (valid) {                            
                            item.attributes['selected'] = true;
                            let primFieldValue = inthis.props.options.FieldsData ? (inthis.props.options.FieldsData[inthis.props.options.FieldsData.findIndex((obj => obj.Fieldkey === "PRIMARY_"+inthis.props.selectedLayerOptions.SubscribedMasterFieldsKey))]) : null;
                            let primarySelectedRecord_ref = inthis.state.primarySelectedRecord || ( primFieldValue ? primFieldValue.FieldValue : null );                            
                            let isprimarytaxlot = (primarySelectedRecord_ref && item.attributes[layerfield] === primarySelectedRecord_ref) ? true : (!primarySelectedRecord_ref && (index + 1) === inthis.gridresponse.features.length && isTaxlot); 
                            let grpahicsymbol = isprimarytaxlot ? inthis.state.mapConstants.PolygonFillSymbolSecondary
                                : inthis.state.mapConstants.getHighlightsymbol(inthis.props.SubModuleKey, item.geometry.type, item.attributes, inthis.state.selectedLayerOptions)
                            let g = new Graphic({
                                geometry: item.geometry,
                                attributes: item.attributes,
                                symbol: grpahicsymbol
                            });
                            //Unique geometries considered
                            
                            let isGeomRecordExists = newGeoms.filter(res => res.attributes[layerfield] == item.attributes[layerfield])
                            if (isGeomRecordExists.length == 0) {
                                newGeoms.push(g);
                            }
                            if(!inthis.state.primarySelectedRecord && isprimarytaxlot)
                                primarySelectedRecord = item.attributes[layerfield]
                        }
                    });
                    if (geometries.length > 0) {
                        selectrecordGeom = geometries[0];
                        if (geometries.length > 1) {
                            GeometryEngineTask({ "geometries": geometries, "type": "union" }).then(
                                GeomEngineresponse => {
                                    if (GeomEngineresponse && GeomEngineresponse.centroid)
                                        CenterAndZoom(inthis.state.view, GeomEngineresponse.centroid);
                                },
                                GeomEngineerror => {
                                    //  //console.log(GeomEngineerror);
                                });
                        } else if (geometries.length === 1) {
                            CenterAndZoom(inthis.state.view, geometries[0]);
                        }
                    }
                }
                else {
                    inthis.gridresponse.features.forEach(function (item) {
                        item.attributes['selected'] = false
                    })
                    document.getElementsByClassName("selectdFeatures")[0].innerHTML = " All records De-selected "
                    this.layer.graphics.removeAll();
                }
            }


            if (this.props.options.MapBussinessRules) {
                let graphicies = newGeoms;
                if (isTaxlot) {
                    this.processForTaxlots(newGeoms, this.state.view);
                }
                let inthis = this
                GeometryEngineTask({
                    "geometries": graphicies, "spatialReference": this.state.spatialReference, MapBussinessRules: this.props.options.MapBussinessRules, ValidateOf: "TOTAL_GEOMETRY_LENGTH", "type": "validateBussinessRules", units: "miles"
                }).then(
                    validateBussinessRulesResponse => {
                        let isValid = true;
                        if (validateBussinessRulesResponse && validateBussinessRulesResponse.length > 0) {
                            validateBussinessRulesResponse.forEach(vbr => {
                                if (vbr.IsValidationFalied) {
                                    alert(vbr.alertMessage.message);
                                    isValid = false;
                                    // inthis.props.snackbarMessage(vbr.alertMessage.message, vbr.alertMessage.type);
                                    //inthis.layer.remove(event.graphic);
                                }
                            });
                        }
                        if (isValid && newGeoms && newGeoms.length > 0) {
                            newGeoms.forEach(ng => {
                                inthis.layer.graphics.add(ng);
                            });

                            if (isNew)
                                inthis.state.view.map.layers.add(this.layer, 0);
                            let stateselectedRecords = inthis.state.selectedRecords;
                            //Pre load the selected records for taxlots
                            if(isTaxlot){
                                let preselectedrecords = inthis.state.options && Array.isArray(inthis.state.options.FieldsData) ?  inthis.state.options.FieldsData.filter(f => {
                                    return (f.Fieldkey == (inthis.props.selectedLayerOptions.SubscribedMasterFieldsKey) && f.FieldValue) ;
                                }).map(f => f.FieldValue) : "";                                
                                if(preselectedrecords && Array.isArray(preselectedrecords) && Array.isArray(selectedRecords) && preselectedrecords.length > 0){                                    
                                    selectedRecords = [...new Set(selectedRecords.concat(preselectedrecords[0].split(",")))]  //selectedRecords.concat(preselectedrecords[0].split(","))
                                    console.log('Total selected taxlots------------', selectedRecords)
                                }
                            }
                            stateselectedRecords[inthis.state.selectedLayerOptions.id] = selectedRecords                           
                            inthis.setState({ selectedRecords: stateselectedRecords, primarySelectedRecord: primarySelectedRecord });
                            if (inthis.state.selectedLayerOptions.SubscribedMasterFieldsKey && inthis.props.onSaveorUpdateMapResponse) {
                                let recordsReponse = {}
                                if (inthis.props.SubModuleKey === 'VIOLATIONS')
                                    recordsReponse[inthis.state.selectedLayerOptions.SubscribedMasterFieldsKey] = { Id: row.Id, SitePermitTypeId: row.Site_Permit_Type_Id, SubModuleKey: row.SubModuleKey };
                                else
                                    recordsReponse[inthis.state.selectedLayerOptions.SubscribedMasterFieldsKey] = selectedRecords.join(",");

                                if(primarySelectedRecord)
                                    recordsReponse["PRIMARY_"+inthis.state.selectedLayerOptions.SubscribedMasterFieldsKey] = primarySelectedRecord;

                                //Additional parameter, if layer url to be used
                                recordsReponse['SELECT_LAYER_URL'] = inthis.state.selectedLayerOptions.Url;
                                inthis.props.onSaveorUpdateMapResponse(recordsReponse);
                            } else {
                                inthis.props.snackbarMessage('Not subscribed to any field', 'info');
                            }
                            if (inthis.props.SelectGeomInsteadOfDraw == true) {
                                let geom = selectrecordGeom;
                                if (selectrecordGeom && selectrecordGeom.extent && selectrecordGeom.extent.center && selectrecordGeom.extent !== undefined || selectrecordGeom.extent !== null)
                                    geom = selectrecordGeom.extent.center;

                                if (inthis.props.options && inthis.props.options.MasterLookupFields) {
                                    GISLookupTask(inthis.props.options, { geometry: geom }, inthis.props.options.MasterLookupFields).then((gisres) => {
                                        if (inthis.props.setStateMapInformation)
                                            inthis.props.setStateMapInformation(gisres);
                                    })
                                }
                            }
                        }
                    },
                    validateBussinessRuleseerror => {
                    });
            } else {
                let recordsReponse = {}
                recordsReponse[inthis.state.selectedLayerOptions.SubscribedMasterFieldsKey] = { Id: row.Id, SitePermitTypeId: row.Site_Permit_Type_Id, SubModuleKey: row.SubModuleKey };
                inthis.props.onSaveorUpdateMapResponse(recordsReponse);
            }
        });
    }

    loadDataBySelectedArea() {
        if (this.selectedLayer && this.state.selectedArea && this.state.selectedArea.graphic && this.state.selectedArea.graphic.geometry) {
            document.getElementsByClassName("dataTableInfo")[0].innerHTML = " Loading data "
            QueryTaskBufferWithGeom(this.state.selectedLayerOptions, "1=1", this.state.spatialReference, this.state.selectedArea.graphic.geometry).then(
                response => {
                    let selectedRecords = this.state.selectedRecords[this.state.selectedLayerOptions.id] || [];
                    if (response && response.features && response.features.length > 0) {
                        let graphics = response.features;
                        let selected = this.state.selectedLayerOptions.AutoSelect || false
                        var data = graphics.map(function (feature, i) {
                            return Object.keys(feature.attributes).reduce((obj, key) => {
                                obj[key] = feature.attributes[key];
                                obj['selected'] = selected;
                                return obj;
                            }, {});
                        });
                        if (this.layer && this.layer.graphics && this.layer.graphics.items && this.layer.graphics.items.length > 0)//Already selected
                        {
                            let objidkey = null;
                            this.layer.graphics.items.forEach(feature => {
                                data = data.filter(a => a["OBJECTID"] != feature.attributes["OBJECTID"]);
                                let objval = Object.keys(feature.attributes).reduce((obj, key) => {
                                    obj[key] = feature.attributes[key];
                                    //obj['selected'] = true;
                                    return obj;
                                }, {})
                                response.features.push(feature);
                                data.push(objval)
                                /*if(objval['selected'])
                                {
                                    let selectedrecordObj=this.getselectedRecordId(objidkey,objval,this.state.selectedLayerOptions.SelectField)
                                    objidkey=selectedrecordObj.objidkey
                                    if(selectedRecords.indexOf(selectedrecordObj.selectedrecord)==-1)
                                        selectedRecords.push(selectedrecordObj.selectedrecord);
                                }*/
                            });
                            /*
                            let stateselectedRecords= this.state.selectedRecords;
                            stateselectedRecords[this.state.selectedLayerOptions.id]=selectedRecords
                            this.setState({ selectedRecords: stateselectedRecords });
                            */
                        }
                        document.getElementsByClassName("dataTableInfo")[0].innerHTML = " Total Records <b>" + data.length.toString() + " </b>"
                        this.columns = this.buildColumns(response.fields);
                        this.gridresponse = response;
                        this.griddata = data;
                        this.setState({ gridData: data, columns: this.columns, showGrid: true }, () => { })
                        if (this.state.selectedLayerOptions.AutoSelect == true) {
                            if (this.state.isDataTableExpand == false) {
                                this.DataTableExpand.expanded = false;
                                this.setState({ isDataTableExpand: true })
                            }
                            this.onSelectionChange({ dataItem: data }, this)
                        }
                    } else {
                        this.clearGridData()
                    }
                },
                error => {
                    //console.log(error);
                })
        }
    }

    processForTaxlots = (graphics, view) => {
        let inthis = this;
        let field = inthis.state.selectedLayerOptions.SelectField
        loadModules([
            "esri/Graphic",
            "esri/layers/GraphicsLayer",
            "esri/PopupTemplate",
        ]).then(([Graphic, GraphicsLayer, PopupTemplate]) => {
            try{
                let featurelist = [];
                graphics.map(function (feature, i) {
                    feature.popupTemplate = null
                    let hasinlist = featurelist.filter(res => res.attributes[field] == feature.attributes[field])
                    if (hasinlist.length == 0) {
                        //console.log('Adding custom template for ---', feature);
                        feature.popupTemplate = {
                            title: '<b>Map Tax Lot: {' + field + '} </b>',
                            content: (evt) => inthis.PrimaryChangeContent(feature, view)
                        }
                    }
                    featurelist.push(feature)
                });
                //console.log('Featurelist -----------',featurelist);
                // featurelist.map(listres => {
                //     //console.log(listres.attributes[field])
                // })
                // view.popup.open({
                //     features: featurelist
                // });
            }catch(ex){
                console.log(ex)
            }            
        })
    }

    PrimaryChangeContent = (evt, view) => {
        //console.log(evt, this);
        //let selection = options.tasks.TaxParcels;
        var node = document.createElement("div");
        let chk = document.createElement("input");
        chk.type = "checkbox";
        chk.onclick = (evt1) => this.onPrimaryChage(view, evt, this);
        chk.id = "primarycheck_id";

        node.appendChild(chk);
        let brek = document.createElement("br");
        let bold = document.createElement("b");
        let lable = document.createElement("label");
        lable.for = "primarycheck_id";
        lable.innerHTML = " Is Primary Taxlot";
        bold.appendChild(lable);
        node.appendChild(bold);
        node.appendChild(brek);
        node.appendChild(brek);

        //let lablebody = document.createElement("label");
        //lablebody.innerHTML = "Pre Selected taxlots are : "+ (selection.queryexpression ? selection.queryexpression : "NA");
        //node.appendChild(lablebody);

        return node;
    }

    onPrimaryChage(view, geom, inthis) {
        //console.log(view);
        let field = inthis.state.selectedLayerOptions.SelectField
        let uniquetaxlots = geom.attributes[field];
        this.updatePrimaryGeomSymbols([uniquetaxlots], inthis);
        //alert('Changed to primary' + uniquetaxlots)
        return true;
    }

    updatePrimaryGeomSymbols(queryParams, inthis) {
        loadModules([
            "esri/Graphic",
            "esri/layers/GraphicsLayer",
            "esri/PopupTemplate",
        ]).then(([Graphic, GraphicsLayer, PopupTemplate]) => {
            //let primaryrecord = "";
            let allrecords = [];
            let field = inthis.state.selectedLayerOptions.SelectField;
            inthis.layer.graphics.forEach(grph => {
                //console.log(grph);
                if (grph.attributes) {
                    let isprimary = queryParams.includes(grph.attributes[field]);
                    //primaryrecord = isprimary ? grph.attributes.MapTaxlot : "";
                    if (!isprimary && !allrecords.includes(grph.attributes[field]))
                        allrecords.push(grph.attributes[field]);

                    let graphic = new Graphic({
                        geometry: grph.geometry,
                        attributes: grph.attributes,
                        symbol: isprimary ? inthis.state.mapConstants.PolygonFillSymbolSecondary : inthis.state.mapConstants.getHighlightsymbol(inthis.props.SubModuleKey, grph.geometry.type, grph.attributes, inthis.state.selectedLayerOptions)
                    });
                    inthis.layer.graphics.add(graphic);
                }
            });
            allrecords.push(queryParams[0]);
            let recordsReponse = {}
            recordsReponse[inthis.state.selectedLayerOptions.SubscribedMasterFieldsKey] = allrecords.join(",");
            //Additional parameter, if layer url to be used
            recordsReponse['SELECT_LAYER_URL'] = inthis.state.selectedLayerOptions.Url;
            inthis.props.onSaveorUpdateMapResponse(recordsReponse);
        })

    }

    isSelectionValid = (selectedLayerOptions, selectrecord, inthis) => {
        let isValid = true;
        if (selectedLayerOptions.HasValidation == true) {
            const validatRules = selectedLayerOptions.Validation;
            validatRules.forEach(function (validatRule) {
                if (validatRule != null && validatRule.FieldName != null && validatRule.AllowedValues != null && validatRule.AllowedValues.length > 0) {
                    let fildVal = selectrecord.attributes[validatRule.FieldName]
                    if (validatRule.AllowedValues.indexOf(fildVal) == -1) {
                        alert("Selected route is not allowed. Selected route has " + validatRule.FieldName + " is " + fildVal);
                        if (isValid == true)
                            isValid = false;
                        //inthis.props.snackbarMessage("Selected route is not allowed. Selected route has "+validatRule.FieldName +" is "+fildVal, "E");

                    }
                }
            });

        }
        return isValid;
    }
    getselectedRecordId = (objidkey, objval, selectedField) => {
        if (!objidkey) {
            let objidkey = Object.keys(objval).find(k => k == "OBJECTID")
            if (!objidkey)
                objidkey = Object.keys(objval).find(k => k == "objectId")
        }
        const objectId = objval[objidkey];
        let selectedrecord = objval[selectedField];
        if (selectedrecord && typeof selectedrecord === 'string')
            selectedrecord = selectedrecord.trim()
        return { objidkey: objidkey, selectedrecord: selectedrecord }

    }
    buildColumns(fields) {
        let columns = [];
        fields.forEach(field => {
            if (field.name != "GLOBALID" || field.name != "Join_Count" || field.name != "TARGET_FID" || field.name.indexOf(".Area") !== -1 || field.name.indexOf(".Len") !== -1 || field.name.indexOf(".AREA") !== -1 || field.name.indexOf(".LEN") !== -1) {
                if (field.name === "OBJECTID") {
                    columns.push({
                        field: field.name,
                        label: field.name,
                        sortable: true,
                        hidden: true,
                        show: false
                    })
                } else {
                    columns.push({
                        field: field.name,
                        label: field.alias,
                        sortable: true,
                        show: true
                    })
                }
            }
        });
        return columns;
    }

    selectionChange = (event) => {
        let tempData = this.state.gridData
        tempData.map(item => {
            let currentdataitem = event?.dataItem ? event.dataItem : event.dataItems[0];
            if (this.props.SubModuleKey == 'VIOLATIONS')
                item.selected = false;

            if (item.OBJECTID && item.OBJECTID === currentdataitem.OBJECTID) {
                item.selected = !currentdataitem.selected;
            } else if (item.ObjectId && item.ObjectId === currentdataitem.ObjectId) {
                item.selected = !currentdataitem.selected;
            }
            let searchfieldkey = this.state.selectedLayerOptions ? this.state.selectedLayerOptions.SearchField : "Id";
            if (item[searchfieldkey] && item[searchfieldkey] === currentdataitem[searchfieldkey]){
                item.selected = !currentdataitem.selected;
            }
            return item;
        });
        this.setState({ gridData: tempData, columns: this.columns, showGrid: true }, () => {
            this.onSelectionChange(event, this)
        })
    };

    headerSelectionChange = (event) => {
        let tempData = this.state.gridData
        const checked = event.syntheticEvent.target.checked;
        tempData.forEach((item) => {
            item['selected'] = checked;
        });
        this.setState({ gridData: tempData }, () => {
            this.onSelectionChange(event, this)
        })
    };

    clearGridData = () => {
        if (document.getElementsByClassName("dataTableInfo") && document.getElementsByClassName("dataTableInfo").length > 0)
            document.getElementsByClassName("dataTableInfo")[0].innerHTML = "<b> No Data found </b>"
        this.gridresponse = null
        this.griddata = []
        this.columns = []
        this.setState({ gridData: this.griddata, columns: this.columns, showGrid: false });
    }

    render() {
        let layerkey = this.props.layerkey || -1;
        const { gridData, columns, canEdit, isGridView } = this.state

        return (
            <div id="DatTableParent" style={{ order: this.props.order }}>
                <div id="DatTable" className="map-datatable-container">
                    Data Table  <span class="info" className="dataTableInfo"></span> <span class="info" className="selectdFeatures"></span> <span class="esri-icon-erase" style={{ "display": "none" }}></span>
                    {this.state.selectedLayerOptions && gridData && gridData.length > 0 && this.state.showGrid && <Grid
                        style={{ height: "250px", width: (canEdit && isGridView) ? '600px' : '1000px', overflow: 'auto' }}
                        resizable={true}
                        sortable={true}
                        data={gridData}
                        selectable={{
                            enabled: true,
                            drag: false,
                            cell: false,
                        }}
                        selectedField="selected"
                        onSelectionChange={this.selectionChange}
                        onHeaderSelectionChange={this.headerSelectionChange}
                    >
                        <Column
                            field="selected"
                            width="100px"
                            headerSelectionValue={
                                gridData && gridData.length > 0 && gridData.findIndex(dataItem => dataItem.selected === false) === -1
                            }
                        />
                        {columns && columns.length > 0 && columns.map(each => { if (each.show) return <Column field={each.field} title={each.label} width="130px" /> })}
                    </Grid>}
                </div>
            </div>

        );
    }

}
const CDatTableActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}
CDatTable = connect(null, CDatTableActions)(CDatTable)
export default CDatTable;