import { getEnvVariable } from "../../../modules/environmentalList";
import { GetData } from "./requests";

export const downloadNoticeData = (id) => {
    const pdfurl = getEnvVariable('TemplateAPI') + `/api/Template/PDF/${id}`;
    GetData(pdfurl).then(data => {
        if (data) {
            var contentPDF = data.fileContentPDF;
            var filename = 'Notice_' + data.id + '.pdf';
            handleFileDownload(contentPDF, filename);
        }
    })
}


export const handleFileDownload = (contentPDF, filename) => {
    var url = "data:pdf;base64," + contentPDF;
    GetData(url)
        .then(res => res.blob())
        .then(function (blob) {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        })
}