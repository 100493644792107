import { getEnvVariable } from "../../../modules/environmentalList"

const components = {
    "components":[{
        "type": "formdropdown",
        "key": "imageType",
        "options": {
            "label": "image Type",
            "name": "imagetype",
            "id": "imagetype",
            "width": 6,
            "crud": {
                "read": {
                    "get": {
                        "url": getEnvVariable('Admin')+`/api/imageType/All/Active`
                    }
                }
            },
            "transformProps": {
                "value": "Id",
                "name": "Name"
            }

        }
    },
    {
        "type": "upload",
        "key": "imageupload",
        "options": {
            "label": "Upload",
            "name": "Upload",
            "width": 10,
        }
    }]
}
export default components