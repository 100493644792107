import { getEnvVariable } from "../../../../modules/environmentalList";
 
export const getColor = () => {
    let module = getEnvVariable('ModuleKey')
    switch (module) {
        case 'WRS':
            return 'red' 
       
        default:
            return 'black';
    }
}