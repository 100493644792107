import React from "react"
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveReportIcon from '@material-ui/icons/Description';
import ExportExcel from '@material-ui/icons/SaveAlt';
import FilterListIcon from '@material-ui/icons/ClearAll';
import DraftsIcon from '@material-ui/icons/Drafts';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import CancelIcon from '@material-ui/icons/Cancel';
import Badge from '@material-ui/core/Badge';
import HistoryIcon from '@material-ui/icons/History';
import DoneAll from '@material-ui/icons/DoneAll';
import CheckBox from '@material-ui/icons/CheckBox';
import FilterList from '@material-ui/icons/FilterList';
import VisibilityIcon from '@material-ui/icons/Visibility';
import QuickView from "./quickview";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { getEnvVariable } from "../../../modules/environmentalList";

const fieldTypes = {
  edit: EditIcon,
  add: AddIcon,
  save: SaveIcon,
  close: CloseIcon,
  back: ChevronLeftIcon,
  duplicate: FileCopyIcon,
  download: GetAppIcon,
  refresh: RefreshIcon,
  reports: SaveReportIcon,
  excel: ExportExcel,
  clear: FilterListIcon,
  markread: DraftsIcon,
  markunread: MarkunreadIcon,
  cancel: CancelIcon,
  history: HistoryIcon,
  check_box: CheckBox,
  doneall: DoneAll,
  Filter: FilterList,
  quickview: QuickView,
  delete: DeleteForeverIcon
}

const validate = (name, value, condition) => {
  switch (condition) {
    case 'not in':
      if (!value.includes(name))
        return true
      else return false
    default:
      return false
  }

}

export default function Icons(props) {

  const { icon, type, title, color, name, hidevalue, sharedProps, hideon, isLocalStorage, condition, IsBuildMGR, ...otherProps } = props
  const EachComp = fieldTypes[icon];
  if (sharedProps && hidevalue && (sharedProps[hideon] === hidevalue)) {
    return null
  }
  else if (isLocalStorage && isLocalStorage.value === localStorage.getItem(isLocalStorage.name)) {
    return null
  }
  else if (condition && validate(sharedProps[hideon], hidevalue, condition)) {
    return null
  }
  else if (IsBuildMGR && getEnvVariable('BuildScopeMGR') === true) {
    return null
  }
  else {
    if (name === 'quickview') {
      return <i className={name} {...otherProps}>&nbsp;<Fab size="small" title={title ? title : ''} color={color ? color : "inherit"}> <EachComp {...props} />
      </Fab></i>
    } else {
      return <i className={name + " col m-0 p-0"} {...otherProps}>&nbsp;<Fab size="small" title={title ? title : ''} color={color ? color : "inherit"}> <EachComp />
      </Fab></i>
    }
  }

}