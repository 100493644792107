
import { getEnvVariable } from "../../../modules/environmentalList"

const AddNotice = {
    "type": "popup",
    "key": "AddNotice",
    "name": "AddNotice",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'addOpen'
    },
    sharedValues: ['open'],
    container: {
        values: { save: false, back: false, fullscreen: false, },
        mapValues: {
            header: [
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
            ],
            Notice: [{ key: 'save', name: 'save' },
            { key: 'exitfullscreen', name: 'exitfullscreen' },
            { key: 'fullscreen', name: 'fullscreen' },]
        },
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },
            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'Notice',
                    type: 'div',
                    className: 'col-12 mx-4 pt-1 m-4',
                    sub: {
                        type: 'div',
                        className: 'col-12  mx-4 pt-1 m-4',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Notice',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "Add Notice Type", "materialicon": "article", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'save',
                        hidevalue: false,
                        title: 'Save Notice'
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hidevalue: false,
                        title: 'FullScreen'
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false,
                        title: 'Exit FullScreen'
                    }
                ],
            },
            Notice: {
                "type": "form",
                "key": "Notice",
                "name": "Notice",
                aliases: {
                    save: 'save',
                },
                "routeParams": { replace: 'noticestypes', param: 'Id', routeName: 'mid' },
                sharedValues: ['save', 'back', 'exitfullscreen', 'fullscreen'],
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'Name',
                                    type: 'div',
                                    className: 'col-10',
                                    sub: {
                                        type: 'div',
                                        className: 'col-10',
                                    }
                                },
                            ],
                        },
                        {
                            order: 1,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'Description',
                                    type: 'div',
                                    className: 'col-10',
                                    sub: {
                                        type: 'div',
                                        className: 'col-10'
                                    }
                                },
                            ]
                        },
                        {
                            order: 2,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'SubModuleKey',
                                    type: 'div',
                                    className: 'col-4',
                                    sub: {
                                        type: 'div',
                                        className: 'col-4'
                                    }
                                },
                                {
                                    name: 'SitePermitType',
                                    type: 'div',
                                    className: 'col-4',
                                    sub: {
                                        type: 'div',
                                        className: 'col-4'
                                    }
                                },
                            ]
                        },
                        {
                            order: 3,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'CanEditNoticeDate',
                                    type: 'div',
                                    className: 'col-10',
                                    sub: {
                                        type: 'div',
                                        className: 'col-10'
                                    }
                                },
                            ]
                        },
                        {
                            order: 4,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'CanEditPastNoticeDate',
                                    type: 'div',
                                    className: 'col-3',
                                    sub: {
                                        type: 'div',
                                        className: 'col-3'
                                    }
                                },
                                {
                                    name: 'CanEditCurrentNoticeDate',
                                    type: 'div',
                                    className: 'col-3',
                                    sub: {
                                        type: 'div',
                                        className: 'col-3'
                                    }
                                },
                                {
                                    name: 'CanEditFutureNoticeDate',
                                    type: 'div',
                                    className: 'col-3',
                                    sub: {
                                        type: 'div',
                                        className: 'col-3'
                                    }
                                },
                            ]
                        },
                        {
                            order: 5,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'Status',
                                    type: 'div',
                                    className: 'col-10',
                                    sub: {
                                        type: 'div',
                                        className: 'col-10'
                                    }
                                },
                            ]
                        },
                    ],
                },
                values: {
                    Name: '',
                    Description: '',
                    SubModuleKey: '',
                    Status: 'I',
                    SitePermitType: '',
                    CanEditCurrentNoticeDate: 'N',
                    CanEditFutureNoticeDate: 'N',
                    CanEditNoticeDate: 'N',
                    CanEditPastNoticeDate: 'N',
                    TemplateId: 0,
                   
                },

                "components": {
                    Name: {
                        "type": "textfield",
                        "key": "Name",
                        "label": "Name",
                        "name": "Name",
                    },
                    Description: {
                        "type": "textfield",
                        "key": "Description",
                        "label": "Description",
                        "name": "Description",
                    },
                    SubModuleKey: {
                        "type": "dropdown",
                        "key": "SubModuleKey",
                        "label": "Sub Module",
                        "name": "SubModuleKey",
                        extraProps: {
                            transformProps: {
                                label: 'SubModuleName',
                                value: 'Key'
                            },
                        },
                    },
                    SitePermitType: {
                        "type": "dropdown",
                        "key": "SitePermitType",
                        "label": "Permit Type",
                        "name": "SitePermitType",
                        extraProps: {
                            transformProps: {
                                label: 'Name',
                                value: 'Id'
                            },
                        },
                    },
                    Status: {
                        "type": "toggle",
                        "key": "Status",
                        "label": "Status",
                        "name": "Status",
                        disabled: true,
                        extraProps: {
                            transformProps: {
                                I: false,
                                A: true,
                                'true': 'A',
                                'false': 'I'
                            },
                        }
                    },
                    CanEditCurrentNoticeDate: {
                        "type": "checkbox",
                        "key": "CanEditCurrentNoticeDate",
                        "label": "Current",
                        "name": "CanEditCurrentNoticeDate",
                        extraProps: {
                            transformProps: {
                                N: false,
                                Y: true,
                                'true': 'Y',
                                'false': 'N'
                            },
                        }
                    },
                    CanEditFutureNoticeDate: {
                        "type": "checkbox",
                        "key": "CanEditFutureNoticeDate",
                        "label": "Future",
                        "name": "CanEditFutureNoticeDate",
                        extraProps: {
                            transformProps: {
                                N: false,
                                Y: true,
                                'true': 'Y',
                                'false': 'N'
                            },
                        }
                    },
                    CanEditNoticeDate: {
                        "type": "checkbox",
                        "key": "CanEditNoticeDate",
                        "label": "Can Edit Notice Date?",
                        "name": "CanEditNoticeDate",
                        extraProps: {
                            transformProps: {
                                N: false,
                                Y: true,
                                'true': 'Y',
                                'false': 'N'
                            },
                        }
                    },
                    CanEditPastNoticeDate: {
                        "type": "checkbox",
                        "key": "CanEditPastNoticeDate",
                        "label": "Past",
                        "name": "CanEditPastNoticeDate",
                        extraProps: {
                            transformProps: {
                                N: false,
                                Y: true,
                                'true': 'Y',
                                'false': 'N'
                            },
                        }
                    },                  
                },
                mapActionsToEffects: {
                    'onLoad': {
                        init: {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'businessrules',
                                            "read": [
                                                {
                                                    "url": getEnvVariable('Application') + `/api/BusinessRules/Grid/Admin.NoticeTypes`,
                                                    type: 'get',
                                                }
                                            ],
                                        },
                                        {
                                            type: 'load',
                                            name: 'SubModuleKey',
                                            "read": [
                                                {
                                                    url: getEnvVariable('MasterAdmin') + `/api/module/${getEnvVariable('ModuleKey')}/submodule/All/Active/IsConfigurable`,
                                                    type: 'get',
                                                    saveAs: {
                                                        key: 'items'
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            type: 'validations',
                                            "read": [
                                                {
                                                    "url": getEnvVariable('Application') + `/api/ProjectMessages/RulesByName?name=${getEnvVariable('ModuleKey')}admin.Noticetypes`,
                                                    type: 'get'
                                                }
                                            ],
                                            transformProps: {
                                                removesubfieldname: `${getEnvVariable('ModuleKey')}ADMIN.Noticetypes_`
                                            }
                                        },
                                    ]
                                }
                            }]
                        },
                        'exitfullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'exitfullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'fullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'fullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    },
                    'onChange': {
                        SubModuleKey: {
                            rules: [
                                {
                                    canOverwrite: true,
                                    check: [{
                                        condition: "AND",
                                        rules: [
                                            { name: "SubModuleKey", type: "!=", value: "", rules: [] }]
                                    }],
                                    fields: ["SubModuleKey"],
                                    run: {
                                        type: "check",
                                        valid: [
                                            {
                                                type: 'load',
                                                name: 'SitePermitType',
                                                "read": [
                                                    {
                                                        url: getEnvVariable('AdminConfig') + `/api/SitePermitTypes/SubModuleKey/Active/{SubModuleKey}`,
                                                        type: 'get',
                                                        "matchProps": [{
                                                            name: "SubModuleKey",
                                                            key: "SubModuleKey",
                                                        }],
                                                        saveAs: {
                                                            key: 'items'
                                                        }
                                                    }
                                                ]
                                            },
                                        ],
                                        invalid: []
                                    },
                                    uncheck: false
                                }
                            ],
                            fields: [
                                'SubModuleKey'
                            ]
                        },
                    },
                    'afterSave': {
                        'save': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Admin') + `/api/NoticeTypes/Add`,
                                                        type: 'post',
                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    }
                }
            }
        },

    }
}

export default AddNotice



