  /**        
        *********************************************************
        Currently not used anywhere. 
        *********************************************************
 **/   

import React, { Component, Fragment } from 'react';

const CFormInputHidden = (props) => {
    const { value,name } = props

    return <input type="text" value= {value || ''} name={name} hidden/>
}
export default CFormInputHidden;