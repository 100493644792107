import { getEnvVariable } from '../../../../modules/environmentalList';
import GeneralsettingsAdd from '../generalsettings/generalsettingsadd';
import GeneralsettingsEdit from '../generalsettings/generalsettingsview'
const GeneralsettingsGrid = {
    "title": "Settings",
    "type": "grid",
    "key": "GeneralsettingsGrid",
    "gridkey": 'GeneralsettingsGrid',
    "name": "GeneralsettingsGrid",
    "addRoute": true,
    "routeParams": { replace: 'settings', param: 'Id', routeName: 'TableName' },
    customizedheight: 250,
    "hasreport": true,
    values: {
        addOpen: false,
        editOpen: false,
        editInfo: null,
        load: false,
        allgriddata: null,
        navigateItem: null,
        navigation :null,
        reportOpen: false,
        loadreportfilter: null,
        ReportJsonQueryStr: null,
    },
    sharedValues: ['addOpen', 'editOpen', 'editInfo', 'allgriddata', 'navigateItem', 'navigation', 'loadreportfilter', 'ReportJsonQueryStr', 'reportOpen'],
    aliases: {
        addOpen: 'addOpen',
        editOpen: 'editOpen',
        editInfo: 'editInfo',
        allgriddata: 'allgriddata',
        navigateItem: 'navigateItem',
        navigation: 'navigation',
        reportOpen: 'reportOpen',
        loadreportfilter: 'loadreportfilter',
        ReportJsonQueryStr: 'ReportJsonQueryStr'
    },
    headerProps: {
        title: "Generalsettings",

        actions: [

            {
                type: 'fab',
                icon: 'clear',
                name: 'clear',
                color: 'primary',
                title: 'Clear Filters',
                menucolor: '#22a9b3',
                hideon: 'clear',
                hidevalue: false
            },

            {
                type: 'menu',
                icon: 'excel',
                name: 'excel',
                color: 'primary',
                title: 'Export to Excel',
                menucolor: '#c90685',
                hideon: 'excel',
                hidevalue: false,
                items: [{
                    name: 'Export Current Page',
                    action: 'filtercurrent'
                },
                {
                    name: 'Export All Pages',
                    action: 'filterall'
                }
                ]
            },
            {
                type: 'fab',
                icon: 'reports',
                name: 'reports',
                color: 'primary',
                title: 'Save Report',
                menucolor: '#C85A54',
                hideon: 'reports',
                hidevalue: false
            },
            {
                type: 'fab',
                icon: 'add',
                name: 'add',
                color: 'primary',
                hideon: 'addButton',
                hidevalue: true,
                title: 'Add Settings',
                menucolor: '#36088a'
            },
        ]
    },
    "columns": [
        {
            "title": "Name",
            "field": "Name",
            "show": true,
            "width": 250
        },
        {
            "title": "Value",
            "field": "Value",
            "show": true,
            "width": 200
        },
        {
            "title": "Type",
            "field": "TypeDesc",
            "show": true,
            "width": 150
        },
        {
            "title": "Status",
            "field": "StatusDesc",
            "show": true,
            "width": 150
        },
        {
            "title": "Created By",
            "field": "CreatedByName",
            "show": true,
            "width": 185
        },
        {
            "title": "Created On",
            "field": "CreatedOn",
            "show": true,
            "width": 185,
            filter: "date",
            format: "{0:d}",
            convert: "MM/DD/YYYY hh:mm:ss A"
        },
        {
            "title": "Updated By",
            "field": "UpdatedByName",
            "show": true,
            "width": 185
        },
        {
            "title": "Updated On",
            "field": "UpdatedOn",
            "show": true,
            "width": 185,
            filter: "date",
            format: "{0:d}",
            convert: "MM/DD/YYYY hh:mm:ss A"
        },

    ],
    displayName: 'editInfo',
    allgriddata: 'allgriddata',
    navigateItem: 'navigateItem',
    navigation: 'navigation',
    loadreportfilter: 'loadreportfilter',
    ReportJsonQueryStr: 'ReportJsonQueryStr',
    mapActionsToEffects: {
        load: {
            rules: [{
                run: {
                    valid: [{
                        type: 'grid',
                        "read": [
                            {
                                url: getEnvVariable('Admin')+`/api/AppSettings/Grid?`,
                                type: 'get',
                                saveAs: {
                                    key: 'data'
                                }
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        addOpen: {
            rules: [{
                check: [
                    {
                        condition: 'AND',
                        rules: [
                            {
                                "name": "addOpen",
                                "type": "!=",
                                "value": true,
                                "rules": []
                            }
                        ]
                    }
                ],
                run: {
                    valid: [{
                        type: 'grid',
                        "read": [
                            {
                                url: getEnvVariable('Admin')+`/api/AppSettings/Grid?`,
                                "matchProps": [{
                                    name: "routeid1",
                                    key: "routeid1",
                                }],
                                type: 'get',
                                saveAs: {
                                    key: 'data'
                                }
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        editOpen: {
            rules: [{
                check: [
                    {
                        condition: 'AND',
                        rules: [
                            {
                                "name": "editOpen",
                                "type": "!=",
                                "value": true,
                                "rules": []
                            }
                        ]
                    }
                ],
                run: {
                    valid: [{
                        type: 'grid',
                        "read": [
                            {
                                url: getEnvVariable('Admin')+`/api/AppSettings/Grid?`,
                                type: 'get',
                                saveAs: {
                                    key: 'data'
                                }
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        'add': {
            rules: [
                {
                    run: {
                        valid: [
                            {
                                type: 'value',
                                key: 'addOpen',
                                result: true
                            },

                        ]
                    }
                }
            ]
        },
        'display': {
            rules: [
                {
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'value',
                                key: 'editOpen',
                                result: true
                            },
                            {
                                type: 'resultvalue',
                                name: 'editInfo',
                            },
                            {
                                type: 'resultvalue',
                                name: 'allgriddata',
                            },
                            {
                                type: 'resultvalue',
                                name: 'navigateItem',
                            },
                            {
                                type: 'resultvalue',
                                name: 'navigation',
                            },

                        ]
                    }
                }
            ]
        },
        'report': {
            rules: [
                {
                    run: {
                        valid: [
                            {
                                type: 'value',
                                key: 'reportOpen',
                                result: true
                            },
                            {
                                type: 'resultvalue',
                                name: 'loadreportfilter',
                            },
                            {
                                type: 'resultvalue',
                                name: 'ReportJsonQueryStr',
                            },

                        ]
                    }
                }
            ]
        },
    }



}

const GeneralsettingsReport = {
    type: 'popup',
    key: 'GeneralsettingsReport',
    name: 'GeneralsettingsReport',

    anchor: 'right',
    open: false,
    aliases: {
        open: 'reportOpen'
    },
    sharedValues: ['open', 'loadreportfilter', 'ReportJsonQueryStr'],
    container: {
        values: {
            loadreportfilter: null,
            ReportJsonQueryStr: null
        },
        mapValues: {
            Report: [
                {
                    key: 'ReportJsonQueryStr',
                    name: 'ReportJsonQueryStr'
                },
                {
                    key: 'loadreportfilter',
                    name: 'loadreportfilter'
                },
            ],
        },
        mapActionsToEffects: {},
        layout: {
            group: [
                {
                    order: 0,
                    type: 'div',
                    className: '',
                    components: [
                        {
                            name: 'Report'
                        },
                    ]
                }
            ]

        },
        components: {
            Report: {
                "type": "form",
                "key": "Report",
                "name": "Report",
                aliases: {

                },
                sharedValues: ['ReportJsonQueryStr', 'loadreportfilter'],
                values: {
                    loadreportfilter: null,
                    ReportJsonQueryStr: null
                },
                mapActionsToEffects: {},
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: '',
                            components: [{
                                name: 'ReportJsonQueryStr',
                                type: 'div',

                            },
                            {
                                name: 'loadreportfilter',
                                type: 'div',

                            }],

                        },
                    ],
                },
                "components": {
                    "ReportJsonQueryStr": {
                        "type": "gridreport",
                        "gridkey": 'GeneralsettingsGrid',
                    },
                },
            }
        }
    }
}
const GeneralsettingsContainer = {
    type: 'container',
    custom: true,
    key: 'GeneralsettingsContainer',
    values: {
        addOpen: false,
        editOpen: false,
        editInfo: null,
        allgriddata: null,
        navigateItem: null,
        navigation :null,
        loadreportfilter: null,
        ReportJsonQueryStr: null,
        reportOpen : false,
    },
    mapValues: {
        GeneralsettingsAdd: [
            {
                key: 'addOpen',
                name: 'open'
            }
        ],
        GeneralsettingsEdit: [
            {
                key: 'editOpen',
                name: 'open'
            },
            {
                key: 'editInfo',
                name: 'editInfo'
            },
            {
                key: 'allgriddata',
                name: 'allgriddata'
            },
            {
                key: 'navigateItem',
                name: 'navigateItem'
            },
            {
                key: 'navigation',
                name: 'navigation'
            },
        ],
        GeneralsettingsGrid: [
            {
                key: 'editOpen',
                name: 'editOpen'
            },
            {
                key: 'addOpen',
                name: 'addOpen'
            },
            {
                key: 'reportOpen',
                name: 'reportOpen'
            },
            {
                key: 'ReportJsonQueryStr',
                name: 'ReportJsonQueryStr'
            },
            {
                key: 'loadreportfilter',
                name: 'loadreportfilter'
            },
        ],
        GeneralsettingsReport: [
            {
                key: 'reportOpen',
                name: 'open'
            },
            {
                key: 'loadreportfilter',
                name: 'loadreportfilter'
            },
            {
                key: 'ReportJsonQueryStr',
                name: 'ReportJsonQueryStr'
            },

        ]

    },
    mapActionsToEffects: {
        'addOpen': {
            rules: [
                {
                    check: [
                        {
                            condition: 'AND',
                            rules: [
                                {
                                    "name": "addOpen",
                                    "type": "!=",
                                    "value": true,
                                    "rules": []
                                }
                            ]
                        }
                    ],
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'removelayout',
                                name: 'GeneralsettingsAdd',
                            }

                        ],
                        inValid: [
                            {
                                type: 'addlayout',
                                name: 'GeneralsettingsAdd',
                            }
                        ]
                    }
                }
            ]
        },
        'editOpen': {
            rules: [
                {
                    check: [
                        {
                            condition: 'AND',
                            rules: [
                                {
                                    "name": "editOpen",
                                    "type": "!=",
                                    "value": true,
                                    "rules": []
                                }
                            ]
                        }
                    ],
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'removelayout',
                                name: 'GeneralsettingsEdit',
                            }

                        ],
                        inValid: [
                            {
                                type: 'addlayout',
                                name: 'GeneralsettingsEdit',
                            }
                        ]
                    }
                }
            ]
        },
        'reportOpen': {
            rules: [
                {
                    check: [
                        {
                            condition: 'AND',
                            rules: [
                                {
                                    "name": "reportOpen",
                                    "type": "!=",
                                    "value": true,
                                    "rules": []
                                }
                            ]
                        }
                    ],
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'removelayout',
                                name: 'GeneralsettingsReport',
                            }

                        ],
                        inValid: [
                            {
                                type: 'addlayout',
                                name: 'GeneralsettingsReport',
                            }
                        ]
                    }
                }
            ]
        },
    },
    layout: {
        group: [
            {
                order: 0,
                type: 'div',
                className: 'row',
                components: [
                    {
                        name: 'GeneralsettingsGrid'
                    },
                ]
            }
        ]

    },
    components: {
        GeneralsettingsGrid: GeneralsettingsGrid,
        GeneralsettingsAdd: GeneralsettingsAdd,
        GeneralsettingsEdit: GeneralsettingsEdit,
        GeneralsettingsReport:GeneralsettingsReport
    }
}


export default GeneralsettingsContainer

