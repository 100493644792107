import { getToken, GetFetchProps } from "../header";



export const asyncgetData = async (url) => {
    return await fetch(`${url}`, {
        method: 'GET',
        ...GetFetchProps()
    }).then(res => res.json()).catch(ex => ex);
}

export const getData = (url) => {
    return fetch(`${url}`, {
        method: 'GET',
        ...GetFetchProps()
    }).then(res => res.json()).catch(ex => ex);
}

export const postData = (url) => {
    let record = {}
    return fetch(`${url}`, {
        method: 'POST',
        ...GetFetchProps(),
        body: JSON.stringify(record)
    }).then(res => res.json()).catch(ex => ex);
}

export const postRecordData = (url, record) => {
    return fetch(`${url}`, {
        method: 'POST',
        ...GetFetchProps(),
        body: JSON.stringify(record)
    }).then(res => {
        if (res.ok) { return res.json() }
        else {
            throw res.json();
        }
    }).catch(ex => ex);
}


export const putRecordData = (url, record) => {
    return fetch(`${url}`, {
        method: 'PUT',
        ...GetFetchProps(),
        body: JSON.stringify(record)
    }).then(res => res.json()).catch(ex => ex);
}

export const deleteData = (url) => {
    return fetch(`${url}`, {
        method: 'DELETE',
        ...GetFetchProps(),
    }).then(res => res.json()).catch(ex => ex);
}