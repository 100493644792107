import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { loadModules } from 'esri-loader';
import { QueryTask, CenterAndZoom } from '../util/queryTask';
import { none } from 'ramda';
import { snackbarMessage, Confirmation } from '../../../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
class CLocationZoom extends Component {
    constructor(props) {
        super(props); 
        this.state =  { 
            view: this.props.view
        } 
        this.startup(
            this.props
        ); 
    }   
     
    startup = (config) => {
        loadModules([
            "esri/core/watchUtils"            
        ]).then(([watchUtils]) => {
            this.homecontainer = ReactDOM.findDOMNode(this);
           // var elem = document.getElementsByClassName('locationzoom-box')            
            let content = document.getElementById('location-zoom');
            let divElement = document.createElement('div');
            divElement.onclick= () => this.zoomTo(config)
            divElement.innerHTML = content.innerHTML;            
            config.view.ui.add(divElement, "top-left");
        });
    } 

    zoomTo(config){
        let layer = config.view.map.layers.find(function (layer) {
            return layer.title === "Sketch Layer";
        });
        if(layer && layer.graphics && layer.graphics.items.length > 0){   
            console.log('zooming to location')         
            CenterAndZoom(config.view, layer.graphics.items[0].geometry, 12);
        }else{
            this.props.snackbarMessage('No selected information found to zoom into the location.', 'info');
        }
    }

  render() {
    return (
        <div class="location-zoom">
            <div id="location-zoom" style={{'display':'none'}}>
                <div class="locationzoom-box esri-custom-location esri-widget--button esri-widget" role="button" tabindex="0" aria-label="Go to location" title="Go to location">
                    <span aria-hidden="true" class="esri-icon esri-icon-locate"></span>
                    <span class="esri-icon-font-fallback-text">Locate</span>
                </div>
            </div>            
            {/* <div id="location-zoom" class="esri-home esri-widget--button esri-widget esri-component" role="button" tabindex="0" aria-label="Go to location" title="Go to location">
                <span aria-hidden="true" class="esri-icon esri-icon-home"></span>
                <span class="esri-icon-font-fallback-text">Home</span>
            </div> */}
        </div>        
    );
  }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage, Confirmation }, dispatch);
}

CLocationZoom = connect(null, mapActions)(CLocationZoom)

export default CLocationZoom;