import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { loadModules } from 'esri-loader';
import { QueryTask, QueryTaskBufferWithGeom, CenterAndZoom, EsriRequest } from '../util/queryTask';
import { none } from 'ramda';
import { snackbarMessage, Confirmation } from '../../../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { xyToLngLat, getLatLong, GetArea, getXYFromGeom } from '../util/mercatorUtils'

import { SaveMultipleLocations, GetRecordValuesFromPrimaryLocation, UpdateApplicationGISFields, UpdateMultipleLocations } from '../eps/multipleLocationServices';

class CLocationCompare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: this.props.view
        }
        this.startup(
            this.props
        );
    }

    startup = (config) => {
        loadModules([
            "esri/core/watchUtils"
        ]).then(([watchUtils]) => {
            this.homecontainer = ReactDOM.findDOMNode(this);
            let content = document.getElementById('location-compare');
            let divElement = document.createElement('div');
            divElement.onclick = () => this.compareGISData(config)
            divElement.innerHTML = content.innerHTML;
            config.view.ui.add(divElement, "top-left");
            this.props.widgetRenderCallBack("MultiLocationSelector");
        });
    }

    compareGISData(config) {
        this.props.snackbarMessage('Please wait fetching information from GIS layer', 'info');
        this.GetCurrentGISInformation();
        //this.props.Confirmation((evt) => this.onConfirm(evt, this), (evt) =>this.onCancel(evt, this), 'Found difference in location gis. Update to new information?', true);
    }

    onConfirm(event, inthis) {
        inthis.props.Confirmation(null, null, null, false);
        setTimeout(function () { inthis.props.snackbarMessage('Primary information updated successfully !!', 'success'); }, 2000);
    }

    onCancel(event, inthis) {
        inthis.props.Confirmation(null, null, null, false);
    }

    GetCurrentGISInformation = () => {
        if (this.props.options && this.props.options.MultipleLocationData) {
            let primaryloc = this.props.options.MultipleLocationData.filter(a => a.IsPrimary == 'Y')

            if (primaryloc && primaryloc.length > 0) {
                let x = primaryloc[0].X;
                let y = primaryloc[0].Y;
                let queryTaskConfigMultiLocation = {
                    url: `https://maps.kytc.ky.gov/arcgis/rest/services/MeasuredRoute/MapServer/exts/RouteMetrics/GetMultipleRouteInfo?X=${x}&Y=${y}&SearchRadius=2`
                }
                EsriRequest(queryTaskConfigMultiLocation, null, 102763).then(
                    response => {
                        if (response && response.RouteInfos && response.RouteInfos.length > 0) {
                            const routeUNQ = response.RouteInfos.filter(a => a.GovLevelCode == '01');
                            let propsDiffer = this.compareObjects(primaryloc[0], routeUNQ[0]);
                            let isEqual = propsDiffer.length === 0;
                            if (isEqual)
                                this.props.snackbarMessage('No changes has been found after comparing', 'info');
                            else {
                                this.props.snackbarMessage('Changes found, update if required.', 'info');
                                this.updateLocationInformaiton(primaryloc[0], routeUNQ[0], propsDiffer);
                            }
                        }
                    }).catch(ex => {
                        console.error(ex)
                    })
            }
        }
    }

    updateLocationInformaiton(primary, gisrow, propsDiffer) {
        this.props.Confirmation(() => this.onConfirmation(primary, gisrow, propsDiffer), this.onConfirmationClose, propsDiffer.join() + ' are differing. Would you like to update?', true);
    }

    onConfirmation = (primary, gisrow, propsDiffer) => {
        propsDiffer.forEach(element => {
            primary[element] = gisrow[element]
        });
        let siteid = this.props.options['SITE_PERMIT_TYPE_ID'];
        let recordid = primary['RefId'];
        if (siteid && recordid) {
            UpdateMultipleLocations(primary['Id'], recordid, primary).then(res => {
                console.log(res)
                //this.props.snackbarMessage('Primary location information update sucessfully', 'success');
            })
            let recorddetails = GetRecordValuesFromPrimaryLocation(primary);
            UpdateApplicationGISFields(siteid, recordid, recorddetails).then(appres => {
                console.log(appres)
                //this.props.snackbarMessage('Application location information update sucessfully', 'success');
            })
        }
        this.props.snackbarMessage('Please wait updating', 'info');
        this.props.Confirmation(null, null, null, false);
    }


    onConfirmationClose = (evt) => {
        this.props.Confirmation(null, null, null, false);
    }

    compareObjects = (o1, o2) => {
        let propsDiffer = []
        for (var p in o1) {
            if (o1.hasOwnProperty(p) && o2.hasOwnProperty(p)) {
                if (o1[p] !== o2[p]) {
                    propsDiffer.push(p);
                }
            }
        }
        return propsDiffer;
        // diff = propsDiffer.join();
        // if(propsDiffer.length > 0)
        //             return false;

        // return true;
    };




    render() {
        return (
            <div class="location-compare" style={{ order: this.props.order }}>
                <div id="location-compare" style={{ 'display': 'none' }}>
                    <div class="locationcompare-box esri-custom-location esri-widget--button esri-widget" role="button" tabindex="0" aria-label="Compare and update primary location with GIS" title="Compare and update primary location with GIS">
                        <span aria-hidden="true" class="esri-icon esri-icon-refresh"></span>
                        <span class="esri-icon-font-fallback-text">Locate</span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage, Confirmation }, dispatch);
}
CLocationCompare = connect(null, mapActions)(CLocationCompare)
export default CLocationCompare;