import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import DefaultSettings from '../../core/controls/components/settings';
import { createRequest, requestApi as requests } from '../../core/controls/helpers/rest';
import { requestApi } from '../../core/modules/form/utilities';
import { globalvalidate as GlobalValidate } from '../../core/modules/form/validations';
import { getEnvVariable } from '../environmentalList';
import AccountType from './AccountType';
import CompanyDetails from './CompanyDetails';
import ModuleSelection from './ModuleSelection';
import SignUp from './SignUp';
import Success from './success';
import UserDetails from './UserDetails';

const styles = theme => ({
  root: {
    flexGrow: 1,

  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
  },

});
export class OwnerRegistration extends Component {

  state = {
    submoduleItemsToParent: null,
    step: 1,
    Name: '',
    IsElectronicCommunicationAllowed: 'N',
    MaxUsers: 1000,
    AddressType: 'O',
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    State: 'OR',
    Zip: '',
    Phone: '',
    Fax: '',
    Title: '',
    SecondaryPhone: '',
    HomePhone: '',
    Country: 'USA',
    UserPhone: '',
    Email: '',
    UserName: '',
    FirstName: '',
    LastName: '',
    CompanyType: 'C',
    Modules: [],
    SubModuleKey: [],
    ModuleKeys: [],
    EPSCheck: false,
    AgreeTerms: 'N',
    ReCaptcha: 'N',
    StateList: [],
    //ContactType: '',
    CompanyValidations: [],
    ContactValidations: [],
    CompanyModules: [],
    HasValidationError: false,
    Officephonecheck: 'N',
    errors: {
      Name: {
        error: false,
        errortext: "",
        isRequired: true
      },
      AddressLine1: {
        error: false,
        errortext: "",
        isRequired: true
      },
      AddressLine2: {
        error: false,
        errortext: ""
      },
      City: {
        error: false,
        errortext: "",
        isRequired: true
      },
      State: {
        error: false,
        errortext: "",
        isRequired: true
      },
      Zip: {
        error: false,
        errortext: "",
        isRequired: true
      },
      Phone: {
        error: false,
        errortext: "",
        isRequired: true
      },
      Fax: {
        error: false,
        errortext: "",
        isRequired: true
      },
      Title: {
        error: false,
        errortext: "",
        isRequired: true
      },
      UserPhone: {
        error: false,
        errortext: "",
        isRequired: true
      },
      SecondaryPhone: {
        error: false,
        errortext: "",
        isRequired: true
      },
      HomePhone: {
        error: false,
        errortext: "",
        isRequired: true
      },
      Country: {
        error: false,
        errortext: "",
      },
      Email: {
        error: false,
        errortext: "",
        isRequired: true
      },
      UserName: {
        error: false,
        errortext: "",
        isRequired: true
      },
      FirstName: {
        error: false,
        errortext: "",
        isRequired: true
      },
      LastName: {
        error: false,
        errortext: "",
        isRequired: true
      }
    },
    ownerRegErrors: {
      Name: {
        error: false,
        errortext: "",
        isRequired: true
      },
      AddressLine1: {
        error: false,
        errortext: "",
        isRequired: true
      },
      AddressLine2: {
        error: false,
        errortext: ""
      },
      City: {
        error: false,
        errortext: "",
        isRequired: true
      },
      State: {
        error: false,
        errortext: "",
        isRequired: true
      },
      Zip: {
        error: false,
        errortext: "",
        isRequired: true
      },
      Phone: {
        error: false,
        errortext: "",
        isRequired: true
      },
      SecondaryPhone: {
        error: false,
        errortext: "",
      },

      HomePhone: {
        error: false,
        errortext: "",
      },
      Country: {
        error: false,
        errortext: "",
      },
      Fax: {
        error: false,
        errortext: "",
        isRequired: false
      }
    },
    userDetailsErrors: {
      Title: {
        error: false,
        errortext: "",
        isRequired: false
      },
      UserPhone: {
        error: false,
        errortext: "",
        isRequired: true
      },
      Email: {
        error: false,
        errortext: "",
        isRequired: true
      },
      FirstName: {
        error: false,
        errortext: "",
        isRequired: true
      },
      LastName: {
        error: false,
        errortext: "",
        isRequired: true
      },
    },
    signUpError: {
      UserName: {
        error: false,
        errortext: "",
        isRequired: true
      }
    }

  };
  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  closestep = () => {
    this.setState({
      onclose: true
    });
  }
  // Proceed to next step
  regNextStepValidation = (type) => {
    const { step, CompanyType } = this.state;
    let error = false;
    if (step === 2 || step === 4) {
      error = this.validateRegistrationData(type);
    }
    else this.setState({
      step: (step === 2 && CompanyType === 'T') ? 4 : (step + 1)
    });
  };

  // Proceed to next step
  regNextStepUserValidation = (type) => {
    const { step } = this.state;
    var error = false;
    if (step === 4 || step === 3) {
      error = this.validateRegistrationData(type);
    }
  };

  validateRegistrationData(type) {
    const { step, CompanyType } = this.state;
    var errors = (type === "owner") ? this.state.ownerRegErrors : this.state.userDetailsErrors;
    var customErrors = (type === "owner") ? this.state.userDetailsErrors : this.state.ownerRegErrors;
    let changedFieldName = []
    let requiredFieldErrors = {}
    var hasError = false;
    // const { step } = this.state;
    //Iterate over object and check for any errors
    Object.entries(errors).map(obj => {
      const key = obj[0];
      const nestedObj = obj[1];
      var isRequired = nestedObj['isRequired'];
      var formData = this.state;
      let value = formData[key];
      if (typeof value === 'string' && value != "" && value.charAt(0) === " ") {
        this.setState({ [key]: value.trim() });
      }
      if (isRequired) {
        if (value)
          value = value.trim();
        if ((value === null) || (value === undefined) || (value === '')) {
          changedFieldName.push({ [key]: { error: true, errortext: 'Field is Required', isRequired: true } })
          requiredFieldErrors[key] = { error: true, errortext: 'Field is Required', isRequired: true };
          hasError = true;
        } else {
          requiredFieldErrors[key] = { error: false, errortext: '', isRequired: true };
        }
      }
      let validationerror = []
      if (value !== "") {
        var errorJson = {}
        errorJson = this.getDBValidationMessages(key);
        if (errorJson.length > 0) {
          errorJson.map(err => {
            validationerror[key] = [err]
          })
          const error = GlobalValidate(validationerror, { [key]: value })
          var isError = error[key]['error'];
          if (isError) {
            changedFieldName.push({ [key]: { error: true, errortext: error[key]['errortext'], isRequired: false } })
            requiredFieldErrors[key] = { error: true, errortext: error[key]['errortext'], isRequired: false };
            hasError = true;
          }
        }
      }
    });
    if (hasError) {
      this.setState({
        errors: {
          ...requiredFieldErrors,
          ...customErrors
        }
      });
    } else {
      const { step, CompanyType } = this.state;
      //Validating the uniqueness of user email and proceed
      if (type === 'user') {
        var value = this.state.Email;
        var externalUserEmail = {
          "post": {
            "url": getEnvVariable('MasterAdmin') + `/api/CompanyExternal/ExternalUserUniqueEmail?email=${value}&type=${this.state.CompanyType}`
          }
        };
        requestApi(externalUserEmail, '', null, null, true, true).then(results => {
          if (results) {
            this.setState({
              errors: {
                ...this.state.errors,
                Email: { error: false, errortext: '', isRequired: true }
              },
              HasValidationError: false,
              step: (step === 2 && CompanyType === 'T') ? 4 : (step + 1)
            })
          }
        }).catch(error => {
          error.then(err => {
            this.setState({
              errors: {
                ...this.state.errors,
                Email: { error: true, errortext: `Email address already exists. Please provide another email address.`, isRequired: true }
              },
              HasValidationError: true
            })
          })

        })
      }
      else {
        this.setState({
          step: (step === 2 && CompanyType === 'T') ? 3 : (step + 1)
        });
      }
      // else if (type == 'owner') {
      //   let ownerErrors = Object.keys(this.state.errors).some(each => this.state.errors[each].error === true) || false;
      //   if (!ownerErrors) {
      //     var value = this.state.Name;
      //     var externalName = {
      //       "get": {
      //         "url": getEnvVariable('MasterAdmin') + `/api/CompanyInternal/UniqueCompanyName/${getEnvVariable('ModuleKey')}?companiesName=${value}&companiesType=${this.state.CompanyType}`
      //       }
      //     };
      //     requestApi(externalName, '', null, null, true).then(results => {
      //       if (results && results.isValid) {
      //         this.setState({
      //           errors: {
      //             ...this.state.errors,
      //             Name: { error: false, errortext: '', isRequired: true }
      //           },
      //           HasValidationError: false,
      //           step: (step === 2 && CompanyType === 'T') ? 4 : (step + 1)
      //         })
      //       }
      //       else {
      //         this.setState({
      //           errors: {
      //             ...this.state.errors,
      //             Name: { error: true, errortext: `${CompanyType === 'T' ? 'Consultant ' : 'Applicant '} Company Name already exists. Please provide another company name`, isRequired: true }
      //           },
      //           HasValidationError: true
      //         })
      //       }
      //     }).catch(error => {
      //       error.then(err => {
      //         this.setState({
      //           errors: {
      //             ...this.state.errors,
      //             Name: { error: true, errortext: `${CompanyType === 'T' ? 'Consultant ' : 'Applicant '} Company Name already exists. Please provide another company name`, isRequired: true }
      //           },
      //           HasValidationError: true
      //         })
      //       })
      //     })
      //   }

      // }
    }
  }
  // Go back to prev step
  prevStep = () => {
    const { step, CompanyType } = this.state;
    this.setState({
      step: (step === 4 && CompanyType === 'T') ? (step - 2) : step - 1
    });
  };


  singuperrorupdate = (data) => {
    this.setState({ ...data });
  }

  // Handle fields change
  handleChange = input => e => {
    if (input === "UserName") {
      e.target.value = e.target.value.replace(/\s/g, '')
      this.setState({ [input]: e.target.value.trim() });
    }
    else {
      if (e.target.value.length - 1 > 0)
        this.setState({ [input]: e.target.value.trim() });
      else
        this.setState({ [input]: e.target.value.trim() });
    }
    var errorJson = {}
    errorJson = { [input]: this.getDBValidationMessages(input) };
    const error = GlobalValidate(errorJson, { [input]: e.target.value });
    var isRequiredVal = (this.state.errors[input] && this.state.errors[input].isRequired) ? this.state.errors[input].isRequired : false;
    if (Object.keys(error).length > 0) {
      var hasError = error[input]['error'];
      if (hasError && hasError === true) {
        this.setState({
          errors: {
            ...this.state.errors,
            [input]: { error: error[input]['error'], errortext: error[input]['errortext'], isRequired: isRequiredVal }
          },
          HasValidationError: true
        })

      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            [input]: { error: false, errortext: '', isRequired: isRequiredVal }
          },
          HasValidationError: true
        })

      }
    } else {

      this.setState({
        errors: {
          ...this.state.errors,
          [input]: { error: false, errortext: '', isRequired: isRequiredVal }
        },
        HasValidationError: false
      })
    }
  };

  handleCheckChange = (input, e) => {
    if (input === "UserName") {
      e.target.value = e.target.value.replace(/\s/g, '')
      this.setState({ [input]: e.target.value.trim() });
    }
    else if (input === "Officephonecheck") {
      if (e && e.target && e.target.checked) {
        this.setState({ [input]: 'Y' });
      }
      else {
        this.setState({ [input]: 'N' });
      }
    }
    else {
      if (e.target.value.length - 1 > 0)
        this.setState({ [input]: e.target.value });
      else
        this.setState({ [input]: e.target.value.trim() });
    }

    var errorJson = {}
    errorJson = { [input]: this.getDBValidationMessages(input) };
    const error = GlobalValidate(errorJson, { [input]: e && e.target.value || null });
    var isRequiredVal = (this.state.errors[input] && this.state.errors[input].isRequired) ? this.state.errors[input].isRequired : false;
    if (Object.keys(error).length > 0) {
      var hasError = error[input]['error'];
      if (hasError && hasError === true) {
        this.setState({
          errors: {
            ...this.state.errors,
            [input]: { error: error[input]['error'], errortext: error[input]['errortext'], isRequired: isRequiredVal }
          },
          HasValidationError: true
        })
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            [input]: { error: false, errortext: '', isRequired: isRequiredVal }
          },
          HasValidationError: true
        })
      }
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          [input]: { error: false, errortext: '', isRequired: isRequiredVal }
        },
        HasValidationError: false
      })
    }
  };

  validateUnique(type, value) {
    if (type === 'Email') {
      var externalUserEmail = {
        "get": {
          "url": getEnvVariable('MasterAdmin') + `/api/CompanyExternal/ExternalUserUniqueEmail?email=${value}&type=${this.state.CompanyType}`
        }
      };
      if (value) {
        requestApi(externalUserEmail, '').then(results => {
          console.log(results);
        }).catch(error => {

          error.then(err => {

            this.setState({
              errors: {
                ...this.state.errors,
                [type]: { error: true, errortext: `Email address already exists. Please provide another email address.`, isRequired: true }
              },
              HasValidationError: true
            })

          })

        }).catch(error => {
          this.setState({
            errors: {
              ...this.state.errors,
              [type]: { error: false, errortext: '' }
            },
            HasValidationError: false
          })
        })
      }
    } else if (type === "Username") { }
  }

  getDBValidationMessages(key) {
    var companyValidations = this.state.CompanyValidations;
    var contactValidations = this.state.ContactValidations;
    var allValidations = companyValidations.concat(contactValidations);
    var keyinuppercase = key.toUpperCase();
    var validationSearchKey = "_" + keyinuppercase;
    var matchingKeys = allValidations.filter(f => f['Key'].includes(validationSearchKey));;
    return matchingKeys;
  }

  // Handle fields change
  moduleChecked = (e) => {
    let result = e;
    this.setState({ CompanyModules: result.CompanyModules })
  }

  handleChecked = input => e => {
    if (e.target.checked) {
      this.setState({ [input]: 'Y', ModuleKeys: [`${getEnvVariable('ModuleKey')}`] });
    }
    else {
      this.setState({ [input]: 'N', ModuleKeys: [`${getEnvVariable('ModuleKey')}`] });
    }
  };


  componentDidMount() {
    this.loadDropdownValues();
  }

  loadDropdownValues() {
    const dropLists = [
      {
        url: getEnvVariable('MasterAdmin') + `/api/CompanyExternal/GetDomainValuesByKey?domainkey=US_STATES`,
        type: 'get'
      },
      {
        url: getEnvVariable('MasterAdmin') + `/api/CompanyExternal/GetDomainValuesByKey?domainkey=CONTACT_TYPE`,
        type: 'get'
      },
      {
        url: getEnvVariable('MasterAdmin') + `/api/CompanyExternal/GetRulesByName?name=master.companies,master.users`,
        type: 'get'
      },
      {
        url: getEnvVariable('MasterAdmin') + `/api/CompanyExternal/GetRulesByName?name=master.contacts`,
        type: 'get'
      },
    ]
    Promise.all(dropLists.map(each => requests(createRequest(each, {}, null)).catch(error => { return error; }))).then(response => {
      const [status, contacts, comvalidations, convaliations] = response
      if (Array.isArray(status)) {
        this.setState({ StateList: status, ContactTypeList: contacts, CompanyValidations: comvalidations, ContactValidations: convaliations });
      }
    })
  }

  errorSubmit = (error) => {
    this.setState({
      errors: {
        ...this.state.errors,
        ['Name']: { error: true, errortext: error.Name[0].toString(), isRequired: true }
      },
      HasValidationError: true
    })
  }


  handlerecaptchachange = (value) => {
    if (value && value.length > 0) {
      this.setState({ ReCaptcha: 'Y' });
    }
  }

  handleStepFunc = (step) => {
    const { Name, IsElectronicCommunicationAllowed, MaxUsers, AddressType, AddressLine1,
      AddressLine2,
      City,
      State,
      Zip,
      Phone,
      SecondaryPhone,
      HomePhone,
      Country,
      Fax,
      Title,
      UserPhone,
      Email,
      UserName,
      FirstName,
      LastName,
      CompanyType,
      Modules,
      SubModuleKey,
      ModuleKeys,
      AgreeTerms,
      ReCaptcha,
      EPSCheck,
      StateList,
      CompanyValidations,
      ContactValidations,
      HasValidationError,
      CompanyModules,
      Officephonecheck,
    } = this.state;
    const values = {
      Name, IsElectronicCommunicationAllowed, MaxUsers, AddressType, AddressLine1,
      AddressLine2,
      City,
      State,
      Zip,
      Phone,
      SecondaryPhone,
      HomePhone,
      Country,
      Fax,
      Title,
      UserPhone,
      Email,
      UserName,
      FirstName,
      LastName,
      CompanyType,
      Modules, SubModuleKey, ModuleKeys, AgreeTerms, EPSCheck, ReCaptcha,
      StateList,
      CompanyValidations,
      ContactValidations,
      HasValidationError,
      CompanyModules,
      Officephonecheck,
    };
    switch (step) {
      case 1:
        return (
          <AccountType
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            onClose={this.props.onClose}
          />

        );
      case 2:
        return (
          <CompanyDetails
            nextStep={() => this.regNextStepValidation('owner')}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            handleCheckChange={(name, e) => this.handleCheckChange(name, e)}
            values={values}
            errors={this.state.errors}
            onClose={this.props.onClose}
            moduleChecked={this.moduleChecked}
          />
        );
      // case 3:
      //   return (
      //     <ModuleSelection
      //       nextStep={this.nextStep}
      //       handleChange={this.handleChange}
      //       moduleChecked={this.moduleChecked}
      //       values={values}
      //       prevStep={this.prevStep}
      //       onClose={this.props.onClose}
      //       submoduleItemsToParent={this.state.submoduleItemsToParent || null}
      //       setSubmoduleItemsToParent={(items) => { this.setState({ submoduleItemsToParent: items }) }}
      //     />
      //   );
      case 3:
        return (
          <UserDetails
            nextStep={() => this.regNextStepUserValidation('user')}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            handleChecked={this.handleChecked}
            values={values}
            errors={this.state.errors}
            onClose={this.props.onClose}
            handleCheckChange={this.handleCheckChange}
          />
        );
      case 4:
        return (
          <SignUp
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            handleChecked={this.handleChecked}
            values={values}
            errors={this.state.errors}
            signuperrors={this.state.signUpError}
            singuperrorupdate={this.singuperrorupdate}
            onClose={this.props.onClose}
            errorSubmit={this.errorSubmit}
            handlerecaptchachange={this.handlerecaptchachange}
          />
        );
      case 5:
        return (
          <Success
            onClose={this.props.onClose}
          />
        )
      default: return <></>
    }
  }

  render() {
    const { step } = this.state;
    const { classes } = this.props
    return <div className={classes.root + ' eps-content-center'} >
      <Paper className={classes.paper} elevation={0}>
        <Grid container spacing={2}>
          {this.handleStepFunc(step)}
        </Grid>
      </Paper>
    </div>
  }
}

export default withStyles(styles)(OwnerRegistration);
