import { loadModules } from 'esri-loader';
import { QueryTask, CenterAndZoom, setCenterforMapviewwithFlag, setCenterforMapviewNoFlag,QueryTaskExecuteZoomAddLayerToResponse } from '../util/queryTask';
import { GeometryEngineTask } from '../util/geometryEngineTask';

export function TaxParcelTask(view, config, queryexpression, mapConstants, zoom, isReset = false) {
    return new Promise((resolve, reject) => {
        if (!loadModules) {
            reject('TaxParcelTask: ArcGIS API is not loaded');
            return;
        }
        const TaxParcelzoom = zoom;
        if (config && config.TaxParcels) {
            config.TaxParcels.queryExpression = queryexpression

            if (config.TaxParcels.queryExpression == null || config.TaxParcels.queryExpression == "" || config.TaxParcels.queryExpression == "1!=1") {
                resolve({ "Message": "Invalid Expression" });
            } else {
                if (queryexpression != null && queryexpression != "") {
                    // Specific to AMES project.
                    //Showing all the taxparcels on the map. Checking the condition for all here
                    if (queryexpression === 'all') {

                        if (queryexpression != undefined && queryexpression != null && queryexpression != "") {
                            //let parcelIdarra = queryexpression.split(",");
                            let geometries = [];

                            let querycondition = "1=1";
                            config.TaxParcels.queryExpression = querycondition;
                            let layer = view.map.layers.find(function (layer) {
                                return layer.title === "Tax Parcel Graphics Layer";
                            });

                            QueryTask(config.TaxParcels, querycondition, 4326).then(
                                response => {
                                    if (response && response.features) {
                                        loadModules([
                                            "esri/Graphic",
                                            "esri/layers/GraphicsLayer",
                                            "esri/layers/FeatureLayer"
                                        ]).then(([Graphic, GraphicsLayer, FeatureLayer]) => {

                                            if (!layer) {
                                                layer = new GraphicsLayer({
                                                    "title": "Tax Parcel Graphics Layer", listMode: 'hide'
                                                });
                                            }
                                            if (isReset)
                                                //layer.graphics.removeAll();

                                                response.features.forEach(function (item) {
                                                    //console.log(parcelIdarra[i],"----------------------parcel Id to test ----------");
                                                    if (item && item.geometry && item.geometry.centroid) {
                                                        let taxparcelcenter = item.geometry.centroid;
                                                        setCenterforMapviewNoFlag(view, parseFloat(taxparcelcenter.x), parseFloat(taxparcelcenter.y), 4326);
                                                    }
                                                    geometries.push(item.geometry);
                                                    let g = new Graphic({
                                                        geometry: item.geometry,
                                                        attributes: item.attributes,
                                                        symbol: mapConstants.PolygonNoFillSymbol
                                                    });
                                                    layer.graphics.add(g);

                                                });

                                            if (geometries.length > 0) {
                                                if (geometries.length > 1) {
                                                    GeometryEngineTask({ "geometries": geometries, "type": "union" }).then(
                                                        GeomEngineresponse => {
                                                            if (GeomEngineresponse && GeomEngineresponse.centroid)
                                                                CenterAndZoom(view, GeomEngineresponse.centroid, TaxParcelzoom);
                                                        },
                                                        GeomEngineerror => {
                                                            //  console.log(GeomEngineerror);
                                                        });
                                                } else if (geometries.length === 1) {
                                                    CenterAndZoom(view, geometries[0], TaxParcelzoom);
                                                }
                                            }

                                            view.map.layers.add(layer, (config.TaxParcels.LayerIndex || 0));
                                            resolve({ "Message": geometries.length + " Tax Parcels Added" });
                                        });
                                    }
                                    else {
                                        resolve({ "Message": "Tax Parcels not found" });
                                    }
                                },
                                error => {
                                    console.log(error);
                                    reject({ "error": error });
                                }
                            );

                        }

                    } else {
                        if (queryexpression != undefined && queryexpression != null && queryexpression != "") {
                            let parcelIdarra = queryexpression.split(",");
                            let geometries = [];
                            for (let i = 0; i <= parcelIdarra.length; i++) {

                                if (parcelIdarra[i] != undefined && parcelIdarra[i] != "") {
                                    let taxparcelTosend = parcelIdarra[i].split('').join('%');
                                    let querycondition = "MapTaxlot like('" + taxparcelTosend + "')";
                                    config.TaxParcels.queryExpression = querycondition;
                                    let layer = view.map.layers.find(function (layer) {
                                        return layer.title === "Tax Parcel Graphics Layer";
                                    });

                                    QueryTask(config.TaxParcels, querycondition, 4326).then(
                                        response => {
                                            if (response && response.features) {
                                                loadModules([
                                                    "esri/Graphic",
                                                    "esri/layers/GraphicsLayer"
                                                ]).then(([Graphic, GraphicsLayer]) => {

                                                    if (!layer) {
                                                        layer = new GraphicsLayer({
                                                            "title": "Tax Parcel Graphics Layer", listMode: 'hide'
                                                        });
                                                    }
                                                    if (isReset)
                                                        //layer.graphics.removeAll();

                                                        response.features.forEach(function (item) {
                                                            if (item && item.attributes.PIN === parcelIdarra[i]) {
                                                                if (item && item.geometry && item.geometry.centroid) {
                                                                    let taxparcelcenter = item.geometry.centroid;
                                                                    setCenterforMapviewwithFlag(view, parseFloat(taxparcelcenter.x), parseFloat(taxparcelcenter.y),
                                                                        4326, mapConstants.greenFlagsymbol);

                                                                }
                                                                geometries.push(item.geometry);
                                                                let g = new Graphic({
                                                                    geometry: item.geometry,
                                                                    attributes: item.attributes,
                                                                    symbol: mapConstants.PolygonFillSymbol
                                                                });
                                                                layer.graphics.add(g);

                                                            }

                                                        });
                                                    if (geometries.length > 0) {
                                                        if (geometries.length > 1) {
                                                            GeometryEngineTask({ "geometries": geometries, "type": "union" }).then(
                                                                GeomEngineresponse => {
                                                                    if (GeomEngineresponse && GeomEngineresponse.centroid)
                                                                        CenterAndZoom(view, GeomEngineresponse.centroid, TaxParcelzoom);
                                                                },
                                                                GeomEngineerror => {
                                                                    //    console.log(GeomEngineerror);
                                                                });
                                                        } else if (geometries.length === 1) {
                                                            CenterAndZoom(view, geometries[0], TaxParcelzoom);
                                                        }
                                                    }
                                                    view.map.layers.add(layer, (config.TaxParcels.LayerIndex || 0));
                                                    resolve({ "Message": geometries.length + " Tax Parcels Added" });
                                                });
                                            }
                                            else {
                                                resolve({ "Message": "Tax Parcels not found" });
                                            }
                                        },
                                        error => {
                                            console.log(error);
                                            reject({ "error": error });
                                        }
                                    );
                                }
                            }

                        }
                    }



                }

            }
        }
    });
};



export function TaxParcelTaskFeatureLayer(view, config, queryexpression, mapConstants, zoom, showTaxLayer = false, seltaxlotnumber = null) {
    return new Promise((resolve, reject) => {
        if (!loadModules) {
            reject('TaxParcelTask: ArcGIS API is not loaded');
            return;
        }
        const TaxParcelzoom = zoom;

        if (config && config.TaxParcels) {
            config.TaxParcels.queryExpression = queryexpression

            if (config.TaxParcels.queryExpression == null || config.TaxParcels.queryExpression == "" || config.TaxParcels.queryExpression == "1!=1") {
                resolve({ "Message": "Invalid Expression" });
            } else {
                if (queryexpression != null && queryexpression != "") {
                    // Specific to AMES project.
                    //Showing all the taxparcels on the map. Checking the condition for all here
                    if (queryexpression === 'all') {

                        if (queryexpression != undefined && queryexpression != null && queryexpression != "") {
                            //let parcelIdarra = queryexpression.split(",");
                            let geometries = [];

                            let querycondition = "1=1";
                            config.TaxParcels.queryExpression = querycondition;

                            let layer = view.map.layers.find(function (layer) {
                                return layer.title === "Tax Parcel Feature Layer";
                            });

                            QueryTask(config.TaxParcels, querycondition, 4326).then(
                                response => {
                                    if (response && response.features) {
                                        loadModules([
                                            "esri/layers/FeatureLayer",
                                            "esri/PopupTemplate"
                                        ]).then(([FeatureLayer, PopupTemplate]) => {

                                            if (layer) {
                                                view.map.layers.remove(layer);
                                            }
                                            layer = new FeatureLayer({
                                                "title": "Tax Parcel Feature Layer",
                                                "url": config.TaxParcels.url
                                            });

                                            //Filling the taxparcel layer with tranperent opacity
                                            var filltaxlotsymbol = {
                                                type: "simple-fill",
                                                // autocasts as new SimpleFillSymbol()
                                                color: [132, 186, 245, 0.05],
                                                style: "solid",
                                                outline: {
                                                    // autocasts as new SimpleLineSymbol()
                                                    color: "orange",
                                                    width: 1
                                                }
                                            };

                                            layer.renderer = {
                                                type: "simple",
                                                symbol: filltaxlotsymbol
                                            };

                                            let title = "Taxlot Info -AMES";
                                            let popupTemplate = new PopupTemplate({
                                                // autocasts as new PopupTemplate()
                                                title: title,
                                                content: [{
                                                    type: "fields",
                                                    fieldInfos: [{
                                                        fieldName: "County",
                                                        label: "County"
                                                    },
                                                    {
                                                        fieldName: "MapNumber",
                                                        label: "MapNumber"
                                                    },
                                                    {
                                                        fieldName: "Taxlot",
                                                        label: "Taxlot"
                                                    },
                                                    {
                                                        fieldName: "MapTaxlot",
                                                        label: "MapTaxlot"
                                                    }]
                                                }]
                                            });

                                            //layer.popupTemplate = popupTemplate;
                                            layer.outFields = "*";
                                            view.map.layers.add(layer, 0);

                                            try {
                                                //Center and zoom to the taxlot layer for taxlot search
                                                let item = response.features[0];

                                                //Zoom to the selected taxlot number if exists
                                                if (seltaxlotnumber)
                                                    item = response.features.filter(a => a.attributes.PIN == seltaxlotnumber)[0]

                                                if (item && item.geometry && item.geometry.centroid) {
                                                    let taxparcelcenter = item.geometry.centroid;
                                                    setCenterforMapviewNoFlag(view, parseFloat(taxparcelcenter.x), parseFloat(taxparcelcenter.y), 4326);
                                                }
                                                if (item && item.geometries)
                                                    geometries.push(item.geometry);

                                                if (geometries.length > 0) {
                                                    if (geometries.length > 1) {
                                                        GeometryEngineTask({ "geometries": geometries, "type": "union" }).then(
                                                            GeomEngineresponse => {
                                                                if (GeomEngineresponse && GeomEngineresponse.centroid)
                                                                    CenterAndZoom(view, GeomEngineresponse.centroid, TaxParcelzoom);
                                                            },
                                                            GeomEngineerror => {
                                                                // console.log(GeomEngineerror);
                                                            });
                                                    } else if (geometries.length === 1) {
                                                        CenterAndZoom(view, geometries[0], TaxParcelzoom);
                                                    }
                                                }
                                            } catch (e) { }



                                            resolve({ "Message": geometries.length + " Tax Parcels Added" });
                                        });
                                    }
                                    else {
                                        resolve({ "Message": "Tax Parcels not found" });
                                    }
                                },
                                error => {
                                    console.log(error);
                                    reject({ "error": error });
                                }
                            );

                        }

                    } else {
                        if (queryexpression != undefined && queryexpression != null && queryexpression != "") {
                            let parcelIdarra = queryexpression.split(",");
                            let geometries = [];
                            let taxparcelgraphics = [];
                            let primaryTaxLot = parcelIdarra && parcelIdarra.length > 0 ? parcelIdarra[0] : null
                            let queryTaxlotsString = ''
                            for (var i = 0; i < parcelIdarra.length; i++) {
                                if (queryTaxlotsString === "") {
                                    if (parcelIdarra[i] && parcelIdarra[i] != null)
                                        queryTaxlotsString = "'" + parcelIdarra[i] + "'";
                                } else {
                                    if (parcelIdarra[i] && parcelIdarra[i] != null)
                                        queryTaxlotsString = queryTaxlotsString + ",'" + parcelIdarra[i] + "'";
                                }
                            }
                            let querycondition = "MapTaxlot in (" + queryTaxlotsString + ")";
                            config.TaxParcels.queryExpression = querycondition;

                            let layer = view.map.layers.find(function (layer) {
                                //return layer.title === "Tax Parcel Graphic Layer";
                                return layer.title === "Highlight Layer";
                            });

                            QueryTask(config.TaxParcels, querycondition, 4326).then(
                                response => {
                                    if (response && response.features) {
                                        loadModules([
                                            "esri/Graphic",
                                            "esri/layers/GraphicsLayer",
                                            "esri/layers/FeatureLayer",
                                            "esri/PopupTemplate"
                                        ]).then(([Graphic, GraphicsLayer, FeatureLayer, PopupTemplate]) => {

                                            if (!layer) {
                                                layer = new GraphicsLayer({
                                                    //"title": "Tax Parcel Graphic Layer", listMode: 'hide'
                                                    "title": "Highlight Layer", listMode: 'hide'
                                                });
                                            }
                                            layer.graphics.removeAll();
                                            response.features.forEach(function (item, index) {
                                                if (item && item.geometry && item.geometry.centroid) {
                                                    let taxparcelcenter = item.geometry.centroid;
                                                    setCenterforMapviewNoFlag(view, parseFloat(taxparcelcenter.x), parseFloat(taxparcelcenter.y),
                                                        4326);
                                                }
                                                geometries.push(item.geometry);
                                                let g = new Graphic({
                                                    geometry: item.geometry,
                                                    attributes: item.attributes,
                                                    symbol: (primaryTaxLot && primaryTaxLot == item.attributes.PIN) ? mapConstants.PolygonFillSymbol : mapConstants.PolygonFillSymbolSecondary
                                                });
                                                taxparcelgraphics.push(g);
                                                if (layer.graphics)
                                                    layer.graphics.add(g);
                                            });
                                            if (geometries.length > 0) {
                                                if (geometries.length > 1) {
                                                    GeometryEngineTask({ "geometries": geometries, "type": "union" }).then(
                                                        GeomEngineresponse => {
                                                            if (GeomEngineresponse && GeomEngineresponse.centroid)
                                                                CenterAndZoom(view, GeomEngineresponse.centroid, TaxParcelzoom);
                                                        },
                                                        GeomEngineerror => {
                                                            //   console.log(GeomEngineerror);
                                                        });
                                                } else if (geometries.length === 1) {
                                                    CenterAndZoom(view, geometries[0], TaxParcelzoom);
                                                }
                                            }
                                            view.map.layers.add(layer, (config.TaxParcels.LayerIndex || 0));
                                            //Set geom information to MAPConstanstans, process further for simplified zoom levels and search
                                            resolve({ "Message": geometries.length + " Tax Parcels Added", graphics: taxparcelgraphics });
                                        });
                                    }
                                    else {
                                        resolve({ "Message": "Tax Parcels not found" });
                                    }
                                },
                                error => {
                                    console.log(error);
                                    reject({ "error": error });
                                }
                            );

                            // for (let i = 0; i <= parcelIdarra.length; i++) {
                            //let taxparcelTosend = parcelIdarra[i].split('').join('%');
                            //       if (parcelIdarra[i] != undefined && parcelIdarra[i] != "") {
                            //         ;
                            //       }
                            // }

                        }
                    }



                }

            }
        }
    });
}

export function TaxParcelLayerTask(view, options, config, feature, mapConstants, queryField="SearchField") {
    return new Promise((resolve, reject) => {
        QueryTaskExecuteZoomAddLayerToResponse(view, options, config, feature, mapConstants, queryField).then(res => {
            resolve(res)
        })
    })
}
