/**
      *********************************************************
            Currently not used anywhere
      *********************************************************
**/
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { requestApi } from './utilities';

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    icon: {
        margin: theme.spacing(2),
    },
    iconHover: {
        margin: theme.spacing(2),
        '&:hover': {
            color: red[800],
        },
    },
});

class CDropDownWithAdd extends Component {

    constructor(props) {
        super(props);
        const { name, initialvalue } = props.options;
        this.state = {
            open: false,
            name: '',
            items: [],
            checked: [0],
        }

    }

    componentDidMount() {
        const { options, initialvalue, checkkey, value, error } = this.props
        const { crud, values, name, transformProps } = options
        let valueKey, valueName
        if (transformProps) {
            valueKey = transformProps.value
            valueName = transformProps.name
        }
        else {
            valueKey = 'id'
            valueName = 'name'
        }
        if (crud) {
            const { create, read, update } = crud
            const del = crud.delete
            if (!values) {
                requestApi(read).then(results => {
                    const dropdownvalues = results
                    this.setState({
                        items: results.map(each => {
                            return { value: each[valueKey], name: each[valueName] }
                        })
                    })
                })
            }

        }

    }

    handleChange = (name) => event => {
        const target = event.target;
        if (target) {
            const value = target && target.value;
            if (this.props.setFormFieldValue) {
                this.props.setFormFieldValue(name, value, false, '', this.subform[value])
            }

        }
    };

    handleBlur = () => (event) => {
        const { options } = this.props
        const { validations } = options
        const target = event.target;
        if (target) {
            const value = target && target.value;

        }
    }


    openDialog = () => {
        this.setState({
            open: true
        })
    }
    closeDialog = () => {
        this.setState({
            open: false
        })
    }

    searchItem = () => event => {
        this.setState({
            name: event.target.value
        })
    }

    addItem = () => {
        this.setState({
            items: this.state.items.concat(this.state.name)
        })

        this.props.handleothercomponents(this.props.options.name, this.state.items.concat(this.state.name))
    }

    deleteItem = () => {
        this.setState({

        })
    }
    handleToggle = value => () => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked,
        });
    };


    render() {
        const { options, classes } = this.props;
        const { values } = options
        const { id, label, htmlForLabel, name, value, error, placeholder, validations, helperText, disabled, required, inputLabelProps, inputattrprops, inputprops, inputadornmentprops, margin, ...otherOptions } = options
        const dropdownvalues = values || this.state.items
        return (
            <div className='row'>
                <div className='col-10'>
                    <FormControl disabled={disabled} error={error} required={required} fullWidth>
                        <InputLabel htmlFor={htmlForLabel}>{label}</InputLabel>
                        <Select
                            multiple
                            value={dropdownvalues}
                            onChange={this.handleChange(name)}
                            inputProps={{
                                name: name,
                                id: id || name,
                                ...inputattrprops
                            }}
                            renderValue={selected => (
                                <div className={classes.chips}>
                                    {selected.map(value => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                        >
                            {dropdownvalues && dropdownvalues.map((each, index) => {
                                const { value, name, key } = each
                                return <MenuItem key={key || ('item' + value)} value={value}>{name}</MenuItem>
                            })}
                        </Select>
                        {(helperText) && <FormHelperText>{helperText}</FormHelperText>}
                    </FormControl>
                </div>

                <div className='col-2'>
                    <Icon className={classes.icon} color="primary" onClick={this.openDialog}>
                        add_circle
                    </Icon>
                </div>

                <Dialog aria-labelledby="simple-dialog-title"
                    open={this.state.open}
                    onClose={this.closeDialog}
                >
                    <DialogTitle id="simple-dialog-title">Add or Delete Items</DialogTitle>
                    <div>
                        <TextField
                            id="standard-name"
                            label="Name"
                            value={this.state.name}
                            onChange={this.searchItem('name')}
                            margin="normal"
                        />
                        <Button variant="contained" size="small" color="primary" className={classes.margin} onClick={this.addItem} >
                            Add
                        </Button>
                        <List className={classes.root}>
                            {this.state.items.map(value => (
                                <ListItem key={value} role={undefined} dense button onClick={this.handleToggle(value)}>
                                    <Checkbox
                                        checked={this.state.checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                    <ListItemText primary={`${value}`} />
                                    <ListItemSecondaryAction>
                                        <IconButton aria-label="Delete" onClick={this.deleteItem}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Dialog>
            </div>
        )
    }
}

CDropDownWithAdd.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CDropDownWithAdd);



/**
 * {
 * type:'dropdown',
 * label:'dropdown',
 * values:[1,2,4],
 * options:{
 *
 * },
 * rules:[{
 *      'forvalue':[1,2,4],
 *      'onevent':['onchange','onblur','onfocus'],
 *      'effect': 'create',
 *      'condition':[
 *      {
 *          type:'textbox',
 *          label:'textbox',
 *          value:'textbox'
 *        },
 *        {
 *          type:'textbox',
 *          label:'textbox',
 *          value:'textbox'
 *        }
 *
 *  ]
 * }],
 * defaults:{
 *      theme:'material',
 * }
 * }
 */



