import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputBase from '@material-ui/core/InputBase';
import Chip from "@material-ui/core/Chip";
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    fontSize: 13,
  },
  button: {
    fontSize: 13,
    width: '100%',
    textAlign: 'left',
    paddingBottom: 8,
    color: '#586069',
    fontWeight: 600,
    '&:hover,&:focus': {
      color: '#0366d6',
    },
    '& span': {
      width: '100%',
    },
    '& svg': {
      width: 16,
      height: 16,
    },

  },
  noMaxWidth: {
    maxWidth: 'none',
  },
  tag: {
    marginTop: 3,
    height: 20,
    padding: '.15em 4px',
    fontWeight: 600,
    lineHeight: '15px',
    borderRadius: 2,
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
  },
  header: {
    borderBottom: '1px solid #e1e4e8',
    padding: '8px 10px',
    fontWeight: 600,
  },
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
}));

export default function GitHubLabel(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState({ "company 1": null, "company 2": null });
  const [labelsList, setLabelsList] = React.useState({ "company 1": labels, "company 2": labels }); //userslist as per company
  const [value, setValue] = React.useState({ "company 1": [], "company 2": [] });
  const [pendingValue, setPendingValue] = React.useState({ "company 1": [], "company 2": [] });

  const [usersList, setUsersList] = React.useState(["company 1", "company 2"]);
  const [visible, setVisible] = React.useState({ "company 1": false, "company 2": false });

  // const [anchorEl, setAnchorEl] = React.useState({});
  // const [labelsList, setLabelsList] = React.useState({}); //userslist as per company
  // const [value, setValue] = React.useState({});
  // const [pendingValue, setPendingValue] = React.useState({});

  // const [usersList, setUsersList] = React.useState([]);
  // const [visible, setVisible] = React.useState({});


  const handleClick = (event, name) => {
    setPendingValue({ ...pendingValue, [name]: value[name] });
    setAnchorEl({ ...anchorEl, [name]: event.currentTarget });
  };

  const viewUser = (e, name) => {
    setVisible({ ...visible, [name]: true });
  }

  const hideUser = (e, name) => {
    setVisible({ ...visible, [name]: false });
  }

  const handleClose = (name, event, reason) => {
    if (reason === 'toggleInput') {
      return;
    }
    setValue(pendingValue);
    if (anchorEl[name]) {
      anchorEl[name].focus();
    }
    setAnchorEl({ ...anchorEl, [name]: null });
  };


  return (
    <React.Fragment>
      {usersList.map(each => {
        return (
          <div>
            <div>
              <span>{each}</span>
              <span className="pl-5">
                <IconButton title={"Edit Users"} color={"primary"} onClick={e => handleClick(e, each)}> <EditIcon fontSize="small" />
                </IconButton>
              </span>
              {!visible[each] && <span className="pl-2">
                <IconButton title={"View Users"} color={"primary"} onClick={e => viewUser(e, each)}> <VisibilityOffIcon fontSize="small" />
                </IconButton>
              </span>}
              {visible[each] && <span className="pl-2">
                <IconButton title={"Hide Users"} color={"primary"} onClick={e => hideUser(e, each)}> <VisibilityIcon fontSize="small" />
                </IconButton>
              </span>}
            </div>
            {visible[each] && <div className={classes.root}>
              {value[each].map((label, getTagProps) => (
                <Chip
                  variant="outlined"
                  label={label.name}
                />
              ))}
            </div>}
            <Popper
              open={Boolean(anchorEl[each])}
              anchorEl={anchorEl[each]}
              placement="bottom-start"
              className={classes.popper}
            >
              <div className={classes.header}>Add users for {each}</div>
              <Autocomplete
                open
                onClose={e => handleClose(each, e)}
                multiple
                classes={{
                  paper: classes.paper,
                  option: classes.option,
                  popperDisablePortal: classes.popperDisablePortal,
                }}
                value={pendingValue[each]}
                onChange={(event, newValue) => {
                  setPendingValue({ ...pendingValue, [each]: newValue });
                }}
                disableCloseOnSelect
                disablePortal
                renderTags={() => null}
                noOptionsText="No labels"
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <DoneIcon
                      className={classes.iconSelected}
                      style={{ visibility: selected ? 'visible' : 'hidden' }}
                    />
                    <div className={classes.text}>
                      {option.name}
                    </div>
                    <CloseIcon
                      className={classes.close}
                      style={{ visibility: selected ? 'visible' : 'hidden' }}
                    />
                  </React.Fragment>
                )}
                options={[...labelsList[each]].sort((a, b) => {
                  // Display the selected labels first.
                  let ai = value[each].indexOf(a);
                  ai = ai === -1 ? value[each].length + labelsList[each].indexOf(a) : ai;
                  let bi = value[each].indexOf(b);
                  bi = bi === -1 ? value[each].length + labelsList[each].indexOf(b) : bi;
                  return ai - bi;
                })}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <InputBase
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    autoFocus
                    className={classes.inputBase}
                  />
                )}
              />
            </Popper>
          </div>
        )
      })}
    </React.Fragment>
  );
}


const labels = [
  {
    name: 'user 1',
  },
  {
    name: 'user 2',
  },
  {
    name: 'user 3',
  },
  {
    name: 'user 4',
  },
  {
    name: 'user 5',
  },
  {
    name: 'user 6',
  },
  {
    name: 'user 7',
  },
  {
    name: 'user 8',
  },
  {
    name: 'user 9',
  },
  {
    name: 'user 10',
  },
  {
    name: 'user 11',
  },
  {
    name: 'user 12',
  },
  {
    name: 'user 13',
  },
  {
    name: 'user 14',
  },
  {
    name: 'user 15',
  },
  {
    name: 'user 16',
  },
  {
    name: 'user 17',
  },
  {
    name: 'user 18',
  },
  {
    name: 'user 19',
  },
  {
    name: 'user 20',
  },
  {
    name: 'user 21',
  },
  {
    name: 'user 22',
  },
  {
    name: 'user 23',
  },
  {
    name: 'user 24',
  },
  {
    name: 'user 25',
  },
  {
    name: 'user 26',
  },
  {
    name: 'user 27',
  },
  {
    name: 'user 28',
  },
  {
    name: 'user 29',
  },
  {
    name: 'user 30',
  },
];
