import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { GetData, PostData, PutData } from "../../core/controls/helpers/requests";
import { snackbarMessage } from '../../core/actions';
import FormHeader from '../../core/modules/form/cformheader';
import CFormTextBox from '../../core/modules/form/cformtextbox';
import TemplateForm from '../../core/modules/template/template';
import CFormDropDown from '../../core/modules/form/cformdropdown';


const transformProps = {
    "value": "Id",
    "name": "Subject",
    "key": "Id"
}

class StandardDescOfConstruction extends Component {

    constructor(props) {
        super(props); this.state = { editorState: null, result: {}, Subject: '', ConstructionTypeJson: [], ConstructionTypeId : null, constructionTypeError : null, Id:null, parentId:null };
        this.TemplateForm = React.createRef()
    }


    componentDidMount() {
        const { options } = this.props;
        const { editorState } = this.state;
        const { crud } = options;
        const { read, update } = crud;
        let url = this.props && this.props.dataItem && this.props.dataItem.Id ? read.get.url + this.props.match.params.Id + '/' + this.props.dataItem['Id'] : read.get.url;
        if(this.props.hidesub) {
            url = url+this.props.match.params.routeid1+'/All'
            let dropdownurl = this.props.options.crud.dropdownread.get.url+this.props.match.params.routeid +'/Grid'
            GetData(dropdownurl).then(result => {
                if (result && result.data && result.data.length>0) {
                    this.setState({ ConstructionTypeJson : result.data })
                }
                
            }).catch(error => {
    
            })
        }
        GetData(url).then(result => {
            if(this.props.hidesub && result && result.length>0) {
                this.setState({ resultExists: true, editorState: parseInt(atob(result[0].Body)), result: result[0],  ConstructionTypeId: result[0].TypeId, Id: result[0].Id })
            }
            else if (result && result.Id) {
                this.setState({ resultExists: true, editorState: parseInt(atob(result.BodyStr)), result: result, Subject: result.Subject, Rank: result.Rank })
            }
            else this.setState({ editorState: null, resultExists: false })
        }).catch(error => {

        })
    }

    handleEditorTemplateId = (editorVal) => {
        this.handleSave(editorVal.id);
    }

    callTemplateSave = () => {
        this.TemplateForm.current.saveTemplate();
    }

    handleSave = (editorVal) => {
        const { options } = this.props;
        const { resultExists, result } = this.state;
        const { crud } = options;
        const { read, update } = crud;
        let save = true
        result.BodyStr = btoa(editorVal.toString());
        if(this.props.hidesub) {
            if(!this.state.ConstructionTypeId) {
                save = false;
                this.setState({constructionTypeError : "Description of construction is required"})
            }
            else {
                if(this.state.Id) {
                    result.Id = this.state.Id
                }
                result.TypeId = this.state.ConstructionTypeId
            }
        }
        let url = ''
        if(save) {
        if (!resultExists) {
            // 
            if (this.props.options.tabprops) {
                url = update.put.url + this.props.match.params['Id'] + '/Add';
            }
            else {
                url = update.post.url + '/' + this.props.match.params.routeid1 + '/Add';
            }
            let finalresult = {...result}
            PostData(url, finalresult).then(res => {
                this.setState({ resultExists: false })

                if (!this.props.options.tabprops) {
                    this.props.onClose();
                }

            }).catch(error => {


            })
        }
        else {
            // 
            if (this.props.options.tabprops) {
                url = update.put.url + this.props.match.params['Id'] + '/Update';
            }
            else {
                url = update.put.url + this.props.match.params.routeid1 + '/Update/' + this.state.Id;
            }
            let finalresult = {...result, Rank : this.state.Rank}
            PutData(url, finalresult).then(res => {
                this.setState({ resultExists: false })


                if (!this.props.options.tabprops) {
                    this.props.onClose();
                }

            }).catch(error => {


            })
        }
    }
    }

    handleFieldChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            result: {
                ...this.state.result,
                [event.target.name]: event.target.value
            }
        })
    }

    
    handleDropdownChange = (event) => {
        const value = event.target.value;
        this.setState({ ConstructionTypeId: value, constructionTypeError:null})
        let dropdownurl = this.props.options.crud.dropdownread.get.url+this.props.match.params.routeid + '/' + value
        GetData(dropdownurl).then(result => {
            if (result && result.Id) {
                this.setState({ parentId: parseInt(atob(result.BodyStr)) })
            }
            else this.setState({ parentId: null})
        }).catch(error => {

        })
    }

    handleBack = () => {
        const { match } = this.props
        if(this.props.options.tabprops) {
            this.props.onClose();
            this.props.handleCustomBack();
        }
        else {
        let url = match.url
        let final = url
        final = url.substring(0, url.lastIndexOf("/"))
        this.props.history.push(final)
        }
    }
    handleTemplateId = (Id) => {
        this.handleSave(Id);
    }

    render() {
        const { options, hidesub } = this.props;
        const { headerProps, title, fullheight, designprops } = options;
        const { savebutton, editbutton, backbutton, closebutton, breadcrumbs, sendbutton, nonstickyposition, navigateup, navigatedown, duplicatebutton } = headerProps ? headerProps : {}
        const { editorState, result } = this.state
        return (
            <div>
                <FormHeader accessPermissions={this.props.accessPermissions} accessLevelPermissions={this.props.accessLevelPermissions} breadcrumbs={breadcrumbs} title={title} onFullScreen={this.props.onFullScreen} onExitFullScreen={this.props.onExitFullScreen} fullscreen={this.props.fullscreen} exitfullscreen={this.props.exitfullscreen} onNextItem={this.onNextItemClick} onPrevItem={this.onPrevItemClick}
                    handleSave={() => this.callTemplateSave()} handleBack={this.handleBack} dataItem={this.props.dataItem} navigateup={navigateup} navigatedown={navigatedown}
                    savebutton={savebutton} backbutton={backbutton} designprops={designprops} />
                {!hidesub && <div className="pl-2 pt-2 col-sm-10">
                    <CFormTextBox value={this.state.Subject} name="Subject" label="Subject"
                        handleFieldChange={this.handleFieldChange} edit={true} />
                </div>}
                {hidesub && <CFormDropDown value={this.state.ConstructionTypeId} dropdownvalues={this.state.ConstructionTypeJson} required={true}
                                    name="TypeId" label="Description of Construction Type" transformProps={transformProps}
                                    handleFieldChange={this.handleDropdownChange}  helperText={this.state.constructionTypeError} error={this.state.constructionTypeError ? true : false} />}
                <TemplateForm templateId={editorState} parentid = {this.state.parentId} isEnabled={true} mentions={null} entryData={null} showComments={false}
                    ref={this.TemplateForm} handleTemplateId={(editorVal) => { this.handleEditorTemplateId(editorVal) }} isadmin={false} fullheight={fullheight} />
            </div>
        )
}
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

StandardDescOfConstruction = connect(null, mapActions, null, { forwardRef: true })(StandardDescOfConstruction)
export default withRouter(StandardDescOfConstruction)