import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React, { Component,Fragment } from 'react';
import SubModuleSelection from '../../core/controls/components/custom/submoduleselection';
import Heading from './heading';
import { getEnvVariable } from '../environmentalList';
const _ = require('lodash');


const styles = {
  root: {
    maxWidth: window.innerwidth,
    flexGrow: 1,
  },
};

const checkwidth = 960
export class ModuleSelection extends Component {
  state = {
    activeStep: 2,
    subModuleMenu: '',
    checkboxvalues: '',
    CompanyModules: []
  };
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };


  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  handleChange = (result, name) => {
    this.handleCheckboxChange(result, name);
  }

  handleCheckboxChange = (result, name) => {
    if (!(_.isEqual(this.props.values.CompanyModules, result))) {
      this.setState({ [name]: result }, () => this.props.moduleChecked(this.state));
    }
  }

  render() {
    const { values, classes, moduleChecked } = this.props;
    let hidesubmodules =  getEnvVariable('HideSubmodules') == "Y" ? true : false
    return (
      <Fragment>
      <div className='col-sm-12 '>
      {!hidesubmodules && <div>
        <Heading subheading='Request Access for' /><br /></div>}
        <div className="row">
          <SubModuleSelection name='CompanyModules' value={this.props.values && this.props.values.CompanyModules || []}
            submoduleItemsToParent={this.props.submoduleItemsToParent}
            customnoticesave={this.handleChange} setSubmoduleItemsToParent={this.props.setSubmoduleItemsToParent} />
        </div>
        {!hidesubmodules &&<MobileStepper
          variant="dots"
          steps={4}
          position="static"
          activeStep={this.state.activeStep}
          className={classes.root}
          nextButton={
            <Button size="small" onClick={this.continue} disabled={this.props.values.CompanyModules.length > 1 ? false : true} >
              Next
              {<KeyboardArrowRight />}
            </Button>
          }
          backButton={<Button size="small" onClick={this.back}>                {<KeyboardArrowLeft />}                  Back                </Button>}
        />
      }
      </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(ModuleSelection);
