import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import QueryBuilder from '../../dnd/widgets/validationrules/mainquerybuilder'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/FilterListSharp';
import { makeStyles } from '@material-ui/core/styles';
import { getEnvVariable } from '../../../../modules/environmentalList';
import { GetData } from '../../../controls/helpers/requests';
import { formatQuery as Formatter } from 'react-querybuilder';
import DatePicker from '../../../controls/components/datetimepicker'
import TextBox from '../../../modules/form/cformtextbox';
import Dropdown from '../../../modules/form/cformdropdown';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
}));

export default function FormDialog(props) {
  const classes = useStyles();
  //  const [query, setQuery] = useState(JSON.parse(localStorage.getItem('layerQuery')) || {})
  const [fields, setFields] = useState([])
  const [unitsdomain, setDomains] = useState([])
  const [isArchive, setIsArchive] = useState([])
  const [reload, setReload] = useState(false)


  useEffect(() => {
    let url = getEnvVariable('Admin') + `/api/Domains/values?domainkey=UNITS`;
    GetData(url, false, '', false).then(list => {
      if (list)
        setDomains(list);
    })
    props.widgetRenderCallBack("LayerFilter");
  }, [])

  useEffect(() => {
    if (props.layerId) {
      let url = `${getEnvVariable('Admin')}/api/Layers/FiltersBuilder/${props.layerId}`;
      GetData(url).then(fieldsdata => {
        if (fieldsdata && fieldsdata.rulesBuilder && fieldsdata.rulesBuilder.length > 0)
          setFields(fieldsdata.rulesBuilder);
        setIsArchive(fieldsdata.isArchive);
      })
    }
  }, [props.layerId])

  const handleLayersQuery = (tquery) => {
    let tempQuery = JSON.parse(localStorage.getItem('layerQuery')) || {}
    if (tempQuery && tempQuery[props.layerId]) {
      tempQuery[props.layerId]['query'] = tquery
    }
    else {
      tempQuery[props.layerId] = {};
      tempQuery[props.layerId]['query'] = tquery
    }
    localStorage.setItem('layerQuery', JSON.stringify(tempQuery));
    setReload(!reload)
  }

  const handleSave = () => {
    let tempquery = JSON.parse(localStorage.getItem('layerQuery')) || {};
    tempquery[props.layerId]['query'] = Formatter(tempquery[props.layerId].query, 'sql')
    props.handlelayerFilter(tempquery[props.layerId]);
  }

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    let tempQuery = JSON.parse(localStorage.getItem('layerQuery')) || {}
    if (tempQuery && tempQuery[props.layerId]) {
      tempQuery[props.layerId][name] = value
    }
    else {
      tempQuery[props.layerId] = {}
      tempQuery[props.layerId][name] = value
    }
    localStorage.setItem('layerQuery', JSON.stringify(tempQuery))
    setReload(!reload)
  }

  const query = JSON.parse(localStorage.getItem('layerQuery')) || {};
  return (
    <div style={{ order: props.order }}>
      {reload}
      <Dialog open={props.showLayerFilter || false} onClose={props.layerFilter} aria-labelledby="form-dialog-title" fullWidth={true}
        maxWidth={"md"}>
        <div className={classes.root}>
          <AppBar position="static" color="secondary">
            <Toolbar variant="dense" className={classes.toolbar}>
              <Typography variant="h6" className={classes.title} > <span aria-hidden="true" className="material-icons">filter_alt</span>Layer Filters           </Typography>
              {query && query[props.layerId] !== '' && <IconButton edge="end" color="inherit" onClick={handleSave} aria-label="Apply Filter" title="Apply Filter">            <SaveIcon />          </IconButton>} &nbsp;
              <IconButton color="inherit" onClick={() => props.layerFilter(null)} aria-label="close" title="Close">            <CloseIcon />          </IconButton>
            </Toolbar>
          </AppBar>
        </div>
        <DialogContent>
          <label className="fw-bold">Filter By Value</label>
          <QueryBuilder fields={fields || []} query={query && Object.keys(query).length > 0 && query[props.layerId] && query[props.layerId].query || null}
            onQueryChange={handleLayersQuery} />
          <label className="fw-bold">Filter By Distance</label>
          <div className="row col-sm-12">
            <TextBox className="col-sm-6" label={'Distance'} xtype="number" edit={true} handleFieldChange={(evt) => handleChange(evt)} name="Distance" value={query && Object.keys(query).length > 0 && query[props.layerId] && query[props.layerId].Distance || 0} />
            <Dropdown className="col-sm-6" label={'Units'} edit={true} handleFieldChange={(evt) => handleChange(evt)} name="Units" value={query && Object.keys(query).length > 0 && query[props.layerId] && query[props.layerId].Units || ''}
              dropdownvalues={unitsdomain || []} transformProps={{
                "value": "Value",
                "name": "Name"
              }} />
          </div>
          {isArchive && <div><label className="fw-bold">Filter By Date</label><br />
            <DatePicker value={query && Object.keys(query).length > 0 && query[props.layerId] && query[props.layerId].Asofdate || ''} name="Asofdate" handleFieldValue={(evt) => handleChange(evt)} />
          </div>}
        </DialogContent>
      </Dialog >
    </div>
  );
}