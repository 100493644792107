/**
      *********************************************************
      Description: Number Textbox component. .
      Required props:  type,value.
      limitations    : N/A.
      *********************************************************
**/

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import React, { Fragment, useEffect, useState } from 'react';
import DefaultSettings from './settings';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

export default function NumberFormat(props) {
    const { handleFieldValue, error, changefieldto, extraProps, disabled, required, name, id, edit, options, tooltip, contexthelp, className, xtype,
        ...otherProps } = props
    const { label, type, format, helperText, min = null, max = null, fullWidth, ...otherConfigs } = otherProps;
    const [value, setValue] = useState(props.value)
    const [hold, setHold] = useState(null)
    //  let val = ((value === null) || (value === undefined) || (value === '')) ? 0 : (!isNaN(value) ? value : 0);
    let pvalue = 0
    let numberType = xtype || changefieldto
    if (changefieldto === 'P') {
        pvalue = value !== 0 ? (!isNaN(value) ? value : 0) : 0
    }

    useEffect(() => {
        if (value !== props.value)
            setValue(props.value)
    }, [props.value])

    const handleFieldChange = (e, runBussinessRule = true) => {
        let evtVal = numberType === 'N' ? parseInt(e.target.value) : e.target.value
        handleFieldValue({
            target: {
                name: name,
                value: (e.target.value && runBussinessRule) ? setRange(evtVal) : evtVal,
                //.toString().match('^-?\\d+(?:\.\\d{0,' + (format || -1) + '})?')[0]) : null,//e.target.value> Number(e.target.props.value)? Number(e.target.props.value)+1: e.target.value<Number(e.target.props.value)?e.target.value:e.target.value,
                checked: false
            },
            currentTarget: { dataset: null }
        }, runBussinessRule)
    }

    const setRange = (tar = 0) => {
        let target = tar === '' ? null : tar
        let result = null;
        if (tar === null)
            result = null
        else if ((min === null && max === null) || (min === undefined && max === undefined)) {
            if (Number(target) === null)
                result = null
            else if (target === null)
                result = null
            else
                result = Number(target)
        }
        else if (min !== null && max !== null) {
            if (min < 0) {
                result = target
            }
            else if (Number(target) >= min && Number(target) <= max) {
                result = Number(target)
            }
            else if (target === "") {
                result = null
            }
            else if (Number(target) === 0) {
                result = Number(target)
            }
            else result = value
        }
        else if (min !== null && max === null) {
            if (Number(target) === null)
                result = null
            else if (target === null)
                result = null
            else if (Number(target) >= min)
                result = Number(target)
            else result = min
        }
        else if (min === null && max !== null) {
            if (Number(target) === null)
                result = null
            else if (Number(target) <= max)
                result = Number(target)
            else result = value || null
        }

        else result = value || null
        return result
    }

    let tlabel = (tooltip || contexthelp) ? <Fragment> {label} < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
        <HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#007bff' }} onClick={() => DefaultSettings.showDrawer(contexthelp)} />
    </Tooltip> </Fragment> : label;
    return (
        <>
            {numberType === 'DN' && <TextField
                className='pt-2'
                label={tlabel}
                type="number"
                error={error} required={required}
                fullWidth={fullWidth ? fullWidth : true}
                helperText={helperText}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{ min: min, max: max }}
                disabled={disabled || false}
                value={value ? (!isNaN(value) ? value.toString().match('^-?\\d+(?:\.\\d{0,' + (format || -1) + '})?')[0] : null) : null}
                onChange={(e) => handleFieldChange(e, true)}
                onBlur={(e) => handleFieldChange(e, false)}
                {...otherConfigs}
            />}
            {numberType === 'N' && <TextField
                className='pt-2'
                type="number"
                // format={'n0'}
                error={error} required={required}
                fullWidth={fullWidth ? fullWidth : true}
                label={tlabel}
                // className='pt-2'
                value={value ? (!isNaN(value) ? parseInt(value) : '') : null}
                disabled={disabled || false}
                onChange={(e) => handleFieldChange(e, false)}
                onBlur={(e) => handleFieldChange(e, true)}

                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    inputProps: { min: min, max: max }
                }}
                name={name}
                helperText={helperText}
                {...otherConfigs}
                {...extraProps}
            />}
            {numberType === 'P' &&
                <TextField
                    label={tlabel}
                    inputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        inputProps: { min: min, max: max }
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type="number"
                    error={error} required={required}
                    fullWidth={fullWidth ? fullWidth : true}
                    helperText={helperText}
                    className='pt-2 text-secondary'
                    disabled={disabled || false}
                    value={pvalue ? parseInt(value) : null}
                    onChange={(e) => handleFieldValue({
                        target: {
                            name: name,
                            value: setRange(Number(e.target.value)),//e.target.value > Number(e.target.props.value) ? Number(e.target.props.value) + 1 : e.target.value < Number(e.target.props.value) ? e.target.value : e.target.value,
                            checked: false
                        },
                        currentTarget: { dataset: null }
                    })}
                    onBlur={(e) => handleFieldValue({
                        target: {
                            name: name,
                            value: setRange(Number(e.target.value)),//e.target.value > Number(e.target.props.value) ? Number(e.target.props.value) + 1 : e.target.value < Number(e.target.props.value) ? e.target.value : e.target.value,
                            checked: false
                        },
                        currentTarget: { dataset: null }
                    }, false)}
                    {...otherConfigs}
                />
            }
            {numberType === 'C' && <TextField
                label={tlabel}
                inputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputProps: { min: (min || 0), max: max }
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                type="number"
                error={error} required={required}
                fullWidth={fullWidth ? fullWidth : true}
                helperText={helperText}
                className='text-secondary'
                disabled={disabled || false}
                value={value ? (!isNaN(value) ? value.toString().match('^-?\\d+(?:\.\\d{0,' + (format || -1) + '})?')[0] : 0) : null}
                onChange={(e) => handleFieldValue({
                    target: {
                        name: name,
                        value: setRange(e.target.value.toString().match('^-?\\d+(?:\.\\d{0,' + (format || -1) + '})?')[0]),//e.target.value > Number(e.target.props.value) ? Number(e.target.props.value) + 1 : e.target.value < Number(e.target.props.value) ? e.target.value : e.target.value,
                        checked: false
                    },
                    currentTarget: { dataset: null }
                })}
                onBlur={(e) => handleFieldValue({
                    target: {
                        name: name,
                        value: setRange(e.target.value.toString().match('^-?\\d+(?:\.\\d{0,' + (format || -1) + '})?')[0]),//e.target.value > Number(e.target.props.value) ? Number(e.target.props.value) + 1 : e.target.value < Number(e.target.props.value) ? e.target.value : e.target.value,
                        checked: false
                    },
                    currentTarget: { dataset: null }
                }, false)}
                {...otherConfigs}
            />}
            {/* {helperText && <FormHelperText>{helperText}</FormHelperText>} */}
        </>
    )
}