import React from 'react';
import { getData, postRecordData, putRecordData } from '../rest';
import Drawer from '@material-ui/core/Drawer';
import DrawerContent from './dialogdrawercontent'
import { getEnvVariable } from '../../../modules/environmentalList';

const drawerWidth = 900;
const checkwidth = 960

class CustomizedDialogDemo extends React.Component {

    constructor(props) {
        super(props);
    }
    state = {
        open: true,
        new: false,
        dialogOpen: true,
        anchorEl: null,
        width: window.innerWidth
    };

    updateDimensions = () => {
        if(window.innerWidth<checkwidth) {
        this.setState({ width: window.innerWidth});
        }
      };
     
      
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        console.log(this.props.gridkey)
        this.setState({ dialogOpen: true }, () => {
            this.requestMaster()
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }


    requestMaster = () => {
        let url = getEnvVariable('Report') + '/api/ReportMaster/All'
        getData(url).then(res => {
            let result = res.filter(e => {
                if (e.Key === 'SAVED_QUERIES') {
                    return e
                }
            })
            this.setState({ masterreports: result })
        });

    }


    closeDialog = () => {
        this.setState({
            dialogOpen: false
        }, () => {
            if (this.props && this.props.close)
                this.props.close()
        })
    }


    render() {
        return (
            <div >
                {this.state.masterreports && this.state.masterreports.length > 0 &&
                    <Drawer anchor="right" open={(this.props && this.props.open) ? this.props.open : false} onClose={this.closeDialog} PaperProps={{ style: { width: this.state.width>1000? drawerWidth: this.state.width } }} >
                        <DrawerContent {...this.props}/>
                    </Drawer>
                }
            </div >
        );
    }
}

export default CustomizedDialogDemo;