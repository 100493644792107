/**        
      *********************************************************
      Description: Card panel component. xtype can be used to show the text etc.
      Required props:  type, values (Text or content to show in panel)..
      limitations    : N/A
      Added By: Narendra
      *********************************************************
**/
import React, { Component, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const isHTML = (str) => {
    var doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
  }

const CFormDiscussionPanel = (props) => {
    const { handleFieldChange, value, name, id, edit, options, ...otherProps } = props
    const { label, xtype, inputProps } = otherProps
    const val = ((value === null) || (value === undefined)) ? [] : value
    
    const classes = {};    
    return <Fragment>
            <div className=""> 
                   
                {val && val.map((iter,index) => { 
                      return( <Card className="">
                            <CardContent>
                            
                                <Typography  color="textSecondary" >
                                    {iter.CreatedByName ? iter.CreatedByName: ""}  commented on {moment(iter.CreatedOn).format('MM/DD/YYYY')}
                                </Typography> 

                                <Typography className="" variant="body2"  component="p" gutterBottom>
                                <b>Comments: </b>  
                                    { isHTML(atob(iter.Body)) ? 
                                            <div dangerouslySetInnerHTML={{ __html: atob(iter.Body) }} /> : iter.Body}
                                    <br />                                    
                                </Typography>

                            </CardContent>
                            <CardActions>
                                {/* <Button size="small">Learn More</Button> */}
                            </CardActions>
                        </Card>) 
                })}
            </div>
        </Fragment >
}
export default CFormDiscussionPanel;
