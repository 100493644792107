import MaterialTable from '@material-table/core';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { green, pink, teal } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import moment from 'moment';
import { Fragment } from "react";
import WorkflowUse from "../../../modules/workflow/workflowuse";
import CircularStatic from './daystimer';
import DefaultSettings from './settings';
import Editor from '../../modules/form/cwysiwyg'
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    teal: {
        color: theme.palette.getContrastText(teal[500]),
        backgroundColor: teal[500],
    },
}));


export default function SubHeader(props) {
    const { value, handleFieldValue, handleButtonClick, extraProps, subname, shareProps, type, error, required, tooltip, contexthelp, changefieldto, color, changeTypefieldto, ...otherProps } = props
    const { label, helperText, name, items, xtype, fullWidth, ismultiple, datedisplay, disabled, isIcon, displayonedit, icon, className, variant, format, style, ...otherConfigs } = otherProps
    let { transformProps, nested, columns } = extraProps || {}
    let hidelabel = (name === 'UpdatedByName' || name === 'CreatedByName' || name === 'UpdatedOn' || name === 'CreatedOn') ? true : false
    let checktype = props && props.sharedProps && props.sharedProps.createduplicate && hidelabel ?
        'hidedisplay' : datedisplay ? 'historydate' : (type ? type : (changefieldto || changeTypefieldto))
    let val = ((value === null) || (value === undefined) || (value === '')) ? 0 : value;
    let labelval = ((value === null) || (value === undefined) || (value === '')) ? '' : value
    let finallabel = label
    let dateFormat = extraProps && extraProps.dateFormat ? extraProps.dateFormat : "MM/DD/YYYY"
    let dateMonthFormat = extraProps && extraProps.dateFormat ? extraProps.dateFormat : "MM/DD"
    
    let allitems = []

    return (
        <FormControl className={className} color={'red'} fullWidth={fullWidth ? fullWidth : true} error={error} required={required}>
            {tooltip && <Fragment> <Typography variant={'caption'} className="fw-bold" style={style}>{finallabel}&nbsp;
                <Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                    <HelpOutlineIcon fontSize="small" style={{ width: '0.6em', color: '#007bff' }} onClick={(evt) => contexthelp ? DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
                </Tooltip> </Typography> </Fragment>
            }
            {!tooltip && <Fragment>
                <Typography variant={'caption'} style={{color: '#074078' }} className="fw-bold" >{finallabel}&nbsp;
                </Typography> </Fragment>
            }
            <Typography variant={'caption'} className="text-primary" style={{ overflowWrap: 'break-word' }}>{labelval}   </Typography>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
   
   
}


