import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MobileStepper from '@material-ui/core/MobileStepper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React, { Component } from 'react';
import { getEnvVariable } from '../environmentalList';
import Heading from './heading';
const styles = {
  root: {
    maxWidth: window.innerwidth,
    flexGrow: 1,
  },
};

const RadioList = {
  'AMES': [{ value: 'C', label: 'Applicant', Title: 'Applicant' }], // { value: 'T', label: 'Consultant', Title: 'Applicant' }
  'Other': [{ value: 'C', label: 'Company', Title: 'Applicant' },
  { value: 'T', label: 'Consultant', Title: 'Applicant' }]
}

export class AccountType extends Component {
  state = {
    activeStep: 0,
  };

  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };


  render() {
    const { values, classes, handleChange } = this.props;
    return (<div className="col-sm-12">
      <FormControl component="fieldset">   {RadioList && RadioList[getEnvVariable('ModuleKey')].length > 1 && <Heading subheading='Please Select Account Type' />}<br />
        <RadioGroup aria-label="AccounType" name="AccounType" value={values.CompanyType} onChange={handleChange("CompanyType")}              >
          {(RadioList[getEnvVariable('ModuleKey')] || RadioList['Other']).map((each, index) => {
            return <FormControlLabel key={index} value={each.value} control={<Radio />} label={each.label} />
          })}
        </RadioGroup>            </FormControl>
      <div className="row">
        <MobileStepper className={classes.root} variant="dots" steps={4}
          position='static' activeStep={this.state.activeStep}
          nextButton={<Button size="small" onClick={this.continue} >                Next                {<KeyboardArrowRight />}              </Button>}
          backButton={<Button size="small" onClick={this.back} disabled>                {<KeyboardArrowLeft />}                Back              </Button>}
        />
      </div>
    </div>
    );
  }
}

export default withStyles(styles)(AccountType);