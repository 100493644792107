
import React, { Fragment, useEffect, useRef } from "react"
import Container from '../../../core/controls/components/container'
import HolidayGrid from '../../jsons/holidays/holidaysgrid'


//wrappers folder

//xttype
const HolidayContainer = (props) => {

    return (
        <Fragment>
            <Container {...HolidayGrid} {...props} />
        </Fragment>
    )
}

export default HolidayContainer



