import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getEnvVariable } from '../../../src/modules/environmentalList';
//import { getCalculatedAmount } from '../../../src/payment/manager';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {  snackbarMessage } from '../../core/actions';
import {
    GenerateInvoice, GetDomainValues,
    GetPaymentInformation, GetRecordDataByKeys, GetRecordInvoiceByType, GetSitePermitType, GetTotalDue, GetTotalFee, ReGenerateInvoice, UpdateInvoiceStatus
} from './billingservice';
//import BillingSidepopup from '../billing/billingsidepopup'
//import BillingRegenerate from '../billing/billingregenerate'
import InvoiceSideBar from './billinginvoice';
import RegenerateSideBar from './billingregenerateoptions';
import { IsValidInvoiceStatus, IsValidPermitStatus } from './statusvalidator';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PaymentIcon from '@material-ui/icons/Payment';
import DefaultSettings from '../../core/controls/components/settings';
import GridHeader from '../../core/modules/Grid/cgridheader';
import BillingGrid from './billinggrid';
import PhysicalCheckSideBar from './billingphysicalcheck';
const _ = require('lodash');

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {/* by removing style scrollbar will be hided {value === index && <Box p={3} style={{ overflow: 'auto', height: '800px' }}>{children}</Box>} */}
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        "& .MuiTab-root": {
            'text-transform': 'capitalize'
        },
    },
}));

function Billing(props) {
    const { headerProps } = props.options
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [gridColumns, setGridColumns] = useState([])
    // const [attachmentTypeResults, setAttachmentTypeResults] = useState(null)
    const [TabValue, setTabValue] = useState(0)
    const [invoice, setInvoice] = useState({})
    const [pdf, setPDF] = useState(null)
    const [openInvoice, setOpenInvoice] = useState(false)
    const [currentDataItem, setCurrentDataItem] = useState(null)
    const [openSlide, setOpenSlide] = useState(false)
    const [generateInvoiceType, setGenerateInvoiceType] = useState(null)
    const [openGenerateInvoice, setOpenGenerateInvoice] = useState(false)

    const [permitDropdown, setPermitDropdown] = useState([])
    const [appDropdown, setAppDropdown] = useState([])
    const [paymentStatusOptions, setPaymentStatusOptions] = useState([])
    const [paymentStatusValue, setPaymentStatusValue] = useState(null)
    const [initialloadextrainfo, setInitialloadextrainfo] = useState(null)
    //const [components, setComponents] = useState({ ...BillingSidepopup })
    const [dataState, setDataState] = useState({
        skip: 0, take: 5
    })
    const [EachComp, setEachComp] = useState(null)
    const [pageable, setPageable] = useState({
        buttonCount: 6,
        info: true,
        type: 'numeric',
        pageSizes: [10, 20, 30, 40, 50, "all"],
        previousNext: true
    })
    const helpContext = useRef(null);
    const silderContext = useRef(null);
    const [gridSelectionRecord, setGridSelectionRecord] = useState(false)
    const [hascheckbox, setHasCheckBox] = useState(false)
    const [gridCheckBoxSelectionRecords, setGridCheckBoxSelectionRecords] = useState([])
    const [sitePermitTypeData, setSitePermitTypeData] = useState(null)
    const [physicalCheck, setPhysicalCheck] = useState(false)
    const [physicalCheckProps, setPhysicalCheckProps] = useState({ open: false, type: 'NEW', id: 0 })
    const [recordDetails, setRecordDetails] = useState({});


    useEffect(() => {
        let recorddatakeys = ["COMPANY_ID", "PERMIT_TYPE_ID", "APPLICATION_STATUS", "SITE_PERMIT_TYPE_ID", "PERMIT_NO",
            "DISTRICT_ID", "COUNTY", "AUTHORIZED_DATE", "AUTHORIZED_BY", "PERMIT_NO", "EXEMPTED_FROM_RENEWAL", "EXEMPTED_FROM_PRORATE"]
        Promise.all([
            GetRecordDataByKeys(props.match.params.routeid, props.match.params.routeid1, recorddatakeys),
            GetSitePermitType(props.match.params.routeid),
            GetDomainValues('APPLICATION_PAYMENT_STATUS'),
            GetDomainValues('PERMIT_PAYMENT_STATUS')
        ]).then((values) => {
            //const { recorddetails, sitepermittypedetails, apppaymentstatus, permitpaymentstatus} = values;
            //console.log(recorddetails, sitepermittypedetails, apppaymentstatus, permitpaymentstatus);
            if (values && Array.isArray(values)) {
                let recorddetails = values[0];
                let sitepermittypedetails = values[1];
                let apppaymentstatus = values[2];
                let permitpaymentstatus = values[3];
                let fullrecorddetails = recorddetails[0];
                fullrecorddetails["KEY"] = props.match.params.key;
                let initialloadextrainfodata = { results: values[0][0] }
                setInitialloadextrainfo(initialloadextrainfodata);
                setRecordDetails(fullrecorddetails);
                let initialtabvalue = sitepermittypedetails && sitepermittypedetails.HasApplicationFee && sitepermittypedetails.HasApplicationFee == "Y" ? 0 : sitepermittypedetails.HasPorate && sitepermittypedetails.HasPorate == "Y" ? 1 : sitepermittypedetails.PermitRenewal && sitepermittypedetails.PermitRenewal == "Y" ? 2 : 3

                setTabValue(initialtabvalue);
                setSitePermitTypeData(sitepermittypedetails);

                setAppDropdown(apppaymentstatus);
                setPaymentStatusOptions(apppaymentstatus);

                setPermitDropdown(permitpaymentstatus);
            }
            console.log(values);
        });
        //LoadExistingInvoiceData()
        LoadData();
    }, [])

    const LoadExistingInvoiceData = () => {
        if (TabValue < 4) {
            LoadInvoiceDetails();
        }
        if (TabValue == 4) {
            LoadPaymentInformation();
        }
    }

    const LoadInvoiceDetails = () => {
        //console.log('Loading from ------------------------------------------------------*******')
        let invoicetypeexp = TabValue == 0 ? 'Application' : TabValue == 1 ? 'Prorate' : 'PermitRenewal';
        GetRecordInvoiceByType(props.match.params.routeid1, invoicetypeexp).then(invres => {
            let extdata = []; //[...data, ...invres.InvoiceLineItems];
            let statusopts = TabValue < 2 ? appDropdown : permitDropdown
            if (invres && Array.isArray(invres) && invres.length > 0) {
                if (TabValue < 3) {
                    let proinvoice = invres.filter(a => a.InvoiceType == invoicetypeexp && a.Status != "C")
                    if (proinvoice.length > 0) {
                        proinvoice = proinvoice.sort(function (a, b) {
                            return b.Id - a.Id;
                        });
                        extdata = proinvoice[0].InvoiceLineItems
                        setData(extdata)
                        setInvoice(proinvoice[0])
                        setPaymentStatusOptions(statusopts)
                        setGridSelectionRecord(null)
                    } else {
                        setData([])
                        //setInvoice({})
                        setInvoice({})
                        // GetBaseRecordDetail(props.match.params.routeid1).then(res => {
                        //     let invoice = {PermitNumber: res.PermitNo}
                        //     setInvoice(invoice)
                        // })
                    }
                } else {
                    let invdataarr = invres.filter(a => a.InvoiceType == 'PermitRenewal');
                    if (invdataarr && Array.isArray(invdataarr)) {
                        invdataarr.forEach(element => {
                            element.InvoiceLineItems = element.InvoiceLineItems.length;
                            element.InvoiceDetails = element.InvoiceDetails.length;
                        });
                    }

                    setData(invdataarr)
                    setInvoice(invdataarr[0])
                    setPaymentStatusOptions(statusopts)
                    setGridSelectionRecord(null)
                }
            } else {
                setInvoice({})
            }
        })
    }

    const LoadPaymentInformation = () => {
        GetPaymentInformation(props.match.params.routeid1).then(invres => {
            console.log(invres)
            if (invres && Array.isArray(invres)) {
                setData(invres);
                setInvoice({});
                setPaymentStatusOptions([]);
                setGridSelectionRecord(null);
            }
        })
    }

    useEffect(() => {
        LoadData();
    }, [dataState])

    const LoadData = () => {
        const queryStr = `${toDataSourceRequestString(dataState)}`;
        //we do request grid request and need to  append queryStr for url
        let dataitems = data || []
        //setData(dataitems);
        setTotal(dataitems.length);
        //for getting distinct columns, returns all distinct columns by verifying all objects in data array
        let columnsarr = _.union(_.flatten(_.map(dataitems, (e) => _.keys(e))));
        var removedfields = _.remove(columnsarr, function (n) {
            if (TabValue < 3)
                return n != "UpdatedBy" && n != "UpdatedOn" && n != "Id" && n != "BillingPayments" && n != "DownloadId";
            else
                return n != "UpdatedBy" && n != "UpdatedOn" && n != "BillingPayments" && n != "DownloadId";
        });
        setGridColumns(removedfields)
    }

    const handleDataStateChange = (changeEvent) => {
        setDataState(changeEvent.data)
    }

    const handleBack = () => {
        const { back } = props.options;
        const { routes } = back;
        if (Array.isArray(routes)) {
            let path = ''
            routes.forEach(each => {
                const { route, routeParams, matchProps } = each
                path += route + "/" + props.match.params[routeParams]
            })
            props.history.push(path)
        }
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue == 3) {
            setHasCheckBox(true)
        }
        else {
            setHasCheckBox(false)
        }
    }

    useEffect(() => {
        //console.log('Loading from tab value change----------------------**')
        LoadExistingInvoiceData();
        setData([])
    }, [TabValue])

    //Genreate options
    const handleButtonClick = () => {
        if (TabValue == '1') {
            GenerateProrateInvoice(recordDetails);
        } else if (TabValue == '2') {
            GeneratePermitRenewalInvoice(recordDetails);
        }
    }

    const GeneratePermitRenewalInvoice = (recordDetails) => {
        GenerateInvoice(recordDetails, "PERMITRENEWAL").then(res => {
            console.log(res);
            if (res && res.Id && res.InvoiceLineItems) {
                LoadExistingInvoiceData();
            }
        }).catch(function (err) {
            console.error(err);
            let nobll = err.Error == 'NOBILLING'
            let nosetting = err.Error == 'NOSETTING'
            props.snackbarMessage(`No permit fee found in settings.`, 'info')
        })
    }

    const GenerateProrateInvoice = (recordDetails) => {
        GenerateInvoice(recordDetails, "PRORATE").then(res => {
            console.log(res);
            if (res && res.Id && res.InvoiceLineItems) {
                LoadExistingInvoiceData();
            }
        }).catch(function (err) {
            console.error(err);
            let nobll = err.Error == 'NOBILLING'
            let nosetting = err.Error == 'NOSETTING'
            props.snackbarMessage(`No prorate fee found in settings.`, 'info')
        })
    }


    useEffect(() => {
        SetGridData();
    }, [data])



    const SetGridData = () => {
        const queryStr = `${toDataSourceRequestString(dataState)}`;
        //we do request grid request and need to  append queryStr for url
        let dataitems = data || []
        //setData(dataitems);
        setTotal(dataitems.length);
        //for getting distinct columns, returns all distinct columns by verifying all objects in data array
        let columnsarr = _.union(_.flatten(_.map(dataitems, (e) => _.keys(e))));
        var removedfields = _.remove(columnsarr, function (n) {
            if (TabValue < 3)
                return n != "UpdatedBy" && n != "UpdatedOn" && n != "Id" && n != "BillingPayments" && n != "DownloadId";
            else
                return n != "UpdatedBy" && n != "UpdatedOn" && n != "BillingPayments" && n != "PermitNumber" && n != "DownloadId";
        });
        setGridColumns(removedfields)
    }

    const payOnline = () => {
        let routeid = props.match.params.routeid;
        let routeid1 = props.match.params.routeid1;
        let currentuser = props.accessPermissions.userId
        let url = getEnvVariable('PaymentGateWay') + `/RequestPayment/Index/${routeid}/${routeid1}/${invoice.Id}/${currentuser}`  //'http://localhost/payment/RequestPayment/Index/1/1/1';
        let payment_window = window.open(url, 'popUpWindow', 'height=800,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
        payment_window.focus();
        var timer = setInterval(function () {
            if (payment_window.closed) {
                clearInterval(timer);
                LoadExistingInvoiceData();
            }
        }.bind(this), 1000);

    }

    const payOnlineAll = () => {
        let routeid = props.match.params.routeid;
        let routeid1 = props.match.params.routeid1;
        let currentuser = props.accessPermissions.userId;
        let invoicecondition = ""
        let selectionmapdataid = gridCheckBoxSelectionRecords.map(res => {
            return res.Id
        })
        if (selectionmapdataid && Array.isArray(selectionmapdataid)) {
            selectionmapdataid.forEach(element => {
                if (Number.isInteger(element))
                    invoicecondition = invoicecondition + 'id=' + element + '&';
            });

            let url = getEnvVariable('PaymentGateWay') + `/OnlinePayment/Index/${routeid}/${routeid1}/${currentuser}?${invoicecondition}`  //${invoice.Id}
            let payment_window = window.open(url, 'popUpWindow', 'height=800,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
            payment_window.focus();
            var timer = setInterval(function () {
                if (payment_window.closed) {
                    clearInterval(timer);
                    LoadExistingInvoiceData();
                }
            }.bind(this), 1000);
        }
    }



    const PaymentStatus = () => {
        return invoice ? (invoice.Status == 'P' ? 'Paid' : 'UnPaid') : '';
    }


    // const rowRender = (trElement, row) => {
    //     const trProps = {
    //         ...trElement.props,
    //         onDoubleClick: () => {
    //             if(TabValue < 3){
    //                 setGridSelectionRecord(row.dataItem.InvoiceId)
    //             }else{
    //                 setGridSelectionRecord(row.dataItem.Id)
    //             }
    //         }
    //     };
    //     return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    // }

    const rowRenderData = (row) => {
        let gridrow = row.dataItem;
        gridrow.InvoiceId = TabValue < 3 ? row.dataItem.InvoiceId : row.dataItem.Id
        setGridSelectionRecord(gridrow)
        // if (TabValue < 3) {
        //     setGridSelectionRecord(row.dataItem.InvoiceId)
        // } else {
        //     setGridSelectionRecord(row.dataItem.Id)
        // }
    }

    const gridSelection = (datavalues, selectionmapdata) => {
        //setData(datavalues);
        setGridCheckBoxSelectionRecords(selectionmapdata)
    }
    const gridselectionLoad = (gridcheckload) => {
        console.log(gridcheckload);
    }

    useEffect(() => {
        if (TabValue == 4 && gridSelectionRecord) {
            physicalcheckSlider(true, 'PAYMENT', gridSelectionRecord.id)
        } else if (gridSelectionRecord && gridSelectionRecord.InvoiceId > 0) {
            setOpenInvoice(true);
        }
    }, [gridSelectionRecord])

    const handleSlide = () => {
        setOpenSlide(false);
        setCurrentDataItem(null);
        LoadExistingInvoiceData();
    }

    const handleInvoiceSlider = () => {
        setOpenInvoice(false);
        setGridSelectionRecord(false);
    }

    const regenerateSlider = (type) => {
        setGenerateInvoiceType(type);
    }

    const physicalcheckSlider = (open, type, id) => {
        //setPhysicalCheck(open);
        if (!open) {
            setPhysicalCheckProps({ open: false, viewtype: '', id: 0 });
            LoadExistingInvoiceData();
        }
        else
            setPhysicalCheckProps({ open: true, viewtype: type, id: id })
    }

    const handleRegenerateSlider = (RegenerateOptions) => {
        //setGenerateInvoiceType(type);
        if (RegenerateOptions && RegenerateOptions.RegenerateType) {
            if (generateInvoiceType == "G" && RegenerateOptions.RegenerateType == "2") {
                let invoiceprops = { ...RegenerateOptions, ...recordDetails };
                if (TabValue == '1') {
                    GenerateProrateInvoice(invoiceprops);
                } else if (TabValue == '2') {
                    GeneratePermitRenewalInvoice(invoiceprops);
                }
            } else {
                let applicantid = recordDetails['COMPANY_ID'] //props.match.params.routeid2
                let sitepermittypeid = props.match.params.routeid
                let recordid = props.match.params.routeid1
                let invtype = (RegenerateOptions.RegenerateType == "1" && generateInvoiceType == "R" || (RegenerateOptions.RegenerateType == "1" && generateInvoiceType == "G")) ? "A" : "C"
                let invid = invoice.Id ? invoice.Id : '-1'
                let cancelParent = RegenerateOptions.CancelInvoice ? "Y" : "N";
                let RegenerateProps = { ...RegenerateOptions, SitePermitTypeId: sitepermittypeid, InvoiceId: invid, ApplicantId: applicantid, InvoiceType: invtype, RecordId: recordid, CancelParent: cancelParent, SubModuleKey: props.match.params.key };
                ReGenerateInvoice(RegenerateProps).then(a => {
                    console.log(a);
                    LoadExistingInvoiceData();
                    if (DefaultSettings.getAlert() != null)
                        DefaultSettings.getAlert().show(`Invoice generated successfully.`, 'success');
               //     props.snackbarMessage(`Invoice generated successfully.`, 'success');
                }).catch(function (err) {
                    if (DefaultSettings.getAlert() != null)
                        DefaultSettings.getAlert().show(`No records found for this owner.`, 'error');
                    //console.error(err);
                    // props.snackbarMessage(`No records found for this owner.`, 'error')
                })

            }
        }
        setOpenGenerateInvoice(false);
        setGenerateInvoiceType(null);
    }

    // const handleRegenerateSlider = (type, iscancel) =>{
    //     //setGenerateInvoiceType(type);
    //     if(type){
    //         if(generateInvoiceType == "G" && type == "2"){
    //             handleButtonClick();
    //         }else{
    //             let applicantid = props.match.params.routeid2
    //             let sitepermittypeid = props.match.params.routeid
    //             let recordid = props.match.params.routeid1
    //             let invtype = (type == "1" && generateInvoiceType == "R" || (type == "1" && generateInvoiceType == "G")) ? "A" : "C"
    //             let invid = invoice.Id ? invoice.Id : '-1'
    //             let cancelParent = iscancel ? "Y": "N"
    //             ReGenerateInvoice(invid, applicantid, invtype, recordid, cancelParent, props.match.params.key).then(a =>{
    //                 console.log(a);
    //                 LoadExistingInvoiceData();
    //                 props.snackbarMessage(`Invoice generated successfully.`, 'success');
    //             })

    //         }
    //     }
    //     setOpenGenerateInvoice(false);
    //     setGenerateInvoiceType(null);
    // }

    const InvoiceStatusChange = (e) => {
        let currstatus = e.target.value;
        if (TabValue < 3) {
            if (!IsValidInvoiceStatus(invoice.Status, currstatus)) {
                props.snackbarMessage(`Invalid status change.`, 'error');
            } else {
                let inv = invoice;
                inv.Status = currstatus;
                // setInvoice(inv)
               // props.Confirmation(updateInvoiceStatusCancel, () => updateInvoiceStatus(inv), 'Are You sure you want update. This cannot be undone.', true);
            }
        } else {
            if (!IsValidPermitStatus(invoice.Status, currstatus)) {
                props.snackbarMessage(`Invalid status change.`, 'error');
            } else {
                let inv = invoice;
                inv.Status = currstatus;
                // setInvoice(inv)
            }
        }

        const updateInvoiceStatusCancel = () => {
           // props.Confirmation(null, null, null, false);
        }

        const updateInvoiceStatus = (inv) => {
          //  props.Confirmation(null, null, null, false);
            UpdateInvoiceStatus(inv.Id, inv.Status).then(res => {
                setInvoice(inv)
                props.snackbarMessage(`Updated successfully.`, 'success');
            })
        }
    }

    useEffect(() => {
        if (generateInvoiceType)
            setOpenGenerateInvoice(true)
    }, [generateInvoiceType])

    const viewInvoice = () => {
        if (invoice && invoice.Id > 0) {
            let gridselectionrecord = { InvoiceId: invoice.Id, ...invoice }
            setOpenInvoice(true);
            setGridSelectionRecord(gridselectionrecord)
        }
    }

    const lable_baseCAP = recordDetails["KEY"] == 'VIOLATIONS' ? "Fine" : "Invoice";
    const lable_base = recordDetails["KEY"] == 'VIOLATIONS' ? "fine" : "invoice";
    const lable_allinvoices = recordDetails["KEY"] == 'VIOLATIONS' ? "All Fines" : "All Permit Invoices";

    const getGrid = (gridColumns) => {
        let stagedetails = localStorage.getItem('stagedetails')
        let progressstatus = localStorage.getItem('permitstatus')
        let prorateinfoval = localStorage.getItem('prorateinfo')
        let showbutton = (stagedetails == "InProgress" || progressstatus == "10143") ? true : false;
        let calTotalFee = (invoice && invoice.Id) ? GetTotalFee(invoice) : 0;
        let calTotalDue = (invoice && invoice.Id) ? GetTotalDue(invoice) : 0;
        let generatebutton = stagedetails && stagedetails == "Approved" ? true : false;
        let showbutton_fine = (recordDetails["KEY"] == 'VIOLATIONS');
        return (
            <div>

                {TabValue < 3 && <div>
                    <Typography className="float-right pr-3 pt-2" gutterBottom><b>Total Fee</b> : $ {calTotalFee}</Typography>
                    {/* <Typography className="float-right pr-3 pt-2" gutterBottom><b>Amount Paid</b> : {(calTotalFee - calTotalDue)}</Typography>
                <Typography className="float-right pr-3 pt-2" gutterBottom><b>Amount Due</b> : { calTotalDue }</Typography>                 */}
                    <Typography display="block" className="pl-3 pt-2" gutterBottom><b>Record/Permit Number</b> : {recordDetails && recordDetails["PERMIT_NO"]} </Typography>
                    {/* {invoice  ? (invoice.PermitNumber) : props.match.params.routeid1} */}
                    <Typography className="float-right pr-3" gutterBottom><b>Invoice Id</b> : {(invoice && invoice.Id) ? invoice.Id : 'Not Generated Yet'}</Typography>
                    <Typography display="block" className="pl-3" gutterBottom><b>Applicant Id</b> : {recordDetails && recordDetails["COMPANY_ID"]}</Typography>
                    {(TabValue <= 1 || props.accessPermissions.portal == "E") && <Typography className="float-right pr-3" gutterBottom><b>{lable_baseCAP} Status</b> : {PaymentStatus()} </Typography>}
                    {TabValue > 1 && (props.accessPermissions.portal != "E") && <Typography align="right" className="float-right pr-3" gutterBottom><b>{lable_baseCAP} Status</b> :<Select onChange={(e) => InvoiceStatusChange(e)} value={invoice && invoice.Status ? ((invoice.Status == 'R' || invoice.Status == 'U') ? 'U' : invoice.Status) : ''} >
                        {paymentStatusOptions && paymentStatusOptions.length > 0 && paymentStatusOptions.map((opt, index) => {
                            return <MenuItem key={index} value={opt.Value}>{opt.Name}</MenuItem>
                        })}
                    </Select> </Typography>}
                    {/* {TabValue > 1 && <Typography className="float-right pr-3" gutterBottom><b>{lable_baseCAP} Status</b> :  <Select onChange={(e) => InvoiceStatusChange(e)} value={invoice && invoice.Status ?  ((invoice.Status == 'R'  || invoice.Status == 'U') ? 'U' : invoice.Status) : ''} >
                        {paymentStatusOptions && paymentStatusOptions.length > 0 && paymentStatusOptions.map((opt, index) => {
                            return <MenuItem key={index} value={opt.Value}>{opt.Name}</MenuItem>
                        })}
                     </Select></Typography>} */}
                    <Typography display="block" className="pl-3" gutterBottom><b>{lable_baseCAP} Date :</b>{(invoice && invoice.InvoiceDate) ? moment(invoice.InvoiceDate).format('MM/DD/YYYY') : 'N/A'} </Typography>
                    {TabValue > 2 && <Typography display="block" className="pl-3" gutterBottom><b>{lable_baseCAP} Due Date :</b>{(invoice && invoice.DueDate) ? moment(invoice.DueDate).format('MM/DD/YYYY') : 'N/A'} </Typography>}
                    <Typography align={TabValue > 2 ? "left" : "right"} display={TabValue > 2 ? "inline" : "block"} className={TabValue > 2 ? "pl-3" : "float-right pr-3"} gutterBottom><b>Payment Confirmation</b> : {invoice && invoice.PaymentReferenceId && invoice.PaymentReferenceId != "" ? invoice.PaymentReferenceId : 'N/A'}</Typography>
                    {TabValue <= 2 && <Typography display="block" className="pl-3" gutterBottom><b>{lable_baseCAP} Due Date :</b>{(invoice && invoice.DueDate) ? moment(invoice.DueDate).format('MM/DD/YYYY') : 'N/A'} </Typography>}
                    <Typography className="float-right pr-3" gutterBottom><b>Amount Paid</b> : $ {(calTotalFee - calTotalDue)}</Typography>
                    <Typography display="block" className="pl-3" gutterBottom><b>Due Amount :</b> $ {calTotalDue}</Typography>

                </div>}
                {TabValue == 3 && <div>
                    <Typography display="block" className="pl-3 pt-2" gutterBottom><b>{lable_allinvoices}</b></Typography>
                    <Typography display="block" className="pl-3 pt-2" gutterBottom><b>Multiple unpaid {lable_base}s can be paid online. Maximum selection limited to 50 {lable_base}.</b></Typography>
                </div>}
                {TabValue == 4 && <div>
                    <Typography display="block" className="pl-3 pt-2" gutterBottom><b>All {lable_base} payment information</b></Typography>
                </div>}

                <BillingGrid data={data} total={total} gridSelection={gridSelection} gridselectionLoad={gridselectionLoad} hascheckbox={hascheckbox} rowRenderData={rowRenderData} gridColumns={gridColumns} sidebar={true} />

                {TabValue == 3 && <div>
                    <Typography display="block" color="secondary" className="pl-3 pt-2" gutterBottom><b>Note: Partial payment {lable_base}s can only be paid from payment information section.</b></Typography>
                </div>}
                <div className="col-sm-12 pt-3">
                    <InvoiceSideBar ref={helpContext} open={openInvoice} gridrow={gridSelectionRecord} handleInvoiceSlider={handleInvoiceSlider} />

                    <RegenerateSideBar ref={silderContext} open={openGenerateInvoice} type={generateInvoiceType} invoiceid={invoice ? invoice.Id : -1} handleRegenerateSlider={handleRegenerateSlider} recordDetails={recordDetails} />

                    {physicalCheckProps.open && <PhysicalCheckSideBar open={physicalCheckProps.open}
                        invoiceid={(invoice && invoice.Id) ? invoice.Id : ((gridSelectionRecord && gridSelectionRecord.invoices) ? gridSelectionRecord.invoices : 0)}
                        viewtype={physicalCheckProps.viewtype}
                        billingid={physicalCheckProps.id}
                        appdata={recordDetails}
                        physicalcheckSlider={physicalcheckSlider}
                        recorddata={gridSelectionRecord} {...props} />}

                    {showbutton && getEnvVariable('AccessPortal') === 'I' && TabValue == '1' && !invoice.Id && recordDetails && recordDetails["EXEMPTED_FROM_PRORATE"] == "N" && <Button className="col-sm-2 float-right pr-2" variant="contained" color="primary" onClick={handleButtonClick}>Generate</Button>}

                    {/*Following button specific for violation fines*/}
                    {showbutton_fine && getEnvVariable('AccessPortal') === 'I' && TabValue == '2' && recordDetails && <Button className="col-sm-2 float-right pr-2" variant="contained" color="primary" onClick={() => regenerateSlider("G")}>Generate</Button>}

                    {!showbutton_fine && getEnvVariable('AccessPortal') === 'I' && TabValue == '2' && invoice && invoice.Id && <Button className="col-sm-2 float-right pr-2" variant="contained" color="primary" onClick={() => regenerateSlider("R")}>Regenerate {lable_baseCAP}</Button>}

                    {generatebutton && getEnvVariable('AccessPortal') === 'I' && TabValue == '2' && recordDetails && recordDetails["EXEMPTED_FROM_RENEWAL"] == "N" && <Button className="col-sm-2 float-right pr-2" variant="contained" color="primary" onClick={() => regenerateSlider("G")}>Generate</Button>}

                    {invoice && invoice.Id && invoice.Status == 'U' && TabValue != '3' && <Button className="col-sm-2 float-right pr-2" variant="contained" color="primary" onClick={payOnline}>Pay Online</Button>}

                    {getEnvVariable('AccessPortal') === 'I' && invoice && invoice.Id && invoice.Id > 0 && invoice.Status != 'P' && TabValue < 3 && <Button className="col-sm-2 float-right pr-2" variant="contained" color="primary" onClick={() => physicalcheckSlider(true, 'INVOICE', invoice.Id)}>Physical Check</Button>}

                    {TabValue == '3' && gridCheckBoxSelectionRecords.length > 0 && <Button className="col-sm-2 float-right pr-2" variant="contained" color="primary" onClick={payOnlineAll}>Pay Online(Selected)</Button>}

                    {invoice && invoice.Id && invoice.Id > 0 && TabValue < 3 && <Button className="col-sm-2 float-right pr-2" variant="contained" color="primary" onClick={viewInvoice}>View {lable_baseCAP}</Button>}
                    {/* <Button className="col-sm-2 float-right pr-2" variant="contained" color="primary" onClick={DownloadInvoicePDF}>Download</Button> */}
                </div>
                <div>
                    {pdf && <iframe src={pdf} width="100%" height="100%" frameborder="0" scrolling="no" id="iframe" />}
                </div>

            </div>
        )
    }

    const lable_renewallable = recordDetails["KEY"] == 'VIOLATIONS' ? "Latest Fine Record" : "Current Renewal Invoice";
    const lable_allrenewal = recordDetails["KEY"] == 'VIOLATIONS' ? "All Fines" : "All Renewal Invoices";
    const lable_paymentinformation = recordDetails["KEY"] == 'VIOLATIONS' ? "Fine Payment Information" : "Invoice Payment Information";
    return (
        <Fragment>
            {headerProps &&
                <GridHeader initialloadextrainfo={initialloadextrainfo} headerProps={headerProps} compmap={props.compmap} accessPermissions={props.accessPermissions} accessLevelPermissions={props.accessLevelPermissions} handleBack={handleBack} />}
            <AppBar position="static" color="white">
                <Tabs
                    value={TabValue}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    scrollButtons="on"
                    aria-label="scrollable auto tabs example"
                >
                    {sitePermitTypeData && sitePermitTypeData.HasApplicationFee && sitePermitTypeData.HasApplicationFee == "Y"
                        && <Tab label="Application Fee" icon={<AttachMoneyIcon />} {...a11yProps(0)} value="0" />}
                    {sitePermitTypeData && sitePermitTypeData.HasPorate && sitePermitTypeData.HasPorate == "Y"
                        && <Tab label="Prorate Fee" icon={<LocalAtmIcon />} {...a11yProps(0)} value="1" />}
                    {sitePermitTypeData && sitePermitTypeData.PermitRenewal && sitePermitTypeData.PermitRenewal == "Y"
                        && <Tab label={lable_renewallable} icon={<MonetizationOnIcon />} {...a11yProps(0)} value="2" />}
                    <Tab label={lable_allrenewal} icon={<PaymentIcon />} {...a11yProps(0)} value="3" />
                    <Tab label={lable_paymentinformation} icon={<AccountBalanceWalletIcon />} {...a11yProps(0)} value="4" />
                </Tabs>
            </AppBar>
            {getGrid(gridColumns)}
        </Fragment>
    )
}

const mapProps = (state) => {
    return state
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

Billing = connect(mapProps, mapActions)(Billing)
export default withRouter(Billing)