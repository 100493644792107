import MomentUtils from '@date-io/moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { snackbarMessage } from '../../core/actions';
import CheckboxesTags from '../../core/controls/components/checkautocomplete';
import { GetData, PostData, PutData } from '../../core/controls/helpers/requests';
import CFormCheckBox from '../../core/modules/form/cformcheckbox';
import CFormDropDown from '../../core/modules/form/cformdropdown';
import FormHeader from '../../core/modules/form/cformheader';
import CFormTextBox from '../../core/modules/form/cformtextbox';
import { globalvalidate as GlobalValidate } from '../../core/modules/form/validations';
import Grid from '../../core/modules/Grid/cgrid';
import Template from '../../core/modules/template/template';
import historygrid from '../../json/common/notices/noticehistorygrid';
import noticeCommonComponents from '../../json/notificationsettings/nsComponents';
import { getEnvVariable } from '../environmentalList';

const transformProps = {
    "value": "Id",
    "name": "Name",
    "key": "Id"
}


const addbreadcrumbs = [
    { "link": "Add Notice", "materialicon": "tab", className: 'eps-breadcrumb-item', class: 'text-light' }
]
const editbreadcrumbs = [

    { "link": "View/Edit Notice", appendval: 'NoticeTypeIdDesc', "materialicon": "tab", className: 'eps-breadcrumb-item', class: 'text-light' }
]


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

class NoticeCommon extends Component {

    constructor(props) {
        super(props);
        this.state = {
            noticeTypeJson: [],
            results: { TemplateId: 0, NoticeTypeId: "", NoticeDate: "", CertifiedMailNo: "", NoticeDescription: "" },
            showTemplate: false,
            TemplateId: 0,
            ParentId: 0,
            NoticeTypeId: 0,
            updateRecord: this.props.options.updateRecord,
            noticeEntryData: [],
            NoticeDate: moment(new Date()).format('MM/DD/YYYY'),
            CertifiedMailNo: "",
            NoticeDescription: "",
            ProjectMessages: [],
            errors: {},
            errorForBody: {},
            SendToOwner: false,
            tabvalue: 0,
            EmailModel: { Subject: '', Body: '', MailTo: [], TemplateList: [] },
            CanEditNoticeDate: '',
            CanEditPastNoticeDate: '',
            CanEditCurrentNoticeDate: '',
            CanEditFutureNoticeDate: '',
            DisableNoticeDate: true,
            sideBarTablesRecords: [],
            sideBarTables: [],
            attachmentsdata: [],
            Templatedownloaddata: [],
            AttachmentIDS: '',
            canAttachApplication: '',
            canAttachPermit: '',
            PermitTempalteID: '',
            ApplicationTemplateID: '',
            edit: this.props.options.updateRecord ? true : false,
            expanded: 'panel1'


        }
        this.masterTableId = this.props.match.params.routeid1 // ApplicationId, PermitId, CompanyId
        this.sitePermitTypeId = this.props.match.params.routeid
        this.fieldValues = {};
        if (this.state.updateRecord) {
            this.noticeComponentsArray = { ...historygrid };
            this.historygridjson = this.noticeComponentsArray.options
        }
        this.nsComponentsArray = { ...noticeCommonComponents };
        this.statusJson = this.nsComponentsArray.components.find(x => x.key === "nsstatus").options;
        this.url1 = getEnvVariable('Admin') + `/api/NoticeTypes/All/${this.props.match.params.routeid}`
        this.url2 = this.props.dataItem && this.props.dataItem.Id ? getEnvVariable('Application') + `/api/Notices/${this.props.match.params.routeid1}/Notice/${this.props.dataItem.Id}` : getEnvVariable('Application') + `/api/Notices/${this.props.match.params.routeid1}/Notice/${this.props.initialloadextrainfo.results["COMPANY_ID"]}`
        this.url3 = this.props.dataItem && this.props.dataItem.Id ? getEnvVariable('Application') + `/api/Notices/${this.props.match.params.routeid1}/Update/${this.props.dataItem.Id}` : getEnvVariable('Application') + `/api/Notices/${this.props.match.params.routeid1}/Update/${this.props.initialloadextrainfo.results["COMPANY_ID"]}`
        this.url4 = getEnvVariable('Application') + `/api/Notices/${this.props.match.params.routeid1}/Add`
        this.url5 = getEnvVariable('Application') + `/api/Application/FieldValues`;
        this.url6 = getEnvVariable('Application') + `/api/Attachments/${this.props.match.params.routeid1}/AttachmentsList`
        this.url7 = getEnvVariable('AdminConfig') + `/api/SitePermitTypeTemplateSettings/${this.props.match.params.routeid}/${this.props.initialloadextrainfo && this.props.initialloadextrainfo.results && this.props.initialloadextrainfo.results['APPLICATION_STATUS']}/GetAllForApplication`
        this.noticeporjectmessage = getEnvVariable('Application') + `/api/ProjectMessages/RulesByName?name=modulemaster.notices`;
        this.sidebartablesurl = getEnvVariable('Application') + `/api/SubModuleSideBar/All`;
        this.TemplateForm = React.createRef()
    }

    handleFieldChange = (event) => {
        let value = event.target.value;
        const name = event.target.name;
        if (name === 'canAttachPermit' || name === 'canAttachApplication') {
            value = event.target.checked === true ? "Y" : "N";
        }
        if (name === 'NoticeDescription') {
            this.setState({ errors: { ...this.state.errors, NoticeDescription: { error: false, errortext: null } } });
        }
        if (name === 'NoticeTypeId') {
            let selrecord = this.state.noticeTypeJson.find(x => x.Id === value);
            let sdtables = selrecord.SideBarTables ? selrecord.SideBarTables.split(',') : [];
            let sdtablesfilter = this.state.sideBarTablesRecords.filter(a => sdtables.includes(a.id.toString()));
            let sdtablesloadmap = sdtablesfilter ? sdtablesfilter.map(a => (a.name)) : [];
            if (selrecord) {
                this.setState({
                    [name]: value,
                    ParentId: selrecord.Id,
                    CanEditNoticeDate: selrecord.CanEditNoticeDate,
                    CanEditPastNoticeDate: selrecord.CanEditPastNoticeDate,
                    CanEditCurrentNoticeDate: selrecord.CanEditCurrentNoticeDate,
                    CanEditFutureNoticeDate: selrecord.CanEditFutureNoticeDate,
                    DisableNoticeDate: selrecord.CanEditNoticeDate === "Y" ? false : true,
                    showTemplate: true,
                    sideBarTables: sdtablesloadmap,
                    results: {
                        ...this.state.results,
                        [name]: value
                    }
                }, () => {
                    this.loadNoticeEntryData(this.state.sideBarTables)
                    this.validateFieldChange(name, value)
                })
            }

        }
        else if (name === 'NoticeDate') {
            console.log(event);
            this.setState({
                [name]: moment(value).format('MM/DD/YYYY'),
                results: {
                    ...this.state.results,
                    [name]: value
                }
            }, () => this.validateFieldChange(name, value))
        }

        else {
            this.setState({
                [name]: value,
                results: {
                    ...this.state.results,
                    [name]: value
                }
            }, () => this.validateFieldChange(name, value))
        }
    }

    handleAccordionChange = (panel) => (event, isExpanded) => {
        this.setState({ expanded: isExpanded ? panel : false })
    };


    handleNoticeDateChange = (e) => {
        let convertMoment = e ? moment(e, 'MM/DD/YYYY').format('MM/DD/YYYY') : null
        this.setState({
            "NoticeDate": convertMoment ? convertMoment : '',
            results: {
                ...this.state.results,
                "NoticeDate": convertMoment ? convertMoment : '',
            }
        }, () => this.validateFieldChange("NoticeDate", convertMoment))
    }

    componentDidMount() {
        let statusid = localStorage.getItem('applicationstatus') && JSON.parse(localStorage.getItem('applicationstatus'))
        this.url1 = (this.props && this.props.match && this.props.match.params && statusid) ? getEnvVariable('AdminConfig') + `/api/SitePermitTypesConfig/${this.props.match.params.routeid}/${statusid}/NoticeTypes` : getEnvVariable('Admin') + `/api/NoticeTypes/All/${this.props.match.params.routeid}`
        this.didmountinitialload();
    }
    didmountinitialload() {

        this.fetchData('GET', this.noticeporjectmessage, null, null).then(data => this.setState({
            ProjectMessages: data
        }))

        this.fetchData('GET', this.url1, null, null).then(data => this.setState({
            noticeTypeJson: data,

        }))



        this.fetchData('GET', `${this.sidebartablesurl}`, null, null)
            .then(data => {
                if (data && Array.isArray(data)) {
                    let tabls = data.map(a => ({ id: a.Id, name: a.TableName, value: a.Id }))
                    this.setState({
                        sideBarTablesRecords: tabls
                    })
                }
            })

        if (this.state.updateRecord) {
            this.fetchData('GET', this.url2, null, null)
                .then(data => {
                    console.log(data);
                    let selrecord = this.state.noticeTypeJson.find(x => x.Id === data.NoticeTypeId);
                    this.setState({
                        TemplateId: data.TemplateId,
                        NoticeTypeId: data.NoticeTypeId,
                        NoticeDate: data.NoticeDate,
                        CertifiedMailNo: data.CertifiedMailNo,
                        NoticeDescription: data.NoticeDescription,
                        canAttachPermit: data.canAttachPermit,
                        canAttachApplication: data.canAttachApplication,
                        results: data,
                        showTemplate: true,
                        CanEditNoticeDate: data.CanEditNoticeDate,
                        CanEditPastNoticeDate: data.CanEditPastNoticeDate,
                        CanEditCurrentNoticeDate: selrecord && selrecord.CanEditCurrentNoticeDate ? selrecord.CanEditCurrentNoticeDate : data.CanEditCurrentNoticeDate,
                        CanEditFutureNoticeDate: selrecord && selrecord.CanEditFutureNoticeDate ? selrecord.CanEditFutureNoticeDate : data.CanEditFutureNoticeDate,
                        DisableNoticeDate: selrecord && selrecord.CanEditNoticeDate === "Y" ? false : true
                    })
                }
                )
        }
        this.fetchData('GET', this.url6, null, null)
            .then(attachmentsdata => {
                if (attachmentsdata && attachmentsdata.length > 0 && Array.isArray(attachmentsdata)) {
                    let attachids = attachmentsdata.map(a => ({ id: a.id, name: a.name, value: a.id }))
                    this.setState({
                        attachmentsdata: attachids,
                        AttachmentIDS: attachids[0].id
                    })
                }
            })

        this.fetchData('GET', this.url7, null, null)
            .then(Templatedownloaddata => {
                if (Templatedownloaddata && Templatedownloaddata.length > 0 && Array.isArray(Templatedownloaddata)) {
                    let Apptemplateid = Templatedownloaddata.map(a => ({ id: a.AppDownloadsId, name: a.AppDownloadsGroup, value: a.AppDownloadsId }))
                    let Permittemplateid = Templatedownloaddata.map(a => ({ id: a.PermitDownloadsId, name: a.PermitDownloadsGroup, value: a.PermitDownloadsId }))
                    this.setState({
                        PermitTempalteID: Permittemplateid[0].id,
                        ApplicationTemplateID: Apptemplateid[0].id
                    })
                }
            })

    }

    loadNoticeEntryData(tables) {
        let urlcon = "";
        let body = {};
        let SideBarDataItems = {}
        let sideBarItems = tables;
        sideBarItems.forEach(element => {
            urlcon = urlcon + 'table=' + element + '&';
            if (this.props.childTable)
                SideBarDataItems[element] = this.props.childTable.Id
        });
        body['sitePermitTypeId'] = this.sitePermitTypeId
        body['masterTableId'] = this.masterTableId
        body['SideBarDataItems'] = SideBarDataItems;
        body['sideBarItems'] = sideBarItems;
        /* "sitePermitTypeId": 276,
  "masterTableId": 23343,
  "sideBarItems": [
    "Notes"
  ],
  "SideBarDataItems": {
    "Notes": 10312
  } */
        let url = this.url5 //+ "?" + urlcon;
        this.fetchData('POST', url, null, body, true).then(data => {
            this.setState({ noticeEntryData: data });
        })
    }

    // Description : for handling notice date format
    handleSaveWithTemplate = (event) => {
        if (this.state.results.NoticeDate === "" && this.state.NoticeDate === "") {
            this.setState({ errors: { ...this.state.errors, NoticeDate: { error: true, errortext: 'Notice Date is required' } } });
        }
        // if (this.state.CertifiedMailNo != "") {
        else {
            let convertMoment = moment(this.state.results.NoticeDate === "" || this.state.results.NoticeDate === null ? new Date() : this.state.results.NoticeDate).format('MM/DD/YYYY')
            this.setState({
                NoticeDate: convertMoment,
                results: {
                    ...this.state.results,
                    "NoticeDate": convertMoment
                }
            }, () => { this.handleSaveWithTemplateData(event) })
            //}
        }
    }

    handleSaveWithTemplateData = (event) => {
        console.log(this.state.results);
        var isValid = this.validateData(this.state.results);
        let descInValid = this.state.results.NoticeDescription.trim()
        let strlengthnotice = this.state.results.NoticeDescription != null ? this.state.results.NoticeDescription.length : ""
        let descLeading = this.state.results.NoticeDescription && this.state.results.NoticeDescription != "" && this.state.results.NoticeDescription.charAt(0) === " " || this.state.results.NoticeDescription.charAt(0) === '\n' || this.state.results.NoticeDescription.charAt(strlengthnotice - 1) === " "
        let descLength = this.state.results.NoticeDescription && this.state.results.NoticeDescription != "" && strlengthnotice > 4000
        if (descInValid.length === 0 && this.state.results.NoticeDescription && this.state.results.NoticeDescription != "") {
            this.props.snackbarMessage('Verify Validation Errors', 'error');
            this.setState({ errors: { ...this.state.errors, NoticeDescription: { error: true, errortext: 'Notice Description is not valid' } } });
        }
        else if (descLeading) {
            this.props.snackbarMessage('Verify Validation Errors', 'error');
            this.setState({ errors: { ...this.state.errors, NoticeDescription: { error: true, errortext: 'Leading or trailing spaces are not allowed' } } });
        }
        else if (descLength) {
            this.props.snackbarMessage('Verify Validation Errors', 'error');
            this.setState({ errors: { ...this.state.errors, NoticeDescription: { error: true, errortext: 'Notice Description length should be in the range of (1-4000)' } } });
        }
        else if (isValid) {

            this.TemplateForm.current.saveTemplate();
        } else {
            this.props.snackbarMessage('Invalid Data, Cannot be saved', 'error')
        }
    }

    handleSave = (event) => {
        console.log(this.state.results);
        //if(this.validateData(this.state.results)){}

        if (this.state.updateRecord) {
            this.fetchData('PUT', this.url3, null, this.state.results)

                .then(data => {
                    this.setState({
                        results: data,
                        opensnackbar: true
                    })

                    this.props.onClose();

                })
        }
        else {
            let finalresults = { ...this.state.results, AttachmentIDS: this.state.AttachmentIDS, PermitTempalteID: this.state.PermitTempalteID, ApplicationTemplateID: this.state.ApplicationTemplateID }
            this.fetchData('POST', this.url4, null, finalresults)

                .then(data => {
                    this.setState({
                        results: { data },
                        opensnackbar: true
                    })

                    this.props.onClose();


                })
        }
    }
    handleBack = () => {
        this.props.onClose()
    }

    validateData = (resultdata) => {
        var allErrors = this.validateStateResults(resultdata);
        if (Object.keys(allErrors).length > 0) {
            this.setState({ errors: allErrors })
            return false
        } else {
            this.setState({ errors: {} })
            return true
        }
    }

    validateStateResults = (resultdata) => {
        var allErrors = {};
        for (let [key, value] of Object.entries(resultdata)) {
            //console.log(`${key}: ${value}`);
            var errorJson = {}
            errorJson = { [key]: this.getDBValidationMessages(key) };
            const error = GlobalValidate(errorJson, { [key]: value })
            var isError = (Object.keys(error).length > 0 && error[key]['error']) ? error[key]['error'] : false;
            if (isError) {
                allErrors[key] = { error: true, errortext: error[key]['errortext'] };
            }
        }
        return allErrors;
    }

    validateFieldChange(key, value) {
        var allErrors = this.state.errors;
        var errorJson = {}
        errorJson = { [key]: this.getDBValidationMessages(key) };
        const error = GlobalValidate(errorJson, { [key]: value })
        var isError = (Object.keys(error).length > 0 && error[key]['error']) ? error[key]['error'] : false;
        if (isError) {
            allErrors[key] = { error: true, errortext: error[key]['errortext'] };
        } else {
            delete allErrors[key];
        }
        this.setState({ errors: allErrors });
    }

    getDBValidationMessages(key) {
        var keyinuppercase = key.toUpperCase();
        var validationSearchKey = "_" + keyinuppercase;
        var matchingKeys = this.state.ProjectMessages.filter(f => f['Key'].includes(validationSearchKey));;
        return matchingKeys;
    }

    //This is the template Id
    handleTemplateId = (data) => {

        console.log(this.state);
        this.setState({
            TemplateId: data.id,
            ParentId: 0,
            results: {
                ...this.state.results,
                "TemplateId": data.id
            }
        }, () => { this.handleSave(); })
    }

    fetchData(requestType, url, queryParam, body, alert = false) {

        const queryStr = queryParam != null ? `${url}?${queryParam}` : `${url}`

        if (requestType === "GET") {
            return GetData(queryStr);
        }
        else if (requestType === "POST") {
            return PostData(queryStr, body, alert);
        }
        else if (requestType === "PUT")
            return PutData(queryStr, body);
    }

    downloadExternalPDF = (id) => {
        //const pdfurl = getEnvVariable('Application')+`/api/Notices/${this.props.match.params.routeid1}/PDF/${this.props.match.params.routeid2}`
        const pdfurl = getEnvVariable('TemplateAPI') + `/api/Template/PDF/${this.state.TemplateId}`;
        this.fetchData('GET', pdfurl, null, null).then(data => {
            if (data) {

                var contentPDF = data.fileContentPDF;
                var filename = 'Notice_' + data.id + '.pdf';
                this.handleFileDownload(contentPDF, filename);
            }
        })
    }


    handleFileDownload = (contentPDF, filename) => {
        var url = "data:pdf;base64," + contentPDF;
        fetch(url)
            .then(res => res.blob())
            .then(function (blob) {
                console.log(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                var url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(url);
            })
    }

    toggleEmailDrawer = (open) => {
        this.setState({ SendToOwner: open });
    }

    SendNoticeEmail = () => {
        //if (this.state.noticeEntryData && this.state.noticeEntryData.length > 0) {
        if (this.state.EmailModel.Subject && this.state.EmailModel.Subject.length > 0) {

            let strlength = this.state.EmailModel.Subject != null ? this.state.EmailModel.Subject.length : ""
            let strbodylen = this.state.EmailModel.Body != null ? this.state.EmailModel.Body.length : ""
            let bodyInValid = this.state.EmailModel.Body.trim()
            let subLeading = this.state.EmailModel.Subject && this.state.EmailModel.Subject != "" && this.state.EmailModel.Subject.charAt(0) === " " || this.state.EmailModel.Subject.charAt(strlength - 1) === " "
            let subLength = this.state.EmailModel.Subject && this.state.EmailModel.Subject != "" && strlength > 100
            let bodyLeading = this.state.EmailModel.Body && this.state.EmailModel.Body != "" && this.state.EmailModel.Body.charAt(0) === " " || this.state.EmailModel.Body.charAt(strbodylen - 1) === " "
            let bodyLength = this.state.EmailModel.Body && this.state.EmailModel.Body != "" && strbodylen > 4000
            let subInValid = this.state.EmailModel.Subject.trim()

            if (subLeading || (subInValid.length === 0) || subLength || bodyLeading || bodyLength || (bodyInValid.length === 0)) {
                if (subLeading || subLength || (subInValid.length === 0)) {
                    if (subInValid.length === 0) {
                        this.props.snackbarMessage('Verify Validation Errors', 'error');
                        this.setState({ errors: { ...this.state.errors, Subject: { error: true, errortext: 'Subject is not valid' } } });
                    } else if (subLength) {
                        this.props.snackbarMessage('Verify Validation Errors', 'error');
                        this.setState({ errors: { ...this.state.errors, Subject: { error: true, errortext: 'Subject length should be in the range of (1-100)' } } });
                    } else if (subLeading) {
                        this.props.snackbarMessage('Verify Validation Errors', 'error');
                        this.setState({ errors: { ...this.state.errors, Subject: { error: true, errortext: 'Leading or trailing spaces are not allowed' } } });
                    }
                }
                if (bodyLeading || bodyLength || (bodyInValid.length === 0)) {
                    if (bodyInValid.length === 0) {
                        this.props.snackbarMessage('Verify Validation Errors', 'error');
                        this.setState({ errorForBody: { ...this.state.errorForBody, Body: { error: true, errortext: 'Body is not valid' } } });
                    } else if (bodyLeading) {
                        this.props.snackbarMessage('Verify Validation Errors', 'error');
                        this.setState({ errorForBody: { ...this.state.errorForBody, Body: { error: true, errortext: 'Leading or trailing spaces are not allowed' } } });
                    } else if (bodyLength) {
                        this.props.snackbarMessage('Verify Validation Errors', 'error');
                        this.setState({ errorForBody: { ...this.state.errorForBody, Body: { error: true, errortext: 'Body length should be in the range of (1-4000)' } } });
                    }
                }
            }
            else {

                let ownerid = this.props.initialloadextrainfo.results["COMPANY_ID"]
                let ownerdetailsurl = getEnvVariable('MasterAdmin') + `/api/CompanyInternal/` + getEnvVariable('ModuleKey') + `/` + ownerid

                GetData(ownerdetailsurl, false).then(resp => {
                    if (resp && resp.Contacts && resp.Contacts.PrimaryContactAddress && resp.Contacts.PrimaryContactAddress.Email) {
                        let mailContent = { ...this.state.EmailModel, "MailTo": [resp.Contacts.PrimaryContactAddress.Email], "Notices": [this.state.NoticeTypeId] }
                        let emaiLUrl = getEnvVariable('TemplateAPI') + `/api/Template/SendNotice`;
                        PostData(emaiLUrl, mailContent, false, 'Notice sent to ('+resp.Contacts.PrimaryContactAddress.Email+')').then(res => {
                            this.toggleEmailDrawer(false);;
                            this.setState({ EmailModel: { Subject: '', Body: '', MailTo: [], Notices: [] } })
                        })
                    }
                })
                // this.fetchData('GET', ownerdetailsurl, null, null).then(data => {
                //     if (data) {
                //         var email = data.Contacts.PrimaryContactAddress.Email;
                //         if (email) {
                //             var model = this.state.EmailModel;
                //             model.MailTo = []
                //             model.MailTo.push(email)
                //             //const sendurl = getEnvVariable('TemplateAPI')+`/api/Template/SendEmail/${this.state.TemplateId}`;
                //             model.TemplateList.push(this.state.TemplateId, this.state.PermitTempalteID, this.state.ApplicationTemplateID)
                //             const sendurl = getEnvVariable('TemplateAPI') + `/api/Template/SendNoticeEmail`;
                //             this.fetchData('POST', sendurl, null, model, true)

                //                 .then(data => {
                //                     console.log(data);
                //                     this.props.snackbarMessage('Notice Sent Successfully(' + email + ')', 'success');
                //                     this.toggleEmailDrawer(false);

                //                     // this.setState({ EmailModel: { Subject: '', Body: '', MailTo: [], TemplateList: [] } })
                //                 })
                //         }

                //     }
                // })
            }
        } if ((this.state.EmailModel.Subject === null) || (this.state.EmailModel.Subject === undefined) || (this.state.EmailModel.Subject === '')) {
            this.props.snackbarMessage('Verify Validation Errors', 'error');
            this.setState({ errors: { ...this.state.errors, Subject: { error: true, errortext: 'Subject is required' } } });
        }
        if ((this.state.EmailModel.Body === null) || (this.state.EmailModel.Body === undefined) || (this.state.EmailModel.Body === '')) {
            this.props.snackbarMessage('Verify Validation Errors', 'error');
            this.setState({ errorForBody: { ...this.state.errorForBody, Body: { error: true, errortext: 'Body is required' } } });
        }

        //}
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dataItem != this.props.dataItem) {
            this.setState({ tabvalue: 0 })
            this.url2 = getEnvVariable('Application') + `/api/Notices/${this.props.match.params.routeid1}/Notice/${this.props.dataItem.Id}`
            this.url3 = getEnvVariable('Application') + `/api/Notices/${this.props.match.params.routeid1}/Update/${this.props.dataItem.Id}`
            this.didmountinitialload();
        }
    }

    handleEmailFieldChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        if (name === 'Subject') {
            this.setState({ errors: { ...this.state.errors, Subject: { error: false, errortext: null } } });
        }
        else if (name === 'Body') {
            this.setState({ errorForBody: { ...this.state.errorForBody, Body: { error: false, errortext: null } } });
        }
        this.setState({
            EmailModel: {
                ...this.state.EmailModel,
                [name]: value
            }
        })
    }
    handleChange = (event, newValue) => {
        this.setState({ tabvalue: newValue, EmailModel: { Subject: '', Body: '', MailTo: [], TemplateList: [] } })
    }

    handleEdit = () => {
        this.setState({ edit: false })
    }

    vieweditnotice = () => {
        const { NoticeDate, updateRecord, edit, tabvalue, results, CanEditPastNoticeDate, CanEditCurrentNoticeDate, CanEditFutureNoticeDate, DisableNoticeDate, sideBarTables, PermitTempalteID } = this.state;
        const caneditfields = true;//!(this.state.results.TemplateId > 0) ;
        const noticeTypeError = this.state.errors && this.state.errors.NoticeTypeId && this.state.errors.NoticeTypeId.error ? this.state.errors.NoticeTypeId.errortext : null
        const certifiedMailError = this.state.errors && this.state.errors.CertifiedMailNo && this.state.errors.CertifiedMailNo.error ? this.state.errors.CertifiedMailNo.errortext : null
        const noticeDateError = this.state.errors && this.state.errors.NoticeDate && this.state.errors.NoticeDate.error ? this.state.errors.NoticeDate.errortext : null
        const noticeDescriptionError = this.state.errors && this.state.errors.NoticeDescription && this.state.errors.NoticeDescription.error ? this.state.errors.NoticeDescription.errortext : null
        let approvedflag = this.props && this.props.match && this.props.match.params && this.props.initialloadextrainfo.results["APPROVED_FLAG"] ? this.props.initialloadextrainfo.results["APPROVED_FLAG"] : "N"
        let hidesavebutton = approvedflag === "Y" ? true : false;
        let roleaccess = this.props.accessPermissions && this.props.accessPermissions.moduleDistrictAccess && this.props.accessPermissions.moduleDistrictAccess ? Object.values(this.props.accessPermissions.moduleDistrictAccess).includes('Add') ? 'CanAdd' : Object.values(this.props.accessPermissions.moduleDistrictAccess).includes('Update') ? 'CanEdit' : Object.values(this.props.accessPermissions.moduleDistrictAccess).includes('Read') ? 'CanRead' : 'CanAdd' : 'CanAdd'
        let portal = this.props.accessPermissions ? this.props.accessPermissions.portal : null
        let canAdd = (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanAdd" ? true : (portal === "E") ? true : false
        let canEdit = (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanEdit" ? true : (portal === "E") ? true : false
        const canShowTemplate = (this.state.showTemplate && ((this.state.ParentId && this.state.ParentId > 0
            && Array.isArray(this.state.noticeEntryData) && this.state.noticeEntryData.length > 0)
            || (this.state.updateRecord)))
        return <div className="col-12">

            <div className="row mt-3">
                <div className="col-md-12">
                    {updateRecord && <FormHeader title="Entrance Permit - Notices" handleEdit={this.handleEdit} handleSave={this.handleSaveWithTemplate} editbutton={(this.props.accessPermissions.portal === 'E' || (hidesavebutton)) ? false : (canAdd || canEdit) ? caneditfields ? edit ? true : false : false : false} savebutton={edit ? false : (this.props.accessPermissions.portal === 'E' || (hidesavebutton)) ? false : (canAdd || canEdit) ? caneditfields : false} breadcrumbs={editbreadcrumbs} accessLevelPermissions={this.props.accessLevelPermissions} backbutton={true} handleBack={this.handleBack} designprops={true} dataItem={this.props.dataItem} />}
                    {edit && updateRecord && <div>
                        <div>
                            <Typography variant="caption" className="text-secondary">
                                Notice Type
                            </Typography>
                            {this.state.NoticeTypeId && this.state.NoticeTypeId != "" && this.state.noticeTypeJson && this.state.noticeTypeJson.length > 0 && this.state.noticeTypeJson.map(menu => {
                                if (menu.Id === this.state.NoticeTypeId) {
                                    return <Typography variant={'body2'} color={'textPrimary'} display="block" className="fw-bold eps-content-wrap">
                                        {menu.Name}
                                    </Typography>
                                }
                                return null
                            })
                            }
                        </div>
                        <div><Typography variant="caption" className="text-secondary">
                            Notice Date
                        </Typography>
                            <Typography variant={'body2'} color={'textPrimary'} display="block" className="fw-bold eps-content-wrap">
                                {((NoticeDate && NoticeDate === '') ? new Date() : moment(NoticeDate).format('MM/DD/YYYY')) || null}
                            </Typography></div>
                        <div><Typography variant="caption" className="text-secondary">
                            Certified Mail Number
                        </Typography>
                            <Typography variant={'body2'} color={'textPrimary'} display="block" className="fw-bold eps-content-wrap">
                                {this.state.CertifiedMailNo}
                            </Typography></div>
                        <div><Typography variant="caption" className="text-secondary">
                            Notice Description
                        </Typography>
                            <Typography variant={'body2'} color={'textPrimary'} display="block" className="fw-bold eps-content-wrap">
                                {this.state.NoticeDescription}
                            </Typography>
                            {/* {this.props.match.params.key != 'VIOLATIONS' && <div> <Typography variant="caption" className="text-secondary">
                                Can Attach Permit
                            </Typography>
                                <Typography variant={'body2'} color={'textPrimary'} display="block" className="fw-bold eps-content-wrap">
                                    {this.state.canAttachPermit === 'Y' ? "Yes" : "No"}
                                </Typography>
                                <Typography variant="caption" className="text-secondary">
                                    Can Attach Application
                                </Typography>
                                <Typography variant={'body2'} color={'textPrimary'} display="block" className="fw-bold eps-content-wrap">
                                    {this.state.canAttachApplication === 'Y' ? "Yes" : "No"}
                                </Typography></div>} */}
                        </div></div>}
                    {!edit && <div><CFormDropDown value={this.state.NoticeTypeId} dropdownvalues={this.state.noticeTypeJson} required={true}
                        name="NoticeTypeId" label="Notice Type" transformProps={transformProps}
                        handleFieldChange={this.handleFieldChange} disabled={updateRecord ? true : false} helperText={noticeTypeError} error={noticeTypeError ? true : false} />
                        {/* <CDateTimePicker value={this.state.NoticeDate} type = "datetime-local"
                                            name="NoticeDate" options = {label}
                                            handleFieldChange={this.handleFieldChange}/> */}
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                clearable
                                variant="filled"
                                ampm={false}
                                value={((NoticeDate === null) ? new Date() : NoticeDate) || null}
                                disablePast={CanEditPastNoticeDate === "Y" ? false : true}
                                disableFuture={CanEditFutureNoticeDate === "Y" ? false : true}
                                onChange={this.handleNoticeDateChange}
                                label={"Notice Date"}
                                name={"NoticeDate"}
                                format="MM/DD/YYYY"
                                InputLabelProps={{
                                    shrink: true,
                                    required: true
                                }} disabled={DisableNoticeDate}
                                error={noticeDateError ? true : false}
                                helperText={noticeDateError}
                            />
                        </MuiPickersUtilsProvider>
                        <CFormTextBox value={this.state.CertifiedMailNo} /*required={true}*/
                            name="CertifiedMailNo" label="Certified Mail Number" xtype="number"
                            handleFieldChange={this.handleFieldChange} disabled={!caneditfields} helperText={certifiedMailError} error={certifiedMailError ? true : false} />

                        <CFormTextBox value={this.state.NoticeDescription}
                            name="NoticeDescription" label="Notice Description"
                            handleFieldChange={this.handleFieldChange} multiline={true} disabled={!caneditfields} helperText={noticeDescriptionError} error={noticeDescriptionError ? true : false} />
                        {/* <Cautocompletetagsnotification name="AttachmentsList" value={this.state.AttachmentIDS} handleFieldChange={this.handleFieldChange} options={{ label: 'Select Attachment(s)' }} dropdownvalues={this.state.attachmentsdata} /> */}
                        <CheckboxesTags name="AttachmentIDS" value={this.state.AttachmentIDS ? this.state.AttachmentIDS.toString() : ''} ismulti={true} label='Select Attachment(s)' handleFieldValue={this.handleFieldChange}
                            options={{ label: 'Select Attachment(s)' }} items={this.state.attachmentsdata} extraProps={{
                                transformProps: {
                                    label: 'name',
                                    value: 'id',
                                }
                            }} />
                        {/* {this.props.match.params.key != 'VIOLATIONS' && <div>  <CFormCheckBox value={this.state.canAttachPermit === "Y" ? true : false} name="canAttachPermit" label="Attach Permit" handleFieldChange={this.handleFieldChange} transformProps={this.transformProps} />
                            &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                            <CFormCheckBox value={this.state.canAttachApplication === "Y" ? true : false} name="canAttachApplication" label="Attach Application" handleFieldChange={this.handleFieldChange} transformProps={this.transformProps} /> </div>} */}
                    </div>}
                </div>
            </div>
            <div className="col-4 mt-2" dir="rtl">
            </div>
            <div className="col-12 mt-2">
                {canShowTemplate &&
                    <Template ref={this.TemplateForm} fullheight={true}  handleTemplateId={this.handleTemplateId}
                        entryData={this.state.noticeEntryData}
                        parentid={this.state.ParentId}
                        isAdmin={false}
                        templateId={this.state.TemplateId}
                        isEnabled={(getEnvVariable('AccessPortal') === 'E') ? false : true}
                        sideBarTables={sideBarTables}
                        refId={this.state.NoticeTypeId}
                        refType={'NT'}
                    />}
            </div>
            {updateRecord && <div className="row pt-1">
                <div className="col-sm-6" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D' }}>Created By : </span> {results.CreatedByName}</div>
                <div className="col-sm-6" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D' }}>Created On : </span> {moment(results.CreatedOn).format('MM/DD/YYYY hh:mm:ss A')}</div>
                {results.UpdatedBy === null ? ('') : (<div className="col-sm-6" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D' }}>Updated By : </span> {results.UpdatedByName}</div>)}
                {results.UpdatedOn === null ? ('') : (<div className="col-sm-6" style={{ color: '#6C757D', fontSize: '12px' }}><span style={{ color: '#6C757D' }}>Updated On : </span> {moment(results.UpdatedOn).format('MM/DD/YYYY hh:mm:ss A')}</div>)}
            </div>}


        </div>
    }
    render() {
        const { compmap, onFullScreen, onExitFullScreen, fullscreen, exitfullscreen, onNextItemClick, onPrevItemClick } = this.props;
        const { NoticeDate, updateRecord, edit, tabvalue, results, CanEditPastNoticeDate, CanEditCurrentNoticeDate, CanEditFutureNoticeDate, DisableNoticeDate, sideBarTables, PermitTempalteID } = this.state;
        const caneditfields = true;//!(this.state.results.TemplateId > 0) ;
        const label = { 'label': "Notice Date" }
        const noticeTypeError = this.state.errors && this.state.errors.NoticeTypeId && this.state.errors.NoticeTypeId.error ? this.state.errors.NoticeTypeId.errortext : null
        const certifiedMailError = this.state.errors && this.state.errors.CertifiedMailNo && this.state.errors.CertifiedMailNo.error ? this.state.errors.CertifiedMailNo.errortext : null
        const noticeDateError = this.state.errors && this.state.errors.NoticeDate && this.state.errors.NoticeDate.error ? this.state.errors.NoticeDate.errortext : null
        const noticeDescriptionError = this.state.errors && this.state.errors.NoticeDescription && this.state.errors.NoticeDescription.error ? this.state.errors.NoticeDescription.errortext : null
        const subjectError = this.state.errors && this.state.errors.Subject && this.state.errors.Subject.error ? this.state.errors.Subject.errortext : null
        const bodyError = this.state.errorForBody && this.state.errorForBody.Body && this.state.errorForBody.Body.error ? this.state.errorForBody.Body.errortext : null
        let approvedflag = this.props && this.props.match && this.props.match.params && this.props.initialloadextrainfo.results["APPROVED_FLAG"] ? this.props.initialloadextrainfo.results["APPROVED_FLAG"] : "N"
        let hidesavebutton = approvedflag === "Y" ? true : false;
        let roleaccess = this.props.accessPermissions && this.props.accessPermissions.moduleDistrictAccess && this.props.accessPermissions.moduleDistrictAccess ? Object.values(this.props.accessPermissions.moduleDistrictAccess).includes('Add') ? 'CanAdd' : Object.values(this.props.accessPermissions.moduleDistrictAccess).includes('Update') ? 'CanEdit' : Object.values(this.props.accessPermissions.moduleDistrictAccess).includes('Read') ? 'CanRead' : 'CanAdd' : 'CanAdd'
        let portal = this.props.accessPermissions ? this.props.accessPermissions.portal : null
        let canAdd = (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanAdd" ? true : (portal === "E") ? true : false
        let canEdit = (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanEdit" ? true : (portal === "E") ? true : false
        let canRead = (!roleaccess || !portal) ? true : portal === "I" && roleaccess === "CanRead" ? true : (portal === "E") ? true : false
        const canShowTemplate = (this.state.showTemplate && ((this.state.ParentId && this.state.ParentId > 0
            && Array.isArray(this.state.noticeEntryData) && this.state.noticeEntryData.length > 0)
            || (this.state.updateRecord)))
        return (
            <Fragment>
                <FormHeader title="Entrance Permit - Notices" handleSave={this.handleSaveWithTemplate} savebutton={(updateRecord || this.state.noticeEntryData.length <= 0) ? false : (canAdd || canEdit) ? caneditfields : false} breadcrumbs={updateRecord ? editbreadcrumbs : addbreadcrumbs} accessLevelPermissions={this.props.accessLevelPermissions} onFullScreen={onFullScreen} onExitFullScreen={onExitFullScreen} fullscreen={fullscreen} exitfullscreen={exitfullscreen} backbutton={true} handleBack={this.handleBack} dataItem={this.props.dataItem} navigateup={updateRecord ? true : false} navigatedown={updateRecord ? true : false} onNextItemClick={onNextItemClick} onPrevItemClick={onPrevItemClick} />
                {updateRecord && <div>
                    <Accordion expanded={this.state.expanded === 'panel1'} onChange={this.handleAccordionChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography>Notice Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {this.vieweditnotice()}
                        </AccordionDetails>
                    </Accordion>
                    {this.state.TemplateId && this.state.TemplateId > 0 && portal !== 'E' && <Accordion expanded={this.state.expanded === 'panel2'}
                        onChange={this.handleAccordionChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography >Send to Applicant</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>

                                <div className="row mt-5 ml-2">

                                    <div className="col-10">
                                        <CFormTextBox value={this.state.EmailModel.Subject}
                                            name="Subject" label="Subject" required={true}
                                            handleFieldChange={this.handleEmailFieldChange}
                                            helperText={subjectError} error={subjectError ? true : false}
                                        />

                                        <CFormTextBox value={this.state.EmailModel.Body}
                                            name="Body" label="Email Body" required={true}
                                            handleFieldChange={this.handleEmailFieldChange}
                                            multiline={true}
                                            helperText={bodyError} error={bodyError ? true : false}
                                            rows="6"
                                        />
                                        {/* {this.props.match.params.key != 'VIOLATIONS' && <div> <Typography variant="caption" className="text-secondary">
                                Can Attach Permit
                            </Typography>
                                <Typography variant={'body2'} color={'textPrimary'} display="block" className="fw-bold eps-content-wrap">
                                    {this.state.canAttachPermit === 'Y' ? "Yes" : "No"}
                                </Typography>
                                <Typography variant="caption" className="text-secondary">
                                    Can Attach Application
                                </Typography>
                                <Typography variant={'body2'} color={'textPrimary'} display="block" className="fw-bold eps-content-wrap">
                                    {this.state.canAttachApplication === 'Y' ? "Yes" : "No"}
                                </Typography></div>} */}
                                        {/* {this.props.match.params.key != 'VIOLATIONS' && <div>  <CFormCheckBox value={this.state.canAttachPermit === "Y" ? true : false} name="canAttachPermit" label="Attach Permit" handleFieldChange={this.handleFieldChange} transformProps={this.transformProps} />
                                            &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                                            <CFormCheckBox value={this.state.canAttachApplication === "Y" ? true : false} name="canAttachApplication" label="Attach Application" handleFieldChange={this.handleFieldChange} transformProps={this.transformProps} /> </div>} */}

                                        <Button className="my-2 " variant="contained" color="primary" onClick={this.SendNoticeEmail}>
                                            Send Email
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>}
                    {this.state.TemplateId && this.state.TemplateId > 0 && portal != 'E' && <Accordion expanded={this.state.expanded === 'panel3'} onChange={this.handleAccordionChange('panel3')}>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography >Notice History</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <Grid options={this.historygridjson} dataItem={this.props.dataItem} />
                        </AccordionDetails>
                    </Accordion>}
                </div>
                }
                {!updateRecord && this.vieweditnotice()}
            </Fragment>

        )
    }
}
const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

NoticeCommon = connect(null, mapActions)(NoticeCommon)
export default withRouter(NoticeCommon)