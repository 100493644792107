/**
      *********************************************************
      Description: Textbox component. .
      Required props:  type,value.
      limitations    : N/A.
      *********************************************************
**/

import React from "react";


export default function Link(props) {
    const { value, handleFieldValue, multiline, extraProps, rowsMax, shareProps, error, required, tooltip,
        contexthelp, disabled, changefieldto, linkurl, FieldIsSpellCheck, ...otherProps } = props
    const { label, helperText, name, xtype, fullWidth, ...otherConfigs } = otherProps;

    return (
        disabled ? <div style={{ color: 'rgba(0, 0, 0, 0.38)', fontSize: '12px' }} disabled={true} className="pointer" > <u>{label} </u> </div> :
            <div style={{ color: 'blue', fontSize: '12px' }} onClick={() => { window.open((value || linkurl), '_blank').focus(); }} className="pointer" > <u>{label} </u> </div>
    )
}