import { ExposureTwoTone } from "@material-ui/icons"
import { getEnvVariable } from "../../modules/environmentalList"

export const NotAdminRegList = {
    'WRS': {
        "epsgeneral":
            [
                {
                    "type": "menu",
                    "title": "Notifications",
                    "icon": "notifications_active",
                    "materialicon": "notifications_active",
                    "link": "/notifications",
                    "key": "Notifications",
                    "style": {
                        'color': '#d50000'
                    }
                },
                {
                    "type": "menu",
                    "title": "Search",
                    "icon": "search",
                    "materialicon": "search",
                    "link": "/search",
                    "key": "Search",
                    "style": {
                        'color': '#61A5DC'
                    }
                },
                {
                    "type": "menu",
                    "title": "Records",
                    "key": "newapplicationdetails",
                    "link": "#/Administration/applications",
                    "icon": "fa fa-file-text",
                    "materialicon": "new_releases",
                    "style": {
                        'color': '#9c27b0'
                    }
                },

                {
                    "type": "menu",
                    "title": "Reports",
                    "icon": "fa fa-files-o",
                    "link": getEnvVariable('ReportsUI'),
                    "key": "reports",
                    "newtab": true,
                    "materialicon": "post_add",
                    "style": {
                        'color': '#827717'
                    }
                },
                {
                    "type": "menu",
                    "title": "Contractors",
                    "icon": "fa fa-building",
                    "link": "#/Administration/companies",
                    "key": "companies",
                    "materialicon": "apartment",
                    "style": {
                        'color': '#ea156d'
                    }
                },
            ],
        "Adminlist":
            [

                {
                    "type": "menu",
                    "title": "Applicants",
                    "icon": "fa fa-building",
                    "link": "#/Administration/companies",
                    "key": "companies",
                    "materialicon": "apartment",
                    "style": {
                        'color': '#ea156d'
                    }
                },
                {
                    "type": "menu",
                    "title": "Users",
                    "icon": "fa fa-users",
                    "link": "/Administration/agencyusers",
                    "key": "AgencyUsers",
                    "materialicon": "person",
                    "style": {
                        'color': '#01579b'
                    }
                },
                // {
                //     "type":"menu",
                //     "title": "Authorization",
                //     "key": "Authorization",
                //     "link": "/Administration/authorizations",
                //     "icon": "fa fa-globe",
                //     "materialicon": "vpn_key",
                //     "style": {
                //         'color': '#c158dc'
                //     }
                // },
                // {
                //     "type": "menu",
                //     "title": "Registrations",
                //     "key": "registrationrequest",
                //     "link": "#/Administration/registrationrequest",
                //     "icon": "fa fa-caret-square-o-right",
                //     "materialicon": "how_to_vote",
                //     "style": {
                //         'color': '#9fa8da'
                //     }
                // },
            ],
    },

}
