import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DefaultSettings from '../../controls/components/settings';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import { groupBy } from '@progress/kendo-data-query';
import { setExpandedState, setGroupIds } from '@progress/kendo-react-data-tools';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { React, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getEnvVariable } from '../../../modules/environmentalList';
import Checkbox from '../../controls/components/checkbox';
import DropDown from '../../controls/components/dropdown';
import TextBox from '../../controls/components/textbox';
import { createRequest, requestApi } from '../../controls/helpers/rest';
import Editable from './editable';
import { MyCommandCell } from "./myCommandCell";
import TemplateSettings from './templatesettings';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import NoticeIcon from '@material-ui/icons/Note';
import GetAppIcon from '@material-ui/icons/GetApp';

const editField = "inEdit";

const JSON = {
    notices: {
        header: {
            name: "header",
            type: 'header',
            title: 'Status Settings',
            components: [
                {
                    type: 'breadcrumbs',
                    items: [
                        { "link": "Status Settings - Notices", materialicon: 'note', className: 'eps-breadcrumb-item ', class: 'text-light' },
                    ]
                }
            ],
            actions: [
                {
                    type: 'icon',
                    icon: 'back',
                    name: 'back',
                    hideon: 'back',
                    hidevalue: false,
                    title: 'Back'
                },
                {
                    type: 'icon',
                    icon: 'save',
                    name: 'save',
                    hideon: 'save',
                    hidevalue: false,
                    title: 'Save'
                },

            ],
        },
        id: 'NoticesTypeId',
        get: [{
            url: getEnvVariable('AdminConfig') + `/api/SitePermitTypesConfig/ConfigNoticeTypes/{Id}`,
            type: 'get',
            "routeProps": [
                {
                    name: "Id",
                    key: "Id",
                }
            ],
        },
        {
            url: getEnvVariable('AdminConfig') + `/api/SitePermitTypeNotices/{Id}/{sitePermitTypeAppStatusId}/GetAllNotices`,
            type: 'get',
            "routeProps": [
                {
                    name: "Id",
                    key: "Id",
                },
                {
                    name: "sitePermitTypeAppStatusId",
                    key: "sitePermitTypeAppStatusId",
                }
            ],
        },],
        addorupdate: [{
            url: getEnvVariable('AdminConfig') + `/api/SitePermitTypeNotices/{Id}/{sitePermitTypeAppStatusId}/AddOrUpdate`,
            type: 'post',
            "routeProps": [
                {
                    name: "Id",
                    key: "Id",
                },
                {
                    name: "sitePermitTypeAppStatusId",
                    key: "sitePermitTypeAppStatusId",
                }
            ],
        }]

    },

    attachments: {
        id: 'AttachmentTypeId',
        header: {
            name: "header",
            type: 'header',
            title: 'Status Settings',
            components: [
                {
                    type: 'breadcrumbs',
                    items: [
                        { "link": "Status Settings - Attachments", materialicon: 'attach_file', className: 'eps-breadcrumb-item ', class: 'text-light' },
                    ]
                }
            ],
            actions: [
                {
                    type: 'icon',
                    icon: 'back',
                    name: 'back',
                    hideon: 'back',
                    hidevalue: false,
                    title: 'Back'
                },
                {
                    type: 'icon',
                    icon: 'save',
                    name: 'save',
                    hideon: 'save',
                    hidevalue: false,
                    title: 'Save'
                },
            ],
        },
        get: [{
            url: getEnvVariable('AdminConfig') + `/api/SitePermitTypesConfig/{Id}/AttachmentTypes`,
            type: 'get',
            "routeProps": [
                {
                    name: "Id",
                    key: "Id",
                }
            ],
        }, {
            url: getEnvVariable('AdminConfig') + `/api/SitePermitTypeAttachments/{Id}/{sitePermitTypeAppStatusId}/GetAllAttachments`,
            type: 'get',
            "routeProps": [
                {
                    name: "Id",
                    key: "Id"
                },
                {
                    name: "sitePermitTypeAppStatusId",
                    key: "sitePermitTypeAppStatusId",
                }
            ],
        },],
        addorupdate: [{
            url: getEnvVariable('AdminConfig') + `/api/SitePermitTypeAttachments/{Id}/{sitePermitTypeAppStatusId}/AddOrUpdate`,
            type: 'post',
            "routeProps": [
                {
                    name: "Id",
                    key: "Id",
                },
                {
                    name: "sitePermitTypeAppStatusId",
                    key: "sitePermitTypeAppStatusId",
                }
            ],
        }]

    }
}


const initialGroup = [{
    field: 'AppStatusLabel'
},];

const processWithGroups = (data, group) => {
    const newDataState = groupBy(data, group);
    setGroupIds({
        data: newDataState,
        group: group
    });
    return newDataState;
};

export default function ApplicationStatu(props) {
    const [status, setStatus] = useState([])
    const [reload, setReload] = useState(false)
    const [TemplateSettingsOpen, setTemplateSettingsOpen] = useState(null)
    const [edit, setEdit] = useState(false)
    const [add, setAdd] = useState(false)
    const [dataItem, setDataitem] = useState(null)
    const [search, setSearch] = useState(null)
    const [moduleJSON, setModuleJSon] = useState(null)
    //Grid Functions
    const [data, setData] = useState([]);
    const [orgData, setOrgData] = useState([]);
    const [group, setGroup] = useState(initialGroup);
    const [collapsedState, setCollapsedState] = useState([]);

    const params = useParams()

    useEffect(() => {
        const read = [
            {
                url: getEnvVariable('Admin') + `/api/ApplicationStatus/Active`,
                type: 'get'
            },
        ]
        Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(res => {
            const [active] = res;// add buttonsettings
            let tempStatus = active.map(each => {
                each['StatusCodeDesc'] = each.StatusName + `- (${each.StatusCode})`
                return each
            })
            setStatus(tempStatus)
            handleTransision(active)
        }).catch(ex => {
            console.error(ex)
        })
    }, [])


    const CommandCell = (props) => {
        if (props.rowType === "groupHeader") {
            return null;
        }
        else return <MyCommandCell {...props} handleDelete={handleDelete} subGrid={(obj) => { getTableView(obj) }}
            setTemplateSettingsOpen={(id) => { setTemplateSettingsOpen(id) }}
            handleEdit={(dataItem) => {
                let newData = data.map(item => item.Id === dataItem.Id ?
                    { ...item, inEdit: true } : item);
                setData(newData)
                console.log(data)
            }} handleSave={handleSave} editField={editField} />
    };


    const itemChange = (event, dataItem) => {
        const field = event.target.name || '';
        const newData = data.map(item => item.Id === dataItem.Id ? {
            ...item,
            [field]: event.target.value
        } : item);
        setData(newData);
        setReload(!reload)
    };

    const cellRender = (tdElement, cellProps) => {
        if (cellProps.rowType === "groupHeader") {
            let row = cellProps.dataItem.items.find(each => each.AppStatusLabel === cellProps.dataItem.value)
            return (
                <td className="col-sm-10 m-4" style={{ borderColor: 'white' }}>
                    <IconButton title="Notice Settings" color="primary" component="span" onClick={() => getTableView({ id: row.Id, name: 'notices' })}>
                        <NoticeIcon />
                    </IconButton>&nbsp;&nbsp;&nbsp;&nbsp;
                    {cellProps.dataItem.value}
                </td>
            )
        }
        return tdElement;
    };

    const DropDownCell = (props) => {
        const { dataItem } = props;
        const field = props.field || '';
        const dataValue = dataItem[field] === null ? '' : dataItem[field];
        let objT = null;
        if (field === 'TransmisionStatusId')
            objT = (status.find(x => parseInt(x.Id) === parseInt(dataValue)) || {});
        else if (field === 'IsDefault')
            objT = (data.find(x => parseInt(x.Id) === parseInt(dataValue)) || {})

        if (props.rowType === "groupHeader") {
            return null
        }
        else if (field === 'TransmisionStatusId') return <td>
            {dataItem.inEdit ? <DropDown name='TransmisionStatusId' items={status} value={dataValue || null}
                handleFieldValue={(e) => itemChange(e, dataItem)} extraProps={{ transformProps: { label: 'StatusCodeDesc', value: 'Id' } }} /> :
                objT && (objT.StatusName + `- (${objT.StatusCode})`) || ''}
        </td>
        else return <td>
            {dataItem.inEdit ? <Checkbox name="IsDefault" value={dataValue || null}
                handleFieldValue={(e) => itemChange({ target: { name: 'IsDefault', value: e.target.checked ? 'Y' : 'N' } }, dataItem)} extraProps={{
                    transformProps: { "Y": true, "N": false, true: "Y", false: "N" }
                }} /> : dataItem.IsDefault === 'Y' ? 'Yes' : 'No'}
        </td>;
    };

    const onExpandChange = useCallback(event => {
        const item = event.dataItem;
        if (item.groupId) {
            const newCollapsedIds = !event.value ? [...collapsedState, item.groupId] : collapsedState.filter(groupId => groupId !== item.groupId);
            setCollapsedState(newCollapsedIds);
        }
    }, [collapsedState]);

    const handleSave = (result) => {
        if(result['ApplicationStatusId'] && result['TransmisionStatusId'])   
        {
        result['IsDefault'] = result && result['IsDefault'] == true ? 'Y' : result['IsDefault'] == 'Y' ? 'Y' : 'N'
        const read = [
            {
                url: result && result.Id ? getEnvVariable('AdminConfig') + `/api/SitePermitTypesStatus/Update` :
                    getEnvVariable('AdminConfig') + `/api/SitePermitTypesStatus/${params.Id}/Create`,
                type: 'post',
                message:result && result.Id ? 'Updated Successfully' : 'Saved Successfully',
            },
        ]
        delete result['AppStatusLabel']
        Promise.all(read.map(each => requestApi(createRequest(each, result)))).then(res => {
            setDataitem(null)
            setEdit(false)
            setAdd(false)
            handleTransision(status)
        })
    }
    else
    {
        DefaultSettings.getAlert().show("Please provide status details", 'error');
    }
    }


    const handleTransision = (active = []) => {
        const read = [{
            url: getEnvVariable('AdminConfig') + `/api/SitePermitTypesStatus/${props.dataItem.Id}/All`,
            type: 'get'
        }]
        Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(res => {
            const [addedstatus] = res
            let tempData = {};
            tempData = addedstatus.map(each => {
                let obj = (active.find(x => parseInt(x.Id) === parseInt(each.ApplicationStatusId)) || {})
                let objT = (active.find(x => parseInt(x.Id) === parseInt(each.TransmisionStatusId)) || {});
                each['AppStatusLabel'] = obj.StatusName + `- (${obj.StatusCode})`;
                each['TransStatusLabel'] = objT.StatusName + `- (${objT.StatusCode})`;
                each['IsDefaultDesc'] = each.IsDefault === 'Y' ? 'Yes' : 'No'
                return each
            })
            setData(tempData)
            setOrgData(tempData)
        })
    }

    const handleDelete = (dataItem) => {
        const read = [{
            url: getEnvVariable('AdminConfig') + `/api/SitePermitTypesStatus/Delete/` + dataItem,
            type: 'delete'
        }]
        Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(res => {
            handleTransision(status)
        }).catch(ex => {
            console.error(ex)
        })
    }

    const handleSearch = () => {
        if (search !== '') {
            const filterData = orgData.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(search.toLowerCase())
            })
            setData(filterData)
            setReload(!reload)
        }
        else setData(orgData)
    }

    const handleSearchInfo = (evt) => {
        const { name, value } = evt.target;
        setSearch(value)

    }

    const newData = setExpandedState({
        data: processWithGroups(data, initialGroup),
        collapsedIds: collapsedState
    });

    const getTableView = (obj) => {
        if (obj) {
            let temp = { ...JSON }
            let id = obj.id
            if (obj.name) {
                setModuleJSon({ json: temp[obj.name], id: id })
                return true
            }
        }
        else return null
    }


    return (
        <div className="h-100">
            {reload}
            <div >
                <TextBox className="col-sm-10 m-4" handleFieldValue={handleSearchInfo} label="Search.." name="search" value={search} />
                <Fab className='float-end' size="small" title='Search' color="primary" onClick={handleSearch} > <SearchIcon /></Fab>&nbsp;
                <Fab className='float-end' size="small" title='Add' color="primary" onClick={() => { setAdd(true) }} > <AddIcon /></Fab>
            </div>
            <div className='clearfix' />
            {add && <Paper variant="outlined" className='my-2 p-1' >
                <StatusAdd handleSave={handleSave} dataItem={dataItem} status={status} closeEdit={() => { setAdd(false) }} />
            </Paper>}
            <Grid
                style={{ height: window.innerHeight - 230, width: '100%' }} data={newData} editField={editField} groupable={true} resizable
                group={group} onExpandChange={onExpandChange} cellRender={cellRender}
                expandField="expanded">
                <Column cell={CommandCell} title="Actions" width="170px" editable={false} />
                <Column field="TransmisionStatusId" title="Status Transition" width="350px" cell={DropDownCell} />
                <Column field="IsDefault" title="Is Default" width="160px" cell={DropDownCell} />

            </Grid>
            <small className='float-right text-secondary'>Total Results: {data.length || 0}</small>
            {moduleJSON && moduleJSON.json && <Editable sitePermitTypeAppStatusId={moduleJSON.id} close={() => { setModuleJSon(null); setReload(reload) }} ModuleJSON={moduleJSON.json} />}
            {TemplateSettingsOpen && <TemplateSettings sitePermitTypeAppStatusId={TemplateSettingsOpen} closeTemplate={() =>
                setTemplateSettingsOpen(null)} />}
        </div>
    )
}

function StatusAdd({ dataItem = {}, handleSave, status, closeEdit }) {

    const [newDataItem, setDataitem] = useState(dataItem || {});
    const [reload, setReload] = useState(false);

    const handleFieldChange = (evt) => {
        const { name, value, checked } = evt.target;
        newDataItem[name] = value;
        if (name === 'IsDefault') {
            newDataItem[name] = (checked === true || checked === false) ? checked : value;
        }
        if (name === 'ApplicationStatusId') {
            let obj = status.find(x => parseInt(x.Id) === parseInt(newDataItem.ApplicationStatusId))
            newDataItem['AppStatusLabel'] = obj.StatusName + `- (${obj.StatusCode})`
        }
        else if (name === 'TransmisionStatusId') {
            let obj = status.find(x => parseInt(x.Id) === parseInt(newDataItem.TransmisionStatusId))
            newDataItem['TransStatusLabel'] = obj.StatusName + `- (${obj.StatusCode})`
        }
        newDataItem['IsDefaultDesc'] = (newDataItem.IsDefault === 'Y' || newDataItem.IsDefault === true) ? 'Yes' : 'No'
        if (newDataItem && !newDataItem.IsDefault) {
            newDataItem['IsDefault'] = 'N'
        }
        setDataitem(newDataItem)
        setReload(!reload)
    }

    return <div className='row '>
        <DropDown className='col-4 ' name='ApplicationStatusId' label="Status" items={status} value={(newDataItem && newDataItem.ApplicationStatusId) || null}
            handleFieldValue={(e) => handleFieldChange(e)} extraProps={{
                transformProps: { label: 'StatusCodeDesc', value: 'Id' }
            }} />
        <DropDown className='col-4 ' name='TransmisionStatusId' label="Transition Status" items={status} value={(newDataItem && newDataItem.TransmisionStatusId) || null}
            handleFieldValue={(e) => handleFieldChange(e)} extraProps={{
                transformProps: { label: 'StatusCodeDesc', value: 'Id' }
            }} />
        <Checkbox className='col-4 ' label='Is Default?' name="IsDefault" value={(newDataItem && newDataItem.IsDefault) || null}
            handleFieldValue={(e) => handleFieldChange(e)} extraProps={{
                transformProps: { "Y": true, "N": false, true: "Y", false: "N" }
            }} />

        <Button autoFocus onClick={() => closeEdit()} color="primary">
            <IconButton color="inherit" aria-label="Close">
                <CloseIcon />
            </IconButton></Button>
        <Button autoFocus onClick={() => handleSave(newDataItem)} color="primary">
            {/* onClick={() => handleEdit(dataItem)} */}
            <IconButton title="Save" color="primary" aria-label="upload picture" component="span" ></IconButton>
            <SaveIcon />
        </Button>
    </div>
}




