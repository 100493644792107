import ProposedLandContainer from '../../../controls/jsons/proposedlanduse/proposedlandusegrid';
import { requestApi } from './utilities';

export const handlecustomCondition = async (propvalues) => {
    let condition = null;
    let resultsdata = null;
    let urldata = propvalues.options && propvalues.options.crud && propvalues.options.crud.customcondition ? propvalues.options.crud.customcondition : null
    if (urldata) {
        let ids = []
        let requesturl = urldata.url
        if (urldata.matchProps || urldata.routeProps) {
            const tempsaveprops = urldata.matchProps || urldata.routeProps
            tempsaveprops.forEach(each => {
                const { value } = each
                ids.push({ key: value, value: propvalues.match.params[value] })
            })
        }
        ids.forEach(each => {
            const { key, value } = each;
            requesturl = `${requesturl.replace(`{${key}}`, value)}`;
        })
        requesturl = {[urldata.type] : {"url" : requesturl}}
        await requestApi(requesturl, null, urldata.body, null, true).then(results => {
            resultsdata = results[0]
            condition = conditionsHandle(propvalues, results[0]) .condition 
        })
    }
    else {
        condition = conditionsHandle(propvalues).condition
    }
    return {"condition" : condition, "results" : resultsdata }
}

export const conditionsHandle = (propdata, reqdata) => {
    let condition = null;
    propdata.options.hidecondition && propdata.options.hidecondition.forEach(element => {
        const { prevcondition, Name, Value, getfrom, notEquals } = element
        let getdatafrom = getfrom == "localstorage" ? 'localstorage' :  getfrom == "requestdata" ? reqdata : getfrom == "accessPermissions" ? propdata.accessPermissions : getfrom == "dataItem" ? propdata.dataItem : getfrom == "params" && propdata && propdata.match && propdata.match.params? propdata.match.params : null
        if (getdatafrom) {
            condition = prevcondition && prevcondition == 'or' && condition == true ? true : prevcondition == 'and' && condition == false ? false : (notEquals ? (getfrom == "localstorage" ? localStorage.getItem(Name) != Value : getdatafrom[Name] != Value) : (getfrom == "localstorage" ? localStorage.getItem(Name) == Value : getdatafrom[Name] == Value)) ? true : false
        }
    });
    return {"condition" : condition}
}