
export const validation = (values, transformProps, fieldKeys) => {
    const { removesubfieldname, addsubfieldname } = transformProps ? transformProps : {}
    const removesubfieldnamelength = removesubfieldname ? (removesubfieldname.length) : 0
    return values.reduce((accum, each) => {
        const { validations, required, tooltip, contexthelp, sectionToolTips } = accum
        const { Key, Type, Order } = each
        let name = Key.slice(removesubfieldnamelength)
        if (addsubfieldname) name = addsubfieldname + name
        if (fieldKeys && fieldKeys.length > 0) {
            fieldKeys.forEach(element => {
                if (element.toUpperCase() === name)
                    name = element
            });
        }
        if (Type === 'R') required.push(name)
        if (Type === 'T') tooltip[name] = each.Message || ''
        if (Type === 'P') sectionToolTips[name] = each.Message || '' // add section tooltips
        if (Type === 'H') contexthelp[name] = each.Message || ''
        if (validations[name]) validations[name].push(each)
        else validations[name] = [each]
        // if (Type === 'T') toolTipFieldNamesInUpperCase[validationname] = each.Message || ''
        return accum
    }, { validations: {}, required: [], tooltip: {}, contexthelp: {}, sectionToolTips: {} })
}

export const objectConversion = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    let a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return '';
        }
    }
    return o;
}
