import React, { Component } from 'react';
import CTextBox from '../../../form/ctextbox';
import CRadioGroup from '../../../form/cradiogroup';
import CCheckBoxGroup from '../../../form/ccheckboxgroup';
import CLabel from '../../../form/clabel';
import CCheckbox from '../../../form/cformcheckbox';
import CDateTime from '../../../form/materialpicker'
import CDropDown from '../../../form/cdropdown';
import CDate from '../../../form/materialdatepicker'
import CTime from '../../../form/materialtimepicker'
import CMultiDropdown from '../../../../controls/components/checkautocomplete'
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { Button } from '@material-ui/core';

class FieldsConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: this.props.options
        };
    }

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        const { FieldName, section, field } = this.state.options
        const { OnSettings } = this.props
        const { FieldType } = field
        return (
            <span>
                <span className="fieldComponents">
                    {
                        (FieldType === "TB" || FieldType === "RT" || FieldType === "TBB" ||
                            FieldType === "TA" || FieldType === "N" || FieldType === "DN" || FieldType === "P" || FieldType === "C")
                        && <CTextBox options={{ "name": FieldName, label: FieldName, initialvalue: "", disabled: true }} currentValue={{ value: "", error: "" }} />
                    }{
                        (FieldType === "HM" || FieldType === "LB" || FieldType === "L" || FieldType === "ULB" || FieldType === 'G') && <CLabel label={FieldName} name={FieldName} options={{ "name": FieldName, label: FieldName }} />
                    }
                    {
                        (FieldType === "RB") && <CRadioGroup options={{ "name": FieldName, label: FieldName, value: "1", disabled: true, items: [{ "options": { "label": "Option 1", value: "1" } }, { "options": { "label": "Option 2", value: "2" } }] }} currentValue={{ value: "", error: "" }} />
                    }
                    {
                        (FieldType === "B" || FieldType === "RC") && <CRadioGroup options={{ "name": FieldName, label: FieldName, value: "N", disabled: true, items: [{ "options": { "label": "Yes", value: "Y" } }, { "options": { "label": "No", value: "N" } }] }} currentValue={{ value: "", error: "" }} />
                    }
                    {
                        (FieldType === "CSC") && <CCheckbox label={FieldName} options={{ "name": FieldName, value: "N", disabled: true, items: [{ "options": { "label": "Yes", value: "Y" } }, { "options": { "label": "No", value: "N" } }] }} currentValue={{ value: "", error: "" }} />
                    }

                    {
                        (FieldType === "CB" || FieldType === "CF") && <CCheckBoxGroup options={{ "name": FieldName, label: FieldName, value: "1", disabled: true }} dropdownvalues={[{ "options": { "label": "Option 1", value: "1" } }, { "options": { "label": "Option 2", value: "2" } }]} currentValue={{ value: "", error: "" }} />
                    }
                    {
                        (FieldType === "DS" || FieldType === "CS" || FieldType === "CM" || FieldType === "AC") && <CDropDown options={{ "name": FieldName, label: FieldName, initialvalue: "1", disabled: true, values: [{ "name": `select ${FieldName}`, value: "1" }] }} currentValue={{ value: "1", error: "" }} />
                    }
                    {
                        (FieldType === "DT") && <CDateTime options={{ "name": FieldName, label: FieldName, initialvalue: "1", disabled: true }} value={new Date()} />
                    }
                    {
                        (FieldType === "D") && <CDate options={{ "name": FieldName, label: FieldName, initialvalue: "1", disabled: true }} value={new Date()} />
                    }
                    {
                        (FieldType === "T") && <CTime options={{ "name": FieldName, label: FieldName, initialvalue: "1", disabled: true }} value={new Date()} />
                    }
                    {
                        (FieldType === "DM") && <CMultiDropdown name={FieldName} label={FieldName} value="1,2" disabled={true} items={[{ 'name': `select ${FieldName} 1`, 'id': '1' }, { 'name': `field 2`, 'id': '2' }]} extraProps={{ transformProps: { label: 'name', value: 'id' } }} />
                    }
                    {
                        (FieldType === "BU" || FieldType === "RBT" || FieldType === "LB-BU") && <Button variant='outlined' disabled={true}>{FieldName}</Button>
                    }
                </span>
                <span className="fieldSettings m-2" onClick={(ev) => OnSettings(ev, { ...section }, { ...field })} title="Settings" >         <IconButton aria-label="delete" >
                    <SettingsIcon fontSize="small" />
                </IconButton></span>
            </span>
        );
    }
}

export default FieldsConfig;