const workflow = {
    "type": "workflow",
    "key": "workflowapplication",
    "order": 35,
    "options": {
        //"route": "/Administration",
        "routes": [
            {
                "route": "/Administration/applicationreview",
                "routeParams": ":routeid",
            },
            {
                "route": "",
                "routeParams": ":routeid1"
            },
            {
                "route": "/Workflow"
            },
        ],

        styles: {
         //   width: '80vw'
        },
        "params": {
            "SitePermitTypeAppSettingsId": {
                getvaluefromtype: 'props',
                paramname: 'SitePermitTypeAppSettingsId',
            },
            "WorkflowId": {
                getvaluefromtype: 'props',
                paramname: 'WorkflowId',
            },
            "ParentWorkflowId": {
                getvaluefromtype: 'props',
                paramname: 'ParentWorkflowId',
            },
           // "ParentWorkflowId": 0,
            "ApplicationID": {
                getvaluefromtype: 'props',
                paramname: 'ApplicationID',
            },
            "siteID": {
                getvaluefromtype: 'props',
                paramname: 'siteID',
            },
            "RegionId":{
                getvaluefromtype:'props',//route,rest,value,prop,
                paramname:'RegionId',
            }, 
            isAdmin: false
        },
        headerProps: {
            "backbutton": true,
        },
        "back": {
            "popupback": true
        },
    }
};

export default workflow;