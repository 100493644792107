import { Button, Fab, Icon, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Pageview from '@material-ui/icons/Info';
import SettingsIcon from '@material-ui/icons/Settings';
import { process, toDataSourceRequestString } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid as KendoGrids, GridColumn as Column, GridColumnMenuFilter } from "@progress/kendo-react-grid";
import { Splitter } from "@progress/kendo-react-layout";
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Asynchronous from '../../core/controls/components/autocomplete';
import MultiDropDown from '../../core/controls/components/checkautocomplete';
import Breadcrumbs from '../../core/controls/components/breadcrumbs';
import CheckBox from '../../core/controls/components/checkbox';
import { CustomColumnMenu } from '../../core/controls/components/customColumnMenu';
import DatePickers from '../../core/controls/components/datepicker';
import Dropdown from '../../core/controls/components/dropdown';
import GridHeader from '../../core/controls/components/kendoheader';
import NumberFormat from '../../core/controls/components/numberformat';
import RadioButtonsGroup from '../../core/controls/components/radiogroup';
import DefaultSettings from '../../core/controls/components/settings';
import Textbox from '../../core/controls/components/textbox';
import { createRequest, requestApi } from '../../core/controls/helpers/rest';
import Map from '../../core/modules/map/map';
import AddRecordMapJson from '../../json/map/permitsgridmap';
import { getEnvVariable } from '../environmentalList';
import { filter } from 'lodash';

const breadcrumbs = {
    type: 'breadcrumbs',
    items: [
        { "link": "Dashboard", "tab": 'D', "materialicon": "dashboard", href: getEnvVariable('AccessPortal') === 'I' ? getEnvVariable('InternalBasepath') : getEnvVariable('ExternalBasepath'), className: 'breadcrumb-item text-dark', class: 'text-dark'},
        { "link": "Search", href: "#/search", "materialicon": "search", className: 'breadcrumb-item', class: 'text-light'}
    ],
}

const PermitOptions = {
    'AMES': [

        {
            FieldKey: 'module', FieldDisplayName: 'Module', UIFieldTypeComponent: 'DS', url: {
                url: getEnvVariable('MasterAdmin') + `/api/Module/${getEnvVariable('ModuleKey')}/SubModule/All/Active`,
                type: 'get'
            }, className: "col-4", extraProps: {
                transformProps: {
                    label: 'SubModuleName',
                    value: 'Key',
                }
            }
        },
        {
            FieldKey: 'SITE_PERMIT_TYPE', className: "col-4", FieldDisplayName: 'Request Type', UIFieldTypeComponent: 'DS', url: {
                url: getEnvVariable('AdminConfig') + `/api/SitePermitTypes/SubModuleKey/Active/{module}`,
                type: 'get',
                matchProps: [
                    {

                        name: "module",
                        key: "module",
                    },
                ]
            }, className: "col-4", dependency: 'module', //disabled: true,//disabledBy: 'module',
            extraProps: {
                transformProps: {
                    label: 'Name',
                    value: 'Id',
                }
            }
        }
    ],
    'WRS': [

        {
            FieldKey: 'module', FieldDisplayName: 'Module', UIFieldTypeComponent: 'DS', url: {
                url: getEnvVariable('MasterAdmin') + `/api/Module/${getEnvVariable('ModuleKey')}/SubModule/All/Active`,
                type: 'get'
            }, className: "col-4", extraProps: {
                transformProps: {
                    label: 'SubModuleName',
                    value: 'Key',
                }
            }
        },
        {
            FieldKey: 'SITE_PERMIT_TYPE', className: "col-4", FieldDisplayName: 'Request Type', UIFieldTypeComponent: 'DS', url: {
                url: getEnvVariable('AdminConfig') + `/api/SitePermitTypes/SubModuleKey/Active/{module}`,
                type: 'get',
                matchProps: [
                    {

                        name: "module",
                        key: "module",
                    },
                ]
            }, className: "col-4", dependency: 'module', //disabled: true,//disabledBy: 'module',
            extraProps: {
                transformProps: {
                    label: 'Name',
                    value: 'Id',
                }
            }
        }
    ]
}
const QuickSearchOptions = {
    'AMES': [
        {
            FieldKey: 'SEARCH_CRITERIA', FieldDisplayName: 'Search Criteria', UIFieldTypeComponent: 'DS', className: "col-4", extraProps: {
                transformProps: {
                    label: 'Name',
                    value: 'Value',
                }
            },
        },
        {
            FieldKey: 'SearchKey', FieldDisplayName: 'Search Key', UIFieldTypeComponent: 'TB', className: "col-4",
            conditions: [{ name: 'SEARCH_CRITERIA', value: '0' }]

        }
    ],
    'WRS': [
        {
            FieldKey: 'SEARCH_CRITERIA', FieldDisplayName: 'Search Criteria', UIFieldTypeComponent: 'DS', className: "col-4", extraProps: {
                transformProps: {
                    label: 'Name',
                    value: 'Value',
                }
            },
        },
        {
            FieldKey: 'SearchKey', FieldDisplayName: 'Search Key', UIFieldTypeComponent: 'TB', className: "col-4",
            conditions: [{ name: 'SEARCH_CRITERIA', value: '0' }]
        },
        {
            FieldKey: 'SearchKey', FieldDisplayName: 'Search Key', UIFieldTypeComponent: 'DM', className: "col-4", ismulti: true,
            extraProps: {
                transformProps: {
                    label: 'Name',
                    value: 'Id',
                }
            }
        },
        {
            FieldKey: 'SearchKey', FieldDisplayName: 'Search Key', UIFieldTypeComponent: 'DS', className: "col-4",
            extraProps: {
                transformProps: {
                    label: 'StatusName',
                    value: 'Id',
                }
            }
        }
    ]
}

const headerProps = {
    title: "Contact",

    actions: [
        {
            type: 'fab',
            icon: 'clear',
            name: 'clear',
            color: 'primary',
            title: 'Clear Filters',
            menucolor: '#22a9b3',
            hideon: 'clear',
            hidevalue: false
        },

        {
            type: 'menu',
            icon: 'excel',
            name: 'excel',
            color: 'primary',
            title: 'Export to Excel',
            menucolor: '#c90685',
            hideon: 'excel',
            hidevalue: false,
            items: [{
                name: 'Export Current Page',
                action: 'filtercurrent'
            },
            {
                name: 'Export All Pages',
                action: 'filterall'
            }
            ]
        },

    ]
}

const defaultProps = {
    transformProps: {
        label: 'name',
        value: 'value',
    }
}

function otherURLS(key) {
    switch (key) {
        case 'COMPANY_ID': return {
            optionsurl: {
                url: getEnvVariable('MasterAdmin') + `/api/CompanyInternal/` + getEnvVariable('ModuleKey') + `/Search/`,
                type: 'get',

            },
            transformProps: {
                label: 'Name',
                value: 'Id',
            }
        }
        default: return {
            transformProps: {
                label: 'name',
                value: 'value',
            }
        }
    }
}


export default function Search() {
    const [permitSearch, setPermitSearch] = useState({})
    const [total, setTotal] = useState(null)
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(true);
    const [permitOptions, setPermitOptions] = useState(QuickSearchOptions[getEnvVariable('ModuleKey')] || [])
    const [items, setItems] = useState([])
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [selectionmapdata, setSelectionmapdata] = useState([])
    const [reload, setReload] = useState(false)
    const [searchType, setsearchType] = useState('0');
    const [changedModule, setChangedModule] = useState(false)
    const [waitMap, setWaitMap] = useState(false)
    const [quicksearchitems, setQuickSearchItems] = useState([])
    const [panes, setPanes] = React.useState([
        {
            size: "50%",
            min: "20px",
            collapsible: true,
        },
        {},
        {
            size: "20%",
            min: "20px",
            collapsible: true,
        },
    ]);

    const onChange = (event) => {
        setPanes(event.newState);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'searchType':
                setsearchType(value);
                handleClear()
                if (value === '0') {
                    setPermitOptions(QuickSearchOptions[getEnvVariable('ModuleKey')]) 
                    setItems(quicksearchitems)             
                } else setPermitOptions(PermitOptions[getEnvVariable('ModuleKey')])
                break;
            case 'SearchKey':
                if (permitSearch && permitSearch[permitSearch['SEARCH_CRITERIA']])
                    delete permitSearch[permitSearch['SEARCH_CRITERIA']]
                setPermitSearch({ ...permitSearch, [permitSearch['SEARCH_CRITERIA']]: value, SearchKey: value });
                break;
            case 'module':
                setItems({ ...items, 'SITE_PERMIT_TYPE': [] })
                if (!showAdvancedSearch) {
                    handleModuleChange(name, value);
                }
                else {
                    handleModuleChange(name, value);
                    let tempResultdata = permitSearch
                    setPermitSearch({ ...tempResultdata, [name]: value });
                    setReload(!reload)
                }
                break;
            default:
                let tempResult = permitSearch
                if (name === 'SEARCH_CRITERIA')
                    tempResult = {}
                setPermitSearch({ ...tempResult, [name]: value });
                setReload(!reload)
        }

    }

    const handleModuleChange = (name, value) => {
        let permitOptionsData = permitOptions.filter(res => {
            if (res.FieldKey && (res.FieldKey == "module" || res.FieldKey == "SITE_PERMIT_TYPE"))
                return res
        })
        setPermitOptions(permitOptionsData);
        setItems({ ...items, 'SITE_PERMIT_TYPE': [] })
        setPermitSearch({ [name]: value });
        setChangedModule(true);
        setShowAdvancedSearch(true)
        //setReload(!reload)
    }

    useEffect(() => {
        if (changedModule) {
            handleAdvanceSearch();
        }
    }, [changedModule, permitSearch])


    const handleClear = () => {
        setItems({ ...items, 'SITE_PERMIT_TYPE': [] })
        setPermitSearch({});
        setData([]);
        setColumns([]);
        setShowAdvancedSearch(true)
        setPermitOptions(QuickSearchOptions[getEnvVariable('ModuleKey')] || []);
    }

    useEffect(() => {
        if (permitOptions && showAdvancedSearch) {
            let read = [];
            let names = []
            permitOptions.forEach(each => {
                if (each.dependency && each.url) {
                    if (permitSearch[each.dependency]) {
                        read.push(each.url);
                        names.push(each.FieldKey)
                    }
                }
                else {
                    if (!items[each.FieldKey] && each.url) {
                        read.push(each.url)
                        names.push(each.FieldKey)
                    }
                }
            })
            if (read && (read.length > 0)) {
                Promise.all(read.map(each => requestApi(createRequest(each, permitSearch)).catch(error => { return error; }))).then(resp => {
                    let tempItems = items
                    names.forEach((each, index) => {
                        tempItems[each] = resp[index]
                    })
                    setItems(tempItems)
                    setReload(!reload)
                })
            }
        }
    }, [permitSearch, searchType])


    useEffect(() => {
        const read = [
            {
                url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=SEARCH_CRITERIA`,
                type: 'get'

            },
            {
                url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=SEARCH_TYPE`,
                type: 'get'
            },
            {
                url: getEnvVariable('Admin') + `/api/Sites/${getEnvVariable('ModuleKey')}/All/Active`,
                type: 'get'
            },
            {
                url: getEnvVariable('Admin') + `/api/ApplicationStatus/Active`,
                type: 'get'
            },
            // {
            //     url: getEnvVariable('Application') + `/api/Application/All/ActiveNames`,
            //     type: 'get'
            // }
        ]
        Promise.all(read.map(each => requestApi(createRequest(each)).catch(error => { return error; }))).then(res => {
            const [CriteriaItems = [], SearchList, Sites = [], Status = []] = res;
            let tempItems = items
            tempItems['SEARCH_CRITERIA'] = getEnvVariable('AccessPortal') === 'E' ? CriteriaItems.filter(each => each.Name !== 'Applicant Name') : CriteriaItems
            tempItems['SearchList'] = SearchList
            tempItems['SITE_ID'] = Sites
            tempItems['APPLICATION_STATUS'] = Status.filter(e=>e.Stage != "DRAFT")
             //tempItems['ASSIGNED_TO'] = Assigned
             setQuickSearchItems(tempItems)
            setItems(tempItems)
            setReload(!reload)
        })
    }, [])


    const disableField = (param) => {
        let disabled = param.disabled || false
        //let  hasPadding = param.disabled || false
        if (param.conditions) {
            param.conditions.map(each => {
                if (each.value === permitSearch[each.name]) {
                    disabled = true
                }
            })
        }
        return disabled
    }

    const hasPaddingS = (param) => {
       // let const hasPaddingS = (param) => {
        let hasPadding = param.hasPadding || true
        //let  hasPadding = param.disabled || false
        if (param.conditions) {
            param.conditions.map(each => {
                if (each.value === permitSearch[each.name]) {
                    hasPadding = false
                }
            })
        }
        return hasPadding 
    }

    

    const renderSwitch = (param) => {
        let type = param.UIFieldTypeComponent;
        let params = {
            ...param,
            value: permitSearch[param.FieldKey] || param.value || '',
            label: param.FieldDisplayName,
            name: param.FieldKey,
            className:  'col-12 m-2' ,
            
        };
        let disabled = disableField(param);
        let hasPadding = hasPaddingS(param)
        let permitsearchkey =
            permitSearch &&
            permitSearch['SEARCH_CRITERIA'] &&
            params.FieldKey === 'SearchKey'
                ? permitSearch['SEARCH_CRITERIA']
                : null;
        switch (type) {
            case 'DM': return (<div className='col-8'> <MultiDropDown {...params} handleFieldValue={handleChange} disabled={disabled} items={ permitsearchkey ? items[permitsearchkey]: param.items || items[params.FieldKey]  } extraProps={params.extraProps || defaultProps} /> </div>   );
            case 'DS': return (<div className='col-4'> <Dropdown  {...params} handleFieldValue={handleChange} disabled={disabled} items={permitsearchkey ? items[permitsearchkey] : param.items || items[params.FieldKey] }  extraProps={params.extraProps || defaultProps}  /> </div> );
            case 'CF': return (<div className='col-4'> <CheckBox {...params} handleFieldValue={handleChange} disabled={disabled}  items={items[params.FieldKey]} extraProps={params.extraProps || defaultProps}  /> </div> );
            case 'D': return (<div className='col-4' > <DatePickers {...params} handleFieldValue={handleChange} disabled={disabled} hasPadding={hasPadding} extraProps={params.extraProps || defaultProps} /> </div>);
            case 'DT': return (<div className='col-4'> <DatePickers {...params} handleFieldValue={handleChange} disabled={disabled} hasPadding={hasPadding} extraProps={params.extraProps || defaultProps} /> </div>);
            case 'AC': return (<div className='col-4'> <Asynchronous {...params} items={ param.items || items[params.FieldKey] || []} handleFieldValue={handleChange} disabled={disabled} extraProps={params.extraProps || { ...otherURLS(params.name) }}/> </div>);
            case 'N': return (<div className='col-4'> <NumberFormat {...params} handleFieldValue={handleChange} disabled={disabled} items={items[params.FieldKey]} extraProps={params.extraProps || defaultProps} /> </div>);
            case 'RG': return (<div className='col-4'> <RadioButtonsGroup {...params} handleFieldValue={handleChange} disabled={disabled} items={param.items || items[params.FieldKey]} extraProps={params.extraProps || defaultProps} /></div>);
            default: return ( <div className='col-4'> <Textbox {...params} handleFieldValue={handleChange} disabled={disabled} extraProps={params.extraProps || defaultProps}/> </div>);
        }
    };

    const handleAdvanceSearch = () => {
        if (permitSearch.module && permitSearch.SITE_PERMIT_TYPE) {
            const read = [{
                url: getEnvVariable('Application') + `/api/${getEnvVariable('ModuleKey')}SitePermitSectionFieldsView/SearchFields/{module}/{SITE_PERMIT_TYPE}`,
                type: 'get',
                matchProps: [
                    {
                        name: "module",
                        key: "module",
                    },
                    {

                        name: "SITE_PERMIT_TYPE",
                        key: "SITE_PERMIT_TYPE",
                    },
                ]
            },
            {
                url: getEnvVariable('Application') + `/api/DomainsMapper/ByParams/{SITE_PERMIT_TYPE}?autoComplete=true`,
                type: 'post',
                alert: true,
                matchProps: [
                    {
                        name: "SITE_PERMIT_TYPE",
                        key: "SITE_PERMIT_TYPE",
                    },
                ]
            },]
            Promise.all(read.map(each => requestApi(createRequest(each, permitSearch)).catch(error => { return error; }))).then(res => {
                const [searchdata = [], domains] = res;
                var wrssearchdata = searchdata.filter(e=> e.FieldKey != 'SITE_ID' && e.FieldKey != 'ASSIGNED_TO' && e.FieldKey != 'COUNTY_HWY_DEPT_RESPONDER_NAME' && e.FieldKey != 'CONTRACTOR_RESPONDER_NAME' )
                let tempdomains
                if( getEnvVariable('ModuleKey') === "WRS" && domains && domains.APPLICATION_STATUS.length >1)
                {
                    domains.APPLICATION_STATUS = domains.APPLICATION_STATUS.filter(e=>e.name != "Draft");
                    tempdomains = domains 
                }
                let tempsearchdata = getEnvVariable('ModuleKey') === "WRS" ? wrssearchdata: searchdata
                if (searchdata && searchdata.length > 0 && getEnvVariable('AccessPortal') === 'E') {
                    tempsearchdata = searchdata.filter(each => (each.FieldKey !== 'COMPANY_ID'))
                }
                let tempFields = [...permitOptions, ...tempsearchdata]
                setChangedModule(false);
                setPermitOptions(tempFields);
                setShowAdvancedSearch(false)
                getEnvVariable('ModuleKey') === "WRS" && domains && domains.APPLICATION_STATUS.length > 1 ? setItems({ ...items, ...domains }) : setItems({ ...items, ...tempdomains })
                setReload(!reload)
            })
        }
    }

    const handleSearch = (datastate, message) => {
        let tempResult = permitSearch;
        if ((searchType === '0' && tempResult.SEARCH_CRITERIA === '0' || tempResult.SearchKey) ||
            searchType === '1' && tempResult.module && permitSearch.SITE_PERMIT_TYPE
        ) {
            let tempSearchKey = tempResult.SEARCH_CRITERIA;
            let tempModule = tempResult.module
            let tempSearchKeyresult = tempResult.SearchKey
            delete tempResult.SEARCH_CRITERIA
            delete tempResult.SearchKey
            delete tempResult.module
            tempResult = {
                searchParams: (!showAdvancedSearch || searchType === '0') ? permitSearch : {}, operator:
                    permitSearch.operator || 'and', IsQuickSearch: (searchType === '0' ? "Y" : "N")
            }
            if(getEnvVariable('ModuleKey') == "WRS"){
                tempResult.searchParams = tempSearchKey == "0" && !tempSearchKeyresult ?  {"SITE_PERMIT_TYPE_ID" : '1' } : tempResult.searchParams
            }
            let dataState = datastate ? datastate : { skip: 0, take: 20 }
            const queryStr = `${toDataSourceRequestString(dataState)}`;
            const read = [{
                url: getEnvVariable('Application') + `/api/Application/DynamicSearchGrid/${tempModule || 'APPLICATIONS'}/${permitSearch.SITE_PERMIT_TYPE || '-1'}?${queryStr}`, // -1
                type: 'post',
                message: message ? "Search Completed" : null,
                //message: dataState.sort && (dataState.sort.length> 0 || dataState.sort.length == 0) ?  null : "Search Completed",
            },
            {
                url: getEnvVariable('Application') + `/api/${searchType === '0' ? `${getEnvVariable('ModuleKey')}SitePermitSectionFieldsView/AllRecordsGridFields` : `SitePermitSectionFieldsView/GridFields/${permitSearch.module || '-1'}/${permitSearch.SITE_PERMIT_TYPE || '-1'}`}`, // change
                type: 'get',
                matchProps: [
                    {
                        name: "module",
                        key: "module",
                    },
                    {

                        name: "SITE_PERMIT_TYPE",
                        key: "SITE_PERMIT_TYPE",
                    },
                ]
            }]
            Promise.all(read.map(each => requestApi(createRequest(each, tempResult)).catch(error => { return error; }))).then(res => {
                const [result, tempcolumns] = res;
                let customcolumns=[];
                setTotal(result.total)
                setData(result && result.data.map(dataItem => Object.assign({ selected: false }, dataItem)));
                if(tempcolumns && tempcolumns.length>0 && getEnvVariable('ModuleKey')==='WRS'){
                    customcolumns=tempcolumns.filter(each => (each.fieldKey !=='RECORD_ID'))
                }
                customcolumns.map(m => {
                    m.show = true;
                    m.title = m.columnName
                })
                setColumns(customcolumns);
                setPermitSearch({ ...permitSearch, 'SEARCH_CRITERIA': tempSearchKey, 'module': tempModule, 'SearchKey': tempSearchKeyresult })
                setWaitMap(false)
                setReload(!reload)
            })
        } else {
            if (DefaultSettings.getAlert() != null)
                DefaultSettings.getAlert().show("Please provide search information ", 'error');
        }
    }

    const handleSeclectionfromMap = (ids) => {
        ids.forEach(id => {
            data.forEach(dataItem => {
                if (dataItem.Id === id) {
                    dataItem.selected = true
                }
            })
        })
        setData(data)
    }


    return <Fragment>
        {reload}
        <Breadcrumbs {...breadcrumbs} className="pt-2" />
        <div className='m-2'>
            <RadioButtonsGroup items={items['SearchList'] || []} name='searchType' value={searchType} handleFieldValue={handleChange} extraProps={{
                transformProps: {
                    label: 'Name',
                    value: 'Value',
                }
            }} />
            <Card >
                <CardContent>
                    <div className='row'>
                        {permitOptions.map((each, index) => {
                            //  permitSearch.SEARCH_CRITERIA == "COMPANY_ID"
                            if ((permitSearch.SEARCH_CRITERIA == "PERMIT_NO" || permitSearch.SEARCH_CRITERIA == "0") && each.FieldKey == "SearchKey" && each.UIFieldTypeComponent == 'TB') {
                                return <Fragment key={index}>
                                    {renderSwitch(each)}
                                </Fragment>
                            }
                            else if (permitSearch.SEARCH_CRITERIA == "SITE_ID" && each.FieldKey == "SearchKey" && each.UIFieldTypeComponent == 'DM') {
                                return <Fragment key={index}>
                                    {renderSwitch(each)}
                                </Fragment>
                            }
                            else if (permitSearch.SEARCH_CRITERIA == "APPLICATION_STATUS" && each.FieldKey == "SearchKey" && each.UIFieldTypeComponent == 'DS') {
                                return <Fragment key={index}>
                                    {renderSwitch(each)}
                                </Fragment>
                            }
                            else if (each.UIFieldTypeComponent !== 'LB' && each.FieldKey == "SEARCH_CRITERIA") {
                                return <Fragment key={index}>
                                    {renderSwitch(each)}
                                </Fragment>
                            }
                            else if (each.FieldKey !== "SEARCH_CRITERIA" && each.FieldKey !== "SearchKey") {
                                return <Fragment key={index}>
                                    {renderSwitch(each)}
                                </Fragment>
                            }

                        })}
                        {/* {(showAdvancedSearch && searchType === '1') && <Fab variant='outlined' size='small' color='primary' onClick={handleAdvanceSearch}
                            title='Advanced Search'><SettingsIcon /></Fab>} */}
                    </div>
                </CardContent>
                <CardActions>
                    {
                        !showAdvancedSearch && searchType === '1' && <div className='col-3 m-3'> <RadioButtonsGroup name='operator' label={'Condition'} value={permitSearch['operator'] || 'and'} handleFieldValue={handleChange} extraProps={{
                            transformProps: {
                                label: 'name',
                                value: 'value',
                            }
                        }} items={[{ name: 'and', value: 'and' }, { name: 'or', value: 'or' }]} /></div>
                    }
                    <Button className='m-1' color="primary" variant="outlined" onClick={(e) => { setWaitMap(true); handleSearch(e, true) }}>            Search        </Button>
                    <Button className='m-1' color="primary" variant="outlined" onClick={() => { setsearchType('0'); handleClear() }}>Clear</Button>
                </CardActions>
            </Card>
            <div className='mt-3'>

                {
                    data && data.length > 0 ? <Splitter
                        panes={panes} onChange={onChange}
                    >
                        <KenodGrid
                            columns={columns}
                            searchType={searchType}
                            data={data || []}
                            total={total}
                            result={permitSearch}
                            fetchData={handleSearch}
                            showAdvancedSearch={showAdvancedSearch}
                            setSelectionmapdata={(selecteddata) => setSelectionmapdata(selecteddata)}
                        />
                        {waitMap === false ? <Map height={showAdvancedSearch ? 500 : 400} mapdata={data && data.length > 0 && data.map(res => {
                            return res.Id
                        }) || []} selectiondata={selectionmapdata} {...AddRecordMapJson}
                            SubModuleKey={(permitSearch && permitSearch['Module']) || 'APPLICATIONS'}
                            isGridView={true}
                            handleSeclectionfromMap={handleSeclectionfromMap}
                            SITE_PERMIT_TYPE_ID={permitSearch.SITE_PERMIT_TYPE}
                        /> : <></>}
                    </Splitter> : (columns && columns.length > 0) && <div >{' No Search Results Found '}</div>
                }
            </div>
        </div>
    </Fragment >
}

function KenodGrid({ columns, data, result, total, fetchData, setSelectionmapdata, showAdvancedSearch, searchType }) {

    const [dataState, setDataState] = useState({ skip: 0, take: 20 })
    const [column, setColumns] = useState(columns);
    const [filteredfield, setFilteredField] = useState([])
    const [reload, setReload] = useState(false)
    //    const [data, setData] = useState(kendoData)
    const [pageable, setPageable] = useState({
        buttonCount: 2,
        info: true,
        type: 'numeric',
        pageSizes: [10, 20, 30, 40, 50,100],
        previousNext: true
    })
    const _export = useRef(null)

    // useEffect(() => {
    //     setData(kendoData)
    // }, [kendoData])

    useEffect(() => {
        setColumns(columns);
        setReload(!reload)
    }, [columns])

    const handleDataStateChange = (e) => {
        let hasfilterfields = [];
        hasfilterfields = column ? column.filter(field => {
            if (GridColumnMenuFilter.active(field.field, e.dataState.filter)) {
                return field;
                
            }
        }) : [];
        setFilteredField(hasfilterfields)
        setDataState(e.dataState);
        fetchData(e.dataState, false)
    }

    const handleViewPermit = (props) => {
        const { dataItem } = props
        return <td><IconButton title={'View Disposal Request'} color="success" onClick={() =>
            window.open('#/Records/' + `${dataItem.SubModuleKey}/${dataItem['Application Type']}/${dataItem.Id}`, 'self')}        > < Pageview />        </IconButton ></td>
    }

    const selectionChange = (event) => {
        let selectData = data
        const tempdata = selectData.map(item => {
            if (item.Id === event.dataItem.Id) {
                item.selected = !event.dataItem.selected;
            }
            return item;
        });
        let selectionmapdata = tempdata.filter(res => {
            if (res.selected)
                return res.Id
        })
        selectionmapdata = selectionmapdata.map(res => {
            return res.Id
        })
        setSelectionmapdata(selectionmapdata)
    }

    const headerSelectionChange = (event) => {
        let selectData = data
        const checked = event.syntheticEvent.target.checked;
        const tempdata = selectData.map(item => {
            item.selected = checked;
            return item;
        });
        let selectionmapdata = tempdata.filter(res => {
            if (res.selected)
                return res.Id
        })
        selectionmapdata = selectionmapdata.map(res => {
            return res.Id
        })
        setSelectionmapdata(selectionmapdata)
    }

    const onColumnsSubmit = (columnsState) => {
        setColumns(columnsState)

    }

    const clearFilters = () => {
        let dataState = {
            skip: 0, take: 20
        }
        setFilteredField([])
        setDataState(dataState)
        fetchData(dataState);
    }

    const handleExport = (action) => {
        let tempResult = result;
        tempResult = { searchParams: tempResult, operator: 'and', IsQuickSearch: (searchType === '0' ? "Y" : "N") }
        let tempdataState = dataState ? dataState : { skip: 0, take: 20 }
        const queryStr = action === 'filterall' ? '' : `${toDataSourceRequestString(tempdataState)}`;
        const read = [{
            url: getEnvVariable('Application') + `/api/Application/DynamicSearchGrid/${tempResult.searchParams.module || 'APPLICATIONS'}/${tempResult.searchParams.SITE_PERMIT_TYPE || '-1'}?${queryStr}`,
            type: 'post',
            message: "Search Completed"
        }]
        Promise.all(read.map(each => requestApi(createRequest(each, tempResult)).catch(error => { return error; }))).then(res => {
            const [results] = res;
            if (_export.current !== null) {
                _export.current.save(results.data, column);
            }
        })
    }

    let gridDataState = { ...dataState, take: pageable.pageSizes.includes(dataState['take']) ? dataState.take : 'All' }
    let gridPageable = { ...pageable, ['info']: pageable.pageSizes.includes(dataState['take']) ? true : false }
    return <Fragment>
        {reload}
        <GridHeader  {...headerProps}  {...{ addOnsByName: { excel: { onClick: handleExport }, clear: { onClick: clearFilters }, } }} />
        <ExcelExport
            data={data}
            fileName={'Search.xlsx'}
            ref={_export}
        >
            <KendoGrids
                pageable={gridPageable}
                sortable={true}
                filterable={false}
                style={{
                    height: showAdvancedSearch ? '500px' : '380px',
                    overflow: 'auto'
                }}
                data={data}
                total={total}
                // file deepcode ignore DOMXSS: <please specify a reason of ignoring this>
                {...gridDataState}
                onDataStateChange={(e) => {
                    handleDataStateChange(e);
                }}
                selectedField="selected"
                onSelectionChange={selectionChange}
                onHeaderSelectionChange={headerSelectionChange}
                resizable
            >
                <Column
                    className="selected"
                    field="selected"
                    width="75px"
                    headerSelectionValue={
                        data && data.length > 0 && data.findIndex(dataItem => dataItem.selected === false) === -1

                    } />
                <Column cell={handleViewPermit} width={'50px'} filterable={false} sortable={false} />
                {column && column.length > 0 && column.map((each, index) => {
                    return <Column field={each.fieldName} title={each.columnName} width={'220px'} filter={each.filter} key={index}
                        columnMenu={
                            props =>
                                <CustomColumnMenu
                                    {...props}
                                    columns={columns}
                                    onColumnsSubmit={onColumnsSubmit}
                                />
                        }
                    />
                })}
            </KendoGrids>
        </ExcelExport>
    </Fragment>

}