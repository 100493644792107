import { getEnvVariable } from '../../modules/environmentalList'
const iwsusersignin =
{
    "type": "popupbutton",
    "key": "iwssignin",
    "order": 3,
    "options": {
        responsivescreen: true,
        "alignitem": "right",
        "buttonprops":
        {
            "text": "iws Sign In",
            "style": {
                "color": "#fafafa",
                "backgroundColor": '#212529 !important'
            }
        },
        "dialogprops": {
            "maxWidth": 'xs',
        },
        conditions: {

        }
    },
    "components": [
        {
            "type": "signupform",
            "options": {
                "steps": 19,
                "initialstep": 2,
            },
            "key": "signupformform1",
            "components": [
                {//1
                    "type": "form",
                    "key": "Username",
                    "options": {
                        "step": 1,
                        "name": "form1",
                        validations: {
                            'form1': {
                                'username': [{
                                    validation: 'email',
                                    message: 'username is not valid'
                                }]
                            }
                        },
                        crud: {
                            "rest": {
                                "get": {
                                    "url": getEnvVariable('Auth') + `/api/userauth/Usernameexist`,
                                    "query": [{
                                        "key": "portal",
                                        "value": "I"
                                    },
                                    {
                                        "key": "userName",
                                        "fieldName": "username",
                                        "value": "IWA"
                                    }
                                    ]
                                }
                            },
                        }
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "SignIn",
                            "options": {
                                "text": "Sign In",
                                "name": "usernamesignin",
                                "align": "center",
                                "style": {
                                    "fontSize": "25px",
                                    "fontWeight": "bold",
                                }
                            }
                        },
                        {
                            "type": "etext",
                            "order": 2,
                            "key": "passwordmessage",
                            "options": {
                                "text": "",
                                "name": "passwordmessage",
                                "error": true
                            }
                        },
                        {
                            "type": "formtextbox",
                            "margin": "normal",
                            "variant": "outlined",
                            "id": "outlined-required",
                            "order": 3,
                            "key": "username",
                            "options": {
                                // "placeholder": "Username",
                                "name": "username",
                                "label": "Username"
                            }
                        },

                        {
                            "type": "navformbutton",
                            "order": 4,
                            "key": "nextbutton",
                            "options": {
                                "jumptostep": 2,
                                "text": "Next",
                                "variant": "contained",
                                "color": "primary",
                                "name": "usernamebutton"
                            }
                        },
                        {
                            "type": "navlink",
                            "order": 5,
                            "key": "forgotusername",
                            "options": {
                                "jumptostep": 3,
                                "text": "Forgot your Username?",
                                "name": "usernameforgotlink"
                            }
                        }
                    ]
                },
                {//2
                    "type": "form",
                    "key": "siginpassowrdform",
                    "options": {
                        "step": 2,
                        "name": "form2",
                        "link": {
                            "url": "/notifications"
                        },
                        formdata: true,
                        crud: {
                            "rest": {
                                "post": {
                                    "url": getEnvVariable('ConnectToken') + ``,
                                    "body": [
                                        {
                                            "key": "username",
                                            "fieldName": "username",
                                            "value": "IWA"
                                        },
                                        {
                                            "key": "password",
                                            "fieldName": "password",
                                            "value": "IWA"
                                        },
                                        {
                                            "key": "portal",
                                            "value": "I"
                                        },
                                        {
                                            "key": "module",
                                            "value": getEnvVariable('ModuleKey')
                                        },
                                        {
                                            "key": "grant_type",
                                            "value": "password"
                                        }
                                    ]
                                }
                            },
                        },
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "Passwordtext",
                            "options": {
                                "text": "Sign In",
                                "name": "passwordsignin",
                                "align": "center",
                                "style": {
                                    "fontSize": "25px",
                                    "fontWeight": "bold",
                                }
                            }
                        },
                        {
                            "type": "etext",
                            "order": 2,
                            "key": "passwordmessage",
                            "options": {
                                "text": "",
                                "name": "passwordmessage",
                                "error": true
                            }
                        },
                        // {
                        //     "type": "formtextbox",
                        //     "margin": "normal",
                        //     "variant": "outlined",
                        //     "id": "outlined-required",
                        //     "order": 3,
                        //     "key": "username",
                        //     "options": {
                        //         // "placeholder": "Username",
                        //         "name": "username",
                        //         "label": "Username"
                        //     }
                        // },
                        // {
                        //     "type": "formtextbox",
                        //     "order": 4,
                        //     "key": "passwordformtextbox",
                        //     "margin": "normal",
                        //     "variant": "outlined",
                        //     "id": "outlined-required",
                        //     "options": {
                        //         //"placeholder": "Password",
                        //         "name": "password",
                        //         "label": "Password",
                        //         "xtype": "password"
                        //     }
                        // },
                        {
                            "type": "navformbutton",
                            "order": 5,
                            "key": "passwordsigninbutton",
                            "options": {
                                "text": "Sign In",
                                "jumptostep": 14,
                                "jumptofalsestep": 16,
                                "route": "/",
                                "name": "passwordbutton",
                                "variant": "contained",
                                "color": "primary"
                            }
                        },
                        {
                            "type": "navlink",
                            "order": 6,
                            "key": "forgotusername",
                            "options": {
                                "jumptostep": 3,
                                "text": "Forgot your Username?",
                                "name": "usernameforgotlink"
                            }
                        },
                        {
                            "type": "navlink",
                            "order": 7,
                            "key": "passwordforgotpasswordtext",
                            "options": {
                                "jumptostep": 4,
                                "text": "Forgot your Password?",
                                "name": "passwordforgotlink"
                            }
                        }
                    ]
                },
                {//3
                    "type": "form",
                    "key": "forgotusernameform",
                    "options": {
                        "step": 3,
                        "name": "form3",
                        crud: {
                            "rest": {
                                "post": {
                                    "url": getEnvVariable('Auth') + `/api/UserAuth/ForgotUserName`,
                                    "body": [
                                        {
                                            "key": "Email",
                                            "fieldName": "emailaddressusername"
                                        }, {
                                            "key": "Portal",
                                            "value": "I"
                                        }

                                    ]
                                }
                            },
                        }
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "forgotusernameSignIn",
                            "options": {
                                "text": "Forgot Your Username?",
                                "name": "emailaddress",
                                "align": "center",
                                "style": {
                                    "fontSize": "25px",
                                    "fontWeight": "bold",
                                }
                            }
                        },
                        {
                            "type": "etext",
                            "order": 2,
                            "key": "passwordmessage",
                            "options": {
                                "text": "",
                                "name": "passwordmessage",
                                "error": true
                            }
                        },
                        {
                            "type": "formtextbox",
                            "order": 3,
                            "key": "forgotusernameusername",
                            "variant": "outlined",
                            "options": {
                                "placeholder": "Enter your Email Address",
                                "name": "emailaddressusername",
                                "xtype": "email",
                            }
                        },
                        {
                            "type": "navformbutton",
                            "order": 4,
                            "key": "forgotusernamenextbutton",
                            "options": {
                                "jumptostep": 5,
                                "jumptofalsestep": 7,
                                "text": "Next",
                                "color": "primary",
                                "variant": "contained",
                                "name": "emailaddressnextbtn",

                            }
                        }
                    ]
                },
                {//4
                    "type": "form",
                    "key": "forgotpasswordform3",
                    "options": {
                        "step": 4,
                        "name": "form4",
                        crud: {
                            "rest": {
                                "post": {
                                    "url": getEnvVariable('Auth') + `/api/UserAuth/` + getEnvVariable('ModuleKey') + `/ForgotPassword`,
                                    "body": [
                                        {
                                            "key": "Module",
                                            "value": getEnvVariable('ModuleKey')
                                        },
                                        {
                                            "key": "Email",
                                            "fieldName": "Email"
                                        }, {
                                            "key": "Portal",
                                            "value": "I"
                                        }

                                    ]
                                }
                            },
                        }
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "forgotpasswordtext",
                            "options": {
                                "text": "Forgot Your Password?",
                                "name": "passwordemailaddress",
                                "align": "center",
                                "style": {
                                    "fontSize": "25px",
                                    "fontWeight": "bold",
                                }
                            }
                        },
                        {
                            "type": "etext",
                            "order": 2,
                            "key": "passwordmessage",
                            "options": {
                                "text": "",
                                "name": "passwordmessage",
                                "error": true
                            }
                        },
                        {
                            "type": "formtextbox",
                            "order": 3,
                            "key": "forgotpasswordformtextbox",
                            "variant": "outlined",
                            "options": {
                                "placeholder": "Enter your Email Address",
                                "name": "emailaddresspassword",
                                "validations": [
                                    {
                                        "validation": "emailAddress"
                                    }
                                ]
                            }
                        },
                        {
                            "type": "navformbutton",
                            "order": 4,
                            "key": "okbutton",
                            "options": {
                                "jumptostep": 6,
                                "jumptofalsestep": 7,
                                "text": "Next",
                                "name": "emailaddressnextbtn1",
                                "variant": "contained",
                                "color": "primary",
                            }
                        }
                    ]
                },
                {//5
                    "type": "form",
                    "key": "forgotusernameokform",
                    "options": {
                        "step": 5,
                        "name": "form5"
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "forgotusernameoktext",
                            "options": {
                                "text": "Forgot Your Username?",
                                "name": "forgotusername"
                            }
                        },
                        {
                            "type": "text",
                            "order": 1,
                            "key": "forgotusernameokForgottext",
                            "options": {
                                "text": "An email has been sent to your email address",
                                "name": "forgotusernametext"
                            }
                        },
                        {
                            "type": "navformbutton",
                            "order": 3,
                            "key": "forgotusernameokokbutton",
                            "options": {
                                "text": "Ok",
                                "name": "forgotusernameokbtn",
                                "close": true,
                                "variant": "contained",
                                "color": "primary",
                            }
                        }
                    ]
                },
                {
                    "type": "form",
                    "key": "forgotpassowrd",
                    "options": {
                        "step": 6,
                        "name": "form6"
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "forgotpassowrdtext",
                            "options": {
                                "text": "Forgot Your Password?",
                                "name": "forgotpassword"
                            }
                        },
                        {
                            "type": "text",
                            "order": 1,
                            "key": "forgotpassowrdForgotPassword",
                            "options": {
                                "text": "An email has been sent to your email address",
                                "name": "forgotpasswordtext"
                            }
                        },
                        {
                            "type": "button",
                            "order": 3,
                            "key": "forgotpassowrdokbutton",
                            "options": {
                                "text": "Ok",
                                "name": "forgotpasswordokbtn",
                                "close": true,
                                "variant": "contained",
                                "color": "primary",
                            }
                        }
                    ]
                },
                {
                    "type": "form",
                    "key": "notvalidemailaddressform",
                    "options": {
                        "step": 7,
                        "name": "form7"
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "notvalidemailaddresstext",
                            "options": {
                                "text": "Forgot Your Username?",
                                "name": "notvalidemailaddress"
                            }
                        },
                        {
                            "type": "text",
                            "order": 1,
                            "key": "notvalidemailaddresstextinred",
                            "options": {
                                "text": "Email address entered is not valid.Please contact support",
                                "name": "notvalidemailaddressinred"
                            }
                        },
                        {
                            "type": "button",
                            "order": 3,
                            "key": "okbutton",
                            "options": {
                                "text": "Ok",
                                "name": "notvalidemailaddressokbtn",
                                "variant": "contained",
                                "color": "primary",
                                "close": true,
                            }
                        }
                    ]
                },
                {
                    "type": "form",
                    "key": "forgotpasswordform1",
                    "options": {
                        "step": 8,
                        "name": "form8"
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "forgotpasswordtext",
                            "options": {
                                "text": "Forgot Your Password?",
                                "name": "notvalidemailaddresspassword"
                            }
                        },
                        {
                            "type": "text",
                            "order": 1,
                            "key": "forgotpasswordtextnired",
                            "options": {
                                "text": "Email address entered is not valid.Please contact support",
                                "name": "notvalidemailaddresspasswordtext"
                            }
                        },
                        {
                            "type": "button",
                            "order": 3,
                            "key": "forgotpasswordokbutton",
                            "options": {
                                "text": "Ok",
                                "name": "notvalidemailaddressokbtn",
                                "close": true,
                                "variant": "contained",
                                "color": "primary",
                            }
                        }
                    ]
                },
                {
                    "type": "form",
                    "key": "signinpincodeform",
                    "options": {
                        "step": 9,
                        "name": "form9"
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "signinpincodetext",
                            "options": {
                                "text": "Sign In",
                                "name": "unknowndevice",
                            }
                        },
                        {
                            "type": "text",
                            "order": 1,
                            "key": "signinpincodetextnotdetected",
                            "options": {
                                "name": "unknowndevicetext",
                                "text": "The system has detected that you are using an unknown device to signin. An email has been sent to your email address with a pincode please use that pin code to login "
                            }
                        },
                        {
                            "type": "button",
                            "order": 3,
                            "key": "signinpincodeokbutton",
                            "options": {
                                "name": "unknowndeviceOkbtn",
                                "text": "Ok"
                            }
                        }
                    ]
                },
                {
                    "type": "form",
                    "key": "signinpincodepasswordform",
                    "options": {
                        "step": 10,
                        "name": "form10",
                        "link": {
                            "url": "/notifications"
                        },
                        formdata: true,
                        crud: {
                            "rest": {
                                "post": {
                                    "url": getEnvVariable('ConnectToken') + ``,
                                    "body": [
                                        {
                                            "key": "username",
                                            "fieldName": "username",
                                            "value": "IWA"
                                        },
                                        {
                                            "key": "password",
                                            "fieldName": "password",
                                            "value": "IWA"
                                        },
                                        {
                                            "key": "portal",
                                            "value": "I"
                                        },
                                        {
                                            "key": "module",
                                            "value": getEnvVariable('ModuleKey')
                                        },
                                        {
                                            "key": "grant_type",
                                            "value": "password"
                                        },
                                        {
                                            "key": "randomnumber",
                                            "fieldName": "pincodevalue"
                                        }
                                    ]
                                }
                            }
                        }

                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "signinpincodepasswordtext",
                            "options": {
                                "text": "Sign In",
                                "name": "pincodesignin",
                                "align": "center",
                                "style": {
                                    "fontSize": "25px",
                                    "fontWeight": "bold",
                                }
                            }
                        },
                        {
                            "type": "dtext",
                            "order": 2,
                            "key": "passwordmessage",
                            "options": {
                                "text": "",
                                "name": "passwordmessage",
                                "error": true

                            }
                        },
                        {
                            "type": "text",
                            "order": 3,
                            "key": "signinpincodepasswordpassowrd",

                            "options": {
                                //  //"placeholder": "Password",
                                "name": "pincodepassword",
                                "text": "The system has detected that you are using an unknown device to signin. An email has been sent to your email address with a pincode please use that pin code to login ",
                            }
                        },
                        {
                            "type": "formtextbox",
                            "order": 4,
                            "key": "signinpincodepasswordpin",
                            "margin": "normal",
                            "variant": "outlined",
                            "options": {
                                "name": "pincodevalue",
                                "placeholder": "Pin Code"
                            }
                        },
                        {
                            "type": "formtextbox",
                            "order": 5,
                            "key": "passwordformtextbox",
                            "margin": "normal",
                            "variant": "outlined",
                            "id": "outlined-required",
                            "options": {
                                //"placeholder": "Password",
                                "name": "password",
                                "label": "Password",
                                "xtype": "password"
                            }
                        },
                        {
                            "type": "navformbutton",
                            "order": 6,
                            "key": "signinpincodepasswordsignin",
                            "options": {
                                "name": "pincodepincodesiginbtn",
                                "text": "OK",
                                "variant": "contained",
                                "color": "primary",

                            }
                        },
                        {
                            "type": "navlink",
                            "order": 7,
                            "key": "signinpincodeforgotpassword",
                            "options": {
                                "jumptostep": 4,
                                "name": "pincodeforgotlink",
                                "text": "Forgot Your Password?"
                            }
                        },
                        // {
                        //     "type": "navlink",
                        //     "order": 7,
                        //     "key": "signinpincoderesendpincode",
                        //     "options": {
                        //         "name": "pincoderesendlink",
                        //         "text": "Resend pin code"
                        //     }
                        // }
                    ]
                },
                {
                    "type": "form",
                    "key": "accountlockedform",
                    "options": {
                        "step": 11,
                        "name": "form11"
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "accountlockedtext",
                            "options": {
                                "text": "Account Locked",
                                "name": "accountlocked",
                                "align": "center",
                                "style": {
                                    "fontSize": "25px",
                                    "fontWeight": "bold",
                                }
                            }
                        },
                        {
                            "type": "text",
                            "order": 1,
                            "key": "accountlockedtextlock",
                            "options": {
                                "name": "accountlockedtext",
                                "text": "Your account has been locked due to too many unsuccessful login attempts. Please contact Administrator"
                            }
                        },
                        {
                            "type": "navformbutton",
                            "order": 3,
                            "key": "signinpincodepasswordsignin",
                            "options": {
                                "name": "accountlockedokbtn",
                                "text": "Ok",
                                "close": true,
                                "variant": "contained",
                                "color": "primary",
                            }
                        }
                    ]
                },
                {
                    "type": "form",
                    "key": "expiredpassword",
                    "options": {
                        "step": 12,
                        "name": "form12"
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "expiredpasswordtext",
                            "options": {
                                "text": "Password Expired",
                                "name": "passwordexpired",
                                "align": "center",
                                "style": {
                                    "fontSize": "25px",
                                    "fontWeight": "bold",
                                }
                            }
                        },
                        {
                            "type": "text",
                            "order": 2,
                            "key": "expiredpasswordtextpassword",
                            "options": {
                                "text": "Your password has expired, Email has been sent to emailaddress to change password",
                                "name": "passwordexpiredtext"
                            }
                        },
                        {
                            "type": "navformbutton",
                            "order": 3,
                            "key": "signinpincodepasswordsignin",
                            "options": {
                                "jumptostep": 2,
                                "text": "Ok",
                                "variant": "contained",
                                "color": "primary",
                                "name": "passwordexpiredokbtn"
                            }
                        }
                    ]
                },
                {
                    "type": "form",
                    "key": "changepasswordform",
                    "options": {
                        "step": 13,
                        "name": "form13",
                        formdata: true,
                        crud: {
                            "rest": {
                                "post": {
                                    "url": getEnvVariable('Auth') + `` + '/api/UserAuth/ResetPassword',
                                    "body": [
                                        {
                                            "key": "guid",
                                            "fieldName": "guid"
                                        },
                                        {
                                            "key": "password",
                                            "fieldName": "confirmpassword"
                                        },
                                    ]
                                }
                            }
                        }
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "changepasswordtext",
                            "options": {
                                "text": "Change  Password",
                                "name": "changepassword",
                                "align": "center",
                                "style": {
                                    "fontSize": "25px",
                                    "fontWeight": "bold",
                                }
                            }
                        },
                        {
                            "type": "dtext",
                            "order": 2,
                            "key": "passwordmessage",
                            "options": {
                                "text": "",
                                "name": "passwordmessage",

                            }
                        },
                        {
                            "type": "formtextbox",
                            "order": 3,
                            "key": "expiredpasswordtextnewpassword",
                            "margin": "normal",
                            "variant": "outlined",
                            "id": "outlined-required",
                            "options": {
                                "placeholder": "New password",
                                "name": "newpassword",
                                "label": "New Password",
                                "xtype": "password"
                            }
                        },
                        {
                            "type": "formtextbox",
                            "order": 4,
                            "key": "expiredpasswordtextconfirmpassword",
                            "margin": "normal",
                            "variant": "outlined",
                            "id": "outlined-required",
                            "options": {
                                "placeholder": "Confirm New password",
                                "name": "confirmpassword",
                                "label": "Confirm Password",
                                "xtype": "password"
                            }
                        },
                        {
                            "type": "infotext",
                            "key": "Infotext",
                            "options": {
                                "row": 2,
                                "col": 0,
                                "width": 12,
                                "text": "Password Policy:",

                            }
                        },
                        {
                            "type": "infotext",
                            "key": "Infotext",
                            "options": {
                                "row": 2,
                                "col": 0,
                                "width": 12,
                                "text": "1. Password must contain at least 10 characters.",

                            }
                        },
                        {
                            "type": "infotext",
                            "key": "Infotext",
                            "options": {
                                "row": 2,
                                "col": 0,
                                "width": 12,
                                "text": "2. Password should includes 1 Uppercase Alphabet[A-Z], 1 Lowercase Alphabet[a-z], 1 number[0-9], 1 Special Character.",

                            }
                        },
                        {
                            "type": "infotext",
                            "key": "Infotext",
                            "options": {
                                "row": 2,
                                "col": 0,
                                "width": 12,
                                "text": "3. Password cannot contain spaces at the begin/end, Applicant Name, Username and cannot use the last five passwords.",

                            }
                        },
                        {
                            "type": "navformbutton",
                            "order": 5,
                            "key": "changepassordbtn",
                            "changepassword": true,
                            "options": {
                                "text": "OK",
                                "name": "changebtnpassword",
                                "variant": "contained",
                                "color": "primary",
                            }
                        },
                        {
                            "type": "navlink",
                            "order": 6,
                            "key": "forgotpasswordlink",
                            "options": {
                                "text": "Forgot Your Old Password?",
                                "name": "changepasswordlink"
                            }
                        }
                    ]
                },
                {
                    "type": "form",
                    "key": "createnewpasswordform",
                    "options": {
                        "step": 14,
                        "name": "form14"
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "createnewpasswordtext",
                            "options": {
                                "text": "Create New Password",
                                "name": "createnewpassword"
                            }
                        },
                        {
                            "type": "formtextbox",
                            "order": 2,
                            "key": "createnewpasswordnew",
                            "options": {
                                "placeholder": "New password",
                                "name": "createnewnewpassword"
                            }
                        },
                        {
                            "type": "formtextbox",
                            "order": 3,
                            "key": "createnewpasswordconfirm",
                            "options": {
                                "placeholder": "Confirm New password",
                                "name": "createnewconfirmnewpassword"
                            }
                        },
                        {
                            "type": "infotext",
                            "key": "Infotext",
                            "options": {
                                "row": 2,
                                "col": 0,
                                "width": 12,
                                "text": "Password Policy:",

                            }
                        },
                        {
                            "type": "infotext",
                            "key": "Infotext",
                            "options": {
                                "row": 2,
                                "col": 0,
                                "width": 12,
                                "text": "1. Password must contain at least 10 characters.",

                            }
                        },
                        {
                            "type": "infotext",
                            "key": "Infotext",
                            "options": {
                                "row": 2,
                                "col": 0,
                                "width": 12,
                                "text": "2. Password should includes 1 Uppercase Alphabet[A-Z], 1 Lowercase Alphabet[a-z], 1 number[0-9], 1 Special Character.",

                            }
                        },
                        {
                            "type": "infotext",
                            "key": "Infotext",
                            "options": {
                                "row": 2,
                                "col": 0,
                                "width": 12,
                                "text": "3. Password cannot contain spaces at the begin/end, Applicant Name, Username and cannot use the last five passwords.",

                            }
                        },
                        {
                            "type": "navformbutton",
                            "order": 4,
                            "key": "changepassordbtn",
                            "options": {
                                "text": "Change",
                                "name": "createnewpasswordokbtn"
                            }
                        }
                    ]
                },
                {
                    "type": "form",
                    "key": "createPasswordexpired",
                    "options": {
                        "step": 15,
                        "name": "form15",
                    },
                    "components": [
                        {
                            "type": "dtext",
                            "order": 1,
                            "key": "tokenexpired",
                            "options": {
                                "text": "Password is expired, Email is send to email address",
                                "name": "tokenexpired"
                            }
                        },

                    ]
                },
                {
                    "type": "form",
                    "key": "otpinputchange",
                    "options": {
                        "step": 16,
                        "name": "form16",
                        "link": {
                            "url": "/notifications"
                        },
                        formdata: true,
                        crud: {
                            "rest": {
                                "post": {
                                    "url": getEnvVariable('ConnectToken') + ``,
                                    "body": [
                                        // {
                                        //     "key": "otpinput",
                                        //     "fieldName": "otpinput"
                                        // },
                                        {
                                            "key": "username",
                                            "fieldName": "username",
                                            "value": "IWA"
                                        },
                                        {
                                            "key": "password",
                                            "fieldName": "password",
                                            "value": "IWA"
                                        },
                                        {
                                            "key": "portal",
                                            "value": "I"
                                        },
                                        {
                                            "key": "module",
                                            "value": getEnvVariable('ModuleKey')
                                        },
                                        {
                                            "key": "grant_type",
                                            "value": "password"
                                        },
                                        {
                                            "key": "randomnumber",
                                            "fieldName": "otpinput"
                                        }
                                    ]
                                }
                            },
                        },
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "Passwordtext",
                            "options": {
                                "text": "Pincode",
                                "name": "passwordsignin",
                                "align": "center",
                                "style": {
                                    "fontSize": "25px",
                                    "fontWeight": "bold",
                                }
                            }
                        },
                        {
                            "type": "text",
                            "order": 2,
                            "key": "otpmessage",
                            "options": {
                                "text": "Please enter the pincode sent to your email",
                                "name": "otpmessage",
                            }
                        },
                        {
                            "type": "otp",
                            "order": 3,
                            "key": "otpinput",
                            "options": {
                                "name": "otpinput",
                            }
                        },
                        {
                            "type": "etext",
                            "order": 4,
                            "key": "passwordmessage",
                            "options": {
                                "text": "",
                                "name": "passwordmessage",
                                "error": true
                            }
                        },
                        {
                            "type": "navlink",
                            "order": 5,
                            "key": "resendotplink",
                            "options": {
                                "jumptostep": 17,
                                "text": "Resend Pincode",
                                "name": "resendotplink"
                            }
                        },
                        {
                            "type": "navformbutton",
                            "order": 6,
                            "key": "otpsigninbutton",
                            "options": {
                                "text": "Verify and Login",
                                "jumptostep": 14,
                                "route": "/",
                                "name": "otppasswordbutton",
                                "variant": "contained",
                                "color": "primary"
                            }
                        },

                    ]
                },
                {//17
                    "type": "form",
                    "key": "resendotptext",
                    "options": {
                        "step": 17,
                        "name": "form17",
                        crud: {
                            "rest": {
                                "get": {
                                    "url": getEnvVariable('Auth') + `/api/UserAuth/ResendPin`,
                                    "query": [
                                        {
                                            "key": "Username",
                                            "fieldName": "emailaddressusername"
                                        },
                                        {
                                            "key": "portal",
                                            "value": "I"
                                        }
                                    ]
                                }
                            },
                        }
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "resendotptextemailtext",
                            "options": {
                                "text": "An email will be sent to your email address",
                                "name": "resendotptextemailtext"
                            }
                        },
                        {
                            "type": "formtextbox",
                            "order": 2,
                            "key": "emailaddressusername",
                            "variant": "outlined",
                            "options": {
                                "placeholder": "Enter your UserName",
                                "name": "emailaddressusername",
                                //"xtype": "email",
                            }
                        },
                        {
                            "type": "etext",
                            "order": 3,
                            "key": "passwordmessage",
                            "options": {
                                "text": "",
                                "name": "passwordmessage",
                                "error": true

                            }
                        },
                        {
                            "type": "navformbutton",
                            "order": 4,
                            "key": "resendotptextbutton",
                            "options": {
                                "jumptostep": 18,
                                "jumptofalsestep": 19,
                                "text": "Next",
                                "color": "primary",
                                "variant": "contained",
                                "name": "emailaddressnextbtn2",
                            }
                        }
                    ]
                },
                {//18
                    "type": "form",
                    "key": "forgototpok",
                    "options": {
                        "step": 18,
                        "name": "form18"
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "forgototpoktext",
                            "options": {
                                "text": "Resend Pincode",
                                "name": "forgototp"
                            }
                        },
                        {
                            "type": "text",
                            "order": 1,
                            "key": "forgototpokForgottext",
                            "options": {
                                "text": "An email has been sent to your email address",
                                "name": "forgototptext"
                            }
                        },
                        {
                            "type": "navformbutton",
                            "order": 3,
                            "key": "forgototpokokbutton",
                            "options": {
                                "text": "Ok",
                                "name": "forgototpokokbutton",
                                "close": true,
                                "variant": "contained",
                                "color": "primary",
                            }
                        }
                    ]
                },
                {//19
                    "type": "form",
                    "key": "notvalidusername",
                    "options": {
                        "step": 19,
                        "name": "form19"
                    },
                    "components": [
                        {
                            "type": "text",
                            "order": 1,
                            "key": "notvalidemailaddresstextinred",
                            "options": {
                                "text": "UserName entered is not valid.Please contact support",
                                "name": "notvalidemailaddressinred"
                            }
                        },
                        {
                            "type": "button",
                            "order": 3,
                            "key": "okbutton",
                            "options": {
                                "text": "Ok",
                                "name": "notvalidemailaddressokbtn",
                                "close": true,
                                "variant": "contained",
                                "color": "primary",
                                "close": true,
                            }
                        }
                    ]
                },
            ]
        }
    ]
}

export default iwsusersignin