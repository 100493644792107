// const AdminConfig = 'http://development.delasoft.com:8443/UPA-adminconfig'
// const Admin = 'http://development.delasoft.com:8443/UPA-admin'

import { getEnvVariable } from "../../../modules/environmentalList"

const HolidayAdd = {
    "type": "popup",
    "key": "HolidayAdd",
    "name": "HolidayAdd",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'addOpen'
    },
    sharedValues: ['open'],
    container: {
        values: { save: false, back: false, fullscreen: false, },
        mapValues: {
            header: [
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
            ],
            Holidays: [{ key: 'save', name: 'save' },
            { key: 'exitfullscreen', name: 'exitfullscreen' },
            { key: 'fullscreen', name: 'fullscreen' },]
        },
        //  sharedValues: ['save', 'edit'],
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },
            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'Holidays',
                    type: 'div',
                    className: 'col-12 pl-4 m-3',
                    sub: {
                        type: 'div',
                        className: 'col-12 pl-4 m-3',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Holidays',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "Holidays", "materialicon": "today", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'save',
                        hidevalue: false,
                        title: 'Save Holiday',
                        color:"#008000"
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hidevalue: false,
                        title: 'FullScreen'
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false,
                        title: 'Exit FullScreen'
                    }
                ],


            },
            Holidays: {
                "type": "form",
                "key": "Holidays",
                "name": "Holidays",
                aliases: {
                    save: 'save',
                },
                "routeParams": { replace: 'holidays', param: 'Id', routeName: 'id' },
                sharedValues: ['save', 'back', 'exitfullscreen', 'fullscreen'],
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'HolidayDay',
                                type: 'div',
                                className: 'col-10 m-2',
                                sub: {
                                    type: 'div',
                                    className: 'col-10 m-2',
                                }
                            },

                            ]
                        },
                        {
                            order: 1,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'HolidayDate',
                                    type: 'div',
                                    className: 'col-6 m-2',
                                    sub: {
                                        type: 'div',
                                        className: 'col-6 m-2',
                                    }
                                },
                            ]
                        },
                        {
                            order: 2,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Status',
                                type: 'div',
                                className: 'col-6 m-2',
                                sub: {
                                    type: 'div',
                                    className: 'col-6 m-2',
                                }
                            },
                            ]
                        },
                        // {
                        //     order: 6,
                        //     type: 'div',
                        //     className: 'row',
                        //     components: [{
                        //         name: 'CreatedOn',
                        //         type: 'div',
                        //         className: 'col-6',
                        //         sub: {
                        //             type: 'div',
                        //             className: 'col-6'
                        //         }
                        //     },
                        //     {
                        //         name: 'CreatedByName',
                        //         type: 'div',
                        //         className: 'col-6',
                        //         sub: {
                        //             type: 'div',
                        //             className: 'col-6'
                        //         }
                        //     },
                        //     ]
                        // },
                        // {
                        //     order: 7,
                        //     type: 'div',
                        //     className: 'row',
                        //     components: [
                        //         {
                        //             name: 'UpdatedOn',
                        //             type: 'div',
                        //             className: 'col-6',
                        //             sub: {
                        //                 type: 'div',
                        //                 className: 'col-6'
                        //             }
                        //         },
                        //         {
                        //             name: 'UpdatedByName',
                        //             type: 'div',
                        //             className: 'col-6',
                        //             sub: {
                        //                 type: 'div',
                        //                 className: 'col-6'
                        //             }
                        //         },]
                        // },
                    ]

                },
                
                values: {
                    HolidayDay: '',
                    HolidayDate: '',
                    Status:'I',
                    // UpdatedByName: '',
                    // CreatedByName: '',
                    // UpdatedOn: '',
                    // CreatedOn: '',
                    // UpdatedBy: '',
                    // CreatedBy: '',
                    Id: 0,
                    editInfo: null,
                    allgriddata: null,
                    navigation: null,
                },

                "components": {
                    HolidayDay: {
                        "type": "textfield",
                        "key": "HolidayDay",
                        "label": "Day",
                        "name": "HolidayDay",
                    },
                    HolidayDate: {
                        "type": "datemonth",
                        "key": "HolidayDate",
                        "label": "Date",
                        "name":"HolidayDate"
                    },
                    Status: {
                        "type": "toggle",
                        "key": "Status",
                        "label": "Status",
                        "name": "Status",
                        disabled :true,
                        extraProps: {
                            "transformProps": {
                                "A": true,
                                "I": false,
                                "true": "A",
                                "false": "I"
                            }
                        }
                    },
                    // CreatedOn: {
                    //     "type": "label",
                    //     "key": "CreatedOn",
                    //     "label": "Created On",
                    //     name: "CreatedOn",
                    //     changefieldto: "label",
                    // },
                    // CreatedByName: {
                    //     "type": "label",
                    //     "key": "CreatedByName",
                    //     "label": "Created By",
                    //     name: "CreatedByName",
                    //     changefieldto: "label",
                    // },
                    // UpdatedOn: {
                    //     "type": "label",
                    //     "key": "UpdatedOn",
                    //     "label": "Updated On",
                    //     name: "UpdatedOn",
                    //     changefieldto: "label",
                    // },
                    // UpdatedByName: {
                    //     "type": "label",
                    //     "key": "UpdatedByName",
                    //     "label": "Updated By",
                    //     name: "UpdatedByName",
                    //     changefieldto: "label",
                    // },
                },
                mapActionsToEffects: {
                    'onLoad': {
                        init: {
                            rules: [{
                                run:
                                {
                                    valid: [                                    
                                        {
                                            type: 'validations', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                            "read": [
                                                {
                                                    url: getEnvVariable('Application') + `/api/ProjectMessages/RulesByName?name=WRSADMIN.Holidays`,
                                                    type: 'get'
                                                }
                                            ],
                                            transformProps: {
                                                removesubfieldname: 'WRSADMIN.HOLIDAYS_'
                                            }
                                        },
                                        {
                                            type: 'businessrules',
                                            "read": [
                                                {
                                                    //url: getEnvVariable('Application') + `/api/BusinessRules/Grid/modulemaster.notes`,
                                                    url:getEnvVariable('Application')+`/api/BusinessRules/Grid/WRSADMIN.Holidays`,
                                                    type: 'get',
                                                }
                                            ],
                                        },
                                    ]
                                }
                            }]
                        },
                        'exitfullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'exitfullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'fullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'fullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    },
                    'afterSave': {
                        'save': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',// http://development.delasoft.com:8443/AMES-application/api/Property/23123/Add
                                                "read": [
                                                    {
                                                        url: getEnvVariable('Admin') + `/api/Holidays/Add`,
                                                        //"url":"http://localhost:6004/api/Holidays/Add",
                                                        type: 'post',
                                                        "matchProps": [{
                                                            name: "routeid1",
                                                            key: "routeid1",
                                                        }]
                                                    }
                                                ],
                                            }

                                        ]
                                    }
                                }
                            ]
                        },
                    }
                }
            }
        },

    }
}

export default HolidayAdd
