
import React from 'react';

import {
  Chart, ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels, ChartTitle
} from '@progress/kendo-react-charts';

const labelContent = (e) => (e.dataItem.share);

class KendoChart extends React.Component {

  constructor(props) {
    super(props);
  }

  donutCenterRenderer = () => {
    const { data } = this.props;
    let initialValue = 0;
    let total = data.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.share
    }, initialValue)
    console.log(total)
    return <h3>{total}</h3>
  };

  render() {
    return (
      <Chart donutCenterRender={this.donutCenterRenderer} >
        <ChartTitle text={this.props.monthName} color="white" background="black" font="19pt" />
        <ChartSeries>
          <ChartSeriesItem type="donut" data={this.props.data} categoryField="kind" field="share" >
            <ChartSeriesLabels color="#fff" background="none" content={labelContent} />

          </ChartSeriesItem>
        </ChartSeries>
        <ChartLegend visible={true} />
      </Chart>
    )
  }
}

export default KendoChart
