
const grid = 8;
export const getSection = (count, offset) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        Id: `Section-${k + offset}`,
        Name: `Section ${k + offset}`,
        fields: getFields(count+5,(k*count)+offset)
    }));

export const getFields = (count, offset) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        Id: `Field-${k + offset}`,
        Name: `Field ${k + offset}`
    }));

export const getList = (state, droppableId) =>{
    if(state.droppables!=null && state.droppables.length>0){
        const sourcedroppables= state.droppables.filter(f=>f.dndId==droppableId);
        if(sourcedroppables!==null && sourcedroppables.length>0)
        return sourcedroppables[0].items;
    }
    return [];
};

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone || [];
    result[droppableDestination.droppableId] = destClone || [];
    return result;
};

export const getSectionStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',

  border: '1px solid ' + (isDragging ? 'skyblue' : 'lightgrey'),
  //border:(isDragging ? '1px solid skyblue' : '0px'),
  background: isDragging ? 'skyblue' : 'none',
  ...draggableStyle
});

export const getRowStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  borderBottom:(isDragging ? '1px solid skyblue' : '0px !important'),
  ...draggableStyle
});

export const getRowFieldStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  border:(isDragging ? '1px solid skyblue' : '0px'),
  background: isDragging ? 'grey' : 'none',
  ...draggableStyle
});

export const getFieldStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  //padding: grid * 2,/
  background: isDragging ? 'skyblue' : 'lightgrey',
  border:(isDragging ? '1px solid skyblue' : '0px'),
  ...draggableStyle
});


export const getFieldComponentStyle = (isDragging, draggableStyle,isSectionField) => ({
  userSelect: 'none',
  //padding: grid * 2,/
  background: (isDragging ? 'skyblue' : (isSectionField ? 'none' : 'lightgrey')),
  border:(isDragging ? '2px solid skyblue' : (isSectionField ? '2px solid white' : '0px')),
  ...draggableStyle
});


export const getListStyle = isDragging => ({
     border:(isDragging ? '1px solid skyblue' : '0px'),
});
export const getNestedListStyle = isDragging => ({
     border:(isDragging ? '1px solid skyblue' : '0px'),
});


export const getFieldListStyle = isDragging => ({
  borderBottom:(isDragging ? '1px solid skyblue' : '0px !important'),
  border:(isDragging ? '1px solid skyblue' : '1px dotted lightgrey') ,
});


export function IsEqual(l,r) {
  return (l+"")=== (r+"");
};

export function Clone(jsonObj) {
  return jsonObj!=null?JSON.parse(JSON.stringify(jsonObj)):{};//// Object.assign({},settings.row);
};

export function getListClass(direction) {
  if(direction==="NestedItemStyle"){
    return `NestedItemStyle`;
  }
  else if(direction==="sectionList"){
    return `sectionList`;
  }else if(direction==="NestedListStyle"){
    return `NestedListStyle`;
  }

  else if(direction==="sectionvertical"){
      return `SectionVerticalList`;
    }
    else if(direction==="vertical"){
      return `VerticalList`;
    }else{
      return `HorizontalList`;
    }
}

export function getFieldClass(direction) {
    if(direction==="vertical"){
      return `VerticalField`;
    }else{
      return `HorizontalField`;
    }
}
