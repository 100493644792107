import { Button } from '@material-ui/core';
import '@progress/kendo-react-animation';
import { handleTreeViewCheckChange, processTreeViewItems, TreeView } from '@progress/kendo-react-treeview';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { passtabData, snackbarMessage } from '../../actions';
import ProgressBar from '../progress-bar';
import { getData, postRecordData } from '../rest';
import FormHeader from './cformheader';
const _ = require('lodash');

class CTreeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: null, singleMode: false, checkChildren: true, checkParents: true, newtempitem: null,
            check: { ids: [], applyCheckIndeterminate: true },
        };
        this.newArray = []
        this.loaditems = []
    }
    componentDidMount() {
        const { options, components, extraProps, match, dataItem } = this.props
        const { crud, validations, iscurrentuser } = options ? options : {}
        const { read } = crud ? crud : {}
        const { get } = read ? read : {}
        const { routeProps, customProps } = get ? get : {}
        const { addURL } = match;
        let ids = [], id, treeURL;
        this.progressbar.start();
        if (Array.isArray(routeProps)) {
            routeProps.forEach(each => {
                const { value } = each
                ids.push({ key: value, value: this.props.match.params[value] })
            })
        }
        else if (routeProps) {
            id = routeProps ? this.props.match.params[routeProps.value] : null
        }
        else if (customProps) {
            id = customProps ? (dataItem.Id || dataItem.userId) : null
        }
        if (iscurrentuser && this.props.accessPermissions) {
            id = this.props.accessPermissions.userId;
        }
        if (addURL == "?isadmin=Y") {
            treeURL = get.url + id + addURL;
        }
        else {
            if (id)
                treeURL = get.url + id;
            else
                treeURL = get.url
        }
        getData(treeURL).then(res => {
            let result = this.treeStructurechange(res)
            this.setState({ items: result, newtempitem: result })

            this.progressbar.complete();
        }).catch(err => {
            if (err)
                this.progressbar && this.progressbar.complete();
        });
    }

    getAllId = (arr, key) => {
        arr.forEach(item => {
            for (let elements in item) {
                if (elements === key) {
                    if (item.checked) {
                        let checkeditem = { parentID: item.parentID, id: item.id }
                        this.newArray.push(checkeditem)
                    }
                } else if (Array.isArray(item[elements])) {
                    this.getAllId(item[elements], key)
                }
            }

        })
    }

    getPathdata = (array, id, parentID) => {
        var result;
        array.some((o, i) => {
            var temp;
            if (o.id === id && o.parentID == parentID) return result = `${i}`;
            if (temp = this.getPathdata(o.items || [], id, parentID)) return result = `${i}_${temp}`;
        });
        return result;
    }

    formatData = (arr) => {
        arr.forEach(i => {
            if (_.isEqual(i.checked, true)) {
                i.checked = false
                i.items = _.isNull(i.items) ? [] : i.items
                this.formatData(i.items)
            } else {
                i.items = _.isNull(i.items) ? [] : i.items
                this.formatData(i.items)
            }
        });
        return arr;
    }

    treeStructurechange = (res) => {
        const { options, match } = this.props;
        const { addURL } = match;
        const { tree } = this.state
        const { headerProps, title, viewMode } = options;
        let isViewMode = viewMode
        if (addURL == "?isadmin=Y") {
            isViewMode = true;
        }
        return res.map(each => {
            each.expanded = true;
            each.disabled = isViewMode;
            if (each.items) {
                each.items = this.treeStructurechange(each.items);
            }
            return each
        })
    }

    handleSave = () => {
        const { options, components, extraProps, dataItem } = this.props
        const { crud, validations, title } = options ? options : {}
        const { update } = crud ? crud : {}
        const { put } = update ? update : {}
        const { routeProps, customProps } = put ? put : {}
        let ids = [], id
        if (Array.isArray(routeProps)) {
            routeProps.forEach(each => {
                const { value } = each
                ids.push({ key: value, value: this.props.match.params[value] })
            })
        }
        else if (routeProps) {
            id = routeProps ? this.props.match.params[routeProps.value] : null
        }
        else if (customProps) {
            id = customProps ? this.props.dataItem[customProps.custom] : null
        }
        postRecordData(put.url + id, processTreeViewItems(this.state.items, { check: this.state.check })).then(res => {
            this.props.snackbarMessage(title + ' Saved Successfully', 'success');
            this.props.onClose();
        }).catch(error => {
            this.props.snackbarMessage(JSON.stringify(error), 'error');
        })
    }

    handleDoneOutline = () => {
        this.props.passtabData(this.state.items);
        let e = {}
        this.props.handleTabChange(e, this.props.options.tabindex)
    }

    render() {
        const { options, match } = this.props;
        const { addURL } = match;
        const { items, check } = this.state
        const { headerProps, title, viewMode, designprops } = options;
        let isViewMode = viewMode;
        if (addURL == "?isadmin=Y") { isViewMode = true; }
        return (
            <Fragment>
                <div className=" example-config">
                    <FormHeader className="float-right" title={title}
                        handleSave={this.handleSave}
                        doneoutline={headerProps.doneoutline}
                        handleDoneOutline={this.handleDoneOutline}
                        sendbutton={headerProps.sendbutton}
                        savebutton={headerProps.savebutton && !isViewMode}
                        closebutton={headerProps.closebutton}
                        handleClose={() => this.props.onClose()}
                        designprops={designprops} />
                </div>
                <div> <ProgressBar ref={e => this.progressbar = e} /></div>
                {!isViewMode && <div className={!designprops ? 'row  float-right p-2' : ''}>
                    <Button variant="contained" color="primary" onClick={this.checkAll} >Check all</Button>
                    <span>&nbsp;</span>
                    <Button variant="contained" color="primary" onClick={this.uncheckAll}>Uncheck all</Button>
                </div>}
                <div className={!designprops ? "row  col-sm-12 eps-treestyles pt-2" : ""}>
                    <TreeView expandIcons={true} onExpandChange={this.onExpandChange}
                        data={processTreeViewItems(items, {
                            check: check
                        })}
                        checkboxes={true} onCheckChange={this.onCheckChange}

                    />
                </div>

            </Fragment>
        );
    }

    onCheckChange = (event) => {
        const settings = {
            //singleMode: true,
            checkChildren: true,
            checkParents: true
        };
        let split = event.itemHierarchicalIndex.split("_");
        let pop = [];
        let items = this.newCheckedChange(this.state.newtempitem, split);
        this.setState({ items: items, newtempitem: items, check: handleTreeViewCheckChange(event, this.state.check, items, settings) });
    }

    handleSubItems = (items, check) => {
        return items.map(each => {
            if (each && each.items && each.items.length > 0) {
                each.checked = check;
                this.handleSubItems(each.items, check)
            }
            else each.checked = check;
            return each
        })
    }

    newCheckedChange = (items, split) => {
        return items.map((each, index) => {
            if ((split && split.length > 0) && index === parseInt(split[0])) {
                split.shift();
                if (split.length !== 0)
                    this.newCheckedChange(each.items, split)
                else {
                    if (each && each.items && each.items.length > 0) {
                        each.checked = !each.checked
                        each.items = this.handleSubItems(each.items, each.checked);
                        return each
                    }
                    else {
                        each.checked = !each.checked
                    }
                }
            }
            return each
        })
    }

    newExpandChange = (items, split, count) => {
        return items.map((each, index) => {
            if (index === parseInt(split[count])) {
                count++;
                if (split.length !== count) {
                    this.newExpandChange(each.items, split, count)
                }
                else each.expanded = !each.expanded;
            }
            return each
        })
    }


    searchSpecifiedTreeItem(id, name, treeItems) {
        for (let index = 0; index < treeItems.length; index++) {
            const element = treeItems[index];
            if (element.id == id && element.text == name) {
                if (element && element.items.some(s => s.checked == false)) {
                    element.checkIndeterminate = true;
                }
                else {
                    element.checkIndeterminate = false;
                    element.checked = true;
                }
                break;
            }
            if (element.items) {
                this.searchSpecifiedTreeItem(id, name, element.items);
            }
        }
    }

    makeChecked(items, isChecked) {
        items.forEach(e => { e.checked = isChecked });
    }

    onExpandChange = (event) => {
        let split = event.itemHierarchicalIndex.split("_")
        let count = 0;
        let items = this.newExpandChange(this.state.newtempitem, split, count);
        this.setState({ items: items, newtempitem: items });

    }


    checkStatusChange = (items, status) => {
        return items.map(each => {
            each.checked = status
            if (each.items) {
                each.items = this.checkStatusChange(each.items, status);
            }
            return each
        })
    }



    checkAll = () => {
        const settings = { checkChildren: true, checkParents: true };
        let items = this.checkStatusChange(this.state.items, true);
        this.setState({ check: handleTreeViewCheckChange(this.state.check, items, settings) });
    }

    uncheckAll = () => {
        const settings = { checkChildren: true, checkParents: true };
        let items = this.checkStatusChange(this.state.items, false);
        this.setState({ check: handleTreeViewCheckChange(this.state.check, items, settings) });
        this.forceUpdate();
    }

    onSingleModeChange = ({ target: { checked } }) => {
        let { checkChildren, checkParents } = this.state;
        if (checked) {
            checkChildren = checkParents = false;
        }
        this.setState({ singleMode: checked, checkChildren, checkParents });
    }
    onCheckChildrenChange = ({ target: { checked } }) => {
        let { singleMode } = this.state;
        if (checked) {
            singleMode = false;
        }
        this.setState({ singleMode, checkChildren: checked });
    }
    onCheckParentsChange = ({ target: { checked } }) => {
        let { singleMode } = this.state;
        if (checked) {
            singleMode = false;
        }
        this.setState({ singleMode, checkParents: checked });
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage, passtabData }, dispatch);
}

CTreeView = connect(null, mapActions)(CTreeView)
export default withRouter(CTreeView)
