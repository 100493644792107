import React, { Component, Fragment } from 'react'
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CheckBoxAutoComplete from '../../controls/components/checkautocomplete'

const CFormAutoComplete = (props) => {
    const { classes } = props
    const { handleFieldChange, dropdownvalues, edit, name, tooltip, label, disabled, error, required, htmlForLabel,
        helperText, id, crud, styles, className, multiple, handleAutoCompleteInputChange, ...otherProps } = props
    let { transformProps, newtransformProps } = props
    let value = props.value
    if (multiple) value = value ? value.split(',') : []
    let displayname, isLoading
    if (newtransformProps) {
        let selarr = []
        const selected1 = dropdownvalues && dropdownvalues.filter(each => {
            let val = each.Value
            if(value && value.includes(val)) {
                selarr.push(each.Name)
                return each.Name
            }
        }
        )
        if (selected1 && selarr.length>0) {
            displayname = selarr.join()
        }
        else displayname = value
    }
    else if (transformProps) {
        const selected = dropdownvalues && dropdownvalues.find(each => {
            return each[transformProps.value] == value
        }
        )
        if (selected) displayname = selected[transformProps.name || transformProps.label]
        else displayname = value
    }
    else displayname = value
    // defaultValue={(dropdownvalues && Array.isArray(dropdownvalues) ? dropdownvalues[0].value : null)}

    function loadoptions(e) {
        handleAutoCompleteInputChange({ target: { name: name, value: e ? e : null, checked: false } });
    }

    if (edit === false) {
        return <div className={className ? className : ''} >
            {tooltip && <Fragment> <Typography variant={'caption'} className="fw-bold">{label}  &nbsp; < Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                <HelpOutlineIcon fontSize="small" style={{ width: '0.8em' }} />
            </Tooltip></Typography>
                <Typography variant={'body2'} color={'textPrimary'} className="text-secondary">{displayname}            </Typography></Fragment>}
            {!tooltip && <Fragment> <Typography variant={'caption'} className="fw-bold">{label} </Typography>
                <Typography variant={'body2'} color={'textPrimary'} className="text-secondary">{displayname}            </Typography></Fragment>}
        </div>
    }
    else {
        return (
            <div>
                {/* <InputLabel htmlFor={htmlForLabel || label}>{label}</InputLabel>
                <FormControl disabled={disabled} error={error} required={required} fullWidth >
                    <Select
                        value={{ label: displayname, value: value }}
                        onInputChange={loadoptions}
                        onChange={(e) => handleFieldChange({ target: { name: name, value: e && e.value ? e.value : null, checked: false } })}
                        options={(dropdownvalues && Array.isArray(dropdownvalues) && dropdownvalues.map((each, index) => {
                            const value = transformProps ? each[transformProps.value] : each.value
                            const name = transformProps ? each[transformProps.name] : each.name
                            const key = each.key || value
                            return { label: name, value: value }
                        }) || [])}
                        closeMenuOnSelect={true}
                        isLoading={!(dropdownvalues && Array.isArray(dropdownvalues) && dropdownvalues.length > 0)}
                        isClearable
                        isMulti={multiple}
                    />
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl> */}
                <CheckBoxAutoComplete label={label} name={name} required={required} error={error} helperText={helperText} ismulti={true} value={value} extraProps={{ transformProps: transformProps ?  transformProps : newtransformProps}}
                    items={dropdownvalues || []} handleFieldValue={(evt) => handleFieldChange(evt)} />
            </div>
        )
    }
}


export default CFormAutoComplete;