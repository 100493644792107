import { getEnvVariable } from '../../../modules/environmentalList';
import newsavedreportstypes from '../../reports/savedreports';
import comapanyuser from './edit';
import newcomapanyuser from './new';
const companyusersgrid = {
    "type": "grid",
    "key": "companyusersgrid",
    "gridkey": 'companyusersgrid',
    "order": 1,
    "options": {
        sidepopupgridheight: true,
        "routes": [
            {
                "route": "/companies",
                "routeParams": ":routeid",
            },
            {
                "route": "/users"
            },
        ],
        "crud": {
            "update": {
                "link": [
                    {
                        "link": "/companies",
                        "matchProps": {
                            "value": "routeid"
                        }
                    },
                    {
                        "link": "/users/view",
                        "matchProps": {
                            "value": "UserId"
                        }
                    },
                ]
            },
            "read": {
                "get": {
                    "url": getEnvVariable('MasterAdmin')+`/api/ExternalUser/Grid/`+getEnvVariable('ModuleKey')+`/{Id}`,
                    "customProps": {
                        "value": "Id"
                    }
                }
            }
        },
        "back": {
            "routes": [
                {
                    "route": "/companies/view",
                    "routeParams": "routeid",
                },
            ],
        },
        "childGrid": true,
        "sidebar": true,
        "formgrid": true,
        headerProps: {
            title: "Users",
            customheader: true,
            headerProps:{
            //designprops: true,
            kendogridprops: true,
            title: "Users",
            actions: [
                {
                    type: 'fab',
                    icon: 'clear',
                    name: 'clear',
                    color: 'primary',
                    title: 'Clear Filters',
                    menucolor: '#22a9b3'
                },

                  {
                    type: 'menu',
                    icon: 'excel',
                    name: 'excel',
                    color: 'primary',
                    title: 'Export to Excel',
                    menucolor: '#c90685',
                    items: [{
                        name: 'Export Current Page',
                        action: 'filtercurrent'
                    },
                    {
                        name: 'Export All Pages',
                        action: 'filterall'
                    }
                    ]
                },
                {
                    type: 'fab',
                    icon: 'add',
                    name: 'add',
                    color: 'primary',
                    title: 'Add Users ',
                    menucolor: '#36088a'
                },

            ]
        }
        },
        "afterSaveroutes": {
            "route": "/Administration/agencyusers/",
            "routeparams": 'UserId'
        },
        savedreports: {
            ...newsavedreportstypes
        },
        "components": [
            comapanyuser
        ],
        "addcomponents": [
            newcomapanyuser
        ],
        "gridcolumns": [
            {
                "title": "Username",
                "field": "User.UserName",
                "show": true,
                "width": 185
            },
            {
                "title": "First Name",
                "field": "User.FirstName",
                "show": true,
                "width": 150
            },
            {
                "title": "Last Name",
                "field": "User.LastName",
                "show": true,
                "width": 150
            },
            {
                "title": "Email",
                "field": "Email",
                "show": true,
                "width": 250
            },
            {
                "title": "Contact Number",
                "field": "Phone",
                "show": true,
                "width": 150
            },
            {
                "title": "Status",
                "field": "User.Status",
                "show": false,
                "width": 150
            },
            {
                "title": "Created By",
                "field": "CreatedByName",
                "show": true,
                "width": 185
            },
            {
                "title": "Created On",
                "field": "CreatedOn",
                "show": true,
                "width": 185
            },
            {
                "title": "Updated By",
                "field": "UpdatedByName",
                "show": true,
                "width": 185
            },
            {
                "title": "Updated On",
                "field": "UpdatedOn",
                "show": true,
                "width": 185
            }
        ]
    }
}

export default companyusersgrid;