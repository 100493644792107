import { loadModules } from 'esri-loader';
import { QueryTask, CenterAndZoom } from '../util/queryTask';
import { GeometryEngineTask } from '../util/geometryEngineTask';
import { GISLookupTask, GISLookupTaskWithGeom } from './GISLookupTask'
import MapConstants from '../util/mapConstants'

export function GetLookUpValues(coordinates, lookupFieldsData){
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/layers/GraphicsLayer",
            "esri/Graphic",
            "esri/geometry/Point",
            "esri/geometry/Multipoint",
            'esri/geometry/Polyline',
            'esri/geometry/Polygon',
            'esri/symbols/PictureMarkerSymbol', 'esri/geometry/SpatialReference',
            "esri/layers/FeatureLayer"
        ]).then(([GraphicsLayer, Graphic, Point, Multipoint, Polyline, Polygon,
            PictureMarkerSymbol, SpatialReference, FeatureLayer]) => {
                let xCenter = coordinates.X //5283726.000121132,
                let yCenter = coordinates.Y //3905064.999991983
                let spatialReference = new SpatialReference(102763);
                let geomPt = new Point(xCenter, yCenter,spatialReference);
                GISLookupTaskWithGeom([geomPt], lookupFieldsData).then(function (lookupDataresult) {
                    resolve(lookupDataresult);
                })
        })
    })
}


export function GetMasterLookUpValues(view, graphic){
    return new Promise((resolve, reject) => {
        loadModules([
            "esri/layers/GraphicsLayer",
            "esri/Graphic",
            "esri/geometry/Point",
            "esri/geometry/Multipoint",
            'esri/geometry/Polyline',
            'esri/geometry/Polygon',
            'esri/symbols/PictureMarkerSymbol', 'esri/geometry/SpatialReference',
            "esri/layers/FeatureLayer"
        ]).then(([GraphicsLayer, Graphic, Point, Multipoint, Polyline, Polygon,
            PictureMarkerSymbol, SpatialReference, FeatureLayer]) => {
                resolve({'DISTRICT_ID' :'', 'COUNTY_ID':'', 'REGION_ID':'' })
        })
    })
}

export function GetMasterGISFieldsData(options, geom) {
    return new Promise((resolve, reject) => {
        let lookupdata = options.MasterLookupFields;
        console.log('lookup data', lookupdata);
        if (lookupdata && lookupdata.length > 0) {
            if (geom) {
                GISLookupTask(options, geom, lookupdata).then((masterdataresponse) => {
                    resolve(masterdataresponse)
                    //inThis.props.setStateMapInformation(mapinformation);
                })
            }else{ resolve({}) }
        }else{
            resolve({})
        }
    })
}


