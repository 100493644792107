/**
      *********************************************************
      Description: Handles the multiduallistbox component with form fields below the multiduallistbox. Not completely written, still needs to be verified and modified.
      Required props:  type, components.
      limitations    : N/A.
      *********************************************************
**/

import React, { Component, Fragment } from 'react';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Autocompletetags from '../../core/controls/components/custom/notificationtags';
import { snackbarMessage } from '../../core/actions';
import FormHeader from '../../core/modules/form/cformheader';
import { requestApi } from '../../core/modules/form/utilities';
import { getEnvVariable } from '../environmentalList';

class NotificationEvents extends Component {
    constructor(props) {
        super(props)
        console.log(props);
        /**
                *********************************************************
                Description: Initializing the state to handle the duallist box.
                Required props:  N/A.
                limitations    : N/A.
                *********************************************************
        **/
        this.state = {
            firstboxselected: [],
            firstboxavailable: [],
            secondboxselected: [],
            secondboxavailable: [],
            firstboxresults: null,
            secondboxresults: null,
            currentfirstboxselecteditem: null,
            currentsecondboxselecteditem: null,
            changedFieldName: [],
            opensnackbar: false,
            snackbarmessageType: '',
            snackbarmessage: '',

            availablenotificationevents: [],
            availablenotificationeventsTRANSITION: [],
            selectednotificationevents: '',
            selectednotificationeventsTRANSITION: '',
            selectednotificationeventscollection: [],
            selectednotificationeventscollectionTRANSITION: [],
            notificationeventresults: [],
            parentId: '',
            eventId: '',
            allpermittypestatusitems: []
        };
    }




    /**
      *********************************************************
      Description: Gets the values for the duallist box and setups click listener on each of the values.
      Required props:  components.
      limitations    : N/A.
      *********************************************************
**/

    componentDidMount() {
        const { options, components, eventOn, duallistprops } = this.props
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const firstboxtranformProps = firstboxoptions ? firstboxoptions.transformProps : null
        const firstboxcrud = firstboxoptions ? firstboxoptions.crud : null
        const firstboxread = firstboxcrud ? firstboxcrud.read : null
        const firstboxget = firstboxread ? firstboxread.get : null
        //const firstboxrouteProps = firstboxget ? firstboxget.routeProps : null
        // id = firstboxrouteProps ? this.props.match.params[firstboxrouteProps.value] : null

        const firstboxeventsavailable = firstboxcrud ? firstboxcrud.available : null
        const firstboxeventsselected = firstboxcrud ? firstboxcrud.selected : null
        const appstatusfirstboxeventsavailable = firstboxcrud ? firstboxcrud.appstatusavailable : null

        const firstboxeventsavailableget = firstboxeventsavailable ? firstboxread.get : null
        const firstboxeventsselectedget = firstboxeventsselected ? firstboxeventsselected.get : null

        const { matchProps, routeProps, extraProps, customProps } = firstboxget ? firstboxget : {}

        //this.progressBar.start()


        let ids = [], id
        if (Array.isArray(routeProps)) {
            routeProps.forEach(each => {
                const { value, custom } = each
                if (custom) {
                    ids.push({ key: custom, value: this.props.dataItem[custom] })
                }
                else {
                    ids.push({ key: value, value: this.props.match.params[value] })
                }
            })
        }
        else if (routeProps) {
            id = routeProps ? this.props.match.params[routeProps.value] : null
        }
        else if (customProps) {
            id = this.props.dataItem[customProps.custom]
        }
        else if (matchProps) {
            if (Array.isArray(matchProps)) {
                matchProps.forEach(each => {
                    const { value } = each
                    ids.push({ key: value, value: this.props.extraProps ? this.props.extraProps[matchProps[0].value] : null })
                })
            }
            //if(results) id = results[matchProps.value]

        }

        if (eventOn) {
            var val = ''
            switch (eventOn) {
                case "APPLICATION_STATUS_CHANGE":
                    val = 'APPLICATION_STATUS';
                    break;
                case "FIELD_VALUE_CHANGE":
                    val = 'FIELD_VALUE';
                    break;
                case "PERMIT_STATUS_CHANGE":
                    val = 'PERMIT_STATUS';
                    break;
                case "WORKFLOW_STATUS_CHANGE":
                    val = 'WORKFLOW_STATUS_CHANGE';
                    break;
                case "RESOLUTION_STATUS":
                    val = 'RESOLUTION_STATUS';
                    break;
                default:
                    val = '';
                    break;
            }
            ids.push({ key: 'eventOn', value: val });
            let parentId = this.props.dataItem['ID'];
            let eventId = val;

            if (eventOn == "FIELD_VALUE_CHANGE") {
                //Refactor props for event on details
                const { matchProps, routeProps, extraProps } = firstboxeventsselectedget ? firstboxeventsselectedget : {}
                if (Array.isArray(routeProps)) {
                    routeProps.forEach(each => {
                        const { value, custom } = each
                        if (custom) {
                            ids.push({ key: custom, value: this.props.dataItem[custom] })
                        }
                        else {
                            ids.push({ key: value, value: this.props.match.params[value] })
                        }
                    })
                }
                else if (routeProps) {
                    id = routeProps ? this.props.match.params[routeProps.value] : null
                }
                else if (matchProps) {
                    if (Array.isArray(matchProps)) {
                        matchProps.forEach(each => {
                            const { value } = each
                            ids.push({ key: value, value: this.props.extraProps ? this.props.extraProps[matchProps[0].value] : null })
                        })
                    }
                }
                //firstboxeventsselected.urlpath = firstboxeventsselected.urlpath
                firstboxeventsavailableget && requestApi(firstboxeventsavailable, (ids.length > 0 ? ids : id)).then(results => {
                    if (results) {
                        let availablenotificationevents = results.map(each => {
                            return { id: each.Value, name: each.Label, key: each.Value }
                        })
                        this.setState({ availablenotificationevents: availablenotificationevents, parentId: parentId, eventId: eventId });

                        firstboxeventsselectedget && requestApi(firstboxeventsselected, (ids.length > 0 ? ids : id)).then(results1 => {
                            if (results1 && results1.selectedCollection.length > 0) {
                                let selectedValues = results1.selectedCollection.map(a => { return a.key })
                                let selectednotificationeventscollection = results.filter(a => selectedValues.includes(a.id)).map(each => {
                                    return { id: each.Value, name: each.Label, key: each.Value }
                                })
                                let selcted = [...new Set(selectedValues)].join()
                                this.setState({
                                    availablenotificationevents: availablenotificationevents,
                                    selectednotificationevents: selcted,
                                    parentId: parentId, eventId: eventId, selectednotificationeventscollection: selectednotificationeventscollection
                                });
                            } else {
                                this.setState({ availablenotificationevents: availablenotificationevents, parentId: parentId, eventId: eventId });
                            }
                            //  this.progressBar.complete()
                        })
                    }

                })

            } else if (eventOn == "APPLICATION_STATUS_CHANGE") {
                if (this.props.extraProps) {
                    ids.push({ key: 'SitePermitTypeId', value: this.props.extraProps['SitePermitTypeId'] })
                }
                //firstboxget.urlpath = firstboxget.urlpath + val;
                appstatusfirstboxeventsavailable && requestApi(appstatusfirstboxeventsavailable, (ids.length > 0 ? ids : id)).then(results => {
                    console.log(results);
                    if (results && results.length > 0) {
                        let availablenotificationevents = []
                        results.forEach(each => {
                            let selectedStatus = each.SelectedStatus || {}
                            availablenotificationevents.push({ id: selectedStatus.Id, name: selectedStatus.StatusName, key: selectedStatus.Id });
                            let transmisionstatus = each.TransmisionStatus;
                            transmisionstatus.forEach(each => {
                                availablenotificationevents.push({ id: selectedStatus.Id, name: selectedStatus.StatusName, key: selectedStatus.Id })
                            })

                        });
                        //unique data
                        let unique = Array.from(new Set(availablenotificationevents.map(s => s.id))).map(id => {
                            return {
                                id: id,
                                name: availablenotificationevents.find(a => a.id === id).name,  //(a.FirstName +' '+a.LastName),
                                key: availablenotificationevents.find(a => a.id === id).key
                            }
                        })

                        this.setState({ availablenotificationevents: unique, parentId: parentId, eventId: eventId }, () => {
                            this.loadSelectedNotificationEvents(firstboxtranformProps, firstboxget, firstboxread, (ids.length > 0 ? ids : id), unique, results)
                        })
                    }
                    // this.progressBar.complete()
                })

            }else if (eventOn == "WORKFLOW_STATUS_CHANGE"){
                firstboxget && requestApi(firstboxread, (ids.length > 0 ? ids : id)).then(results => {
                    if (results) {
                        let available = [];
                        let selected = [];
                        if (results.availableCollection) {
                            available = results.availableCollection.map(each => {
                                return { id: each.key, name: each.name, key: each.key }
                            })
                        }
                        if (results.selectedCollection) {
                            selected = results.selectedCollection.map(each => {
                                return { id: each.key, name: each.name, key: each.key }
                            })
                        }
                        let selectedValues = selected.map(a => { return a.id })
                        let selctedsep = [...new Set(selectedValues)].join()

                        //For selected transitions
                        let selectedTransitionValArr = results.selectedCollection.map(a => a.transition);
                        let sectedTransitionsep = [...new Set(selectedTransitionValArr)].join();

                        this.setState({
                            availablenotificationevents: available,
                            selectednotificationevents: selctedsep,
                            selectednotificationeventscollection: selected,
                            parentId: parentId, eventId: eventId,
                            availablenotificationeventsTRANSITION: available,
                            selectednotificationeventsTRANSITION: sectedTransitionsep
                        });
                    }
                })
            } else {
                //firstboxget.urlpath = firstboxget.urlpath + val;
                firstboxget && requestApi(firstboxread, (ids.length > 0 ? ids : id)).then(results => {
                    if (results) {
                        let available = [];
                        let selected = [];
                        if (results.availableCollection) {
                            available = results.availableCollection.map(each => {
                                return { id: each.key, name: each.name, key: each.key }
                            })
                        }
                        if (results.selectedCollection) {
                            selected = results.selectedCollection.map(each => {
                                return { id: each.key, name: each.name, key: each.key }
                            })
                        }
                        let selectedValues = available.map(a => { return a.id })
                        let selctedsep = [...new Set(selectedValues)].join()
                        this.setState({
                            availablenotificationevents: available,
                            selectednotificationevents: selctedsep,
                            selectednotificationeventscollection: selected,
                            parentId: parentId, eventId: eventId
                        });
                    }
                    // this.progressBar.complete();
                })
            }
        }else{
            //Load reports for the scheduled notifications
            this.loadScheudleNotificationReports(firstboxread,ids);
        }
    }

    loadScheudleNotificationReports(firstboxread,ids){
        let parentId = this.props.dataItem['ID'];
        let reportsurl = { "get": { "url": getEnvVariable('Report')+`/api/Reports/All` } };
        requestApi(reportsurl, null).then(results => {
            if(results && results.length > 0){
              let eventdetails =  results.map(a => { return { id: a.Id, name: a.Name, key: a.Id } })
              requestApi(firstboxread, ids).then(selresults => {
                    console.log(selresults);
                    if(selresults){
                        let selids = selresults.selectedCollection.map(a => a.key);
                        let selctedsep = [...new Set(selids)].join();
                        this.setState({
                            availablenotificationevents: eventdetails,
                            selectednotificationevents: selctedsep,
                            parentId:parentId
                        })
                    }
              })
            }
        })
    }


    loadSelectedNotificationEvents(firstboxtranformProps, firstboxget, firstboxread, ids, availableresults, allpermittypestatusitems) {
        firstboxget && requestApi(firstboxread, ids).then(results => {
            console.log(results);
            let selectedresults = results[firstboxtranformProps.selected.key]
            if (results && selectedresults && selectedresults.length > 0) {
                let selectedValues = selectedresults.map(a => { return a.name })
                let selectedTransition = selectedresults[0].transition ? selectedresults[0].transition : '';
                //let selectedTransitionArr = selectedTransition.split(",");

                let selectednotificationeventscollection = availableresults.filter(a => selectedValues.includes(a.id.toString())).map(each => {
                    return { id: each.id, name: each.name, key: each.key }
                })

                let alltransitionstatus = allpermittypestatusitems.filter(a => selectedValues.includes(a.SelectedStatus.Id.toString()));
                let transitionstatus = []
                if (alltransitionstatus && alltransitionstatus.length > 0)
                    transitionstatus = alltransitionstatus[0].TransmisionStatus ? alltransitionstatus[0].TransmisionStatus.map(a => { return { id: a.Id, name: a.StatusName, key: a.Id } }) : [];

                let selcted = [...new Set(selectedValues)].join()
                this.setState({
                    selectednotificationevents: selcted,
                    selectednotificationeventscollection: selectednotificationeventscollection,
                    allpermittypestatusitems: allpermittypestatusitems,
                    availablenotificationeventsTRANSITION: transitionstatus,
                    selectednotificationeventsTRANSITION: selectedTransition
                });
            } else {
                this.setState({ allpermittypestatusitems: allpermittypestatusitems, selectednotificationeventsTRANSITION: '' })
            }
        })
    }

    /**
     *********************************************************
     Description: handles the saving of the results for the firstdual list box.
     Required props:  components. Within components first dual list box save is needed.
     limitations    : N/A.
     *********************************************************
**/

    onhandleSave = () => {
        //selected - {value: "ADC_MAP_GRID_NO", label: "ADC Map Grid No", key: "ADC_MAP_GRID_NO", name: "ADC Map Grid No"}
        //available - {value: "ADC_MAP_GRID_NO", label: "ADC Map Grid No", key: "ADC_MAP_GRID_NO", name: "ADC Map Grid No"}
        const { selectednotificationeventscollection, availablenotificationevents, parentId, eventId, selectednotificationeventscollectionTRANSITION, selectednotificationeventsTRANSITION } = this.state;
        let selectedcollection = selectednotificationeventscollection.map(each => {
            return { value: each.id, label: each.name, key: each.id, name: each.name, transition: selectednotificationeventsTRANSITION }
        })
        let availablecollection = availablenotificationevents.map(each => {
            return { value: each.id, label: each.name, key: each.id, name: each.name }
        })
        let results = {
            availableCollection: availablecollection,
            selectedCollection: selectedcollection,
            parentId: parentId,
            eventId: eventId
        }
        //this.progressBar.start()
        const id = this.props.match.params.routeid
        const { options, components } = this.props
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const firstboxcrud = firstboxoptions ? firstboxoptions.crud : null
        const firstboxsave = firstboxcrud ? firstboxcrud.update : null
        const { multiboxes, save, title } = options ? options : {}
        if (firstboxsave) {
            requestApi(firstboxsave, id, results).then(results => {
                //this.progressBar.complete()
                if (this.props.save) this.props.handleSave()
                this.props.snackbarMessage((title ? title : '') + ' Saved Successfully', 'success')

            }).catch(e => {
                // this.progressBar.complete()
                if (this.props.save) this.props.handleSave()
                this.props.snackbarMessage('Invalid Data, Please check the data', 'error')
            });
        }


    }
    /**
          *********************************************************
          Description: handles closing of the alerts.
          Required props:   N/A.
          limitations    : N/A.
          *********************************************************
    **/

    handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ opensnackbar: false });
    };





    getEventLabel(eventOn, tag) {
        switch (eventOn) {
            case "APPLICATION_STATUS_CHANGE":
            case "PERMIT_STATUS_CHANGE":
                return 'Select ' + tag + ' status';
            case "FIELD_VALUE_CHANGE":
                return 'Select field(s)';
            case "WORKFLOW_STATUS_CHANGE":
                return 'Select workflow ' + tag + ' status';
            case "RESOLUTION_STATUS":
                return 'Select resolution ' + tag + ' status';
            case null:
            case "":
                return 'Select report(s)';
            default:
                return 'Select notification event(s)';
        }
    }

    onNotificationEventChange = (tags) => {
        //console.log(tags)
        let retags = tags;
        let resultdata = tags.target.value.map(a => a.id);
        let transitionstatus = [];
        let seltransitionstatus = '';
        if (this.props.eventOn == 'APPLICATION_STATUS_CHANGE') {
            seltransitionstatus = ''
            let existingdata = this.state.selectednotificationevents ? this.state.selectednotificationevents.split(",") : [];
            tags = { target: { name: tags.target.name, value: tags.target.value.filter(a => !existingdata.includes(a.id.toString())) } }
            resultdata = [...new Set(resultdata)]
            //resultdata = tags.target.value.filter(a => !existingdata.includes(a.id)).map(a => a.id);

            let alltransitionstatus = this.state.allpermittypestatusitems.filter(a => resultdata.includes(a.SelectedStatus.Id))
            if (alltransitionstatus && alltransitionstatus.length > 0)
                transitionstatus = alltransitionstatus[0].TransmisionStatus ? alltransitionstatus[0].TransmisionStatus.map(a => { return { id: a.Id, name: a.StatusName, key: a.Id } }) : [];
        }else if (this.props.eventOn == 'WORKFLOW_STATUS_CHANGE'){
            let existingdata = this.state.selectednotificationevents ? this.state.selectednotificationevents.split(",") : [];
            tags = { target: { name: tags.target.name, value: tags.target.value.filter(a => !existingdata.includes(a.id.toString())) } }
            seltransitionstatus = this.state.selectednotificationeventsTRANSITION
            transitionstatus = this.state.availablenotificationevents;
        }else {
            seltransitionstatus = this.state.selectednotificationeventsTRANSITION
            transitionstatus = this.state.availablenotificationevents;
        }
        this.setState({
            selectednotificationevents: [...new Set(resultdata)].join(),
            selectednotificationeventscollection: tags.target.value,
            notificationeventresults: { ...this.state.firstboxresults, selectednotificationeventscollection: tags.target.value },
            availablenotificationeventsTRANSITION: transitionstatus,
            selectednotificationeventsTRANSITION: seltransitionstatus
        })
    }

    onToNotificationEventChange = (tags) => {
        console.log(tags)
        let resultdata = tags.target.value.map(a => a.id);
        this.setState({
            selectednotificationeventsTRANSITION: [...new Set(resultdata)].join(),
            selectednotificationeventscollectionTRANSITION: tags.target.value,
            //notificationeventresults: { ...this.state.firstboxresults, selectednotificationeventscollection: tags.target.value }
        })
    }

    /**
      *********************************************************
      Description: handles rendering of the duallist boxes and the forms beneath it.
      Required props:   type ,components, options.
      limitations    : N/A.
      *********************************************************
    **/

    render() {
        let autcompletetags_FROM = {
            "options": {
                "label": this.getEventLabel(this.props.eventOn, 'from'),
                "placeholder": "",
                "row": 0,
                "col": 0,
                "width": 12,
                "name": "NotificationEvents",
                "itemname": "notificationEventId",
                disabled: false,
                conditions: {
                    hideonnull: { 'roleId': null }
                },
                "defaultSelected": true
            }
        }

        let autcompletetags_TO = {
            "options": {
                "label": this.getEventLabel(this.props.eventOn, 'to'),
                "placeholder": "",
                "row": 0,
                "col": 0,
                "width": 12,
                "name": "NotificationEventsTo",
                "itemname": "notificationEventId",
                disabled: false,
                conditions: {
                    hideonnull: { 'roleId': null }
                },
                "defaultSelected": true
            }
        }
        const { options, components, compmap, eventOn } = this.props
        const { multiboxes, save, title } = options ? options : {}
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const secondboxoptions = secondbox ? secondbox.options : null
        const firstboxprops = {}, secondboxprops = {}

        const { availablenotificationevents, selectednotificationevents, selectednotificationeventscollection, selectednotificationeventsTRANSITION, availablenotificationeventsTRANSITION } = this.state
        const { headerProps, designprops } = options
        const { savebutton, closebutton } = headerProps ? headerProps : {}

        return (
            <Fragment>
                {headerProps && <FormHeader title={title} handleSave={this.onhandleSave} savebutton={savebutton} handleClose={this.props.onClose} closebutton={closebutton} duallistheader={true} designprops={designprops} />}
                {/* <div> <ProgressBar ref={e => this.progressBar = e} /></div> */}
                <div className="mt-2">
                    <Autocompletetags
                        label="Notification Events"
                        name="notificationEvents"
                        dropdownvalues={availablenotificationevents}
                        options={autcompletetags_FROM.options}
                        handleFieldChange={this.onNotificationEventChange}
                        defaultSelValue={selectednotificationevents}
                    />
                </div>
                {(eventOn == 'APPLICATION_STATUS_CHANGE' || eventOn == 'WORKFLOW_STATUS_CHANGE') && <div className="mt-2">
                    <Autocompletetags
                        label="Notification Events"
                        name="notificationEventsTRANSITION"
                        dropdownvalues={availablenotificationeventsTRANSITION}
                        options={autcompletetags_TO.options}
                        handleFieldChange={this.onToNotificationEventChange}
                        defaultSelValue={selectednotificationeventsTRANSITION}
                    />
                </div>}
            </Fragment>
        )
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

NotificationEvents = connect(null, mapActions)(NotificationEvents)
export default withRouter(NotificationEvents)

