import React, { useRef, useState, Fragment, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import Preview from '@material-ui/icons/Visibility';
import Fab from '@material-ui/core/Fab';
//import FilterListIcon from '@material-ui/icons/ClearAll';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import IconButton from '@material-ui/core/IconButton';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import FilterListIcon from '@material-ui/icons/FilterList';
import BackspaceIcon from '@material-ui/icons/Backspace';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import CallToActionIcon from '@material-ui/icons/CallToAction';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);


const iconMapper  = {
  filter:FilterListIcon,
  markread: DraftsIcon,
  markunread: MarkunreadIcon,  
  allinbox: AllInboxIcon,
  quickview: FilterListIcon, 
  dismissed: BackspaceIcon,
  undismissed: CallToActionIcon
}

export default function QuickView(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuItems, setMenuItems] = React.useState([]);
  const [value, setValue] = React.useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
     console.log(props,'---------quick view ');
     if(props.loadonclick){
        loadQuickViewMenu();
     }else{
      setDefaultMenuItems()
     }
  }, [])

  const setDefaultMenuItems = () => {
    setMenuItems(props.items)
  }

  const loadQuickViewMenu =() =>{
    console.log('Need props for loading quick menu');
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuClicked = (event, val) => {
    console.log(val);
    setValue(val);    
    // if(props.onClick)
    //   props.onClick(event, val)
  }

  const IconComp = iconMapper[props.icon] ;

  return (
    <div>       
       {IconComp && 
        <i>
          <Fab size="small" color="primary" aria-label={props.title} className="float-right" title={props.title} onClick={handleClick}> 
            <IconComp fontSize="small" />
          </Fab>  
         </i> 
       }
          
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}    
        value = {value}    
      >
        {Array.isArray(menuItems) && menuItems.map(item => {
          const Itemicon = iconMapper[item.icon];
          return (<StyledMenuItem value={item.name} onClick={(evt) => menuClicked(evt, item.value) }>
                <ListItemIcon>
                  <Itemicon  fontSize="small" />                  
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </StyledMenuItem>)
        }) }
        {/* { Array.isArray(menuItems)  &&  } */}
      </StyledMenu>
    </div>
  );
}
