import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { getEnvVariable } from '../../../modules/environmentalList';
import { GetData } from '../helpers/requests';
import { useParams } from 'react-router-dom';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function CustomizedMenus(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const signOptions = getEnvVariable('AuthorizationLogins');
    const params = useParams()

    useEffect(() => {
        if ((props && props.options && props.options.type === 'R') || (params && params.guid))
            window.location.replace(getEnvVariable('AuthorizationScreen') + 'forgot/resetpassword/' + params.guid)
    }, [params])


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRoute = (each) => {
        let url = getEnvVariable('Auth') + '/api/UserAuth/AuthType/' + getEnvVariable("AccessPortal");
        GetData(`${url}`).then(response => {
            if (response['OAuth'] === 'Y') {
                // localStorage.setItem('AccessPortal', each.AccessPortal || 'D')
                window.location.replace(each.AuthorizationPath)
            }
        })
    }

    

    return (
        <div >
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                style={{ color: 'inherit' }}
                onClick={handleClick}
            >
                Sign In
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                // keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {signOptions && signOptions.length > 0 && signOptions.map(each => {
                    return <StyledMenuItem>
                        <ListItemText primary={each.Name} onClick={() => handleRoute(each)} />
                    </StyledMenuItem>
                })}
            </StyledMenu>
        </div>
    );
}
