import { loadModules } from 'esri-loader';
import { GeometryEngineTask } from '../util/geometryEngineTask';
import { QueryTaskExecuteWithrelation } from '../util/queryTask';
import { MapConstants } from '../util/mapConstants';
import { GISLookupTask, GISLookupTaskWithGeom } from './GISLookupTask'
import { getEnvVariable } from '../../../../modules/environmentalList';
import moment from "moment";

export function ApplyEditsFeatures(config, mapConstants, graphicsLayer, UserId, fetchGeomType, lookupdata = [], SubModuleKey) {
    return new Promise((resolve, reject) => {
        if (!loadModules) {
            reject('ApplyEditsFeaturesTask: ArcGIS API is not loaded');
            return;
        }
        ApplyEditsTaskExecute(config, mapConstants, graphicsLayer, UserId, fetchGeomType, lookupdata, SubModuleKey).then(
            response => {
                resolve(response);
            },
            error => {
                console.log(error);
                reject({ "error": error });
            });
    });
}
function ApplyEditsTaskExecute(config, mapConstants, graphicsLayer, UserId, fetchGeomType, lookupdata, SubModuleKey) {
    return new Promise((resolve, reject) => {
        if (!loadModules) {
            reject('ApplyEditsFeaturesTask: ArcGIS API is not loaded');
            return;
        }
        var allpointlinepolygongraphics = graphicsLayer.graphics.filter(g => { return g.geometry.type != null });
        var pointgraphics = graphicsLayer.graphics.filter(g => { return g.geometry.type == "point" });
        var linegraphics = graphicsLayer.graphics.filter(g => { return g.geometry.type == "line" || g.geometry.type == "polyline" });
        var polygongraphics = graphicsLayer.graphics.filter(g => { return g.geometry.type == "polygon" });
        console.log(pointgraphics, linegraphics, polygongraphics);
        var promises = []

        // For KEPT use Only, MULTIPLE LOCATIONS can be selected for each geometry: Saving each geometry.
        // allpointlinepolygongraphics.forEach(sketchgraphics => {
        //     console.log(sketchgraphics);
        //     let editsExcuteGraphic = graphicsLayer.graphics.filter(g=>{return g.geometry.type == sketchgraphics.geometry.type
        //         && g.geometry.x == sketchgraphics.geometry.x
        //         && g.geometry.y == sketchgraphics.geometry.y});
        //     promises.push(ApplyEditsTaskExecuteByType(config, mapConstants, editsExcuteGraphic, UserId, fetchGeomType, lookupdata, sketchgraphics.geometry.type));
        // });
        graphicsLayer.graphics.forEach(graphicTo => {
            promises.push(ApplyEditsTaskExecuteByType(config, mapConstants, [graphicTo], UserId, fetchGeomType, lookupdata, graphicTo.geometry.type));
        });

        // promises.push(ApplyEditsTaskExecuteByType(config, mapConstants, pointgraphics, UserId, fetchGeomType, lookupdata));
        // promises.push(ApplyEditsTaskExecuteByType(config, mapConstants, linegraphics, UserId, fetchGeomType, lookupdata));
        // promises.push(ApplyEditsTaskExecuteByType(config, mapConstants, polygongraphics, UserId, fetchGeomType, lookupdata));


        Promise.all(promises).then(applyEditResponses => {
            if (applyEditResponses) {

                // POINT_GEOM_OBJECT_ID,LINE_GEOM_OBJECT_ID,POLYGON_GEOM_OBJECT_ID,MULTIPOINT_GEOM_OBJECT_ID,CENTER_GEOM_OBJECT_ID;
                let pointgeomres = applyEditResponses.filter(a => a.POINT_GEOM_OBJECT_ID && a.POINT_GEOM_OBJECT_ID != null);
                let linegeomres = applyEditResponses.filter(a => a.LINE_GEOM_OBJECT_ID && a.LINE_GEOM_OBJECT_ID != null);
                let polygeomres = applyEditResponses.filter(a => a.POLYGON_GEOM_OBJECT_ID && a.POLYGON_GEOM_OBJECT_ID != null);
                let multipointgeomres = applyEditResponses.filter(a => a.MULTIPOINT_GEOM_OBJECT_ID && a.MULTIPOINT_GEOM_OBJECT_ID != null)

                let poinggeomid = '', polygongeomid = '', linegeomid = '', multipointgeomid = '';
                if (pointgeomres.length > 0)
                    poinggeomid = pointgeomres.map(a => a.POINT_GEOM_OBJECT_ID).join()
                if (linegeomres.length > 0)
                    linegeomid = linegeomres.map(a => a.LINE_GEOM_OBJECT_ID).join()
                if (polygeomres.length > 0)
                    polygongeomid = polygeomres.map(a => a.POLYGON_GEOM_OBJECT_ID).join()
                if (multipointgeomres.length > 0)
                    multipointgeomid = multipointgeomres.map(a => a.POLYGON_GEOM_OBJECT_ID).join()

                let MultipleLocations = []
                graphicsLayer.graphics.forEach(graphicTo => {
                    if (graphicTo && graphicTo.attributes && graphicTo.attributes.MultipleLocations) {
                        MultipleLocations.push(graphicTo.attributes.MultipleLocations[0])
                    }
                })

                let delpoints = GetDeletedObjects(config, 'POINT_GEOM_OBJECT_ID', poinggeomid);
                let delline = GetDeletedObjects(config, 'LINE_GEOM_OBJECT_ID', linegeomid);
                let delpolygon = GetDeletedObjects(config, 'POLYGON_GEOM_OBJECT_ID', polygongeomid);


                const POINT_IDS = {
                    'POINT_GEOM_OBJECT_ID': poinggeomid,
                    'LINE_GEOM_OBJECT_ID': linegeomid,
                    'POLYGON_GEOM_OBJECT_ID': polygongeomid,
                    'MULTIPOINT_GEOM_OBJECT_ID': multipointgeomid,
                    'MultipleLocationData': MultipleLocations,
                    'DELETED_GEOM_OBJECT_ID': [...delpoints, ...delline, ...delpolygon]
                }
                let applyObjectids = {}
                applyEditResponses.forEach(element => {
                    applyObjectids = { ...applyObjectids, ...element }
                });
                applyObjectids = { ...applyObjectids, ...POINT_IDS };
                // const pointgeomApplyEditResponse = applyEditResponses[0]; //Point
                // const linegeomApplyEditResponse = applyEditResponses[1]; //Line
                // const polygongeomApplyEditResponse = applyEditResponses[2]; //Polygon

                //let applyObjectids = { ...pointgeomApplyEditResponse, ...linegeomApplyEditResponse, ...polygongeomApplyEditResponse };
                ///return applyObjectids;
                if (getEnvVariable('ModuleKey') === 'AMES') {
                    if (applyObjectids && applyObjectids['95_SPIS_SITE'])
                        applyObjectids['95_SPIS_SITE'] = applyObjectids['95_SPIS_SITE'] && applyObjectids['95_SPIS_SITE'] > 95 ? 'Y' : 'N'
                    applyObjectids['HIGHWAY_SUBCLASS'] = applyObjectids['HIGHWAY_SUBCLASS'] ? '1' : '2'
                    applyObjectids['MPO'] = applyObjectids['MPO'] ? 'Y' : 'N';
                    applyObjectids['PROPOSED_COLLECTION_DATE'] = applyObjectids['PROPOSED_HIGHWAY_NAME'] ? moment(new Date()).format('MM/DD/YYYY') : ''
                    applyObjectids['PROPOSED_COLLECTION_DATE'] = applyObjectids['EXISTING_HIGHWAY_NAME'] ? moment(new Date()).format('MM/DD/YYYY') : ''
                    //"PROPOSED_HIGHWAY_NAME",//EXISTING_HIGHWAY_NAME
                }

                console.log('applied results -------------', applyObjectids)
                resolve(applyObjectids);
            }
        },
            error => {
                console.log(error);
                reject({ "error": error });
            });
    });
}

function GetDeletedObjects(config, objecttype, latestids) {
    if (latestids.length > 0) {
        let objectfeildvalue = config.FieldsData.filter(f => {
            return (f.Fieldkey.includes(objecttype));
        }).map(f => f.FieldValue);
        if (objectfeildvalue && Array.isArray(objectfeildvalue)) {
            let latestobjectarr = latestids.split(',');
            let oldobjectarr = objectfeildvalue[0] ? objectfeildvalue[0].split(',') : [];
            let deletedids = oldobjectarr.filter(x => !latestobjectarr.includes(x));
            return deletedids.filter(function (el) {
                return el != null && el != '';
            });
        }
    }
    return [];
}

function ApplyEditsTaskExecuteByType(config, mapConstants, graphics, UserId, fetchGeomType, lookupdata, applyeditsgeomtype, SubModuleKey) {
    return new Promise((resolve, reject) => {
        if (config && graphics && config.FeatureLayers) {
            let geometries = [];
            let proposedgeometries = [];
            let existinggeometries = [];
            let applyObjectids = {};
            let type = "POINT";
            //let mapconstants = new MapConstants();

            if (fetchGeomType && fetchGeomType.length > 0) {
                var pointTypes = fetchGeomType;
                let svgPointtSymbol = mapConstants.getSVGPointtSymbol()
                graphics.forEach(function (item) {
                    type = (item.geometry.type === "point" ? "POINT" : (item.geometry.type === "polyline" ? "LINE" : (item.geometry.type === "polygon" ? "POLYGON" : null)));
                    if (item.geometry.type === "multipoint") type = "MULTIPOINT"
                    let d = new Date();
                    let itemobjectid = item.attributes ? item.attributes.OBJECTID : null;
                    let attributes = { "ObjectId": itemobjectid, "OBJECTID": itemobjectid, "UpdatedBy": config.UserId, "UpdatedOn": d.getTime(), "HASHKEY": config.HASHKEY, "CreatedBy": config.UserId, "CreatedOn": d.getTime() };

                    if (type && type.toLowerCase() === "point") {
                        if (item.symbol && (item.symbol.url === mapConstants.ExistingPointSymbol.url || (svgPointtSymbol && item.symbol.url === svgPointtSymbol.url)))
                            existinggeometries.push({ "type": type, "Graphic": item.geometry, "attributes": attributes });
                        else
                            proposedgeometries.push({ "type": type, "Graphic": item.geometry, "attributes": attributes });
                    } else {

                        //if (geompointtype === "E")
                        existinggeometries.push({ "type": type, "Graphic": item.geometry, "attributes": attributes });
                        //else
                        //proposedgeometries.push({ "type": type, "Graphic": item.geometry, "attributes": attributes });
                    }
                });
                let allPromise = [];

                pointTypes.forEach(function (geompointtype) {
                    let editgeometries = geompointtype === "P" ? proposedgeometries : existinggeometries;
                    //let fetchGeomType = geompointtype === "PROPOSED" ?  "P" : "E"
                    let lookupdatabytype = lookupdata.filter(a => a.FieldFetchGeomType === geompointtype || a.FieldFetchGeomType === 'B');
                    let eachpromise = new Promise((resolve, reject) => {

                        applyEditsTaskExecuteWrapper(config, mapConstants, editgeometries, type, UserId, geompointtype, lookupdatabytype, applyeditsgeomtype, SubModuleKey)
                            .then(applyResponses => {
                                if (applyResponses) {
                                    if (graphics[0] && graphics[0].attributes && graphics[0].attributes.MultipleLocations) {
                                        let objresultid = applyResponses.POINT_GEOM_OBJECT_ID || applyResponses.POLYGON_GEOM_OBJECT_ID || applyResponses.LINE_GEOM_OBJECT_ID || applyResponses.MULTIPOINT_GEOM_OBJECT_ID
                                        if (graphics[0].attributes && Array.isArray(graphics[0].attributes.MultipleLocations) && graphics[0].attributes.MultipleLocations.length > 0)
                                            graphics[0].attributes.MultipleLocations[0].ObjectId = objresultid
                                    }
                                    resolve(applyResponses);
                                }
                            },
                                error => {
                                    console.log(error);
                                    reject({ "error": error });
                                });
                    })

                    allPromise.push(eachpromise);
                })

                Promise.all(allPromise).then(function (data) {
                    let returnObj = {}
                    data.forEach(function (item) {
                        returnObj = {
                            ...returnObj,
                            ...item
                        }
                    })

                    //return returnObj;
                    resolve(returnObj);
                }).catch(error => {
                    console.error(error.message)
                });

            } else {
                let lookupdatabytype = lookupdata.filter(a => a.FieldFetchGeomType === 'E' || a.FieldFetchGeomType === 'B');
                graphics.forEach(function (item) {
                    type = (item.geometry.type === "point" ? "POINT" : (item.geometry.type === "polyline" ? "LINE" : (item.geometry.type === "polygon" ? "POLYGON" : null)));
                    if (item.geometry.type === "multipoint") type = "MULTIPOINT"
                    let d = new Date();
                    let itemobjectid = item.attributes ? item.attributes.OBJECTID : null;
                    let attributes = { "ObjectId": itemobjectid, "OBJECTID": itemobjectid, "UpdatedBy": config.UserId, "UpdatedOn": d.getTime(), "HASHKEY": config.HASHKEY, "CreatedBy": config.UserId, "CreatedOn": d.getTime() };
                    //geometries.push({"geometry": item.geometry})
                    geometries.push({ "type": type, "attributes": attributes, "Graphic": item.geometry });
                });
                console.log('geom --------------------------', geometries)
                //Based on type selection
                applyEditsTaskExecuteWrapper(config, mapConstants, geometries, type, UserId, null, lookupdatabytype, SubModuleKey)
                    .then(applyResponses => {
                        if (applyResponses) {
                            if (graphics[0] && graphics[0].attributes && graphics[0].attributes.MultipleLocations) {
                                let objresultid = applyResponses.POINT_GEOM_OBJECT_ID || applyResponses.POLYGON_GEOM_OBJECT_ID || applyResponses.LINE_GEOM_OBJECT_ID || applyResponses.MULTIPOINT_GEOM_OBJECT_ID
                                if (graphics[0].attributes && Array.isArray(graphics[0].attributes.MultipleLocations) && graphics[0].attributes.MultipleLocations.length > 0)
                                    graphics[0].attributes.MultipleLocations[0].ObjectId = objresultid
                            }
                            /*
                            const applyPointObject = applyResponses[0]; //point
                            if (applyPointObject)
                                applyObjectids={...applyObjectids,...applyPointObject};

                            const applyPolylineObject = applyResponses[1]; //polyline
                            if (applyPolylineObject)
                                applyObjectids={...applyObjectids,...applyPolylineObject};

                            const applyPolygonObject = applyResponses[2]; //polygon
                            if (applyPolygonObject)
                                applyObjectids={...applyObjectids,...applyPolygonObject};
                                */
                            resolve(applyResponses);
                        }
                    },
                        error => {
                            console.log(error);
                            reject({ "error": error });
                        });

            }





        } else {
            reject({ "error": "Feature layers not found " });
        }
    });
}

function applyEditsTaskExecuteWrapper(config, mapConstants, geometries, type, UserId, fetchGeomPointType, lookupdata, applyeditsgeomtype, SubModuleKey) {
    return new Promise((resolve, reject) => {
        parseApplyEditsGeometries(config, mapConstants, geometries, type, UserId, fetchGeomPointType, SubModuleKey).then(
            applyGeoms => {
                if (applyGeoms && ((applyGeoms.geomapplyFeatures)
                    || (applyGeoms.centerapplyFeatures))) {
                    let applyObjectids = {};
                    let lookupgeoms = geometries
                    let geomlist = geometries.map(f => f.Graphic);
                    if (geomlist && Array.isArray(geomlist)) {
                        lookupgeoms = geomlist;
                    }
                    let allPromises = [ApplyEdits(config, applyGeoms.geomapplyFeatures, applyGeoms.isMultiGeom, type, null, fetchGeomPointType, applyeditsgeomtype),
                    ApplyEdits(config, applyGeoms.centerapplyFeatures, applyGeoms.isMultiGeom, type, applyGeoms.centerGeom, fetchGeomPointType, applyeditsgeomtype)]

                    if (Array.isArray(lookupgeoms) && lookupgeoms.length > 0) {
                        allPromises.push(GISLookupTaskWithGeom(config, lookupgeoms, lookupdata))
                    }

                    //ApplyAdcgrid(config, applyGeoms.geomapplyFeatures, applyGeoms.isMultiGeom, type, applyGeoms.centerGeom) : Removed ADCGrid,AMES specific
                    Promise.all(allPromises).then(applyEditResponses => {
                        if (applyEditResponses) {
                            const geomApplyEditResponse = applyEditResponses[0]; //Geom
                            const centerApplyEditResponse = applyEditResponses[1]; //Center
                            //const adcgridApplyEditResponse = applyEditResponses[2]; //ADCGrid
                            const lookupApplyEditResponse = applyEditResponses[2] || {}; //GIS Lookup
                            applyObjectids = { ...geomApplyEditResponse, ...centerApplyEditResponse, ...lookupApplyEditResponse };
                            resolve(applyObjectids);
                        }
                    },
                        error => {
                            console.log(error);
                            reject({ "error": error });
                        });
                }
                else {
                    resolve(null);
                }
            },
            applyGeomsError => {
                console.log(applyGeomsError);
                reject({ "error": applyGeomsError });
            });
    });
}


function ApplyAdcgrid(config, applyGeoms, isMultiGeom, type, centerGeom) {
    return new Promise((resolve, reject) => {
        if (applyGeoms && ((applyGeoms.addFeatures && applyGeoms.addFeatures.length > 0)
            || (applyGeoms.updateFeatures && applyGeoms.updateFeatures.length > 0))) {
            loadModules([
                "esri/layers/FeatureLayer", "esri/layers/MapImageLayer"
            ]).then(([FeatureLayer, MapImageLayer]) => {
                const adcgridlayer = new MapImageLayer({
                    url: getEnvVariable('ArcGIS') + `/rest/services/EPS_Dev/EPS_ADC_Grid/MapServer/0/query`,
                    outFields: ["*"]
                });
                let queryExpression = "1=1";
                let inputgeom;
                if (applyGeoms.addFeatures.length < 1) inputgeom = applyGeoms.updateFeatures[0];
                else inputgeom = applyGeoms.addFeatures[0];

                QueryTaskExecuteWithrelation(adcgridlayer, queryExpression, config.viewProperties.extent.spatialReference, inputgeom).then(
                    response => {
                        if (response && response.features) {
                            let gridRefString = "";
                            response.features.map(adcval => {
                                gridRefString = gridRefString + adcval.attributes.GridRef + ", ";
                            });
                            const objectIdResponse = {};
                            if (gridRefString != "") gridRefString = gridRefString.substring(0, gridRefString.length - 2)
                            objectIdResponse["ADC_MAP_GRID_NO"] = gridRefString;
                            resolve(objectIdResponse);
                        }
                    },
                    error => {
                        console.log(error);
                        reject({ "error": error });
                    }
                )
            });

        }

    });
}


function ApplyEdits(config, applyGeoms, isMultiGeom, type, centerGeom, fetchGeomPointType, applyeditsgeomtype) {
    /*
    applyGeoms.geomapplyFeatures;
    applyGeoms.centerapplyFeatures;
    */
    return new Promise((resolve, reject) => {
        if (applyGeoms && ((applyGeoms.addFeatures && applyGeoms.addFeatures.length > 0)
            || (applyGeoms.updateFeatures && applyGeoms.updateFeatures.length > 0))) {
            loadModules([
                "esri/layers/FeatureLayer"
            ]).then(([FeatureLayer]) => {
                const layerType = (centerGeom !== null ? "POINT" : ((isMultiGeom === true && type === "POINT") ? "MULTIPOINT" : type));

                const featureUrl = config.FeatureLayers[layerType];
                if (featureUrl) {
                    const featureLayer = new FeatureLayer({
                        url: featureUrl,
                        outFields: ["*"]
                    });
                    /*

                    Points={"EXisting":[],"Proposed":[]}
                    */

                    featureLayer.applyEdits(applyGeoms).then(result => {
                        const objectIdResponse = {};
                        objectIdResponse["GEOM_TYPE"] = applyeditsgeomtype;
                        if (result.addFeatureResults.length > 0 || result.updateFeatureResults.length > 0) {
                            if (centerGeom) {
                                // objectIdResponse["MAP_CENTER_COORDINATE_X"] = centerGeom.x;
                                // objectIdResponse["MAP_CENTER_COORDINATE_Y"] = centerGeom.y;
                                //Specific to AMES project checking for the twoway multipoint (Proposed/Existing)
                                if (fetchGeomPointType && fetchGeomPointType != null) {
                                    let ref_centerLatitude = (fetchGeomPointType === "P") ? "PROPOSED" + "_MAP_CENTER_LATITUDE" : "MAP_CENTER_LATITUDE";
                                    let ref_centerLongitude = (fetchGeomPointType === "P") ? "PROPOSED" + "_MAP_CENTER_LONGITUDE" : "MAP_CENTER_LONGITUDE";
                                    let ref_geomObjectID = (fetchGeomPointType === "P") ? "PROPOSED" + "_CENTER_GEOM_OBJECT_ID" : "CENTER_GEOM_OBJECT_ID";

                                    objectIdResponse[ref_centerLatitude] = centerGeom.latitude;
                                    objectIdResponse[ref_centerLongitude] = centerGeom.longitude;
                                    if (result.addFeatureResults.length > 0)
                                        objectIdResponse[ref_geomObjectID] = result.addFeatureResults[0].objectId;
                                    else if (result.updateFeatureResults.length > 0)
                                        objectIdResponse[ref_geomObjectID] = result.updateFeatureResults[0].objectId;
                                } else {
                                    objectIdResponse["MAP_CENTER_LATITUDE"] = centerGeom.latitude;
                                    objectIdResponse["MAP_CENTER_LONGITUDE"] = centerGeom.longitude;
                                    if (result.addFeatureResults.length > 0)
                                        objectIdResponse["CENTER_GEOM_OBJECT_ID"] = result.addFeatureResults[0].objectId;
                                    else if (result.updateFeatureResults.length > 0)
                                        objectIdResponse["CENTER_GEOM_OBJECT_ID"] = result.updateFeatureResults[0].objectId;
                                }


                            } else {
                                /* if (layerType=="POINT") {
                                     objectIdResponse["MAP_CENTER_LATITUDE"] = centerGeom.latitude;
                                     objectIdResponse["MAP_CENTER_LONGITUDE"] = centerGeom.longitude;
                                 }*/
                                if (fetchGeomPointType) {
                                    let ref_layerGeomObjectID = (fetchGeomPointType === "P") ? "PROPOSED" + "_" + layerType + "_GEOM_OBJECT_ID" : layerType + "_GEOM_OBJECT_ID";

                                    if (result.addFeatureResults.length > 0) {
                                        objectIdResponse[ref_layerGeomObjectID] = result.addFeatureResults[0].objectId;
                                        objectIdResponse["isnewObjectId"] = true
                                    } else if (result.updateFeatureResults.length > 0) {
                                        objectIdResponse[ref_layerGeomObjectID] = result.updateFeatureResults[0].objectId;
                                        objectIdResponse["isnewObjectId"] = false
                                    }
                                } else {
                                    if (result.addFeatureResults.length > 0) {
                                        objectIdResponse[layerType + "_GEOM_OBJECT_ID"] = result.addFeatureResults[0].objectId;
                                        objectIdResponse["isnewObjectId"] = true
                                    }

                                    else if (result.updateFeatureResults.length > 0) {
                                        objectIdResponse[layerType + "_GEOM_OBJECT_ID"] = result.updateFeatureResults[0].objectId;
                                        objectIdResponse["isnewObjectId"] = false
                                    }
                                }
                            }
                        }
                        resolve(objectIdResponse);
                    },
                        error => {
                            console.log(error);
                            reject({ "error": error });
                        });
                } else {
                    reject({ "error": "Feature layer not found ::" + type });
                }
            });
        }
        else {
            resolve(null);
        }
    });
}
function parseApplyEditsGeometries(config, mapConstants, geometries, type, UserId, fetchGeomPointType, SubModuleKey) {
    return new Promise((resolve, reject) => {
        let applyFeatures = { geomapplyFeatures: [], centerapplyFeatures: [] };
        if (geometries && geometries.length > 0) {
            const geomFeatures = geometries;
            let multigeoms = geometries.filter(f => {
                return f.type === "MULTIPOINT" || f.type === "multipoint";
            });
            let pointgeoms = geometries.filter(f => {
                return f.type === "POINT" || f.type === "point";
            });
            let ismultigeom = false;
            if ((multigeoms && multigeoms.length > 0) || (pointgeoms && pointgeoms.length > 1)) ismultigeom = true;
            if (geomFeatures && geomFeatures.length > 0) {
                const graphicies = geomFeatures.map(f => f.Graphic);
                const attributes = (geomFeatures !== null && geomFeatures.length > 0) ? geomFeatures[0].attributes : {};

                if (graphicies && graphicies.length === 1) {
                    loadModules([
                        "esri/Graphic",
                        "esri/geometry/Point",
                        "esri/geometry/Multipoint",
                        'esri/geometry/Polyline',
                        'esri/geometry/Polygon',
                        "esri/geometry/SpatialReference"
                    ]).then(([Graphic, Point, Multipoint, Polyline, Polygon, SpatialReference]) => {
                        const geom = (type === "POINT" ? new Point(graphicies[0]) : (type === "LINE" ? new Polyline(graphicies[0])
                            : (type === "POLYGON" ? new Polygon(graphicies[0]) : null)));
                        console.log('--SRID CHANGE-----------------------', config.viewProperties.extent.spatialReference)
                        let spatialReference = new SpatialReference(config.viewProperties.extent.spatialReference);
                        applyFeatures = parseApplyEditsGeometriesWrapper(config, Graphic, geom, attributes, graphicies[0], mapConstants, type, ismultigeom, spatialReference, UserId, fetchGeomPointType, SubModuleKey).then(
                            applyFeaturesResponse => {
                                resolve(applyFeaturesResponse);
                            },
                            applyFeaturesineerror => {
                                reject({ "error": applyFeaturesineerror });
                            });
                    });
                } else {
                    GeometryEngineTask({ "geometries": graphicies, "type": "union" }).then(
                        geomEngineResponse => {
                            if (geomEngineResponse) {
                                loadModules([
                                    "esri/Graphic",
                                    "esri/geometry/Multipoint",
                                    'esri/geometry/Polyline',
                                    'esri/geometry/Polygon',
                                    "esri/geometry/SpatialReference"
                                ]).then(([Graphic, Multipoint, Polyline, Polygon, SpatialReference]) => {
                                    const geom = (type === "POINT" ? new Multipoint(geomEngineResponse) : (type === "LINE" ? new Polyline(geomEngineResponse)
                                        : (type === "POLYGON" ? new Polygon(geomEngineResponse) : null)));
                                    console.log('--SRID CHANGE-----------------------', config.viewProperties.extent.spatialReference)
                                    let spatialReference = new SpatialReference(config.viewProperties.extent.spatialReference);
                                    applyFeatures = parseApplyEditsGeometriesWrapper(config, Graphic, geom, attributes, geomEngineResponse, mapConstants, type, ismultigeom, spatialReference, UserId, fetchGeomPointType, SubModuleKey).then(
                                        applyFeaturesResponse => {
                                            resolve(applyFeaturesResponse);
                                        },
                                        applyFeaturesineerror => {
                                            reject({ "error": applyFeaturesineerror });
                                        });
                                },
                                    error => {
                                        console.log(error);
                                        reject({ "error": error });
                                    });
                            } else {
                                resolve(applyFeatures);
                            }
                        },
                        geomEngineerror => {
                            console.log(geomEngineerror);
                            reject({ "error": geomEngineerror });
                        });
                }
            }
            else {
                resolve(applyFeatures);
            }
        }
        else {
            resolve(applyFeatures);
        }
    });
}

function parseApplyEditsGeometriesWrapper(config, Graphic, geom, attributes, uniongeomEngineResponse, mapConstants, type, isMultiGeom, spatialReference, UserId, fetchGeomPointType, SubModuleKey) {
    return new Promise((resolve, reject) => {
        let applyFeatures = {};
        let geomapplyFeatures = { addFeatures: [], updateFeatures: [] };
        let centerapplyFeatures = { addFeatures: [], updateFeatures: [] };

        const geomSymbol = (type === "POINT" ? (SubModuleKey == "COLLISION" || SubModuleKey == "INVESTIGATION") ? mapConstants.getSVGHighlighPointtSymbol() : mapConstants.PointSymbol : (type === "LINE" ? mapConstants.LineSymbol : (type === "POLYGON" ? mapConstants.PolygonFillSymbol : null)));

        //const layerType = (isMultiGeom === true && type === "POINT") ? "MULTIPOINT" : type;
        // let FDObjectid = config.FieldsData.filter(f => {
        //     return (f.Fieldkey.includes(layerType + "_GEOM_OBJECT_ID") && f.FieldValue > 0);
        // }).map(f => f.FieldValue);

        // if((FDObjectid[0]==0 || FDObjectid[0]==null || FDObjectid[0]== "") && layerType=="MULTIPOINT"){
        //     let constype="POINT";
        //     FDObjectid = config.FieldsData.filter(f => {
        //         return (f.Fieldkey.includes(constype + "_GEOM_OBJECT_ID") && f.FieldValue > 0);
        //     }).map(f => f.FieldValue);
        // }

        let FDCenterObjectid = config.FieldsData.filter(f => {
            return f.Fieldkey === "CENTER_GEOM_OBJECT_ID";
        }).map(f => f.FieldValue);

        if (fetchGeomPointType === "P") {
            FDCenterObjectid = config.FieldsData.filter(f => {
                return f.Fieldkey === "PROPOSED_CENTER_GEOM_OBJECT_ID";
            }).map(f => f.FieldValue)
        }

        // if (FDObjectid && FDObjectid.length > 0) {
        //     attributes.ObjectId = (fetchGeomPointType === "P" && FDObjectid.length > 0)  ? Number(FDObjectid[1]) : Number(FDObjectid[0]);
        //     attributes.UpdatedBy = UserId;
        //     attributes.CreatedBy = UserId;
        // } else {
        //     attributes.CreatedBy = UserId;
        // }

        if (attributes.ObjectId && attributes.ObjectId > 0) {
            attributes.UpdatedBy = UserId;
            attributes.CreatedBy = UserId;
        } else {
            attributes.CreatedBy = UserId;
        }

        let centerattributes = { ...attributes };

        if (FDCenterObjectid && FDCenterObjectid.length > 0){
            centerattributes.ObjectId = Number(FDCenterObjectid[0]);
            centerattributes.OBJECTID = Number(FDCenterObjectid[0]);
        }
            
        applyFeatures.isMultiGeom = isMultiGeom;
        const centerGeom = (isMultiGeom == false && type === "POINT") ? geom : (uniongeomEngineResponse.extent ? uniongeomEngineResponse.extent.center : uniongeomEngineResponse);
        console.log('parseApplyEditsGeometriesWrapper', centerattributes)
        if (spatialReference) {
            Promise.all([GeometryEngineTask({ "geometries": geom, "spatialReference": spatialReference, "type": "project" }),
            GeometryEngineTask({ "geometries": centerGeom, "spatialReference": spatialReference, "type": "project" })])
                .then(geomEngineResponse => {
                    if (geomEngineResponse) {
                        let projGeom = geomEngineResponse[0] || {};
                        let projcenterGeom = geomEngineResponse[1] || {};

                        let graphic = new Graphic({
                            geometry: projGeom,
                            attributes: attributes,
                            symbol: geomSymbol
                        });

                        if (centerGeom && centerGeom.latitude && centerGeom.longitude) {
                            projcenterGeom.latitude = centerGeom.latitude
                            projcenterGeom.longitude = centerGeom.longitude
                            applyFeatures.centerGeom = projcenterGeom;
                            let centerGeomGraphic = new Graphic({
                                geometry: projcenterGeom,
                                attributes: centerattributes,
                                symbol: geomSymbol
                            });


                            if (attributes.ObjectId)
                                geomapplyFeatures.updateFeatures.push(graphic);
                            else
                                geomapplyFeatures.addFeatures.push(graphic);

                            if (centerattributes.ObjectId)
                                centerapplyFeatures.updateFeatures.push(centerGeomGraphic);
                            else
                                centerapplyFeatures.addFeatures.push(centerGeomGraphic);

                            applyFeatures.geomapplyFeatures = geomapplyFeatures;
                            applyFeatures.centerapplyFeatures = centerapplyFeatures;
                            resolve(applyFeatures);
                        }
                    }
                },
                    error => {
                        console.log(error);
                        reject({ "error": error });
                    });
        }
    });
}


export function ApplyEditsGeometry(config, mapConstants, graphics, UserId, applyeditsgeomtype, lookupdata = [], SubModuleKey) {
    return new Promise((resolve, reject) => {
        ApplyEditsTaskExecuteByType(config, mapConstants, graphics, UserId, [], lookupdata, applyeditsgeomtype, SubModuleKey).then(
            response => {
                resolve(response);
            },
            error => {
                console.log(error);
                reject({ "error": error });
            });
    });
    // try {
    //     ApplyEditsTaskExecuteByType(config, mapConstants, graphics, UserId, [], [], applyeditsgeomtype,SubModuleKey).then(res =>{
    //         console.log('select result save----------------',res);
    //     })
    // } catch (error) {
    //     console.log(error)
    // }
}