/**
     *********************************************************
     Description: Creates drawer on the left side. Needs to be modified to make it generic.
     Required props:  Used internally by other components.
     limitations    : N/A
     *********************************************************
**/
import { AppBar, Toolbar } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { getEnvVariable } from '../../../../modules/environmentalList';
import ProgressBar from '../../../modules/progress-bar';
import Template from '../../../modules/template/template';
import { createRequest, requestApi } from '../../helpers/rest';

class NotificationEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.openNotificationSlide,
            subject: '',
            recordDetails: [],
            eventDetails: [],
            internalNotificationTemplate: {},
            externalNotificationTemplate: {},
            isNotificationSent: false,
            confirmOpen: false,
            hasError: false,
            isSuccess: false
        }
        this.TemplateForm = React.createRef()
    }

    componentDidMount() {
        this.getLatestRecordDataDictionary();
    }


    getLatestRecordDataDictionary() {
        //this.progressBar.start()
        let siteid = this.props.history ? this.props.match.params.routeid : this.props.sitepermittypeid
        let recordid = this.props.history ? this.props.match.params.routeid1 : this.props.recordid
        // let applicationdataurl = getEnvVariable('Application')+`/api/Application/FieldValues/${siteid}/${recordid}`

        let applicationdataurl = {
            url: getEnvVariable('Application')+`/api/Application/FieldValues/${siteid}/${recordid}`,
            type: 'get',
            // alert: true,
            // loader: true
        }
        requestApi(createRequest(applicationdataurl)).then(results => {
            if (results) {
                this.setState({
                    recordDetails: results
                }, () => {
                    if (this.props.isFieldValueChange && this.props.isFieldValueChange === true) {
                        this.getLastUpdatedFieldRecords()
                    } else {
                        this.getPossibleNotifications()
                    }
                })
            }

        })
    }

    getLastUpdatedFieldRecords() {
        let newurl = {
            url: getEnvVariable('Application')+`/api/application/lastUpdatedRecordFields/${this.props.sitepermittypeid}/${this.props.recordid}`,
            type: 'get',
            // alert: true,
            // loader: true
        }
        //  let newurl = getEnvVariable('Application')+`/api/application/lastUpdatedRecordFields/${this.props.sitepermittypeid}/${this.props.recordid}`;
        //requestApi({ get: { url: newurl, routeProps: {} } }, null)
        requestApi(createRequest(newurl)).then(results => {
            if (results) {
                this.setState({
                    eventDetails: results
                }, () => this.getPossibleNotifications())
            }
        })
    }

    getPossibleNotifications() {
        let siteid = this.props.history ? this.props.match.params.routeid : this.props.sitepermittypeid
        let recordid = this.props.history ? this.props.match.params.routeid1 : this.props.recordid

        let getPossibleNotificationURL = getEnvVariable('Hangfire')+`/api/NotificationTrigger/PossibleNotifications`
        let eventDetails = {
            SubModuleKey: "APPLICATIONS",
            SitePermitTypeId: siteid,
            EventId: recordid,
            EventOnKey: this.props.eventonkey || 'FIELD_VALUE_CHANGE',//'FIELD_VALUE_CHANGE',
            EventDetails: this.state.eventDetails,
            DataDictionary: this.state.recordDetails,
        }
        let requrl = {
            url: getEnvVariable('Hangfire')+`/api/NotificationTrigger/PossibleNotifications`,
            type: 'post',
            alert: true,
        }
        // requestApi({ post: { url: getPossibleNotificationURL, routeProps: {} } }, null, eventDetails)

        requestApi(createRequest(requrl, eventDetails)).then(results => {
            if (results && results.length > 0) {
                let internal = results.filter(a => a.UseType == "I")[0]
                let external = results.filter(a => a.UseType == "E")[0]
                this.setState({
                    internalNotificationTemplate: internal,
                    externalNotificationTemplate: external
                });
                //this.progressBar.complete()
            } else {
                this.setState({ hasError: true });
            }
        })
    }

    handleClose = () => {
        if (!this.state.isNotificationSent) {
            this.setState({ confirmOpen: true })
        } else {
            this.setState({ open: false })
        }

    }

    handleSave = () => {
        this.TemplateForm.current.GetTemplateFormats();
    }

    templateEditorSave(returnid) {
        // Not required, not saving template records
    }

    templateFormatsCallBack(inthis, template) {
        //   debugger
        console.log(template);
        inthis.triggerManualNotification(template)
    }

    triggerManualNotification(templateformat) {
        //let siteid = this.props.history ? this.props.match.params.routeid : this.props.sitepermittypeid
        let recordid = this.props.history ? this.props.match.params.routeid1 : this.props.recordid

        let url = getEnvVariable('Hangfire')+`/api/NotificationTrigger/RaiseManualEventNotifications`

        let NotificationTemplateModel = [];
        let internal = this.state.internalNotificationTemplate
        if (internal && internal.NotificationSettingId && internal.NotificationSettingId > 0) {
            internal.NotificationSubject = templateformat.subject;
            internal.NotificationContentHTML = templateformat.fileContentHTML;
            internal.RefId = recordid;
            NotificationTemplateModel.push(this.state.internalNotificationTemplate)
        }
        let reqtriggerurl = {
            url: getEnvVariable('Hangfire')+`/api/NotificationTrigger/RaiseManualEventNotifications`,
            type: 'post',
            alert: true,
            loader: true
        }
        if (NotificationTemplateModel.length > 0) {
            // requestApi({ post: { url: url, routeProps: {} } }, null, NotificationTemplateModel)
            requestApi(createRequest(reqtriggerurl, NotificationTemplateModel)).then(results => {
                if (results) {
                    this.setState({ isNotificationSent: true }, () => {
                        this.handleClose();
                    })
                }
            })
        }
    }

    confirmSave(inthis) {
        inthis.setState({ open: false, confirmOpen: false })
        if (inthis.props.handleNotificationClose)
            inthis.props.handleNotificationClose();
        else
            this.setState({ isSuccess: true })
    }

    confirmClose(inthis) {
        inthis.setState({ confirmOpen: false })
    }

    render() {
        const { classes } = this.props
        const { open, confirmOpen, internalNotificationTemplate, recordDetails, hasError, isSuccess } = this.state
        return (
            <Fragment>
                <AppBar position="sticky" color='secondary'>
                    <Toolbar variant="dense">
                        Notification
                                <div className='flex-grow-1' />
                        <div>
                        <i className="" onClick={this.handleClose} >
                                <IconButton title="Close" color="inherit">
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </i>
                            <i className="" onClick={this.handleSave} >
                                <IconButton title="Send" color="inherit">
                                    <SendIcon fontSize="small" />
                                </IconButton>
                            </i>
                        </div>
                    </Toolbar>
                </AppBar>
                <div> <ProgressBar ref={e => this.progressBar = e} /></div>
                <div className="col-12">
                    <Typography variant="subtitle2" gutterBottom>
                        Following notification content is generated based on settings. You can modify notification subject and body before sending.
                    </Typography>
                    {hasError && <div style={{ color: 'red' }}> No related notification settings found</div>}
                    {isSuccess && <div style={{ color: 'green' }}> ****************************Notification sent successfully !!**********************************</div>}
                    <Template ref={this.TemplateForm}
                        handleTemplateId={this.templateEditorSave}
                        entryData={recordDetails}
                        parentid={(internalNotificationTemplate && internalNotificationTemplate.TemplateId) ? internalNotificationTemplate.TemplateId : 0}
                        isAdmin={false}
                        templateId={0}
                        isEnabled={true}
                        hasSubject={true}
                        templateFormatsCallBack={(data) => this.templateFormatsCallBack(this, data)}
                        canDownload={false}
                    />
                    {/* {confirmOpen && <ConfirmationDialog open={true}
                        description={"Are you sure, you don't want to send this notification ? "}
                        title={"Cancel sending notification"}
                        onSubmit={() => this.confirmSave(this)}
                        onClose={() => this.confirmClose(this)}
                        labels={["Don't Send", "Cancel"]}
                    />} */}
                </div>
            </Fragment>
        )
    }

}

export default (withRouter)(NotificationEditor);