import React, { Component, Fragment } from 'react';
import { getEnvVariable } from '../../../../modules/environmentalList';
import { withRouter } from 'react-router-dom';
import { requestApi } from '../../../modules/form/utilities';
import { GetAppDownloadTableDetails } from './appdownloadscustomdata';
import { createRequest, requestApi as RequestNewApi } from '../../helpers/rest';
import Cgridheader from '../../../modules/Grid/cgridheader';
import { GetData } from '../../helpers/requests';

// const editorheight = {
//     height: '900px'
// }


const headerProps = {
    title: "Project Downloads",
    components: [
        {
            type: 'breadcrumbs',
            items: [
                { "link":getEnvVariable('TitleKey')+` Settings`, "tab": 2, "materialicon": "spellcheck", href: getEnvVariable('InternalBasepath'), className: 'breadcrumb-item text-dark', class: 'text-dark' },
                { "link": "Project Downloads", href: "#/", "materialicon": "download", className: 'breadcrumb-item', class: 'text-light' }
            ],
        }
    ],
}
class AppDownloads extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mergeData: [],
            mentions: [],
            loadData: false,
            sidebartables: [],
            sidebartablementionoptions: [],
            iframeoptions: {}
        }
        this.frameView = React.createRef()
    }

    componentDidMount() {
        console.log("iframe is here")
        let inthis = this
        let modulesData
        let sitePermitTypesData
        window.addEventListener('message', (msg) => this.messageHandler(msg, inthis));
        let read = [

            {
                url: getEnvVariable('MasterAdmin') + `/api/module/` + getEnvVariable('ModuleKey') + `/submodule/All/Active/IsConfigurable`,
                type: 'get'
            },
            {
                url: getEnvVariable('AdminConfig') + `/api/SitePermitTypes/SubModuleKey/Active`,
                type: 'get'
            },

        ]
        Promise.all(read.map(each => RequestNewApi(createRequest(each, {}, null)).catch(error => { return error; }))).then(resp => {
            const [modulesdata, sitepermittypesdata] = resp
            if (modulesdata) {
                console.log(modulesdata);
                modulesData = modulesdata;
            }
            if (sitepermittypesdata) {
                console.log(sitepermittypesdata);
                sitePermitTypesData = sitepermittypesdata;
            }
            if (modulesData != null && modulesData != undefined && sitePermitTypesData != null && sitePermitTypesData != undefined)
                inthis.dataMerge(modulesData, sitePermitTypesData);
        })

    }


    dataMerge = (modulesData, sitePermitTypesData) => {
        let entitydata = []
        for (var i = 0; i < modulesData.length; i++) {
            for (var j = 0; j < sitePermitTypesData.length; j++) {
                if (modulesData[i].Key == sitePermitTypesData[j].SubModuleKey) {
                    entitydata.push({
                        "Key": modulesData[i].Key + "(" + sitePermitTypesData[j].Name + ")",
                        "Value": modulesData[i].Key + "(" + sitePermitTypesData[j].Name + ")",
                        "Label": modulesData[i].Key + "(" + sitePermitTypesData[j].Name + ")",
                        "Module": "1",
                        "SubModule": "1",
                        "DownloadId": "1",
                        "Group": modulesData[i].Key + "(" + sitePermitTypesData[j].Name + ")",
                        "SitePermitTypeId": sitePermitTypesData[j].Id
                    })
                }
            }

        }
        //Pushing Additional Data for Invoices data
        entitydata.push({
            "Key": "Invoices", "Value": "Invoices", "Label": "Invoices", "Module": "1",
            "SubModule": "1", "DownloadId": "1", "Group": "Invoices", "SitePermitTypeId": "Invoice"
        })
        entitydata.push({
            "Key": "InvoiceLineItems", "Value": "InvoiceLineItems", "Label": "InvoiceLineItems", "Module": "1",
            "SubModule": "1", "DownloadId": "1", "Group": "InvoiceLineItems", "SitePermitTypeId": "Invoice"
        })
        entitydata.push({
            "Key": "InvoiceDetails", "Value": "InvoiceDetails", "Label": "InvoiceDetails", "Module": "1",
            "SubModule": "1", "DownloadId": "1", "Group": "InvoiceDetails", "SitePermitTypeId": "Invoice"
        })
        entitydata.push({
            "Key": "BillingPaymentDetails", "Value": "BillingPaymentDetails", "Label": "BillingPaymentDetails", "Module": "1",
            "SubModule": "1", "DownloadId": "1", "Group": "BillingPaymentDetails", "SitePermitTypeId": "Invoice"
        })

        this.setState({ mergeData: entitydata, loadData: true });
    }

    messageHandler(message, inthis) {
        try {
            if (inthis.frameView && inthis.frameView.contentWindow) {
                if (message.data === 'Initialized')
                    inthis.frameView.contentWindow.postMessage({ prop: { ...inthis.iframeProps() } }, "*")
                if (message.data && message.data.messagetype == 'RecordSelected') {
                    let ifrmaopt = inthis.iframeProps();
                    ifrmaopt.id = message.data.id;
                    inthis.frameView.contentWindow.postMessage({ prop: { ...ifrmaopt } }, "*")
                }
                if (message.data && message.data.messagetype == 'templateformat') {
                    inthis.formatsCallBack()
                    let ifrmaopt = inthis.iframeProps();
                    ifrmaopt.id = message.data.id;
                    inthis.frameView.contentWindow.postMessage({ prop: { ...ifrmaopt } }, "*")
                }
                if (message.data && message.data.messagetype == 'seldownloadmodule') {
                    let seldownloadmodule = message.data.seldownloadmodule;
                    let ifrmaopt
                    let filterSitePermitType = this.state.mergeData.filter(b => b.Key == seldownloadmodule)[0];
                    let ifrmaOpts
                    let SubmoduleKey = filterSitePermitType.Key.split('(')
                    let url = getEnvVariable('Application') + `/api/SubModuleSideBar/All/` + SubmoduleKey[0]
                    GetData(url).then(resp => {
                        //TBD-Moving to services
                        //Specific to custom and invoice tables
                        let sidebarlist = this.getSideBarItems(resp, filterSitePermitType.SitePermitTypeId)
                        if (filterSitePermitType.SitePermitTypeId == "Invoice") {
                            let values = GetAppDownloadTableDetails(seldownloadmodule)
                            ifrmaOpts = this.iframeProps();
                            ifrmaOpts.mentions = values;
                            ifrmaopt = ifrmaOpts;
                            ifrmaopt.module = seldownloadmodule
                            ifrmaopt.sidebartables = sidebarlist || []
                            this.setState({ iframeoptions: ifrmaopt, sidebartables: sidebarlist, filterSitePermitType: filterSitePermitType }, () => {
                                inthis.frameView.contentWindow.postMessage({ prop: { ...ifrmaopt } }, "*")
                            })

                        } else {
                            var sourceURL = getEnvVariable('Application') + "/api/Application/Fields/" + filterSitePermitType.SitePermitTypeId;//+ "?table=Images";
                            let arr = []
                            arr.push(this.GetDataPromise(sourceURL))

                            Promise.all(arr).then((values) => {
                                if (Array.isArray(values)) {
                                    ifrmaOpts = this.iframeProps();
                                    ifrmaOpts = { ...ifrmaOpts, ...this.state.iframeoptions }
                                    ifrmaOpts.mentions = values[0];
                                    ifrmaopt = ifrmaOpts;
                                    ifrmaopt.module = seldownloadmodule
                                    ifrmaopt.sidebartables = sidebarlist || []//this.getSideBarItems(filterSitePermitTypeID);
                                    this.setState({ iframeoptions: ifrmaopt, sidebartables: sidebarlist, filterSitePermitType: filterSitePermitType }, () => {
                                        inthis.frameView.contentWindow.postMessage({ prop: { ...ifrmaopt } }, "*")
                                    })
                                }
                            });
                        }

                    })

                    //TBD-Moving to services
                    //Specific to custom and invoice tables

                } if (message.data && message.data.messagetype == 'sidebartablesel') {
                    let seltable = message.data.result;
                    console.log('Sidebar tables data to be populated-' + seltable);
                    let ifrmaOpts = this.iframeProps();
                    ifrmaOpts = { ...ifrmaOpts, ...this.state.iframeoptions }
                    this.getSideBarTableMentionOptionsAsync(seltable).then(sidebarres => {
                     //   ifrmaOpts.sidebartablementionoptions = sidebarres;
                        ifrmaOpts.mentions=sidebarres
                        inthis.frameView.contentWindow.postMessage({ prop: { ...ifrmaOpts } }, "*")
                    })
                    //ifrmaOpts.sidebartablementionoptions = this.getSideBarTableMentionOptionsAsync(seltable) //this.getSideBarTableMentionOptions(seltable);
                    //inthis.frameView.contentWindow.postMessage({ prop: { ...ifrmaOpts } }, "*")
                }
                else if (message.data && message.data.id)
                    inthis.callBack(message.data)
            }
        } catch (e) {
            console.log(e)
        }
    }



    getSideBarItems(sidebars, sitepermittypeid) {
        let arr = []
        sidebars.forEach(element => {
            arr.push({ "Key": element.Key, "Value": element.TableName, "Label": element.SidebarTittle, "Module": "1", "SubModule": "1", "DownloadId": "1", "Group": element.Key, "SitePermitTypeId": sitepermittypeid })
        })
        // arr.push({ "Key": "Notes", "Value": "Notes", "Label": "Notes", "Module": "1", "SubModule": "1", "DownloadId": "1", "Group": "Notes", "SitePermitTypeId": sitepermittypeid })
        // //arr.push({ "Key":"Volunteers","Value": "Volunteers","Label": "Volunteers","Module": "1", "SubModule": "1", "DownloadId": "1", "Group": "Volunteers","SitePermitTypeId": sitepermittypeid })
        // //arr.push({ "Key":"Attachments","Value": "Attachments","Label": "Attachments","Module": "1", "SubModule": "1", "DownloadId": "1", "Group": "Attachments","SitePermitTypeId": sitepermittypeid })
        // //arr.push({ "Key":"AttachmentDetails","Value": "AttachmentDetails","Label": "AttachmentDetails","Module": "1", "SubModule": "1", "DownloadId": "1", "Group": "AttachmentDetails","SitePermitTypeId": sitepermittypeid })
        // //arr.push({ "Key":"Images","Value": "Images","Label": "Images","Module": "1", "SubModule": "1", "DownloadId": "1", "Group": "Images","SitePermitTypeId": sitepermittypeid })
        // arr.push({ "Key": "AppWorkNotification", "Value": "AppWorkNotification", "Label": "AppWorkNotification", "Module": "1", "SubModule": "1", "DownloadId": "1", "Group": "AppWorkNotification", "SitePermitTypeId": sitepermittypeid })
        // arr.push({ "Key": "WorkNotifVolunteers", "Value": "WorkNotifVolunteers", "Label": "WorkNotifVolunteers", "Module": "1", "SubModule": "1", "DownloadId": "1", "Group": "WorkNotifVolunteers", "SitePermitTypeId": sitepermittypeid })
        // 
        return arr;
    }

    getSideBarTableMentionOptionsAsync(tablename) {
        //  var sourceURL = getEnvVariable('Application') + "/api/Application/SidebarFields/" + tablename;
        var sourceURL = getEnvVariable('Application') + "/api/Application/Fields/" + this.state.filterSitePermitType.SitePermitTypeId + "?table=" + tablename;
        return new Promise((resolve, reject) => {
            requestApi({ get: { url: sourceURL } }, null, null).then(results => {
                if (results)
                    resolve(results)
            }).catch(ex => {
                resolve([])
            })
        })
    }

    getSideBarTableMentionOptions() {
        return [
            { "Key": "Id", "Label": "Id", "IsTemplate": false, "TemplateId": null, "Value": "Id", "Section": "AppWorkNotification" },
            { "Key": "Comments", "Label": "Comments", "IsTemplate": false, "TemplateId": null, "Value": "Comments", "Section": "AppWorkNotification" },
            { "Key": "RefId", "Label": "RefId", "IsTemplate": false, "TemplateId": null, "Value": "RefId", "Section": "AppWorkNotification" },
            { "Key": "RefType", "Label": "RefType", "IsTemplate": false, "TemplateId": null, "Value": "RefType", "Section": "AppWorkNotification" },
            { "Key": "StartTime", "Label": "StartTime", "IsTemplate": false, "TemplateId": null, "Value": "StartTime", "Section": "AppWorkNotification" },
            { "Key": "ReasonNo", "Label": "ReasonNo", "IsTemplate": false, "TemplateId": null, "Value": "ReasonNo", "Section": "AppWorkNotification" },
            { "Key": "InternalComments", "Label": "InternalComments", "IsTemplate": false, "TemplateId": null, "Value": "InternalComments", "Section": "AppWorkNotification" },
            { "Key": "WorkApproved", "Label": "WorkApproved", "IsTemplate": false, "TemplateId": null, "Value": "WorkApproved", "Section": "AppWorkNotification" },
            { "Key": "VolunteerName", "Label": "VolunteerName", "IsTemplate": false, "TemplateId": null, "Value": "VolunteerName", "Section": "AppWorkNotification" },
            { "Key": "NameOfMinor", "Label": "NameOfMinor", "IsTemplate": false, "TemplateId": null, "Value": "NameOfMinor", "Section": "AppWorkNotification" },
            { "Key": "SignDate", "Label": "SignDate", "IsTemplate": false, "TemplateId": null, "Value": "SignDate", "Section": "AppWorkNotification" },
            { "Key": "IsMinor", "Label": "IsMinor", "IsTemplate": false, "TemplateId": null, "Value": "IsMinor", "Section": "AppWorkNotification" },
        ]
    }


    callBack(callbackdata) {
        console.log(callbackdata);
    }

    formatsCallBack(callbackdata) {
        console.log(callbackdata);
    }

    GetDataPromise = (sourceURL) => {
        return new Promise((resolve, reject) => {
            requestApi({ get: { url: sourceURL } }, null, null).then(results => {
                if (results)
                    resolve(results)

            }).catch(ex => {
                resolve([])
            })
        })
    }

    iframeProps() {
        let access_token = localStorage.access_token
        let iframeoptions = {
            mentions: this.state.mentions,
            isAdmin: true,
            id: 0,
            parentid: 0,
            callBack: this.templateCallBack,
            entryData: this.state.entrydata,
            isEnabled: true,
            canDownload: true,
            save: false,
            showhistory: true,
            fields: [{}],
            fullheight: true,
            project: getEnvVariable('ModuleKey'),
            downloadmodules: this.state.mergeData,
            module: '',
            useraccesstoken: access_token,
            sidebartables: this.state.sidebartables,
            sidebartablementionoptions: this.state.sidebartablementionoptions
        }
        return iframeoptions;
    }

    resizeIframe(obj) {
        console.log(obj.style.height)
        console.log(obj.contentWindow.document.documentElement.scrollHeight)
    }


    render() {
        let inthis = this;
        let src = (getEnvVariable('ModuleKey') == "UPS") ? 'https://development.delasoft.com:8443/UPS-appdownloads/#/' : getEnvVariable('AppDownloadUI');
        return (
            <div>
                <Cgridheader headerProps={headerProps} />
                {this.state.loadData == true ?
                    (<iframe id='appdownload-editor' src={src} ref={(f) => { this.frameView = f }}
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        width="100%"
                        height={window.innerHeight - 150}
                        title='Download Templates'
                        scrolling="auto"
                        onload={(e) => this.resizeIframe(e)}  >
                    </iframe>) : ('Loading...')}
            </div>
        )
    }
}
export default withRouter(AppDownloads);
