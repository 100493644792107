  /**        
        *********************************************************
            deprecated
        *********************************************************
 **/  

import { addcomp, delcomp, updatecomp} from './utilities'

const adminformReducer =(state = null, action)=> {
    const items = action.message
    switch (action.type) {
        case 'ADMINFORM_STATUS_SAVE':
            return { ...state, ...items }
        case 'ADMINFORM_STATUS_DEL':
            return { ...state, components: delcomp(state.components, items)  }
        case 'ADMINFORM_STATUS_ADD':
            return { ...state, components: addcomp(state.components, items) }
        case 'ADMINFORM_STATUS_UPDATE':
            return { ...state }
        default:
            return state

    }
}

export default adminformReducer