import { getEnvVariable } from "../../modules/environmentalList"

const newsavedreportstypes = {
    "type": "formcontainer",
    "key": "reporttypesnewform",
    "options": {
        "totalcolumns": 2,
        "totalrows": 4,
        "alignitem": "center",
        "name": "savedreport",
        "title": "Save Report",

        "crud": {
            "update": {
                "post": {
                    "url": getEnvVariable('Admin')+`/api/reportType/Add`,
                    "newroute": {
                        "route": "/Administration/reporttypes",
                        "matchProps": {
                            "value": "Id"
                        }
                    }
                }
            }
        },
        "validations": {
            get: {
                "url": getEnvVariable('Admin')+`/api/ProjectMessages/RulesByName?name=Module.reports`
            },
            transformProps: {
                name: 'Key',
                subfieldname: getEnvVariable('ModuleKey')+'MODULE.REPORTS_'
            }

        }
    },
    "components": [
        // {
        //     "type": "formdropdown",
        //     "key": "sitepermitsite",
        //     "options": {
        //         "row": 0,
        //         "col": 0,
        //         "width": 12,
        //         "label": "Report Group",
        //         "name": "MasterId",
        //         crud: {
        //             read: {
        //                 get: {
        //                     url: getEnvVariable('Report')+`/ReportMaster/All`,

        //                 }
        //             }
        //         },
        //         defaultValue: 7,
        //         "transformProps": {
        //             "value": "Id",
        //             "name": "Name"
        //         }, "disabled": true,
        //         conditions: {
        //             enable: true,
        //         }
        //     }
        // },
        {
            "type": "formtextbox",
            "key": "reporttypename",
            "options": {
                "row": 1,
                "col": 0,
                "width": 12,
                "label": "Report Name",
                "name": "Name",
                required: true
            }
        },
        // {
        //     "type": "formtextbox",
        //     "key": "reporttypKey",
        //     "options": {
        //         "row": 1,
        //         "col": 0,
        //         "width": 12,
        //         "label": "Key",
        //         "name": "Key"
        //     }
        // },

        // {
        //     "type": "formtextbox",
        //     "key": "templateTitle",
        //     "options": {
        //         "row": 2,
        //         "col": 0,
        //         "width": 12,
        //         "label": "Title",
        //         "name": "Title"
        //     }
        // },

        {
            "type": "formtextbox",
            "key": "description",
            "options": {
                "row": 2,
                "col": 0,
                "width": 12,
                "label": "Description",
                "name": "Description",
                "multiline": true
            }
        },
        // {
        //     "type": "formtextbox",
        //     "key": "templateJsonQuery",
        //     "options": {
        //         "row": 4,
        //         "col": 0,
        //         "width": 12,
        //         "label": "ReportJsonQueryStr",
        //         "name": "ReportJsonQueryStr",
        //         "multiline": true
        //     }
        // },
        // {
        //     "type": "formswitch",
        //     "key": "reportwitch",
        //     "options": {
        //         "row": 5,
        //         "col": 0,
        //         "width": 12,
        //         "label": "Status",
        //         "name": "Status",
        //         initialvalue: 'I',
        //         "transformProps": {
        //             "A": true,
        //             "I": false,
        //             "true": "A",
        //             "false": "I"
        //         }
        //     }
        // }
    ]
}

export default newsavedreportstypes