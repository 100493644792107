import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { green, pink, teal } from '@material-ui/core/colors';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { makeStyles } from '@material-ui/core/styles';
import Textbox from './textbox';
import DefaultSettings from './settings';
import moment from 'moment';
import { getEnvVariable } from '../../../modules/environmentalList';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    teal: {
        color: theme.palette.getContrastText(teal[500]),
        backgroundColor: teal[500],
    },
}));


function CircularProgressWithLabel(props) {
    const { name, label, view } = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [dailog, setDialog] = React.useState(false)
    const [changeDate, setText] = React.useState(props.currentDay)
    const [error, seterror] = React.useState(false)

    const classes = useStyles();
    const handleClick = (event) => {
        if (!view) {
            setAnchorEl(event.currentTarget);
            setOpen(true);
        }
        else event.preventDefault();
    };

    const handleValidation = () => {
        if (parseInt(changeDate) > 0 && props.name) {
            setDialog(false);
            seterror(false)
            props.handleFieldValue(props.name, changeDate);
        }
        else seterror(true)

        // if (parseInt(changeDate) <= parseInt(props.totalDays)) {
        //     setDialog(false);
        //     seterror(false)
        //     props.handleFieldValue('currentDay', changeDate);
        // }
        // else seterror(true)
    }

    let colorClass = ''
    if (props.eventState === 'X') {
        colorClass = classes.pink
    }
    else if (props.eventState === 'S')
        colorClass = classes.green
    else if (props.eventState === 'R')
        colorClass = classes.teal
    return (
        <Fragment>
            <Box className="pt-3" position="relative" display="inline-flex" >
                <CircularProgress variant="static" {...props} />
                <Box
                    top={18}
                    left={3}
                    bottom={0}
                    right={0}
                    position="absolute"
                    alignItems="center"
                    justifyContent="center"
                >
                    <IconButton disabled={props.disabled} className={getEnvVariable('AccessPortal') === 'E' ? 'none-pointer' : ''} onClick={(evt) => { getEnvVariable('AccessPortal') === 'E' ? evt.preventDefault() : handleClick(evt) }}> <Avatar className={colorClass}
                        style={{ height: 30, width: 30 }} >{`${Math.round(props.currentDay || 0)}`}</Avatar></IconButton>
                </Box>
            </Box>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={() => setOpen(false)}
            >
                {props.items && props.items.length > 0 && props.items.map((item, index) => {
                    return <MenuItem key={index.toString()} selected={props.eventState === item.value}
                        onClick={() => props.handleFieldValue('event', item.value)}>{item.name === "emptyspace" ? "" : item.name}</MenuItem>
                })}
                <MenuItem onClick={() => setDialog(true)}>Edit</MenuItem>
            </Menu>
            <Dialog open={dailog} onClose={() => setDialog(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Change the {label}</DialogTitle>
                <DialogContent>
                    <Textbox
                        autoFocus
                        margin="dense"
                        name="changeDate"
                        error={error}
                        value={changeDate}
                        helperText={error ? " Clock should be greater than zero " : ''}
                        label={label}
                        type="number"
                        handleFieldValue={(e) => { setText(e.target.value) }}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { seterror(false); setDialog(false) }} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleValidation()} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment >
    );
}


export default function CircularStatic(props) {
    const { handleFieldValue, extraProps, shareProps, error, required, tooltip, disabled, contexthelp, FieldIsSpellCheck, view, ...otherProps } = props
    const { label, helperText, name, xtype, fullWidth, items, ...otherConfigs } = otherProps;
    const { extraFields } = extraProps || {}
    const [progress, setProgress] = React.useState(null);
    const [eventState, setEvent] = React.useState(null);
    let value = { 'currentDay': props && props.value || 0, 'totalDays': (extraFields && extraFields.totalDays && extraFields.totalDays.value), 'event': (extraFields && extraFields.event && extraFields.event.value) }


    React.useEffect(() => {
        if (value && value.currentDay)
            setProgress((100 * parseInt(value.currentDay)) / value.totalDays)
        else setProgress(0)
    }, [value && value.currentDay]);

    React.useEffect(() => {
        setEvent(eventState || value.event)
    }, []);

    const handleChange = (desc, val) => {
        if (desc === 'currentDay') {
            setProgress(val)
            handleFieldValue({ target: { value: val, name: extraFields.totalDays.fieldKey, checked: false }, currentTarget: { dataset: null } })
            handleFieldValue({ target: { value: val, name: name, checked: false }, currentTarget: { dataset: null } })
        }
        else if (desc === 'event') {
            setEvent(val)
            handleFieldValue({ target: { value: val, name: extraFields.event.fieldKey, checked: false }, currentTarget: { dataset: null } })
        }
        else handleFieldValue({ target: { value: val, name: name, checked: false }, currentTarget: { dataset: null } })
    }
    var currentDate = moment(new Date()).format('MM/DD/YYYY');
    var timerDate = props.value && props.value > 0 ? moment(currentDate).add(props.value, 'd') : '';
    if (props.value !== '' && props.value !== undefined && props.value)
        return <FormControl fullWidth={fullWidth ? fullWidth : true} error={error} required={required}>
            <InputLabel style={{ fontWeight: 'bold', color: error ? 'red' : 'black' }} shrink> {label}&nbsp; {tooltip && <Tooltip title={tooltip ? tooltip : null}
                placement="bottom-start" ><HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#007bff', marginLeft: 5 }} onClick={(evt) => contexthelp ?
                    DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
            </Tooltip>} </InputLabel>
            <CircularProgressWithLabel value={progress} label={label} view={view} name={name}{...value} disabled={disabled || false} items={items}
                eventState={eventState} handleFieldValue={handleChange} />
            {timerDate && <Typography className="text-secondary">
                {timerDate.format('MM/DD/YYYY')}
            </Typography>}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl >
    else return <div className='d-none'></div>
}
