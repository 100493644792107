
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

const styles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

class BackDrop extends Component {

    state = {
        open: false
    }

    backdropOpen = () => {
        this.setState({ open: true })
    }


    backdropClose = () => {
        this.setState({ open: false })
    }

    render() {
        const { open } = this.state;
        const { classes } = this.props
        return (
            <Fragment>
                <Backdrop className={classes.backdrop} open={open} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Fragment>
        )
    }

}

export default withStyles(styles)(BackDrop);