import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { loadModules } from 'esri-loader';
class CMeasurement extends Component {
    constructor(props) {
        super(props); 
        this.state =  { 
            view: this.props.view
        } 
        this.startup(
            this.props
        ); 
    }  
   
     
    startup = (config) => {

        
        
        loadModules([
            "esri/core/watchUtils",
            "esri/widgets/Expand",
            "esri/widgets/DistanceMeasurement2D",
            "esri/widgets/AreaMeasurement2D"
        ]).then(([watchUtils, Expand, DistanceMeasurement, AreaMeasurement]) => {
             
            const measurement = new DistanceMeasurement({
                view: config.view,
                unit: "feet"
            });   
            if (config.Tools && config.Tools.indexOf("polygon")>-1){
                measurement = new AreaMeasurement({
                    view: config.view,
                    unit: "feet"
                });
            }
            this.measurementcontainer = ReactDOM.findDOMNode(this);
            this.measurement = measurement;
            
            const measurementExpand = new Expand({
                collapseTooltip: "Collapse Measurement Tool",
                expandTooltip: "Expand Measurement Tool",
                view: config.view,
                content: measurement,
                container: this.measurementcontainer
            });  
            measurement.on("measure-start",function(evt){         
                console.log(evt,'measurement started')
            })

            measurement.on("measure-end",function(evt){         
                console.log(evt)
            })
            config.view.ui.add(measurementExpand, "top-right");
            this.props.widgetRenderCallBack("Measurement");
            
            const inThis = this;
            watchUtils.pausable(measurementExpand, "expanded", function (newValue, oldValue) { 
                inThis.props.onexpanded(measurementExpand, newValue);
                if(newValue){
                    inThis.addClearTool(inThis)                              
                }                                       
            });
        });
    }
     
    addClearTool(inthis){
        //let theDiv = document.getElementsByClassName("esri-direct-line-measurement-3d__actions");
        let isElementExist = document.getElementsByClassName("measurement-clear")
        if(isElementExist && isElementExist.length > 0){
            return;
        }else{
            let parentdiv = document.createElement('div')
            parentdiv.setAttribute("class","esri-direct-line-measurement-3d__actions");           
            parentdiv.style.paddingTop = '3px';

            let element = document.createElement('button');
            element.setAttribute("id","measurement-clear")
            element.setAttribute("class","measurement-clear esri-button esri-button--secondary")            
            element.innerHTML="Clear"
            element.onclick = () => inthis.clearMeasurement(inthis);
            parentdiv.appendChild(element);

            let currDocElem = inthis.measurementcontainer;
            if(currDocElem){
                var container = document.getElementsByClassName('esri-direct-line-measurement-3d__container');
                if(container && Array.isArray(container) && container.length > 0)
                    container[0].appendChild(parentdiv)
            }
        }            
    }

    clearMeasurement(inthis){
        if(inthis.measurement && inthis.measurement.viewModel)
            inthis.measurement.viewModel.clearMeasurement()
    }

  render() {
    return (
        <div className="Measurement-box" style={{order:this.props.order}}>
            
        </div>
    );
  }
}

export default CMeasurement;