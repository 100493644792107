import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },

}));

export default function DialogSelect(props) {
  const [anchorEl, setAnchorEl] = React.useState(props.open);
  const open = Boolean(anchorEl);
  let arr = (props.sidebaritemsinfo && props.sidebaritemsinfo.options.items) || []
  let checkboxitems = arr.map(x => x.key);
  let obj = {}
  for (const element of checkboxitems) {
    obj[element] = "N"
  }
  const [cloneitems, setCloneItems] = React.useState(obj);

  const handleChange = (event) => {
    setCloneItems({ ...cloneitems, [event.target.name]: event.target.checked == true ? "Y" : "N" });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOk = () => {
    setAnchorEl(null);
    props.onClick(cloneitems)
  }

  const ITEM_HEIGHT = 48;

  if (props.onClick !== "hidedisplay") {
    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          color={"inherit"}
        >
          <DynamicFeedIcon fontSize="small" />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          disableBackdropClick
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
          {arr.map(each => {
            return (
              <FormControlLabel className="pt-2"
                control={<Checkbox checked={cloneitems[each.key] == "N" ? false : true} onChange={handleChange} name={each.key} />}
                label={<span style={{ fontSize: '14px' }}>{each.title}</span>}
              />
            )
          })}
        </Menu>
      </div>
    );
  }
  else {
    return null
  }
}
