
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import TemplateForm from '../../core/modules/template/template';
import { createRequest, requestApi as requestApiNew } from '../../core/controls/helpers/rest';

export default function TermsAndCond({ handleAgreement, handleClose, agreement, url, SITE_PERMIT_TYPE_ID, openTerms }) {

    const [editorState, seteditor] = useState();
    const [tAndC, settAndC] = useState();

    useEffect(() => {
        let arrayUrl = [
            { url: url.get.url + '/' + SITE_PERMIT_TYPE_ID, type: 'get' },
        ]
        Promise.all(arrayUrl.map(each => requestApiNew(createRequest(each)))).then(res => {
            const [tAndC] = res
            if (tAndC && tAndC.Id) {
                seteditor(parseInt(atob(tAndC.TermsAndConditionsStr))); settAndC(tAndC)
            }
        }).catch(ex => {

        })
    }, [])


    return <Drawer anchor="right" PaperProps={{ style: { width: window.innerWidth - 520 } }} classes={{ paper: window.innerWidth - 200 || '' }} onClose={handleClose} open={openTerms}>
        <AppBar position="static" color='secondary'>
            <Toolbar variant="dense">
                <Typography variant="h6" color="inherit" >
                    Terms and Conditions
                </Typography>
            </Toolbar>
        </AppBar>
        {editorState && <TemplateForm templateId={editorState} isEnabled={false} mentions={null} entryData={tAndC} showcomments={false}
            hasSubject={false} isadmin={true} handleTemplateId={() => { }} />}
        <FormControlLabel
            control={<Checkbox
                checked={agreement || false}
                onChange={handleAgreement}
                color='primary'
            />}
            label='I have read and agree to the Terms and Conditions'
            labelPlacement='end'
            className="col"
        />
    </Drawer>
}