import { Droppable } from "react-beautiful-dnd";
import CSectionDraggable from './cSectionDraggable';
import * as DNDSettings from './util/dndSettings';


const CSectionsContainer = ({ mainSections, OnAddSubSection, sections, errors, direction, clazzName, wrapperWlazzName, getSubSections, droptype, isSectionsOpen, OnAddRow, OnMoveDown, OnMoveUp, CanShowMoveUp, CanShowMoveDown, OnSectionSettings, OnSectionRemove, OnRowSettings }) =>
  <Droppable droppableId={`Sec-${droptype}`} type="section" direction={direction}>
    {(sp, ss) => (
      <div ref={sp.innerRef} className={clazzName}
        style={DNDSettings.getListStyle(ss.isDraggingOver)}>
        <div className={wrapperWlazzName} >
          {mainSections && mainSections.map((section, index) => {
            if ((section.dndId === "allfields"))
              return <CSectionDraggable sectionConfig={{ OnAddSubSection, sections, getSubSections, OnAddRow, OnSectionSettings, OnSectionRemove, titleClazz: "", clazzName: "sectionList", wrapperWlazzName, droptype, direction, OnMoveDown, OnMoveUp, CanShowMoveUp, CanShowMoveDown, errors, isSectionsOpen }} section={section} draggable={index} OnSectionSettings={OnSectionSettings} OnSectionRemove={OnSectionRemove} OnRowSettings={OnRowSettings} OnAddSubSection={OnAddSubSection} OnAddRow={OnAddRow} OnMoveDown={OnMoveDown} OnMoveUp={OnMoveUp}
                CanShowMoveUp={CanShowMoveUp} errors={errors} CanShowMoveDown={CanShowMoveDown} isSectionsOpen={isSectionsOpen} availablelist={true} />
          }
          )}
          <div style={{ height: '75vh', overflow: 'auto' }}>
            {mainSections && mainSections.map((section, index) => {
              if ((section.dndId !== "allfields"))
                return <CSectionDraggable sectionConfig={{ OnAddSubSection, sections, getSubSections, OnAddRow, OnSectionSettings, OnSectionRemove, titleClazz: "", clazzName: "sectionList", wrapperWlazzName, droptype, direction, OnMoveDown, OnMoveUp, CanShowMoveUp, CanShowMoveDown, errors, isSectionsOpen }} section={section} draggable={index} OnSectionSettings={OnSectionSettings} OnSectionRemove={OnSectionRemove} OnRowSettings={OnRowSettings} OnAddSubSection={OnAddSubSection} OnAddRow={OnAddRow} OnMoveDown={OnMoveDown} OnMoveUp={OnMoveUp}
                  CanShowMoveUp={CanShowMoveUp} errors={errors} CanShowMoveDown={CanShowMoveDown} isSectionsOpen={isSectionsOpen} />
            }
            )}
          </div>
          {sp.placeholder}
        </div>
      </div>
    )}
  </Droppable>

export default CSectionsContainer;