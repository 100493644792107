import React, { Fragment, useCallback, useEffect, useState } from 'react';
import FormHeader from '../../core/modules/form/cformheader';
import { getEnvVariable } from '../..//modules/environmentalList';
import { createRequest, requestApi } from '../../core/controls/helpers/rest';
import QueryBuilder from '../../core/modules/dnd/widgets/validationrules/mainquerybuilder'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Paper from '@material-ui/core/Paper'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { formatQuery as Formatter } from 'react-querybuilder';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
const _ = require('lodash');


export default function BillingBR(props) {
  const [fields, setFields] = useState([])
  let newItem = {
    "RuleType": "S",
    "Status": "A",
    "EventOn": "AS",//OL,OC,
    "Name": props.options.businessrulename,
    "CanOverwriteGlobalRule": "Y",
    "IsTriggerAnotherRuleOnFailure": "N",
    "NoticesEventOn": "N",
    "NoticesOnLoad": "N",
    "NotificationSettingsEventOn": "N",
    "NotificationSettingsOnLoad": "N",
    "ProjectMessagesEventOn": "N",
    "ProjectMessagesOnLoad": "N",
    "OnDemand":"Y"
  }
  const [currentresult, setCurrentResult] = useState({ ...newItem })
  const [initialresult, setInitialResult] = useState({ ...newItem })
  const [queryError, setqueryError] = useState({ error: false, helperText: '' })
  const [amount, setAmount] = useState(null)
  const [data, setData] = useState([])
  const [add, setAdd] = useState(false)
  const [masterfieldid, setmasterfieldid] = useState(null)
  const [sectionId, setSectionId] = useState(null)
  //required values
  //sectionid -- doubt
  //routeid -- props.dataItem.Id
  //masterfieldid -- we need to do request
  //masterfieldkeyname -- props.options.masterfieldkey

  useEffect(() => {
    let geturl = {
      url: getEnvVariable('AdminConfig') + `/api/SitePermitTypeSection/${props.dataItem.Id}/Selected`,
      type: 'get',
    }
    requestApi(createRequest(geturl, '')).then(results => {
      if(results && results.length>0) {
        let requiredsection = _.find(results, { 'Key': "SIGN_INFORMATION"});
        if(requiredsection) {
          setSectionId(requiredsection.Id)
        }
     }
    })

  }, [])

  useEffect(() => {

    let geturl = {
      url: getEnvVariable('AdminConfig') + `/api/MasterFields/AllLookup`,
      type: 'get',
    }
    requestApi(createRequest(geturl, '')).then(results => {
      if(results && results.length>0) {
        let masterfielddata = _.find(results, { 'key': props.options.masterfieldkey });
        setmasterfieldid(masterfielddata.id)
     }})

  }, [])

  useEffect(() => {
    if(sectionId) {
    let haskey = ''
    if (!currentresult.Id && currentresult.HashKey)
      haskey = `&HashKey=${currentresult.HashKey}`
    const read = [
      {
        url: getEnvVariable('Application') + `/api/BusinessRules/Builder?SitePermitTypeId=${props.dataItem.Id}&sitePermitTypeSectionId=${sectionId}&businessRuleId=${currentresult.Id || 0}${haskey}`,
        type: 'get'
      },
    ]
    Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(response => {
      const [fields] = response;
      setFields(fields)
    })
  }
  }, [sectionId])

  //get request and set data
  useEffect(() => {
    if(sectionId) {
    let geturl = {
      url: getEnvVariable('AdminConfig') + `/api/BusinessRules/${props.dataItem.Id}/All?sitePermitTypeSectionId=${sectionId}`,
      type: 'get',
    }
    requestApi(createRequest(geturl, '')).then(results => {
      if(results && results.length>0) {
        let res = [...results]
        res.forEach(element => {
          element.query = JSON.parse(element.JsonQueryStr);
        });
        res = res.filter(element => element.Name == props.options.businessrulename);
        setData(res);
      }
      else {
        setData(results);
     }

    })
  }

  }, [sectionId])

  const handleFieldValue = (event, IdVal) => {
    const { name, value } = event.target;
    if (IdVal) {
      let pushdata = []
      let tempdata = [...data]
      let res = _.find(data, { 'Id': IdVal })
      let querystr = JSON.parse(res.JsonQueryStr) //JSON.stringify(res.query)
      res[name] = querystr
      res.JsonQueryStr = JSON.stringify(querystr)
      res.SqlQuery = Formatter(querystr, 'sql')
      pushdata.push(res)
      tempdata.map(obj => pushdata.find(o => o.Id === obj.Id) || obj);
      setData(tempdata);
    }
    else {
      currentresult[name] = value
      currentresult.JsonQueryStr = JSON.stringify(currentresult.query)
      currentresult.SqlQuery = Formatter(currentresult.query, 'sql')
      setCurrentResult(currentresult)
    }

  }

  const handleBack = () => {
    props.onClose();
  }

  const handleChange = (event, IdVal) => {
    if (IdVal) {
      let pushdata = []
      let tempdata = [...data]
      let res = _.find(data, { 'Id': IdVal })
      res['BusinessRuleFields'][0].Value = event.target.value
      pushdata.push(res)
      tempdata.map(obj => pushdata.find(o => o.Id === obj.Id) || obj);
      setData(tempdata);
    }
    else {
      let businessrulefields = [{
        "AllowedValues": null,
        "ValueType": "D",
        "Value": event.target.value,
        "IsEnabled": "N",
        "IsVisible": "N",
        "RuleType": "V",
        "Status": "A",
        "IsRequired": "N",
        "MasterFieldId": masterfieldid,
        "MasterFieldName": null
      }]
      currentresult['BusinessRuleFields'] = businessrulefields
      setCurrentResult(currentresult)
      setAmount(event.target.value)
    }

  }

  const handleSave = (type, IdVal) => {
    if (type === 'add') {
      let saveurl = {
        url: getEnvVariable('AdminConfig') + `/api/BusinessRules/${props.dataItem.Id}/Add?sitePermitTypeSectionId=${sectionId}`,
        type: 'post',
      }
      requestApi(createRequest(saveurl, currentresult)).then(results => {
        setAdd(false);
        currentresult['Id'] = results.Id
        currentresult['BusinessRuleFields'] = results.BusinessRuleFields
        let adddata = [...data]
        adddata.push(currentresult);
        setData(adddata);
        setCurrentResult({ ...initialresult });
      })
    }
    else {
      let saveurl = {
        url: getEnvVariable('AdminConfig') + `/api/BusinessRules/${IdVal}/Update`,
        type: 'put',
      }
      let res = _.find(data, { 'Id': IdVal })
      requestApi(createRequest(saveurl, res)).then(results => {
        setAdd(false);
        setCurrentResult({ ...initialresult })
      })
    }
  }

  const handleAdd = () => {
    setAdd(!add);
    setCurrentResult({ ...initialresult })
    setAmount(null)
  }

  return (
    <Fragment>
      <FormHeader title="Billing Fee" handleBack={handleBack} addbutton={sectionId && !(_.isEmpty(fields))? true : false} backbutton={true} handleAdd={handleAdd} />
      {!sectionId && <Typography variant="body1" gutterBottom>
        Billing related sections are not available for this permit type.
      </Typography>}
      {_.isEmpty(fields) && sectionId && <Typography variant="body1" gutterBottom>
        No fields are available to calculate billing amount.
      </Typography>
      }
      {!(_.isEmpty(fields)) && sectionId && data && data.length>0 && data.map(each => {
        return <div className="mt-1 mb-1">
          <Paper elevation={3} >
            <FormControl error={queryError.error} required={true}>
              <QueryBuilder fields={fields} query={each && each.query || null} onQueryChange={(query) => handleFieldValue({ target: { value: query, name: 'query', checked: false }, currentTarget: { dataset: null } }, each.Id)} />
              {queryError.helperText && <FormHelperText>{queryError.helperText || ''}</FormHelperText>}
            </FormControl>
            <FormControl className="pl-3 pb-1">
              <InputLabel className="pl-3">Amount</InputLabel>
              <Input
                value={each['BusinessRuleFields'] && each['BusinessRuleFields'].length> 0 ? each['BusinessRuleFields'][0].Value : null}
                onChange={(e) => handleChange(e, each.Id)}
                type="number"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
              />
            </FormControl>
            <IconButton tooltip="Save" className="pt-3" color="primary" aria-label="upload picture" component="span" onClick={() => handleSave('update', each.Id)}>
              <SaveIcon />
            </IconButton>
          </Paper>
        </div>
      })
      }
      {!(_.isEmpty(fields)) && sectionId && add && <div className="mt-1 mb-1">
        <Paper elevation={3} >
          <FormControl error={queryError.error} required={true}>
            <QueryBuilder fields={fields} query={currentresult && currentresult.query || null} onQueryChange={(query) => handleFieldValue({ target: { value: query, name: 'query', checked: false }, currentTarget: { dataset: null } })} />
            {queryError.helperText && <FormHelperText>{queryError.helperText || ''}</FormHelperText>}
          </FormControl>
          <FormControl className="pl-3 pb-1">
            <InputLabel className="pl-3">Amount</InputLabel>
            <Input
              value={amount}
              onChange={(e) => handleChange(e)}
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
          </FormControl>
          <IconButton tooltip="Save" className="pt-3" color="primary" aria-label="upload picture" component="span" onClick={() => handleSave('add')}>
            <SaveIcon />
          </IconButton>
        </Paper>
      </div>}
    </Fragment>
  );

}
