import React, { useRef, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from "react-router-dom";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import { Download } from '../../modules/form/printfunctions';
import MissingItemsDrawer from './custom/missinglists';
import { Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DefaultSettings from './settings';

export default function Buttons(props) {
    const { value, handleFieldValue, handleButtonClick, handleButtonSave, extraProps, changefieldto, shareProps, useFieldVals, fieldValues, fieldProps, urlparams, type, ...otherProps } = props
    const { variant, color } = extraProps
    const { label, callRequest, disabled, buttontype, clickval, getIdUrl, buttonfunction, isIcon, size, navurl, optionalRoutes, name, icon, isButton, tooltip, contexthelp,
        stylecolor, hidecondition, ...otherOptions } = otherProps
    let history = useHistory();
    const params = useParams();
    let disable = disabled
    const [disabledvalue, setDisabledvalue] = useState(disable)

    useEffect(() => {
        setDisabledvalue(disable)
    }, [disable])

    useEffect(() => {
        if (hidecondition) {
            // getdatafrom[Name] != Value : getdatafrom[Name] == Value
            hidecondition.forEach(element => {
                const { prevcondition, Name, Value, getfrom, notEquals } = element
                let getdatafrom = getfrom === "accessPermissions" ? props.accessPermissions : getfrom === "routeparams" ? props.routeparams : getfrom === "dataItem" ? props.dataItem : getfrom === "localstorage" ? 'localstorage' : null
                if (getdatafrom) {
                    disable = prevcondition && prevcondition === 'or' && disable == true ? true : prevcondition === 'and' && disable === false ? false :
                        (notEquals ? getfrom === "localstorage" ? localStorage.getItem(Name) !== Value :
                            getdatafrom[Name] !== Value : getfrom === "localstorage" ? localStorage.getItem(Name) == Value : getdatafrom[Name] == Value) ? true : false
                }
                setDisabledvalue(disable)
            })
        }
    }, [])

    const routing = (navurl) => {
        let newpath = ''
        if (useFieldVals)
            for (let key in fieldValues) {
                if (navurl) {
                    if (optionalRoutes && Object.keys(fieldValues).includes(optionalRoutes) && fieldValues[optionalRoutes])
                        navurl = `${navurl.replace(`{${key}}`, fieldValues[optionalRoutes])}`;
                    else navurl = `${navurl.replace(`{${key}}`, fieldValues[key])}`;
                    newpath = navurl;
                }
            }
        else
            for (let key in params) {
                if (navurl) navurl = `${navurl.replace(`{${key}}`, params[key])}`;
                newpath = navurl;
            }
        return newpath
    }

    const handleClick = (e) => {
        if (buttontype === 'routing') {
            let routepath = routing(navurl)
            history.push(routepath)
        }
        else if (buttontype === 'save') {
            return handleButtonSave(e)
        }
        else if (buttontype === 'customfunction') {
            Download(params, buttonfunction, getIdUrl)
        }
        else if (buttontype === 'setValue' || changefieldto === 'LB-BU') {// in record screen to execute business rules as per section
            handleFieldValue({ target: { value: changefieldto === 'LB-BU' ? null : clickval, name: name, checked: false, }, currentTarget: { dataset: null } })
        }
        else return handleButtonClick({ currentTarget: { name: name } }, callRequest)
    }

    if (isIcon) {
        return <Fab onClick={handleClick} size={size ? size : 'large'} color={disabledvalue ? 'default' : (color || stylecolor)}
            name={name} title={label} disabled={disabledvalue ? true : false}  {...otherOptions}>
            < Icon > {icon}</Icon >
        </Fab >
    }
    else if (icon === 'required' || icon === 'dcrequired') {
        return <MissingItemsDrawer fieldValues={fieldValues} urlparams={urlparams} label={label} isButton={isButton} fieldProps={fieldProps} name={name} />
    }
    return (
        <div>
            <Button onClick={handleClick}
                color={(disabledvalue || disabled) ? '' : color || 'primary'}
                variant={variant || 'outlined'}
                name={name}
                disabled={(disabledvalue || disabled) ? true : false}
            >
                {label}
            </Button>  {tooltip && <Tooltip title={tooltip ? tooltip : null} placement="bottom-start" >
                <HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#007bff' }} onClick={(evt) => contexthelp ? DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
            </Tooltip>}
        </div >
    );
}