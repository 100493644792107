import { getEnvVariable } from "../../../../modules/environmentalList"

const ProjectMessagesAdd = {
    "type": "popup",
    "key": "ProjectMessagesAdd",
    "name": "ProjectMessagesAdd",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'addOpen'
    },
    sharedValues: ['open'],
    container: {
        values: { save: false, back: false  },
        mapValues: {
            AddProjectMessages: [{ key: 'save', name: 'save' },]
        },
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },


            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'AddProjectMessages',
                    type: 'div',
                    className: 'col-12 pl-4',
                    sub: {
                        type: 'div',
                        className: 'col-12 pl-4',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Project Messages',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "Add Project Messages", "materialicon": "messages", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'save',
                        title: 'Save Project Messages',
                        hidevalue: false.valueOf
                        
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hideon: 'exitfullscreen',
                        hidevalue: false
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false
                    }
                ],


            },
            AddProjectMessages: {
                "type": "form",
                "key": "AddProjectMessages",
                "name": "AddProjectMessages",
                aliases: {
                    save: 'save',
                },
                sharedValues: ['save'],
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Name',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            }],

                        },
                    ],
                },
                values: {
                    Name: '',
                },

                "components": {
                    Name: {
                        "type": "textfield",
                        "key": "Name",
                        "label": "Table Name",
                        "name": "Name",
                    },
                },
                mapActionsToEffects: {
                    init: {
                        rules: [{
                            run:
                            {
                                valid: [{
                                    type: 'load',
                                    name: 'Type',
                                    "read": [
                                        {
                                            url: getEnvVariable('Admin')+`/api/Domains/values?domainkey=PROJECT_MESSAGE_TYPE`,
                                            type: 'get',
                                            saveAs: {
                                                key: 'items'
                                            }
                                        }
                                    ]
                                },

                                {
                                    type: 'validations', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                    "read": [
                                        {
                                            "url": getEnvVariable('MasterAdmin')+`/api/ProjectMessages/RulesByName?name=master.appsettings`,
                                            type: 'get'
                                        }
                                    ],
                                    transformProps: {
                                        removesubfieldname: 'MASTER.APPSETTINGS_'
                                    }
                                },
                                ]
                            }
                        }]
                    },
                    'save': {
                        rules: [
                            {
                                run: {
                                    valid: [
                                        {
                                            type: 'save',
                                            "read": [
                                                {
                                                    "url": getEnvVariable('Admin')+`/api/ProjectMessages/Add`,
                                                    type: 'post',

                                                }
                                            ],
                                        },

                                    ]
                                }
                            }
                        ]
                    },
                }
            }
        },

    }
}

export default ProjectMessagesAdd