import {DownloadWithSideBarData} from '../../controls/components/custom/downloadshelper'
import { requestApi } from './utilities';
export const Download = (params, type, getIdUrl) => {
if(type == 'DownloadWithSideBarData') {
    let requesturl = {get : {"url" : getIdUrl}}
     requestApi(requesturl, null, null, null, true).then(results => {
         let downloadObject = {DownloadId: results.TemplateId, Group: "AAH Volunteer"}
        DownloadWithSideBarData(downloadObject, params.routeid, params.routeid1, {"AppWorkNotification" : params.worknotifId  });
    })

}
}