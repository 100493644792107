/**        
      *********************************************************
      Currenlty not used anywhere
      *********************************************************
**/

import React, { Fragment } from 'react';
import { MultiSelect  } from '@progress/kendo-react-dropdowns';

const CMultiSelect = (props) => {
    const { handleFieldChange, dropdownvalues, name, label, disabled, error, required, htmlForLabel, value, helperText, id, transformProps, crud, placeholder, ...otherProps } = props
    return (
        <Fragment>
            <MultiSelect  data={dropdownvalues} placeholder={placeholder} onChange={handleFieldChange} />
        </Fragment>

    );

}

export default CMultiSelect