import { getEnvVariable } from '../../../../modules/environmentalList';
import ProjectMessagesTableAdd from '../projectmessages/projectmessagestableadd';
import ProjectMessagesTableEdit from '../projectmessages/projectmessagestableview'
const ProjectMessagesTable = {
    "type": "grid",
    "key": "ProjectMessagesTable",
    "gridkey": 'ProjectMessagesTable',
    "name": "ProjectMessagesTable",
    // "addRoute": true,
    "routeParams": { replace: 'settings', param: 'Value' },
    "subgrid": true,
    customsubgrid : true,
    values: {
        addOpen: false,
        editOpen: false,
        editInfo: null,
        load: false,
        allgriddata: null,
        navigateItem: null,
        editProjectInfo: null,
        navigation: null,
        subnavigation: null
    },
    sharedValues: ['addOpen', 'editOpen', 'editInfo', 'editProjectInfo', 'navigation', 'subnavigation', 'allgriddata'],
    aliases: {
        addOpen: 'addOpen',
        editOpen: 'editOpen',
        editInfo: 'editInfo',
        editProjectInfo: 'editProjectInfo',
        allgriddata: 'allgriddata',
        navigateItem: 'navigateItem',
        navigation: 'navigation',
        subnavigation: 'subnavigation'
    },
    headerProps: {
        title: "Project Messages",

        actions: [
            {
                type: 'fab',
                icon: 'clear',
                name: 'clear',
                color: 'primary',
                title: 'Clear Filters',
                menucolor: '#22a9b3',
                hideon: 'clear',
                hidevalue: false
            },

            {
                type: 'menu',
                icon: 'excel',
                name: 'excel',
                color: 'primary',
                title: 'Export to Excel',
                menucolor: '#c90685',
                hideon: 'excel',
                hidevalue: false,
                items: [{
                    name: 'Export Current Page',
                    action: 'filtercurrent'
                },
                {
                    name: 'Export All Pages',
                    action: 'filterall'
                }
                ]
            },
            {
                type: 'fab',
                icon: 'add',
                name: 'add',
                color: 'primary',
                hideon: 'addButton',
                hidevalue: true,
                title: 'Add Project Messages',
                menucolor: '#36088a'
            },
        ]
    },
    "columns": [
        {
            "title": "Message",
            "field": "Message",
            "show": true,
            "width": 300
        },
        {
            "title": "Type",
            "field": "Type",
            "show": true,
            "width": 200
        },
        // {
        //     "title": "Min",
        //     "field": "Minlen",
        //     "show": true,
        //     "width": 150
        // },
        // {
        //     "title": "Max",
        //     "field": "Maxlen",
        //     "show": true,
        //     "width": 150
        // },
        // {
        //     "title": "Mask/Regex",
        //     "field": "Mask",
        //     "show": true,
        //     "width": 150
        // },
        // {
        //     "title": "Is Required",
        //     "field": "IsRequired",
        //     "show": true,
        //     "width": 150
        // },
        {
            "title": "Created By",
            "field": "CreatedByName",
            "show": true,
            "width": 185
        },
        {
            "title": "Created On",
            "field": "CreatedOn",
            "show": true,
            "width": 185,
            filter: "date",
            format: "{0:d}",
            convert: "MM/DD/YYYY hh:mm:ss A"
        },
        {
            "title": "Updated By",
            "field": "UpdatedByName",
            "show": true,
            "width": 185
        },
        {
            "title": "Updated On",
            "field": "UpdatedOn",
            "show": true,
            "width": 185,
            filter: "date",
            format: "{0:d}",
            convert: "MM/DD/YYYY hh:mm:ss A"
        },

    ],
    displayName: 'editProjectInfo',
    allgriddata: 'allgriddata',
    navigateItem: 'navigateItem',
    navigation: 'navigation',
    subnavigation: 'subnavigation',
    mapActionsToEffects: {
        load: {
            rules: [{
                run: {
                    valid: [{
                        type: 'grid',
                        "read": [
                            {
                                url: getEnvVariable('Admin') + `/api/ProjectMessages/{name}/Grid?`,
                                key: 'save',
                                "matchProps": [
                                    {
                                        name: "navigation.Value",
                                        key: "name",
                                    }
                                ],
                                type: 'get',
                                saveAs: {
                                    key: 'data'
                                },
                                alternateUrl: getEnvVariable('MasterAdmin') + `/api/ProjectMessages/{name}/Grid?`,
                                condition: [
                                    { value: 'Master', name: 'navigation.Path' }
                                ],
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        addOpen: {
            rules: [{
                check: [
                    {
                        condition: 'AND',
                        rules: [
                            {
                                "name": "addOpen",
                                "type": "!=",
                                "value": true,
                                "rules": []
                            }
                        ]
                    }
                ],
                run: {
                    valid: [{
                        type: 'grid',
                        "read": [
                            {
                                url: getEnvVariable('Admin') + `/api/ProjectMessages/{name}/Grid?`,
                                key: 'save',
                                "matchProps": [
                                    {
                                        name: "editInfo.Value",
                                        key: "name",
                                    }
                                ],
                                type: 'get',
                                saveAs: {
                                    key: 'data'
                                },
                                alternateUrl: getEnvVariable('MasterAdmin') + `/api/ProjectMessages/{name}/Grid?`,
                                condition: [
                                    { value: 'Master', name: 'Path' }
                                ],
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        editOpen: {
            rules: [{
                check: [
                    {
                        condition: 'AND',
                        rules: [
                            {
                                "name": "editOpen",
                                "type": "!=",
                                "value": true,
                                "rules": []
                            }
                        ]
                    }
                ],
                run: {
                    valid: [{
                        type: 'grid',
                        "read": [
                            {
                                url: getEnvVariable('Admin') + `/api/ProjectMessages/{name}/Grid?`,
                                alternateUrl: getEnvVariable('MasterAdmin') + `/api/ProjectMessages/{name}/Grid?`,
                                condition: [
                                    { value: 'Master', name: 'Path' }
                                ],
                                key: 'save',
                                "matchProps": [
                                    {
                                        name: "editInfo.Value",
                                        key: "name",
                                    }
                                ],
                                type: 'get',
                                saveAs: {
                                    key: 'data'
                                }
                            }
                        ]
                    },
                    ]
                }
            }]
        },
        'add': {
            rules: [
                {
                    run: {
                        valid: [
                            {
                                type: 'value',
                                key: 'addOpen',
                                result: true
                            },

                        ]
                    }
                }
            ]
        },
        'display': {
            rules: [
                {
                    uncheck: true,
                    run: {
                        valid: [
                            {
                                type: 'value',
                                key: 'editOpen',
                                result: true
                            },
                            {
                                type: 'resultvalue',
                                name: 'editProjectInfo',
                            },
                            {
                                type: 'resultvalue',
                                name: 'subnavigation',
                            },
                            {
                                type: 'resultvalue',
                                name: 'allgriddata',
                            },

                        ]
                    }
                }
            ]
        },

    }
}


export default ProjectMessagesTable
