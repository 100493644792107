import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1) * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

class NsPopUp extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { options, type, actions, compmap, components, parentProps, edit } = this.props;
        const { id, name, dialogtitle, buttonprops, dialogprops, ...otherOptions } = options ? options : {}
        const { text, icon, color, disabled, size, variant, ...otherButtonProps } = buttonprops ? buttonprops : {}
        let iconname, iconColor, iconFontSize, iconposition, otherIconProps
        if (icon) {
            iconname = icon.iconname
            iconColor = icon.iconColor
            iconFontSize = icon.iconFontSize
        }
        let closefunc
        if (type !== 'confirmationpopupbutton') closefunc = { onClose: this.handleClose }
        let disable = disabled
        if (edit === false) {
            disable = true
        }
        return (
            <div>
                <Button onClick={this.handleClickOpen}
                    color={color || 'default'}
                    disabled={disable}
                    size={size}
                    variant={variant}
                    {...otherButtonProps}
                >
                    {text}
                    {(iconposition === 'left') && <Icon
                        color={iconColor || 'inherit'} fontSize={iconFontSize || 'default'} {...otherIconProps}>{iconname}
                    </Icon>
                    }
                    {iconposition && !(iconposition === 'left') && <Icon
                        color={iconColor || 'inherit'} fontSize={iconFontSize || 'default'} {...otherIconProps}>{iconname}
                    </Icon>
                    }

                </Button>
                <Dialog
                    aria-labelledby={id || name}
                    open={this.state.open}
                    fullWidth
                    {...dialogprops}
                    {...closefunc}
                >
                    {dialogtitle && <DialogTitle  id={id} onClose={this.handleClose}>
                        {dialogtitle}
                    </DialogTitle>}
                    <DialogContent {...otherOptions}>
                        {components && components.map(each => {
                            const EachComp = compmap[each.type]
                            return <EachComp {...each} key={each.key} {...this.props} compmap={compmap} onClose={this.handleClose} />
                        })}
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default NsPopUp;
