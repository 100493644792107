  /**        
        *********************************************************
        Description: Header component.
        Required props:   N/A 
        limitations    : N/A
        *********************************************************
        **/
        import React, { Component, Fragment } from 'react';
        import MuiEdgeLayout from './layouts/muiedge';
        import { AppBar } from '@material-ui/core';
        import WRSHeader from '../header/images/WRSBanner.png'
        import WRsLogo from '../header/images/wrslogo.png'
        import { getEnvVariable } from '../../../modules/environmentalList';
        
          
        class HeaderView extends Component {
        
          render() {
            const { options, components, defaults, order, type, compmap, actionmessage, accessPermissions } = this.props;
            const { layout } = options
            if (actionmessage) {
              const { action, items } = actionmessage
            }
        
            return (
              getEnvVariable('ModuleKey') === 'WRS' && !accessPermissions ? 
             <Fragment>
             <div>
               <img src={WRSHeader} alt='Logo' style={{ height: 220 ,width:'100%'}}/>
               </div>
               <MuiEdgeLayout {...this.props} />
               <div>
               <img src={WRsLogo} className="pt-5" style={{ padding:'20px', height:'40%',width:'20%',margin:'0 auto',display:'block'}}/>
               </div>
           </Fragment> :
           <Fragment>
              <MuiEdgeLayout {...this.props} />
           </Fragment> 
            )
          }
        
        }
        
        export default HeaderView;
        
        
        /**
         * type:'header',
         * key:'header',
         * options:{
         * },
         * defaults:{
         * layout:'border'
         * },
         * components:[{
         *  type:'text',
         * order:1,
         * key:'title',
         * options:{
         *  isresponsive: true
         * },
         *
         * components:[]
         * }],
         *
         */
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        // import React, { Component, Fragment } from 'react';
        // import BootstrapNavLayout from './layouts/bootstrapnav'
        // import BorderLayout from './layouts/border'
        // import EqualLayout from './layouts/equal'
        // import MuiEdgeLayout from './layouts/muiedge'
        
        // class HeaderView extends Component {
        
        //   render() {
        //     const { options, components, defaults, order, key, type, children, actionmessage } = this.props;
        //     if(actionmessage){
        //       const {action, items} = actionmessage
        //     }
        //     const { theme, layout, ...otherDefaults } = defaults
        //     let header
        //     if (theme !== 'custom') {
        
        //       // if (layout === 'bootstrapnav') {
        //       //   header = <BootstrapNavLayout {...newoptions} />
        //       // }
        //       // if (layout === 'border') {
        //       //   header = <BorderLayout {...newoptions} />
        //       // }
        //       // if (layout === 'equal') {
        //       //   header = <EqualLayout {...newoptions} />
        //       // }
        //       if (layout === 'muiedge') {
        //         header = <MuiEdgeLayout {...this.props} />
        //       }
        
        //     }
        //     else{
        //       header = this.props.children
        //     }
        
        //     return (
        //       <Fragment>
        //         {header}
        //       </Fragment>
        
        //     )
        //   }
        
        // }
        
        // export default HeaderView;
        
        
        // /**
        //  * type:'header',
        //  * key:'header',
        //  * options:{
        //  * },
        //  * defaults:{
        //  * layout:'border'
        //  * },
        //  * components:[{
        //  *  type:'text',
        //  * order:1,
        //  * key:'title',
        //  * options:{
        //  *  isresponsive: true
        //  * },
        //  *
        //  * components:[]
        //  * }],
        //  *
        //  */