/**
     *********************************************************
     Description: Creates drawer on the left side. Needs to be modified to make it generic.
     Required props:  Used internally by other components.
     limitations    : N/A
     *********************************************************
**/
import { AppBar, Toolbar } from "@material-ui/core";
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ConfirmationDialog from "../confirmationPopup";
import { snackbarMessage } from '../../../actions';
import { getEnvVariable } from '../../../../modules/environmentalList';
import ProgressBar from '../../../modules/progress-bar';
import Template from '../../../modules/template/template';
import { createRequest, requestApi } from '../../helpers/rest';


const styles = theme => ({
    paperAnchorRight: {
        top: 'auto',
        maxHeight: '100%',
        width: '70%'
    },
    root: {
        width: '70%'
    }
});

class NotificationEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.openNotificationSlide,
            subject: '',
            recordDetails: [],
            eventDetails: [],
            internalNotificationTemplate: {},
            externalNotificationTemplate: {},
            isNotificationSent: false,
            confirmOpen: false,
            hasError: false,
            isSuccess: false
        }
        this.TemplateForm = React.createRef()
    }

    componentDidMount() {
        this.getLatestRecordDataDictionary();
    }


    getLatestRecordDataDictionary() {
        let siteid = this.props.sitepermittypeid || (this.props.sitePermitTypeId) || (this.props.history && this.props.match.params.routeid)
        let recordid = this.props.recordid || (this.props.history && this.props.match.params.routeid1)
        // let includeRecordData = this.props.includeRecordData || false;
        let SideBarDataItems = {}
        let sideBarItems = this.props.sideBarItems;
        sideBarItems && sideBarItems.forEach(element => {
            if (this.props.childTable)
                SideBarDataItems[element] = this.props.childTable.Id
        });
        let body = {
            'sitePermitTypeId': siteid,
            'masterTableId': recordid,
            'SideBarDataItems': this.props.ParentValues ? {} : SideBarDataItems,
            'sideBarItems': sideBarItems || [],
            //   'includeRecordData': includeRecordData,
            'Values': this.props.ParentValues || '',
            'Info': this.props.ParentTableName || ''
        }
        let applicationdataurl = {
            url: getEnvVariable('Application') + `/api/Application/FieldValues`,// /${siteid}/${recordid}`,
            type: 'post',
            alert: true
        }
        requestApi(createRequest(applicationdataurl, body)).then(results => {
            if (results) {
                this.setState({
                    recordDetails: results
                }, () => {
                    if (this.props.isFieldValueChange && this.props.isFieldValueChange === true) {
                        this.getLastUpdatedFieldRecords()
                    } else {
                        this.getPossibleNotifications()
                    }
                })
            }

        })
    }


    getLastUpdatedFieldRecords() {
        let newurl = {
            url: getEnvVariable('Application') + `/api/application/lastUpdatedRecordFields/${this.props.sitepermittypeid || (this.props.sitePermitTypeId)}/${this.props.recordid}`,
            type: 'get',
            // alert: true,
            // loader: true
        }
        //  let newurl = getEnvVariable('Application')+`/api/application/lastUpdatedRecordFields/${this.props.sitepermittypeid}/${this.props.recordid}`;
        //requestApi({ get: { url: newurl, routeProps: {} } }, null)
        requestApi(createRequest(newurl)).then(results => {
            if (results) {
                this.setState({
                    eventDetails: results
                }, () => this.getPossibleNotifications())
            }
        })
    }

    getPossibleNotifications() {
        //    let getPossibleNotificationURL = getEnvVariable('Notification')+`/api/NotificationTrigger/PossibleNotifications`
        let eventDetails = {
            SubModuleKey: (this.props.childTable && this.props.childTable.key) || '',
            SitePermitTypeId: this.props.sitepermittypeid || (this.props.sitePermitTypeId),
            EventId: this.props.recordid,
            EventOnKey: this.props.eventonkey, // || 'FIELD_VALUE_CHANGE',//'FIELD_VALUE_CHANGE',
            EventDetails: this.state.eventDetails,
            DataDictionary: this.state.recordDetails,
            id: this.props.id
        }
        let requrl = {
            url: getEnvVariable('Hangfire') + `/api/NotificationTrigger/PossibleNotifications`,
            type: 'post',
            alert: true,
            loader: true
        }
        // requestApi({ post: { url: getPossibleNotificationURL, routeProps: {} } }, null, eventDetails)

        requestApi(createRequest(requrl, eventDetails)).then(results => {
            if (results && results.length > 0) {
                let internal = []
                internal = results.filter(a => {
                    if (a.UseType === "I" && (((typeof this.props.id === 'string') ? a.NotificationSettingId.toString() : a.NotificationSettingId) === this.props.id))
                        return a
                })
                let external = []
                external = results.filter(a => {
                    if (a.UseType === "E"
                        && (((typeof this.props.id === 'string') ? a.NotificationSettingId.toString() : a.NotificationSettingId) === this.props.id))
                        return a
                })
                this.setState({
                    internalNotificationTemplate: internal[0],
                    externalNotificationTemplate: external[0]
                });
                //this.progressBar.complete()
            } else {
                this.setState({ hasError: true });
            }
        })
    }

    handleClose = () => {
        if (!this.state.isNotificationSent) {
            this.setState({ open: false }, () => {
                if (this.props.handleNotificationClose)
                    this.props.handleNotificationClose(true);
            })
            //   this.setState({ confirmOpen: true })
        } else {
            this.setState({ open: false }, () => {
                if (this.props.handleNotificationClose)
                    this.props.handleNotificationClose(true);
                else
                    this.setState({ isSuccess: true })
            })

        }

    }

    handleSave = () => {
        this.TemplateForm.current.GetTemplateFormats();
    }

    templateEditorSave(returnid) {
        // Not required, not saving template records
    }

    templateFormatsCallBack(inthis, template) {
        console.log(inthis, template);
        inthis.triggerManualNotification(template)
    }

    triggerManualNotification(templateformat) {
        let url = getEnvVariable('Hangfire') + `/api/NotificationTrigger/RaiseManualEventNotifications`
        let NotificationTemplateModel = [];
        let internal = this.state.internalNotificationTemplate
        if (internal && internal.NotificationSettingId && internal.NotificationSettingId > 0 && templateformat) {
            internal.NotificationSubject = templateformat.subject;
            internal.NotificationContentHTML = templateformat.fileContentHTML;
            internal.RefId = this.props.recordid;
            NotificationTemplateModel.push(this.state.internalNotificationTemplate)
        }
        let reqtriggerurl = {
            url: getEnvVariable('Hangfire') + `/api/NotificationTrigger/RaiseManualEventNotifications`,
            type: 'post',
            // alert: true,
            loader: true
        }
        if (NotificationTemplateModel.length > 0) {
            // requestApi({ post: { url: url, routeProps: {} } }, null, NotificationTemplateModel)
            requestApi(createRequest(reqtriggerurl, NotificationTemplateModel)).then(results => {
                if (results) {
                    this.setState({ isNotificationSent: true }, () => {
                        this.handleClose();
                    })
                }
            })
        }
    }

    confirmSave(inthis) {
        inthis.setState({ open: false, confirmOpen: false })

        if (inthis.props.handleNotificationClose)
            inthis.props.handleNotificationClose();
        else
            this.setState({ isSuccess: true })

    }

    confirmClose(inthis) {
        inthis.setState({ confirmOpen: false })
    }

    render() {
        const { classes } = this.props
        const { open, confirmOpen, internalNotificationTemplate, externalNotificationTemplate, recordDetails, hasError, isSuccess } = this.state
        return (
            <Fragment>
                <Drawer
                    open={open}
                    anchor="right"
                    classes={{
                        paperAnchorRight: classes.paperAnchorRight,
                        root: classes.root
                    }} onClose={this.handleClose}>
                    <AppBar position="sticky" color='secondary'>
                        <Toolbar variant="dense">
                            Send Notification
                            <div className='flex-grow-1' />
                            <div>
                                <i className="" onClick={this.handleSave} >
                                    <IconButton title="Send" color="inherit">
                                        <SendIcon fontSize="small" />
                                    </IconButton>
                                </i>
                                <i className="" onClick={this.handleClose} >
                                    <IconButton title="Close" color="inherit">
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </i>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <div> <ProgressBar ref={e => this.progressBar = e} /></div>
                    <div className="col-12">
                        <Typography variant="subtitle2" gutterBottom>
                            Following notification content is generated based on settings. You can modify notification subject and body before sending.
                        </Typography>
                        {hasError && <div style={{ color: 'red' }}> No related notification settings found</div>}
                        {isSuccess && <div style={{ color: 'green' }}> ****************************Notification sent successfully !!**********************************</div>}
                        <Template ref={this.TemplateForm}
                            handleTemplateId={this.templateEditorSave}
                            entryData={recordDetails}
                            //  parentid={this.props.recordid || 0}
                            isAdmin={false}
                            templateId={getEnvVariable('AccessPortal') === 'I' ? (internalNotificationTemplate &&
                                internalNotificationTemplate.TemplateId) ? internalNotificationTemplate.TemplateId :
                                (externalNotificationTemplate && externalNotificationTemplate.TemplateId ? externalNotificationTemplate.TemplateId : 0) : 0}
                            isEnabled={getEnvVariable('AccessPortal') === 'E' ? false : true}
                            hasSubject={true}
                            templateFormatsCallBack={(data) => this.templateFormatsCallBack(this, data)}
                            canDownload={false}
                        //refType={this.props.refType}
                        //    refId={this.props.id || 0}
                        />
                        {confirmOpen && <ConfirmationDialog open={true}
                            description={"Are you sure, you don't want to send this notification ? "}
                            title={"Cancel sending notification"}
                            onSubmit={() => this.confirmSave(this)}
                            onClose={() => this.confirmClose(this)}
                            labels={["Don't Send", "Cancel"]}
                        />}

                    </div>
                </Drawer>
            </Fragment>
        )
    }

}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

NotificationEditor = connect(null, mapActions)(NotificationEditor)
NotificationEditor = withStyles(styles)(NotificationEditor);
export default (withRouter)(NotificationEditor);