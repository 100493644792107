import { getEnvVariable } from "../../../modules/environmentalList"

const ProposedLandUse = {
    "type": "popup",
    "key": "ProposedLandUse",
    "name": "ProposedLandUse",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'addOpen'
    },
    sharedValues: ['open'],
    container: {
        values: { save: false, back: false, fullscreen: false, },
        mapValues: {
            header: [
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
            ],
            AddProposedLandUse: [{ key: 'save', name: 'save' },
            { key: 'exitfullscreen', name: 'exitfullscreen' },
            { key: 'fullscreen', name: 'fullscreen' },]
        },
        //  sharedValues: ['save', 'edit'],
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },


            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'AddProposedLandUse',
                    type: 'div',
                    className: 'col-12 pl-4',
                    sub: {
                        type: 'div',
                        className: 'col-12 pl-4',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Proposed Land Use',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "Add Proposed Land Use", "materialicon": "eject", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'save',
                        hidevalue: false,
                        title: 'Save Proposed Land Use'
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hidevalue: false,
                        title: 'FullScreen'
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false,
                        title: 'Exit FullScreen'
                    }
                ],


            },
            AddProposedLandUse: {
                "type": "form",
                "key": "AddProposedLandUse",
                "name": "AddProposedLandUse",
                aliases: {
                    save: 'save',
                },
                "routeParams": { replace: 'proposedlanduse', param: 'Id', routeName: 'id' },
                sharedValues: ['save', 'exitfullscreen', 'fullscreen'],
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'ProposedHeader',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            }],

                        },
                        {
                            order: 1,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'UsageNotes',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            }],

                        },
                        {
                            order: 2,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'LandUse',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8'
                                }
                            }]
                        },
                        {
                            order: 3,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Unit',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            },]
                        },
                        {
                            order: 4,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'LandUseADT',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8'
                                }
                            },]
                        },
                        {
                            order: 5,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Id',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8'
                                }
                            },]
                        },
                        {
                            order: 6,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'PropertyGroupID',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8'
                                }
                            },]
                        },
                    ],
                },
                values: {
                    UsageNotes: '',
                    LandUse: '',
                    Unit: null,
                    LandUseADT: '',
                    portal: getEnvVariable('AccessPortal') === 'E' ? 'N' : 'Y',
                    PropertyGroupID: '',
                    ProposedHeader:''
                },
                dataAttributes: {
                    LandUse: {},

                },
                "components": {
                    ProposedHeader: {
                        "type": "label",
                        "key": "ProposedHeader",
                        "label": "Proposed Land Use",
                        "name": "ProposedHeader",

                    },
                    UsageNotes: {
                        "type": "textfield",
                        "key": "UsageNotes",
                        "label": "Usage Notes",
                        "name": "UsageNotes",
                    },
                    LandUse: {
                        "type": "dropdown",
                        "key": "LandUse",
                        "label": "Land Use ",
                        "name": "LandUse",
                        extraProps: {
                            transformProps: {
                                label: 'Name',
                                value: 'Value',
                                //default: 'IsDefault'
                            },
                            dataAttributes: [
                                {
                                    key: 'data-calculatedvalue',
                                    name: 'CalculatedValue'
                                }
                            ]
                        }
                    },
                    Unit: {
                        "type": "textfield",
                        "key": "unit",
                        "label": "Unit Count",
                        name: "Unit",
                        "extraProps": {
                            "type": "number"
                        }

                    },
                    LandUseADT: {
                        "type": "domainCalVal",
                        "key": "LandUseADT",
                        "label": "Land Use ADT",
                        name: "LandUseADT",
                        disabled: true,
                        "extraProps": {
                            "type": "number"
                        }
                    },
                    Id: {
                        "type": "textfield",
                        "key": "Id",
                        "label": "Land Use ID",
                        name: "Id",
                        disabled: true,
                        "extraProps": {
                            "type": "number"
                        }
                    },
                    PropertyGroupID: {
                        "type": "textfield",
                        "key": "PropertyGroupID",
                        "label": "Property Group ID",
                        name: "PropertyGroupID",
                        disabled: true,
                        "extraProps": {
                            "type": "number"
                        }
                    },
                },
                mapActionsToEffects: {
                    'onLoad': {
                        init: {
                            rules: [{
                                run: {
                                    valid: [{
                                        type: 'load',
                                        name: 'LandUse',
                                        "read": [
                                            {
                                                url: getEnvVariable('Admin')+`/api/Domains/values?domainkey=PROPOSEDLANDUSE`,
                                                type: 'get',
                                                saveAs: {
                                                    key: 'items'
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        type: 'load',
                                        name: 'LandUseADT',
                                        "read": [
                                            {
                                                url: getEnvVariable('Admin')+`/api/Domains/values?domainkey=PROPOSEDLANDUSE`,
                                                type: 'get',
                                                saveAs: {
                                                    key: 'items'
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        type: 'validations', //http://development.delasoft.com:8443/AMES-application/api/ProposedLandUse/5864/Add
                                        "read": [
                                            {
                                                "url": getEnvVariable('Application')+`/api/ProjectMessages/RulesByName?name=modulemaster.proposedlanduse`,
                                                type: 'get'
                                            }
                                        ],
                                        transformProps: {
                                            removesubfieldname: 'AMESMODULEMASTER.PROPOSEDLANDUSE_'
                                        }
                                        },
                                        {
                                            type: 'businessrules',
                                            "read": [
                                                {
                                                    url: getEnvVariable('Application') + `/api/BusinessRules/Grid/modulemaster.proposedlanduse`,
                                                    type: 'get',
                                                }
                                            ],
                                        },
                                        // {
                                        //     type: 'loadValues',
                                        //     name: 'LandUse',
                                        //     loadtransformProps: {
                                        //         label: 'Name',
                                        //         value: 'Value',
                                        //         defaultCondition: 'IsDefault',
                                        //         checkDefaultValue: 'Y',
                                        //         savetype: 'add'
                                        //     },
                                        //     initialdataAttributes: {
                                        //         CalculatedValue: 'calculatedvalue',
                                        //         Value: 'value',
                                        //     }
                                        // },
                                    ]
                                }
                            }]// 1 intital load
                        },
                        'exitfullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'exitfullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'fullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'fullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'GLOBAL': {
                            rules: [
                                // {
                                //     run: {
                                //         valid: [
                                //             {
                                //                 type: 'loadValues',
                                //                 name: 'LandUse',
                                //                 loadtransformProps: {
                                //                     label: 'Name',
                                //                     value: 'Value',
                                //                     defaultCondition: 'IsDefault',
                                //                     checkDefaultValue: 'Y',
                                //                     savetype: 'add'
                                //                 },
                                //                 initialdataAttributes: {
                                //                     CalculatedValue: 'calculatedvalue',
                                //                     Value: 'value',
                                //                 }
                                //             },
                                //         ]
                                //     },
                                // },
                                {
                                    canOverwrite: false,
                                    check: [
                                        {
                                            condition: "AND",
                                            rules: [
                                                { name: "LandUse", type: ">", value: "0", rules: [] },
                                                { name: "LandUse", type: "=", value: "20", rules: [] },
                                                { name: "Unit", type: "is not null", rules: [] }
                                            ]
                                        }
                                    ],
                                    fields: ["LandUse"],
                                    run: {

                                        inValid: [
                                            { type: "prop", name: "Unit",  required: true, disabled: false },
                                            { type: "prop", name: "UsageNotes", required: false, disabled: false },

                                        ],
                                        //   type: "uncheck",
                                        valid: [
                                            { type: "prop", name: "Unit",  required: false, disabled: false },
                                            { type: "prop", name: "UsageNotes",  required: true, disabled: false },

                                        ],

                                    },
                                    uncheck: true
                                },
                                {
                                    canOverwrite: false,
                                    check: [
                                        {
                                            condition: "AND",
                                            rules: [
                                                { name: "portal", type: "=", value: "N", rules: [] },
                                            ]
                                        }
                                    ],
                                    fields: ["portal"],
                                    ruleOrder: 0,
                                    run: {

                                        inValid: [
                                            { type: "prop", name: "LandUseADT", disabled: true, isVisible: true },
                                            { type: "prop", name: "PropertyGroupID", disabled: true, isVisible: true },
                                            { type: "prop", name: "Id", disabled: true, isVisible: true },
                                        ],
                                        //   type: "uncheck",
                                        valid: [
                                            { type: "prop", name: "LandUseADT", disabled: true, isVisible: false },
                                            { type: "prop", name: "PropertyGroupID", disabled: true, isVisible: false },
                                            { type: "prop", name: "Id", disabled: true, isVisible: false },
                                        ],

                                    },
                                    uncheck: true
                                },
                            ]
                        },
                    },
                    'onChange': {
                        'GLOBAL': {
                            rules: [
                                // {
                                //     canOverwrite: false,
                                //     check: [
                                //         {
                                //             condition: "AND",
                                //             rules: [
                                //                 { name: "LandUse", type: "is not null", rules: [] },
                                //                 { name: "portal", type: "=", value: 'Y', rules: [] }
                                //             ]
                                //         }
                                //     ],
                                //     fields: ["LandUse", 'Unit'],
                                //     run: {
                                //         valid: [{
                                //             type: 'calculate',
                                //             name: 'LandUseADT',
                                //             attr: 'calculatedvalue',
                                //             operands: 'LandUse',
                                //             quotient: 'Unit',
                                //             operator: '*',
                                //             hasValue: true,
                                //             valueType: 'D',
                                //             isVisible: true,disabled:true

                                //         }],
                                //         inValid: [{
                                //             type: 'calculate',
                                //             name: 'LandUseADT',
                                //             attr: 'calculatedvalue',
                                //             operands: 'LandUse',
                                //             quotient: 'Unit',
                                //             operator: '*',
                                //             hasValue: true,
                                //             valueType: 'D',
                                //             isVisible: false,disabled:true

                                //         }
                                //         ]
                                //     },
                                // },
                                // {
                                //     canOverwrite: false,
                                //     check: [
                                //         {
                                //             condition: "AND",
                                //             rules: [
                                //                 { name: "Unit", type: "is not null", rules: [] },
                                //                 { name: "LandUse", type: "is not null", rules: [] },
                                //                 { name: "portal", type: "=", value: 'Y', rules: [] }
                                //             ]
                                //         }
                                //     ],
                                //     fields: ["LandUse", 'Unit'],
                                //     run: {
                                //         valid: [{
                                //             type: 'calculate',
                                //             name: 'LandUseADT',
                                //             attr: 'calculatedvalue',
                                //             operands: 'LandUse',
                                //             quotient: 'Unit',
                                //             operator: '*',
                                //             hasValue: true,
                                //             valueType: 'D',
                                //             isVisible: true,disabled:true

                                //         }],
                                //         inValid: [{
                                //             type: 'calculate',
                                //             name: 'LandUseADT',
                                //             attr: 'calculatedvalue',
                                //             operands: 'LandUse',
                                //             quotient: 'Unit',
                                //             operator: '*',
                                //             hasValue: true,
                                //             valueType: 'D',
                                //             isVisible: false,disabled:true

                                //         }
                                //         ]
                                //     },
                                // },
                                {
                                    canOverwrite: false,
                                    check: [
                                        {
                                            condition: "AND",
                                            rules: [
                                                { name: "LandUse", type: ">", value: "0", rules: [] },
                                                { name: "LandUse", type: "=", value: "20", rules: [] },
                                            ]
                                        }
                                    ],
                                    fields: ["LandUse"],
                                    ruleOrder: 0,
                                    run: {

                                        inValid: [
                                            { type: "prop", name: "Unit",  required: true, disabled: false },
                                            { type: "prop", name: "UsageNotes", required: false, disabled: false },

                                        ],
                                        //   type: "uncheck",
                                        valid: [
                                            { type: "prop", name: "Unit",  required: false, disabled: false },
                                            { type: "prop", name: "UsageNotes",  required: true, disabled: false },

                                        ],

                                    },
                                    uncheck: true
                                },
                            ],
                            fields: [
                                'Unit', 'LandUse'
                            ]
                        },
                    },
                    'afterSave': {
                        'save': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',// http://development.delasoft.com:8443/AMES-application/api/Property/20120/Add
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Application')+`/api/ProposedLandUse/{routeid1}/Add`,
                                                        type: 'post',
                                                        "matchProps": [{
                                                            name: "routeid1",
                                                            key: "routeid1",
                                                        }]
                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    },
                },
            }
        },

    }
}

export default ProposedLandUse
