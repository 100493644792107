import * as React from "react";
import AttachmentIcon from '@material-ui/icons/AttachFile';
import NoticeIcon from '@material-ui/icons/Note';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import { getEnvVariable } from "../../../modules/environmentalList";

export const MyCommandCell = props => {
  const { dataItem, handleEdit, handleSave, handleDelete, setTemplateSettingsOpen, subGrid } = props;

  return <td className="k-command-cell">
    {!dataItem.inEdit && <IconButton title="Edit" color="primary" component="span" onClick={() => handleEdit(dataItem)}>
      <EditIcon />
    </IconButton>}
    {dataItem.inEdit && <IconButton title="Save" color="primary" component="span" onClick={() => handleSave(dataItem)}>
      <SaveIcon />
    </IconButton>}
    <IconButton title="Delete" color="primary" component="span" onClick={(e) => handleDelete(dataItem.Id)}>
      <DeleteIcon />
    </IconButton>
    <IconButton title="Attachment Settings" color="primary" component="span" onClick={() => subGrid({ id: dataItem.Id, name: 'attachments' })}>
      <AttachmentIcon />
    </IconButton>
    {/* <IconButton title="Notice Settings" color="primary" component="span" onClick={() => subGrid({ id: dataItem.Id, name: 'notices' })}>
      <NoticeIcon />
    </IconButton>*/}
    {getEnvVariable('ModuleKey') !== 'AMES' && <IconButton title="Template Settings" color="primary" component="span" onClick={() => setTemplateSettingsOpen(dataItem.Id)}>
      <GetAppIcon />
    </IconButton>}
  </td >;
};