import { getEnvVariable } from "../../modules/environmentalList";

const map = {
    "type": "map",
    "key": "EPS_map_EDIT",
    "order": 32,
    "width":'160vh',
    "options": {
        "mapkey":"EPS_map_EDIT",
        "routes": {
            "route": "/Administration/map"
        },
        "hastaxlotlayer":"N",
        "widgets": ["Home", "Search", "Zoom", "Sketch", "BasemapGallery", "LayerList","Measurement","TaxlotSelector", "BufferCalc", "Print","Select","LayerFilter","DatTable", "MultiLocationSelector"], // ["Locate", "Home", "Search", "Zoom", "Measurement", "Draw", "Sketch", "BasemapGallery", "LayerList", "Bookmark"],
        "basemap": "streets",//"streets", "satellite", "hybrid", "terrain", "topo", "gray", "dark-gray", "oceans", "national-geographic", "osm", "dark-gray-vector", "gray-vector", "streets-vector", "topo-vector", "streets-night-vector", "streets-relief-vector", "streets-navigation-vector"
        "viewProperties": {
            // "extent": {
            //     "xmax": -8057114.558988379,
            //     "xmin": -8679311.969229413,
            //     "ymax": 4872179.908902848,
            //     "ymin": 4605567.554244282,
            //     "spatialReference": 102100
            // },
            "zoom": 15,
            "center": [-8368213.264108896,4738873.731573565],
            "ui": {
                "components": ["attribution"]// ["compass", "attribution"] // empty the UI, except for attribution
            }
        },
        "printOptions":{
            "serviceURL":"http://development.delasoft.com:8443/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task"
        },
        "searchOptions": {
            "State": "OR"
        },
        "zoomOptions": {
            "maxZoomed": false,
            "minZoomed": false
        },
        "drawOptions": {
            "Tools": [],//["point", "polyline", "polygon", "rectangle", "circle"]
            "AllowMultiple":{"Points":false, "Lines":false,"Polygons":false},
            "LayerIndex": 1
        },
        'searchbookmarks':{
            "drawTool":' Hello I ma me',
            "url":"http://demo.delasoft.com:9443/arcgis/rest/services/KEPT_Test/Bookmarks/FeatureServer/0",
            "defaultChoice":'E',
            "defaultName":'Bookmark #',
            "defaultSymbol":{
                type: "simple-fill",
                outline: {
                    style: "dash",
                    miterLimit: 16,
                    color: [0, 112, 255, 1]
                },
                color: [115, 223, 255, 0.24]
            },
            "defaultMessage":'Draw Box on Map for Bookmark',
            'appname':'INQUERY',
            'username':'13384'
        },
        'tasks': {
            "TaxParcels": {
                "url": "",
                "queryExpression": "1!=1",
                "LayerIndex":0
            }
        },
        "LayerOptionsConfig":
        {
            "Category": ["F"],
            "MapType": ["A","R"]   //A-Add Record,L-Landing,G-Grid,R-Record review
        },
        "LayerOptions":[],
        "FeatureLayerListOptions":[],
        "CountyLayerOptions":[],
        "FeatureLayers": {
            "POINT": getEnvVariable('ArcGIS')+`/rest/services/EPS/MasterTable_Geom_Service/FeatureServer/0`,
            "MULTIPOINT": getEnvVariable('ArcGIS')+`/rest/services/EPS/MasterTable_Geom_Service/FeatureServer/1`,
            "LINE": getEnvVariable('ArcGIS')+`/rest/services/EPS/MasterTable_Geom_Service/FeatureServer/2`,
            "POLYGON": getEnvVariable('ArcGIS')+`/rest/services/EPS/MasterTable_Geom_Service/FeatureServer/3`,
            "LayerIndex":2//For Adding GraphicsLayer
        },
        "MasterLookupFields":[
                    {
                        Id:0,
                        'ServiceUrl': 'https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/26/query',
                        'FieldKey':'REGION_GISID',
                        'NameReference':'REG_NO',
                        'ValueReference':'REG_NO'
                    },
                    {
                        Id:1,
                        'ServiceUrl': 'https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/25/query',
                        'FieldKey':'DISTRICT_GISID',
                        'NameReference':'DISTRICT',
                        'ValueReference':'DISTRICT'
                    },
                    {
                        Id:2,
                        'ServiceUrl': 'https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/41/query',
                        'FieldKey':'SITE_GISID',
                        'NameReference':'CNTY_CODE',
                        'ValueReference':'CNTY_CODE'
                    }
        ],
        "FieldsData": [
            {
               "Fieldkey":"MAP_CENTER_LATITUDE",
               "FieldValue":""
            },
            {
               "Fieldkey":"MAP_CENTER_LONGITUDE",
               "FieldValue":""
            },
            {
               "Fieldkey":"PROPOSED_MAP_CENTER_LATITUDE",
               "FieldValue":""
            },
            {
               "Fieldkey":"PROPOSED_MAP_CENTER_LONGITUDE",
               "FieldValue":""
            },
            {
               "Fieldkey":"POINT_GEOM_OBJECT_ID",
               "FieldValue":0
            },
            {
                "Fieldkey":"PROPOSED_POINT_GEOM_OBJECT_ID",
                "FieldValue":0
            },
            {
               "Fieldkey":"LINE_GEOM_OBJECT_ID",
               "FieldValue":0
            },
            {
                "Fieldkey":"PROPOSED_LINE_GEOM_OBJECT_ID",
                "FieldValue":0
             },
            {
               "Fieldkey":"POLYGON_GEOM_OBJECT_ID",
               "FieldValue":0
            },
            {
                "Fieldkey":"PROPOSED_POLYGON_GEOM_OBJECT_ID",
                "FieldValue":0
            },
            {
               "Fieldkey":"MULTIPOINT_GEOM_OBJECT_ID",
               "FieldValue":0
            },
            {
                "Fieldkey":"PROPOSED_MULTIPOINT_GEOM_OBJECT_ID",
                "FieldValue":0
            },
            {
               "Fieldkey":"TAX_PARCEL_IDS"
            },
            {
                "Fieldkey":"TAX_PARCEL_IDS_GEOM",
                "FieldValue":''
            },
            {
                "Fieldkey":"PRIMARY_TAX_PARCEL_IDS",
                "FieldValue":''
            },
            {
               "Fieldkey":"ADC_MAP_GRID_NO"
            },
            {
               "Fieldkey":"CENTER_GEOM_OBJECT_ID",
               "FieldValue":0
            },
            {
                "Fieldkey":"PROPOSED_CENTER_GEOM_OBJECT_ID",
                "FieldValue":0
            },
            {
                "Fieldkey":"LATITUDE_ROAD_POSITION",
                "FieldValue":""
             },
             {
                "Fieldkey":"LONGITUDE_ROAD_POSTION",
                "FieldValue":""
             },
        ],
        "MapSettings": {// section settings of Map section it means values[0] is MapSettings

        },
        "MultipleLocationData":[], // Specific to KEPT project for stroing the multiple location data, if any.
        "UserId":"1"
    }
};

export default map;