import { getEnvVariable } from '../../modules/environmentalList';
import agencyuserloginhistory from './userprofileabc';
const userprofile = {
    "type": "slidingbutton",
    "key": "agencyusersprofileformslidingbutton",
    "options": {
        "row": 6,
        "col": 4,
        "width": 2,
        "buttonprops": {
            "text": "Users",
            "size": "large",
            "color": "primary",
            "variant": "contained"
        },
        excludefromresults: true,
        "label": "Users",
        "name": "Users",
        receiveValues: [{ paramname: 'userId', valuefromparent: 'userId' }],
    },
    open: true,
    components: [{
        "type": "formcontainer",
        "key": "agencyusersform",
        "options": {
            "title": "User",
            headerProps: {
                "editbutton": false,
                "savebutton": false,
                "backbutton": true,
                "closebutton": false,
                "navigateview": true,
                "breadcrumbs": [
                    { "link": "View/Edit User", "materialicon": "person", className: 'eps-breadcrumb-item', class: 'text-light' }
                ],
            },
            hidescroll: true,
            "name": "agencyusersform",
            "back": {
                "routes": [{
                    "route": "/Administration/userprofile",
                    "exactRoute": true
                },],
                "popupback": true
            }
        },
        "sidepopupadd": true,
        "components": [
            {
                "type": "ctab",
                "key": "tab",
                hidemargin: true,
                "name": "agencyuserstabform",
                excludefromresults: true,
                "options": {
                    tabinfo: [
                        {
                            tablabel: "View/Edit User",
                            tabkey: 0
                        },
                        {
                            tablabel: "Access Control",
                            tabkey: 1,
                            alttabkey:3,
                            'profileaccesscontrol' : 'Y',
                            isadmintabkey:3
                        },
                        // {
                        //     tablabel: "Temporary Roles",
                        //     tabkey: 2
                        // },
                        // {
                        //     tablabel: "Update Password",
                        //     tabkey: 2,
                        //     'profilepwd' : 'Y',
                        //     'hidetab': true,
                        //     "hidetabval": "I",
                        //     "aftersavetab": true,
                        //     isLocalStorage: 'IWAAUTH',
                        //     alttabkey: 3
                        // },
                        {
                            tablabel: "Login History",
                            tabkey: 3

                        },
                    ],
                },
                "components": [
                    {
                        "tabkey": 0,
                        "type": "formcontainer",
                        "key": "agencyusersform",
                        "options": {
                            "totalcolumns": 2,
                            "totalrows": 18,
                            "alignitem": "center",
                            "title": "User",
                            "name": "agencyusersform",
                            "routes": {
                                "route": "/Administration/userprofile",
                                "routeParams": ":routeid",
                                "exactRoute": true
                            },
                            className: 'siteclass',
                            styles: {
                                //margin: 15
                            },
                            headerProps: {
                                "editbutton": true,
                                "savebutton": true,
                                "backbutton": true,
                                "closebutton": false,
                                "navigateview": true,
                                // "duplicatebutton": true,
                            },
                            designprops: true,
                            alignprofile: true,
                            "name": "agencyusersform",
                            "crud": {
                                "read": {
                                    "get": {
                                        "url": getEnvVariable('MasterAdmin') + `/api/${getEnvVariable('ModuleKey')}InternalUser/${getEnvVariable('ModuleKey')}UserById`,
                                        "customProps": {
                                            "value": "userId"
                                        }
                                    }
                                },
                                "update": {
                                    "put": {
                                        "isProfile": true,
                                        "url": getEnvVariable('MasterAdmin') + `/api/${getEnvVariable('ModuleKey')}InternalUser/${getEnvVariable('ModuleKey')}Update`,
                                    }
                                },
                                // "duplicate": {
                                //     "post": {
                                //         "url": getEnvVariable('MasterAdmin') + `/api/WRSInternalUser/` + getEnvVariable('ModuleKey') + `/getEnvVariable('ModuleKey') + Add`,
                                //     }
                                // }
                            },
                            "validations": [
                                {
                                    get: {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.users`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'MASTER.USERS_',
                                        addsubfieldname: 'USER.'
                                    }
                                },
                                {
                                    get: {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.internalusers`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'MASTER.INTERNALUSERS_'
                                    }
                                }
                            ],
                        },
                        "components": [
                            {
                                "type": "fileupload",
                                "key": "userimageupload",
                                "options": {
                                    "row": 0,
                                    "col": 0,
                                    "width": 5,
                                    "height":5,
                                    "text": "Profile Picture ",
                                    "name": "UserImageUI",
                                    "profile": true,
                                    "defaultImage": "resources/Default-UserImg.jpg"
                                }
                            },
                            // {
                            //     "type": "fileupload",
                            //     "key": "signatureupload",
                            //     "options": {
                            //         "row": 0,
                            //         "col": 1,
                            //         "width": 6,
                            //         "text": "Signature ",
                            //         "name": "SignatureImageUI",
                            //         "defaultImage": "/resources/Default-UserImg.jpg"
                            //     }
                            // },
                            {
                                "type": "formtextbox",
                                "key": "username",
                                "options": {
                                    "row": 1,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Username",
                                    "name": "User.UserName",
                                    "disabled": true,
                                    conditions: {
                                        enable: true,
                                    }
                                },
                                "inputProps": {
                                    "maxLength": 100
                                }
                            },
                            // {
                            //     "type": "infotext",
                            //     "key": "Infotext",
                            //     "options": {
                            //         "row": 2,
                            //         "col": 0,
                            //         "width": 12,
                            //         "text": "Username Policy:",

                            //     }
                            // },
                            // {
                            //     "type": "infotext",
                            //     "key": "Infotext",
                            //     "options": {
                            //         "row": 2,
                            //         "col": 0,
                            //         "width": 12,
                            //         "text": "1. Username can contain letters (a-z), numbers (0-9), underscore (_) and periods (.).",

                            //     }
                            // },
                            // {
                            //     "type": "infotext",
                            //     "key": "Infotext",
                            //     "options": {
                            //         "row": 2,
                            //         "col": 0,
                            //         "width": 12,
                            //         "text": "2.Username cannot contain an ampersand (&), equals sign (=), apostrophe ('), dash (-), plus sign (+), comma (,), brackets (<,>)"

                            //     }
                            // },
                            {
                                "type": "formtextbox",
                                "key": "firstname",
                                "options": {
                                    "row": 3,
                                    "col": 0,
                                    "width": 6,
                                    "label": "First Name",
                                    "name": "User.FirstName"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "lastname",
                                "options": {
                                    "row": 3,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Last Name",
                                    "name": "User.LastName"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "title",
                                "options": {
                                    "row": 5,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Title",
                                    "name": "Title",
                                    "disabled": true,
                                    conditions: {
                                        enable: true,
                                    }
                                },

                            },
                            {
                                "type": "formdropdown",
                                "key": "UserType",
                                "options": {
                                    "row": 6,
                                    "col": 0,
                                    "width": 12,
                                    "label": "User Type",
                                    "name": "UserType",
                                    "id": "DomainType",
                                    "disabled": true,
                                    conditions: {
                                        enable: true,
                                    },
                                    "crud": {
                                        "read": {
                                            "get": {
                                                "url": getEnvVariable('Admin') + `/api/Domains/values?domainkey=USER_TYPE`
                                               // "url":"http://localhost:6004/WRS-admin/api/Domains/values?domainkey=USER_TYPE",
                                                
                                            }
                                        }
                                    },
                                    "transformProps": {
                                        "value": "Value",
                                        "name": "Name"
                                    }
                                }
                            },
                            {
                                "type": "formdropdown",
                                "key": "companyId",
                                "options": {
                                    "row": 7,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Contractor",
                                    "name": "companyId",
                                    "disabled": true,
                                    conditions: {
                                        enable: true,
                                    },
                                    "crud": {
                                        "read": {
                                            "get": {
                                                //"url": getEnvVariable('MasterAdmin') + `/api/CompanyInternal/All/Active`,
                                                 "url": getEnvVariable('MasterAdmin') + `/api/CompanyInternal/`+ getEnvVariable('ModuleKey') +`/All/Active`
                                                //    "url":"http://localhost:8002/WRS-master-admin/api/CompanyInternal/WRS/All/Active"
                                            }
                                        }
                                    },
                                    "transformProps": {
                                        "value": "Id",
                                        "name": "Name"
                                    },
                                    conditions: { 
                                        hide: { UserType:'D'},// For Contractor,County,County as Contractor,'' the field is Disabled.
                                    },
                                    //  removeotherfield: [
                                    //     {  changedvalue: 0 },
                                    //  ]
                                },
                            },                
                            // {
                            //     "type": "formtextbox",
                            //     "key": "department",
                            //     "options": {
                            //         "row": 5,
                            //         "col": 0,
                            //         "width": 12,
                            //         "label": "Department",
                            //         "name": "Department"
                            //     },

                            // },
                            // {
                            //     "type": "formtextbox",
                            //     "key": "company",
                            //     "options": {
                            //         "row": 6,
                            //         "col": 0,
                            //         "width": 12,
                            //         "label": "Company",
                            //         "name": "Company"
                            //     },

                            // },
                            {
                                "type": "formtextbox",
                                "key": "email",
                                "options": {
                                    "row": 8,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Email",
                                    "name": "Email"
                                },

                            },
                            {
                                "type": "cformphonetext",
                                "key": "Phone",
                                "options": {
                                    "row": 8,
                                    "col": 1,
                                    "width": 6,
                                    "label": "Phone",
                                    "name": "Phone"
                                }
                            },
                            {
                                "type": "formswitch",
                                "key": "switch",
                                "options": {
                                    "row": 9,
                                    "col": 0,
                                    "width": 3,
                                    "label": "Status",
                                    "disabled": true,
                                    conditions: {
                                        enable: true,
                                    },
                                    "name": "User.Status",
                                    dependency: {
                                        isStatus: "User",
                                        statusURL: getEnvVariable('Admin') + `/api/Users/IsAtleastOneUserAccessById`,
                                        statusCheck: "IsAdmin",
                                        statusID: "routeid",
                                        statusTarget: "UserAccessControl",
                                        statusField: "User.Status",
                                        statusValue: "A",
                                        helperText: "Atleast one Group/Role/County should be selected"
                                    },
                                    "transformProps": {
                                        "A": true,
                                        "I": false,
                                        true: "A",
                                        false: "I"
                                    }
                                }
                            },
                            {
                                "type": "formcheckbox",
                                "key": "IsAdmin",
                                "options": {
                                    "row": 10,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Administrator",
                                    "tooltip": "If admin role is checked then it overrides entire access control",
                                    "name": "IsAdmin",
                                    dependency: {
                                        isAdmin: "IsAdmin",
                                        isAdminAlert: "Selecting / Unselecting Admin will override the entire Access Control"
                                    },
                                    initialvalue: 'I',
                                    "transformProps": {
                                        "Y": true,
                                        "N": false,
                                        true: "Y",
                                        false: "N"
                                    },
                                    "disabled": true,
                                    conditions: {
                                        enable: true,
                                    },
                                    removeotherfield: [
                                        { removefield: 'IsRequestAdmin', changedvalue: 'N' },
                                    ]
                                }
                            },
                            {
                                "type": "formcheckbox",
                                "key": "IsRequestAdmin",
                                "options": {
                                    "row": 10,
                                    "col": 1,
                                    "width": 6,
                                    "label": "Authorization Approver",
                                    "name": "IsRequestAdmin",
                                    initialvalue: 'I',
                                    "transformProps": {
                                        "Y": true,
                                        "N": false,
                                        true: "Y",
                                        false: "N"
                                    },
                                   // "disabled": true,
                                    // conditions: {
                                    //     disabled: {UserType: 'D'||'C'||'CC'||'CO'}
                                    // },
                                    "disabled": true,
                                    conditions: {
                                        enable: true,
                                    },
                                    removeotherfield: [
                                        { removefield: 'IsRequestAdmin', changedvalue: 'N' },
                                    ]
                                }
                            },
                            {
                                "type": "formcheckbox",
                                "key": "IsSendNotification",
                                "options": {
                                    "row": 11,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Can receive Dashboard Notifications",
                                    "name": "IsSendNotification",
                                    "disabled": true,
                                    conditions: {
                                        enable: true,
                                    },
                                    initialvalue: 'N',
                                    "transformProps": {
                                        "Y": true,
                                        "N": false,
                                        true: "Y",
                                        false: "N"
                                    },
                                },
                            },
                            {
                                "type": "formcheckbox",
                                "key": "IsSendEmailNotification",
                                "options": {
                                    "row": 11,
                                    "col": 1,
                                    "width": 6,
                                    "label": "Can receive Email Notifications (Primary User)",
                                    "name": "IsSendEmailNotification",
                                    "disabled": true,
                                    conditions: {
                                        enable: true,
                                    },
                                    initialvalue: 'N',
                                    "transformProps": {
                                        "Y": true,
                                        "N": false,
                                        true: "Y",
                                        false: "N"
                                    },
                                },
                            },

                            {
                                "type": "formcheckbox",
                                "key": "isMobileAccess",
                                "options": {
                                    "row": 10,
                                    "col": 1,
                                    "width": 6,
                                    "label": "Mobile Access",
                                    "name": "IsMobile",
                                    "disabled": true,
                                    conditions: {
                                        enable: true,
                                    },
                                    initialvalue: 'I',
                                    "transformProps": {
                                        "Y": true,
                                        "N": false,
                                        true: "Y",
                                        false: "N"
                                    }
                                }
                            },

                            {
                                "type": "historydate",
                                "key": "datecreated",
                                "options": {
                                    "row": 12,
                                    "col": 0,
                                    "width": 6,
                                    "text": "Created On: ",
                                    "name": "CreatedOn"
                                }
                            },
                            {
                                "type": "historyuser",
                                "key": "createdby",
                                "options": {
                                    "row": 12,
                                    "col": 1,
                                    "width": 6,
                                    "text": "Created By: ",
                                    "name": "CreatedByName"
                                }
                            },
                            {
                                "type": "historydate",
                                "key": "updatedon",
                                "options": {
                                    "row": 13,
                                    "col": 0,
                                    "width": 6,
                                    "text": "Updated On: ",
                                    "name": "UpdatedOn"
                                }
                            },
                            {
                                "type": "historyuser",
                                "key": "updatedbyname",
                                "options": {
                                    "row": 13,
                                    "col": 1,
                                    "width": 6,
                                    "text": "Updated By: ",
                                    "name": "UpdatedByName"
                                }
                            },
                            // {
                            //     "type": "infotext",
                            //     "key": "Infotext",
                            //     "options": {
                            //         "row": 14,
                            //         "col": 0,
                            //         "width": 12,
                            //         "text": "Note: User is not active. Once you save your information and click on the send activation link button, an email will be dispatched to the provided email address containing instructions for activating new account.",

                            //     }
                            // },
                        ]
                    },
                    // {
                    //     "tabkey": 1,
                    //     "type": "treeview",
                    //     "key": "treeviewaccesscontrol",
                    //     "options": {
                    //         "width": 12,
                    //         "row": 0,
                    //         "col": 0,
                    //         save: true,
                    //         "title": 'User Access Control',
                    //         headerProps: {
                    //             "savebutton": true,
                    //             "closebutton": true
                    //         },
                    //         viewMode: true,
                    //         designprops: true,
                    //         "crud": {
                    //             "read": {
                    //                 "get": {
                    //                     "url": getEnvVariable('Admin') + `/api/Users/UserAccessControlById/`,
                    //                     "customProps": {
                    //                         "custom": "userId"
                    //                     }
                    //                 }
                    //             },
                    //             "update": {
                    //                 "put": {
                    //                     "url": getEnvVariable('Admin') + `/api/Users/SetUserAccessControl/`,
                    //                     "customProps": {
                    //                         "custom": "userId"
                    //                     }
                    //                 }
                    //             }
                    //         },
                    //     },
                    // },
                    {
                        "tabkey": 1,
                        "type": "accessControl",
                        "key": "treeviewaccesscontrol",
                        name: 'UserAccessControl',
                        "disabled":true,
                        "options": {
                            name: 'UserAccessControl',
                            "title": 'User Access Control',
                            read: [
                                {// this url will change based on recordsref
                                    url: getEnvVariable('Admin') + `/api/Users/UserAccessControlById/`,
                                    type: 'get',
                                    customProps: {
                                        value: "userId",
                                    },
                                },
                                {// this url will change based on recordsref
                                    url: getEnvVariable('MasterAdmin') + `/api/WRSInternalUser/WRSUserById/`,
                                    type: 'get',
                                    customProps: {
                                        value: "userId",
                                    },
                                },
                                // {// this url will change based on recordsref
                                //     url: getEnvVariable('Admin') + `/api/Users/SetUserAccessControl/`,
                                //     type: 'post',
                                //     customProps: {
                                //         value: "userId",
                                //     },
                                // },
                            ]
                        },
                    },
                    {
                        "tabkey": 2,
                        "type": "formcontainer",
                        "key": "updatepassword",
                        "options": {
                            "totalcolumns": 2,
                            "totalrows": 13,
                            "alignitem": "center",
                            "title": "Update password",
                           // "savebtn": true,
                            headerProps: {
                                "savebutton": true,
                                "closebutton": true,
                                "navigateadd": true,
                                "editbutton": false,
                            },
                            // style:{
                              //  "padding-left": 20,
                          //  },
                            designprops: true,
                            alignprofile: true,
                            "tabadd": true,
                            "crud": {
                                "update": {
                                    "post": {
                                        "url": getEnvVariable('Admin') + '/api/Users/UpdatePassword',
                                        "customProps": {
                                            "custom": "userId"
                                        }
                                    },
                                }
                            },
                            "validations": [
                                {
                                    get: {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.users`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'MASTER.USERS_',
                                    }
                                },
                            ],
                        },

                        "components": [
                            {
                                "type": "formtextbox",
                                "key": "newpassword",
                                "options": {
                                    "row": 2,
                                    "col": 0,
                                    "width": 12,
                                  //  "margin-left": 2,
                                    "label": "New Password",
                                    "name": "newpassword",
                                    "xtype": "password",
                                },
                                // style:{
                                //     marginRight : '15px',
                                // },
                              //  className: 'col-10 m-2'

                            },
                            {
                                "type": "formtextbox",
                                "key": "Password",
                                "options": {
                                    "row": 3,
                                    "col": 0,
                                    "width": 12,
                                 //   "margin": 2,
                                    "label": "Confirm Password",
                                    "name": "Password",
                                    "xtype": "password",
                                    dependency: {
                                        equal: 'newpassword'
                                    },
                                   // className: 'col-10 m-1'
                                    
                                },

                            },
                            {
                                "type": "infotext",
                                "key": "Infotext",
                                "options": {
                                    "row": 1,
                                    "col": 0,
                                    "width": 12,
                                    "text": "Password Policy:",

                                }
                            },
                            {
                                "type": "infotext",
                                "key": "Infotext",
                                "options": {
                                    "row": 1,
                                    "col": 0,
                                    "width": 12,
                                    "text": "1. Password must contain at least 10 characters.",

                                }
                            },
                            {
                                "type": "infotext",
                                "key": "Infotext",
                                "options": {
                                    "row": 1,
                                    "col": 0,
                                    "width": 12,
                                    "text": "2. Password should includes 1 Uppercase Alphabet[A-Z], 1 Lowercase Alphabet[a-z], 1 number[0-9], 1 Special Character.",

                                }
                            },
                            // {
                            //     "type": "infotext",
                            //     "key": "Infotext",
                            //     "options": {
                            //         "row": 2,
                            //         "col": 0,
                            //         "width": 12,
                            //         "text": "3. Password cannot contain spaces at the begin/end, Applicant Name, Username and cannot use the last five passwords.",

                            //     }
                            // },
                            // {
                            //     "type": "formcheckbox",
                            //     "key": "IsForcePasswordUpdate",
                            //     "options": {
                            //         "row": 8,
                            //         "col": 0,
                            //         "width": 12,
                            //         "label": "Require this user to change the password when login",
                            //         "name": "IsForcePasswordUpdate",
                            //         initialvalue: 'N',
                            //         "transformProps": {
                            //             "Y": true,
                            //             "N": false,
                            //             true: "Y",
                            //             false: "N"
                            //         }
                            //     }
                            // },
                        ]
                    },
                    {
                        "tabkey": 3,
                        ...agencyuserloginhistory
                    },
                ]
            },
        ]
    }]
}

export default userprofile