import { getEnvVariable } from "../../../modules/environmentalList"

const ViewNotice = {
    "type": "popup",
    "key": "ViewNotice",
    "name": "ViewNotice",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'editOpen',
    },
    sharedValues: ['open', 'back'],
    container: {
        "addRoute": true,
        'removelayout': 'ViewNotice',
        values: { editInfo: null, edit: false, createduplicate: false, fullscreen: false, back: false, allgriddata: null, navigation: null },
        mapValues: {
            header: [
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'createduplicate', name: 'createduplicate' },
            ],
            sectionscomp: [
                { key: 'editInfo', name: 'editInfo' },
                { key: 'allgriddata', name: 'allgriddata' },
                { key: 'save', name: 'save' },
                { key: 'saveduplicate', name: 'saveduplicate' },
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'createduplicate', name: 'createduplicate' },
                { key: 'back', name: 'back' },
                { key: 'navigation', name: 'navigation' },]
        },
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },
            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'sectionscomp',
                    type: 'div',
                    className: 'col-12 m-4',
                    sub: {
                        type: 'div',
                        className: 'col-12 m-4',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Details',

                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "View/Edit Master Template", "materialicon": "document_scanner", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },

                    {
                        type: 'icon',
                        icon: 'edit',
                        name: 'edit',
                        hideon: 'createduplicate',
                        hideanother: 'edit',
                        hidevalue: true,
                        title: 'Edit Master Template'
                    },
                    // {
                    //     type: 'icon',
                    //     icon: 'duplicate',
                    //     name: 'createduplicate',
                    //     hideon: 'edit',
                    //     hideanother: 'createduplicate',
                    //     hidevalue: true,
                    //     title: 'Clone Master Template'
                    // },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'edit',
                        hidevalue: false,
                        title: 'Save Master Template'
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'saveduplicate',
                        hideon: 'createduplicate',
                        hidevalue: false,
                        title: 'Save Master Template'
                    },
                    {
                        type: 'icon',
                        icon: 'previtem',
                        name: 'previtem',
                        hideon: 'previtem',
                        hidevalue: false,
                        title: 'Previous Item'
                    },
                    {
                        type: 'icon',
                        icon: 'nextitem',
                        name: 'nextitem',
                        hideon: 'nextitem',
                        hidevalue: false,
                        title: 'Next Item'
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        title: 'Full Screen',
                        hidevalue: false,
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        title: 'Exit Full Screen',
                        hideon: 'fullscreen',
                        hidevalue: false
                    }
                ],
            },
            sectionscomp: {
                type: "sections",
                name: "sectionscomp",
                key: "sectionscomp",
                initRun: true,
                layout: {
                    'Details': {
                        group: [
                            {
                                order: 0,
                                type: 'div',
                                className: 'row col',
                                components: [
                                    {
                                        name: 'Name',
                                        type: 'div',
                                        className: 'col-8',
                                        sub: {
                                            type: 'div',
                                            className: 'col-8',
                                        }
                                    },
                                ],
                            },
                            {
                                order: 1,
                                type: 'div',
                                className: 'row col',
                                components: [
                                    {
                                        name: 'Description',
                                        type: 'div',
                                        className: 'col-10',
                                        sub: {
                                            type: 'div',
                                            className: 'col-10'
                                        }
                                    },
                                ]
                            },
                            {
                                order: 2,
                                type: 'div',
                                className: 'row col',
                                components: [
                                    {
                                        name: 'SubModuleKey',
                                        type: 'div',
                                        className: 'col-6',
                                        sub: {
                                            type: 'div',
                                            className: 'col-6'
                                        }
                                    },
                                    {
                                        name: 'SitePermitTypeId',
                                        type: 'div',
                                        className: 'col-6',
                                        sub: {
                                            type: 'div',
                                            className: 'col-6'
                                        }
                                    },
                                ]
                            },
                            {
                                order: 3,
                                type: 'div',
                                className: 'row col',
                                components: [
                                    {
                                        name: 'Status',
                                        type: 'div',
                                        className: 'col-6',
                                        sub: {
                                            type: 'div',
                                            className: 'col-6'
                                        }
                                    },
                                ]
                            },
                            {
                                order: 4,
                                type: 'div',
                                className: 'row col',
                                components: [{
                                    name: 'CreatedOn',
                                    type: 'div',
                                    className: 'col-6',
                                    sub: {
                                        type: 'div',
                                        className: 'col-6'
                                    }
                                },
                                {
                                    name: 'CreatedByName',
                                    type: 'div',
                                    className: 'col-6',
                                    sub: {
                                        type: 'div',
                                        className: 'col-6'
                                    }
                                },
                                ]
                            },
                            {
                                order: 5,
                                type: 'div',
                                className: 'row col',
                                components: [
                                    {
                                        name: 'UpdatedOn',
                                        type: 'div',
                                        className: 'col-6',
                                        sub: {
                                            type: 'div',
                                            className: 'col-6'
                                        }
                                    },
                                    {
                                        name: 'UpdatedByName',
                                        type: 'div',
                                        className: 'col-6',
                                        sub: {
                                            type: 'div',
                                            className: 'col-6'
                                        }
                                    },]
                            },
                        ],
                    },
                    'Template': {
                        group: [
                            {
                                order: 0,
                                type: 'div',
                                className: 'row',
                                components: [
                                    {
                                        name: 'SideBarTables',
                                        type: 'div',
                                        className: 'col-10',
                                        sub: {
                                            type: 'div',
                                            className: 'col-10',
                                        }
                                    },
                                ],
                            },
                            {
                                order: 1,
                                type: 'div',
                                className: 'row',
                                components: [
                                    {
                                        name: 'TemplateId',
                                        type: 'div',
                                        className: 'col-10',
                                        sub: {
                                            type: 'div',
                                            className: 'col-10',
                                        }
                                    },
                                ],
                            },
                        ],
                    },
                    // 'History': {
                    //     group: [
                    //         {
                    //             order: 0,
                    //             type: 'div',
                    //             className: 'row',
                    //             components: [{
                    //                 name: 'History',
                    //                 type: 'div',
                    //                 className: 'col-12',
                    //                 sub: {
                    //                     type: 'div',
                    //                     className: 'col-12'
                    //                 }
                    //             },]
                    //         },
                    //     ],
                    // }
                },
                aliases: {
                    'editInfo': 'editInfo',
                    'allgriddata': 'allgriddata',
                    'navigateItem': 'navigateItem'
                },
                sharedValues: ['editInfo', 'allgriddata', 'navigateItem', 'navigation', 'edit', 'save', 'createduplicate', 'exitfullscreen', 'fullscreen', 'back'],
                validationMapKeys: [],
                values: {
                    Id: '',
                    Name: '',
                    Description: '',
                    SubModuleKey: '',
                    Status: 'I',
                    SitePermitTypeId: '',
                    UpdatedByName: '',
                    CreatedByName: '',
                    UpdatedOn: '',
                    CreatedOn: '',
                    UpdatedBy: '',
                    CreatedBy: '',
                    TemplateId: '',
                    SideBarTables: '',
                  //  History:''
                },
                fieldsinSectionNames: {
                    Name: 'Details',
                    Description: 'Details',
                    SubModuleKey: 'Details',
                    Status: 'Details',
                    SitePermitTypeId: 'Details',
                    UpdatedByName: 'Details',
                    CreatedByName: 'Details',
                    UpdatedOn: 'Details',
                    CreatedOn: 'Details',
                    UpdatedBy: 'Details',
                    CreatedBy: 'Details',
                    TemplateId: 'Template',
                    SideBarTables: 'Template',
                },

                sections: {
                    type: 'tab',
                    sectionNames: ['Details', 'Template'],
                    steps: ['Details', 'Template' ],
                },
                components: {
                    Name: {
                        "changefieldto": "textfield",
                        "type": "label",
                        custom: true,
                        "key": "Name",
                        "label": "Master Template Name",
                        "name": "Name",
                    },
                    Description: {
                        "changefieldto": "textfield",
                        "type": "label",
                        custom: true,
                        "key": "Description",
                        "label": "Description",
                        "name": "Description",
                         multiline: true,
                    },
                    SubModuleKey: {
                        "changefieldto": "label",
                        "type": "label",
                        custom: true,
                        "key": "SubModuleKey",
                        "label": "Sub Module",
                        "name": "SubModuleKey",
                        extraProps: {
                            transformProps: {
                                label: 'SubModuleName',
                                value: 'Key'
                            },
                        },
                    },//checkauto
                    SitePermitTypeId: {
                        "changefieldto": "label",
                        "type": "label",
                        custom: true,
                        "key": "SitePermitTypeId",
                        "label": "Permit Type",
                        "name": "SitePermitTypeId",
                        extraProps: {
                            transformProps: {
                                label: 'Name',
                                value: 'Id'
                            },
                        },
                    },
                    Status: {
                        "changefieldto": "toggle",
                        "type": "label",
                        custom: true,
                        "key": "Status",
                        "label": "Status",
                        "name": "Status",
                        extraProps: {
                            transformProps: {
                                I: false,
                                A: true,
                                'true': 'A',
                                'false': 'I'
                            },
                        }
                    },
                    CreatedOn: {
                        "type": "label",
                        "key": "CreatedOn",
                        custom: true,
                        "label": "Created On",
                        name: "CreatedOn",
                        changefieldto: "label",
                    },
                    CreatedByName: {
                        "type": "label",
                        custom: true,
                        "key": "CreatedByName",
                        "label": "Created By",
                        name: "CreatedByName",
                        changefieldto: "label",
                    },
                    UpdatedOn: {
                        "type": "label",
                        custom: true,
                        "key": "UpdatedOn",
                        "label": "Updated On",
                        name: "UpdatedOn",
                        changefieldto: "label",
                    },
                    UpdatedByName: {
                        "type": "label",
                        custom: true,
                        "key": "UpdatedByName",
                        "label": "Updated By",
                        name: "UpdatedByName",
                        changefieldto: "label",
                    },
                    SideBarTables: {
                        "changefieldto": "checkauto",
                        "type": "label",
                        custom: true,
                        "key": "SideBarTables",
                        "label": "Record Sidebar List(s)",
                        "name": "SideBarTables",
                        ismulti: true,
                        limitTags: 5,
                        extraProps: {
                            transformProps: {
                                label: 'TableName',
                                value: 'TableName'
                            },
                        },
                    },
                    TemplateId: {
                        "type": "template",
                        custom: true,
                        "key": "template",
                        "label": "Notice Template",
                        name: "TemplateId",
                        changefieldto: "template",
                        customField: true,
                        edit: false,
                        extraProps: {
                            transformProps: {
                                label: 'label',
                                value: 'name'
                            },
                        },
                    },
                    // NoticeHistory: {
                    //     "changefieldto": "grid",
                    //     "key": "NoticeHistory",
                    //     "label": "Details History",
                    //     "name": "NoticeHistory",
                    //     "type": "grid",
                    //     customField: true,
                    //     custom: true,
                    //     values: [],
                    //     headerProps: {
                    //         title: "History",
                    //         components: [

                    //         ],
                    //         actions: [
                    //             {
                    //                 type: 'fab',
                    //                 icon: 'clear',
                    //                 name: 'clear',
                    //                 color: 'primary',
                    //                 title: 'Clear Filters',
                    //                 menucolor: '#22a9b3',
                    //                 hideon: 'clear',
                    //                 hidevalue: false
                    //             },

                    //             {
                    //                 type: 'fab',
                    //                 icon: 'excel',
                    //                 name: 'excel',
                    //                 color: 'primary',
                    //                 title: 'Export to Excel',
                    //                 menucolor: '#c90685',
                    //                 hideon: 'excel',
                    //                 hidevalue: false
                    //             },
                    //         ]
                    //     },
                    //     "columns": [
                    //         {
                    //             "title": "Field Name",
                    //             "field": "FieldLabel",
                    //             "show": true,
                    //             "width": 150,
                    //         },
                    //         {
                    //             "title": "Old Value",
                    //             "field": "OldValue",
                    //             "show": true,
                    //             "width": 150,
                    //         },
                    //         {
                    //             "title": "New Value",
                    //             "field": "NewValue",
                    //             "show": true,
                    //             "width": 150,

                    //         },
                    //         {
                    //             "title": "Updated By",
                    //             "field": "CreatedByName",
                    //             "show": true,
                    //             "width": 150,

                    //         },
                    //         {
                    //             "title": "Updated On",
                    //             "field": "CreatedOn",
                    //             "show": true,
                    //             "width": 150,
                    //             filter: "date",
                    //             format: "{0:d}",
                    //             convert: "MM/DD/YYYY hh:mm:ss A"
                    //         },
                    //     ],
                    //     mapActionsToEffects: {
                    //         load: {
                    //             rules: [{
                    //                 run: {
                    //                     valid: [{
                    //                         type: 'grid',
                    //                         "read": [
                    //                             {
                    //                                 url: getEnvVariable('Application') + `/api/MasterTemplates/History/{Id}?`,
                    //                                 "matchProps": [{
                    //                                     name: "routeid1",
                    //                                     key: "routeid1",
                    //                                 },
                    //                                 {
                    //                                     name: "navigation.Id",
                    //                                     key: "Id",
                    //                                 },
                    //                                 ],
                    //                                 type: 'get',
                    //                                 saveAs: {
                    //                                     key: 'data'
                    //                                 }
                    //                             }
                    //                         ]
                    //                     },
                    //                     ]
                    //                 }
                    //             }]
                    //         },
                    //     }
                    // },
                },
                mapActionsToEffects: {
                    'onLoad': {
                        'init': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'load',
                                                name: 'SubModuleKey',
                                                "read": [
                                                    {
                                                        url: getEnvVariable('MasterAdmin') + `/api/module/${getEnvVariable('ModuleKey')}/submodule/All/Active/IsConfigurable`,
                                                        type: 'get',
                                                        saveAs: {
                                                            key: 'items'
                                                        }
                                                    }
                                                ]
                                            },
                                            {
                                                type: 'load',
                                                name: 'SideBarTables',
                                                "read": [
                                                    {
                                                        url: getEnvVariable('Application') + `/api/SubModuleSideBar/All/{SubModuleKey}`,
                                                        type: 'get',
                                                        "matchProps": [{
                                                            name: "navigation.SubModuleKey",
                                                            key: "SubModuleKey",
                                                        }],
                                                        saveAs: {
                                                            key: 'items'
                                                        }
                                                    }
                                                ]
                                            },
                                            {
                                                type: 'load',
                                                name: 'TemplateId',
                                                "read": [
                                                    {
                                                        url: getEnvVariable('Application') + `/api/BusinessRules/Builder/Template?SitePermitTypeId={SitePermitTypeId}`,
                                                        type: 'get',
                                                        "matchProps": [{
                                                            name: "navigation.SitePermitTypeId",
                                                            key: "SitePermitTypeId",
                                                        }],
                                                        saveAs: {
                                                            key: 'items'
                                                        }
                                                    }
                                                ]
                                            },
                                            {
                                                type: 'load',
                                                name: 'SitePermitTypeId',
                                                "read": [
                                                    {
                                                        url: getEnvVariable('AdminConfig') + `/api/SitePermitTypes/SubModuleKey/Active/{SubModuleKey}`,
                                                        type: 'get',
                                                        "matchProps": [{
                                                            name: "navigation.SubModuleKey",
                                                            key: "SubModuleKey",
                                                        }],
                                                        saveAs: {
                                                            key: 'items'
                                                        }
                                                    }
                                                ]
                                            },
                                            {
                                                type: 'validations',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Application') + `/api/ProjectMessages/RulesByName?name=admin.MasterTemplates`,
                                                        type: 'get',
                                                    }
                                                ],
                                                transformProps: {
                                                    removesubfieldname: getEnvVariable('ModuleKey') + 'ADMIN.MASTERTEMPLATES_'
                                                }
                                            },
                                            {
                                                type: 'businessrules',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Application') + `/api/BusinessRules/Grid/modulemaster.NoticeS`,
                                                        type: 'get',
                                                    }
                                                ],
                                            },
                                        ]
                                    }
                                },

                            ]
                        },


                    },
                    'onChange': {
                        SubModuleKey: {
                            rules: [
                                {
                                    canOverwrite: true,
                                    check: [{
                                        condition: "AND",
                                        rules: [
                                            { name: "SubModuleKey", type: "!=", value: "", rules: [] }]
                                    }],
                                    fields: ["SubModuleKey"],
                                    run: {
                                        type: "check",
                                        valid: [
                                            {
                                                type: 'load',
                                                name: 'SitePermitTypeId',
                                                "read": [
                                                    {
                                                        url: getEnvVariable('AdminConfig') + `/api/SitePermitTypes/SubModuleKey/Active/{SubModuleKey}`,
                                                        type: 'get',
                                                        "matchProps": [{
                                                            name: "navigation.SubModuleKey",
                                                            key: "SubModuleKey",
                                                        }],
                                                        saveAs: {
                                                            key: 'items'
                                                        }
                                                    }
                                                ]
                                            },
                                        ],
                                        invalid: []
                                    },
                                    uncheck: false
                                }
                            ],
                            fields: [
                                'SubModuleKey'
                            ]
                        },
                        'navigation': {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'initial',
                                            "read": [
                                                {
                                                    url: getEnvVariable('TemplateAPI') + `/api/MasterTemplates/{Id}`,
                                                    key: 'save',
                                                    "matchProps": [
                                                        {
                                                            name: "navigation.Id",
                                                            key: "Id",
                                                        },
                                                    ],
                                                    type: 'get',
                                                }
                                            ]
                                        },

                                    ]
                                }
                            }]
                        },
                        'edit': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'edit',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'exitfullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'exitfullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'fullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'fullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'createduplicate': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'createduplicate',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    },
                    'afterSave': {
                        'saveduplicate': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('TemplateAPI') + `/api/MasterTemplates/Add`,
                                                        type: 'post',

                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'save': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('TemplateAPI') + `/api/MasterTemplates/Update`,
                                                        type: 'put',
                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    }
                }

            }
        },

    }
}

export default ViewNotice



