import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Fragment, useEffect, useState } from 'react';
import { getEnvVariable } from '../../../../modules/environmentalList';
import { requestApi } from '../../../modules/form/utilities';
import ConfirmationDialog from '../confirmationPopup';
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
}));

export default function SubModuleCheckboxes(props) {

    const classes = useStyles();
    let resultdata = props.value ? props.value : null
    const [subModuleMenu, setSubModuleMenu] = useState(null);
    let submoduleselection = getEnvVariable('IsSubModuleSelection') == "Y" ? true : false
    let hidesubmodules =  getEnvVariable('HideSubmodules') == "Y" ? true : false
    const [subModuleItems, setSubModuleItems] = useState({});
    const [openConfirmation, setopenConfirmation] = useState(null);
    const [filteredResults, setfilteredResults] = useState(null);
    const [reload, setReload] = useState(false)
    let isconsultantprofile = props && props.options && props.options.isprofile && props.accessPermissions && props.accessPermissions.isConsultant == "Y" ? true : false

    useEffect(() => {
        let SubModuleMenuRead = {
            "get": {
                url: getEnvVariable('MasterAdmin') + `/api/module/` + getEnvVariable('ModuleKey') + `/submodule/All/Active/IsConfigurable`
            }
        };
        requestApi(SubModuleMenuRead, '').then(res => {
            if (res && Array.isArray(res)) {
                setSubModuleMenu(res)
            }
        })
    }, [])

    useEffect(() => {
        let itemresult = []
        if (hidesubmodules && subModuleMenu && subModuleMenu.length>0) {
           subModuleMenu.forEach(element => {
               let obj = { "ModuleKey": getEnvVariable('ModuleKey') }
                       obj['SubmoduleId'] = element.Id
                       itemresult.push(obj);
           });
           props.customnoticesave(itemresult, props.name, 'includeinresults')
       }

        newfun();
    }, [subModuleMenu])

    useEffect(() => {
        newfun();
    }, [resultdata])

    const handleConfirmationCancel = () => {
        setopenConfirmation(false);
        setfilteredResults(null);
    }

    const handleConfirmationChange = (urlinfo) => {
        let moduleaccess = {};
        if (urlinfo == "grant") {
            moduleaccess = {
                "put": {
                    url: getEnvVariable('MasterAdmin') + `/api/CompanyInternal/Access/${props && props.dataItem && props.dataItem.Id ? props.dataItem.Id : props.match.params.routeid}/` + getEnvVariable('ModuleKey')
                }
            };
        }
        else {
            moduleaccess = {
                "put": {
                    url: getEnvVariable('MasterAdmin') + `/api/CompanyInternal/RequestAccess/${props.dataItem && props.dataItem.CompanyId}/` + getEnvVariable('ModuleKey')
                }
            };
        }
        requestApi(moduleaccess, null, filteredResults).then(res => {
            if (res && urlinfo == "grant") {
                let obj = subModuleItems;
                let filteredlist = res.CompaniesModules
                // let arrlist = subModuleMenu || localStorage.getItem('submodule') && JSON.parse(localStorage.getItem('submodule')) ? JSON.parse(localStorage.getItem('submodule')) : []
                let checkboxitems = subModuleMenu.map(x => x.key ? x.key : x.Id);
                // for (const element of checkboxitems) {
                if (filteredlist) {
                    filteredlist.forEach(elements => {
                        if (checkboxitems.includes(elements.SubModuleId)) {
                            obj[elements.SubModuleId] = true
                        }
                        else if (checkboxitems.includes(Number(elements.SubmoduleId))) {
                            obj[elements.SubmoduleId] = true
                        }
                        else {
                            obj[elements.SubModuleId] = false
                        }
                    });
                }
                else {
                    for (const element of checkboxitems) {
                        obj[element] = false
                    }
                }
                setSubModuleItems(obj);

            }
        })
        setopenConfirmation(false);
        setfilteredResults(null);

    }

    const handleLink = (e, id) => {
        setopenConfirmation(true);
        let filteredarr = subModuleMenu.filter(a => a.Id == id);
        setfilteredResults(filteredarr[0]);
    }

    const newfun = () => {
        if (subModuleMenu && subModuleMenu.length > 0) {
            let checkboxitems = subModuleMenu.map(x => x.key ? x.key : x.Id);
            let obj = subModuleItems;
            if (resultdata) {
                resultdata.forEach(elements => {
                    if (checkboxitems.includes(elements.SubModuleId)) {
                        obj[elements.SubModuleId] = true
                    }
                    else if (checkboxitems.includes(Number(elements.SubmoduleId))) {
                        obj[elements.SubmoduleId] = true
                    }
                    else {
                        obj[elements.SubModuleId] = false
                    }


                });
            }
            else {
                for (const element of checkboxitems) {
                    obj[element] = false
                }
            }
            setSubModuleItems(obj);
            setReload(!reload)
        }
    }

    const handleChange = (event) => {
        setSubModuleItems({ ...subModuleItems, [event.target.name]: event.target.checked });
    };

    useEffect(() => {
        if (submoduleselection) {
            let selectesSubModuleItems = Object.keys(subModuleItems).map(function (k) { if (subModuleItems[k] == true) { return k } else return false })
            let subModuleArr = selectesSubModuleItems.filter(Boolean);
            let itemresult = []
            if (!(_.isEmpty(subModuleArr)) && !hidesubmodules) {
                for (const element of subModuleArr) {
                    let obj = { "ModuleKey": getEnvVariable('ModuleKey') }
                    obj['SubmoduleId'] = element
                    itemresult.push(obj);
                }
            }
            if(!hidesubmodules && subModuleMenu && subModuleMenu.length > 0) {
                props.customnoticesave(itemresult, props.name)
            }

        }
    }, [subModuleItems]);

    if (props.edit === false && submoduleselection && !hidesubmodules) {
        return (
            <Fragment>
                {isconsultantprofile && <div>
                <Typography variant={'caption'} className="fw-bold">{props.label}Access To</Typography>
                {subModuleMenu && subModuleMenu.length > 0 && subModuleMenu.map(each => {

                        return (
                            <Fragment>
                                {subModuleItems[each.key || each.Id] && (<Typography className="text-secondary" variant={'body2'} color={'textPrimary'}> {each.title ? each.title : each.DisplayName} </Typography>)}
                            </Fragment>
                        )
                })}</div>
             }
            </Fragment>
        )
    }
    else if (submoduleselection && !hidesubmodules) {
        return (
            <div className={classes.root}>
                {reload}
                <FormControl component="fieldset" className={classes.formControl}>
                    <Typography variant={'caption'} className="text-secondary">{props.label}</Typography>
                    <FormGroup>
                        {subModuleMenu && subModuleMenu.length > 0 && subModuleMenu.map(each => {
                                return (
                                    <Fragment>
                                        {props.options && props.options.islabel && props.options.islabel == true && !isconsultantprofile &&
                                            <div>
                                                {openConfirmation && <ConfirmationDialog value={openConfirmation} title={props.options.titleinfo} labels={['Yes', 'No']} onSubmit={(e) => handleConfirmationChange(props.options.urlinfo)} onClose={handleConfirmationCancel} />}
                                                {subModuleItems[each.key || each.Id] ?
                                                    (<Typography  variant={'body2'} color={'textPrimary'} ><b className="fw-bold" >Access To :</b> <span className="text-secondary"> {each.title ? each.title : each.DisplayName} </span> </Typography>)
                                                    :
                                                    <Button variant={'outlined'} onClick={(e) => handleLink(e, each.Id)} color="primary"> {props.options.labelinfo} {each.DisplayName} </Button>}
                                            </div>}
                                        {!(props.options && props.options.islabel && props.options.islabel == true) && <FormControlLabel
                                            control={<Checkbox checked={subModuleItems[each.key ? each.key : each.Id] ? true : false} onChange={handleChange} name={each.key ? each.key : each.Id} />}
                                            label={<span style={{ fontSize: '14px' }}>{each.title ? each.title : each.DisplayName}</span>}
                                        />}
                                    </Fragment>
                                )
                        })}
                    </FormGroup>
                </FormControl>
            </div>
        );
    }
    else {
        return null
    }
}
