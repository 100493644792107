/**
      *********************************************************
          deprecated
      *********************************************************
**/

import React, { Component, Fragment } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const mapkeyval = {
    N: false,
    Y: true
}
class CCheckbox extends Component {
    constructor(props) {
        super(props);
        // const { name, initialvalue, transformProps } = props.options
        // this.state = {
        //     [name]: mapkeyval[initialvalue] || false
        // };
    }
    componentDidMount() {
        const { options, initialvalue } = this.props
        const { name } = options
        this.setState({
            [name]: mapkeyval[initialvalue]
        })
    }

    handleChange = name => event => {
        const { transformProps } = this.props.options
        this.setState({ [name]: event.target.checked });
        if (this.props.setFormFieldValue) {
            let val
            if (event.target.checked) val = transformProps['checked']
            else val = transformProps['unchecked']
            this.props.setFormFieldValue(name, val)
        }
    };

    render() {
        const { options } = this.props;
        const { label, labelPlacement, color, checked, name, checkboxoutlineblankicon, checkboxIcon, transformProps, compmap, ...otherOptions } = options
        const initialvalue = this.props.initialvalue ? this.props.initialvalue : false
        let otherCustomOptions = {}
        if (checkboxoutlineblankicon) {
            const { options, type } = checkboxoutlineblankicon
            const EachComp = compmap[type]
            otherCustomOptions.icon = <EachComp {...options} />
        }
        if (checkboxIcon) {
            const { options, type } = checkboxIcon
            const EachComp = compmap[type]
            otherCustomOptions.checkedIcon = <EachComp {...options} />
        }
        const { value } = this.props.currentValue || false
        return (
            <Fragment>
                <FormControlLabel
                    control={<Checkbox
                        checked={(transformProps && transformProps[value]) || value || initialvalue}
                        onChange={this.handleChange(name)}
                        //value={value}
                        color={color || 'primary'}
                        {...otherOptions}
                        {...otherCustomOptions}
                    />}
                    label={label}
                    labelPlacement={labelPlacement || 'end'}
                />
            </Fragment>
        )
    }

}

export default CCheckbox;



/**
* {
* type: 'checkbox',
* options:{
* label: '',
        * },
* defaults:{
* theme: 'default'
        * }
        * }
*/

