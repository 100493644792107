import React, { Fragment, useEffect, useRef } from "react"
import settingsgrid from '../../jsons/settings/settingsgrid';
import Container from '../../../core/controls/components/container'


const SettingsContainer = () => {

    return (
        <Fragment>
            <Container {...settingsgrid} />
        </Fragment>
    )
}
export default SettingsContainer