import { Button, Fab, IconButton } from "@material-ui/core"
import React, { useState, useCallback, useEffect } from "react"
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TextBox from "../textbox";
import Dropdown from "../dropdown";
import { getEnvVariable } from "../../../../modules/environmentalList";
import { createRequest, requestApi } from "../../helpers/rest";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const defaultDrawerWidth = 900;
const minDrawerWidth = 700;
const maxDrawerWidth = window.innerWidth - 220;
const fullscreenwidth = window.innerWidth
const checkwidth = 960

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    drawer: {
        flexShrink: 0
    },
    dragger: {
        width: "7px",
        cursor: "ew-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 100,
        //   backgroundColor: "#f4f7f9"
    }
}));

export default function LayerAuthorization(props) {

    const [sidePopUp, setSidepopup] = useState(false);
    let screensize = (props.width === "sm" || props.width === "xs" || props.width === "md") ? false : true
    const [drawerWidth, setDrawerWidth] = useState((props.width === "sm" || props.width === "xs" || props.width === "md") ? fullscreenwidth : defaultDrawerWidth);
    const [fullscreen, setFullScreen] = useState(false)
    const [fullwidth, setFullWidth] = useState(fullscreenwidth)
    const [value, setValue] = React.useState(0);
    const [data, setData] = React.useState(null);
    const [authList, setAuthList] = React.useState(null);
    const [typeList, settypeList] = useState([])
    const [refresh, setRefresh] = useState(false)

    React.useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    });

    const updateWidth = () => {
        if (window.innerWidth < checkwidth) {
            setDrawerWidth(window.innerWidth);
        }
        else {
            setDrawerWidth(defaultDrawerWidth)
        }
    };

    React.useEffect(() => {
        if (!screensize) {
            let width = window.innerWidth
            setDrawerWidth(width);
            setFullWidth(width)
        }
        else {
            setDrawerWidth(defaultDrawerWidth);
            // setFullWidth(defaultDrawerWidth)
        }
    }, [props.width]);


    const handleMouseDown = e => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let newWidth =
            document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        //  const newWidth = e.clientX - document.body.offsetLeft;
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            setDrawerWidth(newWidth);
        }
    }, []);


    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            GetLatestData()
        }
    };

    const changeTab = () => {
        setValue(0);
        setData(null);
        GetLatestData()
    }

    const GetLatestData = () => {
        const business = [
            {
                url: getEnvVariable('Admin') + `/api/LayerAuthentication/All`,
                type: 'get'
            }
        ]
        Promise.all(business.map(each => requestApi(createRequest(each, {}, null)).catch(error => { return error; }))).then(resp => {
            const [authorization] = resp
            setAuthList(authorization)
            setRefresh(!refresh)
        })

    }

    useEffect(() => {
        const business = [
            {
                url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=TOKEN_TYPES`,
                type: 'get'
            }
        ]
        Promise.all(business.map(each => requestApi(createRequest(each, {}, null)).catch(error => { return error; }))).then(resp => {
            const [types] = resp;
            settypeList(types);
            GetLatestData()
        })
    }, [])


    const onAuthChange = (evt) => {
        const { name, value } = evt.target
        let temp = authList.find(each => each.Id === value)
        setData(temp)
        setRefresh(!refresh)
    }

    const classes = useStyles();
    return <>
        {refresh}
        <Button size="small" variant="contained" color="primary" onClick={() => { setSidepopup(!sidePopUp) }}>Token</Button>
        <Drawer anchor={'right'} open={sidePopUp} onClose={() => { setSidepopup(!sidePopUp) }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
            disableBackdropClick
            color="primary"
            PaperProps={{ style: { width: fullscreen ? fullwidth : drawerWidth } }} >
            <div onMouseDown={e => handleMouseDown(e)} className={classes.dragger} />
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar variant="dense">
                        Authorizations
                        <div className={classes.grow} />
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            onClick={() => { setSidepopup(!sidePopUp) }}
                            color="inherit"
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth">
                    <Tab label="Select Authorization" {...a11yProps(0)} />
                    <Tab label="Create Authorization" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <Dropdown className="col-10" label={'Authorizations'} value={(data && data.Id) || ''} handleFieldValue={onAuthChange} items={authList} name={'Authorizations'} extraProps={{
                        transformProps: {
                            label: 'AuthenticationName',
                            value: 'Id'
                        }
                    }} />
                    <div className="pt-4" />
                    {data && Object.keys(data).length > 0 && <Authorization data={data} typeList={typeList} />}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Authorization typeList={typeList} changeTab={changeTab}/>
                </TabPanel>
            </div>
        </Drawer>
    </>
}

function Authorization(props) {
    const { data = {}, typeList = [], changeTab } = props
    const [result, setResult] = useState(data)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        if (result && data && (result.Id !== data.Id)) {
            setResult(data)
            setRefresh(!refresh)
        }
    }, [data])

    const onChange = (evt) => {
        const { name, value } = evt.target
        let temp = result;
        temp[name] = value
        setResult(temp)
        setRefresh(!refresh)
    }

    const handleSave = () => {
        let business = []
        if (result && result.Id) {
            business = [
                {
                    url: getEnvVariable('Admin') + `/api/LayerAuthentication/Update`,
                    type: 'put'
                }
            ]
        }
        else business = [
            {
                url: getEnvVariable('Admin') + `/api/LayerAuthentication/Add`,
                type: 'post'
            }
        ]
        result['Status'] = 'A'
        Promise.all(business.map(each => requestApi(createRequest(each, result, null)).catch(error => { return error; }))).then(resp => {
            const [types] = resp;
            if (result && !result.Id) {
                changeTab()
            }

        })
    }

    return <>
        {refresh}
        <Fab className='float-right py-1' size='small' color='primary' onClick={handleSave}><SaveIcon /></Fab>
        <TextBox className="col-10" label={'Name'} value={result.AuthenticationName} handleFieldValue={onChange} name={'AuthenticationName'} />
        <Dropdown className="col-10" label={'Type'} value={result.AuthenticationType} handleFieldValue={onChange} items={typeList} name={'AuthenticationType'} extraProps={{
            transformProps: {
                label: 'Name',
                value: 'Value'
            }
        }} />
        {result.AuthenticationType === "T" && <TextBox className="col-10" label={'Token'} value={result.Token} handleFieldValue={onChange} name={'Token'} />}
        {result.AuthenticationType === "U" && <>
            <TextBox className="col-10" label={'MapUrl'} value={result.MapUrl} handleFieldValue={onChange} name={'MapUrl'} />
            <TextBox className="col-10" label={'UserName'} value={result.UserName} handleFieldValue={onChange} name={'UserName'} />
            <TextBox className="col-10" label={'Password'} value={result.Password} handleFieldValue={onChange} name={'Password'} />
        </>
        }
        {/* <Switch label={'Status'} /> */}
    </>
}