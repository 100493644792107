import Skeleton from '@material-ui/lab/Skeleton';
import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import Holidays from '../controls/wrappers/holidays/index';
import MasterTemplates from '../controls/wrappers/mastertemplate';
import Notice from '../controls/wrappers/notice';
import Settings from '../controls/wrappers/settings/settingscontainer';
import SidebarBr from '../controls/wrappers/sidebarbr';
import Alert from '../core/controls/components/alert';
import ConfirmationDialog from '../core/controls/components/confirmationPopup';
import HelpContext from '../core/controls/components/helpContext';
import Loader from '../core/controls/components/loading';
import RecordEditor from '../core/controls/components/recordeditors';
import DefaultSettings from '../core/controls/components/settings';
import consultantgrid from '../json/consultant/consultantgrid';
import Search from '../modules/search/index_tsp';
import Authorizations from '../controls/wrappers/authorizations';

const CreateRecord = lazy(() => import('../controls/wrappers/record/createrecord'));
const EditRecord = lazy(() => import('../controls/wrappers/record/editrecord'));
const CGrid = lazy(() => import('../core/modules/Grid/cgrid'));
const ProjectDownloads = lazy(() => import('../controls/wrappers/projectdownloads/projectdownloadscontainer'));

export default function WRSRouteControls(props) {

    let userProps = {
        'AccessPortal': props.AccessPortal,
        'accessPermissions': props.accessPermissions,
        'accessLevelPermissions': props.accessLevelPermissions
    }
    let compmap = props.compmap
    const helpContext = useRef(null);
    const loaderView = React.useRef(null);
    const alertView = React.useRef(null);
    const noticeView = useRef(null)
    const confirmationView = useRef(null)

    useEffect(() => {
        if (loaderView && loaderView.current)
            DefaultSettings.setLoader(loaderView.current);
        if (alertView && alertView.current)
            DefaultSettings.setAlert(alertView.current);
        if (helpContext && helpContext.current)
            DefaultSettings.setDrawer(helpContext.current);
        if (noticeView && noticeView.current)
            DefaultSettings.setNotices(noticeView.current);
        if (confirmationView && confirmationView.current)
            DefaultSettings.setConfirmation(confirmationView.current);
    }, [loaderView, alertView, helpContext, noticeView, confirmationView])



    return (<Suspense fallback={<div className="col">
        {/* <Backdrop open={true} /> */}
        <Skeleton variant="text" width='100%' height={50} />
        <Skeleton variant="rect" width='100%' height={800} />
    </div>}>
        {/* <Router> */}
            <Loader ref={loaderView} />
            <Alert ref={alertView} />
            <HelpContext ref={helpContext} />
            <RecordEditor ref={noticeView} />
            <ConfirmationDialog ref={confirmationView} />
            <Switch>
                <Route exact strict path='/Records/:key/new' render={() => <CreateRecord {...userProps} compmap={compmap} />} />
                <Route exact strict path='/Records/:key/:routeid/:routeid1/:sidebar?/:id?' render={() => <EditRecord userProps={userProps} compmap={compmap} />} />
                <Route exact strict path='/Administration/settings/:TableName?' component={Settings} />
                <Route exact strict path='/Administration/noticestypes/:mid?' component={Notice} />
                <Route exact strict path='/Administration/masternotices/:id?' component={MasterTemplates} />
                <Route exact strict path='/Administration/sidebarbr/:id?' component={SidebarBr} />
                <Route exact strict path='/Administration/projectdownloads' component={ProjectDownloads} />
                <Route exact strict path='/Administration/holidays/:id?' component={Holidays} />
                <Route exact strict path='/search' component={Search} />
                <Route exact strict path='/Administration/authorizations/:id?' component={Authorizations} />
                <Route exact strict path='/Administration/consultants/:Id?' render={() => <CGrid compmap={compmap} components={props.components}
                    {...userProps} {...consultantgrid} />} />
            </Switch>
        {/* </Router> */}
    </Suspense>
    );
}