import React from 'react';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

export default function Unauthorize() {
    const user = JSON.parse(localStorage.getItem('profile')).name


    return (
        <div className="eps-content-center " >
            <div className="row">
                <div className="col-sm-5">
                    <div className="text-center  eps-content-center">
                        <Icon className="text-center " style={{ fontSize: '12rem', color: 'red' }}>search_off</Icon></div>
                </div>
                <div className="col-sm-7">
                    <h1 className="text-center" style={{ fontSize: '15rem' }}> 404 </h1>
                    <h2 className="text-secondary text-center">{`PAGE NOT FOUND`} </h2>
                    <div className="text-center p-2">
                        <small> Please return to the homepage    <Button variant="contained" size="small" color="primary" href='#' target="_self" >Home</Button></small>
                    </div>
                </div>
            </div>


        </div>

    );
}
