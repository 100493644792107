
import React, { Fragment } from 'react';
import { GetData, PostData, PutData } from '../../core/controls/helpers/requests';
import BreadCrumb from '../../core/modules/breadcrumbs';
import { getEnvVariable } from '../../modules/environmentalList';
import CFormDropDown from '../../core/modules/form/cformdropdown';
import { getToken } from '../../core/modules/header';
import KendoChart from '../chart/kendochart';
import KendoLine from './kendoline';
import { getData } from '../../core/modules/rest';
import Label from '../../core/controls/components/label';
import { e } from 'mathjs';
const siteTransformProps = {
  "value": "Id",
  "name": "Name",
  "key": "Id"
}
const reportsTransformProps = {
  "value": "Id",
  "name": "Name",
  "key": "Id"
}
const permittypeTransformProps = {
  "value": "Id",
  "name": "Name",
  "key": "Id"
}
const monthsTransformProps = {
  "value": "Id",
  "name": "Name",
  "key": "Id"
}
const yearTransformProps = {
  "value": "Id",
  "name": "Name",
  "key": "Id"
}
const contractorJson = {
  "value": "Id",
  "name": "Name",
  "key": "Id"
}
const reportsJson = [
  // {
  //   "Id":"1",
  //   "Name":"Pending Disposal By User"
  // },
  {
    "Id": "2",
    "Name": "Pending Disposal By County"
  },
  {
    "Id": "3",
    "Name": "Performance of Contractors  "
  },
  {
    "Id": "4",
    "Name": "Performance of County"
  },
  {
    "Id": "5",
    "Name": "Performance County As Contractors"
  },
  {
    "Id": "6",
    "Name": "Pending Disposal by Counties"
  }
]
const monthsJson = [
  {
    "Id": "1",
    "Name": "January"
  },
  {
    "Id": "2",
    "Name": "Feburary"
  },
  {
    "Id": "3",
    "Name": "March"
  },
  {
    "Id": "4",
    "Name": "April"
  },
  {
    "Id": "5",
    "Name": "May"
  },
  {
    "Id": "6",
    "Name": "June"
  },
  {
    "Id": "7",
    "Name": "July"
  },
  {
    "Id": "8",
    "Name": "August"
  },
  {
    "Id": "9",
    "Name": "September"
  },
  {
    "Id": "10",
    "Name": "October"
  },
  {
    "Id": "11",
    "Name": "November"
  },
  {
    "Id": "12",
    "Name": "December"
  }
]
class DashboardChart extends React.Component {
  constructor(props) {
    super(props);
    this.token = getToken()
    this.state = {
      chartApplicationData: [],
      chartMonthApplicationData: [],
      chartPermitData: [],
      site: 1,
      sitesJson: [],
      permitType: 1,
      month: monthsJson[5].Id,
      permitTypeJson: [],
      monthsJson: monthsJson,
      reports: props.accessPermissions.isAdmin == "Y" ? 2 : 1,
      reportsJson: reportsJson,
      contractor: null,
      contractorJson: [],
      contractors: [],
      contractorChartData: [],
      contractorperformaceData: [],
      // activeusersList:{}
    }
  }
  componentDidMount() {
    this.loadInitialChart();
  }
  contractorChart = (body, targetval) => {
    const body2 = {
      // "SITEVAL": this.state.site,
      "ASSIGNED_TO": this.state.permitType,
      // "MONTHVAL": parseInt(this.state.month),
    }
    let reportkey = targetval == "1" ? "PendingDisposalByUserByCounty" : "PerformanceContractorsByCounty"
    const url = getEnvVariable('Report') + `/api/ReportQuery/QueryLoad`
    const queryParam = `kendoGrid=N&reportkey=` + reportkey.replace(/['"]+/g, '');
    // let bodyval = targetval == "1" ? body2 : body
    this.fetchData('POST', url, queryParam, body)
      .then(data => {
        let result = [];
        if (Array.isArray(data) && data.length > 0) {
          // let temp = data.filter(res => {
          //   let siteid = parseInt(res.ID)
          //   if (this.state.site == siteid)
          //     return res
          // })
          let avoidkeys = ["ID", "Name", "UserType"]
          data.forEach(e => {
            avoidkeys.forEach(element => {
              delete e[element]
            })
          })
          data.forEach(e => {
            result.push({
              "share": e["Total"],
              "kind": e["County"]
            })
          })
          this.setState({
            contractorperformaceData: result,
            monthdisplay: true,
            //monthName: monthName
          })
        }
        else {
          let result = [{
            "share": "0",
            "kind": "No Data"
          }]
          this.setState({
            contractorperformaceData: result,
            monthdisplay: true,
            // contractor:username.Id
            //monthName: monthName
          })
        }
        // data && data.length > 0 && data.map(res => {
        //   result.push(res.SatusCount)
        // })
        this.setState({
          contractorperformaceData: result,
        })
      })
  }
  performanceReport = (body, value) => {
    // var username = this.state.contractors[0]
    const body2 = {
      "SITEVAL": this.state.site,
      // "C_NAME":body !=null ? body.C_NAME : username.Name,
      // "MONTHVAL": parseInt(this.state.month),
      // "YEARVAL": this.state.year || new Date().getFullYear()
    }
    var bodytype = body != null ? body : body2
    var reportkey = value == "3" ? "PerformanceContractors" : (value == "4" ? "PerformanceCounty" : "PerformanceCountyAsContractors")
    const url = getEnvVariable('Report') + `/api/ReportQuery/QueryLoad`
    const queryParam = `kendoGrid=N&reportkey=` + reportkey.replace(/['"]+/g, '');
    this.fetchData('POST', url, queryParam, bodytype)
      .then(data => {
        let result = [];
        if (Array.isArray(data) && data.length == 1) {
          let item = []
          let avoidkeys = ["ID", "Name", "UserType", "County"]
          data.forEach(e => {
            avoidkeys.forEach(element => {
              delete e[element]
            })
          })
          data.map(e => {
            Object.keys(e).map(function (key) {
              result.push({
                "share": e[key],
                "kind": key
              })
            });

          })
          this.setState({
            chartMonthApplicationData: result,
            monthdisplay: true,
            // contractor:username.Id
            //monthName: monthName
          })
        }
        else if (Array.isArray(data) && data.length > 1) {
          let avoidkeys = ["ID", "Name", "UserType", "County"]
          data.forEach(e => {
            avoidkeys.forEach(element => {
              delete e[element]
            })
          })
          var tempdata = []
          data.forEach(e => {
            for (let [key, value] of Object.entries(e))
              if (tempdata[key]) {
                tempdata[key] += value;
              } else {
                tempdata[key] = value;
              }
          });
          var temp = []
          Object.keys(tempdata).map(function (key) {
            temp.push({
              [key]:
                tempdata[key]
            })
          });
          temp.forEach(e => {
            Object.keys(e).map(function (key) {
              result.push({
                "share": e[key],
                "kind": key
              })
            })
          })
          this.setState({
            chartMonthApplicationData: result,
            monthdisplay: true,
            // contractor:username.Id
            //monthName: monthName
          })
        }
        else {
          result = [{
            "share": "0",
            "kind": "No Data"
          }]
          this.setState({
            chartMonthApplicationData: result,
            monthdisplay: true,
            // contractor:username.Id
            //monthName: monthName
          })
        }
      })
  }
  loadInitialApplicationChart = (body, reportno) => {
    // const url = getEnvVariable('Report') + `/api/ReportQuery/QueryLoad`
    // const queryParam = `kendoGrid=N&reportkey=DASHBOARD_SUMMARY_APPLICATIONS_YEAR`;
    // //this.setState({ chartApplicationData : [0,0,0,0,0,0,0,0,0,0,0,10] })
    // this.fetchData('POST', url, queryParam, body)
    //   .then(data => {
    //     let result = [];
    //     data && data.length > 0 && data.map(res => {
    //       result.push(res.SatusCount)
    //     })
    //     this.setState({
    //       chartApplicationData: result,
    //     })
    //   })
    function groupby(data, name) {
      return data.reduce(function (memo, x) {
        if (!memo[x[name]]) {
          memo[x[name]] = [];
        }
        memo[x[name]].push(x);
        return memo;
      }, {});
    }
    let reportkey = reportno == "6" ? "PendingDisposalByCounties" : this.state.reports == "1" ? "PendingDisposalByUser" : "PendingDisposalByCounty"
    const url2 = getEnvVariable('Report') + `/api/ReportQuery/QueryLoad`
    const queryParam2 = `kendoGrid=N&reportkey=` + reportkey.replace(/['"]+/g, '');
    const body2 = reportno == "1" ? { "ASSIGNED_TO": this.props.accessPermissions.userId, } : reportno == "2" ? { "SITEVAL": this.state.site } : body
    this.fetchData('POST', url2, queryParam2, body2)
      .then(data => {
        if (Array.isArray(data)) {
          let item = []
          let avoidkeys
          // let temp = data.filter(res => {
          //   let siteid = parseInt(res.ID)
          //   if (this.state.site == siteid)
          //     return res
          // })
          if (reportno == "6") {
            avoidkeys = ["AID"]
            data.map(e => {
              avoidkeys.forEach(element => {
                delete e[element]
              })
            })
            const groupbycounty = groupby(data, "County")
            const keys = Object.keys(groupbycounty);
            var output = [];
            keys.forEach(key => {
              //merge using reduce
              const out = groupbycounty[key].reduce((acc, current) => {
                return {
                  County: current.County,
                  Total: acc.Total + current.Total
                }
              });
              output.push(out);
            });
            output.forEach(e => {
              item.push({
                "share": e["Total"],
                "kind": e["County"]
              })
            })
            this.setState({
              chartMonthApplicationData: item,
              monthdisplay: true,
              reports: reportno
              //monthName: monthName
            })
          }
          else if (reportno != "6") {
            avoidkeys = this.state.reports == "1" ? ["AID"] : ["ID", "County", "Permittypeid"]
            data.map(e => {
              avoidkeys.forEach(element => {
                delete e[element]
              })
            })
            data.map(e => {
              Object.keys(e).map(function (key) {
                item.push({
                  "share": e[key],
                  "kind": key
                })
              });

            })
            let pending = this.state.reports == "1" ? this.contractorChart(body2, this.state.reports) : null



            // if (data[0]["StatusSettings"]) {
            //   let chartlabels = data[0]["StatusSettings"]
            //   let arrchartlabels = chartlabels.split(',')
            //   if (arrchartlabels.length > 0) {
            //     arrchartlabels.forEach(element => {
            //       item.push({
            //         "share": data[0][element],
            //         "kind": this.chartLegendName(element)
            //       })
            //     });
            //   }
            // } 
            // else {
            //   item = [
            //     {
            //       "share": data[0]["DRAFT"],
            //       "kind": "Draft"
            //     },
            //     {
            //       "share": data[0]["NOT_CLEARED"],
            //       "kind": "Not Cleared"
            //     },
            //     {
            //       "share": data[0]["CLOSED"],
            //       "kind": "Closed"
            //     },
            //     {
            //       "share": data[0]["CLEARED"],
            //       "kind": "Cleared"
            //     },
            //     {
            //       "share": data[0]["FORWARDED_TO_COUNTY"],
            //       "kind": "Forwarded To County"
            //     }
            //   ]
            // }
            this.setState({
              chartMonthApplicationData: item,
              monthdisplay: true,
              reports: this.state.reports
              //monthName: monthName
            })
          }
        }
        else {
          let item = [{
            "share": "0",
            "kind": "No Data"
          }]
          this.setState({
            chartMonthApplicationData: item,
            monthdisplay: true,
            //monthName: monthName
          })
        }
      })
  }
  chartLegendName = (name) => {
    name = name.toLowerCase().replaceAll("_", " ")
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  monthApplicationChart = (month, monthName) => {
    const body = {
      "SITEVAL": this.state.site,
      "PERMITVAL": this.state.permitType.PermitTypeId,
      "MONTHVAL": parseInt(month),
      "YEARVAL": this.state.year || new Date().getFullYear()
    }
    const url = getEnvVariable('Report') + `/api/ReportQuery/QueryLoad`
    const queryParam = `kendoGrid=N&reportkey=DASHBOARD_SUMMARY_APPLICATIONS`;
    this.fetchData('POST', url, queryParam, body)
      .then(data => {
        if (Array.isArray(data)) {
          let item = []
          if (data[0]["StatusSettings"]) {
            let chartlabels = data[0]["StatusSettings"]
            let arrchartlabels = chartlabels.split(',')
            if (arrchartlabels.length > 0) {
              arrchartlabels.forEach(element => {
                item.push({
                  "share": data[0][element],
                  "kind": this.chartLegendName(element)
                })
              });
            }
          } else {
            item = [
              {
                "share": data[0]["DRAFT"],
                "kind": "Draft"
              },
              {
                "share": data[0]["NOT_CLEARED"],
                "kind": "Not Cleared"
              },
              {
                "share": data[0]["CLOSED"],
                "kind": "Closed"
              },
              {
                "share": data[0]["CLEARED"],
                "kind": "Cleared"
              },
              {
                "share": data[0]["FORWARDED_TO_COUNTY"],
                "kind": "Forwarded To County"
              }
            ]
          }
          this.setState({
            chartMonthApplicationData: item,
            monthdisplay: true,
            monthName: monthName
          })
        }
        else {
          let item = [{
            "share": "0",
            "kind": "No Data"
          }]
          this.setState({
            chartMonthApplicationData: item,
            monthdisplay: true,
            monthName: monthName
          })
        }
      })
  }



  loadInitialChart = () => {
    const sitedata = this.fetchData('GET', getEnvVariable('Admin') + `/api/Sites/` + getEnvVariable('ModuleKey') + `/All/Active`, null, null)
    const permittypedata = this.fetchData('GET', getEnvVariable('AdminConfig') + `/api/SitePermitTypes/All`, null, null)
    Promise.all([sitedata, permittypedata]).then(data => {
      const sites = data[0]
      const permittypes = data[1];
      let initalpermit = permittypes.SitePermitTypesConfig.filter(res => {
        if (sites[0].Id == res.SiteId)
          return res
      })
      const body = {
        "SITEVAL": sites[0].Id,
        "PERMITVAL": permittypes.PermitTypeId,
        "YEARVAL": this.state.year || new Date().getFullYear()
      }
      this.loadInitialApplicationChart(body)
      //  this.loadInitialPermitChart(body)
      this.setState({
        sitesJson: data[0],
        site: sites[0].Id,
        permitTypeJson: Array(permittypes),//data[1],
        permitType: permittypes.PermitTypeId,
        // monthdisplay: false,
        permitsAll: data[1]
      })
    })
    //All Active Contractors
    var users = this.fetchData('GET', getEnvVariable('MasterAdmin') + `/api/WRSInternalUser/All?ModuleKey=` + getEnvVariable('ModuleKey'), null, null)
    Promise.all([users]).then(data => {
      var userlist = data[0].filter(e =>
        e.Status == "A" && e.IsAdmin != "Y" && e.companyId != null
      )
      var map = new Map();
      for (let d of userlist) {
        map.set(d["companyId"], d);
      }
      var iteratorValues = map.values();
      var uniquecontractors = [...iteratorValues];

      this.setState({
        contractors: uniquecontractors,
        contractor: uniquecontractors[0].Id
      })
    })

    // var userslist = this.fetchData('GET',getEnvVariable('MasterAdmin') + `/api/`+getEnvVariable('ModuleKey')+`InternalUser/All?ModuleKey=`+getEnvVariable('ModuleKey'), null, null)
    // Promise.all([userslist].then(data => {
    //     var Usersdata = data
    //     this.setState({
    //       activeusersList:Usersdata
    //     })
    // }))
  }
  handleContractorChange = (event) => {
    var targetval = parseInt(event.target.value)
    var username = this.state.contractors.filter(e => e.Id == targetval)
    const body = {
      "C_NAME": username[0].Name
    }
    let value = username[0].UserType == "C" ? "3" : username[0].UserType == "CO" ? "4" : "5"
    this.performanceReport(body, value);
    this.contractorChart(body, value);
    this.setState({
      contractor: targetval
    })
  }

  handleReportChange = (event) => {

    var applicant = this.state.contractors;
    var applicant = event.target.value == "3" ? applicant.filter(e => e.UserType == "C") : applicant
    var body = {}
    if (event.target.value == "3" && applicant.length > 0) {
      const body = {
        "C_NAME": applicant[0].Name

      }
      this.setState({
        contractor: applicant[0].Id,
        contractors: applicant,
        contractorJson: applicant,
        reports: event.target.value
      })
      this.performanceReport(body, event.target.value)
      this.contractorChart(body, event.target.value)
    }
    else {
      this.setState({
        reports: event.target.value
      })
      var reportype = event.target.value ? event.target.value == "1" || event.target.value == "2" || event.target.value == "6" ? this.loadInitialApplicationChart(null, event.target.value) : this.performanceReport(null, event.target.value) : this.loadInitialApplicationChart()
    }


  }


  handleSiteDropdownChange = (event) => {
    const { permitsAll } = this.state;
    let initalpermit = permitsAll.SitePermitTypesConfig.filter(res => {
      if (event.target.value == res.SiteId)
        return res
    })
    this.setState({
      site: event.target.value,
      permitTypeJson: initalpermit,
      year: this.state.year,
      monthdisplay: false,
      permitType: (initalpermit && initalpermit.length > 0) ? initalpermit[0].SitePermitTypeId : '',
    }, () => {
      const body = {
        "SITEVAL": event.target.value,
        "PERMITVAL": this.state.permitType,
        //    "MONTHVAL": this.state.month,
        "YEARVAL": this.state.year || new Date().getFullYear(),
      }
      var temp = this.state.reports == "4" || this.state.reports == "5" ? this.performanceReport(null, this.state.reports) : this.loadInitialApplicationChart(body)
    })
  }
  handlePermitTypeDropdownChange = (event) => {
    let selectedpermit = this.state.permitTypeJson.filter(e => {
      if (event.target.value == e.Id)
        return e
    })
    this.setState({
      permitValue: event.target.value, monthdisplay: false, permitType: selectedpermit[0].PermitTypeId
    })
    const body = {
      "SITEVAL": this.state.site,
      "PERMITVAL": selectedpermit[0].PermitTypeId,
      "YEARVAL": this.state.year || new Date().getFullYear()
    }
    this.loadInitialApplicationChart(body)
    //  this.loadInitialPermitChart(body)
  }
  handleMonthDropdownChange = (event) => {
    this.setState({
      year: event.target.value
    })
    const body = {
      "SITEVAL": this.state.site,
      "PERMITVAL": this.state.permitType,
      "MONTHVAL": event.target.value,
      "YEARVAL": this.state.year || new Date().getFullYear()
    }
    this.loadInitialApplicationChart(body)
    // this.loadInitialPermitChart(body)
  }
  handleYearDropdownChange = (event) => {
    this.setState({
      year: event.target.value,
      monthdisplay: false
    }, () => {
      const body = {
        "SITEVAL": this.state.site,
        "PERMITVAL": this.state.permitType.PermitTypeId,
        "YEARVAL": this.state.year
      }
      this.loadInitialApplicationChart(body)
    })
    //  this.loadInitialPermitChart(body)
  }
  fetchData(requestType, url, queryParam, body) {
    const queryStr = queryParam != null ? `${url}?${queryParam}` : `${url}`
    if (requestType == "GET") {
      return GetData(queryStr);
    }
    else if (requestType == "POST") {
      return PostData(queryStr, body, true);
    }
    else if (requestType == "PUT")
      return PutData(queryStr, body, true);
  }
  getYears = () => {
    let currentyear = new Date().getFullYear();
    let yearlist = [];
    while (yearlist.length < 6) {
      yearlist.push({ Id: currentyear + 1, Name: currentyear + 1 });
      currentyear--;
    }
    // this.setState({ year: currentyear })
    return yearlist;
  }
  handlemonthval = (props) => {
    console.log(props);
    monthsJson.map(res => {
      if (res.Name == props) {
        this.monthApplicationChart(res.Id, res.Name);
        this.loadInitialPermitChart(res.Id)
      }
    })
  }
  render() {
    let yearlist = this.getYears();
    const { monthdisplay } = this.state
    const { options } = this.props;
    const { headerProps } = options
    return (
      <Fragment>
        <div className="col pt-1 m-2">
          <BreadCrumb breadcrumbs={headerProps.breadcrumbs} /></div>
        <div className="d-flex bd-highlight mb-3">
          {this.props.accessPermissions.isAdmin !== "Y" && <div className="p-2 bd-highlight">
            <Label label="Pending Disposal Requests" />
          </div>}
          {this.props.accessPermissions.isAdmin == "Y" && <div className="p-2 bd-highlight">
            <CFormDropDown value={this.state.reports} dropdownvalues={this.state.reportsJson} label="Report Type" transformProps={reportsTransformProps} handleFieldChange={this.handleReportChange} />
          </div>}
          {this.props.accessPermissions.isAdmin == "Y" && (this.state.reports == "2" || this.state.reports == "4" || this.state.reports == "5") && <div className="p-2 bd-highlight">
            <CFormDropDown value={this.state.site || 2} dropdownvalues={this.state.sitesJson} label="County" transformProps={siteTransformProps} handleFieldChange={this.handleSiteDropdownChange} />
          </div>}
          {this.props.accessPermissions.isAdmin == "Y" && (this.state.reports == "3") && <div className="p-2 bd-highlight">
            <CFormDropDown value={this.state.contractor || null} dropdownvalues={this.state.contractors} label="Contractor" transformProps={contractorJson} handleFieldChange={this.handleContractorChange} />
          </div>}
        </div>
        <div className="d-flex bd-highlight mb-3">
          {<div className="p-2 bd-highlight col-6">
            <KendoChart data={this.state.chartMonthApplicationData} reportval={this.handleReportChange} />
          </div>
          }
          {(this.state.reports == "1" || this.state.reports == "3") && <div className="p-2 bd-highlight">
            <KendoChart data={this.state.contractorperformaceData} reportval={this.handleContractorChange} />
          </div>
          }
        </div>
      </Fragment >
    )
  }
}
//monthdisplay ? "col-sm-6" :
export default DashboardChart
