import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { GetData, PostData, PutData } from '../../core/controls/helpers/requests';
import { snackbarMessage } from '../../core/actions';
import { getEnvVariable } from '../environmentalList';
import FormHeader from '../../core/modules/form/cformheader';
import { requestApi } from '../../core/modules/form/utilities';
import atComponents from '../../json/common/attachments/attachmentComponents';
import ExtendedAttachment from './attachmentextend';

export const fetchData = (requestType, url, queryParam, body) => {
    const queryStr = queryParam != null ? `${url}?${queryParam}` : `${url}`
    if (requestType == "GET") {
        return GetData(queryStr);
    }
    else if (requestType == "POST") {
        return PostData(queryStr, body);
    }
    else if (requestType == "PUT")
        return PutData(queryStr, body);
}

const addbreadcrumbs = [
    { "link": "Add Attachments", "materialicon": "attach_file", className: 'eps-breadcrumb-item', class: 'text-light' }
]

const editbreadcrumbs = [
    { "link": "Edit Attachments", "materialicon": "attach_file", className: 'eps-breadcrumb-item', class: 'text-light' }
]


class FileAttachments extends Component {
    constructor(props) {
        super(props)
        const { read, update } = props.options.crud
        let saveUrl = '', removeUrl = '';
        console.log("here")
        if (update) {
            const { post } = update
            const { url, routeProps, matchProps } = post
            saveUrl = url
            removeUrl = url;
            routeProps.forEach(each => {
                const { value } = each
                saveUrl.replace(`{${value}}`, this.props.match.params[value])
            });
            matchProps.forEach(each => {
                const { value } = each
                saveUrl.replace(`{${value}}`, this.props[value])
            });
        }
        this.state = {
            AttachmentTypeId: this.props.AttachmentTypeId || 0,
            Id: 0,
            attachmentTypedd: [],
            saveUrl: saveUrl || '',
            removeUrl: removeUrl || '',
            parentId: this.props.parentId || this.props.match.params.routeid1 || this.props.customprops,
            uploadedAttachments: [],
            updateAttachment: this.props.options.updateAttachment || false,
            selecttype: '',
            snackbarmessage: '',
            passresults: null,
            editformat: false,
            noteWizard: '',
            attachmentTypeWizard: '',
            wizardView: false,
            docmissing: false,
            typemissing: false,
            urlmissing: false,
            Attachemntresults: null,
            urlhelpertext: null


        }
        this.componentsArray = { ...atComponents };
        this.url1 = getEnvVariable('Application') + `/api/Attachments/${this.props.match.params.routeid1}/attachment/${this.props.match.params.routeid2}`;
        this.url2 = getEnvVariable('Admin') + `/api/AttachmentType/All/Active`;
        this.LayoutForm = React.createRef()
    }

    componentDidMount() {
        this.didmountinitialload();
    }

    didmountinitialload() {
        const { options, components, extraProps, dataItem } = this.props
        const { crud, validations } = options ? options : {}
        const { read } = crud ? crud : {}
        const { get } = read ? read : {}
        const { routeProps, hidepath } = get ? get : {}
        // if (this.props.match.path === '/Administration/applicationreview/:routeid/:routeid1/attachments/view/:routeid2' || this.props.options.ViewWizardAttachment) {
        //     requestApi({ get: { url: getEnvVariable('Application')+`/api/Attachments/${this.props.match.params.routeid1 || this.state.parentId || this.props.customprops}/attachment/${this.props.match.params.routeid2 || dataItem.Id}` } }, this.props.match.params.routeid2, null).then(results => {
        //         this.setState({
        //             passresults: results, wizardView: true,
        //         })
        //     })
        // }

        if (this.props.options.updateAttachment) {
            requestApi({ get: { url: getEnvVariable('Application') + `/api/Attachments/${this.props.match.params.routeid1 || this.state.parentId || this.props.customprops}/attachment/${this.props.match.params.routeid2 || this.props.dataItem.Id}` } }, this.props.dataItem.Id, null).then(results => {
                this.setState({
                    passresults: results, wizardView: true
                })
            })
        }

    }

    handleEdit = () => {
        this.setState({ editformat: true });
    }
    handleBack = () => {
        this.props.onClose();
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dataItem != this.props.dataItem) {
            this.didmountinitialload();
        }
    }

    handleSave = () => {
        const { options, customprops, formgridadd, WizardAttachment } = this.props;
        const { crud } = options;
        const { read, update } = crud;
        const { passresults } = this.state
        let editorValResults = this.state.Attachemntresults

        if (editorValResults == null) {
            this.setState({ typemissing: true })
        }
        else if (editorValResults && editorValResults.AttachmentDetails && editorValResults.AttachmentDetails.length > 0 && editorValResults.AttachmentDetails[0].AttachmentType && editorValResults.AttachmentDetails[0].AttachmentType === 'L' && (editorValResults.AttachmentDetails[0].externallink === null || editorValResults.AttachmentDetails[0].externallink === undefined || editorValResults.AttachmentDetails[0].externallink === "")) {
            this.setState({ urlmissing: true, docmissing: false })
        }
        else if (editorValResults && editorValResults.AttachmentDetails && editorValResults.AttachmentDetails.length == 0) {
            this.setState({ docmissing: true, urlmissing: false, urlhelpertext: null })
        }
        else if (editorValResults && editorValResults.AttachmentDetails && editorValResults.AttachmentDetails.length > 0 && editorValResults.AttachmentDetails[0].AttachmentType && editorValResults.AttachmentDetails[0].AttachmentType === 'U') {
            let attach = editorValResults.AttachmentDetails;
            let attachcount = editorValResults.AttachmentDetails.length
            let inactivecount = attach.filter((obj) => obj.Status === "I").length;
            if (attachcount == inactivecount) {
                this.setState({ docmissing: true, urlmissing: false, urlhelpertext: null })
            }
            else {
                this.handlefinalSave();
            }
        }
        else {
            this.handlefinalSave();
        }
    }

    handlefinalSave = () => {
        const { options, customprops, formgridadd, WizardAttachment } = this.props;
        const { crud } = options;
        const { read, update } = crud;
        const { passresults } = this.state
        let editorValResults = this.state.Attachemntresults

        if (editorValResults && editorValResults.AttachmentTypeId && !this.state.urlhelpertext && !this.state.docmissing && !this.state.urlmissing) {
            //editorValResults.AttachmentDetails = editorValResults
            if (editorValResults && this.props.options.updateAttachment) {
                let dfinalresults = { ...passresults, ...editorValResults }
                requestApi({ post: { url: getEnvVariable('Application') + `/api/Attachments/${this.state.parentId}/UploadFiles` } }, null, dfinalresults)
                    .then(res => {
                        this.setState({ AttachmentTypeId: dfinalresults.AttachmentTypeId })
                    })
            }
            else if (editorValResults) {
                requestApi({ post: { url: getEnvVariable('Application') + `/api/Attachments/${this.state.parentId}/UploadFiles` } }, null, editorValResults)
                    .then(res => {
                        this.setState({ AttachmentTypeId: editorValResults.AttachmentTypeId }, () => {
                            this.props.onClose();
                        })
                    })
            }
        }
        // else this.setState({ customSave: true })

    }

    getState = (state) => { // this will trigger always
        let formresults = { Notes: state.results.Notes, AttachmentTypeId: (state.results.AttachmentTypeId) || null, Id: (state.results.Id) }
        this.setState({ finalresults: formresults })
    }


    handleCustomSave = () => {
        this.setState({ customSave: false })
    }

    getallresultsInfo = (files, attachment) => {
        //console.log(state);


        const Notes = attachment.notesVal
        let AttachmentDetails = files
        if (files && files[0].attachedfiles) {
            AttachmentDetails = files[0].attachedfiles
        }
        // if (AttachmentDetails && AttachmentDetails[0] && AttachmentDetails[0].AttachmentType && AttachmentDetails[0].AttachmentType == 'U' && AttachmentDetails[0].ContentStr) {
        //     let urlValue = AttachmentDetails[0].externallink
        //     let exp = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
        //     if (!exp.test(urlValue)) {
        //         this.setState({ urlhelpertext: 'URL is not valid', urlmissing: false })
        //     }
        //     else {
        //         this.setState({ urlhelpertext: null, urlmissing: false })
        //     }

        // }
        const AttachmentTypeId = attachment.AttachmentTypeIdVal
        if (AttachmentTypeId != "") {
            this.setState({ typemissing: false })
        }
        if (AttachmentDetails && AttachmentDetails[0] && AttachmentDetails[0].externallink && AttachmentDetails[0].externallink != '') {
            let urlValue = AttachmentDetails[0].externallink
            let exp = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
            if (!exp.test(urlValue)) {
                this.setState({ urlhelpertext: 'URL is not valid', urlmissing: false })
            }
            else {
                this.setState({ urlhelpertext: null, urlmissing: false })
            }

        }
        else {
            this.setState({ urlhelpertext: null, urlmissing: false, docmissing: false })
        }
        const Dataresult = { AttachmentDetails, Notes, AttachmentTypeId }
        this.setState({ Attachemntresults: Dataresult })
    }

    render() {
        const { options, compmap, dataItem, WizardAttachment, onFullScreen, onExitFullScreen, fullscreen, exitfullscreen, onNextItemClick, onPrevItemClick } = this.props;
        const { validations, updateAttachment } = options;
        const { customSave } = this.state
        return (
            <Fragment>
                {!this.props.hideheader && !this.props.WizardAttachment && <FormHeader className="row" savebutton={this.props.options.updateAttachment ? false : true} backbutton={true}
                    handleSave={this.handleSave} breadcrumbs={this.props.options.updateAttachment ? editbreadcrumbs : addbreadcrumbs} handleEdit={this.handleEdit} handleBack={this.handleBack}
                    onFullScreen={onFullScreen} onExitFullScreen={onExitFullScreen} fullscreen={fullscreen} exitfullscreen={exitfullscreen} dataItem={dataItem} navigateup={this.props.options.updateAttachment ? true : false} navigatedown={this.props.options.updateAttachment ? true : false}
                    onNextItemClick={onNextItemClick} onPrevItemClick={onPrevItemClick} />}
                {/* <div className={dataItem || WizardAttachment ? "col-sm-12" : "col-sm-6"}>
                    <CForm {...this.props} customId={this.props.customId} compmap={compmap} components={!this.props.options.updateAttachment ? this.componentsArray.components[0].components : viewapplicationattachments.components[0].components} getState={this.getState} validations={validations}
                        options={options} results={this.state.passresults} customSave={customSave} handleCustomSave={this.handleCustomSave} />
                </div> */}
                <ExtendedAttachment ref={this.LayoutForm} getallresultsInfo={this.getallresultsInfo} classes={dataItem || WizardAttachment ? true : false} AttachmentTypeId={this.state.AttachmentTypeId} atUploadOptions={this.atUploadOptions} passresults={this.state.passresults} updateAttachment={this.props.options.updateAttachment} editformat={this.state.editformat} archiveParentId={this.props.match.params.routeid1} docmissing={this.state.docmissing} urlmissing={this.state.urlmissing} urlhelpertext={this.state.urlhelpertext} typemissing={this.state.typemissing} handleSave={this.handleSave} handleEdit={this.handleEdit} dataItem={this.props.dataItem} />
            </Fragment>
        )
    }
}
const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

FileAttachments = connect(null, mapActions)(FileAttachments)
export default withRouter(FileAttachments)