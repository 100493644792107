import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import useDeepCompareEffect from 'use-deep-compare-effect';
import { handleActionsToEffects } from '../effects/effects';
import { fieldValidate } from '../helpers/checkers';
import { groupByType } from '../helpers/components';
import { fieldTypes } from '../helpers/fieldtypes';
import { layoutTypes } from '../layout/types';
import { objectConversion } from '../wrappers/helpers/converters';
import { Evaluate } from '../effects/calculate';
import { useDispatch, useSelector } from 'react-redux';
import { unsavedData } from '../../actions';
import { setResetValue } from '../../actions';

const _ = require('lodash');

const checkBoxTransformProps = {
    N: false,
    Y: true,
    'true': 'Y',
    'false': 'N'

}


function handleFieldChange(state, action) {
    const { type, ...otherProps } = action
    if (type === 'init') {
        const { values } = otherProps
        return { ...state, fieldValues: { ...values } }
    }
    if (type === 'value') {
        const { name, value, dataAttr } = otherProps
        return {
            ...state, fieldValues: { ...state.fieldValues, [name]: value },
            // dataAttr: { ...state.dataAttr, [name]: dataAttr } 
        }
    }
    if (type === 'prop') {
        const { name, value, dataAttr } = otherProps
        let newstate = { ...state }
        newstate = {
            ...state, fieldProps: { ...state.fieldProps, [otherProps.name]: { ...state.fieldProps[otherProps.name], ...otherProps.value } },
            // dataAttr: { ...state.dataAttr, [otherProps.name]: dataAttr }
        }
        return newstate
    }
    if (type === 'props') {
        const fields = otherProps
        let newstate = { ...state }
        fields.forEach(each => {
            newstate = { ...newstate, fieldProps: { ...state.fieldProps, [each]: { ...state.fieldProps[each], ...otherProps[each] } } }
        })
        return newstate
    }
    if (type === 'inital') {
        const resultskeys = Object.keys(state.fieldValues)
        resultskeys.forEach(each => {
            state = { ...state, fieldValues: { ...state.fieldValues, [each]: otherProps.updates[each] } }
        })
        return state
    }
    if (type === 'required') {
        //  const { fields } = otherProps
        const resultskeys = Object.keys(otherProps.fields)
        resultskeys.forEach(each => {
            state = { ...state, fieldProps: { ...state.fieldProps, [each]: { ...state.fieldProps[each], ...otherProps.fields[each] } } }
        })
        return state
    }
    if (type === 'validations') {
        state = { ...state, validations: { ...otherProps } }
        return state
    }
    if (type === 'bussinessrules') {
        return { ...state, conditions: { ...state.conditions, ...otherProps } }
    }

    if (type === 'values') {
        const { values } = otherProps
        return { ...state, fieldValues: { ...state.fieldValues, ...values } }
    }
    if (type === 'updates') {
        let newstate = { ...state }
        const { updates } = otherProps;
        const { prop, value, layout, initial, clearList, validations, required, businessrules, loadDefaultValues, edit, createduplicate, error, nestedlist, nestedlistArray,
            nestedlistArrayValues, nesteddropdownArray, savesuccess, domainmapper, appendextravalues, getLocalStorageData, BRActivity, BRHiddenState } = updates
        // if (getLocalStorageData) {
        //     console.log(state.fieldValues)
        //     state = {
        //         ...state, fieldValues: getLocalStorageData[0].result, loadAfterPreInit: true
        //     }
        //     newstate = { ...state }
        // }
        if (prop) {
            prop.forEach(each => {
                const { name, ...otherPropProperties } = each
                if (otherPropProperties && otherPropProperties.hasOwnProperty('allowedValues') && otherPropProperties.allowedValues.length > 0) {
                    let transformValue = (state.fieldProps[name].extraProps.transformProps && state.fieldProps[name].extraProps.transformProps.value) || 'value';
                    const defaultItems = state.fieldProps[name].defaultItems && state.fieldProps[name].defaultItems.length > 0 ? state.fieldProps[name].defaultItems : (state.fieldProps[name].items || [])
                    const filterItems = ((state.fieldProps[name].defaultItems && state.fieldProps[name].defaultItems) || state.fieldProps[name].items).filter(function (item) {
                        return otherPropProperties.allowedValues.indexOf((item[transformValue]).toString()) > -1
                    })
                    state = {
                        ...state, fieldProps: {
                            ...state.fieldProps, [name]: {
                                ...state.fieldProps[name],
                                items: filterItems || [],
                                defaultItems: defaultItems || []

                            }
                        },
                    }
                    // state = {
                    //     ...state, fieldProps: {
                    //         ...state.fieldProps, [name]: {
                    //             ...state.fieldProps[name],
                    //             items: state.fieldProps[name].hasFilter ? filterItems || [] : (state.fieldProps[name].defaultItems && state.fieldProps[name].defaultItems.length > 0) ? defaultItems : state.fieldProps[name].items,
                    //             defaultItems: defaultItems || []
                    //         }
                    //     },
                    // }
                    newstate = { ...state }
                }
                if (otherPropProperties && otherPropProperties.hasOwnProperty('hasValue') && otherPropProperties.hasValue && (otherPropProperties.valueType === 'D' || otherPropProperties.valueType === 'C')) {

                    state = {
                        ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], ...otherPropProperties, className: 'd-block', } },
                        fieldValues: {
                            ...state.fieldValues, [name]:
                                otherPropProperties.valueType === 'D' ?
                                    otherPropProperties.value === '' ? '' : (otherPropProperties.value || (otherPropProperties.disabled ? 'Y' : 'N')) :
                                    otherPropProperties.valueType === 'C' ? Evaluate(otherPropProperties.value, state.fieldValues, otherPropProperties.currentrefIds, otherPropProperties.dynamicData)
                                        : state.fieldValues[name]
                        }
                    }
                    newstate = { ...state }
                }
                if (otherPropProperties && otherPropProperties.hasOwnProperty('isVisible')) {
                    if (!otherPropProperties.isVisible)
                        state = {
                            ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], ...otherPropProperties, className: 'd-none' } },
                        }

                    else state = {
                        ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], ...otherPropProperties, className: 'd-block' } },
                    }
                    newstate = { ...state }
                }
                if (name === 'domainitems') {
                    let tempDomains = {};
                    if (otherPropProperties && otherPropProperties.domainitems)
                        tempDomains = otherPropProperties.domainitems
                    else tempDomains = otherPropProperties
                    let listKeys = Object.keys(tempDomains)
                    for (let i = 0; i < listKeys.length; i++) {
                        if (state.fieldProps[listKeys[i]])
                            state = {
                                ...state, fieldProps: {
                                    ...state.fieldProps, [listKeys[i]]: {
                                        ...state.fieldProps[listKeys[i]],
                                        items: tempDomains[listKeys[i]] || []
                                    }
                                },
                            }
                    }
                    newstate = { ...state }
                }
                else {
                    state = {
                        ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], ...otherPropProperties } },
                    }
                    newstate = { ...state }
                }
            })
        }
        if (loadDefaultValues) {
            loadDefaultValues.forEach(each => {
                const { defaultCondition, value, checkDefaultValue, savetype } = each.transformProps
                let allItems = (state.fieldProps && state.fieldProps[each.name].items) ? state.fieldProps[each.name].items : null
                let defaultval
                if (savetype === 'add' && defaultCondition && value && checkDefaultValue && allItems && allItems.length) {
                    defaultval = allItems.find(i => i[defaultCondition] === checkDefaultValue)
                }
                if (savetype === 'update' && value && allItems && allItems.length && state.fieldValues) {
                    defaultval = allItems.find(i => i.Value === state.fieldValues[each.name])
                }
                if (defaultval) {
                    if (each.datasetval) {
                        let items = each.datasetval
                        let dataAttrVal = {}
                        for (let key in items) {
                            let itemkey = items[key]
                            let itemval = defaultval[key]
                            dataAttrVal[itemkey] = itemval
                        }
                        state = {
                            ...state, fieldValues: { ...state.fieldValues, [each.name]: defaultval[value] },
                            dataAttr: { ...state.dataAttr, [each.name]: dataAttrVal },
                        }
                        newstate = { ...state }
                    }
                    else {
                        state = {
                            ...state, fieldValues: { ...state.fieldValues, [each.name]: defaultval[value] }
                        }
                        newstate = { ...state }
                    }
                }
            }
            )
        }
        if (domainmapper) {
            domainmapper.forEach(each => {
                const { name } = each;
                let items = each[name] ? { items: each[name] } : { items: [] }
                state = {
                    ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], ...items, className: 'd-block', } }, fieldValues: { ...state.fieldValues, [name]: (each[name] && each[name].length > 0) ? each[name][0].name : '' },
                }

            })
            newstate = { ...state }

        }
        if (appendextravalues) {
            appendextravalues.forEach(each => {
                const { name, getdatafrom } = each;
                let disablekey = each[getdatafrom] ? each[getdatafrom] : []
                state = {
                    ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], disablekey: disablekey, className: 'd-block', } }, fieldValues: { ...state.fieldValues, [name]: (each[name] && each[name].length > 0) ? each[name][0].name : '' },
                }

            })
            newstate = { ...state }

        }

        if (edit || createduplicate) {
            const resultskeys = Object.keys(state.fieldProps)
            resultskeys.map(name => {
                state = {
                    ...state, fieldProps: {
                        ...state.fieldProps, [name]: {
                            ...state.fieldProps[name], type: state.fieldProps[name].changefieldto ?
                                state.fieldProps[name].changefieldto : 'label', edit: true
                        }
                    },
                }
            })
            newstate = { ...state }

        }
        if (initial && initial.length > 0 && state && state.fieldValues) {
            let resultskeys = Object.keys(state.fieldValues)
            let initalfieldValues = state.fieldValues
            initial.forEach(init => {
                if (init) {
                    resultskeys.forEach(each => {
                        let value = init[each] ? init[each] : objectConversion(init, each)
                        state = { ...state, fieldValues: { ...state.fieldValues, [each]: ((value || value === 0) && value !== ' ') ? value : initalfieldValues[each] } } //each.split(".").reduce((res, prop) => { return res && res[prop] }, initialdata)
                    })
                    initalfieldValues = state.fieldValues;
                    newstate = { ...state }
                }
            })
        }
        if (value) {
            value.forEach(each => {
                const { key, result, resultKey } = each
                state = { ...state, fieldValues: { ...state.fieldValues, [key]: resultKey ? state.fieldValues[resultKey] : result.value } }
            })
            newstate = { ...state }
        }
        if (BRActivity) {
            newstate = { ...state, ['triggerBRActivity']: false }
        }
        if (nestedlist) {
            const { items, name, otherProps } = nestedlist[0]
            const { mapNameList, nested, subnested } = otherProps
            name.forEach(each => {
                const { key, value } = each
                state = { ...state, fieldValues: { ...state.fieldValues, [each]: subnested ? items[nested][subnested][mapNameList[each]] : items[nested][mapNameList[each]] } }
            })
            newstate = { ...state }
        }
        if (nesteddropdownArray) {
            nesteddropdownArray.forEach(eachitem => {
                const { name, value, ...otherPropProperties } = eachitem
                name.forEach(each => {
                    state = {
                        ...state, fieldValues: { ...state.fieldValues, [each]: value ? value : state.fieldValues[each] }, fieldProps: { ...state.fieldProps, [each]: { ...state.fieldProps[each], ...otherPropProperties, className: 'd-block', } },
                    }
                    newstate = { ...state }
                })
            })
        }
        if (nestedlistArray) {
            const { items, name, otherProps } = nestedlistArray[0]
            const { mapNameList, nested } = otherProps
            name.forEach(each => {
                const { key, value } = each
                newstate = { ...newstate, fieldValues: { ...newstate.fieldValues, [each]: items[0][mapNameList[each]] } }
            })
        }

        if (nestedlistArrayValues) {
            const { items, name, itemfilterval, otherProps } = nestedlistArrayValues[0]
            const { mapNameList, nested, subnested } = otherProps
            let itemslist;
            if (items && items.length > 0 && itemfilterval && newstate && newstate.fieldValues && newstate.fieldValues[itemfilterval]) {
                itemslist = itemfilterval ? _.find(items, { 'Id': newstate.fieldValues[itemfilterval] }) : items[0]
            }
            else if (items && !Array.isArray(items)) {
                itemslist = items
            }
            itemslist && name.forEach(each => {
                const { key, value, nestedname, name, nonnested } = each
                let nestedvalue = nestedname ? nestedname : (nested ? nested : null)
                if (name && nonnested) {
                    newstate = { ...newstate, fieldValues: { ...newstate.fieldValues, [name]: itemslist[mapNameList[name]] } }
                }
                else if (name) {
                    newstate = { ...newstate, fieldValues: { ...newstate.fieldValues, [name]: nestedvalue ? itemslist[nestedvalue][mapNameList[name]] : itemslist[mapNameList[name]] } }
                }
                else if (subnested) {
                    newstate = { ...newstate, fieldValues: { ...newstate.fieldValues, [each]: nestedvalue ? itemslist[nestedvalue][subnested][mapNameList[each]] : itemslist[subnested][mapNameList[each]] } }
                }
                else
                    newstate = { ...newstate, fieldValues: { ...newstate.fieldValues, [each]: nestedvalue ? itemslist[nestedvalue][mapNameList[each]] : itemslist[mapNameList[each]] } }
            })
        }

        if (savesuccess) {
            let values = state.fieldValues
            if (savesuccess[0] && savesuccess[0].fieldValues)
                values = savesuccess[0].fieldValues
            if (state.fieldValues['edit']) {
                const resultskeys = Object.keys(state.fieldProps)
                resultskeys.map(name => {
                    state = {
                        ...state, fieldProps: {
                            ...state.fieldProps, [name]: {
                                ...state.fieldProps[name], value: values[name],
                                type: (state.fieldProps[name].customField || (savesuccess[0] && savesuccess[0].noEdit)) ?
                                    state.fieldProps[name].type : 'label', helperText: '', error: false,
                                edit: (savesuccess[0] && savesuccess[0].noEdit) ? true : false
                            }
                        }, triggerBRActivity: savesuccess[0] ? true : false
                    }
                })
                newstate = {
                    ...state, back: state.fieldValues['back'] && state.fieldValues['back'] === true ? true : false,
                    fieldValues: {
                        ...state.fieldValues, ...values, ['save']: false,
                        ['edit']: (savesuccess[0] && savesuccess[0].noEdit) ? true : false
                    }, triggerBRActivity: savesuccess[0] ? true : false
                }
                // dispatch(saveFindings(values))
            }
            else if (state.fieldValues['createduplicate']) {
                newstate = { ...state, back: true }
            }
            else {
                let data = savesuccess && savesuccess.length > 0 && savesuccess[0].fieldValues || {}
                data = _.merge(state.fieldValues, data)
                newstate = {
                    ...state, addData: { ...state.fieldValues, ...data }, fieldValues: {
                        ...data, ['save']: false, ['edit']: true,
                    }, back: savesuccess && savesuccess.length > 0 && savesuccess[0].back === false ? false : true, triggerBRActivity: savesuccess[0] ? true : false
                }
            }
        }
        if (error) {
            let fielderrors = error[0].error
            if (fielderrors && fielderrors.errors) {
                fielderrors = fielderrors.errors
            }
            let errorKeys = Object.keys(fielderrors);
            errorKeys.forEach(err => {
                state = {
                    ...state, fieldProps: { ...state.fieldProps, [err]: { ...state.fieldProps[err], error: true, helperText: fielderrors[err][0] } },
                }
                newstate = { ...state }
            })
            newstate = { ...newstate, fieldValues: { ...newstate.fieldValues, ['save']: false } }
        }
        if (validations) {
            if (Array.isArray(validations)) {
                validations.map(valids => {
                    if (valids['validations']) {
                        let fieldValidationsData = _.isEmpty(state.fieldValidations) ? valids['validations'] : { ...state.fieldValidations, ...valids['validations'] }
                        state = {
                            ...state, fieldValidations: fieldValidationsData
                        }
                        newstate = { ...state }
                    }
                    // if (valids['validations']) {
                    //     state = { ...state, fieldValidations: valids['validations'] }
                    //     newstate = { ...state }
                    // }
                    if (valids['required']) {
                        valids['required'].forEach(each => {
                            let name = (state.validationRequiredMapKeys && state.validationRequiredMapKeys[each]) || each;
                            if (state.fieldProps[name])
                                state = { ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], required: true } } }
                        })
                        newstate = { ...state, requiredFields: valids['required'] }
                    }
                    if (valids['tooltip']) {
                        if (Object.keys(valids['tooltip']).length > 0)
                            Object.keys(valids['tooltip']).forEach(each => {
                                state = { ...state, fieldProps: { ...state.fieldProps, [each]: { ...state.fieldProps[each], tooltip: valids['tooltip'][each] } } }
                            })
                        newstate = { ...state }
                    }
                    if (valids['contexthelp']) {
                        if (Object.keys(valids['contexthelp']).length > 0)
                            Object.keys(valids['contexthelp']).forEach(each => {
                                state = { ...state, fieldProps: { ...state.fieldProps, [each]: { ...state.fieldProps[each], contexthelp: valids['contexthelp'][each] } } }
                            })
                        newstate = { ...state }
                    }
                })
            }
            else {
                state = { ...state, fieldValidations: validations }
                newstate = { ...state }
            }

        }
        if (businessrules) {
            newstate = {
                ...state, conditions: {
                    ...state.conditions, ['afterSave']: {
                        ...state.conditions['afterSave'], ...businessrules[0]['afterSave']
                    },
                    ['onChange']: {
                        ...state.conditions['onChange'], ...businessrules[0]['onChange']
                    }, ['onLoad']: {
                        ...state.conditions['onLoad'], ...businessrules[0]['onLoad']
                    },
                    ['auto']: { ...state.conditions['auto'], ...businessrules[0]['auto'] },
                    ['onEvent']: { ...state.conditions['onEvent'], ...businessrules[0]['onEvent'] },
                    ['info']: { ...state.conditions['info'], ...businessrules[0]['info'] },
                }
            }
        }
        if (BRHiddenState) {
            if (BRHiddenState && BRHiddenState[0]) {
                localStorage.setItem('RecordHiddenState', JSON.stringify(Object.values(BRHiddenState[0])))
            }
        }
        if (required) {
            required.forEach(each => {
                let name = (state.validationRequiredMapKeys && state.validationRequiredMapKeys[each]) || each
                state = { ...state, fieldProps: { ...state.fieldProps, [name]: { ...state.fieldProps[name], required: true } } }
            })
            newstate = { ...state, requiredFields: required }
        }
        if (clearList) {
            state = { ...state, fieldValues: { ...state.initialFieldValues } }
            return state
        }
        if (layout) {
        }
        if (newstate)
            return newstate
        else return state
    }

    if (type === 'customfields') {
        const { layout, components } = otherProps
        const { group, ...othersLayout } = layout
        return {
            ...state, fieldProps: { ...state.fieldProps, ...components }, fieldsLayout: {
                ...state.fieldsLayout, ...othersLayout,
                group: state.fieldsLayout.group.concat(group).reduce((acc, current) => {
                    const x = acc.find(item => item.order === current.order);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        const { position, components } = current
                        x.components.splice(position, 0, ...components)
                        return acc;
                    }
                }, [])
            }
        }
    }

}


export default function useForm(props) {
    const { key, components, layout, values, initRun, removeValues, removereduxValues, nestedValues, aliases, mapActionsToEffects, extraProps, dispatchSharedValueChange, validations, required, validationRequiredMapKeys,
        routeParams, noParams, dispatchhandleSave } = props //dataAttributes
    const { crud } = extraProps || {}
    const layoutType = layout.type
    const sharedProps = props.sharedProps || {}
    const sharedValues = props.sharedValues || []
    const prevState = useRef(sharedProps)
    const dispatch = useDispatch()
    const unsaveddata = useSelector(state => state.header && state.header.unsaveddata);
    const parentRecordVals = useSelector(state => state.sidebar && state.sidebar.modules);
    const [prevFieldvalues, setPrevFeildValues] = useState(values);
    const resetval = useSelector(state => state.header && state.header.resetval);
    const [currValue, setCurrValue] = useState(null)
    // let counterval
    //const getstoreresults = useSelector(state => state.initialresults)
    //render before

    const [fields, dispatchFieldsChange] = useReducer(
        handleFieldChange,
        {
            fieldValues: values, initialFieldValues: values, fieldProps: components, fieldsLayout: layout, fieldValidations: validations || {}, requiredFields: required || [], conditions: mapActionsToEffects, back: false,
            validationRequiredMapKeys: validationRequiredMapKeys, triggerBRActivity: false,
            //   dataAttr: dataAttributes, 
            addData: null
        }
    )
    const errors = useRef(false)
    const currentField = useRef('')
    let history = useHistory();
    let params = useParams();
    const counter = useRef(0)
    const { back, addData, fieldValues, fieldProps, fieldsLayout, fieldValidations, conditions, triggerBRActivity } = fields //dataAttr

    useDeepCompareEffect(() => {
        // dispatch(unsavedData(key, fieldValues, ))
        let updatedSharedValues = sharedValues.reduce((accum, each) => {
            accum[each] = fieldValues[each]
            return accum
        }, {})
        dispatch(unsavedData({ unsaveddata: fieldValues, initialdata: unsaveddata && unsaveddata.initialdata && !(_.isEmpty(unsaveddata.initialdata)) ? { ...unsaveddata.initialdata, ...sharedProps } : fieldValues }))
        // if (!initialdata) {

        //  }
        //const names = currentField.current
        const names = Object.keys(updatedSharedValues)
        if (counter.current > 0 && names.length > 0 && dispatchSharedValueChange) {
            dispatchSharedValueChange({
                type: 'values',
                values: names.map(each => {
                    return {
                        name: aliases[each] || each,
                        value: updatedSharedValues[each]
                    }
                })
            })
        }
        counter.current++
        // currentField.current = []
    }, [fieldValues])

    useEffect(() => {
        if (triggerBRActivity) {
            handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: { ...fieldValues, ...params },
                    conditions: (conditions.afterSave['businessrulesactivity']),
                })
            )
        }
    }, [triggerBRActivity])


    useDeepCompareEffect(() => {
        try {
            if (sharedProps && Object.keys(sharedProps).length > 0)
                dispatchFieldsChange({
                    type: 'updates',
                    updates: {
                        value: Object.keys(sharedProps).map(each => {
                            return {
                                key: each,
                                result: {
                                    value: sharedProps[each]
                                }
                            }
                        })
                    }
                })
        }
        catch (ex) {
            console.error("console error in section" + ex)
        }
    }, [sharedProps])

    // useEffect(() => {
    //     if (conditions && conditions.onLoad && conditions.onLoad['init']) { // dependency and bussiness rules checking
    //         handleEffectUpdates(
    //             handleActionsToEffects({
    //                 fieldValues: { ...fieldValues, ...sharedProps, ...params },
    //                 conditions: (conditions.onLoad['init']),
    //                 global: conditions.onLoad.GLOBAL,
    //             })
    //         )
    //     }
    //     // counterval = 1;
    // }, [])


    // need to remove the code here and extend the functionality function
    useEffect(() => {
        let oldconditionsarr = [];
        let newconditionarr = [];
        for (let key in mapActionsToEffects.onLoad) {
            oldconditionsarr.push(key);
        };
        for (let key in conditions.onLoad) {
            newconditionarr.push(key);
        };
        let oldvalues = newconditionarr.filter(x => !oldconditionsarr.includes(x));
        for (let i = 0; i < oldvalues.length; i++) {
            let onloadname = oldvalues[i]
            if (conditions && conditions.onLoad && conditions.onLoad[onloadname]) { // dependency and bussiness rules checking
                handleEffectUpdates(
                    handleActionsToEffects({
                        fieldValues: { ...fieldValues, ...sharedProps, ...params },
                        conditions: (conditions.onLoad[onloadname]),
                        global: conditions.onLoad.GLOBAL,
                    })
                )
            }
        }
        // counterval = 1;
    }, [conditions.onLoad])


    useDeepCompareEffect(() => {
        let sharedkeys = [];
        let prevStateShaedProps = prevState.current
        let tempConditions = conditions && conditions.onChange || {};
        let reduxVals = {}
        if (tempConditions && (sharedProps['save'] !== prevStateShaedProps['save'])) {
            if (fieldValues['GetParentValues']) {
                /**  GetParentValues:  should be add to jsons to data ( applicant, compnay) get from parent data */
                reduxVals = parentRecordVals
            }
            sharedkeys.push('save');
            tempConditions = conditions.afterSave;
            if (tempConditions)
                tempConditions['GLOBAL'] = conditions.afterSave.GLOBAL;
        }
        else if (sharedProps['saveduplicate'] === true) {
            sharedkeys.push('saveduplicate');
            tempConditions = conditions.afterSave
        }
        else if (sharedProps['edit'] === true || sharedProps['createduplicate'] === true) {
            let prevStateProps = Object.keys(sharedProps).filter(each => sharedProps[each] != prevStateShaedProps[each])
            sharedkeys = (prevStateProps)
        }
        else sharedkeys = Object.keys(sharedProps)
        sharedkeys && sharedkeys.map(share => {
            if (tempConditions && tempConditions[share] && (sharedProps[share] !== false)) {
                let sendvalues = noParams ? { ...fieldValues, ...sharedProps } : { ...fieldValues, ...sharedProps, ...params }
                let proceed = true;
                if (sharedProps[share] && (share === 'save' || share === 'saveduplicate')) {
                    proceed = !saveFields();
                    if (!proceed) {
                        counter.current = 0
                        let names = share === 'saveduplicate' ? ['saveduplicate'] : ['save']
                        dispatchSharedValueChange({
                            type: 'values',
                            values: names.map(each => {
                                return {
                                    name: each,
                                    value: false
                                }
                            })
                        })
                        dispatchFieldsChange({
                            type: 'value',
                            name: share,
                            value: sendvalues[share],
                            //  dataAttr: dataAttr
                        })
                    }

                    let tempsendvalues = share === 'saveduplicate' ? { ...fieldValues, ...sharedProps, ...params, IsDuplicate: "Y" } :
                        (noParams ? { ...fieldValues, ...sharedProps } : { ...fieldValues, ...sharedProps, ...params })
                    sendvalues = tempsendvalues;
                    if (nestedValues) {
                        for (const [key, value] of Object.entries(nestedValues)) {
                            let keyval = { [key]: {} }
                            value.forEach(element => {
                                keyval[key][element] = sendvalues[element]
                            });
                            sendvalues = { ...tempsendvalues, ...keyval }
                        }

                    }
                    if (removeValues) {
                        removeValues.forEach(fields => {
                            delete sendvalues[fields]
                        })
                    }
                    if (removereduxValues) {
                        removereduxValues.forEach(fields => {
                            delete reduxVals[fields]
                        })
                    }
                }
                proceed && handleEffectUpdates(
                    handleActionsToEffects({
                        fieldValues: {
                            ...sendvalues, ...reduxVals
                        },
                        conditions: (sharedProps[share] || share === props.tempconditions) ? tempConditions[share] : [],
                        global: (!sharedProps[share] || fieldValues['RunAfterSave']) ? (tempConditions && tempConditions.GLOBAL) : [],
                        extras: { runEvents: (tempConditions[share] && tempConditions[share].runEvents) || fieldValues['RunAfterSave'] ? true : false, } // based on onload and onsave
                    })
                )
            }
        })
        prevState.current = sharedProps;
    }, [sharedProps])

    const handleEffectUpdates = (res) => {
        if (res)
            Promise.all(res).then(results => {
                dispatchFieldsChange({
                    type: 'updates',
                    updates: groupByType(results)
                })
            })
    }

    useEffect(() => {
        if (addData)
            if (routeParams && routeParams.replace) {
                history.push(routeParams.replace + '/' + addData.Id)
            }
            else {
                props.handleBack(true)
            }
    }, [addData])

    useEffect(() => {
        if (back)
            props.handleBack(true)
    }, [back])

    useEffect(() => {
        if (key) {
            localStorage.setItem('TOUR_KEY', key)
        }
        if (conditions && conditions.onLoad && conditions.onLoad['init']) { // dependency and bussiness rules checking
            let reduxVals = {}
            if (fieldValues['GetParentValues']) {
                /**  GetParentValues:  should be add to jsons to data ( applicant, compnay) get from parent data */
                reduxVals = parentRecordVals
            }
            handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: { ...fieldValues, ...sharedProps, ...params, ...reduxVals },
                    conditions: (conditions.onLoad['init']),
                    extras: { ...params }
                })
            )
        }
    }, [])

    useEffect(() => {
        if (resetval) {
            const { name, isReset } = resetval
            if (isReset) {
                let tempConditions = _.cloneDeep(conditions)
                let values = []
                Object.keys(fieldValues).map(each => {
                    if (fieldValues[each] !== prevFieldvalues[each])
                        values.push({
                            key: each,
                            result: {
                                value: prevFieldvalues[each]
                            }
                        })
                })
                if (name && conditions) {
                    //   let sectionname = fieldsinSection ? fieldsinSection[currValue] : currValue;
                    let eachCondition = (conditions.onChange[name] && conditions.onChange[name].fields) || null;
                    let globalCondition = (conditions.onChange.GLOBAL && conditions.onChange.GLOBAL.fields) || null;

                    //each filter
                    let filterEachRules = [];
                    // eslint-disable-next-line no-unused-expressions
                    tempConditions.onChange[name] && tempConditions.onChange[name].rules.map(each => {
                        if (each.fields && each.fields.includes(currValue)) {
                            filterEachRules.push(each)
                        }
                    }) || [];
                    let OnCondition = tempConditions.onChange[name] || {};
                    OnCondition['rules'] = filterEachRules;
                    // Global filter
                    let filterGlobalRules = [];
                    tempConditions.onChange.GLOBAL && tempConditions.onChange.GLOBAL.rules.map(each => {
                        if (each.fields && each.fields.includes(currValue)) {
                            filterGlobalRules.push(each)
                        }
                    })
                    let OnGlbalCondition = tempConditions.onChange.GLOBAL || {};
                    OnGlbalCondition['rules'] = filterGlobalRules;
                    if ((eachCondition && eachCondition.includes(currValue)) || (globalCondition && globalCondition.includes(currValue))) {
                        handleEffectUpdates(
                            handleActionsToEffects({
                                fieldValues: { ...prevFieldvalues, ...params },
                                conditions: OnCondition || [],
                                global: OnGlbalCondition,
                            }))
                    }
                }
                dispatch(setResetValue({}))
                dispatchFieldsChange({
                    type: 'updates',
                    updates: {
                        value: values,
                    }
                })
            }
        }
    }, [resetval && resetval.isReset])

    const handleFieldValue = (e) => {
        const { name, value, checked } = e.target
        const { dataset } = e && e.currentTarget ? e.currentTarget : { dataset: null };
        let tempConditions = _.cloneDeep(conditions)
        const val = handleValue(name, value, checked)
        const errorObj = fieldValidate(fieldValidations[name], val)
        dispatch(unsavedData({ unsaveddata: { ...fieldValues, [name]: value }, initialdata: unsaveddata.initialdata }))
        if (!errorObj) {
            dispatchFieldsChange({
                type: 'prop',
                name: name,
                value: {
                    error: null,
                    helperText: null
                },
                //  dataAttr: dataset
            })
        }
        if (errorObj) {
            if (errorObj.error)
                errors.current = true
            // else {
            //     errors.current = false
            // }

            dispatchFieldsChange({
                type: 'prop',
                name: name,
                value: {
                    error: errorObj.error,
                    helperText: errorObj.errorText
                },
                //      dataAttr: dataset
            })
        }
        errors.current = false;
        dispatch(setResetValue({ name: name, isReset: null }))
        if ((conditions && conditions.onChange && conditions.onChange[name] && conditions.onChange[name].fields) || (conditions && conditions.onChange && conditions.onChange.GLOBAL && conditions.onChange.GLOBAL.fields)) {
            setPrevFeildValues(fieldValues);
            let eachCondition = (conditions.onChange[name] && conditions.onChange[name].fields) || null;
            let globalCondition = (conditions.onChange.GLOBAL && conditions.onChange.GLOBAL.fields) || null;

            //each filter
            let filterEachRules = [];
            // eslint-disable-next-line no-unused-expressions
            tempConditions.onChange[name] && tempConditions.onChange[name].rules.map(each => {
                if (each.fields && each.fields.includes(name)) {
                    filterEachRules.push(each)
                }
            }) || [];
            let OnCondition = tempConditions.onChange[name] || {};
            OnCondition['rules'] = filterEachRules;
            // Global filter
            let filterGlobalRules = [];
            tempConditions.onChange.GLOBAL && tempConditions.onChange.GLOBAL.rules.map(each => {
                if (each.fields && each.fields.includes(name)) {
                    filterGlobalRules.push(each)
                }
            })
            let OnGlbalCondition = tempConditions.onChange.GLOBAL || {};
            OnGlbalCondition['rules'] = filterGlobalRules;

            if ((eachCondition && eachCondition.includes(name)) || (globalCondition && globalCondition.includes(name))) {
                errors.current = false
                currentField.current = name
                handleEffectUpdates(
                    handleActionsToEffects({
                        fieldValues: { ...fieldValues, ...params, [name]: val },
                        conditions: OnCondition || [],
                        global: OnGlbalCondition,
                        extras: { [name]: dataset, runEvents: true },//dataAttr: { ...dataAttr, [name]: dataset }, 
                        onEvent: name !== 'save' ? (conditions.onEvent && conditions.onEvent.GLOBAL) : {},
                        info: conditions.info ? conditions.info : null
                    })
                )
            }
            dispatchFieldsChange({
                type: 'value',
                name: name,
                value: val
            })
        }

        //const val = handleValue(name, value, checked)
        dispatchFieldsChange({
            type: 'value',
            name: name,
            value: val,
            //   dataAttr: dataset
        })
    }

    const handleButtonClick = (e, callRequest) => {
        const { name } = e.currentTarget
        let proceed = true;
        if (conditions && conditions.onChange && conditions.onChange[name]) {
            counter.current++
            if (name === 'save' || name === 'IsLocked' || callRequest)
                proceed = !saveFields();
            let sendvalues = fieldValues
            if (removeValues) {
                removeValues.forEach(fields => {
                    delete sendvalues[fields]
                })
            }
            proceed && handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: { ...sendvalues, ...sharedProps, ...params },
                    conditions: conditions.onChange[name] || [],
                    extras: { runEvents: true, },
                    onEvent: name !== 'save' ? (conditions.onEvent && conditions.onEvent[name]) : {},
                    info: conditions.info ? conditions.info : null
                })
            )
        }
    }



    const handleValue = (name, value, checked) => {
        // const { type } = components[name]
        // switch (type) {
        const { changefieldto, type, custom } = components[name]
        let changeType = changefieldto || type
        switch (changeType) {
            case 'checkbox':
                return checkBoxTransformProps[checked.toString()]
            default:
                return value
        }
    }


    //custom components (editor/ multiselect)
    function setCustomFields(customprops) {
        dispatchFieldsChange({
            type: 'customfields',
            ...customprops
        })
    }

    //custom components (editor/ multiselect)
    const updateForm = (res) => {
        dispatchFieldsChange({
            type: 'updates',
            updates: res
        })
    }

    //use set additionals
    const setInitialFieldValues = (res) => {
        dispatchFieldsChange({
            type: 'inital',
            updates: res[0]

        })
    }

    const setBussinessrules = (res) => {
        dispatchFieldsChange({
            type: 'bussinessrules',
            ...res
        })
    }

    const saveFields = () => {
        let errorlist = {};
        let saveStatus = false
        if (errors.current)
            console.log('Please Fix errors')
        else {
            let fields = Object.keys(fieldProps)
            const errorFields = fields.filter(each => {
                if (fieldProps[each].required) {
                    if (fieldValues[each] && fieldValues[each] !== '') {
                        if (fieldProps[each].error) {
                            saveStatus = true
                            return true
                        }
                        else {
                            return false
                        }
                    }
                    else {
                        saveStatus = true
                        return true
                    }
                }
                if (fieldProps[each].error) {
                    saveStatus = true
                    return true
                }
                else return false
            })
            errorFields.map(each => {
                let errorfield = fieldProps[each]
                let errorfieldlabel = errorfield.label
                if (!(fieldProps && fieldProps[each] && fieldProps[each].error) && fieldProps[each].required) {
                    return errorlist[each] = {
                        error: true,
                        helperText: `${errorfieldlabel} is Required`,
                    }
                }
                else {
                    return errorlist[each] = {
                        error: true,
                        helperText: fieldProps[each].helperText ? fieldProps[each].helperText : '',
                    }
                }

            })
            dispatchFieldsChange({
                type: 'required',
                fields: errorlist
            })
        }
        return saveStatus
    }

    const handleRowClick = (e, data) => {
        const { name, value, checked } = e.target
        if (conditions[name]) {
            // currentField.current = name
            handleEffectUpdates(
                handleActionsToEffects({
                    fieldValues: { ...fieldValues },
                    conditions: conditions[name],
                    extras: { ...data }
                })
            )
        }
    }


    const componentsCreation = (fieldNames, values) => {
        if (fieldProps)
            try {
                return fieldNames.reduce((accum, each) => {
                    //   console.log(each)
                    if (fieldProps[each]) {
                        const { type, custom, ...otherProps } = fieldProps[each]
                        if (custom) {
                            const EachComp = type
                            accum[each] = <EachComp  {...otherProps} value={values[each]} />
                        }
                        else {
                            const EachComp = fieldTypes[type]
                            if (type) {
                                accum[each] = <EachComp  {...otherProps} value={values[each]} handleFieldValue={handleFieldValue} handlerowClick={handleRowClick} sharedProps={sharedProps} accessPermissions={props.accessPermissions}
                                    loadReport={sharedProps.loadreportfilter} back={props.addOnsByName['back']} routeparams={props.routeparams}// 'gridreport'
                                    handleButtonClick={handleButtonClick} fieldValues={values} fieldProps={fieldProps}//'button'
                                />
                                return accum
                            }
                            else {
                                console.log("type is missing" + type, each)
                            }
                        }
                    }
                }, {})
            }
            catch (ex) {
                console.log("exception " + ex)
            }
    }

    useEffect(
        () => {
            if (!initRun) {
                handleEffectUpdates(
                    handleActionsToEffects({
                        fieldValues: { ...fieldValues },
                        conditions: {},
                        global: (conditions && conditions.GLOBAL) || [],
                        extras: { runEvents: false, }
                    })
                )
            }
        }, [conditions])

    return [
        <div>
            {layoutType && layoutTypes[layoutType] ?
                layoutTypes[layoutType]({
                    layout: fieldsLayout,
                    fields: componentsCreation(Object.keys(fieldProps), fieldValues)
                }) :
                layoutTypes.default({
                    layout: fieldsLayout,
                    fields: componentsCreation(Object.keys(fieldProps), fieldValues)
                })
            }
        </div>,
        fieldValues,
        fieldProps,
        setCustomFields,
        updateForm,
        setInitialFieldValues,
        saveFields,
        setBussinessrules,
    ]

}