import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { GetData, PostData, PutData } from "../../core/controls/helpers/requests";
import { snackbarMessage } from '../../core/actions';
import FormHeader from '../../core/modules/form/cformheader';
import CFormTextBox from '../../core/modules/form/cformtextbox';
import TemplateForm from '../../core/modules/template/template';
import CFormDropDown from '../../core/modules/form/cformdropdown';

class CTemplateLoader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: null,
            result: {},
            Subject: '',
            dropdowndata: [],
            dropdowndefaultvalue: '',
            templateid: '',
            fieldvaluedata: [],
            routeProps: [],
            templateFormats: false,
            passresults: false,
            notificationSettingId: null,
            routinghelpertext : null,
            routingerror : false
        };
        this.TemplateForm = React.createRef()
    }


    componentDidMount() {
        const { options } = this.props;
        const { editorState } = this.state;
        const { crud } = options;
        const { read, update } = crud;
        const { get } = read ? read : {}
        let dropdownurl = get.url;
        const { routeProps, customProps, allnotesurl, allroutingurl } = get ? get : {}
        let ids = []
        if (Array.isArray(routeProps)) {
            routeProps.forEach(each => {
                const { value } = each
                ids.push({ key: value, value: this.props.match.params[value] })
            })
        }
        ids && ids.forEach(each => {
            const { key, value } = each
            if (dropdownurl) dropdownurl = `${dropdownurl.replace(`{${key}}`, value)}`;
        });
        GetData(dropdownurl).then(result => {
            if (result && Array.isArray(result)) {
                let ddldata = result.map(el => ({ value: el.Id, key: el.Id, name: el.Name }));
                console.log(ddldata);
                this.setState({ dropdowndata: ddldata, routeProps: ids })
            }
        }).catch(error => {
            console.log(error);
        })

        // let url = this.props && this.props.dataItem && this.props.dataItem.Id ? read.get.url + this.props.match.params.Id + '/' + this.props.dataItem['Id'] : read.get.url;
        // GetData(url).then(result => {
        //     if (result && result.Id) {
        //         this.setState({ resultExists: true, editorState: parseInt(atob(result.BodyStr)), result: result, Subject: result.Subject, Rank: result.Rank })
        //     }
        //     else this.setState({ editorState: null, resultExists: false })
        // }).catch(error => {

        // })
    }

    handleEditorTemplateId = (editorVal) => {
        console.log(editorVal);
        let editorValOrg = editorVal;
        editorValOrg.Subject = editorVal.subject;
        editorValOrg.Body = editorVal.fileContentHTML;
        editorValOrg.NotificationSettingId = this.state.dropdowndefaultvalue;
        if (this.state.passresults) {
            this.setState({ passresults: false }, () => this.props.customnoticesave(editorValOrg, null, 'finalsave'))
        }
    }

    callTemplateSave = () => {
        if(!this.TemplateForm.current) {
           this.setState({routinghelpertext : 'Field is Required', routingerror : true})
        }
        else {
            this.TemplateForm.current.saveTemplate();
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.callbacksave != this.props.callbacksave) {
            this.setState({ passresults: true }, () => this.callTemplateSave())

        }
    }

    handleSave = (editorVal) => {
        const { options } = this.props;

        const { resultExists, result } = this.state;
        const { crud } = options;
        const { read, update } = crud;

        result.BodyStr = btoa(editorVal.toString());
        let url = ''
        if (!resultExists) {
            //
            if (this.props.options.tabprops) {
                url = update.put.url + this.props.match.params['Id'] + '/Add';
            }
            else {
                url = update.put.url + this.props.match.params.routeid + '/Add';
            }
            let finalresult = { ...result }
            PostData(url, finalresult).then(res => {
                this.setState({ resultExists: false })

                if (!this.props.options.tabprops) {
                    this.props.onClose();
                }

            }).catch(error => {


            })
        }
        else {
            //
            if (this.props.options.tabprops) {
                url = update.put.url + this.props.match.params['Id'] + '/Update';
            }
            else {
                url = update.put.url + this.props.match.params.routeid + '/Update';
            }
            let finalresult = { ...result, Rank: this.state.Rank }
            PutData(url, finalresult).then(res => {
                this.setState({ resultExists: false })


                if (!this.props.options.tabprops) {
                    this.props.onClose();
                }

            }).catch(error => {


            })
        }

    }

    handleFieldChange = (event) => {
        if(event.target.value) {
         this.props.handleFieldChange({target : {name : 'RoutingNotification' , value :event.target.value }});
        this.setState({
            routinghelpertext : null,
            routingerror : false,
            [event.target.name]: event.target.value,
            result: {
                ...this.state.result,
                [event.target.name]: event.target.value,
                NotificationSettingId: event.target.value,

            }
        }, () => this.getTemplateDetails(event.target.value))
    }
    }

    getTemplateDetails = (settingid) => {
        const { options } = this.props;
        const { editorState, routeProps } = this.state;
        const { crud } = options;
        const { read, update } = crud;
        let templateurl = read.get.templateurl + settingid;

        GetData(templateurl).then(result => {
            if (result && Array.isArray(result)) {
                let templateid = result[0].TemplateId
                //this.setState({ templateid :  templateid })
                // Get applicaiton field record details and send it to template
                let sitePermitTypeId = routeProps.filter(a => a.key == 'routeid')[0].value;//383;
                let recordId = routeProps.filter(a => a.key == 'routeid1')[0].value;//23627;
                let reqbody = {
                    'sitePermitTypeId': sitePermitTypeId,
                    'masterTableId': recordId,
                    'SideBarDataItems': {},
                    'sideBarItems': []
                }
                let datarequrl = read.get.recorddataurl;
                // let applicationdataurl = {
                //     url: getEnvVariable('Application') + `/api/Application/FieldValues`,
                //     type: 'post',
                //     alert: true
                // }
                PostData(datarequrl, reqbody, true).then(res => {
                    this.setState({ fieldvaluedata: res, templateid: templateid }, () => {
                        this.setState({ templateFormats: true });
                    });
                }).catch(error => {
                    error.then(err => {
                        if (err) { //correct: unused tempResult,  need to define
                            // tempResult.query = JSON.parse(tempResult.JsonQueryStr)
                            // setResult(tempResult)
                            // onError(err);
                        }
                    })
                })
            }
        }).catch(error => {
            console.log(error);
        })
    }

    handleBack = () => {
        const { match } = this.props
        if (this.props.options.tabprops) {
            this.props.onClose();
            this.props.handleCustomBack();
        }
        else {
            let url = match.url
            let final = url
            final = url.substring(0, url.lastIndexOf("/"))
            this.props.history.push(final)
        }
    }

    templateFormatsCallBack = (templatedata) => {
        console.log(templatedata);
    }

    render() {
        const { options, hidesub } = this.props;
        const { headerProps, title, fullheight, designprops } = options;
        const { savebutton, editbutton, backbutton, closebutton, breadcrumbs, sendbutton, nonstickyposition, navigateup, navigatedown, duplicatebutton } = headerProps ? headerProps : {}
        const { editorState, result, dropdowndefaultvalue } = this.state
        return (
            <div>
                <div className="pl-4 col-sm-12">
                    <CFormDropDown value={this.state.dropdowndefaultvalue}
                        error = {this.state.routinghelpertext}
                        name="dropdowndefaultvalue"
                        label="Select Routing Notification"
                        required={this.props.required}
                        handleFieldChange={this.handleFieldChange} helperText={this.state.routinghelpertext} edit={true} dropdownvalues={this.state.dropdowndata} />
                </div>
                {dropdowndefaultvalue !== '' && <div className="col-sm-12">
                    <TemplateForm parentid={this.state.templateid} hasSubject={true}  disabled={true}
                        isEnabled={false} mentions={null} entryData={this.state.fieldvaluedata} showComments={false}
                        ref={this.TemplateForm}
                        handleTemplateId={(editorVal) => { this.handleEditorTemplateId(editorVal) }}
                        isadmin={false} fullheight={fullheight}
                    />
                </div>}

            </div>
        )
    }
}

const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

CTemplateLoader = connect(null, mapActions, null, { forwardRef: true })(CTemplateLoader)
export default withRouter(CTemplateLoader)