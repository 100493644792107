import React from 'react';
import { RequestTaskExecute } from '../../modules/map/util/geometryEngineTask';
import { DeleteRecord, GetData, PostData, PostFormData, PutData, OldPostMutiFormData } from '../../controls/helpers/requests';

/**
      *********************************************************
     Deprecated
      *********************************************************
**/
export const checkcomp = (value, rules, evname, Passer) => {
    const { events } = rules
    return passcomp(value, events[evname], Passer)
}

/**
      *********************************************************
     Deprecated
      *********************************************************
**/

const passcomp = (targetval, values, Passer) => {
    let allpasses = []
    if (values) {
        if (values.length > 0) {
            const foundval = values.find(each => {
                const { value } = each.value
                return targetval === value
            })
            if (foundval) {
                const { scope } = foundval
                if (scope.length > 0) {
                    scope.forEach((each, index) => {
                        const { action, addupdate, save, del, style } = each
                        if (Passer) {
                            allpasses.push(<Passer type={action} message={addupdate || save || del || style} key={index + 'passer'} />)
                        }
                    })
                }
            }
        }
        else {
            const { scope } = values
            if (scope.length > 0) {
                scope.forEach((each, index) => {
                    const { action, addupdate, save, del, style } = each
                    if (Passer) {
                        allpasses.push(<Passer type={action} message={addupdate || save || del || style} key={index + 'passer'} />)
                    }
                })
            }
        }

    }
    return allpasses

}
export const requestApiFormData = (rest, id, results, formdata, alert = false, message) => {
    const { get, post, put, remove, geom } = rest ? rest : {}
    if (post) {
        const requestparams = postDataFetch(post, results)
        let requestUrl = post.url

        if (id && Array.isArray(id) && (post.matchProps || post.routeProps || post.customProps || post.customextraProps) && Array.isArray(post.matchProps || post.routeProps || post.customProps || post.customextraProps)) {
            requestUrl += post.urlpath
            id.forEach(each => {
                const { key, value } = each;
                requestUrl = `${requestUrl.replace(`{${key}}`, value)}`;
            })
        }
        else if (id && !Array.isArray(id) && (post.matchProps || post.routeProps || post.customProps || post.customextraProps)) {
            (post.matchProps || post.routeProps || post.customProps || post.customextraProps) && id && (requestUrl += '/' + id)
        }

        if (formdata) return OldPostMutiFormData(requestUrl, formdata, alert)
        else return OldPostMutiFormData(requestUrl, formdata, alert, message)
    }
}
/**
      *********************************************************
      Description: handles the requests: get,post,put. In the request, if there is a matchProps or routeProps, we replace the matching keys in the url
        with values of results that matches the results prop or id.
      Required props:  rest. It should have get,post,put key whose value is an object. This value is used to generate the url.
      limitations    : N/A
      *********************************************************
**/

export const requestApi = (rest, id, results, formdata, alert = false, message) => {
    const { get, post, put, remove, geom } = rest ? rest : {}
    const del = rest ? rest.delete : null
    if (get) {
        const requestparams = getDataFetch(get, id, results)
        return GetData(get.url + requestparams)
    }
    if (post) {
        const requestparams = postDataFetch(post, results)
        let requestUrl = post.url

        if (id && Array.isArray(id) && (post.matchProps || post.routeProps || post.customProps || post.customextraProps) && Array.isArray(post.matchProps || post.routeProps || post.customProps || post.customextraProps)) {
            requestUrl += post.urlpath
            id.forEach(each => {
                const { key, value } = each;
                requestUrl = `${requestUrl.replace(`{${key}}`, value)}`;
            })
        }
        else if (id && !Array.isArray(id) && (post.matchProps || post.routeProps || post.customProps || post.customextraProps)) {
            (post.matchProps || post.routeProps || post.customProps || post.customextraProps) && id && (requestUrl += '/' + id)
        }

        if (formdata) return PostFormData(requestUrl, requestparams, alert)
        else return PostData(requestUrl, requestparams, alert, message)
    }
    if (put && results) {
        const requestparams = putDataFetch(put, results)
        var requestUrl = put.url;

        if (Array.isArray(id) && (put.matchProps || put.routeProps || put.customProps || put.passingProps) && Array.isArray(put.matchProps || put.routeProps || put.customProps)) {
            requestUrl += put.urlpath
            id.forEach(each => {
                const { key, value } = each;
                requestUrl = `${requestUrl.replace(`{${key}}`, value)}`;
            })
        }
        else if (!Array.isArray(id) && put.matchProps) {
            (put.matchProps || put.routeProps) && id && (requestUrl += '/' + id)
        }
        else if (put.customProps) {
            (put.customProps) && id && (requestUrl += '/' + id)
        }

        return PutData(requestUrl, requestparams, alert,message)
    }
    if (geom) {
        return RequestTaskExecute(geom.url)
    }

    if (remove && results) {
        const requestparams = removeDataFetch(remove, id, results)
        return DeleteRecord(remove.url, requestparams)
    }


}

/**
      *********************************************************
      Description: Generates the post request url. In the request, if there is a body, replaces the matching keys with values from results or else results are embedded
        in the body.
      Required props:  post.
      limitations    : N/A
      *********************************************************
**/

const postDataFetch = (post, results) => {
    let bodyrequest = {}
    const { body } = post
    if (body && (body.length > 0)) {
        body.forEach(each => {
            if (each.value || results) bodyrequest[each.key] = each.value || results
            if (each.fieldName) bodyrequest[each.key] = results[each.fieldName]
        })
    }
    else {
        bodyrequest = results
    }
    return bodyrequest
}

/**
      *********************************************************
      Description: Generates the post request url. In the request, if there is a body, replaces the matching keys with values from results or else results are embedded
        in the body.
      Required props:  post.
      limitations    : N/A
      *********************************************************
**/

const putDataFetch = (put, results) => {
    let bodyrequest = {}
    const { body } = put
    if (body && (body.length > 0)) {
        if (body.every(each => {
            return !each.formName
        })) {
            body.forEach(each => {
                if (each.value || results) bodyrequest[each.key] = each.value || results
                if (each.fieldName) bodyrequest[each.key] = results[each.fieldName]
            })
        }
        else {
            // if (fetchData) {
            //     fetchData(rest)
            // }
        }
    }
    else {
        bodyrequest = results
    }

    return bodyrequest
}

/**
      *********************************************************
      Description: Generates the get request url.In the request, if there is a matchProps or routeProps or extraProps, we replace the matching keys in the url
        with values of results that matches the results prop or id. Query handles the query parameters by replacing keys with values from results.
         Suburl is part of the url where
        some values are  inserted before it.
      Required props:  post.
      limitations    : N/A
      *********************************************************
**/
const removeDataFetch = (remove, id, results) => {
    let getrequestparams = ''
    const { query, matchProps, gridProps, routeProps, extraProps, suburl, urlpath, subquery } = remove
    if (urlpath) getrequestparams = urlpath
    if (matchProps || routeProps || extraProps) {
        if (Array.isArray(id)) {
            id.forEach(each => {
                const { key, value } = each
                //let parameterNameregex = new RegExp('{' + key + '}');
                //getrequestparams = getrequestparams.replace(parameterNameregex, value);
                getrequestparams = `${getrequestparams.replace(`{${key}}`, value)}`;
            })
        }
        else {
            if (id && !Array.isArray(id)) getrequestparams = getrequestparams + '/' + id
            else {
                if (results) getrequestparams = getrequestparams + '/' + results[matchProps.value]
            }
        }
    }
    if (gridProps) {
        if (results) getrequestparams = getrequestparams + '/' + results[gridProps.value]
    }
    if (subquery) {
        if (results) getrequestparams = getrequestparams + '?' + subquery.name + '=' + results[subquery.value]
    }

    if (suburl) {
        getrequestparams = getrequestparams + suburl
    }
    if (query) {
        getrequestparams += '?'
        query.forEach((each, index) => {
            if (index === 0) {
                if (each.value) getrequestparams += each.key + '=' + each.value
                if (each.fieldName && results) getrequestparams += each.key + '=' + results[each.fieldName]
            }
            else {
                if (each.value !== undefined) getrequestparams += '&' + each.key + '=' + each.value
                if (each.fieldName && results) getrequestparams += '&' + each.key + '=' + results[each.fieldName]
            }
        })
    }

    return getrequestparams
}


/**
      *********************************************************
      Description: Generates the get request url.In the request, if there is a matchProps or routeProps or extraProps, we replace the matching keys in the url
        with values of results that matches the results prop or id. Query handles the query parameters by replacing keys with values from results. Suburl is part of the url where
        some values are  inserted before it.
      Required props:  post.
      limitations    : N/A
      *********************************************************
**/

const getDataFetch = (get, id, results) => {
    let getrequestparams = ''
    const { query, matchProps, gridProps, routeProps, extraProps, customProps, passingProps, customextraProps, initialloadextrainfoProps, suburl, urlpath, subquery } = get
    if (urlpath) getrequestparams = urlpath
    if (matchProps || routeProps || extraProps || customProps || customextraProps || passingProps || initialloadextrainfoProps) {
        if (Array.isArray(id)) {
            id.forEach(each => {
                const { key, value } = each
                //let parameterNameregex = new RegExp('{' + key + '}');
                //getrequestparams = getrequestparams.replace(parameterNameregex, value);
                getrequestparams = `${getrequestparams.replace(`{${key}}`, value)}`;
            })
        }
        else {
            if (id && !Array.isArray(id)) getrequestparams = getrequestparams + '/' + id
            else {
                if (results && matchProps) getrequestparams = getrequestparams + '/' + results[matchProps.value]
                if (results && !matchProps) getrequestparams = getrequestparams + '/' + results['Id']
            }
        }
    }
    if (gridProps) {
        if (Array.isArray(id)) {
            id.forEach(each => {
                const { key, value } = each
                getrequestparams = `${getrequestparams.replace(`{${key}}`, value)}`;
            })
        }
        else {
            if (id && !Array.isArray(id)) getrequestparams = getrequestparams + '/' + id
            else {
                if (results) getrequestparams = getrequestparams + '/' + results[gridProps.value]
            }
        }
    }
    if (subquery) {
        if (results) getrequestparams = getrequestparams + '?' + subquery.name + '=' + results[subquery.value]
    }

    if (suburl) {
        getrequestparams = getrequestparams + suburl
    }
    if (query) {
        getrequestparams += '?'
        query.forEach((each, index) => {
            if (index === 0) {
                if (each.value) getrequestparams += each.key + '=' + each.value
                if (each.fieldName && results) getrequestparams += each.key + '=' + results[each.fieldName]
            }
            else {
                if (each.value !== undefined) getrequestparams += '&' + each.key + '=' + each.value
                if (each.fieldName && results) getrequestparams += '&' + each.key + '=' + results[each.fieldName]
            }
        })
    }

    return getrequestparams
}



const initvalues = {
    formdropdown: '',
    formtextbox: '',
    formcheckbox: false,
    radio: false,
    text: '',
    dtext: ''
}
const fieldtransformProps = {
    formcheckbox: {
        key: 'checked'
    },
    text: {
        key: 'text'
    },
    dtext: {
        key: 'text'
    },
    formdropdown: {
        key: 'value'
    },
    formtextbox: {
        key: 'value'
    },
    popupbutton: {
        key: 'label'
    }
}
const formstyles = {
    formcontainer: {
        //margin: 15
    },
    formrow: {
        margin: 10
    },

    itemrow: {
        margin: 0
    }
}

const hidestyles = {
    formcontainer: {
        //margin: 15
    },
    formrow: {
        //margin: 10
    },

    itemrow: {
        margin: 0
    }
}
/**
      *********************************************************
      Description: handles form layout generation and updates the field values based on name and value.
        receivevalues is used by child component to receive values from parent.
        cacheColProps is used to save the configurations of each child component. The name is used as key and configuration details are the properties.
        cache is an array which contains all the rows.
      Required props:  commap,components, options. Within the components array there are subcomponents and the properties type, options are required. name is required within the
        subcomponent options prop.
      limitations    : Can be used to hide or disable but cannot be used to insert rows.
      *********************************************************
**/

export const createSubFormLayout = (props) => {
    const { components, compmap, options, type, handleFieldChange, results, effects, res, ...otherProps } = props
    const { totalrows, totalcolumns, items, className, styles } = options ? options : {}
    const allComponents = components
    let cache = [], cacheColProps = {}, cacheRowProps = [], itemslen = 0, allRows = [], cacheHiddenRowProps = [], hideMargin = false, customclassName = ''
    if (items) itemslen = items.length
    const rows = totalrows || (allComponents.length + itemslen)
    let hidemarginRow = {}
    allComponents.forEach((each, index) => {
        const { type, options, order, key, hidemargin, customClassName = '' } = each
        const { width, name, initialvalue, values, receiveValues, ...otherOptions } = options
        const show = effects ? (effects[name] ? (effects[name].show ? 'block' : 'none') : 'block') : 'block'
        const hide = effects ? (effects[name] ? (effects[name].hide ? 'none' : 'block') : 'none') : 'block'
        const load = effects ? (effects[name] ? (effects[name].load ? effects[name].load : null) : null) : null
        const divstyle = {
            display: show
        }
        hideMargin = hidemargin || false;
        customclassName = customClassName || ''
        let dispClassName = ''
        if (show === 'block') dispClassName += ' d-block'
        if (show === 'none') dispClassName += ' d-none'
        const { styles } = options
        const compClassName = options.className ? options.className : ''
        const formControlsConfigs = {}, storeFormControlInitValues = {}
        formControlsConfigs[name] = { name: name, type: type, ...otherOptions }
        if (results) {
            storeFormControlInitValues[name] = {
                ...results[name]
            }
        }
        else {
            storeFormControlInitValues[name] = {
                value: (initialvalue || initvalues[type]),
                error: false,
                touched: 0,
                dropdownvalues: values ? values : null
            }
        }

        let extraProps = {}
        receiveValues && receiveValues.forEach(each => {
            const { paramname, valuefromparent } = each
            extraProps[paramname] = res[valuefromparent]
        })

        const EachComp = compmap[type]
        const compwidth = width ? ('col-sm-' + width) : 'col-sm-12'
        const row = totalrows ? (options.row === 0 ? 0 : (options.row || (order - 1) || index)) : ((order - 1) || index)


        cache[row] || (cache[row] = [])

        cacheColProps[name] = {
            ...each,
            ...otherProps,
            comprow: row,
            compcol: cache[row].length,
            className: compwidth + ' ' + compClassName,
            compmap: compmap,
            handleFieldChange: handleFieldChange,
            ...formControlsConfigs[name],
            ...storeFormControlInitValues[name],
            key: key,
            style: customclassName ? null : { ...styles },
            extraProps: { ...extraProps }
        }
        hidemarginRow[row] = hideMargin ? true : false
        const { className, ...compAttr } = cacheColProps[name]
        cache[row].push(
            <div className={className + cacheColProps[name].display + dispClassName} key={key}>
                <EachComp {...compAttr} />
            </div>
        )

        if (cache[row].length === 1) {
            if (dispClassName === ' d-none') {
                cacheHiddenRowProps[row] = {
                    className: 'row d-none'
                }
            }
        }
        else {
            cacheHiddenRowProps[row] = {
                className: 'row ' + customClassName
            }
        }
    })

    for (let currentrow = 0; currentrow <= rows; currentrow++) {
        const rowProps = {
            className: ' row', key: 'fieldrow' + currentrow,
            style: hidemarginRow[currentrow] ? null : hideMargin ? hidestyles.formrow : formstyles.formrow, ...cacheHiddenRowProps[currentrow]
        }
        cacheRowProps[currentrow] = rowProps
        allRows.push(
            <div {...rowProps}>
                {cache[currentrow]}
            </div>
        )
    }

    /**
      *********************************************************
      Description: Memoization function. Return a functions which when called returns a new layout with update values of form fields. In react, since the children
        are an array, positions and properties of each child are mapped against names. When the value in a form field changes, mapped postion and properties are fetched and
        updated with new values.
      Required props: fieldNames is an array. FieldNames contains the changed field values and name is required to update.
      limitations    : N/A
      *********************************************************
    **/

    return (fieldNames) => {
        if (fieldNames && fieldNames.length > 0) {
            fieldNames.forEach(changedFieldName => {
                const { name, value, effect, ...otherFieldProps } = changedFieldName
                if (name && name != "undefined") {
                    const compProps = cacheColProps[name]
                    //     console.log(compProps && compProps.type)
                    const EachComp = compProps && compProps.type ? compmap[compProps.type] : console.log("here is the issue in utilities " + compProps, compProps.type)
                    const { comprow, compcol, key, ...otherCompProps } = compProps
                    let rowProps = cacheRowProps[comprow]

                    cacheColProps[name] = {
                        ...cacheColProps[name],
                        ...otherFieldProps
                    }
                    let { className, ...compAttr } = cacheColProps[name]

                    let rowDisplayProps = ' '

                    if (cache[comprow].length === 1) {
                        rowDisplayProps = cacheColProps[name] && cacheColProps[name].display &&
                            (cacheColProps[name].display).includes("d-none") ? `row  d-none` : `row ` + cacheColProps[name].customClassName
                        // if (dispClassName === ' d-none') {
                        //     cacheHiddenRowProps[row] = {
                        //         className: 'row d-none'
                        //     }
                        // }
                    }
                    else {
                        rowDisplayProps = "row " + cacheColProps[name].customClassName
                    }


                    rowProps = { ...rowProps, className: rowDisplayProps }
                    cache[comprow][compcol] = <div className={className + cacheColProps[name].display} key={key}>
                        <EachComp key={key}  {...compAttr} value={value} />
                    </div>


                    allRows[comprow] = <div {...rowProps}>
                        {cache[comprow]}
                    </div>
                }
            })
        }
        return <div>{allRows}</div>
    }

}
