import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { loadModules } from 'esri-loader';
import { TaxParcelTask, TaxParcelTaskFeatureLayer } from '../eps/taxParcelTask';
import { MapConstants } from '../util/mapConstants';
import { QueryTask, QueryTaskBufferWithGeom, CenterAndZoom, EsriRequest } from '../util/queryTask';
import loadingimg from '../../../resources/gis-loading.gif'
import { xyToLngLat, getLatLong, GetArea, getXYFromGeom } from '../util/mercatorUtils'

const gridFields = [
    "OBJECTID",
    "Taxlot",
    "MapTaxlot",
    "MapNumber",
    "County"
];


let queryResponse
let gridtaxlots
class CCompMultiLocationBufferCalc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: this.props.view,
            units: "feet",
            distance: "3000",
            selected:[]
        }
        this.startup(
            this.props
        );

        this.bufferSearch = this.bufferSearch.bind(this);
        //this.selectTaxlotWithinBuffer = this.selectTaxlotWithinBuffer.bind(this);
    }

    defaultGridColumns() {
        return [
            { "field": "RTUnique", "label": "Route#", "sortable": true, get: function(object){
                let a = object.RTUnique.split('-');
                let result="";
                if(a.length > 1){
                   result = result + a[1].trim();
                }
                if(a.length > 2){
                    result = result + ' ' + a[2].trim();
                }
                if(a.length > 4){
                    result = result + ' ' + a[3].trim();
                }
                
                return result.toString()
            } 
        },
        { "field": "Routename", "label": "Route Name", "sortable": true },
        { "field": "CountyName", "label": "County", "sortable": true },
        { "field": "Distance", "label": "Distance(ft)", "sortable": true, get: function(object){
                return object.Distance.toFixed(2);
            } 
        },       
        { "field": "MilePoint", "label": "Mile Point", "sortable": true},
        { "field": "RTUnique", "label": "Route Unique Number", "sortable": true}
        ]
    }

    componentDidUpdate(prevProps, prevState){        
        if(this.props.coordinates.longitude != prevProps.coordinates.longitude){
            this.bufferSearch(this)          
        }
        if(this.props.clearsketch != prevProps.clearsketch){
            let inthis = this;
            setTimeout(function(){ 
                inthis.projectGridData([])
            }, 5000);            
        }
    }

    // componentDidMount(){
    //     this.bufferSearch(this);
    // }

    startup = (config) => {
        loadModules([
            "dgrid/OnDemandGrid",
            "dgrid/extensions/ColumnHider",
            "dgrid/Selection"
        ]).then(([OnDemandGrid, ColumnHider, Selection]) => { 
            if(document.getElementById("multilocationbuffererror"))
                document.getElementById("multilocationbuffererror").innerHTML = ""
                
            if(document.getElementById("multiLocationFeatureCount"))
                document.getElementById("multiLocationFeatureCount").innerHTML = ""
            
            if(document.getElementById("multiLocationSaveMsg"))
                document.getElementById("multiLocationSaveMsg").innerHTML = ""
            const inThis = this;            
            let columns = this.defaultGridColumns();
            // create a new onDemandGrid with its selection and columnhider
            // extensions. Set the columns of the grid to display attribute           
            this.grid = new (OnDemandGrid.createSubclass([Selection, ColumnHider]))(
                { columns: columns },
                inThis.props.parentcal + "grid"
            );
            // add a row-click listener on the grid. This will be used
            // to highlight the corresponding feature on the view
            this.grid.on("dgrid-select", (event) => this.selectFeatureFromGrid(event, this));
            this.bufferSearch(this);
        });
       
    }



    bufferSearch = (inthis) => {
        
        if(document.getElementById("multilocationbuffererror"))
            document.getElementById("multilocationbuffererror").innerHTML = ""
            
        if(document.getElementById("multiLocationFeatureCount"))
            document.getElementById("multiLocationFeatureCount").innerHTML = ""
        
        if(document.getElementById("multiLocationSaveMsg"))
            document.getElementById("multiLocationSaveMsg").innerHTML = "" 
            
        if(inthis.projectGridData)
            inthis.projectGridData([])
             
        inthis.showLoading(true);       
        let distance = inthis.state.distance;
        let x = inthis.props.coordinates.longitude;//-84.48977212775438;
        let y = inthis.props.coordinates.latitude; // 38.0379900098567;
        getXYFromGeom(inthis.props.coordinates.graphic.geometry).then(res =>{
            if(res.x && res.y){                
                let queryTaskConfigMultiLocation = {
                    url: `https://maps.kytc.ky.gov/arcgis/rest/services/MeasuredRoute/MapServer/exts/RouteMetrics/GetMultipleRouteInfo?X=${res.x}&Y=${res.y}&SearchRadius=${distance}`
                }
                EsriRequest(queryTaskConfigMultiLocation, null , 102763).then(
                    response => {
                        inthis.showLoading(false);
                        if (response && response.RouteInfos && response.RouteInfos.length > 0) {  
                            const routeUNQ = response.RouteInfos.filter(a => a.GovLevelCode == '01');   
                            //console.log(routeUNQ)               
                            inthis.processResponse(routeUNQ)
                        }
                })
            }
        })
                
    }

    processResponse(response) {
        queryResponse = response
        //let allAttributes = response.features.map(a => a.attributes);
        this.projectGridData(response)        
    }
   

    projectGridData(data) {
        loadModules([
            "dstore/Memory",
            "dstore/legacy/StoreAdapter",
            "dgrid/OnDemandGrid",
            "dgrid/extensions/ColumnHider",
            "dgrid/Selection",
            "dijit/registry"
        ]).then(([Memory, StoreAdapter, OnDemandGrid, ColumnHider, Selection,registry]) => {
            let columns = this.defaultGridColumns() //this.props.bufferCalcLayer && this.props.bufferCalcLayer.GridTemplate ? this.props.bufferCalcLayer.GridTemplate : this.defaultGridColumns()
            // create a new onDemandGrid with its selection and columnhider
            // extensions. Set the columns of the grid to display attribute       
            let gridid = this.props.parentcal + "grid";
            var theGrid = registry.byId(gridid);
            if(data && Array.isArray(data)){
                //dggrid are depend on the id, adding uniuqe details if not exists
                data.forEach(rec => {
                    if(!rec.id){
                        rec.id = rec.RTUnique
                    }
                });
            }
            if(this.grid){
                this.grid.set("collection",  new Memory({
                    data: data
                })); 
            }else{
                this.grid = new (OnDemandGrid.createSubclass([Selection, ColumnHider]))(
                    { columns: columns },
                    this.props.parentcal + "grid"
                );
                this.grid.on("dgrid-select", (event) => this.selectFeatureFromGrid(event, this));
            }
                       
        })
    } 
    

   


    /************************************************
     * fires when user clicks a row in the grid
     * get the corresponding graphic and select it
     *************************************************/
    selectFeatureFromGrid(event, inthis) {
        // get the ObjectID value from the clicked row
        const row = event.rows[0];
        const RTUnique = row.data.RTUnique;
        const MilePoint = row.data.MilePoint;
        const routeUNQ = queryResponse.filter(a => a.RTUnique === RTUnique);
        console.log(routeUNQ);
        if(document.getElementById("multiLocationFeatureCount"))
            document.getElementById("multiLocationFeatureCount").innerHTML = "Selected route "+RTUnique+" with milepoint "+MilePoint; 
        let requestURL = `https://maps.kytc.ky.gov/arcgis/rest/services/MeasuredRoute/MapServer/exts/RouteMetrics/GetXYByRouteAndMP?RT_UNIQUE=${RTUnique}&MP=${MilePoint}`
        let requestConfig = {
            url: requestURL
        } 
        
        EsriRequest(requestConfig, null , 102763).then(
            response => {
                inthis.showLoading(false);
                if (response && response.XYResults && response.XYResults.length > 0) {   
                    let firstres =  response.XYResults[0];                   
                    let selected = [];
                    let selecteddetail = routeUNQ[0];
                    delete selecteddetail.id;
                    selecteddetail.X = firstres.x;
                    selecteddetail.Y = firstres.y;                   
                    console.log('selected detail with XYYYYYYYY', selected);                     
                    // xyToLngLat( { x: firstres.x, y: firstres.y}, 102763).then(latlong => {
                    //     let msg = "Selected route "+RTUnique+" with milepoint "+MilePoint+" lat/long "+latlong.lat+"/"+latlong.long; 
                    //     document.getElementById("multiLocationFeatureCount").innerHTML =  msg;
                    //     selecteddetail.Longitude = latlong.long;
                    //     selecteddetail.Latitude = latlong.lat;
                    //     selected.push(selecteddetail);
                    //     inthis.setState({selected: selected});   
                    // })
                    getLatLong(firstres.x, firstres.y) .then(latlong =>{
                        let msg = "Selected route "+RTUnique+" with milepoint "+MilePoint+" lat/long "+latlong.lat+"/"+latlong.long; 
                        if(document.getElementById("multiLocationFeatureCount"))
                            document.getElementById("multiLocationFeatureCount").innerHTML =  msg;
                        console.log('selected graphics', inthis.props.coordinates.graphic);
                        GetArea(inthis.props.coordinates.graphic.geometry).then(areares => {
                            console.log(areares)
                            selecteddetail.Longitude = latlong.long;
                            selecteddetail.Latitude = latlong.lat;
                            selecteddetail.GeomLongitude = inthis.props.coordinates.longitude;
                            selecteddetail.GeomLatitude = inthis.props.coordinates.latitude;
                            selecteddetail.GeomType = inthis.props.coordinates.type;
                            selecteddetail.GeomArea = areares;
                            selecteddetail.RouteUniqueNumber = RTUnique;
                            selecteddetail.Status = 'A';
                            selected.push(selecteddetail);                            
                            if(inthis.props.coordinates.graphic){
                                inthis.props.coordinates.graphic.setAttribute("MultipleLocations", selected)
                            }
                            inthis.setState({selected: selected});
                        })
                    })        
                    //inthis.processResponse(response.RouteInfos)
                }
        })

    }

    

    errorCallback(error) {
        console.log("error:", error);
    }

    showLoading(canShow) {
        let dom = document.getElementById("multiLocLoadingImg");
        if (dom) {
            dom.style.display = canShow ? "" : "none";
        }
    }


    updateState(event, inthis) {
        
        if(document.getElementById("multilocationbuffererror"))
            document.getElementById("multilocationbuffererror").innerHTML = ""
        let name = event.target.name
        let val = event.target.value
        inthis.setState({ [name]: val })
    }

   

    clearTaxLots(inthis) {
        inthis.projectGridData([])
    }

    saveSelection(inthis) {
        inthis.showLoading(true);
        console.log(inthis.state.selected);
        //Currently saving only one selection can make it multiple on requirement
        let currentResult = inthis.state.selected[0] ;
        currentResult.RouteUniqueNumber = currentResult.RTUnique;
        this.props.multipleLocationHandler(currentResult);        
        if(document.getElementById("multiLocationSaveMsg"))
            document.getElementById("multiLocationSaveMsg").innerHTML =  "Saved Successfully !!";
        inthis.showLoading(false);
    }

    render() {
        let layerkey = this.props.layerkey || -1;
        return (
            <div id={"cbuffereditArea" + layerkey} className="map-customexpand-container Buffer-box ">
                <div id="addFeatureDiv" >
                    <p>
                        <h6 class="list-heading"><b>Search and select location within buffer</b></h6>
                        <b> <h5> <span class="info" id="multiLocationSaveMsg" style={{ "color": "green" }}></span> </h5> </b>
                    </p>
                    <div id="addTemplatesDivBuffer1" style={{ "marginTop": "2px" }}>
                        <label id="multilocationbuffererror" style={{ "color": "red" }}></label>
                    </div>
                    <div id="addTemplatesDivBuffer2" style={{ "marginTop": "1px" }}>
                        <label style={{ "width": "15%" }}>Distance:</label>
                        <input style={{ "width": "20%", "height": "30px" }} id="distance" type="number" name="distance" min="1" value={this.state.distance} onChange={(event) => this.updateState(event, this)} />

                    &nbsp;

                        <label style={{ "width": "10%" }}>Units:</label>
                        <select style={{ "width": "20%", "height": "30px" }} id="units" name="units" value={this.state.units} onChange={(event) => this.updateState(event, this)}>
                            <option value="feet">Feet (ft)</option>
                            {/* <option value="meters">Meters (m)</option>
                            <option value="kilometers">Kilometers (km)</option>
                            <option value="miles">Miles (mi)</option>
                            <option value="us-nautical-miles">Nautical Miles (NM -US)</option>
                            <option value="nautical-miles">Nautical Miles (NM)</option> */}
                        </select>
                        <button id="buffersearch" style={{ "margin-left": "4px", "background": "#bff3bf" }} type="button" class="my_button buffersearch" onClick={(inthis) => this.bufferSearch(this)}>Search</button>
                        <button id="selecttaxlots" style={{ "margin-left": "4px", "background": "#f1b7b7" }} type="button" class="my_button selecttaxlots" onClick={(inthis) => this.clearTaxLots(this)}>Clear</button>
                        {/* <button id="saveselection" style={{ "margin-left": "4px", "background": "#a3c7f3" }} type="button" class="my_button saveselection" onClick={(inthis) => this.saveSelection(this)}>Save</button> */}

                        <img id="multiLocLoadingImg" src={loadingimg} style={{ "height": "30px", "display": "none" }} />

                    </div>
                    <div id="addTemplatesDivBuffer3" style={{ "marginTop": "3px" }}>

                    </div>

                    {/* Gridview for the selected buffer */}
                    <div id="gridDisplay">
                        <span class="info" id="multiLocationFeatureCount" style={{ "color": "blue" }}></span>                        
                        <div id={this.props.parentcal + "grid"}></div>
                    </div>
                </div>
            </div>
        );
    }
} 

export default CCompMultiLocationBufferCalc;