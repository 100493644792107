/**
      *********************************************************
      Description: Switch component.
      Required props:  type, value
      limitations    : N/A
      *********************************************************
**/
import React, { Component, Fragment } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText'
import { green, red } from '@material-ui/core/colors';
import FormControl from '@material-ui/core/FormControl';

const styles = {
    root: {
        flexGrow: 1,
    },
};

const GreenSwitch = withStyles({
    switchBase: {
        color: red[800],
        '&$checked': {
            color: green[700],
        },
        '&$checked + $track': {
            backgroundColor: green[700],
        },
    },
    checked: {},
    track: {},
})(Switch);

const FormSwitch = (props) => {
    const { handleFieldValue, value, name, error, label, extraProps, disabled, edit, conditions, helperText, LabeltransformProps } = props;
    const {transformProps} = extraProps
    let disabledstatus = conditions && conditions.enable ? conditions.enable : disabled;
    const handlefieldchange = (e) => {
        const { name, checked } = e.target
        let value = transformProps[checked]
        const { dataset } = e.currentTarget;
        handleFieldValue({ target: { name: name, value: value, checked: checked }, currentTarget: { dataset: dataset } })
    }
    return (
        <Fragment>
            <FormControl required={props.required} error={error || false}>
                <FormControlLabel
                    control={
                        <GreenSwitch
                            checked={value ? (transformProps ? transformProps[value] : value) : false}
                            onChange={handlefieldchange}
                            name={name}
                            disabled={disabledstatus || false}
                        />
                    }
                    label={<span style={{ fontSize: '12px' }}>{value ? (LabeltransformProps ? LabeltransformProps[value] : (label || '')) : label}</span>}
                    />
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </Fragment>
    );
}


export default withStyles(styles)(FormSwitch);