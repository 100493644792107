import { Droppable } from "react-beautiful-dnd";
import CSectionDraggable from './cSectionDraggable';
import * as DNDSettings from './util/dndSettings';

const CSubSectionsContainer = ({ sectionConfig, mainSections, errors, direction, clazzName, wrapperWlazzName, getSubSections,
  droptype, isSectionsOpen, OnAddSubSection, OnAddRow, OnMoveDown, OnMoveUp, CanShowMoveUp, CanShowMoveDown, OnSectionSettings, OnSectionRemove, OnRowSettings }) =>

  < Droppable droppableId={`Sec-${droptype}`} type="section" direction={direction} >

    <div>
      {(sp, ss) => (
        <div ref={sp.innerRef} className={clazzName}
          style={DNDSettings.getListStyle(ss.isDraggingOver)}>
          <div className={wrapperWlazzName}>
            {mainSections && mainSections.length > 0 && mainSections.map((section, index) => (
              section && <div><CSectionDraggable sectionConfig={sectionConfig} section={section} getSubSections={getSubSections} draggable={index} OnSectionSettings={OnSectionSettings} OnSectionRemove={OnSectionRemove} OnRowSettings={OnRowSettings} OnAddSubSection={OnAddSubSection} OnAddRow={OnAddRow} OnMoveDown={OnMoveDown} OnMoveUp={OnMoveUp}
                CanShowMoveUp={CanShowMoveUp} errors={errors} CanShowMoveDown={CanShowMoveDown} isSectionsOpen={isSectionsOpen} /></div>
            ))}
            {sp.placeholder}
          </div>
        </div>
      )}
    </div>
  </Droppable >

export default CSubSectionsContainer;