import { loadModules } from "esri-loader";

import { requestApi } from "../../form/utilities";
import { QueryTask, CenterAndZoom } from "./queryTask";
import { GeometryEngineTask } from "./geometryEngineTask";
import { getEnvVariable } from "../../../../modules/environmentalList";

export function AddLayers(view, config, mapdata, mapkey, mapConstants, SubModuleKey, layersList, statusList) {
  return new Promise((resolve, reject) => {
    if (!loadModules) {
      reject("AddLayers: ArcGIS API is not loaded");
      return;
    }

    AddingLayers(view, config, mapdata, mapkey, mapConstants, SubModuleKey, layersList, statusList).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getpermittypeSymbol(permitTypecolor, statusColor) {
  const defaultSym = {
    type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
    style: "circle", color: statusColor || permitTypecolor, size: "10px", // 
    outline: { // autocasts as new SimpleLineSymbol() 
      color: statusColor || permitTypecolor, width: 3 // points
    }
  }
  // {
  //   type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
  //   color: permitTypecolor,
  //   size: 18,
  //   outline: {
  //     // autocasts as new SimpleLineSymbol()
  //     width: 0.5,
  //     color: statusColor,
  //   },
  //   path:
  //     "M24.00505,12.31835c-3.7701,0-6.81,3.05-6.81,6.81c0,0.14,0.01,0.28,0.0199,0.42     c0.03,0.56,0.13,1.12,0.31,1.66c0.1701,0.53,0.4,1.03,0.71,1.5c0.0201,0.03,0.2101,0.3,0.5,0.74c0,0,0,0,0.01,0.01     c1.3,1.96,4.73,7.09,4.75,7.12c0.24,0.36,0.7701,0.36,1.0101,0c0.01-0.02,0.0299-0.04,0.0399-0.05     c0.07-0.11,5.11-7.66,5.2701-7.89c0.0399-0.07,0.0899-0.14,0.13-0.21c0.2699-0.46,0.4699-0.95,0.6099-1.46     c0.17-0.6,0.25-1.22,0.25-1.84C30.80495,15.36835,27.75505,12.31835,24.00505,12.31835z M24.17495,22.57835     c-1.5,0.09-2.7899-1.05-2.89-2.55c-0.0899-1.5,1.0501-2.79,2.5401-2.88c1.5-0.1,2.8,1.04,2.8899,2.54     C26.80495,21.18835,25.67495,22.47835,24.17495,22.57835z",
  // };
  return defaultSym;
}
//return color

// const defaultSym = {
//   type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
//   color: permitTypecolor,
//   size: 18,
//   outline: {
//     // autocasts as new SimpleLineSymbol()
//     width: 0.5,
//     color: statusColor,
//   },
//   path:
//     "M24.00505,12.31835c-3.7701,0-6.81,3.05-6.81,6.81c0,0.14,0.01,0.28,0.0199,0.42     c0.03,0.56,0.13,1.12,0.31,1.66c0.1701,0.53,0.4,1.03,0.71,1.5c0.0201,0.03,0.2101,0.3,0.5,0.74c0,0,0,0,0.01,0.01     c1.3,1.96,4.73,7.09,4.75,7.12c0.24,0.36,0.7701,0.36,1.0101,0c0.01-0.02,0.0299-0.04,0.0399-0.05     c0.07-0.11,5.11-7.66,5.2701-7.89c0.0399-0.07,0.0899-0.14,0.13-0.21c0.2699-0.46,0.4699-0.95,0.6099-1.46     c0.17-0.6,0.25-1.22,0.25-1.84C30.80495,15.36835,27.75505,12.31835,24.00505,12.31835z M24.17495,22.57835     c-1.5,0.09-2.7899-1.05-2.89-2.55c-0.0899-1.5,1.0501-2.79,2.5401-2.88c1.5-0.1,2.8,1.04,2.8899,2.54     C26.80495,21.18835,25.67495,22.47835,24.17495,22.57835z",
// };
// return defaultSym;
//}
export function getHighlightSymol() {
  let highlightrenderer = {
    type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
    color: "yellow",
    size: 8,
    outline: {
      // autocasts as new SimpleLineSymbol()
      width: 0.5,
      color: "darkyellow",
    },
  };
  return highlightrenderer;
}

export function getHighlightSymolColor(color) {
  let highlightrenderer = {
    type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
    color: color || "yellow",
    size: 8,
    outline: {
      // autocasts as new SimpleLineSymbol()
      width: 0.5,
      color: color || "darkyellow",
    },
  };
  return highlightrenderer;
}

function AddingLayers(view, layers, mapdata, mapkey, mapConstants, SubModuleKey, res, statusres) {
  return new Promise((resolve, reject) => {
    loadModules([
      "esri/layers/GraphicsLayer",
      "esri/layers/FeatureLayer",
      "esri/PopupTemplate",
      "esri/layers/MapImageLayer",
      "esri/config"
    ]).then(([GraphicsLayer, FeatureLayer, PopupTemplate, MapImageLayer, esriConfig]) => {
      let highlight;
      if (layers && view && layers.length > 0) {
        const toremlayers = view.map.layers.filter((f) => {
          return f.listMode === "show";
        });
        view.map.layers.removeMany(toremlayers);
        // Promise.all([requestApi({ get: { url: getEnvVariable('Admin') + `/api/MasterPermitTypes/All` } }),
        // requestApi({ get: { url: getEnvVariable('Admin') + `/api/ApplicationStatus/All` } })
        // ]).then((values) => {
        // const [res, statusres] = values;
        layers.forEach(function (lyrinfo) {
          let layer = null;
          if (lyrinfo.HasAuthentication == "Y" && lyrinfo.Token) {
            esriConfig.request.interceptors.push({
              urls: lyrinfo.url,
              before: function (params) {
                params.requestOptions.query = params.requestOptions.query || {};
                params.requestOptions.query.token = lyrinfo.Token;
                params.requestOptions.query.expiration = lyrinfo.TokenExpiration;
              }
            });
          }
          // if(lyrinfo.HasAuthentication == "Y" && lyrinfo.Token){
          //   console.log('Layer has authentication---------',lyrinfo);
          //   lyrinfo.url = lyrinfo.url + '?token=' + lyrinfo.Token + '&expiration=' + lyrinfo.TokenExpiration;
          // }

          if (lyrinfo && lyrinfo.url && lyrinfo.url != "") {
            let labelClass = null;
            if (lyrinfo.showLabels && lyrinfo.labelExpression) {
              labelClass = { ...mapConstants.labelClass }
              //labelClass.labelExpression=lyrinfo.labelExpression
              labelClass.labelExpressionInfo = {
                expression: lyrinfo.labelExpression
              }
              if (lyrinfo.labelColor) {
                labelClass.symbol.color = lyrinfo.labelColor
              }
            }
          //  console.log('layer url------------', lyrinfo.url);
            switch (lyrinfo.type) {
              case "F": //F-FeatureLayer
              case "A": //F-FeatureLayer
                layer = new FeatureLayer({
                  title: lyrinfo.title,
                  url: lyrinfo.url,
                  id: lyrinfo.id,
                  LayerOptions: lyrinfo,
                  visible: (lyrinfo.ShowOnMap == "Y")
                }); //
                break;
              case "G": //G-GraphicsLayer
                layer = new GraphicsLayer({
                  title: lyrinfo.title,
                  url: lyrinfo.url,
                  LayerOptions: lyrinfo,
                  visible: (lyrinfo.ShowOnMap == "Y")
                });
                break;
              case "M": //G-GraphicsLayer
                layer = new MapImageLayer({
                  url: lyrinfo.url,                  
                  highlightOptions: {
                    color: [255, 222, 173],
                    fillOpacity: 0.1,
                  },
                  LayerOptions: lyrinfo,
                  visible: (lyrinfo.ShowOnMap == "Y")
                });
                break;
                default:
                  console.log('No layer found')
            }
            if (layer) {
              // Add layer info template to the layer.
              let title = lyrinfo.title || lyrinfo.key;
              if (lyrinfo.key == "CenterGeomService" || lyrinfo.IsRecord == 'Y' || lyrinfo.key == "RECORD_HIGHLIGHT_LAYER") {
                if (SubModuleKey == "COLLISION")
                  title = "Record Info  " + "<b><a href='#/Records/{SubModuleKey}/{Site_Permit_Type_Id}/{ObjectId}' >View Collision </a></b> " + " / " + "<b><a href='#/Records/{SubModuleKey}/{Site_Permit_Type_Id}/{Investigation_Id}' > Investigation </a></b> ";
                else
                  title = "Record Info  " + "<b><a href='#/Records/{SubModuleKey}/{Site_Permit_Type_Id}/{ObjectId}' >View Details </a></b> ";
              }  
              var popupTemplateContent;
              if (lyrinfo.InfoTemplate) {
                popupTemplateContent = lyrinfo.InfoTemplate;
              }
              if (popupTemplateContent) {
                let popupTemplate = new PopupTemplate({                  
                  title: title,
                  content: popupTemplateContent,
                });
                layer.popupTemplate = popupTemplate;
              }

              // Only for one layer to show pins
              if (lyrinfo.key == "CenterGeomService" || lyrinfo.IsRecord == 'Y') {
                // Color pin information based on master types and status
                let rendereruniqueValueInfos = [];
                if (res && res.length > 0) {
                  res.map((permittype) => {
                    statusres.map((statustype) => {
                      let uniqvalObj = {
                        value: permittype.Id, //+","+statustype.Id
                        symbol: getpermittypeSymbol(permittype.Color, statustype.StatusColor),
                        label: permittype.Name + " " + statustype.StatusName,
                      };
                      rendereruniqueValueInfos.push(uniqvalObj);
                    });
                  });
                }
                const defaultSym = {
                  type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
                  color: "yellow",
                  size: 8,
                  outline: {
                    // autocasts as new SimpleLineSymbol()
                    width: 0.5,
                    color: "darkyellow",
                  },
                };

                const permittypeRenderer = {
                  type: "unique-value",
                  defaultSymbol: defaultSym,
                  defaultLabel: "Permit Type Color",
                  field: "Permit_Type_Id",
                  uniqueValueInfos: rendereruniqueValueInfos,
                };
                layer.renderer = permittypeRenderer;
              }              

              if (lyrinfo.outFields)
                layer.outFields = lyrinfo.outFields || ["*"];

              //When layer loaded set color
              layer && layer.when(function () {
                if (lyrinfo.LayerColor && layer.renderer 
                  && layer.renderer.symbol && layer.renderer.symbol.outline) {
                  //layer.renderer.symbol.color = lyrinfo.HighlightColor;
                  layer.renderer.symbol.outline.color = lyrinfo.LayerColor;
                }
              });

              if (layer && lyrinfo.ExtentZoom === true) {
                layer.when(function () {
                  //console.log("layer loaded------------------", layer);
                  // view.goTo(layer.fullExtent);
                });
              }
              //Set Layer Scale Beyond
              if (lyrinfo.LayerScaleType == 'Z') {
                layer.minScale = lyrinfo.ScaleInBeyond;
                layer.maxScale = lyrinfo.ScaleOutBeyond;
              }

              if (Array.isArray(mapdata) && (lyrinfo.key == "CenterGeomService" || 
                  lyrinfo.IsRecord == 'Y' || 
                  lyrinfo.key == 'RECORD_HIGHLIGHT_LAYER')) {
                    let expression = "Id in(" + mapdata.toString() + ")";
                    //let expression = lyrinfo.defaultExpression || "1=1";
                    layer.definitionExpression = expression;
              } else {
                let expression = lyrinfo.defaultExpression || "1=1";
                layer.definitionExpression = expression;
              }
              if (lyrinfo.showLabels) {
                layer.labelingInfo = [labelClass];
                layer.labelsVisible = lyrinfo.showLabels;
              }

              view.graphics.removeAll();
              let existinglayer = view.map.layers.find(function (elayer) {
                return elayer.title === layer.title;
              });
              if (existinglayer) {
                view.map.layers.remove(existinglayer)
              }

              if (lyrinfo.IsRecord == "Y" && lyrinfo.key !== "CenterGeomService") {
                let userdefinitions = GetRecordQueryExpressions(lyrinfo);
                if (userdefinitions && SubModuleKey)
                  layer.definitionExpression = " SubModuleKey = '" + SubModuleKey + "' AND " + userdefinitions
                else if (!userdefinitions && SubModuleKey)
                  layer.definitionExpression = " SubModuleKey = '" + SubModuleKey + "'";
                else if (!SubModuleKey && userdefinitions)
                  layer.definitionExpression = userdefinitions

                if (Array.isArray(mapdata)) {
                  let expression = "ID in(" + mapdata.toString() + ")";
                  if (expression) {
                    layer.definitionExpression = layer.definitionExpression + " AND " + expression
                  }
                }
              }

              if (lyrinfo.key == "CenterGeomService" && lyrinfo.IsRecord != "Y") {
                let userdefinitions = GetRecordQueryExpressions(lyrinfo);
                layer.definitionExpression = userdefinitions
              }

              //Adding layers to the list here
              if(lyrinfo.type == 'M'){                
                view.map.layers.add(layer,0);
              }else{
                view.map.layers.add(layer);
              }
              
            }
          }
        });
        setTimeout(() => {
          resolve(true);
        }, 300);
        //   });
      } else {
        resolve(true);
      }

    });
  });
}


function GetRecordQueryExpressions(layerinfo) {
  let profile = localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))
  let defaultLayerExpression = layerinfo.defaultExpression;
  let layerDefinitionExpressionFilter;

  if (profile) {
    let isInternal = profile && profile.portal == "I";
    if (isInternal) {
      let districts = profile && profile.moduleDistrictAccess && Object.keys(profile.moduleDistrictAccess) || [];
      if (Array.isArray(districts) && districts.length > 0) {
        layerDefinitionExpressionFilter = " District_Id in (" + districts.join(",") + ")";
      }
    } else {
      layerDefinitionExpressionFilter = " Owner ='" + profile.modulePermissions[0].companyId + "'";
    }
  }
  let layerDefinitionExpression = (!layerDefinitionExpressionFilter || layerDefinitionExpressionFilter == "()" || layerDefinitionExpressionFilter == "1=1") ? (defaultLayerExpression) : (("(" + defaultLayerExpression + ")") + " and " + layerDefinitionExpressionFilter);
  return layerDefinitionExpression;
}





/*
function getTitle(feature)
{
  if (feature.graphic.attributes.SubModuleKey=="COLLISION")
  {
    return "Record T Info  " + "<b><a href='#/Records/{SubModuleKey}/{Site_Permit_Type_Id}/{ObjectId}' >View Collision </a></b> "+" / " + "<b><a href='#/Records/{SubModuleKey}/{Site_Permit_Type_Id}/{Investigation_Id}' > Investigation </a></b> ";
  }
  else
  {
  return  "Record Info  " + "<b><a href='#/Records/{SubModuleKey}/{Site_Permit_Type_Id}/{ObjectId}' >View Details </a></b> ";
  }
}*/