/**
       *********************************************************
           deprecated
       *********************************************************
**/
import React, { Component, Fragment } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText'

const styles = theme => ({
    colorBar: {},
    colorChecked: {},
    iOSSwitchBase: {
        '&$iOSChecked': {
            color: theme.palette.common.white,
            '& + $iOSBar': {
                backgroundColor: '#52d869',
            },
        },
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    iOSChecked: {
        transform: 'translateX(15px)',
        '& + $iOSBar': {
            opacity: 1,
            border: 'none',
        },
    },
    iOSBar: {
        borderRadius: 13,
        width: 42,
        height: 26,
        marginTop: -13,
        marginLeft: -21,
        border: 'solid 1px',
        borderColor: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    iOSIcon: {
        width: 24,
        height: 24,
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1],
    },
});



const Switches = (props) => {
    const { options, classes, handleFieldChange, compmap } = props;
    const { value, color, name, disabled, inputattrProps, checkedIcon, uncheckedIcon, transformProps, label, ...otherOptions } = options ? options : {}
    let otherCustomOptions = {}
    if (uncheckedIcon) {
        const { options, type } = uncheckedIcon
        const EachComp = compmap[type]
        otherCustomOptions.icon = <EachComp {...options} />
    }
    if (checkedIcon) {
        const { options, type } = checkedIcon
        const EachComp = compmap[type]
        otherCustomOptions.checkedIcon = <EachComp {...options} />
    }

    const checked = props.value
    return (
        <Fragment>
            <FormControlLabel
                control={<Switch
                    classes={{
                        switchBase: classes.iOSSwitchBase,
                        bar: classes.iOSBar,
                        icon: classes.iOSIcon,
                        iconChecked: classes.iOSIconChecked,
                        checked: classes.iOSChecked,
                    }}
                    disableRipple
                    //color={color || 'primary'}
                    checked={checked ? checked : false}
                    onChange={handleFieldChange}
                    {...otherOptions}
                    {...otherCustomOptions}
                />}
                label={label}
                value={value}
            />
        </Fragment>
    );
}

export default withStyles(styles)(Switches);