import { getEnvVariable } from '../../modules/environmentalList';
import newsavedreportstypes from '../reports/savedreports';
import consultantuser from './consultant';

const consultantgrid = {
    "type": "grid",
    "key": "consultantsgrid",
    "gridkey": 'consultantsgrid',
    "custompropsvalue": "Id",
    "options": {
      //  "gridheightfortabs": true,
        "custompropsvalue": "Id",
        "routes": {
            "route": "/Administration/consultants/:Id?"
        },
        "crud": {
            "update": {
                "link": {
                    "link": "/consultants/view",
                    "matchProps": {
                        "value": "Id"
                    }
                }
            },
            "read": {
                "get": {
                   // "url": getEnvVariable('MasterAdmin') + `/api/CompanyInternal/` + getEnvVariable('ModuleKey') + `/Grid`,
                    "url": getEnvVariable('MasterAdmin') + `/api/CompanyInternal/` + getEnvVariable('ModuleKey') + `/Consultants/Grid`,
             }
            }
        },
        "sidebar": true,
        headerProps: {
            title: "Consultants",
            components: [
                {
                    type: 'breadcrumbs',
                    items: [
                        { "link":  "Administration", "materialicon": "spellcheck", href: getEnvVariable('InternalBasepath'), className: 'breadcrumb-item text-dark', class: 'text-dark' },
                        { "link": "Consultants", href: "#/consultants", "materialicon": "connect_without_contact", className: 'breadcrumb-item ', class: '' }
                    ],
                }
            ],
            actions: [
                {
                    type: 'fab',
                    icon: 'clear',
                    name: 'clear',
                    color: 'primary',
                    title: 'Clear Filters',
                    menucolor: '#22a9b3'
                },

                {
                    type: 'menu',
                    icon: 'excel',
                    name: 'excel',
                    color: 'primary',
                    title: 'Export to Excel',
                    menucolor: '#c90685',
                    items: [{
                        name: 'Export Current Page',
                        action: 'filtercurrent'
                    },
                    {
                        name: 'Export All Pages',
                        action: 'filterall'
                    }
                    ]
                },
                {
                    type: 'fab',
                    icon: 'reports',
                    name: 'reports',
                    color: 'primary',
                    title: 'Save Report',
                    menucolor: '#C85A54'
                },

            ]
        },
        savedreports: {
            ...newsavedreportstypes
        },
        "components": [
            consultantuser

        ],
        "gridcolumns": [
            {
                "title": "Consultant ID",
                "field": "Id",
                "show": true,
                "width": 250
            },
            {
                "title": "Consultant Name",
                "field": "Name",
                "show": true,
                "width": 250
            },

            // {
            //     "title": "Address Line 1",
            //     "field": "Contacts.PrimaryContactAddress.AddressLine1",
            //     "show": true,
            //     "width": 250
            // },

            // {
            //     "title": "Office Phone",
            //     "field": "Contacts.PrimaryContactAddress.Phone",
            //     "show": true,
            //     "width": 200
            // },
            {
                "title": "Status",
                "field": "StatusDesc",
                "show": true,
                "width": 150
            },

            {
                "title": "Created By",
                "field": "CreatedByName",
                "show": true,
                "width": 185
            },
            {
                "title": "Created On",
                "field": "CreatedOn",
                "show": true,
                "width": 185
            },
            {
                "title": "Updated By",
                "field": "UpdatedByName",
                "show": true,
                "width": 185
            },
            {
                "title": "Updated On",
                "field": "UpdatedOn",
                "show": true,
                "width": 185
            },
        ]
    }
}

export default consultantgrid