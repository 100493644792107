export class MapConstants {
    constructor() {
        const line_Color = "#005517";
        const line_Size = "2";
        const lineSelected_Size = "4";
        const lineSymbolColor = "#ea1515";
        const polygonFillSymbolColor = "#f5f29f";
        const polygonFillSymbolColorSecondary = "#81dfff";
        const polygonNoFillSymbolColor = "#00dfff";
        const pointSymbolColor = "#cc0000";
        this.SRID = "";

        this.PointSymbol = {
            type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
            url: "http://static.arcgis.com/images/Symbols/Basic/RedStickpin.png",
            width: "32px",
            height: "32px"
        };
        this.PointSymbolHidden = {
            type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
            url: "http://static.arcgis.com/images/Symbols/Basic/RedStickpin.png",
            width: "1px",
            height: "1px"
        };

        this.PointSymbol = {
            type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
            url: "http://static.arcgis.com/images/Symbols/Basic/RedStickpin.png",
            width: "32px",
            height: "32px"
        };

        this.ExistingPointSymbol = {
            type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
            url: "http://static.arcgis.com/images/Symbols/Basic/GreenStickpin.png",
            width: "32px",
            height: "32px"
        };

        this.LineSymbol = {
            type: "simple-line",
            color: lineSymbolColor,
            width: line_Size + "px",
            style: "solid"
        };

        this.LineSelectedSymbol = {
            type: "simple-line",
            color: lineSymbolColor,
            width: lineSelected_Size + "px",
            style: "solid"
        };
        this.PolygonSymbol = {
            type: "simple-fill",
            color: polygonFillSymbolColor,
            style: "none",
            outline: this.LineSymbol
        };

        this.PolygonFillSymbol = {
            type: "simple-fill",
            color: [255, 255, 153, 0.5], //polygonFillSymbolColor,
            style: "solid",
            outline: this.LineSymbol
        };


        this.PolygonNoFillSymbol = {
            type: "simple-fill",
            style: "solid",
            //color: polygonNoFillSymbolColor,
            outline: this.LineSymbol
        };

        this.PolygonFillSymbolSecondary = {
            type: "simple-fill",
            color: [129, 223, 255, 0.5], //polygonFillSymbolColorSecondary,
            style: "solid",
            outline: this.LineSymbol
        };

        this.PolygonFillSymbolReset = {
            type: "simple-fill",
            color: [132, 186, 245, 0.01],
            style: "solid",
            outline: {
                color: "orange",
                width: 1
            }
        };
        this.labelClass = {
            symbol: {
                type: "text",
                color: [256, 0, 0, 0.01],// from map settings
                haloColor: "black",
                font: {
                    family: "Playfair Display",
                    size: 12,
                    weight: "normal"
                }
            },
            labelPlacement: "above-center",
            /*labelExpressionInfo: { from map settings
                expression: "$feature.Provincienaam"
            }*/
        }
        this.greenFlagsymbol = {
            type: "picture-marker", // autocasts as new SimpleMarkerSymbol()
            "url": "http://static.arcgis.com/images/Symbols/Basic/GreenFlag.png",
            "width": "32px",
            "height": "32px"
        };

        this.getDefaultSymbols = {
            "point": this.PointSymbol,
            "multipoint": this.PointSymbol,
            "polyline": this.LineSelectedSymbol,
            "polygon": this.PolygonFillSymbol
        }
    }
    getSVGPointSymbolWrapper = (linecolor, fillColor, size, outlineWidth) => {
        //return color
        // return {
        //     type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
        //     style: "circle", color: linecolor, size: "10px", // 
        //     outline: { // autocasts as new SimpleLineSymbol() 
        //         color: linecolor, width: 3 // points
        //     }
        // }
        return {
          type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
          color:fillColor,
          size: size || 18,
          outline: {
            // autocasts as new SimpleLineSymbol()
            width: outlineWidth || 0.5,
            color: linecolor,
          },
          path:
            "M24.00505,12.31835c-3.7701,0-6.81,3.05-6.81,6.81c0,0.14,0.01,0.28,0.0199,0.42     c0.03,0.56,0.13,1.12,0.31,1.66c0.1701,0.53,0.4,1.03,0.71,1.5c0.0201,0.03,0.2101,0.3,0.5,0.74c0,0,0,0,0.01,0.01     c1.3,1.96,4.73,7.09,4.75,7.12c0.24,0.36,0.7701,0.36,1.0101,0c0.01-0.02,0.0299-0.04,0.0399-0.05     c0.07-0.11,5.11-7.66,5.2701-7.89c0.0399-0.07,0.0899-0.14,0.13-0.21c0.2699-0.46,0.4699-0.95,0.6099-1.46     c0.17-0.6,0.25-1.22,0.25-1.84C30.80495,15.36835,27.75505,12.31835,24.00505,12.31835z M24.17495,22.57835     c-1.5,0.09-2.7899-1.05-2.89-2.55c-0.0899-1.5,1.0501-2.79,2.5401-2.88c1.5-0.1,2.8,1.04,2.8899,2.54     C26.80495,21.18835,25.67495,22.47835,24.17495,22.57835z",
        };
    }

    getSVGPointtSymbol = () => {
        return this.getSVGPointSymbolWrapper("green", "green", 22, .8)
    }


    getSVGHighlighPointtSymbol = () => {
        return this.getSVGPointSymbolWrapper("red", "red", 22, .8)
    }
    getHighlightsymbol = (subModuleKey, type, attributes, layerConfig) => {
        if (layerConfig && layerConfig.KEY == "CenterGeomService" && (subModuleKey == "COLLISION" || subModuleKey == "INVESTIGATION"))
            return this.getSVGPointSymbolWrapper((attributes.Permit_Color || layerConfig.LayerColor), (attributes.StatusColor || layerConfig.HighlightColor), 22, .8)
        return this.getsymbolsByLayerConfig(type, layerConfig);
    }
    getsymbol = (type, isHighlight, layerConfig) => {

        return this.getDefaultSymbols[type];
    }
    getsymbolsByLayerConfig = (type, layerConfig) => {
        const lc_line_Size = "2";
        const lc_lineSelected_Size = "7";
        const lc_lineSymbolColor = layerConfig.HighlightColor || "#ea1515";
        const lc_polygonFillSymbolColor = layerConfig.LayerColor || "#f5f29f";
        const lc_polygonFillSymbolColorSecondary = layerConfig.LayerColor || "#81dfff";
        const lc_polygonNoFillSymbolColor = layerConfig.HighlightColor || "#00dfff";
        const lc_pointSymbolColor = layerConfig.HighlightColor || "#cc0000";
        let lc_PointSymbol = {//this.getSVGPointSymbolWrapper("red","red",22,.8)
            type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
            url: "http://static.arcgis.com/images/Symbols/Basic/RedStickpin.png",
            width: "32px",
            height: "32px"
        };

        let lc_ExistingPointSymbol = {
            type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
            url: "http://static.arcgis.com/images/Symbols/Basic/GreenStickpin.png",
            width: "32px",
            height: "32px"
        }
        let lc_LineSymbol = {
            type: "simple-line",
            color: lc_lineSymbolColor,
            width: lc_line_Size + "px",
            style: "solid"
        };

        let lc_LineSelectedSymbol = {
            type: "simple-line",
            color: lc_lineSymbolColor,
            width: lc_lineSelected_Size + "px",
            style: "solid"
        };
        let lc_PolygonSymbol = {
            type: "simple-fill",
            color: lc_polygonFillSymbolColor,
            style: "none",
            outline: lc_LineSymbol
        };

        let lc_PolygonFillSymbol = {
            type: "simple-fill",
            color: [255, 255, 153, 0.5], //polygonFillSymbolColor,
            style: "solid",
            outline: lc_LineSymbol
        };


        let lc_PolygonNoFillSymbol = {
            type: "simple-fill",
            style: "solid",
            //color: polygonNoFillSymbolColor,
            outline: lc_LineSymbol
        };

        let lc_PolygonFillSymbolSecondary = {
            type: "simple-fill",
            color: [129, 223, 255, 0.5], //polygonFillSymbolColorSecondary,
            style: "solid",
            outline: lc_LineSymbol
        };

        let lc_PolygonFillSymbolReset = {
            type: "simple-fill",
            color: [132, 186, 245, 0.01],
            style: "solid",
            outline: {
                color: "orange",
                width: 1
            }
        }
        let lc_labelClass = {
            symbol: {
                type: "text",
                color: [256, 0, 0, 0.01],// from map settings
                haloColor: "black",
                font: {
                    family: "Playfair Display",
                    size: 12,
                    weight: "normal"
                }
            },
            labelPlacement: "above-center",
            /*labelExpressionInfo: { from map settings
                expression: "$feature.Provincienaam"
            }*/
        };

        let lc_getDefaultSymbols = {
            "point": lc_PointSymbol,
            "multipoint": lc_PointSymbol,
            "polyline": lc_LineSelectedSymbol,
            "polygon": lc_PolygonFillSymbol
        }
        return lc_getDefaultSymbols[type];
    }
};

