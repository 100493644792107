

const confirmationReducer = (state = null, action) => {

    switch (action.type) {
        case 'CONFIRMATION':
            return { ...state, onSubmit: action.onyes, onClose: action.onno, description: action.description, value: action.value }

        default:
            return state

    }
}

export default confirmationReducer