import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Template from '../../modules/template'
import { useEffect, useState, useRef } from 'react';
import { getEnvVariable } from '../../../modules/environmentalList';
import { GetData } from '../helpers/requests';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save'
import DefaultSettings from './settings';

export default function TemplateComp(props) {
    const { value, handleFieldValue, multiline, extraProps, isSave, rowsMax, shareProps, error, required, tooltip, items,
        contexthelp, className, disabled, changefieldto, FieldIsSpellCheck, fieldValues, edit, label, helperText, name, xtype, fullWidth, ...otherProps } = props
    const [save, setSave] = useState(false);
    const [list, setList] = useState([])

    useEffect(() => {
        if (fieldValues['SideBarTables'] !== '') {
            let tables = fieldValues['SideBarTables'].split(',')
            let url = ""
            tables.forEach((element) => {
                url = url + 'table=' + element + '&';
            });
            let sourceURL = getEnvVariable('Application') + "/api/Application/Fields/" + fieldValues['SitePermitTypeId'] + "?" + url;
            GetData(sourceURL, "").then(results => {
                if (results && results.length > 0) {
                    setList(results)
                }
            })
        }
    }, [fieldValues['SideBarTables']])

    useEffect(() => {
        let sourceURL = getEnvVariable('Application') + "/api/Application/Fields/" + fieldValues['SitePermitTypeId'];
        GetData(sourceURL, "").then(results => {
            if (results && results.length > 0) {
                setList(results)
            }
        })
    }, [])

    const handleSave = (template) => {
        if (template && template.id) {
            handleFieldValue({ target: { value: template.id.toString(), name: name, checked: false }, currentTarget: { dataset: null } })
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
        }
    }

    useEffect(() => {
        if (save === true) {
            setSave(false);
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().add();
            if (DefaultSettings.getAlert() != null)
                DefaultSettings.getAlert().show('Template is created, Please click on save at header to update information to Master Template ', 'info');
        }
    }, [save])

    const inputEl = useRef(null);
    return <Box className={className}>
        {save}
        {edit && <Fab className="p-1 float-right" onClick={() => { setSave(true) }} size="small" color='primary' variant='contained'><SaveIcon /></Fab>}
        <FormControl fullWidth={fullWidth ? fullWidth : true} error={error} required={required} className={className + ' p-1'}>
            {fieldValues['SitePermitTypeId'] !== '' && <Template
                ref={inputEl}
                moduleId={fieldValues['SubModuleKey']}
                sitePermitTypeId={fieldValues['SitePermitTypeId']}
                templateId={value}
                isadmin={true}
                isEnabled={edit}
                showHistory={true}
                id={parseInt(value) || 0}
                canDownload={true}
                save={save}
                parentid={0}
                callBack={(data) => { handleSave(data) }}
                mentions={list}
                fields={items}

            />}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    </Box>
}