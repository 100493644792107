import { getEnvVariable } from "../../../modules/environmentalList"
import AttachmentGrid from './attachments'
const ViewNotice = {
    "type": "popup",
    "key": "ViewNotice",
    "name": "ViewNotice",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'editOpen',
    },
    sharedValues: ['open', 'back'],
    container: {
        closepopup: true,
        closepopupval: 'noticestypes',
        "addRoute": true,
        'removelayout': 'ViewNotice',
        values: { editInfo: null, edit: false, createduplicate: false, fullscreen: false, back: false, allgriddata: null, navigation: null },
        mapValues: {
            header: [
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                //{ key: 'createduplicate', name: 'createduplicate' },
            ],
            sectionscomp: [
                { key: 'editInfo', name: 'editInfo' },
                { key: 'allgriddata', name: 'allgriddata' },
                { key: 'save', name: 'save' },
                { key: 'saveduplicate', name: 'saveduplicate' },
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
               // { key: 'createduplicate', name: 'createduplicate' },
                { key: 'back', name: 'back' },
                { key: 'navigation', name: 'navigation' },]
        },
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },
            {
                order: 1,
                type: 'div',
                className: 'row col',
                components: [{
                    name: 'sectionscomp',
                    type: 'div',
                    className: 'col-12 m-3',
                    sub: {
                        type: 'div',
                        className: 'col-12 m-3',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Notice',

                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "View/Edit Notice Type", "materialicon": "article", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },

                    // {
                    //     type: 'icon',
                    //     icon: 'edit',
                    //     name: 'edit',
                    //     hideon: 'createduplicate',
                    //     hideanother: 'edit',
                    //     hidevalue: true,
                    //     title: 'Edit Notice'
                    // },
                    // {
                    //     type: 'icon',
                    //     icon: 'duplicate',
                    //     name: 'createduplicate',
                    //     hideon: 'edit',
                    //     hideanother: 'createduplicate',
                    //     hidevalue: true,
                    //     title: 'Clone Notice'
                    // },
                    // {
                    //     type: 'icon',
                    //     icon: 'save',
                    //     name: 'save',
                    //     hideon: 'edit',
                    //     hidevalue: false,
                    //     title: 'Save Notice'
                    // },
                    // {
                    //     type: 'icon',
                    //     icon: 'save',
                    //     name: 'saveduplicate',
                    //     hideon: 'createduplicate',
                    //     hidevalue: false,
                    //     title: 'Save Notice'
                    // },
                    {
                        type: 'icon',
                        icon: 'previtem',
                        name: 'previtem',
                        hideon: 'previtem',
                        hidevalue: false,
                        title: 'Previous Item'
                    },
                    {
                        type: 'icon',
                        icon: 'nextitem',
                        name: 'nextitem',
                        hideon: 'nextitem',
                        hidevalue: false,
                        title: 'Next Item'
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hidevalue: false,
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false
                    },
                    {
                        type: 'icon',
                        icon: 'history',
                        name: 'history',
                        title: 'History',
                        hideon: 'history',
                        hidevalue: false,
                        historyurl: {
                            url: getEnvVariable('Admin') + `/api/NoticeTypes/History/{Id}?`,
                            "routeProps": [{ name: "mid", key: "Id", },],
                            type: 'get',
                            saveAs: { key: 'data' }
                        }
                    },
                ],
            },
            sectionscomp: {
                type: "sections",
                name: "sectionscomp",
                key: "sectionscomp",
                initRun: true,
                layout: {
                    'Details': {
                        group: [
                            {
                                order: 0,
                                type: 'div',
                                className: 'row col d-flex flex-row-reverse',
                                components: [
                                    {
                                        name: 'save',
                                        type: 'div',
                                        className: 'col-2',
                                        sub: {
                                            type: 'div',
                                            className: 'col-2',
                                        }
                                    },
                                    {
                                        name: 'Edit',
                                        type: 'div',
                                        className: 'col-1',
                                        sub: {
                                            type: 'div',
                                            className: 'col-1',
                                        }
                                    },
                                    // {
                                    //     name: 'createduplicate',
                                    //     type: 'div',
                                    //     className: 'col-2',
                                    //     sub: {
                                    //         type: 'div',
                                    //         className: 'col-2',
                                    //     }
                                    // },
                                    {
                                        name: 'saveduplicate',
                                        type: 'div',
                                        className: 'col-1',
                                        sub: {
                                            type: 'div',
                                            className: 'col-1',
                                        }
                                    },
                                ],
                            },
                            {
                                order: 1,
                                type: 'div',
                                className: 'row col',
                                components: [
                                    {
                                        name: 'Name',
                                        type: 'div',
                                        className: 'col-10',
                                        sub: {
                                            type: 'div',
                                            className: 'col-10',
                                        }
                                    },
                                ],
                            },
                            {
                                order: 2,
                                type: 'div',
                                className: 'row col',
                                components: [
                                    {
                                        name: 'Description',
                                        type: 'div',
                                        className: 'col-10',
                                        sub: {
                                            type: 'div',
                                            className: 'col-10'
                                        }
                                    },
                                ]
                            },
                            {
                                order: 3,
                                type: 'div',
                                className: 'row col',
                                components: [
                                    {
                                        name: 'SubModuleKey',
                                        type: 'div',
                                        className: 'col-4',
                                        sub: {
                                            type: 'div',
                                            className: 'col-4'
                                        }
                                    },
                                    {
                                        name: 'SitePermitType',
                                        type: 'div',
                                        className: 'col-4',
                                        sub: {
                                            type: 'div',
                                            className: 'col-4'
                                        }
                                    },
                                ]
                            },
                            {
                                order: 4,
                                type: 'div',
                                className: 'row col',
                                components: [
                                    {
                                        name: 'CanEditNoticeDate',
                                        type: 'div',
                                        className: 'col-5',
                                        sub: {
                                            type: 'div',
                                            className: 'col-5'
                                        }
                                    },
                                ]
                            },
                            {
                                order: 5,
                                type: 'div',
                                className: 'row col',
                                components: [
                                    {
                                        name: 'CanEditPastNoticeDate',
                                        type: 'div',
                                        className: 'col-3',
                                        sub: {
                                            type: 'div',
                                            className: 'col-3'
                                        }
                                    },
                                    {
                                        name: 'CanEditCurrentNoticeDate',
                                        type: 'div',
                                        className: 'col-3',
                                        sub: {
                                            type: 'div',
                                            className: 'col-3'
                                        }
                                    },
                                    {
                                        name: 'CanEditFutureNoticeDate',
                                        type: 'div',
                                        className: 'col-3',
                                        sub: {
                                            type: 'div',
                                            className: 'col-3'
                                        }
                                    },
                                ]
                            },
                            {
                                order: 6,
                                type: 'div',
                                className: 'row col',
                                components: [
                                    {
                                        name: 'Status',
                                        type: 'div',
                                        className: 'col-10',
                                        sub: {
                                            type: 'div',
                                            className: 'col-10'
                                        }
                                    },
                                ]
                            },
                            {
                                order: 7,
                                type: 'div',
                                className: 'row col',
                                components: [{
                                    name: 'CreatedOn',
                                    type: 'div',
                                    className: 'col-6',
                                    sub: {
                                        type: 'div',
                                        className: 'col-6'
                                    }
                                },
                                {
                                    name: 'CreatedByName',
                                    type: 'div',
                                    className: 'col-6',
                                    sub: {
                                        type: 'div',
                                        className: 'col-6'
                                    }
                                },
                                ]
                            },
                            {
                                order: 8,
                                type: 'div',
                                className: 'row col',
                                components: [
                                    {
                                        name: 'UpdatedOn',
                                        type: 'div',
                                        className: 'col-6',
                                        sub: {
                                            type: 'div',
                                            className: 'col-6'
                                        }
                                    },
                                    {
                                        name: 'UpdatedByName',
                                        type: 'div',
                                        className: 'col-6',
                                        sub: {
                                            type: 'div',
                                            className: 'col-6'
                                        }
                                    },]
                            },
                        ],
                    },
                    'Attachments for Inserts': {
                        group: [
                            {
                                order: 0,
                                type: 'div',
                                className: 'row col',
                                components: [
                                    {
                                        name: 'Attachment',
                                        type: 'div',
                                        className: 'col-12',
                                        sub: {
                                            type: 'div',
                                            className: 'col-12',
                                        }
                                    },
                                ],
                            },
                        ],
                    },
                    'Build Notice': {
                        group: [
                            {
                                order: 0,
                                type: 'div',
                                className: 'row col',
                                components: [
                                    {
                                        name: 'TemplateBr',
                                        type: 'div',
                                        className: 'col-12',
                                        sub: {
                                            type: 'div',
                                            className: 'col-12',
                                        }
                                    },
                                ],
                            },
                        ],
                    },
                },
                aliases: {
                    'editInfo': 'editInfo',
                    'allgriddata': 'allgriddata',
                    'navigateItem': 'navigateItem'
                },
                sharedValues: ['editInfo', 'allgriddata', 'navigateItem', 'navigation', 'edit', 'save', 'createduplicate', 'exitfullscreen', 'fullscreen', 'back'],
                validationMapKeys: [],
                values: {
                    Id: '',
                    Name: '',
                    Description: '',
                    SubModuleKey: '',
                    Status: 'A',
                    SitePermitType: '',
                    UpdatedByName: '',
                    CreatedByName: '',
                    UpdatedOn: '',
                    CreatedOn: '',
                    UpdatedBy: '',
                    CreatedBy: '',
                    TemplateBr: '',
                    Attachment: '',
                    CanEditCurrentNoticeDate: '',
                    CanEditFutureNoticeDate: '',
                    CanEditNoticeDate: 'N',
                    CanEditPastNoticeDate: '',
                    save: false,
                    edit: false,
                    createduplicate: false,
                    saveduplicate: false
                },
                fieldsinSectionNames: {
                    Name: 'Details',
                    Description: 'Details',
                    SubModuleKey: 'Details',
                    Status: 'Details',
                    SitePermitType: 'Details',
                    UpdatedByName: 'Details',
                    CreatedByName: 'Details',
                    UpdatedOn: 'Details',
                    CreatedOn: 'Details',
                    UpdatedBy: 'Details',
                    CreatedBy: 'Details',
                    TemplateBr: 'Build Details',
                    CanEditCurrentNoticeDate: 'Details',
                    CanEditFutureNoticeDate: 'Details',
                    CanEditNoticeDate: 'Details',
                    CanEditPastNoticeDate: 'Details',
                    Attachment: 'Attachments for Inserts',
                },

                sections: {
                    type: 'tab',
                    sectionNames: ['Details', 'Attachments for Inserts', 'Build Notice'],
                    steps: ['Details', 'Attachments for Inserts', 'Build Notice'],//
                },
                components: {
                    save: {
                        "changefieldto": "button",
                        "type": "button",
                        isIcon: true,
                        custom: true,
                        "key": "save",
                        "label": "save",
                        "name": "save",
                        icon: 'save',
                        size: 'small',
                        extraProps: {
                            color: 'primary',
                            variant: "outlined",
                        }
                    },
                    Edit: {
                        "changefieldto": "button",
                        "type": "button",
                        isIcon: true,
                        custom: true,
                        "key": "Edit",
                        "label": "Edit",
                        "name": "Edit",
                        icon: 'edit',
                        size: 'small',
                        extraProps: {
                            color: 'primary',
                            variant: "outlined",
                        }

                    },
                    saveduplicate: {
                        "changefieldto": "button",
                        "type": "button",
                        isIcon: true,
                        custom: true,
                        "key": "saveduplicate",
                        "label": "Save Notice",
                        "name": "saveduplicate",
                        icon: 'save',
                        size: 'small',
                        extraProps: {
                            color: 'primary',
                            variant: "outlined",
                        }
                    },
                    // createduplicate: {
                    //     "changefieldto": "button",
                    //     "type": "button",
                    //     isIcon: true,
                    //     custom: true,
                    //     "key": "CreateDuplicate",
                    //     "label": 'Clone Notice',
                    //     "name": "createduplicate",
                    //     icon: 'file_copy',
                    //     size: 'small',
                    //     extraProps: {
                    //         color: 'primary',
                    //         variant: "outlined",
                    //     }

                    // },
                    Name: {
                        "changefieldto": "textfield",
                        "type": "label",
                        custom: true,
                        "key": "Name",
                        "label": "Name",
                        "name": "Name",
                    },

                    Description: {
                        "changefieldto": "textfield",
                        "type": "label",
                        custom: true,
                        "key": "Description",
                        "label": "Description",
                        "name": "Description",
                    },
                    SubModuleKey: {
                        "changefieldto": "label",
                        "type": "label",
                        custom: true,
                        "key": "SubModuleKey",
                        "label": "Sub Module",
                        "name": "SubModuleKey",
                        extraProps: {
                            transformProps: {
                                label: 'SubModuleName',
                                value: 'Key'
                            },
                        },
                    },

                    SitePermitType: {
                        "changefieldto": "label",
                        "type": "label",
                        custom: true,
                        "key": "SitePermitType",
                        "label": "Permit Type",
                        "name": "SitePermitType",
                        extraProps: {
                            transformProps: {
                                label: 'Name',
                                value: 'Id'
                            },
                        },
                    },
                    Status: {
                        "changefieldto": "toggle",
                        "type": "label",
                        custom: true,
                        "key": "Status",
                        "label": "Status",
                        "name": "Status",
                        extraProps: {
                            transformProps: {
                                I: false,
                                A: true,
                                'true': 'A',
                                'false': 'I'
                            },
                        },
                        LabeltransformProps: {
                            I: 'In-Active',
                            A: 'Active',
                            true: 'Active',
                           false: 'In-Active',
                        },
                    },
                    CreatedOn: {
                        "type": "label",
                        "key": "CreatedOn",
                        custom: true,
                        "label": "Created On",
                        name: "CreatedOn",
                        changefieldto: "label",
                    },
                    CreatedByName: {
                        "type": "label",
                        custom: true,
                        "key": "CreatedByName",
                        "label": "Created By",
                        name: "CreatedByName",
                        changefieldto: "label",
                    },
                    UpdatedOn: {
                        "type": "label",
                        custom: true,
                        "key": "UpdatedOn",
                        "label": "Updated On",
                        name: "UpdatedOn",
                        changefieldto: "label",
                    },
                    UpdatedByName: {
                        "type": "label",
                        custom: true,
                        "key": "UpdatedByName",
                        "label": "Updated By",
                        name: "UpdatedByName",
                        changefieldto: "label",
                    },
                    TemplateBr: {
                        "type": "templatebusiness",
                        edit: true,
                        custom: true,
                        "key": "TemplateBr",
                        "label": "Notice Template",
                        name: "TemplateBr",
                        changefieldto: "templatebusiness",
                    },
                    Attachment: AttachmentGrid,
                    CanEditCurrentNoticeDate: {
                        changefieldto: 'checkbox',
                        "type": "label",
                        custom: true,
                        "key": "CanEditCurrentNoticeDate",
                        "label": "Current",
                        "name": "CanEditCurrentNoticeDate",
                        extraProps: {
                            transformProps: {
                                N: false,
                                Y: true,
                                'true': 'Y',
                                'false': 'N'
                            },
                        }
                    },
                    CanEditFutureNoticeDate: {
                        changefieldto: 'checkbox',
                        "type": "label",
                        custom: true,
                        "key": "CanEditFutureNoticeDate",
                        "label": "Future",
                        "name": "CanEditFutureNoticeDate",
                        extraProps: {
                            transformProps: {
                                N: false,
                                Y: true,
                                'true': 'Y',
                                'false': 'N'
                            },
                        }
                    },
                    CanEditNoticeDate: {
                        changefieldto: 'checkbox',
                        "type": "label",
                        custom: true,
                        "key": "CanEditNoticeDate",
                        "label": "Can Edit Notice Date",
                        "name": "CanEditNoticeDate",
                        extraProps: {
                            transformProps: {
                                N: false,
                                Y: true,
                                'true': 'Y',
                                'false': 'N'
                            },
                        }
                    },
                    CanEditPastNoticeDate: {
                        changefieldto: 'checkbox',
                        "type": "label",
                        custom: true,
                        "key": "CanEditPastNoticeDate",
                        "label": "Past",
                        "name": "CanEditPastNoticeDate",
                        extraProps: {
                            transformProps: {
                                N: false,
                                Y: true,
                                'true': 'Y',
                                'false': 'N'
                            },
                        }
                    },
                },
                mapActionsToEffects: {
                    'onLoad': {
                        'init': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            { type: "prop", name: "save", isVisible: false },
                                            { type: "prop", name: "saveduplicate", isVisible: false },
                                            {
                                                type: 'load',
                                                name: 'SubModuleKey',
                                                "read": [
                                                    {
                                                        url: getEnvVariable('MasterAdmin') + `/api/module/${getEnvVariable('ModuleKey')}/submodule/All/Active/IsConfigurable`,
                                                        type: 'get',
                                                        saveAs: {
                                                            key: 'items'
                                                        }
                                                    }
                                                ]
                                            },
                                            {
                                                type: 'load',
                                                name: 'SitePermitType',
                                                "read": [
                                                    {
                                                        url: getEnvVariable('AdminConfig') + `/api/SitePermitTypes/SubModuleKey/Active/{SubModuleKey}`,
                                                        type: 'get',
                                                        "matchProps": [{
                                                            name: "SubModuleKey",
                                                            key: "SubModuleKey",
                                                        }],
                                                        saveAs: {
                                                            key: 'items'
                                                        }
                                                    }
                                                ]
                                            },
                                            {
                                                type: 'validations',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Application') + `/api/ProjectMessages/RulesByName?name=amesadmin.Noticetypes`,
                                                        type: 'get'
                                                    }
                                                ],
                                                transformProps: {
                                                    removesubfieldname: `${getEnvVariable('ModuleKey')}ADMIN.Noticetypes_`
                                                }
                                            },
                                            {
                                                type: 'businessrules',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Application') + `/api/BusinessRules/Grid/Admin.NoticeTypes`,
                                                        type: 'get',
                                                    }
                                                ],
                                            },
                                        ]
                                    }
                                },

                            ]
                        },


                    },
                    'onChange': {
                        SubModuleKey: {
                            rules: [
                                {
                                    canOverwrite: true,
                                    check: [{
                                        condition: "AND",
                                        rules: [
                                            { name: "SubModuleKey", type: "!=", value: "", rules: [] }]
                                    }],
                                    fields: ["SubModuleKey"],
                                    run: {
                                        type: "check",
                                        valid: [
                                            {
                                                type: 'load',
                                                name: 'SitePermitType',
                                                "read": [
                                                    {
                                                        url: getEnvVariable('AdminConfig') + `/api/SitePermitTypes/SubModuleKey/Active/{SubModuleKey}`,
                                                        type: 'get',
                                                        "matchProps": [{
                                                            name: "navigation.SubModuleKey",
                                                            key: "SubModuleKey",
                                                        }],
                                                        saveAs: {
                                                            key: 'items'
                                                        }
                                                    }
                                                ]
                                            },
                                        ],
                                        invalid: []
                                    },
                                    uncheck: false
                                }
                            ],
                            fields: [
                                'SubModuleKey'
                            ]
                        },
                        'navigation': {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'initial',
                                            "read": [
                                                {
                                                    url: getEnvVariable('Admin') + `/api/NoticeTypes/{Id}`,
                                                    key: 'save',
                                                    "matchProps": [
                                                        {
                                                            name: "navigation.Id",
                                                            key: "Id",
                                                        },
                                                    ],
                                                    type: 'get',
                                                }
                                            ]
                                        },
                                    ]
                                }
                            }]
                        },
                        // 'edit': {
                        //     rules: [
                        //         {
                        //             run: {
                        //                 valid: [
                        //                     {
                        //                         type: 'edit',

                        //                     },

                        //                 ]
                        //             }
                        //         }
                        //     ]
                        // },
                        'exitfullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'exitfullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'fullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'fullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        // 'createduplicate': {
                        //     rules: [
                        //         {
                        //             run: {
                        //                 valid: [
                        //                     {
                        //                         type: 'createduplicate',

                        //                     },

                        //                 ]
                        //             }
                        //         }
                        //     ]
                        // },
                        'Edit': {
                            rules: [
                                {
                                    fields: [
                                        'Edit'
                                    ],
                                    run: {
                                        valid: [
                                            {
                                                type: 'edit',

                                            },
                                            { type: "prop", name: "save", isVisible: true },
                                            { type: "prop", name: "Edit", isVisible: false },
                                            { type: "prop", name: "saveduplicate", isVisible: false },
                                            { type: "prop", name: "createduplicate", isVisible: true },

                                        ]
                                    }
                                }
                            ],
                            fields: [
                                'Edit'
                            ]
                        },
                        // 'createduplicate': {
                        //     rules: [
                        //         {
                        //             fields: [
                        //                 'createduplicate'
                        //             ],
                        //             run: {
                        //                 valid: [
                        //                     {
                        //                         type: 'edit',

                        //                     },
                        //                     { type: "prop", name: "saveduplicate", isVisible: true },
                        //                     { type: "prop", name: "createduplicate", isVisible: true },
                        //                     { type: "prop", name: "Edit", isVisible: false },

                        //                 ]
                        //             }
                        //         }
                        //     ],
                        //     fields: [
                        //         'createduplicate'
                        //     ]
                        // },
                        'save': {
                            rules: [
                                {
                                    fields: [
                                        'save'
                                    ],
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Admin') + `/api/NoticeTypes/Update`,
                                                        type: 'put',
                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ],
                            fields: [
                                'save'
                            ],
                        },
                        'saveduplicate': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Admin') + `/api/NoticeTypes/Add`,
                                                        type: 'post',

                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    },
                    // 'afterSave': {
                    //     'saveduplicate': {
                    //         rules: [
                    //             {
                    //                 run: {
                    //                     valid: [
                    //                         {
                    //                             type: 'save',
                    //                             "read": [
                    //                                 {
                    //                                     "url": getEnvVariable('Admin') + `/api/NoticeTypes/Add`,
                    //                                     type: 'post',

                    //                                 }
                    //                             ],
                    //                         },

                    //                     ]
                    //                 }
                    //             }
                    //         ]
                    //     },
                    //     // 'save': {
                    //     //     rules: [
                    //     //         {
                    //     //             run: {
                    //     //                 valid: [
                    //     //                     {
                    //     //                         type: 'save',
                    //     //                         "read": [
                    //     //                             {
                    //     //                                 "url": getEnvVariable('Admin') + `/api/NoticeTypes/Update`,
                    //     //                                 type: 'put',
                    //     //                             }
                    //     //                         ],
                    //     //                     },

                    //     //                 ]
                    //     //             }
                    //     //         }
                    //     //     ]
                    //     // },
                    // }
                }

            }
        },

    }
}

export default ViewNotice



