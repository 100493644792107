import React, { useEffect, useState, Fragment } from 'react'
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Dropdown from '../../../modules/form/cformdropdown';
import TextBox from '../../../modules/form/cformtextbox';
import { IconButton } from '@material-ui/core';

export default function LabelExpression(props) {
    const { dropdownvalues, transformProps, label, edit, handleFieldChange } = props;
    const [add, setAdd] = useState(false)
    const [addValue, setAddValue] = useState(null)
    const [reload, setReload] = useState(false)
    let value = props.value && JSON.parse(props.value) || {};

    const handleChange = (evt, index) => {
        const { name } = evt.target;
        if (name === 'postfix') {
            value[name] = evt.target.value
        }
        else {
            if (value && value[index]) {
                value['list'][index][name] = evt.target.value
            }
            else {
                if (!addValue) {
                    let subvalue = Object.assign({}, { [name]: evt.target.value })
                    setAddValue(subvalue)
                }
                else {
                    addValue[name] = evt.target.value;
                    setAddValue(addValue)
                    setReload(!reload)
                }
            }
        }
        if (!add)
            handleFieldChange({ target: { name: props.name, value: JSON.stringify(value), checked: false } })
    }

    const handleEachSave = () => {
        if (value && value.list)
            value['list'].push(addValue)
        else {
            value['list'] = [];
            value['list'].push(addValue)
        }
        handleFieldChange({ target: { name: props.name, value: JSON.stringify(value), checked: false } })
        setAdd(false);
        setAddValue(null)
    }

    const handleDelete = (evt, index) => {
        if (value.list.length > 0) {
            value.list.splice(index, 1);
            setAdd(false);
            handleFieldChange({ target: { name: props.name, value: JSON.stringify(value), checked: false } })
        }

    }

    return (<div className="row">
        {reload}
        <small className="fw-bold col-sm-12" style={{ fontSize: '12px' }}>{label} {edit && <IconButton size="small" color='primary' onClick={() => setAdd(true)} ><AddIcon />  </IconButton>}<br /></small>
        {add &&
            <Fragment><LabelComponets handleChange={handleChange} edit={true} dropdownvalues={dropdownvalues} transformProps={transformProps} value={addValue} /> <IconButton size="small" color="primary" onClick={() => handleEachSave()} ><SaveIcon />  </IconButton></Fragment>
        }
        <div className="col-12">
            {value && value.list && value.list.length > 0 && <small className="row"> {value.list.map((each, index) => {
                return <Fragment><LabelComponets handleChange={handleChange} index={index} edit={edit} dropdownvalues={dropdownvalues} transformProps={transformProps} value={each} />
                    {edit && <IconButton size="small" color="primary" onClick={(evt) => handleDelete(evt, index)} ><DeleteIcon />  </IconButton>}   <br /></Fragment>
            })}<br /></small>}
        </div>
        {((value && Object.keys(value).length > 0) || add) && <div className="col-sm-10">< TextBox label={'Postfix'} edit={edit} handleFieldChange={(evt) => handleChange(evt)} name="postfix" value={value && value.postfix || ''} /></div>}
    </div>)

}


function LabelComponets(props) {

    const { handleChange, value, dropdownvalues, edit, transformProps, index, } = props;
    return <div className="row col-sm-12">
        <div className="col-sm-6">
            <TextBox label={'Prefix'} edit={edit} handleFieldChange={(evt) => handleChange(evt, index)} name="label" value={value && value.label || ''} />
        </div>
        <div className="col-sm-6">
            {dropdownvalues && <Dropdown edit={edit} name="fieldName" label={'Field Name'} value={value && value.fieldName || ''} dropdownvalues={dropdownvalues || []}
                transformProps={transformProps} handleFieldChange={(evt) => handleChange(evt, index)} />}
            {!dropdownvalues && <TextBox label={'Field Name'} edit={edit} handleFieldChange={(evt) => handleChange(evt, index)} name="fieldName" value={value && value.fieldName || ''} />
            }
        </div>
    </div>
}