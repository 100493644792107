/**
      *********************************************************
      Description: Handles the multiduallistbox component with form fields below the multiduallistbox. Not completely written, still needs to be verified and modified.
      Required props:  type, components.
      limitations    : N/A.
      *********************************************************
**/

import React, { Component, Fragment } from 'react';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { requestApi } from './utilities'
import { HashRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import SubForm from './csubform'
import Typography from '@material-ui/core/Typography';
import FormHeader from './cformheader'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { unsavedData } from '../../actions';
import { FormControl, FormHelperText } from '@material-ui/core';

class CMultiDualListBox extends Component {
    constructor(props) {
        super(props)
        console.log(props);
        /**
                *********************************************************
                Description: Initializing the state to handle the duallist box.
                Required props:  N/A.
                limitations    : N/A.
                *********************************************************
        **/
        this.state = {
                firstboxselected: [],
                firstboxavailable: [],
                secondboxselected: [],
                secondboxavailable: [],
                firstboxresults: null,
                secondboxresults: null,
                currentfirstboxselecteditem: null,
                currentsecondboxselecteditem: null,
                changedFieldName: [],
                opensnackbar: false,
                snackbarmessageType: '',
                snackbarmessage: '',
                errortext:'',
       

            };
    }


    /**
      *********************************************************
      Description: Handles firstbox change when the values are moved between selected and available.
      Required props:  components. Within components one prop which is an object and contains the properties of firstbox.
      limitations    : N/A.
      *********************************************************
**/
    onFirstBoxChange = (selected) => {
        const { firstboxselected } = this.state
        const { options, components } = this.props
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const firstboxtranformProps = firstboxoptions ? firstboxoptions.transformProps : null

        const { firstboxresults } = this.state
        const firstboxresultsavailable = firstboxresults[firstboxtranformProps.available.key]
        const firstboxresultsselected = firstboxresults[firstboxtranformProps.selected.key]
        let newselecteditems = []
        this.setState({ errortext: ""})
        if (selected.length > firstboxselected.length) {
            const addeditemsvalue = selected.filter(each => {
                const val = firstboxselected.find(e => e === each)
                if (!val) return true
                else return false
            })

            firstboxresultsavailable.forEach(each => {
                const val = each[firstboxtranformProps.available.value]
                if (addeditemsvalue.find(e => e === val) !== undefined) {
                    const temp = { ...each, [firstboxtranformProps.selected.label]: each[firstboxtranformProps.available.label], [firstboxtranformProps.selected.value]: each[firstboxtranformProps.available.value] }
                    newselecteditems.push(temp)
                }
            })
            newselecteditems = firstboxresultsselected.concat(newselecteditems)
        }
        if (selected.length < firstboxselected.length) {
            firstboxresultsselected.forEach(each => {
                const val = each[firstboxtranformProps.selected.value]
                if (selected.find(e => e === val) !== undefined) {
                    newselecteditems.push(each)
                }
            })
        }
        console.log('previous selectd, new selected', firstboxresultsselected, newselecteditems)

        this.setState({
            firstboxselected: selected,
            firstboxresults: { ...this.state.firstboxresults, [firstboxtranformProps.selected.key]: newselecteditems }
        }, () => {
            this.props.unsavedData({results : this.state.firstboxresults , compareresults : this.state.compareresults});
        })
    };

    onSecondBoxChange = (selected) => {
        this.setState({
            secondboxselected: selected
        })
    }

    /**
      *********************************************************
      Description: Gets the values for the duallist box and setups click listener on each of the values.
      Required props:  components.
      limitations    : N/A.
      *********************************************************
**/
    componentDidMount() {
        const { options, components, eventOn, duallistprops } = this.props
        if(options && options.subtypeurls)
        {

        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const firstboxcrud = firstboxoptions ? firstboxoptions.crud : null
        const customurl = firstboxcrud && firstboxcrud.customurl ? firstboxcrud.customurl.read : null
        const firstboxget = customurl ? customurl.get : null
        const { matchProps, routeProps, extraProps, customProps, customextraProps } = firstboxget ? firstboxget : {}
        let customvalue
        let ids = []
        if (Array.isArray(customextraProps)) {
            customextraProps.forEach(each => {
                const { value, custom } = each
                if(customextraProps) {
                    ids.push({ key: custom, value: this.props.dataItem[custom] })
                }

            })
        }
            requestApi(customurl, ids).then(res => {
                customvalue=res       //[options.subtypeurls]
                this.newComponentDidMount(customvalue);
            })
        }
        else {
            this.newComponentDidMount();
        }

    }

    newComponentDidMount = (customvalue) => {
        const { options, components, eventOn, duallistprops } = this.props
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const firstboxtranformProps = firstboxoptions ? firstboxoptions.transformProps : null
        const firstboxcrud = firstboxoptions ? firstboxoptions.crud : null
        const firstboxread = firstboxcrud ? firstboxcrud.read : null
        const firstboxget = firstboxread ? firstboxread.get : null
        //const firstboxrouteProps = firstboxget ? firstboxget.routeProps : null
        // id = firstboxrouteProps ? this.props.match.params[firstboxrouteProps.value] : null
        const firstboxeventsavailable = firstboxcrud ? firstboxcrud.available : null
        const firstboxeventsselected = firstboxcrud ? firstboxcrud.selected : null

        const firstboxeventsavailableget = firstboxeventsavailable ? firstboxread.get : null
        const firstboxeventsselectedget = firstboxeventsselected ? firstboxeventsselected.get : null

        const { matchProps, routeProps, extraProps, customProps, customextraProps } = firstboxget ? firstboxget : {}




        let ids = [], id
        if (Array.isArray(routeProps)) {
            routeProps.forEach(each => {
                const { value, custom } = each
                if(custom) {
                    ids.push({ key: custom, value: this.props.dataItem[custom] })
                }
                else {
                ids.push({ key: value, value: this.props.match.params[value] })
                }
            })
        }
        else if (routeProps) {
            id = routeProps ? this.props.match.params[routeProps.value] : null
        }
        else if (customProps){
            id = this.props.dataItem[customProps.custom]
        }
        else if (matchProps) {
            if (Array.isArray(matchProps)) {
                matchProps.forEach(each => {
                    const { value } = each
                    ids.push({ key: value, value: this.props.extraProps ? this.props.extraProps[matchProps[0].value] : null })
                })
            }
            //if(results) id = results[matchProps.value]

        }
        else if (Array.isArray(customextraProps)) {
            customextraProps.forEach(each => {
                const { value, custom } = each
                    ids.push({ key: custom, value: customvalue[custom]  })

            })
        }
        if (eventOn) {
            var val = ''
            switch (eventOn) {
                case "APPLICATION_STATUS_CHANGE":
                    val = 'APPLICATION_STATUS';
                    break;
                case "FIELD_VALUE_CHANGE":
                    val = 'FIELD_VALUE';
                    break;
                case "PERMIT_STATUS_CHANGE":
                    val = 'PERMIT_STATUS';
                    break;
                case "WORKFLOW_STATUS_CHANGE":
                    val = 'WORKFLOW_STATUS_CHANGE';
                    break;
                case "RESOLUTION_STATUS":
                        val = 'RESOLUTION_STATUS';
                        break;
                default:
                    val = '';
                    break;
            }
            ids.push({ key: 'eventOn', value: val });

            if (eventOn == "FIELD_VALUE_CHANGE") {
                //Refactor props for event on details
                const { matchProps, routeProps, extraProps } = firstboxeventsselectedget ? firstboxeventsselectedget : {}
                if (Array.isArray(routeProps)) {
                    routeProps.forEach(each => {
                        const { value, custom } = each
                        if(custom) {
                            ids.push({ key: custom, value: this.props.dataItem[custom] })
                        }
                        else {
                        ids.push({ key: value, value: this.props.match.params[value] })
                        }
                    })
                }
                else if (routeProps) {
                    id = routeProps ? this.props.match.params[routeProps.value] : null
                }
                else if (matchProps) {
                    if (Array.isArray(matchProps)) {
                        matchProps.forEach(each => {
                            const { value } = each
                            ids.push({ key: value, value: this.props.extraProps ? this.props.extraProps[matchProps[0].value] : null })
                        })
                    }
                }


                //firstboxeventsselected.urlpath = firstboxeventsselected.urlpath
                firstboxeventsavailableget && requestApi(firstboxeventsavailable, (ids.length > 0 ? ids : id)).then(results => {
                    console.log(results);

                    var availableCollection = results;
                    var firstboxavailablelist = [];
                    var firstboxselectedlist = [];
                    firstboxeventsselectedget && requestApi(firstboxeventsselected, (ids.length > 0 ? ids : id)).then(results1 => {
                        console.log(results1);

                        if (results1 && results1.selectedCollection.length > 0) {
                            firstboxselectedlist = results1.selectedCollection ? results1.selectedCollection : {};
                        } else {
                            firstboxavailablelist = availableCollection;
                        }
                        let selectedKeys = firstboxselectedlist.map(a => { return a.key}) || [];

                        let firstboxavailablelistbind = firstboxavailablelist.map(each => {
                            return { value: each.Value, label: each.Label }
                        });
                        let firstboxselectedlistbind=  firstboxselectedlist.map(each => {
                            return { value: each.key, label: each.key, key: each.key, name: each.key }
                        })

                        if(selectedKeys.length > 0){
                            firstboxavailablelistbind = availableCollection.filter(a => !selectedKeys.includes(a.Key)).map(each => {
                                return { value: each.Value, label: each.Label }
                            });
                            firstboxselectedlistbind =  availableCollection.filter(a => selectedKeys.includes(a.Key)).map(each => {
                                return { value: each.Value, label: each.Label, key: each.Value, name: each.Label }
                            });
                        }


                        let  firstboxresultsbind = {}
                        firstboxresultsbind.availableCollection = availableCollection.map(each => {
                            return { value: each.Value, label: each.Label, key: each.Value, name: each.Label }
                        });
                        firstboxresultsbind.selectedCollection = firstboxselectedlistbind;
                        firstboxresultsbind.parentId = this.props.dataItem['ID'];
                        firstboxresultsbind.eventId = eventOn;
                        this.setState({
                            firstboxavailable: firstboxavailablelistbind,
                            firstboxselected: selectedKeys,
                            firstboxresults: firstboxresultsbind
                        })
                    })


                })

            } else {
                //firstboxget.urlpath = firstboxget.urlpath + val;
                firstboxget && requestApi(firstboxread, (ids.length > 0 ? ids : id)).then(results => {

                    this.setState({
                        firstboxavailable: results[firstboxtranformProps.available.key].map(each => {
                            return { value: each[firstboxtranformProps.available.value], label: each[firstboxtranformProps.available.label] }
                        }),
                        firstboxselected: results[firstboxtranformProps.selected.key].map(each => {
                            return each[firstboxtranformProps.selected.value]
                        }),
                        firstboxresults: results
                    })
                })

            }


        }
        else {
            firstboxget && requestApi(firstboxread, (ids.length > 0 ? ids : id)).then(results => {

                this.setState({
                    firstboxavailable: results[firstboxtranformProps.available.key].map(each => {
                        return { value: each[firstboxtranformProps.available.value], label: each[firstboxtranformProps.available.label] }
                    }),
                    firstboxselected: results[firstboxtranformProps.selected.key].map(each => {
                        return each[firstboxtranformProps.selected.value]
                    }),
                    firstboxresults: results,
                    compareresults : results
                })


            })
        }



        this.firstselectedbox.addEventListener('click', (event) => {
            const { firstboxresults } = this.state
            const selectedvalue = Number(event.target.value)
            const selecteditem = firstboxresults[firstboxtranformProps.selected.key].find(each => selectedvalue === each[firstboxtranformProps.selected.value])


            if (secondbox) {
                const secondboxoptions = secondbox ? secondbox.options : null
                const secondboxtranformProps = secondboxoptions ? secondboxoptions.transformProps : null
                const secondboxboxcrud = secondboxoptions ? secondboxoptions.crud : null
                const secondboxread = secondboxboxcrud ? secondboxboxcrud.read : null
                const secondboxget = secondboxread ? secondboxread.get : null
                const secondboxrouteProps = secondboxget ? secondboxget.routeProps : null
                const id = secondboxrouteProps ? this.props.match.params[secondboxrouteProps.value] : null
                const { ids } = secondboxget ? secondboxget : null
                let subids = [id]
                if (ids) {
                    ids.forEach(each => {
                        subids.push(selecteditem[each])
                    })
                }
                secondboxread && requestApi(secondboxread, ids ? subids : id).then(results => {

                    this.setState({
                        secondboxavailable: results[secondboxtranformProps.available.key].map(each => {
                            return { value: each[secondboxtranformProps.available.value], label: each[secondboxtranformProps.available.label] }
                        }),
                        secondboxselected: results[secondboxtranformProps.selected.key].map(each => {
                            return each[secondboxtranformProps.selected.value]
                        }),
                        secondboxresults: results
                    })
                })
                this.secondselectedbox.addEventListener('click', (event) => {
                    console.log('second box')
                })
            }
            this.setState({
                currentfirstboxselecteditem: selecteditem
            })
        });
    }

    // changeResults = (objProperty, len, splitName, value) => {
    //     if (len === 0) return value
    //     else {
    //         const index = splitName.length - len
    //         const name = splitName[index]
    //         if (name) return { ...objProperty, [name]: this.changeResults(objProperty[name], --len, splitName, value) }
    //     }
    // }

    /**
    *********************************************************
    Description: handles the form field values for the first duallistbox.
    Required props:   name, value.
    limitations    : N/A.
    *********************************************************
**/

    handleFirstBoxFieldChange = (event) => {
        const { name, value } = event.target
        const { options, components } = this.props
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const firstboxtranformProps = firstboxoptions ? firstboxoptions.transformProps : null
        this.setState({
            changedFieldName: [{ name: name, value: value }],
            firstboxresults: { ...this.state.firstboxresults, [firstboxtranformProps.selected.key]: { ...this.state.firstboxresults[firstboxtranformProps.selected.key], [name]: value } }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.save != this.props.save) {
            if (this.props.save) this.onhandleSave()
        }
    }

    /**
     *********************************************************
     Description: handles the saving of the results for the firstdual list box.
     Required props:  components. Within components first dual list box save is needed.
     limitations    : N/A.
     *********************************************************
**/

    onhandleSave = () => {

        const id = this.props.options && this.props.options.subtypeurls ? [{key : 'Id', value :this.props.match.params.Id}] : this.props.match.params.routeid
        const { options, components } = this.props
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const firstboxcrud = firstboxoptions ? firstboxoptions.crud : null
        const firstboxsave = firstboxcrud ? firstboxcrud.update : null
        const message = firstboxsave && firstboxsave.message ? firstboxsave.message : null
        const { firstboxresults, secondboxresults } = this.state
        const { multiboxes, save, title } = options ? options : {}
        if (firstboxsave) {
            requestApi(firstboxsave, id,firstboxresults,null,null,message).then(results => {
                this.props.unsavedData({ results: this.state.results, compareresults: this.state.results });
                if (this.props.save) this.props.handleSave()
              //  this.props.snackbarMessage((title ? title : '') + ' Saved Successfully', 'success')

            }).catch(e=> {
                e.then(err => {
                    if(Array.isArray(err.selectedCollection) && err.selectedCollection.length > 0)
                    this.setState({ errortext: err.selectedCollection[0] });
                })

                if (this.props.save) this.props.handleSave()
             //   this.props.snackbarMessage('Invalid Data, Please check the data', 'error')
            });
        }

    }
    /**
          *********************************************************
          Description: handles closing of the alerts.
          Required props:   N/A.
          limitations    : N/A.
          *********************************************************
    **/

    handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ opensnackbar: false });
    };

    /**
      *********************************************************
      Description: handles rendering of the duallist boxes and the forms beneath it.
      Required props:   type ,components, options.
      limitations    : N/A.
      *********************************************************
**/

    render() {
        const { firstboxselected, firstboxavailable, secondboxselected, secondboxavailable ,errortext} = this.state
        const { options, components, compmap, helperText, error, required } = this.props
        const { multiboxes, save, title } = options ? options : {}
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const secondboxoptions = secondbox ? secondbox.options : null
        const firstboxprops = {}, secondboxprops = {}
        if (multiboxes) {
            if (firstboxoptions) {
                firstboxprops.width = firstboxoptions.width || 6
                firstboxprops.helperText = firstboxoptions.helperText || ''
                firstboxprops.name = firstboxoptions.name || 'firstbox'
            }
            if (secondboxoptions) {
                secondboxprops.width = secondboxoptions.width || 6
                secondboxprops.helperText = secondboxoptions.helperText || ''
                secondboxprops.name = secondboxoptions.name || 'secondbox'
            }
        }
        else {
            firstboxprops.width = firstboxoptions.width || 6
            firstboxprops.helperText = firstboxoptions.helperText || ''
            firstboxprops.name = firstboxoptions.name || 'firstbox'
        }
        const { opensnackbar, snackbarmessage, snackbarmessageType } = this.state
        const { headerProps, designprops } = options
        const { savebutton, closebutton } = headerProps ? headerProps : {}

        return (
            <Fragment>
                {headerProps && <FormHeader title={title} handleSave={this.onhandleSave} savebutton={savebutton} handleClose={this.props.onClose} closebutton={closebutton} duallistheader={true} designprops={designprops}/>}             

                <div className="row mt-3">
                    {firstbox && <div className={"col-" + firstboxprops.width}>
                        <DualListBox
                            options={firstboxavailable}
                            selected={firstboxselected}
                            onChange={this.onFirstBoxChange}
                            selectedRef={(element) => { this.firstselectedbox = element; }}
                            showHeaderLabels={true}
                            icons={{
                                moveLeft: '<',
                                moveAllLeft: '<<',
                                moveRight: '>',
                                moveAllRight: '>>'
                            }}
                        />
                        <Typography>
                            {firstboxprops.helperText}
                        </Typography>
                        {firstbox.components && <SubForm {...firstbox} compmap={compmap} changedFieldName={this.state.changedFieldName} handleFieldChange={this.handleFirstBoxFieldChange} results={this.state.currentfirstboxselecteditem} />}
                    </div>}
                    {secondbox && <div className={"col-" + secondboxprops.width}>
                        <DualListBox
                            options={secondboxavailable}
                            selected={secondboxselected}
                            onChange={this.onSecondBoxChange}
                            selectedRef={(element) => { this.secondselectedbox = element; }}
                            showHeaderLabels={true}
                            icons={{
                                moveLeft: '<',
                                moveAllLeft: '<<',
                                moveRight: '>',
                                moveAllRight: '>>'
                            }}
                        />
                        <Typography>
                             {secondboxprops.helperText}
                        </Typography>
                        {secondbox.components && <SubForm {...secondbox} compmap={compmap} changedFieldName={this.state.changedFieldName} handleFieldChange={this.handleSecondBoxFieldChange} />}
                    </div>}
                </div>
                {/* {
                    save && <div className="row">
                        {save && <div className="ml-4 mb-3 pt-2"> <Button variant="contained" onClick={this.onhandleSave} color="secondary" > Save</Button></div>}
                    </div>

                } */}
         {this.state.errortext && <FormHelperText error={true}>{this.state.errortext}</FormHelperText>}


            </Fragment>
        )
    }
}

const mapActions = dispatch => {
    return bindActionCreators({unsavedData }, dispatch);
}

CMultiDualListBox = connect(null, mapActions)(CMultiDualListBox)
export default withRouter(CMultiDualListBox)

