import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DownIcon from '@material-ui/icons/ArrowDownward';
import UpIcon from '@material-ui/icons/ArrowUpward';
import RightIcon from '@material-ui/icons/Check';
import WrongIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import { useEffect, useState } from 'react';
import QueryBuilder, { formatQuery as Formatter } from 'react-querybuilder';
import { useParams } from 'react-router-dom';
import { getEnvVariable } from '../../../../modules/environmentalList';
import { DeleteRecord, GetData, PostData, PutData } from '../../helpers/requests';
import { createRequest, requestApi } from '../../helpers/rest';
import Dropdown from '../dropdown';
import Label from '../label';
import Switch from '../switch';
import Textbox from '../textbox';
import CalcualtedQueryBuilder from '../../../../core/modules/dnd/widgets/validationrules/mainquerybuilder'
import CheckboxesTags from '../checkautocomplete';
//import { materialControlElements } from '@react-querybuilder/material';
const _ = require('lodash');

const extraProps = {
    transformProps: {
        label: 'Name',
        value: 'Id'
    }
}

const transformProps = {
    "A": true,
    "I": false,
    true: "A",
    false: "I"
}

export default function TemplateBusinessRule({ fieldValues }) {
    const [expanded, setExpanded] = useState(false);
    const [list, setList] = useState([])
    const [current, setCurrent] = useState({})
    const [fields, setFields] = useState([])
    const [templates, setTemplates] = useState([])
    const [notices, setNotices] = useState([])
    const [add, setAdd] = useState(false)
    const [reload, setReload] = useState(false)
    const [errorlist,setErrorList] = useState({})
    const params = useParams();


    useEffect(() => {
        const business = [
            {
                url: getEnvVariable('Sidebar') + `/api/BusinessRules/Builder?SitePermitTypeId=${fieldValues['editInfo']['SitePermitType']}`,
                type: 'get'
            },
            {
                url: getEnvVariable('TemplateAPI') + `/api/MasterTemplates/All/SubModuleKey/Active/${fieldValues['editInfo']['SitePermitType']}/${fieldValues['editInfo']['SubModuleKey']}`,
                type: 'get'
            },
            {
                url: getEnvVariable('Admin') + `/api/NoticeTypes/All/Active`,
                type: 'get'
            },
        ]
        Promise.all(business.map(each => requestApi(createRequest(each, {}, null)).catch(error => { return error; }))).then(resp => {
            const [field, template, notice] = resp;
            setFields(field)
            setTemplates(template)
            setNotices(notice.filter(each => each.Id.toString() !== params.mid))
            getData()
        }).catch(ex => {
            console.error(ex)
        })
    }, [])

    const getData = () => {
        let url = getEnvVariable('TemplateAPI') + `/api/TemplateBusinessRules/AllNoticeTypeBR/${fieldValues['editInfo']['Id']}`
        GetData(url).then(resp => {
            let temp = resp.sort((a, b) => a.SectionOrder - b.SectionOrder).map(each => {
                if (each.JsonQueryStr && each.JsonQueryStr !== '')
                    each['ISQuery'] = 'A'
                else each['ISQuery'] = 'I'
                return each
            })
            setList(temp)
            setReload(!reload)
        }).catch(ex => {
            console.error(ex)
        })
    }

    const handleFieldValue = (idx = -1, evt = {}, nested = null) => {
        const { name, value } = evt.target
        let temp = add ? current : list[idx];
        let tempLists = list
        if (nested)
            if (temp[nested])
                temp[nested][name] = value;
            else {
                temp[nested] = {}
                temp[nested][name] = value;
            }
        else temp[name] = value;
        if (idx === -1 && add) {
            setCurrent(temp)
        }
        else {
            tempLists[idx] = temp
            setList(tempLists)
        }
        setReload(!reload)
    }

    const handleSave = () => {
        let result = current;
        result.Status = add ? 'A' : result.Status
        result.RefId = add ? params.mid : result.RefId
        //   result.jsonQueryStr = JSON.stringify(result.jsonQuery)
        result.JsonQuery = JSON.stringify(result.jsonQuery)
        result.SqlQuery = result.JsonQueryStr ? Formatter(JSON.parse(result.JsonQueryStr), 'sql') : null
        let url = ''
        if (add) {
            url = getEnvVariable('TemplateAPI') + `/api/TemplateBusinessRules/AddNoticeTypeTemplateBR/${result.RefId}`;
            PostData(url, result).then(resp => {
                setAdd(false);
                getData()
            }).catch(errors => {
                console.log(errors)
                errors.then(err => {
                
                    if (err['Name']) {
                        setErrorList({ errorYN: true, helperText: err['Name'][0] });
                        console.log(errorlist)
                    }
                    
                })
            })
        }
        else {
            url = getEnvVariable('TemplateAPI') + `/api/TemplateBusinessRules/Update/${result.RefId}`;
            PutData(url, result).then(res => {
                getData();
                setCurrent({})
            }).catch(errors => {
                console.log(errors)
                errors.then(err => {
                
                    if (err['Name']) {
                        setErrorList({ errorYN: true, helperText: err['Name'][0] });
                        console.log(errorlist)
                    }
                    
                })
            })
        }
    }

    const handleDelete = (each) => {
        let url = getEnvVariable('TemplateAPI') + `/api/TemplateBusinessRules/Delete/${each.Id}`;
        DeleteRecord(url, '', each).then(res => {
            getData();
            setCurrent({})
        }).catch(ex => {
            console.error(ex)
        })
    }

    const handleMove = (each, type) => {
        let url = getEnvVariable('TemplateAPI') + `/api/TemplateBusinessRules/Move/${each.Id}?isDown=${type}`;
        PutData(url, each).then(res => {
            getData();
            setCurrent({})
        }).catch(ex => {
            console.error(ex)
        })
    }

    const handleCurrent = (each) => {
        setCurrent(each);
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return <div className='col-12'>
        {reload}
        <Fab size="small" className="float-end " color="primary" aria-label="add" title = "Add Build Notice Template" onClick={() => { setAdd(!add); setCurrent({}) }}>        <AddIcon />    </Fab>
        <div className='clearfix' />

        {
            add && <TemplateBusinessRuleScreen each={current} handleFieldValue={handleFieldValue} fields={fields} index={-1} notices={notices} templates={templates}
                showSave={add} handleSave={handleSave} add={add} handleChange={handleChange} expanded={add ? -1 : expanded} errors ={errorlist}/>
        }
        <Box>
            {fields && fields.length > 0 && list && list.map((each, index) => {
                return <TemplateBusinessRuleScreen each={each.Id === current.Id ? current : each} handleFieldValue={handleFieldValue} index={index} fields={fields}
                    notices={notices} templates={templates} setCurrent={() => handleCurrent(each)} showSave={current && Object.keys(current) > 0 ? true : false}
                    handleSave={handleSave} handleMove={(each, type) => handleMove(each, type)} handleChange={handleChange}
                    expanded={add ? -1 : expanded} handleDelete={handleDelete} disabled={add ? true : false} errors ={errorlist}
                />
            })}
        </Box>
    </div >
}


function TemplateBusinessRuleScreen({ each, handleFieldValue, index = -1, fields = [], notices = [], templates = [],
    showSave, setCurrent, handleSave, add, handleMove, handleChange, expanded, handleDelete, disabled,errors }) {

     const {errorYN,helperText} =errors;

    const [save, setSave] = useState(showSave);
    const [edit, setEdit] = useState(add ? add : false);
    const [calculated, setCalculated] = useState(true);

    useEffect(() => {
        setEdit(false)
        setSave(showSave)
    }, [expanded])

    const handleEdit = () => {
        setCurrent(each);
        setEdit(true)
        setSave(true)
    }
    if (each)
        return <div className="my-1 col-12">
            <Accordion expanded={expanded === index} onChange={handleChange(index)} disabled={disabled} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <div>
                        <small className='text-primary'>{add ? 'Build Notice Template:' : each.Name}</small>
                        <div className='float-right'>
                            {(!add && edit) && <ButtonGroup>
                                <IconButton aria-label="delete" onClick={() => handleMove(each, false)}>
                                    <UpIcon fontSize="small" />
                                </IconButton>
                                <IconButton aria-label="delete" onClick={() => handleMove(each, true)}>
                                    <DownIcon fontSize="small" />
                                </IconButton>
                            </ButtonGroup>}

                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails >
                    {(edit || add) ? <Textbox className="col-8 m-3" handleFieldValue={(evt) => handleFieldValue(index, evt)} name={'Name'} value={each.Name} label="Name" required  error={errorYN} helperText={errorYN && helperText}/> :
                        <Label className="col-8 m-3" value={each.Name} label="Name" />}
                    <div className="col-4 ">
                        <div className='float-right'>
                   {(save || edit) ? <IconButton color="primary" onClick={handleSave} title="Save"> <SaveIcon /> </IconButton> :
                                <IconButton color="primary" onClick={() => handleEdit(each)} title="Edit"><EditIcon /> </IconButton>}
                            {edit && <IconButton color="primary" onClick={() => handleDelete(each)} title="Delete"><DeleteIcon /> </IconButton>}
                        </div>
                    </div>
                </AccordionDetails>
                <AccordionDetails className='col m-3' >
                    {(edit || add) ? <Switch label='Is Condition?' name="ISQuery" value={(each && each.ISQuery) || null} handleFieldValue={(e) => handleFieldValue(index, e)} extraProps={{
                        transformProps: transformProps
                    }} /> : <Label value={each.ISQuery === 'A' ? 'Yes' : 'No'} label="Is Condition?" />}
                </AccordionDetails>
                {each.ISQuery === 'A' && <AccordionDetails >
                    <small className='fw-bold col my-2 m-3'>Condition <br />
                        <Switch name='calculated' label="Look Up Domains List" value={calculated ? 'Y' : 'N'} disabled={(edit || add) ? false : true}
                            handleFieldValue={() => setCalculated(!calculated)} extraProps={{
                                transformProps: {
                                    "Y": true,
                                    "N": false,
                                    true: "Y",
                                    false: "N"
                                }
                            }} /><br />
                        {!calculated ? <QueryBuilder disabled={(edit || add) ? false : true} fields={fields || []} name='JsonQueryStr'
                            showCombinatorsBetweenRules={true} showCloneButtons={true} enableDragAndDrop={true} //controlElements={materialControlElements}
                            query={each.JsonQueryStr ? JSON.parse(each.JsonQueryStr) : null} onQueryChange={(val) => {
                                if (add || (each.JsonQueryStr !== JSON.stringify(val)))
                                    handleFieldValue(index, {
                                        target: {
                                            value: JSON.stringify(val),
                                            name: 'JsonQueryStr',
                                        }, currentTarget: { dataset: null }
                                    })
                            }} /> :
                            <CalcualtedQueryBuilder disabled={(edit || add) ? false : true} fields={fields || []} name='JsonQueryStr'
                                query={each.JsonQueryStr ? JSON.parse(each.JsonQueryStr) : null} onQueryChange={(val) => {
                                    if (add || (each.JsonQueryStr !== JSON.stringify(val)))
                                        handleFieldValue(index, {
                                            target: {
                                                value: JSON.stringify(val),
                                                name: 'JsonQueryStr',
                                            }, currentTarget: { dataset: null }
                                        })
                                }} />}
                    </small>
                </AccordionDetails>}
                {each.ISQuery === 'A' && <AccordionDetails className='col m-3'>
                    {
                        ['IfValidSection', 'ElseInValidSection'].map(rule => {
                            return <Conditional edit={(edit || add)} rule={rule} each={each} handleFieldValue={(e) => handleFieldValue(index, e, rule)}
                                index={index} notices={notices} templates={templates} />
                        })
                    }
                </AccordionDetails>}
                {(each.ISQuery === 'I' || !each.ISQuery) && <AccordionDetails className='col m-3'>
                    {
                        ['IfValidSection'].map(rule => {
                            return <Conditional edit={(edit || add)} rule={rule} each={each} handleFieldValue={(e) => handleFieldValue(index, e, rule)}
                                index={index} notices={notices} templates={templates} />
                        })
                    }
                </AccordionDetails>}
                <AccordionDetails className='col m-3' >
                    {!add && (edit ? <Switch  label='Status' name="Status" value={each && each.Status || null} handleFieldValue={(e) => handleFieldValue(index, e)} extraProps={{
                        transformProps: transformProps
                    }} /> : <Label  value={each.Status === 'A' ? 'Active' : 'In-Active'} label="Status" />)}
                </AccordionDetails>
            </Accordion >
        </div>
    else <></>
}

function Conditional({ rule, handleFieldValue, each = {}, notices, templates, edit }) {


    return <div className='col-6 my-1'>
        <small className='fw-bold my-4'>{rule === 'ElseInValidSection' ? <Chip
            className='col-12'
            icon={<WrongIcon />}
            label="In-Valid"
            color="secondary"
            variant="outlined"
        />
            : <Chip
                className='col-12'
                icon={<RightIcon />}
                label="Valid"
                color="primary"
                variant="outlined"
            />
        }</small>
        {/* {edit ?  */}
        <CheckboxesTags handleFieldValue={(evt) => handleFieldValue(evt)} name={'TemplateId'}
            value={each && each[rule] && each[rule]['TemplateId']} ismulti={true}
            label={'Master Template'} disabled={edit ? false : true}
            extraProps={{
                transformProps: {
                    label: 'Name',
                    value: 'TemplateId'
                }
            }} items={templates} />
            {/* // : <Label className='m-2'
                //     value={each && each[rule] ? (templates.find(e => { return e.TemplateId === (each && each[rule] && each[rule]['TemplateId']) }) || {}).Name : ''}
                //     label="Master Template" />}
            // {edit ?  */}
            <Dropdown disabled={edit ? false : true} handleFieldValue={(evt) => handleFieldValue(evt)} name={'SubRefId'} value={each && each[rule] && each[rule]['SubRefId']} label={'Select Another Notice'}
            extraProps={extraProps} items={notices} />
        {/* : <Label className='m-2' value={(notices.find(e => { return e.Id === (each && each[rule] && each[rule]['SubRefId']) }) || {}).Name} label="Notices" />} */}
    </div>
}
