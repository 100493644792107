/**        
      *********************************************************
      Updated By: Vijaya
      Comments: Implemented for Confirmation popup.
      *********************************************************
**/
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';


class cconfirmationpopup extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    open: true,
    title: '',
    description: ''

  };

  onSubmit = (e) => {
    this.setState({ open: false }, () => {
      this.props.onSubmit();
    })
  }

  onClose = (e) => {
    this.setState({ open: false },() => {
      this.props.onClose();
    })

  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog open={this.state.open}>
          <DialogTitle>{this.props.title}</DialogTitle>

          <DialogContent>
            <DialogContentText>{this.props.description}</DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button color="primary" onClick={this.onSubmit}>
              YES, I AGREE
                    </Button>
            <Button color="primary" onClick={this.onClose}>
              CANCEL
                    </Button>
          </DialogActions>
        </Dialog>
      </div>
    );

  }
}

export default cconfirmationpopup;
