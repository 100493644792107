import { createRequest, createGridRequest, requestApi } from '../../helpers/rest'

export const  customSave =  async (params, newData, mapActionsToEffects, savetype) => {
    let save = mapActionsToEffects[savetype]
    let read = save['read']
    let allProm = [];
    let fieldValues = {...newData, ...params}
      read.map(function(each){
        allProm.push(requestApi(createRequest(each, fieldValues)))
      })

  const dataval = await Promise.all(allProm).then(results => {
      if (results)
          return { ...results[0] }
  }).catch(error => {
      return 'fail'

  })
  return dataval
}

export const  customUpdate =  async (params, newData, mapActionsToEffects, savetype) => {
  let save = mapActionsToEffects[savetype]
  let read = save['read']
  let url = read[0].url
  read[0].url = `${url.replace(`{Id}`, newData.Id)}`;
  let allProm = [];
  let fieldValues = {...newData, ...params}
    read.map(function(each){
      allProm.push(requestApi(createRequest(each, fieldValues)))
    })

const dataval = await Promise.all(allProm).then(results => {
    if (results)
        return { ...results[0] }
}).catch(error => {
    return 'fail'

})
return dataval
}