import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Switch from './switch';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "auto"
    },
    paper: {
        width: 250,
        height: 320,
        overflow: "auto"
    },
    button: {
        margin: theme.spacing(0.5, 0)
    }
}));



export default function TransferList({ label, value, extraProps, name, handleFieldValue, isCheckBox, switchProps }) {
    let { tname, tvalue } = ((extraProps || {}).transformProps) || {}
    const classes = useStyles();
    const [selected, setSelected] = React.useState(null);
    const [left, setLeft] = React.useState(value.available);
    const [right, setRight] = React.useState(value.selected);

    useEffect(() => {
        setLeft(value.available)
        setRight(value.selected)
    }, [value])

    const handleToggle = (value) => () => {
        setSelected(value);
    };

    const not = (a, b) => {
        let temp = a.filter((e) => {
            if (e[tvalue] !== b) {
                return e
            }
        });
        return temp
    }


    const handleAllRight = () => {
        let events = {
            target: {
                value: {
                    available: [],
                    selected: right.concat(left)
                }, name: name, checked: false
            }, currentTarget: { dataset: null }
        }
        handleFieldValue(events);
        // setRight(right.concat(left));
        // setLeft([]);
        setSelected(null);
    };

    const handleCheckedRight = () => {
        let select = left.filter(each => { return each[tvalue] === selected })
        let events = {
            target: {
                value: {
                    available: not(left, selected),
                    selected: right.concat(select)
                }, name: name, checked: false
            }, currentTarget: { dataset: null }
        }
        handleFieldValue(events);
        // setRight(right.concat(selected));
        // setLeft(not(left, selected));
        setSelected(null);
    };

    const handleCheckedLeft = () => {
        let select = right.filter(each => { return each[tvalue] === selected })
        let events = {
            target: {
                value: {
                    available: left.concat(select),
                    selected: not(right, selected)
                }, name: name, checked: false
            }, currentTarget: { dataset: null }
        }
        handleFieldValue(events);
        // setLeft(left.concat(selected));
        // setRight(not(right, selected));
        setSelected(null);
    };

    const handleAllLeft = () => {
        let events = {
            target: {
                value: {
                    available: left.concat(right),
                    selected: []
                }, name: name, checked: false
            }, currentTarget: { dataset: null }
        }
        handleFieldValue(events);
        // setLeft(left.concat(right));
        // setRight([]);
        setSelected(null);
    };

    const handleCheckOnRight = (checked, val) => {
        let tempValue = value;
        (tempValue['selected'] || []).forEach(element => {
            if (val === element[tvalue]) {
                element[isCheckBox] = checked ? 'Y' : 'N'
            }
        });
        let events = { target: { value: tempValue, name: name, checked: false }, currentTarget: { dataset: null } }
        setLeft(tempValue.available)
        setRight(tempValue.selected)
        handleFieldValue(events);
    }

    const customList = (items, isRight = false) => (
        <Paper className={classes.paper}>
            <List dense component="div" role="list">
                {(items || []).map((each) => {
                    const labelId = `transfer-list-item-${each[tvalue]}-label`;
                    return (
                        <ListItem
                            key={each[tvalue]}
                            role="listitem"
                            button
                            className=""
                            onClick={handleToggle(each[tvalue])}                        >

                            <ListItemText id={labelId} primary={each[tname]} />
                            {isRight && isCheckBox && <ListItemIcon>
                                <Switch
                                    handleFieldValue={(evt) => { handleCheckOnRight(evt.target.checked, each[tvalue]) }}
                                    value={each[isCheckBox]}
                                    tabIndex={-1}
                                    disableRipple
                                    extraProps={switchProps}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>}
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <> <small className='fw-bold' style={{ fontSize: '12px' }}>{label}</small>
            <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                className={classes.root}
            >
                <Grid item>{customList(left, false)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleAllRight}
                            disabled={(left || []).length === 0}
                            aria-label="move all right"
                        >
                            ≫
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            disabled={!selected}
                            onClick={handleCheckedRight}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={!selected}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleAllLeft}
                            disabled={(right || []).length === 0}
                            aria-label="move all left"
                        >
                            ≪
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>{customList(right, true)}</Grid>
            </Grid>
        </>
    );
}
