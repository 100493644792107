import React, { useState, useEffect } from 'react'
import Drawer from '@material-ui/core/Drawer';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import Fab from '@material-ui/core/Fab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Typography from '@material-ui/core/Typography';
import { useHistory, useParams } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { getEnvVariable } from '../../../../modules/environmentalList';
import { requestApi, createRequest } from '../../helpers/rest';
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Badge from '@material-ui/core/Badge';



const styles = muiBaseTheme => ({
  card: {
    width: 220,
    margin: "10px",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    },
    display: "inline-block",
    textAlign: "center"

  },
  media: {
    paddingTop: "56.25%"
  },
  content: {
    textAlign: "left",
    padding: muiBaseTheme.spacing.unit * 1,
    "&:last-child": {
      paddingBottom: 0
    }
  },
  divider: {
    margin: 0
  },
  heading: {
    fontWeight: "bold"
  },
  subheading: {
    lineHeight: 1.8
  },
});

const minDrawerWidth = 700;

function RegistrationDashboardDrawer(props) {
  const { classes } = props;
  const [state, setState] = React.useState(false);
  const [count, setCount] = React.useState({});
  const [totalCount, setTotalCount] = React.useState(0);
  let history = useHistory();
  const params = useParams();
  let arr = localStorage.getItem('submodule') && JSON.parse(localStorage.getItem('submodule')) ? JSON.parse(localStorage.getItem('submodule')) : []
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };

  useEffect(() => {
    const read = [
      {
        url: getEnvVariable('MasterAdmin') + `/api/CompanyInternal/RegistrationRequest/` + getEnvVariable('ModuleKey') + `/GetPending` + `${localStorage.getItem('impersonate') ? '?impersonateFor=' + localStorage.getItem('impersonate'):''}`,
        type: 'get'
      },
    ]
    Promise.all(read.map(each => requestApi(createRequest(each, {})))).then(response => {
      const [data] = response;
      let submodulearr = arr.map(each => {
        return { key: each.key }
      })
      let finalarr = {}
      submodulearr.forEach(element => {
        const arr = data.filter(data => data.key == element.key);
        finalarr[element.key] = arr && arr.length > 0 ? arr.length : 0
      });

      setCount(finalarr)
      let sum = 0;
      arr.forEach(element => {
        sum += finalarr[element.key]
      });
      setTotalCount(sum);
    })
  }, [])



  const goToRegistrationRequest = (e) => {
    history.push('/Administration/registrationrequest');
  }

  return (
    <div className="pl-6 pt-2">
      <React.Fragment key={'right'}>
        <Badge badgeContent={totalCount} max={1000000000000}
          color="error">
          <Fab size="small" color="primary" aria-label="Pending Registration Request" className="float-right" title="Pending Registration Request"><HowToVoteIcon onClick={toggleDrawer(true)} fontSize="small" /></Fab>
        </Badge>
        <Drawer
          anchor="right"
          open={state}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          PaperProps={{ style: { width: minDrawerWidth } }}
        >
          <AppBar position="static" color='secondary'>
            <Toolbar variant="dense">
              <div className="row float-right">
                <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
                  Registration Requests
                </Typography>
              </div>
            </Toolbar>
          </AppBar>
          <Button color="primary" variant="text" aria-label="Go to Registration Request" className="float-right" title="Go to Registration Request" onClick={(e) => goToRegistrationRequest(e)} startIcon={<HowToVoteIcon />}>Go to registration requests</Button>
          <div className='pt-5'></div>
          {/* <Card componentlist={invoiceSummary} /> */}
          {arr.map(each => {
            return (
              <div className={classes.card}>
                <Card >
                  <CardMedia />
                  <CardContent className={classes.content}>
                    <Typography
                      className={"MuiTypography--subheading"}
                      variant={"caption"}
                      display="block"
                    >
                      {each.title ? each.title : each.DisplayName}
                    </Typography>
                    <Typography variant="button" display="block" gutterBottom>
                      {count[each.key]}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            )
          })}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

RegistrationDashboardDrawer = withStyles(styles)(RegistrationDashboardDrawer);
export default (withRouter)(RegistrationDashboardDrawer);