import { getEnvVariable } from '../../../modules/environmentalList';
import externaluserloginhistory from './history';

const companyinternal = {
    "type": "slidingbutton",
    "key": "companyinternaluserslidingbutton",
    "options": {
        "row": 6,
        "col": 4,
        "width": 2,
        "buttonprops": {
            "text": "User",
            "size": "large",
            "color": "primary",
            "variant": "contained"
        },
        excludefromresults: true,
        "label": "User",
        "name": "User",
        receiveValues: [{ paramname: 'Id', valuefromparent: 'Id' }],
    },
    open: true,
    components: [{
        "type": "formcontainer",
        "key": "companyinternaluserform",
        "options": {
            "title": "Groups",
            headerProps: {
                "navigateup": true,
                "navigatedown": true,
                "editbutton": false,
                "savebutton": false,
                "backbutton": true,
                "closebutton": false,
                "navigateview": true,
                "breadcrumbs": [
                    { "link": "View/Edit Consultant User", "materialicon": "person", className: 'eps-breadcrumb-item', class: 'text-light' }
                ],
            },

            hidescroll: true,
            "name": "companyinternaluserform",
            "back": {
                "popupback": true,
                "issubgrid": true
            },
        },
        "sidepopupadd": true,
        "components": [
            {
                "type": "ctab",
                "key": "tab",
                hidemargin: true,
                "name": "companyinternalusertabform",
                excludefromresults: true,
                "options": {
                    tabinfo: [
                        {
                            tablabel: "View/Edit Consultant User",
                            tabkey: 0
                        },

                        {
                            tablabel: "Update Password",
                            tabkey: 1,
                            'hidetab': true,
                            'onhidetabcondition': true,
                            "hidetabval": "I",
                            'nested': 'User',
                            alttabkey: 2,
                            hidename: 'Status'
                        },
                        {
                            tablabel: "Login History",
                            tabkey: 2,
                        },

                    ],
                },
                components: [
                    {
                        "tabkey": 0,
                        "type": "formcontainer",
                        "key": "companyuserform",
                        "options": {
                            "totalcolumns": 2,
                            "totalrows": 13,
                            "title": "User ",
                            "alignitem": "center",
                            "routes": [
                                {
                                    "route": "/companies",
                                    "routeParams": ":routeid",
                                },
                                {
                                    "route": "/users/view",
                                    "routeParams": ":routeid1"
                                },
                            ],
                            styles: {
                                margin: 15
                            }, headerProps: {
                                "editbutton": true,
                                "savebutton": true,
                                "backbutton": true,
                                "closebutton": false,
                                "navigateview": true,
                                "breadcrumbs": [
                                    // { "link": "View/Edit Applicant", href: "#/companies", popupclose: true, "materialicon": "edit", className: 'eps-breadcrumb-item ', class: 'text-light' },
                                    { "link": "View/Edit User", "materialicon": "person", className: 'eps-breadcrumb-item', class: 'text-light' }
                                ],
                            },
                            designprops: true,
                            "name": "companyaddressform",
                            "back": {
                                "routes": [
                                    {
                                        "route": "/companies",
                                        "routeParams": "routeid",
                                    },
                                    {
                                        "route": "/users",
                                        exactRoute: true
                                    },
                                ],
                                "popupback": true
                            },
                            "crud": {
                                "read": {
                                    "get": {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ExternalUser/` + getEnvVariable('ModuleKey') + `/`,
                                        "urlpath": '{UserId}',
                                        // "routeProps": {
                                        //     "value": "routeid1"
                                        // }
                                        "customProps": [{
                                            "custom": "UserId"
                                        }]
                                    }
                                },
                                "update": {
                                    "put": {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ExternalUser/` + getEnvVariable('ModuleKey') + `/`,
                                        "urlpath": 'Update/{UserId}',
                                        // "matchProps": [{
                                        //     "value": "routeid1"
                                        // }]
                                        "customProps": [{
                                            "custom": "UserId"
                                        }]
                                    }
                                }
                            },
                            "validations": [
                                {
                                    get: {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.users`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'MASTER.USERS_',
                                        addsubfieldname: 'USER.'
                                    }

                                },
                                {
                                    get: {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.externalusers`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'MASTER.EXTERNALUSERS_'
                                    }

                                },
                                {
                                    get: {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.contacts`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'MASTER.CONTACTS_',
                                        addsubfieldname: 'CONTACTS.'
                                    }

                                }
                            ],
                        },
                        "components": [
                            {
                                "type": "simplelink",
                                //"order": 6,
                                "key": "signinpincoderesendpincode",
                                "options": {
                                    "row": 0,
                                    "col": 2,
                                    "width": 12,
                                    "format": "icon",
                                    "name": "pincoderesendlink",
                                    "text": "Resend Activation Link",
                                    "crud": {
                                        "update": {
                                            "post": {
                                                "url": getEnvVariable('MasterAdmin') + `/api/InternalUser/ResendActivationLink/` + getEnvVariable('ModuleKey'),
                                                "urlpath": '/{UserId}',
                                                "customProps": {
                                                    "custom": "UserId"
                                                }
                                            }
                                        }
                                    },
                                    conditions: {
                                        hide: [{ 'Email': '' }, { 'Email': null }, { nestedhide: { nestedkey: 'Status', value: 'A', isnested: 'User' } }]
                                    },
                                }
                            },
                            {
                                "type": "formtextbox",
                                "key": "username",
                                "options": {
                                    "row": 1,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Username",
                                    "name": "User.UserName",
                                    "disabled": true,
                                    conditions: {
                                        enable: true,
                                    }
                                },

                            },
                            {
                                "type": "infotext",
                                "key": "Infotext",
                                "options": {
                                    "row": 2,
                                    "col": 0,
                                    "width": 12,
                                    "text": "Username Policy:",

                                }
                            },
                            {
                                "type": "infotext",
                                "key": "Infotext",
                                "options": {
                                    "row": 2,
                                    "col": 0,
                                    "width": 12,
                                    "text": "1. Username can contain letters (a-z), numbers (0-9), underscore (_) and periods (.).",

                                }
                            },
                            {
                                "type": "infotext",
                                "key": "Infotext",
                                "options": {
                                    "row": 2,
                                    "col": 0,
                                    "width": 12,
                                    "text": "2. Username cannot contain an ampersand (&), equals sign (=), apostrophe ('), dash (-), plus sign (+), comma (,), brackets (<,>)"

                                }
                            },
                            {
                                "type": "infotext",
                                "key": "Infotext",
                                "options": {
                                    "row": 2,
                                    "col": 0,
                                    "width": 12,
                                    "text": "3. The first character of your username must be ascii letter (a-z) or number (0-9)."

                                }
                            },
                            {
                                "type": "formtextbox",
                                "key": "firstname",
                                "options": {
                                    "row": 3,
                                    "col": 0,
                                    "width": 6,
                                    "label": "First Name",
                                    "name": "User.FirstName"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "lastname",
                                "options": {
                                    "row": 3,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Last Name",
                                    "name": "User.LastName"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "title",
                                "options": {
                                    "row": 4,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Title",
                                    "name": "Title"
                                },

                            },
                            {
                                "type": "formtextbox",
                                "key": "email",
                                "options": {
                                    "row": 5,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Email",
                                    "name": "Email"
                                },

                            },
                            {
                                "type": "cformphonetext",
                                "key": "Phone",
                                "options": {
                                    "row": 5,
                                    "col": 1,
                                    "width": 6,
                                    "label": "Contact Number",
                                    "name": "Phone"
                                }
                            },
                            {
                                "type": "formswitch",
                                "key": "statusswitch",
                                "options": {
                                    "row": 6,
                                    "col": 0,
                                    "width": 6,
                                    "label": "Status",
                                    "name": "User.Status",
                                    confirmation: true,
                                    "transformProps": {
                                        "A": true,
                                        "I": false,
                                        true: "A",
                                        false: "I"
                                    }
                                }
                            },

                            {
                                "type": "formcheckbox",
                                "key": "official",
                                "options": {
                                    "row": 6,
                                    "col": 1,
                                    "width": 3,
                                    "label": "Admin",
                                    "name": "IsOfficial",
                                    //  onlyLabel: true,
                                    initialvalue: 'N',
                                    // hidecond: getEnvVariable('IsSubModuleSelection'),
                                    // hideval: "Y",
                                    "transformProps": {
                                        "Y": true,
                                        "N": false,
                                        true: "Y",
                                        false: "N"
                                    }

                                }
                            },
                            // {
                            //     "type": "formcheckbox",
                            //     "key": "IsConsultant",
                            //     "options": {
                            //         "row": 6,
                            //         "col": 2,
                            //         "width": 3,
                            //         "label": "Consultant",
                            //         "name": "IsConsultant",
                            //   //      onlyLabel: true,
                            //         initialvalue: 'N',
                            //         "transformProps": {
                            //             "Y": true,
                            //             "N": false,
                            //             true: "Y",
                            //             false: "N"
                            //         }

                            //     }
                            // },
                            // {
                            //     "type": "simplelink",
                            //     "order": 6,
                            //     "key": "signinpincoderesendpincode",
                            //     "options": {
                            //         "name": "pincoderesendlink",
                            //         "text": "Resend Activation Link",
                            //         "crud": {
                            //             "update": {
                            //                 "post": {
                            //                     "url": getEnvVariable('MasterAdmin') + `/api/InternalUser/ResendActivationLink/` + getEnvVariable('ModuleKey'),
                            //                     "urlpath": '/{UserId}',
                            //                     "customProps": {
                            //                         "custom": "UserId"
                            //                     }
                            //                 }
                            //             }
                            //         }
                            //     }
                            // },
                            {
                                "type": "historydate",
                                "key": "datecreated",
                                "options": {
                                    "row": 8,
                                    "col": 0,
                                    "width": 6,
                                    "text": "Created On : ",
                                    "name": "CreatedOn"
                                }
                            },
                            {
                                "type": "historyuser",
                                "key": "createdby",
                                "options": {
                                    "row": 8,
                                    "col": 1,
                                    "width": 6,
                                    "text": "Created By : ",
                                    "name": "CreatedByName"
                                }
                            },
                            {
                                "type": "historydate",
                                "key": "updatedon",
                                "options": {
                                    "row": 9,
                                    "col": 0,
                                    "width": 6,
                                    "text": "Updated On : ",
                                    "name": "UpdatedOn"
                                }
                            },
                            {
                                "type": "historyuser",
                                "key": "updatedbyname",
                                "options": {
                                    "row": 9,
                                    "col": 1,
                                    "width": 6,
                                    "text": "Updated By : ",
                                    "name": "UpdatedByName"
                                }
                            }
                        ]
                    },
                    {
                        "tabkey": 1,
                        "type": "formcontainer",
                        "key": "updatepassword",
                        "options": {
                            "totalcolumns": 2,
                            "totalrows": 13,
                            "alignitem": "center",
                            "title": "Update password",
                           // "savebtn": true,
                            headerProps: {
                                "savebutton": true,
                                "closebutton": true,
                                "editbutton": true,
                                //  "navigateadd": true,
                                "navigateview": true,
                            },
                            designprops: true,
                            "tabadd": true,
                            "formdata": true,
                            "crud": {
                                "update": {
                                    "post": {
                                        "url": getEnvVariable('Admin') + '/api/Users/UpdatePassword',
                                        "customProps": {
                                            "custom": "UserId"
                                        }
                                    },

                                }
                            },
                            "validations": [
                                {
                                    get: {
                                        "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.users`
                                    },
                                    transformProps: {
                                        name: 'Key',
                                        subfieldname: 'MASTER.USERS_',
                                    }
                                },
                            ],
                        },
                        "components": [
                            {
                                "type": "formtextbox",
                                "key": "newpassword",
                                "options": {
                                    "row": 1,
                                    "col": 0,
                                    "width": 12,
                                    "label": "New Password",
                                    "name": "newpassword",
                                    "xtype": "password"
                                },
                            },
                            {
                                "type": "formtextbox",
                                "key": "Password",
                                "options": {
                                    "row": 2,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Confirm Password",
                                    "name": "Password",
                                    "xtype": "password",
                                    dependency: {
                                        equal: 'newpassword'
                                    },
                                },
                            },
                            {
                                "type": "infotext",
                                "key": "Infotext",
                                "options": {
                                    "row": 2,
                                    "col": 0,
                                    "width": 12,
                                    "text": "Password Policy:",

                                }
                            },
                            {
                                "type": "infotext",
                                "key": "Infotext",
                                "options": {
                                    "row": 2,
                                    "col": 0,
                                    "width": 12,
                                    "text": "1. Password must contain at least 10 characters.",

                                }
                            },
                            {
                                "type": "infotext",
                                "key": "Infotext",
                                "options": {
                                    "row": 2,
                                    "col": 0,
                                    "width": 12,
                                    "text": "2. Password should includes 1 Uppercase Alphabet[A-Z], 1 Lowercase Alphabet[a-z], 1 number[0-9], 1 Special Character.",

                                }
                            },
                            {
                                "type": "formcheckbox",
                                "key": "IsForcePasswordUpdate",
                                "options": {
                                    "row": 8,
                                    "col": 0,
                                    "width": 12,
                                    "label": "Require this user to change the password when login",
                                    "name": "IsForcePasswordUpdate",
                                    initialvalue: 'N',
                                    "transformProps": {
                                        "Y": true,
                                        "N": false,
                                        true: "Y",
                                        false: "N"
                                    }
                                }
                            },
                        ]
                    },
                    {
                        "tabkey": 2,
                        ...externaluserloginhistory
                    },

                ]
            }]
    }]
}

export default companyinternal