import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import MaskedInput from 'react-text-mask';
import DefaultSettings from './settings';



function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
            guide={false}
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,

};



export default function PhoneFormat(props) {
    const { value, handleFieldValue, extraProps, error, required, shareProps, tooltip, contexthelp, ...otherProps } = props
    const { label, name, helperText, fullWidth, disabled, ...otherConfigs } = otherProps;
    const [currentVal, setCurrentVal] = useState(value)

    useEffect(() => {
        setCurrentVal(value)
    }, [value])
    return (
        <FormControl fullWidth error={error} required={required} >
            <InputLabel style={{ fontWeight: 'bold', color: error ? 'red' : 'black' }} shrink>{label}{tooltip && <Tooltip title={tooltip ? tooltip : null}
                placement="bottom-start" ><HelpOutlineIcon fontSize="small" style={{ width: '0.8em',color: '#007bff' }} onClick={(evt) => contexthelp ? DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
            </Tooltip>}</InputLabel>
            <Input
                name={name}
                value={currentVal}
                onChange={(evt) => { setCurrentVal(evt.target.value) }}
                id="formatted-text-mask-input"
                required={required}
                inputComponent={TextMaskCustom}
                InputLabelProps={{
                    shrink: true,
                }}
                onBlur={() => {
                    handleFieldValue({ target: { value: currentVal, name: name, checked: false }, currentTarget: { dataset: null } })
                }}
                disabled={disabled || false}
            />
            {helperText && <FormHelperText >{helperText}</FormHelperText>}
        </FormControl>
    );
}
