import Typography from '@material-ui/core/Typography';
import React, { Component, Fragment } from 'react';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createRequest, requestApi as requestURL } from '../../controls/helpers/rest';
import { snackbarMessage } from '../../actions';
import Autocompletetags from '../../controls/components/custom/notificationtags';
import { getEnvVariable } from '../../../modules/environmentalList';
import ProgressBar from '../progress-bar';
import CFormCheckbox from './cformcheckbox';
import FormHeader from './cformheader';
import SubForm from './csubform';
import { requestApi } from './utilities';


class CMultiDualListBox2 extends Component {

    state = {
        firstboxselected: null,
        firstboxavailable: [],
        secondboxselected: null,
        secondboxavailable: [],
        firstboxresults: null,
        secondboxresults: null,
        currentfirstboxselecteditem: null,
        currentsecondboxselecteditem: null,
        changedFieldName: [],
        snackbarmessage: '',
        notificationToRoles: "N",
        notificationToUsers: "N",
        notificationToApplicantOwner: "N",
        notificationToPrimaryContact: "N",
        notificationToPropertyOwner: null,
        contactTypes: []
    };
    results = {};

    transformProps = {
        "Y": true,
        "N": false,
        true: "Y",
        false: "N"
    }

    componentDidMount() {
        this.getContactTypes();
        const { options, components } = this.props
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const firstboxtranformProps = firstboxoptions ? firstboxoptions.transformProps : null
        const firstboxcrud = firstboxoptions ? firstboxoptions.crud : null
        const firstboxread = firstboxcrud ? firstboxcrud.read : null
        const firstboxget = firstboxread ? firstboxread.get : null
        const firstboxrouteProps = firstboxget ? firstboxget.routeProps : null
        const id = firstboxrouteProps ? (this.props.dataItem ? this.props.dataItem[firstboxrouteProps.value] : this.props.match.params[firstboxrouteProps.value]) : null

        //Second box code
        const secondboxoptions = secondbox ? secondbox.options : null
        const secondboxtranformProps = secondboxoptions ? secondboxoptions.transformProps : null
        const secondboxboxcrud = secondboxoptions ? secondboxoptions.crud : null
        const secondboxread = secondboxboxcrud ? secondboxboxcrud.read : null
        const secondboxget = secondboxread ? secondboxread.get : null
        const secondboxrouteProps = secondboxget ? secondboxget.routeProps : null
        console.log(firstboxread, 'roles read----')
        firstboxget && requestApi(firstboxread, id).then(res => {

            const results = res.notificationRoles
            const results2 = res.notificationUsers
            let firstboxselectedarray = results[firstboxtranformProps.selected.key].map(each => {
                return each[firstboxtranformProps.selected.value]
            })
            let secondboxselectedarray = results2[secondboxtranformProps.selected.key].map(each => {
                return each[secondboxtranformProps.selected.value]
            })
            this.results = res
                this.setState({

                    firstboxavailable: results[firstboxtranformProps.available.key],
                    firstboxselected: firstboxselectedarray.join(),
                    firstboxresults: results,
                    secondboxresults: results2,
                    notificationToRoles: res.notificationToRoles,
                    notificationToUsers: res.notificationToUsers,
                    notificationToApplicantOwner: res.notificationToApplicantOwner,
                    secondboxavailable: results2[secondboxtranformProps.available.key],
                    secondboxselected: secondboxselectedarray.join(),
                    notifyToContactTypes: res.notifyToContactTypes,
                    notificationToPrimaryContact: res.notificationToPrimaryContact,
                })
        })
    }

    getContactTypes() {
        let requesturi = {
            get: {
                url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=CONTACT_TYPE`
            }
        }
        requestApi(requesturi).then(res => {
            if (res && res.length > 0) {
                let ctypes = res.map(a => ({ id: a.Value, name: a.Name, refId: a.Id, description: a.Key }))
                console.log(ctypes);
                this.setState({ contactTypes: ctypes })
            }
        })
    }

    onhandleSave = () => {
        //changed the code please verify
        const id = this.props.dataItem.ID || this.props.dataItem.Id
        const { options, components } = this.props
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const firstboxcrud = firstboxoptions ? firstboxoptions.crud : null
        const firstboxsave = firstboxcrud ? firstboxcrud.update : null
        const { firstboxresults, secondboxresults, notifyToContactTypes } = this.state

        this.results.notificationRoles = firstboxresults;
        this.results.notificationUsers = secondboxresults;
        this.results.notificationToRoles = this.state.notificationToRoles;
        this.results.notificationToUsers = this.state.notificationToUsers;
        this.results.notificationToApplicantOwner = this.state.notificationToApplicantOwner;
        this.results.notificationToPrimaryContact = this.state.notificationToPrimaryContact;
        this.results.notifyToContactTypes = notifyToContactTypes;
        this.results.parentId = id;
        let firstboxsaveurl = {
            url: firstboxsave.put.url,
            type: 'put',
        }
        requestURL(createRequest(firstboxsaveurl, this.results, (id))).then(results => {
        })
    }

    handleFieldChange = (event) => {
        console.log(event)
        const value = this.transformProps ? this.transformProps[event.target.checked] : value;
        this.setState({
            [event.target.name]: value,
        })
    }

    autcompletetagshelp = {
        "options": {
            "label": "Select contact type(s)",
            "placeholder": "",
            "row": 0,
            "col": 0,
            "width": 12,
            "name": "UsersList",
            "itemname": "userId",
            "defaultSelected": true
        }
    }

    autcompletetagshelproles = {
        "options": {
            "label": "Select role(s)",
            "placeholder": "",
            "row": 0,
            "col": 0,
            "width": 12,
            "name": "RolesList",
            "itemname": "roleId",
            disabled: false,
            conditions: {
                hideonnull: { 'roleId': null }
            },
            "defaultSelected": true
        }
    }
    autcompletetagshelpusers = {
        "options": {
            "label": "Select User(s)",
            "placeholder": "",
            "row": 0,
            "col": 0,
            "width": 12,
            "name": "NamedUsersList",
            "itemname": "NameduserId",
            disabled: false,
            conditions: {
                hideonnull: { 'NameduserId': null }
            },
            "defaultSelected": true
        }
    }

    autcomcompletetagschange = (tags) => {
        console.log(tags)
        let resultdata = tags.target.value.map(a => a.id);
        this.setState({
            notifyToContactTypes: [...new Set(resultdata)].join(),
            notifyToContactTypesValue: tags.target.value
        })
    }
    autcomcompletetagschangeroles = (tags) => {
        console.log(tags)
        let resultdata = tags.target.value.map(a => a.id);
        this.setState({
            firstboxselected: [...new Set(resultdata)].join(),
            firstboxresults: { ...this.state.firstboxresults, ['selectedCollection']: tags.target.value }
        })
    }
    autcomcompletetagschangeusers = (tags) => {
        console.log(tags)
        let resultdata = tags.target.value.map(a => a.id);
        this.setState({
            secondboxselected: [...new Set(resultdata)].join(),
            secondboxresults: { ...this.state.secondboxresults, ['selectedCollection']: tags.target.value }
        })
    }
    render() {
        const { firstboxselected, firstboxavailable, secondboxselected, secondboxavailable, notifyToContactTypes, notifyToContactTypesValue, contactTypes, notifyTorolesValue } = this.state
        const { options, components, compmap } = this.props
        const { multiboxes, save, title } = options ? options : {}
        const [firstbox, secondbox] = components
        const firstboxoptions = firstbox ? firstbox.options : null
        const secondboxoptions = secondbox ? secondbox.options : null
        const firstboxprops = {}, secondboxprops = {}
        if (multiboxes) {
            if (firstboxoptions) {
                firstboxprops.width = firstboxoptions.width || 6
                firstboxprops.helperText = firstboxoptions.helperText || ''
                firstboxprops.name = firstboxoptions.name || 'firstbox'
            }
            if (secondboxoptions) {
                secondboxprops.width = secondboxoptions.width || 6
                secondboxprops.helperText = secondboxoptions.helperText || ''
                secondboxprops.name = secondboxoptions.name || 'secondbox'
            }
        }
        const { headerProps } = options
        const { savebutton, closebutton } = headerProps ? headerProps : {}

        return (
            <Fragment>
                {headerProps && <FormHeader title={title} handleSave={this.onhandleSave} designprops={true} savebutton={savebutton} handleClose={this.props.onClose} closebutton={closebutton} />}
                <div> <ProgressBar ref={e => this.progressBar = e} /></div>
                <div className="row mt-3">
                    {firstbox && <div className={"col-sm-12"}>
                        <CFormCheckbox value={this.state.notificationToRoles} handleFieldChange={this.handleFieldChange} name="notificationToRoles" transformProps={this.transformProps} label="Send notification to users belonging to selected roles" />
                        <div className="mt-2">
                            <Autocompletetags
                                key="Roles"
                                label="Role Users"
                                name="notificationRoles"
                                dropdownvalues={firstboxavailable}
                                {...this.autcompletetagshelproles}
                                handleFieldChange={this.autcomcompletetagschangeroles}
                                defaultSelValue={firstboxselected}
                            />
                        </div>
                        <Typography>
                            {firstboxprops.helperText}
                        </Typography>

                        {firstbox.components && <SubForm {...firstbox} compmap={compmap} changedFieldName={this.state.changedFieldName} handleFieldChange={this.handleFirstBoxFieldChange} results={this.state.currentfirstboxselecteditem} />}
                    </div>}
                    {secondbox && <div className={"col-sm-12"}>
                        <CFormCheckbox value={this.state.notificationToUsers} handleFieldChange={this.handleFieldChange} name="notificationToUsers" transformProps={this.transformProps} label="Send notification to named users" />
                        <Autocompletetags
                            key="Users"
                            label="Role Users"
                            name="notificationUsers"
                            dropdownvalues={secondboxavailable}
                            {...this.autcompletetagshelpusers}
                            handleFieldChange={this.autcomcompletetagschangeusers}
                            defaultSelValue={secondboxselected}
                        />

                        <Typography>
                            {secondboxprops.helperText}
                        </Typography>
                        {secondbox.components && <SubForm {...secondbox} compmap={compmap} changedFieldName={this.state.changedFieldName} handleFieldChange={this.handleSecondBoxFieldChange} />}
                    </div>}
                    <div className="col-12">
                        <div className="mt-2">
                            <Typography paragraph variant="caption">
                                Notification will be sent to users based upon region access and role membership. Workflow notifications are always send to
                                the assigned user in addition to the recipients selected on this screen. Users will not receive multiples of the same notification.
                                Application, notices are only sent to users that belong to the region of the application.
                                Only users that belong to the regions selected are listed in named users. They receive the notification irrespective of belonging to the roles selected as long as they have region access.
                            </Typography>
                        </div>
                        {this.props.notificationtype != 'SCHEDULED_NOTIFICATION' && <div className="mt-2">
                            <CFormCheckbox value={this.state.notificationToApplicantOwner} handleFieldChange={this.handleFieldChange} name="notificationToApplicantOwner"
                                transformProps={this.transformProps} label="Send notification to application submitter" />
                            <CFormCheckbox value={this.state.notificationToPrimaryContact} handleFieldChange={this.handleFieldChange} name="notificationToPrimaryContact"
                                transformProps={this.transformProps} label="Send notification to application primary contact" />
                            { getEnvVariable("ModuleKey") != "WRS" &&
                                <div className="mt-2">
                                <Autocompletetags
                                    key="contacts"
                                    label="Contacts"
                                    name="notifyToContactTypes"
                                    dropdownvalues={contactTypes}
                                    {...this.autcompletetagshelp}
                                    handleFieldChange={this.autcomcompletetagschange}
                                    defaultSelValue={notifyToContactTypes}
                                />
                            </div>
                            }
                            <Typography paragraph variant="caption">
                                If sending notification to applicant primary contact,
                                please ensure that the corresponding notification template has been created.
                            </Typography>
                        </div>}

                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapActions = dispatch => {
    return bindActionCreators({ snackbarMessage }, dispatch);
}

CMultiDualListBox2 = connect(null, mapActions)(CMultiDualListBox2)
export default withRouter(CMultiDualListBox2)


