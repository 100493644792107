import React from 'react';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

export default function Unauthorize() {
    const user = JSON.parse(localStorage.getItem('profile')) || null
    localStorage.clear();


    return (
        <div className="eps-content-center " >
            <div className="text-center eps-img-unauthorize">
                <Icon className="text-center " style={{ fontSize: '10rem', color: 'red' }}>hourglass_bottom</Icon></div>
            <h1 className="text-center"> Your Session has Expired </h1>
            {user && user.name && <h5 className="text-secondary text-center">{`Please login into your ${user && user.name}`} </h5>}
            <div className="text-center p-2">
                <Button variant="contained" size="small" color="primary" href='#' target="_self" >Login</Button>
            </div>
        </div>

    );
}
