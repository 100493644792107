import { getEnvVariable } from "../../../../../modules/environmentalList"

const BusiessRulesEdit = {
    "type": "popup",
    "key": "BusiessRulesEdit",
    "name": "BusiessRulesEdit",
    anchor: 'right',
    open: false,
    aliases: {
        open: 'editOpen'
    },
    sharedValues: ['open'],
    container: {
        //"addRoute": true,
        closepopup: true,
        closepopupval: 'settings',
        values: { editInfo: null, edit: false, createduplicate: false, fullscreen: false, back: false, allgriddata: null, navigation: null },
        mapValues: {
            header: [
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'createduplicate', name: 'createduplicate' },
            ],
            EditBusiessRules: [
                { key: 'editInfo', name: 'editInfo' },
                { key: 'allgriddata', name: 'allgriddata' },
                { key: 'save', name: 'save' },
                { key: 'saveduplicate', name: 'saveduplicate' },
                { key: 'edit', name: 'edit' },
                { key: 'exitfullscreen', name: 'exitfullscreen' },
                { key: 'fullscreen', name: 'fullscreen' },
                { key: 'createduplicate', name: 'createduplicate' },
                { key: 'back', name: 'back' },
                { key: 'navigation', name: 'navigation' },]
        },
        mapActionsToEffects: {},
        layout: {
            group: [{
                order: 0,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'header',
                    type: 'div',
                    className: 'col-12 ',
                    sub: {
                        type: 'div',
                        className: 'col-12',
                    }
                }],

            },


            {
                order: 1,
                type: 'div',
                className: 'row',
                components: [{
                    name: 'EditBusiessRules',
                    type: 'div',
                    className: 'col-12 pl-4 pt-3 m-4',
                    sub: {
                        type: 'div',
                        className: 'col-12 pl-4 pt-3 m-4',
                    }
                }],

            },
            ]
        },

        components: {
            header: {
                name: "header",
                type: 'header',
                title: 'Settings',
                components: [
                    {
                        type: 'breadcrumbs',
                        items: [
                            { "link": "View/Edit Business Rules Message", "materialicon": "settings", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        ]
                    }
                ],
                layout: {},
                actions: [
                    {
                        type: 'icon',
                        icon: 'back',
                        name: 'back',
                        hideon: 'back',
                        hidevalue: false,
                        title: 'Back'
                    },

                    {
                        type: 'icon',
                        icon: 'edit',
                        name: 'edit',
                        hideon: 'createduplicate',
                        hideanother: 'edit',
                        hidevalue: true,
                        title: 'Edit Business Rules Message'
                    },
                    // {
                    //     type: 'icon',
                    //     icon: 'duplicate',
                    //     name: 'createduplicate',
                    //     hideon: 'edit',
                    //     hideanother: 'createduplicate',
                    //     hidevalue: true,
                    //     title: 'Clone Business Rules Message'
                    // },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'save',
                        hideon: 'edit',
                        hidevalue: false,
                        title: 'Save Business Rules Message'
                    },
                    {
                        type: 'icon',
                        icon: 'save',
                        name: 'saveduplicate',
                        hideon: 'createduplicate',
                        hidevalue: false,
                        title: 'Save Business Rules Message'
                    },
                    {
                        type: 'icon',
                        icon: 'previtem',
                        name: 'previtem',
                        hideon: 'previtem',
                        hidevalue: false,
                        title: 'Previous Item'
                    },
                    {
                        type: 'icon',
                        icon: 'nextitem',
                        name: 'nextitem',
                        hideon: 'nextitem',
                        hidevalue: false,
                        title: 'Next Item'
                    },
                    {
                        type: 'icon',
                        icon: 'fullscreen',
                        name: 'fullscreen',
                        hidevalue: false,
                        title: 'FullScreen'
                    },
                    {
                        type: 'icon',
                        icon: 'exitfullscreen',
                        name: 'exitfullscreen',
                        hideon: 'fullscreen',
                        hidevalue: false,
                        title: 'Exit FullScreen'
                    }
                ],


            },
            EditBusiessRules: {
                "type": "form",
                "key": "EditBusiessRules",
                "name": "EditBusiessRules",
                noParams: true,
                aliases: {
                    'editInfo': 'editInfo',
                    'allgriddata': 'allgriddata',  //
                    'navigation': 'navigation'
                },
                sharedValues: ['editInfo', 'allgriddata', 'navigation', 'edit', 'createduplicate', 'exitfullscreen', 'fullscreen'],
                layout: {
                    group: [
                        {
                            order: 0,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Type',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8'
                                }
                            },
                            ]
                        },
                        {
                            order: 1,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'Message',
                                type: 'div',
                                className: 'col-md-10 col-lg-8',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-10 col-lg-8',
                                }
                            },
                            ]
                        },
                        {
                            order: 2,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'TableName',
                                type: 'div',
                                className: 'col-md-6',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-6',
                                }
                            },
                            {
                                name: 'Status',
                                type: 'div',
                                className: 'col-md-6',
                                sub: {
                                    type: 'div',
                                    className: 'col-md-6',
                                }
                            },
                            ]
                        },
                        {
                            order: 3,
                            type: 'div',
                            className: 'row',
                            components: [{
                                name: 'CreatedOn',
                                type: 'div',
                                className: 'col-6',
                                sub: {
                                    type: 'div',
                                    className: 'col-6'
                                }
                            },
                            {
                                name: 'CreatedByName',
                                type: 'div',
                                className: 'col-6',
                                sub: {
                                    type: 'div',
                                    className: 'col-6'
                                }
                            },
                            ]
                        },
                        {
                            order: 4,
                            type: 'div',
                            className: 'row',
                            components: [
                                {
                                    name: 'UpdatedOn',
                                    type: 'div',
                                    className: 'col-6',
                                    sub: {
                                        type: 'div',
                                        className: 'col-6'
                                    }
                                },
                                {
                                    name: 'UpdatedByName',
                                    type: 'div',
                                    className: 'col-6',
                                    sub: {
                                        type: 'div',
                                        className: 'col-6'
                                    }
                                },]
                        },
                    ],
                },
                values: {
                    Type: '',
                    Message: '',
                    Key: getEnvVariable('ModuleKey')+'Module.BUSINESS_RULES_ALERTS',
                    TableName: getEnvVariable('ModuleKey')+'Module.BUSINESS_RULES_ALERTS',
                    Status: '',
                    UpdatedByName: '',
                    CreatedByName: '',
                    UpdatedOn: '',
                    CreatedOn: '',
                    UpdatedBy: '',
                    CreatedBy: '',
                    Id: '',
                    editInfo: null,
                    allgriddata: null,
                    navigation: null,

                },

                "components": {
                    Key: {
                        "type": "label",
                        "key": "Key",
                        "label": "Key",
                        "name": "Key",
                        changefieldto: "label",
                    },
                    Type: {
                        "type": "label",
                        "key": "Type",
                        "label": "Type",
                        "name": "Type",
                        changefieldto: "dropdown",
                        extraProps: {
                            transformProps: {
                                label: 'Name',
                                value: 'Value'
                            },
                        }
                    },
                    Message: {
                        "type": "label",
                        "key": "Message",
                        "label": "Name",
                        "name": "Message",
                        changefieldto: "textfield",
                    },
                    TableName: {
                        "type": "label",
                        "key": "TableName",
                        "label": "TableName",
                        "name": "TableName",
                        changefieldto: "label",
                    },
                    Status: {
                        "type": "label",
                        "key": "Status",
                        "label": "Status",
                        "name": "Status",
                        changefieldto: "toggle",
                        extraProps: {
                            "transformProps": {
                                "A": true,
                                "I": false,
                                "true": "A",
                                "false": "I"
                            }
                        },
                        LabeltransformProps: {
                            I: 'In-Active',
                            A: 'Active',
                            true: 'Active',
                           false: 'In-Active',
                        },
                    },
                    CreatedOn: {
                        "type": "label",
                        "key": "CreatedOn",
                        "label": "Created On",
                        name: "CreatedOn",
                        changefieldto: "label",
                    },
                    CreatedByName: {
                        "type": "label",
                        "key": "CreatedByName",
                        "label": "Created By",
                        name: "CreatedByName",
                        changefieldto: "label",
                    },
                    UpdatedOn: {
                        "type": "label",
                        "key": "UpdatedOn",
                        "label": "Updated On",
                        name: "UpdatedOn",
                        changefieldto: "label",
                    },
                    UpdatedByName: {
                        "type": "label",
                        "key": "UpdatedByName",
                        "label": "Updated By",
                        name: "UpdatedByName",
                        changefieldto: "label",
                    },
                },
                mapActionsToEffects: {
                    'onLoad': {
                        'init': {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'load',
                                            name: 'Type',
                                            "read": [
                                                {
                                                    url: getEnvVariable('Admin') + `/api/Domains/values?domainkey=PROJECT_MESSAGE_TYPE`,
                                                    type: 'get',
                                                    saveAs: {
                                                        key: 'items'
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            type: 'validations',
                                            "read": [
                                                {
                                                    "url": getEnvVariable('MasterAdmin') + `/api/ProjectMessages/RulesByName?name=master.projectmessages`,
                                                    type: 'get'
                                                }
                                            ],
                                            transformProps: {
                                                removesubfieldname: 'MASTER.PROJECTMESSAGES_'
                                            }
                                        },
                                        { type: 'prop', name: 'TableName', isVisible: false },
                                    ]
                                }
                            }]
                        }
                    },
                    'onChange': {
                        'exitfullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'exitfullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'fullscreen': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'fullscreen',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'createduplicate': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'createduplicate',

                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'navigation': {
                            rules: [{
                                run:
                                {
                                    valid: [
                                        {
                                            type: 'initial',
                                            "read": [
                                                {
                                                    url: getEnvVariable('Admin')+`/api/ProjectMessages/Id?id={name}`,
                                                    key: 'save',
                                                    "matchProps": [
                                                        {
                                                            name: "routeid1",
                                                            key: "routeid1",
                                                        },
                                                        {
                                                            name: "navigation.Id",
                                                            key: "name",
                                                        }
                                                    ],
                                                    type: 'get',
                                                }
                                            ]
                                        },
                                    ]
                                }
                            }]
                        },
                        'edit': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'edit',

                                            },

                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    'afterSave': {
                        'saveduplicate': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Admin') + `/api/ProjectMessages/Add/{name}`,
                                                        type: 'post',
                                                        key: 'save',
                                                        "matchProps": [
                                                            {
                                                                name: "TableName",
                                                                key: "name",
                                                            }
                                                        ],
                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                        'save': {
                            rules: [
                                {
                                    run: {
                                        valid: [
                                            {
                                                type: 'save',
                                                "read": [
                                                    {
                                                        "url": getEnvVariable('Admin') + `/api/ProjectMessages/Update`,
                                                        type: 'put',
                                                        key: 'save',
                                                    }
                                                ],
                                            },

                                        ]
                                    }
                                }
                            ]
                        },
                    }
                }
            }
        },

    }
}

export default BusiessRulesEdit