import { getEnvVariable } from '../../../modules/environmentalList';
import newsavedreportstypes from '../../reports/savedreports';
const noticehistorygrid = {
    "type": "grid",
    "key": "noticeshistorygrid",
    "gridkey": 'noticeshistorygrid',
    "order": 1,
    "options": {
        sidepopupgridheight:true,
        "stopRowClick":true,
        "routes": [
            {
                "route": "/Administration/applicationreview",
                "routeParams": ":routeid",
            },
            {
                "route": "",
                "routeParams": ":routeid1"
            },
            {
                "route": "/notices",
                "routeParams": ":routeid2"
            },
            {
                "route": "/history"
            }
        ],
        "crud": {
            "update": {
              
            },
            "read": {
                "get": {
                    "url": getEnvVariable('Sidebar')+`/api/Notices/history/{Id}?`,
                    "customProps": [{
                        "custom": "Id"
                    }]
                }
            }
        },
        "back": {
            "routes": [
                {
                    "route": "/Administration/applicationreview",
                    "routeParams": "routeid",
                },
                {
                    "route": "",
                    "routeParams": "routeid1"
                },
                {
                    "route": "/notices/view",
                    "routeParams": "routeid2"
                },
            ],
        },
        "headerProps": {
            "title": "Notices History",
            designprops:true,             
            "breadcrumbs": [
                { "link": "Notices History", "materialicon": "history", className: 'breadcrumb-item text-dark', class: 'text-dark' }
            ]
        },
        savedreports: {
            ...newsavedreportstypes
        },
        "gridcolumns": [
            {
                "title": "Field Name",
                "field": "FieldLabel",
                "show": true,
                width:185
            },
            {
                "title": "Old Value",
                "field": "OldValue",
                "show": true,
                width:185
            },
            {
                "title": "New Value",
                "field": "NewValue",
                "show": true,
                width:185
            },
            {
                "title": "Updated By",
                "field": "CreatedByName",
                "show": true,
                width:185
            },            
            {
                "title": "Updated On",
                "field": "CreatedOn",
                "show": true,
                width:185
            },
        ]
    }
}
export default noticehistorygrid