/**
      *********************************************************
      Description: Textbox component. .
      Required props:  type,value.
      limitations    : N/A.
      *********************************************************
**/

import React, { Fragment, useState, useEffect } from "react";
import OtpInput from 'react-otp-input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { Tooltip, Typography } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DefaultSettings from './settings';

export default function (props) {
    const { value, handleFieldValue, extraProps, disabled, error, required, fullWidth, tooltip, max, contexthelp,  length, ...otherProps } = props
    const { label, helperText, name } = otherProps;

    return (
        <FormControl className={name} fullWidth={fullWidth ? fullWidth : true} error={error} required={required}>
            <InputLabel style={{ fontWeight: 'bold', color: error ? 'red' : 'black' }} shrink>  {label}&nbsp; {tooltip && <Tooltip title={tooltip ? tooltip : null}
                placement="bottom-start" ><HelpOutlineIcon fontSize="small" style={{ width: '0.8em',color: '#007bff' }} onClick={(evt) => contexthelp ?
                    DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
            </Tooltip>} </InputLabel>&nbsp;
            <OtpInput
                className="text-secondary"
                style={{ color: 'grey' }}
                value={value}
                onChange={(otp) => { handleFieldValue({ target: { value: otp, name: name, checked: false }, currentTarget: { dataset: null } }) }}
                numInputs={max || 1}
                separator={<p>&nbsp;</p>}
                inputStyle={{ width: '2em', height: '2em' }}
                isDisabled={disabled}
                hasErrored={error}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}