import { getEnvVariable } from "../../modules/environmentalList"

export const amesmap = {
   "type":"map",
   "key":"EPS_map_ADD",
   "order":32,
   "width":"160vh",
   "options":{
      "mapkey":"EPS_map_ADD",
      "routes":{
         "route":"/Administration/map"
      },
      "widgets":[
         "Home",
         "Search",
         "Zoom",
         "Sketch",
         "Select",
         "BasemapGallery",
         "LayerList",
         "Measurement",
         "TaxlotSelector",
         "BufferCalc",
         "Print"
         
       //  "SearchBookmark"
      ],
      "basemap":"streets",
      "viewProperties":{
         // "extent":{
         //    "xmax":-8057114.558988379,
         //    "xmin":-8679311.969229413,
         //    "ymax":4872179.908902848,
         //    "ymin":4605567.554244282,
         //    "spatialReference":102100
         // },
         "zoom":15,
         "initialzoom":12,
         "center":[
            -8368213.264108896,
            4738873.731573565
         ],
         "ui":{
            "components":[
               "attribution"
            ]
         }
      },
      "printOptions":{
         "serviceURL":"http://development.delasoft.com:8443/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task"
      },
      "searchOptions":{
         "State":"OR"
      },
      "zoomOptions":{
         "maxZoomed":false,
         "minZoomed":false
      },
      "drawOptions":{
         "Tools":[

         ],
         "AllowMultiple":{"Points":false, "Lines":false,"Polygons":false},
         "LayerIndex":1
      },
      'searchbookmarks':{
            "drawTool":' Hello I ma me',
            "url":getEnvVariable('ArcGIS')+`/rest/services/AWS_KEPT_Test/Bookmarks/FeatureServer/0`,
            "defaultChoice":'E',
            "defaultName":'Bookmark #',
            "defaultSymbol":{
               type: "simple-fill",
               outline: {
                  style: "dash",
                  miterLimit: 16,
                  color: [0, 112, 255, 1]
               },
               color: [115, 223, 255, 0.24]
            },
            "defaultMessage":'Draw Box on Map for Bookmark',
            'appname':'INQUERY',
            'username':'13384'
      },
      "tasks":{
         "TaxParcels":{
            "url":"", 
            "queryExpression":"",
            "LayerIndex":0
         }
      },
      "LayerOptionsConfig":
      {
            "Category": ["F"],
            "MapType": ["A","R"]   //A-Add Record,L-Landing,G-Grid,R-Record review
      },
      "LayerOptions":[
         {
            "ExtentZoom": true,//CenterAndZoom after load
            "title": "Tax Parcel Feature Layer",
            "type": "F",//F-FeatureLayer,G-GraphicsLayer
            "url": "", //https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/29
            "outFields": ["*"],
            "LayerColor" : { type: "simple-fill", color: [ 255, 204, 153, 0.9 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "HighlightColor" : { type: "simple-fill", color: [ 0, 255, 255, 0.1 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "InfoTemplate" :[{type: "fields", fieldInfos: [{ fieldName: "County", label: "County" }, { label: "MapNumber" }, { fieldName: "Taxlot", label: "Taxlot" },{ fieldName: "MapTaxlot", label: "MapTaxlot" }] }],
            "Key": "TaxLot"
        },
      ],
      "FeatureLayerListOptions":[],
      "CountyLayerOptions":[
         {
            "ExtentZoom": true,//CenterAndZoom after load
            "title": "Baker County Taxlots",
            "type": "F",//F-FeatureLayer,G-GraphicsLayer
            "url": "https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/28",
            "outFields": ["*"],
            "LayerColor" : { type: "simple-fill", color: [ 255, 204, 153, 0.9 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "HighlightColor" : { type: "simple-fill", color: [ 0, 255, 255, 0.1 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "InfoTemplate" :[{type: "fields", fieldInfos: [{ fieldName: "County", label: "County" }, { label: "MapNumber" }, { fieldName: "Taxlot", label: "Taxlot" },{ fieldName: "MapTaxlot", label: "MapTaxlot" }] }],
            "Key": "TaxLot",
            "id":"0",
            "GridTemplate": [{"field":"OBJECTID","label":"OBJECTID","sortable":true,"hidden":true},
                              {"field":"Taxlot","label":"Taxlot","sortable":true},
                              {"field":"MapTaxlot","label":"MapTaxlot","sortable":true, colSpan: 2 },
                              {"field":"MapNumber","label":"MapNumber","sortable":true},
                              {"field":"County","label":"County","sortable":true}],
            "EditorTemplate" : []
        },
        {
            "ExtentZoom": true,//CenterAndZoom after load
            "title": "Benton County Taxlots",
            "type": "F",//F-FeatureLayer,G-GraphicsLayer
            "url": "https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/29",
            "outFields": ["*"],
            "LayerColor" : { type: "simple-fill", color: [ 255, 204, 153, 0.9 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "HighlightColor" : { type: "simple-fill", color: [ 0, 255, 255, 0.1 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "InfoTemplate" :[{type: "fields", fieldInfos: [{ fieldName: "County", label: "County" }, { label: "MapNumber" }, { fieldName: "Taxlot", label: "Taxlot" },{ fieldName: "MapTaxlot", label: "MapTaxlot" }] }],
            "Key": "TaxLot",
            "id":"1",
            "GridTemplate": [{"field":"OBJECTID","label":"OBJECTID","sortable":true,"hidden":true},
                              {"field":"Taxlot","label":"Taxlot","sortable":true},
                              {"field":"MapTaxlot","label":"MapTaxlot","sortable":true, colSpan: 2 },
                              {"field":"MapNumber","label":"MapNumber","sortable":true},
                              {"field":"County","label":"County","sortable":true}],
            "EditorTemplate" : []
        },
        {
            "ExtentZoom": true,//CenterAndZoom after load
            "title": "Clackamas County Taxlots ",
            "type": "F",//F-FeatureLayer,G-GraphicsLayer
            "url": "https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/30",
            "outFields": ["*"],
            "LayerColor" : { type: "simple-fill", color: [ 255, 204, 153, 0.9 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "HighlightColor" : { type: "simple-fill", color: [ 0, 255, 255, 0.1 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "InfoTemplate" :[{type: "fields", fieldInfos: [{ fieldName: "County", label: "County" }, { label: "MapNumber" }, { fieldName: "Taxlot", label: "Taxlot" },{ fieldName: "MapTaxlot", label: "MapTaxlot" }] }],
            "Key": "TaxLot",
            "id":"2",
            "GridTemplate": [{"field":"OBJECTID","label":"OBJECTID","sortable":true,"hidden":true},
                              {"field":"Taxlot","label":"Taxlot","sortable":true},
                              {"field":"MapTaxlot","label":"MapTaxlot","sortable":true, colSpan: 2 },
                              {"field":"MapNumber","label":"MapNumber","sortable":true},
                              {"field":"County","label":"County","sortable":true}],
            "EditorTemplate" : []
        },
        {
            "ExtentZoom": true,//CenterAndZoom after load
            "title": "Curry County Taxlots ",
            "type": "F",//F-FeatureLayer,G-GraphicsLayer
            "url": "https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/31",
            "outFields": ["*"],
            "LayerColor" : { type: "simple-fill", color: [ 255, 204, 153, 0.9 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "HighlightColor" : { type: "simple-fill", color: [ 0, 255, 255, 0.1 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "InfoTemplate" :[{type: "fields", fieldInfos: [{ fieldName: "County", label: "County" }, { label: "MapNumber" }, { fieldName: "Taxlot", label: "Taxlot" },{ fieldName: "MapTaxlot", label: "MapTaxlot" }] }],
            "Key": "TaxLot",
            "id":"3",
            "GridTemplate": [{"field":"OBJECTID","label":"OBJECTID","sortable":true,"hidden":true},
                              {"field":"Taxlot","label":"Taxlot","sortable":true},
                              {"field":"MapTaxlot","label":"MapTaxlot","sortable":true, colSpan: 2 },
                              {"field":"MapNumber","label":"MapNumber","sortable":true},
                              {"field":"County","label":"County","sortable":true}],
            "EditorTemplate" : []
        },
        {
            "ExtentZoom": true,//CenterAndZoom after load
            "title": "Grant County Taxlots ",
            "type": "F",//F-FeatureLayer,G-GraphicsLayer
            "url": "https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/32",
            "outFields": ["*"],
            "LayerColor" : { type: "simple-fill", color: [ 255, 204, 153, 0.9 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "HighlightColor" : { type: "simple-fill", color: [ 0, 255, 255, 0.1 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "InfoTemplate" :[{type: "fields", fieldInfos: [{ fieldName: "County", label: "County" }, { label: "MapNumber" }, { fieldName: "Taxlot", label: "Taxlot" },{ fieldName: "MapTaxlot", label: "MapTaxlot" }] }],
            "Key": "TaxLot",
            "id":"4",
            "GridTemplate": [{"field":"OBJECTID","label":"OBJECTID","sortable":true,"hidden":true},
                              {"field":"Taxlot","label":"Taxlot","sortable":true},
                              {"field":"MapTaxlot","label":"MapTaxlot","sortable":true, colSpan: 2 },
                              {"field":"MapNumber","label":"MapNumber","sortable":true},
                              {"field":"County","label":"County","sortable":true}],
            "EditorTemplate" : []
        },
        {
            "ExtentZoom": true,//CenterAndZoom after load
            "title": "Harney County Taxlots",
            "type": "F",//F-FeatureLayer,G-GraphicsLayer
            "url": "https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/33",
            "outFields": ["*"],
            "LayerColor" : { type: "simple-fill", color: [ 255, 204, 153, 0.9 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "HighlightColor" : { type: "simple-fill", color: [ 0, 255, 255, 0.1 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "InfoTemplate" :[{type: "fields", fieldInfos: [{ fieldName: "County", label: "County" }, { label: "MapNumber" }, { fieldName: "Taxlot", label: "Taxlot" },{ fieldName: "MapTaxlot", label: "MapTaxlot" }] }],
            "Key": "TaxLot",
            "id":"5",
            "GridTemplate": [{"field":"OBJECTID","label":"OBJECTID","sortable":true,"hidden":true},
                              {"field":"Taxlot","label":"Taxlot","sortable":true},
                              {"field":"MapTaxlot","label":"MapTaxlot","sortable":true, colSpan: 2 },
                              {"field":"MapNumber","label":"MapNumber","sortable":true},
                              {"field":"County","label":"County","sortable":true}],
            "EditorTemplate" : []
        },
        {
            "ExtentZoom": true,//CenterAndZoom after load
            "title": "Jackson County Taxlots ",
            "type": "F",//F-FeatureLayer,G-GraphicsLayer
            "url": "https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/34",
            "outFields": ["*"],
            "LayerColor" : { type: "simple-fill", color: [ 255, 204, 153, 0.9 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "HighlightColor" : { type: "simple-fill", color: [ 0, 255, 255, 0.1 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "InfoTemplate" :[{type: "fields", fieldInfos: [{ fieldName: "County", label: "County" }, { label: "MapNumber" }, { fieldName: "Taxlot", label: "Taxlot" },{ fieldName: "MapTaxlot", label: "MapTaxlot" }] }],
            "Key": "TaxLot",
            "id":"6",
            "GridTemplate": [{"field":"OBJECTID","label":"OBJECTID","sortable":true,"hidden":true},
                              {"field":"Taxlot","label":"Taxlot","sortable":true},
                              {"field":"MapTaxlot","label":"MapTaxlot","sortable":true, colSpan: 2 },
                              {"field":"MapNumber","label":"MapNumber","sortable":true},
                              {"field":"County","label":"County","sortable":true}],
            "EditorTemplate" : []
        },
        {
            "ExtentZoom": true,//CenterAndZoom after load
            "title": "Jefferson County Taxlots ",
            "type": "F",//F-FeatureLayer,G-GraphicsLayer
            "url": "https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/35",
            "outFields": ["*"],
            "LayerColor" : { type: "simple-fill", color: [ 255, 204, 153, 0.9 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "HighlightColor" : { type: "simple-fill", color: [ 0, 255, 255, 0.1 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "InfoTemplate" :[{type: "fields", fieldInfos: [{ fieldName: "County", label: "County" }, { label: "MapNumber" }, { fieldName: "Taxlot", label: "Taxlot" },{ fieldName: "MapTaxlot", label: "MapTaxlot" }] }],
            "Key": "TaxLot",
            "id":"7",
            "GridTemplate": [{"field":"OBJECTID","label":"OBJECTID","sortable":true,"hidden":true},
                              {"field":"Taxlot","label":"Taxlot","sortable":true},
                              {"field":"MapTaxlot","label":"MapTaxlot","sortable":true, colSpan: 2 },
                              {"field":"MapNumber","label":"MapNumber","sortable":true},
                              {"field":"County","label":"County","sortable":true}],
            "EditorTemplate" : []
        },
        {
            "ExtentZoom": true,//CenterAndZoom after load
            "title": "Klamath Tax Parcels",
            "type": "F",//F-FeatureLayer,G-GraphicsLayer
            "url": "https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/36",
            "outFields": ["*"],
            "LayerColor" : { type: "simple-fill", color: [ 255, 204, 153, 0.9 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "HighlightColor" : { type: "simple-fill", color: [ 0, 255, 255, 0.1 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "InfoTemplate" :[{type: "fields", fieldInfos: [{ fieldName: "County", label: "County" }, { label: "MapNumber" }, { fieldName: "Taxlot", label: "Taxlot" },{ fieldName: "MapTaxlot", label: "MapTaxlot" }] }],
            "Key": "TaxLot",
            "id":"8",
            "GridTemplate": [{"field":"OBJECTID","label":"OBJECTID","sortable":true,"hidden":true},
                              {"field":"Taxlot","label":"Taxlot","sortable":true},
                              {"field":"MapTaxlot","label":"MapTaxlot","sortable":true, colSpan: 2 },
                              {"field":"MapNumber","label":"MapNumber","sortable":true},
                              {"field":"County","label":"County","sortable":true}],
            "EditorTemplate" : []
        },
        {
            "ExtentZoom": true,//CenterAndZoom after load
            "title": "Lake County Taxlots ",
            "type": "F",//F-FeatureLayer,G-GraphicsLayer
            "url": "https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/37",
            "outFields": ["*"],
            "LayerColor" : { type: "simple-fill", color: [ 255, 204, 153, 0.9 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "HighlightColor" : { type: "simple-fill", color: [ 0, 255, 255, 0.1 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "InfoTemplate" :[{type: "fields", fieldInfos: [{ fieldName: "County", label: "County" }, { label: "MapNumber" }, { fieldName: "Taxlot", label: "Taxlot" },{ fieldName: "MapTaxlot", label: "MapTaxlot" }] }],
            "Key": "TaxLot",
            "id":"9",
            "GridTemplate": [{"field":"OBJECTID","label":"OBJECTID","sortable":true,"hidden":true},
                              {"field":"Taxlot","label":"Taxlot","sortable":true},
                              {"field":"MapTaxlot","label":"MapTaxlot","sortable":true, colSpan: 2 },
                              {"field":"MapNumber","label":"MapNumber","sortable":true},
                              {"field":"County","label":"County","sortable":true}],
            "EditorTemplate" : []
        },
        {
            "ExtentZoom": true,//CenterAndZoom after load
            "title": "Lincoln County Taxlots ",
            "type": "F",//F-FeatureLayer,G-GraphicsLayer
            "url": "https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/38",
            "outFields": ["*"],
            "LayerColor" : { type: "simple-fill", color: [ 255, 204, 153, 0.9 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "HighlightColor" : { type: "simple-fill", color: [ 0, 255, 255, 0.1 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "InfoTemplate" :[{type: "fields", fieldInfos: [{ fieldName: "County", label: "County" }, { label: "MapNumber" }, { fieldName: "Taxlot", label: "Taxlot" },{ fieldName: "MapTaxlot", label: "MapTaxlot" }] }],
            "Key": "TaxLot",
            "id":"10",
            "GridTemplate": [{"field":"OBJECTID","label":"OBJECTID","sortable":true,"hidden":true},
                              {"field":"Taxlot","label":"Taxlot","sortable":true},
                              {"field":"MapTaxlot","label":"MapTaxlot","sortable":true, colSpan: 2 },
                              {"field":"MapNumber","label":"MapNumber","sortable":true},
                              {"field":"County","label":"County","sortable":true}],
            "EditorTemplate" : []
        },
        {
            "ExtentZoom": true,//CenterAndZoom after load
            "title": "Tillamook County Taxlots ",
            "type": "F",//F-FeatureLayer,G-GraphicsLayer
            "url": "https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/39",
            "outFields": ["*"],
            "LayerColor" : { type: "simple-fill", color: [ 255, 204, 153, 0.9 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "HighlightColor" : { type: "simple-fill", color: [ 0, 255, 255, 0.1 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "InfoTemplate" :[{type: "fields", fieldInfos: [{ fieldName: "County", label: "County" }, { label: "MapNumber" }, { fieldName: "Taxlot", label: "Taxlot" },{ fieldName: "MapTaxlot", label: "MapTaxlot" }] }],
            "Key": "TaxLot",
            "id":"11",
            "GridTemplate": [{"field":"OBJECTID","label":"OBJECTID","sortable":true,"hidden":true},
                              {"field":"Taxlot","label":"Taxlot","sortable":true},
                              {"field":"MapTaxlot","label":"MapTaxlot","sortable":true, colSpan: 2 },
                              {"field":"MapNumber","label":"MapNumber","sortable":true},
                              {"field":"County","label":"County","sortable":true}],
            "EditorTemplate" : []
        },
        {
            "ExtentZoom": true,//CenterAndZoom after load
            "title": "Wheeler County Taxlots ",
            "type": "F",//F-FeatureLayer,G-GraphicsLayer
            "url": "https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/40",
            "outFields": ["*"],
            "LayerColor" : { type: "simple-fill", color: [ 255, 204, 153, 0.9 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "HighlightColor" : { type: "simple-fill", color: [ 0, 255, 255, 0.1 ], style: "solid", outline: {  color: "red",  width: 1 } },
            "InfoTemplate" :[{type: "fields", fieldInfos: [{ fieldName: "County", label: "County" }, { label: "MapNumber" }, { fieldName: "Taxlot", label: "Taxlot" },{ fieldName: "MapTaxlot", label: "MapTaxlot" }] }],
            "Key": "TaxLot",
            "id":"12",
            "GridTemplate": [{"field":"OBJECTID","label":"OBJECTID","sortable":true,"hidden":true},
                              {"field":"Taxlot","label":"Taxlot","sortable":true},
                              {"field":"MapTaxlot","label":"MapTaxlot","sortable":true, colSpan: 2 },
                              {"field":"MapNumber","label":"MapNumber","sortable":true},
                              {"field":"County","label":"County","sortable":true}],
            "EditorTemplate" : []
        }

      ],
      "FeatureLayers":{
         "POINT":"http://development.delasoft.com:8443/arcgis/rest/services/EPS/MasterTable_Geom_Service/FeatureServer/0",
         "MULTIPOINT":"http://development.delasoft.com:8443/arcgis/rest/services/EPS/MasterTable_Geom_Service/FeatureServer/1",
         "LINE":"http://development.delasoft.com:8443/arcgis/rest/services/EPS/MasterTable_Geom_Service/FeatureServer/2",
         "POLYGON":"http://development.delasoft.com:8443/arcgis/rest/services/EPS/MasterTable_Geom_Service/FeatureServer/3",
         "LayerIndex":2
      },
      "MasterLookupFields":[
               {
                     Id:0,
                     'ServiceUrl': 'https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/26/query',
                     'FieldKey':'REGION_GISID',
                     'NameReference':'REG_NO',
                     'ValueReference':'REG_NO'
               },
               {
                     Id:1,
                     'ServiceUrl': 'https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/25/query',
                     'FieldKey':'DISTRICT_GISID',
                     'NameReference':'DISTRICT',
                     'ValueReference':'DISTRICT'
               },
               {
                     Id:2,
                     'ServiceUrl': 'https://gistest1.odot.state.or.us/arcgis1006/rest/services/ames/ames/MapServer/41/query',
                     'FieldKey':'SITE_GISID',
                     'NameReference':'CNTY_NAME',
                     'ValueReference':'OBJECTID'
               }
      ],
      "FieldsData":[
         {
            "Fieldkey":"MAP_CENTER_LATITUDE",
            "FieldValue":""
         },
         {
            "Fieldkey":"MAP_CENTER_LONGITUDE",
            "FieldValue":""
         },
         {
            "Fieldkey":"PROPOSED_MAP_CENTER_LATITUDE",
            "FieldValue":""
         },
         {
            "Fieldkey":"PROPOSED_MAP_CENTER_LONGITUDE",
            "FieldValue":""
         },
         {
            "Fieldkey":"POINT_GEOM_OBJECT_ID",
            "FieldValue":0
         },
         {
            "Fieldkey":"PROPOSED_POINT_GEOM_OBJECT_ID",
            "FieldValue":0
         },
         {
            "Fieldkey":"LINE_GEOM_OBJECT_ID",
            "FieldValue":0
         },
         {
            "Fieldkey":"PROPOSED_LINE_GEOM_OBJECT_ID",
            "FieldValue":0
         },
         {
            "Fieldkey":"POLYGON_GEOM_OBJECT_ID",
            "FieldValue":0
         },
         {
            "Fieldkey":"PROPOSED_POLYGON_GEOM_OBJECT_ID",
            "FieldValue":0
         },
         {
            "Fieldkey":"MULTIPOINT_GEOM_OBJECT_ID",
            "FieldValue":0
         },
         {
            "Fieldkey":"PROPOSED_MULTIPOINT_GEOM_OBJECT_ID",
            "FieldValue":0
         },
         {
            "Fieldkey":"TAX_PARCEL_IDS",
            "FieldValue":''
         },
         {
            "Fieldkey":"TAX_PARCEL_IDS_GEOM",
            "FieldValue":''
         },
         {
            "Fieldkey":"PRIMARY_TAX_PARCEL_IDS",
            "FieldValue":''
         },
         {
            "Fieldkey":"ADC_MAP_GRID_NO",
            "FieldValue":''
         },
         {
            "Fieldkey":"CENTER_GEOM_OBJECT_ID",
            "FieldValue":0
         },
         {
            "Fieldkey":"PROPOSED_CENTER_GEOM_OBJECT_ID",
            "FieldValue":0
         },
         {
            "Fieldkey":"LATITUDE_ROAD_POSITION",
            "FieldValue":""
         },
         {
            "Fieldkey":"LONGITUDE_ROAD_POSTION",
            "FieldValue":""
         },
      ],
      "MapSettings":{

      },
      //"MultipleLocationData":[], // Specific to KEPT project for stroing the multiple location data, if any.
      "UserId":"1"
   }
}


export const  DefaultMapSettingsSinglePoint = {
               "HASHKEY":"1213456789055",
               "IsInternal":"N",
               "IsAllowingInternalEdit":"Y",
               "IsAllowingInternalToEditPoints":"Y",
               "IsAllowingInternalToEditLines":"Y",
               "IsAllowingInternalToEditPolygons":"Y",
               "IsAllowingInternalToEditPointsSignleOrMultiple":"Y",
               "IsAllowingInternalToEditLinesSignleOrMultiple":"Y",
               "IsAllowingInternalToEditPolygonsSignleOrMultiple":"Y",
               "IsAllowingExternalEdit":"Y",
               "IsAllowingExternalToEditPoints":"Y",
               "IsAllowingExternalToEditLines":"Y",
               "IsAllowingExternalToEditPolygons":"Y",
               "IsAllowingExternalToEditPointsSignleOrMultiple":"Y",
               "IsAllowingExternalToEditLinesSignleOrMultiple":"Y",
               "IsAllowingExternalToEditPolygonsSignleOrMultiple":"Y",
               "IsTwoWayMultiPoint":"N"
}

export const  DefaultMapSettingsMultiPoint = {
         "HASHKEY":"1213456789055",
         "IsInternal":"N",
         "IsAllowingInternalEdit":"Y",
         "IsAllowingInternalToEditPoints":"Y",
         "IsAllowingInternalToEditLines":"Y",
         "IsAllowingInternalToEditPolygons":"Y",
         "IsAllowingInternalToEditPointsSignleOrMultiple":"Y",
         "IsAllowingInternalToEditLinesSignleOrMultiple":"Y",
         "IsAllowingInternalToEditPolygonsSignleOrMultiple":"Y",
         "IsAllowingExternalEdit":"Y",
         "IsAllowingExternalToEditPoints":"Y",
         "IsAllowingExternalToEditLines":"Y",
         "IsAllowingExternalToEditPolygons":"Y",
         "IsAllowingExternalToEditPointsSignleOrMultiple":"Y",
         "IsAllowingExternalToEditLinesSignleOrMultiple":"Y",
         "IsAllowingExternalToEditPolygonsSignleOrMultiple":"Y",
         "IsTwoWayMultiPoint":"Y"
}