import React, { Component } from 'react';
import { getEnvVariable } from '../../../modules/environmentalList';
import { GetData } from '../form/formsapi';
import Template from './index';

class CTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mentions: [],
            noticetypementions: [],
            attachmentsmentions:[],
            sidebartablementions: [],
            loadtemplate: false,
            reports:[],
            reportsval:''
        }
        this.TemplateForm = React.createRef()
    }

    componentWillMount() {
        this.loadDropDownData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Typical usage (don't forget to compare props):
        //console.log('template didupdate---------------', prevProps, prevState, this.state, this.props)
        if (this.props !== prevProps) {
            if (this.state.mentions !== prevState.mentions || this.props.templateId != prevProps.templateId)
                setTimeout(() => {
                    this.loadDropDownData();
                }, 200)
            if (this.props.sideBarTables && Array.isArray(this.props.sideBarTables) && this.props.sideBarTables.length > 0 && this.props.isadmin && this.props.sideBarTables !== prevProps.sideBarTables) {
                this.loadSideBarTableColumns();
            }
        }
    }

    loadSideBarTableColumns() {
        const { moduleId, sitePermitTypeId } = this.props;
        let tables = this.props.sideBarTables
        let url = ""
        tables.forEach(element => {
            url = url + 'table=' + element.name + '&';
        });
        var sourceURL = getEnvVariable('Application') + "/api/Application/Fields/" + sitePermitTypeId + "?" + url;
        GetData(sourceURL, "").then(results => {
            if (results && results.length > 0) {
                this.setState({
                    mentions: results  //results.map(res => { return { label: res.Label } })
                })
            }
        })
    }






    getRecordsPromise_old(sourceURL, type) {
        return new Promise((resolve, reject) => {
            (GetData(sourceURL, "").then(results => {
                resolve(results)
                if (type == 'mentions') {
                    if (results && results.length > 0) {
                        this.setState({
                            mentions: results
                        }, () => { resolve(results) })
                    }

                }
                else if (type == 'noticetypementions') {
                    if (results && results.length > 0) {
                        let mapresults = results.map(a => { return { Value: a.Name, Key: a.TemplateId } });
                        this.setState({
                            noticetypementions: mapresults
                        })

                    }
                }

                else if (type == 'attachmentsmentions'){
                    if (results && results.length > 0){
                        let mapattach = results.map(a => { return { Value: a.Name, Key: a.Id } });
                        this.setState({
                            attachmentsmentions: mapattach
                        })
                    }
                }

            }))
        })
    }


    GetDataPromise = (sourceURL) => {
        return new Promise((resolve, reject) => {
            GetData(sourceURL, "").then(results => {
                if (results)
                    resolve(results)

            }).catch(ex => {
                resolve([])
            })
        })
    }

    loadDropDownData() {
        const { moduleId, sitePermitTypeId } = this.props;
        //console.log(sitePermitTypeId)
        //moduleId === 'APPLICATIONS' &&
        let arr = []
        if (sitePermitTypeId > 0) {
            var sourceURL = getEnvVariable('Application') + "/api/Application/Fields/" + sitePermitTypeId;
            var sourceURL1 = getEnvVariable('Admin') + `/api/NoticeTypes/all/${sitePermitTypeId}`;
            var sourceURL2 = getEnvVariable('Report') + `/api/Reports/All`;
            var sourceURL3 = getEnvVariable('Admin') + `/api/AttachmentType/All/Active`;

            arr.push(this.GetDataPromise(sourceURL))
            arr.push(this.GetDataPromise(sourceURL1))
            arr.push(this.GetDataPromise(sourceURL2))
            arr.push(this.GetDataPromise(sourceURL3))
            Promise.all(arr).then((values) => {
                if (Array.isArray(values)) {
                    let mentionsval = values[0]
                    let noticetypementionsval = values[1]
                    let attachmentsVal = values[3]
                    let allreports = values[2]
                    let mapresults = noticetypementionsval.map(a => { return { Value: a.Name, Key: a.TemplateId } });
                    let mapattach = attachmentsVal.map(a => { return { Value: a.Name, Key: a.Id } }); //TemplateId
                    let reportsmapresults = allreports.map(a => { return { Value: a.Title, Key: a.Id } });
                    this.setState({
                        noticetypementions: mapresults, mentions: mentionsval, loadtemplate: true, reports: reportsmapresults, attachmentsmentions: mapattach
                    })
                }
            });



            // arr.push(this.getRecordsPromise(sourceURL, 'mentions'))

            // arr.push(this.getRecordsPromise(sourceURL1, 'noticetypementions'))
            // Promise.all(arr).then((values) => {
            //     console.log(values);
            // });

        } else if (moduleId === 'PERMITS' && sitePermitTypeId > 0) {
            var sourceURL = getEnvVariable('Permit') + "/api/Permit/Fields/" + sitePermitTypeId;
            arr.push(this.GetDataPromise(sourceURL1))

            GetData(sourceURL, "").then(results => {
                //console.log(results);
                if (results && results.length > 0) {
                    this.setState({
                        mentions: results, loadtemplate: true
                    })
                }
            })
        }
        else {
            this.setState({
                loadtemplate: true,
                requiredProps: {
                    ...this.state.requiredProps,
                    mentions: []
                }
            })
        }
    }



    /*Dropdown values to be populating.*/
    //mentions = [{ 'key': 'name', 'value': 'name' }, { 'key': 'lname', 'value': 'lname' }]

    /*Pass this data only you required to replace the content.*/
    /*entryData = {
        name: 'I am first name',
        lname: 'Im last ....'
    };*


    /*This is your call back. Recive the response after saving/updating the data*/
    callBack = (data) => {
        console.log(data)
        this.setState({ save: false }, () => {
            this.props.handleTemplateId(data);
        })
    }




    /**
     * mentions: Dropdown valus for the editor. If not required pass this as null
     * isAdmin: When saving the notice from administration. Parentid prop will be 0 for this.
     * id: Pass the load the data. Default value 0 loads the empty editor.
     * parentid: If passed, loads the existing data based on the passing value.
     * callBack: All the responses will be returned here.
     * entryData: This is the replacing data. Its an object.
     * isEnabled: enables the document
     * canDownload: Download options availble for the user.
     * hasSubject: If subject text box is required, true.
     * subject: subject text
     *
     * */


    saveTemplate = () => {
        this.setState({ save: true })
    }

    GetTemplateFormats = () => {
        this.setState({ templateFormats: true })
    }

    setSubject = (data) => {
        this.setState({ subject: data.subject })
    }


    setAttachment = (data) => {
        this.setState({ attachments: data.attachments })
    }
    setReportsVal = (data) => {
        this.setState({ reportsval: data.reportsval })
    }

    render() {
        let parentFields = this.props.fields || []
        if (this.state.mentions && this.state.mentions.length > 0) {
            let tempmentions = this.state.mentions.map(res => { return { name: res.Value, label: res.Label } })
            parentFields = [...parentFields, ...tempmentions]
        }
        return (
            this.state.loadtemplate && <Template ref={this.TemplateForm}
                mentions={this.state.mentions}
                isAdmin={this.props.isadmin}
                id={this.props.templateId || 0}
                parentid={this.props.parentid || 0}
                callBack={(data) => { this.callBack(data) }}
                entryData={this.props.entryData || null}
                isEnabled={this.props.isEnabled == false ? false : true}
                canDownload={true}
                hasSubject={this.props.hasSubject || false}
                save={this.state.save || false}
                showhistory={this.props.showHistory}
                templateFormats={this.state.templateFormats}
                //hasSubject={(this.props.isadmin === true && this.props.isEnabled === true) ? true : false}
                hideattachments={this.props.hideAttachments || false}
                templateFormatsCallBack={this.props.templateFormatsCallBack}
                noticetypementions={this.state.noticetypementions}
                //attachments={this.state.attachmentsmentions}
                fields={parentFields || []}//{parentFields.push(this.state.mentions) || []}
                setSubject={this.setSubject}
                setAttachment={this.setAttachment}
                subject={this.state.subject}
                attachments={this.state.attachmentsmentions}
                fullheight={this.props.fullheight}
                disabled={this.props.disabled}
                reports={this.state.reports}
                showReports={true}
                reportsval = {this.state.reportsval}
                setReportsVal = {this.setReportsVal}
                refId={this.props.refId}
                refType={this.props.refType}
            />

        );
    }

    componentDidCatch(error, info) {
        console.log(error);
    }
}

export default CTemplate;