import { getEnvVariable } from "../../../modules/environmentalList";

const newcompanyuser = {
    "type": "slidingbutton",
    "key": "newcompanyuserslidingbutton",
    "options": {
        "row": 8,
        "col": 2,
        "width": 2,
        "buttonprops": {
            "text": "Add User",
            "size": "large",
            "color": "primary",
            "variant": "contained"
        },
        excludefromresults: true,
        "label": "User",
        "name": "User",
    },
    open: true,
    "components": [
        {
            "type": "formcontainer",
            "key": "newcompanyuserform",
            "options": {
                "totalcolumns": 2,
                "totalrows": 13,
                "title": "Users ",
                "alignitem": "center",
                "reducername": "adminform",
                "routes": [
                    {
                        "route": "/companies",
                        "routeParams": ":routeid",
                    },
                    {
                        "route": "/users/add"
                    },
                ],
                headerProps: {
                    "editbutton": false,
                    "savebutton": true,
                    "backbutton": true,
                    "closebutton": false,
                    "navigateadd": true,
                    "breadcrumbs": [
                        // { "link": "View/Edit Applicant", href: "#/companies", popupclose: true, "materialicon": "edit", className: 'eps-breadcrumb-item ', class: 'text-light' },
                        { "link": "Add User", "materialicon": "person", className: 'eps-breadcrumb-item', class: 'text-light' }
                    ],
                },
                "afterSaveroutes": {
                    "route": "/companies/",
                    "routeparams": 'CompanyId',
                    "connectingroute": '/users/view/',
                    "connectingrouteid": 'UserId'
                },
                "back": {
                    "routes": [
                        {
                            "route": "/companies",
                            "routeParams": "routeid",
                        },
                        {
                            "route": "/users",
                            exactRoute: true
                        },
                    ],
                    "popupback": true,
                    "issubgrid": true
                },
                "name": "newcompanyuserform",
                "crud": {
                    "update": {
                        "post": {
                            "url": getEnvVariable('MasterAdmin')+`/api/ExternalUser`,
                            "urlpath": `/{Id}/`+getEnvVariable('ModuleKey')+`/Add`,
                            "routeProps": [{
                                "custom": "Id"
                            }]
                        }
                    }
                },
                "validations": [
                    {
                        get: {
                            "url": getEnvVariable('MasterAdmin')+`/api/ProjectMessages/RulesByName?name=master.users`
                        },
                        transformProps: {
                            name: 'Key',
                            subfieldname: 'MASTER.USERS_',
                            addsubfieldname: 'USER.'
                        }

                    },
                    // {
                    //     get: {
                    //         "url": getEnvVariable('MasterAdmin')+`/api/ProjectMessages/RulesByName?name=master.internalusers`
                    //     },
                    //     transformProps: {
                    //         name: 'Key',
                    //         subfieldname: 'MASTER.INTERNALUSERS_'
                    //     }

                    // },
                    {
                        get: {
                            "url": getEnvVariable('MasterAdmin')+`/api/ProjectMessages/RulesByName?name=master.contacts`
                        },
                        transformProps: {
                            name: 'Key',
                            subfieldname: 'MASTER.CONTACTS_',
                            addsubfieldname: 'CONTACTS.'
                        }

                    }
                ]
            },
            "formgridadd": true,
            "components": [

                {
                    "type": "formtextbox",
                    "key": "username",
                    "options": {
                        "row": 1,
                        "col": 0,
                        "width": 12,
                        "label": "Username",
                        "name": "User.UserName"
                    },

                },
                {
                    "type": "infotext",
                    "key": "Infotext",
                    "options": {
                        "row": 2,
                        "col": 0,
                        "width": 12,
                        "text": "Username Policy:",

                    }
                },
                {
                    "type": "infotext",
                    "key": "Infotext",
                    "options": {
                        "row": 2,
                        "col": 0,
                        "width": 12,
                        "text": "1. Username can contain letters (a-z), numbers (0-9), underscore (_) and periods (.).",
                    }
                },
                {
                    "type": "infotext",
                    "key": "Infotext",
                    "options": {
                        "row": 2,
                        "col": 0,
                        "width": 12,
                        "text": "2. Username cannot contain an ampersand (&), equals sign (=), apostrophe ('), dash (-), plus sign (+), comma (,), brackets (<,>)"

                    }
                },
                {
                    "type": "infotext",
                    "key": "Infotext",
                    "options": {
                        "row": 2,
                        "col": 0,
                        "width": 12,
                        "text": "3. The first character of your username must be ascii letter (a-z) or number (0-9)."

                    }
                },
                {
                    "type": "formtextbox",
                    "key": "firstname",
                    "options": {
                        "row": 3,
                        "col": 0,
                        "width": 6,
                        "label": "First Name",
                        "name": "User.FirstName"
                    },

                },
                {
                    "type": "formtextbox",
                    "key": "lastname",
                    "options": {
                        "row": 3,
                        "col": 1,
                        "width": 6,
                        "label": "Last Name",
                        "name": "User.LastName"
                    },

                },
                {
                    "type": "formtextbox",
                    "key": "title",
                    "options": {
                        "row": 4,
                        "col": 0,
                        "width": 6,
                        "label": "Title",
                        "name": "Title"
                    },

                },
                {
                    "type": "formtextbox",
                    "key": "email",
                    "options": {
                        "row": 5,
                        "col": 0,
                        "width": 6,
                        "label": "Email",
                        "name": "Email",
                        removeotherfield : [
                            {removefield : 'sendactivationlink', changedvalue: 'N'}
                        ]
                    },

                },
                {
                    "type": "cformphonetext",
                    "key": "Phone",
                    "options": {
                        "row": 5,
                        "col": 1,
                        "width": 6,
                        "label": "Contact Number",
                        "name": "Phone"
                    }
                },
                {
                    "type": "formswitch",
                    "key": "statusswitch",
                    "options": {
                        "row": 6,
                        "col": 0,
                        "width": 2,
                        "label": "Status",
                        "name": "User.Status",
                        confirmation: true,
                        initialvalue: 'I',
                        disabled: true,
                        conditions: {
                            enable: true,
                        },
                        "transformProps": {
                            "A": true,
                            "I": false,
                            true: "A",
                            false: "I"
                        }
                    }
                },
                {
                    "type": "formcheckbox",
                    "key": "official",
                    "options": {
                        "row": 6,
                        "col": 1,
                        "width": 2,
                        "label": "Admin",
                        "name": "IsOfficial",
                        initialvalue: 'N',
                      //  onlyLabel: true,
                        // hidecond: getEnvVariable('IsSubModuleSelection'),
                        // hideval: "Y",
                        "transformProps": {
                            "Y": true,
                            "N": false,
                            true: "Y",
                            false: "N"
                        }
                    }
                },
                // {
                //     "type": "formcheckbox",
                //     "key": "IsConsultant",
                //     "options": {
                //         "row": 6,
                //         "col": 2,
                //         "width": 3,
                //         "label": "Consultant",
                //         "name": "IsConsultant",
                //      //   onlyLabel: true,
                //         initialvalue: 'N',
                //         "transformProps": {
                //             "Y": true,
                //             "N": false,
                //             true: "Y",
                //             false: "N"
                //         }

                //     }
                // },
                {
                    "type": "formcheckbox",
                    "key": "sendactivationlink",
                    "options": {
                        "row": 6,
                        "col": 3,
                        "width": 4,
                        "label": "Send Activation Link",
                        "name": "IsActivationLink",
                        initialvalue: 'N',
                        "transformProps": {
                            "Y": true,
                            "N": false,
                            true: "Y",
                            false: "N"
                        },
                        conditions: {
                            hide: [{ 'Email': '' }],
                        },
                    }
                },
            ]
        }]
}

export default newcompanyuser;